<template>
	<router-view v-slot="{ Component }">
		<transition
			:name="transitionName"
			appear
		>
			<div
				:key="$route.name"
				class="min-h-screen sm:px-8 sm:py-16"
			>
				<validation-form
					v-slot="{ meta }"
					:validation-schema="validationFormSchema"
					class="signup-box"
				>
					<div
						class="flex flex-col relative w-full h-full overflow-y-auto"
						aria-label="signup"
					>
						<img
							class="mt-6 mb-2 mx-auto w-48"
							src="/static/images/qomon/black.svg"
						>
						<component
							:is="Component"
							:space-invitation="spaceInvitation"
							v-model:user-data="userData"
							v-model:user-avatar="userAvatar"
							v-model:group-invitation="groupInvitation"
							v-model:by-pass-signup="byPassSignup"
							v-model:user-code="userCode"
							class="px-10 sm:px-8 md:px-20 py-8 pb-8"
						/>

						<navigation
							v-model:by-pass-signup="byPassSignup"
							v-model:user-data="userData"
							v-model:user-avatar="userAvatar"
							v-model:group-invitation="groupInvitation"
							:invitation-token="invitationToken"
							:routes="routes"
							:is-form-valid="meta.valid"
							class="sm:px-8 md:px-4"
						/>
					</div>
				</validation-form>
			</div>
		</transition>
	</router-view>
</template>

<script>
import { mapGetters } from 'vuex'
import session_store from '../../../models/session_store'
import { defineAsyncComponent } from 'vue'
import { Form as ValidationForm } from 'vee-validate'
import '/src/utils/veeValidate'

const baseInput = {
	value: '',
	name: '',
	title: '',
	placeholder: '',
	autocomplete: '',
	confirmed: false,
	isDisabled: false,
	isSecret: false,
	updateInstant: true,
	methodEmitChange: () => {},
}

export default {
	components: {
		ValidationForm,
		navigation: defineAsyncComponent(() => import('./signupNavigation.vue')),
	},

	data() {
		return {
			transitionName: 'slide-to-next',
			prevRouteName: '',
			byPassSignup: false,
			routes: [
				{
					name: 'create-account',
				},
				{
					name: 'validate-email',
				},
				{
					name: 'add-account-details',
				},
				{
					name: 'join-a-group',
				},
			],

			validationFormSchema: {},
			emailExcludedValues: [],

			userData: [
				{
					...baseInput,
					name: 'firstname',
					title: this.$t(`_COMMON.FIRSTNAME`),
					placeholder: this.$t(`_COMMON.FIRSTNAME`),
					autocomplete: 'given-name',
					mandatory: XCUSTOM_USER_AGENT === 'kennedy',
				},
				{
					...baseInput,
					name: 'lastname',
					title: this.$t(`_COMMON.LASTNAME`),
					placeholder: this.$t(`_COMMON.LASTNAME`),
					autocomplete: 'family-name',
					mandatory: XCUSTOM_USER_AGENT === 'kennedy',
				},
				{
					...baseInput,
					value: '',
					name: 'email',
					title: this.$t(`_COMMON.EMAIL`),
					placeholder: this.$t(`_COMMON.EMAIL`),
					methodEmitChange: this.checkExistingMail,
					autocomplete: 'email',
				},
				{
					...baseInput,
					name: 'password',
					title: this.$t(`APP.SIGNUP.PASSWORD`),
					placeholder: this.$t(`APP.SIGNUP.PASSWORD`),
					isSecret: true,
					autocomplete: 'new-password',
				},
				{
					...baseInput,
					name: 'address',
					title: this.$t(`_COMMON.ADDRESS`),
					placeholder: this.$t(`_COMMON.ADDRESS`),
					autocomplete: 'street-address',
				},
				{
					...baseInput,
					name: 'zip',
					title: this.$t(`_COMMON.POSTAL_CODE`),
					placeholder: this.$t(`_COMMON.POSTAL_CODE`),
					autocomplete: 'postal-code',
					mandatory: XCUSTOM_USER_AGENT === 'kennedy',
				},
				{
					...baseInput,
					name: 'city',
					title: this.$t(`_COMMON.ADDRESS_DETAILS.CITY`),
					placeholder: this.$t(`_COMMON.ADDRESS_DETAILS.CITY`),
					autocomplete: 'address-level2',
				},
				{
					...baseInput,
					name: 'phone',
					title: this.$t(`APP.SIGNUP.PHONE_NUMBER`),
					placeholder: this.$t(`APP.SIGNUP.PHONE_NUMBER`),
					autocomplete: 'tel-area-code',
				},
				{
					...baseInput,
					name: 'birthdate',
					title: this.$t(`_COMMON.BIRTHDATE`),
					placeholder: this.$t(`_COMMON.BIRTHDATE`),
					autocomplete: 'bday',
					type: 'birthdate',
				},
			],
			userAvatar: '',
			userCode: ['', '', '', ''],
			groupInvitation: {
				...baseInput,
				error: '',
				name: 'invitation-code',
				title: this.$t(`APP.SIGNUP.INVITATION_CODE`),
			},

			spaceInvitation: '',
			invitationToken: '',
		}
	},

	computed: {
		...mapGetters('@authentication', ['getCredentialsEntered']),
	},
	watch: {
		'$route.name'(value) {
			this.toggleTransitionAnimation(value)
			this.methodSetFormValidationSchema()
		},
	},
	mounted() {
		this.checkRouteParams()
		this.$nextTick(() => {
			this.checkRouteQuery()
		})
		this.methodSetFormValidationSchema()
	},
	methods: {
		toggleTransitionAnimation(currentRouteName) {
			const currentRouteIndex = this.routes.findIndex((r) => r.name === currentRouteName)
			const prevRouteIndex = this.routes.findIndex((r) => r.name === this.prevRouteName)

			if (currentRouteIndex > prevRouteIndex) {
				this.transitionName = 'slide-to-prev'
			}
			else {
				this.transitionName = 'slide-to-next'
			}

			this.prevRouteName = currentRouteName
		},
		checkExistingMail() {
			const email = this.userData?.find((i) => i.name === 'email')?.value

			session_store.checkMail(email).catch(() => this.emailExcludedValues.push(email))
		},

		checkRouteParams() {
			if (this.getCredentialsEntered) {
				this.userData.find((i) => i.name === 'email').value = this.getCredentialsEntered.email
				this.userData.find((i) => i.name === 'password').value = this.getCredentialsEntered.password
			}
		},

		checkRouteQuery() {
			if (this.$route.query) {
				const userKeys = this.$route.query

				if (userKeys) {
					Object.keys(userKeys).forEach((aUserKey) => {
						if (aUserKey === 'campaign') {
							this.spaceInvitation = userKeys[aUserKey]
							return
						}
						if (aUserKey === 'invitation_token') {
							this.invitationToken = userKeys[aUserKey]
						}

						const aUserDataIndex = this.userData.findIndex((aData) => aData.name === aUserKey)

						if (aUserDataIndex !== -1) {
							this.userData[aUserDataIndex].methodEmitChange(userKeys[aUserKey])
							this.userData[aUserDataIndex].value = userKeys[aUserKey]
							if (aUserKey === 'email') {
								this.userData[aUserDataIndex].disabled = true
							}
						}
					})
				}
			}
		},

		methodSetFormValidationSchema() {
			switch (this.$route.name) {
				case 'create-account':
					this.validationFormSchema = {
						firstname: 'required',
						lastname: 'required',
						email: { required: true, email: true, unique: this.emailExcludedValues },
						password: 'strong_password',
						cgs: 'required',
					}
					break

				case 'add-account-details':
					if (XCUSTOM_USER_AGENT === 'kennedy') {
						this.validationFormSchema = {
							zip: 'required',
							phone: 'phone_number',
						}
						if (this.getCredentialsEntered.raw_id_token) {
							this.validationFormSchema = {
								...this.validationFormSchema,
								firstname: 'required',
								lastname: 'required',
							}
						}
					}
					else {
						this.validationFormSchema = {
							phone: 'phone_number',
						}
					}

					break

				case 'join-a-group':
					this.validationFormSchema = { 'invitation-code': 'required' }
					break

				default:
					this.validationFormSchema = {}
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/animations.scss';

.signup-box {
	max-width: 640px;
	box-shadow:
		0 1px 6px 0 rgba(33, 62, 97, 0.2),
		0 0 1px 0 rgba(33, 62, 97, 0.15);
	@apply min-h-screen bg-white px-8 w-full relative z-10 mx-auto overflow-hidden sm:min-h-full sm:w-3/5 sm:rounded-2xl lg:px-16;
	transition: all ease 0.5s;
}

</style>
