import { ElMessage } from 'element-plus'
import router from '../router.js'
import store from '../store/stateStore.js'
import { i18n } from './i18nizer'

const translate = (key) => {
	return i18n.global.t(`ERRORS.${key}`)
}

export const handleErrors = (status, response, responseText) => {
	let message = ''
	switch (status) {
		case 400:
			if (response.message && response.message.toString().indexOf('No such key') <= -1) {
				message = response.message
			} else {
				message = translate(400)
				router.push({ name: 'login' })
			}
			break
		case 403:
			if (responseText && responseText !== 'Old password is invalid') {
				router.push({ name: 'download' })
			} else if (responseText) {
				message = responseText
			}
			break
		case 401:
		case 402: {
			// 	freshMessage = 'Problème d\'authentification, veuillez vous re-authentifier'
			const freshMessage = translate(401)
			if (response.req.url.includes('/api/user/mail?mail=')) {
				return
			} else if (response.message && response.message.toString().indexOf('No such key') === -1) {
				message = response.message ? response.message : freshMessage
			} else {
				message = freshMessage
			}
			store.commit('LOGOUT')
			router.push({ name: 'login' })
			break
		}
		// case 500:
		// case 502:
		// case 504:
		// case 521:
		// 	store.commit('LOGOUT')
		// 	router.push({ name: 'login' })
		case 503:
			message = `${translate('INVALID_FILE_FORMAT')}: ${response.text}`
			break
		default:
			if (status < 300) {
				break
			}
			if (status >= 500 && status < 600) {
				message = translate(500)
			} else if (status >= 400 && status !== 413) {
				message = translate(status)
			}
	}
	// if (message !== '') {
	// 	Message({
	// 		message: message,
	// 		showClose: true,
	// 		type: 'error',
	// 		duration: 7000,
	// 	})
	// }
}
;(function (open) {
	XMLHttpRequest.prototype.open = function (method, url, async, userConnected, pass) {
		this.addEventListener(
			'loadend',
			function () {
				if (!navigator.onLine) {
					ElMessage({
						message: translate('OFFLINE'),
						showClose: true,
						type: 'warning',
					})
				}
			},
			false
		)

		open.call(this, method, url, async, userConnected, pass)
	}
})(XMLHttpRequest.prototype.open)
