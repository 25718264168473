<template>
	<action-creation-helper-container
		small-margin-after-title
		less-padding-at-top-and-bottom
		:header-height="headerHeight"
	>
		<div class="w-full flex items-center justify-center">
			<div class="flex flex-col items-center justify-center w-full xs:w-10/12 sm:w-8/12 md:w-7/12">
				<div class="flex flex-col w-full justify-start items-start gap-5">
					<span class="font-title text-3xl">{{ $t('ACTION.CALL_TO_ACTION.SELECT_MEMBERS_TITLE') }}</span>
					<div
						class="w-full flex items-center gap-3"
					>
						<tab-with-numbers
							v-for="(tab, index) in tabs"
							:key="index"
							:title="methodGetTitleTabs(tab.name)"
							:is-diabled="methodGiveIsDisabledBoolean(tab.name)"
							:selected="tabSelected === tab.name"
							:count="tab.name === 'teams' ? totalAmountSelectedTeams : totalAmountSelectedUsers"
							:value="tab.name"
							:status-pack="tab.statusPack"
							font-size="1.1rem"
							class="p-3"
							@tab-click="methodSetView(tab.name, tab.statusPack, tab.popupPacker, tab.featurePack)"
						/>
					</div>
				</div>
				<div
					id="CTA-team"
					class="w-full"
				>
					<div class="w-full overflow-hidden flex">
						<div
							v-show="!isMemberChoosen"
							class="h-full w-full"
						>
							<div class="w-full bg-white py-6 rounded-md">
								<div class="w-full flex flex-col items-center pr-5 justify-between sm:flex-row flex-wrap gap-y-2">
									<div
										style="max-width: 65%;"
										class="w-full"
									>
										<div
											class="w-full search-input-container flex items-center px-4 py-1 border-gray-300 rounded-full"
										>
											<div
												class="qi-search is-left text-2xl text-gray-500 mr-2 flex items-center "
											/>
											<input
												v-model="searchTeamInput"
												class="h-full w-full q-search fix-input-search focus:outline-none"
												:placeholder="$t('ACTION.CALL_TO_ACTION.SEARCH.SEARCH_TEAMS')"
											>
										</div>
									</div>
									<div
										class="pointer hover:text-pink-main sm:mt-0 flex items-center gap-1.5"
										@click="selectAllTeams"
									>
										<i class="gui-select_all text-xl" />
										<span>{{ t.selectAllMembers }}</span>
									</div>
								</div>
								<div
									v-if="localTeams.length > 0"
									class="teams mt-6 w-full overflow-auto"
								>
									<div class="q-hide-scroll-content py-1 w-full ">
										<div
											v-for="team in localTeams"
											:key="team.id"
											class="team flex justify-between items-center px-4 py-4 mb-2 cursor-pointer w-full flex-wrap"
											:class="[
												{'--focus': team.focus},
												{'--dark': team?.private},
											]"
											@click="toggleFocusTeam(team)"
										>
											<div class="flex justify-start items-center gap-2 w-fit">
												<div class="w-fit">
													<q-check
														:theme="team?.private ? 'dark' : 'light'"
														:checked="team.focus"
														@change="toggleFocusTeam(team)"
													/>
												</div>
												<div class="min-w-[60px]">
													<div class="bg-gray-200 flex items-center w-fit rounded-lg text-black px-2 py-0.5 gap-1 ">
														<i class="gui-group" />
														<span class="font-bold text-sm">{{ team?.users?.length }}</span>
													</div>
												</div>

												<span
													v-if="team?.private"
													class="bg-gray-700 rounded-lg flex items-center justify-center text-white px-1.5 py-1"
												>
													<i class="gui-secret" />
												</span>
												<span
													class="team-name-span bold w-fit"
												>{{ team.name }}</span>
											</div>
											<div class="flex items-center gap-5">
												<div
													v-if="team.leaders && team.leaders.length > 0"
													class="flex items-center"
												>
													<div class="flex">
														<!-- team.leaders.slice(0, 2) -->
														<div
															v-for="(aLeader, leaderIndex) in team.leaders.slice(0,3)"
															:key="aLeader.id"
															:style="`z-index: ${leaderIndex}`"
															class="flex relative w-9 h-9 rounded-full will-change-transform transition-all transform hover:-translate-y-3"
															:class="[team?.private ? 'bg-black': 'bg-white',{ '-mr-3': leaderIndex < team.leaders.length - 1}]"
															:title="`${aLeader.firstname} ${aLeader.surname}`"
														>
															<div class="flex relative flex-shrink-0">
																<!-- Member with icon -->
																<img
																	v-if="aLeader.avatar && aLeader.avatar !== null"
																	:src="aLeader.avatar"
																	:style="[
																		`border: 2px solid ${aLeader.role_data.color}`
																	]"
																	class="w-9 h-9 rounded-full"
																>
																<!-- Member without icon -->
																<div
																	v-else
																	class="rounded-full w-9 h-9 flex items-center justify-center opacity-100"
																	:style="[
																		{ color: aLeader.role_data.color },
																		{ backgroundColor: aLeader.role_data.color + '15' },
																		`border: 2px solid ${aLeader.role_data.color}`
																	]"
																>
																	<p
																		v-if="aLeader.firstname && aLeader.surname"
																		class="text-ellipsis overflow-hidden"
																	>
																		{{ aLeader.firstname.toUpperCase().split('')[0]
																		}}{{ aLeader.surname.toUpperCase().split('')[0] }}
																	</p>
																</div>
																<!-- Team lead icon -->
																<i
																	v-if="methodSetLeaderIconOnRightUser(leaderIndex, team.leaders.length)"
																	class="team-reward-icon gui-rewards bg-pink-main text-white border border-white rounded-full absolute -right-1 -bottom-1 z-10"
																/>
															</div>
															<div
																v-if="leaderIndex === 2 && team.leaders.length > 3"
																:class="{ '-ml-3': leaderIndex > 0 }"
																class="w-7 h-7 text-sm bg-pink-50 ring-1 ring-white rounded-full flex justify-center items-center"
															>
																+{{ team.leaders.length - 3 }}
															</div>
														</div>
													</div>
												</div>
												<div
													v-if="team.hide_users"
													class="flex items-center gap-2"
												>
													<span
														class="avatar-placeholder w-11 h-11 flex justify-center items-center rounded-full border-2"
														:class="
															team.private ? 'bg-[#3b3b51] border-black' : 'bg-gray-100 border-white '
														"
													>
														<i class="gui-eye_dashed text-gray-medium text-lg" />
													</span>
													<div
														class="text-sm"
														:class="team.private ? 'text-gray-medium' : 'text-gray-strong '"
													>
														{{ $t('USER_MANAGEMENT.USER_LIST.TEAMS.HIDDEN_MEMBERS') }}
													</div>
												</div>
												<template v-else>
													<div
														v-if="team.users && team.users.length > 0"
														class="flex items-center"
													>
														<div class="flex">
															<div
																v-for="(user, index) in team.users.slice(0, 3)"
																:key="user.id"
																:class="{ '-ml-3': index > 0 }"
																class="flex"
																:style="`z-index: ${index}`"
																:title="`${user.firstname} ${user.surname}`"
															>
																<div
																	v-if="!user.avatar || user.avatar === 'null'"
																	style="min-width: 2rem"
																	:class="team?.private ? 'ring-black text-black' : 'ring-white text-white'"
																	class="w-9 h-9 text-sm bg-blue-medium ring-2  rounded-full flex justify-center items-center flex-shrink-0 will-change-transform transition-all transform hover:-translate-y-3"
																>
																	{{ user.firstname.charAt(0) }}{{ user.surname.charAt(0) }}
																</div>
																<img
																	v-else
																	style="min-width: 2rem"
																	:class="team?.private ? 'ring-black' : 'ring-white'"
																	class="w-9 h-9 rounded-full flex-shrink-0 ring-2  will-change-transform transition-all transform hover:-translate-y-3"
																	:src="user.avatar"
																>
																<div
																	v-if="index === 2 && team.users.length > 3"
																	:class="[team?.private ? 'ring-black text-black' : 'ring-white text-white', { '-ml-3': index > 0 }]"
																	style="min-width: 2rem"
																	:style="`z-index: ${index + 1}`"
																	class="w-9 h-9 text-sm bg-blue-medium ring-2 rounded-full flex justify-center items-center flex-shrink-0"
																>
																	+{{ team.users.length - 3 }}
																</div>
															</div>
														</div>
													</div>
													<div v-else>
														{{ $t('CARTO.CC.CALL_TO_ACTION.MEMBERS_HERE_V2', 0, {count: 0}) }}
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
								<div
									v-else
									class="mt-16 flex justify-center text-xl bold"
								>
									{{ t.noTeams }}
								</div>
							</div>
						</div>
						<div
							v-show="isMemberChoosen"
							class="w-full h-full"
						>
							<div class="w-full flex items-center flex-wrap py-6 gap-y-2">
								<div
									style="max-width: 65%;"
									class="w-full"
								>
									<div
										class="search-input-container flex items-center px-4 py-1 border-gray-300 rounded-full"
									>
										<div class="qi-search is-left text-2xl text-gray-500 mr-2 flex items-center" />
										<input
											v-model="searchUserInput"
											class="h-full w-full q-search fix-input-search focus:outline-none"
											:placeholder="$t('ACTION.CALL_TO_ACTION.SEARCH.SEARCH_MEMBERS')"
										>
									</div>
								</div>
								<el-divider
									class="text-2xl ml-4"
									direction="vertical"
								/>
								<availibilities-dropdown
									:title="$t('USER_MANAGEMENT.AVAILABILITY.DROPDOWN_TITLE')"
									:border-color="'border-gray-300'"
									@availibilities-changes="methodSetAvailibilitiesFilters"
									@none-availabilities-change="methodSetSearchForNoneAvailabilities"
								/>
								<div class="flex items-center ml-3">
									<q-check
										v-model="setFilterWithoutTeam"
										class="text-md font-semibold text-gray-600"
									>
										{{ t.memberWithoutTeams }}
									</q-check>
								</div>
							</div>
							<div class="tableContainer">
								<el-table
									class="settings-table overflow-auto"
									header-cell-class-name="tableHeaderCellStyle"
									:row-class-name="
										(scope) => `q-hide-scroll-content clickable ${isRowSelected(scope)}`
									"
									:data="computedUsersArrayOnlyDisplayed"
									:max-height="'100%'"
									@row-click="(row) => toggleUserSelection(row, !row.isSelected)"
								>
									<el-table-column
										width="50"
										min-width="50"
									>
										<template #header>
											<div
												class="pointer"
												@click="selectAllUsers"
											>
												<i
													class="gui-select_all text-xl"
													:class="{ 'color-pink': areAllUsersSelected }"
												/>
											</div>
										</template>
										<template #default="{ row }">
											<q-check
												:value="row.isSelected"
												class="center"
												:disabled="isAlreadyInTeam(row) || isAlreadyLeader(row)"
												:checked="row.isManuallySelected || isAlreadyInTeam(row)"
												@change="toggleUserSelection(row, !row.isSelected)"
											/>
										</template>
									</el-table-column>
									<el-table-column
										:prop="'avatar'"
										width="50"
										min-width="50"
									>
										<template #default="{ row }">
											<div class="flex items-center gap-1">
												<UserAvatar
													:user="row"
												/>
											</div>
										</template>
									</el-table-column>
									<el-table-column
										:prop="'firstname'"
										:label="t.firstname.toUpperCase()"
										width="200"
										min-width="200"
									>
										<template #default="{ row }">
											<span
												:class="{ 'text-bold': row.isSelected }"
												class="text-row"
											>
												{{ row.firstname || '-' }}</span>
										</template>
									</el-table-column>
									<el-table-column
										:prop="'surname'"
										:label="t.lastname.toUpperCase()"
										width="200"
										min-width="200"
									>
										<template #default="{ row }">
											<span class="text-row"> {{ row.surname || '-' }}</span>
										</template>
									</el-table-column>
									<el-table-column
										:prop="'status'"
										:label="t.lastname.toUpperCase()"
										width="200"
										min-width="200"
									>
										<template #header>
											<span class="text-header">
												{{ t.status.toUpperCase() }}&nbsp;
												<el-tooltip placement="top">
													<template #content>
														<span v-dompurify-html="t.tooltip" />
													</template>
													<i class="gui-infos" />
												</el-tooltip>
											</span>
										</template>
										<template #default="{ row }">
											<el-tooltip
												:disabled="
													row.status == undefined || row.status == null || !methodIsAStatus(row)
												"
												placement="top"
											>
												<template #content>
													<div
														v-for="status in methodGetMemberStatus(row.status)"
														:key="status.id"
														class="text-sm"
													>
														{{ status }}
													</div>
												</template>
												<div
													v-if="row.status !== undefined && row.status !== null && methodIsAStatus(row)"
													class="text-row"
												>
													<span>
														{{ methodGetMemberStatus(row.status)[0] }}
													</span>
													<span
														v-if="methodGetMemberStatus(row.status).length > 1"
														class="ml-1 w-7 h-7 flex items-center justify-center text-xs bg-pink-100 rounded-full"
													>
														+{{ methodGetMemberStatus(row.status).length - 1 }}
													</span>
												</div>
												<span
													v-else
													class="text-row"
												> {{ '-' }}</span>
											</el-tooltip>
										</template>
									</el-table-column>
									<el-table-column
										:prop="'teams'"
										:label="t.teams.toUpperCase()"
										min-width="300"
									>
										<template #default="{ row }">
											<el-tooltip
												:disabled="
													readableTeams(row).split(',').length === 1 ||
														readableTeams(row).split(',')[0] === '-'
												"
												placement="top"
											>
												<template #content>
													<div class="text-sm">
														<span
															v-for="team in readableTeams(row).split(',')"
															:key="team.id"
														>{{ team }}<br></span>
													</div>
												</template>
												<div class="text-row">
													<span
														class="bold"
														:class="{ 'teams-span': readableTeams(row).split(',')[0] !== '-' }"
													>
														{{ readableTeams(row).split(',')[0] }}</span>
													<span
														v-if="readableTeams(row).split(',').length > 1"
														class="ml-1 w-7 h-7 flex items-center justify-center text-xs bg-pink-100 rounded-full"
													>
														+{{ readableTeams(row).split(',').length - 1 }}
													</span>
												</div>
											</el-tooltip>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
					<!-- <div class="q-fullwidth q-m-t q-p-l">
				<div class="w-5-12 q-is-secondary"> -->
					<!-- 🛑 do not delete the line below 👇 because there is some logic behind
					<h2 class="no-m bold hidden">{{ uniqueUsersIds.length }}</h2> -->
					<!-- </div>
			</div> -->
				</div>
			</div>
		</div>
		<popup-packer ref="actioncreationselectmembers" />
	</action-creation-helper-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import deburr from 'lodash/deburr'
import filter from 'lodash/filter'
import some from 'lodash/some'
import { ACTION } from '../../../../constants'
import { defineAsyncComponent, nextTick } from 'vue'
import analytics, { TRACKING } from '../../../../external/analytics'

export default {
	components: {
		actionCreationHelperContainer: defineAsyncComponent(
			() => import('../ActionCreationHelperContainer'),
		),
		AvailibilitiesDropdown: defineAsyncComponent(
			() => import('../../../general/availibilities-dropdown.vue'),
		),
		TabWithNumbers: defineAsyncComponent(() => import('../../../general/tabWithNumber.vue')),
		PopupPacker: defineAsyncComponent(() => import('../../../packs/popupPacker.vue')),
		UserAvatar: defineAsyncComponent(() => import('../../../user_management-module/user-list/sub-components/UserAvatar.vue')),
	},

	emits: ['update:nextValidatorBoolean', 'update:errorMessage', 'update:nextFunction', 'update:nextLabelButton'],

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			isMemberChoosen: false,
			localUsers: [],
			localTeams: [],
			totalAmountSelectedUsers: 0,
			totalAmountManuallySelectedUsers: 0,
			totalAmountSelectedTeams: 0,
			areAllUsersSelected: false,
			searchUserInput: '',
			searchTeamInput: '',
			availibilities: [],
			searchForNoneAvailabilities: false,
			setFilterWithoutTeam: false,
			tabs: [
				{
					name: 'teams',
					popupPacker: 'actioncreationselectmembers',
					featurePack: 'access_web_teams',
				},
				{ name: 'members', statusPack: 'enabled' },
			],
			tabSelected: 'teams',
			realStatusArray: [
				'available_holidays',
				'available_weekend',
				'available_week_night',
				'available_all_the_time',
			],
		}
	},

	computed: {
		...mapGetters('@packs', ['getAccessStatusMembersTeams']),
		...mapGetters('@action', ['newAction', 'steps', 'activeStep', 'entryPoint']),
		...mapGetters(['getAllUsers', 'userConnected']),
		...mapGetters('@team', ['getTeams']),
		...mapGetters('@search', [
			'getSearchAddressIncluded',
			'getSearchPollingStationsIncluded',
			'getAllowUnknowAddressesOnActionCreation',
		]),

		uniqueUsersIds() {
			// Create one array containing all users ids for each team and manually selected user, without duplicates
			let teamsUsers = []
			if (this.newAction.teamsIds && this.getTeams.length) {
				teamsUsers = this.getTeams
					// Filter only selected teams that have Users (can be [])
					.filter((team) => this.newAction.teamsIds.indexOf(team.id) > -1 && team.users !== null)
			}

			let allIds = []
			if (teamsUsers.length) {
				allIds = teamsUsers
					// We only want the users in each team (map), concatenated in one array (flat)
					.flatMap((team) => team.users)
					// Get only the users id
					.map((user) => user.id)
					// Then add the manually selected Users to the array of ids
					.concat(this.newAction.usersIds)
			}
			else {
				allIds = this.newAction.usersIds
			}
			// Remove duplicates
			return allIds.filter((id, pos, array) => array.indexOf(id) === pos)
		},

		t: function () {
			let prefix = 'ACTION.CALL_TO_ACTION'

			return {
				emptyStateTeam: this.$t(`${prefix}.SEARCH.TEAM_EMPTY`),
				emptyStateUser: this.$t(`${prefix}.SEARCH.USER_EMPTY`),
				memberWithoutTeams: this.$t(`${prefix}.SEARCH.MEMBER_WITHOUT_TEAMS`),
				firstname: this.$t('_COMMON.FIRSTNAME'),
				lastname: this.$t('_COMMON.LASTNAME'),
				status: this.$t('_COMMON.STATUS'),
				searchHelp: this.$t(`${prefix}.SEARCH.HELP`),
				searchHelpTeam: this.$t(`${prefix}.SEARCH.TEAM_HELP_CTA`),
				searchHelpUser: this.$t(`${prefix}.SEARCH.USER_HELP_CTA`),
				searchUser: this.$t(`${prefix}.SEARCH.USER`),
				teamLabel: this.$t(`${prefix}.TEAMS`),
				teams: this.$t(`${prefix}.TEAMS`),
				noTeams: this.$t(`${prefix}.NO_TEAMS`),
				users: this.$t(`${prefix}.USERS`),
				// Submit action
				error: this.$t(`${prefix}.ERROR`),
				success: this.$t(`${prefix}.SUCCESS`),
				valid: {
					title: this.$t(`${prefix}.VALIDATE.TITLE`),
					type: this.$t(`${prefix}.VALIDATE.TYPE`),
					volonteers: this.$t(`${prefix}.VALIDATE.VOLONTEERS`),
				},
				tooltip: this.$t(`${prefix}.MEMBERS_AVAILABILITIES_TOOLTIP`),
				selectAllMembers: this.$t(`${prefix}.MEMBERS_SELECT_ALL`),
				ctaUsers: (number) => this.$tc(`${prefix}.USER_LENGTH`, number, { count: number }),
				// Teams cards -> Get traduction from teams module
				members: (number) =>
					this.$tc('USER_MANAGEMENT.USER_LIST.TEAMS.MEMBERS', number, {
						count: number,
					}),
				actions: (number) =>
					this.$tc('USER_MANAGEMENT.USER_LIST.TEAMS.ACTIONS', number, {
						count: number,
					}),
			}
		},

		next() {
			if (this.activeStep.index < 3) {
				return this.steps[this.activeStep.index + 1].route
			}
			else {
				return null
			}
		},

		previous() {
			if (this.activeStep.index > 0) {
				const shouldNotGoToTarget = this.newAction.type === ACTION.TYPES.STATIC
				const offset = shouldNotGoToTarget ? 2 : 1
				return this.steps[this.activeStep.index - offset].route
			}
			else {
				return null
			}
		},

		isTeamsInCampaign() {
			return !isEmpty(this.localTeams)
		},

		computedUsersArrayOnlyDisplayed: {
			get() {
				if (this.availibilities.length && this.searchForNoneAvailabilities) {
					return this.localUser.filter((user) => {
						if (user.status) {
							const statusFromUser = user.status.split(',')
							let isStatusFound = false
							this.availibilities.forEach((filter) => {
								if (statusFromUser.includes(filter)) {
									isStatusFound = true
								}
							})
							return isStatusFound
						}
						if (
							!Object.prototype.hasOwnProperty.call(user, 'status')
							|| user.status.length === 0
							|| user.status === ''
						) {
							return true
						}
					})
				}
				if (this.availibilities.length) {
					return this.localUsers.filter((user) => {
						if (user.status) {
							const statusFromUser = user.status.split(',')
							let isStatusFound = false
							this.availibilities.forEach((filter) => {
								if (statusFromUser.includes(filter)) {
									isStatusFound = true
								}
							})
							return isStatusFound
						}
					})
				}
				if (this.searchForNoneAvailabilities) {
					return this.localUsers.filter((user) => {
						if (
							!Object.prototype.hasOwnProperty.call(user, 'status')
							|| user.status.length === 0
							|| user.status === ''
						) {
							return true
						}
					})
				}
				if (this.setFilterWithoutTeam) {
					return this.localUsers.filter((user) => {
						return (
							!Object.prototype.hasOwnProperty.call(user, 'Teams')
							&& user.firstname !== 'unknown'
							&& user.surname !== 'unknown'
						)
					})
				}
				return this.localUsers.filter((aUser) => {
					return aUser.display && aUser.firstname !== 'unknown' && aUser.surname !== 'unknown'
				})
			},
		},

		computedGiveKpiToSubTitle() {
			return this.totalAmountSelectedUsers
		},

		computedGetValidator() {
			return !!this.totalAmountSelectedUsers || !!this.totalAmountSelectedTeams
		},
	},

	watch: {
		getAllUsers(newVal) {
			if (newVal) {
				this.localUsers = cloneDeep(newVal)
				this.setTeamsInUsers()
			}
		},
		// ? used for the checkAll checkbox, was not working with computed props
		// totalAmountSelectedUsers() {
		// 	this.areAllUsersSelected = this.getAllUsers.every(
		// 		(user) => user.isSelected || user.isManuallySelected
		// 	)
		// 		? true
		// 		: false
		// },
		getTeams(newVal) {
			if (newVal) {
				this.localTeams = this.methodFilterPrivateTeams(cloneDeep(newVal))
				this.setTeamsInUsers()
			}
		},

		searchUserInput(newSearch) {
			let areAllUserDisplayedSelected = true
			if (newSearch) {
				this.localUsers.forEach((anUser) => {
					const isDisplay
						= deburr((anUser.firstname || '').toLowerCase()).indexOf(
							deburr(newSearch.toString().toLowerCase()),
						) > -1
						|| deburr((anUser.surname || '').toLowerCase()).indexOf(
							deburr(newSearch.toString().toLowerCase()),
						) > -1
					anUser.display = isDisplay
					if (anUser.display)
						areAllUserDisplayedSelected &= anUser.isManuallySelected || anUser.isSelected
				})
			}
			else {
				this.localUsers.forEach((anUser) => {
					anUser.display = true
					areAllUserDisplayedSelected &= anUser.isManuallySelected || anUser.isSelected
				})
			}
			this.areAllUsersSelected
				= areAllUserDisplayedSelected && this.computedUsersArrayOnlyDisplayed.length
			this.forceReRender()
		},

		searchTeamInput(newSearch) {
			const teams = [...cloneDeep(this.getTeams)]
			if (newSearch) {
				this.localTeams = this.methodFilterPrivateTeams(
					teams.filter((team) => {
						return (
							deburr(team.name.toString().toLowerCase()).indexOf(
								deburr(newSearch.toString().toLowerCase()),
							) > -1
						)
					}),
				)
			}
			else {
				this.localTeams = this.methodFilterPrivateTeams(cloneDeep(this.getTeams))
			}
			this.forceReRender()
		},

		computedGetValidator: {
			handler(newValue, oldValue) {
				this.$emit('update:nextValidatorBoolean', newValue)
				if (oldValue === true && newValue === false) this.$emit('update:errorMessage', this.t.valid.volonteers)
			},
			immediate: true,
		},
	},

	mounted() {
		this.actionSetStep('SELECT_MEMBERS')
		if (isEmpty(this.getTeams)) this.actionFetchTeams()
		this.$nextTick(() => {
			this.constructLocalValuesForSelecting()
			if (this.getTeams.length === 0 || this.getAccessStatusMembersTeams !== 'enabled') {
				this.tabs[0].statusPack = this.getAccessStatusMembersTeams
				this.tabSelected = 'members'
				this.isMemberChoosen = true
			}
			else {
				this.tabs[0].statusPack = this.getAccessStatusMembersTeams
				this.tabSelected = 'teams'
				this.isMemberChoosen = false
			}

			if (this.newAction.leaders?.length > 0) {
				this.newAction.leaders.forEach((user) => {
					this.toggleUserSelection(user, true)
				})
			}

			this.$emit('update:nextFunction', this.submitAction)
			this.$emit('update:nextLabelButton', this.$t('ACTION.CALL_TO_ACTION.CREATE'))
			this.$emit('update:errorMessage', this.t.valid.volonteers)
		})
	},

	beforeUnmount() {
		this.totalAmountSelectedUsers = this.totalAmountManuallySelectedUsers = 0
	},

	methods: {
		...mapActions('@action', [
			'actionSkipNavigationGuardBeforeRouteLeave',
			'actionSetStep',
			'actionCreateNewFieldAction',
			'actionResetFlow',
		]),
		...mapActions('@filter', ['actionSetFiltersForSearch']),
		...mapActions('@team', ['actionFetchTeams']),
		...mapActions({
			search: 'SEARCH_USER',
		}),

		methodFiltered() {},

		/**
		 * @func toggleFocusTeam
		 */
		toggleFocusTeam(team) {
			/**
			 * To focus / unfocus the team selected
			 */
			team.focus = !team.focus

			if (!team?.hide_users) {
				const allLocalUsers = this.localUsers.filter((user) =>
					team.users.find((teamUser) => teamUser.id === user.id),
				)

				for (const user of allLocalUsers) {
					if (user.isSelected) {
						if (this.remainingTeamOfUser(team, user)) {
							this.toggleUserSelection(user)
						}
					}
					else {
						this.toggleUserSelection(user)
					}
				}

				if (team.focus) {
					this.totalAmountSelectedTeams += 1
				}
			}

			if (!team.focus) {
				this.totalAmountSelectedTeams -= 1
			}

			this.forceReRender()
		},

		remainingTeamOfUser(currentTeamSelected, user) {
			// ? get user's teams and make the difference with all the un-focused teams
			// ? userTeams(A, B, C) \ unfocusedTeams(B, C) => userTeams(A)
			// ? check if userTeams(A) - currentTeam(A) === Ø, if ok, uncheck it 🎊
			const unfocusedTeamsArray = [...this.globalNotFocusedTeamsNames()]
			const focusedTeamsOfTheUser = user.Teams.filter(
				(userTeam) => !unfocusedTeamsArray.includes(userTeam),
			)
			return (
				focusedTeamsOfTheUser.filter(
					(activeTeamsOfTheUser) => activeTeamsOfTheUser.name !== currentTeamSelected.name,
				).length === 0
			)
		},

		// ? Handle Table callbacks : Toggle selection of all users
		selectAllUsers() {
			if (this.localUsers.length) {
				this.areAllUsersSelected = !this.areAllUsersSelected
				this.localUsers.forEach((anUser) => {
					if (anUser.display) {
						anUser.isManuallySelected = this.areAllUsersSelected
						anUser.isSelected = false
					}
				})
				// ? if we uncheck the "check all checkbox", reset all teams to un-focused
				if (!this.areAllUsersSelected) {
					this.localTeams.forEach((aTeam) => {
						aTeam.focus = false
					})
				}
				// ? if all users are selected, get the full length of them, otherwise return 0
				this.totalAmountSelectedUsers = this.localUsers.reduce((counter, user) => {
					return (counter += user.isSelected || user.isManuallySelected ? 1 : 0)
				}, 0)
			}
			this.forceReRender()
		},

		selectAllTeams() {
			this.localTeams.forEach((team) => {
				this.toggleFocusTeam(team)
			})
		},

		toggleUserSelection(user, manual = false) {
			const Users = this.localUsers
			const index = Users.findIndex((localUser) => localUser.id === user.id)
			const isAlreadyLeader = this.isAlreadyLeader(user)
			if (isAlreadyLeader && !manual) return

			if (!user.isSelected && user.isManuallySelected && !manual) {
				Users[index].isManuallySelected = false
				Users[index].isSelected = true
			}
			else if (!user.isSelected && !manual) {
				Users[index].isManuallySelected = false
				Users[index].isSelected = true
				this.totalAmountSelectedUsers += 1
				this.areAllUsersSelected = !this.computedUsersArrayOnlyDisplayed.some(
					(user) => user.display && !(user.isSelected || user.isManuallySelected),
				)
			}
			else if (!user.isSelected && manual && !user.isManuallySelected) {
				Users[index].isManuallySelected = true
				Users[index].isSelected = true
				this.totalAmountSelectedUsers += 1
				this.areAllUsersSelected = !this.computedUsersArrayOnlyDisplayed.some(
					(user) => user.display && !(user.isSelected || user.isManuallySelected),
				)
			}
			else {
				Users[index].isManuallySelected = false
				Users[index].isSelected = false
				this.totalAmountSelectedUsers -= 1
				this.areAllUsersSelected = false
			}
		},
		notEmpty() {
			// this.newAction.usersIds = this.uniqueUsersIds
			return !(this.newAction.usersIds?.length < 1 && this.newAction.teamsIds?.length < 1)
		},

		// Concatenate every teams in one string
		readableTeams(user) {
			if (!user.Teams) {
				return '-'
			}
			else {
				return [...new Set(user.Teams)].join(', ') // ugly af but heh, need to ship quickly 🤷‍♂
			}
		},

		forceReRender() {
			/**
			 * Just force a re-render of the screen
			 * because the changes are not detected
			 * because they are to deep in the arrays.
			 */
			this.$forceUpdate()
		},

		/**
		 * @func submitAction
		 * In the BottomBar function we will dispatch
		 * this function. We will use a boolean to
		 * if the action need to be empty at the creation.
		 */
		submitAction(needToBeEmptyAction = false) {
			// Dispatch the new action to the store
			// ! ⚠️ short-circuit all the filtering, only taking the selected ones ⚠️ !
			const usersIds = this.localUsers
				.filter((user) => user.isManuallySelected)
				.map((user) => user.id)
			const teamsIds = this.localTeams.filter((team) => team.focus).map((team) => team.id)
			const addressIncluded = cloneDeep(this.getSearchAddressIncluded)
			const pollingStationsIncluded = cloneDeep(this.getSearchPollingStationsIncluded)
			const allowUnknowAddressesOnActionCreation = cloneDeep(
				this.getAllowUnknowAddressesOnActionCreation,
			)
			this.actionSkipNavigationGuardBeforeRouteLeave(true)
			let clonedNewAction = cloneDeep(this.newAction)
			// Pure action creation to create the final action
			this.actionCreateNewFieldAction({
				needToBeEmptyAction,
				action: {
					...clonedNewAction,
					usersIds,
					teamsIds,
					notify: true,
				},
				payload: {
					addressIncluded,
					pollingStationsIncluded,
					allowUnknowAddressesOnActionCreation,
				},
			})

			// Track action created
			analytics.track(TRACKING.action_created, { type_action: clonedNewAction.type })

			// Leave the tunnel
			this.$router.push({
				name: 'actions',
				query: {
					action_created: 1,
				},
			})
			/**
			 * Display a message to see the actions is in the creation
			 * workflow from backend
			 */
			nextTick(() => {
				this.$message({
					message: this.$t('ACTION.CALL_TO_ACTION.SUBMIT_INFO'),
					type: 'info',
					showClose: true,
				})
			})
		},

		redirectToUser() {
			this.actionResetFlow()
			this.$router.push({ name: 'users-list' })
		},

		isAlreadyInTeam(user) {
			return this.localTeams.some((team) => {
				return team.focus && team.users.some((tUser) => tUser.id === user.id)
			})
		},

		isAlreadyLeader(user) {
			return this.newAction.leaders?.some((leader) => leader.id === user.id)
		},

		/**
		 * On mount, we assing team name to saw each user's team
		 */
		setTeamsInUsers() {
			for (let localUser of this.localUsers) {
				// not the most efficient way of doing thing but did not achieved with a map...🙀
				localUser.isManuallySelected = false
				localUser.isSelected = false
				localUser.display = true
			}

			if (this.localTeams.length) {
				this.localTeams.forEach((aTeam) => {
					if (aTeam.users) {
						aTeam.users.forEach((aUserInTeam) => {
							if (this.localUsers.length) {
								const userFound = this.localUsers.find(
									(aLocalUser) => aLocalUser.id === aUserInTeam.id,
								)
								if (userFound) {
									if (!userFound.Teams) {
										userFound.Teams = [aTeam.name]
									}
									else {
										if (Array.isArray(userFound.Teams)) {
											userFound.Teams.push(aTeam.name)
										}
									}
								}
							}
						})
					}
				})
				this.$forceUpdate()
			}
		},

		globalNotFocusedTeamsNames() {
			// ? get all the teams that are not focused right now
			return this.localTeams
				.filter((globalTeam) => globalTeam.focus !== true)
				.map((team) => team.name)
		},

		constructLocalValuesForSelecting() {
			this.localUsers = cloneDeep(this.getAllUsers)
			this.localTeams = this.methodFilterPrivateTeams(cloneDeep(this.getTeams))

			this.setTeamsInUsers()
		},
		methodSetAvailibilitiesFilters(newVal) {
			const newAvailabilities = newVal.map((avail) => avail.value)
			this.availibilities = newAvailabilities
		},
		methodSetSearchForNoneAvailabilities(newVal) {
			this.searchForNoneAvailabilities = newVal
		},
		methodGetMemberStatus(memberStatus) {
			if (memberStatus !== undefined && memberStatus !== null) {
				const splittedStatus = memberStatus.split(',')
				let finalStatus = []
				if (splittedStatus.length > 0) {
					splittedStatus.forEach((status) => {
						finalStatus.push(this.$t(`USER_MANAGEMENT.AVAILABILITY.${status.toUpperCase()}`))
					})
					return finalStatus
				}
				return splittedStatus
			}
		},
		methodSetView(tab, statusPack = '', refPopupPacker = '', featurePack = '') {
			const setView = () => {
				if (tab === 'teams') {
					if (statusPack === 'enabled') {
						this.tabSelected = tab
						this.isMemberChoosen = false
					}
					if (statusPack === 'promoted') {
						this.$refs[refPopupPacker].methodPackerClick(featurePack)
					}
				}
				if (tab === 'members') {
					this.isMemberChoosen = true
					this.tabSelected = tab
				}
			}
			if (!document.startViewTransition) return setView()
			document.startViewTransition(() => {
				setView()
			})
		},

		methodGetTitleTabs(tabName) {
			switch (tabName) {
				case 'teams':
					return this.$t('ACTION.CALL_TO_ACTION.TEAMS')
				case 'members':
					return this.$t('ACTION.CALL_TO_ACTION.USERS')
			}
		},
		methodStripedRow({ rowIndex }) {
			if (rowIndex % 2 === 1) {
				return 'rowStripeDark'
			}
			else {
				return ''
			}
		},
		isRowSelected(scope) {
			if (scope.row.isSelected || scope.row.isManuallySelected) {
				return 'green-row'
			}
			return ''
		},
		methodGiveIsDisabledBoolean(tabName) {
			if (tabName === 'teams') {
				return this.getTeams.length === 0
			}
			return this.getAllUsers.length === 0
		},
		methodIsAStatus(user) {
			let res = false
			if (user?.status) {
				user.status.split(',').forEach((status) => {
					if (this.realStatusArray.includes(status)) {
						res = true
						return
					}
				})
			}
			return res
		},
		methodFilterPrivateTeams(teamArray) {
			// 'this' don't work inside the function
			let userConnected = this.userConnected
			let filteredArray = filter(teamArray, function (team) {
				// Display the only the ones the user is part of if he does not have the right to see the teams and if the team is private
				return (
					some(team.users, ['id', userConnected.id])
					|| !team.private
					|| userConnected?.role_data?.access?.includes('members.teams:delete')
				)
			})
			return filteredArray
		},

		methodSetLeaderIconOnRightUser(aLeaderIndex, teamLeadersLength) {
			if (teamLeadersLength > 3) {
				if (aLeaderIndex === teamLeadersLength - 3) return true
				return false
			}
			if (teamLeadersLength <= 3) {
				if (aLeaderIndex === teamLeadersLength - 1) return true
				return false
			}
		},
	},
}
</script>

<style lang="scss">
.green-row {
	background-color: #f0fbf4 !important;
}
</style>

<style lang="scss" scoped>
@import 'src/assets/scss/settings/table-settings.sass';

.tableContainer {
	height: 47.7vh;

	.text-header,
	.text-row {
		height: min-content;
		align-self: center;
		display: flex;
		white-space: nowrap;
		align-items: center;
		overflow: hidden;
		font-family: centra-book;
		font-weight: 400;
		text-overflow: ellipsis;
		font-size: 13px;
		line-height: 18px;
	}
}

#CTA-team {
	height: 100%;
	box-sizing: border-box;
}

.users {
	max-height: 48.5vh;
	min-height: 48.5vh;
}
.teams {
	max-height: 45vh;
	min-height: 45vh;
}
.team {
	border: 1px solid #ebebeb;
	border-radius: 1rem;

	&.--dark {
		@apply bg-black text-white
	}

	&:hover {
		&.--dark {
			@apply border-pink-medium
		}
		@apply border-green-500
	}

	&.--focus {
		&.--dark {
			@apply border-pink-main
		}
		@apply border-green-600
	}
}
.team-name-span {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.team .q-card-container {
	height: 8em;
}

.fixed-head {
	thead,
	tbody {
		display: block;
		tr {
			.flex-td {
				display: inline-flex;
				flex-direction: row;
				justify-content: center;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		th:nth-child(1),
		td:nth-child(1) {
			width: 50px;
		}
		th:nth-child(2),
		td:nth-child(2) {
			width: 50px;
		}
		th:nth-child(3),
		td:nth-child(3),
		th:nth-child(4),
		td:nth-child(4) {
			min-width: 150px;
			width: 150px;
			max-width: 150px;
		}

		th:nth-child(5),
		td:nth-child(5) {
			min-width: 200px;
			width: 200px;
			max-width: 200px;
		}
	}

	thead,
	thead tr {
		z-index: 10;
	}
	tbody,
	thead {
		min-width: 100%;
		max-height: 55vh;
	}
	thead {
		width: 300px;
	}
}
.search-input-container {
	border-width: 1.5px;
}
.absolute-span-status {
	position: absolute;
	top: 0;
	right: 0;
}
.teams-span {
	border: solid 1.3px black;
	border-radius: 1rem;
	padding: 1px 10px;
}
.text-bold {
	font-weight: 700 !important;
}
.selector-container {
	border: 1.3px solid #ebebeb;
	background: #f8f8f8;
}
.selector-span {
	transition: transform 0.5s ease;
	background: #ff387f;
	z-index: 2;
}
.selector-text {
	z-index: 3;
	transition-delay: 0.1s;
}
.selector-text:hover {
	font-weight: 700;
}
.selector-span-left {
	transform: translateX(0%);
}
.selector-span-right {
	transform: translateX(100%);
}

.fix-input-search {
	@apply rounded pb-0
}
</style>
