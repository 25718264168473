import { $request } from './requester'

/**
 * @model Form
 */
export default {
	/**
	 * GET `/forms/name/:name`
	 * @param {String} name name of the form. Could be FORM,STATIC, campagne, name_presence...
	 */
	async getFormByName(name, masterView = false) {
		return $request('GET', `/${masterView ? 'master-forms' : 'forms'}/name/${name}`).then(
			(res) => res.body.data.forms
		)
	},

	/**
	 * POST `/forms`
	 * @param {Object[]} forms the form to save
	 * @todo Should be recall createForms
	 */
	saveForms(forms) {
		return $request('POST', '/forms', { forms })
	},

	/**
	 * PATCH `/forms`
	 * @param {Object[]} forms form to overwrite
	 * @deprecated not used...
	 */
	overwrite_forms(forms) {
		return $request('PATCH', '/forms', { forms })
	},

	/**
	 * PATCH `/forms/names/:names`
	 * @param {Object[]} forms
	 * @param {String[]} names
	 */
	async saveFormsWithNames(forms, names) {
		return await $request('PATCH', `/forms/names/${names}`, { forms }).then((res) => res.body.data)
	},
	/**
	 * PATCH `/forms/names/:names`
	 * @param {Object[]} forms
	 * @param {String[]} names
	 */
	async canDeleteCustomField(id) {
		const queryParams = {
			id: id,
		}
		return await $request('GET', `/forms/name/CUSTOM/candelete`, { id: id }).then(
			(res) => res.body.data
		)
	},
}
