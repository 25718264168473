<template>
	<div class="is-active flex flex-col h-full">
		<div class="cta-modal q-card-top q-fullwidth">
			<div class="content flex flex-col">
				<action-creation-header
					ref="actionCreationHeader"
					:method-clean-view="methodCleanView"
					:close-action-creation-event="leaveActionCreationClick"
					:next-label-button="computedGetNextButtonLabel"
					:next-function="computedGetNextFunction"
					:next-validator-boolean="computedGetNextValidatorBoolean"
					:error-message="computedGetErrorMessage"
					:action-type-label="actionTypeLabel"
				/>
			</div>
		</div>
		<router-view v-slot="{ Component }">
			<component
				:is="Component"
				:key="$route.path"
				:header-height="headerHeight"
				:action-type-label="actionTypeLabel"
				v-model:next-validator-boolean="computedGetNextValidatorBoolean"
				v-model:next-function="computedGetNextFunction"
				v-model:error-message="computedGetErrorMessage"
				v-model:next-label-button="computedGetNextButtonLabel"
			/>
		</router-view>
	</div>
</template>

<script>
/**
 * Main component used by command-center, actions, and contacts
 * to show the tunnel of action action
 */
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ActionCreationHeader: defineAsyncComponent(() =>
			import('./callToAction/ActionCreationHeader.vue'),
		),
	},

	beforeRouteLeave(to, from, next) {
		if (!this.getSkipBeforeRouteLeaveActionCreation) {
			this.$confirm(
				this.$t('ACTION.CALL_TO_ACTION.CONFIRM_CLOSE.MESSAGE'),
				this.$t('ACTION.CALL_TO_ACTION.CONFIRM_CLOSE.TITLE'),
				{
					cancelButtonText: this.$t('ACTION.CALL_TO_ACTION.CONFIRM_CLOSE.CANCEL_TEXT'),
					confirmButtonText: this.$t('ACTION.CALL_TO_ACTION.CONFIRM_CLOSE.CONFIRM_TEXT'),
					customClass: 'custom_message_box',
				},
			)
				.then(() => {
					next(true)

					// Avoid weird pattern with ActionCreationHelperContainer
					// and ActionCreationBottomBar which dispatch an error message
					// if we didn't set timeout to reset the flow
					setTimeout(() => {
						this.actionResetNewActionFilterAndSearchSystem()
						this.actionResetFlow()
					}, 1000)
				})
				.catch(() => {
					if (to.name === 'login') next(true)

					next(false)
				})
		}
		else {
			this.actionResetNewActionFilterAndSearchSystem()
			this.actionSkipNavigationGuardBeforeRouteLeave(false)
			this.actionResetFlow()
			next()
		}
	},

	data() {
		return {
			nextComplete: 0,
			nextValidatorBoolean: false,
			nextFunction: () => null,
			errorMessage: '',
			nextButtonLabel: this.$t('_COMMON.NEXT'),
			headerHeight: 0,
		}
	},

	computed: {
		...mapGetters('@action', ['entryPoint', 'getSkipBeforeRouteLeaveActionCreation', 'newAction']),
		...mapGetters('@filter', ['polygon']),

		computedGetNextButtonLabel: {
			get() {
				return this.nextButtonLabel
			},
			set(value) {
				this.nextButtonLabel = value
			},
		},

		computedGetNextFunction: {
			get() {
				return this.nextFunction
			},
			set(value) {
				this.nextFunction = value
			},
		},

		computedGetNextValidatorBoolean: {
			get() {
				return this.nextValidatorBoolean
			},
			set(value) {
				this.nextValidatorBoolean = value
			},
		},

		computedGetErrorMessage: {
			get() {
				return this.errorMessage
			},
			set(value) {
				this.errorMessage = value
			},
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.methodToggleMenu()
			document.addEventListener('resize', this.methodCalculateHeaderHeight)
			this.methodCalculateHeaderHeight()
		})
	},

	updated() {
		this.$nextTick(() => {
			this.methodCalculateHeaderHeight()
		})
	},

	unmounted() {
		this.actionSetMenuVisibility(true)
		document.removeEventListener('resize', this.methodCalculateHeaderHeight)
	},

	methods: {
		...mapActions('@navBar', ['actionSetMenuVisibility']),
		...mapActions('@action', [
			'actionSkipNavigationGuardBeforeRouteLeave',
			'actionResetNewActionFilterAndSearchSystem',
			'actionResetFlow',
		]),

		leaveActionCreationClick() {
			const beforeEraseEntryPoint = this.entryPoint
			let options = { name: beforeEraseEntryPoint, query: this.$route.query }
			if (this.polygon.length && beforeEraseEntryPoint != 'profiles') options.query = { ...options.query, savePolygon: true }
			this.$router.push(options)
		},

		methodToggleMenu() {
			if (this.$route.fullPath.includes('new/action')) this.actionSetMenuVisibility(false)
		},

		methodCleanView() {
			this.nextComplete = false
			this.nextValidatorBoolean = false
			this.nextFunction = () => null
			this.errorMessage = ''
			this.nextButtonLabel = this.$t('_COMMON.NEXT')
		},

		methodCalculateHeaderHeight() {
			this.headerHeight = this.$refs?.actionCreationHeader?.$el?.clientHeight
		},

		actionTypeLabel() {
			const { type, globalType } = this.newAction
			if (type == 'event') {
				if (globalType == 'online') {
					return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.EVENT_ONLINE')
				}
				else {
					return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.EVENT_OFFLINE')
				}
			}
			else if (type == 'other') {
				if (globalType == 'online') {
					return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.OTHER_ONLINE')
				}
				else {
					return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.OTHER_OFFLINE')
				}
			}
			else if (type) {
				return this.$t(`ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.${type.toUpperCase()}`)
			}
			return ''
		},
	},
}
</script>
<style lang="scss">
@import '../../assets/dequorum/style/variables.sass';
@import '../../assets/dequorum/style/general/inherited_variables.sass';

.card-call-to-action {
	margin: 15px;
	padding: 2%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	background-color: #fcfcfc;
}

.q-modal-content {
	padding: 0;
	max-height: calc(95vh - 20px);

	.q-card-top {
		background: $background;
	}
}
#cta-modal {
	.cta-modal {
		margin: 0;
		padding: 0;
	}
}
.display-content-card-action-creation {
	padding-right: 5%;
	padding-left: 5%;
}
</style>
