<template>
	<div class="qview">
		<div class="q-header border-none shadow-sm">
			<div class="q-fullwidth q-p-lg">
				<div
					id="task-header"
					class="q-fullwidth q-h-scroll-hidden title q-m-lg-l"
				>
					<template v-if="refvaluesSorted || !computedIsLoadingTasks">
						<transition-group name="fade-in">
							<div
								v-show="!refsFollowed.length"
								key="tab"
								class="title"
							>
								<tab-with-number
									:title="$t('TASKS.TITLE')"
									:selected="true"
									:count="0"
									font-size="1.25rem"
									class="mr-10 cursor-pointer whitespace-nowrap"
								/>
							</div>
							<div
								v-show="refsFollowed.length"
								key="titles"
								class="title"
							>
								<template
									v-for="ref in refvaluesSorted"
									:key="ref.id"
								>
									<q-button
										link
										:class="{ boldUnderline: currentTab === ref.id }"
										:secondary="currentTab !== ref.id"
										@click="currentTab = ref.id"
									>
										<span> {{ ref.label }}&nbsp; </span>
										<span class="q-is-warning">
											<i
												v-if="computedIsLoadingTasks"
												class="fa fa-circle-o-notch fa-spin text-pink-main"
											/>
											<span
												v-else
												class="text-pink-main"
											>
												{{ countContactsDoneByRefId(ref.id) }}
											</span>
										</span>
									</q-button>
									<div class="q-h-spacer" />
								</template>
							</div>
						</transition-group>
					</template>
				</div>
			</div>
		</div>
		<div class="qview-main">
			<div
				id="tasks"
				class="q-fullwidth"
			>
				<div class="q-container q-col">
					<div
						id="task-content"
						class="expander q-fullwidth q-col q-container"
					>
						<template v-if="computedTasks">
							<div
								v-if="!refsFollowed.length"
								class="flex flex-col items-center"
								style="max-width: 440px"
							>
								<img
									src="/static/images/empty-state/hands-go.svg"
									class="w-60 mb-11"
								>
								<h1 class="font-title-bold text-4xl mb-3">
									{{ $t('TASKS.CONFIGURE') }}
								</h1>
								<p class="text-lg font-semibold text-center mb-8">
									{{ $t('TASKS.ACCESS_SETTINGS') }}
								</p>
								<router-link
									v-if="userConnected?.role_data?.access?.includes('settings:delete')"
									:to="{ name: 'lead-form', query: { q: 'ACTION' } }"
									class="q-button"
								>
									{{ $t('TASKS.CREATE_TUNNEL') }}
								</router-link>
								<p
									v-else
									class="center"
								>
									{{ $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE') }}
								</p>
							</div>

							<h3
								v-else-if="!tasksFilteredbyId.length && !computedIsLoadingTasks"
								class="q-is-secondary"
							>
								{{ t.allGood }}
							</h3>

							<div
								v-else
								class="q-p expander q-m-b q-fullwidth q-scroll"
							>
								<transition-group
									:key="tasksKey"
									name="cards"
								>
									<div
										v-for="(contact, index) in tasksFilteredbyId"
										:key="`${contact.id}-${index}`"
										class="q-card no-shadow todo-card"
									>
										<transition name="fade">
											<div
												v-if="!!isDone(contact)"
												class="q-card-overlay"
											>
												<div class="content bold">
													<h3>{{ $filters.capitalizeFirst(done(contact), false) }}</h3>
												</div>
											</div>
										</transition>
										<div class="q-card-options">
											<div class="q-card-o-icon qi-more" />
											<div class="q-card-o-content">
												<div class="options">
													<confirm-delete
														class="action danger"
														@confirm="cancelTask(contact)"
													>
														{{
															t.cancel
														}}
													</confirm-delete>
												</div>
											</div>
										</div>
										<div class="q-row nowrap">
											<div class="todo-complete q-bar-right q-row q-p">
												<span class="q-p-l">
													<el-tooltip
														:content="!!isDone(contact) ? t.undo : t.doneToolkit"
														placement="left"
													>
														<q-check
															xl
															:checked="!!isDone(contact)"
															@change="finish(contact, $event)"
														/> </el-tooltip></span>
											</div>
											<div class="q-smart-grid expander q-fullwidth no-scroll">
												<div
													class="q-col-4 q-card no-shadow bordered-grey clickable"
													style="margin: 0"
													@click="
														$router.push({
															name: 'contact-details-forms-infos',
															params: { id: contact.id },
														})
													"
												>
													<div class="q-card-content">
														<div class="action thin">
															{{ contact.firstname ? contact.firstname + ' ' : ''
															}}{{ contact.surname ? contact.surname : '' }}
														</div>
														<small class="q-is-secondary">{{ metBy(contact) }}</small>
													</div>
													<div class="center q-p-v">
														<el-select
															v-if="actualStatus.length"
															:model-value="statusOf(contact)"
															size="small"
															:placeholder="t.chooseStatus"
															@change="updateContact(contact, $event)"
														>
															<el-option
																v-for="(ref, refIndex) in actualStatus"
																:key="`${ref.id}-${refIndex}`"
																:value="ref.id"
																:label="ref.label"
															>
																<div class="q-row unset gap-2">
																	<i
																		class="gui-color"
																		:style="{
																			color: ref.color ? ref.color : 'transparent',
																		}"
																	/>
																	<span>{{ ref.label }}</span>
																</div>
															</el-option>
														</el-select>
													</div>
												</div>
												<div class="q-col-5 q-container q-p-h">
													<div class="q-container q-fullwidth">
														<div class="q-col unset start q-container q-fullwidth nowrap">
															<div class="q-smart-grid no-scroll no-gap q-fullwidth">
																<div
																	v-for="(refvalue, refValueIndex) in existInFormdatas(
																		refsFollowed,
																		contact
																	)"
																	:key="`${refvalue.id}-${refValueIndex}`"
																	class="q-col-12 q-row bg-white q-border-sm"
																>
																	<!-- eslint-disable -->
																	<div
																		class="center"
																		:class="`q-fullwidth ${
																			hasRefvalueDone(contact, refvalue)
																				? 'bg-white'
																				: currentTab === refvalue.id
																				? 'bg-gray-strong'
																				: 'bg-gray-medium'
																		}`"
																	>
																		<!-- eslint-enable -->
																		<span v-if="hasRefvalueDone(contact, refvalue)"><span class="qi-check text-green-main q-m-sm-r" /></span><span><span>
																				<div
																					v-for="(formdata, formDataIndex) in formdataFilteredBy(
																						refvalue,
																						contact
																					)"
																					:key="`${formdata.id}-${formDataIndex}`"
																				>
																					<div
																						v-if="formdata"
																						:title="`${formdata.question} : ${formdata.data}`"
																					>
																						{{ formdata.question }} : {{ formdata.data }}
																					</div>
																				</div></span>
																			<!-- eslint-disable -->
																			<span
																				:title="refvalue.label"
																				:class="`ellipsis ${
																					hasRefvalueDone(contact, refvalue)
																						? 'text-gray-500'
																						: currentTab === refvalue.id
																						? 'text-white'
																						: 'text-white'
																				}`"
																			>
																				<!-- eslint-enable -->
																				{{ refvalue.label }}
																			</span>
																			<div
																				v-for="formdata in formdataFilteredBy(refvalue, contact)"
																				:key="formdata.id"
																			>
																				{{ formdata.question }}
																				<span>
																					<small>&nbsp;: {{ formdata.data }}</small>
																				</span>
																			</div>
																		</span>
																	</div>
																</div>
															</div>
															<div class="q-fullwidth q-m-t one-liner">
																<el-tooltip
																	position="bottom"
																	trigger="hover"
																>
																	<template #content>
																		<div>
																			<template
																				v-for="form in getOtherFormdatasOf(contact)"
																				:key="form.id"
																			>
																				<span class="q-is-info"> {{ form.question }}:&nbsp; </span>
																				<span
																					v-if="form.type === 'date'"
																					class="alt-font"
																				>
																					{{ $filters.date(form.answer) }}
																				</span>
																				<span
																					v-else
																					class="alt-font"
																				>
																					{{ form.answer }}
																				</span>
																				<br>
																			</template>
																		</div>
																	</template>
																	<div v-if="getLastOtherFormDatasOf(contact)">
																		<span class="q-is-info">
																			{{ getLastOtherFormDatasOf(contact)?.question }}:&nbsp;
																		</span>
																		<span
																			v-if="getLastOtherFormDatasOf(contact)?.type === 'date'"
																			class="alt-font"
																		>
																			{{ $filters.date(getLastOtherFormDatasOf(contact)?.answer) }}
																		</span>
																		<span
																			v-else
																			class="alt-font"
																		>
																			{{ getLastOtherFormDatasOf(contact)?.answer }}
																		</span>
																	</div>
																</el-tooltip>
															</div>
														</div>
													</div>
												</div>
												<div class="q-col-3">
													<div class="q-col space-between q-container q-p unset">
														<template
															v-for="(task, currentTaskIndex) in currentTask(contact)"
															:key="`${task.key}-${currentTaskIndex}`"
														>
															<div
																class="action thin"
																:class="{ 'disabled helper': task.disabled }"
																@click="task.cta()"
															>
																<span
																	class="center q-m-r"
																	:class="task.icon"
																/><span>{{ task.tooltip }}</span>
															</div>
														</template>
													</div>
												</div>
											</div>
										</div>
									</div>
								</transition-group>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { firstBy } from 'thenby'
import actionsConfig from './dynamicTableActions'
import ConfirmDelete from '../general/confirmDelete.vue'
import { cloneDeep } from 'lodash'
import { getDisplayDateFromLocalStorage } from '../../utils/dateFnsHelper'

/**
 * @component TasksView
 * @route /tasks
 * @desc handle the possibles actions
 */
export default {
	components: {
		ConfirmDelete,
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},

	data() {
		return {
			refsFollowed: [],
			dataset: actionsConfig,
			// Increment to force the visual update of tasks
			tasksKey: 0,
			tasksLocalCopy: [],
		}
	},

	computed: {
		...mapGetters('@issueCenter', ['getTasks', 'getIsLoadingTasks']),

		...mapGetters(['getAllUsers', 'userConnected']),

		...mapGetters('@form', ['action']),
		...mapGetters('@form', { status: 'status' }),

		t() {
			const prefix = 'MANAGEMENT.ISSUE_CENTER'

			return {
				undo: this.$t(`${prefix}.UNDO`),
				cancel: this.$t(`${prefix}.CANCEL`),
				finish: this.$t(`${prefix}.FINISH`),
				allGood: this.$t(`${prefix}.ALL_GOOD`),
				dropdown: this.$t(`${prefix}.DROPDOWN`),
				seeDetails: this.$t(`${prefix}.SEE_DETAILS`),
				doneToolkit: this.$t(`${prefix}.DONE_TOOLKIT`),
				chooseStatus: this.$t(`${prefix}.CHOOSE_STATUS`),
				successInvite: this.$t(`${prefix}.SUCCESS_INVITE`),
				notConfigured: this.$t(`${prefix}.NOT_CONFIGURED`),

				metBy: (data) => this.$t(`${prefix}.MET_BY`, data),
				userUnknown: (number) => this.$t('CONTACT.LIST.USER_UNKNOWN', { id: number }),
				call: (phone) =>
					phone ? this.$t(`${prefix}.CALL`, { phone }) : this.$t(`${prefix}.NO_PHONE`),
				sendMail: (mail) => (mail ? this.$t(`${prefix}.MAIL`) : this.$t(`${prefix}.NO_MAIL`)),
				invite: (mail) => (mail ? this.$t(`${prefix}.INVITE`) : this.$t(`${prefix}.INVITE`)),
			}
		},

		/**
		 * @computed {Object} tasksRoot
		 * @desc select the form that select the actions to do
		 */

		refvaluesSorted() {
			if (this.computedTasks) {
				this.refsFollowed.forEach((aRef) => {
					aRef.total = this.countContactsDoneByRefId(aRef.id)
				})
				this.ordereRefsFollowed()

				return this.refsFollowed
			}

			return []
		},

		/**
		 * @computed {Number} currentTab
		 * @desc the current refvalue id. Will also persist it in the route
		 */
		currentTab: {
			// eslint-disable-next-line vue/return-in-computed-property
			get() {
				if (this.$route.query && this.$route.query.tab) {
					return parseInt(this.$route.query.tab)
				}

				if (this.refsFollowed.length) {
					this.$router.replace({
						...this.$route,
						query: {
							tab: this.refsFollowed[0].id,
						},
					})

					return this.refsFollowed[0].id
				}
			},
			set(value) {
				this.$router.replace({
					...this.$route,
					query: {
						tab: value,
					},
				})
			},
		},

		actualStatus() {
			return cloneDeep((this.status.find((s) => s.type === 'radio') || { refvalues: [] }).refvalues)
		},

		computedTasks() {
			return this.tasksLocalCopy
		},

		computedIsLoadingTasks() {
			return this.getIsLoadingTasks
		},

		/**
		 * @method tasksFilteredbyId
		 * @desc filter the current contacts based on currentTab
		 * @returns {Contact[]}
		 */
		tasksFilteredbyId() {
			return this.computedTasks
				.filter((contact) => {
					return Boolean(contact.formdatas.find((formdata) => formdata.form_ref_id === this.currentTab))
				})
				.sort(
					firstBy((contact) => (this.isDone(contact) ? 1 : -1))
						.thenBy(this.done, -1)
						.thenBy('lastchange', -1),
				)
		},
	},

	watch: {
		getTasks: {
			handler(value) {
				this.tasksLocalCopy = cloneDeep(value)
			},
			deep: true,
		},
	},

	async mounted() {
		this.$store.commit('@issueCenter/START_LOADING')
		// Look for GLOBAL_DATA
		await this.getActionForms()
		this.actionGetTasks()
		const actionForms = cloneDeep(this.action)
		const refsFollowed = []

		actionForms.forEach((aForm) => {
			if (aForm.refvalues) {
				aForm.refvalues.forEach((aRefValue) => {
					if (aRefValue.followed === true) {
						refsFollowed.push(aRefValue)
					}
				})
			}
		})
		this.refsFollowed = refsFollowed
	},

	methods: {
		...mapActions('@issueCenter', ['actionGetTasks', 'getTotalByRef', 'actionSetTasks']),
		...mapActions('@form', ['getActionForms']),
		...mapActions('@contact', ['actionUpdateAContact']),
		...mapActions('@invitation', ['actionSendEmailInvitation']),

		initRoute() {
			const valueWithMoreItems = this.mainAction.refvalues.find((ref) => Math.max(ref.total))

			this.$router.replace({
				...this.$route,
				query: {
					tab: valueWithMoreItems.id,
				},
			})
		},

		/**
		 * @method phoneTo
		 * @param {String} phoneNumber
		 * @desc href tel
		 */
		phoneTo(phoneNumber) {
			if (phoneNumber) {
				window.location.href = `tel:${phoneNumber}`
			}
		},

		/**
		 * @method mailTo
		 * @param {String} email
		 * @desc href mailto
		 */
		mailTo(email) {
			if (email) {
				window.location.href = `mailto:${email}`
			}
		},

		/**
		 * @method inviteContact
		 * @param {String} email
		 * @desc send an invite email to the targeted email
		 */
		inviteContact(email) {
			if (email) {
				this.actionSendEmailInvitation({ invitations: [{ email }] }).then(() => {
					this.$message({
						type: 'success',
						message: this.t.successInvite,
						showClose: true,
					})
				})
			}
		},

		metBy(contact) {
			const date = contact.lastchange
				? moment(contact.lastchange)
					.locale(this.$i18n.locale.substring(0, 2))
					.calendar(null, {
						sameElse: getDisplayDateFromLocalStorage().toUpperCase(),
					})
				: ''
			const user = this.getMember(contact.user_id)

			return this.t.metBy({ date, user })
		},

		/**
		 * @method statusOf
		 * @param {Object} contact
		 * @desc Read the status of a contact and give him the right label of the status
		 * This function can be improved by not find in first by filter then search in
		 * all the forms the value.
		 */
		statusOf(contact) {
			let status = ''
			const statusForm = cloneDeep(
				this.status.find((s) => s.type === 'radio' || s.type === 'checkbox'),
			)

			if (contact.formdatas) {
				const aFormDataTarget = contact.formdatas.find(
					(aFormData) => aFormData.form_id === statusForm.id,
				)

				if (aFormDataTarget) {
					statusForm.refvalues.forEach((aRefValue) => {
						if (aRefValue.id === aFormDataTarget.form_ref_id) {
							status = aRefValue.label
						}
					})
				}
			}

			return status
		},

		existInFormdatas(refvalues, contact) {
			return refvalues.filter((refvalue) =>
				contact.formdatas.find((formdata) => formdata.form_ref_id === refvalue.id),
			)
		},

		formdataFilteredBy(refvalue, contact) {
			const formFilteredBy = cloneDeep(
				this.action.filter((form) => form.filterbyidrefvalue === refvalue.id),
			)
			const formFilteredBy_ids = formFilteredBy.map((form) => form.id)

			const formdata = contact.formdatas
				.filter((formdata) => formFilteredBy_ids.indexOf(formdata.form_id) > -1)
				.map((formdata) => {
					const question = formFilteredBy.find((form) => form.id === formdata.form_id)

					return {
						...formdata,
						data:
							(question.refvalues.find((ref) => ref.id === formdata.form_ref_id) || {}).label
							|| formdata.data,
						question: question.label,
					}
				})

			return formdata
		},

		hasRefvalueDone(contact, refvalue) {
			const formdata = contact.formdatas.find((formdata) => formdata.form_ref_id === refvalue.id)

			return formdata && formdata.donedate
		},

		/**
		 * @method currentTask
		 * @param {Contact} contact
		 * @desc will return the ctas for this specific contact
		 * @returns {Array}
		 */
		currentTask(contact) {
			const mail = {
				id: 0,
				key: '=',
				disabled: !contact.mail,
				cta: () => {
					this.mailTo(contact.mail)
				},
				tooltip: this.t.sendMail(contact.mail),
				icon: 'qi-email',
			}

			const invite = {
				id: 1,
				key: 'invite',
				disabled: !contact.mail,
				cta: () => {
					this.inviteContact(contact.mail)
				},
				tooltip: this.t.invite(contact.mail),
				icon: 'qi-add-team',
			}

			const phone = {
				id: 2,
				key: 'phone',
				disabled: !contact.phone,
				cta: () => {
					this.phoneTo(contact.phone)
				},
				tooltip: this.t.call(contact.phone),
				icon: 'fa fa-phone',
			}

			const mobile = {
				id: 3,
				key: 'mobile',
				disabled: !contact.mobile,
				cta: () => {
					this.phoneTo(contact.mobile)
				},
				tooltip: this.t.call(contact.mobile),
				icon: 'qi-phone',
			}

			return [phone, mobile, mail, invite]
		},

		/**
		 * @method countContactsDoneByRefId
		 * @param {Refvalue.id} id
		 * @desc filter the current contacts based on the ref id
		 * @returns {number}
		 */
		countContactsDoneByRefId(id) {
			return this.computedTasks.filter((contact) => {
				return Boolean(contact.formdatas.find(
					(formdata) => formdata.form_ref_id === id && !formdata.donedate,
				))
			}).length
		},

		/**
		 * @method getMember
		 * @param {Number} userId - the user's id to look for
		 * @description return a formatted string of the user's name depending on its id
		 * for readability sake
		 *
		 * @returns {String}
		 */
		getMember(userId) {
			const user = cloneDeep(this.getAllUsers.find((user) => user.id === userId))

			return user ? `${user.firstname} ${user.surname}` : this.t.userUnknown(userId)
		},

		/**
		 * @method getOtherFormdatasOf
		 * @param {Contact} contact
		 * @desc will return every other form answer (!== getTasks)
		 * @returns {Array<String, String>}
		 */
		getOtherFormdatasOf(contact) {
			const answersWanted = []
			const isNotCond = (form) => !form.filterbyidrefvalue

			if (Array.isArray(contact.formdatas)) {
				contact.formdatas.map((formdata) => {
					const form = cloneDeep(this.action.filter(isNotCond))

					if (form) {
						return form.map((aForm) => {
							const answers = aForm.refvalues.filter(
								(refvalue) => refvalue.id === formdata.form_ref_id && refvalue.followed !== true,
							)

							return answers.forEach((anAnswer) => {
								answersWanted.push({
									type: aForm.type,
									question: aForm.label,
									answer: anAnswer.label || formdata.data,
								})
							})
						})
					}
				})
			}

			return answersWanted.length ? answersWanted : []
		},

		getLastOtherFormDatasOf(contact) {
			let otherFormDatas = this.getOtherFormdatasOf(contact)

			return otherFormDatas[otherFormDatas.length - 1]
		},

		/**
		 * @method isDone
		 * @param {Contact} contact
		 * @desc if the `donedate` field is present (if not : to do)
		 * @returns {Boolean}
		 */
		isDone(contact) {
			return contact.formdatas.find((formdata) => formdata.form_ref_id === this.currentTab).donedate
		},

		/**
		 * @method orderedMainAction
		 * @desc order and sort the opportunities depends of the total
		 */
		ordereRefsFollowed() {
			if (this.refsFollowed.length) {
				this.refsFollowed.sort((a, b) => {
					return b.total - a.total
				})
			}
		},

		/**
		 * @method done
		 * @param {Contact} contact
		 * @desc since when the done status was set
		 * @returns {String}
		 */
		done(contact) {
			return moment(this.isDone(contact)).calendar()
		},

		updateContact(contact, value) {
			let statusRefValue

			this.status.forEach((aStatus) => {
				if (aStatus.refvalues) {
					const founded = cloneDeep(
						aStatus.refvalues.find((aRefValue) => {
							if (aRefValue.id === value) {
								statusRefValue = aRefValue
							}
						}),
					)

					if (founded) return founded

					return false
				}
			})
			const isStatusData = (form) => form.form_id === statusRefValue.form_id

			if (contact.formdatas) {
				contact.formdatas = contact.formdatas.filter((form) => {
					if (isStatusData(form)) {
						return false
					}

					return true
				})
			}

			contact.formdatas.push({
				data: statusRefValue.value,
				date: new Date(),
				group_id: this.userConnected.selected_group_id[0],
				contact_id: contact.id,
				form_id: statusRefValue.form_id,
				form_ref_id: statusRefValue.id,
			})

			this.actionUpdateAContact(contact).then(() => {
				// Permite the visual update
				this.tasksKey++
			})
		},

		/**
		 * @method finish
		 * @param {Contact} contact
		 * @desc will update the associated formdata `donedate`
		 * to put the status to 'done' accordingly
		 */
		finish(contact, event) {
			if (event.target.checked) {
				contact.formdatas.find((formdata) => formdata.form_ref_id === this.currentTab).donedate
					= new Date()
			}
			else {
				contact.formdatas.find((formdata) => formdata.form_ref_id === this.currentTab).donedate
					= null
			}

			this.actionUpdateAContact(contact).then(() => {
				const refIdWanted = this.currentTab

				this.refsFollowed.forEach((aRef) => {
					if (aRef.id === refIdWanted) aRef.total--
				})
				// Permite the visual update
				this.tasksKey++
			})
		},

		/**
		 * @method cancelTask
		 * @param {Contact} contact
		 * @desc will delete the formdata tha cause the contact to be display.
		 * By deleting the action associated formdata, you delete the task.
		 */
		cancelTask(contact) {
			contact.formdatas = contact.formdatas.filter(
				(formdata) => formdata.form_ref_id !== this.currentTab,
			)
			this.actionUpdateAContact(contact).then(() => {
				const index = this.computedTasks.findIndex((task) => task.id === contact.id)

				this.computedTasks.splice(index, 1)
				this.actionSetTasks(this.computedTasks)
			})
		},
	},
}
</script>
<style lang="sass">
#task-header
	white-space: nowrap
.report
	white-space: initial

$right-pad : calc(30px + 3em)
.todo-card
	position: relative
	border: none !important
	padding-right: $right-pad !important
	.q-card-overlay
		width: calc(100% - #{$right-pad}) !important
	.q-card-options
		// right: $right-pad !important
	.todo-complete
		z-index: 10
		position: absolute
		height: calc(100% + 20px)
		right: 0
		input
			border-radius: 4px !important
			&:not(:checked), &:checked
				background: white !important
			&:checked::after
				color: black !important
				font-size: 4.5rem !important
				top: -1.25rem !important
				left: -1.25rem !important
.vignette
	height: 5em
</style>
