import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import modulesInstancesMixin from '../../../components/mixins/modules-instances-mixin'
import { DataTransformer } from '@quorumsco/quorum-helpers/lib/filter/DataTransformer'

/**
 * @const initialPayloadWanted
 * @desc initial payload used to create the object
 * by default for create the new search query
 */
const initialPayloadWanted = {
	address_included: [],
	polling_station_included: [],
}

/**
 * @exports
 * @func formatOfFutureObjectForSearching
 * @param {object} initialPayloadWanted
 * @desc will format an object returned in complement
 * of the future object for searching. This function is updated
 * to give a new structure for the polling_station_included which is now
 * an array of objects. ex: { pollingstation: "mypollingstation" }
 */
export function formatOfFutureObjectForSearching(payload = initialPayloadWanted) {
	if (payload === null) payload = initialPayloadWanted
	let {
		addressIncluded,
		pollingStationsIncluded,
		/* allowUnknowAddressesOnActionCreation */
	} = payload

	let allowMissingPollingstations = false

	if (!addressIncluded) addressIncluded = []
	if (!pollingStationsIncluded) pollingStationsIncluded = []
	else {
		allowMissingPollingstations = pollingStationsIncluded.some(
			(aPollingStation) => aPollingStation === 'missing',
		)

		/**
		 * Remove missing pollingstations
		 */
		if (allowMissingPollingstations) {
			pollingStationsIncluded = pollingStationsIncluded.filter(
				(aPollingStationToInclude) => aPollingStationToInclude !== 'missing',
			)
		}

		pollingStationsIncluded = pollingStationsIncluded.map((aPollingStationToInclude) => {
			return { pollingstation: aPollingStationToInclude }
		})
	}

	return {
		address_included: addressIncluded,
		polling_station_included: pollingStationsIncluded,
		polling_station_included_missing: allowMissingPollingstations,
	}
}

export function constructQueryFromStoreAdaptator(
	rootGetters,
	query,
	filter,
	polygon,
	tags_filter,
	duplicates_filter,
	includedAddresses,
	geohash_precision,
	advanced_search,
	contact_ids,
	instanceId = '',
	polygons = null,
) {
	const search = modulesInstancesMixin.methods.getInstanceRegisteredByModule('@search', instanceId)
	const getAdvancedSearchQuery = rootGetters[`${search}/getAdvancedSearchQuery`]

	const queryForSearch = {
		query: query,
		fields: filter,
		polygon: polygon,
		tags: tags_filter,
		only_duplicates: duplicates_filter,
		contact_ids: contact_ids,
		...includedAddresses,
	}

	if (geohash_precision) {
		queryForSearch.geohash_precision = geohash_precision
	}

	if (advanced_search) {
		queryForSearch.advanced_search = advanced_search
	}
	else if (!isEmpty(JSON.parse(JSON.stringify(getAdvancedSearchQuery)))) {
		queryForSearch.advanced_search = getAdvancedSearchQuery.build()
	}

	if (!isEmpty(queryForSearch.advanced_search)) {
		if (queryForSearch.polygon.length) {
			queryForSearch.advanced_search.polygon = cloneDeep(queryForSearch.polygon)
		}
	}

	if (polygons?.length) {
		// Add the advanced search object if it doesn't exist to add the multi polygons inside
		if (!queryForSearch.advanced_search) {
			DataTransformer(queryForSearch)
		}

		queryForSearch.advanced_search.multi_polygons = polygons
	}

	return queryForSearch
}

export function constructQueryFromStore(rootGetters, instanceId = '') {
	const filter = modulesInstancesMixin.methods.getInstanceRegisteredByModule('@filter', instanceId)
	const search = modulesInstancesMixin.methods.getInstanceRegisteredByModule('@search', instanceId)

	const getFilter = rootGetters[`${filter}/filter`]
	const getQuery = rootGetters[`${filter}/query`]
	const getPolygon = rootGetters[`${filter}/polygon`]
	const getPolygons = rootGetters[`${filter}/polygons`]
	const getTags_filter = rootGetters[`${filter}/tags_filter`]
	const getDuplicates_filter = rootGetters[`${filter}/duplicates_filter`]

	const getAdvancedSearchQuery = rootGetters[`${search}/getAdvancedSearchQuery`]
	const getSearchAddressIncluded = rootGetters[`${search}/getSearchAddressIncluded`]
	const getSearchPollingStationsIncluded = rootGetters[`${search}/getSearchPollingStationsIncluded`]

	const query = {
		query: getQuery,
		fields: getFilter,
		polygon: getPolygon,
		tags: getTags_filter,
		only_duplicates: getDuplicates_filter,
		...formatOfFutureObjectForSearching({
			addressIncluded: getSearchAddressIncluded,
			pollingStationsIncluded: getSearchPollingStationsIncluded,
		}),
	}

	if (!isEmpty(JSON.parse(JSON.stringify(getAdvancedSearchQuery)))) {
		query.advanced_search = getAdvancedSearchQuery.build()
	}

	if (!isEmpty(query.advanced_search)) {
		if (query.polygon.length) {
			query.advanced_search.polygon = cloneDeep(query.polygon)
		}
	}

	if (getPolygons?.length) {
		// Add the advanced search object if it doesn't exist to add the multi polygons inside
		if (!query.advanced_search) {
			DataTransformer(query)
		}

		query.advanced_search.multi_polygons = getPolygons
	}

	return cloneDeep(query)
}
