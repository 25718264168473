import { controllerPackRedirection } from './utils/routerController'
import store from './../store/stateStore'

const OnlineFormsMarketing = {
	title: 'SURVEY.ONLINE_FORM',
	path: 'online-forms',
	name: 'packer-onlineforms',
	component: () => import('../components/online-forms-modules/marketing/OnlineFormsPromoted.vue'),
	props: true,
	soon: true,
	opacity: true,
	isTooltipDisabled: true,
}

export const OnlineFormsPromoted = {
	path: '/promoted',
	name: 'promoted-onlineforms',
	component: () =>
		import('../components/online-forms-modules/marketing/OnlineFormsMarketingMain.vue'),
	redirect: () => {
		return { name: 'packer-onlineforms' }
	},
	children: [OnlineFormsMarketing],
	props: true,
}

export const OnlineFormsRoutes = {
	meta: { title: 'MENU.FORM' },
	path: '/online-forms',
	name: 'online-forms',
	props: true,
	component: () => import('../components/online-forms-modules/onlineFormsMain.vue'),
	beforeEnter: (to: object, from: object, next: Function) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('online_form:delete')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessOnlineForms', 'promoted-onlineforms', [
				'enabled',
			])
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			title: 'ONLINE_FORMS.HEADER.VIEW_TITLE',
			path: 'view',
			name: 'online-forms-view',
			component: () => import('../components/online-forms-modules/view/onlineFormView.vue'),
		},
		{
			path: 'create',
			name: 'online-forms-create',
			redirect: { name: 'create-base-form-type' },
			component: () =>
				import('../components/online-forms-modules/create-tunnel/createOnlineFormsMain.vue'),
			children: [
				{
					path: 'form-type-choice',
					name: 'create-base-form-type',
					component: () =>
						import(
							'../components/online-forms-modules/create-tunnel/createOnlineFormsBaseFormType.vue'
						),
				},
				{
					path: 'base',
					name: 'create-base-online-forms',
					component: () =>
						import('../components/online-forms-modules/create-tunnel/createOnlineFormsBase.vue'),
				},
				{
					path: 'respondent',
					name: 'create-respondent',
					component: () =>
						import(
							'../components/online-forms-modules/create-tunnel/createOnlineFormsRespondent.vue'
						),
				},
			],
		},
	],
}
