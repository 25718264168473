/**
 * @func applyValueFromKey
 * @param {string} keywanted
 * @param {array} defaultValuesArray
 * @param {array} parametersArray
 */
function applyValueFromKey(keyWanted, defaultValue, parametersArray) {
	if (defaultValue !== undefined) {
		if (parametersArray && keyWanted in parametersArray)
			return parametersArray[keyWanted].toString()
		return defaultValue
	}
}

/**
 * @exports
 * @func createFilterFromParamsObject
 * @param {object} parameters is an object defined with keys provided by keysForFilter
 * @returns {array} return an array will be handle by the search, location summary geohash, etc.
 */
export default function createFilterFromParamsObject(state, parameters) {
	let newArrayOfFilters = new Array(
		applyValueFromKey('group_id', state[0], parameters),
		applyValueFromKey('searchType', state[1], parameters),
		applyValueFromKey('nb_results', state[2], parameters),
		applyValueFromKey('result_index', state[3], parameters),
		applyValueFromKey('sexe_filter', state[4], parameters),
		applyValueFromKey('bdv_filter', state[5], parameters),
		applyValueFromKey('age_filter', state[6], parameters),
		applyValueFromKey('sorting', state[7], parameters),
		applyValueFromKey('order', state[8], parameters),
		applyValueFromKey('lastchange_filter', state[9], parameters),
		applyValueFromKey('email_filter', state[10], parameters),
		applyValueFromKey('phone_filter', state[11], parameters)
	)
	if (parameters && Array.isArray(parameters.form_filter_data)) {
		if (parameters.form_filter_data.length > 0) {
			newArrayOfFilters = newArrayOfFilters.concat(parameters.form_filter_data)
		}
	}
	return newArrayOfFilters
}
