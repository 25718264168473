import { defineStore, acceptHMRUpdate } from 'pinia'
import GiveRequester from '@/requesters/gives'
import { amountToFrontend } from '@/requesters/transactionSerializer'

export const useGivesStore = defineStore('gives', {
	state: () => ({
		donations: [],
		memberships: [],
	}),
	getters: {
		getDonationsList: (state) => {
			return state.donations
				.map((aDonation: DonationItem): any => {
					return {
						id: aDonation.id,
						amount: amountToFrontend(aDonation.amount),
						currency: aDonation.currency,
						name: aDonation.name,
						allowCustomAmount: aDonation.allow_custom_amount,
						hidden: aDonation.hidden,
						kind: aDonation.kind,
					}
				})
				.sort((a, b): number => {
					return a.amount - b.amount
				})
		},

		getMembershipsList: (state) => {
			return state.memberships.map((aMembership: MembershipItem) => {
				return {
					...aMembership,
					membership_prices: aMembership.membership_prices
						.map((aPrice: any) => {
							return {
								...aPrice,
								amount: amountToFrontend(aPrice.amount),
							}
						})
						.sort((a, b): number => {
							return a.amount - b.amount
						}),
				}
			})
		},
	},

	actions: {
		async actionRequestGetDonations() {
			try {
				const response = await GiveRequester.getGiveDonations()
				this.donations = response
			}
			catch (error) {
				console.log(error)
			}
		},
		async actionRequestGetMemberships() {
			try {
				const response = await GiveRequester.getGiveMemberships()
				this.memberships = response
			}
			catch (error) {
				console.log(error)
			}
		},
	},
})

if (import.meta.webpackHot) {
	import.meta.webpackHot.accept(acceptHMRUpdate(useGivesStore, import.meta.webpackHot))
}
