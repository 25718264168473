import { $request } from './requester'

/**
 * @model Communication
 * @desc handle any mail / autosend related API
 */
export default {
	/**
	 * GET `/templates`
	 *
	 * @param {*} root ???
	 * @param {Function} cb
	 * @deprecated not used
	 */
	async getTemplates(root, cb) {
		return $request('GET', '/templates').then((res) => {
			cb(res.body.data.templates)
		})
	},

	/**
	 * POST `/templates`
	 * @param {Object} template template to save
	 */
	async saveTemplate(template) {
		return $request('POST', '/templates', { template })
	},

	/**
	 * DELETE `/templates/:id`
	 * @param {Number} id id of the template to delete
	 */
	async deleteTemplate(id) {
		return $request('DELETE', `/templates/${id}`)
	},

	/**
	 * GET `/autosends`
	 */
	async getAutosend() {
		return $request('GET', '/autosends').then((res) => res.body.data.autosend)
	},

	/**
	 * POST `/autosends`
	 * @param {Object} autosend save autosend
	 */
	async saveAutosend(autosend) {
		return $request('POST', '/autosends', { autosend }).then((res) => res.body.data.autosend)
	},

	/**
	 * GET `/emailaccs`
	 */
	async getEmailaccs() {
		return $request('GET', '/emailaccs').then((res) => res.body.data.emailaccs)
	},

	/**
	 * POST `/sendmail`
	 * @param {Object} data data to send
	 * @deprecated not used
	 */
	async sendEmail(data) {
		return $request('POST', '/sendemail', { data }).then((res) => res.body.data)
	},

	/**
	 * POST `/sendSMSs`
	 * @param {String} message message to send on
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 */
	async sendEmailWithFilters(payload) {
		const finalPayload = {
			email: {
				object: payload.email.mailObject,
				subject: payload.email.mailContent,
			},
			search: {
				Search: payload.filters,
			},
		}

		if (payload.email.sender) {
			finalPayload.email['sender'] = payload.email.sender
			finalPayload.email['sender_name'] = payload.email.sender_name
		}

		if (payload.email.cc) {
			finalPayload.email['cc'] = payload.email.cc
		}

		if (payload.email.reply_to) {
			finalPayload.email['reply_to'] = payload.email.reply_to
		}

		if (payload?.email?.contacts) {
			finalPayload.email['contacts'] = payload.email.contacts
		}
		// Reinit the pagination of the search sent to 0
		payload.filters.fields[3] = '0'

		return $request('POST', '/sendemail', finalPayload)
			.then((res) => {
				return res.body
			})
			.catch((err) => {
				if (err) return err
			})
	},

	/**
	 * POST `/sendSMSs`
	 * @param {String} message message to send on
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 * @param {Number} messagingProfileId messaging profile id to use
	 */
	async sendSms({ smsBody: message, filters, messagewithoutstop, file, messagingProfileId }) {
		// Reinit the pagination of the search sent to 0
		filters.fields[3] = '0'

		const payload = {
			message,
			search: {
				Search: filters,
			},
			messagewithoutstop: messagewithoutstop,
		}

		if (file) payload.files = [file]
		if (messagingProfileId) payload.user_account = messagingProfileId

		return $request('POST', '/sendsmss', payload).then((res) => {
			return res.body.data
		})
	},

	/**
	 * POST `/sendTestSms`
	 * @param {String} message message to send on
	 * @param {Array} usersIds users ids to send to
	 * @param {Object} file file to send
	 * @param {Number} messagingProfileId messaging profile id to use
	 */
	async sendTestSms({ smsBody: message, file, usersIds, messagingProfileId }) {
		const payload = {
			message,
			users: usersIds,
		}

		if (file) payload.files = [file]
		if (messagingProfileId) payload.user_account = messagingProfileId
		return $request('POST', '/sendtestsmss', payload)
	},

	async sendGlobalNotification(payload) {
		return $request('POST', '/global-message', {
			...payload,
		}).then((res) => {
			return res.body.data
		})
	},

	/**
	 * GET `/getusagesmsdb`
	 */
	async getUsageSmsDb() {
		return $request('GET', '/getusagesmsdb').then((res) => res.body.data)
	},

	async actionGetMessageBirdBalance() {
		return $request('GET', '/getbalancemessagebird').then((res) => {
			return {
				balance: parseInt(res.body.data.balance),
				balance_mms: parseInt(res.body.data?.balance_mms || 0),
			}
		})
	},

	async getEmailsBalance(isElasticEmailActivated) {
		if (isElasticEmailActivated)
			return $request('GET', '/emailer/account').then((res) => {
				return {
					balance: res.body.data.emailer_account?.credits || 0,
					account: res.body.data.emailer_account,
				}
			})
		else return $request('GET', '/getbalanceemail').then((res) => res.body.data.balance)
	},

	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},

	async getMessagingProfiles() {
		return $request('GET', '/telnyx/messagingprofiles').then((res) => res.body.data)
	},
	
	async getProviderCampaignStatuses(link) {
		return $request('GET', `${link}`).then((res) => res.body.data)
	},
}
