<template>
	<div class="flex flex-row" :class="customContainerClass">
		<i v-if="icon" :class="icon"> </i>
		<span v-if="label">{{ label }}</span>
	</div>
</template>

<script>
/**
 * @component Badge
 * @desc a simple badge that consist of a icon with a tooltip
 */
export default {
	props: {
		/**
		 * @vprop {String} type - the badge you want.
		 */

		icon: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},

		customContainerClass: {
			type: String,
			default: '',
		},
	},

	computed: {},
}
</script>
