import Signup from 'components/app-module/signup/signupMain.vue'
import CreateAccount from 'components/app-module/signup/createAccount.vue'
import AddAccountDetails from 'components/app-module/signup/addAccountDetails.vue'
import ValidateEmail from 'components/app-module/signup/validateEmail.vue'
import JoinAGroup from 'components/app-module/signup/joinAGroup.vue'

export default {
	meta: { title: '_COMMON.SIGNUP' },
	path: '/signup',
	redirect: '/signup/create-account',
	name: 'signup',
	component: Signup,
	props: true,
	children: [
		{
			path: '/signup/create-account',
			name: 'create-account',
			component: CreateAccount,
		},
		{
			path: '/signup/add-account-details',
			name: 'add-account-details',
			component: AddAccountDetails,
		},
		{
			path: '/signup/validate-email',
			name: 'validate-email',
			component: ValidateEmail,
		},
		{
			path: '/signup/join-a-group',
			name: 'join-a-group',
			component: JoinAGroup,
		},
	],
}
