<template>
	<div
		class="relative flex items-center"
		:class="[
			{ 'cursor-not-allowed pointer-events-none': isSoon && clickBlocked },
			noPaddingY ? 'py-0' : 'py-2',
			isDisabled ? 'cursor-not-allowed pointer-events-none text-gray-200' : 'text-gray-400',
		]"
		@click="$emit('tabClick', true)"
	>
		<packer
			:status="statusPack"
			:badge="badge || null"
			:no-opacity="noOpacity || null"
			:tag="tag || null"
		>
			<span
				class="tab-title pb-1 hover:text-pink-strong"
				:class="[
					{
						selected: selected,
					},
					isDisabled ? 'cursor-not-allowed pointer-events-none text-gray-200' : 'text-gray-400',
				]"
				:style="`font-size: ${tabFontSize}`"
			>
				{{ title }} 
			</span>
			<span v-if="additionalTooltip && selected"> 
				<el-tooltip
					placement="top"
					effect="dark"
					:content="$t(`${additionalTooltip.tooltipValue}`)">
				<i  v-if="additionalTooltip.iconClasses" :class="additionalTooltip.iconClasses"></i>
			
			</el-tooltip>
			</span>

			<span v-if="statusPack === 'enabled'">
				<span
					v-if="isLoading === 'loading'"
					class="ml-2"
				><i class="fa fa-circle-o-notch fa-spin" /></span>
				<span
					v-else-if="count"
					class="pl-2"
					:class="
						(value === 'teams' || value === 'members' ? 'text-pink-main' : 'text-gray-400',
							isDisabled ? 'cursor-not-allowed pointer-events-none text-gray-200' : 'text-gray-400')
					"
					:style="`font-size: ${countFontSize}`"
				>{{ count }}</span>
				<span
					v-else-if="count || value === 'teams' || value === 'members'"
					class="pl-2"
					:class="
						isDisabled ? 'cursor-not-allowed pointer-events-none text-gray-200' : 'text-gray-400'
					"
					style="font-weight: 600;"
					:style="`font-size: ${countFontSize}`"
				>{{ count }}</span>
				<span
					v-else-if="count === 0 && value === 'signatories'"
					class="pl-2"
					:class="
						isDisabled ? 'cursor-not-allowed pointer-events-none text-gray-200' : 'text-gray-400'
					"
					:style="`font-size: ${countFontSize}`"
				>
					{{ count }}
				</span>
			</span>
		</packer>
		<is-soon-bubble-label
			v-if="isSoon"
			:custom-tailwinds-class="'bold ml-3'"
		/>
	</div>
</template>

<script>
import packer from '../packs/packer.vue'
import isSoonBubbleLabel from '../../components/isSoon/isSoonBubbleLabel.vue'
export default {
	components: { packer, isSoonBubbleLabel },
	props: {
		additionalTooltip: {
			type: Object,
			default: null,
		},
		count: {
			type: Number,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		isLoading: {
			type: String,
			default: 'false',
		},
		selected: {
			type: Boolean,
			default: false,
		},
		fontSize: {
			type: String,
			default: '',
		},
		noZero: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: '',
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		statusPack: {
			type: String,
			default: 'enabled',
		},
		tag: {
			type: String,
			default: 'div',
			required: false,
		},
		badge: {
			type: String,
			default: '',
		},
		noOpacity: {
			type: Boolean,
			default: false,
		},
		isSoon: {
			type: Boolean,
			required: false,
			default: false,
		},
		clickBlocked: {
			type: Boolean,
			required: false,
			default: true,
		},
		noPaddingY: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['tabClick'],
	data() {
		return {
			tabFontSize: 0,
			countFontSize: 0,
		}
	},

	mounted() {
		this.tabFontSize = this.fontSize
		window.addEventListener('resize', this.methodHandleResize)
		this.methodHandleResize()
	},

	unmounted() {
		window.removeEventListener('resize', this.methodHandleResize)
	},

	methods: {
		methodHandleResize() {
			const screenWidth = window.innerWidth

			if (screenWidth < 640) {
				if (!this.fontSize) this.tabFontSize = '0.75rem'
				this.countFontSize = '0.70rem'
			}
			if (screenWidth > 640 && screenWidth < 768) {
				if (!this.fontSize) this.tabFontSize = '0.875rem'
				this.countFontSize = '0.75rem'
			}
			if (screenWidth > 768) {
				if (!this.fontSize) this.tabFontSize = '1.125rem'
				this.countFontSize = '1rem'
			}
			if (screenWidth > 900) {
				if (!this.fontSize) this.tabFontSize = '1.25rem'
				this.countFontSize = '1.1rem'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.tab-title {
	cursor: pointer;
}
</style>

<style scoped>
.selected {
	font-weight: bold;
	color: black;
	display: inline-block;
	margin-bottom: -5px;
	padding-bottom: 5px;
	position: relative;
}

.selected::after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	right: 0;
	height: 4px;
	background-image: url('/static/images/svg/underline-svg.svg');
	background-size: cover;
	background-repeat: no-repeat;
}

.disabledSpan {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.3rem;
	background-color: rgb(233, 231, 231);
	opacity: 0.8;
	cursor: not-allowed;
}
</style>
