<template>
	<div class="qview">
		<div v-if="!$route.name.includes('import-') && $route.name !== 'an-import'">
			<settings-header></settings-header>
		</div>
		<div v-else-if="$route.name === 'an-import'">
			<import-header :leaving-popup="false" disabled-progress></import-header>
		</div>
		<div class="qview-main bck-color">
			<div class="q-hide-scroll q-fullwidth">
				<router-view class="q-hide-scroll-content"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'Settings',
	components: {
		settingsHeader: defineAsyncComponent(() => import('./settings-header.vue')),
		importHeader: defineAsyncComponent(() => import('./import/importHeader')),
	},
	async mounted() {
		await this.actionCheckIfMessageBirdEnabled()
	},

	methods: {
		...mapActions(['actionCheckIfMessageBirdEnabled']),
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.bck-color {
	background-color: $pink-lighted;
}
</style>
