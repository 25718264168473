export const dataTypeAvailable = [
	{
		type: 'static',
	},
	{
		type: 'formdatas:custom_fields',
	},
	{
		type: 'formdatas:forms',
	},
	{
		type: 'formdatas:name_presence',
	},
	{
		type: 'formdatas:status',
	},
	{
		type: 'interactions',
	},
	{
		type: 'notes',
	},
	{
		type: 'formdatas:actions',
	},
	{
		type: 'formdatas:consent',
	},
	{
		type: 'actiondatas:actions_ids',
	},
	{
		type: 'reporting',
	},
	{
		type: 'petitionsdata:petition_id',
	},
	{
		type: 'onlineformsdata:base_id',
	},
	{
		type: 'mandates',
	},
	{
		type: 'transactions',
	},
	{
		type: 'donations',
	},
	{
		type: 'memberships',
	},
	{
		type: 'sanctions',
	},
]

export const operatorsAvailables = [
	{
		type: 'eql:strictdata',
		disabled: false,
	},
	{
		type: 'not_eql:strictdata',
		disabled: false,
	},
	{
		type: 'contains',
		disabled: false,
	},
	{
		type: 'not_contains',
		disabled: false,
	},
	{
		type: 'eql',
		disabled: false,
	},
	{
		type: 'not_eql',
		disabled: false,
	},
	{
		type: 'any_of',
		disabled: false,
	},
	{
		type: 'none_of',
		disabled: false,
	},
	{
		type: 'all_of',
		disabled: false,
	},
	{
		type: 'ext',
		disabled: false,
	},
	{
		type: 'not_ext',
		disabled: false,
	},
	{
		type: 'range',
		disabled: false,
	},
	{
		type: 'lte',
		disabled: false,
	},
	{
		type: 'gte',
		disabled: false,
	},
	{
		type: 'start_with',
		disabled: false,
	},
	{
		type: 'not_start_with',
		disabled: false,
	},
	{
		type: 'period',
		disabled: false,
	},
	{
		type: 'today',
		disabled: false,
	},
]

export const dynamicDateAvailable = {
	today: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.TODAY',
		from: 'now/d',
		to: 'now/d',
	},
	yesterday: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.YESTERDAY',
		from: 'now-1d/d',
		to: 'now-1d/d',
	},
	tomorrow: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.TOMORROW',
		from: 'now+1d/d',
		to: 'now+1d/d',
	},
	lastWeekend: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.LAST_WEEKEND',
		from: 'now/w-2d',
		to: 'now-1w/w',
	},
	thisWeek: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.THIS_WEEK',
		from_monday: 'now/w',
		to_monday: 'now/w',
		from_sunday: 'now/w-1d',
		to_sunday: 'now/w-1d',
		isWeek: true,
	},
	last7days: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.LAST_7_DAYS',
		// Do 6 days because the current day is included
		from: 'now-6d/d',
		to: 'now/d',
	},
	lastWeek: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.LAST_WEEK',
		from_monday: 'now-1w/w',
		to_monday: 'now-1w/w',
		from_sunday: 'now-1w/w-1d',
		to_sunday: 'now-1w/w-1d',
		isWeek: true,
	},
	next7days: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.NEXT_7_DAYS',
		from: 'now/d',
		// Do 6 days because the current day is included
		to: 'now+6d/d',
	},
	thisMonth: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.THIS_MONTH',
		from: 'now/M',
		to: 'now/M',
	},
	lastMonth: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.LAST_MONTH',
		from: 'now-1M/M',
		to: 'now-1M/M',
	},
	nextMonth: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.NEXT_MONTH',
		from: 'now+1M/M',
		to: 'now+1M/M',
	},
	threeMonthsAgo: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.THREE_MONTHS_AGO',
		from: 'now-3M/d',
		to: 'now',
	},
	thisYear: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.THIS_YEAR',
		from: 'now/y',
		to: 'now/y',
	},
	lastYear: {
		label: 'CONTACT.FILTERS.RELATIVE_DATE.LAST_YEAR',
		from: 'now-1y/y',
		to: 'now-1y/y',
	},
}

const attributesTypologies = [
	{
		type: 'date',
		keys: ['birthdate', 'lastchange', 'CreatedAt', 'UpdatedAt'],
	},
	{
		type: 'radio',
		keys: [
			'address.pollingstation',
			'address.invalid',
			'black_list',
			'user_id',
			'tags.name',
			'gender',
			'age_cat',
		],
	},
	{
		type: 'text:strictdata',
		keys: ['mail'],
	},
	{
		type: 'text:match',
		keys: ['address.postalcode', 'address.state', 'address.county'],
	},
	{
		type: 'none',
		keys: ['birthday'],
	},
]

export const typologyDispatchingAttributes = (key) => {
	const foundTypology = attributesTypologies.find((anAttribute) => anAttribute.keys.includes(key))
	if (foundTypology) return foundTypology.type
	return 'text'
}

export const mandatesFields = [
	{
		id: 'mandates.title',
		name: 'MANDATES',
		label: 'Title',
		type: 'radio',
	},
	{
		id: 'mandates.organisation',
		name: 'MANDATES',
		label: 'Organism',
		type: 'radio',
	},
	{
		id: 'mandates.subentity',
		name: 'MANDATES',
		label: 'Subentity',
		type: 'radio',
	},
	{
		id: 'mandates.start_date',
		name: 'MANDATES',
		label: 'Start date',
		type: 'date',
	},
	{
		id: 'mandates.end_date',
		name: 'MANDATES',
		label: 'End date',
		type: 'date',
	},
	{
		id: 'mandates.mail',
		name: 'MANDATES',
		label: 'Mail',
		type: 'text',
	},
	{
		id: 'mandates.phone',
		name: 'MANDATES',
		label: 'Phone',
		type: 'text',
	},
	{
		id: 'mandates.address.postalcode',
		name: 'MANDATES',
		label: 'Postal code',
		type: 'text',
	},
	{
		id: 'mandates.address.city',
		name: 'MANDATES',
		label: 'City',
		type: 'text',
	},
	{
		id: 'mandates.validity',
		name: 'MANDATES',
		type: 'radio',
	},
]

export const transactionsFields = [
	{
		id: 'transactions.code_campaign',
		name: 'TRANSACTIONS',
		type: 'text',
	},
	{
		id: 'transactions.status_id',
		name: 'TRANSACTIONS',
		type: 'radio',
	},
	{
		id: 'transactions.payment_method_kind',
		name: 'TRANSACTIONS',
		type: 'radio',
	},
	{
		id: 'transactions.amount',
		name: 'TRANSACTIONS',
		type: 'numeric',
	},
	{
		id: 'transactions.sum_amount',
		name: 'TRANSACTIONS',
		type: 'numeric',
	},
	{
		id: 'transactions.date',
		name: 'TRANSACTIONS',
		type: 'date',
	},

]

export const donationsFields = [
	{
		id: 'donations.amount',
		name: 'DONATIONS',
		type: 'numeric',
	},
	{
		id: 'donations.sum_amount',
		name: 'DONATIONS',
		type: 'numeric',
	},
	{
		id: 'donations.count_amount',
		name: 'DONATIONS',
		type: 'numeric',
	},
	{
		id: 'donations.donation_price_id',
		name: 'DONATIONS',
		type: 'radio',
	},
	{
		id: 'donations.created_at',
		name: 'DONATIONS',
		type: 'date',
	},
	{
		id: 'donations.transaction_date',
		name: 'DONATIONS',
		type: 'date',
	},
]

export const membershipsFields = [
	{
		id: 'memberships.period',
		name: 'MEMBERSHIPS',
		type: 'radio',
	},
	{
		id: 'memberships.multiple_in_period',
		name: 'MEMBERSHIPS',
		type: 'radio',
	},
	{
		id: 'memberships.membership_price_id',
		name: 'MEMBERSHIPS',
		type: 'radio',
	},
	{
		id: 'memberships.start_date',
		name: 'MEMBERSHIPS',
		type: 'date',
	},
	{
		id: 'memberships.end_date',
		name: 'MEMBERSHIPS',
		type: 'date',
	},
	{
		id: 'memberships.created_at',
		name: 'MEMBERSHIPS',
		type: 'date',
	},
	{
		id: 'memberships.transaction_date',
		name: 'MEMBERSHIPS',
		type: 'date',
	},
]

export const interactionsFields = [
	{
		id: 'interactions.title',
		name: 'INTERACTIONS',
		type: 'text',
	},
	{
		id: 'interactions.date_of_event',
		name: 'INTERACTIONS',
		type: 'date',
	},
	{
		id: 'interactions.type',
		name: 'INTERACTIONS',
		type: 'radio',
	},
	{
		id: 'interactions.sub_type',
		name: 'INTERACTIONS',
		type: 'radio',
	},
	{
		id: 'interactions.tags.name',
		name: 'INTERACTIONS',
		type: 'radio',
	},
	{
		id: 'interactions.address',
		name: 'INTERACTIONS',
		type: 'text',
	},
]

export const sanctionsFields = [
	{
		id: 'sanctions.created_at',
		name: 'SANCTIONS',
		type: 'date',
	},
	{
		id: 'sanctions.start_date',
		name: 'SANCTIONS',
		type: 'date',
	},
	{
		id: 'sanctions.end_date',
		name: 'SANCTIONS',
		type: 'date',
	},
	{
		id: 'sanctions.sanction_authority_id',
		name: 'SANCTIONS',
		type: 'radio',
	},
	{
		id: 'sanctions.sanction_type_id',
		name: 'SANCTIONS',
		type: 'radio',
	},
	{
		id: 'sanctions.validity',
		name: 'SANCTIONS',
		type: 'radio',
	},
	// {
	// 	id: 'sanctions.status',
	// 	name: 'SANCTIONS',
	// 	type: 'radio',
	// },
]

export const notesFields = [
	{
		id: 'notes.data',
		name: 'NOTES',
		type: 'text',
	},
	{
		id: 'notes.created_at',
		name: 'NOTES',
		type: 'date',
	},
	{
		id: 'notes.updated_at',
		name: 'NOTES',
		type: 'date',
	},
]
