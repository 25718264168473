<template>
	<el-tooltip
		:disabled="status !== 'finalization_process_requested' && !computedErrorParsing"
		class="item"
		effect="dark"
		:content="
			computedErrorParsing
				? computedErrorParsingReason
				: $t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.NEED_INTERVENTION')
		"
		placement="top"
	>
		<span>
			<div
				class="badgeStyle"
				:class="computedStatusWording.color"
			>
				{{ computedStatusWording.label }}
			</div>
		</span>
	</el-tooltip>
</template>

<script>
export default {
	props: {
		status: {
			type: String,
			default: '',
		},
		statuses: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		computedStatusWording() {
			switch (this.status) {
				case 'to_start':
				case 'to_mapping':
				case 'to_name':
				case 'file_config_defined':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.CONFIG_REQUIRED'),
						color: 'bg-blue-lighted',
					}

				case 'file_parsing_error':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.ERROR_PARSING'),
						color: 'bg-red-400 text-white',
					}

				case 'error':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.ERROR'),
						color: 'bg-red-400 text-white',
					}
				case 'requested':
					return {
						label: this.$t('SETTINGS.EXPORT.STATUS.REQUESTED'),
						color: 'bg-gray-600 text-white',
					}

				case 'deleted': {
					return {
						label: this.$t('SETTINGS.EXPORT.STATUS.DELETED'),
						color: 'bg-gray-600 text-white',
					}
				}

				case 'finalization_process_rejected':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.REJECTED'),
						color: 'bg-red-400 text-white',
					}
				case 'done':
				case 'finalization_process_done':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.FINISHED'),
						color: 'bg-green-lighted',
					}

				case 'finalization_process_requested':
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.FINISHED_WARNING'),
						color: 'bg-green-300 text-white',
					}
				case 'to_pay':
					return {
						label: this.$t('SETTINGS.INVOICE.TO_BE_PAYED'),
						color: 'bg-blue-lighted',
					}
				case 'payed':
					return {
						label: this.$t('SETTINGS.INVOICE.PAID'),
						color: 'bg-green-lighted',
					}

				case 'wip':
				case 'conflict_process_done':
				case 'conflictjobs_done':
				case 'file_parsing_wip':
				case 'file_parsing_enqueued':
				case 'finalization_process_wip':
				case 'wip_deleting':
				default:
					return {
						label: this.$t('SETTINGS.IMPORT.STATUS_IMPORT_LIST.IN_PROCESSING'),
						color: 'bg-gray-light',
					}
			}
		},

		computedErrorParsing() {
			switch (this.status) {
				case 'file_parsing_error':
					return true

				default:
					return false
			}
		},

		computedErrorParsingReason() {
			if (this.statuses.length) {
				const lastStatusesErrorParsing = this.statuses.find(
					(aStatus) => aStatus.label === 'file_parsing_error',
				)
				if (lastStatusesErrorParsing) {
					return lastStatusesErrorParsing.description
				}
			}
			return ''
		},
	},
}
</script>

<style class="badgeStyle">
.badgeStyle {
	display: inline-block;
	font-size: 13px;
	height: 23px;
	line-height: 23px;
	border-radius: 6px;
	padding: 0 0.5rem;
	font-weight: lighter;
}
.badgeStyle:not(.text-white) {
	color: black;
}
</style>
