/**
 * The purpose of this store is to handle the modifications for
 * the mailchimp bulk sync with the backend and module works
 */
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import contact_store from '../../models/contact_store'
import {
	// FROM MAILCHIMP
	POST_MAILCHIMP_BULK_STARTED_SUCCESS,
	POST_MAILCHIMP_BULK_STARTED_ERROR,
	RESET_MAILCHIMP_STORE_MODULE,
} from './_mutation-types'
import QueryBuilder from '@quorumsco/quorum-helpers/lib/filter/QueryBuilder'
import { formatOfFutureObjectForSearching } from './utils/searchTools'

const getDefaultState = () => {
	return {
		isMailchimpStartsBulkError: false,
		isMailchimpStartsBulkSuccess: false,
	}
}
const state = getDefaultState

const mutations = {
	[POST_MAILCHIMP_BULK_STARTED_SUCCESS]: (currentState) => {
		currentState.isMailchimpStartsBulkError = false
		currentState.isMailchimpStartsBulkSuccess = true
	},
	[POST_MAILCHIMP_BULK_STARTED_ERROR]: (currentState) => {
		currentState.isMailchimpStartsBulkError = true
		currentState.isMailchimpStartsBulkSuccess = false
	},
	[RESET_MAILCHIMP_STORE_MODULE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
}

const getters = {
	getIsMailchimpStartsBulkError: (state) => state.isMailchimpStartsBulkError,
	getIsMailchimpStartsBulkSuccess: (state) => state.isMailchimpStartsBulkSuccess,
}

const actions = {
	/**
	 * @func actionAddContactsBulkServiceMailchimp
	 * @desc We will bulk add users to the mailchimp services
	 * We remove the limit on filter 3 if we want to all the pages
	 * We can provide an arrayOfContacts if we want to send just
	 * some ids to mailchimp
	 */
	actionAddContactsBulkServiceMailchimp({ commit, rootState }, payload) {
		const { keys, extraPayload, arrayOfContacts, totalHit } = payload

		if (arrayOfContacts && Array.isArray(arrayOfContacts)) {
			const arrayOfContactsIds = arrayOfContacts.map((aContact) => aContact.id)

			contact_store
				.bulkAddContactsService(
					keys,
					rootState['@filter'].query,
					rootState['@filter'].filter,
					rootState['@filter'].polygon,
					rootState['@filter'].tags_filter,
					rootState['@filter'].duplicates_filter,
					{
						...formatOfFutureObjectForSearching(extraPayload),
					},
					arrayOfContactsIds
				)
				.then(() => {
					commit(POST_MAILCHIMP_BULK_STARTED_SUCCESS)
				})
				.catch(() => {
					commit(POST_MAILCHIMP_BULK_STARTED_ERROR)
				})
		} else {
			// We remove the limit provided by the current query.
			const advancedSearchCloned = cloneDeep(rootState['@search'].advanced_search)

			if (!isEmpty(advancedSearchCloned)) {
				advancedSearchCloned.perPage = totalHit
			}
			const stateDeepCloned = {
				filter: cloneDeep(rootState['@filter'].filter),
				advancedSearch: !isEmpty(advancedSearchCloned)
					? new QueryBuilder(
							advancedSearchCloned.query,
							advancedSearchCloned.perPage,
							advancedSearchCloned.page,
							advancedSearchCloned.sortAttribute,
							advancedSearchCloned.sortOrder
						).build()
					: {},
			}

			stateDeepCloned.filter[2] = String(totalHit)
			const extraPayload = {
				...formatOfFutureObjectForSearching(payload.extraPayload),
			}
			if (!isEmpty(stateDeepCloned.advancedSearch))
				extraPayload.advanced_search = stateDeepCloned.advancedSearch

			contact_store
				.bulkAddContactsService(
					keys,
					rootState['@filter'].query,
					stateDeepCloned.filter,
					rootState['@filter'].polygon,
					rootState['@filter'].tags_filter,
					rootState['@filter'].duplicates_filter,
					extraPayload
				)
				.then(() => {
					commit(POST_MAILCHIMP_BULK_STARTED_SUCCESS)
				})
				.catch(() => {
					commit(POST_MAILCHIMP_BULK_STARTED_ERROR)
				})
		}
	},

	/**
	 * @func actionResetMailchimpStoreModule
	 * @desc reset the store module mailchimp
	 */
	actionResetMailchimpStoreModule({ commit }) {
		commit(RESET_MAILCHIMP_STORE_MODULE)
	},
}

export const mailchimpStore = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
