<template>
	<div class="mobile-simulator bg-white">
		<div class="q-row">
			<div class="notch"></div>
		</div>
		<div class="q-col q-container unset start bg-white">
			<slot class="q-p-lg" name="header"></slot>
			<div class="expander q-fullwidth q-m-b" style="overflow: auto">
				<slot></slot>
			</div>
			<slot name="footer"></slot>
			<div class="q-v-spacer"></div>
		</div>
	</div>
</template>
<style lang="sass">
.mobile-simulator
	margin: 1em
	width: calc(100% - 2em)
	height: 500px
	min-height: 150mm
	min-width: 85mm
	max-width: 0.9vw
	border: 16px solid black
	box-sizing: border-box
	border-radius: 3em
	overflow-x: hidden
	overflow-y: auto

	.notch
		position: absolute
		margin: auto
		top: -7px
		border: 9px solid black
		width: 50%
		height: 3px
		border-bottom-left-radius: 1em
		border-bottom-right-radius: 1em
		z-index: 20
		background-color: black
</style>
