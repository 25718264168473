<template>
	<div id="brief-list" class="flex flex-col">
		<div v-if="!filteredBriefs.length" class="q-col">
			<h2 v-if="!searchInput" class="center q-is-grey pt-6">
				{{ t.emptyState }}<br /><br />
				<div v-dompurify-html="t.informationBrief" class="strong-pink q-p-lg-h"></div>
				<br />
				<small class="action">
					<qi icon="plus"></qi>
					<span @click="$emit('add')">
						{{ t.add }}
					</span>
				</small>
			</h2>
			<h2 v-else class="center q-is-grey pt-6">{{ t.briefNotFound }}</h2>
		</div>
		<div v-else style="height: 66vh" class="overflow-y-auto">
			<transition-group name="cards" tag="div" class="flex flex-wrap">
				<div v-for="brief in filteredBriefs" :key="brief.id" class="md:w-1/3 w-1/2">
					<q-card
						clickable
						:active="brief.active"
						:class="{ highlight: brief.active }"
						:pinable="true"
						@click="edit(brief)"
						@pinned="toggleSelect(brief.id)"
					>
						<template #options>
							<div v-t="t.actions.edit" class="action" @click="edit(brief)"></div>
							<confirm-delete class="action" @confirm="deleteBriefs([brief.id])">
								<i class="gui-danger"></i>
								{{ t.actions.delete }}
							</confirm-delete>
						</template>
						<template #header>
							<div class="title pb-1">{{ brief.label }}</div>
						</template>
						<template #content>
							<div class="q-scroll q-container q-fullwidth q-card-top">
								<p v-dompurify-html="$filters.newLineToHtml(brief.refvalues[0].label)"></p>
							</div>
						</template>
					</q-card>
				</div>
			</transition-group>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import confirmDelete from 'components/general/confirmDelete'
import { cloneDeep, debounce } from 'lodash'

export default {
	components: { confirmDelete },

	props: {
		edit: {
			type: Function,
			default: () => {},
		},
		searchInput: {
			type: String,
			default: '',
		},
	},
	emits: ['add', 'setSelectedIds', 'setSelectedData'],

	data() {
		return {
			filteredBriefs: [],
			localCopyBriefs: [],
		}
	},

	computed: {
		...mapGetters('@briefs', ['briefs']),

		t() {
			const prefix = 'SHARING2.BRIEFS'
			return {
				actions: {
					delete: this.$t(`${prefix}.ACTIONS.DELETE`),
					edit: this.$t(`${prefix}.ACTIONS.EDIT`),
					add: this.$t(`${prefix}.ACTIONS.ADD`),
				},
				emptyState: this.$t(`${prefix}.EMPTY_STATE`),
				informationBrief: this.$t(`${prefix}.INFORMATION`),
				briefNotFound: this.$t(`${prefix}.NOT_FOUND`),
			}
		},

		computedFilteredBriefs() {
			return this.filteredBriefs
		},
	},

	watch: {
		briefs() {
			this.localCopyBriefs = cloneDeep(this.briefs.map((obj) => ({ ...obj, active: false })))
			this.methodFilterBriefs()
		},

		$route: {
			handler(newVal) {
				this.methodFilterBriefs(newVal.query.b)
			},
			deep: true,
		},
	},

	mounted() {
		this.localCopyBriefs = cloneDeep(this.briefs.map((obj) => ({ ...obj, active: false })))

		this.methodFilterBriefs()
	},

	methods: {
		...mapActions('@briefs', ['deleteBriefs']),

		// Select brief and emit selected ids (to delete)
		toggleSelect(id) {
			let brief = this.filteredBriefs.find((brief) => brief.id === id)
			if (brief.active === true || brief.active === false) {
				brief.active = !brief.active
			}

			let filteredBriefActive = cloneDeep(this.filteredBriefs.filter((brief) => brief.active))
			const selectedData = this.filteredBriefs.filter((brief) => brief.active).map((brief) => brief)
			let selectedIds = filteredBriefActive.map((brief) => brief.id)
			this.$emit('setSelectedIds', selectedIds)
			this.$emit('setSelectedData', selectedData)
		},

		methodFilterBriefs: debounce(function (query) {
			this.filteredBriefs = this.localCopyBriefs.filter((brief) => {
				if (query) {
					if (!brief.label) return false
					return brief.label.toLowerCase().indexOf((query || '').toLowerCase()) > -1
				}
				return true
			})
		}, 350),
	},
}
</script>

<style lang="scss">
@import '../../assets/dequorum/main';
.hover-gradient {
	position: absolute;
	top: 60%;
	width: 100%;
	height: 40%;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgb(255, 255, 255));
}
div .strong-pink {
	padding: 0px 12em;
	& strong {
		color: $pink;
	}
}
</style>
