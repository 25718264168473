import Button from './components/button'
import UiKitTinyLoader from './components/UiKitTinyLoader'
import Filter from './components/filter'
import Filters from './components/filters'
import Input from './components/input'
import Checkbox from './components/checkbox'
import Radio from './components/radios'
import RadioGroup from './components/radiogroup'
import Icon from './components/icon'
import SearchBar from './components/searchbar'
import Toggle from './components/toggle'
import Card from './components/card'
import Shimmer from './components/shimmer'
import Tag from './components/tag'
import './dequorum-font/style.css'
// import './main.sass'

export default {
	install(app) {
		app.component('Qi', Icon)
		app.component('QCard', Card)
		app.component('QInput', Input)
		app.component('QRadio', Radio)
		app.component('QButton', Button)
		app.component('QUiKitTinyLoader', UiKitTinyLoader)
		app.component('QFilter', Filter)
		app.component('QCheck', Checkbox)
		app.component('QShimmer', Shimmer)
		app.component('QFilters', Filters)
		app.component('QRadiogroup', RadioGroup)
		app.component('QSearchbar', SearchBar)
		app.component('QToggle', Toggle)
		app.component('QTag', Tag)
	},
}
