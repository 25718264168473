<template>
	<div class="w-full h-full relative">
		<router-view :version="computedVersion" />
		<div class="absolute top-10 right-14">
			<LocaleSelector />
		</div>
		<div
			v-if="computedIsVersionDisplayed"
			class="absolute bottom-5 left-0 right-0 ml-auto mr-auto w-fit"
		>
			<a
				href="http://changelog.qomon.app"
				target="_blank"
				class="text-gray-dark text-sm"
			>{{ computedGetVersionApp }}</a>
		</div>
	</div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const LocaleSelector = defineAsyncComponent(() => import('src/components/general/LocaleSelector.vue'))

const getLoginMode = computed(() => store.getters['@authentication/getLoginMode'])

const computedVersion = computed(() => RELEASE_VERSION)
const computedGetVersionApp = computed(() => {
	return `v ${computedVersion.value}`
})

const computedIsVersionDisplayed = computed(() => {
	return getLoginMode.value != 'auth0'
})

</script>

<style lang="scss" scoped>

</style>
