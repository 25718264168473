<template>
	<a class="q-filters">
		<span
			v-for="choice in choices"
			:key="choice.value"
			:class="{ active: choice.active, disabled: choice.disabled }"
			@click.prevent="handleClick(choice)"
		>
			{{ choice.label && !choice.template ? choice.label : '' }}
			<span v-if="choice.template" v-dompurify-html="choice.template"></span>
		</span>
	</a>
</template>

<script>
/* eslint-disable */
export default {
	model: {
		prop: 'model',
		event: 'toggle',
	},

	props: {
		model: {
			type: Array | String,
		},
		choices: {
			type: Array,
			required: true,
			validator(value) {
				return value.every((choice) => choice.value)
			},
		},
		unique: {
			type: Boolean,
			default: false,
		},
	},

	mounted() {
		this.choices.forEach((aChoice) => {
			if (this.unique) {
				aChoice.active = aChoice.value === this.model
			} else {
				aChoice.active = this.model.indexOf(aChoice.value) > -1
			}
		})
		this.$forceUpdate()
	},

	emits: ['toggle'],

	methods: {
		handleClick(choice) {
			if (!choice.disabled) {
				if (this.unique) {
					this.choices.forEach((aChoice) => (aChoice.active = aChoice.value === choice.value))
					this.$emit(
						'toggle',
						this.choices.filter((choice) => choice.active).map((choice) => choice.value)[0]
					)
				} else {
					choice.active = !choice.active
					this.$emit(
						'toggle',
						this.choices.filter((choice) => choice.active).map((choice) => choice.value)
					)
				}
				this.$forceUpdate()
			}
			// if (!disabled) {
			//   this.choices.map((choice, index) => {
			//     choice.active = choice.value === value;
			//   });
			//   this.$emit('toggle', value);
			// }
		},
	},
}
</script>

<style lang="css"></style>
