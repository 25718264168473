import role_management_store from '../../models/role_management_store'
import { RESET_ROLE_MANAGEMENT_STORE, SET_ROLES_LIST, SET_TEMPLATES_LIST } from './_mutation-types'
import { cloneDeep, find, orderBy, reject, remove } from 'lodash'
import { i18n } from '../../middlewares/i18nizer'
import store from '../../store/stateStore'

/**
 * @store @roleManagement
 * @namespaced
 * @description Role management related info and store module
 */

const getDefaultState = () => {
	return {
		rolesList: [],
		templatesList: [],
	}
}
const state = getDefaultState

const mutations = {
	[RESET_ROLE_MANAGEMENT_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_ROLES_LIST]: (state, payload) => {
		let clonedRolesList = cloneDeep(payload)

		clonedRolesList = orderBy(clonedRolesList, ['order'])
		state.rolesList = clonedRolesList
	},
	[SET_TEMPLATES_LIST]: (state, payload) => {
		state.templatesList = payload
	},
}

const actions = {
	actionResetRoles({ commit }) {
		commit(RESET_ROLE_MANAGEMENT_STORE)
	},

	actionRetrieveRolesList: ({ commit }) => {
		return role_management_store
			.getRoles()
			.then((res) => {
				commit(SET_ROLES_LIST, res.roles)
			})
			.catch(() => {})
	},

	actionRetrieveTemplatesList: ({ commit }) => {
		return role_management_store
			.getRolesTemplates()
			.then((res) => {
				res.roles = orderBy(res.roles, ['order'])
				// Add custom role to the template list
				res.roles.unshift({
					access: [],
					mobile: true,
					name: i18n.global.t('SETTINGS.PROFILE.ROLES.CUSTOM'),
					type: 'custom',
					color: '#E2A812',
					web: true,
				})
				commit(SET_TEMPLATES_LIST, res.roles)
			})
			.catch(() => {})
	},

	actionRetrieveLists: ({ dispatch }) => {
		dispatch('actionRetrieveRolesList')
		dispatch('actionRetrieveTemplatesList')
	},

	actionAddRole: ({ commit, state }, payload) => {
		// Remove the order in case it clone from existing role
		delete payload.order

		return role_management_store
			.addRole(payload)
			.then((res) => {
				// Add new role to the list
				let clonedRolesList = cloneDeep(state?.rolesList)

				clonedRolesList.push(res.role)
				commit(SET_ROLES_LIST, clonedRolesList)
			})
			.catch(() => {})
	},

	actionUpdateRole: ({ commit, state }, payload) => {
		return role_management_store
			.updateRole(payload)
			.then((res) => {
				// Update the role in the list
				let clonedRolesList = cloneDeep(state?.rolesList)

				remove(clonedRolesList, (role) => role.id === payload.id)
				clonedRolesList.push(res.role)
				commit(SET_ROLES_LIST, clonedRolesList)
				// Update the role of the user connected to see the changes without refresh
				let roleUserConnected = find(clonedRolesList, [
					'id',
					store.getters.userConnected?.role_data?.id,
				])

				if (roleUserConnected) {
					commit('UPDATE_LOCAL_USER_ROLE', roleUserConnected, { root: true })
				}
			})
			.catch(() => {})
	},

	actionDeleteRole: ({ commit, state }, id) => {
		return role_management_store
			.deleteRole(id)
			.then(() => {
				// Remove the role from the list
				let clonedRolesList = cloneDeep(state?.rolesList)

				remove(clonedRolesList, (role) => role?.id === id)
				commit(SET_ROLES_LIST, clonedRolesList)
			})
			.catch(() => {})
	},
}

const getters = {
	getRolesListTruncated: (state, getters, rootState) => {
		if (store?.getters?.userConnected?.role_data?.type !== 'superadmin') {
			const listWithoutAdmin = reject(state.rolesList, ['type', 'superadmin'])
			if (XCUSTOM_USER_AGENT === 'kennedy') {
				return listWithoutAdmin.filter((aRole) => aRole?.order >= rootState?.userConnected?.role_data?.order)
			}
			return listWithoutAdmin
		}

		return state.rolesList
	},

	getRolesList: (state) => {
		if (store?.getters?.userConnected?.role_data?.type !== 'superadmin') {
			return reject(state.rolesList, ['type', 'superadmin'])
		}

		return state.rolesList
	},
	getTemplatesList: (state) => state.templatesList,
}

export const roleManagementModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
