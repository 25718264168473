const prefix = 'VENDORS.VITALLY.NPS'

export default {
	account: (group = null) => {
		const isVitallyEnabled = !!window.Vitally
		if (isVitallyEnabled && group.id && group.name) {
			window.Vitally.account({
				accountId: group.id,
				traits: {
					name: group.name,
				},
			})
		}
	},
	user: (user = {}, group = null) => {
		const isVitallyEnabled = !!window.Vitally
		if (user.id && user.mail && group.id) {
			const isQomonUser = user.mail.includes('@qomon.com') || user.mail.includes('@quorum.co')
			if (isVitallyEnabled && !isQomonUser) {
				window.Vitally.user({
					userId: user.id,
					accountId: group.id,
					traits: {
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.mail,
					},
				})
			}
		}
	},

	// https://docs.vitally.io/using-vitallys-nps-surveys/installing-and-configuring-nps-surveys
	nps: (ctx, user_mail) => {
		const isVitallyEnabled = !!window.Vitally
		const isQomonUser = user_mail.includes('@qomon.com') || user_mail.includes('@quorum.co')
		if (isVitallyEnabled && !isQomonUser) {
			// eslint-disable-next-line no-undef
			let show_mode = process.env.NODE_ENV === 'production' ? 'survey' : 'show'

			window.Vitally.nps(show_mode, {
				productName: 'Qomon',
				// autoLoadSegment: true,
				primaryColor: '#FF387F',
				delay: 5000,
				npsQuestion: ctx.$t(`${prefix}.NPSQUESTION`),
				followUpTitle: ctx.$t(`${prefix}.FOLLOWUPTITLE`),
				thanksSubtitle: ctx.$t(`${prefix}.THANKSSUBTITLE`),
				followUpSubtitle: ({ _, score }) => ctx.$t(`${prefix}.FOLLOWUPSUBTITLE`, { score }),
				thanksTitle: ctx.$t(`${prefix}.THANKSTITLE`),
				minLabel: ctx.$t(`${prefix}.MINLABEL`),
				maxLabel: ctx.$t(`${prefix}.MAXLABEL`),
				placeholderText: ctx.$t(`${prefix}.PLACEHOLDERTEXT`),
				submitText: ctx.$t(`${prefix}.SUBMITTEXT`),
				dismissText: ctx.$t(`${prefix}.DISMISSTEXT`),
			})
		}
		return () => null
	},
}
