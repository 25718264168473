import { RESET_NOTIFICATION_MODULE, SET_STATE_GLOBAL_NOTIFICATION } from './_mutation-types'
import communication_store from '../../models/communication_store'

const getDefaultState = () => {
	return {
		stateGlobalNotification: 'init',
	}
}
const state = getDefaultState

const mutations = {
	[RESET_NOTIFICATION_MODULE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_STATE_GLOBAL_NOTIFICATION]: (currentState, payload) => {
		currentState.stateGlobalNotification = payload
	},
}

const actions = {
	actionSendGlobalNotification: ({ commit }, payload) => {
		commit(SET_STATE_GLOBAL_NOTIFICATION, 'loading')
		communication_store.sendGlobalNotification(payload).then(() => {
			commit(SET_STATE_GLOBAL_NOTIFICATION, 'sent')
		})
	},
	actionResetStoreNotification: ({ commit }) => {
		commit(RESET_NOTIFICATION_MODULE)
	},
}

const getters = {
	getStateGlobalNotification: (state) => state.stateGlobalNotification,
}

export const notificationStore = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
