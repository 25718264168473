import invitation_store from '../../models/invitation_store'
import { DELETE_INVITATION, REPLACE_INVITATION, SET_INVITATIONS } from './_mutation-types'
/**
 * @store @invitation
 * @namespaced
 * @description Invitations' related info and store module
 */

const state = {
	invitations: [],
	deletePending: false,
}

const mutations = {
	[SET_INVITATIONS]: (state, res) => {
		state.invitations = res
	},
	[REPLACE_INVITATION]: (state, res, index) => {
		state.invitations[index] = res
	},
	[DELETE_INVITATION]: (state, res) => {
		const index = state.invitations.indexOf(res)

		state.invitations.splice(index, 1)
	},
}

const actions = {
	actionSetInvitations: async ({ commit }, invitations) => {
		commit(SET_INVITATIONS, invitations)
	},
	actionFetchInvitations: async ({ dispatch }) => {
		const result = await invitation_store.getInvitations()
		if (result) dispatch('actionSetInvitations', result)
	},

	/**
	 * @description send emails address, x params asked.
	 * @param {Array} invitation - array of string which correspond to valid emails address
	 * @returns {Promise}
	 */
	actionSendEmailInvitation: async ({ dispatch }, invitations) => {
		await invitation_store.sendEmailInvitations(invitations).then((res) => {
			if (res.status === 200 || res.status === 201 || res.status === 'success') {
				dispatch('actionFetchInvitations')
				dispatch('SEARCH_USER', null, { root: true })
			}

			return res
		})
	},

	actionDeleteEmailInvitation: async ({ commit }, invitationToDelete) => {
		await invitation_store.deleteInvitation(invitationToDelete).then((res) => {
			if (res.status === 200) commit(DELETE_INVITATION, invitationToDelete)

			return res
		})
	},

	actionResetStateInvitations: async ({ commit }) => {
		commit(SET_INVITATIONS, [])
	},
}

const getters = {
	getInvitations: (state) => state.invitations,
	getInvitationsLength: (state) => state.invitations ? state.invitations.length : 0,
	getSendingNotification: (state) => state.sendingNotification,
}

export const invitationModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
