import { SET_GROUP_CAMPAIGN } from './_mutation-types'
import get from 'lodash/get'
import { MAP } from 'src/constants'
import sessionStore from '@/models/session_store'

/**
 * @store @search
 * @namespaced
 * @description Search' related info and store module
 */

const getDefaultState = () => {
	return {
		group: {},
	}
}

const state = getDefaultState

const mutations = {
	/**
	 * Group campaign
	 */
	[SET_GROUP_CAMPAIGN]: (currentState, payload) => {
		currentState.group = payload
	},
}

const actions = {
	actionSetGroupCampaign({ commit }, payload) {
		commit(SET_GROUP_CAMPAIGN, payload)
	},

	actionDisableBrevoSync({ commit, state }) {
		const { group } = state
		if (group) {
			group.sendinblue_apikey = ''
			group.mailjetapipublickey = ''
			group.mailjetapisecretkey = ''
		}
		commit(SET_GROUP_CAMPAIGN, group)
		sessionStore.updateMyGroup({ group }).then((newGroup) => {
			commit(SET_GROUP_CAMPAIGN, newGroup)
		})
	},

	actionEnableBrevoSync({ commit, state }, apiKey: string) {
		const { group } = state
		if (group) {
			group.sendinblue_apikey = apiKey
			group.mailjetapipublickey = 'SENDINBLUE'
			group.mailjetapisecret = apiKey
		}
		sessionStore.updateMyGroup({ group }).then((newGroup) => {
			commit(SET_GROUP_CAMPAIGN, newGroup)
		})
	},
}

const getNationBuilderNationName = (state) => {
	const { group } = state
	const nationName = get(group, 'nationbuilder_nation', '')
	return nationName
}

const getSynchronizationNationbuilder = (state) => {
	const { group } = state
	const nationbuilderApikey = get(group, 'nationbuilder_apikey', '') === 'IsSet'
	const nationBuilderNation = get(group, 'nationbuilder_nation', '')
	if (nationbuilderApikey && nationBuilderNation) return 'synchronized'
	return 'setup_needed'
}

const getSynchronizationBrevoCRM = (state) => {
	const { group } = state
	const brevoKey = get(group, 'sendinblue_apikey', '') === 'IsSet'
	if (brevoKey) return 'synchronized'
	return 'setup_needed'
}

const getSynchronizationBrevo = (state) => {
	const { group } = state
	const mailjetapipublickey = get(group, 'mailjetapipublickey', '') === 'SENDINBLUE'
	const mailjetapisecretkey = get(group, 'mailjetapisecretkey', '') === 'IsSet'
	const brevoKey = get(group, 'sendinblue_apikey', '') === 'IsSet'
	if (mailjetapipublickey && mailjetapisecretkey || brevoKey) return 'synchronized'
	return 'setup_needed'
}

const getters = {
	getIsMasterView: (state) => get(state, 'group.masterView', false),
	getIsOnlyOneURLMapProvided: (state) => {
		const { group } = state
		if (!group) return false
		const isOnlyURLMap = group.url_base_map && !group.url_satellite_map
		const isOnlyURLSatelliteMap = !group.url_base_map && group.url_satellite_map
		return Boolean(isOnlyURLMap || isOnlyURLSatelliteMap)
	},
	getURLBaseMap: (state) =>
		get(state, 'group.url_base_map') || get(state, 'group.url_satellite_map') || MAP.URL,
	getURLSatelliteMap: (state) =>
		get(state, 'group.url_satellite_map') || get(state, 'group.url_base_map') || MAP.URL_SATELLITE,
	getCauseCode: (state) => state.group.code_cause,
	getGroupLocale: (state) => state.group.locale,
	getEmailerAPIKey: (state) => state.group.mailjetapipublickey,
	getGeocodageCountry: (state) => state.group.geocodage_country,
	getNationBuilderNationName: (state) => getNationBuilderNationName(state),
	getSynchronizationNationbuilder: (state) => getSynchronizationNationbuilder(state),
	getSynchronizationBrevo: (state) => getSynchronizationBrevo(state),
	getSynchronizationBrevoCRM: (state) => getSynchronizationBrevoCRM(state),
}

export const groupModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
