/**
 * Communications-module routes
 *
 * handle communications routes
 *
 * @rootPath {/communications}
 */
import Communications from 'components/communications-module/Communication.vue'
import CommunicationsReport from 'components/communications-module/communicationsReport.vue'
import CommunicationsGeneral from 'components/communications-module/communicationsGeneral.vue'
import communicationMailCreate from 'components/communications-module/mail/bulkEmailEditor.vue'
import BulkedEmails from 'components/settings-module/bulkEmails.vue'

import { controllerPackRedirection } from './utils/routerController'

import store from '../../src/store/stateStore'

const isNavigationToBulkEmailEditorAuthorized = () => {
	const tempValue = store.getters['@temp/getTempValue']
	if (tempValue) return true
	return false
}

export default {
	meta: { title: 'MENU.COMMUNICATIONS' },
	path: '/communications',
	name: 'communications',
	component: Communications,
	redirect: { name: 'communications-general' },
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('communication:delete')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessCommunication')
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: '/communications/general',
			name: 'communications-general',
			component: CommunicationsGeneral,
		},
		{
			path: '/communications/report',
			name: 'communications-report',
			component: CommunicationsReport,
			redirect: { name: 'automatic-email-dashboard' },
			children: [
				{
					path: '/communications/automatic-email-dashboard',
					name: 'automatic-email-dashboard',
					component: () => import('components/communications-module/AutomaticEmailsDashboard.vue'),
				},
				{
					path: '/communications/sms',
					name: 'communications-sms',
					component: () => import('components/communications-module/sms/communications-sms.vue'),
					redirect: { name: 'communications-sms-dashboard' },
					children: [
						{
							path: 'dashboard',
							name: 'communications-sms-dashboard',
							component: () => import('components/communications-module/sms/SmsDashboard.vue'),
						},
						{
							path: 'analytics',
							name: 'communications-sms-analytics',
							component: () => import('components/communications-module/sms/SmsAnalytics.vue'),
						},
					],
				},
			],
		},
		{
			path: '/sms/create',
			name: 'communications-sms-create',
			component: () => import('components/communications-module/sms/SmsToSend.vue'),
		},
		{
			path: '/communications/mail-create',
			name: 'communication-mail-create',
			component: communicationMailCreate,
			beforeEnter: (to, from, next) => {
				let redirection
				if (!isNavigationToBulkEmailEditorAuthorized())
					redirection = { name: 'contacts', query: { savePolygon: true } }
				next(redirection)
			},
		},
		{
			path: '/communications/bulk-emails',
			name: 'communications-bulk-emails',
			component: BulkedEmails,
		},
	],
}
