<template>
	<div class="flex flex-col w-full">
		<div class="flex w-full sticky -top-2 z-40 bg-white menu-shadow">
			<template v-if="$route.name !== 'lead-form'">
				<div
					v-for="(form, aFormIndex) in forms"
					:key="`${aFormIndex}/${form}`"
					class="q-bar-right p-6 q-fullheight q-row"
					:class="{
						'bg-white q-header action': currentFormName !== form,
						'q-bar-left': aFormIndex === forms.length - 1,
					}"
					@click="handleTab(form)"
				>
					<div v-if="form === 'ACTION' && getAccessStatusTasks === 'enabled'">
						{{ t.formName(form) }}
					</div>
					<div v-else-if="form === 'STATUS'">
						{{ t.formName(form) }}
					</div>
				</div>
			</template>

			<div class="w-full q-header border-none py-3.5">
				<div
					:class="$route.name === 'lead-form' ? 'justify-between' : ' justify-end'"
					class="w-full mr-3 flex items-center"
				>
					<p
						v-if="$route.name === 'lead-form' && $route.query.q === 'ACTION'"
						class="text-sm justify-start px-4"
					>
						{{ t.leadWarning }}
					</p>
					<p
						v-if="$route.name === 'lead-form' && $route.query.q === 'STATUS'"
						class="text-sm justify-start px-4"
					>
						{{ t.levelOfSupportWarning }}
					</p>
					<div class="flex items-center gap-3">
						<div class="px-1.5 py-0.5 rounded-full transition-all hover:bg-gray-100">
							<el-tooltip
								:show-after="300"
								:content="isNormalFrom ? t.howToUseFormBuilder : t.howToUseTunnels"
								placement="top"
							>
								<a
									:href="helpLink"
									target="_blank"
									class=""
								>
									<i class="gui-infos text-xl" />
								</a>
							</el-tooltip>
						</div>
						<el-dropdown @command="handleCommand">
							<q-button
								class="el-dropdown-link"
								square
								ghost
							>
								{{ $t('SETTINGS.FORM_BUILDER.CHOOSE_TEMPLATE') }}
							</q-button>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item disabled>
										<div class="w-full flex justify-center py-2">
											<span class="text-sm">
												{{ isNormalFrom ? t.templates : t.defaultParam }}
											</span>
										</div>
									</el-dropdown-item>
									<el-dropdown-item
										v-for="(template, templateIndex) in computedRenderTemplatesList"
										:key="templateIndex"
										:command="template.command"
									>
										{{ template.label }}
									</el-dropdown-item>
									<el-dropdown-item
										v-if="!isNormalFrom"
										command="template_status"
										:disabled="$route.query.q !== 'STATUS'"
									>
										{{ t.template_status }}
									</el-dropdown-item>

									<template
										v-if="getSynchronizationType && getSynchronizationType.type === 'nationbuilder'"
									>
										<el-dropdown-item
											v-if="!isNormalFrom"
											command="template_nb_status"
											:disabled="$route.query.q !== 'STATUS'"
										>
											{{ t.template_nb_status }}
										</el-dropdown-item>
									</template>
									<el-dropdown-item
										v-if="!isNormalFrom"
										command="template_action"
										:disabled="$route.query.q !== 'ACTION'"
									>
										{{ t.template_action }}
									</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
						<q-button
							v-if="!saving"
							icon="share"
							:ghost="!needAFormPublish"
							:disabled="!needAFormPublish"
							@click="publishForm"
						>
							{{ isNormalFrom ? t.publish : t.save }}
						</q-button>
						<q-button
							v-else
							icon="share"
						>
							<i class="fa fa-circle-o-notch fa-spin" />
						</q-button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="flex flex-col md:flex-row flex-1 flex-grow bg-white pb-36 px-4 pt-4"
			:class="{ start: computedQuestionsGroupedBySection.length }"
		>
			<div class="flex overflow-auto flex-auto flex-col">
				<template v-if="!computedQuestionsGroupedBySection.length && !saving">
					<h2 class="center">
						{{ t.emptyForm }}
					</h2>
					<h2
						class="center action"
						@click="addSection"
					>
						<span class="qi-plus" /><span>{{ t.addSection }}</span>
					</h2>
				</template>
				<div v-else>
					<transition-group
						name="fade"
						tag="div"
					>
						<q-card
							v-for="(section, sectionIndex) in computedQuestionsGroupedBySection"
							:key="`section${sectionIndex}`"
							class="elastic pt-6"
							:pinable="false"
						>
							<template #header>
								<div class="q-row nowrap pt-6 mb-2">
									<transition name="fade">
										<div>
											<div
												v-if="!section.showQuestions"
												class="action qi-plus qi-2x"
												@click="toggleSection(section)"
											/>
											<div
												v-else
												class="action qi-less qi-2x"
												@click="toggleSection(section)"
											/>
										</div>
									</transition>
									<div class="relative w-full pr-3">
										<span class="absolute text-sm text-gray-400 -top-5">{{ t.sectionName }}</span>
										<el-input
											v-model="section.name"
											class="q-m-lg-r"
											@input="() => updateForm(null, true)"
										/>
									</div>

									<div
										v-if="!isUniqueStatus"
										class="q-filter qi-arrow-up"
										:class="{ disabled: sectionIndex === 0 }"
										@click="sectionUp(sectionIndex)"
									/>
									<div class="q-h-spacer-sm" />
									<div
										v-if="!isUniqueStatus"
										class="q-filter qi-arrow-down"
										:class="{ disabled: sectionIndex === computedQuestionsGroupedBySection.length - 1 }"
										@click="sectionDown(sectionIndex)"
									/>
									<div class="q-h-spacer-sm" />
									<div
										v-if="!isUniqueStatus"
										class="action danger qi-close qi-2x"
										@click="removeSection(sectionIndex)"
									/>
								</div>
							</template>
							<template
								v-if="section.showQuestions"
								#content
							>
								<div class="q-fullwidth">
									<draggable
										v-model="section.questions"
										item-key="i"
										handle=".qi-order"
										:group="`nested.${sectionIndex}`"
										@end="onEnd"
									>
										<template #item="{ element, index }">
											<question-card
												:question="element"
												:question-index="index"
												:section-index="sectionIndex"
												:forms="currentForm"
												:current-form-name-from-route="getTheCurrentFormStrFromRoute()"
												:is-unique-status="isUniqueStatus"
												:status-forms-type="statusFormsType"
												@focus="displayWarning"
												@update="updateForm"
												@conditionnalize="makeQuestionConditionnal($event, element)"
												@conditionnalize-ref="makeRefConditionnal"
												@remove="removeQuestion"
												@handle-need-a-publish="handleNeedAPublish"
												@auto-send-toggle="autoSendToggle($event)"
												@auto-send-sms-toggle="autoSendSmsToggle($event)"
											/>
										</template>
									</draggable>
									<div class="q-fullwidth q-p center">
										<q-button
											v-if="!isUniqueStatus || section.questions.length === 0"
											class="q-is-info"
											icon="plus"
											link
											@click="addQuestion(sectionIndex)"
										>
											{{ t.addQuestion }}
										</q-button>
									</div>
								</div>
							</template>
						</q-card>
					</transition-group>
					<div
						v-if="!saving"
						class="q-fullwidth q-p"
					>
						<q-button
							v-if="isNormalFrom"
							class="q-fullwidth"
							icon="plus"
							ghost
							@click="addSection"
						>
							{{ t.addSection }}
						</q-button>
					</div>
				</div>
			</div>
			<div
				v-if="isNormalFrom"
				class="flex justify-center"
			>
				<sidebar :forms="currentForm" />
			</div>
		</div>

		<modal
			:is-active="isAddingCond"
			:reset-action="resetCondData"
			small
		>
			<template #content>
				<div class="q-p-lg bg-white q-m-lg center">
					<template v-if="saving || (!condQuestion && !condRef)">
						<loader size="25%" />
					</template>
					<template v-else>
						<div class="q-grid">
							<h3 class="q-col-4">
								{{ condQuestion ? t.modal.showQuestion : t.modal.showAnswer }}
							</h3>
							<div class="q-col-8 q-is-info q-border-info q-p-sm">
								{{ condQuestion ? condQuestion.label : condRef.label }}
							</div>
						</div>
						<div class="q-grid">
							<h3 class="q-col-4">
								{{ t.modal.onlyIf }}
							</h3>
							<el-select
								v-model="condReferent"
								class="q-col-8"
								size="small"
								value-key="label"
								@change="condFilterId = null"
							>
								<el-option-group
									v-for="section in computedQuestionsGroupedBySection"
									:key="section.name"
									:label="methodEllipsizeText(section.name)"
								>
									<!--
                                    New question hasn't correct id fixed by the back
                                    so the link with new questions is disabled below.
                                    A new question has a temporary non-integer id which is deleted
                                    before the form is published.
                                -->

									<template
										v-for="(question, index) in questionsOf(section)"
										:key="index"
									>
										<el-option
											:value="question"
											:label="methodEllipsizeText(question.label)"
											:disabled="!Number.isInteger(question.id)"
										>
											<template #default>
												<el-tooltip
													v-if="!Number.isInteger(question.id)"
													:content="$t('SETTINGS.FORM_BUILDER.NEED_PUBLISH_TOOLTIP')"
													placement="top"
												>
													<span>{{ methodEllipsizeText(question.label) }}</span>
												</el-tooltip>
												<span v-else>{{ methodEllipsizeText(question.label) }}</span>  <!-- Pas de tooltip si activé -->
											</template>
										</el-option>
									</template>
								</el-option-group>
							</el-select>
						</div>
						<div>
							<transition name="fade">
								<div
									v-if="condReferent"
									class="q-grid"
								>
									<h3 class="q-col-4">
										{{ t.modal.didAnswer }}
									</h3>
									<el-select
										v-model="condFilterId"
										class="q-col-8"
										size="small"
									>
										<el-option
											v-for="ref in condReferent.refvalues"
											:key="ref.id"
											:value="ref.id"
											:label="methodEllipsizeText(ref.label)"
										/>
									</el-select>
								</div>
							</transition>
						</div>
						<div class="q-v-spacer-l" />
						<div class="q-row space-between">
							<div
								v-if="condQuestion ? !condQuestion.filterbyidrefvalue : !condRef.filterbyidrefvalue"
								class="expander"
							/>
							<q-button
								v-else
								ghost
								square
								icon="trash"
								@click="deleteCond"
							>
								{{ t.modal.deleteLink }}
							</q-button>
							<q-button
								success
								square
								icon="check"
								@click="confirmCond"
							>
								{{
									t.modal.confirm
								}}
							</q-button>
						</div>
					</template>
				</div>
			</template>
		</modal>
		<auto-send-popover
			v-if="showAutoSendPopover"
			class="auto-send-popover-container no-print"
			:indices="autoSendIndices"
			:sections="computedQuestionsGroupedBySection"
			@close-popover="updateAndClose"
		/>

		<auto-send-sms-dialog
			v-model:show-auto-send-sms-dialog="computedShowAutoSendSmsDialog"
			v-model:sections="computedQuestionsGroupedBySection"
			:indices="autoSendIndices"
			:update-and-close="updateAndClose"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'
import { firstBy } from 'thenby'
import Sidebar from './form-builder/sidebar.vue'
import Loader from 'components/general/loader.vue'
import Modal from 'components/general/modal'
import AutoSendPopover from 'src/components/form-module/auto-send/auto-send-popover.vue'
import QuestionCard from './form-builder/question-card.vue'

import { templatesDictionnary } from './form-builder/templatesDictionnary'

import template_pap_politic_fr from './form-builder/template-pap-politic-fr.json'
import template_pap_politic_en from './form-builder/template-pap-politic-en.json'

import { v4 as uuidv4 } from 'uuid'
import { defineAsyncComponent } from 'vue'

/**
 * @component MultiFormBuilder
 * @route /settings/lead-form
 * @route /settings/form
 */
export default {
	components: {
		draggable,
		QuestionCard,
		Loader,
		Modal,
		Sidebar,
		AutoSendPopover,
		autoSendSmsDialog: defineAsyncComponent(
			() => import('../form-module/auto-send/autoSendSmsDialog.vue'),
		),
	},

	beforeRouteUpdate(to, from, next) {
		this.displayLeavePageMessageBox(to, from, next)
	},

	beforeRouteLeave(to, from, next) {
		// called before the route that renders this component is confirmed.
		// does NOT have access to `this` component instance,
		// because it has not been created yet when this guard is called!
		this.displayLeavePageMessageBox(to, from, next)
	},
	emits: ['update-cond-pin'],
	data() {
		return {
			formIndex: 0,
			saving: false,
			isAddingCond: false,
			condQuestion: null,
			condReferent: null,
			condFilterId: null,
			firstTimeWarningMeaning: true,

			condRef: null,
			questionsGroupedBySection: [],
			currentForm: [],
			// Is static for now but TODO : call a route to get all existing forms as tabs
			currentFormName: '',
			// auto send popover
			showAutoSendPopover: false,
			showAutoSendSmsDialog: false,
			autoSendIndices: {},
			showWarningOnMeaningLabel: false,
			isAlreadyPreventForDefaultTemplate: false,
		}
	},

	computed: {
		...mapGetters('@form', [
			'form',
			'formCond',
			'status',
			'action',
			'needAFormPublish',
			'backupFormName',
			'isFormModified',
		]),

		...mapGetters(['userConnected']),
		...mapGetters(['sections', 'getSynchronizationType']),
		...mapGetters('@packs', [
			'getStatusNumberStatusForms',
			'getStatusTypeStatusForms',
			'getAccessStatusTasks',
		]),
		...mapGetters('@group', ['getGroupLocale']),

		computedShowAutoSendSmsDialog: {
			get() {
				return this.showAutoSendSmsDialog
			},
			set(value) {
				this.showAutoSendSmsDialog = value
			},
		},

		t() {
			const prefix = 'SETTINGS.FORM_BUILDER'
			return {
				min: this.$t(`${prefix}.MIN`),
				max: this.$t(`${prefix}.MAX`),
				save: this.$t('_COMMON.SAVE'),
				step: this.$t(`${prefix}.STEP`),
				answer: this.$t(`${prefix}.ANSWER`),
				success: this.isNormalFrom
					? this.$t(`${prefix}.SUCCESS`)
					: this.$t(`${prefix}.SUCCESS_PARAM`),
				successParams: this.$t(`${prefix}.SUCCESS_PARAM`),
				publish: this.$t(`${prefix}.PUBLISH`),
				warningMeaning: this.$t(`${prefix}.WARNING_MEANING_LABEL`),
				warningMeaningSettings: this.$t(`${prefix}.WARNING_MEANING_LABEL_SETTINGS`),
				iUnderstandLabel: this.$t('_COMMON.I_UNDERSTAND'),
				question: this.$t(`${prefix}.QUESTION`),
				emptyForm: this.$t(`${prefix}.EMPTY_FORM`),
				addSection: this.$t(`${prefix}.ADD_SECTION`),
				addQuestion: this.isNormalFrom
					? this.$t(`${prefix}.ADD_QUESTION`)
					: this.$t(`${prefix}.ADD_FIELD`),
				leadWarning: this.$t(`${prefix}.LEAD_WARNING`),
				levelOfSupportWarning: this.$t(`${prefix}.LEVEL_OF_SUPPORT_WARNING`),
				sectionName: this.$t(`${prefix}.SECTION_NAME`),
				mustBeNumber: this.$t(`${prefix}.MUST_BE_NUMBER`),
				seeQuestions: this.$t(`${prefix}.SEE_QUESTIONS`),
				datePlaceholder: this.$t(`${prefix}.DATE_PLACEHOLDER`),

				print: this.$t(`${prefix}.PRINT`),
				defaultFormTitle: this.$t(`${prefix}.USE_DEFAULT_FORM_TITLE`),
				defaultFormWarning: this.$t(`${prefix}.USE_DEFAULT_FORM_WARNING`),
				templates: this.$t(`${prefix}.TEMPLATES`),
				template1_fr: this.$t(`${prefix}.TEMPLATE1`),
				template2_fr: this.$t(`${prefix}.TEMPLATE2`),
				template1_en: this.$t(`${prefix}.TEMPLATE1`),
				template2_en: this.$t(`${prefix}.TEMPLATE2`),
				template_pap_politic_fr: this.$t(`${prefix}.TEMPLATE_PAP_POLITIC`),
				template_pap_politic_en: this.$t(`${prefix}.TEMPLATE_PAP_POLITIC`),
				template_town_fr: this.$t(`${prefix}.TEMPLATE_TOWN`),
				template_town_en: this.$t(`${prefix}.TEMPLATE_TOWN`),
				template_democracy_participative_fr: this.$t(`${prefix}.TEMPLATE_DEMOCRACY`),
				template_democracy_participative_en: this.$t(`${prefix}.TEMPLATE_DEMOCRACY`),
				template_mediation_fr: this.$t(`${prefix}.TEMPLATE_MEDIATION`),
				template_mediation_en: this.$t(`${prefix}.TEMPLATE_MEDIATION`),
				template_action: this.$t(`${prefix}.TEMPLATE_ACTION`),
				template_status: this.$t(`${prefix}.TEMPLATE_STATUS`),
				template_nb_status: this.$t(`${prefix}.TEMPLATE_NB_STATUS`),
				defaultParam: this.$t(`${prefix}.DEFAULT_PARAM`),
				howToUseFormBuilder: this.$t(`${prefix}.HOW_TO_USE_FORM_BUILDER`),
				howToUseTunnels: this.$t(`${prefix}.HOW_TO_USE_TUNNELS`),

				modal: {
					confirm: this.$t('_COMMON.CONFIRM'),

					onlyIf: this.$t(`${prefix}.MODAL.ONLY_IF`),
					didAnswer: this.$t(`${prefix}.MODAL.DID_ANSWER`),
					deleteLink: this.$t(`${prefix}.MODAL.DELETE_LINK`),
					showAnswer: this.$t(`${prefix}.MODAL.SHOW_ANSWER`),
					showQuestion: this.$t(`${prefix}.MODAL.SHOW_QUESTION`),
				},
				leave: this.$t('_COMMON.LEAVE'),
				stayOnPage: this.$t('_COMMON.STAY_ON_PAGE'),
				leaveThePageTitle: this.$t(`${prefix}.LEAVE_THE_PAGE_TITLE`),
				leaveThePageTitleSettings: this.$t(`${prefix}.LEAVE_THE_PAGE_TITLE_SETTINGS`),
				leaveThePageMessage: this.$t(`${prefix}.LEAVE_THE_PAGE_MESSAGE`),

				formName: (name) =>
					this.$te(`${prefix}.NAMES.${name}`) ? this.$t(`${prefix}.NAMES.${name}`) : name,
				questionType: (type) =>
					this.$t(`${prefix}.QUESTIONS_TYPE.${type.toString().toUpperCase()}`),
			}
		},

		isNormalFrom() {
			return this.$route.name !== 'lead-form'
		},

		helpLink() {
			return this.isNormalFrom
				? 'http://help.qomon.com/articles/3095867'
				: this.$route.query.q.includes('STATUS')
					? `http://help.qomon.com/${this.$i18n.locale}/articles/3203400`
					: `https://help.qomon.com/${this.$i18n.locale}/articles/3271524`
		},

		forms() {
			if (this.$route.name === 'survey-builder') {
				return []
			}
			const allForms = []
			allForms.push('STATUS')
			if (this.getAccessStatusTasks === 'enabled') allForms.push('ACTION')
			return allForms
		},

		isUniqueStatus() {
			return (
				this.getTheCurrentFormStrFromRoute() === 'status'
				&& this.getStatusNumberStatusForms === 'unique'
			)
		},

		statusFormsType() {
			return this.getTheCurrentFormStrFromRoute() === 'status'
				? this.getStatusTypeStatusForms
					? this.getStatusTypeStatusForms
					: 'all'
				: 'all'
		},

		computedQuestionsGroupedBySection: {
			get() {
				return this.questionsGroupedBySection
			},
			set(value) {
				this.questionsGroupedBySection = value
			},
		},

		computedRenderTemplatesList() {
			const groupLocale = this.getGroupLocale
			const locale = groupLocale === 'fr' || groupLocale === 'en' ? groupLocale : 'en'
			const templateNormalArray = [
				{
					label: this.t[`template_pap_politic_${locale}`],
					command: 'template_pap_politic',
				},
				{
					label: this.t[`template_town_${locale}`],
					command: 'template_town',
				},
				{
					label: this.t[`template_democracy_participative_${locale}`],
					command: 'template_democracy_participative',
				},
				{
					label: this.t[`template_mediation_${locale}`],
					command: 'template_mediation',
				},
			]
			if (this.isNormalFrom) {
				return templateNormalArray
			}
			else return []
		},
		computedRenderTemplatesDictionnary() {
			// const locale = this.$i18n.locale
			const locale = this.getGroupLocale
			return templatesDictionnary(locale)
		},
	},

	watch: {
		$route: {
			handler() {
				if (this.$route.query.q) {
					this.handleTab(this.$route.query.q)
				}
				else {
					this.handleTab(this.forms[0])
				}
			},
			immediate: true,
		},
	},

	async mounted() {
		this.saving = true
		/**
		 * For the moment remove the if to refresh the form
		 * on the mounted
		 */
		// if (!this.form.length) {
		await this.getForms()
		// }
		this.getNewForm()
		if (this.$route.query.q) {
			this.handleTab(this.$route.query.q)
		}
		else {
			this.handleTab(this.forms[0])
		}

		this.methodCleanEmptySections()
		this.saving = false
		this.changeTheModeOfThePublish(false)
	},

	methods: {

		...mapActions(['actionSetSections']),

		...mapActions('@form', [
			'getForms',
			'getStatusForms',
			'saveForms',
			'changeTheModeOfThePublish',
			'actionSetForm',
			'getActionForms',
		]),

		/**
		 * @method printForm
		 */
		printForm() {
			window.print()
		},

		methodCleanEmptySections() {
			if (!this.sections || (this.sections && this.sections.length === 0)) {
				const clonedQuestions = cloneDeep(this.computedQuestionsGroupedBySection)
				const newSections = clonedQuestions.filter((section) => section.questions.length > 0)
				this.actionSetSections(newSections)
				this.updateForm(null, true)
			}
		},

		displayLeavePageMessageBox(to, from, next) {
			if (this.needAFormPublish) {
				this.$msgbox({
					title:
						this.getTheCurrentFormStrFromRoute() === 'form'
							? this.t.leaveThePageTitle
							: this.t.leaveThePageTitleSettings,
					message: this.t.leaveThePageMessage,
					distinguishCancelAndClose: true,
					confirmButtonText: this.t.leave,
					showCancelButton: true,
					customClass: 'custom_message_box',
					cancelButtonText: this.t.stayOnPage,
				})
					.then(async () => {
						switch (from.name) {
							case 'form':
								this.getForms()
								break

							case 'lead-form':
								if (from?.query?.q && from?.query?.q === 'STATUS') {
									this.getStatusForms()
								}
								if (from?.query?.q && from?.query?.q === 'ACTION') {
									this.getActionForms()
								}
								break

							default:
								break
						}

						this.changeTheModeOfThePublish(false)
						this.firstTimeWarningMeaning = true
						next()
					})
					.catch(() => {
						next(false)
					})
			}
			else {
				next()
			}
		},
		displayWarning(value) {
			if (this.firstTimeWarningMeaning) {
				if (value === true) {
					this.firstTimeWarningMeaning = false
					this.$msgbox({
						customClass: 'custom_message_box form-el-message',
						message:
							this.getTheCurrentFormStrFromRoute() === 'form'
								? this.t.warningMeaning
								: this.t.warningMeaningSettings,
						dangerouslyUseHTMLString: true,
						duration: 0,
						showClose: true,
						confirmButtonText: this.t.iUnderstandLabel,
					}).catch(() => null)
				}
			}
		},

		handleNeedAPublish(value) {
			if (this.firstTimeWarningMeaning) {
				this.displayWarning(value)
			}
			this.changeTheModeOfThePublish(value)
		},

		handleTab(form) {
			if (this.$route.name === 'survey-builder') {
				this.currentForm = cloneDeep(this.form.concat(this.formCond).sort(firstBy('order')))
				this.currentFormName = 'FORM'
			}
			else {
				this.currentFormName = form
				this.currentForm = cloneDeep(this[form.toLowerCase()]).sort(firstBy('order'))
			}
			if (this.$route.query.q !== this.currentFormName) {
				this.$router.replace({
					...this.$route,
					query: {
						...this.$route.query,
						q: this.currentFormName,
					},
				})
			}
			this.getNewForm()
		},

		handleCommand(command) {
			if (!command) return
			if (this.isAlreadyPreventForDefaultTemplate) {
				this.methodChooseTemplate(command)
				return
			}
			this.$msgbox({
				title: this.t.defaultFormTitle,
				customClass: 'custom_message_box',
				message: this.t.defaultFormWarning,
				dangerouslyUseHTMLString: true,
				distinguishCancelAndClose: true,
				confirmButtonText: this.t.delete,
				showCancelButton: true,
				cancelButtonText: this.t.cancel,
			})
				.then(() => {
					this.methodChooseTemplate(command)
					this.isAlreadyPreventForDefaultTemplate = true
				})
				.catch(() => {})
		},

		methodChooseTemplate(command) {
			let workingTemplate
			// const locale = this.$i18n.locale
			const groupLocale = this.getGroupLocale
			const locale = groupLocale === 'fr' || groupLocale === 'en' ? groupLocale : 'en'
			switch (command) {
				case 'template1':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template1_${locale}`],
					)
					break
				case 'template2':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template2_${locale}`],
					)
					break
				case 'template_pap_politic':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_pap_politic_${locale}`],
					)
					break
				case 'template_town':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_town_${locale}`],
					)
					break
				case 'template_democracy_participative':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_democracy_participative_${locale}`],
					)
					break
				case 'template_mediation':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_mediation_${locale}`],
					)
					break
				case 'template_action':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_action_${locale}`],
					)
					break
				case 'template_status':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_status_${locale}`],
					)
					break
				case 'template_nb_status':
					workingTemplate = cloneDeep(
						this.computedRenderTemplatesDictionnary[`template_nb_status_${locale}`],
					)
					break

				default:
					workingTemplate
						= this.$i18n.locale === 'fr' ? template_pap_politic_fr : template_pap_politic_en
					break
			}

			this.actionSetForm({
				name: this.currentFormName.toLowerCase(),
				value: workingTemplate,
			})
			this.currentForm = cloneDeep(workingTemplate)
			this.getNewForm()
			this.changeTheModeOfThePublish(true)
			this.$forceUpdate()
		},

		/**
		 * assure immutability of data
		 * @method getNewForm
		 */
		getNewForm() {
			this.formIndex = 0
			const sections = [
				...new Set(
					cloneDeep(this.currentForm)
						.sort(firstBy('order'))
						.map((f) => f.section),
				),
			]
			const oldSections = this.computedQuestionsGroupedBySection.map((section) => {
				return {
					id: section.id,
					showQuestions: section.showQuestions,
				}
			})
			this.computedQuestionsGroupedBySection = sections.map((section, index) => {
				const oldShowQuestionsState = oldSections.find((s) => s.id === index)
				return {
					id: index,
					name: section,
					showQuestions: oldShowQuestionsState ? oldShowQuestionsState.showQuestions : true,
					questions: cloneDeep(this.currentForm)
						.filter((f) => f.section === section)
						.sort(firstBy('order')),
				}
			})
		},

		onEnd() {
			this.reorderQuestionsGroupedBySections(false)
			this.updateForm(null, true)
		},

		/**
		 * commit the last version of the questions
		 */
		updateForm(data = null, updateCurrent = false) {
			const questions = this.flattenForm(data || cloneDeep(this.computedQuestionsGroupedBySection), false)
			// this.actionSetForm({
			//  name: this.currentFormName.toLowerCase(),
			//  value: questions,
			// })
			if (updateCurrent) {
				this.currentForm = cloneDeep(questions)
			}

			this.setThePublishModeEvaluation()
		},

		sectionUp(index) {
			if (index === 0) {
				return
			}
			this.sectionMove(index, -1)
		},
		sectionDown(index) {
			if (index === this.computedQuestionsGroupedBySection.length - 1) {
				return
			}
			this.sectionMove(index, 1)
		},

		sectionMove(index, offset) {
			const section = cloneDeep(this.computedQuestionsGroupedBySection.splice(index, 1))
			this.computedQuestionsGroupedBySection = cloneDeep(this.computedQuestionsGroupedBySection.splice(index + offset, 0, ...section))

			/**
			 * The following line is really important
			 * to prevent the form to not be ordered
			 * correctly.
			 */
			this.reorderQuestionsGroupedBySections()
			this.updateForm()
		},

		flattenForm(form, removeEmptyLabels = false) {
			const formLocal = cloneDeep(form)

			let globalOrder = 0

			return formLocal.flatMap((section) => {
				return section?.questions?.map((q) => {
					if (q.type === 'range') {
						q.refvalues[0].min = Number(q.refvalues[0].min)
						q.refvalues[0].max = Number(q.refvalues[0].max)
						q.refvalues[0].step = Number(q.refvalues[0].step)
					}
					else if (q.type === 'checkbox' || q.type === 'radio') {
						if (q.refvalues) {
							q.refvalues = q.refvalues
								.filter((ref) => (removeEmptyLabels ? !!ref.label : true))
								.map((ref) => {
									if (!ref.id) {
										ref.value = ref.label ? ref.label.toLowerCase().replace(/ /g, '_') : null
									}
									return ref
								})
						}
					}
					globalOrder++
					return {
						...q,
						name: this.currentFormName,
						order: globalOrder,
						section: section.name,
						value: q.label ? q.label.toLowerCase().replace(/ |"'`/g, '_') : null,
						group_id: this.userConnected.selected_group_id,
					}
				}) || []
			})

			// .sort(firstBy('order'))
		},

		/**
		 * @method toggleSection
		 * @param {Object} section
		 * @param {Boolean} section.showQuestions the value to toggle
		 * @param {Boolean} [force] force the set value. If omitted, boolean switch
		 */
		toggleSection(section, force) {
			section.showQuestions = force !== undefined ? force : !section.showQuestions
			this.$forceUpdate()
		},

		/**
		 * @method addSection
		 * @desc push new empty section to the form-builder
		 */
		addSection() {
			const cpySection = cloneDeep(this.computedQuestionsGroupedBySection)
			cpySection.push({
				id: cpySection.length,
				name: `Section #${cpySection.length + 1}`,
				showQuestions: true,
				questions: [],
			})

			this.computedQuestionsGroupedBySection = cpySection
			// this.questionsGroupedBySection.push({
			// 	id: this.questionsGroupedBySection.length,
			// 	name: `Section #${this.questionsGroupedBySection.length + 1}`,
			// 	showQuestions: true,
			// 	questions: [],
			// })
			this.$forceUpdate()
		},

		/**
		 * @method removeSection
		 * @desc delete one section
		 * @param {Number} index the section's index to remove
		 */
		removeSection(index) {
			this.computedQuestionsGroupedBySection.splice(index, 1)

			this.updateForm(null, true)
		},

		/**
		 * @method addQuestion
		 * @desc push new text question to the form-builder
		 * @param {Number} sectionIndex the index of the section to push new question into
		 */
		addQuestion(sectionIndex) {
			const sections = cloneDeep(this.computedQuestionsGroupedBySection)

			const getOrder = (index) => {
				let result = 0
				for (let i = 0; i <= index; i++) {
					result += sections[i].questions.length
				}
				return result + 1
			}
			const order = getOrder(sectionIndex)

			this.reorderQuestionsGroupedBySections()

			const newQuestion = {
				label: `Question #${sectionIndex + 1}.${sections[sectionIndex].questions.length + 1}`,
				type: 'text',
				name: this.currentFormName,
				order,
				section: `${sections[sectionIndex].name}`,
				refvalues: [{}],
				id: uuidv4(),
			}

			sections[sectionIndex].questions.push(newQuestion)

			this.computedQuestionsGroupedBySection = sections

			this.updateForm(null, true)
		},

		/**
		 * @method removeQuestion
		 * @desc delete one question of a given section
		 * @param {Number} sectionIndex the index of the section where we'll find the question
		 * @param {Number} index the question's index to remove
		 */
		removeQuestion(sectionIndex, index) {
			this.computedQuestionsGroupedBySection[sectionIndex].questions.splice(index, 1)
			// this.reorderQuestionsGroupedBySections()
			this.updateForm(null, true)
		},

		questionsOf(section) {
			const quest = section.questions
				.filter((question) => question.type === 'checkbox' || question.type === 'radio')
				.filter((question) =>
					this.condQuestion
						? question.id !== this.condQuestion.id
						: question.id !== this.condRef.form_id,
				)
				.filter((question) => {
					if (this.condQuestion) {
						return question.order < this.condQuestion.order
					}
					else if (this.condRef) {
						const currentQuestion = cloneDeep(
							this.currentForm.find(
								(currentQuestion) => currentQuestion.id === this.condRef.form_id,
							),
						)
						if (currentQuestion) {
							return question.order < currentQuestion.order
						}
						return true
					}
					return true
				})
			// .sort(firstBy('order'))
			return quest
		},

		getCondData(question) {
			if (question.filterbyidrefvalue) {
				this.computedQuestionsGroupedBySection.forEach((section) => {
					section.questions.forEach((aQuestion) => {
						if (aQuestion.refvalues) {
							if (aQuestion.refvalues.some((ref) => ref.id === question.filterbyidrefvalue)) {
								this.condReferent = aQuestion
							}
						}
					})
				})
				this.condFilterId = question.filterbyidrefvalue
			}
		},

		resetCondData() {
			this.condQuestion = null
			this.condReferent = null
			this.condFilterId = null
			this.isAddingCond = false
			this.condRef = null
		},

		async makeQuestionConditionnal(event, question = null) {
			this.isAddingCond = !this.isAddingCond
			if (this.isAddingCond) {
				this.condQuestion = question
				this.getCondData(question)
			}
		},

		async makeRefConditionnal(refvalue) {
			this.isAddingCond = !this.isAddingCond
			if (this.isAddingCond) {
				this.condRef = refvalue
				this.getCondData(refvalue)
			}
		},

		confirmCond() {
			if (this.condQuestion) {
				const question = this.currentForm.find((q) => q.id === this.condQuestion.id)
				if (question) {
					question.filterbyidrefvalue = this.condFilterId
				}
				this.computedQuestionsGroupedBySection.forEach((section) => {
					const question = section.questions.find((q) => q.id === this.condQuestion.id)
					if (question) {
						question.filterbyidrefvalue = this.condFilterId
					}
				})
			}
			if (this.condRef) {
				const ref = this.currentForm
					.flatMap((q) => q.refvalues)
					.find((ref) => ref.id === this.condRef.id)
				if (ref) {
					ref.filterbyidrefvalue = this.condFilterId
				}
				this.computedQuestionsGroupedBySection.forEach((section) => {
					section.questions.forEach((question) => {
						const ref = question.refvalues.find((ref) => ref.id === this.condRef.id)
						if (ref) {
							ref.filterbyidrefvalue = this.condFilterId
						}
					})
				})
			}
			this.resetCondData()
			this.updateForm()
			this.$emitter.emit('update-cond-pin')
		},

		deleteCond() {
			if (this.condQuestion) {
				const question = this.currentForm.find((q) => q.id === this.condQuestion.id)
				if (question) {
					question.filterbyidrefvalue = null
				}
				this.computedQuestionsGroupedBySection.forEach((section) => {
					const question = section.questions.find((q) => q.id === this.condQuestion.id)
					if (question) {
						question.filterbyidrefvalue = null
					}
				})
			}
			if (this.condRef) {
				const ref = this.currentForm
					.flatMap((q) => q.refvalues)
					.find((ref) => ref.id === this.condRef.id)
				if (ref) {
					ref.filterbyidrefvalue = null
				}
				this.computedQuestionsGroupedBySection.forEach((section) => {
					section.questions.forEach((question) => {
						const ref = question.refvalues.find((ref) => ref.id === this.condRef.id)
						if (ref) {
							ref.filterbyidrefvalue = null
						}
					})
				})
			}
			this.resetCondData()
			this.updateForm()
			this.$emitter.emit('update-cond-pin')
		},

		/**
		 * @method publishForm
		 * @async
		 */
		async publishForm(withMessage = true, successParams) {
			await this.getForms()
			if (!this.saving) {
				this.reorderQuestionsGroupedBySections()
				const questionsFlattenWithRemovingEmptyLabels = this.flattenForm(
					this.computedQuestionsGroupedBySection,
					true,
				)
				questionsFlattenWithRemovingEmptyLabels.forEach((question) => {
					if (question.id && !question.CreatedAt) delete question.id
				})

				this.saving = true
				try {
					const res = await this.saveForms({
						names: this.currentFormName,
						forms: questionsFlattenWithRemovingEmptyLabels,
					})
					if (res.forms) {
						this.actionSetForm({
							name: this.currentFormName.toLowerCase(),
							value: res.forms,
							backupForm: true,
						})
						this.currentForm = cloneDeep(res.forms)
						this.updateForm()
					}

					if (withMessage) {
						this.$message({
							type: 'success',
							message: successParams ? this.t.successParams : this.t.success,
							showClose: true,
						})
					}
					this.getNewForm()
					this.methodCleanEmptySections()
				}
				catch (error) {
					// Error should be catched by interceptor
				}
				finally {
					this.saving = false
				}
			}
			this.$forceUpdate()
		},
		autoSendToggle: function (event) {
			// let question = this.sections[data.sectionIndex].questions[
			//  data.questionIndex
			// ]
			this.autoSendIndices = event.detail
			this.showAutoSendPopover = true
			this.updateForm(null, true)
		},
		autoSendSmsToggle: function (event) {
			// let question = this.sections[data.sectionIndex].questions[
			// 	data.questionIndex
			// ]
			this.autoSendIndices = event.detail
			this.showAutoSendSmsDialog = true
			this.updateForm(null, true)
		},

		async updateAndClose(sections) {
			this.showAutoSendPopover = false

			this.updateForm(sections, true)
		},

		/**
		 * @method getTheCurrentFormStrFromRoute
		 * @desc give the right value of the current form
		 * needed to get the form and identify it
		 */
		getTheCurrentFormStrFromRoute() {
			if (this.$route.name === 'form') {
				return 'form'
			}
			else if (this.$route.name === 'lead-form' && this.$route.query.q === 'STATUS') {
				return 'status'
			}
			else if (this.$route.name === 'lead-form' && this.$route.query.q === 'ACTION') {
				return 'action'
			}
			return 'form'
		},

		/**
		 * @method setThePublishModeEvaluation
		 * @desc depending of the form and route, set a boolean
		 * to notice if the user need to publish or not
		 */
		setThePublishModeEvaluation() {
			const backupFormName = this.backupFormName(this.getTheCurrentFormStrFromRoute())
			this.changeTheModeOfThePublish(this.isFormModified(this.currentForm, backupFormName))
		},

		/**
		 * @method reorderQuestionsGroupedBySections
		 * @desc reorder the questions of the form
		 */
		reorderQuestionsGroupedBySections(reSortBefore = false) {
			/**
			 * The following code was added to reorder correctly the questions
			 * with the changes on sections
			 */
			let orderCount = 1
			this.computedQuestionsGroupedBySection.forEach((aSection) => {
				if (aSection.questions && Array.isArray(aSection.questions) && aSection.questions.length) {
					if (reSortBefore) {
						aSection.questions.sort(firstBy('order'))
					}
					aSection.questions.forEach((aQuestion) => {
						aQuestion.order = orderCount
						orderCount += 1
					})
					if (reSortBefore) this.$forceUpdate()
				}
			})
		},

		methodEllipsizeText(text) {
			if (!text) return ''
			if (text.length < 90) return text
			else {
				let indexWhiteSpace = text.substring(70).indexOf(' ')
				if (indexWhiteSpace !== -1 && indexWhiteSpace < 20) {
					return `${text.substring(0, 70 + indexWhiteSpace)}...`
				}
				else {
					return `${text.substring(0, 90)}...`
				}
			}
		},
	},
}
</script>

<style scoped lang="sass">
.menu-shadow
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px
</style>

<style lang="sass" scoped>
#warning
    padding-bottom: 4px !important
    margin-right: 2em
    width: 500px

.mobile-check
    width: calc(50% - 12px)
    height: 5em
    margin: 5px
    box-sizing: border-box
.el-message-box.form-el-message
    width: 600px !important
    padding-top: 2%
@media only print
    #actual-form
        overflow: visible
</style>
