// eslint-disable-next-line no-warning-comments

import commandStore from '../../../models/command_store'
import get from 'lodash/get'
import multiGroupBy from '../../../utils/multiGroupBy'
import { DisplayedTurfsInfo } from '@/components/command-center-module/utils/GeoContext'

class TreeDisplayerData {
	#preparedData

	/**
	 * FIXME a lot of logic is here, should be done in the backend.
	 *
	 * @param scaleDataToDisplay
	 * @param {Array.<Object>} catalogue
	 * @param architecture
	 */
	constructor({ scaleDataToDisplay, catalogue, architecture }) {
		/**
		 * @type {Map.<String, Map.<String, Object>>}
		 */
		const catalogueByDatasetAndVariable = multiGroupBy(
			catalogue,
			['dataset_name', 'variable_name'],
			true,
		)

		const mergeData = (set) => {
			if (set.data !== true) {
				return undefined
			}

			const {
				multiple,
				unit,
				label,
				label_key,
				name,
				_uniqueId,
				forced_locked,
				will_be_available_soon,
				description,
			} = set
			const out = {
				multiple,
				unit,
				label,
				label_key,
				name,
				_uniqueId,
				forced_locked,
				will_be_available_soon,
				description,
			}

			if (Array.isArray(set.possible_scales_data)) {
				out.dataShouldBeDisplayed = set.possible_scales_data.includes(scaleDataToDisplay)
			}
			else if (Array.isArray(set.computed_possible_scales)) {
				// Fallback to the configured scales
				out.dataShouldBeDisplayed = set.computed_possible_scales.includes(scaleDataToDisplay)
			}
			else {
				// if we don't have explicit info, we consider that we can display it.
				out.dataShouldBeDisplayed = true
			}

			if (set.type === 'category') {
				out.elements = set.elements.map(mergeData).filter((d) => typeof d !== 'undefined')
			}
			else if (set.type === 'variable') {
				const { dataset_name, variable_name } = set
				const match = catalogueByDatasetAndVariable.get(dataset_name)?.get(variable_name)
				Object.assign(out, match || {}, {
					dataset_name,
					variable_name,
					type: match?.type || set.type,
				})
			}
			else {
				return undefined
			}
			out.architectureTranslationKey = out.label_key || out.variable_name || out.name
			return out
		}

		this.#preparedData = (architecture || [])
			.filter((set) => typeof set.data !== 'undefined')
			.filter((set) => set.variable_name !== 'empty')
			.map(mergeData)
			.filter((d) => typeof d !== 'undefined')
	}

	get preparedData() {
		return this.#preparedData
	}
}

class TreeDisplayerDataHandler {
	#config

	/**
	 * @type Map.<String, Set.<String>>
	 */
	#baseRestrictionsVariablesNamesByDatasetsNames

	/**
	 * @type TreeDisplayerData
	 */
	#baseData

	/**
	 * @type {Map.<String, Map<String, Object>>}
	 */
	#dataCache

	constructor({
		datasetsAndVariablesNames,
		scaleDataToDisplay,
		architecture,
		baseRestrictionsVariablesNamesByDatasetsNames,
		geoContext,
	}) {
		this.#dataCache = new Map()
		this.#config = {
			datasetsAndVariablesNames,
			scaleDataToDisplay,
			architecture,
			geoContext,
		}
		this.#baseRestrictionsVariablesNamesByDatasetsNames
			= baseRestrictionsVariablesNamesByDatasetsNames
	}

	async initBase(preferredLanguageLocale) {
		this.#baseData = await this.fetchWithRestrictionsAndPrepare(
			this.#baseRestrictionsVariablesNamesByDatasetsNames,
			preferredLanguageLocale,
		)
	}

	/**
	 * @param {Map.<String, Set.<String>>} restrictions
	 * @param {String} preferredLanguageLocale
	 * @returns {Promise<Object[]>}
	 */
	async fetchWithRestrictions(restrictions, preferredLanguageLocale) {
		const { datasetsAndVariablesNames, scaleDataToDisplay, geoContext } = this.#config

		const dataToUse = datasetsAndVariablesNames.filter(({ dataset_name, variable_name }) =>
			restrictions.has(dataset_name) && restrictions.get(dataset_name).has(variable_name),
		)

		const out = []

		let apiRes
		if (typeof scaleDataToDisplay !== 'undefined' && dataToUse.length > 0) {
			try {
				const geoContextToUse = geoContext.displayed instanceof DisplayedTurfsInfo
					? geoContext.withNewDisplayedId(geoContext.parents.scale)
					: geoContext
				apiRes = await commandStore.getDataPerScope({
					scale: scaleDataToDisplay,
					additionalData: dataToUse,
					geoContextPayload: geoContextToUse.asApiGeoContextPayload(),
					preferredLanguageLocale,
				})
			}
			catch (err) {
				// eslint-disable-next-line no-console
				console.error(err)

				// moveOn, we don't want crashes, what's after should work
			}
		}

		const datas = get(apiRes, 'data[0].data', [])
		const descriptions = get(apiRes, 'properties.variables_description', [])

		const datasGrouped = multiGroupBy(datas, ['dataset_name', 'variable_name'], true)
		const descriptionsGrouped = multiGroupBy(descriptions, ['dataset_name', 'variable_name'], true)

		// reconstruct a proper output with all datasetNames / variables that
		// we requested even if the API hasn't returned it, so that we don't rerequest it later
		restrictions.forEach((variableNames, datasetName) => {
			variableNames.forEach((variableName) => {
				const newEl = Object.assign(
					{
						dataset_name: datasetName,
						variable_name: variableName,
					},
					datasGrouped.get(datasetName)?.get(variableName) || {},
					descriptionsGrouped.get(datasetName)?.get(variableName) || {},
				)

				if (!this.#dataCache.has(datasetName)) {
					this.#dataCache.set(datasetName, new Map())
				}

				this.#dataCache.get(datasetName).set(variableName, newEl)
				out.push(newEl)
			})
		})
		return out
	}

	/**
	 * @param {Map.<String, Set.<String>>} restrictions
	 * @param {String} preferredLanguageLocale
	 * @returns {Promise<TreeDisplayerData>}
	 */
	async fetchWithRestrictionsAndPrepare(restrictions, preferredLanguageLocale) {
		const { scaleDataToDisplay, architecture } = this.#config

		const catalogue = await this.fetchWithRestrictions(restrictions, preferredLanguageLocale)

		return new TreeDisplayerData({
			architecture,
			scaleDataToDisplay: scaleDataToDisplay,
			catalogue,
		})
	}

	get basePreparedData() {
		return this.#baseData.preparedData
	}

	/**
	 * @returns {Map<String, Map<String, Object>>}
	 */
	get dataCache() {
		return this.#dataCache
	}
}

export default TreeDisplayerDataHandler
