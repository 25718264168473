/**
 * @typedef Codes
 * @prop {String} [region] - region code
 * @prop {String} [department] - department code
 * @prop {String} [municipality] - municipality code
 * @prop {String} [iris] - iris code
 * @prop {String} [polling_station] - polling_station number
 *
 * @example
 * {
 *     region: 11,
 *     department: 33,
 *     municipality: 33140
 * }
 */

/**
 * @store @commandCenter
 * @namespaced
 * @description Vuex store handling the command center requests
 */
import commandStore from '../../models/command_store'
import { getAvailableDatasetsAndVariablesNames } from './command-center/middlewares'
import store from '../../store/stateStore'
import TreeDisplayerIdsHandler from '../../components/command-center-module/utils/TreeDisplayerIdsHandler'
import TreeDisplayerDataHandler from '../../components/command-center-module/utils/TreeDisplayerDataHandler'
import GeoContext, {
	DisplayedContoursInfo,
	ParentContoursInfo,
} from '../../components/command-center-module/utils/GeoContext'
import { addUniqueIds } from '../../components/command-center-module/utils/architectureHelpers'
import { shallowRef } from 'vue'

/**
 * @param should
 * @returns {string|null}
 */
const parseShould = (should) => {
	if (should?.length > 0) {
		return [...Object.values(should[0])][0][0]
	}

	return null
}

const getDefaultState = () => ({
	/* parent and children must be keys of other level in object !! */
	geoRootInfo: shallowRef({}),
	/**
	 * @type {Array<String>}
	 */
	authorizedScales: shallowRef([]),
	/**
	 * @type {GeoContext}
	 */
	geoContext: shallowRef(null),
	/**
	 * @type {Array.<GeoContext>}
	 */
	geoContextsBreadcrumb: shallowRef([]),
	archi: shallowRef([]),
	loadingCatalogue: false,
	loading: false,
	treeDisplayerIdsHandler: shallowRef(new TreeDisplayerIdsHandler()),
	displayedVariableInfoOnTheMap: shallowRef({}),
	treeDisplayerDataHandler: shallowRef({}),
	prio_loading: false,
	prioArchi: shallowRef([]),
	polygonIsLoading: false,
	// Boolean to tell if the different architectures
	// are being loaded
	architecturesAreLoading: false,
	haveTriedLoadingArchitectures: false,
	displayMobilisation: 'contacts',
	hasDisplayableContacts: false,
	// right padding apply when fitting the map to given bounds
	mapRightPadding: 270,
	mapBottomPadding: 0,
})

const state = getDefaultState()

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState())
	},

	SET_GEO_ROOT_INFO: (state, payload) => {
		state.geoRootInfo = payload
	},

	SET_GEO_CONTEXTS_BREADCRUMB: (state, payload) => {
		state.geoContextsBreadcrumb = payload
		state.geoContext = payload[payload.length - 1]
	},

	RESET_GEO_CONTEXTS_BREADCRUMB: (state) => {
		const rootGeoContext = state.geoContextsBreadcrumb[0]

		state.geoContextsBreadcrumb = [rootGeoContext]
		state.geoContext = rootGeoContext
	},

	SET_ARCHI: (state, payload) => {
		state.archi = payload
	},

	RESET_ARCHI: (state) => {
		state.archi = {}
	},

	RESET_HAVE_TRIED_LOADING_ARCHITECTURES: (state) => (state.haveTriedLoadingArchitectures = false),

	SET_ARCHITECTURES_ARE_LOADING: (state, payload) => {
		state.architecturesAreLoading = payload

		if (payload === true) {
			state.haveTriedLoadingArchitectures = true
		}
	},

	START_LOADING: (state) => {
		state.loading = true
	},

	START_PRIO_LOADING: (state) => {
		state.prio_loading = true
	},

	END_LOADING: (state) => {
		state.loading = false
	},

	END_PRIO_LOADING: (state) => {
		state.prio_loading = false
	},

	START_LOADING_CATALOGUE: (state) => {
		state.loadingCatalogue = true
	},

	END_LOADING_CATALOGUE: (state) => {
		state.loadingCatalogue = false
	},

	SET_TREE_DISPLAYER_DATA_HANDLER: (state, payload) => {
		state.treeDisplayerDataHandler = payload
	},

	RESET_TREE_DISPLAYER_DATA_HANDLER: (state) => {
		state.treeDisplayerDataHandler = {}
	},

	SET_PRIO_ARCHITECTURE: (state, payload) => {
		state.prioArchi = payload
	},

	RESET_PRIO_ARCHITECTURE: (state) => {
		state.prioArchi = []
	},

	SET_TREE_DISPLAYER_IDS_HANDLER: (state, payload) => {
		state.treeDisplayerIdsHandler = payload
	},

	SET_DISPLAYED_VARIABLE_INFO_ON_THE_MAP: (state, payload) => {
		state.displayedVariableInfoOnTheMap = payload
	},

	SET_DISPLAY_MOBILISATION: (state, payload) => {
		state.displayMobilisation = payload
	},

	SET_AUTHORIZED_SCALES: (state, payload) => {
		state.authorizedScales = payload
	},

	SET_HAS_DISPLAYABLE_CONTACTS: (state, payload) => {
		state.hasDisplayableContacts = payload
	},
	RESET_HAS_DISPLAYABLE_CONTACTS: (state) => {
		state.hasDisplayableContacts = false
	},

	SET_MAP_RIGHT_PADDING: (state, payload) => {
		state.mapRightPadding = payload
	},
	SET_MAP_BOTTOM_PADDING: (state, payload) => {
		state.mapBottomPadding = payload
	},

	SET_POLYGON_IS_LOADING: (state, payload) => {
		state.polygonIsLoading = payload
	},
}

const getters = {
	archi: (state) => state.archi,
	authorizedScales: (state) => state.authorizedScales,
	geoContext: (state) => state.geoContext,
	geoContextsBreadcrumb: (state) => state.geoContextsBreadcrumb,
	treeDisplayerDataHandler: (state) => state.treeDisplayerDataHandler,
	loading: (state) => state.loading,
	loadingCatalogue: (state) => state.loadingCatalogue,
	prio_loading: (state) => state.prio_loading,
	prioArchi: (state) => state.prioArchi,
	architecturesAreLoading: (state) => state.architecturesAreLoading,
	haveTriedLoadingArchitectures: (state) => state.haveTriedLoadingArchitectures,
	treeDisplayerIdsHandler: (state) => state.treeDisplayerIdsHandler,
	displayedVariableInfoOnTheMap: (state) => state.displayedVariableInfoOnTheMap,
	getDisplayMobilisation: (state) => state.displayMobilisation,
	hasDisplayableContacts: (state) => state.hasDisplayableContacts,
	mapRightPadding: (state) => state.mapRightPadding,
	mapBottomPadding: (state) => state.mapBottomPadding,
	polygonIsLoading: (state) => state.polygonIsLoading,
}

export const getGeoContextFromInfos = (geoRootAlias, info_campagne) => {
	let {
		data_map_contours_scale,
		data_parent_territories_administrative_ids,
		data_map_root_label,
		data_parent_territories_scale,
	} = info_campagne

	// sublevel_id / parentTerritoryIdsString stores a ";" separated list of ids
	const parentIds = data_parent_territories_administrative_ids.split(';')

	return new GeoContext({
		displayed: new DisplayedContoursInfo(data_map_contours_scale),
		parents: new ParentContoursInfo({
			scale: data_parent_territories_scale,
			ids: parentIds,
		}),
		label: data_map_root_label,
		polygon: [],
		geoRootAlias,
	})
}

const actions = {
	/**
	 * @action getCatalogue
	 * @param {Codes} codes
	 * @desc return all the variables available at a specific scope (from state.geoContext)
	 */
	async getCatalogue({ commit, state }, preferredLanguageLocale) {
		commit('START_LOADING_CATALOGUE')
		commit('RESET_TREE_DISPLAYER_DATA_HANDLER')
		const { geoContext } = state

		const scaleDataToDisplay
			= geoContext.highlightedTerritoryId === null
				? geoContext.parents.scale
				: geoContext.displayed.scale
		const datasetsAndVariablesNames = getAvailableDatasetsAndVariablesNames(
			state.archi,
			scaleDataToDisplay,
		)

		const selectedVariablesNamesByDatasetName
			= state.treeDisplayerIdsHandler.selectedVariablesNamesByDatasetName

		const treeDisplayerDataHandler = new TreeDisplayerDataHandler({
			datasetsAndVariablesNames,
			scaleDataToDisplay,
			architecture: state.archi,
			baseRestrictionsVariablesNamesByDatasetsNames: selectedVariablesNamesByDatasetName,
			geoContext,
		})

		await treeDisplayerDataHandler.initBase(preferredLanguageLocale)
		commit('SET_TREE_DISPLAYER_DATA_HANDLER', treeDisplayerDataHandler)
		commit('END_LOADING_CATALOGUE')
	},

	/**
	 * @desc is responsible for getting the data for the map
	 */
	async getContoursData({ state }, { payload, preferredLanguageLocale }) {
		const geoContext = state.geoContext
		const {
			variable_name,
			dataset_name,
			extra_config_for_backend,
			should,
			ref_variable_name,
			ref_should,
			ref_dataset_name,
		} = payload

		if (typeof dataset_name !== 'string') {
			return {}
		}

		const main_variable = {
			dataset_name,
			variable_name,
			secondary_group_by_col_value: null,
			extra_config_for_backend: extra_config_for_backend || null,
		}

		if (should?.length !== 0) {
			main_variable.secondary_group_by_col_value = parseShould(should)
		}

		const extra_variables = []

		if (typeof ref_variable_name === 'string') {
			const extraVariable = {
				variable_name: ref_variable_name,
				dataset_name: ref_dataset_name,
				secondary_group_by_col_value: null,
			}

			if (ref_should?.length !== 0) {
				extra_variables.secondary_group_by_col_value = parseShould(ref_should)
			}

			extra_variables.push(extraVariable)
		}

		let res = {}

		try {
			res = await commandStore.getMapTerritoriesData({
				main_variable,
				extra_variables,
				geo_context: geoContext.asApiGeoContextPayload(true),
				preferred_language_locale: preferredLanguageLocale,
			})
		}
		catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}

		if (typeof res?.caption === 'object') {
			store.dispatch('@commandCenterCaption/actionSetCaption', res.caption)
		}

		return res
	},

	/**
	 * @action generatePriorisation
	 * @desc generate a priorisation for a given contours
	 */
	async generatePriorisation({ commit, state }, { variables, preferredLanguageLocale, isPublic = false }) {
		const { geoContext } = state

		try {
			const variablesCleaned = variables.map(
				({ dataset_name, variable_name, should, weight, secondary_group_by_col_value }) => ({
					dataset_name,
					variable_name,
					// FIXME, remove this in the future and use only secondary_group_by_col_value
					should,
					secondary_group_by_col_value: secondary_group_by_col_value || parseShould(should),
					weight,
				}),
			)

			commit('START_PRIO_LOADING')
			const res = await commandStore.getPriorisation({
				variables: variablesCleaned,
				geo_context: geoContext.asApiGeoContextPayload(true),
				preferredLanguageLocale,
				isPublic,
			})

			if (typeof res?.caption === 'object') {
				store.dispatch('@commandCenterCaption/actionSetCaption', res.caption)
			}

			return res
		}
		finally {
			commit('END_PRIO_LOADING')
		}
	},

	/**
	 * @action getDatalake
	 * @desc get architecture.json from the prio store, based on the `archi_data_carto`
	 * saved in the backend
	 */
	async getDatalake({ commit, rootState, dispatch, state }, { preferredLanguageLocale, forcedGeoContext = null }) {
		const { archi_data_carto } = rootState.info_campagne

		if (archi_data_carto.toUpperCase() === 'NO_DATA') {
			console.warn('Cannot request data with architecture', archi_data_carto)

			return
		}

		commit('SET_ARCHITECTURES_ARE_LOADING', true)
		const geoContext = forcedGeoContext ?? state.geoContext
		try {
			const parent_scale = geoContext.parents.scale
			const payload = { parent_scale, preferred_language_locale: preferredLanguageLocale }
			const prioArchi = await commandStore.getPrioArchi({
				archi_data_carto,
				payload,
				geoContextPayload: geoContext.asApiGeoContextPayload(),
			})

			addUniqueIds(prioArchi)
			commit('SET_PRIO_ARCHITECTURE', prioArchi)
			const archi = await commandStore.getDatalake({
				archi_data_carto,
				payload,
				geoContextPayload: geoContext.asApiGeoContextPayload(),
			})

			addUniqueIds(archi)
			commit('SET_ARCHI', archi)
			return archi
		}
		finally {
			dispatch('getCatalogue', preferredLanguageLocale)
			commit('SET_ARCHITECTURES_ARE_LOADING', false)
		}
	},

	/**
	 * @action resetState
	 * @desc properly reset the command module
	 */
	resetState({ commit }) {
		commit('RESET_STATE')
	},

	actionResetHaveTriedLoadingArchitectures({ commit }, payload) {
		commit('RESET_HAVE_TRIED_LOADING_ARCHITECTURES')
	},

	actionSetTreeDisplayerHandler({ commit }, payload) {
		commit('SET_TREE_DISPLAYER_IDS_HANDLER', payload)
	},

	actionSetDisplayedVariableInfoOnTheMap({ commit }, payload) {
		commit('SET_DISPLAYED_VARIABLE_INFO_ON_THE_MAP', payload)
	},

	actionSetDisplayMobilisation({ commit }, payload) {
		commit('SET_DISPLAY_MOBILISATION', payload)
	},

	actionInitGeoContextsBreadcrumb({ commit, rootState }) {
		commit('SET_GEO_CONTEXTS_BREADCRUMB', [getGeoContextFromInfos(rootState.dataGeoRootAlias, rootState.info_campagne)])
	},
	actionSetGeoContextsBreadcrumb({ commit, state }, payload) {
		// only called for contour search results
		const { geoContextsBreadcrumb } = state
		const root = geoContextsBreadcrumb[0]
		const newRoot = root.withNewDisplayedTerritories(
			new DisplayedContoursInfo(
				payload[0].highlightedTerritoryId === null
					? payload[0].parents.scale
					: payload[0].displayed.scale,
			),
		)

		commit('SET_GEO_CONTEXTS_BREADCRUMB', [newRoot, ...payload])
	},

	actionReplaceTailGeoContextInBreadcrumb({ commit, state }, payload) {
		const { geoContextsBreadcrumb } = state

		commit('SET_GEO_CONTEXTS_BREADCRUMB', [...geoContextsBreadcrumb.slice(0, -1), payload])
	},

	actionAppendGeoContextInBreadcrumb({ commit, state }, payload) {
		const { geoContextsBreadcrumb } = state

		commit('SET_GEO_CONTEXTS_BREADCRUMB', [...geoContextsBreadcrumb, payload])
	},

	actionPopLastGeoContextsInBreadcrumbIfPossible({ commit, state }) {
		const { geoContextsBreadcrumb } = state
		const newGeoContextsBreadcrumb = geoContextsBreadcrumb.slice(0, -1)

		if (newGeoContextsBreadcrumb.length > 0) {
			commit('SET_GEO_CONTEXTS_BREADCRUMB', [...geoContextsBreadcrumb.slice(0, -1)])
		}
	},

	actionPopGeoContextsInBreadcrumbUntilIndex({ commit, state }, index) {
		const { geoContextsBreadcrumb } = state

		commit('SET_GEO_CONTEXTS_BREADCRUMB', geoContextsBreadcrumb.slice(0, index + 1))
	},

	actionStartLoading({ commit }) {
		commit('START_LOADING')
	},

	actionEndLoading({ commit }) {
		commit('END_LOADING')
	},

	actionStartPrioLoading({ commit }) {
		commit('START_PRIO_LOADING')
	},

	actionEndPrioLoading({ commit }) {
		commit('END_PRIO_LOADING')
	},

	actionSetAuthorizedScales({ commit }, payload) {
		commit('SET_AUTHORIZED_SCALES', payload)
	},

	actionSetHasDisplayableContacts({ commit }, payload) {
		commit('SET_HAS_DISPLAYABLE_CONTACTS', payload)
	},
	actionSetMapRightPadding({ commit }, payload) {
		commit('SET_MAP_RIGHT_PADDING', payload)
	},
	actionSetMapBottomPadding({ commit }, payload) {
		commit('SET_MAP_BOTTOM_PADDING', payload)
	},
	actionSetPolygonIsLoading({ commit }, payload) {
		commit('SET_POLYGON_IS_LOADING', payload)
	},
}

export const commandCenterModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
