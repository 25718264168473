import {
	SET_CAPTION,
	RESET_CAPTION_STORE,
	SET_SECONDARY_CAPTION,
	RESET_SECONDARY_CAPTION,
} from './_mutation-types'

const getDefaultState = () => {
	return {
		caption: null,
		secondaryCaption: null,
	}
}

const state = getDefaultState

const mutations = {
	[SET_CAPTION]: (state, payload) => {
		state.caption = payload
	},

	[SET_SECONDARY_CAPTION]: (state, payload) => {
		state.secondaryCaption = payload
	},

	[RESET_SECONDARY_CAPTION]: (state) => {
		state.secondaryCaption = null
	},

	[RESET_CAPTION_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
}
const getters = {
	caption: (state) => state.secondaryCaption || state.caption,
}
const actions = {
	actionSetCaption({ commit }, payload) {
		commit(SET_CAPTION, payload)
	},
	actionSetSecondaryCaption({ commit }, payload) {
		commit(SET_SECONDARY_CAPTION, payload)
	},
	actionResetSecondaryCaption({ commit }) {
		commit(RESET_SECONDARY_CAPTION)
	},
	actionResetStateCaption({ commit }) {
		commit(RESET_CAPTION_STORE)
	},
}

export const commandCenterCaptionModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
