import waiting_users_store from './../../models/waiting_users_store'
import { SET_WAITING_USERS_LIST, RESET_WAITING_USERS_STORE } from './_mutation-types'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

/**
 * @store @waitingUsers
 * @namespaced
 * @description Waiting users related info and store module
 */

const getDefaultState = () => {
	return {
		waitingUsersList: [],
	}
}
const state = getDefaultState

const mutations = {
	[RESET_WAITING_USERS_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_WAITING_USERS_LIST]: (state, payload) => {
		state.waitingUsersList = payload
	},
}

const actions = {
	actionResetWaitingUsers({ commit }) {
		commit(RESET_WAITING_USERS_STORE)
	},

	// actionRetrieveWaitingUsersList: ({ commit }) => {
	// 	return waiting_users_store
	// 		.listWaitingUsers()
	// 		.then((res) => {
	// 			commit(SET_WAITING_USERS_LIST, res)
	// 		})
	// 		.catch(() => {})
	// },

	actionAcceptWaitingUser: ({ commit, state }, payload) => {
		return waiting_users_store
			.acceptWaitingUser(payload.id)
			.then(() => {
				// Remove user from the list
				let clonedWaitingUser = cloneDeep(state?.waitingUsersList)
				remove(clonedWaitingUser, (user) => user?.id === payload?.id)
				commit(SET_WAITING_USERS_LIST, clonedWaitingUser)
			})
			.catch(() => {})
	},

	actionDeclineWaitingUser: ({ commit, state }, payload) => {
		return waiting_users_store
			.declineWaitingUser(payload.id)
			.then(() => {
				// Remove user from the list
				let clonedWaitingUser = cloneDeep(state?.waitingUsersList)
				remove(clonedWaitingUser, (user) => user?.id === payload?.id)
				commit(SET_WAITING_USERS_LIST, clonedWaitingUser)
			})
			.catch(() => {})
	},
}

const getters = {
	getWaitingUsersList: (state) => state.waitingUsersList,
	getWaitingUsersListLength: (state) => state.waitingUsersList.length,
}

export const waitingUsersModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
