import { $request } from './requester'

export default {
	async getRoles() {
		return $request('GET', '/roles').then((res) => res.body.data)
	},

	async getRolesTemplates() {
		return $request('GET', '/roles/templates').then((res) => res.body.data)
	},

	async addRole(role) {
		return $request('POST', '/roles', role).then((res) => res.body.data)
	},

	async updateRole(role) {
		return $request('PATCH', '/roles', role).then((res) => res.body.data)
	},

	async deleteRole(id) {
		return $request('DELETE', `/roles/${id}`).then((res) => res.body.data)
	},
}
