import petitionModel from '../../models/petition_store'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

import {
	RESET_PETITIONS_STORE,
	SET_PETITIONS_CATEGORIES,
	SET_STATUS_FETCH_PETITIONS,
	SET_PETITIONS_LIST,
	SET_STATUS_FETCH_ONE_PETITION,
	SET_PETITION,
	SET_STATUS_POST_PETITION,
	SET_STATUS_UPDATE_PETITION,
	SET_STATUS_PUBLISH_PETITION,
	SET_STATUS_UNPUBLISH_PETITION,
	SET_STATUS_DELETE_PETITION,
} from './_mutation-types'

const getDefaultState = () => {
	return {
		categories: [],

		statusFetchPetitions: 'init',
		getPetitionsList: [],
		statusFetchOnePetition: 'init',
		getPetition: {},
		statusPostPetition: 'init',
		statusUpdatePetition: 'init',
		statusPublishPetition: 'init',
		statusUnpublishPetition: 'init',
		statusDeletePetition: 'init',
	}
}
const state = getDefaultState

export const mutations = {
	[RESET_PETITIONS_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_PETITIONS_CATEGORIES]: (state, payload) => {
		state.categories = payload
	},

	[SET_STATUS_FETCH_PETITIONS]: (state, payload) => {
		state.statusFetchPetitions = payload
	},

	[SET_PETITIONS_LIST]: (state, payload) => {
		state.getPetitionsList = payload
	},

	[SET_STATUS_FETCH_ONE_PETITION]: (state, payload) => {
		state.statusFetchOnePetition = payload
	},

	[SET_PETITION]: (state, payload) => {
		state.getPetition = payload
	},

	[SET_STATUS_POST_PETITION]: (state, payload) => {
		state.statusPostPetition = payload
	},

	[SET_STATUS_UPDATE_PETITION]: (state, payload) => {
		state.statusUpdatePetition = payload
	},

	[SET_STATUS_PUBLISH_PETITION]: (state, payload) => {
		state.statusPublishPetition = payload
	},

	[SET_STATUS_UNPUBLISH_PETITION]: (state, payload) => {
		state.statusUnpublishPetition = payload
	},

	[SET_STATUS_DELETE_PETITION]: (state, payload) => {
		state.statusDeletePetition = payload
	},
}

export const actions = {
	actionResetPetitions({ commit }) {
		commit(RESET_PETITIONS_STORE)
	},

	async actionGetCategories({ commit }) {
		return await petitionModel
			.getCategories()
			.then((res) => commit(SET_PETITIONS_CATEGORIES, res || []))
			.catch((e) => console.error(e))
	},

	actionSetPetitionsList: ({ commit }) => {
		commit(SET_STATUS_FETCH_PETITIONS, 'loading')

		petitionModel
			.getPetitions()
			.then((res) => {
				commit(SET_STATUS_FETCH_PETITIONS, 'success')
				commit(SET_PETITIONS_LIST, res)
			})
			.catch(() => commit(SET_STATUS_FETCH_PETITIONS, 'error'))
	},

	actionSetPetition: ({ commit, state }, payload) => {
		commit(SET_STATUS_FETCH_ONE_PETITION, 'loading')

		petitionModel
			.getPetitionById(payload)
			.then((petitionFetched) => {
				const allPetitions = cloneDeep(state.getPetitionsList) || []
				const currentPetition = allPetitions.find((p) => p.petition_id == payload)
				const amount = currentPetition?.amount || 0

				remove(allPetitions, (el) => el.petition_id === payload)
				allPetitions.push({ ...petitionFetched, amount })
				commit(SET_PETITIONS_LIST, allPetitions)
				commit(SET_PETITION, { ...petitionFetched, amount })
				commit(SET_STATUS_FETCH_ONE_PETITION, 'success')
			})
			.catch(() => commit(SET_STATUS_FETCH_ONE_PETITION, 'error'))
	},

	actionSetNewPetition: ({ commit, state }, payload) => {
		commit(SET_STATUS_POST_PETITION, 'loading')

		petitionModel
			.postPetition({ petition: { ...payload } })
			.then((res) => {
				commit(SET_STATUS_POST_PETITION, 'success')
				commit(SET_PETITION, res)
				const clonedPetitionsList = cloneDeep(state.getPetitionsList) || []
				clonedPetitionsList.push(res)
				commit(SET_PETITIONS_LIST, clonedPetitionsList)
			})
			.catch(() => commit(SET_STATUS_POST_PETITION, 'error'))
	},

	actionUpdatePetition: ({ commit, state }, payload) => {
		delete payload.data.id
		delete payload.data.petition_id

		commit(SET_STATUS_UPDATE_PETITION, 'loading')

		petitionModel
			.updatePetition({ id: payload.id, data: { petition: { ...payload.data } } })
			.then((petitionUpdated) => {
				const allPetitions = cloneDeep(state.getPetitionsList)
				remove(allPetitions, (el) => el.petition_id === payload.id)
				allPetitions.push(petitionUpdated)
				commit(SET_PETITIONS_LIST, allPetitions)
				commit(SET_PETITION, petitionUpdated)
				commit(SET_STATUS_UPDATE_PETITION, 'success')
			})
			.catch(() => commit(SET_STATUS_UPDATE_PETITION, 'error'))
	},

	actionPublishPetition: ({ commit, state }, payload) => {
		commit(SET_STATUS_PUBLISH_PETITION, 'loading')

		petitionModel
			.publishPetition(payload)
			.then((res) => {
				const petitionPublishing = res.body.data.petition
				const allPetitions = cloneDeep(state.getPetitionsList)

				remove(allPetitions, (el) => el.petition_id === payload)
				allPetitions.push(petitionPublishing)

				commit(SET_PETITIONS_LIST, allPetitions)
				commit(SET_PETITION, petitionPublishing)
				commit(SET_STATUS_PUBLISH_PETITION, 'success')
			})
			.catch((petitionPublishedError) => {
				const allPetitions = cloneDeep(state.getPetitionsList)
				remove(allPetitions, (el) => el.petition_id === payload)
				allPetitions.push(petitionPublishedError)
				commit(SET_PETITIONS_LIST, allPetitions)
				commit(SET_STATUS_PUBLISH_PETITION, 'error')
			})
	},

	actionUnpublishPetition: ({ commit, state }, payload) => {
		commit(SET_STATUS_UNPUBLISH_PETITION, 'loading')

		petitionModel
			.unpublishPetition(payload)
			.then((res) => {
				const petitionUnpublishing = res.body.data.petition
				const allPetitions = cloneDeep(state.getPetitionsList)

				remove(allPetitions, (el) => el.petition_id === payload)
				allPetitions.push(petitionUnpublishing)

				commit(SET_PETITIONS_LIST, allPetitions)
				commit(SET_PETITION, petitionUnpublishing)
				commit(SET_STATUS_UNPUBLISH_PETITION, 'success')
			})
			.catch(() => commit(SET_STATUS_UNPUBLISH_PETITION, 'error'))
	},

	actionDeletePetition: ({ commit, state }, payload) => {
		commit(SET_STATUS_DELETE_PETITION, 'loading')

		petitionModel
			.deletePetition(payload)
			.then(() => {
				commit(SET_STATUS_DELETE_PETITION, 'success')
				const allPetitions = cloneDeep(state.getPetitionsList)
				remove(allPetitions, (el) => el.petition_id === payload)
				commit(SET_PETITIONS_LIST, allPetitions)
			})
			.catch((err) => {
				switch (err.status) {
					case 423:
						commit(SET_STATUS_DELETE_PETITION, 'locked')
						break

					default:
						commit(SET_STATUS_DELETE_PETITION, 'error')
						break
				}
			})
	},

	actionDeleteImageUploaded: async ({}, payload) => {
		return petitionModel.deletePhotoUploaded(payload)
	},
}

export const getters = {
	getCategories: (state) => state.categories,

	getStatusFetchPetitions: (state) => state.statusFetchPetitions,
	getPetitionsList: (state) => state.getPetitionsList,
	getStatusFetchOnePetition: (state) => state.statusFetchOnePetition,
	getPetition: (state) => state.getPetition,
	getStatusPostPetition: (state) => state.statusPostPetition,
	getStatusUpdatePetition: (state) => state.statusUpdatePetition,
	getStatusPublishPetition: (state) => state.statusPublishPetition,
	getStatusUnpublishPetition: (state) => state.statusUnpublishPetition,
	getStatusDeletePetition: (state) => state.statusDeletePetition,
}

export const petitionModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
