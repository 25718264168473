import { formatDate } from '../../../../utils/dateFnsHelper'
import app from '../../../../appCreation'
import { getDisplayDateFromLocalStorage } from '../../../../utils/dateFnsHelper'
import mixinGetAgeCategories from '../../../mixins/agecategories-mixin'

const selected = true
const fixed = true
const widthBasis = '7rem'

export default function tableConfig(locale) {
	return {
		title: 'CONTACT.LIST.TABLE_TITLE',
		noDataTranslationKey: 'CONTACT.LIST.EMPTY',
		data: [
			{
				id: 1,
				order: 0,
				selected,
				width: `calc(${widthBasis} - 2.5rem)`,
				fixed,
				name: 'checkbox',
				translationKey: '_COMMON.CHECK',
			},
			{
				id: 2,
				order: 1,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				justify: 'flex-start',
				content: (item) => item.firstname,
				sort: 'firstname',
				translationKey: '_COMMON.FIRSTNAME',
			},
			{
				id: 3,
				order: 2,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				justify: 'flex-start',
				content: (item) => item.surname,
				sort: 'surname',
				translationKey: '_COMMON.LASTNAME',
			},
			{
				id: 4,
				order: 3,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				justify: 'flex-start',
				content: (item) => item.married_name,
				sort: 'married_name',
				translationKey: 'CONTACT.LIST.TABLE.MARITAL_NAME',
			},
			{
				id: 5,
				order: 4,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				content: (item, houseNumberAndStreetSortedByFormsOrder) =>
					getAddress(item, null, houseNumberAndStreetSortedByFormsOrder),
				sort: 'street',
				translationKey: 'CONTACT.LIST.TABLE.ADDRESS',
			},
			{
				id: 6,
				order: 5,
				selected,
				width: `calc(${widthBasis} + 2rem)`,
				content: (item) => getAddress(item, 'city'),
				sort: 'city',
				translationKey: '_COMMON.CITY',
			},
			{
				id: 7,
				order: 6,
				selected,
				width: `calc(${widthBasis} + 2rem)`,
				content: (item) => getAddress(item, 'pollingstation'),
				name: 'pollingstation',
				type: 'address.pollingstation',
				translationKey: 'CONTACT.LIST.TABLE.TERRITORY',
			},
			{
				id: 8,
				order: 7,
				selected,
				width: `${widthBasis}`,
				content: (item) => getAddress(item, 'postalcode'),
				name: 'postalCode',
				translationKey: 'CONTACT.LIST.TABLE.POSTALCODE',
			},
			{
				id: 9,
				order: 8,
				selected,
				width: `${widthBasis}`,
				content: (item) => getAddress(item, 'county'),
				name: 'county',
				translationKey: 'CONTACT.LIST.TABLE.COUNTY',
			},
			{
				id: 10,
				order: 9,
				selected,
				width: `${widthBasis}`,
				content: (item) => getAddress(item, 'state'),
				name: 'state',
				translationKey: 'CONTACT.LIST.TABLE.STATE',
			},
			{
				id: 11,
				order: 10,
				selected,
				width: `calc(${widthBasis} + 2rem)`,
				content: (item) => getAddress(item, 'country'),
				name: 'country',
				translationKey: '_COMMON.COUNTRY',
			},
			{
				id: 12,
				order: 11,
				selected,
				width: `calc(${widthBasis} - 1rem)`,
				sort: 'mail',
				name: 'email',
				translationKey: 'CONTACT.LIST.TABLE.EMAIL',
			},
			{
				id: 13,
				order: 12,
				selected,
				width: `calc(${widthBasis} - 1rem)`,
				name: 'phone',
				translationKey: 'CONTACT.LIST.TABLE.PHONENUMBER',
			},
			{
				id: 14,
				order: 13,
				selected,
				width: `calc(${widthBasis} - 2rem)`,
				content: (item) => String(app.config.globalProperties.$filters.toAge(item.birthdate)),
				sort: 'birthdate',
				class: 'center',
				translationKey: 'CONTACT.LIST.TABLE.AGE',
			},
			{
				id: 15,
				order: 14,
				selected,
				width: `calc(${widthBasis} + 1rem)`,
				content: (item) => String(mixinGetAgeCategories.methods.actionFindAgeCategory(item.age_category).label),
				name: 'age_category',
				translationKey: 'CONTACT.LIST.TABLE.AGE_CATEGORY',
			},
			{
				id: 16,
				order: 15,
				selected,
				width: `calc(${widthBasis} - 2.2rem)`,
				content: (item) => {
					return item.gender
				},
				sort: 'gender',
				name: 'gender',
				translationKey: 'CONTACT.LIST.TABLE.GENDER',
			},
			{
				id: 17,
				order: 16,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				justify: 'flex-start',
				type: 'nationality',
				content: (item) => item.nationality,
				translationKey: 'CONTACT.LIST.TABLE.NATIONALITY',
			},
			{
				id: 18,
				order: 17,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				justify: 'flex-start',
				type: 'birthcity',
				content: (item) => item.birthcity,
				translationKey: 'CONTACT.LIST.TABLE.BIRTHCITY',
			},
			{
				id: 19,
				order: 18,
				selected,
				width: `calc(${widthBasis} + 4rem)`,
				justify: 'flex-start',
				type: 'birthcountry',
				content: (item) => item.birthcountry,
				translationKey: 'CONTACT.LIST.TABLE.BIRTHCOUNTRY',
			},
			{
				id: 20,
				order: 19,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				class: 'center',
				name: 'status',
				translationKey: 'CONTACT.LIST.TABLE.STATUS',
			},
			{
				id: 21,
				order: 20,
				selected,
				width: `calc(${widthBasis} + 10rem)`,
				name: 'tags',
				translationKey: 'CONTACT.LIST.TABLE.TAGS',
			},
			{
				id: 22,
				order: 21,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				content: (item) =>
					item.CreatedAt && item.CreatedAt != '0001-01-01T00:00:00Z'
						? formatDate(item.CreatedAt, getDisplayDateFromLocalStorage())
						: null,
				sort: 'CreatedAt',
				translationKey: 'CONTACT.LIST.TABLE.CREATED_AT',
			},
			{
				id: 23,
				order: 22,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				content: (item) =>
					item.UpdatedAt
						? formatDate(item.UpdatedAt, getDisplayDateFromLocalStorage())
						: null,
				sort: 'UpdatedAt',
				translationKey: 'CONTACT.LIST.TABLE.UPDATED_AT',
			},
			{
				id: 24,
				order: 23,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				name: 'user',
				translationKey: 'CONTACT.LIST.TABLE.CONTACT',
			},
			{
				id: 25,
				order: 24,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				content: (item) =>
					item.lastchange
						? formatDate(item.lastchange, getDisplayDateFromLocalStorage())
						: null,
				sort: 'lastchange',
				translationKey: 'CONTACT.LIST.TABLE.LASTSEEN',
			},
			{
				id: 26,
				order: 25,
				selected,
				width: `calc(${widthBasis} + 3rem)`,
				name: 'membership_member',
				content: (item) => {
					return item.membership_member ? JSON.stringify(item.membership_member) : null
				},
				translationKey: 'CONTACT.LIST.TABLE.MEMBERSHIP_MEMBER',
			},
		],
	}
}

const getAddress = (contact, level = null, houseNumberAndStreetSortedByFormsOrder = null) => {
	if (contact.address) {
		if (level) {
			return contact.address[level] || null
		}

		const { housenumber, street } = contact.address

		if (!housenumber && !street) {
			return null
		}

		if (houseNumberAndStreetSortedByFormsOrder)
			return `${contact.address[houseNumberAndStreetSortedByFormsOrder[0]] || ''} ${contact.address[houseNumberAndStreetSortedByFormsOrder[1]] || ''}`

		return `${housenumber || ''} ${street || ''}`
	}

	return null
}
