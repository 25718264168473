import forIn from 'lodash/forIn'
import { numberToLetters } from './convertStrCharNum'

export function constructArrayOfColumns(previewColumns) {
	const previewArray = []
	if (previewColumns) {
		return previewColumns.map((aPreview) => {
			const previewObject = {}
			aPreview.forEach((aPreviewItem, indexItem) => {
				previewObject[indexItem] = aPreviewItem
			})
			return previewObject
		})
	}
	return previewArray
}

function methodConvertPropIndexToName(index, indexNumber, columns) {
	if (index && columns) {
		if (indexNumber === 1) {
			return columns[Number(index)]
		}
		return numberToLetters(Number(index))
	}

	return index
}

export function constructFinalColumns(columns, indexNumber, originalColumns) {
	if (columns && originalColumns) {
		const arrayOfKeys = []

		originalColumns.forEach((aColumn, key) => {
			arrayOfKeys.push({
				key: String(key),
				label: methodConvertPropIndexToName(String(key), indexNumber, originalColumns),
				disabled: true,
				field: '',
			})
		})

		return arrayOfKeys
	}
	return []
}
