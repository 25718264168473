export default class DOM {
	public static create<K extends keyof HTMLElementTagNameMap>(
		tagName: K,
		className?: string,
		container?: HTMLElement
	): HTMLElementTagNameMap[K] {
		const el = window.document.createElement(tagName)
		if (className !== undefined) el.className = className
		if (container) container.appendChild(el)
		return el
	}

	public static remove(node: HTMLElement) {
		if (node.parentNode) {
			node.parentNode.removeChild(node)
		}
	}
}
