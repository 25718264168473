import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

import { computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentUsersAffectation',
  props: {
    idList: {},
    allUsers: {}
  },
  setup(__props: any) {

const el = ref(null)
const { width } = useElementSize(el)

const props = __props

const computedGetUsersByIdList = computed(() => {
	return props.allUsers.filter((user: any) => props.idList === user.role_data?.saved_filters_id)
})

const methodFormatUsername = (user: any) => {
	if (!user) return ''
	return `${user?.firstname} ${user?.surname ? user?.surname[0] : ''}.`
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "affectations-users",
    ref_key: "el",
    ref: el,
    class: "w-full flex flex-row gap-x-4"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedGetUsersByIdList.value, (aUser) => {
      return (_openBlock(), _createElementBlock("span", {
        key: aUser?.id,
        class: "flex flex-row gap-x-2 items-center"
      }, [
        (aUser.avatar)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: aUser?.avatar,
              class: "w-4 h-4 rounded-full"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(methodFormatUsername(aUser)), 1)
      ]))
    }), 128))
  ], 512))
}
}

})