<template>
	<div class="qview">
		<div class="q-header border-none shadow-sm z-49" style="padding-bottom: 0px">
			<div class="w-full" style="padding-bottom: 15px; padding-top: 10px">
				<survey-header></survey-header>
			</div>
		</div>
		<div class="scrollable-qview-main">
			<div class="q-fullwidth px-8 pt-8 pb-2">
				<router-view class="q-container"> </router-view>
			</div>
		</div>
	</div>
</template>

<script>
import SurveyHeader from './SurveyHeader.vue'
import mixinOnlineForms from '../online-forms-modules/_mixinOnlineForms'
import { cloneDeep } from 'lodash'

export default {
	name: 'Survey',
	components: { SurveyHeader },
	mixins: [mixinOnlineForms],
	data() {
		return {}
	},
	computed: {},

	mounted() {},

	methods: {},
}
</script>
