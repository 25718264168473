import { SET_TEMP_VALUE, CLEAN_TEMP_VALUE } from './_mutation-types'

///
/// THIS TEMP STORE IS USEFULL TO ROUTING ANY KIND OF INFORMATION
///

const state = () => {
	return {
		tempValue: null,
	}
}

export const mutations = {
	[SET_TEMP_VALUE]: (state, payload) => {
		state.tempValue = payload
	},

	[CLEAN_TEMP_VALUE]: (state) => {
		state.tempValue = null
	},
}

export const actions = {
	actionSetNewTempValue: ({ commit }, payload) => {
		commit(SET_TEMP_VALUE, payload)
	},

	actionCleanTempValue: ({ commit }) => {
		commit(CLEAN_TEMP_VALUE)
	},
}

export const getters = {
	getTempValue: (state) => state.tempValue,
}

export const tempModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
