<template>
	<div class="py-16">
		<div class="h-20">
			<div
				v-if="computedValidationStatus !== 'loading'"
				id="code-validator"
				class="flex justify-center gap-x-3"
			>
				<input
					v-for="(input, index) in computedUserCode"
					:key="index"
					v-model="computedUserCode[index]"
					type="text"
					inputmode="numeric"
					maxlength="1"
					:controls="false"
					min="0"
					max="9"
					:class="`code-validator__input w-12 h-16 border border-gray-300 rounded-xl text-center focus-within:outline-none focus-within:border-pink-main focus-within:ring-1 focus-within:ring-pink-main ${
						redBorder ? 'border-red-500' : ''
					}`"
					@paste="onPaste"
					@keydown="onKeydown"
					@focus="handleFocus"
				/>
			</div>

			<p
				v-if="computedValidationStatus !== 'loading' && errorMessage.length > 0"
				class="text-center text-sm text-gray-dark mt-5"
			>
				<i class="gui-no text-gray-dark" style="margin-top: 3px" />
				{{ errorMessage }}
			</p>

			<loader v-if="computedValidationStatus === 'loading'" size="5em"></loader>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '../general/loader.vue'

export default {
	components: {
		Loader,
	},
	props: {
		userEmail: { type: String, default: '', required: true },
		userCode: { type: Array, default: () => ['', '', '', ''], required: false },
		validationStatus: { type: String, default: 'init', required: false },
	},
	emits: ['update:user-code', 'update:validation-status'],
	data() {
		return {
			backspaceKey: false,
			errorMessage: '',
			redBorder: false,
		}
	},
	computed: {
		...mapGetters('@authentication', ['getStatusValidationCode']),

		computedUserCode: {
			get() {
				return this.userCode
			},
			set(value) {
				this.$emit('update:user-code', value)
			},
		},
		computedValidationStatus: {
			get() {
				return this.validationStatus
			},
			set(value) {
				this.$emit('update:validation-status', value)
			},
		},
	},
	watch: {
		computedUserCode: {
			handler() {
				this.showError()

				if (this.isFullWithNumbers()) {
					this.actionPostValidationCode({
						code: this.computedUserCode.join(''),
						email: this.userEmail,
					})
				}
			},
			deep: true,
		},

		getStatusValidationCode(status) {
			switch (status) {
				case 'loading':
					setTimeout(() => {
						this.computedValidationStatus = 'loading'
					}, 100)
					break
				case 'success':
					setTimeout(() => {
						this.computedValidationStatus = 'success'
					}, 700)
					break
				case 'error':
					setTimeout(() => {
						this.computedValidationStatus = 'error'
						this.redBorder = false
						this.computedUserCode.fill('')
						this.errorMessage = this.$t(`ERRORS.INVALID_CODE`)
					}, 700)
			}
		},
	},

	methods: {
		...mapActions('@authentication', ['actionPostValidationCode']),

		onPaste(event) {
			this.pasteContentInInputs(event)
		},

		onKeydown(event) {
			if (event.key === 'Backspace') {
				this.backspaceKey = true
			} else {
				this.backspaceKey = false
			}

			this.handleFocus()
		},

		handleFocus() {
			const codeValidator = document.getElementById('code-validator')
			const codeLength = this.computedUserCode.join('').length

			if (this.backspaceKey && codeValidator.children[codeLength - 1]) {
				codeValidator.children[codeLength - 1].focus()
			} else if (codeValidator.children[codeLength]) {
				codeValidator.children[codeLength].focus()
			}
		},

		pasteContentInInputs(event) {
			const paste = (event.clipboardData || window.clipboardData).getData('text')
			const pasteSplit = paste.split('')

			const inputsNumber = Array.from(document.getElementsByClassName('code-validator__input'))
			inputsNumber.forEach((element, index) => {
				if (index < 4) {
					this.computedUserCode[index] = pasteSplit[index]
				}
			})
		},

		showError() {
			if (this.isNoLetters()) {
				this.errorMessage = ''
				this.redBorder = false
			} else {
				this.errorMessage = this.$t(`ERRORS.FOUR_DIGITS`)
				this.redBorder = true
			}
		},

		isNoLetters() {
			return this.computedUserCode.every(
				(element) => !Number.isNaN(Number.parseInt(element)) || element === ''
			)
		},

		isFullWithNumbers() {
			return this.computedUserCode.every((element) => !Number.isNaN(Number.parseInt(element)))
		},
	},
}
</script>
