/**
 * @store @team
 * @namespaced
 */
import firstBy from 'thenby'
import team_store from '../../models/team_store'
import { clone, forIn, get, groupBy, uniqBy } from 'lodash'

const methodSetTeamLeaderKeyIfIsTeamLeader = (teams) => {
	const tmpTeams = teams || []
	tmpTeams?.forEach((aTeam) => {
		let allLeadersIdsInATeam = aTeam?.leaders?.map((leader) => leader.id) || []

		aTeam?.users?.forEach((aUser) => {
			if (allLeadersIdsInATeam.includes(aUser.id)) {
				aUser.isTeamLeader = true
			}
			else {
				aUser.isTeamLeader = false
			}
		})
	})
	return teams
}

const getTeamsFiltered = (state, rootState, getAllTeams = false) => {
	const stateTeams = get(state, 'teams', [])
	const isMasterView = get(rootState, '@group.group.masterView', false)
	if (getAllTeams || !isMasterView) return methodSetTeamLeaderKeyIfIsTeamLeader(stateTeams)
	const localGroupId = get(rootState, '@group.group.id')
	const teamsFiltered = stateTeams.filter((team) => team.groupid === localGroupId)

	return methodSetTeamLeaderKeyIfIsTeamLeader(teamsFiltered)
}

const state = {
	teamsCount: 0,
	teams: [],
	activeTeam: {
		name: '',
		private: false,
		files: [{ url: '' }, { url: '' }, { url: '' }],
		users: [],
		leaders: [],
		description: '',
		address: { city: '' },
		hide_users: false,
	},
	showDetail: false,
	editing: false,
}

const mutations = {
	SET_TEAMS: (state, res) => {
		if (res?.length) {
			res.forEach((aTeam) => {
				if (!aTeam.users) aTeam.users = []
			})
		}
		state.teams = res
	},

	RESET_TEAMS: (state) => {
		state.teams = []
		state.teamsCount = 0
	},

	SET_TEAMS_COUNT: (state, res) => {
		state.teamsCount = res
	},

	SAVE_NEW_TEAM: (state, res) => {
		state.teams.push(res)
	},

	INCREMENT_TEAMS_COUNT: (state, res = 1) => {
		state.teamsCount += res
	},

	SORT_TEAM: (state) => {
		state.teams.sort(firstBy('name')).map((team) => team.users?.sort(firstBy('firstname')))
	},

	SET_ACTIVE_TEAM: (state, action) => {
		state.activeTeam = clone(action)
	},

	EMPTY_ACTIVE_TEAM: (state) => {
		state.activeTeam = {
			name: '',
			private: false,
			files: [{ url: '' }, { url: '' }, { url: '' }],
			users: [],
			leaders: [],
			description: '',
			address: { city: '' },
			hide_users: false,
		}
	},

	DISPLAY_DETAIL: (state) => {
		state.showDetail = true
	},

	HIDE_DETAIL: (state) => {
		state.showDetail = false
	},

	EDIT: (state) => {
		state.editing = true
	},

	EDIT_END: (state) => {
		state.editing = false
	},
}

const actions = {
	actionSetTeams: async ({ commit }, team) => {
		commit('SET_TEAMS', team)
	},
	actionSetTeamsCount: async ({ commit }, teamLength) => {
		commit('SET_TEAMS_COUNT', teamLength)
	},
	actionSetActiveTeam: ({ commit }, team) => {
		commit('SET_ACTIVE_TEAM', team)
	},

	actionFetchTeams: async ({ dispatch, rootState }) => {
		const isMasterView = get(rootState, '@group.group.masterView', false)
		const result = await team_store.getTeams(isMasterView)
		result.sort(firstBy('name')).map((team) => {
			if (team.users && team.users.length) {
				team.users.sort(firstBy('firstname'))
			}
		})
		if (isMasterView) {
			const userGroups = get(rootState, '@user.userGroups', [])
			if (userGroups.length) {
				const groupedTeam = groupBy(result, 'groupid')
				forIn(groupedTeam, (value, key) => {
					const findGroup = userGroups.find((group) => group.id === Number(key))
					if (findGroup) {
						value.forEach((aTeam) => {
							aTeam.name = `${findGroup.name} - ${aTeam.name}`
						})
					}
				})
			}
			result.sort(firstBy('name'))
		}

		// Change avatar value from 'null' to null to avoid conflict //
		// To remove later //
		result.forEach((aTeam) => {
			if (aTeam.users) {
				aTeam.users.forEach((aUser) => {
					if (aUser.avatar === 'null') aUser.avatar = null
				})
			}
		})
		dispatch('actionSetTeams', result)
		dispatch('actionSetTeamsCount', result.length)
	},

	createNewTeam: (store, team) => {
		if (team.leaders) team.leaders = team.leaders.map(({ id }) => ({ id }))
		if (team.users) team.users = team.users.map(({ id }) => ({ id }))
		if (team.files) team.files = team.files.filter((file) => file.url && file.url != '')
		if (team.address && team.address.city == '') delete team.address

		return team_store.saveTeam({ team }).then((res) => {
			if (res.users) {
				const usersIds = res.users.map((user) => user.id)
				res.users = store.rootGetters.users.filter((user) => usersIds.indexOf(user.id) > -1)
			}
			store.commit('SAVE_NEW_TEAM', res)
			store.commit('INCREMENT_TEAMS_COUNT')
			store.commit('SORT_TEAM')
			store.dispatch('actionFetchTeams')
		})
	},

	editTeam: async ({ dispatch }, team) => {
		if (team.leaders) team.leaders = team.leaders.map(({ id }) => ({ id }))
		if (team.users) {
			const dedupeUsersArray = uniqBy(team.users, 'id')
			team.users = dedupeUsersArray.map(({ id }) => ({ id }))
		}
		if (team.files) team.files = team.files.filter((file) => file.url && file.url != '')
		if (team.address && team.address.city == '') delete team.address

		const responseEditedTeam = await team_store.saveEditedTeam({ team })
		dispatch('actionSetTeamsCount', responseEditedTeam.users?.length || 0)
		await dispatch('actionFetchTeams')
		await dispatch('actionSetActiveTeam', responseEditedTeam)
	},

	/**
	 * @method module:@team.deleteTeam
	 * @description call API to delete a given team, then decrease the team count by one
	 * @see {@link module:Model/Team.delete Team/delete}
	 *
	 * @param {Vuex.Store} store
	 * @param {Object} team - the team to delete
	 *
	 * @returns {Promise}
	 */
	deleteTeam: (store, team) => {
		return team_store.delete(team).then(() => {
			state.teams.splice(state.teams.indexOf(team), 1)
			store.commit('INCREMENT_TEAMS_COUNT', -1)
			store.commit('EDIT_END')
		})
	},

	/**
	 * @method module:@team.deleteTeams
	 * @description repeatedly dispatch {@link module:@team.deleteTeam deleteTeam}
	 * for each team in the state which is focused
	 * @param {Vuex.Store} store
	 */
	deleteTeams: (store, teams) => {
		teams.map((team) => {
			store.dispatch('deleteTeam', team)
		})
	},

	resetState: ({ commit }) => {
		commit('RESET_TEAMS')
		commit('EMPTY_ACTIVE_TEAM')
		commit('HIDE_DETAIL')
		commit('EDIT_END')
	},
}

const getters = {
	getTeamsAmount: (state) => state.teamsCount,
	getTeams: (state, getter, rootState) => getTeamsFiltered(state, rootState, false),
	getTeamsAllSpaces: (state, getter, rootState) => getTeamsFiltered(state, rootState, true),
	getAllTeams: (state) => state.teams,
	activeTeam: (state) => state.activeTeam,
	showDetail: (state) => state.showDetail,
	editing: (state) => state.editing,
}

export const teamModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
