import moment from 'moment-timezone'
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz'

export const getDateFromUTCToZoned = (date = new Date(), timezone = getCurrentTimezone()) => {
	return utcToZonedTime(date, timezone)
}

export const getCurrentTimezone = (): string => {
	return moment.tz.guess()
}

export const getTimezoneAbr = (date = new Date(), timezone: string = getCurrentTimezone()) => {
	return formatInTimeZone(date, timezone, 'zzz')
}
