import { $request } from './requester'

export default {
	/**
	 * PATCH `/mygroup`
	 */
	async setMapRootLabel(data) {
		return $request('PATCH', '/mygroup', data)
	},

	/**
	 * PATCH `/space/organisation_size`
	 */
	async setOrganisationSize(data) {
		return $request('PATCH', '/space/organisation_size', data)
	},

	/**
	 * PATCH `/space/features_of_interest`
	 */
	async setFeaturesOfInterest(data) {
		return $request('PATCH', '/space/features_of_interest', data)
	},
}
