import { v4 as uuidv4 } from 'uuid'

export function setBtnTooltip(btn: HTMLButtonElement, title: String) {
	btn.innerHTML = ''
	if (btn.hasAttribute('aria-describedby')) {
		btn.parentNode.removeChild(document.getElementById(btn.getAttribute('aria-describedby')))
	}
	btn.removeAttribute('aria-disabled')
	const tooltip = document.createElement('div')
	btn.parentNode.insertBefore(tooltip, btn.nextSibling)
	tooltip.innerText = title
	tooltip.id = uuidv4()
	tooltip.setAttribute('role', 'tooltip')
	btn.setAttribute('aria-describedby', tooltip.id)
}
