<template>
	<div class="q-fullwidth q-m-lg-l flex items-center">
		<span v-for="(tab, index) in computedGetTabs" :key="index">
			<tab-with-number
				v-if="tab.enabled"
				:title="tab.title"
				:selected="$route.name === tab.name"
				:status-pack="tab.status"
				:badge="tab.badge"
				:tag="tab.tag"
				:no-opacity="tab.noOpacity"
				font-size="1.25rem"
				class="mr-10 cursor-pointer"
				@tabClick="methodGoTo(tab)"
			>
			</tab-with-number>
		</span>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},
	computed: {
		...mapGetters('@packs', ['isEnabledAnalyticsDashboardTab']),
		...mapGetters(['info_campagne']),

		t() {
			const prefix = 'ANALYTICS.HEADER'
			return {
				dashboard: this.$t(`${prefix}.DASHBOARD`),
				detail: this.$t(`${prefix}.DETAIL`),
			}
		},

		computedGetTabs() {
			return [
				{
					title: this.t.dashboard,
					name: 'contacts-analyse',
					enabled: true,
					status: 'enabled',
				},
				{
					title: this.t.detail,
					name: 'analytics-detail',
					enabled: true,
					badge: 'option',
					tag: 'span',
					status: this.info_campagne.dashboardUrl ? 'enabled' : 'promoted',
				},
			]
		},
	},

	methods: {
		methodDisplayUpsellPopin(val) {
			this.computedIsUpsellPopinShown = val
		},
		methodGoTo(route) {
			this.$router.push({ name: route.name })
		},
	},
}
</script>
