// displaying the autosend variable tags requires some work, this file contains the generic functions
/* eslint-disable */
export const autosend = {
	data() {
		return {
			autosendVars: ['gender', 'firstname', 'surname'],
			autosendTagType: 's',
			autosendClass: 'contact-variables',
		}
	},

	methods: {
		tagToVar(string) {
			//? tag into $variable
			const regex =
				/(?<before>\<s class=\"autosend-img-tag\">)(?<content>[A-Zé ]+)(?<after><\/s>)/gi

			const replaceTagWith = (replacer) => {
				string = string.replace(new RegExp(regex, 'i'), replacer)
			}

			const results = string.matchAll(regex)
			for (let result of results) {
				let { content } = result.groups

				switch (content) {
					case this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.FIRSTNAME'):
						replaceTagWith('$firstname')
						break
					case this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.SURNAME'):
						replaceTagWith('$surname')
						break
					case this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.GENDER'):
						replaceTagWith('$gender')
						break
					default:
						console.log(`Sorry, cannot interpolate ${content} for the backend.`)
				}
			}
			return string
		},

		varToTag(string) {
			//? $variable into tag
			const regex = /(?<before>[^$.]*)(?<content>\$[A-Z]*name|\$gender)(?<after>[^$.]*)/gi
			const basicReplacementRegex = /\$[A-Z]*name|\$gender/i

			const replaceTagWith = (replacer) => {
				string = string.replace(
					new RegExp(basicReplacementRegex),
					`<s class="autosend-img-tag">${replacer}</s>`
				)
			}
			const results = string.matchAll(regex)
			for (let result of results) {
				let { content } = result.groups

				switch (content) {
					case '$firstname':
						replaceTagWith(`${this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.FIRSTNAME')}`)
						break
					case '$surname':
						replaceTagWith(`${this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.SURNAME')}`)
						break
					case '$gender':
						replaceTagWith(`${this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.GENDER')}`)
						break
					default:
						console.log(`Sorry, cannot interpolate ${content} from the backend.`)
				}
			}
			return string
		},

		getTag(tag) {
			//? probably not used anymore
			// images work much better, especially in an editor
			let path = this.$route.path
			// TODO - test on integration, not just local
			// src should be at top level, so if path is a sub-path, must go up the structure
			// take off leading/trailing "/" for consistency
			if (path.substr(0, 1) == '/') {
				path = path.substr(1)
			}
			if (path.substr(-1, 1) == '/') {
				path = path.substr(0, path.length - 1)
			}
			// count the number of remaining "/" characters; this is the number of sub paths
			let numSubs = (path.match(/\//g) || []).length
			// add a "../" for each sub path, or a "./" of there are no sub paths
			// Array(numSubs + 1) results in an empty array with numSubs "between" spots filled with the join string, "../"
			let srcPrefix = numSubs > 0 ? Array(numSubs + 1).join('../') : './'
			return `<img class="${this.autosendClass}"/>`
		},

		getTagRegex(tag) {
			//? probably not used anymore
			// quill can change the order of src and class, so have a more relaxed regex to find the tag
			// in between the <img ... >, there can be any alphabetic character, number, period, dash, slash equal sign, space
			// but importantly no ">" or "<" so that we stay in the same tag
			let fillerRegex = '[a-zA-Z0-9\\.\\\\\\/\\s\'"=-]*'
			// note: img is a self closing tag, so ends with just ">"
			return '<img' + fillerRegex + tag + fillerRegex + '>'
		},

		// takes an optional additional class
		tagOpen(c) {
			//? probably not used anymore
			let classStr = ''
			if (c != undefined) {
				classStr = ' ' + c
			}
			// contenteditable='false'
			return (
				'<' +
				this.autosendTagType +
				" class='auto-send-preview-tag" +
				classStr +
				"' style='font-style:normal;'>"
			)
		},
	},
}
