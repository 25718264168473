export const methodGetTypeLink = (link: string) => {
	let type = 'link'

	// Telegram link
	if (link.includes('t.me') || link.includes('telegram')) {
		type = 'telegram'
	}
	// Whatsapp link
	else if (link.includes('whatsapp')) {
		type = 'whatsapp'
	}
	// Facebook link
	else if (link.includes('facebook')) {
		type = 'facebook'
	}
	// Instagram link
	else if (link.includes('instagram')) {
		type = 'instagram'
	}
	// Linkedin link
	else if (link.includes('linkedin')) {
		type = 'linkedin'
	}
	// Messenger link
	else if (link.includes('messenger') || link.includes('m.me')) {
		type = 'messenger'
	}
	// Snapchat link
	else if (link.includes('snapchat')) {
		type = 'snapchat'
	}
	// Tiktok link
	else if (link.includes('tiktok')) {
		type = 'tiktok'
	}
	// Wechat link
	else if (link.includes('wechat') || link.includes('weixin')) {
		type = 'wechat'
	}
	// X link
	else if (link.includes('twitter') || link.includes('x.com')) {
		type = 'x'
	}
	// Youtube link
	else if (link.includes('youtube') || link.includes('youtu.be')) {
		type = 'youtube'
	}
	// Twitch link
	else if (link.includes('twitch')) {
		type = 'twitch'
	}
	// Twitch link
	else if (link.includes('threads.net')) {
		type = 'threads'
	}
	// Twitch link
	else if (link.includes('bsky.app')) {
		type = 'bluesky'
	}

	return type
}

export const methodRetrieveHostname = (link: string) => {
	// Use try catch because link not necessarily an url
	try {
		const urlLink = new URL(link)
		// Remove www when it's present
		return urlLink.hostname.replace(/^www\./, '')
	}
	catch (error) {
		return link
	}
}

export const methodReturnIconOrNameTypeLink = (typeLink, element, link) => {
	switch (typeLink) {
		case 'telegram':
			return element == 'icon' ? 'gui-telegram' : 'Telegram'
		case 'whatsapp':
			return element == 'icon' ? 'gui-whatsapp' : 'WhatsApp'
		case 'facebook':
			return element == 'icon' ? 'gui-facebook' : 'Facebook'
		case 'instagram':
			return element == 'icon' ? 'gui-Instagram' : 'Instagram'
		case 'linkedin':
			return element == 'icon' ? 'gui-linkedin' : 'LinkedIn'
		case 'messenger':
			return element == 'icon' ? 'gui-messenger' : 'Messenger'
		case 'snapchat':
			return element == 'icon' ? 'gui-snapchat' : 'Snapchat'
		case 'tiktok':
			return element == 'icon' ? 'gui-tiktok' : 'Tiktok'
		case 'wechat':
			return element == 'icon' ? 'gui-wechat' : 'WeChat'
		case 'x':
			return element == 'icon' ? 'gui-x' : 'X'
		case 'youtube':
			return element == 'icon' ? 'gui-youtube' : 'Youtube'
		case 'twitch':
			return element == 'icon' ? 'gui-twitch' : 'Twitch'
		case 'threads':
			return element == 'icon' ? 'gui-threads' : 'Threads'
		case 'bluesky':
			return element == 'icon' ? 'gui-bluesky' : 'Bluesky'
		default:
			return element == 'icon' ? 'gui-link' : methodRetrieveHostname(link)
	}
}
