import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-10 items-center justify-center w-full h-screen background-customer" }
const _hoisted_2 = { class: "w-2/3 sm:w-1/2 xl:w-1/4 flex flex-col gap-y-10 p-6 md:p-10 justify-center items-center bg-white rounded shadow-lg" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center gap-y-2 text-center" }
const _hoisted_4 = { class: "text-2xl font-bold" }
const _hoisted_5 = { class: "text-gray-700 text-md" }
const _hoisted_6 = { class: "w-full flex flex-col gap-y-4 items-center justify-center" }
const _hoisted_7 = { class: "animate-flicker text-center background-button text-white w-full p-4 cursor-pointer hover:opacity-75" }

import { watch, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginAuth0Loading',
  setup(__props) {

const auth0 = useAuth0()
const store = useStore()
const router = useRouter()
const { t } = useI18n()

const { idTokenClaims, handleRedirectCallback } = auth0

const getStatusAuth = computed(() => store.getters['@authentication/getStatusAuth'])
const getAuth0MainEmail = computed(() => store.getters['@authentication/getAuth0MainEmail'])

watch(getStatusAuth, () => {
	if (getStatusAuth.value) {
		switch (getStatusAuth.value) {
			case 'success':
				router.push({ name: 'command-center' })
				store.dispatch('@authentication/actionResetAuthenticationModule')
				break

			case 'failed':
				router.push({ name: 'login' })
				break

			case 'nopermission':
				router.push({ name: 'download' })
				break

			case 'external_provider/emailvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'add-account-details' })
				break
			}

			case 'external_provider/emailnotvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'validate-email' })
				break
			}

			case 'emailnotvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'validate-email' })
				break
			}

			case 'nospacejoined': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'join-a-group' })
				break
			}

			case 'error':
				router.push({ name: 'login' })
				break

			default:
				break
		}
	}
})

watch(idTokenClaims, () => {
	if (idTokenClaims?.value) {
		const token = idTokenClaims?.value?.__raw
		store.dispatch('@authentication/actionLoginApplication', { raw_id_token: token })
	}
})

onMounted(() => {
	handleRedirectCallback()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      class: "w-48",
      src: "/static/logo/QomonBlack.svg"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "w-24",
        src: "/static/logo/RFKActivate2024.svg"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('APP.LOGIN.AUTH0.PLEASE_WAIT')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('APP.LOGIN.AUTH0.CONNECTING')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_unref(t)('APP.LOGIN.AUTH0.LOADING')), 1)
        ])
      ])
    ])
  ]))
}
}

})