import { $request, onError } from './requester_prio'

let _requestsThatCanBeAborted = []
let _searchRequests = []

let _geoRootInfo = {}
const getCacheArgs = () => {
	// eslint-disable-next-line no-console
	if (!_geoRootInfo.app_version) console.error('Version not set, weird.')
	return `app_version=${encodeURIComponent(
		_geoRootInfo.app_version,
	)}&last_denormalized_ts=${encodeURIComponent(_geoRootInfo.last_denormalized_ts)}`
}
/**
 * @model Command
 */
export default {
	setGeoRootInfo(geoRootInfo) {
		_geoRootInfo = geoRootInfo
	},
	/**
	 * @method getGeoRootInfo
	 * @param {String} geoRootAlias
	 * @param {String} preferredLanguageLocale
	 * @param {Array<String>} authorizedScales
	 * @param {boolean} isPublic
	 * @param {boolean} canBeAborted
	 * @return {Promise<*>} - Geo root info response
	 */
	async getGeoRootInfo(
		{ geoRootAlias, preferredLanguageLocale, authorizedScales, isPublic = false },
		canBeAborted = false,
	) {
		const payload = {
			preferred_language_locale: preferredLanguageLocale,
			authorized_scales: authorizedScales,
		}
		if (geoRootAlias === undefined || geoRootAlias === 'undefined' || geoRootAlias === null) {
			// This shouldn't ever happen, a hard refresh should fix this.
			// The identified source of the error is an incorrect cache invalidation somewhere in the frontend
			if (window.location.href.indexOf('force_reload=true') === -1) {
				const separator = window.location.href.indexOf('?') === -1 ? '?' : '&'
				window.location.href = window.location.href + separator + 'force_reload=true'
			}
			else {
				console.error('Not possible, space is badly configured')
			}
		}
		else {
			const req = $request('POST', `/live/v1/geo-roots/${geoRootAlias}`, payload, null, isPublic)
			if (canBeAborted === true) {
				_requestsThatCanBeAborted.push(req)
			}
			return req.then((res) => res.body)
		}
	},

	/**
	 * @method getDatalake
	 * @return {Promise<*>} - Architecture to map to the datalake
	 */
	async getDatalake({ archi_data_carto, payload, geoContextPayload }) {
		if (typeof payload !== 'object') {
			payload = { parents: { scale: 'country' } }
		}
		payload.geo_context = geoContextPayload
		const req = $request(
			'POST',
			`/architecture_dyn?archi_data_carto=${archi_data_carto}&${getCacheArgs()}`,
			payload,
		)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * @method getPrioArchi
	 * @return {Promise<*>} - Architecture to map to the datalake for priorisation
	 */
	async getPrioArchi({ archi_data_carto, payload, geoContextPayload }) {
		payload.geo_context = geoContextPayload
		const req = $request(
			'POST',
			`/architecture_prio?archi_data_carto=${archi_data_carto}&${getCacheArgs()}`,
			payload,
		)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * @method getDataPerScope
	 *
	 * @param {Array} scope
	 * @param {String} scale
	 * @param {Array} additionalData
	 * @param {String} preferredLanguageLocale
	 *
	 * @returns {Promise<Array>}     request body
	 */
	async getDataPerScope({
		scale,
		additionalData = [],
		geoContextPayload,
		preferredLanguageLocale,
	}) {
		const req = $request('POST', `/query?${getCacheArgs()}`, {
			geo_context: geoContextPayload,
			variables: [
				...additionalData.map((d) => {
					return {
						...d,
						should: [], // to remove after Archi has changed
					}
				}),
			],
			num_digits: 2,
			scale,
			drop_lower_min: true,
			preferred_language_locale: preferredLanguageLocale,
		})
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * @method getPriorisation
	 *
	 * @param {Array} variables
	 * @param {String} preferredLanguageLocale
	 *
	 * @returns {Promise<Array>}     request body
	 */
	async getPriorisation({ variables = [], geo_context, preferredLanguageLocale, isPublic = false }) {
		const req = $request('POST', `/v1/map-territories-scoring?${getCacheArgs()}`, {
			geo_context,
			variables,
			num_categories: 5,
			preferred_language_locale: preferredLanguageLocale,
		}, onError, isPublic)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * See API doc for more information
	 * @returns {Promise<Array>}     request body
	 */
	async getMapTerritoriesData({
		main_variable,
		extra_variables = [],
		geo_context,
		preferred_language_locale,
	}) {
		const payload = {
			geo_context,
			main_variable,
			extra_variables,
			preferred_language_locale,
		}
		const req = $request('POST', `/v1/map-territories-data?${getCacheArgs()}`, payload)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * See API doc for more information
	 * @returns {Promise<Array>}     request body
	 */
	async getBoundingBox({ geo_context, isPublic = false }) {
		const payload = {
			...geo_context,
		}
		const shouldBeLive = geo_context.displayed.layer_id || geo_context.parents.layer_id
		const req = $request('POST', `${shouldBeLive ? '/live' : ''}/v1/bounding-box?${getCacheArgs()}`, payload, onError, isPublic)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	/**
	 * See API doc for more information
	 * @returns {Promise<Array>}     request body
	 */
	async getPolygon({ geo_context, buffer = 0, perform_union_before_simplification = false }) {
		const payload = {
			...geo_context,
			buffer,
			perform_union_before_simplification,
		}
		const shouldBeLive = geo_context.displayed.layer_id || geo_context.parents.layer_id
		const req = $request('POST', `${shouldBeLive ? '/live' : ''}/v1/polygon?${getCacheArgs()}`, payload)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},

	abortRequests() {
		_requestsThatCanBeAborted.forEach((req) => {
			req.abort()
		})
		_requestsThatCanBeAborted = []
	},

	/**
	 * See API doc for more information
	 * @returns {Promise<Array>}     request body
	 */
	async search({ search_context, isPublic }) {
		const payload = {
			...search_context,
		}
		const req = $request('POST', '/v1/search', payload, onError, isPublic)
		this.abortSearchRequests()
		_searchRequests.push(req)
		return req.then((res) => res.body)
	},
	abortSearchRequests() {
		_searchRequests.forEach((req) => {
			req.abort()
		})
		_searchRequests = []
	},

	/**
	 * See API doc for more information
	 * @returns {Promise<Array>}     request body
	 */
	async getAvailableScales({ geo_context, authorized_scales, highlighted_territory_id, isPublic = false }) {
		const payload = { geo_context, authorized_scales }
		// we force the availabilities in this case
		if (highlighted_territory_id !== null) {
			payload.min_n_scale_intersections = 1
		}
		const req = $request('POST', '/live/v1/available-scales', payload, (e) => {
			// sometimes there is a weird shitty error that causes an incorrect payload to be send
			// to the data backend, so we know return a 409 and force a reload.
			// The identified source of the error is an incorrect cache invalidation somewhere in the frontend
			if (
				e.response?.statusCode === 409
				&& window.location.href.indexOf('force_reload=true') === -1
			) {
				const separator = window.location.href.indexOf('?') === -1 ? '?' : '&'
				window.location.href = window.location.href + separator + 'force_reload=true'
			}
			else {
				onError(e)
			}
		}, isPublic)
		_requestsThatCanBeAborted.push(req)
		return req.then((res) => res.body)
	},
	abortAllRequests() {
		this.abortRequests()
		this.abortSearchRequests()
	},
}
