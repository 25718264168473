<template>
	<div
		class="flex flex-col gap-y-10 items-center justify-center w-full h-screen background-customer"
	>
		<img
			class="w-48"
			src="/static/logo/QomonBlack.svg"
		>
		<div
			class="w-2/3 sm:w-1/2 xl:w-1/4 flex flex-col gap-y-10 p-6 md:p-10 justify-center items-center bg-white rounded shadow-lg"
		>
			<img
				class="w-24"
				src="/static/logo/RFKActivate2024.svg"
			>
			<div class="flex flex-col items-center justify-center gap-y-2 text-center">
				<div class="text-2xl font-bold">
					{{ t('APP.LOGIN.AUTH0.PLEASE_WAIT') }}
				</div>
				<div class="text-gray-700 text-md">
					{{ t('APP.LOGIN.AUTH0.CONNECTING') }}
				</div>
			</div>

			<div class="w-full flex flex-col gap-y-4 items-center justify-center">
				<div
					class="animate-flicker text-center background-button text-white w-full p-4 cursor-pointer hover:opacity-75"
				>
					<span>{{ t('APP.LOGIN.AUTH0.LOADING') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { watch, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'

const auth0 = useAuth0()
const store = useStore()
const router = useRouter()
const { t } = useI18n()

const { idTokenClaims, handleRedirectCallback } = auth0

const getStatusAuth = computed(() => store.getters['@authentication/getStatusAuth'])
const getAuth0MainEmail = computed(() => store.getters['@authentication/getAuth0MainEmail'])

watch(getStatusAuth, () => {
	if (getStatusAuth.value) {
		switch (getStatusAuth.value) {
			case 'success':
				router.push({ name: 'command-center' })
				store.dispatch('@authentication/actionResetAuthenticationModule')
				break

			case 'failed':
				router.push({ name: 'login' })
				break

			case 'nopermission':
				router.push({ name: 'download' })
				break

			case 'external_provider/emailvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'add-account-details' })
				break
			}

			case 'external_provider/emailnotvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'validate-email' })
				break
			}

			case 'emailnotvalid': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'validate-email' })
				break
			}

			case 'nospacejoined': {
				const token = idTokenClaims?.value?.__raw
				store.dispatch('@authentication/actionSetCredentialsEntered', {
					email: getAuth0MainEmail.value,
					raw_id_token: token,
				})
				router.push({ name: 'join-a-group' })
				break
			}

			case 'error':
				router.push({ name: 'login' })
				break

			default:
				break
		}
	}
})

watch(idTokenClaims, () => {
	if (idTokenClaims?.value) {
		const token = idTokenClaims?.value?.__raw
		store.dispatch('@authentication/actionLoginApplication', { raw_id_token: token })
	}
})

onMounted(() => {
	handleRedirectCallback()
})
</script>

<style scoped>
@keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.animate-flicker {
	-webkit-animation: flickerAnimation 3s infinite;
	-moz-animation: flickerAnimation 3s infinite;
	-o-animation: flickerAnimation 3s infinite;
	animation: flickerAnimation 3s infinite;
}

.background-customer {
	background-color: #f6f6f6;
}
.background-button {
	background-color: #ff0031;
}
.color-customer {
	color: #2b2c78;
}
.color-secondary-customer {
	color: #ff0031;
}
</style>
