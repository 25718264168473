import { $request } from './requester'

export default {
	async getTagsInteractions() {
		return $request('GET', '/interactions/tags').then((response) => {
			return response.body.data
		})
	},

	async addTagInteraction(tag) {
		return $request('POST', '/interactions/tags', tag).then((response) => {
			return response.body.data
		})
	},

	async updateTagInteraction(tag) {
		return $request('PATCH', `/interactions/tags/${tag.id}`, tag).then((response) => {
			return response.body.data
		})
	},

	async deleteTagInteraction(tagId) {
		return $request('DELETE', `/interactions/tags/${tagId}`).then(() => {
			return true
		})
	},
}
