export function methodFindSanctionAuthorityById(
	sanctionAuthoritiesList = [],
	sanctionAuthorityId = null
) {
	const sanctionAuthority = sanctionAuthoritiesList.find(
		(sanction) => sanction.id === sanctionAuthorityId
	)
	return sanctionAuthority
}

export function isDefinitiveSanction(date) {
	let year = new Date(date).getFullYear()
	if (year >= 2999) {
		return true
	} else {
		return false
	}
}
export function methodFindSanctionTypeById(sanctionsList = [], sanctionTypeId = null) {
	const sanctionType = sanctionsList.find((sanction) => sanction.id === sanctionTypeId)
	return sanctionType
}
