export const mappingStatutColor = {
	default: '#FFFFFF',
	prospect: '#C99BF4',
	uncertain: '#FFE383',
	enforceSupport: '#048451',
	enforceSupportWeak: '#0FF89B',
	member: '#4ACAF4',
	avoid: '#000000',
	opponentWeak: '#FFD0D0',
	opponent: '#FF6A6A',
}

export const getPrimaryStatusColor = (statusType) => {
	return statusType ? mappingStatutColor[statusType] : mappingStatutColor['default']
}

// if magnitude is negative value  (ex -60) > more darken color
// if magnitude is positive value (ex 60) -> more ligthen color
export const newLighterOrDarkenColor = (hexColor, magnitude) => {
	hexColor = hexColor.replace(`#`, ``)
	if (hexColor.length === 6) {
		const decimalColor = parseInt(hexColor, 16)
		let r = (decimalColor >> 16) + magnitude
		r > 255 && (r = 255)
		r < 0 && (r = 0)
		let g = (decimalColor & 0x0000ff) + magnitude
		g > 255 && (g = 255)
		g < 0 && (g = 0)
		let b = ((decimalColor >> 8) & 0x00ff) + magnitude
		b > 255 && (b = 255)
		b < 0 && (b = 0)
		return `#${(g | (b << 8) | (r << 16)).toString(16)}`
	} else {
		return hexColor
	}
}
