import { $request } from './requester'

export default {
	async fetchAllManip() {
		return $request('GET', '/massive-manipulations/jobs')
			.then((res) => {
				return res.body.data
			})
			.catch(() => {
				throw new Error('Impossible to get the list of massive manipulations')
			})
	},
	async fetchAllContactModifications() {
		return $request('GET', '/metamorph')
			.then((res) => {
				return res.body.data
			})
			.catch(() => {
				throw new Error('Impossible to get the list of massive contact modifications')
			})
	},
	async fetchManipById(id) {
		return $request('GET', `/massive-manipulations/status/${id}`)
			.then((res) => {
				return res.body.data
			})
			.catch(() => {
				throw new Error(`Impossible to get massive manipulation: ${id}`)
			})
	},
	async fetchContactMassiveManipStatus(idContact) {
		return $request('GET', `/massive-manipulations/contact-status/${idContact}`)
			.then((res) => {
				return res.body.data
			})
			.catch(() => {
				throw new Error(
					`Impossible to get the status of the contact ${idContact} (massive manipulation)`
				)
			})
	},
	async startMassiveDeletion(data) {
		return $request('POST', '/massive-manipulations', data)
			.then((res) => {
				return res
			})
			.catch(() => {
				throw new Error('Impossible to start massive suppression')
			})
	},
}
