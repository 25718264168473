import { $request } from './requester'

export const fetchAnImport = (id) => {
	return $request('GET', `/imports/${id}`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the import')
		})
}
export const canCreateImport = () => {
	return $request('GET', `/imports/cancreate`)
		.then((res) => {
			return res
		})
		.catch((err) => {
			if (err.response && err.response.status) {
				return err.response
			}
			else {
				throw new Error('Impossible to create import')
			}
		})
}

export const fetchAnImportConflicts = (id) => {
	return $request('GET', `/imports/${id}/conflicts`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the list of conflicts')
		})
}

export const fetchAllImports = () => {
	return $request('GET', '/imports')
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the list of imports')
		})
}

export const fetchDownloadInvalidLines = (id) => {
	return $request('GET', `/imports/${id}/invalid-lines?format=csv`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the invalid lines of the file')
		})
}

export const fetchPreviewFileUploaded = (id, data) => {
	return $request('GET', `/imports/${id}/file-preview`, data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the preview of the file')
		})
}

export const fetchPreviewFileUploadedColumns = (id, data) => {
	return $request('GET', `/imports/${id}/columns`, data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the preview columuns of the file')
		})
}

export const fetchSampleLinesFileUploaded = (id) => {
	return $request('GET', `/imports/${id}/sample-lines`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the sample lines of the file')
		})
}

export const fetchQuorumProperties = (id) => {
	return $request('GET', `/imports/${id}/properties`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the properties of import')
		})
}

export const fetchQuorumStatuses = (id) => {
	return $request('GET', `/imports/${id}/statuses`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the statuses of import')
		})
}

export const fetchConfigImport = (id) => {
	return $request('GET', `/imports/${id}`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the config of the import')
		})
}

export const postSaveSettingsFileImported = (id, data) => {
	return $request('POST/FORM', `/imports/${id}/csv-settings`, data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to save the settings of the file')
		})
}

export const postSaveCSVMapping = (id, data) => {
	return $request('POST/WITHOUT_DATA_KEY', `/imports/${id}/mapping`, data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to save the mapping of the file')
		})
}

export const patchSetNameImport = (id, name) => {
	return $request('POST/FORM', `/imports/${id}/name`, name)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('')
		})
}

export const deleteSoftlyAnImport = (id) => {
	return $request('DELETE', `/imports/${id}`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('')
		})
}

export const postStartImportEnqueued = (id) => {
	return $request('POST', `/imports/${id}/enqueue?channel=1`)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to start the enqueue of the file')
		})
}

export const postResolveImportConflicts = (id, data) => {
	return $request('POST/FORM', `/imports/${id}/resolve`, data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to set the strategy to resolve the import conflict statement')
		})
}

export const postAIMatchColumns = (data) => {
	return $request('POST/WITHOUT_DATA_KEY', `/qomonai-api/v1/match-columns`, data)
		.then((res) => {
			return res.body
		})
		.catch(() => {
			throw new Error('Impossible to match the columns with AI')
		})
}

export const postAIMatchColumnsForced = (data) => {
	return $request('POST/WITHOUT_DATA_KEY', `/qomonai-api/live/v1/match-columns`, data)
		.then((res) => {
			return res.body
		})
		.catch(() => {
			throw new Error('Impossible to match the columns with AI')
		})
}
