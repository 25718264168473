import { i18n } from '../middlewares/i18nizer'
import store from '../store/stateStore'

export const methodRetrieveNameAccordingType = (role: any, count: number = 0) => {
	const getWordingByRoles = () => {
		switch (role?.type) {
			case 'superadmin':
				return i18n.global.tc('SETTINGS.PROFILE.ROLES.SUPERADMIN', count)
			case 'admin':
				return i18n.global.tc('SETTINGS.PROFILE.ROLES.ADMIN', count)
			case 'manager':
				return i18n.global.tc('SETTINGS.PROFILE.ROLES.MANAGER', count)
			case 'user':
				return i18n.global.tc('SETTINGS.PROFILE.ROLES.USER', count)
			default:
				return role?.name
		}
	}

	if (role?.name) {
		return role?.name
	}

	return getWordingByRoles()
}

export const methodIsAccessGranted = (roleWanted: string) => {
	return store.getters['userConnected']?.role_data?.access?.includes(roleWanted)
}
