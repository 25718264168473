import { MAP } from '../../constants'
import maplibregl from 'maplibre-gl'
import * as turf from '@turf/turf'
import { latLngIsInNorthPole } from '../../utils/geo'

export function methodSetDefaultPolygonBasedOnGeoHashContacts(currentUnitValue, geoHashContactArray, disabled = false) {
	if (!disabled) {
		if (geoHashContactArray && geoHashContactArray.length) {
			const units = mappingUnits(currentUnitValue) || 'kilometers'
			const hashes = geoHashContactArray
				.map(([lat, lng, count]) => ({ lat: Number(lat), lng: Number(lng), count }))
				.filter(({ lat, lng }) => !latLngIsInNorthPole(lat, lng))

			let pointFeatures = null
			let center = null
			if (hashes.length) {
				pointFeatures = hashes.map(({ lat, lng, count }) => turf.point([lng, lat], { count }))
			}

			if (pointFeatures) {
				center = turf.centerMedian(turf.featureCollection(pointFeatures), { weight: 'count' })
				pointFeatures.forEach((p) => p.properties.distanceToCenter = turf.distance(p, center, { units: units }))
				// We consider the distance to follow a log-normal distribution since all values are positive
				// https://en.wikipedia.org/wiki/Log-normal_distribution
				const distanceLogs = pointFeatures.map((p) => Math.log(p.properties.distanceToCenter))
				// Estimate the parameters mu and sigma of the log-normal distribution
				const distanceLogMean = distanceLogs.reduce((a, b) => a + b) / pointFeatures.length
				const distanceLogStd = Math.sqrt(distanceLogs.map((d) => Math.pow(d - distanceLogMean, 2)).reduce((a, b) => a + b) / pointFeatures.length)
				// Don't accept contacts too far away from the center of mass
				const maxAllowedDistanceKm = Math.max(10, Math.exp(distanceLogMean - 0.5 * distanceLogStd))
				const filteredPoints = pointFeatures.filter((p) => p.properties.distanceToCenter <= maxAllowedDistanceKm)

				if (filteredPoints && filteredPoints.length > 0) {
					const polygon = turf.convex(turf.featureCollection(filteredPoints))
					if (polygon) {
						// Extends the polygon, making it bigger, rounder and better looking :D (subjective)
						const buffered = turf.buffer(polygon, 500, { units: 'meters' })
						return buffered.geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }))
					}
				}
			}
		}
	}

	return null
}

export function userIsInsideArea(user, area) {
	const polygon = turf.polygon(area)

	if (user.location) {
		const localUserCoordinate = user.location.split(',').map(Number).reverse()
		return turf.booleanPointInPolygon(localUserCoordinate, polygon)
	}
	else {
		return false
	}
}

export function getCircleData(map, whichCircle = 'polygon') {
	const circleName = whichCircle === 'polygon' ? 'circle' : 'meetingPoint'
	const circleSource = map.getSource(`${circleName}Source`)
	if (circleSource) {
		const circleData = circleSource._data

		return circleData
	}
	else {
		return null
	}
}

function setEndOfLine(map, position) {
	const smallCircleSourceId = 'smallCircleSource'
	const smallCircleLayerId = 'smallCircleLayer'

	if (map.getSource(smallCircleSourceId)) {
		map.getSource(smallCircleSourceId).setData({
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: position,
			},
			layout: {
				visibility: 'none',
			},
		})
	}
	else {
		map.addSource(smallCircleSourceId, {
			type: 'geojson',
			data: {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: position,
				},
				visibility: 'visible',
			},
		})

		map.addLayer({
			id: smallCircleLayerId,
			type: 'circle',
			source: smallCircleSourceId,
			paint: {
				'circle-radius': 5,
				'circle-color': '#ff387f',
			},
			layout: {
				visibility: 'none',
			},
		})
	}
}

export function getRightmostPointFromCircle(circleData) {
	if (!circleData || !circleData.geometry || !circleData.geometry.coordinates) {
		return null
	}

	const coordinates = circleData.geometry.coordinates[0]
	let rightmostPoint = coordinates[0]
	for (const point of coordinates) {
		if (point[0] > rightmostPoint[0]) {
			rightmostPoint = point
		}
	}
	return rightmostPoint
}

export function addCheckedClassToSelectedUsers(users) {
	users.forEach((user) => {
		findElementIdByUserAndAddClass(user, 'marker-checked')
	})
}
export function addLockedClassToSelectedUsers(users) {
	users.forEach((user) => {
		findElementIdByUserAndAddClass(user, 'locked-marker')
	})
}
export function addUsernameTooltipClassUsers(users) {
	users.forEach((user) => {
		findElementIdByUserAndAddClass(user, 'username-tooltip')
	})
}

export function addLockedByTeamClassToUsers(users) {
	users.forEach((user) => {
		findElementIdByUserAndAddClass(user, 'locked-by-team')
	})
}

export function addCheckedClassToSelectedActionLeaders(actionLeaders) {
	actionLeaders.forEach((user) => {
		findElementIdByUserAndAddLeadersCheckedClass(user)
	})
}

export function removeGrayScaledClassToSelectedUsers(users) {
	users.forEach((user) => {
		findElementIdByUserAndRemoveClass(user, 'member-grayscaled', 'marker-')
	})
}

// export function removeLockedClassToSelectedUsers(users) {
// 	users.forEach((user) => {
// 		findElementIdByUserAndRemoveClass(user, 'locked-marker')
// 	})
// }

export function addGrayscaledClassInAllUsers() {
	const elements = document.querySelectorAll('[id^="membermarker-"]')
	elements.forEach((element) => {
		element.classList.add('member-grayscaled')
	})
}

function findElementIdByUserAndAddClass(user, className = 'marker-checked') {
	const element = document.getElementById('marker-' + user.id)
	if (element) {
		element.classList.add(className)
	}
}
function findElementIdByUserAndAddLeadersCheckedClass(user) {
	const element = document.getElementById('marker-' + user.id)
	if (element) {
		element.classList.add('action-leaders')
	}
}

// function findElementIdByUserAndRemoveCheckedClass(user) {
// 	const element = document.getElementById('marker-' + user.id)
// 	if (element) {
// 		element.classList.add('marker-checked')
// 	}
// }

function findElementIdByUserAndRemoveClass(user, className = 'member-grayscaled', prefixId = 'marker-') {
	const element = document.getElementById(prefixId + user.id)
	if (element) {
		element.classList.remove(className)
	}
}

export function resetMarkerCheckedClass() {
	const elements = document.querySelectorAll('[id^="marker-"]')
	elements.forEach((element) => {
		// element.classList.remove('username-tooltip')
		element.classList.remove('marker-checked')
		element.classList.remove('locked-marker')
		element.classList.remove('locked-by-team')
		element.classList.remove('action-leaders')
	})
}
export function resetMarkerCheckedLeaderClass() {
	const elements = document.querySelectorAll('[id^="marker-"]')
	elements.forEach((element) => {
		element.classList.remove('action-leaders')
	})
}

export function addMembersMarkers(map: Map, users: any, methodOnMemberClick: function, methodOnMemberHover: function = () => {}) {
	const iconSize = [50, 50]

	if (users && users.length) {
		users.forEach((user) => {
			if (user.location) {
				const localCoordinate = user.location.split(',').map(Number).reverse()

				const parentEl = document.createElement('div')
				const el = document.createElement('div')
				el.id = `membermarker-${user.id}`
				const checkedItem = document.createElement('div')
				checkedItem.id = `marker-${user.id}`

				el.className = `marker membermarker`
				el.style.width = `${iconSize[0]}px`
				el.style.height = `${iconSize[1]}px`
				el.style.backgroundColor = `white`
				if (user.avatar && user.avatar !== 'null') {
					el.style.backgroundImage
				= `url(${user.avatar})`
					el.style.backgroundSize = 'cover'
				}
				else {
					const initials = `${user?.firstname[0] || ''}${user?.surname[0] || ''}`.toUpperCase()
					el.style.display = 'flex'
					el.style.alignItems = 'center'
					el.style.justifyContent = 'center'
					el.style.backgroundColor = 'gray'
					el.style.color = 'white'
					el.style.fontSize = '14px'
					el.textContent = initials
				}

				// tootltips

				const tooltip = document.createElement('span')
				tooltip.id = `tooltip-id-${user.id}`
				tooltip.className = 'tooltip-user-marker'
				tooltip.innerHTML = ''
				tooltip.style.position = 'absolute'
				tooltip.style.top = '60px'
				tooltip.style.backgroundColor = '#00000063'
				tooltip.style.color = 'white'
				tooltip.style.padding = '5px'
				tooltip.style.width = '200px'
				tooltip.style.borderRadius = '4px'
				tooltip.style.fontSize = '10px'

				tooltip.style.zIndex = '99999999999999'
				tooltip.style.transform = 'translateZ(0)'
				tooltip.style.pointerEvents = 'none'

				// Afficher le tooltip au survol
				el.addEventListener('mouseenter', () => {
					methodOnMemberHover(user)
				})

				el.addEventListener('mouseleave', () => {

				})

				parentEl.addEventListener('mouseenter', () => {
					parentEl.style.zIndex = '2'
				})

				parentEl.addEventListener('mouseleave', () => {
					parentEl.style.zIndex = '1'
				})

				// document.body.appendChild(tooltip)
				parentEl.appendChild(tooltip)

				// end tooltips

				parentEl.addEventListener('click', () => {
					parentEl.style.zIndex = '9999'
					methodOnMemberClick(user)
				})

				parentEl.appendChild(el)
				parentEl.appendChild(checkedItem)

				// parentEl.appendChild(checkedItem)

				new maplibregl.Marker({ element: parentEl })
					.setLngLat(localCoordinate)
					.addTo(map)
			}
		})
	}
}
export function addActionPolygon(map: Map, polygon: any) {
	const polygonCoordinates = polygon.map((point) => [point.lng, point.lat])

	map.on('load', () => {
		if (!map.getSource('actionPolygon')) {
			map.addSource('actionPolygon', {
				type: 'geojson',
				data: {
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: [polygonCoordinates],
					},
				},
			})
		}
		else {
			const source = map.getSource('actionPolygon') as mapboxgl.GeoJSONSource
			source.setData({
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [polygonCoordinates],
				},
			})
		}

		if (map.getLayer('actionPolygonFill')) {
			map.removeLayer('actionPolygonFill')
		}

		map.addLayer({
			id: 'actionPolygonFill',
			type: 'fill',
			source: 'actionPolygon',
			layout: {},
			paint: {
				'fill-color': 'black',
				'fill-opacity': 0.2,
			},
		})

		if (map.getLayer('actionPolygonOutline')) {
			map.removeLayer('actionPolygonOutline')
		}

		map.addLayer({
			id: 'actionPolygonOutline',
			type: 'line',
			source: 'actionPolygon',
			layout: {},
			paint: {
				'line-color': '#000',
				'line-width': 3,
			},
		})
	})

	// adjustZoomAndView(map, polygonCoordinates)
}

export function adjustZoomAndView(map: Map, coordinates: any, callBackMethod = () => {}) {
	callBackMethod(coordinates)
	const bounds = coordinates.reduce((bounds, coord) => {
		return bounds.extend(coord)
	}, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]))

	const distance = bounds.getNorthEast().distanceTo(bounds.getSouthWest())
	let maxZoom = 10 // par défaut
	if (distance > 100000) {
		maxZoom = 8
	}
	else if (distance > 50000) {
		maxZoom = 11
	}

	map.fitBounds(bounds, {
		padding: 40,
		maxZoom: maxZoom,
		duration: 0,
	})
}

export function getCenterOfPolygon(polygon) {
	const polygonCoordinates = polygon.map((point) => [point.lng, point.lat])

	if (
		polygonCoordinates?.length > 0
		&& (polygonCoordinates[0][0] !== polygonCoordinates[polygonCoordinates.length - 1][0]
		|| polygonCoordinates[0][1] !== polygonCoordinates[polygonCoordinates.length - 1][1])
	) {
		polygonCoordinates.push([...polygonCoordinates[0]])
	}

	const polygonGeoJSON = turf.polygon([polygonCoordinates])
	const center = turf.centroid(polygonGeoJSON).geometry.coordinates
	return center
}

export function getMaxDistanceFromCenterToPolygon(polygon) {
	let maxDistance = 0
	if (polygon) {
		const center = getCenterOfPolygon(polygon)

		polygon.forEach((coord) => {
			const point = [coord.lng, coord.lat]
			const distance = turf.distance(center, point, { units: 'kilometers' })
			if (distance > maxDistance) {
				maxDistance = distance
			}
		})
	}

	return maxDistance
}

export function addAiPolygonArea(map, geoJson) {
	const polygonName = 'polygonAI'

	// Vérifier si des points sont fournis, sinon créer un pixel vide

	// Vérifier si la source existe déjà
	if (!map.getSource(`${polygonName}Source`)) {
		const coordinates = geoJson?.geometry?.coordinates[0] || [[0, 0], [0.00001, 0], [0.00001, 0.00001], [0, 0.00001], [0, 0]]
		const polygonGeoJSON = turf.polygon([coordinates])

		// Ajouter la source du polygone
		map.addSource(`${polygonName}Source`, {
			type: 'geojson',
			data: polygonGeoJSON,
		})

		// Ajouter la couche du polygone
		map.addLayer({
			id: `${polygonName}Layer`,
			type: 'fill',
			source: `${polygonName}Source`,
			paint: {
				'fill-color': '#ff387f',
				'fill-opacity': 0.3,
			},
			layout: {
				visibility: 'none', // Rendre le polygone visible
			},
		})
	}
	else {
		// Mettre à jour le polygone si la source existe déjà
		updateAiPolygonArea(map, geoJson)
	}
}

export function mappingUnits(value = 'km') {
	switch (value) {
		case 'km': return 'kilometers'
		case 'm': return 'meters'
		case 'miles': return 'miles'
		default: return 'kilometers'
	}
}
// Fonction pour mettre à jour le polygone existant
export function updateAiPolygonArea(map, geoJson) {
	const coordinates = geoJson?.geometry?.coordinates[0] || [[0, 0], [0.00001, 0], [0.00001, 0.00001], [0, 0.00001], [0, 0]]
	const polygonGeoJSON = turf.polygon([coordinates])
	const polygonSource = map.getSource('polygonAISource')

	if (polygonSource) {
		polygonSource.setData(polygonGeoJSON)
	}
	else {
		// console.log('No polygon source found to update')
	}
}

export function addCircleFromCenter(map, polygon, radiusInKm, whichCircle) {
	const circleName = whichCircle === 'polygon' ? 'circle' : 'meetingPoint'

	const center = getCenterOfPolygon(polygon)

	const radiusInMeters = radiusInKm * 1000

	if (!map.getSource(`${circleName}Source`)) {
		const options = { steps: 64, units: 'meters', properties: {} }
		const circle = turf.circle(center, radiusInMeters, options)

		map.addSource(`${circleName}Source`, {
			type: 'geojson',
			data: circle,
		})

		map.addLayer({
			id: `${circleName}Layer`,
			type: 'fill',
			source: `${circleName}Source`,
			paint: {
				'fill-color': circleName === 'circle' ? '#ff387f' : '#BABEF2',
				'fill-opacity': 0.3,
			},
			layout: {
				visibility: 'none',
			},

		})
	}
	else {
		updateCircle(map, center, radiusInKm, whichCircle, updateCircle)
	}
}

export function transformPointToMiniPolygon(initialPoint) {
	const cpyPoint = initialPoint
	const { longitude: lngRaw, latitude: latRaw } = cpyPoint
	const lng = Number(lngRaw) // Conversion en nombre
	const lat = Number(latRaw)
	const offset = 0.001

	const tmpPolygonCoords = [
		{ lng: lng - offset, lat: lat - offset },
		{ lng: lng + offset, lat: lat - offset },
		{ lng: lng + offset, lat: lat + offset },
		{ lng: lng - offset, lat: lat + offset },
		{ lng: lng - offset, lat: lat - offset },
	]

	return tmpPolygonCoords
}

function getLayerVisibility(map, layerId) {
	const visibility = map.getLayoutProperty(layerId, 'visibility')
	return visibility
}

export function updateCircle(map, polygon, radiusInKm, whichCircle = 'polygon', currentDistanceUnitChoice = 'km') {
	const circleName = whichCircle === 'polygon' ? 'circle' : 'meetingPoint'
	const circleSource = map.getSource(`${circleName}Source`)

	const polygonGeoJSON = turf.polygon([polygon.map((point) => [point.lng, point.lat])])
	const center = turf.centroid(polygonGeoJSON).geometry.coordinates

	// const radiusInMeters = radiusInKm * 1000

	// Générer les points du cercle avec Turf.js
	const options = { steps: 64, units: 'kilometers', properties: {} }
	const circle = turf.circle(center, radiusInKm, options)

	if (circleSource) {
		circleSource.setData(circle)
	}
	else {
		// console.log('no circleSource')
	}
}

export function startUpdateCircleFromMap(map, polygon, customMethodOnUpdateFromMap = null, mouseMoveDetection = false, whichCircle = 'polygon', currentDistanceUnitChoice = 'km') {
	// const polygonGeoJSON = turf.polygon([polygon.map((point) => [Number(point.lng), Number(point.lat)])])

	const circleName = whichCircle === 'polygon' ? 'circle' : 'meetingPoint'
	// const circleName = whichCircle === 'polygon' ? 'circleLayer' : 'meetingPointCircleLayer'

	const coords = polygon.map((point) => [Number(point.lng), Number(point.lat)])

	const polygonGeoJSON = turf.polygon([coords])
	const center = turf.centroid(polygonGeoJSON).geometry.coordinates
	let distanceInKm = null

	const updateCircleOnMouseMove = (e) => {
		const lineColor = circleName === 'circle' ? '#ff387f' : '#BABEF2'
		const circleLayer = circleName + 'Layer'
		const circleVisibility = getLayerVisibility(map, circleLayer)
		if (circleVisibility === 'visible') {
			const hoveredCoordinates = e.lngLat
			const mouseMovePoint = [hoveredCoordinates.lng, hoveredCoordinates.lat]
			const mouseMovePointTurf = turf.point(mouseMovePoint)
			// const units = mappingUnits(currentDistanceUnitChoice) // kilometers or miles or meteres
			distanceInKm = turf.distance(center, mouseMovePointTurf, { units: 'kilometers' })
			updateCircle(map, polygon, distanceInKm, whichCircle, currentDistanceUnitChoice)
			customMethodOnUpdateFromMap(distanceInKm)
			updateLine(map, center, mouseMovePoint, lineColor)
		}
	}

	if (mouseMoveDetection) {
		map._onMouseMoveHandler = (e) => updateCircleOnMouseMove(e, map)
		map.on('mousemove', map._onMouseMoveHandler)
		map.on('click', () => {
			map.off('mousemove', map._onMouseMoveHandler)
		})
	}

	addLineSourceAndLayer(map, center)
}

export function removeAllMapMouseMoveHandlers(map) {
	if (map._onMouseMoveHandler) {
		// map.off('mousemove', map._onMouseMoveHandler)
		// map._onMouseMoveHandler = null
	}
	map.off('mousemove', map._onMouseMoveHandler)
	map._onMouseMoveHandler = null
}

export function updateEndOfLineCircleAndLineColor(map, color) {
	const smallCircleLayerId = 'smallCircleLayer'
	const lineLayerId = 'lineLayer'

	if (map?.getLayer(smallCircleLayerId)) {
		map?.setPaintProperty(smallCircleLayerId, 'circle-color', color)
	}

	if (map?.getLayer(lineLayerId)) {
		map?.setPaintProperty(lineLayerId, 'line-color', color)
	}
}

function addLineSourceAndLayer(map, center) {
	if (map.getSource('lineSource')) {
		map.getSource('lineSource').setData({
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: [center, center], // Initialiser avec une ligne de longueur 0
			},
		})
	}
	else {
		map.addSource('lineSource', {
			type: 'geojson',
			data: {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [center, center], // Initialiser avec une ligne de longueur 0
				},
			},
		})
	}

	// if (map.getLayer('lineLayer')) {
	// 	map.removeLayer('lineLayer')
	// }

	if (map.getLayer('lineLayer')) {
		map.setLayoutProperty('lineLayer', 'visibility', 'visible')
		setDotEndOfLineVisibility(map, 'visible')
	}
	else {
		map.addLayer({
			id: 'lineLayer',
			type: 'line',
			source: 'lineSource',
			layout: {
				visibility: 'visible',
			},
			paint: {
				'line-color': '#ff387f',
				'line-width': 2,
				'line-dasharray': [2, 2], // Motif des tirets (2px de ligne, 4px d'espace)
			},
			layout: {
				visibility: 'none',
			},
		})
	}
}

export function updateLine(map, start, end, color = '#ff387f') {
	const lineSource = map.getSource('lineSource')

	if (lineSource) {
		const lineData = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: [start, end],
			},
		}
		lineSource.setData(lineData)
		setEndOfLine(map, end)
		map.setPaintProperty('smallCircleLayer', 'circle-color', color)
		map.setPaintProperty('lineLayer', 'line-color', color)
	}
	else {
		// console.error('lineSource Error')
	}
}

export function setCircleLayerVisibility(map, visibility = 'none', whichCircle = 'polygon') {
	const circleName = whichCircle === 'polygon' ? 'circle' : 'meetingPoint'

	const circleLayer = map.getLayer(`${circleName}Layer`)
	if (circleLayer) {
		map.setLayoutProperty(`${circleName}Layer`, 'visibility', visibility)
		setLineVisibility(map, visibility)
		setDotEndOfLineVisibility(map, visibility)
	}
	else {
		// console.log(`no ${circleName}Layer`)
	}
}

export function setAiLayerVisibility(map, visibility = 'none') {
	const circleLayer = map.getLayer(`polygonAILayer`)
	if (circleLayer) {
		map.setLayoutProperty(`polygonAILayer`, 'visibility', visibility)
	}
	else {
		// console.log(`no polygonAILayer Layer`)
	}
}

export function setLineVisibility(map, visibility = 'none') {
	const lineLayer = map.getLayer('lineLayer')

	if (lineLayer) {
		map.setLayoutProperty('lineLayer', 'visibility', visibility)
		if (visibility === 'none') {
			setDotEndOfLineVisibility(map, visibility)
		}
	}
	else {
		addLineSourceAndLayer(map, [0, 0])
	}
}

export function setDotEndOfLineVisibility(map, visibility = 'none') {
	const endOfLineLayer = map.getLayer('smallCircleLayer')
	if (endOfLineLayer) {
		map.setLayoutProperty('smallCircleLayer', 'visibility', visibility)
	}
}

export function colorActionPolygon(map, color) {
	map.on('idle', () => {
		if (map.getLayer('actionPolygonFill')) {
			map.setPaintProperty('actionPolygonFill', 'fill-color', color)
		}
		else {
			// console.log('actionPolygonFill layer not found')
		}
	})
}
