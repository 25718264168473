import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white overflow-auto w-full h-full" }

import get from 'lodash/get'
import { ref, onMounted, watch } from 'vue'
import ActionCreationMeetingPoint from './ActionCreationMeetingPoint.vue'

import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionCreationPlaceContainer',
  emits: ['update:nextValidatorBoolean'],
  setup(__props, { emit: __emit }) {

const store = useStore()

const address = ref({})
const mounted = ref(false)

const valid = ref(false)
const skipValidation = ref(false)

const emit = __emit

watch(valid, (value) => {
	if (skipValidation.value) {
		return
	}
	emit('update:nextValidatorBoolean', value)
})

onMounted(() => {
	store.dispatch('@action/actionSetStep', 'PLACE')
	const adressFromStore = get(store.getters['@action/newAction'], 'Addresses[0]', {})
	const actionType = get(store.getters['@action/newAction'], 'type', '')

	/**
	 * If the action is an event or an challenge, we skip the validation
	 */
	if (actionType === 'canvassing' || actionType === 'gotvcanvassing' || actionType === 'mail' || actionType === 'other') {
		skipValidation.value = true
		emit('update:nextValidatorBoolean', true)
	}

	if (adressFromStore) {
		address.value = adressFromStore
	}

	mounted.value = true
})

const dispatchActionCreationPlace = (addressSelected) => {
	const houseNumber = get(addressSelected, 'housenumber', '')
	const street = get(addressSelected, 'street', '')
	const postalCode = get(addressSelected, 'postalcode', '')
	const city = get(addressSelected, 'city', '')
	const country = get(addressSelected, 'country', '')
	const latitude = get(addressSelected, 'latitude', '')
	const longitude = get(addressSelected, 'longitude', '')
	const infos = get(addressSelected, 'infos', '')
	const addition = get(addressSelected, 'addition', '')

	let finalObject = {
		housenumber: houseNumber,
		street,
		postalcode: postalCode,
		city,
		country,
		addition,
		infos,
	}

	if (latitude && longitude) {
		finalObject.latitude = String(latitude)
		finalObject.longitude = String(longitude)
	}
	else {
		finalObject = {}
	}

	store.dispatch('@action/actionSetNewActionMeetingPoint', { index: 0, value: finalObject })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (mounted.value)
      ? (_openBlock(), _createBlock(ActionCreationMeetingPoint, {
          key: 0,
          address: address.value,
          valid: valid.value,
          "onUpdate:valid": _cache[0] || (_cache[0] = ($event: any) => ((valid).value = $event)),
          onSaved: dispatchActionCreationPlace
        }, null, 8, ["address", "valid"]))
      : _createCommentVNode("", true)
  ]))
}
}

})