<template>
	<div
		v-if="
			$route.name === 'contact-list' ||
			$route.name === 'contacts-contactbyid' ||
			instanciedModuleId !== ''
		"
		class="h-full w-full min-h-0"
	>
		<transition name="fade">
			<div
				v-if="$route.name === 'contact-list' || instanciedModuleId !== ''"
				id="contacts-container"
				class="h-full w-full"
				:class="{ blurred: blurred }"
			>
				<div v-show="blurred" id="overlay" @click="methodToggleBlur"></div>
				<div class="h-full w-full">
					<contacts
						v-model:contact-list="computedContactList"
						v-model:nb-contact-selected="computedNbContactSelected"
						v-model:contact-table="computedContactTable"
						v-model:all-contacts-selected="computedAllContactsSelected"
						:multiselect-menu-disabled-clicked="multiselectMenuDisabledClicked"
						:display-mode="displayMode"
						:instancied-module-id="instanciedModuleId"
						:simple-display="simpleDisplay"
						:is-contact-list-visible="isContactListVisible"
						:method-set-selected-contact-id="methodSetSelectedContactId"
						@contact-selected-change="methodPropageEvent('contactSelectedChange', $event)"
						@toggle-hide-dynamic-table="methodPropageEvent('toggleHideDynamicTable', $event)"
					></contacts>
				</div>
			</div>
		</transition>
		<div id="mobile-container" class="q-up-to-tablet"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import modulesInstancesMixin from '../../mixins/modules-instances-mixin'

// Fix for map in command center
// WDK why we need to add this line
// REALLY IMPORTANT
// eslint-disable-next-line no-unused-vars

export default {
	name: 'ContactList',
	components: {
		contacts: defineAsyncComponent(() => import('./contact/contacts.vue')),
	},

	mixins: [modulesInstancesMixin],

	props: {
		blurred: { type: Boolean, default: false },
		contactList: { type: Array, default: () => [], required: true },
		contactTable: { type: Object, default: () => {}, required: true },
		nbContactSelected: { type: Number, default: 0, required: true },
		allContactsSelected: { type: Boolean, default: false, required: true },
		multiselectMenuDisabledClicked: { type: Boolean, default: false, required: true },
		displayMode: { type: String, required: true, default: 'list' },
		instanciedModuleId: {
			type: String,
			default: '',
		},
		simpleDisplay: { type: Boolean, default: false },
		isContactListVisible: { type: Boolean, default: false },
		methodSetSelectedContactId: {
			type: Function,
			required: false,
			default: () => {},
		},
	},

	emits: [
		'update:blurred',
		'allContactsSelected',
		'contactSelectedChange',
		'toggleHideDynamicTable',
		'update:nb-contact-selected',
		'update:all-contacts-selected',
		'update:contact-table',
		'update:contact-list',
	],

	computedGettersInstance() {
		return {
			...mapGetters(['contacts']),
			...mapGetters('@contact', ['showDetailedAnswers']),
			...mapGetters(['windowIsMobile']),
		}
	},

	computed: {
		computedContactList: {
			get() {
				return this.contactList
			},
			set(newVal) {
				this.$emit('update:contact-list', newVal)
			},
		},

		computedNbContactSelected: {
			get() {
				return this.nbContactSelected
			},
			set(newVal) {
				this.$emit('update:nb-contact-selected', newVal)
			},
		},

		computedAllContactsSelected: {
			get() {
				return this.allContactsSelected
			},
			set(newVal) {
				this.$emit('update:all-contacts-selected', newVal)
			},
		},

		computedContactTable: {
			get() {
				return this.contactTable
			},
			set(newVal) {
				this.$emit('update:contact-table', newVal)
			},
		},
	},

	methods: {
		methodToggleBlur() {
			this.$emit('update:blurred', !this.blurred)
		},
		methodPropageEvent(event, args) {
			this.$emit(event, args)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/dequorum/style/variables.sass';

#contacts-container {
	transition: all 0.3s ease-in-out;
	position: relative;

	#overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(#9fa4ab, 0.2);
		z-index: 1001;
	}

	&.blurred {
		filter: blur(2px) grayscale(1);
	}
}
</style>
