import { defineStore, acceptHMRUpdate } from 'pinia'
import cloneDeep from 'lodash/cloneDeep'
import interactionsRequester from '@/requesters/interactions'

type Tag = {
	id: number
	name: string
	count: number
	created_at: string
}

function compareObjects(a: Tag, b: Tag) {
	// Calculate age of the object in days
	const today = new Date()
	const aAge = Math.floor((today - new Date(a.created_at)) / (1000 * 60 * 60 * 24))
	const bAge = Math.floor((today - new Date(b.created_at)) / (1000 * 60 * 60 * 24))

	// Prioritize recent tags (<= 3 days old)
	if (aAge <= 3 && bAge <= 3) {
		// Both are recent, prioritize newer ones
		return new Date(b.created_at) - new Date(a.created_at) // Newer comes first
	}
	else if (aAge <= 3) {
		return -1 // a (recent) comes first
	}
	else if (bAge <= 3) {
		return 1 // b (recent) comes first
	}

	return b.count - a.count
}

export const useInteractionsStore = defineStore('interactions', {
	state: () => ({
		tags: [],
	}),
	actions: {
		async actionRequestGetTagsInteractions() {
			interactionsRequester.getTagsInteractions().then((response) => {
				this.tags = response?.tags
			})
		},

		actionAddTemporaryTagInteraction(tag) {
			this.tags.push(tag)
		},

		actionUpdateTemporaryTagInteraction(tag) {
			const tagIndex = this.tags.findIndex((tagItem) => tagItem.id === tag.id)
			if (tagIndex !== -1) {
				this.tags[tagIndex] = tag
			}
		},

		actionDeleteTagInteraction(tagId) {
			interactionsRequester.deleteTagInteraction(tagId).then(() => {
				this.tags = this.tags.filter((tag) => tag.id !== tagId)
			})
		},

		actionRemoveTemporaryTagInteraction(tagId) {
			this.tags = this.tags.filter((tag) => tag.id !== tagId)
		},

		actionCreateTagInteraction(tag) {
			interactionsRequester.addTagInteraction(tag).then((response) => {
				this.tags.push(response)
			})
		},

		async actionUpdateTagInteraction(tag) {
			// Use local strategy to update the tag
			// For performance reasons, we don't need to wait for the server response
			const tagIndex = this.tags.findIndex((tagItem) => tagItem.id === tag.id)
			if (tagIndex !== -1) {
				this.tags[tagIndex] = tag
			}

			interactionsRequester.updateTagInteraction(tag)
		},
	},
	getters: {
		getTagsInteractions: (state) => {
			const clonedTags = cloneDeep(state.tags)
			clonedTags.sort(compareObjects)
			return clonedTags
		},
		getTagById: (state) => (id) => {
			return state.tags.find((tag) => tag.id === id)
		},
	},
})

if (import.meta.hot) {
	import.meta.webpackHot.accept(acceptHMRUpdate(useInteractionsStore, import.meta.webpackHot))
}
