import deburr from 'lodash/deburr'
import { firstBy } from 'thenby'
import { cloneDeep, isNaNn, some, filter } from 'lodash'
import { differenceInYears } from 'date-fns'
import getUsersPopulatedByTeams from '../../../../components/user_management-module/utils/getUsersPopulatedByTeams'

type User = {
	firstname: string
	surname: string
}

export function methodFilterPrivateTeams(teamArray, userConnected) {
	// 'this' don't work inside the function

	const filteredArray = filter(teamArray, function (team) {
		// Display the only the ones the user is part of if he does not have the right to see the teams and if the team is private
		return (
			some(team.users, ['id', userConnected.id])
			|| !team.private
			|| userConnected?.role_data?.access?.includes('members.teams:delete')
		)
	})
	return filteredArray
}

export function methodFilterUsersByAgeRange(users, ageRange = [0, 100]) {
	const defaultRange = [0, 100]
	let filteredUsers = cloneDeep(users)

	if (ageRange !== defaultRange) {
		filteredUsers = filteredUsers.filter((user) => {
			if (user.birthdate) {
				const birthdate = new Date(user.birthdate)
				const age = differenceInYears(new Date(), birthdate)

				if (isNaN(age && ageRange === defaultRange)) {
					return true
				}
				else {
					return age >= ageRange[0] && age <= ageRange[1]
				}
			}
			return true
		})
	}
	return filteredUsers
}

export function methodSetTeamsInUsers(teams, users, activeUser = null) {
	let localComputedUsersPopulatedByTeams = []

	if (teams.length && users.length) {
		localComputedUsersPopulatedByTeams = getUsersPopulatedByTeams(users, teams)
	}

	if (!teams.length && users.length) {
		localComputedUsersPopulatedByTeams = cloneDeep(users)
	}

	if (activeUser) {
		const index = localComputedUsersPopulatedByTeams.findIndex(
			(user) => user.id === activeUser.id,
		)

		if (index !== -1) {
			localComputedUsersPopulatedByTeams[index].focus = true
		}
	}

	return localComputedUsersPopulatedByTeams
}

export function filterUsersByFuzzySearch(querySearch = '', users) {
	const query = querySearch
	let localClonedUsers = cloneDeep(users)
	if (query) {
		localClonedUsers = fuzzySearch(query, localClonedUsers)
	}

	return localClonedUsers.sort(
		firstBy((v1, v2) => v2.role_data?.access?.length - v1.role_data?.access?.length)
			.thenBy('firstname'))
}

export function filterTeamsByFuzzySearch(querySearch = '', teams) {
	const query = querySearch
	let localClonedteams = cloneDeep(teams)
	if (query) {
		localClonedteams = teamsFuzzySearch(query, localClonedteams)
	}

	return localClonedteams.sort(
		firstBy((v1, v2) => v2.role_data?.access?.length - v1.role_data?.access?.length)
			.thenBy('name'))
}

export function fuzzySearch(query: string, data: Array<User>) {
	const normalizedQuery = deburr(query.toLowerCase())
	const queryWords = normalizedQuery.split(/\s+/)

	return filter(data, (person: User) => {
		const matches = queryWords.every((word: string) => {
			const normalizedFirstName = deburr(person.firstname.toLowerCase())
			const normalizedLastName = deburr(person.surname.toLowerCase())

			return (
				normalizedFirstName.includes(word)
				|| normalizedLastName.includes(word)
			)
		})

		return matches
	})
}
export function teamsFuzzySearch(query: string, data: Array<User>) {
	const normalizedQuery = deburr(query.toLowerCase())
	const queryWords = normalizedQuery.split(/\s+/)

	return filter(data, (team: User) => {
		// Check if each deburred word in the query is present in firstname or lastname
		const matches = queryWords.every((word: string) => {
			const normalizedName = deburr(team.name.toLowerCase())

			return (
				normalizedName.includes(word)

			)
		})

		return matches
	})
}
