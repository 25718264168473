<template>
	<div class="sidebar" :class="{ primary: primary }">
		<slot name="sidebar-content"></slot>
	</div>
</template>

<script>
export default {
	name: 'Sidebar',

	props: {
		// primary represents if this sidebar is the primary thing on screen
		// this determines its visibility if the screen is too narrow to show the page's content and
		// sidebar simultaneously
		primary: {
			default: false,
			type: Boolean,
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/structure/sidebar.scss';
</style>
