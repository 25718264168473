<template>
	<div class="flex relative h-full">
		<div
			class="preview-form w-full pt-2 relative sm:flex md:pb-4 inset-0 sm:top-auto md:px-8 lg:px-16 xl:px-44 2xl:px-52 3xl:px-60 overflow-auto h-full"
			:class="{
				'--params-show': paramsBar.show,
			}"
			style="transition: padding 0.5s"
		>
			<div class="w-full md:w-10/12">
				<div
					v-for="(group, groupIndex) in sectionsList"
					:key="`group-${group.groupIndex}`"
					:class="[
						{ 'rounded-t-lg pt-6': groupIndex == 0 },
						{
							'rounded-b-lg pb-5': groupIndex == sectionsList.length - 1,
						},
					]"
					class="px-6 bg-white w-full"
				>
					<el-divider
						v-if="groupIndex !== 0"
						class="my-0 divider-form"
					/>
					<div
						:id="`form-${group[0].group}`"
						class="z-10 rounded-lg my-2 flex h-16 items-center justify-between font-title transition-all cursor-pointer px-1.5 py-1 border border-transparent hover:border-pink-main"
						@click="methodHandleCollapse(`group-${groupIndex}`)"
					>
						<div class="flex items-center gap-3 pl-2 text-2xl">
							{{ methodGroupTitle(group) }}
						</div>
						<i
							:id="`arrow-group-${groupIndex}`"
							class="gui-arrow_down text-xl rounded-full transform transition-all py-1.5 px-2.5 hover:bg-gray-100"
						/>
					</div>
					<Draggable
						:id="`group-${groupIndex}`"
						v-model="sectionsList[groupIndex]"
						:group="{ name: `form-${groupIndex}`, put: false, pull: false }"
						item-key="dragOrder"
						class="content"
						v-bind="computedDragOptions"
						handle=".handle-field"
						draggable=".field"
						chosen-class="choosen"
						:move="checkMove"
						@start="methodDragStart"
						@end="methodDragEnd"
					>
						<template #item="{ element }">
							<div
								:id="`form-${element.group}-${element.label}-${element.order}`"
								class="field rounded-lg"
							>
								<hr
									v-if="element.divider"
									class="divider"
								>
								<div
									:label="element.type"
									:class="!element.enabled ? '' : 'handle-field'"
									class="flex flex-col"
									@mouseover="
										hoveredFieldProperty = `${element.order}-${element.type}-${element.label}`
									"
									@mouseleave="hoveredFieldProperty = null"
									@click="methodOpenFieldParams(element)"
								>
									<DraggableRowContainer
										class="pr-1"
										:class="!element.enabled ? 'pl-9' : ''"
										:custom-class-slot-container="'my-1 px-4 py-1'"
										:active-row="
											selectedFieldProperty ===
												`${element.order}-${element.type}-${element.label}` ||
												(hoveredFieldProperty ===
													`${element.order}-${element.type}-${element.label}` &&
													!readOnly)
										"
										:read-only="!element.enabled"
									>
										<ContactFielRowContent
											:form="element"
											class="flex items-center"
											:unchanged-form="
												selectedFieldProperty === element.id ? fieldSelectedUnchanged : null
											"
											:method-set-label="methodSetLabel"
											:method-on-change-form="methodOnChangeForm"
											:method-reset-field="methodResetField"
											:save-change="saveChange"
											:is-selected="
												selectedFieldProperty ===
													`${element.order}-${element.type}-${element.label}` ||
													(hoveredFieldProperty ===
														`${element.order}-${element.type}-${element.label}` &&
														!readOnly)
											"
											:is-hovered="
												selectedFieldProperty ===
													`${element.order}-${element.type}-${element.label}` ||
													(hoveredFieldProperty ===
														`${element.order}-${element.type}-${element.label}` &&
														!readOnly)
											"
											:read-only="!element.enabled"
											:type-options="computedGetTypesOptions"
											:method-get-type-translated="methodGetTypeTranslated"
											:method-get-icon-by-field-type="methodGetIconByFieldType"
											:method-handle-params-bar="methodHandleParamsBar"
											@delete-custom-field="(fieldId) => methodDeleteCustomField(group, fieldId)"
											@open-sidebar="methodOpenFieldParams(element)"
										/>
									</DraggableRowContainer>
								</div>
							</div>
						</template>
						<template #footer>
							<div
								key="footer"
								role="group"
								class="w-full flex justify-end py-6 text-right pr-3"
							>
								<div
									v-if="!readOnly"
									class="newFieldCta w-fit cursor-pointer text-pink-main font-bold flex items-center gap-1"
									@click="methodAddCustomField(group, groupIndex)"
								>
									{{ $t('SETTINGS.CONTACT.ADD_CUSTOM_FIELDS') }}
									<i class="gui-more text-2xl add-field-icon" />
								</div>
							</div>
						</template>
					</Draggable>
				</div>
			</div>
		</div>
		<div
			:class="menuLoad ? 'opacity-70' : 'opacity-100'"
			class="transition-opacity ease-in duration-200 h-full"
		>
			<CreateFormParamsBar
				v-model:params="computedParams"
				:is-only-for-ui="true"
				:style="{ height: 'calc(100vh - 6.5rem)', top: 'unset' }"
				class="absolute top-0 bottom-0 right-0 paramsBarContainer w-full md:w-5/12 lg:w-3/12 pt-3 shadow-none"
			>
				<template #content>
					<ContactFormSettingsSidebar
						:key="changesCount"
						v-model:field-selected="fieldSelected"
						:is-for-description="isForDescription"
						:field-selected-unchanged="fieldSelectedUnchanged"
						:method-reset-field="methodResetField"
						:method-get-icon-by-field-type="methodGetIconByFieldType"
						:read-only="fieldSelected ? (fieldSelected.enabled ? false : true) : null"
						:is-access-granted-by-pack="computedParams[0].pack"
						:type-options="computedGetTypesOptions"
					/>
				</template>
			</CreateFormParamsBar>
		</div>
	</div>
</template>

<script>
/**
 * This component will handle the contact forms
 */
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import deburr from 'lodash/deburr'
import contactFormTemplate from './template_contact_form'
import Draggable from 'vuedraggable'
import CreateFormParamsBar from '../../create-form-components/paramsBar/createFormParamsBar.vue'
import { contact_settings_mixins } from '../../../mixins/contact-settings/contact-settings'
import { defineAsyncComponent, nextTick } from 'vue'
import { mergeLocalAndBackendStaticForms } from '../../../utils/mergeLocalAndBackendStaticForms'

/**
 * @component ContactFromSettings
 * @route /settings/contact/form
 * @lifecycle mounted on mount get static if it is not already set
 * @description handle the creation and edition of contact's related form
 */
export default {
	components: {
		ContactFielRowContent: defineAsyncComponent(() => import('./contactFieldRowContent.vue')),
		DraggableRowContainer: defineAsyncComponent(
			() => import('../../create-form-components/paramsBar/draggableRowContainer.vue'),
		),
		Draggable,
		CreateFormParamsBar,
		ContactFormSettingsSidebar: defineAsyncComponent(
			() => import('./contact-form-settings-sidebar/contactFormSettingsSidebar.vue'),
		),
	},
	mixins: [contact_settings_mixins],

	beforeRouteLeave(to, from, next) {
		// prevent leave route if there are some unsaved changes
		if (this.hasChangeToSave && to.name !== 'login') {
			this.$confirm(
				this.$t('SETTINGS.CONTACT.CONFIRM_BOX.DESCRIPTION'),
				this.$t('SETTINGS.CONTACT.CONFIRM_BOX.TITLE'),
				{
					confirmButtonText: this.$t('SETTINGS.CONTACT.CONFIRM_BOX.BTN_OK'),
					cancelButtonText: this.$t('_COMMON.CANCEL'),
					customClass: 'custom_message_box',
				},
			)
				.then(() => {
					this.$emit('unSaveChange', false)
					next()
				})
				.catch(() => next(false))
		}
		else {
			this.$emit('unSaveChange', false)
			next()
		}
	},
	props: {
		saveChange: {
			type: Number,
			default: 0,
		},
		resetSettings: {
			type: Number,
			default: 0,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		hasChangeToSave: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['unSaveChange', 'giveUpdatedSectionsList', 'updateChangeCount'],
	data() {
		return {
			fakeMobileValueGender: null,
			fakeMobileValueTerritory: null,

			allChanges: [],
			questionsUnChanged: [],
			questions: contactFormTemplate.staticContactForm,
			sectionsList: [],
			sectionsListUnchanged: [],

			dragging: false,

			fieldSelected: null,
			selectedFieldProperty: '',
			hoveredFieldProperty: '',
			fieldSelectedUnchanged: null,
			menuLoad: false,
			nbrOfCustomFieldsCreated: 0,
			resetTotalRunning: false,
			changesCount: 0,
			isForDescription: false,
		}
	},
	computed: {
		...mapGetters('@packs', ['getAccessStatusFormsTypologiesQRCode', 'getAccessCustomFields']),
		...mapGetters('@form', [
			'getFormsStatic',
			'getIsLoadingSavingForm',
			'getCustomForms',
			'getBothStaticAndCustomFormsUpdated',
		]),
		...mapGetters('@createForm', ['paramsBar']),

		t() {
			const prefix = 'SETTINGS.CONTACT'
			return {
				age: this.$t(`${prefix}.AGE`),
				city: this.$t(`${prefix}.CITY`),
				mail: this.$t(`${prefix}.MAIL`),
				male: this.$t('_COMMON.MALE'),
				save: this.$t('_COMMON.SAVE'),
				phone: this.$t(`${prefix}.PHONE`),
				female: this.$t('_COMMON.FEMALE'),
				gender: this.$t(`${prefix}.GENDER`),
				mobile: this.$t(`${prefix}.MOBILE`),
				rename: this.$t(`${prefix}.RENAME`),
				address: this.$t(`${prefix}.ADDRESS`),
				contact: this.$t(`${prefix}.CONTACT`),
				default: this.$t(`${prefix}.DEFAULT`),
				surname: this.$t(`${prefix}.SURNAME`),
				reorder: this.$t(`${prefix}.REORDER`),
				identity: this.$t(`${prefix}.IDENTITY`),
				question: this.$t(`${prefix}.QUESTION`),
				birthdate: this.$t(`${prefix}.BIRTHDATE`),
				firstname: this.$t(`${prefix}.FIRSTNAME`),
				nationality: this.$t(`${prefix}.NATIONALITY`),
				birthcountry: this.$t(`${prefix}.BIRTHCOUNTRY`),
				birthcity: this.$t(`${prefix}.BIRTHCITY`),
				explanations: this.$t(`${prefix}.EXPLANATIONS`),
				married_name: this.$t(`${prefix}.MARRIED_NAME`),
				custom: this.$t('SETTINGS.CONTACT.CUSTOM'),
			}
		},

		computedDragOptions() {
			return {
				animation: 500,
				disabled: this.readOnly,
				choosenClass: 'choosen',
				preventOnFilter: false,
				filter: 'input',
			}
		},

		computedGetSideBarTitle() {
			const isCustomFieldsAccessGranted = this.getAccessCustomFields === 'enabled' ? true : false
			let title = ''
			if (this.fieldSelected) {
				if (this.fieldSelected.type == 'custom' && this.$i18n.locale == 'en') {
					title
						= this.methodSetLabel(this.fieldSelected?.type)
						+ ' '
						+ this.$t('SETTINGS.CONTACT.PARAM_BAR.FIELD')
				}
				else {
					title
						= this.$t('SETTINGS.CONTACT.PARAM_BAR.FIELD')
						+ ' '
						+ this.methodSetLabel(this.fieldSelected?.type)
				}
			}
			if (!isCustomFieldsAccessGranted && !this.fieldSelected)
				title = this.$t('SETTINGS.CONTACT.PARAM_BAR.CUSTOM_DEMO_TITLE')
			if (this.isForDescription) title = 'Aide pour sa fiche contact'

			return title
		},

		computedGetSideBarSubtitle() {
			const isCustomFieldsAccessGranted = this.getAccessCustomFields === 'enabled' ? true : false
			let subtitle = ''
			if (this.fieldSelected) subtitle = this.$t('SETTINGS.CONTACT.PARAM_BAR.SUBTITLE')
			if (!isCustomFieldsAccessGranted && !this.fieldSelected)
				subtitle = this.$t('SETTINGS.CONTACT.PARAM_BAR.CUSTOM_DEMO_SUBTITLE')
			if (this.isForDescription) subtitle = 'Pleins de tutos'

			return subtitle
		},

		computedParams() {
			return [
				{
					name: 'general',
					title: this.computedGetSideBarTitle,
					subtitle: this.computedGetSideBarSubtitle,
					hidden: true,
					pack: this.getAccessCustomFields === 'enabled' ? true : false,
					yellowBackground: !this.fieldSelected,
				},
			]
		},
	},

	watch: {
		sectionsList: {
			handler(newValue) {
				this.methodEmitChange(this.sectionsListUnchanged.flat(), newValue.flat())
			},
			deep: true,
		},

		fieldSelected: {
			handler(newValue, oldValue) {
				if (newValue && oldValue) {
					const clonedSectionsList = cloneDeep(this.sectionsList)

					const newSectionsList = clonedSectionsList.map((section) => {
						return section.map((group) => {
							if (group.dragOrder === newValue.dragOrder) return newValue
							return group
						})
					})

					this.sectionsList = newSectionsList
				}
			},
			deep: true,
		},

		getBothStaticAndCustomFormsUpdated: {
			handler(newVal) {
				if (newVal === 'success') {
					this.allChanges = []
					this.$emit('unSaveChange', false)
					this.$message({
						type: 'success',
						message: this.$t('SETTINGS.CONTACT.SUCCESS'),
						showClose: true,
					})
					this.$emit('updateChangeCount')
				}
			},
			deep: true,
		},

		saveChange() {
			this.methodReorderFlattenedSectionsList()
			const flattenedForms = cloneDeep(this.sectionsList.flat())
			const staticFormsPrepared = this.methodPrepareFormsStatic(flattenedForms)
			const customFormsPrepared = this.methodPrepareFormsCustom(flattenedForms)
			const payload = {
				staticForm: {
					fields: staticFormsPrepared,
					name: 'STATIC',
				},
			}
			if (!isEmpty(customFormsPrepared) || !isEmpty(this.getCustomForms)) {
				payload['customForm'] = {
					fields: customFormsPrepared,
					name: 'CUSTOM',
				}
			}
			this.actionSaveBothStaticAndCustomsForms(payload)
			this.methodHandleParamsBar(false, null)
		},

		resetSettings() {
			this.methodInitView(true)
		},

		'paramsBar.show'(newVal) {
			if (newVal === false) {
				this.fieldSelected = null
				this.fieldSelectedUnchanged = null
				this.selectedFieldProperty = ''
				this.hoveredFieldProperty = ''
			}
		},
	},

	beforeUnmount() {
		this.methodHandleParamsBar(false, null)
	},

	async mounted() {
		this.methodInitView()

		nextTick(() => {
			setTimeout(() => {
				const ids = [0, 1, 2, 3]
				ids.forEach((id) => {
					this.methodHandleCollapse(`group-${id}`)
				})
			}, 300)
		})
	},

	methods: {
		...mapActions('@form', ['actionSaveBothStaticAndCustomsForms']),
		...mapActions('@createForm', ['actionSetParamsBar']),

		methodInitView(isByDefault = false) {
			this.methodCreateNewLocalForms(this.getFormsStatic, isByDefault)
			this.methodCreateFormsLists(isByDefault)
		},

		methodGroupTitle(group) {
			switch (group[0].group) {
				case 'identity':
					return this.$t('CONTACT.CREATION.SECTIONS.MARTIAL_STATUS')
				case 'infos':
					return this.$t('CONTACT.CREATION.SECTIONS.CONTACT_DETAILS')
				case 'contact':
					return this.$t('CONTACT.CREATION.SECTIONS.CONTACT_TYPOLOGY')
				case 'address':
					return this.$t('SETTINGS.CONTACT.GROUP.ADDRESS')
			}
		},

		methodOnChangeForm(type, change, newVal) {
			const changeString = `${type}-${change}`
			const nbOfChanges = this.allChanges.length

			if (type == 'custom') return
			if (
				this.questionsUnChanged.filter((question) => question.type == type)[0][change] === newVal
			) {
				this.allChanges = this.allChanges.filter((change) => change !== changeString)
			}
			else if (this.allChanges.every((change) => change !== changeString)) {
				this.allChanges.push(changeString)
			}
			if (nbOfChanges !== this.allChanges.length) {
				this.$emit('unSaveChange', this.allChanges.length !== 0)
			}
		},

		async methodCreateNewLocalForms(backendForms, isByDefault) {
			const localQuestions = mergeLocalAndBackendStaticForms(
				isByDefault ? contactFormTemplate.staticContactForm : this.questions,
				backendForms,
				isByDefault,
				{ methodSetLabel: this.methodSetLabel },
			)
			if (this.getCustomForms && this.getCustomForms.length > 0) {
				const clonedCustomForms = cloneDeep(this.getCustomForms)
				clonedCustomForms.forEach((clonedCustomForm) => {
					clonedCustomForm.enabled = true
					clonedCustomForm.group = clonedCustomForm.section
					clonedCustomForm.field_type = clonedCustomForm.type
					clonedCustomForm.type = 'custom'

					if (!clonedCustomForm?.mandatory) clonedCustomForm.mandatory = false
					delete clonedCustomForm.section
					localQuestions.push(clonedCustomForm)
				})
			}
			this.questions = localQuestions
			if (!isByDefault) this.questionsUnChanged = cloneDeep(this.questions)
		},

		methodPrepareFormsStatic(formsWanted) {
			const formsToUpdate = formsWanted.filter((aForm) => aForm.enabled && aForm.name === 'STATIC')

			formsToUpdate.forEach((aForm) => {
				delete aForm.enabled
				delete aForm.group
				delete aForm.divider
				delete aForm?.is_undupe_field
				delete aForm?.is_geolocated
				if (aForm.mandatory === false) delete aForm.mandatory
				aForm.label = aForm?.label?.trim()
			})
			return formsToUpdate
		},

		methodPrepareFormsCustom(formsWanted) {
			const formsToUpdate = formsWanted.filter((aForm) => aForm.enabled && aForm.name === 'CUSTOM')
			formsToUpdate.map((aForm) => {
				aForm.value = deburr(aForm.label.toLowerCase().replaceAll(' ', '_'))
				aForm.type = aForm.field_type
				aForm.section = aForm.group
				aForm.label = aForm?.label?.trim()
				if (!isEmpty(aForm.refvalues)) {
					if (aForm.type === 'checkbox' || aForm.type === 'radio') {
						aForm.refvalues = aForm.refvalues.map((refvalue) => {
							return {
								...refvalue,
								value: refvalue?.id
									? refvalue.value
									: deburr(refvalue.label.toLowerCase().replaceAll(' ', '_')),
								label: refvalue.label?.trim(),
							}
						})
					}
					if (aForm.type === 'range') {
						aForm.refvalues = aForm.refvalues.map((refvalue) => ({
							...refvalue,
						}))
					}
				}
				else {
					aForm.refvalues = [{}]
				}

				if (!aForm.CreatedAt) delete aForm.id

				delete aForm.enabled
				delete aForm.divider
				delete aForm.field_type
				delete aForm.group
				delete aForm?.propositions
				if (aForm.mandatory === false) delete aForm.mandatory
			})
			return formsToUpdate
		},

		methodSetLabel(key) {
			if (key) {
				if (key.includes('address.')) {
					return this.$t(
						`SETTINGS.CONTACT.ADDRESS_LABELS.${key.split('address.')[1].toUpperCase()}`,
					)
				}
				else if (this.t[key]) {
					return this.t[key]
				}

				return key
			}
			return ''
		},

		methodCreateFormsLists(isByDefault) {
			const identity = this.questions.filter(
				(q) => q.group === 'identity' || q.section === 'identity',
			)
			const contact = this.questions
				.filter((q) => q.group === 'contact' || q.section === 'contact')
				.sort((afield, bfield) => afield.order - bfield.order)
			const infos = this.questions
				.filter((q) => q.group === 'infos' || q.section === 'infos')
				.sort((afield, bfield) => afield.order - bfield.order)
			const address = this.questions
				.filter((q) => q.group === 'address' || q.section === 'address')
				.sort((afield, bfield) => afield.order - bfield.order)

			const tempArray = []

			if (identity.length) {
				identity.forEach((aField, index) => {
					if (!aField.enabled) aField.order = identity[identity.length - 1].order + 1
					aField.dragOrder = `identity-${index}`
				})
				identity.sort((afield, bfield) => afield.order - bfield.order)
				tempArray.push(identity)
			}
			if (contact.length) {
				contact.forEach((aField, index) => {
					if (!aField.enabled) {
						aField.order = contact[contact.length - 1].order + 1
						aField.dragOrder = `contact-${index}`
					}
				})
				contact.sort((afield, bfield) => afield.order - bfield.order)
				tempArray.push(contact)
			}
			if (infos.length) {
				infos.forEach((aField, index) => {
					if (!aField.enabled) aField.order = infos[infos.length - 1].order + 1
					aField.dragOrder = `infos-${index}`
				})
				infos.sort((afield, bfield) => afield.order - bfield.order)
				tempArray.push(infos)
			}
			if (address.length) {
				address.forEach((aField, index) => {
					if (!aField.enabled) aField.order = address[address.length - 1].order + 1
					aField.dragOrder = `address-${index}`
				})
				address.sort((afield, bfield) => afield.order - bfield.order)
				tempArray.push(address)
			}
			this.sectionsList = tempArray
			if (!isByDefault) this.sectionsListUnchanged = cloneDeep(this.sectionsList)
		},

		methodAddHeightToCollapsableContent(target) {
			const collapsableContent = document.getElementById(target)

			if (collapsableContent) {
				let collapsableContentNumber = collapsableContent.style.maxHeight.replace('px', '')
				collapsableContentNumber = Number(collapsableContentNumber) + 300
				collapsableContent.style.maxHeight = collapsableContentNumber + 'px'
			}
		},

		methodFindEquivalentUnchangedField(form) {
			if (form.id) return this.questionsUnChanged.find((field) => field.id == form.id)
			return this.questionsUnChanged.find(
				(field) => field.order == form.order && field.label == form.label && field.type == form.type,
			)
		},

		methodOpenFieldParams(form, openFromCreation = false) {
			const clonedForm = cloneDeep(form)
			this.isForDescription = false
			if (
				this.selectedFieldProperty
				&& this.selectedFieldProperty === `${clonedForm.order}-${clonedForm.type}-${clonedForm.label}`
			)
				return
			this.menuLoad = true

			this.selectedFieldProperty = `${clonedForm.order}-${clonedForm.type}-${clonedForm.label}`
			this.fieldSelected = clonedForm

			this.fieldSelectedUnchanged = this.methodFindEquivalentUnchangedField(clonedForm)
			this.methodHandleParamsBar(true, 'general')
			if (!openFromCreation) {
				this.methodScrollToElement(clonedForm.group, clonedForm.label, clonedForm.order)
			}
			this.changesCount++
			this.menuLoad = false
		},

		async methodAddCustomField(group, groupIndex) {
			if (this.getAccessCustomFields !== 'enabled') {
				if (this.fieldSelected) {
					this.fieldSelected = null
					this.fieldSelectedUnchanged = null
					this.selectedFieldProperty = ''
					this.hoveredFieldProperty = ''
				}
				this.methodHandleParamsBar(true, 'general')
				return
			}
			this.methodAddHeightToCollapsableContent(`group-${groupIndex}`)
			const idsAlreadyUsed = []
			this.sectionsList.forEach((section) => {
				section.forEach((field) => {
					idsAlreadyUsed.push(field.id)
				})
			})
			idsAlreadyUsed.sort((a, b) => b - a)
			const order = group.length + 1
			const customField = {
				divider: false,
				dragOrder: `${group[0].group}-${order}`,
				enabled: true,
				field_type: 'text',
				group: group[0].group,
				group_id: [1],
				hidden: true,
				id: idsAlreadyUsed[0] + 1,
				label: `Custom-${this.nbrOfCustomFieldsCreated}`,
				mandatory: false,
				name: 'CUSTOM',
				order: order,
				refvalues: [],
				type: 'custom',
			}

			group.push(cloneDeep(customField))

			this.questionsUnChanged.push(cloneDeep(customField))
			await this.methodReorderFlattenedSectionsList()
			this.methodOpenFieldParams(cloneDeep(customField), true)
			this.methodScrollToElement(
				group[0].group,
				`Custom-${this.nbrOfCustomFieldsCreated}`,
				customField.order,
			)
			this.nbrOfCustomFieldsCreated += 1
		},

		async methodReorderFlattenedSectionsList() {
			let indexTroughArrays = 1
			this.sectionsList.forEach((section) => {
				section.map((field) => {
					field.order = indexTroughArrays
					indexTroughArrays++
				})
			})
		},

		methodScrollToElement(group, label, order) {
			const el = document.getElementById(`form-${group}-${label}-${order}`)
			el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
		},

		methodDeleteCustomField(group, fieldIdToDelete) {
			const index = group.findIndex((field) => field.id == fieldIdToDelete)
			if (this.fieldSelected && group[index].id === this.fieldSelected.id)
				this.methodHandleParamsBar(false, null)
			group.splice(index, 1)
			this.methodReorderFlattenedSectionsList()
		},

		methodResetField(possiblyLocalFormCloned, formToReset, isActionCanceled) {
			if (isActionCanceled) return
			const unchangedForm
				= possiblyLocalFormCloned !== null ? possiblyLocalFormCloned : this.fieldSelectedUnchanged

			for (const [key, value] of Object.entries(unchangedForm)) {
				if (possiblyLocalFormCloned !== null) {
					formToReset[key] = cloneDeep(value)
				}
				else {
					this.fieldSelected[key] = cloneDeep(value)
				}
			}
			this.changesCount++
		},
		methodDragStart() {
			this.dragging = true
		},

		methodDragEnd() {
			this.dragging = false
			this.methodReorderFlattenedSectionsList()
		},

		methodOpenHelpPannel() {
			if (this.fieldSelected) {
				this.fieldSelected = null
				this.fieldSelectedUnchanged = null
				this.selectedFieldProperty = ''
				this.hoveredFieldProperty = ''
			}
			this.isForDescription = true
			this.methodHandleParamsBar(true, 'general')
		},

		methodHandleParamsBar(show, selection) {
			this.actionSetParamsBar({ show: show, selection: selection })
		},

		methodEmitChange(unchangedValue, newValue) {
			const unchangedForms = cloneDeep(unchangedValue)
			const tempClonedForms = cloneDeep(newValue)

			if (!isEqual(tempClonedForms, unchangedForms)) {
				this.$emit('unSaveChange', true)
				this.$emit('giveUpdatedSectionsList', newValue)
				return
			}
			this.$emit('giveUpdatedSectionsList', newValue)

			this.$emit('unSaveChange', false)
		},

		checkMove(event) {
			if (!event.relatedContext.element.enabled) {
				return false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';
.divider {
	border-color: transparent;
	border-top: 1px solid #ebebeb;
	margin: 0.7rem 2.5rem 1.3rem 0;
}

.preview-form {
	justify-content: center;
	&.--params-show {
		@media (min-width: 768px) {
			padding-left: 3rem;
			justify-content: start;
		}
		@media (min-width: 1024px) {
			padding-right: 7.75rem;
		}
		@media (min-width: 1280px) {
			padding-right: 16.5rem;
		}
		@media (min-width: 1536px) {
			padding-right: 25rem;
		}
		@media (min-width: 1800px) {
			padding-right: 28rem;
		}
	}

	&__container {
		@media (min-width: 768px) {
			margin-top: v-bind(computedMarginLandscape);
		}
	}
}

.paramsBarContainer {
	width: 100%;
}

@media (min-width: 768px) {
	.paramsBarContainer {
		width: 43%;
	}
}
@media (min-width: 850px) {
	.paramsBarContainer {
		width: 40%;
	}
}
@media (min-width: 950px) {
	.paramsBarContainer {
		width: 38%;
	}
}
@media (min-width: 1024px) {
	.paramsBarContainer {
		width: 360px;
	}
}

.content {
	max-height: 0;
	overflow: hidden;
	transition: all 0.45s ease;
}

.add-field-icon {
	transition: transform 0.4s ease;
}
.newFieldCta:hover {
	transition: all;
	opacity: 0.75;
	.add-field-icon {
		transition: transform 0.4s ease;
		transform: rotate(180deg);
	}
}

.field {
	transition: background-color 0.3s ease;
}
</style>

<style lang="scss">
.choosen {
	background-color: white;
	opacity: 1 !important;
	border-radius: 10px;
}
</style>
