<template>
	<modal-lists
		:title="$t('SETTINGS.SEGMENTS.MODAL.DELETE.TITLE')"
		:show-modal="displayModal"
		width="40%"
		@close="displayModal = false"
	>
		<div class="text-sm">
			<p class="mb-4">
				{{ $t('SETTINGS.SEGMENTS.MODAL.DELETE.DESCRIPTION') }}
				<span v-if="segmentSelectedTitle" class="text-pink-main">{{ segmentSelectedTitle }}</span> ?
			</p>
			<p>
				{{ $t('SETTINGS.SEGMENTS.MODAL.DELETE.ALT_DESCRIPTION') }}
			</p>
		</div>
		<template #footer>
			<div class="flex justify-end items-center">
				<div class="mr-2">
					<q-button ghost @click="displayModal = false">
						{{ $t('_COMMON.CANCEL') }}
					</q-button>
				</div>
				<q-button @click="methodHandleClickDeleteList">
					{{ $t('_COMMON.DELETE') }}
				</q-button>
			</div>
		</template>
	</modal-lists>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import modalLists from '@/components/contact-module/contact-dynamic-lists/modal-lists.vue'

const emit = defineEmits(['delete'])

const displayModal = defineModel('displayModal', { type: Boolean })

const methodHandleClickDeleteList = () => {
	emit('delete')
}

const props = defineProps({
	segmentSelectedTitle: { type: String, default: '' },
})

</script>

<style scoped></style>
