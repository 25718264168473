<template>
	<div class="h-full flex flex-col">
		<div
			class="flex px-6 py-4 w-full items-center bg-white z-40 top-0 menu-shadow border-b-4 border-pink-lighted"
		>
			<span v-for="(tab, index) in computedGetTabs" :key="index + 1">
				<tab-with-number
					v-if="tab.isAuthorized"
					:title="tab.title"
					:selected="$route.name === tab.name"
					:status-pack="tab.status"
					:is-soon="tab.isSoon"
					font-size="1.1rem"
					class="mr-10 cursor-pointer"
					@tabClick="methodGoTo(tab.name)"
				>
				</tab-with-number>
			</span>
		</div>
		<router-view class="flex"></router-view>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'SurveyResult',
	components: {
		TabWithNumber: defineAsyncComponent(() => import('../../general/tabWithNumber.vue')),
	},

	computed: {
		...mapGetters('@packs', ['getStatusSurveyAccessAdmin', 'getStatusSurveyAccessAdmin']),
		...mapGetters(['userConnected']),

		computedGetTabs() {
			return [
				{
					title: this.$t('SURVEY.MOBILE_SURVEY'),
					name: 'survey-builder',
					status: 'enabled',
					isAuthorized: this.userConnected?.role_data?.access?.includes('survey:delete'),
				},
				// {
				// 	title: this.$t('SURVEY.ONLINE_SURVEY'),
				// 	name: 'online-survey',
				// 	status: 'enabled',
				// 	isSoon: true,
				// 	role: this.computedIsAuthorizedTab,
				// },
				{
					title: this.$t('SURVEY.GENERAL'),
					name: 'survey-results-general',
					status: 'enabled',
					isSoon: false,
					isAuthorized: true,
				},
				{
					title: this.$t('SURVEY.DETAILED'),
					name: 'survey-results-detailed',
					status: 'enabled',
					isSoon: false,
					isAuthorized: true,
				},
			]
		},
	},

	methods: {
		methodGoTo(name) {
			this.$router.push({ name: name })
		},
	},
}
</script>
