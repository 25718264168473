import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

export const getCoverByActionType = (action) => {
	function randomIntFromInterval() { // min and max included
		return Math.floor(Math.random() * (9 - 1 + 1) + 1)
	}

	function getDiscriminant(id) {
		const idStr = id?.toString()

		let sumDigits = 0
		for (let i = 0; i < idStr.length; i++) {
			sumDigits += parseInt(idStr[i])
		}

		let discriminant = sumDigits % 9

		if (discriminant === 0) {
			discriminant = 9
		}

		return discriminant
	}

	function getCover(action) {
		const { type, id, discriminant } = action
		const cover_index = discriminant ? discriminant : getDiscriminant(id)

		switch (type) {
			case 'event':
				return `/static/images/actions/covers/event_${cover_index}.png`
			case 'canvassing':
			case 'poster':
			case 'gotvcanvassing':
				return `/static/images/actions/covers/canvassing_${cover_index}.png`
			case 'call':
			case 'gotvcalling':
				return `/static/images/actions/covers/calling_${cover_index}.png`

			case 'sharing':{
				const local_index = Number(cloneDeep(cover_index)) % 2 === 0 ? 1 : 2
				return `/static/images/actions/covers/sharing_${local_index}.png`
			}
			case 'static': {
				// Garde l'index entre 1-6 pour correspondre aux images disponibles
				// Si > 6, utilise le modulo pour revenir dans la plage
				const local_index = (Number(cover_index) <= 6) ? Number(cover_index) : Number(cover_index) % 6 || 6
				return `/static/images/actions/covers/static_${local_index}.png`
			}
			case 'mail':
			case 'other':
				return `/static/images/actions/covers/mail_${cover_index}.png`
			default:
				return `/static/images/actions/covers/other_${cover_index}.png`
		}
	}

	if (action.cover_img && !isEmpty(action?.cover_img) && !isEmpty(action?.cover_img?.url) && action?.cover_img?.url.includes('http')) return action.cover_img

	return {
		background_size_ratio: action?.cover_img?.background_size_ratio || 1,
		cover_img_x: action?.cover_img?.cover_img_x || 0,
		cover_img_y: action?.cover_img?.cover_img_y || 0,
		index: !isEmpty(action?.cover_img?.url) && !action?.cover_img?.url.includes('http') ? action?.cover_img?.url : null,
		url: getCover(
			{
				type: action?.type_data || action?.type || '',
				id: action?.ID || randomIntFromInterval(),
				discriminant: action?.cover_img?.url.includes('http') ? null : action?.cover_img?.url,
			},
		),
	}
}

export const getCoversByActionType = (actionType) => {
	function loopToGetAllCovers(url, bannersCount) {
		const allCovers = []
		for (let i = 1; i <= bannersCount; i++) {
			allCovers.push(`${url}_${i}.png`)
		}
		return allCovers
	}

	function getBannersCount(actionType) {
		switch (actionType) {
			case 'sharing': return 2
			case 'static': return 6
			default: return 9
		}
	}

	function getCovers(actionType) {
		let url = ''
		switch (actionType) {
			case 'event':
				url = '/static/images/actions/covers/event'
				break
			case 'canvassing':
			case 'poster':
			case 'gotvcanvassing':
				url = '/static/images/actions/covers/canvassing'
				break
			case 'call':
			case 'gotvcalling':
				url = '/static/images/actions/covers/calling'
				break
			case 'sharing':{
				url = '/static/images/actions/covers/sharing'
				break
			}
			case 'static': {
				url = '/static/images/actions/covers/static'
				break
			}
			case 'mail':
			case 'other':
				url = '/static/images/actions/covers/mail'
				break
			default:
				url = '/static/images/actions/covers/other'
				break
		}

		return loopToGetAllCovers(url, getBannersCount(actionType))
	}

	return getCovers(actionType)
}
