<template>
	<label
		class="q-control"
		:class="{
			disabled,
			clickable: !disabled,
			'one-liner': oneLiner,
			'no-height': noHeight,
			'add-more-padding': addMorePadding,
			'flex-col justify-center h-full flex-grow': large,
			[selectedClass]: checked,
		}"
		:style="`display: ${noDisplay ? 'none' : 'flex'}`"
		@click.stop="emitEvent('click')"
	>
		<span
			v-if="isSlotOnLeft"
			class="ml-1 align-middle"
		><slot /></span>
		<input
			ref="check"
			type="checkbox"
			style="padding: 1px; margin: 1px"
			:class="[
				theme,
				{
					'q-switch': isSwitch,
					danger: danger,
					xl: xl,
					l: l,
					md: md,
					'gui-less': partialChecked,
				}]"
			:disabled="disabled"
			:checked="checked"
			@change="emitEvent('update:modelValue')"
		>
		<span
			v-if="!isSlotOnLeft"
			class="ml-1 align-middle"
		><slot /></span>
	</label>
</template>

<script>
export default {
	props: {
		modelValue: {
			type: [Boolean, String],
			default: '',
		},
		isSwitch: {
			type: Boolean,
			default: false,
		},
		danger: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		oneLiner: {
			type: Boolean,
			default: true,
		},
		addMorePadding: {
			type: Boolean,
			default: false,
		},
		md: {
			type: Boolean,
			default: false,
		},
		l: {
			type: Boolean,
			default: false,
		},
		xl: {
			type: Boolean,
			default: false,
		},
		noDisplay: {
			type: Boolean,
			default: false,
		},
		preventEmit: {
			type: Boolean,
			default: false,
		},
		noHeight: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		selectedClass: {
			type: String,
			default: '',
		},
		isSlotOnLeft: {
			type: Boolean,
			default: false,
		},
		partialChecked: {
			type: Boolean,
			default: false,
		},
		theme: {
			type: String,
			default: 'light',
		},
	},

	emits: ['name', 'click', 'update:modelValue'],

	methods: {
		emitEvent(name) {
			if (!this.preventEmit) {
				this.$emit(name, this.$refs.check.checked)
			}
		},
	},
}
</script>
