<template>
	<div
		id="CTA-target"
		class="q-col bg-white"
	>
		<div class="flex w-full">
			<div
				id="searchSection"
				class="flex w-7/12 flex-col px-10 py-20"
			>
				<div
					id="filterSection"
					ref="filterSection"
					class="q-container q-hide-scroll"
				>
					<div
						ref="Geographical"
						class="q-hide-scroll-content"
						@click.prevent="toggleSection('Geographical')"
					>
						<div class="flex items-baseline">
							<h2 class="text-3xl font-title mb-6 mr-2">
								{{ computedSectionTitle }}
							</h2>
							<el-tooltip
								v-if="!getNeedToBlockTargetCitiesAndTerritories"
								class="item ml-3"
								effect="dark"
								:show-arrow="false"
								popper-class="rounded-xl"
								:content="$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_DRAW_MAP', 0)"
								placement="top"
							>
								<span class="gui-infos text-xl text-gray-dark pointer" />
							</el-tooltip>
						</div>
						<div
							key="title"
							class="contact-selected-container text-xl mb-4"
						>
							<action-creation-display-count-contacts
								display-component
								:count-contacts="countContactsSelected"
								:override-text="overrideTextForTitleContactsSelected"
							/>
						</div>
					</div>
					<div class="q-hide-scroll-content fields mt-8">
						<template v-if="!isEntryPointCommandCenter || computedIsNewActionPolygonSet">
							<div
								:class="`flex flex-col field ${
									getNeedToBlockTargetCitiesAndTerritories || computedIsNewActionPolygonSet
										? 'disable-select-inputs'
										: ''
								}`"
							>
								<el-select
									v-model="searchInput"
									:placeholder="
										!getNeedToBlockTargetCitiesAndTerritories || computedIsNewActionPolygonSet
											? $t('ACTION.CALL_TO_ACTION.TARGET.SEARCH_PLACEHOLDER')
											: ' '
									"
									:remote-method="onChangeAutocompletion"
									value-key="value"
									remote
									filterable
									clearable
									multiple
									:disabled="
										getNeedToBlockTargetCitiesAndTerritories || computedIsNewActionPolygonSet
									"
									popper-class="rounded-xl"
									@clear="resetAutocomplete"
									@visible-change="removeOccurrencesFromQuery"
								>
									<el-option
										class="rounded-lg m-1"
										v-for="(item, itemIndex) in allOccurencesFromQuery"
										:key="`${item.value}-${itemIndex}`"
										:label="`${item.name} ${
											item.count > 0 && !getNeedToBlockTargetCitiesAndTerritories
												? ' - ' +
													$tc('ACTION.CALL_TO_ACTION.SEARCH.COUNT_CONTACT_ADDRESSES', item.count, {
														count: item.count,
													})
												: ''
										}`"
										:value="item.value"
									>
										<div class="q-row space-between">
											<span>{{ item.name }}</span>
											<span class="contacts-address">{{
												`${$tc('ACTION.CALL_TO_ACTION.SEARCH.COUNT_CONTACT_ADDRESSES', item.count, {
													count: item.count,
												})}`
											}}</span>
										</div>
									</el-option>
								</el-select>
							</div>
							<div
								v-if="isNeededToDisplayCheckboxPhoneOrMobile"
								class="w-full mb-8"
							>
								<q-check
									:checked="fromVuexPhoneOnly"
									disabled
									style="flex: 2"
								>
									{{ fromVuexDisplayMobileCheckbox ? t.knownMobileOnly : t.knownPhoneOnly }}
								</q-check>
							</div>
							<div
								v-if="
									!getNeedToBlockTargetCitiesAndTerritories &&
										(newAction.type === ACTION.TYPES.CANVASSING ||
											newAction.type === ACTION.TYPES.MAIL)
								"
								class="w-full text-gray-dark mt-4 flex items-center"
							>
								<span class="gui-infos text-xl" /><span class="ml-2">{{ computedDrawMapHelper }}</span>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="flex-1">
				<transition-group
					id="mapSection"
					class="q-col unset q-container nowrap"
					name="fade"
					tag="div"
				>
					<kpi
						key="map"
						class="w-full"
						id-map="map-target"
						:disable-turf="
							getNeedToBlockTargetCitiesAndTerritories ||
								!!searchInput.length ||
								!!territories.length
						"
						:disable-search-with-map="
							getNeedToBlockTargetCitiesAndTerritories ||
								!!searchInput.length ||
								!!territories.length
						"
						:need-to-set-new-polygon-action="true"
						:rewrite-search-kpi-geohash="triggerNewSearchData"
						:avoid-rerender-geohash="getNeedToBlockTargetCitiesAndTerritories || computedIsNewActionPolygonSet"
					/>
				</transition-group>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * This component is big. It has been reworked (not totally)
 * to be compliant on the new action workflow. The old file used was target.vue
 * Due to the original complexity of the file, this file do not use the container or
 * the bottom bar.
 */
import { mapGetters, mapActions } from 'vuex'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { actionTypology } from 'src/mixins'
import { ACTION } from 'src/constants'
import beforeLeaveRouteHelperGoBack from '../navigation-guards/beforeLeaveRouteHelperGoBack'
import { nextTick } from 'vue'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ActionCreationDisplayCountContacts: defineAsyncComponent(() =>
			import('../creation-utils/ActionCreationDisplayCountContacts'),
		),
		Kpi: defineAsyncComponent(() =>
			import('../../../contact-module/contact-list/contact/sidebars/kpi'),
		),
	},
	emits: ['update:nextValidatorBoolean', 'update:nextFunction', 'update:errorMessage'],

	mixins: [actionTypology],

	beforeRouteLeave(to, from, next) {
		/**
		 * Really important function to avoid conflict
		 * when we go back and change the setup of the
		 * action type, on a different workflow.
		 * All the functions used here are for resetting the
		 * filters or elements puts in the vuex store to empty it.
		 */
		if (beforeLeaveRouteHelperGoBack(to)) {
			if (!this.getNeedToBlockTargetCitiesAndTerritories) {
				if (this.newAction.type === ACTION.TYPES.CALL)
					this.actionSetNewActionFilters({ phone_filter: '', nb_results: 0 })
				else this.actionSetNewActionFilters({ nb_results: 0 })
				// this.actionSetPolygon([])
				this.actionSetNewActionPolygon([])
				this.actionResetAddressIncluded()
				this.actionResetPollingStationIncluded()
				this.actionGenerateLocationSummaryGeohashFromAction()
				this.actionOverrideKpiTotalHit(0)
			}
		}
		next()
	},

	data() {
		const prefix = 'ACTION.CALL_TO_ACTION.TARGET'
		return {
			toast: '',
			testing: '',
			ACTION,
			visitedOrNot: 'all',
			visitedOrNotCheck: false,
			getOldFormFilterData: [],
			lastchange_filter: '',
			searchInput: [],
			filtertags: [],
			territories: [],
			refused: '',
			notPresent: '',
			visitedChoice: [
				{
					value: 'all',
					label: this.$t(`${prefix}.ALL`),
				},
				{
					value: 'not',
					label: this.$t(`${prefix}.ABSENT_OR_NOT_VISITED`),
				},
			],
			valueOptionIncludedEmptyAddresses: '',
			showGeographical: screen.width > 568,
			showChronological: false,
			showLogical: false,
			shouldSearchOnCity: false,
			forceNotSeen: false,
			maxBound: 25000,
			allOccurencesFromQuery: [],
			isEntryPointCommandCenter: false,
		}
	},

	computed: {
		...mapGetters(['contacts']),
		...mapGetters('@action', [
			'activeStep',
			'getTotalHitsFound',
			'newAction',
			'steps',
			'entryPoint',
			'getNeedToBlockTargetCitiesAndTerritories',
			'getNewActionisFilterMobileOrPhoneSelected',
		]),

		...mapGetters('@form', {
			forms_presence: 'getNamePresence',
		}),

		...mapGetters(['contactsCount', 'userConnected', 'total_hit']),
		...mapGetters('@filter', ['filter', 'polygon', 'tags_filter', 'form_filter_data']),

		...mapGetters({
			locationSummaryGeoHash: 'location_summary_geohash',
		}),
		...mapGetters('@tags', ['getTags']),
		...mapGetters('@kpi', ['kpi']),

		...mapGetters('@search', [
			'isStreetAddressOrPollingStationsIncluded',
			'getSearchAddressIncluded',
			'getSearchPollingStationsIncluded',
		]),

		computedSameValidationOptionForMailAndCanvassing() {
			return (
				this.computedDataIsProvidedFromInputOrPolygon
				|| (!this.computedDataIsProvidedFromInputOrPolygon && this.getTotalHitsFound > 0)
			)
		},

		computedDataIsProvidedFromInputOrPolygon() {
			return (this.searchInput.length ? true : false) || this.computedIsNewActionPolygonSet
		},
		isNeededToDisplayStreetsSelected() {
			if (this.newAction.type === ACTION.TYPES.CALL) {
				return false
			}
			return true
		},
		isNeededToDisplayCheckboxPhoneOrMobile() {
			if (this.newAction.type === ACTION.TYPES.CALL) {
				return true
			}
			return false
		},

		countContactsSelected() {
			return this.getTotalHitsFound
		},

		overrideTextForTitleContactsSelected() {
			if (this.entryPoint === 'contacts-list' && this.getNeedToBlockTargetCitiesAndTerritories) {
				return this.$tc(
					'ACTION.CALL_TO_ACTION.INFORMATIONS.COUNT_CONTACTS_SELECTED_FROM_DATABASE',
					this.countContactsSelected,
					{
						count: this.countContactsSelected,
					},
				)
			}
			if (this.newAction.type === ACTION.TYPES.CALL) {
				return this.$tc(
					'ACTION.CALL_TO_ACTION.INFORMATIONS.COUNT_CONTACT_SELECTED_CALL',
					this.countContactsSelected,
				)
			}
			return ''
		},

		fromVuexDisplayPhoneCheckbox() {
			const { displayPhoneCheckbox } = this.getNewActionisFilterMobileOrPhoneSelected
			return displayPhoneCheckbox
		},
		fromVuexDisplayMobileCheckbox() {
			const { displayMobileCheckbox } = this.getNewActionisFilterMobileOrPhoneSelected
			return displayMobileCheckbox
		},
		fromVuexPhoneOnly() {
			const { phoneOnly } = this.getNewActionisFilterMobileOrPhoneSelected
			return phoneOnly
		},

		t() {
			const prefix = 'ACTION.CALL_TO_ACTION.TARGET'
			const prefixFirstStepActionFromMap = 'CARTO.CC.CALL_TO_ACTION.ADD_ACTION_FIRST_STEP'
			return {
				upperBoundReached: this.$t(`${prefix}.UPPER_BOUND_REACHED`, {
					count: this.maxBound,
				}),
				upperBoundReachedWarn: this.$t(`${prefix}.UPPER_BOUND_REACHED_WARN`, {
					count: this.maxBound,
				}),

				tags: this.$t(`${prefix}.TAGS`),
				tagPlaceholder: this.$t(`${prefix}.TAG_PLACEHOLDER`),

				knownPhoneOnly: this.$t(`${prefix}.KNOWN_PHONE_ONLY`),
				knownMobileOnly: this.$t(`${prefix}.KNOWN_PHONE_ONLY_MOBILE`),

				combineContactsUnknowStreets: this.$t(
					`${prefixFirstStepActionFromMap}.COMBINE_CONTACTS_UNKNOW_STREETS`,
				),
				onlyContacts: this.$t(`${prefixFirstStepActionFromMap}.ONLY_CONTACTS`),
			}
		},

		computedErrorMessage() {
			if (this.countContactsSelected > this.maxBound) return this.$t('ACTION.CALL_TO_ACTION.TARGET.UPPER_BOUND_REACHED', {
				count: this.maxBound,
			})
			if (this.countContactsSelected === 0) return this.$tc('ACTION.CALL_TO_ACTION.INFORMATIONS.COUNT_CONTACTS_SELECTED_FROM_DATABASE', 0)
			return ''
		},

		previous() {
			if (this.activeStep.index > 0) {
				return this.steps[this.activeStep.index - 1].route
			}
			else {
				return null
			}
		},

		isSearchAllowed() {
			return this.searchOnBaseFunc(this.newAction.type) && !this.isPolygonSelected
		},

		isPolygonSelected() {
			return !isEmpty(this.polygon)
		},

		/**
		 * The two following computed(s) is designed
		 * to give the actual list of empty or not addresses
		 * selected in the get from vuex connected to the component
		 */
		computedAddressesNonEmpty() {
			if (this.getSearchAddressIncluded.length) {
				const addressesNonEmptyArray = this.getSearchAddressIncluded.filter(
					(anAddress) => !anAddress.empty,
				)
				return addressesNonEmptyArray.length
			}
			return 0
		},
		computedAddressesEmpty() {
			if (this.getSearchAddressIncluded.length) {
				const addressesEmptyArray = this.getSearchAddressIncluded.filter(
					(anAddress) => anAddress.empty,
				)
				return addressesEmptyArray.length
			}
			return 0
		},
		// End of specific computeds
		computedIsNewActionPolygonSet() {
			if (this.newAction.search.polygon.length) return true
			return false
		},
		computedDrawMapHelper() {
			switch (this.newAction.type) {
				case ACTION.TYPES.CANVASSING:
					return this.$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_DRAW_MAP', 2)

				case ACTION.TYPES.MAIL:
					return this.$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_DRAW_MAP', 1)

				default:
					return this.$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_DRAW_MAP', 0)
			}
		},
		computedSectionTitle() {
			if (this.getNeedToBlockTargetCitiesAndTerritories)
				return this.$t('ACTION.CALL_TO_ACTION.TARGET.GEO_SECTION_RECAP')
			else if (this.newAction.type === ACTION.TYPES.CANVASSING) {
				return this.$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_LOCATION', 2)
			}
			else if (this.newAction.type === ACTION.TYPES.MAIL) {
				return this.$tc('ACTION.CALL_TO_ACTION.TARGET.HELPER_LOCATION', 1)
			}
			else return this.$t('ACTION.CALL_TO_ACTION.TARGET.GEO_SECTION')
		},

		computedGetValidator() {
			return this.validatorActionType() && this.computedSameValidationOptionForMailAndCanvassing
		},
	},

	watch: {
		searchInput: {
			handler(values) {
				this.actionResetAddressIncluded()
				values.forEach((aValueSelected) => {
					const futureAddressToInclude = aValueSelected

					this.actionAddAddressIncluded(JSON.parse(futureAddressToInclude))
				})
				this.updateData()
			},
			deep: true,
		},

		territories: {
			handler(values) {
				this.actionResetPollingStationIncluded()
				values.forEach((aValueSelected) => {
					const futureAddressToInclude = aValueSelected
					this.actionAddPollingStationIncluded(futureAddressToInclude)
				})
				this.updateData()
			},
			deep: true,
		},

		visitedOrNotCheck(newVal) {
			this.visitedOrNot = newVal ? 'not' : 'all'
		},

		visitedOrNot() {
			this.updateData()
		},

		computedGetValidator: {
			handler(newValue) {
				this.$emit('update:nextValidatorBoolean', newValue)
			},
			immediate: true,
		},

		computedErrorMessage: {
			handler() {
				this.$emit('update:errorMessage', this.computedErrorMessage)
			},
			immediate: true,
		},
	},

	beforeMount() {
		if (this.entryPoint === 'command-center') {
			this.isEntryPointCommandCenter = true
		}
	},

	created() {
		/**
		 * Initialize the searchInput to include addresses already
		 * declared.
		 */

		const addressIncluded = this.getSearchAddressIncluded
		if (addressIncluded) {
			addressIncluded.forEach((anAddress) => {
				this.allOccurencesFromQuery.push({
					value: JSON.stringify(anAddress),
					name:
						anAddress.city && !anAddress.street
							? `${this.capitalizeFirst(anAddress.city)} (${this.$t('ACTION.CALL_TO_ACTION.TARGET.ADDRESS.CITY')})`
							: `${this.capitalizeFirst(anAddress.street)}, ${this.capitalizeFirst(
									anAddress.city,
									// eslint-disable-next-line
							  )}`,
					count: anAddress.count,
				})
			})
		}

		if (addressIncluded) {
			addressIncluded.forEach((anAddress) => {
				this.searchInput.push(JSON.stringify(anAddress))
			})
		}
	},

	mounted() {
		this.actionSetStep('TARGET')
		this.getSpecialQuestionRequest()
		if (!this.getTags.length) {
			this.actionGetTags()
		}

		this.shouldSearchOnCity = !!this.newAction.city

		if (this.getSearchPollingStationsIncluded.length) {
			this.territories = this.getSearchPollingStationsIncluded
		}

		if (!this.newAction.search.questions) {
			this.newAction.search.questions = this.form_filter_data || []
		}
		else {
			let indexOfNotPresent = this.newAction.search.questions.indexOf(this.notPresent)
			let indexOfRefused = this.newAction.search.questions.indexOf(this.refused)

			this.visitedOrNot = indexOfNotPresent > -1 || indexOfRefused > -1 ? 'not' : 'all'

			this.visitedOrNotCheck = this.visitedOrNot === 'not'
			if (indexOfNotPresent > -1) {
				this.newAction.search.questions.splice(indexOfNotPresent, 1)
			}
			if (indexOfRefused > -1) {
				if (indexOfNotPresent > -1) {
					indexOfRefused--
				}
				this.newAction.search.questions.splice(indexOfRefused, 1)
			}
		}
		this.lastchange_filter = this.newAction.search.filter[9] || ''
		this.timeBreakpoint = this.newAction.search.timeDirection || 0
		if (this.newAction.search && this.newAction.search.tags_filter) {
			this.filtertags = this.newAction.search.tags_filter
		}

		/**
		 * We would like to store current form filters
		 * to use it later on the search
		 */

		this.getOldFormFilterData = this.newAction.search.filter[12]
			? this.newAction.search.filter.slice(12, this.newAction.search.filter.length)
			: []
		this.updateData()
		this.$emit('update:nextFunction', null)
	},
	methods: {
		...mapActions('@action', [
			'actionSetNewActionPolygon',
			'actionSetStep',
			'getAutocomplete',
			'actionSetNewActionFilters',
			'actionGenerateKpiFromAction',
			'actionGenerateKpiFromAction2',
			'actionGenerateLocationSummaryGeohashFromAction',
			'actionEditNewAddressesAction',
			'actionOverrideKpiTotalHit',
		]),
		...mapActions('@tags', ['actionGetTags']),
		...mapActions('@search', [
			'actionAddAddressIncluded',
			'actionAddPollingStationIncluded',
			'actionResetAddressIncluded',
			'actionResetPollingStationIncluded',
			'getAdvancedSearchQuery',
		]),
		...mapActions({
			search: 'SEARCH_CONTACT',
		}),
		...mapActions('@filter', ['actionSetFiltersForSearch', 'actionSetPolygon']),

		removeAddress(index) {
			this.newAction.Addresses.splice(index, 1)
			this.$forceUpdate()
		},

		addAddress() {
			this.newAction.Addresses.push({})
			this.$forceUpdate()
		},

		toggleSection(section) {
			if (!this['show' + section]) {
				setTimeout(() => {
					this.$refs[section].scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				}, 10)
			}

			this['show' + section] = !this['show' + section]
		},

		addFilter() {
			this.updateData()
		},

		handleQuestions(questions) {
			this.actionSetFiltersForSearch(questions)
			this.updateData()
		},

		addDate() {
			nextTick().then(() => {
				this.updateData()
			})
		},

		reset_lastchange() {
			this.lastchange_filter = ''
			nextTick().then(() => {
				this.updateData()
			})
		},

		validatorActionType() {
			// If the action type is call, we don't want to create an empty action
			if (this.newAction.type === ACTION.TYPES.CALL) {
				return !!this.locationSummaryGeoHash && this.getTotalHitsFound > 0
			}

			return this.getTotalHitsFound <= this.maxBound
		},

		updateData: debounce(function () {
			// Object used by src/store/modules/utils/createFilterFromParamsObject.js
			// This object will be read and it will modify
			// the filter params.

			var params = {
				group_id: this.userConnected.selected_group_id.toString(),
				searchType: 'fullname',
				query: this.newAction.search.query,
				order: 'true',
				result_index: '',
				bdv_filter: this.territories.join('/'),
				phone_filter: this.newAction.search.filter[11],
				lastchange_filter: this.newAction.search.filter[9],
				tags_filter: this.filtertags,
				form_filter_data: this.methodReturnArrayOfFormFilterData(),
			}

			this.actionSetNewActionFilters(params)
			this.triggerNewSearchData()
		}, 500),

		getSpecialQuestionRequest() {
			const formPresence = this.forms_presence[0]
			if (formPresence) {
				this.refused = [
					formPresence.type.toUpperCase(),
					formPresence.id,
					false,
					formPresence.refvalues.filter((refvalue) => refvalue.value === 'refus')[0].id,
				].join('/')

				this.notPresent = [
					formPresence.type.toUpperCase(),
					formPresence.id,
					false,
					formPresence.refvalues.filter((refvalue) => refvalue.value === 'present')[0].id,
				].join('/')
			}
			else {
				// console.warn('formPresence is', formPresence)
			}
		},

		/**
		 * @method cleanQuery
		 * @param {String} query
		 * @desc lower case, normalize and remove any accent to the given string
		 * @returns {String}
		 *
		 * @example
		 * cleanQuery('ÉÎö') // returns 'eio'
		 *
		 * @deprecated  extract every cleanQuery throughout the app in one place. Mixin, Filter ?
		 */
		cleanQuery(query) {
			// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
			return query
				.toString()
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
		},

		capitalizeFirst(str) {
			if (!isEmpty(str)) return str[0].toUpperCase() + str.slice(1)
			return ''
		},

		onChangeAutocompletion: debounce(async function (query) {
			let subEntities = []
			if (query && query !== undefined && query !== '') {
				const cleanedQuery = this.cleanQuery(query)
				await this.getAutocomplete({
					query: cleanedQuery,
					allowUnknowAddresses: this.newAction.type !== ACTION.TYPES.CALL,
				})
					.then((res) => {
						subEntities = res.flatMap((occ) => {
							let subResult
							if (Array.isArray(occ.subentities)) {
								subResult = occ.subentities.map((sub) => {
									const postalCode = get(sub, 'postalcode', '')
									return {
										value: JSON.stringify({
											street: sub.occurrence,
											city: occ.occurrence,
											postalcode: postalCode,
											empty: sub.count === 0,
											count: sub.count,
										}),
										count: sub.count,
										name: `${this.capitalizeFirst(sub.occurrence)}${
											this.capitalizeFirst(sub.occurrence).length > 0 ? ', ' : ''
										}${this.capitalizeFirst(occ.occurrence)}${postalCode ? ', ' + postalCode : ''}`,
										empty: sub.count === 0,
									}
								})
							}
							else {
								subResult = []
							}

							// ~x ≡ (x != -1)
							if (~occ.occurrence.indexOf(query.toLowerCase()))
								subResult.unshift({
									value: JSON.stringify({
										city: occ.occurrence,
										count: occ.count,
									}),
									name: `${this.capitalizeFirst(occ.occurrence)} (${this.$t('ACTION.CALL_TO_ACTION.TARGET.ADDRESS.CITY')})`,
									empty: occ.count === 0,
									count: occ.count,
								})
							return subResult
						})
						subEntities.unshift({
							value: query,
							type: 'name',
						})
						subEntities = subEntities.filter((anEntity) => anEntity && anEntity.value)
					})
					.catch((e) => {
						console.warn(e)
					})
				this.allOccurencesFromQuery = subEntities
				/**
				 * Remove the first item of the array
				 * to clean it.
				 */
				this.allOccurencesFromQuery.shift()
			}
		}, 250),

		resetAutocomplete() {
			this.searchInput = []
		},

		/**
		 * @func removeOccurrencesFromQuery
		 * @link https://element.eleme.io/#/en-US/component/select#select-events
		 * @desc On the dropdown display value, we remove the occurences
		 * to avoid the effect that we click again on the component
		 * and we have the list of the last streets when we type something
		 */
		removeOccurrencesFromQuery(value) {
			if (value === false) {
				if (!isEmpty(this.allOccurencesFromQuery)) {
					this.allOccurencesFromQuery = []
				}
			}
		},

		triggerNewSearchData() {
			/**
			 * We create a system will watch the nb_results computed.
			 * If the nbResultsWanted is set to 0, we don't want to display
			 * kpis number. It's actually bugged in the backend.
			 */
			let nbResultsWanted = 100000

			if (!this.getNeedToBlockTargetCitiesAndTerritories) {
				const addressIncluded = this.getSearchAddressIncluded
				const pollingStationsIncluded = this.getSearchPollingStationsIncluded
				const polygon = this.newAction.search.polygon
				if (
					!isEmpty(addressIncluded)
					|| !isEmpty(pollingStationsIncluded)
					|| !isEmpty(this.searchInput)
					|| !isEmpty(polygon)
					|| !isEmpty(this.getAdvancedSearchQuery)
				) {
					nbResultsWanted = 100000
				}
				else {
					nbResultsWanted = 0
				}
			}

			this.actionSetNewActionFilters({
				nb_results: nbResultsWanted,
				form_filter_data: this.methodReturnArrayOfFormFilterData(),
			})
			// We rewrite the nb_results depends of the actual situation of polygon etc.

			const addressIncluded = this.getSearchAddressIncluded
			const pollingStationsIncluded = this.getSearchPollingStationsIncluded
			this.actionGenerateKpiFromAction({ addressIncluded, pollingStationsIncluded })
			this.actionGenerateLocationSummaryGeohashFromAction({
				addressIncluded,
				pollingStationsIncluded,
			})
		},

		methodReturnArrayOfFormFilterData() {
			if (this.notPresent === '' || this.refused === '') {
				this.getSpecialQuestionRequest()
			}
			// Clean previous data
			let indexOfNotPresent = this.form_filter_data.indexOf(this.notPresent)
			let indexOfRefused = this.form_filter_data.indexOf(this.refused)

			if (indexOfNotPresent > -1) {
				this.form_filter_data.splice(indexOfNotPresent, 1)
			}
			if (indexOfRefused > -1) {
				if (indexOfNotPresent > -1) {
					indexOfRefused--
				}
				this.form_filter_data.splice(indexOfRefused, 1)
			}
			const futurFormFilterData = []

			if (this.getOldFormFilterData) {
				futurFormFilterData.push(...this.getOldFormFilterData)
			}

			if (this.visitedOrNotCheck) {
				futurFormFilterData.push(this.refused, this.notPresent)
			}

			return futurFormFilterData
		},
	},
}
</script>

<style lang="scss">
@import '../../../../assets/scss/shared/shared-constants';
@import '../../../../assets/scss/datepicker_overide';

#CTA-target {
	box-sizing: border-box;
	.el-select .el-input .el-select__caret {
		color: black;
	}
}
#filterSection {
	padding-right: 10px;
}
#filterSection,
#searchSection {
	/* 6em is the height of the header */
	height: calc(100vh - 7rem);
	// @media all and (max-width: 568px) {
	// 	height: 30vh;
	// }
	// @media all and (min-width: 569px) {
	// 	height: 100%vh;
	// }
}
#mapSection {
	/* 6em is the height of the header */
	height: calc(100vh - 7rem);
	@media all and (max-width: 568px) {
		height: 100%;
		min-height: 30vh;
	}
	@media all and (min-width: 569px) {
		height: 100%vh;
	}
}

.targetMap {
	box-sizing: border-box;
	padding-left: 2 * $spacing;
}

.no-m {
	margin: 0;
}

.special-field {
	background-color: #f6f7fa;
	margin-top: 8%;
	padding: 20px;
	border-radius: 4px;
}

.contacts-address {
	color: $light-grey;
}
.addresses-selected-container {
	margin: 10px 0px;
	height: 60px;
	.addresses-selected {
		display: flex;
		color: $grey-text-help;
	}
}
.contact-selected-container {
	color: $grey-text-help;
}
</style>
