import { mapGetters } from 'vuex'

export const contact_settings_mixins = {
	computed: {
		...mapGetters('@packs', ['getAccessStatusFormsTypologiesQRCode']),

		computedGetTypesOptions() {
			let fieldTypes = [
				{
					value: 'text',
					label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.TEXT'),
					icon: 'gui-short_text',
				},
				{
					value: 'radio',
					label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.RADIO'),
					icon: 'gui-radio_on',
				},
				{
					value: 'checkbox',
					label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.CHECKBOX'),
					icon: 'gui-select_all',
				},
				{
					value: 'numeric',
					label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.NUMERIC'),
					icon: 'gui-numeric',
				},
				{
					value: 'date',
					label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.DATE'),
					icon: 'gui-calendar',
				},
				// {
				// 	value: 'range',
				// 	label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.RANGE'),
				// 	icon: 'gui-arrow_up_down',
				// },
				// {
				// 	value: 'photos',
				// 	label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.PHOTOS'),
				// 	icon: 'gui-image',
				// },
				// {
				// 	value: 'qr_code',
				// 	label: this.$t('SETTINGS.FORM_BUILDER.FIELDS_TYPE.QR_CODE'),
				// 	icon: 'gui-world',
				// },
			]

			if (this.getAccessStatusFormsTypologiesQRCode === 'disabled')
				return fieldTypes.filter((aFieldType) => aFieldType.value !== 'qr_code')
			return fieldTypes
		},
	},

	methods: {
		methodHandleCollapse(target, isJustForOpen = false) {
			const collapsableContent = document.getElementById(target)
			const arrow = document.getElementById(`arrow-${target}`)

			if (collapsableContent) {
				if (collapsableContent.style.maxHeight && !isJustForOpen) {
					collapsableContent.style.maxHeight = null
					collapsableContent.style.paddingTop = null
					arrow.classList.remove('rotate-180')
				} else {
					collapsableContent.style.maxHeight = collapsableContent.scrollHeight + 350 + 'px'
					collapsableContent.style.paddingTop = 16 + 'px'
					arrow.classList.add('rotate-180')
				}
			}
		},

		methodGetIconByFieldType(fieldType) {
			switch (fieldType) {
				case 'text':
					return 'gui-short_text'
				case 'numeric':
					return 'gui-numeric'
				case 'date':
					return 'gui-calendar'
				case 'checkbox':
					return 'gui-select_all'
				case 'radio':
					return 'gui-radio_on'
				case 'range':
					return 'gui-arrow_up_down'
				case 'photos':
					return 'gui-image'
				case 'qr_code':
					return 'gui-world'
				case 'signature':
					return 'gui-edit'
			}
		},

		methodGetTypeTranslated(type) {
			const findedType = this.computedGetTypesOptions.find((typeObject) => {
				return typeObject.value === type
			})
			if (findedType) return findedType.label
			return null
		},
	},
}
