import {
	SET_FILTERS_USERS,
	SET_FILTERS,
	RESET_FILTERS,
	RESET_POLYGON,
	RESET_FORMS_FILTERS,
	SET_RESULT_INDEX,
	SET_POLYGON,
	SET_POLYGONS,
	SET_NB_RESULTS,
	SET_NB_RESULTS_CONTACT_MEMBERS,
	SET_INSTANCIED_MODULE_ID,
} from './_mutation-types'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

const getDefaultState = () => {
	return {
		filter: [],
		age_filter: [],
		bdv_filter: [],
		email_filter: [],
		phone_filter: '',
		lastchange_filter: '',
		nb_results_users: 1000,
		nb_results: 50,
		order: true,
		query: '',
		result_index: '0',
		searchType: 'all',
		sexe_filter: [],
		sorting: 'surname',
		tags_filter: [],
		duplicates_filter: false,
		polygon: [],
		polygons: [],
		form_filter: false,
		form_filter_data: [],
		instanceId: '',
	}
}

const state = getDefaultState

/**
 * These are the keys used by set filters function
 * to configure the array use for the backend (kpi, contacts)
 */
const keysForFilter = [
	'lastchange_filter',
	'nb_results_users',
	'result_index',
	'email_filter',
	'phone_filter',
	'tags_filter',
	'sexe_filter',
	'bdv_filter',
	'age_filter',
	'nb_results',
	'searchType',
	'sorting',
	'query',
	'order',
	'duplicates_filter',
]

/**
 * getTheInitialArray for filters
 */
const getInitialFilterArray = (state, rootState) => {
	const initialFilterArray = new Array(
		rootState?.userConnected?.selected_group_id
			? rootState?.userConnected?.selected_group_id.toString()
			: '',
		state.searchType,
		state.nb_results.toString(),
		state.result_index.toString(),
		state.sexe_filter.toString(),
		state.bdv_filter.toString(),
		state.age_filter.toString(),
		state.sorting != '' ? state.sorting : 'surname',
		state.order.toString(),
		state.lastchange_filter,
		state.email_filter.toString(),
		state.phone_filter.toString(),
	)
	return initialFilterArray
}

/**
 * @getter
 * @desc getter to know if the filter was modified
 */
const isFilterModifiedFromOrigin = (payloadState, rootstate) => {
	const { filter, tags_filter, query } = payloadState
	const initialFilterArray = getInitialFilterArray(payloadState, rootstate)
	const filterArrayCopy = cloneDeep(filter)
	const initialFilterArrayCopy = cloneDeep(initialFilterArray)
	filterArrayCopy[3] = '100000'
	initialFilterArrayCopy[3] = '100000'
	return !(
		isEqual(filterArrayCopy, initialFilterArrayCopy)
		&& isEqual(tags_filter, state.tags_filter)
		&& isEqual(query, state.query)
	)
}

const mutations = {
	[SET_FILTERS_USERS]: (state, obj) => {
		state.order = obj.order != undefined ? obj.order : state.order
		state.result_index = obj.result_index != undefined ? obj.result_index : state.result_index
		// state.filter = state.order;
	},

	// Initialisation des filtres
	[SET_FILTERS]: (state, payload) => {
		const { user_connected, obj } = payload

		const updateOrUseOld = (key, obj, state) => {
			state[key]
				= obj && obj != undefined ? (obj[key] != undefined ? obj[key] : state[key]) : state[key]
		}

		/**
		 * KeysForFilter is upperscope to be
		 * reusable at some points in this file
		 */
		const keys = keysForFilter

		keys.forEach((key) => {
			updateOrUseOld(key, obj, state)
		})

		var tmp = new Array(
			cloneDeep(user_connected.selected_group_id?.toString()),
			state.searchType,
			state.nb_results.toString(),
			// state.nb_results_users.toString(),
			state.result_index.toString(),
			state.sexe_filter.toString(),
			state.bdv_filter.toString(),
			state.age_filter.toString(),
			state.sorting != '' ? state.sorting : 'surname',
			state.order.toString(),
			state.lastchange_filter,
			state.email_filter.toString(),
			state.phone_filter.toString(),
		)
		// Dans le cas du filtrage sur les forms, l'obj est un tableau de string contenant les filtres préformater
		// Voir recoverData() dans form-filters.vue pour plus de détails

		if ((Array.isArray(obj) && obj.length > 0) || state.form_filter_data.length > 0) {
			if (!Array.isArray(obj)) {
				tmp = tmp.concat(state.form_filter_data)
			}
			else {
				tmp = tmp.concat(obj)
				state.form_filter_data = obj
			}

			state.form_filter = true
		}
		state.filter = tmp
	},

	[RESET_FILTERS]: (state) => {
		state.sexe_filter = ''
		state.lastchange_filter = ''
		state.email_filter = ''
		state.phone_filter = ''
		state.age_filter = ''
		state.bdv_filter = ''
		state.sort_result = ''
		state.result_index = 0
		state.query = ''
		state.order = true
		state.filter = []
		state.form_filter_data = []
		state.form_filter = false
		state.nb_results = 50
		state.nb_results_users = 1000
		state.duplicates_filter = false
	},

	[RESET_POLYGON]: (state) => {
		state.polygon = []
		state.polygons = []
	},

	[RESET_FORMS_FILTERS]: (state) => {
		state.form_filter_data = []
		state.form_filter = false
	},

	[SET_RESULT_INDEX]: (state, res) => {
		state.result_index = res
	},

	[SET_POLYGON]: (state, res) => {
		state.polygon = res
	},

	[SET_POLYGONS]: (state, res) => {
		state.polygons = res
	},

	[SET_NB_RESULTS]: (state, res) => {
		state.nb_results = res
	},

	[SET_NB_RESULTS_CONTACT_MEMBERS]: (state, res) => {
		state.nb_results_users = res
	},

	[SET_INSTANCIED_MODULE_ID]: (state, res) => {
		state.instanceId = res
	},
}

const actions = {
	actionResetFiltersForSearch({ commit, rootState }) {
		commit('RESET_FILTERS')
		const userConnected = rootState.userConnected
		commit('SET_FILTERS', { user_connected: userConnected, obj: {} })
	},

	actionResetFiltersAndPolygonForSearch({ commit, rootState }) {
		commit('RESET_POLYGON')
		commit('RESET_FILTERS')
		const userConnected = rootState.userConnected
		commit('SET_FILTERS', { user_connected: userConnected, obj: {} })
	},

	actionResetPolygon({ commit }) {
		commit('RESET_POLYGON')
	},

	actionSetPolygon({ commit }, payload) {
		commit('SET_POLYGON', payload)
	},

	actionSetPolygons({ commit }, payload) {
		commit('SET_POLYGONS', payload)
	},

	actionSetPaginationIndex: ({ commit, rootState }, payload) => {
		const userConnected = rootState.userConnected
		commit('SET_FILTERS', { user_connected: userConnected, obj: { result_index: payload } })
	},

	actionSetFiltersForSearch({ commit, rootState }, payload) {
		const userConnected = rootState.userConnected
		commit('SET_FILTERS', { user_connected: userConnected, obj: payload })
	},

	actionSetResultIndex({ commit }, payload) {
		commit('SET_RESULT_INDEX', payload)
	},
	actionSetNbResult({ commit }, payload) {
		commit('SET_NB_RESULTS', payload)
	},
	actionSetInstanceId({ commit }, payload) {
		commit(SET_INSTANCIED_MODULE_ID, payload)
	},
}

const getters = {
	initialFilterArray: (state, getters, rootState) => getInitialFilterArray(state, rootState),
	getIsFilterModifiedFromOrigin: (state, getters, rootState) =>
		isFilterModifiedFromOrigin(state, rootState),
	age_filter: (state) => state.age_filter,
	bdv_filter: (state) => state.bdv_filter,
	bdv_label: (state) => state.bdv_label,
	email_filter: (state) => state.email_filter,
	filter: (state) => state.filter,
	lastchange_filter: (state) => state.lastchange_filter,
	phone_filter: (state) => state.phone_filter,
	query: (state) => state.query,
	sexe_filter: (state) => state.sexe_filter,
	sorting: (state) => state.sorting,
	sort_result: (state) => state.sort_result,
	order: (state) => state.order,
	tags_filter: (state) => state.tags_filter,
	duplicates_filter: (state) => state.duplicates_filter,
	nb_results_users: (state) => state.nb_results_users,
	nb_results: (state) => state.nb_results,
	result_index: (state) => parseInt(state.result_index),
	polygon: (state) => state.polygon,
	polygons: (state) => state.polygons,
	form_filter: (state) => state.form_filter,
	form_filter_data: (state) => state.form_filter_data,
	getInstanceId: (state) => state.instanceId,
	getState: (state) => state,
}

export const filterModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
