import { MAP } from '../../constants'
import maplibregl from 'maplibre-gl'

export function setMeetingPointMarker(mapInstance, coords, allMarkersInstance = null) {
	const { latitude, longitude } = coords
	let allMakersCopy = null

	if (latitude && longitude) {
		const iconSize = [50, 50]
		const parentEl = document.createElement('div')
		const el = document.createElement('div')
		const subEl = document.createElement('div')
		const arrowDown = document.createElement('div')

		el.style.width = `${iconSize[0]}px`
		el.style.height = `${iconSize[1]}px`
		el.style.backgroundColor = `white`
		el.className = ' bold text-2xl flex justify-center items-center border-20 border-black'
		el.style.borderRadius = `50px`
		el.style.border = `7px solid black`
		subEl.className = 'gui-pin_colorful bold text-2xl relative'
		arrowDown.className = 'maplibregl-meeting-point-marker-arrow-down'

		el.style.marginTop = '-30px'

		subEl.appendChild(arrowDown)
		el.appendChild(subEl)
		parentEl.appendChild(el)

		// el.className = 'maplibregl-command-center-marker-wrapper'
		// el.innerHTML = `
		//   <div class="maplibregl-command-center-marker-icon-black-tip"></div>
		//   <div class="maplibregl-command-center-marker-icon-black-wrapper">
		//     <div class="pin">
		//       <div class="gui-pin_colorful bold text-2xl">
		//       </div>
		//     </div>
		//   </div>
		//           `

		el.style.zIndex = '2'

		el.addEventListener('mouseenter', () => {
			el.style.zIndex = '3'
		})

		el.addEventListener('mouseleave', () => {
			el.style.zIndex = '2'
		})

		parentEl.appendChild(el)

		allMakersCopy = new maplibregl.Marker({ element: parentEl, _subpixelPositioning: true, offset: [0, -15] })
			.setLngLat([Number(longitude), Number(latitude)])
			.addTo(mapInstance)

		if (allMarkersInstance) {
			allMarkersInstance.push(allMakersCopy)
		}
	}
}

export function removeMeetingPointMarkers(allMarkersInstance) {
	if (allMarkersInstance && allMarkersInstance.length) {
		allMarkersInstance.forEach((marker) => {
			marker.remove()
		})
	}
}
