import { SET_IS_HELPER_DISPLAYED, RESET_MASTER_SPACE_STORE } from './_mutation-types'
// @ts-ignore
import { cloneDeep, findIndex } from 'lodash'

/**
 * @store @masterSpace
 * @namespaced
 * @description Master Space related info and store module
 */

const getDefaultState = () => {
	return {
		isHelperDisplayed: true,
	}
}
const state = getDefaultState

const mutations = {
	[RESET_MASTER_SPACE_STORE]: (currentState: any) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_IS_HELPER_DISPLAYED]: (state: any, payload: boolean) => {
		state.isHelperDisplayed = payload
	},
}

const actions = {
	actionResetMasterSpace({ commit }: any) {
		commit(RESET_MASTER_SPACE_STORE)
	},

	actionSetGroupFavoriteStatus({ rootGetters, commit }: any, payload: any) {
		const usersGroup = cloneDeep(rootGetters['@user/getUserGroups'])

		const groupIndex = findIndex(usersGroup, { id: payload.groupId })

		// TODO: change the value to update
		if (groupIndex !== -1) {
			usersGroup[groupIndex].disable_autourdesoi = payload.favoriteStatus.toString()
		}

		// TODO: do a request
		commit('@user/SET_USER_GROUPS', usersGroup, { root: true })
	},

	actionSetIsHelperDisplayed({ commit }: any, isHelperDisplayed: boolean) {
		commit('SET_IS_HELPER_DISPLAYED', isHelperDisplayed)
	},
}

const getters = {
	getIsHelperDisplayed: (state: any) => state.isHelperDisplayed,
}

export const masterSpaceModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
