<template>
	<div class="q-fullwidth relative">
		<!-- Have a pannel coming down when on or more actions are selected -->
		<transition
			name="slide-down"
		>
			<div
				v-if="focused >= 1 && !isShowingActionDetails && !vuexIsActionCurrentlyEdited"
				class="actions-selected-cta flex items-center "
			>
				<div class="flex items-center justify-between w-full text-xl font-bold">
					<h3
						id="action-header-menu-selected-actions"
						class="flex items-center gap-2"
					>
						<i
							class="gui-close text-3xl cursor-pointer hover:text-pink-strong"
							@click="deselectAll"
						/>
						<span class="font-title uppercase">{{ t.actionsSelected(focused) }}</span>
					</h3>
					<div class="flex items-center justify-end gap-3">
						<!-- Temp commented code to mep if JB is ok with method -->
						<!-- <el-tooltip
							:disabled="hasActionsSelected() < 11"
							placement="bottom"
							popper-class="rounded-xl"
							:show-arrow="false"
							:content="$t('ACTION.LIST.DELETED_TOOLTIP')"
						>
							<q-button
								v-if="userConnected?.role_data?.access?.includes('action:delete')"
								:disabled="hasActionsSelected() > 10"
								@click="openDeleteDialog()"
							>
								{{
									$tc('ACTION.LIST.DELETED', hasActionsSelected(), {
										actionsCount: hasActionsSelected(),
									})
								}}
							</q-button>
						</el-tooltip> -->
						<div
							v-if="isUpdatingStatuses"
							id="action-header-menu-cta-button"
						>
							<a
								class="q-button"
								:class="{ ghost: !!actionToEdit.ID }"
							>
								<i class="fa fa-circle-o-notch fa-spin" />
							</a>
						</div>
						<a
							v-else-if="hasOnlyClosedActionsSelected()"
							class="q-button inline-block"
							:class="{ ghost: !!actionToEdit.ID }"
							@click="reopenActions()"
						>
							{{ t.reopen(hasActionsSelected()) }}
							<span class="q-b-icon-left qi-arrow-down" />
						</a>
						<a
							v-else
							class="q-button inline-block flex items-center gap-1"
							:class="{ ghost: !!actionToEdit.ID }"
							@click="closeActions()"
						>
							{{ t.finish(hasActionsSelected()) }}
						</a>
					</div>
				</div>
			</div>
		</transition>

		<!-- Always on display -->
		<transition-group
			name="slide-up"
			class="flex flex-col"
		>
			<div
				v-if="focused < 1 || focused >= 1 || isShowingActionDetails || vuexIsActionCurrentlyEdited"
				key="action-category-list"
				class="w-full q-m-lg-l"
			>
				<div
					v-if="isShowingActionDetails"
					class="flex items-center justify-between pr-5"
				>
					<div class="flex items-center gap-10">
						<span
							id="left-part"
							@click="moveToActionList"
							class="backwards-navigation-offset text-xl font-bold cursor-pointer hover:text-quorum-500 transition-colors duration-300 ease-in-out"
						>
							<span class="qi-back" />{{ $t('_COMMON.BACK') }}
						</span>

						<div class="flex items-center gap-6">
							<tab-with-number
								v-for="(tab, index) in computedGeActiontDetailsTabs"
								:key="index"
								:title="tab.title"
								:selected="tab.selected"
								:status-pack="tab.status"
								font-size="1.25rem"
								class="cursor-pointer"
								@tab-click="$router.push({name: tab.name})"
							/>
						</div>
					</div>
					<template v-if="vuexIsActionCurrentlyEdited">
						<div class="flex items-center gap-2">
							<q-button
								v-t="$t('_COMMON.CANCEL')"
								ghost
								class="mr-1 focus:outline-none"
								@click="methodEmitBusEventHandler('notif-from-action-header-menu', 'cancel-edit-action')"
							/>

							<q-button
								v-t="$t('_COMMON.SAVE')"
								:disabled="!canSaveCurrentAction || !isActionTitleValid"
								class="q-button w-max m-0-auto"
								@click="methodEmitBusEventHandler('notif-from-action-header-menu', 'save-action')"
							/>
						</div>
					</template>
					<template v-else>
						<div class="flex items-center gap-2">
							<q-button
								v-t="$t('_COMMON.EDIT')"
								class="basic-black mr-1 focus:outline-none"
								@click="methodEmitBusEventHandler('notif-from-action-header-menu', 'edit-action')"
							/>
							<q-button
								class="square basic-black"
								@click="methodEmitBusEventHandler('notif-from-action-header-menu', 'finish-action')"
							>
								{{ isActionTodo ? $tc('ACTION.LIST.FINISH') : $tc('ACTION.LIST.REOPEN') }}
							</q-button>

							<el-dropdown
								trigger="click"
								class="hidden md:block ml-2"
							>
								<span
									class="el-dropdown-link flex items-center justify-center transition-all px-1 rounded-full hover:bg-gray-100"
								>
									<i class="gui-dotted_more text-2xl" />
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<div>
											<el-dropdown-item
												class="group"
												@click="()=> openDeleteDialog()"
											>
												<span class="group-hover:text-pink-main">

													{{ this.$t('ACTION.LIST.DELETE_DIALOG.DELETE_ACTION') }}

												</span>
											</el-dropdown-item>
										</div>
									</el-dropdown-menu>
								</template>
							</el-dropdown>
						</div>
					</template>
				</div>
				<div v-else>
					<div class="flex items-center justify-between pr-6 flex-wrap justify-end">
						<div class="flex items-center">
							<tab-with-number
								v-for="(tab, index) in computedGeActiontListTabs"
								:key="index"
								:title="tab.title"
								:count="tab.count"
								:selected="tab.selected"
								:status-pack="tab.status"
								class="mr-10 cursor-pointer"
								@tab-click="methodNavigateToActionType(tab.actionType)"
							/>
						</div>
						<!-- ADD CTA -->
						<q-button
							:ghost="!!actionToEdit.ID"
							icon="plus"
							@click="goToCallToAction"
						>
							<span v-if="!polygon.length">{{ t.add }}</span>
							<span v-else>{{ t.addWithPolygon }}</span>
						</q-button>
					</div>
				</div>
			</div>
			<div
				v-if="!isShowingActionDetails"
				id="action-filters"
				key="search-filter"
				class="w-full border-t pt-5 mt-5"
			>
				<!-- //? search form part -->
				<div class="w-full flex items-center flex-wrap justify-end gap-3">
					<div class="flex flex-grow flex-wrap items-center gap-2.5">
						<AnimatedSearchBar
							v-model="searchInput"
							:placeholder="t.search"
							container-id="action-filters"
						/>
						<div class="selectors">
							<el-select
								v-model="localFilterTypes.values"
								:placeholder="t.selectors.placeholderActionsType"
								multiple
								collapse-tags
								popper-class="rounded-xl"
								:teleported="false"
								clearable
								:disabled="isActionLoading ||!filteredActions.length"
							>
								<el-option
									class="rounded-lg m-1"
									v-for="action in actionTypes"
									:key="action"
									:label="t.type(action)"
									:value="action"
								/>
							</el-select>
							<el-select
								v-model="localFilterTeams.values"
								:placeholder="t.selectors.placeholderTeams"
								multiple
								collapse-tags
								popper-class="rounded-xl"
								:teleported="false"
								clearable
								:disabled="isActionLoading ||!filteredActions.length"
							>
								<el-option
									class="rounded-lg m-1"
									v-for="team in getTeams"
									:key="team.id"
									:label="team.name"
									:value="team.name"
								/>
							</el-select>
							<!-- <el-select
								v-model="localFilterTerritories.values"
								:placeholder="mixinMethodGetLabelForm('pollingstation')"
								multiple
								collapse-tags
								popper-class="rounded-xl"
								:teleported="false"
								clearable
								:disabled="isActionLoading ||!filteredActions.length"
							>
								<el-option
									class="rounded-lg m-1"
									v-for="pollingStation in getAllPollingStationsWithMissingTransformed"
									:key="pollingStation.id"
									:label="pollingStation.label"
									:value="pollingStation.value"
								/>
							</el-select> -->

							<el-date-picker
								v-model="localFilterDate.values"
								type="daterange"
								range-separator="-"
								popper-class="rounded-xl"
								:start-placeholder="t.selectors.placeholderDatetimeStart"
								:end-placeholder="t.selectors.placeholderDatetimeEnd"
								:teleported="false"
								:format="methodFormatDatePicker()"
								size="large"
								clearable
								:disabled="isActionLoading ||!filteredActions.length"
							/>
						</div>
					</div>
				</div>
			</div>
		</transition-group>
		<el-dialog
			v-model="isDialogDisplayed"
			:title="dialog.title"
		>
			<span>{{ dialog.content }}</span>
			<template #footer>
				<div class="flex justify-end items-center gap-1">
					<q-button
						class="ghost"
						@click="isDialogDisplayed = !isDialogDisplayed"
					>
						{{
							dialog.cancelButtonWording
						}}
					</q-button>
					<q-button @click="methodHandleDialogAction('confirm', dialog.functionTarget)">
						{{
							dialog.confirmButtonWording
						}}
					</q-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { ACTION } from '../../../constants'
import { isDatePast, getDisplayDateFromLocalStorage } from '../../../utils/dateFnsHelper'
import formsStaticMixin from '../../../mixins/formsStatic.ts'

import debounce from 'lodash/debounce'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
		AnimatedSearchBar: defineAsyncComponent(() => import('/src/components/general/AnimatedSearchBar.vue')),
	},

	mixins: [formsStaticMixin],

	props: {
		isShowingActionDetails: {
			type: Boolean,
			default: false,
		},
		openDeleteDialog: {
			type: Function,
			default: () => {},
		},

		resetSearchOnBeforeLeave: {
			type: Boolean,
			default: false,
		},
		displayDateTime: {
			type: Function,
			default: () => {},
		},
		displayDayInMonth: {
			type: Function,
			default: () => {},
		},
		displayMonth: {
			type: Function,
			default: () => {},
		},
	},
	emits: [
		'toggle:toggle-action-header',
		'toggle:show-hide-action-details',
		'update:action-sidebar',
		'action:deselect-all',
		'edit:action:end',
	],
	data() {
		return {
			focused: 0,
			actionTypeQuery: 'running',
			allowCallToAction: true,
			isUpdatingStatuses: false,
			isDialogDisplayed: false,
			dialog: {},
			actionsToDelete: [],
			dialogConfirmDeleteActionVisible: false,
			isConfirmBlocked: false,
			canSaveCurrentAction: false,
			isActionTitleValid: false,
			isActionTodo: false,

			/// filters part
			searchInput: '',
			searchBarIsOpen: false,
			actionTypes: ACTION.TYPES,
			localFilterTypes: {
				values: [],
			},
			localFilterTeams: {
				values: [],
			},
			localFilterTerritories: {
				values: [],
			},
			localFilterDate: {
				values: [],
			},
			closeDeleteDialog: {
				type: Function,
				default: () => {},
			},
		}
	},
	computed: {
		...mapGetters('@action', [
			'newAction',
			'actions',
			'filteredActions',
			'steps',
			'actionToEdit',
			'getIsActionCurrentlyEdited',
			'getActionsDetailFormsValidations',
			'isActionLoading',
		]),
		...mapGetters('@contact', [
			'getAllPollingStationsWithMissingTransformed',
		]),
		...mapGetters('@filter', ['polygon']),
		...mapGetters('@team', ['getTeams']),
		...mapGetters(['userConnected']),

		t() {
			let prefix = 'ACTION.LIST'

			return {
				add: this.$t('_COMMON.ADD_ACTION'),
				addWithPolygon: this.$t(`${prefix}.ADD_ACTION_POLY`),
				actionsSelected: (number) =>
					this.$tc(`${prefix}.ACTIONS_SELECTED`, number, { count: number }),
				search: this.$t(`${prefix}.SEARCH`),
				running: (number) => this.$tc(`${prefix}.RUNNING`, number),
				exceeded: (number) => this.$tc(`${prefix}.EXCEEDED`, number),
				finished: (number) => this.$tc(`${prefix}.FINISHED`, number),
				// cta
				reopen: (number) => this.$tc(`${prefix}.REOPEN`, number, { count: number }),
				finish: (number) => this.$tc(`${prefix}.FINISH`, number, { count: number }),
				update: (action) =>
					this.$t(`${prefix}.UPDATE_SUCCESS`, { name: action?.name ? action?.name : '' }),
				error: (action) =>
					this.$t(`${prefix}.UPDATE_ERROR`, { name: action?.name ? action?.name : '' }),
				deleteSuccess: (action) =>
					this.$t(`${prefix}.DELETE_SUCCESS`, { name: action?.name ? action?.name : '' }),

				// Take the message from edition.vue
				confirm: {
					title: this.$t('ACTION.EDIT.CONFIRM.TITLE'),
					cancel: this.$t('ACTION.EDIT.CONFIRM.CANCEL'),
					message: this.$t('ACTION.EDIT.CONFIRM.MESSAGE'),
					confirm: this.$t('ACTION.EDIT.CONFIRM.CONFIRM'),
				},
				deleteCanceled: this.$t(`${prefix}.DELETE_CANCELED`),
				selectors: {
					placeholderActionsType: this.$t(`${prefix}.SELECTORS.ACTIONS_TYPES`),
					placeholderTerritories: this.$t(`${prefix}.SELECTORS.TERRITORY`),
					placeholderTeams: this.$t(`${prefix}.SELECTORS.TEAMS`),
					placeholderDatetimeStart: this.$t(`${prefix}.SELECTORS.DATETIME_START`),
					placeholderDatetimeEnd: this.$t(`${prefix}.SELECTORS.DATETIME_END`),
				},
				type: (type) => {
					return type
						? this.$te(`${prefix}.TYPE.${type.toUpperCase()}`)
							? this.$t(`${prefix}.TYPE.${type.toUpperCase()}`)
							: type
						: type
				},
			}
		},

		computedGeActiontListTabs() {
			return [
				{
					title: this.t.running(this.numberInProgress),
					count: this.numberInProgress,
					selected: this.actionTypeQuery === 'running',
					status: 'enabled',
					actionType: 'running',
				},
				{
					title: this.t.exceeded(this.computedNumberExceeded),
					count: this.computedNumberExceeded,
					selected: this.actionTypeQuery === 'exceeded',
					status: 'enabled',
					actionType: 'exceeded',
				},
				{
					title: this.t.finished(this.numberFinished),
					count: this.numberFinished,
					selected: this.actionTypeQuery === 'finished',
					status: 'enabled',
					actionType: 'finished',
				},
			]
		},

		computedGeActiontDetailsTabs() {
			return [
				{
					title: 'Informations',
					selected: this.$route.name === 'action-details-informations',
					status: 'enabled',
					name: 'action-details-informations',
				},
				// {
				// 	title: 'Statistiques',
				// 	selected: this.$route.name === 'action-details-stats',
				// 	status: 'enabled',
				// 	name: 'action-details-stats',
				// },
			]
		},

		query() {
			return {
				text: this.searchInput,
				actionTypeQuery: this.actionTypeQuery || 'running',
			}
		},

		numberFinished() {
			return this.actions?.reduce(
				(count, action) => (count += action.status === ACTION.STATUS.FINISHED ? 1 : 0),
				0,
			)
		},
		numberInProgress() {
			return (
				this.actions?.reduce(
					(count, action) =>
						(count
							+= action.status === ACTION.STATUS.TODO || action.status === ACTION.STATUS.INPROGRESS
								? 1
								: 0),
					0,
				) - this.computedNumberExceeded
			)
		},
		computedNumberExceeded() {
			return this.actions?.reduce(
				(count, action) =>
					(count
						+= action.status !== ACTION.STATUS.FINISHED && action.end && isDatePast(action.end)
							? 1
							: 0),
				0,
			)
		},
		vuexIsActionCurrentlyEdited: {
			get() {
				return this.getIsActionCurrentlyEdited
			},
			set(newValue) {
				this.actionUpdateActionEditionStatus(newValue)
			},
		},
	},
	watch: {
		resetSearchOnBeforeLeave: {
			// <-- Edited
			handler(newVal) {
				if (newVal === true) {
					this.searchInput = ''
				}
			},
		},
		searchInput: debounce(function () {
			// this.debounceSearchInput() --> will be usefull when calling backoffice !
			this.$router.replace({
				name: this.$route.name,
				query: {
					...this.$route.query,
					a: this.searchInput,
				},
			})
			this.actionSetActionStack(30)
			this.updateQuery()
		}, 300),

		localFilterTypes: {
			handler(newValue) {
				this.methodEmitBusEventHandler('update-filter-from-action-header-menu', 'types', newValue.values)
			},
			deep: true,
		},
		localFilterTerritories: {
			handler(newValue) {
				this.methodEmitBusEventHandler('update-filter-from-action-header-menu', 'territories', newValue.values)
			},
			deep: true,
		},
		localFilterTeams: {
			handler(newValue) {
				this.methodEmitBusEventHandler('update-filter-from-action-header-menu', 'teams', newValue.values)
			},
			deep: true,
		},
		localFilterDate: {
			handler(newValue) {
				this.methodEmitBusEventHandler('update-filter-from-action-header-menu', 'date', newValue.values)
			},
			deep: true,
		},
	},
	mounted() {
		this.methodSetupEventListenerFromActionDetailHeader()
		// When landing on page and after loading, filter out finished questions
		this.$emitter.on('update:filters', () => {
			this.actionTypeQuery = 'running'
		})
		this.$emitter.on('update:action-sidebar:has-focus', () => {
			this.focused = this.filteredActions.filter((act) => act.focus).length
		})

		this.$emitter.on('action:edit:has-unsaved', (value) => {
			this.allowCallToAction = !value
		})
		this.actionTypeQuery = 'running'

		if (this.$route.query) {
			if (this.$route.query.a) {
				this.$emit('toggle:toggle-action-header')
				this.$emit('toggle:show-hide-action-details', this.$route.query.a)
			}

			if (Object.prototype.hasOwnProperty.call(this.$route.query, 'actionTypeQuery')) {
				if (this.$route.query.actionTypeQuery) {
					this.actionTypeQuery = 'running'
				}
				else {
					this.actionTypeQuery = 'running'
				}
			}
		}
	},

	beforeUnmount() {
		const fakeQuery = {
			text: '',
			actionTypeQuery: 'running',
		}

		this.filterActions(fakeQuery)
		this.$emitter.off('notif-from-action-detail-header')
	},
	methods: {
		...mapActions('@action', [
			'filterActions',
			'closeOneAction',
			'reopenOneAction',
			'actionUpdateActionEditionStatus',
			'actionSetActionStack',
			'actionArchiveAction',
		]),
		updateQuery() {
			this.filterActions(this.query)
			this.$root.$emit('update:action-sidebar')
		},

		deselectAll() {
			this.$store.commit('@action/HIDE_DETAIL')
			this.$store.commit('@action/EMPTY_ACTIVE_ACTION')
			this.$emitter.emit('action:deselect-all')
		},

		hasActionsSelected() {
			return this.filteredActions.filter((action) => action.focus).length
		},

		hasOnlyClosedActionsSelected() {
			return this.filteredActions
				.filter((action) => action.focus)
				.every((action) => action.status === ACTION.STATUS.FINISHED)
		},

		closeActions() {
			this.updateStatus('closeOneAction')
		},

		reopenActions() {
			this.updateStatus('reopenOneAction')
		},

		updateStatus(method) {
			this.isUpdatingStatuses = true
			let promisesQueue = []

			let workingActions = this.filteredActions.filter((action) => {
				if (method === 'closeOneAction') {
					return action.focus && action.status !== ACTION.STATUS.FINISHED
				}

				if (method === 'reopenOneAction') {
					return action.focus && action.status === ACTION.STATUS.FINISHED
				}
			})

			workingActions.map((action) => {
				promisesQueue.push(
					this[method](action.ID)
						.then((res) => {
							this.$message({
								type: 'success',
								message: this.t.update(action),
								showClose: true,
							})
							action = res
							this.focused--
						})
						.catch(() => {
							this.$message({
								type: 'error',
								message: this.t.error(action),
								showClose: true,
							})
						}),
				)
			})
			// Once every promise has resolved (or reject ?), you can stop the loader
			Promise.all(promisesQueue).then(() => {
				this.isUpdatingStatuses = false
				this.focused = 0
				this.deselectAll()
			})
		},

		/**
		 * @method goToCallToAction
		 * @description boot the call to action to tunnel from this view
		 */
		goToCallToAction() {
			if (this.allowCallToAction) {
				this.updateDataForCreation()
			}
			else {
				this.dialog = {
					title: this.t.confirm.title,
					content: this.t.confirm.message,
					cancelButtonWording: this.t.confirm.cancel,
					confirmButtonWording: this.t.confirm.confirm,
					functionTarget: 'goToCallToAction',
				}
				this.isDialogDisplayed = true
			}
		},
		moveToActionList() {
			if (this.vuexIsActionCurrentlyEdited) {
				this.dialog = {
					title: this.$t('ACTION.EDIT.CONFIRM.TITLE'),
					content: this.$t('ACTION.EDIT.CONFIRM.MESSAGE'),
					cancelButtonWording: this.$t('ACTION.EDIT.CONFIRM.CANCEL'),
					confirmButtonWording: this.$t('ACTION.EDIT.CONFIRM.CONFIRM'),
					functionTarget: 'moveToActionList',
				}
				this.isDialogDisplayed = true
			}
			else {
				this.$emit('toggle:toggle-action-header')
				this.$emit('toggle:show-hide-action-details')
				this.vuexIsActionCurrentlyEdited = false
			}
		},

		updateDataForCreation() {
			if (this.polygon.length) {
				this.newAction.search.polygon = this.polygon
			}
			this.$store.commit('@action/EMPTY_ACTION_TO_EDIT')
			this.$store.commit('@action/SET_ENTRY_POINT', 'actions')
			this.$router.push({ name: `actions${this.steps[0].route}` })
		},

		methodNavigateToActionType(actionType) {
			this.actionTypeQuery = actionType

			this.actionSetActionStack(30)
			this.$router.replace({
				name: this.$route.name,
				query: {
					...this.$route.query,
					actionTypeQuery: actionType,
				},
			})
			this.updateQuery()
		},

		methodFormatDatePicker() {
			return getDisplayDateFromLocalStorage().toUpperCase()
		},

		methodHandleDialogAction(mission, target) {
			switch (target) {
				case 'goToCallToAction':
					if (mission === 'confirm') {
						this.updateDataForCreation()
						this.isDialogDisplayed = false
						this.dialog = {}
					}
					else {
						// do cancel actions
					}

					break
				case 'moveToActionList':
					if (mission === 'confirm') {
						this.$emit('toggle:toggle-action-header')
						this.$emit('toggle:show-hide-action-details')
						this.vuexIsActionCurrentlyEdited = false
						this.isDialogDisplayed = false
						this.dialog = {}
					}
					else {
						// do cancel actions
					}

					break
			}
		},

		/**
		 * @method confirmDeleteAction
		 * @description confirmDeleteAction an action. Currently, it deletes it for the user, but
		 * we are only doing a soft delete
		 *
		 * @param  {Object} action
		 * @fires update:action-sidebar
		 * @return {Void}
		 */
		async confirmDeleteAction() {
			if (this.isConfirmBlocked) return

			if (!this.isConfirmBlocked) this.isConfirmBlocked = true

			this.dialogConfirmDeleteActionVisible = false
			this.deselectAll()

			for (const aAction of this.actionsToDelete) {
				try {
					await this.actionArchiveAction(aAction)
					this.$message({
						type: 'success',
						message: this.t.deleteSuccess(aAction),
						showClose: true,
					})
				}
				catch (e) {
					console.warn(e)
					this.$message({
						showClose: true,
						type: 'error',
						message: this.t.error(aAction),
					})
				}
			}

			this.actionsToDelete = []
			this.isConfirmBlocked = false
		},

		// EVENT BUS PART START

		// This method gonna be the event listner
		// instanciator between here and the
		// actionDetailHeader component
		methodSetupEventListenerFromActionDetailHeader() {
			this.$emitter.on('notif-from-action-detail-header', (eventObj) => {
				const { eventName, value } = eventObj
				switch (eventName) {
					case 'update-action-title': {
						let isValid = value?.length && value?.length <= 70 ? true : false
						this.isActionTitleValid = isValid
						break
					}
					case 'update-can-save-action':

						this.canSaveCurrentAction = value
						break
					case 'update-is-action-todo':

						this.isActionTodo = value === 'todo' ? true : false
						break
				}
			})
		},

		methodEmitBusEventHandler(emitterName, eventName, value) {
			const eventObj = {
				eventName: eventName,
				value,
			}
			this.$emitter.emit(emitterName, eventObj)
		},

		// EVENT BUS PART END
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/animations.scss';
@import '../../../assets/dequorum/style/variables.sass';

.actions-selected-cta {
	position: absolute;
	background: $white;
	width: 100%;
	height: 50%;
	z-index: 1;
	padding-right: 1em;
}

.action-header-CTA {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;

	& .q-control {
		flex: 1;
		min-width: 50vw;
	}
}

.backwards-navigation-offset {
	bottom: 1.5em;
	left: -1em;
}

.search-input-container {
	border-width: 1.5px;

	&:hover {
		i {
			color: #ff387f;
		}
	}
}

.selectors {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	padding: 0px 5px;
	margin: 5px 0%;
	gap: 10px;
	.el-select {
		flex: 1 1 90px;
		max-width: 200px;
		padding: 2.5px 5px;
	}
	.el-date-editor.el-range-editor {
		flex: 1 1 150px;
		max-width: 230px;
		flex-grow: 1;
		margin: 2.5px 5px;

		.el-range-input {
			padding-left: 5px !important;
		}
	}
}
</style>
