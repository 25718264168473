import cloneDeep from 'lodash/cloneDeep'

/**
 * This function will merge local and backend static forms into one Array
 * @param localForms local forms coming from template_contact_form.js
 * @param backendForms backend forms
 * @param isByDefault Flag to reset forms to basic values
 * @param additionnalFunctions Object containing additionnal
 * @returns Array of object containing the merged forms
 */
export const mergeLocalAndBackendStaticForms = (
	localForms: Array<Object> = [],
	backendForms: Array<Object> = [],
	isByDefault: Boolean,
	additionnalFunctions: Object = {},
): Array<Object> => {
	const localFormsToFormat: Array<Object> = cloneDeep(localForms)
	const clonedBackendForms: Array<Object> = cloneDeep(backendForms)

	if (backendForms && !isByDefault) {
		localFormsToFormat.forEach((aFormStaticLocally) => {
			const findFormStaticBackend = clonedBackendForms.find(
				(aFormFromBackend) => aFormFromBackend.type === aFormStaticLocally.type,
			)

			if (findFormStaticBackend) {
				aFormStaticLocally.id = findFormStaticBackend.id
				aFormStaticLocally.enabled = true
				aFormStaticLocally.hidden = findFormStaticBackend.hidden
				if (findFormStaticBackend.mandatory)
					aFormStaticLocally.mandatory = findFormStaticBackend.mandatory
				aFormStaticLocally.label = findFormStaticBackend.label
				aFormStaticLocally.group_id = findFormStaticBackend.group_id
				aFormStaticLocally.order = findFormStaticBackend.order
				aFormStaticLocally.read_only = findFormStaticBackend.read_only
			}
			else {
				if (additionnalFunctions?.methodSetLabel)
					aFormStaticLocally.label = additionnalFunctions.methodSetLabel(aFormStaticLocally.key)
			}
		})
	}

	localFormsToFormat.forEach((aForm) => {
		if (!aForm.enabled) {
			if (additionnalFunctions?.methodSetLabel)
				aForm.label = additionnalFunctions.methodSetLabel(aForm.type)
			aForm.hidden = true
		}
	})
	const clonedLocalFormsToFormat = cloneDeep(localFormsToFormat)
	return clonedLocalFormsToFormat
}
