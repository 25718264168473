import { MAP } from '../../constants'
import { Map } from 'maplibre-gl'

type InitOptions = {
	lightTilesUrl: string
	satelliteTilesUrl?: string
	attribution?: string
}

export function initMapBackgroundTiles(map: Map, options: InitOptions) {
	map.addSource('background-tiles-light', {
		type: 'raster',
		tiles: [options.lightTilesUrl],
		tileSize: 256,
		attribution: typeof options.attribution === 'undefined' ? MAP.URL_ATTRIBUTION : options.attribution,
	})

	map.addLayer({
		id: 'background-tiles-light',
		type: 'raster',
		source: 'background-tiles-light',
		minzoom: 0,
		maxzoom: 22,
		layout: {
			visibility: 'visible',
		},
	})

	if (options.satelliteTilesUrl && options.satelliteTilesUrl !== options.lightTilesUrl) {
		map.addSource('background-tiles-satellite', {
			type: 'raster',
			tiles: [options.satelliteTilesUrl],
			tileSize: 512,
			attribution: MAP.URL_SATELLITE_ATTRIBUTION,
		})

		map.addLayer({
			id: 'background-tiles-satellite',
			type: 'raster',
			source: 'background-tiles-satellite',
			minzoom: 0,
			maxzoom: 22,
			layout: {
				visibility: 'none',
			},
		})
	}
}
