import { i18n } from '@/middlewares/i18nizer'
import store from '@/store/stateStore'

export default {
	methods: {
		actionGetAgeCategories(intWanted = false, langage) {
			let getAgeCategories = store.getters['@contact/getAgeCategories']
			/**
			 * From @contactstore
			 * This function overwrite the age_categories by default
			 * on the webapp
			 */
			if (getAgeCategories?.length) {
				return getAgeCategories.map((aCategory, index) => {
					let keyWording = !langage
						? 'CONTACT.HEADER.FILTERS.AGE_CUSTOM_SPAN.XX_TO_XX'
						: 'AGE_CATEGORY.AGE_CUSTOM_SPAN.XX_TO_XX'
					if (index === 0)
						keyWording = !langage
							? 'CONTACT.HEADER.FILTERS.AGE_CUSTOM_SPAN.LT_TO_XX'
							: 'AGE_CATEGORY.AGE_CUSTOM_SPAN.LT_TO_XX'
					if (index === getAgeCategories.length - 1)
						keyWording = !langage
							? 'CONTACT.HEADER.FILTERS.AGE_CUSTOM_SPAN.GT_TO_XX'
							: 'AGE_CATEGORY.AGE_CUSTOM_SPAN.GT_TO_XX'

					return {
						id: aCategory.id_category,
						label: !langage
							? i18n.global.t(keyWording, {
								minimum: aCategory.lower_year,
								maximum: aCategory.upper_year,
							})
							: i18n.global.t(keyWording, langage, {
								minimum: aCategory.lower_year,
								maximum: aCategory.upper_year,
							}),
						value: intWanted ? aCategory.id_category : String(aCategory.id_category),
					}
				})
			}

			return [
				{
					id: 0,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.NO_AGE')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.NO_AGE', langage),
					value: intWanted ? 0 : '0',
				},
				{
					id: 1,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.LT_18')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.LT_18', langage),
					value: intWanted ? 1 : '1',
				},
				{
					id: 2,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.1824')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.1824', langage),
					value: intWanted ? 2 : '2',
				},
				{
					id: 3,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.2534')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.2534', langage),
					value: intWanted ? 3 : '3',
				},
				{
					id: 4,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.3549')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.3549', langage),
					value: intWanted ? 4 : '4',
				},
				{
					id: 5,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.5064')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.5064', langage),
					value: intWanted ? 5 : '5',
				},
				{
					id: 6,
					label: !langage
						? i18n.global.t('CONTACT.HEADER.FILTERS.AGE_SPAN.GT_65')
						: i18n.global.t('AGE_CATEGORY.AGE_SPAN.GT_65', langage),
					value: intWanted ? 6 : '6',
				},
			]
		},

		actionFindAgeCategory(idCategory) {
			let findCategory = null
			if (idCategory) {
				const ageCategories = this.actionGetAgeCategories(typeof idCategory === 'number')
				findCategory = ageCategories.find((aCategory) => aCategory.id === idCategory)
			}
			if (findCategory) return findCategory
			return {
				label: '',
			}
		},
	},
}
