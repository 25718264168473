<template>
	<div class="w-full flex justify-around relative">
		<!-- Left block with access label -->
		<div class="mt-16 mr-6 overflow-auto">
			<div
				v-for="(block, blockIndex) in accessList"
				:key="blockIndex"
			>
				<el-divider
					v-if="blockIndex != 0"
					class="absolute roleBlocDivider"
				/>
				<div
					class="font-title text-2xl py-2 flex flex-col"
					:class="blockIndex != 0 ? 'mt-12' : ''"
				>
					<span class="-mb-1">
						{{ block.blockLabel }}
					</span>
					<span class="text-gray-strong text-sm">
						{{ block.blockSublabel }}
					</span>
				</div>
				<div
					v-for="(section, sectionIndex) in block.blockAccess"
					:key="sectionIndex"
				>
					<!-- Label section -->
					<div
						v-if="methodCheckSection(section.access)"
						class="flex items-end mt-8 pb-4 uppercase text-gray-dark text-sm font-thin"
					>
						{{ section.sectionLabel }}
					</div>
					<div
						v-for="(access, accessIndex) in section.access"
						:key="accessIndex"
					>
						<!-- Label access -->
						<div class="flex flex-col justify-center whitespace-nowrap roleCellHeight">
							<span class="bold -mb-1">
								{{ access.label }}
							</span>
							<span class="text-gray-strong text-sm">
								{{ access.sublabel }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Right block with roles and values -->
		<div class="w-2/3 xl:w-3/5">
			<!-- Label role -->
			<div
				ref="roleTableHeaderScrollContainer"
				class="flex mb-2 sticky top-0 overflow-x-hidden bg-white z-10"
			>
				<div
					v-for="role in computedRolesList"
					:key="role"
					class="flex roleTableCell roleCellHeight flex-shrink-0 justify-center items-center gap-2"
				>
					<span
						class="flex items-center text-center leading-5 py-0.5 px-2 rounded-md"
						:style="[{ color: role.color }, { backgroundColor: role.color + '15' }]"
					>
						{{ methodRetrieveNameAccordingType(role) }}
						<!-- Add tooltip for default role -->
						<el-tooltip
							:content="
								role.type == 'user'
									? $t('SETTINGS.ROLE_MANAGEMENT.DEFAULT_ROLE')
									: $t('SETTINGS.ROLE_MANAGEMENT.INFO_ADMIN')
							"
							placement="top"
						>
							<div>
								<i
									v-if="role.type == 'user' || role.type == 'superadmin'"
									class="gui-warning text-lg ml-1"
								/>
							</div>
						</el-tooltip>
					</span>
					<el-tooltip
						v-if="isEditing"
						:content="
							role.type == 'superadmin'
								? $t('SETTINGS.ROLE_MANAGEMENT.RENAME_ROLE')
								: $t('SETTINGS.ROLE_MANAGEMENT.EDIT_ROLE')
						"
						placement="top"
					>
						<i
							class="text-xl text-gray-strong gui-edit cursor-pointer hover:opacity-80"
							@click="
								() => {
									displayPopupEditRole = true
									selectedRole = cloneDeep(role)
									// Put default name when there is no name
									if (!selectedRole.name)
										selectedRole.name = methodRetrieveNameAccordingType(selectedRole)
								}
							"
						/>
					</el-tooltip>
				</div>
			</div>
			<div
				ref="roleTableScrollContainer"
				class="overflow-x-auto"
				@scroll="methodHandleRoleHeaderScroll"
			>
				<div
					v-for="(block, blockIndex) in accessList"
					:key="blockIndex"
				>
					<!-- Toggle for mobile or web-->
					<div
						class="flex"
						:style="`margin-top: ${blockIndex != 0 ? '48px' : ''}`"
					>
						<div
							v-for="(role, roleIndex) in computedRolesList"
							:key="roleIndex"
							class="flex justify-center items-center py-2 bg-gray-lighted roleTableCell roleCellHeight flex-shrink-0 rounded-md"
						>
							<!-- Toggle value displayed -->
							<div v-if="!isEditing">
								<div class="flex items-center gap-1 w-fit">
									<i
										class="text-2xl"
										:class="role[block.key] ? 'gui-yes' : 'gui-no text-gray-medium text-opacity-50'"
									/>
								</div>
							</div>
							<!-- Toggle value edit -->
							<!-- If the role is superadmin, member or the block is mobile prevent disable access-->
							<div v-else>
								<el-tooltip
									:disabled="block.key != 'mobile' && methodIsTypeEditable(role.type)"
									:content="
										!methodIsTypeEditable(role.type)
											? $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_EDIT')
											: $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_MOBILE')
									"
									placement="top"
								>
									<el-switch
										v-model="role[block.key]"
										:disabled="block.key == 'mobile' || !methodIsTypeEditable(role.type)"
										style="--el-switch-on-color: #05b66f"
										@change="methodDeselectBlockAccess(block.key, role, role[block.key])"
									/>
								</el-tooltip>
							</div>
						</div>
					</div>
					<div
						v-for="(section, sectionIndex) in block.blockAccess"
						:key="sectionIndex"
						class="roleTableCellSpace"
					>
						<div
							v-for="(access, accessIndex) in section.access"
							:key="accessIndex"
							class="flex"
						>
							<!-- Cell -->
							<div
								v-for="(role, roleIndex) in computedRolesList"
								:key="roleIndex"
								class="flex justify-center items-center py-2 bg-gray-lighted roleTableCell roleCellHeight flex-shrink-0"
								:class="[
									accessIndex == 0 ? 'rounded-t-md' : '',
									accessIndex == section.access.length - 1 ? 'rounded-b-md' : '',
									access.key,
									'index' + roleIndex,
								]"
							>
								<!-- Role value displayed -->
								<div v-if="!isEditing">
									<div class="flex items-center gap-1 w-fit">
										<i
											class="text-2xl"
											:class="
												includes(role.access, access.key)
													? 'gui-yes'
													: 'gui-no text-gray-medium text-opacity-50'
											"
										/>
									</div>
								</div>

								<!-- Role value edit -->
								<!-- If the role is superadmin or member prevent disable access-->
								<div v-else>
									<el-tooltip
										:disabled="
											methodIsTypeEditable(role.type) &&
												methodCheckDependencies(access.key, role.access)
										"
										:content="
											!methodIsTypeEditable(role.type)
												? $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_EDIT')
												: accessDependencies[access.key]?.label
										"
										placement="top"
									>
										<i
											class="text-xl"
											:class="[
												includes(role.access, access.key) ? 'gui-check_on' : 'gui-check_off',
												role[block.key] &&
													methodCheckDependencies(access.key, role.access) &&
													methodIsTypeEditable(role.type)
													? 'cursor-pointer'
													: 'cursor-not-allowed',
											]"
											@click="
												methodIsTypeEditable(role.type) &&
													methodCheckDependencies(access.key, role.access)
													? methodSelectOrDeselectAccess(
														role,
														access.key,
														role[block.key] &&
															methodCheckDependencies(access.key, role.access) &&
															methodIsTypeEditable(role.type)
													)
													: methodHighlightDependencies(roleIndex, access.key)
											"
										/>
									</el-tooltip>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<popupEditRole
		v-model:display-dialog="displayPopupEditRole"
		v-model:role-infos="selectedRole"
		:default-roles="defaultRoles"
		:method-edit-role="methodEditRole"
		:method-delete-role="methodDeleteRole"
		:method-reset-role="methodPutDefaultAccess"
	/>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, watch, ref, nextTick } from 'vue'
import { includes, pull, remove, find, every, cloneDeep } from 'lodash'
import { methodRetrieveNameAccordingType } from './../../../utils/RoleUtils.ts'
import popupEditRole from './popupEditRole.vue'

// Data
const store = useStore()
const roleTableHeaderScrollContainer = ref(null)
const roleTableScrollContainer = ref(null)
let isHorizontalScrollNeeded = ref(false)
let displayPopupEditRole = ref(false)
let selectedRole = ref(null)

// Emits
const emit = defineEmits(['update:rolesList'])

// Props
const props = defineProps({
	accessList: { type: Array, default: () => [] },
	accessDependencies: { type: Object, default: () => {} },
	rolesList: { type: Array, default: () => [] },
	isEditing: { type: Boolean, default: false },
	defaultRoles: { type: Array, default: () => [] },
})

// Computed
const accessDataInsights = computed(() => store.getters['@packs/getAccessDataInsights'])
const accessDashboard = computed(() => store.getters['@packs/getAccessStatusAnalytics'])
const accessOnlineForm = computed(() => store.getters['@packs/getAccessOnlineForms'])
const accessActions = computed(() => store.getters['@packs/getAccessStatusActions'])
const accessPetitions = computed(() => store.getters['@packs/getAccessPetitions'])
const accessMandates = computed(() => store.getters['@packs/getAccessMandates'])
const accessDonationsMemberships = computed(
	() => store.getters['@packs/getAccessDonationsMemberships'],
)
const accessTransactionsSanctions = computed(
	() => store.getters['@packs/getAccessSanctions'],
)
const accessDonationBatch = computed(() => store.getters['@packs/getAccessBatch'])
const accessCustomBorderLayer = computed(() => store.getters['@packs/getAccessCustomBorderLayer'])
const accessEmailSenders = computed(() => store.getters['@packs/getAccessStatusSendersDistribution'])
const infoCampagne = computed(() => store.getters['info_campagne'])

const computedRolesList = computed({
	get() {
		return props.rolesList
	},
	set(value) {
		emit('update:rolesList', value)
	},
})

// Watch
watch(
	computedRolesList,
	async (roleList) => {
		// Check for each access if the dependencies are still respected. If not, remove the access from the list
		roleList?.forEach((role) =>
			role.access?.forEach((accessKey) => {
				if (!methodCheckDependencies(accessKey, role.access)) {
					pull(role.access, accessKey)
				}
			}),
		)
		// If a new role is added wait for the list to be updated to scroll to the last role
		if (isHorizontalScrollNeeded.value && roleTableScrollContainer) {
			await nextTick()
			roleTableScrollContainer.value.scrollLeft = roleTableScrollContainer.value.scrollWidth
			isHorizontalScrollNeeded.value = false
		}
	},
	{ deep: true },
)

// Method
const methodDeleteRole = (roleId) => {
	remove(computedRolesList.value, function (role) {
		return role.id == roleId
	})
}

const methodEditRole = () => {
	const oldRoleIndex = computedRolesList.value.findIndex(
		(role) => role.id === selectedRole.value.id,
	)
	if (oldRoleIndex !== -1) computedRolesList.value[oldRoleIndex] = selectedRole.value
}

const methodSelectOrDeselectAccess = (role, accessKey, isEnabled) => {
	// If the block is not disabled
	if (isEnabled) {
		// The back delete access if the array is empty, add the attribute if it's the case
		if (!role.access) {
			role.access = []
		}
		// If access key already present, remove it
		if (includes(role.access, accessKey)) {
			pull(role.access, accessKey)
		}
		else {
			role.access.push(accessKey)
		}
	}
}

const methodPutDefaultAccess = (role) => {
	let defaultType = find(props.defaultRoles, ['type', role.type])
	let roleToUpdate = find(computedRolesList.value, ['id', role.id])

	// Put default values
	roleToUpdate.access = cloneDeep(defaultType.access)
	roleToUpdate.web = defaultType.web
	roleToUpdate.mobile = defaultType.mobile
}

const methodDeselectBlockAccess = (blockKey, role, isSelected) => {
	// If the block is not selected, remove all access of the block
	if (!isSelected) {
		find(props.accessList, ['key', blockKey]).blockAccess.forEach((section) =>
			section.access.forEach((access) => pull(role.access, access.key)),
		)
	}
}

// Check if the dependencies of an access are present inside the role access list
const methodCheckDependencies = (accessKey, roleAccess) => {
	return every(props.accessDependencies[accessKey]?.access, (element) =>
		includes(roleAccess, element),
	)
}

// Check if a pack is linked to the access key and if the access is disabled
const methodCheckPackDisabled = (access) => {
	switch (access.key) {
		case 'data_insight:delete':
			return accessDataInsights.value == 'disabled' || infoCampagne.value?.archi_data_carto.toUpperCase() == 'NO_DATA' ? true : false
		case 'dashboard:delete':
			return accessDashboard.value == 'disabled' ? true : false
		case 'carto.turf:read':
		case 'carto.turf:write':
		case 'carto.turf:delete':
			return accessCustomBorderLayer.value == 'enabled' ? false : true
		case 'online_form:delete':
			return accessOnlineForm.value == 'disabled' ? true : false
		case 'action:write':
		case 'action:delete':
			return accessActions.value == 'disabled' ? true : false
		case 'petition:publish':
			return accessPetitions.value == 'disabled' ? true : false
		case 'transaction_dashboard:read':
		case 'transaction:write':
		case 'transaction:delete':
		case 'transaction_all:read':
		case 'transaction_all:write':
		case 'transaction_all:delete':
		case 'membership:min':
			return accessDonationsMemberships.value == 'enabled' ? false : true
		case 'sanction:write':
		case 'sanction:read':
			return accessTransactionsSanctions.value === 'enabled' ? false : true
			// Check enabled because the pack could be undefined
		case 'reopen_batch:self':
		case 'reopen_batch:all':
			// Check enabled because the pack could be undefined
			return accessDonationBatch.value == 'enabled' ? false : true
		case 'mandate:read':
		case 'mandate:write':
			return accessMandates.value == 'enabled' ? false : true
		case 'communication.sender:write':
		case 'communication.sender:delete':
			return accessEmailSenders.value == 'enabled' ? false : true
		default:
			return false
	}
}

// Check if the section is available and remove the access to not display
const methodCheckSection = (sectionAccess) => {
	remove(sectionAccess, methodCheckPackDisabled)
	return sectionAccess.length != 0
}

const methodScrollRight = () => {
	// The scroll will be done when the role list is updated
	isHorizontalScrollNeeded.value = true
}

// Scroll the header of the table with the role name when the content is scrolled to simulate the scroll of the all block
const methodHandleRoleHeaderScroll = () => {
	roleTableHeaderScrollContainer.value.scrollLeft = roleTableScrollContainer.value.scrollLeft
}

const methodIsTypeEditable = (type) => {
	return type != 'superadmin'
}

// Toggle green background of dependencies of the disabled access
const methodHighlightDependencies = (roleIndex, accessKey) => {
	let accessToHighlight = []
	props.accessDependencies[accessKey]?.access.forEach((access) =>
		// Add to the list the element with a dependencie in the right column
		accessToHighlight.push(
			document.querySelector(
				// Escape characters : and .
				`.${access.replace(/[.:]/g, function (match) {
					return '\\' + match
				})}.index${roleIndex}`,
			),
		),
	)
	accessToHighlight?.forEach((c) => c.classList.toggle('highlightRoleCell'))
	setTimeout(() => {
		accessToHighlight?.forEach((c) => c.classList.remove('highlightRoleCell'))
	}, 400)
}

// Define elements accessible with the ref of the component
defineExpose({ methodScrollRight })
</script>

<style lang="sass" scoped>
::-webkit-scrollbar
	height: 5px

.roleTableCell
	width: 150px
	@apply mx-1
.roleCellHeight
	height: 50px

.roleBlocDivider
	top: 95px
	@apply left-0

.roleTableCellSpace
	margin-top: 63.5px

.highlightRoleCell
	@apply bg-green-main bg-opacity-20 transition
</style>
