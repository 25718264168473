/**
 * @mixin ActionTypology
 * @desc groups methods that returns booleans to describe fields of each action.
 * Pay attention to the `Func` suffix: in views it is used by computed values but on different
 * arguments
 * @example
 * canHaveLink() { return this.canHaveLinkFunc(this.newAction.type) } // Information.vue
 * canHaveLink() { return this.canHaveLinkFunc(this.actionToEdit.type_data) } // Edition.vue
 */

import { ACTION } from 'src/constants'
const {
	CALL,
	MAIL,
	EVENT,
	OTHER,
	STATIC,
	LEAFLET,
	CANVASSING,
	SHARING,
	REPORTING,
	GOTVCANVASSING,
	GOTVCALLING,
} = ACTION.TYPES

export default {
	methods: {
		/**
		 * @method ActionTypology#canHaveSharingContent
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveSharingContentFunc(type) {
			return [SHARING].includes(type)
		},

		/**
		 * @method ActionTypology#canHaveBriefFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveBriefFunc(type) {
			return [CALL, OTHER, STATIC, CANVASSING, REPORTING, GOTVCANVASSING, GOTVCALLING, MAIL, LEAFLET].includes(
				type,
			)
		},

		/**
		 * @method ActionTypology#canHaveLinkFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveLinkFunc(type) {
			return [MAIL, EVENT, OTHER, LEAFLET, CANVASSING, REPORTING, GOTVCANVASSING].includes(type)
		},

		/**
		 * @method ActionTypology#canHaveRegistrationLinkFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveRegistrationLinkFunc(type) {
			return [EVENT].includes(type)
		},

		/**
		 * @method ActionTypology#canHaveGoalFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveGoalFunc(type) {
			return [STATIC, LEAFLET, OTHER].includes(type)
		},

		/**
		 * @method ActionTypology#canHaveGoalFormsFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveGoalFormsFunc(type) {
			return [STATIC].includes(type)
		},

		/**
		 * @method ActionTypology#canHaveDateFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		canHaveDateFunc(type) {
			return [
				CALL,
				MAIL,
				EVENT,
				OTHER,
				STATIC,
				LEAFLET,
				CANVASSING,
				GOTVCANVASSING,
				GOTVCALLING,
				SHARING,
			].includes(type)
		},

		/**
		 * @method ActionTypology#mandatoryDateFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean}
		 */
		mandatoryDateFunc(type) {
			return [STATIC, EVENT].includes(type)
		},

		/**
		 * @method ActionTypology#searchOnBaseFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean} should the step "TARGET" add filters on the base or only list of addresses
		 */
		searchOnBaseFunc(type) {
			return [CALL, CANVASSING, MAIL, REPORTING, GOTVCANVASSING, GOTVCALLING].includes(type)
		},

		useMeetingPoint(type) {
			return [CANVASSING, MAIL, GOTVCANVASSING, REPORTING].includes(type)
		},

		/**

		 * @method ActionTypology#canDisplayMap
		 * @param {string} type the type of the action
		 * @returns {Boolean} should the step "TARGET" add filters on the base or only list of addresses
		 */
		canDisplayMap(type) {
			return [
				CALL,
				MAIL,
				CANVASSING,
				REPORTING,
				GOTVCANVASSING,
				GOTVCALLING,
				EVENT,
			].includes(type)
		},

		/**
		 * @method ActionTypology#canDisplayPitch
		 * @param {string} type the type of the action
		 * @returns {Boolean} should the action edition display pitch edition
		 */
		canDisplayPitch(type) {
			return [
				CALL,
				MAIL,
				EVENT,
				OTHER,
				STATIC,
				LEAFLET,
				CANVASSING,
				SHARING,
				REPORTING,
				GOTVCANVASSING,
				GOTVCALLING,
			].includes(type)
		},

		/**
		 * @method ActionTypology#searchOnBaseFunc
		 * @param {string} type the type of the action
		 * @returns {Boolean} should the step "TARGET" add filters on the base or only list of addresses
		 */
		shouldCreateEmptyAction(type) {
			return [OTHER, EVENT, LEAFLET, SHARING].includes(type)
		},

		isAllowedForCreationWithSelectedContacts(type) {
			return [CANVASSING, LEAFLET, CALL, MAIL].includes(type)
		},

		isAllowedForCreationWithAdvancedSearch(type) {
			return [GOTVCALLING, GOTVCANVASSING].includes(type)
		},

		needToDisplayDisableFormsOptions(type) {
			return [CALL, CANVASSING, REPORTING, GOTVCANVASSING, GOTVCALLING].includes(type)
		},

		canHaveMapCaption(type) {
			return [
				CALL,
				MAIL,
				CANVASSING,
				REPORTING,
				GOTVCANVASSING,
				GOTVCALLING,
			].includes(type)
		},
	},
}
