import { $request } from './requester'

export default {
	fecthAllExport() {
		return $request('GET', '/xlsx-exports')
			.then((res) => {
				return res.body.data
			})
			.catch(() => {
				throw new Error('Impossible to get the list of exports')
			})
	},
	startNewExport(data) {
		return $request('POST', '/xlsx-exports', data)
			.then((res) => {
				return res
			})
			.catch((err) => {
				return err.status
			})
	},

	getExportTemplates() {
		return $request('GET', '/export-templates')
			.then((res) => {
				return res?.body?.data
			})
			.catch((err) => {
				return err.status
			})
	},

	saveExportTemplate(data) {
		return $request('POST', '/export-templates', data)
			.then((res) => {
				return res?.body?.data
			})
			.catch((err) => {
				return err.status
			})
	},

	deleteExportTemplate(id) {
		return $request('DELETE', `/export-templates/${id}`)
			.then((res) => {
				return res?.body?.data
			})
			.catch((err) => {
				return err.status
			})
	},
}
