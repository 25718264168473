import { $request } from './requester.ts'

export default {
	async getGiveDonations() {
		return $request('GET', `/donation_prices`).then((res: any) => {
			if (res.statusCode === 200) return res.body.data
		})
	},

	async getGiveMemberships(data = null) {
		return $request('GET', `/membership_price_groups`).then((res: any) => {
			if (res.statusCode === 200) return res.body.data
		})
	},
}
