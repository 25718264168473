import get from 'lodash/get'

import authModel from '../../models/session_store'
import analytics from '../../external/analytics'
import vitally from '../../external/vitally'

const SET_STATUS_AUTH = 'SET_STATUS_AUTH'
const SET_STATUS_CODE_VALIDATION = 'SET_STATUS_CODE_VALIDATION'
const SET_STATUS_RESEND_CODE = 'SET_STATUS_RESEND_CODE'
const SET_CREDENTIALS_ENTERED = 'SET_CREDENTIALS_ENTERED'
const SET_AUTH0_MAIN_EMAIL = 'SET_AUTH0_MAIN_EMAIL'
const SET_LOGIN_MODE = 'SET_LOGIN_MODE'

const getDefaultState = () => {
	return {
		statusAuth: 'init',
		statusCodeValidation: 'init',
		statusResendCode: 'init',
		credentialsEntered: {},
		// Use for primary email and to store the email
		auth0MainEmail: '',
		loginMode: 'oauth2',
	}
}
const state = getDefaultState

export const mutations = {
	[SET_STATUS_AUTH]: (state, payload) => {
		state.statusAuth = payload
	},
	[SET_STATUS_CODE_VALIDATION]: (state, payload) => {
		state.statusCodeValidation = payload
	},
	[SET_STATUS_RESEND_CODE]: (state, payload) => {
		state.statusResendCode = payload
	},
	[SET_CREDENTIALS_ENTERED]: (state, payload) => {
		state.credentialsEntered = payload
	},
	[SET_AUTH0_MAIN_EMAIL]: (state, payload) => {
		state.auth0MainEmail = payload
	},
	[SET_LOGIN_MODE]: (state, payload) => {
		state.loginMode = payload
	},
}

export const actions = {
	actionLoginApplication: ({ commit, dispatch }, payload) => {
		const credentials = payload
		commit(SET_STATUS_AUTH, 'loading')
		authModel
			.getSession(credentials)
			.then(async (res) => {
				// Return error on 201/202
				if (res.status === 201 || res.status === 202) {
					commit(SET_AUTH0_MAIN_EMAIL, res.body?.data?.mail)
					throw res
				}

				const currentUser = await authModel.getMe()
				commit('LOGIN', currentUser, { root: true })
				if (currentUser) {
					const currentGroup = await authModel.mygroup()
					if (
						Array.isArray(currentUser.selected_group_id)
						&& currentUser?.selected_group_id.length > 1
					) {
						{
							currentGroup.masterView = true
						}
					}
					commit('SET_CAMPAIGN_INFOS', currentGroup, { root: true })
					commit(SET_STATUS_AUTH, 'success')
					dispatch('@group/actionSetGroupCampaign', currentGroup, { root: true })
					dispatch('@packs/actionSetCustomerPackRightsInstance', currentGroup.features, {
						root: true,
					})
					analytics.session(currentUser, currentGroup)
					analytics.sessionGroup(currentGroup)
					vitally.user(currentUser, currentGroup)
					vitally.account(currentGroup)
				}
				else commit(SET_STATUS_AUTH, 'error')
			})
			.catch((data) => {
				switch (data.status) {
					case 201: {
						const getToken = get(data, 'body.data.token', null)
						if (!getToken) {
							commit(SET_STATUS_AUTH, 'error')
							return
						}

						localStorage.setItem('token', getToken)
						commit(SET_STATUS_AUTH, 'external_provider/emailvalid')
						break
					}

					case 202: {
						const getToken = get(data, 'body.data.token', null)
						if (!getToken) {
							commit(SET_STATUS_AUTH, 'error')
							return
						}

						localStorage.setItem('token', getToken)
						commit(SET_STATUS_AUTH, 'external_provider/emailnotvalid')
						break
					}

					case 400:
					case 404:
						commit(SET_STATUS_AUTH, 'failed')
						break

					case 403:
						commit(SET_STATUS_AUTH, 'nopermission')
						break

					case 412: {
						const getToken = get(data, 'response.body.data.token', null)
						if (getToken) {
							localStorage.setItem('token', getToken)
						}
						const getEmail = get(data, 'response.body.data.mail', '')
						if (getEmail) {
							commit(SET_AUTH0_MAIN_EMAIL, getEmail)
						}
						commit(SET_STATUS_AUTH, 'emailnotvalid')
						break
					}

					case 426: {
						const getEmail = get(data, 'response.body.data.mail', '')
						if (getEmail) {
							commit(SET_AUTH0_MAIN_EMAIL, getEmail)
						}
						localStorage.setItem('token', data.response.body.data.token)
						commit(SET_STATUS_AUTH, 'nospacejoined')
						break
					}

					default:
						commit(SET_STATUS_AUTH, 'error')
						break
				}
			})
	},

	actionResetAuthenticationModule: ({ commit }) => {
		commit(SET_STATUS_AUTH, 'init')
		commit(SET_CREDENTIALS_ENTERED, {})
	},

	actionPostValidationCode: ({ commit, getters }, payload) => {
		const { code, email } = payload
		const { getCredentialsEntered } = getters
		commit(SET_STATUS_CODE_VALIDATION, 'loading')
		authModel
			.validate({ code, mail: email, raw_id_token: getCredentialsEntered?.raw_id_token })
			.then(() => {
				commit(SET_STATUS_CODE_VALIDATION, 'success')
			})
			.catch(() => {
				commit(SET_STATUS_CODE_VALIDATION, 'error')
			})
	},

	actionPostResendCode: ({ commit }, payload) => {
		commit(SET_STATUS_RESEND_CODE, 'loading')
		authModel.getSession(payload).catch(({ status }) => {
			switch (status) {
				case 412:
					commit(SET_STATUS_RESEND_CODE, 'resent')
					break

				default:
					commit(SET_STATUS_RESEND_CODE, 'error')
					break
			}
		})
	},

	actionSetCredentialsEntered: ({ commit }, payload) => {
		commit(SET_CREDENTIALS_ENTERED, payload)
	},

	actionSetLoginMode: ({ commit }, payload) => {
		commit(SET_LOGIN_MODE, payload)
	},
}

export const getters = {
	getStatusAuth: (state) => state.statusAuth,
	getStatusValidationCode: (state) => state.statusCodeValidation,
	getStatusResendCode: (state) => state.statusResendCode,
	getCredentialsEntered: (state) => state.credentialsEntered,
	getAuth0MainEmail: (state) => state.auth0MainEmail,
	getLoginMode: (state) => state.loginMode,
}

export const authenticationModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
