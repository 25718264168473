<template>
	<div v-show="showModal" id="modal-contact" @click.self="$emit('close', true)">
		<div class="modal" :style="`width: ${width}`" @click.stop>
			<div>
				<div class="flex justify-between items-center">
					<div class="flex flex-row items-center">
						<i
							v-if="backBtn"
							class="gui-back text-2xl mr-2 cursor-pointer"
							@click="$emit('back')"
						/>
						<span class="h-fit font-bold text-lg">
							{{ title }}
						</span>
					</div>
					<div class="cursor-pointer close-btn" @click="$emit('close', true)">
						<i class="gui-close text-3xl" />
					</div>
				</div>
				<div class="w-full mt-4 mb-5" style="height: 1px; background-color: #ededed"></div>
			</div>
			<slot></slot>
			<div class="w-full my-5" style="height: 1px; background-color: #ededed"></div>
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalLists',
	props: {
		showModal: {
			type: Boolean,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			required: true,
		},
		backBtn: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['back', 'close'],
}
</script>

<style lang="sass" scoped>
#modal-contact
	@apply absolute grid items-center inset-0
	background: #00000055
	overflow-y: auto
	overflow-x: hidden
	z-index: 2000

	.modal
		@apply py-6 px-12 justify-self-center
		margin-top: 2%
		margin-bottom: 2%
		background-color: white
		border-radius: 20px
		box-shadow: 0px 0px 24px 0px #22223033
		transition: all 0.7s ease-in-out
		&.step-filter
			width: 90%
			margin-left: 5%
		&.step-title
			margin-left: 25%
			width: 50%


	.close-btn
		transform: rotate(0)
		transition: 250ms ease-out
		&:hover
			transform: rotate(90deg)

.q-button
	border: 0
	margin: 0
</style>
