import moment from 'moment'

const selected = true
const primary = true

let id = 0
const defaultFields = {
	primary,
	selected,
}
export const fieldFactory = (args = {}) => {
	return {
		...defaultFields,
		id: id++,
		order: id,
		...args,
		compileHtml: !!args.slotName,
	}
}

export const checkbox = fieldFactory({
	class: 'center',
	flex: 0.25,
	minWidth: '1.5em',
	slotName: 'checkbox-column',
})

export const name = fieldFactory({
	content: (item) =>
		`${item.firstname ? item.firstname + ' ' : ''}${item.surname ? item.surname : ''}`,
	minWidth: '100px',
	flex: 1,
	translationKey: '_COMMON.NAME',
})

export const lastchange = fieldFactory({
	content: (item) => (item.lastchange ? moment(item.lastchange).calendar() : null),
	flex: 0.5,
	order: 996,
	primary: false,
	minWidth: '100px',
	translationKey: 'CONTACT.LIST.TABLE.LASTSEEN',
})

export const user = fieldFactory({
	flex: 0.5,
	order: 997,
	primary: false,
	slotName: 'user',
	minWidth: '100px',
	translationKey: 'CONTACT.LIST.TABLE.CONTACT',
})
export const actions = fieldFactory({
	flex: 0.5,
	order: 998,
	class: 'center',
	minWidth: '185px',
	slotName: 'actions',
	translationKey: 'CONTACT.LIST.TABLE.ACTIONS',
})
export const status = fieldFactory({
	flex: 0.5,
	order: 999,
	class: 'center',
	minWidth: '20px',
	slotName: 'status',
	translationKey: 'CONTACT.LIST.TABLE.STATUS',
})
