export const contactNotesRoute = 'contact-details-forms-notes'
export const contactInfosRoute = 'contact-details-forms-infos'
export const contactOpportunitiesRoute = 'contact-details-forms-opportunity'
export const contactFormsRoute = 'contact-details-forms-classic'
export const contactHistoryRoute = 'contact-details-history'
export const contactStatusRoute = 'contact-details-forms-status'
export const contactInteractionsRoute = 'contact-details-interactions'
export const contactEmailsRoute = 'contact-details-emails'
export const contactReceivedEmailsRoute = 'contact-details-received-emails'
export const contactEmailViewRoute = 'contact-details-email-view'
export const contactEmailEditorRoute = 'contact-details-editor-view'
export const contactDonationMembershipRoute = 'contact-details-donation-membership'

export const routesContacts = [
	contactNotesRoute,
	contactInfosRoute,
	contactOpportunitiesRoute,
	contactFormsRoute,
	contactStatusRoute,
	contactHistoryRoute,
	contactInteractionsRoute,
	contactEmailsRoute,
	contactReceivedEmailsRoute,
	contactEmailViewRoute,
	contactEmailEditorRoute,
	contactDonationMembershipRoute,
]

import { controllerPackRedirection, getterControllerStorePackCPR } from './utils/routerController'
import store from '../store/stateStore'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

export default (rootPath, rootName) => {
	return {
		path: `${rootPath}/:id`,
		name: `${rootName}-contactbyid`,
		component: () => import('../components/contact-module/contactDetails/contactDetailsMain.vue'),
		redirect: { name: contactInfosRoute },
		children: [
			{
				name: contactInfosRoute,
				path: 'infos',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsInfos.vue'
					),
				props: true,
			},

			{
				name: contactNotesRoute,
				path: 'notes',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsNotes'
					),
				props: true,
			},

			{
				name: contactOpportunitiesRoute,
				path: 'opportunities',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms'
					),
				props: true,
				beforeEnter: (to, from, next) => {
					// Role access check
					if (store.getters['userConnected']?.role_data?.access?.includes('task:delete')) {
						// Pack check
						controllerPackRedirection(next, 'statusWebAccessTasks', contactInfosRoute)
					}
					else {
						next({ name: 'command-center', replace: true })
					}
				},
			},
			{
				name: contactFormsRoute,
				path: 'forms',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms'
					),
				props: true,
				beforeEnter: (to, from, next) => {
					// controllerPackRedirection(next, 'statusWebAccessSurvey', contactInfosRoute)
					const CPR = getterControllerStorePackCPR()
					if (
						CPR['statusWebAccessSurvey']() === 'enabled'
						|| CPR['statusWebAccessSurveyContactDetail']() === 'enabled'
					) {
						next(true)
					}
					else {
						next({ name: 'command-center', replace: true })
					}
					controllerPackRedirection(next, 'statusWebAccessSurvey', contactInfosRoute)
				},
			},
			// {
			// 	path: '/survey/online-forms',
			// 	name: 'online-forms',
			// 	component: SurveyOnlineForms,
			// },
			{
				name: contactStatusRoute,
				path: 'status',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms'
					),
				props: true,
			},
			{
				name: contactHistoryRoute,
				path: 'history',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsHistory.vue'
					),
				props: true,
			},
			{
				name: contactInteractionsRoute,
				path: 'interactions',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsInteractions.vue'
					),
				props: true,
			},
			{
				name: contactEmailsRoute,
				path: 'emails',
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsEmails.vue'
					),
				props: true,
				redirect: { name: contactReceivedEmailsRoute },
				children: [
					{
						name: contactReceivedEmailsRoute,
						path: 'received',
						component: () =>
							import(
								'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/receivedEmails.vue'
							),
						props: true,
					},
					{
						name: contactEmailViewRoute,
						path: 'view/:emailId',
						component: () =>
							import(
								'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/emailView.vue'
							),
						props: true,
					},
					{
						name: contactEmailEditorRoute,
						path: 'editor',
						component: () =>
							import(
								'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/emailEditorView.vue'
							),
						props: true,
					},
				],
			},
			{
				name: contactDonationMembershipRoute,
				path: 'donation-membership',
				beforeEnter: (to, from, next) => {
					// Role access check
					if (methodIsAccessGranted('transaction_all:read') || methodIsAccessGranted('membership:min')) {
						// Pack check
						controllerPackRedirection(next, 'statusWebAccessDonationsMemberships')
					}
					else {
						next({ name: 'command-center', replace: true })
					}
				},
				component: () =>
					import(
						'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/ContactDetailsMembershipDonation.vue'
					),
				props: true,
			},
		],
	}
}
