<template>
	<div
		ref="communicationContainer"
		class="px-2 pt-2 h-full"
	>
		<div
			ref="communicationHeader"
			class="w-full bg-white p-5 z-40 sticky top-0 menu-shadow"
		>
			<div class="flex">
				<div
					v-for="(tab, index) in computedGetTabs"
					:key="index"
					:class="{
						'cursor-not-allowed pointer-events-none': tab.isSoon,
					}"
					class="flex"
				>
					<tab-with-number
						v-if="tab.isAuthorized && tab.isDisplay"
						:title="tab.title"
						:selected="tab.selected"
						:status-pack="tab.status"
						:badge="tab.badge"
						:tag="tab.tag"
						:no-opacity="tab.noOpacity"
						:is-soon="tab.isSoon"
						font-size="1.1rem"
						class="p-3"
						@tab-click="goto(tab)"
					/>
				</div>
			</div>
			<div
				v-if="tabSelected === 'bulk-emails' && getEmailerAPIKey === 'QOMON'"
				class="flex justify-end items-end lg:items-start lg:justify-between flex-wrap mt-6 ml-2"
			>
				<div class="p-3 w-full bg-gray-lighted rounded-lg">
					<p>
						<span class="gui-warning mr-2" />
						{{ $t('SETTINGS.HEADER.BULK_EMAIL_PRESENTATION') }}
					</p>
				</div>
			</div>
			<div
				v-if="tabSelected === 'autosend-settings' && getAccessStatusSurveyAutoEmail !== 'promoted'"
				class="flex justify-end items-end lg:items-start lg:justify-between flex-wrap"
			>
				<div
					class="p-3 w-full lg:w-8/12"
					v-html="$t('SETTINGS.AUTOSEND_CONF.DESC')"
				/>
				<div class="flex items-center gap-3">
					<el-tooltip
						:content="$t('SETTINGS.AUTOSEND_CONF.MAIL_AUTO_HELP')"
						:show-after="300"
						placement="top"
						popper-class="rounded-xl"
					>
						<a
							:href="emailAutoHelpLink"
							target="_blank"
							class="transition-all w-fit px-2.5 py-1.5 rounded-full hover:bg-gray-100 mr-3 flex justify-center items-center"
						>
							<i class="gui-infos text-xl" />
						</a>
					</el-tooltip>
					<q-button @click="methodTriggerAutoSendSettingsSave">
						{{ $t('_COMMON.SAVE') }}
					</q-button>
				</div>
			</div>
		</div>
		<div>
			<router-view
				v-model:dialog-add-new-domain-visible="computedDialogAddNewDomainVisible"
				:save-change="saveChange"
				:reset-settings="resetSettings"
				:add-sender-trigger="addSenderTrigger"
				:save-autosend-settings-trigger="addAutoSendSettingsTrigger"
				:save-new-domain-name="methodSaveNewDomainName"
				@un-save-change="(hasChange) => (hasChangeToSave = hasChange)"
				:style="{ height: tableHeight || methodCalculateRouterViewHeight }"
				class="overflow-auto"
			/>
		</div>
		<popin-upsell
			v-model:is-pop-up-shown="computedIsUpsellPopinShown"
			source="Emails auto"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent, nextTick } from 'vue'
import { openChat } from '../../../extensions/support'
import debounce from 'lodash/debounce'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
		PopinUpsell: defineAsyncComponent(() => import('../../packs/upsellPopin.vue')),
	},

	data() {
		return {
			hasChangeToSave: false,
			saveChange: 0,
			resetSettings: 0,
			addSenderTrigger: 0,
			addAutoSendSettingsTrigger: 0,
			tabSelected: 'communications-sms',
			key: 0,
			isUpsellPopinShown: false,
			emailAutoHelpLink: `https://help.qomon.com/${this.$i18n.locale}/articles/3451811`,
			dialogAddNewDomainVisible: false,
			tableHeight: '',
		}
	},

	computed: {
		...mapGetters('@packs', ['getAccessStatusSurveyAutoEmail']),
		...mapGetters(['getIsMessageBirdEnabled', 'isBulkedEmailsActivated']),
		...mapGetters('@emailer', ['getEmailerSenders', 'getEmailerAccountStatus']),
		...mapGetters('@group', ['getEmailerAPIKey']),

		computedDialogAddNewDomainVisible: {
			get() {
				return this.dialogAddNewDomainVisible
			},
			set(val) {
				this.dialogAddNewDomainVisible = val
			},
		},

		computedGetTabs() {
			return [
				{
					title: this.computedGetBulkEmailsTabName,
					name: 'bulk-emails',
					status: 'enabled',
					tag: 'span',
					badge: 'premium',
					selected: this.tabSelected === 'bulk-emails',
					isDisplay: true,
					isAuthorized: true,
					noOpacity: !this.isBulkedEmailsActivated,
				},
				{
					title: this.$t('SETTINGS.HEADER.MAILAUTO'),
					name: 'autosend-settings',
					status: this.getAccessStatusSurveyAutoEmail,
					selected: this.tabSelected === 'autosend-settings',
					isDisplay: this.getAccessStatusSurveyAutoEmail === 'enabled',
					tag: 'span',
					badge: 'premium',
					isAuthorized: true,
				},
				{
					title: this.$t('COMMUNCATIONS.HEADER.SMS'),
					name: 'sms',
					selected: this.tabSelected === 'sms',
					status: 'enabled',
					isSoon: false,
					isDisplay: this.getIsMessageBirdEnabled,
					isAuthorized: true,
				},
			].sort((a, b) => {
				return ('' + a.status).localeCompare(b.status)
			})
		},
		computedIsUpsellPopinShown: {
			get() {
				return this.isUpsellPopinShown
			},
			set(val) {
				this.isUpsellPopinShown = val
			},
		},

		computedGetBulkEmailsTabName() {
			let name = this.$t('SETTINGS.HEADER.GROUPED_EMAILS')
			if (this.getEmailerAPIKey === 'QOMON') {
				name = this.$t('SETTINGS.HEADER.BULK_EMAILS_SENDERS')
			}
			return name
		},
	},

	watch: {
		$route(to, from) {
			if (to.name !== from.name) this.tabSelected = to.name
		},
	},

	updated() {
		this.methodCalculateRouterViewHeight()
	},

	mounted() {
		this.methodCalculateRouterViewHeight()
		this.tabSelected = this.$route.name
	},

	methods: {
		openChat,
		goto(tab) {
			if (tab.name === 'autosend-settings' && !tab.status) {
				this.methodDisplayUpsellPopin(true)
			}
			else {
				this.$router.push({ name: tab.name, query: tab.query }).then(() => {
					this.tabSelected = tab.name
				})
			}
		},

		methodCalculateRouterViewHeight() {
			nextTick(() => {
				if (this.$refs.communicationContainer && this.$refs.communicationHeader)
					this.tableHeight = `calc(${
						this.$refs?.communicationContainer?.offsetHeight
						- this.$refs?.communicationHeader?.offsetHeight
					}px - 1rem)`
			})
		},

		methodSaveNewDomainName() {},

		methodDisplayUpsellPopin(val) {
			this.computedIsUpsellPopinShown = val
		},

		methodTriggerAutoSendSettingsSave: debounce(function () {
			this.addAutoSendSettingsTrigger++
		}, 350),
	},
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';
.menu-shadow {
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px;
}
</style>
