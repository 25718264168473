<template>
	<action-creation-helper-container :header-height="headerHeight">
		<div class="flex flex-col justify-center items-center w-full">
			<div class="w-full md:w-2/3">
				<p class="font-title text-black text-4xl text-center mb-8">
					{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOTV_TAGS.ASSIGN_TAGS') }}
				</p>
				<div class="flex flex-col items-center justify-center gap-3">
					<div
						v-for="gotvTag in gotvTags"
						:key="gotvTag.id"
						class="border border-gray-medium rounded-lg flex flex-row justify-between items-center px-3 py-3 w-full md:w-4/5"
					>
						<div class="flex flex-row items-center justify-center w-1/3">
							<p class="text-black flex flex-1 justify-center">
								{{ gotvTag.label }}
							</p>
							<i class="gui-next text-2xl mx-3" />
						</div>

						<el-select
							v-model="gotvTag.data"
							:multiple="false"
							filterable
							:allow-create="true"
							default-first-option
							clearable
							class="select-tags-select flex flex-1"
							@change="methodSelectTag(gotvTag.id, gotvTag.data)"
						>
							<el-option
								v-for="(item, index) in tags"
								:key="index"
								:label="item.name"
								:value="item.name"
							>
								<span>{{ item.name }}</span>
							</el-option>
						</el-select>
					</div>
					<div class="flex flex-row w-full md:w-4/5 mt-8 gap-2">
						<i class="gui-infos text-lg" />
						<div class="flex flex-col">
							<span class="bold">{{ $t('_COMMON.HELP') }}</span>
							<span>{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOTV_TAGS.HELP') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</action-creation-helper-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { actionTypology } from 'src/mixins'
import _ from 'lodash'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ActionCreationHelperContainer: defineAsyncComponent(() =>
			import('../ActionCreationHelperContainer'),
		),
	},

	emits: ['update:nextValidatorBoolean'],

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
	},

	mixins: [actionTypology],

	data() {
		return {
			gotvTags: [
				{
					id: 'gotv_voted_tag',
					label: this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOTV_TAGS.VOTED'),
					data: '',
				},
				{
					id: 'gotv_not_voted_tag',
					label: this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOTV_TAGS.NOT_VOTED'),
					data: '',
				},
				{
					id: 'gotv_abstention_tag',
					label: this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOTV_TAGS.ABSTAIN'),
					data: '',
				},
			],
			tags: [],
		}
	},

	computed: {
		...mapGetters('@action', ['newAction']),
		...mapGetters('@tags', ['getTags']),
	},
	mounted() {
		this.actionSetStep('SELECT_TAGS')
		this.tags = _.cloneDeep(this.getTags)
		this.$emit('update:nextValidatorBoolean', true)
	},
	methods: {
		...mapActions('@tags', ['actionGetTags', 'actionAddTag']),
		...mapActions('@action', ['actionSetStep', 'actionEditNewActionObject']),

		methodSelectTag(gotvTagKey, gotvTagData) {
			this.actionEditNewActionObject({ key: gotvTagKey, value: gotvTagData })
			if (this.getTags) {
				const allTags = this.getTags.map((tag) => tag.name)
				const isTagCreatedYet = allTags.find((aTag) => aTag === gotvTagData)
				if (_.isEmpty(isTagCreatedYet)) {
					this.actionAddTag({ name: gotvTagData })
				}
			}
		},
	},
}
</script>

<style lang="scss">
.select-tags-select .select-trigger {
	width: 100% !important;
}
</style>
