import { controllerPackRedirection } from './utils/routerController'
import store from '../store/stateStore'

export default {
	meta: { title: 'MENU.DATAINSIGHTS' },
	path: 'profiles',
	name: 'profiles',
	redirect: { name: 'profiles-main' },
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('data_insight:delete') && store.getters['info_campagne']?.archi_data_carto.toUpperCase() !== 'NO_DATA') {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessDataInsights', 'command-center', ['enabled'])
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () => import('src/components/profiles-module/ProfilesContainer.vue'),
	children: [
		{
			path: '',
			name: 'profiles-main',
			component: () => import('src/components/profiles-module/ProfilesMain.vue'),
		},
		{
			path: ':id',
			name: 'profiles-details',
			component: () => import('@/components/profiles-module/profiles-details/ProfilesDetails.vue'),
			redirect: { name: 'profiles-overview' },
			children: [
				{
					path: 'overview',
					name: 'profiles-overview',
					component: () =>
						import('@/components/profiles-module/profiles-details/ProfilesDetailsOverview.vue'),
				},
				{
					path: 'compare',
					name: 'profiles-comparison',
					component: () =>
						import('@/components/profiles-module/profiles-details/ProfilesDetailsComparison.vue'),
				},
				{
					path: 'lookalikes-zones',
					name: 'profiles-lookalikes-zones',
					component: () =>
						import(
							'@/components/profiles-module/profiles-details/ProfilesDetailsLookalikesZones.vue'
						),
				},
				{
					path: 'lookalikes-contact',
					name: 'profiles-lookalikes-contact',
					component: () =>
						import(
							'@/components/profiles-module/profiles-details/ProfilesDetailsLookalikesContacts.vue'
						),
				},
			],
		},
	],
}
