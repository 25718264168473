<template>
	<div>
		<div
			v-if="computedAllExports.length"
			class="mt-1"
		>
			<el-table
				class="settings-table"
				:height="tableHeight || '100%'"
				:data="computedAllExports"
				header-cell-class-name="tableHeaderCellStyle"
				:row-class-name="(scope) => `tableRowStyle ${methodStripedRow(scope)}`"
				cell-class-name="tableRowCellStyle"
			>
				<el-table-column
					min-width="170"
					:prop="'user_name'"
				>
					<template #header>
						<span class="pl-5">
							{{ $t('SETTINGS.IMPORT.LIST_IMPORT.EXPORTED_BY') }}
						</span>
					</template>
					<template #default="{ row }">
						<div
							v-tooltip
							class="pl-5 text-black w-full align-middle"
						>
							{{ row.user.firstname + ' ' + row.user.lastname }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					min-width="120"
					:label="$t('SETTINGS.IMPORT.LIST_IMPORT.EXPORTED_HOUR')"
					:prop="'created_at_hour'"
				/>
				<el-table-column
					min-width="120"
					:label="$t('CONTACT.EXPORT.LIST_EXPORT.DATE_LABEL')"
					:prop="'created_at'"
				/>
				<el-table-column
					width="130"
					:label="$t('CONTACT.EXPORT.LIST_EXPORT.STATUS_EXPORT_LABEL')"
					:prop="'status'"
				>
					<template #default="{ row }">
						<div>
							<badge-status
								:status="row.status"
								:statuses="row.statuses"
							/>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					min-width="100"
					:label="$t('CONTACT.EXPORT.LIST_EXPORT.EXPORTED_CONTACTS_LABEL')"
					:prop="'total'"
				>
					<template #default="{ row }">
						{{ row.total > 0 ? row.total : '0' }}
					</template>
				</el-table-column>
				<el-table-column
					min-width="80"
					:label="$t('CONTACT.EXPORT.LIST_EXPORT.DOWNLOAD_LINK')"
					:prop="'link'"
				>
					<template #default="{ row }">
						<el-tooltip
							:content="$t('SETTINGS.EXPORT.DISABLED_DOWNLOAD_TOOLTIP')"
							:disabled="methodIsOwnExportOrAdmin(row)"
						>
						<el-link
							:href="`${computedAPIUrl}/download-export/${row.id}`"
							:disabled="row.status !== 'done' || !methodIsOwnExportOrAdmin(row)"
							:underline="false"
						>
							<i
								class="gui-import text-lg align-middle download-link"
								:class="{ 'disabled-color': row.status !== 'done' }"
							/>
						</el-link>
					</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div
			v-else
			class="mt-1 p-8 bg-white bold"
		>
			{{ $t('CONTACT.EXPORT.LIST_EXPORT.NO_EXPORT_CREATED_YET_LABEL') }}
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import parseISO from 'date-fns/parseISO'
import badgeStatus from '../helpers/statusBadge.vue'
import { API } from '../../../constants'
import tooltipMixin from '/src/mixins/tooltipMixin.js'
import { formatCustomDate, formatTime } from '../../../utils/dateFnsHelper'

export default {
	components: {
		badgeStatus,
	},
	mixins: [tooltipMixin],
	props: {
		tableHeight: {
			type: String,
			default: '',
		},
	},
	emits: ['needTableHeight'],
	computed: {
		...mapGetters('@export', ['getListExports']),
		...mapGetters(['userConnected']),
		computedAllExports() {
			if (this.getListExports.length) {
				let clonedExports = cloneDeep(this.getListExports)
				clonedExports = clonedExports.sort(
					(aExport, bExport) =>
						(new Date(aExport.created_at) < new Date(bExport.created_at)) * 2 - 1,
				)
				return clonedExports.map((anExport) => {
					let created_at = parseISO(anExport.created_at)

					anExport.created_at = formatCustomDate(new Date(created_at))
					anExport.created_at_hour = formatTime(created_at, 'HH\'h\'mm')

					return anExport
				})
			}
			return []
		},
		computedAPIUrl() {
			return API[0].LOCAL
		},
	},
	beforeMount() {
		if (this.tableHeight === '' || !!this.tableHeight) this.$emit('needTableHeight')
	},
	mounted() {
		this.actionFetchExports()
	},
	methods: {
		...mapActions('@export', ['actionFetchExports']),
		methodIsOwnExportOrAdmin(row){
			if(this.userConnected){
				return row.user.id === this.userConnected.id || this.userConnected.role === 'superadmin'
			}else{
				return false
			}
		},
		methodStripedRow({ rowIndex }) {
			if (rowIndex % 2 === 1) {
				return 'rowStripeDark'
			}
			else {
				return ''
			}
		},
	},
}
</script>

<style lang="scss">
@import 'src/assets/scss/settings/table-settings.sass';
</style>
