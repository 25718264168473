<template>
	<el-dialog
		v-model="computedDisplayDialog"
		width="40%"
		class="p-4"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		@closed="methodResetDialog"
	>
		<template #header>
			<div>
				<h1 class="text-xl flex gap-3 items-center">
					{{ t('SETTINGS.ROLE_MANAGEMENT.POPUP_DELETE_TITLE') }}
					<div
						class="w-fit leading-5 py-0.5 px-2 rounded-md"
						:style="[{ color: role?.color }, { backgroundColor: role?.color + '15' }]"
					>
						{{ methodRetrieveNameAccordingType(role) }}
					</div>
				</h1>
				<el-divider class="mb-0 mt-5" />
			</div>
		</template>
		<div class="flex flex-col gap-5">
			<div>
				{{ t('SETTINGS.ROLE_MANAGEMENT.POPUP_DELETE_DESC') }}
			</div>
			<!-- Select role -->
			<div class="relative">
				<el-select
					v-model="newRole"
					value-key="name"
					:placeholder="t('SETTINGS.ROLE_MANAGEMENT.NEW_ROLE')"
					class="w-full"
					:class="newRole?.id ? 'selectNewRoleHidden' : ''"
				>
					<el-option
						v-for="roleItem in computedRoleList"
						:key="roleItem.id"
						:value="roleItem"
						class="flex items-center"
					>
						<div
							class="w-fit leading-5 py-0.5 px-2 rounded-md"
							:style="[{ color: roleItem?.color }, { backgroundColor: roleItem?.color + '15' }]"
						>
							{{ methodRetrieveNameAccordingType(roleItem) }}
						</div>
					</el-option>
				</el-select>
				<!-- Label of the selected element of the role select -->
				<div
					v-if="newRole?.id"
					:style="`color: ${computedSelectedRoleColor}; background-color: ${computedSelectedRoleBgColor}`"
					class="absolute pointer-events-none leading-5 py-0.5 px-2 rounded-md left-3 selectedNewRoleLabel"
				>
					{{ methodRetrieveNameAccordingType(newRole) }}
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-end">
				<button
					class="q-button my-2 sm:w-40"
					type="button"
					:class="{ disabled: !newRole?.id }"
					:disabled="!newRole?.id"
					@click="
						() => {
							methodReplaceRole(role?.id)
						}
					"
				>
					{{ $t('_COMMON.DELETE') }}
				</button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { useStore } from 'vuex'
import { methodRetrieveNameAccordingType } from './../../../utils/RoleUtils.ts'
import { computed, ref, watch } from 'vue'
import { filter } from 'lodash'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'

// Data
const store = useStore()
const { t } = useI18n()
// Add id inside object for the reactivity of the el-select
let newRole = ref(null)

// Variables to check if all the users are correctly updated before the deletion of the role
let numberUserUpdated = ref(0)
let numberUserUpdatedStatus = ref(0)
let userUpdatedStatus = ref('success')
let isUpdateSended = ref(false)

// Emits
const emit = defineEmits(['update:displayDialog'])

// Props
const props = defineProps({
	role: { type: Object, default: null },
	rolesList: { type: Array, default: () => [] },
	displayDialog: { type: Boolean, default: false },
	methodDeleteRole: { type: Function, default: () => {} },
})

// Computed
const listUsers = computed(() => store.getters['getAllUsers'])
const getStatusUserRoleUpdate = computed(() => store.getters['@user/getStatusUserRoleUpdate'])

const computedDisplayDialog = computed({
	get() {
		return props.displayDialog
	},
	set(value) {
		emit('update:displayDialog', value)
	},
})
const computedSelectedRoleColor = computed(() => newRole?.value?.color)

const computedSelectedRoleBgColor = computed(() => newRole?.value?.color + '15')

const computedRoleList = computed(() =>
	filter(props.rolesList, (roleItem) => roleItem.id !== props.role?.id),
)

// Watch
watch(getStatusUserRoleUpdate, (status) => {
	// Check if it is the popup that send the update
	if (isUpdateSended.value) {
		// Case all the user to update aren't done
		if (numberUserUpdatedStatus.value < numberUserUpdated.value) {
			if (status == 'success') {
				numberUserUpdatedStatus.value++
			}
			else if (status == 'error') {
				userUpdatedStatus.value = 'error'
				numberUserUpdatedStatus.value++
			}
		}
		// Case all the users are updated, the deletion of the role can be done if there is no error
		if (numberUserUpdated.value == numberUserUpdatedStatus.value) {
			if (userUpdatedStatus.value == 'success') {
				props.methodDeleteRole(props.role?.id)
				ElMessage({
					showClose: true,
					type: 'success',
					message: t('SETTINGS.ROLE_MANAGEMENT.POPUP_DELETE_SUCCESS'),
				})
				computedDisplayDialog.value = false
			}
			else {
				ElMessage({
					showClose: true,
					type: 'error',
					message: t('SETTINGS.ROLE_MANAGEMENT.POPUP_DELETE_ERROR'),
				})
			}
		}
	}
})

// Method
const methodUpdateUserRole = (user, role) => {
	store.dispatch('@user/actionUpdateUserRole', { user: user, role: role })
}

const methodResetDialog = () => {
	newRole.value = ref(null)
	numberUserUpdated.value = 0
	numberUserUpdatedStatus.value = 0
	userUpdatedStatus.value = 'success'
	isUpdateSended.value = false
}

const methodReplaceRole = (roleId) => {
	let roleUsersList = filter(listUsers.value, (user) => user.role_data.id === roleId)
	roleUsersList.forEach((user) => {
		methodUpdateUserRole(user, newRole.value)
		isUpdateSended.value = true
		numberUserUpdated.value++
	})
	// Delete directly the role if there is no users to update
	if (numberUserUpdated.value == 0) {
		props.methodDeleteRole(roleId)
		ElMessage({
			showClose: true,
			type: 'success',
			message: t('SETTINGS.ROLE_MANAGEMENT.POPUP_DELETE_SUCCESS'),
		})
		computedDisplayDialog.value = false
	}
}
</script>

<style lang="scss">
.selectNewRoleHidden input {
	opacity: 0;
}
.selectedNewRoleLabel {
	top: 9px;
}
</style>
