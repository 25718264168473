/**
 * GLOBAL CONSTANTS
 */
export const SET_INSTANCIED_MODULE_ID = 'SET_INSTANCIED_MODULE_ID'
export const REMOVE_INSTANCIED_MODULE_ID = 'REMOVE_INSTANCIED_MODULE_ID'

/**
 * SEARCH CONSTANTS
 */
export const SEARCH_ADD_ADDRESS_INCLUDED = 'SEARCH_ADD_ADDRESS_INCLUDED'
export const SEARCH_REPLACE_ADDRESS_INCLUDED = 'SEARCH_REPLACE_ADDRESS_INCLUDED'
export const SEARCH_REMOVE_ADDRESS_INCLUDED = 'SEARCH_REMOVE_ADDRESS_INCLUDED'
export const SEARCH_RESET_ADDRESS_INCLUDED = 'SEARCH_RESET_ADDRESS_INCLUDED'
export const SEARCH_ADD_POLLING_STATION_INCLUDED = 'SEARCH_ADD_POLLING_STATION_INCLUDED'
export const SEARCH_REPLACE_POLLING_STATION_INCLUDED = 'SEARCH_REPLACE_POLLING_STATION_INCLUDED'
export const SEARCH_REMOVE_POLLING_STATION_INCLUDED = 'SEARCH_REMOVE_POLLING_STATION_INCLUDED'
export const SEARCH_RESET_POLLING_STATION_INCLUDED = 'SEARCH_REMOVE_POLLING_STATION_INCLUDED'
export const SEARCH_RESET_TO_INITIAL_STATE = 'SEARCH_RESET_TO_INITIAL_STATE'
export const FETCH_POLYGON_TO_GET_COUNT_ADDRESSES = 'FETCH_POLYGON_TO_GET_COUNT_ADDRESSES'
export const IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES = 'IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES'
export const SEARCH_SET_ADVANCED_SEARCH = 'SEARCH_SET_ADVANCED_SEARCH'
export const SEARCH_SET_ADVANCED_SEARCH_ATTR = 'SEARCH_SET_ADVANCED_SEARCH_ATTR'
export const SET_COUNT_CONTACTS_FOUND_ADVANCED_SEARCH_KPI
	= 'SET_COUNT_CONTACTS_FOUND_ADVANCED_SEARCH_KPI'

/**
 * FILTER CONSTANTS
 */
export const SET_FILTERS_USERS = 'SET_FILTERS_USERS'
export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const RESET_POLYGON = 'RESET_POLYGON'
export const RESET_FORMS_FILTERS = 'RESET_FORMS_FILTERS'
export const SET_RESULT_INDEX = 'SET_RESULT_INDEX'
export const SET_POLYGON = 'SET_POLYGON'
export const SET_POLYGONS = 'SET_POLYGONS'
export const SET_NB_RESULTS = 'SET_NB_RESULTS'
export const SET_NB_RESULTS_CONTACT_MEMBERS = 'SET_NB_RESULTS_CONTACT_MEMBERS'

/**
 * KPI CONSTANTS
 */

export const RESET_KPI_STORE = 'RESET_KPI_STORE'
export const SET_KPI = 'SET_KPI'
export const SET_KPIS_CARTO_ARE_VALID = 'SET_KPIS_CARTO_ARE_VALID'
export const SET_KPIS_CARTO_STATUS = 'SET_KPIS_CARTO_STATUS'
export const SET_KPIS_CARTO_PRESENCE = 'SET_KPIS_CARTO_PRESENCE'
export const SET_KPIS_CARTO_CONTACTS = 'SET_KPIS_CARTO_CONTACTS'
export const SET_KPIS_LOADING = 'SET_KPIS_LOADING'
export const SET_EMAILS_MISSING_KPI = 'SET_EMAILS_MISSING_KPI'

/**
 * ACTIONS CONSTANTS
 */

export const ARCHIVE_ACTION = 'ARCHIVE_ACTION'
export const SET_FORCE_EMPTY_ACTION = 'SET_FORCE_EMPTY_ACTION'
export const SET_ACTION_CREATION_STATE = 'SET_ACTION_CREATION_STATE'
export const SET_NEW_ACTION_STEPS = 'SET_NEW_ACTION_STEPS'
export const SET_NEW_ACTION_TOTAL_HIT = 'SET_NEW_ACTION_TOTAL_HIT'
export const SET_NEW_ACTION_NEVER_MET_HIT = 'SET_NEW_ACTION_NEVER_MET_HIT'
export const SET_NEW_ACTION_ABSENT_HIT = 'SET_NEW_ACTION_ABSENT_HIT'
export const SET_NEW_ACTION_TYPOLOGY = 'SET_NEW_ACTION_TYPOLOGY'
export const SET_ACTION_BLOCK_CITIES_TERRITORIES = 'SET_ACTION_BLOCK_CITIES_TERRITORIES'
export const SET_ACTION_RESET_ON_CLOSE_MODAL = 'SET_ACTION_RESET_ON_CLOSE_MODAL'
export const SET_ACTION_NEW_SEARCH_POLYGON = 'SET_ACTION_NEW_SEARCH_POLYGON'
export const SET_ACTION_NEW_SEARCH_TAGS = 'SET_ACTION_NEW_SEARCH_TAGS'
export const SET_ACTION_NEW_SEARCH_FILTERS = 'SET_ACTION_NEW_SEARCH_FILTERS'
export const SET_ACTION_NEW_ADVANCED_SEARCH_FILTERS = 'SET_ACTION_NEW_ADVANCED_SEARCH_FILTERS'
export const SET_ACTION_RESET_ADVANCED_SEARCH_FILTERS = 'SET_ACTION_RESET_ADVANCED_SEARCH_FILTERS'
export const SET_ACTION_NEW_ADDRESS = 'SET_ACTION_NEW_ADDRESS'
export const SET_NEW_ACTION_MEETING_POINT = 'SET_NEW_ACTION_MEETING_POINT'
export const RESET_NEW_ACTION_MEETING_POINT = 'RESET_NEW_ACTION_MEETING_POINT'
export const SET_NEW_ACTION_ONLY_TAKE_STREETS = 'SET_NEW_ACTION_ONLY_TAKE_STREETS'
export const SET_NEW_ACTION_DATA_OBJECT_VALUE = 'SET_NEW_ACTION_DATA_OBJECT_VALUE'
export const SET_NEW_ACTION_DATA_BRIEF = 'SET_NEW_ACTION_DATA_BRIEF'
export const SET_NEW_ACTION_PRECISION = 'SET_NEW_ACTION_PRECISION'
export const SET_SKIP_NAVIGATION_GUARD_BEFORE_ROUTE_LEAVE_ACTION_CREATION
	= 'SET_SKIP_NAVIGATION_GUARD_BEFORE_ROUTE_LEAVE_ACTION_CREATION'
export const SET_RESOLVE_CONFLICT_RESOLUTION_STATE = 'SET_RESOLVE_CONFLICT_RESOLUTION_STATE'
export const SET_STATUS_RENOTIFY = 'SET_STATUS_RENOTIFY'
export const SET_CONTACTS_ACTION = 'SET_CONTACTS_ACTION'
export const SET_ACTION_CONTACT_LIST_ADVANCED_SEARCH = 'SET_ACTION_CONTACT_LIST_ADVANCED_SEARCH'
export const SET_ACTION_TOTAL_HIT = 'SET_ACTION_TOTAL_HIT'
export const SET_ACTION_STACK = 'SET_ACTION_STACK'
export const SET_NEW_ACTION_GLOBAL_TYPE = 'SET_NEW_ACTION_GLOBAL_TYPE'
export const HANDLE_ACTION_DATA_LEADERS = 'HANDLE_ACTION_DATA_LEADERS'
export const REMOVE_AN_ACTION_DATA_LEADER = 'REMOVE_AN_ACTION_DATA_LEADER'
export const SET_ASSIGNMENT_TYPE = 'SET_ASSIGNMENT_TYPE'
export const NAVIGATE_TO_ACTION = 'NAVIGATE_TO_ACTION'

/**
 * GEOHASH MODULE
 */
export const GEOHASH_RESET_TO_INITIAL_STATE = 'GEOHASH_RESET_TO_INITIAL_STATE'
export const RESET_NEW_ACTION_STORE = 'RESET_NEW_ACTION_STORE'

/**
 * MAILCHIMP CONSTANTS
 */
export const POST_MAILCHIMP_BULK_STARTED_SUCCESS = 'POST_MAILCHIMP_BULK_STARTED_SUCCESS'
export const POST_MAILCHIMP_BULK_STARTED_ERROR = 'POST_MAILCHIMP_BULK_STARTED_ERROR'
export const RESET_MAILCHIMP_STORE_MODULE = 'RESET_MAILCHIMP_STORE_MODULE'

/**
 * CONTACT CONSTANTS
 */
export const SET_ACTIVE_CONTACT_BACKUP = 'SET_ACTIVE_CONTACT_BACKUP'
export const GET_NOTES_INTO_CONTACT_STORE_MODULE = 'GET_NOTES_INTO_CONTACT_STORE_MODULE'
export const SET_NEW_NOTE_INTO_CONTACT_STORE_MODULE = 'SET_NEW_NOTE_INTO_CONTACT_STORE_MODULE'
export const SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING
	= 'SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING'
export const SET_LOADING_UPDATE_CONTACT = 'SET_LOADING_UPDATE_CONTACT'
export const SET_CONTACT_WAS_UPDATED = 'SET_CONTACT_WAS_UPDATED'
export const SET_CONTACT_WAS_CREATED = 'SET_CONTACT_WAS_CREATED'
export const SET_CONTACT_CREATION_STATUS = 'SET_CONTACT_CREATION_STATUS'
export const SET_IS_FETCHING_CURRENT_CONTACT = 'SET_IS_FETCHING_CURRENT_CONTACT'
export const CONTACT_MEMBERSHIP_INFOS_STATUS = 'CONTACT_MEMBERSHIP_INFOS_STATUS'
export const CONTACT_MEMBERSHIP_INFOS = 'CONTACT_MEMBERSHIP_INFOS'
export const SANCTIONS_TYPES_STATUS = 'SANCTIONS_TYPES_STATUS'
export const SANCTIONS_TYPES_LIST = 'SANCTIONS_TYPES_LIST'
export const SANCTION_AUTHORITIES_LIST_STATUS = 'SANCTION_AUTHORITIES_LIST_STATUS'
export const SANCTION_AUTHORITIES_LIST = 'SANCTION_AUTHORITIES_LIST'
export const CONTACT_SANCTIONS_STATUS = 'CONTACT_SANCTIONS_STATUS'
export const CONTACT_SANCTIONS = 'CONTACT_SANCTIONS'
export const GET_HISTORY_INTO_CONTACT_STORE_MODULE = 'GET_HISTORY_INTO_CONTACT_STORE_MODULE'
export const SET_LOADING_HISTORY_CONTACT = 'SET_LOADING_HISTORY_CONTACT'
export const SET_LOADING_INTERACTION_CONTACT = 'SET_LOADING_INTERACTION_CONTACT'
export const SET_INTERACTIONS_CONTACT = 'SET_INTERACTIONS_CONTACT'
export const RESET_INTERACTIONS_CONTACT = 'RESET_INTERACTIONS_CONTACT'
export const UPDATE_INTERACTION_CONTACT = 'UPDATE_INTERACTION_CONTACT'
export const DELETE_INTERACTION_CONTACT = 'DELETE_INTERACTION_CONTACT'
export const SET_LOCALY_NOTE_IN_INTERACTION = 'SET_LOCALY_NOTE_IN_INTERACTION'
export const UPDATE_LOCALY_NOTE_IN_INTERACTION = 'UPDATE_LOCALY_NOTE_IN_INTERACTION'
export const DELETE_NOTES_LOCALLY_INTO_CONTACT_STORE_MODULE
	= 'DELETE_NOTES_LOCALLY_INTO_CONTACT_STORE_MODULE'
export const SET_CONTACT_EMAILS_LIST_LOADING = 'SET_CONTACT_EMAILS_LIST_LOADING'
export const SET_CONTACT_EMAILS_LIST = 'SET_CONTACT_EMAILS_LIST'
export const ADD_LOCALLY_NEW_EMAIL_TO_LIST = 'ADD_LOCALLY_NEW_EMAIL_TO_LIST'
export const MARK_EMAIL_AS_READ = 'MARK_EMAIL_AS_READ'
export const SET_REQUEST_LIMIT = 'SET_REQUEST_LIMIT'
export const SET_NEW_COUNTER_LIMIT = 'SET_NEW_COUNTER_LIMIT'
export const SET_INTERACTION_ATTACHMENTS = 'SET_INTERACTION_ATTACHMENTS'
export const DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS
	= 'DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS'
export const DELETE_INTERACTION_ATTACHMENTS = 'DELETE_INTERACTION_ATTACHMENTS'
export const SET_UPLOADING_INTERACTION = 'SET_UPLOADING_INTERACTION'
export const SET_MASSIVE_UPDATE_TOAST = 'SET_MASSIVE_UPDATE_TOAST'
export const REMOVE_EMPTY_CONTACT_BASE_VIEW_WHEN_TAG_REMOVAL
	= 'REMOVE_EMPTY_CONTACT_BASE_VIEW_WHEN_TAG_REMOVAL'
export const SET_CONTACT_AGE_CATEGORIES = 'SET_CONTACT_AGE_CATEGORIES'
export const ADD_POLLING_STATION = 'ADD_POLLING_STATION'
export const SET_CONTACT_FILTERS_FOR_SMS_EMAILS = 'SET_CONTACT_FILTERS_FOR_SMS_EMAILS'
export const SET_CONTACT_TRANSACTION_INFOS = 'SET_CONTACT_TRANSACTION_INFOS'

/**
 * PACK CONSTANTS
 */
export const RESET_PACK_STORE = 'RESET_PACK_STORE'
export const SET_LOCALE_SPECIFIC = 'SET_LOCALE_SPECIFIC'
export const SET_PACK_CUSTOMER_PACK_RIGHTS_INSTANCE = 'SET_PACK_CUSTOMER_PACK_RIGHTS_INSTANCE'

/**
 * IMPORT CONSTANTS
 */
export const RESET_IMPORT_STORE = 'RESET_IMPORT_STORE'
export const FETCH_ALL_IMPORTS = 'FETCH_ALL_IMPORTS'
export const FETCH_PREVIEW_FILE_UPLOADED = 'FETCH_PREVIEW_FILE_UPLOADED'
export const SET_PREVIEW_FILE_UPLOADED_STATE = 'SET_PREVIEW_FILE_UPLOADED_STATE'
export const FETCH_PREVIEW_FILE_UPLOADED_COLUMNS = 'FETCH_PREVIEW_FILE_UPLOADED_COLUMNS'
export const FETCH_SAMPLE_LINES_FILE_UPLOADED = 'FETCH_SAMPLE_LINES_FILE_UPLOADED'
export const POST_SAVE_SETTINGS_FILE_UPLOADED = 'POST_SAVE_SETTINGS_FILE_UPLOADED'
export const SAVE_COLUMNS_FROM_DATA_CHOICE = 'SAVE_COLUMNS_FROM_DATA_CHOICE'
export const SAVE_IMPORT_CSV_MAPPING = 'SAVE_IMPORT_CSV_MAPPING'
export const SET_IMPORT_CONFIG_CSV = 'SET_IMPORT_CONFIG_CSV'
export const SET_IMPORT_ENQUEUED_STATE = 'SET_IMPORT_ENQUEUED_STATE'
export const FETCH_QUORUM_PROPERTIES_STATE = 'FETCH_QUORUM_PROPERTIES_STATE'
export const SET_QUORUM_PROPERTIES = 'SET_QUORUM_PROPERTIES'
export const FETCH_IMPORT_STATE = 'FETCH_IMPORT_STATE'
export const SET_IMPORT = 'SET_IMPORT'
export const SET_BUILDED_COLUMNS = 'SET_BUILDED_COLUMNS'
export const FETCH_LIST_ALL_CONFLICTS_STATE = 'FETCH_LIST_ALL_CONFLICTS_STATE'
export const SET_LIST_ALL_CONFLICTS = 'SET_LIST_ALL_CONFLICTS'
export const SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS = 'SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS'
export const SET_ARCHIVED_STATUS_TO_AN_IMPORT_BY_ID = 'SET_ARCHIVED_STATUS_TO_AN_IMPORT_BY_ID'
export const SET_STATUSES_IN_IMPORT_FETCHED = 'SET_STATUSES_IN_IMPORT_FETCHED'
export const SET_AI_REQUEST_COUNT = 'SET_AI_REQUEST_COUNT'
/**
 * EXPORT CONSTANTS
 */
export const FETCH_ALL_EXPORTS = 'FETCH_ALL_EXPORTS'
export const SET_EXPORTS_TEMPLATES = 'SET_EXPORTS_TEMPLATES'
export const SET_EXPORT_TEMPLATE = 'SET_EXPORT_TEMPLATE'
export const SET_EXPORTS_TEMPLATES_SAVE_STATUS = 'SET_EXPORTS_TEMPLATES_SAVE_STATUS'
export const SET_EXPORTS_TEMPLATES_DELETE_STATUS = 'SET_EXPORTS_TEMPLATES_DELETE_STATUS'
export const DELETE_A_EXPORT_TEMPLATE = 'DELETE_A_EXPORT_TEMPLATE'

/**
 * FORM CONSTANTS
 */
export const SET_FORM = 'SET_FORM'
export const SET_NEED_PUBLISH_FORM = 'SET_NEED_PUBLISH_FORM'
export const SET_LOADING_SAVING_FORM = 'SET_LOADING_SAVING_FORM'
export const SET_LOADING_CAN_DELETE = 'SET_LOADING_CAN_DELETE'
export const SET_CAN_DELETE_INFOS = 'SET_CAN_DELETE_INFOS'
export const RESET_CAN_DELETE_INFOS = 'RESET_CAN_DELETE_INFOS'
export const RESET_FORM_STORE = 'RESET_FORM_STORE'
export const SET_STATIC_AND_CUSTOM_UPDATE_STATUS = 'SET_STATIC_AND_CUSTOM_UPDATE_STATUS'
export const SET_REDUCED_LIST_STATE = 'SET_REDUCED_LIST_STATE'

/**
 * CAPTION CONSTANTS
 */
export const SET_CAPTION = 'SET_CAPTION'
export const SET_SECONDARY_CAPTION = 'SET_SECONDARY_CAPTION'
export const RESET_CAPTION_STORE = 'RESET_CAPTION_STORE'
export const RESET_SECONDARY_CAPTION = 'RESET_SECONDARY_CAPTION'

/**
 * TAGS CONSTANTS
 */
export const SET_TAGS = 'SET_TAGS'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const RESET_TAGS_STORE = 'RESET_TAGS_STORE'

/**
 * COMMUNICATION CONSTANTS
 */
export const RESET_NOTIFICATION_MODULE = 'RESET_NOTIFICATION_MODULE'
export const SET_STATE_GLOBAL_NOTIFICATION = 'SET_STATE_GLOBAL_NOTIFICATION'

/**
 * GROUP CONSTANTS
 */
export const SET_GROUP_CAMPAIGN = 'SET_GROUP_CAMPAIGN'

/**
 * MASSIVE MANIPULATIONS CONSTANTS
 */
export const FETCH_ALL_MASSIVE_MANIPULATION = 'FETCH_ALL_MASSIVE_MANIPULATION'
export const FETCH_ALL_MASSIVE_CONTACT_MODIFICATIONS = 'FETCH_ALL_MASSIVE_CONTACT_MODIFICATIONS'
/**
 * INVITATIONS CONSTANTS
 */
export const SET_INVITATIONS = 'SET_INVITATIONS'
export const REPLACE_INVITATION = 'REPLACE_INVITATION'
export const DELETE_INVITATION = 'DELETE_INVITATION'
/**
 * EMAILER CONSTANTS
 */
export const RESET_EMAILER_STORE = 'RESET_EMAILER_STORE'
export const SET_EMAILS_BALANCE = 'SET_EMAILS_BALANCE'
export const SET_EMAILS_CONTENT_CHARACTERS_COUNT = 'SET_EMAILS_CONTENT_CHARACTERS_COUNT'
export const SET_EMAILS_OBJECT_LENGTH = 'SET_EMAILS_OBJECT_LENGTH'
export const SET_IS_REPLY_TO_VALID = 'SET_IS_REPLY_TO_VALID'
export const SET_RECAP_DIALOG_VISIBILITY = 'SET_RECAP_DIALOG_VISIBILITY'
export const SET_TEST_EMAIL_DIALOG_VISIBILITY = 'SET_TEST_EMAIL_DIALOG_VISIBILITY'
export const UPDATE_IS_SENDING_SUCCEED = 'UPDATE_IS_SENDING_SUCCEED'
export const UPDATE_TEST_EMAIL_LIST = 'UPDATE_TEST_EMAIL_LIST'
export const SET_EMAILER_ACCOUNT_DATA = 'SET_EMAILER_ACCOUNT_DATA'
export const SET_EMAILER_TEMPLATES = 'SET_EMAILER_TEMPLATES'
export const SET_EMAILER_PRICES = 'SET_EMAILER_PRICES'
export const SET_BULK_EMAILS_SUBSCRIPTION = 'SET_BULK_EMAILS_SUBSCRIPTION'
export const UPDATE_TEST_SENDING_STATUS = 'UPDATE_TEST_SENDING_STATUS'
export const UPDATE_GET_EMAILER_ACCOUNT_STATUS = 'UPDATE_GET_EMAILER_ACCOUNT_STATUS'
export const SET_UPSELL_DOWNSELL_UPDATE_STATUS = 'SET_UPSELL_DOWNSELL_UPDATE_STATUS'
export const UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS
	= 'UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS'
export const UPDATE_BULK_EMAILS_SHOW_DETAILS = 'UPDATE_BULK_EMAILS_SHOW_DETAILS'
export const UPDATE_REMOVE_SELECTED_TEMPLATE_TRIGGER = 'UPDATE_REMOVE_SELECTED_TEMPLATE_TRIGGER'
export const UPDATE_EMAILER_DOMAIN_LIST = 'UPDATE_EMAILER_DOMAIN_LIST'
export const SET_STATUS_POST_REGISTER_NEW_DOMAIN = 'SET_STATUS_POST_REGISTER_NEW_DOMAIN'
export const SET_EMAILER_SENDERS = 'SET_EMAILER_SENDERS'
export const SET_EMAILER_SENDERS_LOADING_STATUS = 'SET_EMAILER_SENDERS_LOADING_STATUS'
export const SET_PROVIDER_CAMPAIGN_STATUSES = 'SET_PROVIDER_CAMPAIGN_STATUSES'
export const SET_USER_SENDERS = 'SET_USER_SENDERS'

/**
 * SMS CONSTANTS
 */
export const SET_SMS_SENT = 'SET_SMS_SENT'
export const SET_NEW_SMS_SENT = 'SET_NEW_SMS_SENT'
export const SET_MESSAGE_BIRD_BALANCE = 'SET_MESSAGE_BIRD_BALANCE'
export const SET_MESSAGE_BIRD_STATUS = 'SET_MESSAGE_BIRD_STATUS'
export const SET_STATUS_POST_SMS = 'SET_STATUS_POST_SMS'
export const SET_STATUS_POST_TEST_SMS = 'SET_STATUS_POST_TEST_SMS'
export const RESET_SMS_STORE = 'RESET_SMS_STORE'
export const SET_TEST_DIALOG_VISIBLE = 'SET_TEST_DIALOG_VISIBLE'
export const SET_RECAP_DIALOG_VISIBLE = 'SET_RECAP_DIALOG_VISIBLE'
export const SET_IS_READY_TO_BE_SENT = 'SET_IS_READY_TO_BE_SENT'
export const SET_IS_SENDING_DIALOG_VISIBLE = 'SET_IS_SENDING_DIALOG_VISIBLE'
export const SET_MESSAGING_PROFILES = 'SET_MESSAGING_PROFILES'

/**
 * PROFILE
 */
export const SET_NOTIFICATIONS_PREFERENCES = 'SET_NOTIFICATIONS_PREFERENCES'
export const SET_USER_EMAIL_ACCOUNTS = 'SET_USER_EMAIL_ACCOUNTS'
export const DELETE_USER_EMAIL_ACCOUNT = 'DELETE_USER_EMAIL_ACCOUNT'

/**
 * GROUP INVOICES
 */
export const FETCH_ALL_INVOICES = 'FETCH_ALL_INVOICES'

/**
 * DYNAMIC LISTS
 */
export const SET_LISTS = 'FETCH_LISTS'
export const SET_STATUS_LISTS = 'SET_STATUS_LISTS'
export const REMOVE_LIST = 'REMOVE_LIST'
export const UPDATE_LIST = 'UPDATE_LIST'
export const CREATE_LIST = 'CREATE_LIST'

/**
 * NAV BAR
 */
export const SET_MENU_VISIBLE = 'SET_MENU_VISIBLE'
export const SET_MENU_COLLAPSE = 'SET_MENU_COLLAPSE'

/**
 * CREATE FORM
 */
export const SET_PARAMS_BAR = 'SET_PARAMS_BAR'
export const SET_IS_MOBILE_VIEW = 'SET_IS_MOBILE_VIEW'
export const SET_IS_CONFIRMATION_VIEW = 'SET_IS_CONFIRMATION_VIEW'

/**
 * PETITION
 */
export const RESET_PETITIONS_STORE = 'RESET_PETITIONS_STORE'
export const SET_PETITIONS_CATEGORIES = 'SET_PETITIONS_CATEGORIES'
export const SET_STATUS_FETCH_PETITIONS = 'SET_STATUS_FETCH_PETITIONS'
export const SET_PETITIONS_LIST = 'SET_PETITIONS_LIST'
export const SET_STATUS_FETCH_ONE_PETITION = 'SET_STATUS_FETCH_ONE_PETITION'
export const SET_PETITION = 'SET_PETITION'
export const SET_STATUS_POST_PETITION = 'SET_STATUS_POST_PETITION'
export const SET_STATUS_UPDATE_PETITION = 'SET_STATUS_UPDATE_PETITION'
export const SET_STATUS_PUBLISH_PETITION = 'SET_STATUS_PUBLISH_PETITION'
export const SET_STATUS_UNPUBLISH_PETITION = 'SET_STATUS_UNPUBLISH_PETITION'
export const SET_STATUS_DELETE_PETITION = 'SET_STATUS_DELETE_PETITION'

/**
/**
 * ONLINE FORMS
 */
export const RESET_ONLINE_FORMS_STORE = 'RESET_ONLINE_FORMS_STORE'
export const SET_ONLINE_FORMS_CATEGORIES = 'SET_ONLINE_FORMS_CATEGORIES'
export const SET_STATUS_FETCH_ONLINE_FORMS = 'SET_STATUS_FETCH_ONLINE_FORMS'
export const SET_ONLINE_FORMS_LIST = 'SET_ONLINE_FORMS_LIST'
export const SET_STATUS_FETCH_ONE_ONLINE_FORM = 'SET_STATUS_FETCH_ONE_ONLINE_FORM'
export const SET_ONLINE_FORM = 'SET_ONLINE_FORM'
export const SET_STATUS_POST_ONLINE_FORM = 'SET_STATUS_POST_ONLINE_FORM'
export const SET_STATUS_UPDATE_ONLINE_FORM = 'SET_STATUS_UPDATE_ONLINE_FORM'
export const SET_STATUS_PUBLISH_ONLINE_FORM = 'SET_STATUS_PUBLISH_ONLINE_FORM'
export const SET_STATUS_UNPUBLISH_ONLINE_FORM = 'SET_STATUS_UNPUBLISH_ONLINE_FORM'
export const SET_STATUS_DELETE_ONLINE_FORM = 'SET_STATUS_DELETE_ONLINE_FORM'
export const SET_ONLINE_FORMS_SEARCH = 'SET_ONLINE_FORMS_SEARCH'
export const SET_SOCIAL_NETWORKS_OPTIONS = 'SET_SOCIAL_NETWORKS_OPTIONS'
// export const SET_ONLINE_FORMS_SEARCH_LENGTH = 'SET_ONLINE_FORMS_SEARCH_LENGTH'

/**
 * EXTENSIONS
 */
export const SET_SYNC_CREATION_MESSAGE = 'SET_SYNC_CREATION_MESSAGE'
export const SET_SYNC_DELETION_MESSAGE = 'SET_SYNC_DELETION_MESSAGE'
export const SET_STRIPE_SYNC = 'SET_STRIPE_SYNC'
/**
 * PRIVACY POLICY
 */
export const RESET_PRIVACY_POLICY_STORE = 'RESET_PRIVACY_POLICY_STORE'
export const SET_PRIVACY_POLICY_URL = 'SET_PRIVACY_POLICY_URL'
export const SET_FETCHING_POST_PRIVACY_POLICY_STATUS = 'SET_FETCHING_POST_PRIVACY_POLICY_STATUS'

/**
 * TASKS
 */
export const RESET_STATE = 'RESET_STATE'
export const SET_TASKS = 'SET_TASKS'
export const SET_IS_LOADING_TASKS = 'SET_IS_LOADING_TASKS'

/**
 * WELCOME
 */
export const SET_STATUS_MAP_ROOT_LABEL = 'SET_STATUS_MAP_ROOT_LABEL'
export const SET_STATUS_ORGANISATION_SIZE = 'SET_STATUS_ORGANISATION_SIZE'
export const SET_STATUS_FEATURES_OF_INTEREST = 'SET_STATUS_FEATURES_OF_INTEREST'

/**
 * TEMP STORE
 */
export const SET_TEMP_VALUE = 'SET_TEMP_VALUE'
export const CLEAN_TEMP_VALUE = 'CLEAN_TEMP_VALUE'

/**
 * WAITING USERS
 */
export const SET_WAITING_USERS_LIST = 'SET_WAITING_USERS_LIST'
export const RESET_WAITING_USERS_STORE = 'RESET_WAITING_USERS_STORE'

/**
 * API KEY
 */
export const RESET_API_KEY_STORE = 'RESET_API_KEY_STORE'
export const SET_API_KEYS = 'SET_API_KEYS'
export const SET_STATUS_POST_API_KEY = 'SET_STATUS_POST_API_KEY'
export const SET_STATUS_DELETE_API_KEY = 'SET_STATUS_DELETE_API_KEY'

/**
 * ROLE MANAGEMENT
 */
export const RESET_ROLE_MANAGEMENT_STORE = 'RESET_ROLE_MANAGEMENT_STORE'
export const SET_ROLES_LIST = 'SET_ROLES_LIST'
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST'

/**
 * MASTER SPACE
 */
export const RESET_MASTER_SPACE_STORE = 'RESET_MASTER_SPACE_STORE'
export const SET_IS_HELPER_DISPLAYED = 'SET_IS_HELPER_DISPLAYED'

/**
 * MANDATES
 */
export const RESET_MANDATES_STORE = 'RESET_MANDATES_STORE'
export const SET_MANDATES = 'SET_MANDATES'
export const SET_MANDATES_ORGANIZATIONS = 'SET_MANDATES_ORGANIZATIONS'
export const SET_MANDATES_TITLES = 'SET_MANDATES_TITLES'

/**
 * TURFS
 */
export const RESET_TURF_STORE = 'RESET_TURF_STORE'
export const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER'
export const SET_LAYER_LIST = 'SET_LAYER_LIST'

/**
 * PROFILES
 */
export const RESET_PROFILES_STORE = 'RESET_PROFILES_STORE'
export const SET_PROFILES_LIST = 'SET_PROFILES_LIST'
export const SET_AUTO_CROSS_ANALYSIS_FOR_MAP = 'SET_AUTO_CROSS_ANALYSIS_FOR_MAP'
export const SET_PROFILE_KPI_AVAILABLE_CONTACTS = 'SET_PROFILE_KPI_AVAILABLE_CONTACTS'
