import { getterControllerStorePackCPR } from './utils/routerController'

const routes = {
	meta: { title: 'MENU.IMPORTS' },
	path: 'import/create',
	name: 'import',
	component: () => import('../components/settings-module/import/importContainerRouterView.vue'),
	redirect: { name: 'import-file' },
	props: true,
	children: [
		{
			path: 'file',
			name: 'import-file',
			component: () => import('../components/settings-module/import/importSteps/importFile.vue'),
			props: true,
		},
		{
			path: 'data-choice',
			name: 'import-data-choice',
			component: () =>
				import('../components/settings-module/import/importSteps/importDataChoice.vue'),
			props: true,
		},
		{
			path: 'data-type',
			name: 'import-data-type',
			component: () =>
				import('../components/settings-module/import/importSteps/importDataType.vue'),
			props: true,
		},
		{
			path: 'mapping',
			name: 'import-mapping',
			component: () => import('../components/settings-module/import/importSteps/importMapping.vue'),
			props: true,
		},
		{
			path: 'recap',
			name: 'import-recap',
			component: () => import('../components/settings-module/import/importSteps/importRecap.vue'),
		},
		{
			path: 'final',
			name: 'import-final',
			component: () => import('../components/settings-module/import/importSteps/importFinal.vue'),
			props: true,
		},
	],
	beforeEnter: (to, from, next) => {
		const CPR = getterControllerStorePackCPR()
		if (CPR.statusWebImportContacts() === 'enabled') {
			next(true)
		} else {
			next(from)
		}
	},
}

export default routes
