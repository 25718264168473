import Survey from '../components/survey-module/Survey.vue'
import SurveyResults from '../components/survey-module/survey-sections/SurveyResults.vue'

import { controllerPackRedirection } from './utils/routerController'
import store from './../store/stateStore'

export default {
	meta: { title: 'MENU.SURVEY' },
	path: '/survey',
	name: 'survey',
	component: Survey,
	redirect: () => {
		if (store.getters['userConnected']?.role_data?.access?.includes('survey:delete')) {
			return { name: 'survey-builder' }
		} else {
			return { name: 'survey-results-general' }
		}
	},
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('survey:write')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessSurvey')
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: '/survey/results',
			name: 'survey-results',
			component: SurveyResults,
			redirect: { name: 'survey-builder' },
			children: [
				{
					path: '/survey/builder',
					name: 'survey-builder',
					component: () => import('../components/settings-module/multi-form-builder.vue'),
				},
				{
					path: '/survey/results/detailed',
					name: 'survey-results-detailed',
					component: () =>
						import(
							'../components/survey-module/survey-sections/survey-sections-results/SurveyResultsDetailed.vue'
						),
				},
				{
					path: '/survey/results/general',
					name: 'survey-results-general',
					component: () =>
						import(
							'../components/survey-module/survey-sections/survey-sections-results/SurveyResultsGeneral'
						),
				},
			],
		},
	],
}
