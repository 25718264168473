import { isDefinitiveSanction } from '../../../components/contact-module/contactDetails/transactions/sanctionInfos'


export function setOnGoingSanctionStore(sanctionsList) {

    let onGoingSanction = {}

    const sanctionsListActives = sanctionsList.filter((aSanction) => new Date(aSanction.end_date) > new Date() && new Date(aSanction.start_date) < new Date())


        const contactSanctionsListFilteredByDefinitive = sanctionsListActives.filter(
            (aSanction) => isDefinitiveSanction(aSanction.end_date))

            if(contactSanctionsListFilteredByDefinitive.length > 0) {
              
            const sortDefinitiveSanctionsByCreatedAtMoreRecent = contactSanctionsListFilteredByDefinitive.sort((a, b) => {
                return new Date(b.CreatedAt) - new Date(a.CreatedAt)
            })
            onGoingSanction = sortDefinitiveSanctionsByCreatedAtMoreRecent[0] || []
            
            }else{
              
                const sortSanctionsListByEndDate = sanctionsListActives.sort((a, b) => {
                    return new Date(b.end_date) - new Date(a.end_date)
                }	
                )

                onGoingSanction =  sortSanctionsListByEndDate[0] || []

            }
            return onGoingSanction
	}

	
