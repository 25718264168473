<template>
	<div
		class="q-fullwidth flex items-center justify-between relative"
		:class="{ 'min-h-11': $route.name === 'communication-mail-create' }"
	>
		<div
			v-if="
				$route.name === 'communications-sms-create' || $route.name === 'communication-mail-create'
			"
			:class="{
				'flex justify-between w-full items-center': $route.name === 'communication-mail-create',
			}"
			class="flex flex-wrap"
		>
			<div class="flex items-center gap-10">
				<div
					v-if="isSendingSucceed !== 'success'"
					:class="{ 'opacity-60 pointer-events-none': isSendingSucceed === 'loading' }"
					class="flex items-center pointer hover:text-gray-500 gap-2.5"
					@click="methodGoBack()"
				>
					<i
						class="gui-next transform rotate-180 text-2xl"
						aria-hidden="true"
					/>
					<span class="">{{ $t('_COMMON.BACK') }}</span>
				</div>
				<template v-if="$route.name === 'communication-mail-create'">
					<div
						class="flex items-center text-xs sm:text-xl gap-2"
					>
						<span
							v-if="getKPISendingEmailsMissing"
							class="text-xs sm:text-xl"
							v-html="$tc('MAILING.SEND_MAIL_TO_X_CONTACT', getKPISendingEmailsMissing?.valids || 0, getKPISendingEmailsMissing?.valids || 0)"
						/>
						<div
							v-else
							class="flex items-center justify-center gap-1 text-xs sm:text-xl"
						>
							{{ $t('MAILING.SEND_MAIL_TO_X_CONTACT_LOADING_1') }} <i class="gui-loading animate-spin" /> {{ $t('MAILING.SEND_MAIL_TO_X_CONTACT_LOADING_2') }}
						</div>
						<el-tooltip
							:content="$t('MAILING.TITLE_TOOLTIP')"
							placement="bottom"
							popper-class="rounded-xl"
						>
							<span class="text-xl gui-infos" />
						</el-tooltip>
					</div>
				</template>
			</div>

			<!-- EMAIL CREATE HEADER PART -->
			<template
				v-if="
					$route.name === 'communication-mail-create' &&
						isSendingSucceed !== 'success' &&
						bulkEmailsPanelVisiblity
				"
			>
				<div class="flex items-center gap-3">
					<div class="flex items-center justify-center">
						<div
							v-if="bulkEmailsPanelVisiblity && getTempValue?.advancedBuilderWanted"
							class="w-fit rounded-3xl flex items-center gap-3 px-3 py-1.5 cursor-pointer hover:opacity-70"
							@click="methodHandleShowDetails"
						>
							{{
								!getShowBulkEmailsDetails
									? $t('CONTACT.HEADER.BULK_EMAILS.EDITOR.HEADER_OPEN_DETAILS_SECTION')
									: $t('CONTACT.HEADER.BULK_EMAILS.EDITOR.HEADER_CLOSE_DETAILS_SECTION')
							}}
							<i
								:class="!getShowBulkEmailsDetails ? 'gui-arrow_down ' : 'gui-arrow_up'"
								class="text-xl"
							/>
						</div>
					</div>
					<div class="flex items-center gap-5 pr-3 relative">
						<el-tooltip
							v-if="computedIsSendButtonDisabled"
							placement="top"
							trigger="hover"
							popper-class="rounded-xl"
						>
							<template #content>
								<div class="text-sm">
									<span v-if="getObjectLength === 0">• {{ $t('MAILING.TOOTLTIP_OBJECT_ERROR') }}<br></span>
									<span v-if="!getIsReplyToValid">• {{ $t('MAILING.TOOLTIP_REPLY_TO_INVALID') }}<br></span>
									<span v-if="computedTotalHit === 0">- {{ $t('MAILING.TOOTLTIP_TOTALHIT_ERROR') }}<br></span>
									<span
										v-if="getContentCaractersCount === 0 && !getTempValue?.advancedBuilderWanted"
									>• {{ $t('MAILING.TOOTLTIP_CONTENT_ERROR') }}<br></span>

									<!-- <span v-if="computedGetEmailsBalanceLeft < 0 && !computedIsUpsellAutomatically">• {{ $t('MAILING.TOOTLTIP_BALANCE_ERROR') }}<br></span> -->
									<span
										v-if="!computedIsAnotherEmailerThanElasticEmail && !getValidatedEmailerSenders"
									>• {{ $t('MAILING.TOLTIP_SENDERS_ERROR') }}<br></span>
								</div>
							</template>
							<div
								style="width: 103%"
								class="absolute h-[54px] -left-3 -top-2.5 bg-gray-200 rounded-full opacity-50 cursor-not-allowed"
							/>
						</el-tooltip>
						<q-button
							class="font-normal"
							@click="actionSetTestEmailDialogVisibility(true)"
						>
							{{
								$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.BULK_EMAIL_TEST')
							}}
						</q-button>
						<q-button
							type="primary"
							style="min-width: 200px"
							class="flex justify-center items-center gap-2 basic-black"
							@click="actionSetRecapDialogVisibility(true)"
						>
							{{
								$t('MAILING.SEND_BUTTON')
							}}
						</q-button>
					</div>
				</div>
			</template>
		</div>

		<!-- COMMUNICATION PAGE HEADER -->
		<div
			v-else
			class="flex items-center w-full justify-between"
		>
			<div class="flex items-center">
				<span
					v-for="(tab, index) in tabs"
					:key="index"
				>
					<tab-with-number
						v-if="tab.settingsCtaDisplayed"
						:title="tab.title"
						:selected="tab.selected"
						:status-pack="tab.status"
						:badge="tab.badge"
						:tag="tab.tag"
						:is-soon="tab.isSoon"
						:no-opacity="tab.noOpacity"
						font-size="1.25rem"
						class="mr-10 cursor-pointer"
						@tab-click="methodGoTo(tab)"
					/>
				</span>
			</div>
			<div class="pr-5">
				<el-dropdown popper-class="rounded-xl">
					<q-button class="flex items-center gap-1">
						{{ $t('_COMMON.SEND') }} <i class="gui-arrow_down" />
					</q-button>
					<template #dropdown>
						<el-dropdown-menu class="rounded-xl px-1">
							<el-dropdown-item
								class="rounded-lg"
								:disabled="!methodIsAccessGranted('communication.email:delete')"
								@click="methodGoToContactBase"
							>
								<el-tooltip
									:disabled="methodIsAccessGranted('communication.email:delete')"
									placement="left"
									:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
								>
									<div class="flex items-center gap-1">
										<i class="gui-send_email text-xl" />{{ $t('COMMUNCATIONS.HEADER.COMMUNICATIONS_DROPDOWN.CAMPAIGN') }}
									</div>
								</el-tooltip>
							</el-dropdown-item>
							<el-dropdown-item
								class="rounded-lg flex items-center gap-1"
								@click="methodGoTo({ name: 'global-notification' })"
							>
								<i class="gui-notification_off text-xl" />
								{{ $t('COMMUNCATIONS.HEADER.COMMUNICATIONS_DROPDOWN.NOTIFY') }}
							</el-dropdown-item>
							<el-dropdown-item
								:disabled="!isEmailBoxConnected"
								class="rounded-lg flex items-center gap-1"
								@click="methodGoTo({ name: 'contacts', query: { mail: 'SET' } })"
							>
								<div class="flex items-center gap-1">
									<i class="gui-mail text-xl" />{{ $t('COMMUNCATIONS.HEADER.COMMUNICATIONS_DROPDOWN.INBOX') }}
								</div>
							</el-dropdown-item>
							<el-dropdown-item
								class="rounded-lg flex items-center gap-1"
								:disabled="!methodIsAccessGranted('communication.sms:delete')"
								@click="methodGoTo({ name: 'contacts', query: { phone: 'ONLYMOBILE' } })"
							>
								<el-tooltip
									:disabled="methodIsAccessGranted('communication.sms:delete')"
									placement="left"
									:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
								>
									<div class="flex items-center gap-1">
										<i class="gui-smartphone text-xl" />{{ $t('COMMUNCATIONS.HEADER.COMMUNICATIONS_DROPDOWN.SMS') }}
									</div>
								</el-tooltip>
							</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>

		<!-- SMS CREATE HEADER -->
		<div
			v-if="
				$route.name === 'communications-sms-create'
			"
			class="flex items-center gap-5 pr-5"
		>
			<q-button
				class="w-fit"
				@click="actionSetTestDialogVisible(true)"
				:disabled="!getIsReadyToBeSent"
			>
				{{ $t('SMS.TEST_SENDING_DIALOG.SEND_CTA') }}
			</q-button>
			<q-button
				:disabled="!getIsReadyToBeSent"
				@click="actionSetIsSendingDialogVisible(true)"
				class="basic-black w-40"
			>
				{{ $t('_COMMON.NEXT') }}
			</q-button>
		</div>

		<!-- BACK DIALOG -->
		<el-dialog
			v-model="showBackDialog"
		>
			<template #header>
				<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SAVE_ROUTER_GUARD_CONFIRM.TITLE') }}</span>
			</template>
			<span
				v-dompurify-html="$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SAVE_ROUTER_GUARD_CONFIRM.CONTENT')"
			/>
			<template #footer>
				<div class="flex w-full justify-end items-center gap-3">
					<span
						v-if="!getTempValue?.advancedBuilderWanted"
						class="hover:opacity-75 cursor-pointer"
						@click="showBackDialog = false"
					>
						{{ $t('_COMMON.CANCEL') }}
					</span>

					<span
						v-else
						class="hover:opacity-75 cursor-pointer"
						@click="methodHandleConfirm('quit')"
					>
						{{
							$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SAVE_ROUTER_GUARD_CONFIRM.NO_SAVE_AND_LEAVE')
						}}
					</span>
					<q-button
						v-if="!getTempValue?.advancedBuilderWanted"
						@click="methodReturnToTemplateListWithBasicEditor"
					>
						{{ $t('_COMMON.CONFIRM') }}
					</q-button>
					<q-button
						v-else
						@click="methodHandleConfirm('save_and_quit')"
					>
						{{
							$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SAVE_ROUTER_GUARD_CONFIRM.SAVE_AND_LEAVE')
						}}
					</q-button>
				</div>
			</template>
		</el-dialog>
		<popin-upsell
			v-model:is-pop-up-shown="computedIsUpsellPopinShown"
			source="Emails auto"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

import {
	gotvTemplateFR,
} from './mail/beefree_templates'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
		PopinUpsell: defineAsyncComponent(() => import('../packs/upsellPopin.vue')),
	},

	props: {
		tabs: {
			type: Array,
			required: true,
			default: () => [],
		},

		bulkEmailsPanelVisiblity: {
			type: Boolean,
			required: true,
			default: false,
		},

		mailCc: {
			type: Array,
			required: false,
			default: () => null,
		},
	},

	emits: ['update-visiblity-of-bulk-emails-panel'],

	data() {
		return {
			totalHit: 0,
			showTestEmailDialog: false,
			isUpsellPopinShown: false,
			showBackDialog: false,
		}
	},

	computed: {
		...mapGetters(['userConnected', 'getTestDialogVisible', 'getRecapDialogVisible', 'getIsReadyToBeSent']),
		...mapGetters('@briefs', ['briefs']),
		...mapGetters('@temp', ['getTempValue']),
		...mapGetters('@emailer', [
			'getEmailsBalance',
			'getObjectLength',
			'getContentCaractersCount',
			'isSendingSucceed',
			'getEmailerAccount',
			'getValidatedEmailerSenders',
			'getEmailerPrices',
			'getShowBulkEmailsDetails',
			'getIsReplyToValid',

		]),
		...mapGetters('@group', ['getEmailerAPIKey']),
		...mapGetters('@user', ['isEmailBoxConnected']),
		...mapGetters('@kpi', ['getKPIEmailsMissing', 'getKPISendingEmailsMissing']),

		// computedCost() {
		// 	const cost = this.mailCc ? 2 : 1
		// 	const total
		// 		= this.testEmailsList.length && this.testEmailsList.length > 0
		// 			? this.testEmailsList.length
		// 			: this.totalHit

		// 	return cost * total
		// },

		// computedGetEmailsBalanceLeft() {
		// 	return this.getEmailsBalance - this.computedCost
		// },

		computedIsSendButtonDisabled() {
			return (
				(!this.computedIsAnotherEmailerThanElasticEmail && !this.getValidatedEmailerSenders)
				|| (this.getTempValue?.editorTypology === 'basic' && this.getContentCaractersCount === 0)
				|| this.computedTotalHit === 0
				|| this.getObjectLength === 0
				|| this.isSendingSucceed === 'loading'
				|| !this.getIsReplyToValid
			)
		},

		computedIsUpsellAutomatically() {
			return Boolean(this.getEmailerAccount?.subscription?.automatic_upsell)
		},

		computedIsUpsellPopinShown: {
			get() {
				return this.isUpsellPopinShown
			},
			set(val) {
				this.isUpsellPopinShown = val
			},
		},

		computedIsAnotherEmailerThanElasticEmail() {
			return this.getEmailerAPIKey !== 'QOMON'
		},

		// computedGetEmailsBalanceLeftWithAutoUpsell() {
		// 	const total = this.testEmailsList.length > 0 ? this.testEmailsList.length : this.totalHit

		// 	const tierToUpsell = this.getEmailerPrices.tiers.find(
		// 		(tier) =>
		// 			tier.quantity >= total && tier.quantity > this.getEmailerAccount.credits_refill?.amount,
		// 	)

		// 	return tierToUpsell?.quantity - (total - this.getEmailsBalance)
		// },

		computedGetTemplate() {
			return gotvTemplateFR
		},

		// We don't want to block the send if the correct kpis are not retrieved
		computedTotalHit() {
			return this.getKPISendingEmailsMissing ? (this.getKPISendingEmailsMissing?.valids || 0) : this.totalHit
		},
	},

	mounted() {
		if (this?.getTempValue) {
			if (Object.prototype.hasOwnProperty.call(this?.getTempValue?.filterSelected, 'contact_ids')) {
				this.totalHit = this.getTempValue.filterSelected.contact_ids.length
			}
			else {
				this.totalHit = this.getTempValue.totalContactsSelected

				if (
					this.getTempValue.filterSelected.fields[10] !== 'SET'
					&& this.getKPIEmailsMissing.KpiReplies !== null
				) {
					this.totalHit -= this.getKPIEmailsMissing.KpiReplies[0].Doc_count
				}
			}
		}
	},

	methods: {
		methodIsAccessGranted,

		...mapActions(['actionSetTestDialogVisible',
			'actionSetRecapDialogVisible',
			'actionSetIsReadyToBeSent',
			'actionSetIsSendingDialogVisible',
		]),
		...mapActions('@emailer', [
			'actionSetTestEmailDialogVisibility',
			'actionSetRecapDialogVisibility',
			'actionUpdateBulkEmailsShowDetails',
			'actionRemoveSelectedTemplateTrigger',
			'actionSetEmailContentCharactersCount',
			'actionSetEmailObjectLength',
			'actionSetIsReplyToValid',
		]),
		...mapActions('@temp', ['actionSetNewTempValue']),

		methodGoTo(route) {
			const { name, query } = route

			this.$router.push({ name, query })
		},

		methodGoBack() {
			if (this.$route.name === 'communications-sms-create') {
				this.$router.go(-1)

				return
			}

			const isAdvancedEditorView
				= this.bulkEmailsPanelVisiblity
				&& this.isSendingSucceed !== 'success'
				&& this.getTempValue?.advancedBuilderWanted
			const isBasicEditorView = this.getTempValue?.editorTypology === 'basic'
			const isTemplateAndEditorViewChoice
				= !this.getTempValue?.selectedTemplate && !isBasicEditorView

			if (isTemplateAndEditorViewChoice) {
				this.$router.back()

				return
			}

			if (isAdvancedEditorView) {
				const isThereChanges
					= this.getTempValue?.contentBeenChanged && this.getTempValue.contentBeenChanged

				if (isThereChanges) {
					this.showBackDialog = true

					return
				}

				this.actionRemoveSelectedTemplateTrigger()
				this.$emit('update-visiblity-of-bulk-emails-panel', false)
				this.showBackDialog = false
				this.actionSetNewTempValue({
					...this.getTempValue,
					selectedTemplate: false,
				})
			}

			if (isBasicEditorView) {
				const isThereChanges = this.getContentCaractersCount > 0 || this.getObjectLength > 0

				if (isThereChanges) {
					this.showBackDialog = true

					return
				}

				this.$router.go(-1)
			}
		},

		methodHandleShowDetails() {
			this.actionUpdateBulkEmailsShowDetails()
		},

		methodHandleConfirm(order) {
			switch (order) {
				case 'quit':
					this.actionRemoveSelectedTemplateTrigger()
					this.$emit('update-visiblity-of-bulk-emails-panel', false)
					this.actionSetNewTempValue({
						...this.getTempValue,
						contentBeenChanged: false,
						advancedEditorTypology: 'advanced',
						selectedTemplateTypology: 'last_used_campaign',
						selectedTemplate: false,
					})
					this.actionRemoveSelectedTemplateTrigger()
					this.$emit('update-visiblity-of-bulk-emails-panel', false)
					this.showBackDialog = false
					break
				case 'save_and_quit':
					this.actionSetNewTempValue({
						...this.getTempValue,
						contentBeenChanged: false,
						editorSaveAutoTrigger: true,
						advancedEditorTypology: 'advanced',
						selectedTemplateTypology: 'last_used_campaign',
						selectedTemplate: false,
					})
					this.$emit('update-visiblity-of-bulk-emails-panel', false)
					this.showBackDialog = false

					break
			}
		},

		methodReturnToTemplateListWithBasicEditor() {
			this.actionSetNewTempValue({
				...this.getTempValue,
				advancedBuilderWanted: true,
				contentBeenChanged: false,
				resetBasicEditorData: true,
			})
			this.actionSetEmailContentCharactersCount(0)
			this.actionSetEmailObjectLength(0)
			this.actionSetIsReplyToValid(true)
			this.showBackDialog = false
			this.$emit('update-visiblity-of-bulk-emails-panel', false)
		},

		methodGoToContactBase() {
			const route = {
				name: 'contact-list',
				query: this.$route?.query || { mail: 'SET' },
			}
			const hasDemoTourAlreadyHappened = window.localStorage.getItem('is-demo-tour-activated')

			if (!hasDemoTourAlreadyHappened) route.query.demo_tour = 'bulk-emails'

			this.methodGoTo(route)
		},
	},
}
</script>
