import { IControl, Map } from 'maplibre-gl'
// @ts-ignore
import DOM from './dom.ts'
import { setBtnTooltip } from './control_tooltips.ts'
import { i18n } from '../../middlewares/i18nizer'

type SatelliteControlOptions = {
	lightTileLayerId: string
	satelliteTileLayerId: string
	onChange: Function
}

const defaultSatelliteControlOptions: SatelliteControlOptions = {
	satelliteTileLayerId: 'background-tiles-satellite',
	lightTileLayerId: 'background-tiles-light',
	onChange: () => {},
}
export class SatelliteControl implements IControl {
	_options: SatelliteControlOptions
	_currentTheme: string = 'light'

	// @ts-ignore
	_map: Map

	// @ts-ignore
	_controlContainer: HTMLElement

	// @ts-ignore
	_toggleSatelliteBtn: HTMLButtonElement

	constructor(options?: SatelliteControlOptions) {
		this._options = { ...defaultSatelliteControlOptions, ...(options || {}) }
	}

	onAdd(map: Map) {
		this._map = map
		this._controlContainer = DOM.create('div', 'maplibregl-ctrl maplibregl-ctrl-group')
		this._setupUI()
		return this._controlContainer
	}

	onRemove() {
		DOM.remove(this._controlContainer)
		// @ts-ignore
		this._map = null
	}

	_setupUI() {
		const button = (this._toggleSatelliteBtn = DOM.create(
			'button',
			'gui-satellite_view action',
			this._controlContainer
		))
		button.type = 'button'
		this._setBtnTitle('Enter')

		this._toggleSatelliteBtn.addEventListener('click', () => {
			this._handleClick()
		})
	}

	_setBtnTitle(title: String) {
		const str = i18n.global.t(`VENDORS.MAPLIBRE.LOCALE["SatelliteControl.${title}"]`)
		setBtnTooltip(this._toggleSatelliteBtn, str)
	}

	_handleClick() {
		if (this._currentTheme === 'satellite') {
			this._currentTheme = 'light'
			this._toggleSatelliteBtn.classList.remove('active')

			this._map.setLayoutProperty(this._options.lightTileLayerId, 'visibility', 'visible')
			this._map.setLayoutProperty(this._options.satelliteTileLayerId, 'visibility', 'none')
			this._options.onChange('light')

			this._setBtnTitle('Enter')
		} else {
			this._currentTheme = 'satellite'
			this._toggleSatelliteBtn.classList.add('active')

			this._map.setLayoutProperty(this._options.satelliteTileLayerId, 'visibility', 'visible')
			this._map.setLayoutProperty(this._options.lightTileLayerId, 'visibility', 'none')
			this._options.onChange('satellite')

			this._setBtnTitle('Exit')
		}
	}
}
