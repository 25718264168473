<template>
	<div class="popovers-container">
		<auto-send-editor
			v-if="!help"
			:indices="indices"
			:sections="sections"
			@closePopover="closePopover"
			@help="help = true"
		></auto-send-editor>
		<auto-send-help v-else @closePopover="help = false"></auto-send-help>
	</div>
</template>

<script>
import AutoSendEditor from './auto-send-editor.vue'
import AutoSendHelp from './auto-send-help.vue'

export default {
	components: {
		AutoSendEditor,
		AutoSendHelp,
	},

	props: {
		indices: Object,
		sections: Array,
	},
	emits: ['closePopover'],

	data: function () {
		return {
			help: false,
		}
	},

	methods: {
		closePopover(sections) {
			this.$emit('closePopover', sections)
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/form/auto-send/auto-send-popover.scss';
</style>
