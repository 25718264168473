<template>
	<div ref="window">
		<div class="flex justify-between flex-wrap p-8 bg-white z-10 my-1 sticky -top-2 opacity-100">
			<el-input
				v-model="searchInput"
				:placeholder="$t('APP.TAGS.SEARCH')"
				clearable
				:prefix-icon="Search"
				style="max-width: 28rem"
			>
				<template #prepend>
					<i class="gui-search" />
				</template>
			</el-input>
			<q-button
				ghost
				class="create-tag-btn"
				@click="addTag"
			>
				{{ $t('APP.TAGS.ADD_TAG_BUTTON') }}
			</q-button>
		</div>
		<div
			v-show="getIsLoading"
			class="flex justify-center items-center w-full py-10"
		>
			<loader size="15%" />
		</div>
		<table
			v-show="!getIsLoading"
			class="w-full mt-1"
		>
			<thead class="bg-white uppercase text-gray-dark">
				<th style="width: 60px">
					<div
						class="flex flex-col justify-center h-full flex-grow pointer"
						@click.stop="methodSelectAllTags(!areAllSelected)"
					>
						<i
							class="gui-select_all text-xl"
							:class="{ 'text-green-main': areAllSelected && nbOfSelectedTag > 0 }"
						/>
					</div>
				</th>
				<template v-if="nbOfSelectedTag === 0">
					<th
						class="text-left font-normal uppercase"
						style="max-width: 40%"
					>
						{{ $t('SETTINGS.TAGS.TITLE') }}
					</th>
					<th>
						<div class="flex">
							<span class="font-normal uppercase flex-grow">
								{{ $t('SETTINGS.TAGS.NUMBER_OF_USES') }}
							</span>
							<div style="width: 20%" />
						</div>
					</th>
					<th />
				</template>
				<template v-else>
					<th>
						<div class="flex items-center">
							<span class="lowercase">{{
								$tc('APP.TAGS.SELECTED_DOCUMENTS', nbOfSelectedTag, { count: nbOfSelectedTag })
							}}</span>
							<q-button
								link
								class="ml-4 capitalize"
								style="margin-top: 0.1rem"
								@click="openDeleteModalSeveral"
							>
								{{ $t('APP.TAGS.DELETE') }}
							</q-button>
						</div>
					</th>
					<th />
					<th />
				</template>
			</thead>
			<tbody>
				<tr
					v-for="(tag, index) in sortedTags"
					:key="index"
				>
					<td style="width: 60px">
						<q-check
							:checked="tag.selected ? true : false"
							:value="tag.selected"
							large
							@change="($event) => methodHandleTagSelectionChange($event, tag)"
						/>
					</td>
					<td style="max-width: 150px">
						<span
							v-tooltip
							class="w-full align-middle"
						>{{ tag.name }}</span>
					</td>
					<td style="min-width: 250px">
						<div class="flex justify-center">
							<router-link
								:to="{ name: 'contact-list', query: { tags: tag.name } }"
								class="flex justify-center hover:underline flex-grow"
							>
								{{ tag.appearance_count }}
							</router-link>
							<div style="width: 20%" />
						</div>
					</td>
					<td>
						<div class="flex items-center justify-end pr-4">
							<span
								class="flex items-center p-1 pointer text-lg"
								@click="renameTag(tag, $event)"
							>
								<i class="gui-edit text-green-main" />
							</span>
							<i
								class="bg-gray-light mx-2"
								style="width: 1px; height: 24px"
							/>
							<span
								class="flex items-center p-1 pointer text-lg"
								@click="deleteTag(tag, $event)"
							>
								<i class="gui-delete text-gray-dark" />
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<template v-if="!getIsLoading && sortedTags.length === 0">
			<div
				v-if="searchInput.length === 0"
				class="center mt-6"
			>
				<div class="text-gray-dark">
					{{ $t('APP.TAGS.EMPTY_STATE') }}
				</div>
				<div class="text-gray-dark my-4">
					{{ $t('APP.TAGS.INFORMATION_TAG') }}
				</div>
				<a
					class="q-link bold"
					@click="addTag()"
				>
					{{ $t('APP.TAGS.ADD_FIRST_TAG') }}
				</a>
			</div>
			<div
				v-else
				class="center text-gray-dark mt-6"
			>
				{{ $t('APP.TAGS.TAG_NOT_FOUND') }}
			</div>
		</template>
		<hr style="height: 3rem; border: none">

		<!-- MODALS -->
		<modal
			:is-active="!!editModal"
			:reset-action="closeModal"
			overflow="auto"
			small
		>
			<template #title>
				<div class="bg-white pt-2 pl-4 font-bold text-xl">
					{{ t[editModal] }}
				</div>
			</template>
			<template #content>
				<div class="px-4 pt-4">
					<el-input
						v-model="temporaryTag.name"
						maxlength="50"
						show-word-limit
						:placeholder="$t('APP.TAGS.PLACEHOLDER')"
					/>
					<transition
						v-if="alreadyExists"
						name="fade-in"
						mode="out-in"
					>
						<div class="text-sm pt-2">
							<span class="qi-close" />
							<span>{{ $t('APP.TAGS.ALREADY_EXISTS') }}</span>
						</div>
					</transition>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-end gap-x-1 pt-4">
					<q-button
						ghost
						@click="closeModal"
					>
						{{ $t('APP.TAGS.CANCEL') }}
					</q-button>
					<q-button
						:disabled="alreadyExists"
						@click="editModal === 'addTag' ? addCurrentTag() : renameCurrentTag()"
					>
						{{ editModal === 'addTag' ? $t('APP.TAGS.CREATE') : $t('APP.TAGS.SAVE') }}
					</q-button>
				</div>
			</template>
		</modal>

		<modal
			:is-active="!!deleteModal"
			:reset-action="closeModal"
			overflow="auto"
			small
		>
			<template #title>
				<div class="bg-white pt-2 pl-4 font-bold text-xl">
					{{ $tc('APP.TAGS.DELETION', nbOfSelectedTag) }}
				</div>
			</template>
			<template #content>
				<template v-if="deleteModal === 'delete'">
					<div class="pt-6 px-4 pb-3">
						<span>{{ t.confirmDeletionTags(1) }}&nbsp;</span>
						<b>{{ temporaryTag.name }}</b> ?
					</div>
					<div class="px-4">
						{{ t.impactedContactsFirst }} {{ t.impactedContactsSecond }}
						{{ t.impactedContactsThird }}
					</div>
				</template>
				<template v-else>
					<div class="pt-6 px-4">
						<span v-if="nbOfSelectedTag < 6">
							<span>{{ t.confirmDeletionTags(nbOfSelectedTag) }}&nbsp;</span>
							<span
								v-for="(tag, index) in sortedTags.filter((tag) => tag.selected)"
								:key="index"
							>
								<b>
									{{ tag.name }}
									<span v-if="index !== nbOfSelectedTag - 1">, </span>
								</b>
							</span>
							{{ $t('APP.TAGS.INTERROGATION') }}
						</span>
						<span v-else>
							<span>{{ t.confirmDeletionTagsCount(nbOfSelectedTag) }}</span>
						</span>
					</div>
					<div class="px-4 pt-2">
						{{ t.impactedContactsSeveralFirst() }} {{ t.impactedContactsSeveralSecond() }}
						{{ t.impactedContactsSeveralThird() }}
					</div>
				</template>
			</template>

			<template #footer>
				<div class="flex justify-end gap-x-1 mt-4">
					<q-button
						ghost
						@click="closeModal"
					>
						{{ $t('APP.TAGS.CANCEL') }}
					</q-button>
					<q-button @click="deleteModal === 'delete' ? deleteCurrentTag() : deleteSelected()">
						{{ $t('APP.TAGS.DELETE') }}
					</q-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import tooltipMixin from '/src/mixins/tooltipMixin.js'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

import { Search } from '@element-plus/icons-vue'

export default {
	components: {
		modal: defineAsyncComponent(() => import('src/components/general/modal')),
		Loader: defineAsyncComponent(() => import('src/components/general/loader.vue')),
	},
	mixins: [tooltipMixin],
	data() {
		return {
			searchInput: '',
			editModal: null,
			deleteModal: null,
			temporaryTag: {},
			originalTag: {},

			areAllSelected: false,
			nbOfSelectedTag: 0,
		}
	},

	computed: {
		...mapGetters('@tags', ['getTags', 'getIsLoading']),
		t() {
			const prefix = 'APP.TAGS'
			return {
				addTag: this.$t(`${prefix}.ADD_TAG`),
				renameTag: this.$t(`${prefix}.RENAME_TAG`),
				error: {
					get: this.$t(`${prefix}.ERROR.GET`),
					add: this.$t(`${prefix}.ERROR.ADD`),
					delete: this.$t(`${prefix}.ERROR.DELETE`),
					deleteSeveral: this.$t(`${prefix}.ERROR.DELETE_SEVERAL`),
					rename: this.$t(`${prefix}.ERROR.RENAME`),
				},
				success: {
					add: this.$t(`${prefix}.SUCCESS.ADD`),
					delete: this.$t(`${prefix}.SUCCESS.DELETE`),
					rename: this.$t(`${prefix}.SUCCESS.RENAME`),
					deleteSeveral: (count) =>
						this.$tc(`${prefix}.SUCCESS.DELETE_SEVERAL`, count, { count: count }),
				},
				confirmDeletionTags: (count) =>
					this.$tc(`${prefix}.CONFIRM_DELETION_TAG`, count, { count: count }),
				confirmDeletionTagsCount: (count) =>
					this.$tc(`${prefix}.CONFIRM_DELETION_TAG_COUNT`, count, { count: count }),
				impactedContactsFirst: this.temporaryTag.appearance_count
					? this.$tc(`${prefix}.IMPACTED_CONTACTS_FIRST`, 1)
					: '',
				impactedContactsSecond: this.$tc(
					`${prefix}.IMPACTED_CONTACTS_SECOND`,
					this.temporaryTag.appearance_count,
					{
						count: this.temporaryTag.appearance_count,
					},
				),
				impactedContactsThird: this.temporaryTag.appearance_count
					? this.$tc(`${prefix}.IMPACTED_CONTACTS_THIRD`, 1)
					: '',
				impactedContactsSeveralFirst: () => {
					const impacted = this.sortedTags.reduce(
						(total, tag) => (total += tag.selected ? tag.appearance_count : 0),
						0,
					)
					return impacted ? this.$tc(`${prefix}.IMPACTED_CONTACTS_FIRST`, this.nbOfSelectedTag) : ''
				},
				impactedContactsSeveralSecond: () => {
					const impacted = this.sortedTags.reduce(
						(total, tag) => (total += tag.selected ? tag.appearance_count : 0),
						0,
					)
					return this.$tc(`${prefix}.IMPACTED_CONTACTS_SECOND`, impacted, {
						count: impacted,
					})
				},
				impactedContactsSeveralThird: () => {
					const impacted = this.sortedTags.reduce(
						(total, tag) => (total += tag.selected ? tag.appearance_count : 0),
						0,
					)
					return impacted ? this.$tc(`${prefix}.IMPACTED_CONTACTS_THIRD`, this.nbOfSelectedTag) : ''
				},
			}
		},
		sortedTags() {
			const clonedTags = cloneDeep(this.getTags)

			return clonedTags
				.filter(
					(tag) => tag.name.toLowerCase().indexOf((this.$route.query.b || '').toLowerCase()) > -1,
				)
				.sort((a, b) => b.appearance_count - a.appearance_count)
				.map((tag) => {
					tag.selected = tag.selected || false
					return tag
				})
		},
		computedTagsSelected() {
			return this.sortedTags.filter((tag) => tag.selected)
		},
		alreadyExists() {
			return (
				this.getTags.find((tag) => {
					return tag.name === this.temporaryTag.name && tag.name !== this.originalTag.name
				}) !== undefined
			)
		},
	},
	watch: {
		searchInput: {
			handler: debounce(function () {
				this.methodSelectAllTags(false)
				const { query, name } = this.$route
				if (query?.b !== this.searchInput) {
					this.$router.replace({
						name: name,
						query: {
							...query,
							b: this.searchInput,
						},
					})
				}
			}, 100),
		},
	},
	mounted() {
		this.actionGetTags().then((res) => {
			this.message(res)
		})

		// Close modals when pressing esc
		const self = this
		window.addEventListener('keyup', function (event) {
			if (event.keyCode === 27) {
				self.editModal = null
				self.deleteModal = null
			}
		})

		this.searchInput = this.$route.query?.b || ''
	},
	methods: {
		...mapActions('@tags', [
			'actionGetTags',
			'actionAddTag',
			'actionDeleteTag',
			'actionRenameTag',
			'actionDeleteTags',
		]),
		methodHandleTagSelectionChange(selected, tag) {
			tag.selected = !tag.selected
			this.areAllSelected = this.sortedTags.every((tag) => tag.selected === true)
			this.nbOfSelectedTag += selected?.target?.checked ? 1 : -1
		},
		methodSelectAllTags(selectAll) {
			this.areAllSelected = selectAll
			this.sortedTags.forEach((tag) => {
				tag.selected = selectAll
			})
			this.nbOfSelectedTag = selectAll * this.sortedTags.length
		},
		addTag() {
			this.deleteModal = null
			this.originalTag = {}
			this.editModal = 'addTag'
		},
		renameTag(tag) {
			this.deleteModal = null
			this.temporaryTag = cloneDeep(tag)
			this.originalTag = cloneDeep(tag)
			this.editModal = 'renameTag'
		},
		closeModal() {
			this.temporaryTag = {}
			this.originalTag = {}
			this.editModal = null
			this.deleteModal = null
		},
		deleteTag(tag) {
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = cloneDeep(tag)
			this.deleteModal = 'delete'
		},
		openDeleteModalSeveral() {
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = {}
			this.deleteModal = 'deleteSeveral'
		},
		deleteSelected() {
			let selectedTags = this.sortedTags.filter((tag) => tag.selected)
			if (selectedTags.length === 1) {
				selectedTags = selectedTags[0]
			}
			this.actionDeleteTags(selectedTags).then((res) => {
				this.message(res)
			})
			this.methodSelectAllTags(false)
			this.editModal = null
			this.deleteModal = null
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = {}
		},
		deleteCurrentTag() {
			this.actionDeleteTag(this.temporaryTag).then((res) => {
				this.message(res)
			})
			this.methodSelectAllTags(false)

			this.editModal = null
			this.deleteModal = null
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = {}
		},
		addCurrentTag() {
			this.actionAddTag(this.temporaryTag).then((res) => {
				this.message(res)
			})
			this.methodSelectAllTags(false)
			this.editModal = null
			this.deleteModal = null
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = {}
		},
		renameCurrentTag() {
			this.actionRenameTag({ temporaryTag: this.temporaryTag, originalTag: this.originalTag }).then(
				(res) => {
					this.message(res)
				},
			)
			this.methodSelectAllTags(false)
			this.editModal = null
			this.deleteModal = null
			this.editModal = null
			this.originalTag = {}
			this.temporaryTag = {}
		},

		// Generate message for all possible actions
		message({ type, action, number }) {
			if (type === 'success') {
				if (number) {
					this.$message({
						type,
						message: this.t.success[action](number),
						showClose: true,
					})
				}
				else {
					this.$message({
						type,
						message: this.t.success[action],
						showClose: true,
					})
				}
			}
			else if (type === 'error') {
				this.$message({
					type,
					message: this.t.error[action],
					showClose: true,
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';
.create-tag-btn {
	align-self: center;
	line-height: normal;
	height: 2.2rem;
	padding: 0 2rem;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

table {
	thead {
		height: 52px;
		background-color: white;
		border-bottom: 1px solid #eeeeed;
	}

	tbody {
		width: min-content;
		overflow-y: auto;
		overflow-x: hidden;

		tr {
			border-bottom: 1px solid #eeeeed;
			height: 42px;

			&:nth-child(2n + 1) {
				background-color: white;
			}

			&:nth-child(2n) {
				background-color: rgba($color: white, $alpha: 0.6);
			}
		}
	}
}
</style>
