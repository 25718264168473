/**
 * @store @briefs
 */
import formStore from '../../models/forms_store'
import Module from './ModuleFactory'
import { cloneDeep } from 'lodash'

/**
 * Sort by id
 */
const sortByOldestId = (array) => {
	array.sort((a, b) => {
		if (a.id < b.id) return 1
		else return -1
	})
}

export const state = {
	briefs: [],
}
export const mutations = {
	ADD_BRIEF(state, payload) {
		if (!payload.id) {
			state.briefs.push({
				...payload,
				id: state.briefs.length + 1,
			})
		} else {
			state.briefs.forEach((brief) => {
				if (brief.id === payload.id) {
					brief = { ...brief, ...payload }
				}
			})
		}
	},
}

export const actions = {
	/**
	 * Retrive briefs from the backend
	 * @action actionFetchBriefs
	 */
	async actionFetchBriefs({ commit }) {
		const briefs = await formStore.getFormByName('BRIEF')
		sortByOldestId(briefs)
		commit('SET_BRIEFS', briefs)
	},

	async actionAddBrief({ dispatch, state }, payload) {
		const futursBriefs = [...state.briefs, payload]
		sortByOldestId(futursBriefs)
		const res = await dispatch('saveBrief', futursBriefs)
		return res
	},

	async updateBrief({ dispatch, state }, payload) {
		const futureBriefsPayload = !payload.id
			? state.briefs
			: state.briefs.map((brief) => {
					if (brief.id !== payload.id) return brief
					else return payload
			  })
		sortByOldestId(futureBriefsPayload)
		const res = await dispatch('saveBrief', futureBriefsPayload)
		return res
	},

	async saveBrief({ commit }, payload) {
		const briefPayload = payload.map((brief) => {
			brief.name = 'BRIEF'
			return brief
		})
		const res = await formStore.saveFormsWithNames(briefPayload, 'BRIEF')
		commit('SET_BRIEFS', res.forms || [])
		return res.forms
	},

	async deleteBriefs({ dispatch, state }, ids) {
		const futureBriefs = state.briefs.filter((brief) => !ids.includes(brief.id))
		const res = await dispatch('saveBrief', futureBriefs)
		return res
	},
}

const getters = {
	getBriefs: (state) => state.briefs,
}

export const briefs = new Module({
	state,
	getters,
	actions,
	mutations,
})
