<template>
	<div
		style="background: #fbf8f6"
		class="qview"
	>
		<div
			v-if="!$route.name.includes('global-notification')"
			id="membersHeader"
			class="q-header border-none shadow-sm z-49 flex-col"
			:style="[
				!$route.name.includes('invitations') &&
					!$route.name.includes('global-notification') &&
					!$route.name.includes('waiting-users') &&
					!computedIsTeamsViewNotEnabled
					? { minHeight: '120px' }
					: { minHeight: '40px' },
			]"
		>
			<div class="q-fullwidth">
				<transition name="slide-down">
					<div
						v-if="focused >= 1 && getAccessStatusMembersTeams === 'enabled'"
						class="members-selected-cta flex-wrap"
					>
						<div class="row gap-2">
							<h3
								class="action qi-close qi-2x"
								@click="deselectAll"
							>
&nbsp;
							</h3>
							<h3>{{ t.selectedLabel(focused) }}</h3>
							<div class="gap" />
							<q-button
								v-if="
									displayMembers &&
										!isEditingTeam &&
										userConnected?.role_data?.access?.includes('members:delete')
								"
								icon="trash"
								class="ghost"
								@click="openDeleteMemberDialog()"
							>
								{{ $t('_COMMON.DELETE') }}
							</q-button>
							<q-button
								v-if="
									displayMembers &&
										!isEditingTeam &&
										userConnected?.role_data?.access?.includes('members.teams:delete')
								"
								class="ghost flex items-center gap-1"
								@click="onClickTriggerPopulateTeamDialog"
							>
								<i class="qi-add-team" />
								{{ $t('USER_MANAGEMENT.USER_LIST.ASSIGN_TO_A_TEAM') }}
							</q-button>
							<q-button
								v-if="
									displayMembers &&
										!isEditingTeam &&
										userConnected?.role_data?.access?.includes('members.teams:delete')
								"
								class="ghost flex items-center gap-1"
								@click="onClickCreateTeam"
							>
								<i class="gui-group" />
								{{ t.addTeam }}
							</q-button>
							<q-button
								v-if="
									displayTeams &&
										focused > 0 &&
										userConnected?.role_data?.access?.includes('communication:delete')
								"
								icon="notifications"
								ghost
								@click="methodSentTeamsNotifications"
							>
								{{ $tc('USER_MANAGEMENT.NOTIFY_TEAMS', focused) }}
							</q-button>
							<q-button
								v-if="
									displayTeams && userConnected?.role_data?.access?.includes('members.teams:delete')
								"
								icon="trash"
								class="ghost"
								@click="openDeleteDialog()"
							>
								{{ t.delete }}
							</q-button>
							<div class="q-h-spacer-l" />
						</div>
					</div>
				</transition>
				<div class="w-full flex flex-col xl:flex-row xl:items-center justify-between gap-3 xl:gap-0">
					<div class="flex flex-row items-center gap-3 sm:gap-10">
						<template
							v-for="(tab, index) in computedGetHeaderList"
							:key="index"
						>
							<tab-with-number
								v-if="tab.isAuthorized"
								:title="tab.title"
								:selected="$route.name === tab.name"
								:status-pack="tab.status"
								:badge="tab.badge"
								:tag="tab.tag"
								:no-opacity="tab.noOpacity"
								:count="tab.count"
								:value="tab.value"
								font-size="1.3rem"
								class="py-3 cursor-pointer"
								@tab-click="methodGoTo(tab)"
							/>
						</template>
					</div>

					<div
						class="flex flex-wrap gap-3 "
						:class="{'opacity-0 pointer-events-none': focused > 0}"
					>
						<div
							v-if="userConnected?.role_data?.access?.includes('communication:delete')"
						>
							<packer
								:no-icon="getAccessStatusGlobalNotification === 'promoted'"
								:status="getAccessStatusGlobalNotification"
							>
								<q-button
									ghost
									icon=""
									@click="methodGoToGlobalNotification"
								>
									<i
										v-if="getAccessStatusGlobalNotification === 'promoted'"
										class="gui-lock relative mr-2"
									/>
									{{ $t('USER_MANAGEMENT.NOTIFY_YOUR_MEMBERS') }}
								</q-button>
							</packer>
							<popup-packer ref="globalnotification" />
						</div>
						<div v-else>
							<el-tooltip
								placement="top"
								:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
							>
								<el-button
									disabled
									class="rounded-full h-10"
								>
									<i
										v-if="getAccessStatusGlobalNotification === 'promoted'"
										class="gui-lock relative mr-2"
									/>
									{{ $t('USER_MANAGEMENT.NOTIFY_YOUR_MEMBERS') }}
								</el-button>
							</el-tooltip>
						</div>
						<router-link
							v-if="displayMembers && getAccessStatusMembersInvitations === 'enabled' && methodIsAccessGranted('members.invitation:invite')"
							:to="{ name: 'invitations' }"
						>
							<q-button :ghost="is_edited || isEditingTeam">
								<span class="flex">
									<i class="gui-team relative mr-2" />
									{{ t.addUser }}
								</span>
							</q-button>
						</router-link>
						<q-button
							v-if="
								!displayMembers &&
									getAccessStatusMembersTeams === 'enabled' &&
									userConnected?.role_data?.access?.includes('members.teams:delete')
							"
							:ghost="is_edited || isEditingTeam"
							@click="add"
						>
							<span class="flex">
								<i class="gui-group relative mr-2" />
								{{ t.addTeam }}
							</span>
						</q-button>
					</div>
				</div>
				<div
					v-if="
						!$route.name.includes('invitations') &&
							!$route.name.includes('global-notification') &&
							!$route.name.includes('waiting-users') &&
							!computedIsTeamsViewNotEnabled
					"
					class="q-v-spacer"
				/>
				<div
					v-if="
						!$route.name.includes('invitations') &&
							!$route.name.includes('global-notification') &&
							!$route.name.includes('waiting-users') &&
							!computedIsTeamsViewNotEnabled
					"
					class="flex flex-row items-start justify-between flex-wrap gap-3 w-full"
				>
					<div
						class="flex gap-x-4 w-full"
					>
						<AnimatedSearchBar
							v-model="querySearch"
							:placeholder="displayMembers ? t.searchUser : t.searchTeam"
							container-id="members-filters"
						/>
						<div
							v-if="getAccessStatusMembersTeams === 'enabled' && $route.name.includes('list')"

							class="flex flex-row justify-between items-center flex-wrap pb-2 w-full"
						>
							<div class="flex gap-x-2 gap-y-2">
								<AvailibilitiesDropdown
									v-if="$route.name.includes('list')"
									:placeholder="$t('USER_MANAGEMENT.FILTERS.AVAILABILITIES_FILTER_PLACEHOLDER')"
									@change="methodSetAvailibilitiesFilters"
									@none-availabilities-change="methodSetSearchForNoneAvailabilities"
								/>
								<RolesDropdown
									@change="selectedRolesFilters = $event"
									:roles="getRolesList"
									:placeholder="$t('USER_MANAGEMENT.FILTERS.ROLE_FILTER_PLACEHOLDER_2')"
								/>
								<CitiesDropdown
									@change="selectedCitiesFilter = $event"
									:cities="computedGetCitiesListFromUsers"
									:placeholder="$t('USER_MANAGEMENT.FILTERS.CITIES_FILTER_PLACEHOLDER_2')"
								/>
								<div
									v-if="getAccessStatusMembersTeams === 'enabled'"
									class="flex flex-row items-center cursor-pointer hover:bg-gray-100 rounded-xl py-1 px-2"
									@click="filterWithoutTeams = !filterWithoutTeams"
								>
									<div>
										<q-check
											:checked="filterWithoutTeams"
											class="text-sm"
											@change="filterWithoutTeams = !filterWithoutTeams"
										/>
									</div>
									<span
										class="text-sm"
									>{{ $t('USER_MANAGEMENT.FILTERS.WITHOUT_TEAMS') }}</span>
								</div>
							</div>
							<!-- SELECT ALL START -->
							<div
								v-if="getAccessStatusMembersTeams === 'enabled' && $route.name.includes('list')"
								class="flex flex-row gap-x-1 items-center cursor-pointer hover:bg-gray-100 rounded-xl py-1 px-2"
								:class="{ 'bg-gray-100': filterSelectAll }"
								@click="filterSelectAll = !filterSelectAll"
							>
								<i
									class="gui-select_all text-2xl"
								/>
								<span class="text-sm">{{ $t('USER_MANAGEMENT.FILTERS.MEMBERS_SELECT_ALL') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template
			v-if="
				$route.name.includes('global-notification') ||
					$route.name.includes('invitations') ||
					$route.name.includes('waiting-users')
			"
		>
			<global-notification
				v-if="$route.name.includes('global-notification')"
				ref="globalNotification"
				class="pt-12"
				:selected-teams="teamsForNotification"
			/>
			<invitations v-if="$route.name.includes('invitations')" />
			<waiting-users v-if="$route.name.includes('waiting-users')" />
		</template>
		<div
			v-else
			class="qview-main"
		>
			<div
				style="min-width: 66.66%"
				class="q-hide-scroll lg:w-4/6 w-full"
				:class="[{ 'lg:w-full': getAccessStatusMembersTeams === 'promoted' && !displayMembers }]"
			>
				<div class="q-hide-scroll-content">
					<transition name="slide-down">
						<div
							v-if="!userConnected.gdpr_accepted && displayMembers"
							id="gdpr"
						>
							<div class="q-header q-col">
								<div
									id="close"
									class="action qi-close q-is-secondary qi-2x"
									@click="acceptGDPR(false)"
								/>
								<div class="q-row nowrap q-p">
									<div class="qi-infos qi-2x q-is-info" />
									<div class="q-h-spacer" />
									<span>{{ t.gdpr.text }}</span>
								</div>
								<div class="q-v-spacer" />
								<div class="right q-fullwidth">
									<q-button
										link
										@click="acceptGDPR()"
									>
										{{ t.gdpr.confirm }}
									</q-button>
								</div>
							</div>
						</div>
					</transition>

					<div :class="isEditingTeam ? 'cursor-pointer' : ''">
						<div
							v-if="isEditingTeam && $route.name === 'users-list'"
							class="items-center flex justify-center sticky top-0 py-2 z-10 bg-white"
						>
							<div
								class="w-full mx-5 flex flex-row search-input-container flex items-center px-4 border-gray-500 hover:border-pink-main rounded-full"
							>
								<i class="gui-search text-2xl mr-2 flex items-center font-bold" />
								<input
									v-model="querySearch"
									class="h-full w-full q-search fix-input-search focus:outline-none"
									:placeholder="displayMembers ? t.searchUser : t.searchTeam"
								>
							</div>
						</div>

						<users
							v-if="displayMembers"
							ref="users"
							v-model:users-populated-by-teams="computedUsersPopulatedByTeams"
							v-model:users-ids-selected="computedUsersIdsSelected"
							:watcher="focused"
							:availibility-filters="availibilities"
							:without-availabilities="searchForNoneAvailabilities"
							:is-editing-teams-from-members-tab="
								(isEditingTeam || usersSelected.length > 1) && $route.name === 'users-list'
							"
							:filter-without-teams="filterWithoutTeams"
							:filter-select-all="filterSelectAll"
							:selected-roles-filters="selectedRolesFilters"
							:selected-cities-filter="selectedCitiesFilter"
							@users-selected="selectedUsersData"
							@change="selectedUsers"
						/>

						<teams
							v-else-if="displayTeams"
							ref="teams"
							v-model:filtered-teams="allTeams"
							:watcher="focused"
							:max-team-lead-display="maxTeamLeadDisplay"
							:method-get-team-lead="methodGetTeamLead"
							:filtered-team-users="filteredTeamUsers"
							:method-get-max-display-users="methodGetMaxDisplayUsers"
							:method-get-type-link="methodGetTypeLink"
							@change="selectedTeams"
							@get-teams="selectedTeamsData"
						/>
					</div>
				</div>
			</div>
			<div class="flex flex-grow bg-white relative">
				<div class="q-is-desktop q-hide-scrol h-full w-full">
					<div
						v-if="displayMembers && focused > 1"
						class="q-bar-right rounded q-hide-scroll-content q-container bg-white p-10 overflow-auto w-full"
					>
						<SelectedUserList
							:users="usersSelected"
							@unselect-user="methodUnselectUser"
						/>
					</div>

					<div
						v-if="displayMembers || getAccessStatusMembersTeams !== 'promoted' && !showUserDetail"
						class="q-bar-right rounded q-hide-scroll-content q-container bg-white p-10 overflow-auto"
					>
						<user-kpi class="q-hide-scroll-content" />
					</div>

					<transition name="slide">
						<invite-user
							v-if="inviting"
							class="q-hide-scroll-content"
						/>
					</transition>
					<transition name="slide">
						<user-detail
							v-if="showUserDetail"
							:switch-count="countSwitch"
							:is-tablet-format="false"
							class="q-hide-scroll-content"
						/>
					</transition>
					<transition name="slide">
						<edition
							v-if="is_edited"
							class="q-hide-scroll-content"
						/>
					</transition>
					<transition name="slide">
						<team-detail
							v-if="showDetail"
							class="q-hide-scroll-content"
							:active-team="activeTeam"
							@change="selectedTeams"
						/>
					</transition>
					<transition name="slide">
						<team-edition
							v-if="isEditingTeam"
							class="q-hide-scroll-content"
							:active-team="activeTeam"
							@team-edited="refreshTeamsAfterUpdate"
							@change="selectedTeams"
						/>
					</transition>
				</div>
			</div>
		</div>
		<div class="q-up-to-tablet q-hide-scrol">
			<transition name="slide">
				<invite-user
					v-if="inviting"
					class="q-hide-scroll-content"
				/>
			</transition>
			<transition name="slide">
				<user-detail
					v-if="showUserDetail"
					:switch-count="countSwitch"
					:is-tablet-format="true"
					:is-reset="true"
				/>
			</transition>
			<transition name="slide">
				<edition v-if="is_edited" />
			</transition>
			<transition name="slide">
				<team-detail
					v-if="showDetail"
					:active-team="activeTeam"
					@change="selectedTeams"
				/>
			</transition>
			<transition name="slide">
				<team-edition
					v-if="isEditingTeam"
					:active-team="activeTeam"
					@team-edited="refreshTeamsAfterUpdate"
					@change="selectedTeams"
				>
					<div
						v-if="isEditingTeam && $route.name === 'users-list'"
						class="mt-2 search-input-container flex items-center px-4 border-gray-500 hover:border-pink-main rounded-full"
					>
						<i class="gui-search text-2xl mr-2 flex items-center font-bold" />
						<input
							v-model="querySearch"
							class="h-full w-80 q-search fix-input-search focus:outline-none"
							:placeholder="displayMembers ? t.searchUser : t.searchTeam"
						>
					</div>
				</team-edition>
			</transition>
		</div>

		<!-- populate team with members dialog -->
		<el-dialog
			@close="teamToPopulate = null"
			@closed="teamToPopulate = null"
			v-model="showPopulateTeamDialog"
		>
			<template #header>
				<span class="text-2xl font-title">{{ $t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.TITLE') }}</span>
			</template>
			<div class="flex flex-col gap-10 pt-7">
				<Collapse
					:class="isCollapseDisplay ? 'hover:bg-gray-100 rounded-xl px-2 py-1' : 'px-2 py-1'"
					is-first-content-visible
					:is-collapsable="isCollapseDisplay"
					custom-class="font-title text-lg text-black"
					:title="$t(`USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.SHOW_USERS`)"
				>
					<div
						class="flex flex-wrap items-center"
						ref="userMiniCardContainer"
					>
						<UserMiniCard
							v-for="(aUser, aUserIndex) in usersSelected"
							ref="aUserMiniCard"
							:key="aUserIndex"
							:a-user="aUser"
						/>
					</div>
				</Collapse>
				<div class="flex flex-col gap-3 px-2">
					<span class=" text-black font-title text-lg">
						{{ $t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.SELECT_A_TEAM') }}
					</span>
					<el-select
						popper-class="rounded-xl"
						v-model="teamToPopulate"
						:placeholder="$t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.SELECT_A_TEAM_PLACEHOLDER')"
						clearable
						value-key="id"
						filterable
						style="width: 100%"
					>
						<el-option
							class="rounded-lg mx-2"
							v-for="(team, teamIndex) in getTeams"
							:key="teamIndex"
							:label="team.name"
							:value="team"
						/>
					</el-select>
				</div>
				<div
					class="flex items-center w-full px-2"
					v-if="teamToPopulate"
				>
					<div class="flex h-full items-center w-4/12 justify-around">
						<div class="flex flex-col justify-center items-center gap-1">
							<span class="text-black text-center">{{ $t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.TEAM_SELECTED') }}</span>
							<span class="font-title text-black text-center">{{ teamToPopulate?.name }}</span>
						</div>
						<div class="flex justify-center">
							<i class="gui-next text-lg text-black" />
						</div>
					</div>

					<div class="w-8/12">
						<TeamCard
							:team="teamToPopulate"
							:is-options="false"
							:clickable="false"
							:pinnable="false"
							:user-connected="userConnected"
							:max-team-lead-display="maxTeamLeadDisplay"
							:method-get-team-lead="methodGetTeamLead"
							:filtered-team-users="filteredTeamUsers"
							:method-get-max-display-users="methodGetMaxDisplayUsers"
							:method-get-type-link="methodGetTypeLink"
						/>
					</div>
				</div>
			</div>

			<template #footer>
				<div class="flex justify-end items-center gap-3">
					<q-button
						@click="showPopulateTeamDialog = false"
						class="basic-black"
					>
						{{ $t('_COMMON.CANCEL') }}
					</q-button>
					<q-button
						@click="methodPopulateTeamWithSelectedUsers"
						:disabled="!teamToPopulate"
					>
						{{ $t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.ASSIGN_CTA') }}
					</q-button>
				</div>
			</template>
		</el-dialog>

		<!-- delete dialog members -->

		<delete-dialog
			:cancel="() => closeDeleteDialog()"
			:confirm="() => onClickDeleteMembers(methodFilterIds(usersSelected))"
			:confirm-label="$t('USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.REMOVE_CTA')"
			:close-dialog="() => (dialogConfirmDeleteMembersVisible = false)"
			:visible-dialog="dialogConfirmDeleteMembersVisible"
			:title="
				$tc(`USER_MANAGEMENT.USER_LIST.USERS.DELETE_MEMBERS`, focused, {
					focused,
				})
			"
			:tooltip="{
				icon: 'gui-infos',
				desc: $t('USER_MANAGEMENT.USER_LIST.USERS.HELP'),
				tooltipContent: $t('USER_MANAGEMENT.USER_LIST.USERS.TOOLTIP_CONTENT'),
				targetExternalLink:
					'https://help.qomon.com/fr/articles/3087832-changer-son-code-invitation',
			}"
		>
			<div class="custom_dialog w-full">
				<div class="flex flex-wrap py-3">
					<p class="pb-2 w-full">
						{{
							$tc(`USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.WILL_DELETE_USERS`, focused, {
								focused,
							})
						}}
					</p>

					<UserMiniCard
						v-for="(userToDelete, index) in usersSelected"
						:key="index"
						:a-user="userToDelete"
					/>

					<div class="py-3">
						<div class="border p-2 border-zinc-200 border relative rounded-lg p-4">
							<div class="p-3 rounded-lg">
								<ul class="relative">
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>

										{{
											$tc(
												`USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.USERS_NO_LONGER_ACT`,
												focused,
												{
													focused,
												}
											)
										}}
									</li>
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>

										{{
											$tc(
												`USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.USERS_NEXT_TIME_CONNECTION`,
												focused,
												{
													focused,
												}
											)
										}}
									</li>
								</ul>
								<p class="pl-5 text-gray-medium">
									<!-- {{ $t('USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.USER_NEVER_MORE_ACCESS') }} -->
									{{
										$tc(
											`USER_MANAGEMENT.USER_LIST.USERS.DELETE_DIALOG.USERS_NEVER_MORE_ACCESS`,
											focused,
											{
												focused,
											}
										)
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</delete-dialog>

		<!-- delete dialog Teams Tabs (many team) -->
		<delete-dialog
			:cancel="() => closeDeleteDialog()"
			:confirm="() => onClickDeleteTeams()"
			:close-dialog="() => (dialogConfirmDeleteTeamVisible = false)"
			:visible-dialog="dialogConfirmDeleteTeamVisible"
			:title="computedTitleDialog"
		>
			<div class="custom_dialog w-full">
				<div
					v-if="teamsSelected"
					class="flex flex-col py-3"
				>
					<p class="pb-2">
						{{
							teamsSelected.length > 1
								? $t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.WILL_DELETE_TEAMS')
								: $t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.WILL_DELETE_TEAM')
						}}
					</p>

					<div id="team-users-dialog">
						<div class="flex flex-wrap w-full">
							<div
								v-for="ateam in teamsSelected"
								:key="ateam.id"
								class="w-fit flex flex-row bg-purple-light border-purple-dark border rounded-xl m-1 items-center justify-center p-2"
							>
								<template
									v-for="(user, index) in ateam.users"
									:key="index"
								>
									<div
										v-if="index <= 1"
										class="relative avatar-container flex flex-col justify-center items-center"
										:class="index === 0 ? 'ml-0' : '-ml-2'"
									>
										<div class="flex flex-wrap">
											<el-tooltip
												:content="`${user.firstname} ${user.surname}`"
												placement="top"
											>
												<img
													v-if="user.avatar && user.avatar !== 'null'"
													:src="user.avatar"
													class="w-8 h-8 border-2 rounded-full border-white"
												>
												<div
													v-else
													class="avatar-placeholder bg-black text-white border-2 rounded-full border-white"
												>
													{{ user.firstname.toUpperCase().split('')[0]
													}}{{ user.surname.toUpperCase().split('')[0] }}
												</div>
											</el-tooltip>
										</div>
									</div>
									<div
										v-if="ateam.users && ateam.users.length > 1 && index === 2"
										class="relative flex avatar-container items-center justify-center -ml-2"
									>
										<span
											class="avatar-placeholder flex justify-center items-center rounded-full bg-gray-100 border-2 border-white"
										>
											+{{ ateam.users.length - 2 }}</span>
									</div>
								</template>

								<div class="flex flex-grow">
									<p class="pl-3">
										{{ ateam.name }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="py-3">
						<div class="border p-2 border-zinc-200 border relative rounded-lg p-4">
							<div class="p-3 rounded-lg">
								<ul class="relative">
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>

										{{
											teamsSelected.length > 1
												? $t(
													'USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.MEMBER_TEAMS_NOT_DELETED'
												)
												: $t(
													'USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.MEMBER_TEAM_NOT_DELETED'
												)
										}}
									</li>
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>

										{{
											teamsSelected.length > 1
												? $t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.ACTION_ASSIGNED_TEAMS')
												: $t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.ACTION_ASSIGNED_TEAM')
										}}
									</li>
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>

										{{
											teamsSelected.length > 1
												? $t(
													'USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.MEMBER_TEAMS_WILL_NOT_BE_ABLE'
												)
												: $t(
													'USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.MEMBER_TEAM_WILL_NOT_BE_ABLE'
												)
										}}
									</li>
								</ul>
							<!-- <p class="pl-5 text-gray-medium">
                                    Vos membres ne pourront plus rejoindre cette équipe.
                                </p> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</delete-dialog>

		<popin-upsell
			v-model:is-pop-up-shown="computedIsUpsellPopinShown.display"
			:source="computedIsUpsellPopinShown.source"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import intersectionBy from 'lodash/intersectionBy'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'

import user_store from 'src/models/user_store'
import getUsersPopulatedByTeams from '../utils/getUsersPopulatedByTeams'
import { transformAndDeduplicateCities } from '@/utils/citiesUtils'

import { methodGetTypeLink, methodRetrieveHostname } from '../../../utils/typeLinks.ts'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

export default {
	name: 'MembersView',
	components: {
		TeamEdition: defineAsyncComponent(() => import('./sub-components/sidebars/teams/edition.vue')),
		TeamDetail: defineAsyncComponent(() => import('./sub-components/sidebars/teams/detail.vue')),
		UserDetail: defineAsyncComponent(() => import('./sub-components/sidebars/users/detail.vue')),
		InviteUser: defineAsyncComponent(() => import('./sub-components/sidebars/users/invite.vue')),
		UserKpi: defineAsyncComponent(() => import('./sub-components/sidebars/user-kpi.vue')),
		Edition: defineAsyncComponent(() => import('./sub-components/sidebars/edition.vue')),
		Users: defineAsyncComponent(() => import('./sub-components/users.vue')),
		Teams: defineAsyncComponent(() => import('./sub-components/teams.vue')),
		Invitations: defineAsyncComponent(() => import('./sub-components/invitations.vue')),
		GlobalNotification: defineAsyncComponent(() => import('../../../components/communications-module/global-notification/GlobalNotification.vue')),
		PopupPacker: defineAsyncComponent(() => import('../../packs/popupPacker')),
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
		deleteDialog: defineAsyncComponent(() => import('./sub-components/deleteDialog.vue')),
		PopinUpsell: defineAsyncComponent(() => import('../../packs/upsellPopin.vue')),
		WaitingUsers: defineAsyncComponent(() => import('./sub-components/waitingUsers.vue')),
		SelectedUserList: defineAsyncComponent(() => import('./sub-components/SelectedUserList')),
		UserMiniCard: defineAsyncComponent(() => import('./sub-components/UserMiniCard.vue')),
		TeamCard: defineAsyncComponent(() => import('./sub-components/TeamCard.vue')),
		Collapse: defineAsyncComponent(() => import('/src/components/general/collapse-panel.vue')),
		RolesDropdown: defineAsyncComponent(() => import('./sub-components/RolesDropdown.vue')),
		CitiesDropdown: defineAsyncComponent(() => import('./sub-components/CitiesDropdown.vue')),
		AvailibilitiesDropdown: defineAsyncComponent(() => import('./sub-components/AvailibilitiesDropdown.vue')),
		AnimatedSearchBar: defineAsyncComponent(() => import('/src/components/general/AnimatedSearchBar.vue')),
	},

	beforeRouteLeave(to, from, next) {
		if (from.name === 'global-notification') {
			const isNavCanceled = this.$refs.globalNotification.methodCancelNavigation()

			if (isNavCanceled) {
				this.methodEmitAlertBeforeNavigation(next)

				return
			}

			next(true)
		}
		else {
			next(true)
		}
	},
	beforeRouteUpdate(to, from, next) {
		if (from.name === 'global-notification') {
			const isNavCanceled = this.$refs.globalNotification.methodCancelNavigation()

			if (isNavCanceled) {
				this.methodEmitAlertBeforeNavigation(next)

				return
			}

			next(true)
		}
		else {
			next(true)
		}

		if (this.$route.name === 'teams') {
			this.resetSidePannel()
		}

		next()
	},
	data() {
		return {
			focused: 0,
			querySearch: '',
			byEmail: false,
			byInvitationCode: false,
			availibilities: [],
			searchForNoneAvailabilities: false,
			usersPopulatedByTeams: [],
			dialogConfirmDeleteTeamVisible: false,
			dialogConfirmDeleteMembersVisible: false,
			allTeams: [],
			teamsSelected: [],
			teamsForNotification: [],
			usersSelected: [],
			usersIdsSelected: [],
			isUpsellPopinShown: { display: false, source: '' },
			filterWithoutTeams: false,
			filterSelectAll: false,
			selectedRolesFilters: [],
			selectedCitiesFilter: [],
			showPopulateTeamDialog: false,
			teamToPopulate: null,
			maxTeamLeadDisplay: 3,
			isCollapseDisplay: false,
		}
	},

	computed: {
		...mapGetters('@packs', [
			'getAccessStatusMembersTeams',
			'getAccessStatusMembersInvitations',
			'getAccessStatusCommunication',
			'getAccessStatusGlobalNotification',
		]),
		...mapGetters(['userConnected', 'usersCount', 'getAllUsers', 'is_edited', 'info_campagne']),
		...mapGetters('@action', ['entryPoint', 'steps']),
		...mapGetters('@team', ['getTeams', 'getTeamsAmount', 'showDetail', 'activeTeam']),
		...mapGetters('@user', {
			showUserDetail: 'showUser',
			inviting: 'inviting',
			countSwitch: 'countSwitch',
		}),
		...mapGetters('@user', ['activeUser', 'inviting']),
		...mapGetters({
			isEditingTeam: '@team/editing',
		}),
		...mapGetters('@invitation', ['getInvitationsLength']),
		...mapGetters('@waitingUsers', ['getWaitingUsersListLength']),
		...mapGetters('@roleManagement', ['getRolesList']),

		t() {
			const prefix = 'USER_MANAGEMENT.USER_LIST'

			return {
				delete: this.$t('_COMMON.DELETE'),

				teams: this.$tc(`${prefix}.TABS.TEAMS`, this.computedGetTeamsCount),
				addTeam: this.$t(`${prefix}.ADD_TEAM`),
				addUser: this.$t(`${prefix}.ADD_USER`),
				members: this.$tc(`${prefix}.TABS.MEMBERS`, this.computedGetUserCount),
				searchUser: this.$t(`${prefix}.SEARCHUSER`),
				searchTeam: this.$t(`${prefix}.SEARCHTEAM`),

				gdpr: {
					text: this.$t(`${prefix}.GDPR_TEXT`),
					confirm: this.$t(`${prefix}.GDPR_CONFIRM`),
				},
				dialogDelete: {
					AskTitleDeleteTeams: this.$t(`${prefix}.USERS.DELETE_DIALOG.ASK_TITLE_DELETE_TEAMS`),
					AskTitleOfSpace: this.$t(`${prefix}.USERS.DELETE_DIALOG.ASK_TITLE_OF_SPACE`),
					DescListOne: this.$t(`${prefix}.USERS.DELETE_DIALOG.DESC_LIST_1`),
					DescListTwo: this.$t(`${prefix}.USERS.DELETE_DIALOG.DESC_LIST_2`),
					DescListThree: this.$t(`${prefix}.USERS.DELETE_DIALOG.DESC_LIST_3`),
					WillDeleteTeams: this.$t(`${prefix}.USERS.DELETE_DIALOG.WILL_DELETE_TEAMS`),
				},

				successDelete: (count) => this.$tc(`${prefix}.DELETE_TEAMS_SUCCESS`, count, { count }),
				deleteCanceled: this.$t(`${prefix}.USERS.DELETE_CANCELED`),

				selectedLabel: (count) =>
					this.displayMembers
						? this.$tc(`${prefix}.USER_SELECTED`, count, { count })
						: this.$tc(`${prefix}.TEAM_SELECTED`, count, { count }),
			}
		},
		computedUsersPopulatedByTeams: {
			get() {
				return this.usersPopulatedByTeams
			},
			set(value) {
				this.usersPopulatedByTeams = value
			},
		},

		computedGetCitiesListFromUsers() {
			const cities = []
			this.computedUsersPopulatedByTeams.forEach((aUser) => {
				if (aUser.city && !cities.includes(aUser.city)) {
					cities.push(aUser.city)
				}
			})
			return transformAndDeduplicateCities(cities)
		},

		computedUsersIdsSelected() {
			return this.usersSelected.map((e) => e.id)
		},

		computedTitleDialog() {
			if (this.teamsSelected && this.teamsSelected.length > 1) {
				return this.$t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.ASK_TITLE_DELETE_TEAMS')
			}

			return this.$t('USER_MANAGEMENT.USER_LIST.TEAMS.DELETE_DIALOG.ASK_TITLE_DELETE_TEAM')
		},
		displayMembers() {
			return this.$route.name === 'users-list' || this.entryPoint === 'users-list'
		},

		displayTeams() {
			return this.$route.name === 'teams' || this.entryPoint === 'teams'
		},

		computedDisplayInvitations() {
			return this.$route.name === 'invitations' || this.entryPoint === 'invitations'
		},

		computedIsAccessTeams() {
			switch (this.getAccessStatusMembersTeams) {
				case 'enabled':
				case 'promoted':
					return true

				default:
					return false
			}
		},

		computedGetHeaderList() {
			return [
				{
					title: this.t.members,
					count: this.computedGetUserCount,
					name: 'users-list',
					class: () => {
						this.displayMembers ? 'boldUnderline' : null
					},
					status: 'enabled',
					order: 1,
					value: 'members',
					isAuthorized: true,
				},
				{
					title: this.t.teams,
					count: this.computedGetTeamsCount,
					name: 'teams',
					status: this.getAccessStatusMembersTeams,
					badge: 'premium',
					tag: 'span',
					noOpacity: this.displayTeams,
					class: () => {
						this.displayTeams && this.getAccessStatusMembersTeams === 'enabled'
							? 'boldUnderline'
							: null
						this.getAccessStatusMembersTeams !== 'enabled' ? 'boldUnderline' : null
					},
					order: 4,
					value: 'teams',
					isAuthorized: true,
				},
				// {
				// 	title: this.$t('SETTINGS.WAITING_USERS.TITLE'),
				// 	count: this.getWaitingUsersListLength,
				// 	name: 'waiting-users',
				// 	status: this.computedGetWaitingUsersAccess,
				// 	tag: 'span',
				// 	order: 3,
				// },
				{
					title: this.$t('USER_MANAGEMENT.USER_LIST.TABS.INVITATIONS'),
					count: this.getInvitationsLength,
					name: 'invitations',
					status: this.getAccessStatusMembersInvitations,
					badge: 'premium',
					tag: 'span',
					order: 2,
					isAuthorized: methodIsAccessGranted('members.invitation:invite'),
				},
			].sort((a, b) => {
				return (`${a.status}`).localeCompare(b.status)
			})
		},
		computedGetUserCount() {
			return this.usersCount
		},
		computedGetTeamsCount() {
			return this.getTeamsAmount
		},
		computedIsTeamsViewNotEnabled() {
			if (this.$route.name === 'teams' && this.getAccessStatusMembersTeams === 'promoted') {
				return true
			}

			return false
		},
		computedIsUpsellPopinShown: {
			get() {
				return this.isUpsellPopinShown
			},
			set(val) {
				this.isUpsellPopinShown = val
			},
		},
		// computedGetWaitingUsersAccess() {
		// 	let access = 'disabled'

		// 	if (this.info_campagne?.global_cause) {
		// 		access = 'enabled'
		// 	}

		// 	return access
		// },
	},

	watch: {
		$route() {
			if (!this.isEditingTeam && !this.$route.name === 'users-list') {
				this.resetSidePannel()
			}
		},

		getAllUsers: {
			handler() {
				this.methodSetTeamsInUsers()
			},
			deep: true,
		},
		allTeams: {
			handler() {
				this.methodSetTeamsInUsers()
			},
			deep: true,
		},

		querySearch(newVal) {
			this.querySearch = newVal
			this.fillQuery()
		},

	},

	async mounted() {
		if (this.getAccessStatusMembersTeams === 'enabled') {
			await this.actionFetchTeams()
		}

		this.actionFetchInvitations()
		this.methodSetTeamsInUsers()
		this.actionRetrieveLists()

		// if (this.computedGetWaitingUsersAccess === 'enabled') {
		// 	this.actionRetrieveWaitingUsersList()
		// }

		this.querySearch = this.$route.query.search ? this.$route.query.search.toString() : ''
	},
	beforeUnmount() {
		this.resetSidePannel()
	},
	methods: {
		...mapActions('@team', ['actionFetchTeams', 'actionSetActiveTeam', 'editTeam']),
		...mapActions('@invitation', ['actionFetchInvitations']),
		...mapActions('@user', ['actionDeleteUser']),
		...mapActions({
			search: 'SEARCH_USER',
		}),
		...mapActions({
			deleteTeams: '@team/deleteTeams',
		}),
		// ...mapActions('@waitingUsers', ['actionRetrieveWaitingUsersList']),
		...mapActions('@roleManagement', ['actionRetrieveLists']),

		methodGetTypeLink,
		methodRetrieveHostname,
		methodIsAccessGranted,

		onClickDeleteMembers(idsUsersSelected) {
			const self = this

			let nbOrError = 0
			let lastError = ''

			idsUsersSelected.forEach(function (aId, index, array) {
				self
					.actionDeleteUser(aId)
					.then(() => {
						this.$store.commit('@user/DELETE_ACTIVE_USER')
					})
					.catch((e) => {
						nbOrError++
						lastError = e
					})

				if (index === array.length - 1) {
					if (nbOrError > 0) {
						console.warn(lastError)
						self.$message({
							type: 'error',
							message: self.$t('CONTACT.LIST.ERROR_DELETE'),
							showClose: true,
						})
					}
					else {
						self.$message({
							type: 'success',
							message: self.$t('CONTACT.LIST.SUCCESS_DELETE'),
							showClose: true,
						})
					}

					self.search()
					self.dialogConfirmDeleteMembersVisible = !self.dialogConfirmDeleteMembersVisible
				}
			})
		},

		methodFilterIds(usersSelected) {
			return usersSelected.map((aUser) => aUser.id)
		},
		acceptGDPR(saveAPI = true) {
			let userUpdated = cloneDeep(this.userConnected)

			userUpdated.gdpr_accepted = true
			this.$store.commit('SAVE_USER_CONNECTED', userUpdated)

			if (saveAPI) {
				user_store.updateUser(userUpdated)
			}

			this.$forceUpdate()
		},

		fillQuery() {
			if (this.querySearch) {
				this.$router.push({
					name: this.$route.name,
					query: { search: this.querySearch },
				})
			}
			else {
				this.emptyQuery()
			}
		},

		emptyQuery() {
			this.querySearch = ''
			this.$router.push({
				name: this.$route.name,
				query: {},
			})
		},

		add() {
			this.onClickCreateTeam()

			if (!this.displayTeams) {
				if (this.is_edited) {
					this.$store.commit('@user/HIDE_DETAIL')
					this.$store.commit('EDIT', false)
				}

				this.newUser()
			}
		},

		newUser() {
			this.$store.commit('@user/INVITE')
		},

		selectedUsers(count) {
			if (this.displayMembers) this.focused = count
		},
		selectedUsersData(users) {
			this.usersSelected = users
		},

		selectedTeams(count) {
			if (this.displayTeams) this.focused = count
		},
		selectedTeamsData(teams) {
			this.teamsSelected = teams
		},

		deselectAll() {
			this.$store.commit('@user/DELETE_ACTIVE_USER')
			this.$store.commit('EDIT', false)
			this.$store.commit('@user/HIDE_DETAIL')
			this.$store.commit('@team/HIDE_DETAIL')
			this.$store.commit('@team/EDIT_END')
			this.focused = 0
			this.teamsSelected = []
			this.usersSelected = []
			this.filterSelectAll = false
		},

		onClickCreateTeam() {
			this.$store.commit('@team/EMPTY_ACTIVE_TEAM')
			let localActiveTeam = cloneDeep(this.activeTeam)

			localActiveTeam.users = cloneDeep(this.usersSelected.filter((user) => user.focus))
			this.actionSetActiveTeam(localActiveTeam)
			this.$store.commit('@team/EDIT')
		},

		closeDeleteDialog() {
			this.dialogConfirmDeleteTeamVisible = false
			this.dialogConfirmDeleteMembersVisible = false
			this.$message({
				type: 'info',
				message: this.t.deleteCanceled,
				showClose: true,
			})
		},
		openDeleteDialog() {
			this.dialogConfirmDeleteTeamVisible = !this.dialogConfirmDeleteTeamVisible
		},
		openDeleteMemberDialog() {
			this.dialogConfirmDeleteMembersVisible = !this.dialogConfirmDeleteMembersVisible
		},

		onClickDeleteTeams() {
			let oldFocused = this.focused

			this.focused = 0

			let teamSelectedIds = this.teamsSelected.map((team) => team.id)

			this.allTeams = this.allTeams.filter((team) => !teamSelectedIds.includes(team.id))

			this.deleteTeams(this.teamsSelected).then(() => {
				this.$message({
					type: 'success',
					message: this.t.successDelete(oldFocused),
					showClose: true,
				})
			})

			this.teamsSelected = []
			this.dialogConfirmDeleteTeamVisible = false
			this.resetSidePannel()
		},

		launchAction() {
			if (this.displayTeams) {
				this.$refs.teams.pourActionWithSelected()
			}
			else {
				this.$refs.users.pourActionWithSelected()
			}
		},

		resetSidePannel() {
			this.$store.commit('@user/DELETE_ACTIVE_USER')
			this.$store.commit('@team/EMPTY_ACTIVE_TEAM')
			this.$store.commit('@team/HIDE_DETAIL')
			this.$store.commit('@team/EDIT_END')
			this.$store.commit('@user/END_INVITE')
			this.$store.commit('@user/HIDE_DETAIL')
			this.$store.commit('EDIT', false) // TODO: should be namespaced in @user
		},
		async refreshTeamsAfterUpdate() {
			await this.$refs.teams.filterTeams()
		},

		methodGoToGlobalNotification() {
			switch (this.getAccessStatusGlobalNotification) {
				case 'promoted':
					this.methodDisplayUpsellPopin({ display: true, source: 'Global Notification' })
					// this.$refs.globalnotification.methodPackerClick('global_notification')
					break

				case 'enabled':
					this.$router.push({ name: 'global-notification' })
					break

				default:
					break
			}
		},
		methodSetAvailibilitiesFilters(newVal) {
			this.availibilities = newVal.map((avail) => avail.value)
		},
		methodSetSearchForNoneAvailabilities(newVal) {
			this.searchForNoneAvailabilities = newVal
		},
		methodSetTeamsInUsers() {
			if (this.getTeams.length && this.getAllUsers.length) {
				this.computedUsersPopulatedByTeams = getUsersPopulatedByTeams(this.getAllUsers, this.getTeams)
			}

			if (!this.getTeams.length && this.getAllUsers.length) {
				this.computedUsersPopulatedByTeams = cloneDeep(this.getAllUsers)
			}

			if (this.activeUser) {
				const index = this.computedUsersPopulatedByTeams.findIndex(
					(user) => user.id === this.activeUser.id,
				)

				if (index !== -1) {
					this.computedUsersPopulatedByTeams[index].focus = true
				}
			}
		},

		methodGoTo(tab) {
			if (
				(tab.name === 'teams' || tab.name === 'global-notification')
				&& tab.status !== 'enabled'
			) {
				this.methodDisplayUpsellPopin({
					display: true,
					source: tab.name === 'teams' ? 'Teams' : 'Global Notification',
				})
			}
			else {
				this.$router.push({ name: tab.name })
			}
		},
		methodSentTeamsNotifications() {
			this.allTeams.forEach((team) => {
				if (team.focus) {
					const teamInfos = {
						team_id: team.id,
						team_name: team.name,
					}

					this.teamsForNotification.push(teamInfos)
				}
			})
			this.$router.push({ name: 'global-notification' })
			this.focused = 0
			this.teamsSelected = []
		},

		methodEmitAlertBeforeNavigation(nextFunction) {
			this.$confirm(
				this.$t('COMMUNICATIONS.GLOBAL_NOTIFICATION.LEAVING_POPUP_MESSAGE'),
				this.$t('CONTACT.EDIT.ASKING_TO_DELETE_CONTACT_CREATION_TITLE'),
				{
					confirmButtonText: this.$t('_COMMON.YES'),
					cancelButtonText: this.$t('_COMMON.CANCEL'),
					customClass: 'custom_message_box',
				},
			)
				.then(() => {
					nextFunction(true)
				})
				.catch(() => {
					nextFunction(false)
				})
		},

		methodDisplayUpsellPopin(val) {
			this.computedIsUpsellPopinShown = val
		},

		methodUnselectUser(user) {
			const findedUser = this.usersPopulatedByTeams.find((aUser) => aUser.id === user.id)
			if (findedUser) findedUser.focus = false
			this.focused--
			if (this.focused === 1) {
				const lastSelectedUser = this.usersPopulatedByTeams.find((aUser) => aUser.focus)
				this.$store.commit('@user/SET_ACTIVE_USER', lastSelectedUser)
				this.$store.commit('@user/SHOW_DETAIL')
			}
			// }
		},

		onClickTriggerPopulateTeamDialog() {
			this.showPopulateTeamDialog = !this.showPopulateTeamDialog

			this.$nextTick(() => {
				this.methodHandleExpandDisplay()
			})
		},

		methodHandleExpandDisplay() {
			let userMiniCardContainerWidth = 0
			let userMiniCardTotalWidth = 0

			userMiniCardContainerWidth = this.$refs.userMiniCardContainer.clientWidth
			const children = this.$refs.userMiniCardContainer.children

			if (!children || isEmpty(children) || children.length === 0) {
				setTimeout(() => {
					this.methodHandleExpandDisplay()
				}, 300)
				return
			}

			for (let aUserMiniCard of children) {
				userMiniCardTotalWidth += aUserMiniCard.clientWidth
				if (userMiniCardTotalWidth > userMiniCardContainerWidth) {
					this.isCollapseDisplay = true
					return
				}
			}
			this.isCollapseDisplay = userMiniCardTotalWidth > userMiniCardContainerWidth
		},

		/// teams props function ///
		methodGetTeamLead(team, isFiltered = false) {
			let teamLead = null

			if (team?.leaders) {
				teamLead = team.leaders
				// Display only the visible ones
				if (isFiltered) {
					// Display the max number of user or remove one of them
					let filteredMaxTeamLeadDisplay
						= teamLead.length > this.maxTeamLeadDisplay
							? this.maxTeamLeadDisplay - 1
							: this.maxTeamLeadDisplay
					teamLead = teamLead.filter((teamLead, index) => index < filteredMaxTeamLeadDisplay)
				}
			}

			return teamLead
		},

		filteredTeamUsers(team) {
			// Remove also the team leader inside the list
			return team?.users?.filter(
				(teamUser, index) =>
					index < this.methodGetMaxDisplayUsers(team)
					&& teamUser.firstname
					&& !some(team?.leaders, { id: teamUser.id }),
			)
		},

		methodGetMaxDisplayUsers(team) {
			let maxDisplayUsers = 7

			if (this.methodGetTeamLead(team)) {
				// Remove 0, 1 or 2 of the max display according of the number of team leaders
				if (this.methodGetTeamLead(team).length < this.maxTeamLeadDisplay) {
					maxDisplayUsers = maxDisplayUsers - this.methodGetTeamLead(team).length
				}
				// Remove the max number (first people and icon with number remaining)
				else {
					maxDisplayUsers = maxDisplayUsers - this.maxTeamLeadDisplay
				}
			}
			return maxDisplayUsers
		},

		methodPopulateTeamWithSelectedUsers() {
			this.usersSelected.forEach((user) => {
				this.teamToPopulate.users.push(user)
			})
			this.methodEditTeam()
		},

		async methodEditTeam() {
			await this.editTeam({
				...this.teamToPopulate,
			})
				.then(() => {
					this.$message({
						type: 'success',
						message: this.$t('USER_MANAGEMENT.USER_LIST.ASSIGN_USERS_TO_A_TEAM_DIALOG.SAVE_TOOLTIP'),
						showClose: true,
					})
					this.deselectAll()
					this.showPopulateTeamDialog = false
				})
				.catch(() => {
					this.$message({
						type: 'error',
						message: this.$t('USER_MANAGEMENT.USER_LIST.TEAMS.ERROR'),
						showClose: true,
					})
				})
		},
		/// end teams props function ///
	},
}
</script>

<style lang="scss" scoped>

#team-users-dialog {
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	.avatar-container {
		height: 100%;
		width: 30px;
		position: relative;
		display: flex;
		border-radius: 50%;
		overflow: hidden;

		img,
		.avatar-placeholder {
			// background-color: #3b3b3b;
			// color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 2em;
			width: 2em;
		}
	}
}
</style>

<style lang="scss">
@import '../../../assets/scss/user-management/user-list.scss';

.q-header {
	transition: all 0.5s ease-in-out;
}
#membersHeader {
	padding: 1.3 * $spacing 2 * $spacing 2 * $spacing 2.3 * $spacing;
	position: relative;
	overflow: hidden;

}

#membersHeader.force-height-0 {
	height: 0px !important;
	min-height: 0px !important;
	transition: 0.5s;
	max-height: 0px !important;
	padding: 0px !important;
}

#gdpr {
	position: relative;
	z-index: 500;
	& > * {
		position: relative;
		#close {
			position: absolute;
			right: 0;
			top: 0;
		}
		box-shadow: 0px 1px 12px rgba(#213e61, 0.2);
		position: absolute;
	}
}
.el-notification.right h2 {
	text-align: initial;
}
.members-selected-cta {
	position: absolute;
	background: $white;
	width: 100%;
	height: fit-content;
	z-index: 1;
	padding-right: 1em;
	.row {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		.gap {
			flex: 1;
		}
	}
}
// .pure-alternative {
//  position: absolute;
//  right: 1%;
//  // width: 27%;
//  // max-height: calc(100% - 145px);
//  overflow: hidden;
// }

.invitation-cta {
	transition: all 0.3s ease;
}
.invitation-cta:hover {
	background: rgba(245, 244, 244, 0.904);
}
.invitation-cta:active {
	background: rgb(233, 232, 232);
}
.childrenView {
	max-width: 63%;
}
@media only screen and (max-width: 1023px) {
	.childrenView {
		max-width: 100%;
	}
}
</style>
