<template>
	<!-- pug broke ? what is this shit ? -->
	<popover
		class="auto-send-editor-popover-container"
		:class="{ preview: active }"
		@closePopover="closePopoverEvent()"
	>
		<template #popover-content>
			<div class="auto-send-popover" :class="{ preview: active }">
				<div class="auto-send-popover-content auto-send-visible">
					<div class="auto-send-title">{{ t.activate }}</div>
					<div class="auto-send-info">{{ t.info }}</div>
					<!-- I just put the span in the same level as the auto-send-info to hide auto-send-info and just keep the help link in responsive -->
					<span class="help-link" @click="helpClick()">{{ t.moreInfo }}</span>
					<div class="auto-send-question-info-container">
						<div class="auto-send-question-info">
							<div class="auto-send-info-label">{{ t.question }}</div>
							<div class="auto-send-info-value">{{ questionText }}</div>
						</div>
						<template v-if="optionText">
							<div class="auto-send-question-barrier"></div>
							<div class="auto-send-question-info">
								<div class="auto-send-info-label">{{ t.answer }}</div>
								<div class="auto-send-info-value">{{ optionText }}</div>
							</div>
						</template>
					</div>
					<div class="auto-send-toggle">
						<div
							class="toggle-button toggle-button-left auto-send-active"
							:class="{ selected: active }"
							@click="active = true"
						>
							<div class="toggle-button-header">{{ t.enable }}</div>
							<div class="toggle-button-content">{{ t.mailForThisAnswer }}</div>
						</div>
						<div
							class="toggle-button toggle-button-right auto-send-inactive"
							:class="{ selected: !active }"
							@click="active = false"
						>
							<div class="toggle-button-header">{{ t.disable }}</div>
							<div class="toggle-button-content">{{ t.mailForThisAnswer }}</div>
						</div>
					</div>
					<div class="auto-send-editor-container" :class="[active ? 'h-72' : 'h-0']">
						<div class="auto-send-editor-header">
							<div
								v-t="t.messagePlaceholder"
								class="auto-send-editor-description"
								:class="[active ? 'height-50' : 'height-0']"
							></div>
						</div>
						<!-- //TODO - add back when there's message templates
					.auto-send-message-templates-button(v-on:click="messageTemplatesClick()")
					| utiliser un modèle pré-créé i.fa.fa-chevron-down.icon -->
						<editor
							v-if="active"
							v-model:value="message"
							:placeholder="t.messagePlaceholder"
							:class="[active ? 'textarea-height' : 'height-0']"
						></editor>
					</div>
					<div class="flex justify-end gap-3">
						<q-button class="ghost no-underline" @click="closePopover()">
							{{ t.cancel }}
						</q-button>
						<q-button
							:class="{ ghost: !canConfirm }"
							:disabled="!canConfirm"
							@click="confirmClick()"
						>
							{{ t.confirm }}
						</q-button>
					</div>
				</div>
				<auto-send-preview
					v-show="active"
					class="auto-send-visible"
					:message="message"
				></auto-send-preview>
			</div>
		</template>
	</popover>
</template>

<script>
import Editor from '../../general/editor.vue'
import Popover from '../../general/popover.vue'
import AutoSendPreview from './auto-send-preview.vue'

export default {
	name: 'AutoSendEditor',

	components: {
		Editor,
		AutoSendPreview,
		Popover,
	},

	props: {
		indices: {
			type: Object,
			default() {
				return ''
			},
			// should contain a sectionIndex, questionIndex and optionIndex fields, which should all be numbers
			validator: (value) => {
				let fields = {
					sectionIndex: 'number',
					questionIndex: 'number',
					optionIndex: 'number',
				}

				for (let key in fields) {
					if (Object.prototype.hasOwnProperty.call(fields, key)) {
						if (!(key in value && typeof value[key] === fields[key])) {
							return false
						}
					}
				}
				return true
			},
		},
		sections: {
			type: Array,
			default() {
				return []
			},
		},
	},
	emits: ['closePopover', 'help'],

	data() {
		return {
			active: false,
			originalActive: false,
			initialMessage: '',
			message: '',

			customToolbar: [
				[{ size: [] }],
				['bold', 'italic', 'underline', { color: [] }],
				[{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
				['clean'],
			],
		}
	},

	computed: {
		t() {
			const prefix = 'FORM.AUTO_SEND.EDITOR'
			return {
				activate: this.$t(`${prefix}.ACTIVATE`),
				answer: this.$t(`${prefix}.ANSWER`),
				cancel: this.$t('_COMMON.CANCEL'),
				confirm: this.$t('_COMMON.CONFIRM'),
				disable: this.$t(`${prefix}.DISABLE`),
				enable: this.$t(`${prefix}.ENABLE`),
				info: this.$t(`${prefix}.INFO`),
				mailForThisAnswer: this.$t(`${prefix}.MAIL_FOR_THIS_ANSWER`),
				messagePlaceholder: this.$t(`${prefix}.MESSAGE_PLACEHOLDER`),
				moreInfo: this.$t(`${prefix}.MORE_INFO`),
				question: this.$t(`${prefix}.QUESTION`),
			}
		},

		modified() {
			return this.message !== this.initialMessage
		},

		empty() {
			// when something is typed then deleted, message is equal to a paragraph with a br in it
			// this has to do with quill, not sure if it's worth fixing
			return this.message === '' || this.message === '<p><br></p>'
		},

		activeChange() {
			return this.active !== this.originalActive
		},

		canConfirm() {
			if (this.active) {
				return this.modified && !this.empty
			} else if (this.activeChange) {
				return true
			}
			return false
		},

		questionText() {
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			if (
				sectionIndex >= 0 &&
				sectionIndex < this.sections.length &&
				questionIndex >= 0 &&
				this.sections[sectionIndex] &&
				this.sections[sectionIndex].questions &&
				questionIndex < this.sections[sectionIndex].questions.length
			) {
				return this.sections[sectionIndex].questions[questionIndex].label
			}
			return ''
		},

		optionText() {
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			let optionIndex = this.indices.optionIndex
			if (
				sectionIndex >= 0 &&
				sectionIndex < this.sections.length &&
				questionIndex >= 0 &&
				this.sections[sectionIndex] &&
				this.sections[sectionIndex].questions &&
				questionIndex < this.sections[sectionIndex].questions.length &&
				optionIndex >= 0 &&
				optionIndex < this.sections[sectionIndex].questions[questionIndex].refvalues.length
			) {
				return this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex].label
			}
			return ''
		},
	},

	watch: {
		sections: {
			handler() {
				this.setupActive()
			},
			deep: true,
		},
	},

	mounted() {
		this.validateIndices()
		this.setupActive()
		this.setupMessages()
		this.originalActive = this.active
	},

	methods: {
		validateIndices() {
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			let optionIndex = this.indices.optionIndex
			if (
				sectionIndex < 0 ||
				sectionIndex >= this.sections.length ||
				questionIndex < 0 ||
				questionIndex >= this.sections[sectionIndex].questions.length ||
				optionIndex < 0 ||
				optionIndex >= this.sections[sectionIndex].questions[questionIndex].refvalues.length
			) {
				// indices are invalid
				this.closePopover()
			}
		},

		setupActive() {
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			let optionIndex = this.indices.optionIndex
			this.active =
				this.sections &&
				this.sections[sectionIndex] &&
				this.sections[sectionIndex].questions &&
				this.sections[sectionIndex].questions[questionIndex] &&
				this.sections[sectionIndex].questions[questionIndex].refvalues &&
				this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex] &&
				!!this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex].auto_send
		},

		setupMessages() {
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			let optionIndex = this.indices.optionIndex
			// probably not a necessary check, but just to be safe (maybe useful while column is being added in DB)
			if (
				this.sections &&
				this.sections[sectionIndex] &&
				this.sections[sectionIndex].questions &&
				this.sections[sectionIndex].questions[questionIndex] &&
				this.sections[sectionIndex].questions[questionIndex].refvalues &&
				this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex] &&
				Object.prototype.hasOwnProperty.call(
					this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex],
					'auto_send'
				)
			) {
				this.message =
					this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex].auto_send
			} else {
				this.message = ''
			}
			this.initialMessage = this.message
		},

		// event from popover component; there are conditions for actually closing the popover so don't immediately close it
		closePopoverEvent() {
			// this event is only thrown on "passive" closes like shadow click or esc key press
			// these should only close the popover if the message isn't active or the message hasn't been modified
			if (!this.active || !this.modified) {
				this.closePopover()
			}
		},

		closePopover(sections) {
			this.$emit('closePopover', sections)
		},

		messageTemplatesClick() {
			// TODO - open up a template picker
			// TODO - See MA: Story #5 on Trello
		},

		confirmClick() {
			if (!this.canConfirm) return
			// Don't make API call - form-builder (or whoever brings up this popup) should save it
			let sectionIndex = this.indices.sectionIndex
			let questionIndex = this.indices.questionIndex
			let optionIndex = this.indices.optionIndex
			let option = this.sections[sectionIndex].questions[questionIndex].refvalues[optionIndex]
			// only consider the auto_send active if the message has content
			this.active = this.active && !this.empty
			if (this.active) {
				option.auto_send = this.message // stored as a string on the db
				option.activate_auto_send = 'true'
			} else {
				option.auto_send = ''
				// delete the template in case it existed before
				option.activate_auto_send = '' // stored as a string on the db
			}
			this.closePopover(this.sections)
		},

		helpClick() {
			this.$emit('help')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/form/auto-send/auto-send-editor.scss';
.height-0 {
	height: 0px;
}
.height-100 {
	height: 100%;
}
.textarea-height {
	height: 140px;
}
.qi-ko:before,
.qi-check:before {
	margin-right: 0.37em;
}
</style>

<style lang="sass" scoped>
.secondary-text
	@apply text-sm text-gray-400

.basic-black
	border-color: black !important
</style>
