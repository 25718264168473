import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { firstBy } from 'thenby'
import {
	RESET_IMPORT_STORE,
	FETCH_ALL_IMPORTS,
	FETCH_PREVIEW_FILE_UPLOADED,
	SET_PREVIEW_FILE_UPLOADED_STATE,
	FETCH_PREVIEW_FILE_UPLOADED_COLUMNS,
	POST_SAVE_SETTINGS_FILE_UPLOADED,
	SAVE_COLUMNS_FROM_DATA_CHOICE,
	FETCH_SAMPLE_LINES_FILE_UPLOADED,
	SAVE_IMPORT_CSV_MAPPING,
	SET_IMPORT_CONFIG_CSV,
	SET_IMPORT_ENQUEUED_STATE,
	FETCH_QUORUM_PROPERTIES_STATE,
	SET_QUORUM_PROPERTIES,
	SET_IMPORT,
	FETCH_IMPORT_STATE,
	FETCH_LIST_ALL_CONFLICTS_STATE,
	SET_LIST_ALL_CONFLICTS,
	SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS,
	SET_ARCHIVED_STATUS_TO_AN_IMPORT_BY_ID,
	SET_STATUSES_IN_IMPORT_FETCHED,
	SET_RESOLVE_CONFLICT_RESOLUTION_STATE,
	SET_AI_REQUEST_COUNT,
} from './_mutation-types'

import {
	constructFinalColumns,
	constructArrayOfColumns,
} from '../../components/settings-module/import/helpers/columnsHelper'

import {
	fetchAnImport,
	canCreateImport,
	fetchAnImportConflicts,
	fetchAllImports,
	fetchDownloadInvalidLines,
	fetchPreviewFileUploaded,
	fetchPreviewFileUploadedColumns,
	postSaveSettingsFileImported,
	fetchSampleLinesFileUploaded,
	fetchConfigImport,
	postSaveCSVMapping,
	patchSetNameImport,
	deleteSoftlyAnImport,
	postStartImportEnqueued,
	fetchQuorumProperties,
	fetchQuorumStatuses,
	postResolveImportConflicts,
	postAIMatchColumns,
	postAIMatchColumnsForced,
} from '../../models/import_model'

export const STATUS_IMPORT = {
	NONE: 'NONE',
	REQUESTED: 'REQUESTED',
}

const getDefaultState = () => {
	return {
		listImports: [],
		configImport: {},
		previewFileUploaded: null,
		previewFileUploadedColumns: null,
		previewFileUploadedState: 'init',
		isSettingsSaved: false,
		sampleLinesFileUploaded: null,
		savedColumnsFromDataChoice: [],
		isMappingState: 'init',
		enqueuedState: 'init',
		propertiesQuorum: [],
		propertiesQuorumState: 'init',
		anImport: {},
		anImportFetchState: 'init',
		listAllConflictsFetchState: 'init',
		resolveConflictImportState: 'init',
		listAllConflictsAnImport: [],
		AIRequestCount: 0,
	}
}
const state = getDefaultState

export const mutations = {
	[RESET_IMPORT_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[FETCH_ALL_IMPORTS]: (state, payload) => {
		let arrayOfImports = []
		if (payload) {
			arrayOfImports = payload
		}
		arrayOfImports.forEach((anImport) => {
			anImport.statuses.sort(firstBy('created_at'))
		})
		arrayOfImports.sort(firstBy('created_at', -1))
		state.listImports = arrayOfImports
	},
	[SET_IMPORT_CONFIG_CSV]: (state, payload) => {
		state.configImport = payload
	},
	[FETCH_PREVIEW_FILE_UPLOADED]: (state, payload) => {
		state.previewFileUploaded = payload
	},
	[FETCH_PREVIEW_FILE_UPLOADED_COLUMNS]: (state, payload) => {
		state.previewFileUploadedColumns = payload
	},
	[FETCH_SAMPLE_LINES_FILE_UPLOADED]: (state, payload) => {
		state.sampleLinesFileUploaded = payload
	},
	[SET_PREVIEW_FILE_UPLOADED_STATE]: (state, payload) => {
		state.previewFileUploadedState = payload
	},
	[POST_SAVE_SETTINGS_FILE_UPLOADED]: (state, payload) => {
		state.isSettingsSaved = payload
	},
	[SAVE_COLUMNS_FROM_DATA_CHOICE]: (state, payload) => {
		state.savedColumnsFromDataChoice = payload
	},
	[SAVE_IMPORT_CSV_MAPPING]: (state, payload) => {
		state.isMappingState = payload
	},
	[SET_IMPORT_ENQUEUED_STATE]: (state, payload) => {
		state.enqueuedState = payload
	},
	[FETCH_QUORUM_PROPERTIES_STATE]: (state, payload) => {
		state.propertiesQuorumState = payload
	},
	[SET_QUORUM_PROPERTIES]: (state, payload) => {
		state.propertiesQuorum = payload
	},
	[FETCH_IMPORT_STATE]: (state, payload) => {
		state.anImportFetchState = payload
	},
	[SET_IMPORT]: (state, payload) => {
		state.anImport = payload
	},
	[FETCH_LIST_ALL_CONFLICTS_STATE]: (state, payload) => {
		state.listAllConflictsFetchState = payload
	},
	[SET_LIST_ALL_CONFLICTS]: (state, payload) => {
		state.listAllConflictsAnImport = payload
	},
	[SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS]: (state, payload) => {
		const { id, name } = payload
		const list = cloneDeep(state.listImports)
		const changedImport = list.find((anImport) => anImport.id === id)
		if (changedImport) changedImport.name = name

		state.listImports = list
	},
	[SET_ARCHIVED_STATUS_TO_AN_IMPORT_BY_ID]: (state, payload) => {
		const id = payload
		const list = cloneDeep(state.listImports)
		const archivedImport = list.find((anImport) => anImport.id === id)
		if (archivedImport) archivedImport.archived = true

		state.listImports = list
	},
	[SET_STATUSES_IN_IMPORT_FETCHED]: (state, payload) => {
		if (!isEmpty(state.anImport)) {
			state.anImport.statuses = payload
		}
	},
	[SET_RESOLVE_CONFLICT_RESOLUTION_STATE]: (state, payload) => {
		state.resolveConflictImportState = payload
	},
	[SET_AI_REQUEST_COUNT]: (state, payload) => {
		state.AIRequestCount = payload
	},
}

export const actions = {
	resetState: ({ commit }) => {
		commit(RESET_IMPORT_STORE)
	},

	/**
	 * @action
	 * @desc Will fetch all the imports
	 */
	actionFetchAllImports: ({ commit }) => {
		fetchAllImports()
			.then((res) => {
				commit(FETCH_ALL_IMPORTS, res.body)
			})
			.catch(() => {
				commit(FETCH_ALL_IMPORTS, [])
			})
	},

	actionFetchPreviewFileUploaded: ({ commit }, payload) => {
		const { id, data } = payload
		commit(SET_PREVIEW_FILE_UPLOADED_STATE, 'loading')
		fetchPreviewFileUploadedColumns(id, data).then((resColumns) => {
			commit(FETCH_PREVIEW_FILE_UPLOADED_COLUMNS, resColumns.body || [])
			fetchPreviewFileUploaded(id, data)
				.then((res) => {
					commit(FETCH_PREVIEW_FILE_UPLOADED, res.body || [])
					commit(POST_SAVE_SETTINGS_FILE_UPLOADED, false)
					commit(SET_PREVIEW_FILE_UPLOADED_STATE, 'finished')
				})
				.catch(() => {
					commit(FETCH_PREVIEW_FILE_UPLOADED, 'error')
					commit(SET_PREVIEW_FILE_UPLOADED_STATE, 'finished')
				})
		})
	},

	actionSetPreviewFileUploadesState: ({ commit }, payload) => {
		commit(SET_PREVIEW_FILE_UPLOADED_STATE, payload)
	},
	actionFetchQuorumProperties: ({ commit, state }, payload) => {
		const { propertiesQuorumState } = state
		if (propertiesQuorumState !== 'fetched') {
			const { id } = payload
			commit(FETCH_QUORUM_PROPERTIES_STATE, 'loading')
			fetchQuorumProperties(id)
				.then((res) => {
					const transformKeysForModel = res.body.map((aKey) => {
						return {
							key: aKey,
							type: 'text',
							label: aKey,
						}
					})
					commit(SET_QUORUM_PROPERTIES, transformKeysForModel)
					commit(FETCH_QUORUM_PROPERTIES_STATE, 'fetched')
				})
				.catch(() => {
					commit(FETCH_QUORUM_PROPERTIES_STATE, 'error')
				})
		}
	},

	actionFetchConfigImport: ({ commit, dispatch }, payload) => {
		const { id, buildColumns } = payload
		fetchConfigImport(id).then((res) => {
			if (buildColumns) {
				fetchPreviewFileUploaded(id, {
					separator: res.body.separator_char,
					comment: '#',
					start_read_at: 0,
				}).then((res2) => {
					const buildedColumns = constructArrayOfColumns(res2.body)
					fetchPreviewFileUploadedColumns(id, {
						separator: res.body.separator_char,
						comment: '#',
						start_read_at: 0,
						columns_idx: 0,
					}).then((resColumns) => {
						const buildedColumnsFinal = constructFinalColumns(
							buildedColumns,
							res.body.start_read_at,
							res.body.start_read_at === 1 ? resColumns.body : [],
						)

						dispatch('actionSaveColumnsFromDataChoice', buildedColumnsFinal || [])
					})
				})
			}

			commit(SET_IMPORT_CONFIG_CSV, res.body || [])
		})
	},

	actionPostSaveCSVSettings: ({ commit }, payload) => {
		const { id, data } = payload
		postSaveSettingsFileImported(id, data).then((res) => {
			commit(POST_SAVE_SETTINGS_FILE_UPLOADED, true)
			commit(SET_IMPORT_CONFIG_CSV, res.body)
		})
	},

	actionFetchSampleLinesFileUploaded: ({ commit }, payload) => {
		const { id } = payload
		fetchSampleLinesFileUploaded(id).then((res) => {
			commit(FETCH_SAMPLE_LINES_FILE_UPLOADED, res.body.sample_lines)
		})
	},

	actionSaveColumnsFromDataChoice: ({ commit }, payload) => {
		commit(SAVE_COLUMNS_FROM_DATA_CHOICE, payload)
	},

	actionSaveCSVMapping: ({ commit }, payload) => {
		const { id, data } = payload
		commit(SAVE_IMPORT_CSV_MAPPING, 'loading')
		postSaveCSVMapping(id, data)
			.then((res) => {
				commit(SAVE_IMPORT_CSV_MAPPING, 'fetched')
				commit(SET_IMPORT_CONFIG_CSV, res.body)
			})
			.catch(() => {
				commit(SAVE_IMPORT_CSV_MAPPING, 'error')
			})
	},

	actionStartEnqueuedImport: ({ commit }, payload) => {
		const { id, name } = payload
		commit(SET_IMPORT_ENQUEUED_STATE, 'loading')
		// patchSetNameImport(id, { name }).then(() => {
		// 	postStartImportEnqueued(id)
		// 		.then(() => {
		// 			commit(SET_IMPORT_ENQUEUED_STATE, 'enqueued')
		// 		})
		// 		.catch(() => {
		// 			commit(SET_IMPORT_ENQUEUED_STATE, 'error')
		// 		})
		// })
		postStartImportEnqueued(id)
			.then(() => {
				commit(SET_IMPORT_ENQUEUED_STATE, 'enqueued')
			})
			.catch(() => {
				commit(SET_IMPORT_ENQUEUED_STATE, 'error')
			})
	},

	actionSetNameImportAndRefreshListImports: ({ commit }, payload) => {
		const { id, name } = payload
		patchSetNameImport(id, { name }).then(() => {
			commit(SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS, { id, name })
		})
	},

	actionSetNameImportDetail: ({ commit, state }, payload) => {
		const { id, name } = payload
		patchSetNameImport(id, { name }).then(() => {
			const importCloned = cloneDeep(state.anImport)
			importCloned.name = name
			commit(SET_IMPORT, importCloned)
			commit(SET_NEW_NAME_IMPORT_IN_IMPORT_LISTS, { id, name })
		})
	},

	actionArchiveAnImport: ({ commit }, payload) => {
		const id = payload
		deleteSoftlyAnImport(id).then((res) => {
			commit(SET_ARCHIVED_STATUS_TO_AN_IMPORT_BY_ID, id)
		})
	},

	actionFetchAnImport: ({ commit }, payload) => {
		const { id, withoutLoading } = payload
		if (!withoutLoading) {
			commit(FETCH_IMPORT_STATE, 'loading')
		}
		fetchAnImport(id)
			.then((res) => {
				fetchQuorumStatuses(id).then((res2) => {
					commit(SET_IMPORT, res.body)
					commit(SET_STATUSES_IN_IMPORT_FETCHED, res2.body)
					commit(FETCH_IMPORT_STATE, 'fetched')
				})
			})
			.catch(() => {
				commit(FETCH_IMPORT_STATE, 'error')
			})
	},
	actionCanCreateImport: ({ commit }) => {
		return canCreateImport()
			.then((res) => {
				return res
			})
			.catch((err) => {
				return err
			})
	},

	actionFetchAnImportConflicts: ({ commit }, payload) => {
		const { id } = payload
		commit(FETCH_LIST_ALL_CONFLICTS_STATE, 'loading')
		fetchAnImportConflicts(id)
			.then((res) => {
				commit(SET_LIST_ALL_CONFLICTS, res.body)
				commit(FETCH_LIST_ALL_CONFLICTS_STATE, 'fetched')
			})
			.catch(() => {
				commit(FETCH_LIST_ALL_CONFLICTS_STATE, 'error')
			})
	},

	actionPostResolveImportConflicts: ({ commit, dispatch }, payload) => {
		const { id, strategy } = payload
		commit(SET_RESOLVE_CONFLICT_RESOLUTION_STATE, 'loading')
		postResolveImportConflicts(id, { strategy })
			.then(() => {
				commit(
					SET_RESOLVE_CONFLICT_RESOLUTION_STATE,
					strategy === 'create_new_profiles' ? 'resolved_force_import' : 'resolved_ignore_import',
				)
				dispatch('actionFetchAnImport', { id, withoutLoading: true })
			})
			.catch(() => {
				commit(SET_RESOLVE_CONFLICT_RESOLUTION_STATE, 'error')
			})
	},

	actionResetPreviewFileUploaded: ({ commit }) => {
		commit(FETCH_PREVIEW_FILE_UPLOADED, null)
	},

	actionResetAnImport: ({ commit }) => {
		commit(SET_IMPORT, {})
		commit(FETCH_IMPORT_STATE, 'init')
	},

	actionReturnAILabelMatchingImport: async ({ state, commit }, payload) => {
		// The first request is not forced to get the default payload and there is two forced request
		// to get a new payload before getting the cache payload
		if (state.AIRequestCount !== 0 && state.AIRequestCount < 3) {
			commit(SET_AI_REQUEST_COUNT, state.AIRequestCount + 1)
			return await postAIMatchColumnsForced(payload).then((res) => {
				return res?.imported_column_to_space_column
			})
		}
		else {
			commit(SET_AI_REQUEST_COUNT, state.AIRequestCount + 1)
			return await postAIMatchColumns(payload).then((res) => {
				return res?.imported_column_to_space_column
			})
		}
	},

	actionResetAIRequestCount: ({ commit }) => {
		commit(SET_AI_REQUEST_COUNT, 0)
	},

	actionResetConfig: ({ commit }) => {
		commit(SET_IMPORT_CONFIG_CSV, {})
	},
}

const getters = {
	getListImports: (state) => state.listImports,
	getListImportsLength: (state) => state.listImports.length,
	getConfigImport: (state) => state.configImport,
	getSeparatorSaved: (state) => state.separatorSaved,
	getStartReadingLineSaved: (state) => state.startReadingLineSaved,
	getPreviewFileUploaded: (state) => state.previewFileUploaded,
	getPreviewFileUploadedState: (state) => state.previewFileUploadedState,
	getPreviewFileUploadedColumns: (state) => state.previewFileUploadedColumns,
	getSampleLinesFileUploaded: (state) => state.sampleLinesFileUploaded,
	getIsSettingsSaved: (state) => state.isSettingsSaved,
	getSavedColumnsFromDataChoice: (state) => state.savedColumnsFromDataChoice,
	getPropertiesAvailables: (state) => state.propertiesQuorum,
	getIsMappingState: (state) => state.isMappingState,
	getEnqueuedState: (state) => state.enqueuedState,
	getAnImport: (state) => state.anImport,
	getListAllConflictsAnImport: (state) => state.listAllConflictsAnImport,
	getIsAnImportTotallyLoaded: (state) =>
		state.anImportFetchState === 'fetched' || state.listAllConflictsFetchState === 'fetched',
	getResolveConflictImportState: (state) => state.resolveConflictImportState,
}

export const importModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
