import ImportExportSettings from '../components/settings-module/import-export-settings.vue'
import ExportsSettings from '../components/settings-module/export/exportList.vue'
import store from '../store/stateStore'

import { getterControllerStorePackCPR } from './utils/routerController'

const ExchangesRoutes = [
	{
		title: 'SETTINGS.HEADER.IMPORT_EXPORT',
		selected: '/exchanges/',
		path: 'exchanges',
		name: 'exchanges-list',
		component: ImportExportSettings,
		redirect: { name: 'imports-list' },
		isDisplayed: true,
		children: [
			{
				path: 'imports',
				name: 'imports-list',
				component: () => import('../components/settings-module/import/importList.vue'),
				beforeEnter: (to: object, from: object, next: Function) => {
					if (store.getters['userConnected']?.role_data?.access?.includes('import:delete')) {
						next()
					} else {
						next({ name: 'command-center', replace: true })
					}
				},
				children: [
					{
						path: ':id',
						name: 'an-import',
						component: () => import('../components/settings-module/import/importView/anImport.vue'),
					},
				],
			},
			{
				path: 'archived-imports',
				name: 'archived-imports-list',
				component: () => import('../components/settings-module/import/archivedImportsList.vue'),
				beforeEnter: (to: object, from: object, next: Function) => {
					if (store.getters['userConnected']?.role_data?.access?.includes('import:delete')) {
						next()
					} else {
						next({ name: 'command-center', replace: true })
					}
				},
			},
			{
				path: 'exports',
				name: 'exports-list',
				component: ExportsSettings,
				beforeEnter: (to: object, from: object, next: Function) => {
					if (store.getters['userConnected']?.role_data?.access?.includes('export:delete')) {
						next()
					} else {
						next({ name: 'command-center', replace: true })
					}
				},
			},
		],
	},
]

const MassiveManipulationRoute = {
	title: 'SETTINGS.HEADER.MASSIVE_MANIPULATION',
	path: 'massive-manipulations',
	name: 'massive-manipulations',
	selected: '/massive-manipulations',
	isDisplayed: true,
	component: () => import('../components/settings-module/massiveManipulations.vue'),
	beforeEnter: (to: object, from: object, next: Function) => {
		const CPR = getterControllerStorePackCPR()
		// Role access check
		if (
			store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:delete') &&
			CPR.statusWebMassiveDeleteContacts() === 'enabled'
		) {
			next()
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
}

const MassiveContactModificationsRoute = {
	title: 'SETTINGS.HEADER.MASSIVE_CONTACT_MODIFICATIONS',
	path: 'massive-contact-modifications',
	name: 'massive-contact-modifications',
	selected: '/massive-contact-modifications',
	isDisplayed: true,
	component: () => import('../components/settings-module/massiveContactModifications.vue'),
	beforeEnter: (to: object, from: object, next: Function) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:update')) {
			next()
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
}

const routes = {
	path: '/data',
	name: 'data',
	title: 'Data & Integrations',
	meta: { title: 'SETTINGS.HEADER.IMPORT_EXPORT' },
	component: () => import('../components/data-components/DataMain.vue'),
	redirect: () => {
		// Change redirection route according roles
		if (store.getters['userConnected']?.role_data?.access?.includes('import:delete')) {
			return { name: 'imports-list' }
		} else if (store.getters['userConnected']?.role_data?.access?.includes('export:delete')) {
			return { name: 'exports-list' }
		} else if (
			store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:delete')
		) {
			return { name: 'massive-manipulations' }
		} else if (
			store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:update')
		) {
			return { name: 'massive-contact-modifications' }
		} else {
			return { name: 'command-center' }
		}
	},
	beforeEnter: (to: object, from: object, next: Function) => {
		// Change displayed tab according roles
		if (
			store.getters['userConnected']?.role_data?.access?.includes('import:delete') ||
			store.getters['userConnected']?.role_data?.access?.includes('export:delete')
		) {
			ExchangesRoutes[0].isDisplayed = true
		} else {
			ExchangesRoutes[0].isDisplayed = false
		}
		if (store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:delete')) {
			MassiveManipulationRoute.isDisplayed = true
		} else {
			MassiveManipulationRoute.isDisplayed = false
		}
		if (store.getters['userConnected']?.role_data?.access?.includes('contacts.massive:update')) {
			MassiveContactModificationsRoute.isDisplayed = true
		} else {
			MassiveContactModificationsRoute.isDisplayed = false
		}
		next()
	},
	children: [...ExchangesRoutes, MassiveManipulationRoute, MassiveContactModificationsRoute],
	props: true,
}

export default routes
