<template>
	<Draggable
		v-model="formInputs"
		class="params-bar__form-draggable mb-12"
		item-key="order"
		@start="dragging = true"
		@end="onDraggingEnd"
	>
		<template #item="{ element }">
			<div
				:data-type="element.type"
				class="relative flex cursor-pointer items-center mb-2 py-2 border-b border-gray-200 bg-white pl-1"
			>
				<div :class="{ 'text-gray-300': element?.hidden }" class="transform rotate-90">
					<i class="gui-column text-2xl opacity-40" />
				</div>
				<p
					style="font-size: 0.9rem !important"
					class="font-semibold text-left pl-2 w-fit"
					:class="{ 'text-gray-300': element?.hidden }"
				>
					{{ element?.label ? element?.label : $t(`_COMMON.${element?.type.toUpperCase()}`) }}
				</p>

				<div
					v-if="isAdvised(element?.type)"
					:class="{ 'opacity-60': element?.hidden }"
					class="hidden bg-green-lighted text-green-main text-xs rounded-md px-1 py-px ml-4 whitespace-nowrap lg:block"
				>
					<i class="gui-stars color-part-one color-part-two" />
					<span> {{ $t('CREATE_FORM.ADVISED') }} </span>
				</div>
				<el-select
					:model-value="
						element?.mandatory || element?.type === 'mail'
							? 'mandatory'
							: element?.hidden
							? 'hidden'
							: 'optionnal'
					"
					:disabled="element?.type === 'mail'"
					class="select-importance w-32 ml-auto"
					:class="{ 'params-bar__form-draggable--email-input': element?.type === 'mail' }"
					@change="(e) => changeFormInputPriority(e, element)"
				>
					<el-option
						v-for="option in formOptions"
						:key="option.value"
						:label="option.label"
						:value="option.value"
						class="text-sm flex items-center gap-x-2 justify-between"
					>
						<span style="float: left">{{ option.label }}</span>
						<i
							v-if="
								(option.value === 'mandatory' && element?.mandatory) ||
								(option.value === 'hidden' && element?.hidden) ||
								(option.value === 'optionnal' && !element?.mandatory && !element?.hidden)
							"
							class="gui-yes text-lg"
						/>
					</el-option>
				</el-select>
			</div>
		</template>
	</Draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'

export default {
	components: {
		Draggable,
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
	},
	emits: ['update:form'],
	data() {
		return {
			dragging: false,
			formInputs: [],
			formOptions: [
				{ value: 'mandatory', label: this.$t('_COMMON.MANDATORY') },
				{ value: 'optionnal', label: this.$t('CREATE_FORM.OPTIONAL') },
				{ value: 'hidden', label: this.$t('CREATE_FORM.HIDDEN') },
			],
		}
	},
	computed: {
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
	},
	mounted() {
		this.formInputs = cloneDeep(this.form.fields)
		this.formInputs?.sort((a, b) => a.order - b.order)
	},
	methods: {
		isAdvised(type) {
			return ['firstname', 'surname', 'postalcode', 'birthdate'].includes(type)
		},

		changeFormInputPriority(priority, input) {
			switch (priority) {
				case 'mandatory':
					input.mandatory = true
					input.hidden = false
					break
				case 'hidden':
					input.mandatory = false
					input.hidden = true
					break
				case 'optionnal':
					input.mandatory = false
					input.hidden = false

					break
				default:
					break
			}

			this.formInputs.find((i) => i.type === input.type).mandatory = input.mandatory
			this.formInputs.find((i) => i.type === input.type).hidden = input.hidden

			this.computedForm.fields = this.formInputs
		},

		onDraggingEnd(e) {
			const newList = Array.from(e.to.children)
			const clonedSupportForm = cloneDeep(this.form.fields)

			newList.forEach((el, index) => {
				const input = clonedSupportForm.filter((input) => input.type === el.dataset.type)[0]
				if (input) input.order = index
			})
			this.computedForm.fields = clonedSupportForm

			this.dragging = false
		},
	},
}
</script>

<style lang="sass">
.params-bar
	&__form-draggable
		.el-input__inner
			@apply text-sm border-none


		&--email-input .el-input.is-disabled
			.el-input__inner
				@apply bg-transparent

			.el-input__suffix
				@apply hidden
</style>
