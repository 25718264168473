import { $request } from './requester'

export default {
	async getReverseGeoCoding(lat, lon, city = null, street = null) {
		const params = new URLSearchParams({
			lat: lat.toString(),
			lon: lon.toString(),
		})

		if (city) params.append('city', city)
		if (street) params.append('street', street)

		const reversGeoCodeUrl = `/geocode/reverse?${params.toString()}`

		return $request('GET', reversGeoCodeUrl)
			.then((res) => {
				if (res.statusCode === 200) {
					if (res.body.data) return res.body.data
					return []
				}
			})
			.catch((err) => {
				return err
			})
	},
	async getGeoCodingSearch(payload) {
		const { query, lon, lat, city, street, type, requestId, hightAccuracy } = payload
		// type can be 'address' or 'postcode' or "city"
		const params = new URLSearchParams({
			query: query,
		})

		if (lon && lat) {
			params.append('lat', lat.toString())
			params.append('lon', lon.toString())
		}

		if (city) params.append('city', city)
		if (street) params.append('street', street)
		if (type) params.append('type', type)

		if (requestId) params.append('requestId', requestId)
		if (hightAccuracy) params.append('hightAccuracy', hightAccuracy)

		const geoCodeSearchUrl = `/geocode/search?${params.toString()}`

		return $request('GET', geoCodeSearchUrl)
			.then((res) => {
				if (res.statusCode === 200) {
					if (res.body.data) return res.body.data
					return []
				}
			})
			.catch((err) => {
				return err
				throw new Error(err)
			})
	},
}
