import { $request } from './requester'
/**
 * @model Tags
 */
export default {
	/**
	 * GET `/tags`
	 * @async
	 * @returns {Tag[]}
	 */
	async getTags() {
		return $request('GET', '/tags').then((res) => {
			return res.body.data
		})
	},

	/**
	 * POST `/tags`
	 * @async
	 */
	async addTag(tag) {
		return $request('POST', '/tags', { tag: tag }).then((res) => {
			return res.body.data
		})
	},

	/**
	 * DELETE `/tags`
	 * @async
	 */
	async deleteTag(tag) {
		const key = tag.length > 1 ? 'tags' : 'tag'

		return $request('DELETE/JSON', '/tags', { [key]: tag }).then((res) => {
			return res.body
		})
	},

	/**
	 * PATCH `/tags`
	 * @async
	 */
	async updateTag(originalTag, temporaryTag) {
		return $request('PATCH', '/tags', { old_tag: originalTag, tag: temporaryTag }).then((res) => {
			return res.body.data
		})
	},
}
