<template>
	<div class="mb-6">
		<!-- Mobile personalization -->
		<div class="section flex flex-col gap-8">
			<span class="text-sm text-gray-dark uppercase"
				>{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.MOBILE_CUSTOM_SECTION') }}
			</span>
			<!-- Add contact button -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-add_contact color-part-two text-3xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.MOBILE_CUSTOM_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.MOBILE_CUSTOM_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessMobileQuickActionAddContact == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessMobileQuickActionAddContact == 'enabled'
								? $t('_COMMON.DISPLAY')
								: $t('_COMMON.HIDDEN')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
			<!-- Ask citizen button -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-question text-3xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.ASK_CITIZEN_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.ASK_CITIZEN_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessMobileQuickActionSurvey != 'disabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessMobileQuickActionAddContact != 'disabled'
								? $t('_COMMON.DISPLAY')
								: $t('_COMMON.HIDDEN')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
		</div>
		<!-- Teams -->
		<div v-if="accessMobilePersonalization == 'enabled'" class="section flex flex-col gap-8">
			<span class="text-sm text-gray-dark uppercase">
				{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.TEAMS_SECTION') }}
			</span>
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-group text-2xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.TEAMS_TITLE') }}</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.TEAMS_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<div
						class="flex items-center flex-shrink-0 gap-1 hover:opacity-70 cursor-not-allowed"
						@click="openChat"
					>
						<div class="text-sm">
							{{
								info_campagne.allow_multiple_team_select
									? $t('SETTINGS.MOBILE_APP.FIRST_TAB.TEAMS_MULTIPLE')
									: $t('SETTINGS.MOBILE_APP.FIRST_TAB.TEAMS_SINGLE')
							}}
						</div>
						<i class="gui-arrow_down text-2xl mt-0.5"></i>
					</div>
				</el-tooltip>
			</div>
		</div>
		<!-- Private info security -->
		<div class="section flex flex-col gap-8">
			<span class="text-sm text-gray-dark uppercase">
				{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.PRESONNAL_DATA_SECTION') }}
			</span>
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-lock text-3xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.PERSONNAL_DATA_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.PERSONNAL_DATA_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessConsentLaunchSurvey == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessConsentLaunchSurvey == 'enabled' ? $t('_COMMON.YES') : $t('_COMMON.NO')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
			<div class="flex bg-gray-lighted/50 border border-gray-light p-4 gap-3 rounded-2xl">
				<i class="gui-infos text-2xl -mt-1"></i>
				<div>
					{{ $t('SETTINGS.MOBILE_APP.FIRST_TAB.PERSONNAL_DATA_HELP') }}
				</div>
			</div>
		</div>
		<!-- Helper -->
		<div class="section flex gap-3">
			<i class="gui-infos text-2xl -mt-1"></i>
			<div>
				<div class="bold mb-1">{{ $t('_COMMON.HELP') }}</div>
				<div class="text-gray-strong">
					<span v-dompurify-html="$t('SETTINGS.MOBILE_APP.HELPER_PART_1')"></span>
					<span
						class="text-pink-strong underline cursor-pointer hover:opacity-70"
						@click="openChat"
					>
						{{ $t('SETTINGS.MOBILE_APP.HELPER_PART_2') }}</span
					>
					<span>{{ $t('SETTINGS.MOBILE_APP.HELPER_PART_3') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { openChat } from 'src/extensions/support'
import { computed } from 'vue'
import { useStore } from 'vuex'

// Data
const store = useStore()

// Computed
const accessMobilePersonalization = computed(
	() => store.getters['@packs/getAccessMobilePersonalization']
)
const accessMobileQuickActionAddContact = computed(
	() => store.getters['@packs/getAccessMobileQuickActionAddContact']
)
const accessConsentLaunchSurvey = computed(
	() => store.getters['@packs/getAccessConsentLaunchSurvey']
)
const accessMobileQuickActionSurvey = computed(
	() => store.getters['@packs/getAccessMobileQuickActionSurvey']
)

const info_campagne = computed(() => store.getters['info_campagne'])

// Method
openChat
</script>

<style lang="sass" scoped>
.section
	@apply w-full bg-white p-8 mt-2
	scroll-margin-top: 20px
</style>
