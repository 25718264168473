<template>
	<div class="flex flex-col h-full max-h-screen">
		<div id="q-header" class="q-header border-none shadow-sm">
			<div class="q-p-lg w-full">
				<sharing-header
					:method-toggle-dialog-add-doc-or-link-visible="methodToggleDialogAddDocOrLinkVisible"
					:method-add-modal-brief-visible="methodAddModalBriefVisible"
				></sharing-header>
			</div>
		</div>
		<div class="flex flex-grow flex-col force-flex-grow">
			<div class="w-full flex flex-col flex-grow">
				<keep-alive
					><router-view
						key="sharing"
						:dialog-add-doc-or-link-visible="dialogAddDocOrLinkVisible"
						:dialog-add-modal-brief-visible="dialogAddModalBriefVisible"
						:method-toggle-dialog-add-doc-or-link-visible="methodToggleDialogAddDocOrLinkVisible"
						:method-add-modal-brief-visible="methodAddModalBriefVisible"
					></router-view
				></keep-alive>
			</div>
		</div>
	</div>
</template>

<script>
import SharingHeader from './sharing-header'

export default {
	name: 'General',
	components: { SharingHeader },

	data() {
		return {
			dialogAddDocOrLinkVisible: false,
			dialogAddModalBriefVisible: false,
		}
	},

	methods: {
		methodToggleDialogAddDocOrLinkVisible(value) {
			this.dialogAddDocOrLinkVisible = value
		},

		methodAddModalBriefVisible(value) {
			this.dialogAddModalBriefVisible = value
		},
	},
}
</script>

<style lang="scss">
.force-flex-grow {
	flex-grow: 1;
	background-color: 'red';
}
// @import '../../assets/scss/settings/settings.scss';
</style>
