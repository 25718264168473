<template>
	<i
		:class="[`qi-${icon}`, { 'quorum-themed': quorumThemed }]"
		:style="{ fontSize: `${size}rem` }"
	></i>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			required: true,
		},
		size: {
			type: Number,
			required: false,
			default: 0.8,
		},
		quorumThemed: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/shared/shared-constants';
i[class^='qi-'] {
}
.quorum-themed {
	border: 1px solid $pink-quorum; //todo finish refacto
	border-radius: 0.5em;
	padding: 1em;
	margin: 0 0.5rem;
	border: 1px solid $pink-quorum;
	color: $pink-quorum;
	&:hover {
		background-color: $pink-quorum;
		color: white;
		transition: 200ms;
	}
}
</style>
