<template>
	<div>
		<el-dialog
			v-model="dialogVisible"
			:title="$t('SETTINGS.PROFILE.EMAILS.SYNC_ADVERTISING_TITLE')"
			width="50%"
		>
			<span v-dompurify-html="$t('SETTINGS.PROFILE.EMAILS.SYNC_ADVERTISING')"></span>
			<template #footer>
				<span class="dialog-footer">
					<q-button class="ghost basic-black mr-1" @click="dialogVisible = false">{{
						$t('_COMMON.CANCEL')
					}}</q-button>
					<q-button type="primary" @click="methodConnectEmailBox">{{
						$t('_COMMON.CONTINUE')
					}}</q-button>
				</span>
			</template>
		</el-dialog>
		<div v-if="isEmailBoxConnected || isEmailBoxConnecting">
			<div class="mt-1 bg-white px-8 py-6">
				<div class="text-xl bold pb-4">{{ $t('SETTINGS.PROFILE.EMAILS.TITLE') }}</div>
				<div v-dompurify-html="$t('SETTINGS.PROFILE.EMAILS.SUBTITLE')"></div>
			</div>
			<div class="mt-1 bg-white px-8 py-6">
				<div class="flex justify-between items-center">
					<div class="flex items-center">
						<span class="text-gray-500 text-sm">{{
							$tc('SETTINGS.PROFILE.EMAILS.LABEL', emailAccounts.length)
						}}</span>
						<div
							class="flex items-center px-2 rounded-lg ml-2 cursor-pointer"
							:class="{
								'bg-gray-100 opacity-75 pointer-events-none cursor-not-allowed':
									isRefreshRequestLimited,
							}"
						>
							<i
								class="gui-reload text-lg text-gray-500 transition-all transform hover:scale-125"
								@click="methodRefreshAccountsStatus"
							></i>
							<span v-if="isRefreshRequestLimited" class="ml-2">
								{{ getCounterLimit }}
							</span>
						</div>
					</div>
					<q-button @click="dialogVisible = true">{{
						$t('SETTINGS.PROFILE.EMAILS.CONNECT_ANOTHER_ACCOUNT_BUTTON')
					}}</q-button>
				</div>
				<profile-account-connected
					v-for="(account, index) in emailAccounts"
					:key="index"
					:email-account="account"
					:show-divider="index !== 1 && index !== emailAccounts.length - 1"
					@deleteAccount="methodDeleteEmailBoxConnection"
					@changeAccountSharing="methodChangeAccountSharing"
				></profile-account-connected>
			</div>
		</div>
		<div
			v-else
			class="py-6 px-6 bg-white flex items-center justify-between border-t-3 lg:border-t-0 border-b-4 border-pink-lighted"
		>
			<div class="flex items-center">
				<div class="flex flex-col pr-10">
					<div class="text-xl bold pb-4">{{ $t('SETTINGS.PROFILE.EMAILS.TITLE') }}</div>
					<div v-dompurify-html="$t('SETTINGS.PROFILE.EMAILS.SUBTITLE')"></div>
				</div>
			</div>
			<router-link
				:to="{
					name: 'profile-emails',
				}"
			>
				<q-button class="ghost basic-black" @click="dialogVisible = true">{{
					$t('SETTINGS.PROFILE.EMAILS.CONNECT_ANOTHER_ACCOUNT_BUTTON')
				}}</q-button>
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		profileAccountConnected: defineAsyncComponent(() => import('./profile-account-connected.vue')),
		// containerPacker: defineAsyncComponent(() => import('../../packs/containerPacker.vue')),
	},
	props: {
		emailAccounts: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	data() {
		return {
			value: false,
			dialogVisible: false,
		}
	},
	computed: {
		...mapGetters(['userConnected']),
		...mapGetters('@user', ['isEmailBoxConnecting', 'isEmailBoxConnected']),
		...mapGetters('@contact', ['isRefreshRequestLimited', 'getCounterLimit']),
		computedIsSharedWithMembers() {
			return this.value
		},
	},
	methods: {
		...mapActions('@user', [
			'actionChangeAccountSharing',
			'actionConnectBox',
			'actionDeleteBox',
			'actionGetEmailBoxStatus',
		]),
		...mapActions('@contact', ['actionHandleRequestLimitCounter']),

		methodConnectEmailBox() {
			this.dialogVisible = false
			this.actionConnectBox()
		},
		methodDeleteEmailBoxConnection(accountId) {
			this.actionDeleteBox(accountId)
		},
		methodChangeAccountSharing(payload) {
			const userId = this.userConnected.id
			this.actionChangeAccountSharing({ payload, userId })
		},
		methodRefreshAccountsStatus() {
			const requestLimitInSeconds = 30
			this.actionHandleRequestLimitCounter(requestLimitInSeconds)
			this.actionGetEmailBoxStatus({ userId: this.userConnected.id })
		},
	},
}
</script>

<style lang="sass" scoped>
.secondary-text
	@apply text-sm text-gray-400
</style>
