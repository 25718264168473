import get from 'lodash/get'
import store from './../store/stateStore'
import callToAction from 'components/action-module/callToAction.vue'
import ActionCreationType from 'components/action-module/callToAction/ActionCreationType/ActionCreationType.vue'
import ActionCreationTarget from 'components/action-module/callToAction/ActionCreationTarget/ActionCreationTarget.vue'
import ActionCreationPrecision from 'components/action-module/callToAction/ActionCreationPrecision/ActionCreationPrecision.vue'
import ActionCreationPlace from 'components/action-module/callToAction/ActionCreationPlace/ActionCreationPlaceContainer.vue'
import ActionCreationNameSchedule from 'components/action-module/callToAction/ActionCreationNameSchedule/ActionCreationNameSchedule.vue'
import ActionCreationMoreInformation from 'components/action-module/callToAction/ActionCreationMoreInformation/ActionCreationMoreInformation.vue'
import ActionCreationSelectMembers from 'components/action-module/callToAction/ActionCreationSelectMembers/ActionCreationSelectMembers.vue'
import ActionCreationFilterContacts from 'components/action-module/callToAction/ActionCreationFilterContacts/ActionCreationFilterContacts.vue'
import ActionCreationSelectTags from 'components/action-module/callToAction/ActionCreationSelectTags/ActionCreationSelectTags.vue'
import ActionAssignmentMain from 'components/action-module/callToAction/ActionAssignment/ActionAssignmentMain'
import ActionAssignmentWho from 'components/action-module/callToAction/ActionAssignment/ActionAssignmentWho'
import ActionCreationAboutMessage from 'components/action-module/callToAction/ActionCreationAboutMessage/ActionCreationAboutMessage.vue'

export const actionCreationRoutesAvailables = (rootName) => [
	`${rootName}type`,
	`${rootName}target`,
	`${rootName}precision`,
	`${rootName}place`,
	`${rootName}name_schedule`,
	`${rootName}more_information`,
	`${rootName}select_members`,
	`${rootName}filter_contacts`,
	`${rootName}select_tags`,
	`${rootName}about_messages`,
]

export default (rootPath, rootName) => {
	return [
		{
			name: `new/action-${rootName}`,
			path: 'new',
			redirect: { name: `${rootName}type` },
			component: callToAction,
			beforeEnter: (to, from, next) => {
				// Role access check
				if (store.getters['userConnected']?.role_data?.access?.includes('action:write')) {
					// Route origin check
					if (!from.name || from.name === 'waiting-page') {
						next({ path: rootPath })
					}
					else if (
						get(from, 'query.action_created') === 1
						&& !to.path.includes('new/action/type')
					) {
						next(from)
					}
					else {
						next()
					}
				}
				else {
					next({ name: 'command-center', replace: true })
				}
			},
			children: [
				{
					path: `${rootPath}/new/action/type`,
					name: `${rootName}type`,
					component: ActionCreationType,
				},
				{
					path: `${rootPath}/new/action/target`,
					name: `${rootName}target`,
					component: ActionCreationTarget,
				},
				{
					path: `${rootPath}/new/action/precision`,
					name: `${rootName}precision`,
					component: ActionCreationPrecision,
				},
				{
					path: `${rootPath}/new/action/place`,
					name: `${rootName}place`,
					component: ActionCreationPlace,
				},
				{
					path: `${rootPath}/new/action/name_schedule`,
					name: `${rootName}name_schedule`,
					component: ActionCreationNameSchedule,
				},
				{
					path: `${rootPath}/new/action/about_message`,
					name: `${rootName}about_message`,
					component: ActionCreationAboutMessage,
				},
				{
					path: `${rootPath}/new/action/more_information`,
					name: `${rootName}more_information`,
					component: ActionCreationMoreInformation,
				},
				{
					path: `${rootPath}/new/action/select_members`,
					name: `${rootName}select_members`,
					component: ActionCreationSelectMembers,
				},
				{
					path: `${rootPath}/new/action/filter_contacts`,
					name: `${rootName}filter_contacts`,
					component: ActionCreationFilterContacts,
				},
				{
					path: `${rootPath}/new/action/select_tags`,
					name: `${rootName}select_tags`,
					component: ActionCreationSelectTags,
				},
				{
					path: `${rootPath}/new/action/assignment`,
					name: `${rootName}assignment`,
					component: ActionAssignmentMain,
				},
				{
					path: `${rootPath}/new/action/assignment_who`,
					name: `${rootName}assignment_who`,
					component: ActionAssignmentWho,
				},
			],
		},
	]
}
