<template>
	<div :class="{ 'q-col unset start': column }">
		<q-radio
			v-for="choice in choices"
			:key="String(choice.value)"
			v-model="computedModelValue"
			:value="String(choice.value)"
			:name="name"
			:disabled="choice.disabled"
		>
			{{ choice.label }}
		</q-radio>
	</div>
</template>

<script>
export default {
	props: {
		modelValue: { type: [String, Number, Boolean], default: '' },

		column: {
			type: Boolean,
			default: false,
		},
		choices: {
			type: Array,
			required: true,
		},
		name: {
			type: String,
			default: '',
		},
	},

	emits: ['update:model-value'],

	computed: {
		computedModelValue: {
			get() {
				return this.modelValue
			},

			set(newVal) {
				this.$emit('update:model-value', newVal)
			},
		},
	},
}
</script>
