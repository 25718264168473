import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, resolveComponent as _resolveComponent, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full overflow-hidden flex-grow" }
const _hoisted_2 = { class: "text-4xl mt-8 font-title" }
const _hoisted_3 = { class: "mb-4 mt-2 text-gray-400 w-full" }
const _hoisted_4 = {
  key: 0,
  class: "pb-4 pt-2 flex flex-col flex-grow"
}
const _hoisted_5 = { class: "flex flex-col pb-6" }
const _hoisted_6 = { class: "font-title-bold text-2xl" }
const _hoisted_7 = { class: "text-gray-500 text-md" }
const _hoisted_8 = { class: "leading-tight" }
const _hoisted_9 = { class: "font-title text-xl leading-5" }
const _hoisted_10 = { class: "text-sm leading-5" }
const _hoisted_11 = {
  style: {},
  class: "sticky top-0 w-full z-50 bg-white py-3"
}
const _hoisted_12 = { class: "flex flex-row" }
const _hoisted_13 = { class: "flex flex-grow gap-2 items-center justify-end" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-row items-center w-full justify-end mt-9 mb-6"
}
const _hoisted_15 = { class: "flex-grow flex items-start justify-start gap-4" }
const _hoisted_16 = { class: "el-dropdown-link flex items-center" }
const _hoisted_17 = { class: "pl-2" }
const _hoisted_18 = { class: "text-xs px-3 uppercase text-gray-400" }
const _hoisted_19 = { class: "mx-3 items-center justify-center flex" }
const _hoisted_20 = { class: "text-xs px-3 uppercase text-gray-400" }
const _hoisted_21 = {
  key: 1,
  class: ""
}
const _hoisted_22 = { class: "mx-3 items-center justify-center flex" }
const _hoisted_23 = { class: "text-xs px-3 uppercase text-gray-400" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "leading-tight" }
const _hoisted_26 = { class: "font-title text-xl leading-3" }
const _hoisted_27 = { class: "font-title text-xl flex items-center" }
const _hoisted_28 = ["innerHTML", "href"]
const _hoisted_29 = { class: "text-sm" }
const _hoisted_30 = { class: "flex flex-col text-white rounded-full w-auto justify-end items-end pb-4" }
const _hoisted_31 = { class: "font-extralight py-1" }
const _hoisted_32 = {
  key: 1,
  class: "flex flex-col text-black w-auto justify-end items-center py-5"
}
const _hoisted_33 = {
  key: 0,
  class: "w-10 h-10 flex items-center bg-white rounded-[10px] shadow-lg"
}
const _hoisted_34 = {
  key: 1,
  class: "w-10 h-10 flex items-center bg-white rounded-[10px] shadow-lg"
}
const _hoisted_35 = { class: "py-5" }

import { useStore } from 'vuex'
import { cloneDeep, isEmpty, isNumber, orderBy, intersection, round, uniqBy, isEqual } from 'lodash'
import { addActionPolygon, updateCircle, removeAllMapMouseMoveHandlers, startUpdateCircleFromMap, getCircleData, userIsInsideArea, updateAiPolygonArea, addCheckedClassToSelectedUsers, addLockedClassToSelectedUsers, addCheckedClassToSelectedActionLeaders, resetMarkerCheckedClass, setDotEndOfLineVisibility, removeGrayScaledClassToSelectedUsers, addGrayscaledClassInAllUsers, getCenterOfPolygon, setCircleLayerVisibility, colorActionPolygon, getRightmostPointFromCircle, updateLine, getMaxDistanceFromCenterToPolygon, setLineVisibility, addLockedByTeamClassToUsers, adjustZoomAndView, transformPointToMiniPolygon, setAiLayerVisibility, addUsernameTooltipClassUsers, mappingUnits, methodSetDefaultPolygonBasedOnGeoHashContacts } from '../../../../extensions/maplibregl/members_handlers.ts'
import * as turf from '@turf/turf'
import { getDefaultUnitByGeoCountry } from '@quorumsco/quorum-helpers/lib/actions/index'
import { computed, defineAsyncComponent, provide, nextTick, onMounted, onUnmounted, ref, reactive, watch, onBeforeUnmount } from 'vue'
import { methodFilterPrivateTeams } from '../../../user_management-module/user-list/sub-components/utils'
import getUsersPopulatedByTeams from '../../../user_management-module/utils/getUsersPopulatedByTeams'
import { is } from 'date-fns/locale'
import { generatePolygon } from '../../../../extensions/maplibregl/polygonGenerator.ts'
import { latLngIsInNorthPole } from '../../../../utils/geo'
import analytics, { TRACKING } from '../../../../external/analytics'
import { ElMessage } from 'element-plus'

import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Feature, Point, Polygon } from '@turf/turf'


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionAssignmentMain',
  props: /*@__PURE__*/_mergeModels({
	currentActionData: {
		type: Array,
		default: () => null,
	},
	methodSetCurrentActionFromAssignment: {
		type: Function,
		default: () => {},
	},
	actionDetailMapData: {
		type: Object,
		default: () => {},
	},
	isNewAction: {
		type: Boolean,
		default: true,
	},
	meetingPoint: {
		type: Array,
		default: () => [],
	},
	activeStatus: {
		type: Object,
		default: () => {},
	},

}, {
    "disabledAiSelection": {
	type: Boolean,
	default: false,
},
    "disabledAiSelectionModifiers": {},
    "selectedUsersEdition": {
	type: Boolean,
	default: false,
},
    "selectedUsersEditionModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['update:nextValidatorBoolean', 'update:nextFunction', 'update:nextLabelButton'], ["update:disabledAiSelection", "update:selectedUsersEdition"]),
  setup(__props, { emit: __emit }) {


const store = useStore()

const emit = __emit

const users = computed(() => {
	const allUsers = store.getters['users']

	if (!allUsers) {
		return []
	}
	else {
		return allUsers.map((user) => {
			if (props.activeStatus && !isEmpty(props.activeStatus) && props.activeStatus[user.id]) {
				return { ...user, active: props.activeStatus[user.id] }
			}
			else {
				return {
					...user,
					active: null,
				}
			}
		})
	}
})

const getGeoCodageCountry = computed(() => store.getters['@group/getGeocodageCountry'])
const usersWithDistances = computed(() => returnUsersWithDistances())
const dropdownVisibleSort = ref(false)
const whichListDisplay = ref('usersAndTeams')
const userConnected = computed(() => store.getters['userConnected'])
const getTeams = computed(() => methodFilterPrivateTeams(store.getters['@team/getTeams'], userConnected.value))
const newAction = computed(() => store.getters['@action/newAction'])
const localMeetingPoint = ref([])
const actionType = ref([])
const getSearchAddressIncluded = computed(() => store.getters['@search/getSearchAddressIncluded'])
const getSearchPollingStationsIncluded = computed(() => store.getters['@search/getSearchPollingStationsIncluded'])
const getAllowUnknowAddressesOnActionCreation = computed(() => store.getters['@search/getAllowUnknowAddressesOnActionCreation'])
const getNewActionAssignmentType = computed(() => store.getters['@action/getNewActionAssignmentType'])
const getSkipBeforeRouteLeaveActionCreation = computed(() => store.getters['@action/getSkipBeforeRouteLeaveActionCreation'])

const computedGeohashArray = computed(() => store.getters['geohash_array_clean'])
const computedGeohashArrayNotClean = computed(() => store.getters['geohash_array'])
const mapFullyLoaded = ref(false)

const router = useRouter()

const disabledAiSelection = _useModel(__props, 'disabledAiSelection')

const selectedUsersEdition = _useModel(__props, 'selectedUsersEdition')

const props = __props

const latestListPart = ref('listing')
const dialogResetToAiVisible = ref(false)
const route = useRoute()
const selectedUsers = ref([])
const preSelectedUsers = ref([])
const preSelectedTeams = ref([])
const selectedActionLeaders = ref([])
const sortOrder = ref('asc')
const sortTeamsOrder = ref('asc')
const sortTeamsAndUsersOrder = ref('asc')
const sortOrderValues = ['asc', 'desc', 'asc_name', 'desc_name']

const sortOrderTeamValues = ['asc_name', 'desc_name']
const sortOrderTeamAndUsersValues = ['asc', 'desc', 'asc_name', 'desc_name']
const distanceTargetSort = ref({
	allValues: ['polygonCenter', 'meetingpoint'],
	currentValue: 'meetingpoint',
	mappingKey: {
		polygonCenter: 'distanceToPolygonCenter',
		meetingpoint: 'distanceToMeetingPoint',
	},
})
const distanceTargetUnits = ref({
	allValues: ['km', 'miles'],
	currentValue: 'km',
})

const usersDistancesByTypesCpy = ref([])
const currentZoomAndViewCoords = ref(null)
const lockedUsersAlreadySelectedByTeam = ref({})
const selectedTeams = ref([])
const activeNames = ref(['result-search'])
const mapInstance = ref(null)
const searchQuery = ref('')
const searchBarIsOpen = ref(false)
const cardMoreDetails = ref({
	type: 'user',
	itemId: null,
	visible: false,
})

const aiIsUseless = ref(false)
const aiIsUselessCardVisibile = ref(false)

const aiPolygon = ref(null)
const activeMouseMoveDetection = ref(false)

const showFilters = ref(false)

const whichPartDisplay = ref('listing')
const isAiSettings = ref(true)

const { t } = useI18n()

const selectionTypes = ref({
	allValues: [
		{ value: 'customSelection', label: computed(() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.CUSTOM_SELECTION')), icon: 'gui-ministars text-xl' },
		{ value: 'allUsers', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.ALL_USERS')), icon: '' },
		{ value: 'open', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.OPEN')), icon: '' },
	],
	currentValue: 'customSelection',
})

const usersSuggestionsList = ref([])

const methodSetWichListDisplay = (aValue) => {
	whichListDisplay.value = aValue
	if (aValue === 'usersAndTeams') {
		concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
	}
}

const methodUpdateLeadersAction = (aNewLeader) => {
	let	findedCurrentSelectedUser = selectedUsers.value.find((user) => user.id === aNewLeader.id)
	let aNewLeaderUser = usersWithDistances.value.find((user) => user.id === aNewLeader.id)

	if (!findedCurrentSelectedUser) {
		if (selectedActionLeaders.value.length < leadersMaxLength.value) {
			if (aNewLeaderUser) {
				selectedUsers.value = [...selectedUsers.value, aNewLeaderUser]
			}

			if (aNewLeaderUser?.id) {
				methodUpdateLeadersAction(cloneDeep(aNewLeaderUser))
			}
		}
	}
	else {
		if (methodReturnOnlyIds(selectedActionLeaders.value).includes(aNewLeader.id)) {
			findedCurrentSelectedUser.isActionLeader = false
			selectedActionLeaders.value = selectedActionLeaders.value.filter((leader) => leader.id !== aNewLeader.id)
		}
		else {
			if (selectedActionLeaders.value.length < leadersMaxLength.value) {
				selectedActionLeaders.value = [...selectedActionLeaders.value, aNewLeaderUser]
				findedCurrentSelectedUser.isActionLeader = true
			}
		}
	}

	store.dispatch('@action/actionHandleNewActionLeaders', selectedActionLeaders.value || [])
	methodSetAllMarkerClasses()
}

const toggleDropdownSort = () => {
	dropdownVisibleSort.value = !dropdownVisibleSort.value // Bascule l'affichage
}

const methodSetAllMarkerClasses = () => {
	const allMembersInTeamsIds = selectedTeams.value.flatMap((team) => {
		const memberIds = team.users.map((user) => user.id)
		const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
		return [...memberIds, ...leaderIds]
	})

	const usersInteamsForLockByTeams = usersWithDistances.value.filter((aUser) => allMembersInTeamsIds.includes(aUser.id))

	resetMarkerCheckedClass()
	addCheckedClassToSelectedUsers(selectedUsers.value)
	addCheckedClassToSelectedActionLeaders(selectedActionLeaders.value)
	addLockedByTeamClassToUsers(usersInteamsForLockByTeams)
	removeGrayScaledClassToSelectedUsers(selectedUsers.value)
}

const methodDistanceTargetChange = (aFilter) => {
	removeAllMapMouseMoveHandlers(mapInstance.value)
	activeMouseMoveDetection.value = false
	const valueSelected = cloneDeep(aFilter).currentValue
	let whichCircle = valueSelected === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	let tmpPolygon = valueSelected === 'meetingpoint' ? transformPointToMiniPolygon(localMeetingPoint.value[0]) : newActionPolygon.value
	adjustZoomAndView(mapInstance.value, tmpPolygon, adjustZoomAndViewCallBack)
	switch (valueSelected) {
		case 'meetingpoint':
			activeMouseMoveDetection.value = true
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'visible', whichCircle)
			break
		default:
			activeMouseMoveDetection.value = true
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingPoint')
			setCircleLayerVisibility(mapInstance.value, 'visible', whichCircle)
			break
	}
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue
	startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle, currentDistanceUnitChoice)
}
const methodDistanceUnitChange = (aFilter) => {
	removeAllMapMouseMoveHandlers(mapInstance.value)

	methodAreaRangeChange(0)
	methodAreaRangeChange(0)
	updateCircleFromFilter(0)

	activeMouseMoveDetection.value = false
	activeMouseMoveDetection.value = true

	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	let tmpPolygon = filterDistanceTarget.currentValue === 'meetingpoint' ? transformPointToMiniPolygon(localMeetingPoint.value[0]) : newActionPolygon.value
	adjustZoomAndView(mapInstance.value, tmpPolygon, adjustZoomAndViewCallBack)
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')

	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	const mapRangeConverted = methodConvertUnits(computedMapRange.value.currentValue, currentDistanceUnitChoice)
	computedMapRange.value.currentValue = mapRangeConverted

	startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle, currentDistanceUnitChoice)
	computedMapRange.value.maxValue = methodConvertUnits(100, currentDistanceUnitChoice)
}

const methodSelectTeamOrUser = (aFilter) => {
	const cpyCurrentValue = cloneDeep(aFilter.currentValue)
	methodResetAllOwnSelectionFilters()
	switch (cpyCurrentValue) {
		case 'teamsOnly':
			preSelectedUsers.value = []
			preSelectedTeams.value = getTeams.value

			break
		case 'usersOnly':
			preSelectedTeams.value = []
			preSelectedUsers.value = usersWithDistances.value
			break

		case 'teamsAndUser':
			preSelectedTeams.value = getTeams.value
			preSelectedUsers.value = usersWithDistances.value
			break

		default:
			break
	}
	aFilter.currentValue = cpyCurrentValue
}

const methodAllowAiSelection = () => {
	disabledAiSelection.value = false
}
const methodAreaModification = (aFilter) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	activeMouseMoveDetection.value = false
	let whichCircle
	let tmpPolygon
	switch (aFilter.currentValue) {
		case 'radius':
			activeMouseMoveDetection.value = true
			preSelectedUsers.value = []
			preSelectedTeams.value = []
			setCircleLayerVisibility(mapInstance.value, 'visible')
			setLineVisibility(mapInstance.value, 'visible')
			colorActionPolygon(mapInstance.value, '#000')

			if (filterDistanceTarget.currentValue === 'meetingpoint' && !isEmpty(localMeetingPoint.value[0])) {
				tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
				whichCircle = 'meetingPoint'
			}
			else {
				tmpPolygon = newActionPolygon.value
				whichCircle = 'polygon'
			}

			methodDistanceTargetChange(filterDistanceTarget)
			// startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle)
			preSelectedUsers.value = methodStartFilters()

			break
		case 'actionArea':
			preSelectedTeams.value = []
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#ff387f')
			adjustZoomAndView(mapInstance.value, newActionPolygon.value, adjustZoomAndViewCallBack)

			preSelectedUsers.value = methodStartFilters()
			break

		case 'allUsers':
			preSelectedTeams.value = []
			preSelectedUsers.value = usersWithDistances.value
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#000')
			preSelectedUsers.value = methodStartFilters()
			break

		default:
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#000')
			break
	}
}

const methodOwnSelection = () => {
	selectedTeams.value = []
	selectedUsers.value = cloneDeep(selectedActionLeaders.value)
	showFilters.value = false
	isAiSettings.value = false
	methodGoToSearchListing()
}

const methodClearAllSelection = () => {
	let allSelectedLeadersIds = methodReturnOnlyIds(selectedActionLeaders.value)
	let saveSelectedUsersWhoAreActionLeaders = cloneDeep(selectedUsers.value.filter((aUser) => allSelectedLeadersIds.includes(aUser.id)))
	selectedTeams.value = []
	selectedUsers.value = saveSelectedUsersWhoAreActionLeaders
	selectedActionLeaders.value = []
	const tempLeaders = usersWithDistances.value.filter((aUser) => allSelectedLeadersIds.includes(aUser.id))
	tempLeaders.forEach((element) => {
		methodUpdateLeadersAction(element)
	})

	// selectedActionLeaders.value = []
}

const methodAddUserOrTeamFromAi = () => {
	isAiSettings.value = false
	methodGoToSearchListing()
}

const methodTriggerCounterCard = () => {
	if (whichPartDisplay.value === 'listing') {
		methodGoToSearchListing()
	}
	else {
		methodGoToListing()
	}
}

const methodGoToListing = () => {
	showFilters.value = false
	methodResetAllOwnSelectionFilters()
	whichPartDisplay.value = 'listing'
	searchQuery.value = ''
	searchBarIsOpen.value = false
	setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	setAiLayerVisibility(mapInstance.value, 'none')
	colorActionPolygon(mapInstance.value, '#000')
	methodSetWichListDisplay('usersAndTeams')
}
const methodGoToFilterView = () => {
	latestListPart.value = whichPartDisplay.value
	whichPartDisplay.value = 'filters'
	searchQuery.value = ''
	searchBarIsOpen.value = false
	setCircleLayerVisibility(mapInstance.value, 'visible')
	setAiLayerVisibility(mapInstance.value, 'none')
}

const adjustZoomAndViewCallBack = (aNewZoomAndView) => {
	currentZoomAndViewCoords.value = aNewZoomAndView[0]
}

const methodGoToSearchListing = () => {
	whichPartDisplay.value = 'searchListing'
	searchQuery.value = ''
	searchBarIsOpen.value = true
	setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	colorActionPolygon(mapInstance.value, '#000')
}

const methodCloseMoreDetails = () => {
	cardMoreDetails.value.visible = false
	cardMoreDetails.value.itemId = null
	store.commit('@user/SET_ACTIVE_USER', {})
}

const methodMergedUsersAndUsersInTeams = (users, teams) => {
	const mergedUsers = [
		...users,
		...teams.flatMap((team) => team.users || []),
	]

	const uniqueUsers = uniqBy(mergedUsers, 'id')

	return uniqueUsers || 0
}

const methodGetDistanceToFeature = (point: Feature<Point>, feature: Feature<Point> | Feature<Polygon>) => {
	if (!feature.geometry) {
		console.error('Feature has no geometry:', feature)
		return undefined
	}

	if (feature.geometry?.type === 'Point') {
		return turf.distance(point, feature, { units: 'kilometers' })
	}

	if (feature.geometry?.type === 'Polygon') {
		if (turf.booleanPointInPolygon(point, feature)) {
			return 0
		}
		const polyBorder = turf.lineString(feature.geometry.coordinates[0])
		return turf.nearestPointOnLine(polyBorder, point, { units: 'kilometers' }).properties.dist
	}
}

const computedReturnTabCountNumberTeams = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? selectedTeams.value.length : getTeams.value.length
})

const computedReturnTabCountNumberUsers = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? selectedUsers.value.length : users.value.length
})

const computedReturnTabCountNumberUsersAndTeams = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? (selectedUsers.value.length || 0) + (selectedTeams.value.length || 0) : (users.value.length || 0) + (getTeams.value.length || 0)

	// methodMergedUsersAndUsersInTeams(users.value, getTeams.value).length || 0
})

const computedCurrentSortOrderValue = computed({
	get() {
		switch (whichListDisplay.value) {
			case 'users':
				return sortOrder.value

			case 'teams':
				return sortTeamsOrder.value

			case 'usersAndTeams':
				return sortTeamsAndUsersOrder.value

			default:
				return ''
		}
	},
	set(newValue) {
		switch (whichListDisplay.value) {
			case 'users':
				sortOrder.value = newValue
				break

			case 'teams':
				sortTeamsOrder.value = newValue
				break

			case 'usersAndTeams':
				sortTeamsAndUsersOrder.value = newValue
				break
		}
	},
})

const computedSortOrderValuesByWhichListDisplay = computed(() => {
	let values = []
	switch (whichListDisplay.value) {
		case 'users':
			values = sortOrderValues
			break
			// return sortOrderValues

		case 'teams':
			values = sortOrderTeamValues
			break
			// return sortOrderTeamValues

		case 'usersAndTeams':
			values = sortOrderTeamAndUsersValues
			break
			// return sortOrderTeamAndUsersValues

		default:
			return values
	}
	return methodRemoveDistanceSortPossibilitiesIfNoCenter(values)
})
// sortOrderValues

const computedHasCorrectMeetingPoint = computed(() => {
	return localMeetingPoint.value && !isEmpty(localMeetingPoint.value[0] && localMeetingPoint.value[0].latitude && localMeetingPoint.value[0].longitude)
})

const computedUserDistancesByType = computed(() => {
	const tmpUsersDistancesByTypesCpy = users.value.map((el) => {
		if (!el.location || !el.location.includes(',')) {
			return { ...el, locationError: 'No valid location provided' }
		}

		const [lat, lng] = el.location.split(',')
		const point = turf.point([Number(lng), Number(lat)])

		const userWithDistances = { ...el }
		if (computedHasCorrectMeetingPoint.value) {
			const meetingPointGeoJSON = convertToGeoJSONPoint(localMeetingPoint.value[0])
			userWithDistances.distanceToMeetingPoint = methodGetDistanceToFeature(point, meetingPointGeoJSON)
		}

		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			let polygonFormated = convertToGeoJSONPolygon(newActionPolygon.value)

			userWithDistances.distanceToPolygonCenter = methodGetDistanceToFeature(point, turf.center(polygonFormated))
			userWithDistances.distanceToPolygonBorder = methodGetDistanceToFeature(point, polygonFormated)
		}

		return userWithDistances
	})

	return tmpUsersDistancesByTypesCpy
})

const convertToGeoJSONPoint = (meetingPoint) => {
	if (meetingPoint) {
		return turf.point([parseFloat(meetingPoint.longitude), parseFloat(meetingPoint.latitude)])
	}
	else {
		return null
	}
}

const methodRemoveDistanceSortPossibilitiesIfNoCenter = (sortValues) => {
	const hasPolygon = computedNewActionPolygon.value && !isEmpty(computedNewActionPolygon.value)
	const hasMeetingPoint = computedHasCorrectMeetingPoint.value

	if (!hasPolygon && !hasMeetingPoint) {
		sortOrder.value = 'asc_name'
		sortTeamsOrder.value = 'asc_name'
		sortTeamsAndUsersOrder.value = 'asc_name'

		return sortValues.filter((aValue) => aValue !== 'asc' && aValue !== 'desc')
	}
	else {
		return sortValues
	}
}

const convertToGeoJSONPolygon = (polygon) => {
	const coordinates = polygon.map((point) => [point.lng, point.lat])

	// Fermer le polygone en répétant le premier point à la fin si nécessaire
	if (coordinates[0][0] !== coordinates[coordinates.length - 1][0]
		|| coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
		coordinates.push(coordinates[0])
	}

	return turf.polygon([coordinates])
}

const methodFindSmartDistanceToFeature = (distanceType: string) => {
	const levelDistanceKm = 0.250
	const maxDistanceKm = 25
	const sortedDistances = computedUserDistancesByType.value.map((obj) => obj[distanceType]).sort()
	const rawDistance = computedUserDistancesByType.value.length === 0 ? maxDistanceKm : computedUserDistancesByType.value.length === 1 ? sortedDistances.at(0) : computedUserDistancesByType.value.length > 2 && sortedDistances.at(2) - sortedDistances.at(1) <= levelDistanceKm ? sortedDistances.at(1) + levelDistanceKm : sortedDistances.at(1)

	usersDistancesByTypesCpy.value = cloneDeep(computedUserDistancesByType.value)
	const roundedDistance = Math.ceil(rawDistance / levelDistanceKm) * levelDistanceKm
	return Math.min(maxDistanceKm, roundedDistance)
}
const methodIncreaseFeatureByDistance = (feature: Feature<Point> | Feature<Polygon>, distanceKm: number) => {
	return turf.buffer(feature, distanceKm, { units: 'kilometers' })
}

// const methodSetDefaultPolygonBasedOnGeoHashContacts = (currentUnitValue, geoHashContactArray) => {
// 	if (!disabledAiForActionType.value.includes(actionType.value)) {
// 		if (geoHashContactArray && geoHashContactArray.length) {
// 			let units = mappingUnits(currentUnitValue)
// 			const hashes = geoHashContactArray
// 				.map(([lat, lng, count]) => ({ lat: Number(lat), lng: Number(lng), count }))
// 				.filter(({ lat, lng }) => !latLngIsInNorthPole(lat, lng))

// 			let pointFeatures = null
// 			let center = null
// 			if (hashes.length) {
// 				pointFeatures = hashes.map(({ lat, lng, count }) => turf.point([lng, lat], { count }))
// 			}

// 			if (pointFeatures) {
// 				center = turf.centerMedian(turf.featureCollection(pointFeatures), { weight: 'count' })
// 				pointFeatures.forEach((p) => p.properties.distanceToCenter = turf.distance(p, center, { units: units }))
// 				// We consider the distance to follow a log-normal distribution since all values are positive
// 				// https://en.wikipedia.org/wiki/Log-normal_distribution
// 				const distanceLogs = pointFeatures.map((p) => Math.log(p.properties.distanceToCenter))
// 				// Estimate the parameters mu and sigma of the log-normal distribution
// 				const distanceLogMean = distanceLogs.reduce((a, b) => a + b) / pointFeatures.length
// 				const distanceLogStd = Math.sqrt(distanceLogs.map((d) => Math.pow(d - distanceLogMean, 2)).reduce((a, b) => a + b) / pointFeatures.length)
// 				// Don't accept contacts too far away from the center of mass
// 				const maxAllowedDistanceKm = Math.max(10, Math.exp(distanceLogMean - 0.5 * distanceLogStd))
// 				const filteredPoints = pointFeatures.filter((p) => p.properties.distanceToCenter <= maxAllowedDistanceKm)

// 				if (filteredPoints && filteredPoints.length > 0) {
// 					const polygon = turf.convex(turf.featureCollection(filteredPoints))
// 					if (polygon) {
// 						// Extends the polygon, making it bigger, rounder and better looking :D (subjective)
// 						const buffered = turf.buffer(polygon, 500, { units: 'meters' })
// 						return buffered.geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }))
// 					}
// 				}
// 			}
// 		}
// 	}

// 	return null
// }

const newActionPolygon = ref(null)
const newLeadersAction = ref(store.getters['@action/getNewActionLeaders'] ? store.getters['@action/getNewActionLeaders'] : [])
const leadersMaxLength = ref(store.getters['@action/getNewActionLeadersMaxLength'] ? store.getters['@action/getNewActionLeadersMaxLength'] : 3)

const computedNewActionPolygon = computed(() => {
	return newActionPolygon.value
})

const concatenedSelectedTeamsAndUsers = ref([])
const methodConcatSelectedTeamsAndUsers = () => {
	concatenedSelectedTeamsAndUsers.value = [...selectedTeams.value, ...selectedUsers.value]

	return methodSortUsersAndTeams(concatenedSelectedTeamsAndUsers.value)
}

const disabledAiForActionType = ref(['call', 'event_online', 'share', 'sharing'])
const distanceTarget = ref('center')

const ownSelectionFilters = ref([
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'teamOrUser', values: ['usersOnly', 'teamsOnly', 'teamsAndUser'], currentValue: 'usersOnly', title: '', type: 'card', customMethod: (aFilter) => methodSelectTeamOrUser(aFilter) },
	{ disabledForActionType: ['call', 'event_online', 'share'], usedForFilterCount: true, category: 'selection', values: ['actionArea', 'radius'], currentValue: '', title: 'selection', type: 'card',
		customMethod: (aFilter) => methodAreaModification(aFilter), disabled: computed(() => computedDisabledSelection.value) },
	{
		disabledForActionType: [''], usedForFilterCount: false, category: 'ageRange', values: [18, 25, 35, 45, 55, 65], currentValue: 0, title: '', type: 'slider' },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceType', values: ['distance', 'time'], currentValue: 'distance', title: '', type: 'dropdown' },
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'mapRange', values: [], currentValue: 0, title: '', maxValue: 100 },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceUnit', values: ['km', 'miles'], currentValue: 'km', title: '', type: 'dropdown', customMethod: () => methodDistanceUnitChange() },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceTarget', values: ['polygonCenter', 'meetingpoint'], currentValue: '', title: '', type: 'dropdown', customMethod: (aFilter) => methodDistanceTargetChange(aFilter) },
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'disponibility', values: ['available_all_the_time', 'available_holidays', 'available_week_morning', 'available_week_night', 'available_weekend'], selectAllValue: 'available_all_the_time', currentValue: [], title: '', isMultiSelect: true, type: 'card', customMethod: (aFilter) => preSelectedUsers.value = methodStartFilters() },
])

const UserAvatar = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/UserAvatar.vue'),
)

const ActionAssignmentListing = defineAsyncComponent(
	() => import('./ActionAssignmentListing.vue'),
)

const cardDetailsInfos = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/sidebars/users/cardDetailsInfos.vue'),
)

const ActionAssignmentSearchListing = defineAsyncComponent(
	() => import('./ActionAssignmentSearchListing.vue'),
)
const ActionAssignmentMap = defineAsyncComponent(
	() => import('./ActionAssignmentMap.vue'),
)

const ActionAssignmentFilters = defineAsyncComponent(
	() => import('./ActionAssignmentFilters.vue'),
)
const ActionAssignmentSearchAndFilterHeader = defineAsyncComponent(
	() => import('./ActionAssignmentSearchAndFilterHeader.vue'),
)
const ActionAiAssignmentCard = defineAsyncComponent(
	() => import('./ActionAiAssignmentCard.vue'),
)
const ActionAssignmentSelectedCounterCard = defineAsyncComponent(
	() => import('./ActionAssignmentSelectedCounterCard.vue'),
)
const ActionAssignmentFilterContent = defineAsyncComponent(
	() => import('./ActionAssignmentFilterContent.vue'),
)

const CardSlideContainer = defineAsyncComponent(
	() => import('../../../general/CardSlideContainer.vue'),
)
const DeleteDialog = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/deleteDialog.vue'),
)

const methodUpdateCurrentActionFromAssignment = (data, type) => {
	let dataFormated = data

	if (type === 'users') {
		const allUsersIdsInTeams = selectedTeams.value.flatMap((team) => {
			const memberIds = team.users.map((user) => user.id)
			const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
			return [...memberIds, ...leaderIds]
		})

		const allActionLeaderIds = selectedActionLeaders.value.map((aLeader) => aLeader.id)

		dataFormated = dataFormated.filter((aUser) => !allUsersIdsInTeams.includes(aUser.id) && !allActionLeaderIds.includes(aUser.id))
	}

	props.methodSetCurrentActionFromAssignment(dataFormated, type)
}

const methodUpdateSelectedItemsActionFromAssignment = () => {
	methodUpdateCurrentActionFromAssignment(selectedTeams.value, 'teams')
	methodUpdateCurrentActionFromAssignment(selectedUsers.value, 'users')
	methodUpdateCurrentActionFromAssignment(selectedActionLeaders, 'leaders')
}

watch(selectedUsers, (newSelectedUsers) => {
	if (newSelectedUsers) {
		methodSetAllMarkerClasses()
		selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
		if (!props.isNewAction) {
			methodUpdateSelectedItemsActionFromAssignment()
		}
	}

	concatenedSelectedTeamsAndUsers.value = cloneDeep(methodConcatSelectedTeamsAndUsers())
})

watch(selectedTeams, (newSelectedTeams) => {
	if (newSelectedTeams) {
		methodSetAllMarkerClasses()
		if (!props.isNewAction) {
			methodUpdateSelectedItemsActionFromAssignment()
		}
	}

	concatenedSelectedTeamsAndUsers.value = cloneDeep(methodConcatSelectedTeamsAndUsers())
})
watch(selectedActionLeaders, (newSelectedActionLeaders) => {
	if (!props.isNewAction && newSelectedActionLeaders) {
		methodUpdateSelectedItemsActionFromAssignment()
	}
})

watch(preSelectedUsers, (newPreSelectedUsers, oldPreselectedUsers) => {
	if (newPreSelectedUsers && whichPartDisplay.value === 'filters') {
		const allMembersInTeamsIds = selectedTeams.value.flatMap((team) => {
			const memberIds = team.users.map((user) => user.id)
			const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
			return [...memberIds, ...leaderIds]
		})

		const usersInteamsForLockByTeams = usersWithDistances.value.filter((aUser) => allMembersInTeamsIds.includes(aUser.id))

		const usersWithNewCheck = computedDiffBeetweenSelectedAndPreselected.value.diffUsers
		resetMarkerCheckedClass()
		addUsernameTooltipClassUsers(users.value)
		addCheckedClassToSelectedUsers(selectedUsers.value)
		addLockedClassToSelectedUsers(selectedUsers.value)
		addCheckedClassToSelectedUsers(usersWithNewCheck)
		addLockedByTeamClassToUsers(usersInteamsForLockByTeams)

		selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
	}
})
watch(showFilters, (value) => {
	if (value && value === true && whichPartDisplay.value === 'filters') {
		let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
		if (computedHasCorrectMeetingPoint.value) {
			filterDistanceTarget.currentValue = 'meetingpoint'
		}
		else {
			if (newActionPolygon.value && newActionPolygon.value.length) {
				filterDistanceTarget.currentValue = 'polygonCenter'
			}
		}

		addLockedClassToSelectedUsers(selectedUsers.value)
	}
	else {
		methodSetAllMarkerClasses()
	}
	if (value && value === true) {
		methodRecenterViewFromPolygon()
	}
	methodCloseMoreDetails()
	methodResetAllOwnSelectionFilters()
	methodSetUsersAsDefaultFilters()
	methodSetWichListDisplay('usersAndTeams')
})

watch(mapFullyLoaded, (newVal, oldValue) => {
	if (newVal) {
		if (props.currentActionData || (disabledAiForActionType.value.includes(actionType.value) && (isEmpty(localMeetingPoint.value[0] && isEmpty(newActionPolygon.value))))) {
			isAiSettings.value = false
			methodResetToAiSelection()
			methodStartFromEditSelection()

			if (!props.isNewAction) {
				isAiSettings.value = false
				methodGoToListing()
			}
			else {
				isAiSettings.value = false
				methodGoToSearchListing()
				if (newLeadersAction.value.length) {
					newLeadersAction.value.forEach((aLeader) => {
						methodUpdateLeadersAction(cloneDeep(aLeader))
					})
				}
			}
		}

		if (isAiSettings.value) {
			if (newActionPolygon.value && !isEmpty(newActionPolygon.value) || !isEmpty(localMeetingPoint.value[0])) {
				if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
					addActionPolygon(mapInstance.value, newActionPolygon.value)
				}

				if (!disabledAiSelection.value) {
					methodStartFilters()
					methodResetToAiSelection()
				}
				else {
					methodAllowAiSelection()
					selectedUsers.value = methodStartFilters()
				}
			}
			else {
				if (!disabledAiForActionType.value.includes(actionType.value)) {
					isAiSettings.value = false
					aiIsUseless.value = true
					aiIsUselessCardVisibile.value = true
				}
				methodGoToSearchListing()
			}
		}
	}
	addUsernameTooltipClassUsers(users.value)
	selectedUsers.value = methodSortSelectedUsers()
})

watch(activeMouseMoveDetection, (newValue) => {
	if (!newValue || newValue === false) {
		removeAllMapMouseMoveHandlers(mapInstance.value)
	}
})

// Computed

const computedDisabledSelection = computed(() => {
	const hasPolygon = computedNewActionPolygon.value && !isEmpty(computedNewActionPolygon.value)
	const hasMeetingPoint = computedHasCorrectMeetingPoint.value

	let restrictions = []

	if (!hasPolygon) {
		restrictions.push('actionArea')
	}

	if (!hasPolygon && !hasMeetingPoint) {
		restrictions.push('radius')
	}

	return restrictions
})

const computedFiltersActiveLength = computed(() => {
	const activeFilters = cloneDeep(ownSelectionFilters.value.filter((aFilter) => {
		const currentValue = aFilter.currentValue

		return currentValue !== 0 && String(currentValue).length > 0 && aFilter.usedForFilterCount
	}) || [])
	return activeFilters.length
})
const computedMoreDetails = computed(() => {
	if (cardMoreDetails.value.type === 'user') {
		return usersWithDistances.value.find((user) => user.id === cardMoreDetails.value.itemId) || {}
	}
	else {
		return getTeams.value.find((team) => team.id === cardMoreDetails.value.itemId) || {}
	}
})

const computedDiffBeetweenSelectedAndPreselected = computed(() => {
	let diffTeams = preSelectedTeams.value.filter((aTeam) => !selectedTeams.value.find((selectedTeam) => selectedTeam.id === aTeam.id))
	const idsOfUsersInAllTeams = diffTeams.flatMap((team) => {
		const memberIds = team.users.map((user) => user.id)
		const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
		return [...memberIds, ...leaderIds]
	})

	let diffUsers = preSelectedUsers.value.filter((aUser) => !selectedUsers.value.find((selectedUser) => selectedUser.id === aUser.id) && !idsOfUsersInAllTeams.includes(aUser.id))

	// return true
	return { diffUsers, diffTeams }
})

const methodRecenterViewFromPolygon = () => {
	if (isAiSettings.value) {
		if (aiPolygon.value && !isEmpty(aiPolygon.value)) {
			const aiPolygonCoordinates = aiPolygon.value.geometry.coordinates[0]
			if (aiPolygonCoordinates && aiPolygonCoordinates.length) {
				const formattedCoordinates = aiPolygonCoordinates.map((coord) => [coord[0], coord[1]])
				adjustZoomAndView(mapInstance.value, formattedCoordinates, adjustZoomAndViewCallBack)
			}
		}
	}
	else {
		if (computedHasCorrectMeetingPoint.value) {
			const meetingPoint = convertToGeoJSONPoint(localMeetingPoint.value[0])
			if (meetingPoint) {
				const coordinates = [
					{
						lng: meetingPoint.geometry.coordinates[0],
						lat: meetingPoint.geometry.coordinates[1],
					},
				]
				adjustZoomAndView(mapInstance.value, coordinates, adjustZoomAndViewCallBack)
			}
		}
		else {
			const polygonCoordinates = cloneDeep(newActionPolygon?.value?.map((point) => [point.lng, point.lat])) || []
			if (polygonCoordinates && polygonCoordinates.length) {
				adjustZoomAndView(mapInstance.value, polygonCoordinates, adjustZoomAndViewCallBack)
			}
		}
	}
}

const submitAction = () => {
	const idsSelectedLeadersActionIds = methodReturnOnlyIds(selectedActionLeaders.value)
	const teamsIds = selectedTeams.value.map((team) => team.id)

	let usersIds = selectedUsers.value.map((user) => user.id)
	usersIds = usersIds.filter((userId) => !idsSelectedLeadersActionIds.includes(userId))

	const addressIncluded = cloneDeep(getSearchAddressIncluded.value)
	const pollingStationsIncluded = cloneDeep(getSearchPollingStationsIncluded.value)
	const allowUnknowAddressesOnActionCreation = cloneDeep(getAllowUnknowAddressesOnActionCreation.value)

	const needToBeEmptyAction = false

	store.dispatch('@action/actionSkipNavigationGuardBeforeRouteLeave', true)

	let clonedNewAction = cloneDeep(newAction.value)
	const payload = {
		needToBeEmptyAction,
		action: {
			...clonedNewAction,
			usersIds,
			teamsIds,
			notify: true,
		},
		payload: {
			addressIncluded,
			pollingStationsIncluded,
			allowUnknowAddressesOnActionCreation,
		},
	}

	store.dispatch('@action/actionCreateNewFieldAction', payload)

	// Track action created
	analytics.track(TRACKING.action_created, { type_action: clonedNewAction.type })

	router.push({
		name: 'actions',
		query: {
			action_created: 1,
		},
	})

	nextTick(() => {
		ElMessage({
			message: t('ACTION.CALL_TO_ACTION.SUBMIT_INFO'),
			type: 'info',
			showClose: true,
		})
	})
}

onMounted(() => {
	const { polygon } = props.actionDetailMapData || []

	let currentGeoCountry = getGeoCodageCountry.value || 'FRA'
	const defaultUnits = getDefaultUnitByGeoCountry(currentGeoCountry)

	if (defaultUnits === 'miles') {
		distanceTargetUnits.value.currentValue = defaultUnits
		let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
		filterDistanceUnit.currentValue = defaultUnits
	}

	const storePolygon = store.getters['@action/getNewActionPolygon']

	if (props.isNewAction) {
		localMeetingPoint.value = newAction.value?.Addresses || []
		actionType.value = newAction.value.type || ''
	}
	else {
		localMeetingPoint.value = props.currentActionData.Addresses || []
		actionType.value = props.currentActionData.type_data || ''
	}

	if (actionType.value === 'event' && (!localMeetingPoint.value[0] || isEmpty(localMeetingPoint.value[0]))) {
		actionType.value = 'event_online'
	}

	newActionPolygon.value = !isEmpty(polygon) && JSON.parse(polygon).length
		? JSON.parse(polygon)
		: !isEmpty(storePolygon)
				? storePolygon
				: methodSetDefaultPolygonBasedOnGeoHashContacts(distanceTargetUnits.value.currentValue, computedGeohashArray.value, disabledAiForActionType.value.includes(actionType.value))
	if (computedHasCorrectMeetingPoint.value) {
		methodChangeUsersDistanceTarget('meetingpoint')
		distanceTargetSort.value.currentValue = 'meetingpoint'
	}
	else {
		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			methodChangeUsersDistanceTarget('polygonCenter')
			distanceTargetSort.value.currentValue = 'polygonCenter'
		}
		else {
			distanceTargetSort.value.currentValue = ''
		}
	}

	emit('update:nextValidatorBoolean', true)
	emit('update:nextFunction', submitAction)

	if (props.isNewAction) {
		emit('update:nextLabelButton', t('ACTION.CALL_TO_ACTION.CREATE'))
	}
})

onBeforeUnmount(() => {
	// users.value = []
	mapFullyLoaded.value = false
	aiIsUseless.value = false
	aiIsUselessCardVisibile.value = false

	aiPolygon.value = null
	activeMouseMoveDetection.value = false

	showFilters.value = false

	whichPartDisplay.value = 'listing'
})

// methods
const closeDialogResetToAi = () => {
	dialogResetToAiVisible.value = false
}

const methodSetUsersAsDefaultFilters = () => {
	if (whichPartDisplay.value === 'filters') {
		let findedTeamAndUserFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'teamOrUser')
		if (findedTeamAndUserFilter && whichPartDisplay.value === 'filters') {
			findedTeamAndUserFilter.currentValue = 'usersOnly'
		}
	}
}

const methodResetToAiSelection = () => {
	selectionTypes.value.currentValue = 'customSelection'
	isAiSettings.value = true
	closeDialogResetToAi()
	showFilters.value = false

	whichPartDisplay.value = 'listing'
	if ((newActionPolygon.value && !isEmpty(newActionPolygon.value)) || (computedHasCorrectMeetingPoint.value)) {
		let resultStartAreaCircleFromAI = []

		if ((computedHasCorrectMeetingPoint.value)) {
			methodChangeUsersDistanceTarget('meetingpoint')
			resultStartAreaCircleFromAI = methodStartAreaCircleFromAi('meetingpoint')
		}

		if (!resultStartAreaCircleFromAI.length) {
			methodChangeUsersDistanceTarget('polygonCenter')
			resultStartAreaCircleFromAI = methodStartAreaCircleFromAi('polygon')
		}

		if (resultStartAreaCircleFromAI.length) {
			aiIsUselessCardVisibile.value = false
		}
		else {
			aiIsUselessCardVisibile.value = true
			aiIsUseless.value = true
			isAiSettings.value = false
			methodGoToSearchListing()
		}

		selectedUsers.value = resultStartAreaCircleFromAI

		const circleData = getCircleData(mapInstance.value)
		const endCoordsOfLine = getRightmostPointFromCircle(circleData)

		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			const center = getCenterOfPolygon(newActionPolygon.value)
			updateLine(mapInstance.value, center, endCoordsOfLine)
		}
	}
	else {
		if (props.isNewAction) {
			aiIsUselessCardVisibile.value = true
		}
	}
	selectedActionLeaders.value = []

	if (props.isNewAction) {
		let idsSelectedLeaders = methodReturnOnlyIds(newAction.value.leaders || [])

		const tempLeaders = usersWithDistances.value.filter((aUser) => idsSelectedLeaders.includes(aUser.id))

		tempLeaders.forEach((element) => {
			methodUpdateLeadersAction(element)
		})
	}
	methodRecenterViewFromPolygon()
}
const methodStartFromEditSelection = () => {
	let propsCurrentActionUsersIds = methodReturnOnlyIds(props.currentActionData?.Users || []) || []
	let propsCurrentActionTeamsIds = methodReturnOnlyIds(props.currentActionData?.Teams || []) || []
	let propsCurrentActionLeadersIds = methodReturnOnlyIds(props.currentActionData?.leaders || []) || []

	let allUsers = usersWithDistances.value.filter((aUser) => propsCurrentActionUsersIds.includes(aUser.id))
	let allLeaders = usersWithDistances.value.filter((aUser) => propsCurrentActionLeadersIds.includes(aUser.id))
	let allTeams = getTeams.value.filter((aTeam) => propsCurrentActionTeamsIds.includes(aTeam.id))

	selectedUsers.value = [...allUsers, ...allLeaders]
	selectedTeams.value = allTeams
	selectedActionLeaders.value = allLeaders

	methodGoToListing()
}

const methodStartAreaCircleFromAi = (whichElement = 'meetingpoint') => {
	selectedTeams.value = []
	let circleSizeLog

	if ((computedHasCorrectMeetingPoint.value) && whichElement === 'meetingpoint') {
		let formatedMeetingPoint = convertToGeoJSONPoint(localMeetingPoint.value[0])
		circleSizeLog = methodFindSmartDistanceToFeature('distanceToMeetingPoint')
		aiPolygon.value = methodIncreaseFeatureByDistance	(formatedMeetingPoint, circleSizeLog)
	}

	if ((newActionPolygon.value && !isEmpty(newActionPolygon.value)) && whichElement === 'polygon') {
		circleSizeLog = methodFindSmartDistanceToFeature('distanceToPolygonCenter')
		let polygonFormated = convertToGeoJSONPolygon(newActionPolygon.value)
		aiPolygon.value = methodIncreaseFeatureByDistance	(polygonFormated, circleSizeLog)
	}

	let usersFiltered = cloneDeep(usersWithDistances.value)
	updateAiPolygonArea(mapInstance.value, aiPolygon.value)
	setCircleLayerVisibility(mapInstance.value, 'none')
	setAiLayerVisibility(mapInstance.value, 'visible')

	// selectedUsers.value = methodFilterSelectedUserInsideCircleArea(usersFiltered)

	const aiResult = methodFilterSelectedUserInsideAiPolygon(usersFiltered)

	return aiResult
}

const methodDisplayUserOrTeamInfos = (item, type) => {
	if (cardMoreDetails.value.itemId === item.id && cardMoreDetails.value.type === type) {
		methodCloseMoreDetails()
	}
	else {
		cardMoreDetails.value.visible = true
		cardMoreDetails.value.itemId = item.id
		cardMoreDetails.value.type = type

		let user = usersWithDistances.value.find((aUser) => aUser.id === item.id)
		let team = getTeams.value.find((aTeam) => aTeam.id === item.id)

		if (type === 'user') {
			store.commit('@user/SET_ACTIVE_USER', user)
		}
		else {
			store.commit('@team/SET_ACTIVE_TEAM', team)
			store.commit('@team/DISPLAY_DETAIL')
		}
	}
}

const methodReturnOnlyIds = (arrOfUsers) => {
	return arrOfUsers.map((aUser) => aUser.id)
}

const methodToggleActionLeaderFromTeamInfosSlider = (aUser) => {
	return methodUpdateLeadersAction(aUser)
}

provide('action-leader-from-assignment-slide', (aUser) => methodToggleActionLeaderFromTeamInfosSlider(aUser))

const computedLeadersFull = computed(() => {
	return selectedActionLeaders.value.length >= leadersMaxLength.value
})

provide('leadersFull', computedLeadersFull)

const methodTriggerSortOrder = (selectedValue) => {
	computedCurrentSortOrderValue.value = selectedValue
	selectedUsers.value = methodSortSelectedUsers()
}
const methodToggleFilters = () => {
	showFilters.value = !showFilters.value
}

const methodMergePreselectedWithSelectedUsers = () => {
	selectedUsers.value = [...selectedUsers.value, ...computedDiffBeetweenSelectedAndPreselected.value.diffUsers]
	selectedTeams.value = [...selectedTeams.value, ...computedDiffBeetweenSelectedAndPreselected.value.diffTeams]
	preSelectedUsers.value = []
	preSelectedTeams.value = []

	whichPartDisplay.value = 'listing'
	methodToggleFilters()
}

const methodCancelPreselectedValues = () => {
	preSelectedUsers.value = []
	preSelectedTeams.value = []
	let latestView = latestListPart.value
	whichPartDisplay.value = latestView === 'filters' ? 'listing' : latestListPart.value
	methodToggleFilters()
}

const methodResetAllOwnSelectionFilters = () => {
	ownSelectionFilters.value.forEach((aFilter) => {
		if (aFilter.isMultiSelect) {
			aFilter.currentValue = []
		}
		else {
			if (isNumber(aFilter.currentValue)) {
				aFilter.currentValue = 0
			}
			else {
				if (aFilter.usedForFilterCount) {
					// if (aFilter.category === 'teamOrUser' && whichPartDisplay.value === 'filters') {
					// 	aFilter.currentValue = 'usersOnly'
					// }
					// else {
					aFilter.currentValue = ''
					// }
				}
			}
		}
	})

	preSelectedTeams.value = []
	preSelectedUsers.value = []
	setCircleLayerVisibility(mapInstance.value, 'none')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	colorActionPolygon(mapInstance.value, '#000')
}

const methodAreaRangeChange = (range) => {
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	const tmpRange = methodConvertUnits(range, currentDistanceUnitChoice)

	computedMapRange.value.currentValue = round(tmpRange)
	preSelectedUsers.value = methodStartFilters()
}

const methodAddDistanceBetweenUsersAndActionCenter = () => {
	let tmpPolygon
	if (distanceTargetSort.value.currentValue === 'polygonCenter') {
		tmpPolygon = newActionPolygon.value
	}
	else {
		if (!isEmpty(localMeetingPoint.value[0])) {
			tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
		}
	}
	if (tmpPolygon) {
		return getCenterOfPolygon(tmpPolygon)
	}
}

const calculateDistance = (pointOne, pointTwo) => {
	const distance = turf.distance(pointOne, pointTwo, { units: 'kilometers' })
	return Math.round(distance)
}

const methodConvertUnits = (distance, units) => {
	if (units === 'm') {
		return distance * 1000
	}
	else if (units === 'miles') {
		return Math.round(distance * 0.621371)
	}
	else {
		return distance
	}
}

const convertToKilometers = (distance, units) => {
	if (units === 'm') {
		return distance / 1000 // Convertir les mètres en kilomètres
	}
	else if (units === 'miles') {
		return distance / 0.621371 // Convertir les miles en kilomètres
	}
	else {
		return distance // Déjà en kilomètres
	}
}

const returnUsersWithDistances = () => {
	let centerOfAction
	let usersPopulatedByTeams
	// if (newActionPolygon.value) centerOfAction = methodAddDistanceBetweenUsersAndActionCenter()
	if (mapInstance.value === null) {
		usersPopulatedByTeams = getUsersPopulatedByTeams(users.value, getTeams.value)
	}
	else {
		let usersWithDistance = users.value.map((user) => {
			if (!user.location || (!newActionPolygon.value || isEmpty(newActionPolygon.value))) {
				user.selectedAt = Date.now()

				return user
			}
			else {
				const [lng, lat] = user.location.split(',').map(Number).reverse()

				let findedGreatKey = distanceTargetSort.value.mappingKey[distanceTargetSort.value.currentValue]
				let findedUser = usersDistancesByTypesCpy.value.find((aUser) => aUser.id === user.id)

				if (findedUser) {
					let result = Math.round(findedUser[findedGreatKey]) || null

					if (result) {
						result = methodConvertUnits(result, distanceTargetUnits.value.currentValue)
					}

					else {
						user.distance = null
					}
					user.distance = result
				}
				else {
					user.distance = null
				}

				user.selectedAt = Date.now()
				return user
			}
		})

		usersPopulatedByTeams = getUsersPopulatedByTeams(usersWithDistance, getTeams.value)

		const teamUserMap = {}

		selectedTeams.value.forEach((team) => {
			team.users.forEach((user) => {
				if (!teamUserMap[user.id]) {
					teamUserMap[user.id] = []
				}
				teamUserMap[user.id].push(team)
			})
		})

		usersPopulatedByTeams = usersPopulatedByTeams.map((user) => ({
			...user,
			isLockedByTeam: teamUserMap[user.id] || [],
		}))
	}

	usersPopulatedByTeams = methodSortUsers(usersPopulatedByTeams)

	usersSuggestionsList.value = cloneDeep(usersPopulatedByTeams.slice(0, 19))

	return usersPopulatedByTeams
}
const computedMapRange = computed(() => {
	const mapRangeFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'mapRange')

	return mapRangeFilter
})

const methodChangeUsersDistanceTarget = (aValueSelected) => {
	distanceTargetSort.value.currentValue = cloneDeep(aValueSelected)

	selectedUsers.value.forEach((aSelectedUser) => {
		aSelectedUser.distance = usersWithDistances.value.find((aUser) => aUser.id === aSelectedUser.id).distance
	})
	concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
}
const methodChangeUsersDistanceUnit = (aValueSelected) => {
	distanceTargetUnits.value.currentValue = cloneDeep(aValueSelected)

	selectedUsers.value.forEach((aSelectedUser) => {
		aSelectedUser.distance = usersWithDistances.value.find((aUser) => aUser.id === aSelectedUser.id).distance
	})
	concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
}

const methodSortUsers = (users) => {
	const allSelectedLeadersId = methodReturnOnlyIds(selectedActionLeaders.value)
	let result

	switch (sortOrder.value) {
		case 'selectedAt':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => new Date(user.selectedAt),
				],
				['asc', 'asc', 'desc'],
			)
			break

		case 'asc_name':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.surname,
				],
				['asc', 'asc', 'asc'],
			)
			break

		case 'desc_name':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.surname,
				],
				['asc', 'asc', 'desc'],
			)
			break

		default:
			// Tri par distance
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.distance,
				],
				['asc', 'asc', sortOrder.value],
			)
			break
	}

	return result
}

const methodSortedTeams = (teams) => {
	let result
	switch (sortTeamsOrder.value) {
		case 'selectedAt':

			result = orderBy(
				teams,
				[
					(item) => !item.selectedAt,
					(item) => item.selectedAt,
				],
				['desc'],
			)
			break
		case 'asc_name':
			result = orderBy(
				teams,
				[(item) => item.name],
				['asc'],
			)
			break
		case 'desc_name':

			result = orderBy(
				teams,
				[(item) => item.name],
				['desc'],
			)

			break

		default:
			result = orderBy(
				teams,
				[
					(item) => !item.distance,
					(item) => item.distance,
				],
				[
					'asc',
					sortTeamsOrder.value,
				],
			)
			break
	}

	return cloneDeep(result)
}

const methodSortUsersAndTeams = (arrayData) => {
	let result
	let data = arrayData ? arrayData : concatenedSelectedTeamsAndUsers.value

	const leaders = cloneDeep(data.filter((aItem) =>
		selectedActionLeaders.value.find((aLeader) => (aLeader.id === aItem.id) && !aItem.name),
	))

	const selectedUsersWithoutLeaders = data.filter(
		(aItem) =>
			!selectedActionLeaders.value.find((aLeader) => (aLeader.id === aItem.id) && !aItem.name),
	)

	switch (computedCurrentSortOrderValue.value) {
		case 'selectedAt':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(item) => !item.selectedAt,
					(item) => item.selectedAt,
				],
				['desc'],
			)
			break
		case 'asc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(item) => item.surname || item.name],
				['asc'],
			)
			break
		case 'desc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(item) => item.surname || item.name],
				['desc'],
			)

			break

		default:
			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(item) => !item.distance,
					(item) => item.distance,
				],
				[
					'asc',
					computedCurrentSortOrderValue.value,
				],
			)
			break
	}

	return cloneDeep([...leaders, ...result])
}

const methodSortSelectedUsers = () => {
	let result = []
	const leaders = cloneDeep(selectedUsers.value.filter((aUser) =>
		selectedActionLeaders.value.find((aLeader) => aLeader.id === aUser.id),
	))

	const selectedUsersWithoutLeaders = selectedUsers.value.filter(
		(aUser) =>
			!selectedActionLeaders.value.find((aLeader) => aLeader.id === aUser.id),
	)

	switch (sortOrder.value) {
		case 'selectedAt':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.selectedAt],
				['desc'],
			)
			break

		case 'asc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.surname],
				['asc'],
			)
			break

		case 'desc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.surname],
				['desc'],
			)
			break

		default:

			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(user) => !user.distance,
					(user) => user.distance,
				],
				[
					'asc',
					sortOrder.value,
				],
			)
			break
	}

	return cloneDeep([...leaders, ...result])
}

const methodFilterSelectedUserInsideCircleArea = (usersFiltered) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')
	const circleData = getCircleData(mapInstance.value, whichCircle)

	const circleCoordinate = circleData?.geometry?.coordinates || []
	if ((areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue !== 'allUsers' && areaSelectionFilter.currentValue !== 'actionArea') || isAiSettings.value) {
		return usersFiltered.filter((user) => userIsInsideArea(user, circleCoordinate))
	}
	else {
		return usersFiltered
	}
}
const methodFilterSelectedUserInsideAiPolygon = (usersFiltered) => {
	// let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	// let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')
	// const circleData = getCircleData(mapInstance.value, whichCircle)

	const coordinates = aiPolygon.value.geometry.coordinates || []

	if ((areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue !== 'allUsers' && areaSelectionFilter.currentValue !== 'actionArea') || isAiSettings.value) {
		return usersFiltered.filter((user) => userIsInsideArea(user, coordinates))
	}
	else {
		return usersFiltered
	}
}

const methodFilterSelectedUserInsideActionArea = (usersFiltered) => {
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')

	if (areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue === 'actionArea' || isAiSettings.value) {
		const coordinates = newActionPolygon.value.map((point) => [point.lng, point.lat])
		const usersInsideArea = usersWithDistances.value.filter((user) => userIsInsideArea(user, [coordinates]))
		return cloneDeep(usersInsideArea)
	}
	else {
		return cloneDeep(usersFiltered)
	}
}

const methodSelectUser = (isAlreadyCheckedBoolean, user) => {
	const aUserWithDistance = cloneDeep(usersWithDistances.value).find((aUser) => aUser.id === user.id)

	user.selectedAt = Date.now()
	const allTeamSelectedIds = selectedTeams.value.map((team) => team.id)
	const allteamsIdsOfUser = user.teams?.length ? user.teams.map((team) => team.id) : []

	const hasUserInTeam = allTeamSelectedIds.some((id) => allteamsIdsOfUser.includes(id))

	selectionTypes.value.currentValue = 'customSelection'
	isAiSettings.value = false

	if (isAlreadyCheckedBoolean) {
		selectedUsers.value = selectedUsers.value.filter((aUser) => aUser.id !== user.id)
		selectedActionLeaders.value = selectedActionLeaders.value.filter((aUser) => aUser.id !== user.id)
	}
	else {
		if (!hasUserInTeam) {
			if (aUserWithDistance) {
				selectedUsers.value = [...selectedUsers.value, aUserWithDistance]
			}
			else {
				selectedUsers.value = [...selectedUsers.value, user]
			}
		}
	}

	addCheckedClassToSelectedUsers([user])
}

const updateCircleFromFilter = (value) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	let valueConvertedInKm = convertToKilometers(value, currentDistanceUnitChoice)

	let tmpPolygon
	let whichCircle
	const map = mapInstance.value

	if (filterDistanceTarget.currentValue === 'meetingpoint') {
		tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
		whichCircle = 'meetingPoint'
	}
	else {
		whichCircle = 'polygon'
		tmpPolygon = newActionPolygon.value
	}

	updateCircle(map, tmpPolygon, valueConvertedInKm, whichCircle, currentDistanceUnitChoice)
	preSelectedUsers.value = methodStartFilters()

	const circleData = getCircleData(map, whichCircle)
	const endCoordsOfLine = getRightmostPointFromCircle(circleData)

	const center = getCenterOfPolygon(tmpPolygon)
	const lineColor = whichCircle !== 'meetingPoint' ? '#ff387f' : '#BABEF2'
	updateLine(map, center, endCoordsOfLine, lineColor)
	removeAllMapMouseMoveHandlers(mapInstance.value)
}

const methodStartFilters = (aFilter = null) => {
	let usersFiltered = cloneDeep(usersWithDistances.value)
	if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
		usersFiltered = methodFilterSelectedUserInsideCircleArea(usersFiltered)
		usersFiltered = methodFilterSelectedUserInsideActionArea(usersFiltered)
	}
	usersFiltered = methodFilterByDisponibilities(usersFiltered)

	return usersFiltered
}
const methodFilterByDisponibilities = (users) => {
	const aFilter = ownSelectionFilters.value.find((filter) => filter.category === 'disponibility')

	if (!aFilter || !aFilter.currentValue.length) {
		return users
	}

	return users.filter((user) => {
		const userStatus = user.status || 'not_set'
		const userDisponibilities = userStatus.split(',')

		return userDisponibilities.includes('available_all_the_time')
			|| userDisponibilities.length === 0
			|| intersection(userDisponibilities, aFilter.currentValue).length > 0
	})
}

const methodSelectTeam = (isAlreadyCheckedBoolean, data) => {
	if (isAlreadyCheckedBoolean) {
		selectedTeams.value = selectedTeams.value.filter((team) => team.id !== data.id)
	}
	else {
		selectedTeams.value = [...selectedTeams.value, data]
	}
}

const toggleSelectCard = (data, type) => {
	let allSelectedLeadersId = methodReturnOnlyIds(selectedActionLeaders.value)
	methodCloseMoreDetails()
	switch (type) {
		case 'user':
			if (!allSelectedLeadersId.includes(data.id)) {
				methodSelectUser(methodVerifyIsAlreadyCheck(data, type), data)
				selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
			}
			else {
				methodUpdateLeadersAction(data)
			}

			break
		case 'team':
			methodSelectTeam(methodVerifyIsAlreadyCheck(data, type), data)
			selectedTeams.value.length ? activeNames.value = ['selected-teams', 'result-search'] : activeNames.value = ['result-search']
			break

		default:
			return
	}
}

const methodVerifyIsActionLeader = (aUser) => {
	return methodReturnOnlyIds(selectedActionLeaders.value).includes(aUser.id)
}

provide('verify-is-action-leader', methodVerifyIsActionLeader)

const methodVerifyIsAlreadyCheck = (data, type = 'user') => {
	let dataIds = []
	switch (type) {
		case 'user':

			dataIds = selectedUsers.value.map((user) => user?.id)
			break
		case 'team':
			dataIds = selectedTeams.value.map((team) => team?.id)
			break
		default:
			break
	}

	return dataIds.includes(data?.id)
}

const methodVerifyIfUserIsLocked = (data) => {
	const dataIds = selectedUsers.value.filter((user) => user.isLockedByTeam && user.isLockedByTeam.length > 0).map((user) => user.id)
	return dataIds.includes(data?.id)
}


return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_q_button = _resolveComponent("q-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col bg-white px-14 overflow-auto transition-all", cardMoreDetails.value.visible ? 'w-8/12': 'w-7/12'])
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAIN_TITLE')), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAIN_DESC')), 1),
        _cache[43] || (_cache[43] = _createElementVNode("div", { class: "flex flex-row gap-2 flex-wrap mb-6 w-full justify-end" }, null, -1)),
        (getNewActionAssignmentType.value === 'open' || getNewActionAssignmentType.value === 'allUsers')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TITLE.${getNewActionAssignmentType.value.toUpperCase()}`)), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SUBTITLE.${getNewActionAssignmentType.value.toUpperCase()}`)), 1)
              ]),
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "bg-gray-300 flex-grow mb-10 rounded-2xl" }, null, -1))
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (aiIsUselessCardVisibile.value && __props.isNewAction && (whichPartDisplay.value === 'listing' || whichPartDisplay.value === 'searchListing'))
                ? (_openBlock(), _createBlock(_unref(ActionAiAssignmentCard), {
                    key: 0,
                    "toggle-filters": methodToggleFilters,
                    "method-go-to-listing": methodGoToListing,
                    "active-filters-length": computedFiltersActiveLength.value,
                    "is-ai-settings": isAiSettings.value,
                    "onUpdate:isAiSettings": _cache[0] || (_cache[0] = ($event: any) => ((isAiSettings).value = $event)),
                    "selected-teams": selectedTeams.value,
                    "selected-users": selectedUsers.value,
                    "method-on-cross-click": ()=> aiIsUselessCardVisibile.value = false,
                    "complementary-container-classes": "rounded-[20px] mb-2"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, [
                          (disabledAiForActionType.value.includes(actionType.value))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_ONE_DISABLED_BY_ACTION')), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_ONE')), 1)
                              ], 64))
                        ]),
                        _createElementVNode("p", _hoisted_10, [
                          (disabledAiForActionType.value.includes(actionType.value))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_TWO_DISABLED_BY_ACTION')), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_TWO')), 1)
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["active-filters-length", "is-ai-settings", "selected-teams", "selected-users", "method-on-cross-click"]))
                : _createCommentVNode("", true),
              (!isAiSettings.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_unref(ActionAssignmentSearchAndFilterHeader), {
                          "search-query": searchQuery.value,
                          "onUpdate:searchQuery": _cache[1] || (_cache[1] = ($event: any) => ((searchQuery).value = $event)),
                          "search-bar-is-open": searchBarIsOpen.value,
                          "onUpdate:searchBarIsOpen": _cache[2] || (_cache[2] = ($event: any) => ((searchBarIsOpen).value = $event)),
                          "which-part-display": whichPartDisplay.value,
                          "onUpdate:whichPartDisplay": _cache[3] || (_cache[3] = ($event: any) => ((whichPartDisplay).value = $event)),
                          "show-filters": showFilters.value,
                          "onUpdate:showFilters": _cache[4] || (_cache[4] = ($event: any) => ((showFilters).value = $event)),
                          "method-go-to-listing": methodGoToListing,
                          "method-go-to-filter-view": methodGoToFilterView,
                          "method-open-dialog-for-reset-to-ai": () => {dialogResetToAiVisible.value = true},
                          "active-filters-length": computedFiltersActiveLength.value,
                          "method-reset-all-own-selection-filters": methodResetAllOwnSelectionFilters,
                          "disabled-ai-selection": disabledAiForActionType.value.includes(actionType.value) && _unref(isEmpty)(localMeetingPoint.value[0]),
                          "ai-is-useless": aiIsUseless.value
                        }, null, 8, ["search-query", "search-bar-is-open", "which-part-display", "show-filters", "method-open-dialog-for-reset-to-ai", "active-filters-length", "disabled-ai-selection", "ai-is-useless"]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_unref(ActionAssignmentSelectedCounterCard), {
                            "merged-users-and-users-in-teams-length": methodMergedUsersAndUsersInTeams(selectedUsers.value, selectedTeams.value).length || 0,
                            "selected-teams": selectedTeams.value,
                            "selected-users": selectedUsers.value,
                            "action-method": methodTriggerCounterCard,
                            "container-classes": whichPartDisplay.value === 'listing' ? 'bg-black text-white cursor-pointer' : 'bg-gray-lighted text-black cursor-pointer border-none',
                            "count-number-classes": whichPartDisplay.value === 'listing' ? 'opacity-80' : 'text-gray-400'
                          }, null, 8, ["merged-users-and-users-in-teams-length", "selected-teams", "selected-users", "container-classes", "count-number-classes"])
                        ])
                      ]),
                      (!isAiSettings.value && whichPartDisplay.value !== 'filters')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("span", {
                                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (methodSetWichListDisplay('users')), ["stop"])),
                                class: _normalizeClass(["group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer", [whichListDisplay.value === 'users' ? 'bg-black text-white' : 'bg-gray-100']])
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.USERS')) + " ", 1),
                                _createElementVNode("span", {
                                  class: _normalizeClass(["group-hover:text-white", whichListDisplay.value === 'users' ? 'opacity-80' : 'text-gray-400'])
                                }, _toDisplayString(computedReturnTabCountNumberUsers.value), 3)
                              ], 2),
                              _createElementVNode("span", {
                                onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (methodSetWichListDisplay('teams')), ["stop"])),
                                class: _normalizeClass(["group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer", [whichListDisplay.value === 'teams' ? 'bg-black text-white' : 'bg-gray-100']])
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.TEAMS')) + " ", 1),
                                _createElementVNode("span", {
                                  class: _normalizeClass(["group-hover:text-white", whichListDisplay.value === 'users' ? 'opacity-80' : 'text-gray-400'])
                                }, _toDisplayString(computedReturnTabCountNumberTeams.value), 3)
                              ], 2),
                              _createElementVNode("span", {
                                onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (methodSetWichListDisplay('usersAndTeams')), ["stop"])),
                                class: _normalizeClass(["group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer", [whichListDisplay.value === 'usersAndTeams' ? 'bg-black text-white' : 'bg-gray-100']])
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.USERS_AND_TEAMS')) + " ", 1),
                                _createElementVNode("span", {
                                  class: _normalizeClass([whichListDisplay.value === 'users' ? 'opacity-80' : 'text-gray-400', "group-hover:text-white"])
                                }, _toDisplayString(computedReturnTabCountNumberUsersAndTeams.value), 3)
                              ], 2)
                            ]),
                            ((selectedUsers.value?.length || selectedTeams.value?.length) && whichPartDisplay.value === 'listing')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "cursor-pointer mx-7 hover:opacity-50",
                                  onClick: _withModifiers(methodClearAllSelection, ["stop"])
                                }, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.FILTERS.CLEAR_ALL')), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", {
                              onClick: _withModifiers(toggleDropdownSort, ["stop"]),
                              class: "flex items-center justify-center"
                            }, [
                              _createVNode(_component_el_dropdown, {
                                trigger: "click",
                                onVisibleChange: toggleDropdownSort,
                                visible: dropdownVisibleSort.value,
                                class: "rounded-xl"
                              }, {
                                dropdown: _withCtx(() => [
                                  _createVNode(_component_el_dropdown_menu, { class: "rounded-xm" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.SORT_BY')), 1),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedSortOrderValuesByWhichListDisplay.value, (aSortOrder, index) => {
                                        return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                          key: index,
                                          class: "mx-1 rounded-lg",
                                          onClick: _withModifiers(($event: any) => (methodTriggerSortOrder(aSortOrder)), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("i", {
                                              class: _normalizeClass(computedCurrentSortOrderValue.value === aSortOrder ? 'gui-radio_on': 'gui-radio_off')
                                            }, null, 2),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${aSortOrder.toUpperCase()}`)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 128)),
                                      (whichListDisplay.value !=='teams')
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("div", _hoisted_19, [
                                              _createVNode(_component_el_divider, { class: "my-2" })
                                            ]),
                                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.FROM')), 1),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(distanceTargetSort.value.allValues, (aDistanceTargetSort, index) => {
                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                key: index,
                                                class: "mx-1 rounded-lg",
                                                disabled: _unref(isEmpty)(newActionPolygon.value) && aDistanceTargetSort === 'polygonCenter' || _unref(isEmpty)(localMeetingPoint.value[0]) && aDistanceTargetSort === 'meetingpoint',
                                                onClick: _withModifiers(($event: any) => (methodChangeUsersDistanceTarget(aDistanceTargetSort)), ["stop"])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("i", {
                                                      class: _normalizeClass(distanceTargetSort.value.currentValue === aDistanceTargetSort ? 'gui-radio_on': 'gui-radio_off')
                                                    }, null, 2),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${aDistanceTargetSort.toUpperCase()}`)), 1)
                                                  ])
                                                ]),
                                                _: 2
                                              }, 1032, ["disabled", "onClick"]))
                                            }), 128))
                                          ], 64))
                                        : _createCommentVNode("", true),
                                      (!_unref(isEmpty)(newActionPolygon.value) || !_unref(isEmpty)(localMeetingPoint.value[0]))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            _createElementVNode("div", _hoisted_22, [
                                              _createVNode(_component_el_divider, { class: "my-2" })
                                            ]),
                                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.UNIT`)), 1),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(distanceTargetUnits.value.allValues, (aDistanceTargetUnit, index) => {
                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                key: index,
                                                class: "mx-1 rounded-lg",
                                                disabled: _unref(isEmpty)(newActionPolygon.value) && aDistanceTargetUnit === 'polygonCenter' || _unref(isEmpty)(localMeetingPoint.value[0]) && aDistanceTargetUnit === 'meetingpoint',
                                                onClick: _withModifiers(($event: any) => (methodChangeUsersDistanceUnit(aDistanceTargetUnit)), ["stop"])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("i", {
                                                      class: _normalizeClass(distanceTargetUnits.value.currentValue === aDistanceTargetUnit ? 'gui-radio_on': 'gui-radio_off')
                                                    }, null, 2),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$tc(`_COMMON.DISTANCE.${aDistanceTargetUnit.toUpperCase()}.LONG`, 2)), 1)
                                                  ])
                                                ]),
                                                _: 2
                                              }, 1032, ["disabled", "onClick"]))
                                            }), 128))
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_16, [
                                    (computedCurrentSortOrderValue.value !=='selectedAt')
                                      ? (_openBlock(), _createElementBlock("i", {
                                          key: 0,
                                          class: _normalizeClass(["gui-sort_desc inline-block", [computedCurrentSortOrderValue.value === 'asc' || computedCurrentSortOrderValue.value === 'asc_name' ? 'force-flip-y-icon' : '']])
                                        }, null, 2))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${computedCurrentSortOrderValue.value.toUpperCase()}`)), 1)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["visible"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      (!isAiSettings.value && showFilters.value)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_unref(ActionAssignmentFilters), {
                              "map-range": computedMapRange.value.currentValue,
                              "onUpdate:mapRange": _cache[8] || (_cache[8] = ($event: any) => ((computedMapRange.value.currentValue) = $event)),
                              "own-selection-filters": ownSelectionFilters.value,
                              "onUpdate:ownSelectionFilters": _cache[9] || (_cache[9] = ($event: any) => ((ownSelectionFilters).value = $event)),
                              "selection-types": selectionTypes.value,
                              "onUpdate:selectionTypes": _cache[10] || (_cache[10] = ($event: any) => ((selectionTypes).value = $event)),
                              "method-update-circle-from-filter": updateCircleFromFilter,
                              "which-part-display": whichPartDisplay.value,
                              "active-filters-length": computedFiltersActiveLength.value,
                              "meeting-point": localMeetingPoint.value,
                              polygon: computedNewActionPolygon.value,
                              "action-type": actionType.value,
                              "max-range": computedMapRange.value.maxValue
                            }, null, 8, ["map-range", "own-selection-filters", "selection-types", "which-part-display", "active-filters-length", "meeting-point", "polygon", "action-type", "max-range"]),
                            _createVNode(_unref(ActionAssignmentFilterContent), {
                              "pre-selected-users": computedDiffBeetweenSelectedAndPreselected.value.diffUsers,
                              "pre-selected-teams": computedDiffBeetweenSelectedAndPreselected.value.diffTeams,
                              "method-apply-filters": methodMergePreselectedWithSelectedUsers,
                              "method-cancel-filters": methodCancelPreselectedValues
                            }, null, 8, ["pre-selected-users", "pre-selected-teams"])
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (whichPartDisplay.value === 'searchListing')
                ? (_openBlock(), _createBlock(_unref(ActionAssignmentSearchListing), {
                    key: 2,
                    "search-query": searchQuery.value,
                    "onUpdate:searchQuery": _cache[11] || (_cache[11] = ($event: any) => ((searchQuery).value = $event)),
                    "sort-order": computedCurrentSortOrderValue.value,
                    "onUpdate:sortOrder": _cache[12] || (_cache[12] = ($event: any) => ((computedCurrentSortOrderValue).value = $event)),
                    "search-bar-is-open": searchBarIsOpen.value,
                    "onUpdate:searchBarIsOpen": _cache[13] || (_cache[13] = ($event: any) => ((searchBarIsOpen).value = $event)),
                    "map-range": computedMapRange.value,
                    "onUpdate:mapRange": _cache[14] || (_cache[14] = ($event: any) => ((computedMapRange).value = $event)),
                    "selected-users": selectedUsers.value,
                    "onUpdate:selectedUsers": _cache[15] || (_cache[15] = ($event: any) => ((selectedUsers).value = $event)),
                    "map-instance": mapInstance.value,
                    "onUpdate:mapInstance": _cache[16] || (_cache[16] = ($event: any) => ((mapInstance).value = $event)),
                    "selection-types": selectionTypes.value,
                    "onUpdate:selectionTypes": _cache[17] || (_cache[17] = ($event: any) => ((selectionTypes).value = $event)),
                    "own-selection-filters": ownSelectionFilters.value,
                    "onUpdate:ownSelectionFilters": _cache[18] || (_cache[18] = ($event: any) => ((ownSelectionFilters).value = $event)),
                    "selected-teams": selectedTeams.value,
                    "onUpdate:selectedTeams": _cache[19] || (_cache[19] = ($event: any) => ((selectedTeams).value = $event)),
                    "active-names": activeNames.value,
                    "onUpdate:activeNames": _cache[20] || (_cache[20] = ($event: any) => ((activeNames).value = $event)),
                    "selected-action-leaders": selectedActionLeaders.value,
                    "onUpdate:selectedActionLeaders": _cache[21] || (_cache[21] = ($event: any) => ((selectedActionLeaders).value = $event)),
                    users: usersWithDistances.value,
                    "method-update-leaders-action": methodUpdateLeadersAction,
                    "toggle-select-card": toggleSelectCard,
                    "method-verify-is-already-check": methodVerifyIsAlreadyCheck,
                    "method-verify-if-user-is-locked": methodVerifyIfUserIsLocked,
                    "handle-area-change-method": methodAreaRangeChange,
                    "method-update-circle-from-filter": updateCircleFromFilter,
                    "method-go-to-listing": methodGoToListing,
                    "method-display-user-or-team-infos": methodDisplayUserOrTeamInfos,
                    "method-close-more-details": methodCloseMoreDetails,
                    "method-verify-is-action-leader": methodVerifyIsActionLeader,
                    "method-sort-users": methodSortUsers,
                    "method-sorted-teams": methodSortedTeams,
                    "user-selected-id": cardMoreDetails.value?.itemId,
                    "all-teams": _unref(cloneDeep)(getTeams.value),
                    "which-list-display": whichListDisplay.value,
                    "method-sort-users-and-teams": methodSortUsersAndTeams,
                    "current-distance-target-unit": distanceTargetUnits.value.currentValue
                  }, null, 8, ["search-query", "sort-order", "search-bar-is-open", "map-range", "selected-users", "map-instance", "selection-types", "own-selection-filters", "selected-teams", "active-names", "selected-action-leaders", "users", "user-selected-id", "all-teams", "which-list-display", "current-distance-target-unit"]))
                : _createCommentVNode("", true),
              (whichPartDisplay.value === 'listing')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    (isAiSettings.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          (!aiIsUseless.value)
                            ? (_openBlock(), _createBlock(_unref(ActionAiAssignmentCard), {
                                key: 0,
                                "toggle-filters": methodToggleFilters,
                                "method-go-to-listing": methodGoToListing,
                                "active-filters-length": computedFiltersActiveLength.value,
                                "is-ai-settings": isAiSettings.value,
                                "onUpdate:isAiSettings": _cache[22] || (_cache[22] = ($event: any) => ((isAiSettings).value = $event)),
                                "selected-teams": selectedTeams.value,
                                "selected-users": selectedUsers.value,
                                "show-counter-card": "",
                                "merged-users-and-users-in-teams-length": methodMergedUsersAndUsersInTeams(selectedUsers.value, selectedTeams.value).length || 0
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.TITLE.LINE_ONE')), 1),
                                    _createElementVNode("p", _hoisted_27, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.TITLE.LINE_TWO')) + " ", 1),
                                      _createVNode(_component_el_tooltip, {
                                        effect: "dark",
                                        placement: "top"
                                      }, {
                                        content: _withCtx(() => [
                                          _createElementVNode("a", {
                                            innerHTML: _unref(t)('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.TOOLTIP'),
                                            href: _unref(t)('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.TOOLTIP_LINK'),
                                            class: "custom-tooltip-link",
                                            target: "_blank",
                                            style: {"color":"white"}
                                          }, null, 8, _hoisted_28)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[39] || (_cache[39] = _createElementVNode("i", { class: "pl-2 pt-0.5 gui-infos cursor-help" }, null, -1))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.SUBTITLE')), 1)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["active-filters-length", "is-ai-settings", "selected-teams", "selected-users", "merged-users-and-users-in-teams-length"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["relative", isAiSettings.value ? 'fake-border-gradient' : ''])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["relative z-10", isAiSettings.value ? 'px-4 pt-6' : ''])
                      }, [
                        _createVNode(_unref(ActionAssignmentListing), {
                          "selected-action-leaders": selectedActionLeaders.value,
                          "onUpdate:selectedActionLeaders": _cache[23] || (_cache[23] = ($event: any) => ((selectedActionLeaders).value = $event)),
                          "search-query": searchQuery.value,
                          "onUpdate:searchQuery": _cache[24] || (_cache[24] = ($event: any) => ((searchQuery).value = $event)),
                          "map-range": computedMapRange.value,
                          "onUpdate:mapRange": _cache[25] || (_cache[25] = ($event: any) => ((computedMapRange).value = $event)),
                          "which-part-display": whichPartDisplay.value,
                          "onUpdate:whichPartDisplay": _cache[26] || (_cache[26] = ($event: any) => ((whichPartDisplay).value = $event)),
                          "selected-users": selectedUsers.value,
                          "onUpdate:selectedUsers": _cache[27] || (_cache[27] = ($event: any) => ((selectedUsers).value = $event)),
                          "map-instance": mapInstance.value,
                          "onUpdate:mapInstance": _cache[28] || (_cache[28] = ($event: any) => ((mapInstance).value = $event)),
                          "selection-types": selectionTypes.value,
                          "onUpdate:selectionTypes": _cache[29] || (_cache[29] = ($event: any) => ((selectionTypes).value = $event)),
                          "own-selection-filters": ownSelectionFilters.value,
                          "onUpdate:ownSelectionFilters": _cache[30] || (_cache[30] = ($event: any) => ((ownSelectionFilters).value = $event)),
                          "selected-teams": selectedTeams.value,
                          "onUpdate:selectedTeams": _cache[31] || (_cache[31] = ($event: any) => ((selectedTeams).value = $event)),
                          "selected-users-and-teams": concatenedSelectedTeamsAndUsers.value,
                          "active-names": activeNames.value,
                          "onUpdate:activeNames": _cache[32] || (_cache[32] = ($event: any) => ((activeNames).value = $event)),
                          users: usersWithDistances.value,
                          "method-update-leaders-action": methodUpdateLeadersAction,
                          "toggle-select-card": toggleSelectCard,
                          "method-verify-is-already-check": methodVerifyIsAlreadyCheck,
                          "method-verify-is-action-leader": methodVerifyIsActionLeader,
                          "handle-area-change-method": methodAreaRangeChange,
                          "method-update-circle-from-filter": updateCircleFromFilter,
                          "method-display-user-or-team-infos": methodDisplayUserOrTeamInfos,
                          "method-close-more-details": methodCloseMoreDetails,
                          "user-selected-id": cardMoreDetails.value?.itemId,
                          "which-list-display": whichListDisplay.value,
                          "current-distance-target-unit": distanceTargetUnits.value.currentValue
                        }, null, 8, ["selected-action-leaders", "search-query", "map-range", "which-part-display", "selected-users", "map-instance", "selection-types", "own-selection-filters", "selected-teams", "selected-users-and-teams", "active-names", "users", "user-selected-id", "which-list-display", "current-distance-target-unit"]),
                        _createElementVNode("div", _hoisted_30, [
                          (isAiSettings.value)
                            ? (_openBlock(), _createBlock(_component_q_button, {
                                key: 0,
                                class: "full-black force-rounded-full",
                                onClick: methodAddUserOrTeamFromAi
                              }, {
                                default: _withCtx(() => [
                                  _cache[40] || (_cache[40] = _createElementVNode("i", { class: "gui-more text-xl pr-2 bold" }, null, -1)),
                                  _cache[41] || (_cache[41] = _createTextVNode()),
                                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.ADD_USERS_TO_SELECTION')), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                onClick: methodGoToSearchListing,
                                class: "cursor-pointer mr-4 basic-black q-button ghost square"
                              }, [
                                _cache[42] || (_cache[42] = _createElementVNode("i", { class: "gui-more pr-1" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.ADD_MORE')), 1)
                              ]))
                        ])
                      ], 2)
                    ], 2),
                    (isAiSettings.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("span", {
                            class: "bold bg-gray-lighted rounded-full rounded-full px-24 py-4 cursor-pointer hover:opacity-60",
                            onClick: methodOwnSelection
                          }, _toDisplayString(_ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.OWN_SELECTION')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["bg-white transition-width duration-300 flex-grow relative", [whichPartDisplay.value === 'filters' ? 'disable-cursor-map' : '']])
      }, [
        (usersWithDistances.value.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-auto absolute right-5 bottom-5 z-10 flex flex-row gap-2", whichPartDisplay.value === 'filters' ? 'pointer-events-none cursor-not-allowed opacity-40' : ''])
              }, [
                (localMeetingPoint.value && !_unref(isEmpty)(localMeetingPoint.value[0]))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createVNode(_component_el_tooltip, {
                        effect: "customized-popper-assignment",
                        content: _unref(t)('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAP.CTA.MEETING_POINT_VIEW'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", {
                            onClick: _cache[33] || (_cache[33] = ($event: any) => (_unref(adjustZoomAndView)(mapInstance.value, _unref(transformPointToMiniPolygon)(localMeetingPoint.value[0]), adjustZoomAndViewCallBack))),
                            class: _normalizeClass(["p-2 m-1 flex items-center justify-center hover:bg-pink-main hover:bg-opacity-5 hover:text-pink-main rounded-[10px] cursor-pointer", _unref(isEqual)(currentZoomAndViewCoords.value, _unref(transformPointToMiniPolygon)(localMeetingPoint.value[0])[0]) ? 'bg-pink-main bg-opacity-5 text-pink-main' : ''])
                          }, _cache[44] || (_cache[44] = [
                            _createElementVNode("i", { class: "gui-pin_colorful" }, null, -1)
                          ]), 2)
                        ]),
                        _: 1
                      }, 8, ["content"])
                    ]))
                  : _createCommentVNode("", true),
                (newActionPolygon.value && !_unref(isEmpty)(newActionPolygon.value))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _createVNode(_component_el_tooltip, {
                        effect: "customized-popper-assignment",
                        content: _unref(t)('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAP.CTA.POLYGON_VIEW'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", {
                            onClick: _cache[34] || (_cache[34] = ($event: any) => (_unref(adjustZoomAndView)(mapInstance.value, newActionPolygon.value, adjustZoomAndViewCallBack))),
                            class: _normalizeClass(["p-2 m-1 flex items-center justify-center hover:bg-pink-main hover:bg-opacity-5 hover:text-pink-main rounded-[10px] cursor-pointer", _unref(isEqual)(currentZoomAndViewCoords.value, newActionPolygon.value[0]) ? 'bg-pink-main bg-opacity-5 text-pink-main' : ''])
                          }, _cache[45] || (_cache[45] = [
                            _createElementVNode("i", { class: "qi-turf" }, null, -1)
                          ]), 2)
                        ]),
                        _: 1
                      }, 8, ["content"])
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              _createVNode(_unref(ActionAssignmentMap), {
                "map-fully-loaded": mapFullyLoaded.value,
                "onUpdate:mapFullyLoaded": _cache[35] || (_cache[35] = ($event: any) => ((mapFullyLoaded).value = $event)),
                "map-instance": mapInstance.value,
                "onUpdate:mapInstance": _cache[36] || (_cache[36] = ($event: any) => ((mapInstance).value = $event)),
                "action-polygon": newActionPolygon.value,
                users: usersWithDistances.value,
                "which-part-display": whichPartDisplay.value,
                "toggle-select-card": toggleSelectCard,
                "handle-area-change-method": methodAreaRangeChange,
                "meeting-point": localMeetingPoint.value,
                "current-zoom-and-view-coords": currentZoomAndViewCoords.value,
                "onUpdate:currentZoomAndViewCoords": _cache[37] || (_cache[37] = ($event: any) => ((currentZoomAndViewCoords).value = $event))
              }, null, 8, ["map-fully-loaded", "map-instance", "action-polygon", "users", "which-part-display", "meeting-point", "current-zoom-and-view-coords"])
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "slide-fade" }, {
          default: _withCtx(() => [
            (cardMoreDetails.value.visible)
              ? (_openBlock(), _createBlock(_unref(cardDetailsInfos), {
                  key: 0,
                  "more-details": computedMoreDetails.value,
                  type: cardMoreDetails.value.type,
                  "method-close-more-details": methodCloseMoreDetails
                }, null, 8, ["more-details", "type"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2)
    ]),
    _createVNode(_unref(DeleteDialog), {
      cancel: () => closeDialogResetToAi(),
      confirm: () => methodResetToAiSelection(),
      "confirm-label": _ctx.$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.CONFIRM_CTA'),
      "close-dialog": () => (closeDialogResetToAi()),
      "visible-dialog": dialogResetToAiVisible.value,
      title: `${_ctx.$tc('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.TITLE')}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$tc('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.DESCRIPTION')), 1)
        ])
      ]),
      _: 1
    }, 8, ["cancel", "confirm", "confirm-label", "close-dialog", "visible-dialog", "title"])
  ], 64))
}
}

})