/**
 * Create a new vuex-module. The constructor allow for custom data,
 * but will automatically generate default getters, default SET/RESET mutations,
 * and the `resetState` action
 * @class Module
 * @author Nicolas Delauney <nicolas@qomon.com>
 *
 */
// pug broke ?
export default class Module {
	constructor({ state, getters, mutations, actions }) {
		this.state = state
		this.__defaultState = state
		this.getters = getters || {}
		this.namespaced = true
		this.mutations = mutations || {}
		this.actions = actions || {}
		this.getSimpleGetters(state)
		this.getSimpleMutations(state)
		if (
			!Object.prototype.hasOwnProperty.call(actions, 'resetState') &&
			typeof actions.resetState !== Function
		) {
			this.actions.resetState = ({ commit }) => {
				this.getReseters().forEach((resetKey) => {
					commit(resetKey)
				})
			}
		}
	}

	getSimpleGetters(state) {
		Object.keys(state).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(this.getters, key)) {
				this.getters[key] = (state) => state[key]
			}
		})
	}

	getSimpleMutations(state) {
		Object.keys(state).forEach((key) => {
			const radical = key
				.split(/(?=[A-Z])/g)
				.map((substring) => substring.toUpperCase())
				.join('_')
			if (!Object.prototype.hasOwnProperty.call(this.mutations, `SET_${radical}`)) {
				this.mutations[`SET_${radical}`] = (state, payload) => {
					state[key] = payload
				}
			}
			if (!Object.prototype.hasOwnProperty.call(this.mutations, `RESET_${radical}`)) {
				this.mutations[`RESET_${radical}`] = (state) => {
					state[key] = this.__defaultState[key]
				}
			}
		})
	}

	getReseters() {
		return Object.keys(this.mutations).filter((key) => key.indexOf('RESET') > -1)
	}
}
