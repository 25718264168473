import privacy_policy_store from './../../models/privacy_policy_store'
import {
	SET_PRIVACY_POLICY_URL,
	SET_FETCHING_POST_PRIVACY_POLICY_STATUS,
	RESET_PRIVACY_POLICY_STORE,
} from './_mutation-types'

/**
 * @store @privacyPolicy
 * @namespaced
 * @description Privacy policy related info and store module
 */

const getDefaultState = () => {
	return {
		privacyPolicyUrl: '',
		privacyPolicyRequestStatus: 'init',
	}
}
const state = getDefaultState

const mutations = {
	[RESET_PRIVACY_POLICY_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_PRIVACY_POLICY_URL]: (state, payload) => {
		state.privacyPolicyUrl = payload
	},
	[SET_FETCHING_POST_PRIVACY_POLICY_STATUS]: (state, payload) => {
		state.privacyPolicyRequestStatus = payload
	},
}

const actions = {
	actionResetPrivacyPolicy({ commit }) {
		commit(RESET_PRIVACY_POLICY_STORE)
	},

	actionGetPrivacyPolicyUrl: ({ commit }) => {
		return privacy_policy_store
			.getPrivacyPolicy()
			.then((res) => {
				commit(SET_PRIVACY_POLICY_URL, res.privacy_policy.url)
			})
			.catch(() => {})
	},

	actionSetPrivacyPolicyUrl: async ({ commit }, payload) => {
		privacy_policy_store
			.postPrivacyPolicy({ privacy_policy: { url: payload } })
			.then((res) => {
				if (res.privacy_policy.url == payload) {
					commit(SET_FETCHING_POST_PRIVACY_POLICY_STATUS, 'success')
					//Update value url privacy policy
					commit(SET_PRIVACY_POLICY_URL, res.privacy_policy.url)
				} else {
					commit(SET_FETCHING_POST_PRIVACY_POLICY_STATUS, 'error')
				}
			})
			.catch(() => {
				commit(SET_FETCHING_POST_PRIVACY_POLICY_STATUS, 'error')
			})
		commit(SET_FETCHING_POST_PRIVACY_POLICY_STATUS, 'init')
	},
}

const getters = {
	getPrivacyPolicyUrl: (state) => state.privacyPolicyUrl,
	getPrivacyPolicyRequestStatus: (state) => state.privacyPolicyRequestStatus,
}

export const privacyPolicyModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
