/**
 * We assume that analytics is correctly set
 * The workflow works with segment integration
 * If analytics is not available, to avoid crashes
 * we allways return an empty function
 */
import { trackEvent, update, boot, shutdown } from '@intercom/messenger-js-sdk'
import store from '../store/stateStore'

export const TRACKING = {
	SIGN_IN: 'SIGN_IN',
	SIGN_OUT: 'SIGN_OUT',
	form_published: 'form_published',
	email_sent: 'email_sent',
	action_created: 'action_created',
	petition_published: 'petition_published',
	notification_sent: 'notification_sent',
	subscription_cancelled: 'subscription_cancelled',
	subscription_downgraded: 'subscription_downgraded',
	subscription_modified: 'subscription_modified',
	subscription_support_requested: 'subscription_support_requested',
	premium_requested: 'premium_requested',
	help_center_viewed: 'help_center_viewed',
	sms_enabled: 'sms_enabled',
	notification_explored: 'notification_explored',
	email_explored: 'email_explored',
}

export default {
	/**
	 * @func page
	 * @return {Function}
	 */
	page: () => {
		const isAnalyticsEnabled = !!window.analytics && window.isSegmentEnabled
		if (isAnalyticsEnabled) {
			window.analytics.page()
		}
		return () => null
	},

	/**
	 * @func track
	 *  * @return {Function}
	 */
	track: (event, properties = {}, accountId = null) => {
		const isAnalyticsEnabled = !!window.analytics && window.isSegmentEnabled
		if (isAnalyticsEnabled && event) {
			const propertiesTracked = { ...properties }
			if (accountId) propertiesTracked.account_uid = accountId
			// Put the space id if nothing is provided
			else if (store?.state?.info_campagne?.id) propertiesTracked.account_uid = store?.state?.info_campagne?.id
			return window.analytics.track(event, propertiesTracked)
		}
		else {
			trackEvent(event, properties)
		}
		return () => null
	},

	/**
	 * @func session
	 * @desc will identify the user with correct params
	 */
	session: (user = {}, group = null) => {
		const isAnalyticsEnabled = !!window.analytics && window.isSegmentEnabled
		if (user.mail) {
			const isQomonUser = user.mail.includes('@qomon.com') || user.mail.includes('@quorum.co')

			const {
				id,
				avatar,
				firstname: firstName,
				surname: lastName,
				mail: email,
				phone,
				birthdate,
				address,
				CreatedAt: createdAt,
			} = user

			const payload = {
				avatar,
				firstName,
				lastName,
				phone,
				email,
				birthdate,
				address,
				createdAt,
			}

			if (group) {
				const { id: idGroup, name } = group
				payload.account_uid = idGroup
				payload.company = {
					id: idGroup,
					name,
				}
			}

			if (isAnalyticsEnabled && !isQomonUser) {
				window.analytics.identify(id, payload)
			}
			else {
				payload.name = `${firstName} ${lastName}`
				update(payload)
			}

			if (isQomonUser && isAnalyticsEnabled) window.analytics.reset()
		}
		return () => null
	},

	/**
	 * @func sessionGroup
	 * @desc will identify the user with correct params
	 * We do not allow the frontend application to send more information
	 * or rewrite the specifications of the group
	 */
	sessionGroup: (group = {}) => {
		if (group.id) {
			const isAnalyticsEnabled = !!window.analytics && window.isSegmentEnabled
			if (isAnalyticsEnabled) {
				const { id, name } = group
				window.analytics.group(id, { name })
			}
		}
		return () => null
	},

	/**
	 * @func reset
	 * @desc reset and remove cookie from window.analytics
	 */
	reset: () => {
		const isAnalyticsEnabled = !!window.analytics && window.isSegmentEnabled
		if (isAnalyticsEnabled) {
			window.analytics.track(TRACKING.SIGN_OUT)
		}
		else {
			shutdown()
			setTimeout(() => {
				boot({
					app_id: 'j0zwu1yw',
				})
			}
			, 500)
		}
		return () => null
	},
}
