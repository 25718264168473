<template>
	<div ref="mobileAppContainer" class="px-2 pt-2 h-full">
		<div ref="mobileAppHeader" class="w-full bg-white p-5 z-40 sticky top-0 menu-shadow">
			<div class="flex">
				<div v-for="(tab, index) in computedGetTabs" :key="index">
					<tab-with-number
						:title="tab.title"
						:selected="tab.selected"
						font-size="1.1rem"
						class="p-3"
						@tab-click="methodGoTo(tab)"
					/>
				</div>
			</div>
			<div class="p-3">
				{{ t('SETTINGS.MOBILE_APP.DESCRIPTION') }}
			</div>
		</div>
		<div class="mobileAppSettings">
			<router-view
				:style="{ height: tableHeight || methodCalculateRouterViewHeight }"
				class="overflow-auto"
			></router-view>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, onMounted, onUpdated, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import tabWithNumber from '/src/components/general/tabWithNumber.vue'

// Data
const router = useRouter()
const { t } = useI18n()
let tabSelected = ref('general-mobile-app')
let mobileAppContainer = ref(null)
let mobileAppHeader = ref(null)
let tableHeight = ref('')

// Computed
const computedGetTabs = computed(() => {
	return [
		{
			title: t('SETTINGS.MOBILE_APP.FIRST_TAB.TITLE'),
			name: 'general-mobile-app',
			selected: tabSelected.value === 'general-mobile-app',
		},
		{
			title: t('SETTINGS.MOBILE_APP.SECOND_TAB.TITLE'),
			name: 'action-mobile-app',
			selected: tabSelected.value === 'action-mobile-app',
		},
	]
})

// Updated
onUpdated(() => {
	methodCalculateRouterViewHeight()
})

// Monted
onMounted(() => {
	methodCalculateRouterViewHeight()
	tabSelected.value = router.currentRoute.value.name
})

// Method
const methodCalculateRouterViewHeight = () => {
	nextTick(() => {
		if (mobileAppContainer.value && mobileAppHeader.value)
			tableHeight.value = `calc(${
				mobileAppContainer.value.offsetHeight - mobileAppHeader.value.offsetHeight
			}px - 1rem)`
	})
}

const methodGoTo = (tab) => {
	router.push({ name: tab.name }).then(() => {
		tabSelected.value = tab.name
	})
}
</script>
<style type="scss">
.menu-shadow {
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px;
}

.mobileAppSettings .el-switch__label.is-active {
	@apply text-black;
}
</style>
