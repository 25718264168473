<template>
	<div
		class="tag-container"
		:class="{
			'tag-container--filled': filled,
			'p-4': !removable,
			'pl-3': removable,
			'cursor-pointer': clickable,
		}"
		@click="clickable ? $emit('click') : () => null"
	>
		<i v-if="icon !== ''" class="tag-icon custom-color" :class="icon" />

		<span
			v-tooltip
			class="tag-content"
			:class="{ 'tag-content--ellipsis': maxWidth !== '' }"
			:style="`max-width: ${maxWidth}`"
		>
			{{ content }}
		</span>
		<i v-if="removable" class="tag-close gui-close" @click="$emit('remove')" />
	</div>
</template>

<script>
import tooltipMixin from '/src/mixins/tooltipMixin.js'

export default {
	mixins: [tooltipMixin],
	props: {
		content: {
			type: String,
			required: true,
		},
		maxWidth: {
			type: String,
			default: '',
		},
		filled: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: '',
		},
		removable: {
			type: Boolean,
			default: false,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		customColor: {
			type: String,
			default: 'white',
		},
	},

	emits: ['click', 'remove'],

	computed: {
		computedReturnCustomColor() {
			return this.customColor
		},
	},
}
</script>

<style lang="scss">
.custom-color {
	color: v-bind(customColor);
}
</style>

<style lang="sass" scoped>
@import 'src/assets/scss/shared/shared-constants.scss'

.tag-container
	@apply mr-2 flex items-center rounded-lg
	height: 1.8rem
	border: 1px solid $hover-grey
	background-color: transparent
	color: $title-grey

.tag-container.tag-container--filled
	height: 2.2rem
	border: none
	background-color: #F7F7F7

.tag-container.cursor-pointer:hover
	background-color: $background-grey

.tag-content
	@apply block
	font-size: 12px
	height: min-content
	white-space: nowrap

.tag-content--ellipsis
	text-overflow: ellipsis
	overflow: hidden

.tag-icon
	@apply mr-1
	font-size: 15px

.tag-close
	font-size: 20px
	padding: 0.3rem
	cursor: pointer
	transition: all 200ms
	&:hover
		transform: scale(0.9)
		opacity: 0.8
</style>
