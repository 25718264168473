import { $request } from './requester'

/**
 * @typedef {Object} Team
 * @property {Number} id      - unique id
 * @property {Number} group_id - group id of the user
 * @property {String} name    - name of the team
 * @property {User[]} users   - the list of users in this team
 *
 */

/**
 * @model Team
 */
export default {
	/**
	 * @method getTeams
	 * @description `POST` `/teams/retrieve`
	 * @returns {Promise<Team[]>}
	 */
	async getTeams(isMasterView = false) {
		return $request('POST', `/${isMasterView ? 'master-' : ''}teams/retrieve`).then((res) => {
			return res.body.data.teams
		})
	},

	/**
	 * @method saveTeam
	 * @param {Object} team - the team to save
	 * @description `POST` `/teams`
	 * @returns {Promise<Team>}
	 */
	async saveTeam(team) {
		return $request('POST', '/teams', team).then((res) => {
			return res.body.data.team
		})
	},

	/**
	 * @method saveEditedTeam
	 * @param {Object} team - the team to save
	 * @description `PATCH` `/teams`
	 * @returns {Promise<Team>}
	 */
	async saveEditedTeam(team) {
		return $request('PATCH', '/teams', team).then((res) => {
			return res.body.data.team
		})
	},

	/**
	 * @method delete
	 * @description `DELETE` `/teams?:id`
	 * delete the given team with its id
	 * @param {Object} team - the team to delete
	 * @param {Number} team.id - mandatory to be deleted
	 * @returns {Promise}
	 */
	async delete(team) {
		return $request('DELETE', '/teams', { id: team.id }).then((res) => {
			return res.body.data
		})
	},
}
