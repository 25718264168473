<template>
	<div ref="settingsExchangesContainer" class="h-full">
		<div
			v-if="
				$route.name === 'imports-list' ||
				$route.name === 'exports-list' ||
				$route.name === 'archived-imports-list' ||
				$route.name === 'massive-manipulations'
			"
			ref="settingsExchangesHeader"
			class="w-full bg-white p-5"
		>
			<div class="flex justify-between">
				<div class="flex">
					<span v-for="(tab, index) in computedGetTabs" :key="index">
						<tab-with-number
							v-if="tab.isDisplayed"
							:title="tab.label"
							:selected="$route.path.includes(tab.path)"
							font-size="1.1rem"
							class="mr-4 p-3"
							@tab-click="goto(tab)"
							:additional-tooltip="tab.additionalTooltip || null"
						/>
					</span>
				</div>
				<q-button v-if="$route.name === 'imports-list'" @click="$router.push({ name: 'import' })">
					{{ $t('SETTINGS.IMPORT.LIST_IMPORT.IMPORT_A_FILE') }}
				</q-button>
			</div>
			<div class="flex mt-4 px-3 py-2">
				<span> {{ computedDescriptionText }} </span>
			</div>
		</div>
		<div class="">
			<!-- :class="{ 'overflow-y-hidden': ['imports-list', 'exports-list', 'an-import'].includes($route.name) }" -->
			<router-view
				class="bck-color"
				:table-height="tableHeight"
				:method-format-import-list="methodFormatImportList"
				@need-table-height="methodComputeTableHeight"
			></router-view>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent, nextTick } from 'vue'
import { cloneDeep } from 'lodash'
import { formatCustomDate } from '../../utils/dateFnsHelper'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},

	data() {
		return {
			tabSelected: 'imports-list',
			tableHeight: '',
		}
	},
	computed: {
		...mapGetters('@packs', ['getIsWebImportContactsFeatureEnabled']),
		...mapGetters('@navBar', ['isMenuVisible']),
		...mapGetters(['userConnected']),

		computedGetTabs() {
			return [
				{
					label: this.$t('SETTINGS.HEADER.IMPORTS'),
					path: '/exchanges/imports',
					name: 'imports-list',
					isDisplayed:
						this.getIsWebImportContactsFeatureEnabled &&
						this.userConnected?.role_data?.access?.includes('import:delete'),
				},
				{
					label: this.$t('SETTINGS.HEADER.ARCHIVED_IMPORTS'),
					path: '/exchanges/archived-imports',
					name: 'archived-imports-list',
					isDisplayed: this.userConnected?.role_data?.access?.includes('import:delete'),
				},
				{
					label: this.$t('SETTINGS.HEADER.EXPORTS'),
					path: '/exchanges/exports',
					name: 'exports-list',
					isDisplayed: this.userConnected?.role_data?.access?.includes('export:delete'),
					additionalTooltip: this.userConnected?.role === 'superadmin' ? {
						iconClasses: 'gui-infos text-md absolute mt-1.5 pl-1',
						tooltipValue: 'DONATION_AND_MEMBERSHIP_HEADERS.MAIN_HEADER.TABS.EXPORTS_TOOLTIP',
					} : null
				},
			]
		},

		computedDescriptionText() {
			switch (this.$route.name) {
				case 'imports-list':
					return this.$t('SETTINGS.IMPORT.LIST_IMPORT.EXPLANATION')
				case 'exports-list':
					return this.$t('SETTINGS.EXPORT.EXPLANATION')
				case 'archived-imports-list':
					return this.$t('SETTINGS.IMPORT.LIST_ARCHIVED_IMPORT.EXPLANATION')
				default:
					return ''
			}
		},
	},

	mounted() {
		if (!this.isMenuVisible) {
			this.actionSetMenuVisibility(true)
		}
	},

	methods: {
		...mapActions('@navBar', ['actionSetMenuVisibility']),

		goto(tab) {
			this.tabSelected = tab.name
			this.$router.push({ name: tab.name })
		},

		methodFormatImportList(imports, currentPage) {
			const numberByPage = 50

			const formattedImports = imports
				.slice(numberByPage * (currentPage - 1), numberByPage * currentPage)
				.map((anImport) => {
					const clonedImport = cloneDeep(anImport)
					if (anImport.statuses.length > 1) {
						const lastStatusImport = clonedImport.statuses[clonedImport.statuses.length - 1]
						clonedImport.status = lastStatusImport.label
						clonedImport.dateChange = formatCustomDate(new Date(lastStatusImport.created_at))
					} else {
						if (clonedImport.mapping_defined_at) {
							clonedImport.status = 'to_name'
						} else if (clonedImport.csv_settings_updated_at) {
							clonedImport.status = 'to_mapping'
						} else {
							clonedImport.status = 'to_start'
						}
						clonedImport.dateChange = ''
					}
					return clonedImport
				})

			return formattedImports
		},

		methodComputeTableHeight() {
			nextTick(() => {
				if (this.$refs.settingsExchangesContainer && this.$refs.settingsExchangesHeader)
					this.tableHeight = `calc(${
						this.$refs?.settingsExchangesContainer?.offsetHeight -
						this.$refs?.settingsExchangesHeader?.offsetHeight
					}px - 3.5rem)`
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.bck-color {
	background-color: $pink-lighted;
}
</style>
