/**
 * File to initialize vitally.com javascript
 * We need to global variables
 * API_KEY_VITALLY: which will enable vitally
 * PRODUCTION: true to be effective
 */

!(function () {
	// eslint-disable-next-line no-undef
	if (PRODUCTION && !!process.env.API_KEY_VITALLY) {
		var vitally = (window.Vitally = window.Vitally || [])
		if (!vitally.initialize) {
			vitally.load = function () {
				var t = document.createElement('script')
				t.type = 'text/javascript'
				t.async = !0
				t.src = 'https://cdn.vitally.io/vitally.js/v1/vitally.js'
				var n = document.getElementsByTagName('script')[0]
				n.parentNode.insertBefore(t, n)
			}
			vitally.load()

			vitally.methods = ['init', 'user', 'account', 'track', 'nps']
			vitally.methods.forEach((method) => {
				vitally[method] =
					vitally[method] ||
					function () {
						var args = []
						for (var i = 0; i < arguments.length; i++) {
							args[i] = arguments[i]
						}
						return vitally.push([method, args])
					}
			})

			vitally.init(process.env.API_KEY_VITALLY)
			vitally.initialize = !0
		}
	}
})()
