<template>
	<div class="centered-flex-div">
		<div class="centered-box">
			<div class="p-12">
				<loader size="10rem"></loader>
			</div>
			<h2 v-t="t.pageIsLoading"></h2>
			<p v-show="error" v-t="t.errorHappened"></p>
			<p>
				{{ error }}
			</p>
			<a
				v-show="buttonTimeoutReached"
				v-t="t.backButton"
				class="q-is-primary"
				@click="goBackToCommandCenter"
			></a>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'
import Loader from '../general/loader.vue'

export default {
	components: {
		Loader,
	},

	beforeRouteLeave(from, to, next) {
		this.resetAllAppState()
		if (from.name === 'spaces-view') {
			next({ name: 'command-center', replace: true })
		} else {
			next()
		}
	},

	data() {
		return {
			error: '',
			buttonTimeoutReached: false,
		}
	},
	computed: {
		...mapGetters('@user', ['getUserGroups']),

		t() {
			let prefix = 'WAITING_PAGE'
			return {
				backButton: this.$t(`${prefix}.BACK_BUTTON`),
				errorHappened: this.$t(`${prefix}.ERROR_HAPPENED`),
				pageIsLoading: this.$t(`${prefix}.PAGE_IS_LOADING`),
			}
		},
	},

	async mounted() {
		try {
			setTimeout(() => {
				this.buttonTimeoutReached = true
			}, 10000)
			const splittedCampaignIds = this.$route.params.campaignId.split(',')
			const response = await this.actionUpdateUserCampaignFocus(splittedCampaignIds)
			if (response.body.status === 'success') {
				ElMessage({
					message: this.$t('WAITING_PAGE.NEW_CURRENT_CAMPAIGN'),
					duration: 7000,
					type: 'info',
					showClose: true,
					center: true,
				})
				this.$router.go(-1)
			}
		} catch (err) {
			this.error = err
		}
	},
	methods: {
		...mapActions(['resetAllAppState']),
		...mapActions('@user', ['actionUpdateUserCampaignFocus']),

		goBackToCommandCenter() {
			this.$router.replace({
				name: 'command-center',
			})
		},
	},
}
</script>

<style lang="scss" scoped>
// @import '../../assets/dequorum/style/variables.sass';
.centered-flex-div {
	text-align: center;
	display: flex;
	height: 100vh;
	// min-height: 10em;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.centered-box {
	// border: 2px solid $pink;
	padding: 5em;
	border-radius: 3%;
}
</style>
