<template>
	<div>
		<div v-if="computedForm && computedForm.fields[computedCurrentRowSelected]">
			<div
				v-if="computedForm.fields[computedCurrentRowSelected].type !== 'address'"
				class=""
			>
				<template
					v-if="
						computedForm.fields[computedCurrentRowSelected].values &&
							computedForm.fields[computedCurrentRowSelected].type === 'gender'
					"
				>
					<div
						v-for="(item, index) in computedForm.fields[computedCurrentRowSelected].values"
						:key="index"
						class="no-boder-collapse"
						:style="{ order: orderByType[item.type] }"
					>
						<edit-online-forms-gender-content
							v-if="computedForm.fields[computedCurrentRowSelected].values[index]"
							v-model:current-row-selected="computedCurrentRowSelected"
							v-model:form="computedForm"
							v-model:a-item-body-content="
								computedForm.fields[computedCurrentRowSelected].values[index]
							"
							:all-gender-hidden="
								methodCheckAllGenderAreHidden(
									computedForm.fields[computedCurrentRowSelected].values
								)
							"
							:type-selectable="computedTypeSelectable"
							is-nested-fields
						/>
					</div>
					<div class="mt-2">
						<!-- <el-divider
							v-if="index !== computedForm?.fields[computedCurrentRowSelected]?.values?.length - 1"
							class="mt-6 mb-4"
						></el-divider> -->
						<createParamSideBar
							v-model:value="computedForm.fields[computedCurrentRowSelected].label"
							:data-static-param-sidebar="{
								label: $t(`CREATE_FORM.EDIT_ROW.LABEL.TITLE`),
							}"
							:type-field="'text'"
						/>
					</div>
					<div class="mt-2">
						<createParamSideBar
							v-model:value="computedForm.fields[computedCurrentRowSelected].description"
							:data-static-param-sidebar="{
								label: $t(`CREATE_FORM.EDIT_ROW.DESCRIPTION.TITLE`),
							}"
							:type-field="'textarea'"
						/>
					</div>

					<p class="bold">
						<el-divider class="divider-3 my-6" />
						{{ $t('CREATE_FORM.EDIT_ROW.FIELD.TITLE') }}
					</p>
					<div class="flex flex-row my-2">
						<span class="flex flex-grow font-normal">{{
							$t('CREATE_FORM.EDIT_ROW.MANDATORY.TITLE')
						}}</span>

						<div class="flex flex-row">
							<span class="pr-3">{{
								computedForm.fields[computedCurrentRowSelected].mandatory
									? $t('_COMMON.YES')
									: $t('_COMMON.NO')
							}}</span>

							<el-switch
								v-model="computedForm.fields[computedCurrentRowSelected].mandatory"
								class="force-text-black"
								:disabled="
									methodCheckAllGenderAreHidden(
										computedForm.fields[computedCurrentRowSelected].values
									)
								"
								@change="() => {}"
							/>
						</div>
					</div>
				</template>

				<template v-else>
					<edit-online-forms-row-content
						v-model:current-row-selected="computedCurrentRowSelected"
						v-model:a-item-body-content="computedForm.fields[computedCurrentRowSelected]"
						v-model:form="computedForm"
						:type-selectable="computedTypeSelectable"
					/>
				</template>
			</div>
			<div v-else>
				<div class="flex flex-col">
					<div
						v-for="(item, index) in computedForm.fields[computedCurrentRowSelected].fields"
						:key="index"
						class="no-boder-collapse"
						:style="{ order: orderByType[item.type] }"
					>
						<template v-if="computedForm.fields[computedCurrentRowSelected].type === 'gender'">
							<edit-online-forms-gender-content
								v-model:current-row-selected="computedCurrentRowSelected"
								v-model:a-item-body-content="
									computedForm.fields[computedCurrentRowSelected].fields[index]
								"
								v-model:form="computedForm"
								:type-selectable="computedTypeSelectable"
								is-nested-fields
							/>
						</template>

						<template v-else>
							<el-collapse v-model="activeNames">
								<el-collapse-item
									:name="index"
									class="force-rotate-dropdown-arrow"
								>
									<template #title>
										<div class="flex flex-row items-center">
											<span class="flex flex-row pr-2">
												{{ methodCollapseTitle(item.type) }}
											</span>
											<span><custom-badge
												v-if="recommandedType.includes(item.type)"
												:label="$t('BADGES.RECOMMENDED')"
												:custom-container-class="'bg-green-lighted text-green-main text-xs rounded-md px-1 py-px flex items-center font-light'"
												:icon="'gui-stars color-part-one color-part-two pr-1'"
											/></span>
										</div>
									</template>

									<edit-online-forms-row-content
										v-model:current-row-selected="computedCurrentRowSelected"
										v-model:a-item-body-content="
											computedForm.fields[computedCurrentRowSelected].fields[index]
										"
										v-model:form="computedForm"
										:type-selectable="computedTypeSelectable"
										is-nested-fields
									/>
								</el-collapse-item>
								<!-- v-if="index !== computedForm.fields[computedCurrentRowSelected].fields.length - 1" -->
								<el-divider class="my-0" />
							</el-collapse>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <div v-else>
		<p>Selectionner une rangée pour l'édite watcher currentRowSelectedr</p>
	</div> -->
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		editOnlineFormsRowContent: defineAsyncComponent(
			() => import('./editOnlineFormsRowContent.vue'),
		),
		editOnlineFormsGenderContent: defineAsyncComponent(
			() => import('./editOnlineFormsGenderContent.vue'),
		),
		customBadge: defineAsyncComponent(() => import('../../../badges/customBadge.vue')),

		createParamSideBar: defineAsyncComponent(
			() => import('../../../settings-module/contact-form-settings-sidebar/createParamSidebar.vue'),
		),
	},

	props: {
		currentRowSelected: {
			type: Number || null,
			default: null,
		},
		form: { type: Object, default: () => {}, required: true },
		typeSelectable: {
			type: Object,
			default: () => {},
		},
		defaultParamBarSelection: { type: String || null || Date, default: '' },
	},
	emits: ['update:currentRowSelected', 'update:form'],

	data() {
		return {
			activeNames: [],
			fieldsCopy: [],
			recommandedType: [
				'address.housenumber',
				'address.street',
				'address.postalcode',
				'address.country',
			],
			orderByType: {
				'address.building': 1,
				'address.floor': 2,
				'address.door': 3,
				'address.housenumber': 4,
				'address.street': 5,
				'address.postalcode': 6,
				'address.addition': 7,
				'address.city': 8,
				'address.country': 9,
				'address.state': 10,
				'address.county': 11,
			},
		}
	},

	computed: {
		computedCurrentRowSelected: {
			get() {
				return this.currentRowSelected
			},
			set(val) {
				this.$emit('update:currentRowSelected', val)
			},
		},

		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},

		computedFieldsCopy: {
			get() {
				return this.fieldsCopy
			},
			set(val) {
				this.fieldsCopy = val
			},
		},
		computedTypeSelectable() {
			return this.typeSelectable
		},

		computedLastItemType() {
			const lastItem = cloneDeep(Object.keys(this.orderByType).pop())
			return lastItem
		},
	},

	watch: {
		computedFieldsCopy: {
			handler() {},
			deep: true,
		},
		computedCurrentRowSelected: {
			handler() {
				if (this.computedCurrentRowSelected === null) {
					this.actionSetParamsBar({
						show: true,
						selection: this.defaultParamBarSelection,
					})
				}
			},
		},
	},
	mounted() {
		this.methodSetCopyFields()
	},

	methods: {
		...mapActions('@createForm', ['actionSetParamsBar']),

		methodCheckAllGenderAreHidden(GenderValues) {
			return GenderValues.every((e) => e.hidden)
		},

		methodSortFieldByOrder() {
			computedForm.fields[computedCurrentRowSelected].fields
		},
		methodSetGenderGroupMandatoryToFalse(genderGroup) {
			return (genderGroup.mandatory = false)
		},

		methodSetCopyFields() {
			if (
				this.computedForm.fields
				&& this.computedForm.fields[this.computedCurrentRowSelected]
				&& this.computedForm.fields[this.computedCurrentRowSelected].fields
			) {
				this.fieldsCopy = cloneDeep(
					this.computedForm.fields[this.computedCurrentRowSelected].fields,
				)
			}
			else {
				this.fieldsCopy = []
			}
		},

		methodCollapseTitle(nestedType) {
			let type = nestedType.replace('address.', '').replace('gender.', 'gender_').toUpperCase()
			return this.$t(`CREATE_FORM.EDIT_ROW.TYPE_TITLE.${type}`)
		},
	},
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.no-boder-collapse .el-collapse,
.no-boder-collapse .el-collapse-item .el-collapse-item__header {
	border: 0px;
	font-weight: 800;
}
</style>
