import onlineFormModel from '../../models/online_forms_store'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

import {
	RESET_ONLINE_FORMS_STORE,
	SET_ONLINE_FORMS_CATEGORIES,
	SET_STATUS_FETCH_ONLINE_FORMS,
	SET_ONLINE_FORMS_LIST,
	SET_STATUS_FETCH_ONE_ONLINE_FORM,
	SET_ONLINE_FORM,
	SET_STATUS_POST_ONLINE_FORM,
	SET_STATUS_UPDATE_ONLINE_FORM,
	SET_STATUS_PUBLISH_ONLINE_FORM,
	SET_STATUS_UNPUBLISH_ONLINE_FORM,
	SET_STATUS_DELETE_ONLINE_FORM,
	SET_ONLINE_FORMS_SEARCH,
	SET_SOCIAL_NETWORKS_OPTIONS,
	// SET_ONLINE_FORMS_SEARCH_LENGTH,
} from './_mutation-types'

const getDefaultState = () => {
	return {
		categories: [],
		statusFetchOnlineForms: 'init',
		getOnlineFormsList: [],
		statusFetchOneOnlineForm: 'init',
		getOnlineForm: {},
		statusPostOnlineForm: 'init',
		statusUpdateOnlineForm: 'init',
		statusPublishOnlineForm: 'init',
		statusUnpublishOnlineForm: 'init',
		statusDeleteOnlineForm: 'init',
		onlineFormsSearch: {
			categories: ['published', 'draft'],
			text: '',
		},
		socialNetworkOptions: { color: '', label: true, full: false },
		// onlineFormsSearchLength: 0,
	}
}
const state = getDefaultState

export const mutations = {
	[RESET_ONLINE_FORMS_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_ONLINE_FORMS_CATEGORIES]: (state, payload) => {
		state.categories = payload
	},

	[SET_STATUS_FETCH_ONLINE_FORMS]: (state, payload) => {
		state.statusFetchOnlineForms = payload
	},

	[SET_ONLINE_FORMS_LIST]: (state, payload) => {
		state.getOnlineFormsList = payload
	},

	[SET_STATUS_FETCH_ONE_ONLINE_FORM]: (state, payload) => {
		state.statusFetchOneOnlineForm = payload
	},

	[SET_ONLINE_FORM]: (state, payload) => {
		state.getOnlineForm = payload
	},

	[SET_STATUS_POST_ONLINE_FORM]: (state, payload) => {
		state.statusPostOnlineForm = payload
	},

	[SET_STATUS_UPDATE_ONLINE_FORM]: (state, payload) => {
		state.statusUpdateOnlineForm = payload
	},

	[SET_STATUS_PUBLISH_ONLINE_FORM]: (state, payload) => {
		state.statusPublishOnlineForm = payload
	},

	[SET_STATUS_UNPUBLISH_ONLINE_FORM]: (state, payload) => {
		state.statusUnpublishOnlineForm = payload
	},

	[SET_STATUS_DELETE_ONLINE_FORM]: (state, payload) => {
		state.statusDeleteOnlineForm = payload
	},

	[SET_ONLINE_FORMS_SEARCH]: (state, payload) => {
		state.onlineFormsSearch = payload
	},

	[SET_SOCIAL_NETWORKS_OPTIONS]: (state, payload) => {
		state.socialNetworkOptions = payload
	},

	// [SET_ONLINE_FORMS_SEARCH_LENGTH]: (state, payload) => {
	// 	state.onlineFormsSearchLength = payload
	// },
}

export const actions = {
	// actionResetPetitions({ commit }) {
	actionResetOnlineForms({ commit }) {
		commit(RESET_ONLINE_FORMS_STORE)
	},

	async actionGetCategories({ commit }) {
		return await onlineFormModel
			.getCategories()
			.then((res) => commit(SET_ONLINE_FORMS_CATEGORIES, res || []))
			.catch((e) => console.error(e))
	},

	actionSetOnlineFormsList: ({ commit }) => {
		commit(SET_STATUS_FETCH_ONLINE_FORMS, 'loading')

		onlineFormModel
			.getOnlineForms()
			.then((res) => {
				commit(SET_STATUS_FETCH_ONLINE_FORMS, 'success')
				commit(SET_ONLINE_FORMS_LIST, res)
			})
			.catch(() => commit(SET_STATUS_FETCH_ONLINE_FORMS, 'error'))
	},

	actionSetOnlineForm: ({ commit, state }, payload) => {
		commit(SET_STATUS_FETCH_ONE_ONLINE_FORM, 'loading')

		onlineFormModel
			.getOnlineFormById(payload)
			.then((onlineFormFetched) => {
				const allOnlineForms = cloneDeep(state.getOnlineFormsList) || []
				remove(allOnlineForms, (el) => el.base_id === payload)
				allOnlineForms.push(onlineFormFetched)
				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
				commit(SET_ONLINE_FORM, onlineFormFetched)
				commit(SET_STATUS_FETCH_ONE_ONLINE_FORM, 'success')
			})
			.catch(() => commit(SET_STATUS_FETCH_ONE_ONLINE_FORM, 'error'))
	},

	actionSetNewOnlineForm: ({ commit, state }, payload) => {
		commit(SET_STATUS_POST_ONLINE_FORM, 'loading')

		onlineFormModel
			.postOnlineForm({ online_form: { ...payload } })
			.then((res) => {
				commit(SET_STATUS_POST_ONLINE_FORM, 'success')
				commit(SET_ONLINE_FORM, res)
				const clonedOnlineFormsList = cloneDeep(state.getOnlineFormsList) || []
				clonedOnlineFormsList.push(res)
				commit(SET_ONLINE_FORMS_LIST, clonedOnlineFormsList)
			})
			.catch(() => commit(SET_STATUS_POST_ONLINE_FORM, 'error'))
	},

	actionUpdateOnlineForm: ({ commit, state }, payload) => {
		delete payload.data.id
		delete payload.data.base_id

		commit(SET_STATUS_UPDATE_ONLINE_FORM, 'loading')

		onlineFormModel
			.updateOnlineForm({ id: payload.id, data: payload.data })
			.then((onlineFormUpdated) => {
				const allOnlineForms = cloneDeep(state.getOnlineFormsList)
				remove(allOnlineForms, (el) => el.base_id === payload.id)
				allOnlineForms.push(onlineFormUpdated)
				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
				commit(SET_ONLINE_FORM, onlineFormUpdated)
				commit(SET_STATUS_UPDATE_ONLINE_FORM, 'success')
			})
			.catch(() => commit(SET_STATUS_UPDATE_ONLINE_FORM, 'error'))
	},

	actionPublishOnlineForm: ({ commit, state }, payload) => {
		commit(SET_STATUS_PUBLISH_ONLINE_FORM, 'loading')

		onlineFormModel
			.publishOnlineForm(payload)
			.then((res) => {
				const onlineFormPublishing = res.body.data.online_form
				const allOnlineForms = cloneDeep(state.getOnlineFormsList)
				remove(allOnlineForms, (el) => el.base_id === payload)
				allOnlineForms.push(onlineFormPublishing)
				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
				commit(SET_ONLINE_FORM, onlineFormPublishing)
				commit(SET_STATUS_PUBLISH_ONLINE_FORM, 'success')
			})
			.catch((onlineFormPublishedError) => {
				const allOnlineForms = cloneDeep(state.getOnlineFormsList)
				remove(allOnlineForms, (el) => el.base_id === payload)
				allOnlineForms.push(onlineFormPublishedError)
				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
				commit(SET_STATUS_PUBLISH_ONLINE_FORM, 'error')
			})
	},

	actionUnpublishOnlineForm: ({ commit, state }, payload) => {
		commit(SET_STATUS_UNPUBLISH_ONLINE_FORM, 'loading')

		onlineFormModel
			.unpublishOnlineForm(payload)
			.then((res) => {
				const onlineFormUnpublishing = res.body.data.online_form
				const allOnlineForms = cloneDeep(state.getOnlineFormsList)

				remove(allOnlineForms, (el) => el.base_id === payload)
				allOnlineForms.push(onlineFormUnpublishing)

				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
				commit(SET_ONLINE_FORM, onlineFormUnpublishing)
				commit(SET_STATUS_UNPUBLISH_ONLINE_FORM, 'success')
			})
			.catch(() => commit(SET_STATUS_UNPUBLISH_ONLINE_FORM, 'error'))
	},

	actionDeleteOnlineForm: ({ commit, state }, payload) => {
		commit(SET_STATUS_DELETE_ONLINE_FORM, 'loading')

		onlineFormModel
			.deleteOnlineForm(payload)
			.then(() => {
				commit(SET_STATUS_DELETE_ONLINE_FORM, 'success')
				const allOnlineForms = cloneDeep(state.getOnlineFormsList) || []
				remove(allOnlineForms, (el) => el.base_id === payload)
				commit(SET_ONLINE_FORMS_LIST, allOnlineForms)
			})
			.catch(() => commit(SET_STATUS_DELETE_ONLINE_FORM, 'error'))
	},

	actionDeleteImageUploaded: async ({}, payload) => {
		return onlineFormModel.deletePhotoUploaded(payload)
	},

	actionSetOnlineFormsSearch: ({ commit }, payload) => {
		commit(SET_ONLINE_FORMS_SEARCH, payload)
	},

	actionSetSocialNetworkOptions: ({ commit }, payload) => {
		const { color, label, full } = payload
		commit(SET_SOCIAL_NETWORKS_OPTIONS, { color, label, full })
	},

	// actionSetOnlineFormsSearchLength: ({ commit }, payload) => {
	// 	commit(SET_ONLINE_FORMS_SEARCH_LENGTH, payload)
	// },
}

export const getters = {
	getCategories: (state) => state.categories,

	getStatusFetchOnlineForms: (state) => state.statusFetchOnlineForms,
	getOnlineFormsList: (state) => state.getOnlineFormsList,
	getStatusFetchOneOnlineForm: (state) => state.statusFetchOneOnlineForm,
	getOnlineForm: (state) => state.getOnlineForm,
	getStatusPostOnlineForm: (state) => state.statusPostOnlineForm,
	getStatusUpdateOnlineForm: (state) => state.statusUpdateOnlineForm,
	getStatusPublishOnlineForm: (state) => state.statusPublishOnlineForm,
	getStatusUnpublishOnlineForm: (state) => state.statusUnpublishOnlineForm,
	getStatusDeleteOnlineForm: (state) => state.statusDeleteOnlineForm,
	getOnlineFormsSearch: (state) => state.onlineFormsSearch,
	socialNetworkOptions: (state) => state.socialNetworkOptions,
	// getOnlineFormsSearchLength: (state) => state.onlineFormsSearchLength,
}

export const onlineFormModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
