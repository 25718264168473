<template>
	<div id="preview-container">
		<div id="preview-title">{{ title }}</div>
		<template v-for="section in sections" :key="section.id">
			<div class="preview-section">
				<div class="preview-section-title">{{ section.addedOptions.name }}</div>
				<div class="preview-questions">
					<template v-for="(question, questionIndex) in section.questions" :key="question.id">
						<div
							class="preview-question"
							:class="
								question.type != 'checkbox' && question.type != 'radio'
									? 'preview-one-line-question'
									: ''
							"
						>
							<span class="preview-question-title"
								>{{ questionIndex + 1 }}. {{ question.label }}
								<span v-if="question.type == 'date'" class="preview-date-entry-span"
									>____ / ____ / ____</span
								></span
							>
							<template v-if="question.type == 'text'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'checkbox' || question.type == 'radio'">
								<div class="preview-options-container">
									<template v-for="option in question.refvalues" :key="option.id">
										<div class="preview-option">
											<div
												class="preview-option-icon option-icon"
												:class="question.type + '-icon'"
											></div>
											<span class="preview-option-text">{{ option.label }}</span>
										</div>
									</template>
								</div>
							</template>
							<template v-else-if="question.type == 'range'">
								<div class="preview-one-line-answer preview-range"></div>
							</template>
							<template v-else-if="question.type == 'surname'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'married_name'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'firstname'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'gender'">
								<div class="preview-option gender-option">
									<div class="radio-icon preview-option-icon option-icon"></div>
									<span class="preview-option-text">H</span>
								</div>
								<div class="preview-option gender-option">
									<div class="radio-icon preview-option-icon option-icon"></div>
									<span class="preview-option-text">F</span>
								</div>
							</template>
							<template v-else-if="question.type == 'age'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'address'">
								<div class="preview-one-line-answer preview-text"></div>
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'city'">
								<div class="preview-one-line-answer preview-text"></div>
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'mail'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
							<template v-else-if="question.type == 'phone'">
								<div class="preview-one-line-answer preview-text"></div>
							</template>
						</div>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'FormViewer',

	// Available options:
	// {
	//   optionsDisplay : "grid" or "rows" (grid displays options in a grid, line displays options one per line) default: grid
	// }
	props: ['title', 'sections', 'options'],

	mounted: function () {
		this.fixFormatting()
	},

	updated: function () {
		this.fixFormatting()
	},

	methods: {
		// Some styling has to be dynamically modified/computed based on the sections data.
		// So this can be easily called wheneve the page is loaded/modified, they are all put here in this wrapper function
		fixFormatting: function () {
			this.formatAnswerLine()
			// default behaviour is to display the options in a grid
			if (!(this.options && this.options.optionsDisplay && this.options.optionsDisplay == 'rows')) {
				this.formatOptionsGrid()
			} else {
				$(this.$el).find('.preview-option').css('width', '100%')
			}
		},

		// The default behaviour for text and range quetions is to have an empty underlined section for whatever empty space
		// remains on the line after the question.  However, this asssumes that the question isn't too long and so leaves
		// sufficient space for an answer.  This function ensures that the line is some minimum length, and if not,
		// puts the answer line on a new line.
		// TODO - for range, a simple underline may not be the best
		formatAnswerLine: function () {
			$('.preview-text, .preview-range').each(function () {
				// minimum size in px (kind of arbitrary)
				let minSize = 100
				if (
					$(this).parent().outerWidth() -
						$(this).parent().find('.preview-question-title').outerWidth() <=
					minSize
				) {
					// if the space left isn't big enough, change the css so the answer line appears on a new line
					$(this).parent().css('display', 'block')
					$(this).addClass('preview-empty-line')
				} else {
					$(this).parent().css('display', '')
					$(this).removeClass('preview-empty-line')
				}
			})
		},

		// Display the options in a grid; do this by figuring out the max options/line could fit if every option
		// was the same width as the widest option (so all the spacing is even)
		formatOptionsGrid: function () {
			$(this.$el)
				.find('.preview-options-container')
				.each(function () {
					let maxWidth = 0

					// find the width of the widest option
					$(this)
						.find('.preview-option')
						.each(function () {
							let width = $(this).outerWidth()
							if (width > maxWidth) {
								maxWidth = width
							}
						})

					// Put only as many options per line as would fit if every option had maxWidth width
					let numQuestionsPerLine = Math.floor($(this).outerWidth() / maxWidth)
					if (numQuestionsPerLine == 0) {
						// there should be at least one option per line
						numQuestionsPerLine++
					} else if (numQuestionsPerLine > $(this).find('.preview-option').length) {
						// if there is a single line, evenly space out all the options on that one line
						numQuestionsPerLine = $(this).find('.preview-option').length
					}

					$(this)
						.find('.preview-option')
						.css('width', 'calc(100% / ' + numQuestionsPerLine + ')')
				})
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/form/form-viewer.scss';
</style>
