import { API, LOCAL_DEV_DATA_TEAM_MODE_ENABLED } from '../constants'

var Emitter = require('events').EventEmitter,
	common = new Emitter(),
	data = 'Token',
	// main = require('../components/app-module/index.js'),
	status = 'fail'

common.api = API[0].LOCAL
common.public_api = API[2].LOCAL
common.local_territory_api = ''
common.kpi_api = API[3].LOCAL

// When the data team is developping locally, we need to remap some routes
if (LOCAL_DEV_DATA_TEAM_MODE_ENABLED) {
	common.local_territory_api = '/local-territory-api'
}

common.token = function (res) {
	if (res != undefined) {
		return res.body.data == data && res.body.status == status ? 1 : 0
	}

	return 1
}

common.cb = function (root) {
	root.push('{name : login}')
}

export default common
export const local_territory_api = common.local_territory_api
export const api = common.api
export const public_api = common.public_api
export const kpi_api = common.kpi_api
