import { SET_PARAMS_BAR, SET_IS_MOBILE_VIEW, SET_IS_CONFIRMATION_VIEW } from './_mutation-types'

const getDefaultState = () => {
	return {
		paramsBar: { show: null, selection: null },
		isMobileView: false,
		isConfirmationView: false,
	}
}
const state = getDefaultState

export const mutations = {
	SET_PARAMS_BAR: (state, res) => (state.paramsBar = res),
	SET_IS_MOBILE_VIEW: (state, res) => (state.isMobileView = res),
	SET_IS_CONFIRMATION_VIEW: (state, res) => (state.isConfirmationView = res),
}

export const actions = {
	actionSetParamsBar: ({ commit }, payload) => {
		const { show, selection } = payload
		commit(SET_PARAMS_BAR, { show, selection })
	},

	actionSetIsMobileView: ({ commit }, payload) => {
		commit(SET_IS_MOBILE_VIEW, payload)
	},

	actionSetIsConfirmationView: ({ commit }, payload) => {
		commit(SET_IS_CONFIRMATION_VIEW, payload)
	},

	actionResetCreateForm: ({ commit }) => {
		commit(SET_PARAMS_BAR, { show: null, selection: null })
	},
}

export const getters = {
	paramsBar: (state) => state.paramsBar,
	isMobileView: (state) => state.isMobileView,
	isConfirmationView: (state) => state.isConfirmationView,
}

export const createFormModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
