import { GEOHASH_RESET_TO_INITIAL_STATE } from './_mutation-types'
import contact_store from '../../models/contact_store'
import { constructQueryFromStore } from './utils/searchTools'

const getDefaultState = () => {
	return {
		locationSummaryGeohashIsLoading: false,
		location_summary_geohash: [],
		locationGeohashDone: [],
		locationGeohashUndone: [],
		// map bounding box
		// northeast_lat : "",
		// northeast_lng : "",
		// southwest_lat : "",
		// southwest_lng : "",

		nb_resultats_location_summary_geohash: '500',
		precision_geohash: '5',
		geohash_array: [],
		locationGeohashDoneUpdated: false,
		locationGeohashUndoneUpdated: false,
	}
}

const state = getDefaultState

const mutations = {
	SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING: (state, payload) => {
		state.locationSummaryGeohashIsLoading = payload
	},
	SET_LOCATIONS_SUMMARY_GEOHASH: (state, res) => {
		state.location_summary_geohash = res
	},
	SET_LOCATIONS_GEOHASH_DONE: (state, res) => {
		state.locationGeohashDone = res
	},

	RESET_LOCATIONS_GEOHASH_DONE: (state) => {
		state.locationGeohashDone = []
	},

	SET_LOCATIONS_GEOHASH_UNDONE: (state, res) => {
		state.locationGeohashUndone = res
	},

	RESET_LOCATIONS_GEOHASH_UNDONE: (state) => {
		state.locationGeohashUndone = []
	},
	SET_NB_RESULTATS_LOCATION_SUMMARY_GEOHASH: (state, res) => {
		state.nb_resultats_location_summary_geohash = res
	},
	SET_PRECISION_GEOHASH: (state, res) => {
		state.precision_geohash = res
	},
	SET_GEOHASH_ARRAY: (state, res) => {
		state.geohash_array = res
	},
	[GEOHASH_RESET_TO_INITIAL_STATE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},

	SET_LOCATION_GEOHASH_DONE_UPDATED: (state, payload) => {
		state.locationGeohashDoneUpdated = payload
	},

	SET_LOCATION_GEOHASH_UNDONE_UPDATED: (state, payload) => {
		state.locationGeohashUndoneUpdated = payload
	},
}

const actions = {
	actionSetLocationGeohashSummaryIsLoading({ commit }, payload) {
		commit('SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING', payload)
	},
	actionSetLocationsGeohashDone({ commit }, payload) {
		commit('SET_LOCATIONS_SUMMARY_GEOHASH_DONE', payload)
	},

	actionSetLocationsGeohashUndone({ commit }, payload) {
		commit('SET_LOCATIONS_SUMMARY_GEOHASH_UNDONE', payload)
	},

	actionResetGeohashModule({ commit }) {
		commit(GEOHASH_RESET_TO_INITIAL_STATE)
	},

	actionUpdateLocationGeohashDone: ({ rootState, commit }) => {
		contact_store
			.locationSummaryFactsGeoHash(
				rootState['@filter'].query,
				[],
				[],
				rootState['@filter'].tags_filter,
				false,
				rootState.type_data,
				rootState.actionID,
				'done',
				8,
			)
			.then((res) => {
				commit('SET_LOCATIONS_GEOHASH_DONE', res.body.data.data)
				commit('SET_LOCATION_GEOHASH_DONE_UPDATED', true)
			})
	},

	actionUpdateLocationGeohashUndone: ({ state, rootState, commit }) => {
		contact_store
			.locationSummaryFactsGeoHash(
				rootState['@filter'].query,
				[],
				[],
				rootState['@filter'].tags_filter,
				false,
				rootState.type_data,
				rootState.actionID,
				'todo',
				8,
			)
			.then((res) => {
				commit('SET_LOCATIONS_GEOHASH_UNDONE', res.body.data.data)
				commit('SET_LOCATION_GEOHASH_UNDONE_UPDATED', true)
			})
	},

	actionSetLocationGeohashDoneUpdated({ commit }, payload) {
		commit('SET_LOCATION_GEOHASH_DONE_UPDATED', payload)
	},

	actionSetLocationGeohashUndoneUpdated({ commit }, payload) {
		commit('SET_LOCATION_GEOHASH_UNDONE_UPDATED', payload)
	},

	UPDATE_LOCATION_SUMMARY_GEOHASH: ({ commit, getters, rootGetters }) => {
		commit('SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING', true)
		const precisiongeohash = 8
		contact_store
			.locationSummaryGeoHash({
				...constructQueryFromStore(rootGetters),
				precisiongeohash,
			})
			.then((res) => {
				commit('SET_LOCATIONS_SUMMARY_GEOHASH', res.contacts)
				commit('SET_GEOHASH_ARRAY', res.data)
			})
			.finally(() => {
				commit('SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING', false)
			})
	},

	actionLocationSummaryGeohashWithoutPolygon: ({ commit, rootGetters }) => {
		commit('SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING', true)
		const precisiongeohash = 8
		let payload = {
			...constructQueryFromStore(rootGetters),
			precisiongeohash,
		}
		payload.polygon = []
		delete payload?.advanced_search?.multi_polygons

		contact_store
			.locationSummaryGeoHash(payload)
			.then((res) => {
				commit('SET_LOCATIONS_SUMMARY_GEOHASH', res.contacts)
				commit('SET_GEOHASH_ARRAY', res.data)
			})
			.finally(() => {
				commit('SET_LOCATIONS_SUMMARY_GEOHASH_IS_LOADING', false)
			})
	},
}

const getters = {
	locationSummaryGeohashIsLoading: (state) => state.locationSummaryGeohashIsLoading,
	location_summary_geohash: (state) => state.location_summary_geohash,
	getLocationGeohashDone: (state) => state.locationGeohashDone,

	getLocationGeohashUndone: (state) => state.locationGeohashUndone,

	// northeast_lat : state => {
	// 	return state.northeast_lat
	// },
	// northeast_lng : state => {
	// 	return state.northeast_lng
	// },
	// southwest_lat : state => {
	// 	return state.southwest_lat
	// },
	// southwest_lng : state => {
	// 	return state.southwest_lng
	// },
	nb_resultats_location_summary_geohash: (state) => {
		return state.nb_resultats_location_summary_geohash
	},
	precision_geohash: (state) => {
		return state.precision_geohash
	},
	geohash_array: (state) => {
		return state.geohash_array
	},
	geohash_array_clean: (state) => {
		return state.geohash_array?.length ? state.geohash_array[0]?.Map : []
	},
	getLocationGeohashDoneUpdated: (state) => state.locationGeohashDoneUpdated,
	getLocationGeohashUndoneUpdated: (state) => state.locationGeohashUndoneUpdated,
}

export const geohashModule = {
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
