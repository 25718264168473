/**
 * Helper to create a multi level group by
 *
 * @param {Object} items
 * @param {String[]} keys
 * @param {Boolean} flat If true, only the last item is kept
 * @returns {Map<String, any>}
 */
function multiGroupBy(items, keys, flat = false) {
	if (!items) return new Map()

	const nbKeys = keys.length
	const out = new Map()

	items.forEach((item) => {
		let lastMap = out
		let lastItemKey
		keys.forEach((key, idx) => {
			const itemKey = item[key]
			if (idx < nbKeys - 1) {
				if (!lastMap.has(itemKey)) {
					lastMap.set(itemKey, new Map())
				}
				lastMap = lastMap.get(itemKey)
			}
			lastItemKey = itemKey
		})

		if (flat === false) {
			if (!lastMap.has(lastItemKey)) {
				lastMap.set(lastItemKey, [])
			}
			lastMap.get(lastItemKey).push(item)
		} else {
			lastMap.set(lastItemKey, item)
		}
	})
	return out
}

export default multiGroupBy
