import { ACTION } from '../constants'
import { $request } from './requester'
import { zonedTimeToUtc } from 'date-fns-tz'

/**
 * @typedef {Object} Action
 * @property {Point[]} polygon - array of points
 */

/**
 * @typedef {Object} Point - a geopoint for leaflet
 * @property {Number} lat - latitude of the point
 * @property {Number} lng - longitude of the point
 */

/**
 * @model Action
 */
export default {
	async getActions() {
		return $request('GET', '/actionsfilterbygroup').then((res) => {
			res.body.data.ActionsAggreg = res.body.data.ActionsAggreg?.filter((oneAggregAction) => {
				return oneAggregAction.Action.status !== ACTION.STATUS.ARCHIVED
			})
			// Change public name for the front
			res.body.data.ActionsAggreg.map((action) => {
				if (action.Action.public != null) {
					action.Action.publicAction = action.Action.public
					delete action.Action.public
				}
			})
			return res.body.data
		})
	},

	async getActionById(actionId) {
		return $request('GET', `/actions/${actionId}`).then((res) => {
			console.log(res)
		})
	},

	async createAction(action) {
		// Apify the object :
		// IDEA: Change the object from the beginning ?
		const timezone = action.timezone || 'Europe/Paris'
		for (let key in action) {
			if (!Object.prototype.hasOwnProperty.call(action, key)) continue

			switch (key) {
				case 'cover_img':
					if (action?.cover_img?.index) {
						if (action.cover_img.url.includes('http')) {
							delete action.cover_img?.index
						}
						else {
							action.cover_img.url = action?.cover_img?.index.toString()
							delete action.cover_img?.index
						}
					}
					break
				case 'title':
					action.name = action?.title?.trim()
					delete action.title
					break

				case 'type':
					action.type_data = action.type
					delete action.type
					break

				case 'comment':
					action.pitch = action?.comment?.trim()
					delete action.comment
					if (!action.pitch) delete action.pitch
					break

				case 'usersIds':
					action.users = action.usersIds.map((user) => {
						return { id: user }
					})
					delete action.usersIds
					break

				case 'teamsIds':
					action.teams = action.teamsIds.map((team) => {
						return { id: team }
					})
					delete action.teamsIds
					break

				case 'leaders':
					action.leaders = action.leaders.map((aLeader) => {
						return { id: aLeader.id }
					})
					break

				case 'goal':
					action.goal = parseInt(action.goal)
					if (!action.goal) delete action.goal
					break

				case 'goal_forms':
					action.goal_forms = parseInt(action.goal_forms)
					if (!action.goal_forms) delete action.goal_forms
					break

				case 'typeFormDisabledAt':
				case 'createdBy':
				case 'city':
				case 'firstTimer':
				case 'globalType':
					delete action[key]
					break

				case 'start':
				case 'end': {
					if (timezone) {
						const utcDate = zonedTimeToUtc(action[key], timezone)
						action[key] = utcDate
					}
					if (!action[key]) delete action[key]
					break
				}
				case 'timezone': {
					let tz = action.timezone || 'Europe/Paris'
					action.time_zone = tz
					delete action.timezone
					break
				}

				case 'publicAction':
					action.public = action.publicAction
					delete action.publicAction
					break

				case 'registration_link':
					// Delete the registration link if it's not an event type because it's restricted to this type
					if (
						(action.type_data && action.type_data != 'event')
						|| (action.type && action.type != 'event')
					)
						delete action.registration_link
					break

				case 'search': {
					if (action.search.advanced_search) {
						const queryBuilder = action.search.advanced_search
						queryBuilder.perPage = 25_000
						action.search.advanced_search = queryBuilder.build()
						if (action.search.polygon.length) {
							action.search.advanced_search.polygon = action.search.polygon
						}
					}

					action.search = {
						Search: {
							...action.search,
							query: action.search.query,
							polygon: action.search.polygon,
							fields: action.search.filter.concat(action.search.questions),
							tags: action.search.tags,
						},
					}

					delete action.search.Search.filter
					delete action.search.Search.tags_filter
					break
				}

				default:
					if (!action[key]) delete action[key]
					break
			}
		}
		/**
		 * Add a status to the action
		 */
		action.status = ACTION.STATUS.TODO
		/**
		 * Add a notify to true
		 */
		// action.notify is defined in initial payload
		// action.notify = true
		/**
		 * Create the action
		 */
		return $request('POST', '/actions', action).then((res) => {
			return res.body.data.action
		})
	},

	async disableFormForAnAction(payload) {
		return $request('POST', '/forms_disabled', payload).then(() => true)
	},

	async update(action) {
		// action.users = action.Users.map((user) => {
		// 	return { id: user.id }
		// })
		// Change publicAction name for the back
		if (action.publicAction != null) {
			action.public = action.publicAction
			delete action.publicAction
		}
		return $request('PATCH', '/actions', action).then((res) => {
			// Change public name for the front
			if (res.body.data.action.public != null) {
				res.body.data.action.publicAction = res.body.data.action.public
				delete res.body.data.action.public
			}
			return res.body.data.action
		})
	},

	async archive(action) {
		return $request('PATCH', '/actions', {
			ID: action.ID,
			status: ACTION.STATUS.ARCHIVED,
		}).then((res) => {
			// Change public name for the front
			if (res.body.data.action.public != null) {
				res.body.data.action.publicAction = res.body.data.action.public
				delete res.body.data.action.public
			}
			return res.body.data.action
		})
	},

	async close(actionId) {
		return $request('PATCH', '/actions', {
			ID: actionId,
			status: ACTION.STATUS.FINISHED,
		}).then((res) => {
			// Change public name for the front
			if (res.body.data.action.public != null) {
				res.body.data.action.publicAction = res.body.data.action.public
				delete res.body.data.action.public
			}
			return res.body.data.action
		})
	},

	async open(actionId) {
		return $request('PATCH', '/actions', {
			ID: actionId,
			status: ACTION.STATUS.TODO,
		}).then((res) => {
			// Change public name for the front
			if (res.body.data.action.public != null) {
				res.body.data.action.publicAction = res.body.data.action.public
				delete res.body.data.action.public
			}
			return res.body.data.action
		})
	},

	/**
	 * @async
	 * @func searchAddresses
	 * @param {string} query - the string searched for addresses
	 * @param {boolean} allowUnknowAddresses Boolean used to know if we want to search on empty addresses
	 */
	async searchAddresses(query, withPhone = false, allowUnknowAddresses = false) {
		return $request('GET', '/search/addresses', {
			query: query,
			withPhone: withPhone,
			unknow: allowUnknowAddresses,
		}).then((res) => {
			return JSON.parse(res.text)
		})
	},

	async getContactsLocationSummary(
		query,
		fields,
		polygon,
		precisiongeohash,
		excluded_nevada = false,
	) {
		return $request('POST', '/locationSummaryContactsGeoHash', {
			query,
			fields,
			polygon,
			precisiongeohash,
			excluded_nevada,
		}).then((res) => res.body.data)
	},

	async renotify(data) {
		return $request('POST/WITHOUT_DATA_KEY', '/action-participation-reminder', {
			action_id: data,
		}).then((res) => {
			if (res.body?.status === 'success') return res.body.data
			else return null
		})
	},
}
