/**
 * User-module routes
 *
 * handle user-related routes
 *
 * @rootPath {/user}
 */
import Members from 'components/user_management-module/user-list/members.vue'
import { controllerPackRedirection, getterControllerStorePackCPR } from './utils/routerController'
import store from '../store/stateStore'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

export default {
	meta: { title: 'MENU.MEMBERS' },
	path: '/users',
	name: 'members',
	component: Members,
	redirect: { name: 'users-list' },
	beforeEnter: (to, from, next) => {
		// Role access check
		// Allow global notitification because it's linked to communication
		if (
			store.getters['userConnected']?.role_data?.access?.includes('members:write')
			|| to?.name == 'global-notification'
		) {
			next()
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: 'list',
			name: 'users-list',
		},
		{
			path: 'teams',
			name: 'teams',
			beforeEnter: (to, from, next) => {
				controllerPackRedirection(next, 'statusWebAccessMembersTeams')
			},
		},
		// {
		// 	path: 'waiting-users',
		// 	name: 'waiting-users',
		// 	beforeEnter: (to, from, next) => {
		// 		if (store.getters['info_campagne']?.global_cause) {
		// 			next()
		// 		} else {
		// 			next({ name: 'command-center', replace: true })
		// 		}
		// 	},
		// },
		{
			path: 'invitations',
			name: 'invitations',
			beforeEnter: (to, from, next) => {
				const CPR = getterControllerStorePackCPR()
				if (!CPR.statusWebAccessMembersInvitations()) {
					next()
				}
				else if (methodIsAccessGranted('members.invitation:invite')) {
					controllerPackRedirection(next, 'statusWebAccessMembersInvitations')
				}
				else {
					next({ name: 'command-center', replace: true })
				}
			},
		},
		{
			path: 'global-notification',
			name: 'global-notification',
			component: () =>
				import('../components/communications-module/global-notification/GlobalNotification'),
			beforeEnter: (to, from, next) => {
				// Check role access to communication
				if (store.getters['userConnected']?.role_data?.access?.includes('communication:delete')) {
					next()
				}
				else {
					next({ name: 'command-center', replace: true })
				}
			},
		},
	],
}
