import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import sessionStore from '../../models/session_store'
import userStore from '../../models/user_store'
import team_store from '../../models/team_store'

import {
	DELETE_USER_EMAIL_ACCOUNT,
	SET_NOTIFICATIONS_PREFERENCES,
	SET_USER_EMAIL_ACCOUNTS,
} from './_mutation-types'
import user_store from '../../models/user_store'
import { nextTick } from 'vue'

/**
 * @store @user
 * @namespaced
 * @description Users' related info and store module
 */

const state = {
	activeUser: {},
	notificationsPreferences: {},

	// Booleans
	loading: false,
	new_user: false,
	inviting: false,
	showDetail: false,
	userGroups: [],
	countSwitchActiveUser: 0,
	userEmailAccounts: [],
	statusUserRoleUpdate: 'init',
}

const mutations = {
	SET_ACTIVE_USER: (state, activeUser) => {
		state.activeUser = activeUser
		state.countSwitchActiveUser += 1
	},

	SET_ACTIVE_USER_ROLE: (state, role) => {
		state.activeUser.role_data = role
	},

	DELETE_ACTIVE_USER: (state) => {
		state.activeUser = {}
	},

	START_LOADING: (state) => {
		state.loading = true
	},

	STOP_LOADING: (state) => {
		state.loading = false
	},

	SET_NEW_USER: (state, res) => {
		state.new_user = res
	},

	RESET_NEW_USER: (state) => {
		state.new_user = false
	},

	SHOW_DETAIL: (state) => {
		state.showDetail = true
	},

	HIDE_DETAIL: (state) => {
		state.showDetail = false
	},

	INVITE: (state) => {
		state.inviting = true
	},

	END_INVITE: (state) => {
		state.inviting = false
	},
	SET_USER_GROUPS: (state, payload) => {
		state.userGroups = payload
	},
	[SET_NOTIFICATIONS_PREFERENCES]: (state, payload) => {
		if (!payload.available_types) {
			state.notificationsPreferences.current = payload
		}
		else {
			state.notificationsPreferences = payload
		}
	},
	[SET_USER_EMAIL_ACCOUNTS]: (state, payload) => {
		state.userEmailAccounts = payload
	},
	[DELETE_USER_EMAIL_ACCOUNT]: (state, payload) => {
		const index = state.userEmailAccounts.findIndex((account) => account.id === payload)

		delete state.userEmailAccounts[index]
	},
	SET_STATUS_USER_ROLE_UPDATE: (state, payload) => {
		state.statusUserRoleUpdate = payload
	},
}

const actions = {
	actionCheckPassword(_store, payload) {
		// const {password, mail} = payload
		return sessionStore.getSession(payload, false)
	},

	actionUpdateUserPassword(_store, payload) {
		return sessionStore.updateUserPassword(payload)
	},

	actionResetUserPassword(_store, payload) {
		return sessionStore.confirmPassword(payload)
	},

	actionAskForPasswordResetToken(_store, payload) {
		return sessionStore.password(payload)
	},

	async actionEditTeamForActiveUser({ commit, dispatch, state }, payload) {
		const teamsCloned = cloneDeep(payload.teams)
		const completeTeamsCloned = cloneDeep(payload.completeTeams)

		await Promise.all(
			Array.from(teamsCloned).map(async (team) => {
				const clonedTeam = cloneDeep(team)

				if (clonedTeam.checked) {
					delete clonedTeam.checked
					clonedTeam.users = clonedTeam.users.map((user) => {
						return {
							id: user.id,
						}
					})
					clonedTeam.users.push({ id: state.activeUser.id })
					await team_store.saveEditedTeam({ team: clonedTeam })
				}
				else {
					delete clonedTeam.checked
					clonedTeam.users = clonedTeam.users.map((aUser) => {
						return { id: aUser.id }
					})

					if (clonedTeam.users.some((user) => user.id === state.activeUser.id)) {
						clonedTeam.users = clonedTeam.users.filter((user) => user.id !== state.activeUser.id)
						await team_store.saveEditedTeam({ team: clonedTeam })
					}
				}
			}),
		)
		const clonedActiveUser = cloneDeep(state.activeUser)

		completeTeamsCloned.forEach((team) => {
			if (team.checked) {
				if (!Object.prototype.hasOwnProperty.call(clonedActiveUser, 'teams')) {
					clonedActiveUser.teams = []
				}

				clonedActiveUser.teams.push(team)
			}
			else {
				const index = clonedActiveUser.teams.findIndex((userTeam) => userTeam.id === team.id)

				clonedActiveUser.teams.splice(index, 1)
			}
		})
		dispatch('@team/actionFetchTeams', null, { root: true })
		commit('SET_ACTIVE_USER', clonedActiveUser)
	},

	/**
	 * @action store:@user.updateUser
	 * @param {Vuex.Store} _store
	 * @param {User} user the user to update. **ID is mandatory**
	 * @see {@link model:User.updateUser updateUser} in model
	 *
	 * @returns {Promise}
	 */
	async updateUser({ commit }, user) {
		const avatar = user.avatar

		delete user.avatar

		return userStore.updateUser(user).then(() => {
			commit('SAVE_ACTIVE_USER', { ...user, avatar }, { root: true })
			commit('SET_ACTIVE_USER', { ...user, avatar })
			commit('EDIT', false, { root: true })
		})
	},

	async actionUpdateUserRole({ commit }, payload) {
		const { role, user, advancedSearch, savedFiltersId } = payload
		let updatePayload = { user_id: user.id, role_id: role.id }

		const dataAdvancedSearch = {
			saved_filters_id: savedFiltersId,
			advanced_search: advancedSearch,
		}

		if (advancedSearch && savedFiltersId) {
			updatePayload = {
				...updatePayload,
				...dataAdvancedSearch,
			}
		}

		userStore
			.updateUserRole(updatePayload)
			.then(async () => {
				commit('SET_STATUS_USER_ROLE_UPDATE', 'init')
				// Permite trigger the watcher

				const userToUpdate = {
					...user,
					role_data: {
						...role,
						...dataAdvancedSearch,
					},
				}

				commit('SAVE_USER', userToUpdate, { root: true })
				commit('SET_STATUS_USER_ROLE_UPDATE', 'success')
			})
			.catch(() => commit('SET_STATUS_USER_ROLE_UPDATE', 'error'))
	},

	async actionUpdateUserEmailSenders({ commit }, { userId, senderIds }) {
		try {
			const payload = {
				user_ids: [userId],
				sender_ids: senderIds,
			}
			await userStore.updateEmailSenders(payload)
			return true
		}
		catch (error) {
			console.error('Error updating email senders:', error)
			throw error
		}
	},

	async actionFetchUserGroups({ commit }) {
		return commit('SET_USER_GROUPS', await sessionStore.myGroups())
	},

	async actionUpdateUserCampaignFocus({}, payload) {
		const currentUser = await sessionStore.getMe()

		if (currentUser) {
			currentUser.selected_group_id = [payload]
			currentUser.UpdateToken = true
		}

		return userStore.updateUser(currentUser)
	},

	invite({ rootState }, payload) {
		const code = rootState.info_campagne.code_cause
		const campain = rootState.info_campagne.name

		return userStore.invite({
			code,
			campain,
			emails: payload,
		})
	},

	async actionDeleteUser({}, payload) {
		return userStore.delete(payload)
	},

	async actionFetchUserById({}, payload) {
		return userStore.getUserById(payload.id)
	},

	// MOCK PROFILE
	async actionFetchUserAvailability({}, payload) {
		return await userStore.getUserAvailability(payload.id)
	},
	// MOCK PROFILE
	async actionUpdateUserAvailability({}, payload) {
		return await userStore.updateUserAvailability(payload.id, payload.data)
	},

	async actionFetchAllNotificationsPreferences({ commit }) {
		await userStore.getAllNotificationsPreferences().then((res) => {
			commit(SET_NOTIFICATIONS_PREFERENCES, res)
		})
	},

	async actionSetNotificationsPreferences({ commit }, payload) {
		await userStore.setNotificationPreference({ ...payload }).then((res) => {
			commit(SET_NOTIFICATIONS_PREFERENCES, res)
		})
	},

	async actionConnectBox() {
		userStore.connectBox().catch((err) => console.log(err))
	},

	async actionDeleteBox({ state, commit }, accountId) {
		userStore
			.deleteBox(accountId)
			.then(() => {
				commit(DELETE_USER_EMAIL_ACCOUNT, accountId)
			})
			.catch((err) => {
				console.log(err)
			})
	},

	async actionGetEmailBoxStatus({ commit }, payload) {
		userStore
			.getEmailBoxStatus()
			.then((res) => {
				let userAccounts = res
				if (res.length) userAccounts = userAccounts.filter((account) => account.user_id === payload.userId)

				commit(SET_USER_EMAIL_ACCOUNTS, userAccounts)
			})
			.catch((err) => {
				console.log(err)
			})
	},

	actionChangeAccountSharing({ dispatch }, payload) {
		user_store
			.updateAccountSharing({ ...payload.payload })
			.then(() => {
				dispatch('actionGetEmailBoxStatus', { userId: payload.userId })
			})
			.catch((err) => console.log(err))
	},

	resetState({ commit }) {
		commit('STOP_LOADING')
		commit('RESET_NEW_USER')
		commit('DELETE_ACTIVE_USER')
		commit('HIDE_DETAIL')
		commit('END_INVITE')
	},

	async actionGetUserSenders({ commit }, userId) {
		try {
			const senders = await userStore.getUserSenders(userId)
			if (!senders) {
				return []
			}

			return senders.map((sender) => ({
				...sender,
				validated: 'validated' in sender && sender.validated === true,
				default_flag: false,
			}))
		}
		catch (error) {
			console.error('Store: Error fetching user senders:', error)
			return []
		}
	},
}

const getters = {
	activeUser: (state) => state.activeUser,
	inviting: (state) => state.inviting,
	loading: (state) => state.loading,
	getUserGroups: (state) => {
		if (state.userGroups.length === 0) return []

		const clonedGroups = cloneDeep(state.userGroups)
		const clonedParents = clonedGroups?.filter((aGroup) => aGroup.hierarchy === 'parent')

		if (clonedParents.length === 0) return clonedGroups

		clonedParents.forEach((aParent) => {
			const parentGlobalCause = aParent.global_cause
			const arrayChild = clonedGroups.filter(
				(aGroup) => aGroup.hierarchy === 'child' && aGroup.global_cause === parentGlobalCause,
			)
			const ids = map(arrayChild, 'id')

			ids.unshift(aParent.id)
			aParent.id = ids
		})

		return clonedGroups
	},

	getUserSubGroupName: (state, getters, rootState) => (groupId) => {
		if (!groupId || !rootState.info_campagne?.masterView) return ''

		const findSubGroupId = state.userGroups.find((aGroup) => aGroup.id === groupId)

		if (!findSubGroupId) return ''

		return findSubGroupId.name
	},

	new_user: (state) => state.new_user,
	showUser: (state) => state.showDetail,
	countSwitch: (state) => state.countSwitchActiveUser,
	getNotificationsPreferences: (state) => state.notificationsPreferences,
	getUserEmailAccounts: (state) => state.userEmailAccounts,
	isEmailBoxConnecting: (state) =>
		state.userEmailAccounts.some((account) => account.status === 'syncing'),
	isEmailBoxConnected: (state) =>
		state.userEmailAccounts.some((account) => account.status === 'connected'),
	getStatusUserRoleUpdate: (state) => state.statusUserRoleUpdate,
}

export const userModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
