<template>
	<div
		ref="contactContainer"
		class="px-2 pt-2 h-full"
		:class="!$route.name.includes('form-settings') ? 'overflow-auto' : ''"
	>
		<div
			ref="contactHeader"
			style="z-index: 150"
			class="w-full bg-white p-5 z-10 top-0 menu-shadow"
			:class="!$route.name.includes('form-settings') ? 'static' : 'sticky'"
		>
			<div class="flex justify-between items-center flex-wrap">
				<div class="flex">
					<tab-with-number
						v-for="(tab, index) in computedGetTabs"
						:key="index"
						:title="tab.label"
						:selected="tab.selected"
						font-size="1.1rem"
						class="p-3"
						@tabClick="goto(tab)"
					/>
				</div>

				<div class="flex items-center gap-5 justify-end pl-3">
					<span
						class="transition-all cursor-pointer flex items-center gap-2 text-gray-strong text-sm"
						@click="methodResetByDefault"
					>
						{{ $t('SETTINGS.CONTACT.RESET_BY_DEFAULT_POPIN.CTA') }}
						<i class="gui-reload text-lg" style="transform: scaleX(-1)"></i>
					</span>
					<button-loader>
						<q-button
							v-if="computedShowResetAndSaveBtn"
							class="overflow-visible w-full"
							:class="{ 'basic-black': !hasChangeToSave, load: isButtonShouldLoad }"
							:disabled="
								!hasChangeToSave ||
								!computedAreAllLabelsFilled ||
								!computedAreAllPropositionsFilled ||
								isButtonShouldLoad
							"
							@click="
								() => {
									saveChange++
									isButtonShouldLoad = true
								}
							"
						>
							{{ $t('_COMMON.SAVE') }}
						</q-button>
					</button-loader>
				</div>
			</div>
			<div v-if="computedDescriptionText !== ''" class="flex justify-between mt-4 p-2">
				<span>
					{{ computedDescriptionText }}
				</span>
			</div>
		</div>
		<router-view
			:key="changeCount"
			class="bck-color relative"
			:class="{ 'overflow-hidden': $route.name.includes('form-settings') }"
			:style="[
				$route.name.includes('form-settings')
					? {
							height: `calc(${heightView}px - 2rem)`,
						}
					: '',
			]"
			:save-change="saveChange"
			:reset-settings="resetSettings"
			:has-change-to-save="hasChangeToSave"
			@updateChangeCount="changeCount++"
			@unSaveChange="(hasChange) => (hasChangeToSave = hasChange)"
			@giveUpdatedSectionsList="methodUpdateLocalSectionsListForSearch"
		></router-view>
		<el-dialog>
			<template #default></template>
		</el-dialog>
	</div>
</template>

<script>
import { defineAsyncComponent, nextTick } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, debounce, isArray, isEmpty } from 'lodash'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
		ButtonLoader: defineAsyncComponent(() => import('../../general/button-loader.vue')),
	},

	data() {
		return {
			tabSelected: 'form-settings',
			tabsWithDefaultAndSaveBtn: ['form-settings'],

			hasChangeToSave: false,
			saveChange: 0,
			resetSettings: 0,
			changeCount: 0,
			heightView: 0,
			selectedFieldIdForRouter: null,
			localSectionsList: [],
			isButtonShouldLoad: false,
			resetToDefault: false,
		}
	},

	computed: {
		...mapGetters('@form', ['getBothStaticAndCustomFormsUpdated']),
		computedGetTabs() {
			return [
				{
					label: this.$t('SETTINGS.HEADER.CONTACTS'),
					name: 'form-settings',
					selected: this.tabSelected === 'form-settings',
				},
				{
					label: this.$t('SETTINGS.HEADER.TAGS'),
					name: 'tags-settings',
					selected: this.tabSelected === 'tags-settings',
				},
				{
					label: this.$t('SETTINGS.HEADER.STATUS'),
					name: 'lead-form',
					selected: this.tabSelected === 'lead-form' && this.$route.query?.q === 'STATUS',
					query: { q: 'STATUS' },
				},
				{
					label: this.$t('SETTINGS.HEADER.ACTIONS'),
					name: 'lead-form',
					selected: this.tabSelected === 'lead-form' && this.$route.query?.q === 'ACTION',
					query: { q: 'ACTION' },
				},
			]
		},

		computedShowResetAndSaveBtn() {
			return this.tabsWithDefaultAndSaveBtn.includes(this.$route.name)
		},

		computedDescriptionText() {
			switch (true) {
				case this.$route.name.includes('form-settings'):
					return ''
				case this.$route.name.includes('tags-settings'):
					return this.$t('SETTINGS.TAGS.EXPLANATION')
				case this.$route.name.includes('lead-form') && this.$route.query.q.includes('STATUS'):
					return this.$t('SETTINGS.STATUS.EXPLANATION')
				case this.$route.name.includes('lead-form') && this.$route.query.q.includes('ACTION'):
					return this.$t('SETTINGS.TASK.EXPLANATION')
				default:
					return ''
			}
		},

		computedAreAllLabelsFilled() {
			if (isArray(this.localSectionsList)) {
				return this.localSectionsList.flat().every((field) => field.label !== '')
			} else return true
		},

		computedGetAllCheckboxRefvalueLabels() {
			if (isArray(this.localSectionsList)) {
				const checkboxFields = this.localSectionsList
					.flat()
					.filter((field) => field.field_type === 'checkbox')
				if (checkboxFields) {
					return checkboxFields
						.map((field) => {
							return field.refvalues.map((refvalue) => refvalue.label)
						})
						.flat()
				} else return []
			} else return []
		},

		computedGetAllRadioRefvalueLabels() {
			if (isArray(this.localSectionsList)) {
				const radioFields = this.localSectionsList
					.flat()
					.filter((field) => field.field_type === 'radio' && field.type !== 'gender')

				if (radioFields) {
					return radioFields
						.map((field) => {
							return field.refvalues.map((refvalue) => refvalue.label)
						})
						.flat()
				} else return []
			} else return []
		},

		computedAreAllPropositionsFilled() {
			let areAllCheckboxLabelsfilled = true
			let areAllRadioLabelsFilled = true

			if (
				this.computedGetAllCheckboxRefvalueLabels.length > 0 &&
				this.computedGetAllRadioRefvalueLabels.length === 0
			) {
				areAllCheckboxLabelsfilled = this.computedGetAllCheckboxRefvalueLabels.every(
					(refvalue) => !isEmpty(refvalue)
				)
			}
			if (
				this.computedGetAllRadioRefvalueLabels.length > 0 &&
				this.computedGetAllCheckboxRefvalueLabels.length === 0
			) {
				areAllRadioLabelsFilled = this.computedGetAllRadioRefvalueLabels.every(
					(refvalue) => !isEmpty(refvalue)
				)
			}
			if (
				this.computedGetAllCheckboxRefvalueLabels.length > 0 &&
				this.computedGetAllRadioRefvalueLabels.length > 0
			) {
				areAllCheckboxLabelsfilled = this.computedGetAllCheckboxRefvalueLabels.every(
					(refvalue) => !isEmpty(refvalue)
				)
				areAllRadioLabelsFilled = this.computedGetAllRadioRefvalueLabels.every(
					(refvalue) => !isEmpty(refvalue)
				)
				return areAllCheckboxLabelsfilled && areAllRadioLabelsFilled
			}
			return areAllCheckboxLabelsfilled && areAllRadioLabelsFilled
		},

		computedIsAtLeastOneCustomCreated() {
			if (isArray(this.localSectionsList)) {
				return this.localSectionsList.flat().some((field) => field.name === 'CUSTOM')
			} else return []
		},
	},

	watch: {
		getBothStaticAndCustomFormsUpdated(newVal) {
			if (newVal === 'success') this.isButtonShouldLoad = false
		},
	},

	mounted() {
		window.addEventListener('resize', this.methodCalculateRouterViewHeight)
		this.tabSelected = this.$route.name
		nextTick(() => {
			this.methodCalculateRouterViewHeight()
		})
	},

	unmounted() {
		window.removeEventListener('resize', this.methodCalculateRouterViewHeight)
	},
	methods: {
		...mapActions('@createForm', ['actionSetParamsBar']),

		methodCalculateRouterViewHeight() {
			nextTick(() => {
				if (this.$refs.contactContainer && this.$refs.contactHeader) {
					this.heightView =
						this.$refs?.contactContainer?.offsetHeight - this.$refs?.contactHeader?.offsetHeight
				}
			})
		},

		goto(tab) {
			this.$router
				.push({ name: tab.name, query: tab.query })
				.then(() => {
					this.tabSelected = tab.name
				})
				.catch(() => {})
		},

		methodGoToContactFormSettingsEditView() {
			if (this.selectedFieldIdForRouter) {
				this.$router.push({
					name: 'edit-contact-form',
					params: { selectedFieldIdForRouter: this.selectedFieldIdForRouter },
				})
			} else {
				this.$router.push({ name: 'edit-contact-form' })
			}
			this.actionSetParamsBar({ show: false, selection: null })
		},

		methodUpdateLocalSectionsListForSearch(sectionsList) {
			this.localSectionsList = cloneDeep(sectionsList)
		},

		methodResetByDefault: debounce(function () {
			if (this.computedIsAtLeastOneCustomCreated) {
				this.$confirm(
					this.$t('SETTINGS.CONTACT.RESET_BY_DEFAULT_POPIN.DESCRIPTION'),
					this.$t('SETTINGS.CONTACT.RESET_BY_DEFAULT_POPIN.TITLE'),
					{
						confirmButtonText: this.$t('_COMMON.CONFIRM'),
						cancelButtonText: this.$t('_COMMON.CANCEL'),
						customClass: 'custom_message_box',
					}
				)
					.then(() => {
						this.resetSettings++
						this.resetToDefault = true
					})
					.catch(() => {
						return false
					})
			} else this.resetSettings++
		}, 300),
	},
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.bck-color {
	background-color: $pink-lighted;
}
.menu-shadow {
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px;
}
</style>
