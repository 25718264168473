<template>
	<el-dialog
		id="dialogCustom"
		:model-value="computedDialogAddDocOrLinkVisible"
		:title="
			computedDialogStep === 'init'
				? $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.TITLE')
				: $t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.TITLE')
		"
		class="custom-dialog-class pt-0-body pb-0-body underline-header doc-dialog"
		:class="computedDialogStep === 'init' ? '' : 'custom-margin-top'"
		:append-to-body="true"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
	>
		<!-- @close="closeGlobalDialog" -->
		<hr class="mb-10" />
		<template v-if="computedDialogStep === 'init'">
			<template v-if="!computedNewDocFromDialog.linkUrl.length">
				<div class="flex items-center gap-3 text-black">
					<i class="gui-add_image color-part-two p-1.5 h-auto w-auto bg-gray-100 rounded-full"></i>
					<p class="bold">{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.SUBTITLE_DRAG') }}</p>
				</div>
				<div class="pl-10 -mt-1">
					<p class="text-gray-strong text-sm mb-3">
						{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.DRAG.DESC') }}
					</p>
					<UploadDocs
						v-model:files-list="computedFilesList"
						is-from-dialog
						:disabled-pointer-events="computedNewDocFromDialog.linkUrl.length"
						:before-upload-method="beforeUpload"
						:on-change-method="onChange"
						:show-file-list="true"
						:multiple="true"
						:drag="computedNewDocFromDialog.linkUrl ? true : false"
						:method-remove-a-file="props.methodRemoveAFile"
					></UploadDocs>
				</div>
			</template>

			<div v-if="!computedFilesList.length" class="mt-6">
				<div class="flex items-center gap-3 text-black">
					<i class="gui-link p-1.5 h-auto w-auto bg-gray-100 rounded-full"></i>
					<p class="bold">{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.SUBTITLE_LINK') }}</p>
				</div>

				<div class="pl-10 -mt-1">
					<p class="text-gray-strong text-sm mb-3">
						{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.DESC') }}
					</p>
					<el-input
						v-model="computedNewDocFromDialog.linkUrl"
						placeholder="https://"
						clearable
						size="small"
						:maxlength="255"
						:disabled="computedFilesList.length"
					></el-input>
				</div>
			</div>
		</template>
		<template v-else>
			<EditDoc
				v-model:new-doc-from-dialog="computedNewDocFromDialog"
				v-model:files-list="computedFilesList"
				:docs-categories="docsCategories"
				:method-add-category="methodAddCategory"
				:method-add-from-upload="methodAddFromUpload"
			>
			</EditDoc>
		</template>

		<div class="flex justify-end custom_dialog items-center py-6">
			<p class="pr-4 cursor-pointer" @click="methodCancel">
				{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.CANCEL') }}
			</p>

			<el-tooltip
				:disabled="
					!conditionDisabledNextButton().disabled || !conditionDisabledNextButton().message.length
				"
				:content="$t(conditionDisabledNextButton().message, conditionDisabledNextButton().babelVar)"
				placement="top"
			>
				<a
					class="q-button square"
					:class="conditionDisabledNextButton().disabled ? 'disabled ' : ''"
					@click="() => (!conditionDisabledNextButton().disabled ? methodConfirm() : () => {})"
				>
					<span>
						{{
							computedDialogStep === 'edit' || filesList.length > 1
								? $t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.CONFIRM')
								: $t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.CONFIRM')
						}}
					</span>
				</a>
			</el-tooltip>
		</div>
	</el-dialog>
</template>

<script setup>
import { UploadFilled } from '@element-plus/icons-vue'
import { ref, defineProps, defineEmits, computed, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

// props
const props = defineProps({
	newDocFromDialog: {
		type: Object,
		default: () => {},
	},
	dialogAddDocOrLinkVisible: {
		type: Boolean,
		default: false,
	},
	filesList: {
		type: Array,
		default: () => [],
	},
	dialogStep: {
		type: String,
		default: 'init',
	},
	docsCategories: {
		type: Array,
		default: () => [],
	},
	methodAddCategory: {
		type: Function,
		default: () => {},
	},
	methodAddLink: {
		type: Function,
		default: () => {},
	},

	methodAddFromUpload: {
		type: Function,
		default: () => {},
	},
	methodResetDocFromDialog: {
		type: Function,
		default: () => {},
	},
	methodRemoveAFile: {
		type: Function,
		default: () => {},
	},
	methodSaveAndUpdateDoc: {
		type: Function,
		default: () => {},
	},
	methodDragBehavior: {
		type: Function,
		default: () => {},
	},
})

// component

const UploadDocs = defineAsyncComponent(() => import('./uploadDocs.vue'))
const EditDoc = defineAsyncComponent(() => import('./editDoc.vue'))

// emits
const emit = defineEmits([
	'update:newDocFromDialog',
	'update:dialogAddDocOrLinkVisible',
	'update:filesList',
	'update:dialogStep',
])

// data
const uploadRef = ref(null)
const maxSizeUploads = 10 * 1024 * 1024

// computed
const computedNewDocFromDialog = computed({
	get() {
		return props.newDocFromDialog
	},
	set(value) {
		emit('update:newDocFromDialog', value)
	},
})

const computedSizeOfAllFiles = computed({
	get() {
		return computedFilesList.value.reduce((acc, file) => acc + file.size, 0)
	},
	set() {},
})

const computedFilesList = computed({
	get() {
		return props.filesList
	},
	set(value) {
		emit('update:filesList', value)
	},
})

const computedDialogAddDocOrLinkVisible = computed({
	get() {
		return props.dialogAddDocOrLinkVisible
	},
	set(value) {
		emit('update:dialogAddDocOrLinkVisible', value)
	},
})
const computedDialogStep = computed({
	get() {
		return props.dialogStep
	},
	set(value) {
		emit('update:dialogStep', value)
	},
})

// methods
const allowClick = (event) => {
	event.stopPropagation()

	// Empêche le clic sur le composant upload
	if (event.target === uploadRef.value.$el) {
		event.preventDefault()
	}
}

const methodGetFileSizeInMB = (size) => {
	// Convertit la taille du fichier en Mo (2 décimales)
	return (size / 1024 / 1024).toFixed(0)
}

const conditionDisabledNextButton = () => {
	let result = {
		message: '',
		babelVar: {},
		disabled: false,
	}

	if (computedNewDocFromDialog.value.linkUrl) {
		try {
			new URL(computedNewDocFromDialog.value.linkUrl)
		} catch (error) {
			result = {
				message: 'SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.INVALID_URL',
				disabled: true,
				babelVar: {},
			}
		}
		// const http_and_https_regex = /^http[s]?:\/\//
		// if (!http_and_https_regex.test(computedNewDocFromDialog.value.linkUrl)) {
		// 	result = {
		// 		message: 'SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.MUST_BE_HTTP',
		// 		disabled: true,
		// 		babelVar: {},
		// 	}
		// }
	}

	if (computedSizeOfAllFiles.value > maxSizeUploads) {
		result = {
			message: 'SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.TOO_BIG_FILES',
			disabled: true,
			babelVar: { size: methodGetFileSizeInMB(maxSizeUploads) },
		}
	}

	if (computedDialogStep.value === 'edit') {
		if (computedNewDocFromDialog.value.linkUrl && !computedNewDocFromDialog.value.linkName) {
			result = {
				message: 'SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.NEED_NAME',
				disabled: true,
				babelVar: {},
			}
		}
	} else {
		if (!computedNewDocFromDialog.value.linkUrl && !computedFilesList.value.length) {
			result = {
				message: '',
				disabled: true,
				babelVar: {},
			}
		}
	}

	return result
}
const closeGlobalDialog = () => {
	computedDialogAddDocOrLinkVisible.value = false
	// props.methodResetDocFromDialog()
}

const methodConfirm = async () => {
	if (computedDialogStep.value !== 'edit') {
		if (computedNewDocFromDialog.value.linkUrl || computedFilesList.value.length) {
			if (computedFilesList.value.length > 1 && !computedNewDocFromDialog.value.linkUrl) {
				computedDialogAddDocOrLinkVisible.value = false
				props.methodAddFromUpload(computedFilesList.value)
			} else {
				computedDialogStep.value = 'edit'
			}
		}
	} else {
		computedDialogAddDocOrLinkVisible.value = false

		if (computedNewDocFromDialog.value.currentDocId) {
			await props.methodSaveAndUpdateDoc()
		} else {
			if (computedNewDocFromDialog.value.linkUrl) {
				if (computedNewDocFromDialog.value.linkName?.length) {
					await props.methodAddLink()
				}
			} else {
				await props.methodAddFromUpload(computedFilesList.value)
			}
		}
		props.methodResetDocFromDialog()
	}
}

const methodCancel = () => {
	if (computedDialogStep.value === 'edit' && !computedNewDocFromDialog.value.currentDocId) {
		computedDialogStep.value = 'init'
		computedNewDocFromDialog.value.linkName = ''
	} else {
		computedDialogAddDocOrLinkVisible.value = false
		props.methodResetDocFromDialog()
	}
}

const onChange = async () => {
	await props.methodDragBehavior()
}

const beforeUpload = (file) => {
	computedFilesList.value.push(file)
	computedNewDocFromDialog.value.linkUrl = ''
	return false
}
</script>

<style lang="scss">
.custom-dialog-class.custom-margin-top {
	margin-top: 5vh;
}

#dialogCustom.doc-dialog.custom-dialog-class.el-dialog {
	@media (min-width: 1201px) {
		max-width: 20vw;
	}

	@media (max-width: 1200px) {
		width: 50% !important;
	}

	@media (max-width: 768px) {
		width: 100% !important;
	}
}

.underline-header .el-dialog__body {
	padding-top: 0px;
}

.doc-dialog .el-dialog__body,
.doc-dialog .el-dialog__header {
	@apply px-10;
}
.doc-dialog {
	@media (max-width: 768px) {
		width: 90% !important;
	}
}
</style>
