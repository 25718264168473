<template>
	<div id="form-viewer-container">
		<div id="page-container" class="pure-u-md-24-24">
			<div id="scroll-container">
				<sub-header class="no-print">
					<template #controls-container>
						<div>
							<div
								v-if="!limitedEdit"
								class="download-template-container"
								@click="templateDownloadClick()"
							>
								<object
									class="icon icon-download"
									data="../../../static/download-file.svg"
									type="image/svg+xml"
									width="15px;"
								></object>
								<div class="template-donwload-text"></div>
								<div class="popover">{{ t.useTemplate }}</div>
							</div>
							<div v-else class="edit-button" @click="editClick()">{{ t.edit }}</div>
						</div>
					</template>
					<template #buttons-container>
						<div class="q-m-sm">
							<q-button link secondary @click="cancelClick">{{ t.resetMailAuto }}</q-button>
							<div class="q-h-spacer-sm"></div>
							<div class="q-button" @click="finishClick()">{{ t.publish }}</div>
						</div>
					</template>
				</sub-header>
				<div id="form-container">
					<form-editor
						v-if="!print"
						:title="title"
						:sections="sections"
						:limited-edit="limitedEdit"
						@autoSendToggle="autoSendToggle($event)"
					></form-editor>
					<form-viewer v-else :title="title" :sections="sections"></form-viewer>
				</div>
			</div>
			<form-sidebar class="pure-u-md-10-24" :title="title" :sections="sections"></form-sidebar>
			<popover
				v-if="showUseTemplatePopover"
				class="template-select-popover-container no-print"
				@closePopover="showUseTemplatePopover = false"
			>
				<template #popover-content>
					<div class="template-select-popover">
						<div class="popover-header">
							<tab-select
								v-model:value="selectedTemplateIndex"
								:tabs="['Template 1', 'Template 2', 'Template 3', 'Empty']"
							></tab-select>
							<div class="buttons-container">
								<div class="button" @click="showUseTemplatePopover = false">{{ t.cancel }}</div>
								<div class="button button-primary" @click="selecteTemplateClick()">
									{{ t.choose }}
								</div>
							</div>
						</div>
						<form-viewer :sections="selectedTemplate"></form-viewer>
					</div>
				</template>
			</popover>
			<popover
				v-if="showEditWarningPopover"
				class="edit-warning-popover no-print"
				@closePopover="showEditWarningPopover = false"
			>
				<template #popover-content>
					<div class="template-select-popover">
						<i class="fa fa-times close-icon" @click="showEditWarningPopover = false"></i>
						<div class="edit-warning-header">{{ t.warningHeader }}</div>
						<p>{{ t.warn1 }}</p>
						<p>{{ t.warn2 }}</p>
						<div class="contact-container">
							<div class="spacer"></div>
							<a
								class="mail-link"
								href="mailto:support@qomon.com?subject=Editer%20ma%20questionnaire"
							>
								<div class="contact-element-container">
									<object
										class="contact-icon"
										type="image/svg+xml"
										data="./static/mail-icon.svg"
									></object>
									<div class="contact-icon-text">support@qomon.com</div>
								</div></a
							>
							<div class="spacer space-small"></div>
							<div class="contact-element-container">
								<object
									class="contact-icon"
									type="image/svg+xml"
									data="./static/tel-icon.svg"
								></object>
								<div class="contact-icon-text">01 79 73 40 04</div>
							</div>
							<div class="spacer"></div>
						</div>
					</div>
				</template>
			</popover>
			<auto-send-popover
				v-if="showAutoSendPopover"
				class="auto-send-popover-container no-print"
				:indices="autoSendIndices"
				@closePopover="showAutoSendPopover = false"
			></auto-send-popover>
			<loading-popover v-if="loading"></loading-popover>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { nextTick } from 'vue'

var template1 = require('./templates/template1.json')
var template2 = require('./templates/template2.json')
var template3 = require('./templates/template3.json')

import forms_store from '../../models/forms_store.js'

export default {
	name: 'FormBuilder',

	components: {
		'loading-popover': require('../general/loading-popover.vue'),
		popover: require('../general/popover.vue'),
		'tab-select': require('../general/tab-select.vue'),
		'sub-header': require('../general/sub-header.vue'),
		'auto-send-popover': require('./auto-send/auto-send-popover.vue'),
		'form-editor': require('./form-editor.vue'),
		'form-sidebar': require('./form-sidebar.vue'),
		'form-viewer': require('./form-viewer.vue'),
	},

	data: function () {
		return {
			print: false,
			printOptions: {
				optionsDisplay: 'rows',
			},

			// the save call takes a long time; have a loading popover
			loading: false,

			// template picker popover
			showUseTemplatePopover: false,
			selectedTemplateIndex: 0,
			templateUsed: false,

			// edit warning popover
			showEditWarningPopover: false,

			// auto send popover
			showAutoSendPopover: false,
			autoSendIndices: {},

			// these will be modified manually, so they are data instead of computed
			origSections: [],
			sections: [],
			title: '',

			createNewJson: {
				forms: [],
				title: '',
			},
		}
	},

	computed: {
		...mapGetters(['info_campagne', 'userConnected']),
		...mapGetters('@form', ['forms']),

		t() {
			const prefix = 'FORM.BUILDER'
			return {
				edit: this.$t(`${prefix}.EDIT`),
				print: this.$t(`${prefix}.PRINT`),
				warn1: this.$t(`${prefix}.WARN1`),
				warn2: this.$t(`${prefix}.WARN2`),
				cancel: this.$t('_COMMON.CANCEL'),
				choose: this.$t(`${prefix}.CHOOSE`),
				publish: this.$t(`${prefix}.PUBLISH`),
				useTemplate: this.$t(`${prefix}.USE_TEMPLATE`),
				resetMailAuto: this.$t(`${prefix}.RESET_MAIL_AUTO`),
				warningHeader: this.$t(`${prefix}.WARNING_HEADER`),
				cancelSuccess: this.$t(`${prefix}.CANCEL_SUCCESS`),
				publishSuccess: this.$t(`${prefix}.PUBLISH_SUCCESS`),
			}
		},

		template1Json() {
			return template1[0]
		},
		template2Json() {
			return template2[0]
		},
		template3Json() {
			return template3[0]
		},

		selectedTemplate: function () {
			switch (this.selectedTemplateIndex) {
				case 0:
					return this.getSections(this.template1Json.forms)
				case 1:
					return this.getSections(this.template2Json.forms)
				case 2:
					return this.getSections(this.template3Json.forms)
				default:
					return this.getSections(this.createNewJson.forms)
			}
		},

		// controls whether the user can edit the form
		// this is passed to form-editor and controls which buttons are present in the header
		// see form-editor for more details
		limitedEdit: function () {
			if (this.info_campagne.code_edit_form === 'true') {
				return false
			} else {
				return this.forms.length > 0
			}
		},
	},

	watch: {
		forms: function () {
			if (!this.templateUsed) {
				this.origSections = this.getSections(this.forms)
				nextTick(() => {
					this.$store.commit('EDIT', false)
				})
			}
		},

		info_campagne: function () {
			this.title = this.info_campagne.name
		},

		origSections: {
			handler: function () {
				// create a copy os that sections changes don't affect origSections
				this.sections = JSON.parse(JSON.stringify(this.origSections))
			},
			deep: true,
		},

		// TODO - this will be called a lot (on every key press, for example)
		// If this is too slow, look into only updating sections when going to a popup or leaving the screen
		// (when the data will be needed elsewhere)
		sections: {
			handler: function () {
				this.actionSetSections(this.sections)
				this.$store.commit('EDIT', this.sectionsChanged())
			},
			deep: true,
		},
	},

	mounted: function () {
		// todo - could use forms?
		this.origSections = this.getSections(this.forms)
		this.title = this.info_campagne.name
		this.$store.commit('EDIT', false)
	},

	beforeUnmount: function () {
		this.$store.commit('EDIT', false)
	},

	methods: {
		...mapActions(['actionSetSections']),

		// handler for the autoSendToggle event emitted by form-editor when the auto send button has been toggled
		// form-editor emits a custom event containing the following in the detail field:
		// detail : {
		//   secitonIndex : <section_index as an int>
		//   questionIndex : <question_index as an int>
		//   optionIndex : <option_index as an int>
		//   active : <boolean describing whether the toggle is now active>
		// }
		autoSendToggle: function (event) {
			let data = event.detail
			// let question = this.sections[data.sectionIndex].questions[
			// 	data.questionIndex
			// ]
			this.autoSendIndices = data
			this.showAutoSendPopover = true
		},

		// "Finish" button click handler - make API call to server to save the form
		finishClick: function () {
			// this involves converting this.sections into questions, AND FIXING ORDER (any added questions are given order -1 since order is only
			// used initially in sorting and maintaining order isn't worth it I think).
			// AND FIXING QUESTIONS' SECTIONS (question.section isn't maintained either)
			// Also add values to refvalues

			//console.log(this.sections);

			// Should also do some sort of commit for forms
			let questions = []
			let questionOrder = 1 // order value is 1-indexed
			for (let i = 0; i < this.sections.length; i++) {
				for (let j = 0; j < this.sections[i].questions.length; j++) {
					let question = $.extend(true, {}, this.sections[i].questions[j])
					question.order = questionOrder
					question.section = this.sections[i].addedOptions.name
					// TODO - does API support order for options?
					if (question.type == 'checkbox' || question.type == 'radio') {
						let optionOrder = 1 // order value is 1-indexed
						for (let k = 0; k < question.refvalues.length; k++) {
							if (question.refvalues[k].value == '') {
								// if value has not yet been set,  set the refvalue's value to match the label, but lowercase and spaces replaced by underscores
								question.refvalues[k].value = question.refvalues[k].label
									.toLowerCase()
									.replace(/ /g, '_')
							}

							question.refvalues[k].order = optionOrder

							if (question.refvalues[k].activate_auto_send == 'true') {
								// update question's activate_auto_send
								question.activate_auto_send = 'true'
							}
							// remove added field
							delete question.refvalues[k].activate_auto_send

							optionOrder++
						}
					} else {
						if (question.type == 'range') {
							question.refvalues[0].max = parseInt(question.refvalues[0].max)
							question.refvalues[0].min = parseInt(question.refvalues[0].min)
							question.refvalues[0].step = parseInt(question.refvalues[0].step)
						}
					}
					questions.push(question)
					questionOrder++
				}
			}

			// this uses new API calls, so should only be true if testing locally where the latest backend code exists,
			// or if integration has been updated with the necessary calls
			let makeAPICall = true
			if (makeAPICall) {
				// this is a very long call; show a loading spinner
				this.loading = true

				forms_store
					.saveFormsWithNames(questions, 'FORM')
					.then((res) => {
						// update this.forms and this.static with the result (updates the ids if a new form/refvalue was created)
						this.curForms = res.forms

						let newForms = []
						let newStatic = []
						let responseForms = res.forms

						if (responseForms) {
							for (let i = 0; i < responseForms.length; i++) {
								if (responseForms[i].name === 'FORM') {
									newForms.push(responseForms[i])
								} else if (responseForms[i].name === 'STATIC') {
									newStatic.push(responseForms[i])
								}
							}
						}

						this.$store.commit('SET_FORMS', newForms)
						this.$store.commit('SET_STATIC', newStatic)

						this.$message({
							message: this.t.publishSuccess,
							duration: 5000,
							type: 'success',
							showClose: true,
						})
					})
					.finally(() => {
						// when the call finishes (no matter the success status) take away the spinner
						this.loading = false
					})
			}

			// TODO - what to do after save?  Should probably show an save successful/unsuccessful message
		},

		cancelClick: function () {
			// create a copy os that sections changes don't affect origSections
			this.sections = JSON.parse(JSON.stringify(this.origSections))

			this.$message({
				message: this.t.cancelSuccess,
				duration: 5000,
				type: 'success',
				showClose: true,
			})
		},

		editClick: function () {
			this.showEditWarningPopover = true
		},

		// "Print" button click handler - open up the brower's print
		printClick: function () {
			this.print = true
			// wait for vue to update
			nextTick(() => {
				// print the view version, then change back to edit
				window.print()
				this.print = false
			})
		},

		templateDownloadClick: function () {
			this.showUseTemplatePopover = true
		},

		selecteTemplateClick: function () {
			this.showUseTemplatePopover = false
			this.sections = this.selectedTemplate
			this.templateUsed = true
		},

		// returns true if this.sections is different from this.origSections
		sectionsChanged: function () {
			if (this.origSections.length != this.sections.length) {
				return true
			}

			for (let i = 0; i < this.origSections.length; i++) {
				if (!this.sectionsEqual(this.origSections[i], this.sections[i])) {
					return true
				}
			}

			return false
		},

		// returns true if the passed sectionA/B are equal to each other
		sectionsEqual: function (sectionA, sectionB) {
			if (
				sectionA.addedOptions.name != sectionB.addedOptions.name ||
				(sectionA.questions != undefined &&
					sectionB.questions != undefined &&
					sectionA.questions.length != sectionB.questions.length)
			) {
				return false
			}

			if (sectionA.questions != undefined && sectionB.questions != undefined) {
				for (let i = 0; i < sectionA.questions.length; i++) {
					if (!this.questionsEqual(sectionA.questions[i], sectionB.questions[i])) {
						return false
					}
				}
			}

			return true
		},

		// returns true if the passed questionA/B are equal to each other
		questionsEqual: function (questionA, questionB) {
			if (
				questionA.activate_auto_send != questionB.activate_auto_send ||
				questionA.group_id != questionB.selected_group_id ||
				questionA.id != questionB.id ||
				questionA.label != questionB.label ||
				questionA.name != questionB.name ||
				questionA.order != questionB.order ||
				questionA.section != questionB.section ||
				questionA.type != questionB.type ||
				(questionA.refvalues != undefined &&
					questionB.refvalues != undefined &&
					questionA.refvalues.length != questionB.refvalues.length)
			) {
				return false
			}

			if (questionA.refvalues != undefined && questionB.refvalues != undefined) {
				for (let i = 0; i < questionA.refvalues.length; i++) {
					if (!this.refvaluesEqual(questionA.refvalues[i], questionB.refvalues[i])) {
						return false
					}
				}
			}

			return true
		},

		// returns true if the passed refvalueA/B are equal to each other
		refvaluesEqual: function (refvalueA, refvalueB) {
			return (
				refvalueA.activate_auto_send == refvalueB.activate_auto_send &&
				refvalueA.auto_send == refvalueB.auto_send &&
				refvalueA.form_id == refvalueB.form_id &&
				refvalueA.group_id == refvalueB.selected_group_id &&
				refvalueA.id == refvalueB.id &&
				refvalueA.label == refvalueB.label &&
				refvalueA.value == refvalueB.value
			)
		},

		// Given a forms Json object that is structured like the object returned from a forms server GET call,
		// compute an intermediary object, sections, that is an array of section objects where:
		// 1. Sequential questions with the same "sections" field are collected into one section object's "Questions" field
		// 2. An additional field, "addedOptions", is added to the secitons object that contains additional data like
		//    whether the seciton should be displayed as minimized (used in the edit view, default is false)
		getSections: function (formsJson) {
			let sections = []
			// sort the form's questions by the "order" field
			let formsSorted = _.sortBy(JSON.parse(JSON.stringify(formsJson)), 'order')
			let curSection = null
			let curSectionTitle = ''
			// iterate through all of the questions in the given forms
			for (let questionCount = 0; questionCount < formsSorted.length; questionCount++) {
				let question = formsSorted[questionCount]

				// check if this question is part of a new section
				if (!curSection || curSectionTitle != question.section) {
					if (curSection) {
						// push the previous section (as long as this isn't the first section)
						sections.push(curSection)
					}
					// get a new section
					curSectionTitle = question.section
					curSection = {
						addedOptions: {
							name: curSectionTitle,
							minimized: false,
						},
						questions: [],
					}
				}

				if (question.type == 'checkbox' || question.type == 'radio') {
					// sort the question's options by the "order" field
					// note: this operation won't do anything if the options don't have an order
					question.refvalues = _.sortBy(JSON.parse(JSON.stringify(question.refvalues)), 'order')

					// add templates and activate_auto_send
					// the latter is required for reacitivty to work, since vue will only react to an always-existing variable
					for (let optionCount = 0; optionCount < question.refvalues.length; optionCount++) {
						let option = question.refvalues[optionCount]
						if (option.auto_send) {
							option.activate_auto_send = 'true' // stored as string on db
						} else {
							// no template
							option.activate_auto_send = '' // stored as string on db
						}
					}

					// for reacitivty to work, must also have an always-existing variable that tracks whether an option has auto-send enabled
					// for (let optionCount = 0; optionCount < question.refvalues.length; optionCount++) {
					//   question.refvalues[optionCount].activate_auto_send = null; // TODO - should be based on template API call data
					// }
				}

				curSection.questions.push(question)
			}
			// push the last section
			if (curSection) {
				sections.push(curSection)
			}

			return sections
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/form/form-builder.scss';
</style>
