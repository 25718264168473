<template>
	<popover class="loading-popover-container">
		<template #popover-content>
			<div class="loading-popover">
				<i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw loading-spinner"></i
				><span class="sr-only">Loading...</span>
			</div>
		</template>
	</popover>
</template>

<script>
export default {
	name: 'LoadingPopover',

	components: { popover: require('./popover.vue') },
}
</script>

<style lang="scss">
@import '../../assets/scss/general/loading-popover.scss';
</style>
