<template>
	<div>
		<div class="section">
			<div class="section-title mb-6">
				{{ $t('SETTINGS.PROFILE.CHANGE_PASSWORD') }}
			</div>
			<validation-form
				v-if="!$route.query.mail || !$route.query.code"
				ref="validationObserver"
				v-slot="{ meta: formMeta }"
				as="form"
			>
				<!-- current password -->
				<input-profile
					v-model:value="password"
					name="current-password"
					rules="required"
					:title="$t('SETTINGS.PROFILE.OLD_ENTITY')"
					:placeholder="$t('SETTINGS.PROFILE.OLD_ENTITY')"
					width="20rem"
					secret
				/>
				<!-- new password -->
				<div class="mt-1">
					<input-profile
						v-model:value="newPassword"
						name="new-password"
						:rules="`strong_password|is_not:${password}`"
						:title="$t('SETTINGS.PROFILE.NEW_PASSWORD')"
						:placeholder="$t('SETTINGS.PROFILE.NEW_PASSWORD')"
						width="20rem"
						secret
						check-icon
						@keydown.enter="methodUpdateNewPassword"
					/>
				</div>
				<!-- confirm password -->
				<div class="mt-1">
					<input-profile
						v-model:value="newPasswordConfirm"
						name="confirmation-password"
						rules="confirmed:@new-password"
						:title="$t('SETTINGS.PROFILE.CONFIRM_PASSWORD')"
						:placeholder="$t('SETTINGS.PROFILE.CONFIRM_PASSWORD')"
						width="20rem"
						secret
						check-icon
						@keydown.enter="methodUpdateNewPassword"
					/>
				</div>

				<watch-validation-observer :valid="formMeta.valid" @change="validFormWatched = $event" />
			</validation-form>
		</div>

		<div class="flex section">
			<i class="gui-lock color-part-two text-3xl px-1 self-center" />
			<div class="switch-container flex justify-between items-center flex-grow ml-8">
				<div class="flex flex-column w-4/6">
					<span class="bold">
						{{ $t('SETTINGS.PROFILE.TWO_FACTOR_AUTHENTICATION.LABEL') }}
					</span>
					<span>
						{{ $t('SETTINGS.PROFILE.TWO_FACTOR_AUTHENTICATION.DESC') }}
					</span>
				</div>
				<div class="switch flex gap-2 items-center">
					<span class="text-sm">
						{{
							is_2faEnabled
								? $t('SETTINGS.PROFILE.TWO_FACTOR_AUTHENTICATION.ENABLED')
								: $t('SETTINGS.PROFILE.TWO_FACTOR_AUTHENTICATION.DISABLED')
						}}
					</span>
					<el-switch v-model="is_2faEnabled" @change="methodUpdate2FA"></el-switch>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
import InputProfile from './input-profile.vue'
import { mapGetters, mapActions } from 'vuex'
import { nextTick } from 'vue'
import { Form as ValidationForm } from 'vee-validate'

export default {
	components: {
		ValidationForm,
		InputProfile,

		// hack to watch when the form is valid and enable save btn
		WatchValidationObserver: {
			render: () => '',
			props: {
				valid: {
					type: Boolean,
					required: true,
				},
			},
			watch: {
				valid(newVal) {
					this.$emit('change', newVal)
				},
			},
		},
	},
	props: {
		saveChange: {
			type: Number,
			required: true,
		},
	},
	emits: ['change', 'unSaveChange', 'loading'],
	data() {
		return {
			password: '',
			newPassword: '',
			newPasswordConfirm: '',
			loading: false,
			validFormWatched: false,
			is_2faEnabled: false,
		}
	},

	computed: {
		...mapGetters(['userConnected']),
	},

	watch: {
		validFormWatched: {
			handler(isValid) {
				this.$emit('unSaveChange', isValid)
			},
		},
		saveChange: {
			handler() {
				this.methodUpdateNewPassword()
			},
		},
	},

	mounted() {
		this.is_2faEnabled = this.userConnected.two_factor_enable
	},

	methods: {
		...mapActions(['actionUpdateUser2fa']),
		...mapActions('@user', ['actionUpdateUserPassword']),

		methodUpdateNewPassword() {
			this.$emit('loading', true)
			// also relates to user/contacts page
			this.actionUpdateUserPassword({
				old_password: this.password,
				new_password: this.newPassword,
				mail: this.userConnected.mail,
			})
				.then(() => {
					this.$message({
						message: this.$t('SETTINGS.PROFILE.MESSAGE.UPDATE_SUCCESS'),
						duration: 5000,
						type: 'success',
						showClose: true,
					})
				})
				.catch(() => {
					this.$message({
						message: this.$t('SETTINGS.PROFILE.MESSAGE.BAD_PASSWORD'),
						duration: 5000,
						type: 'error',
						showClose: true,
					})
				})
				.finally(() => {
					this.$emit('loading', false)
				})
			this.password = this.newPassword = this.newPasswordConfirm = ''

			nextTick(() => {
				this.$refs.validationObserver.resetForm()
			})
		},

		methodUpdate2FA: debounce(function (value) {
			this.actionUpdateUser2fa(value)
		}, 500),
	},
}
</script>

<style lang="scss" scoped>
.section {
	@apply mt-1 p-8 bg-white;
}
.section-title {
	@apply text-sm text-gray-dark uppercase;
}

@media only screen and (max-width: 555px) {
	.switch-container {
		flex-direction: column;
	}
	.switch {
		padding-top: 1.5rem;
		justify-content: flex-start;
	}
	.io-goal-input {
		width: 100% !important;
	}
}
</style>
