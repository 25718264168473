import { $request } from './requester'

export default {
	/**
	 * GET `/categories`
	 */
	async getCategories() {
		return $request('GET', '/categories').then((res) => {
			return res.body.data.categories
		})
	},

	/**
	 * GET `/petitions`
	 */
	async getPetitions() {
		return $request('GET', '/petitions').then((res) => res.body.data.petitions)
	},

	/**
	 * `GET` `/petitions/:id`
	 * @param {String} id petition_id of the petition to retrieve
	 */
	async getPetitionById(id) {
		return $request('GET', `/petitions/${id}`).then((res) => res.body.data.petition)
	},

	/**
	 * POST `/petitions`
	 * @param {Object} data
	 */
	async postPetition(data) {
		return $request('POST', '/petitions', data).then((res) => res.body.data.petition)
	},

	/**
	 * `PATCH` `/petitions/:id`
	 */
	async updatePetition({ id, data }) {
		return $request('PATCH', `/petitions/${id}`, data).then((res) => {
			return res.body.data.petition
		})
	},

	/**
	 * PATCH `/petitions/:id/publish`
	 * @param {String} id petition_id of the petition to publish
	 */
	async publishPetition(id) {
		return $request('PATCH', `/petitions/${id}/publish`)
	},

	/**
	 * PATCH `/petitions/:id/unpublish`
	 * @param {String} id petition_id of the petition to unpublish
	 */
	async unpublishPetition(id) {
		return $request('PATCH', `/petitions/${id}/unpublish`)
	},

	/**
	 * `PATCH` `/petitions/:id/delete`
	 * @param {Number} id petition_id to delete
	 */
	async deletePetition(id) {
		return $request('PATCH', `/petitions/${id}/delete`)
	},

	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},
}
