<template>
	<validation-field
		v-slot="{ errors }"
		v-model="computedValidationData"
		:name="selectData.name"
		:rules="{ required: selectData.isRequired }"
		as="div"
		:class="`form-select relative pb-9 ${customCss || ''}`"
	>
		<label
			:for="selectData.name"
			:class="` mb-1 flex ${selectData.black ? 'bold' : 'text-pink-main text-sm'}`"
		>
			{{ selectData.label }}

			<div
				v-if="selectData.badge"
				class="bg-green-lighted text-green-main text-xs rounded-md px-1 py-px ml-4 flex items-center whitespace-nowrap"
			>
				<i class="gui-stars color-part-one color-part-two" />
				<span> {{ $t('_COMMON.ADVISED') }} </span>
			</div>
		</label>

		<p
			v-if="selectData.desc"
			class="mt-2 mb-3"
		>
			{{ selectData.desc }}
		</p>
		<div class="flex flex-row relative">
			<div class="w-full">
				<p
					class="absolute left-3 top-2 bg-white pointer-events-none flex items-center gap-x-2 z-10"
					style="top: 10px"
				>
					<i
						v-if="computedValue?.color"
						class="gui-color"
						:style="`color:${computedValue?.color}`"
					/>
					<span
						class="text-gray-strong relative overflow-hidden"
						style="-webkit-line-clamp: 1; display: -webkit-box; -webkit-box-orient: vertical"
					>
						{{ computedValue?.label }}
					</span>
				</p>

				<el-select
					v-model="computedValue"
					:placeholder="selectData.placeholder"
					:multiple="selectData.multiple"
					:multiple-limit="selectData.multipleLimit"
					filterable
					:allow-create="selectData.allowCreate || false"
					default-first-option
					fit-input-width
					clearable
					:popper-class="`form-popper popper-${selectData.name}`"
					:no-match-text="$t('VENDORS.ELEMENT_UI.el.select.noMatch')"
					class="w-full relative"
					:class="{ 'form-invalid-input': errors.length }"
					@input="handleQueryChange"
				>
					<template #empty>
						<div
							class="flex flex-col justify-start"
							v-if="!computedSelectedDataOptions || !computedSelectedDataOptions.length"
						>
							<div class="py-4 text-center">
								{{ $t('_COMMON.NO_DATA') }}
							</div>
							<div
								@click="methodAddNewTag()"
								class="sticky bottom-0 left-0 w-full z-50 cursor-pointer px-4  bg-white py-3 border-t group"
								:class="searchQueryAlreadyExistInTags || !searchQuery?.length ? 'cursor-not-allowed text-gray-500' : 'text-pink-main'"
							>
								<span
									class="group-hover:opacity-70"
									:class="searchQueryAlreadyExistInTags || !searchQuery?.length ? 'cursor-not-allowed opacity-70' : ''"
								>+ {{ $t('_COMMON.ADD_THE_TAG') }} </span>
							</div>
						</div>
					</template>

					<div
						class="relative"
						:style="'max-width:' + computedGetSelectWidth + 'px;'"
					>
						<template v-if="computedSelectedDataOptions && computedSelectedDataOptions.length">
							<el-option
								v-for="(item, index) in computedSelectedDataOptions"
								:key="index"
								:label="item.label"
								:value="selectData.returnArray ? item : item.value"
							>
								<i
									v-if="selectData.name === 'auto_insert_refvalues'"
									class="gui-color pr-2"
									:style="item.color ? `color: ` + item.color : 'color:white'"
								/>
								<span v-if="selectData.name === 'auto_insert_refvalues'">{{ item.label }}</span>
							</el-option>
						</template>
					</div>

					<div
						v-if="selectData.name === 'tags'"
						@click="methodAddNewTag()"
						class="sticky bottom-0 left-0 w-full z-50 cursor-pointer px-4  bg-white py-3 border-t group"
						:class="searchQueryAlreadyExistInTags || !searchQuery?.length ? 'cursor-not-allowed text-gray-500' : 'text-pink-main'"
					>
						<span
							class="group-hover:opacity-70"
							:class="searchQueryAlreadyExistInTags || !searchQuery?.length? 'cursor-not-allowed opacity-70' : ''"
						> + {{ $t('_COMMON.ADD_THE_TAG') }}</span>
					</div>
				</el-select>
				<span
					class="relative -top-8 left-3 text-sm italic"
					style="color: rgba(33, 62, 97, 0.35)"
				>

					{{ computedValue ? '' : computedValue?.length ? selectData.placeholderFilled : '' }}
				</span>
			</div>
		</div>

		<transition
			v-if="errors.length"
			name="fade-in"
			mode="out-in"
		>
			<p class="absolute flex text-sm text-gray-strong top-auto -bottom-2 pb-6">
				<i
					class="gui-no text-gray-dark mr-1"
					style="margin-top: 3px"
				/>
				{{ errors[0] }}
			</p>
		</transition>
	</validation-field>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Field as ValidationField } from 'vee-validate'

export default {
	components: {
		ValidationField,
	},
	props: {
		selectData: {
			type: Object,
			default() {
				return {
					name: '',
					label: '',
					desc: '',
					badge: '',
					placeholder: '',
					placeholderFilled: '',
					options: [],
					multiple: false,
					allowCreate: false,
					isRequired: false,
				}
			},
			required: true,
		},
		populateTags: { type: Function, default: null },
		value: { type: [Array, Object, String], default: () => [], required: true },
		customCss: { type: String, default: '', required: false },
	},
	emits: ['update:value', 'update:select-data'],
	data() {
		return {
			tagSelect: null,
			newTag: { name: '' },
			searchQuery: '',
		}
	},
	computed: {
		...mapGetters('@tags', ['getTags']),

		computedSelectedDataOptions() {
			return this.selectData.options
		},

		searchQueryAlreadyExistInTags() {
			const allTags = this.selectData.options.map((tag) => tag.label || tag.name)
			return allTags.includes(this.computedSearchQuery)
		},

		computedSearchQuery: {
			get() {
				return this.searchQuery
			},
			set(value) {
				this.searchQuery = value
			},

		},

		computedValue: {
			get() {
				return this.value || []
			},
			set(val) {
				this.$emit('update:value', val)
			},
		},
		computedSelectData: {
			get() {
				return this.selectData
			},
			set(val) {
				this.$emit('update:select-data', val)
			},
		},
		// Computed to check if the value of the select is empty or not for the validation field
		computedValidationData: {
			get() {
				return this.computedValue.length === 0 ? '' : 'valid'
			},
		},
	},
	mounted() {
		this.tagSelect = document.getElementById('tags')
	},
	beforeUnmount() {

	},
	methods: {
		...mapActions('@tags', ['actionGetTags', 'actionAddTag']),

		handleQueryChange(event) {
			if (this.selectData.name === 'tags') {
				this.computedSearchQuery = event.target.value
			}
		},

		methodAddNewTag() {
			if (this.searchQuery && !this.searchQueryAlreadyExistInTags) {
				this.computedValue = [...this.computedValue, this.computedSearchQuery]
				const formatedNewTag = {
					name: this.searchQuery,
				}
				this.actionAddTag(formatedNewTag).then(() => {
					if (this.populateTags) {
						this.populateTags()
					}
				})
			}
		},
	},
}
</script>

<style lang="scss">

.form-popper.popper-tags{
display: flex;
flex-direction: column;
}
.form-popper.popper-tags .el-select-dropdown__list{
	padding-bottom: 0px;
}
.form-popper.popper-tags .el-scrollbar.is-empty{
	order:2;
}

.form-select {
	.el-input__inner {
		min-height: 40px;
		max-height: 40px;
	}

	& .el-select .select-trigger {
		display: flex;
		flex-wrap: wrap;

		.el-select__tags {
			position: relative;
			top: 0.5rem;
			left: -0.4rem;
			transform: none;
			order: 1;

			.el-select-tags-wrapper {
				display: flex;
				& .el-tag {
					color: black;
					border-color: #dee1e7;
					background-color: transparent;
					border-radius: 0.5rem;
					font-size: 13px;
					padding: 15px 10px 15px 15px;
				}
			}
		}

		.el-select__input {
			position: absolute;
			top: -41px;
			padding-left: 5px;
			min-width: fit-content;
			max-width: 100%;
			width: 100% !important;
		}
	}
}
</style>
