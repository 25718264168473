/**
 * Helper method to roughly check if data points are in area 51 or not.
 *
 * Badly located addresses are currently set there (not my design decision).
 *
 * @param {Number} lat
 * @param {Number} long
 * @returns {boolean}
 */
export function latLngIsInNorthPole(lat, long) {
	89.80 || 89.900000 || 90.00
	0.000000 || 0.100000 || 0.20
	return (
		isNaN(lat) || isNaN(long) || (long >= 0.00 && long <= 0.20 && lat >= 89.80 && lat <= 90.00)
	)
}

// Taken from leaflet
function wrapNum(x, range, includeMax) {
	const max = range[1],
		min = range[0],
		d = max - min
	return x === max && includeMax ? x : ((((x - min) % d) + d) % d) + min
}

/**
 * Helper to wrap 4326 lat/lng according to a specific range
 *
 * @param {Number} lat
 * @param {Number} long
 * @param {null|Array.<Number>} lngRange
 * @returns {Array.<Number>}
 */
export function wrapLatLng(lat, long, lngRange) {
	if (lngRange === null) return [lat, long]
	else return [lat, wrapNum(long, lngRange, true)]
}

/**
 * Helper to wrap a webapp "polygon" longitudes
 *
 * @param {Array.<Object>} polygon
 * @param {null|Array.<number>} lngRange
 * @returns {Array.<Object>}
 */
export function wrapPolygon(polygon, lngRange) {
	return polygon.map(({ lat, lng, long }) => {
		// lng || long is to support all notations
		const [finalLat, finalLong] = wrapLatLng(lat, typeof lng === 'number' ? lng : long, lngRange)
		return { lat: finalLat, lng: finalLong }
	})
}

/**
 * Helper to "unwrap" a webapp "polygon" longitudes, only if it's possible
 *
 * @param {Array.<Object>} polygon
 * @returns {Array.<Object>}
 */
export function unwrapPolygon(polygon) {
	if (!Array.isArray(polygon) || polygon.length === 0) {
		return polygon
	}
	const coordinates = polygon.map(({ lat, lng, long }) => ({
		lat,
		lng: typeof lng === 'number' ? lng : long,
	}))
	// an "earthId" gives you on which earth the point is: [-180, 180[ => 0, etc.
	const earthIds = new Set(coordinates.map(({ lng }) => Math.floor((lng + 180) / 360)))

	const actualNbEarthsCovered = Math.abs(Math.max(...earthIds) - Math.min(...earthIds)) + 1
	// if {0}, {-1, 0} or {0, 1}
	if ((actualNbEarthsCovered === 1 || actualNbEarthsCovered === 2) && earthIds.has(0)) {
		// Everything is on the "first" earth, we are good
		// or we are at the intersection of the first earth and another and this is handled by the backend
		return coordinates
	}
	else if (actualNbEarthsCovered === 1 || actualNbEarthsCovered === 2) {
		// we are on another earth than 0 or two successive earths other than 0
		// We translate the polygon back to somewhere around [-180, 180]
		const maxLng = Math.max(...coordinates.map(({ lng }) => lng))
		// we compute the appropriate translation coeff
		const translationCoeff = Math.floor((180 - maxLng) / 360)
		return coordinates.map(({ lat, lng }) => ({ lat, lng: lng + translationCoeff * 360 }))
	}
	else {
		// we are in an unhandable case, a polygon shouldn't cover multiple earths
		return coordinates
	}
}

/**
 * Helper to wrap a geoJson according to a specific range
 *
 * @param geoJson
 * @param {null|Array.<Number>} lngRange
 */
export function wrapGeoJsonLng(geoJson, lngRange) {
	if (lngRange === null) return geoJson

	// internal recursive helper
	const performWrap = (elem) => {
		if (Array.isArray(elem)) {
			if (Array.isArray(elem[0])) {
				return elem.map((el) => performWrap(el))
			}
			else if (typeof elem[0] === 'number') {
				return [L.Util.wrapNum(elem[0], lngRange, true), elem[1]]
			}
		}
	}

	if (geoJson?.type === 'FeatureCollection') {
		return {
			...geoJson,
			features: geoJson.features.map((feat) => ({
				...feat,
				geometry: { ...feat.geometry, coordinates: performWrap(feat.geometry.coordinates) },
			})),
		}
	}
	else {
		return { ...geoJson, coordinates: performWrap(geoJson.coordinates) }
	}
}

/**
 * Helper to get the proper wrapping range for a given Geo Root
 *
 * @param geoRootCode
 * @returns {null|number[]}
 */
export function getWrapLngRangeForDataGeoRootCode(geoRootCode) {
	// WARNING, make sure that the usual drawing areas ends-up between [-180, 180]
	// So that things are properly handled by the backend
	if (geoRootCode === 'NZL') return [0, 360]
	if (geoRootCode === 'USA') return [-360, 0]
	else return null
}
