import listsStore from '../../models/lists_store'
import {
	SET_LISTS,
	SET_STATUS_LISTS,
	REMOVE_LIST,
	UPDATE_LIST,
	CREATE_LIST,
} from './_mutation-types'

export const STATUS = {
	INIT: 'init',
	ERROR: 'error',
	LOADING: 'loading',
	OK: 'ok',
}

const getDefaultState = () => {
	return {
		lists: [],
		statusFetchingLists: STATUS.INIT,
	}
}

const state = getDefaultState

const mutations = {
	[SET_LISTS]: (currentState, lists) => {
		currentState.lists = lists
	},

	[SET_STATUS_LISTS]: (currentState, status) => {
		currentState.statusFetchingLists = status
	},
	[REMOVE_LIST]: (currentState, idListToRemove) => {
		const indexFilterToRemove = currentState.lists.findIndex((list) => list.id === idListToRemove)
		currentState.lists.splice(indexFilterToRemove, 1)
	},
	[CREATE_LIST]: (currentState, listToAdd) => {
		currentState.lists.push(listToAdd)
	},
	[UPDATE_LIST]: (currentState, listToUpdate) => {
		currentState.lists.splice(
			currentState.lists.findIndex((list) => list.id === listToUpdate.id),
			1,
			listToUpdate,
		)
	},
}

const actions = {
	async actionFetchLists({ commit }) {
		commit(SET_STATUS_LISTS, STATUS.LOADING)

		const timeout = setTimeout(() => {
			commit(SET_STATUS_LISTS, STATUS.ERROR)
		}, 2000)

		// `GET, '/filters'`
		await listsStore
			.getFilters()
			.then((res) => {
				commit(SET_LISTS, res)
				clearTimeout(timeout)
				commit(SET_STATUS_LISTS, STATUS.OK)
			})
			.catch(() => {
				clearTimeout(timeout)
				commit(SET_STATUS_LISTS, STATUS.ERROR)
			})
	},
	async actionRemoveList({ commit }, list) {
		// `DELETE, '/filters', { data: { id: idList} }`
		return listsStore.deleteFilter(list).then((status) => {
			if (status) commit(REMOVE_LIST, list.id)
			return status
		})
	},
	async actionUpdateList({ commit }, listToUpdate) {
		// `PATCH, '/filters', { data: list }`

		return listsStore.updateFilter(listToUpdate).then((status) => {
			if (status) commit(UPDATE_LIST, listToUpdate)
			return status
		})
	},
	async actionCreateList({ commit }, listToAdd) {
		// `POST, '/filters', { data: list }`

		return listsStore.addFilter(listToAdd).then((res) => {
			if (res) {
				commit(CREATE_LIST, res.filter)
				return res.filter
			}
			else return false
		})
	},
}

const getters = {
	getSegments: (state) => state.lists.filter((list) => !!list.payload && list.is_segment),
	lists: (state) => state.lists.filter((list) => !list.is_segment),
	segmentLists: (state) => state.lists.filter((list) => list.is_segment),
	statusLists: (state) => state.statusFetchingLists,
	favoriteLists: (state) => {
		return state.lists.filter((list) => !!list?.is_favorite && !list.is_segment)
	},
	nonFavoriteLists: (state) => {
		return state.lists.filter((list) => !!list?.is_favorite === false && !list.is_segment)
	},
}

export const listsModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
