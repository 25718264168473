<!-- Based on vue2-editor, with some modifications -->
<!-- See https://github.com/davidroyer/vue2-editor -->
<!-- This is a slightly modified version of the quilljs editor, allowing for users to compose formatted text, and outputting html with inline styling -->
<!-- Note that it functions as an input (accepts a v-model) -->
<!-- This component also optionally allows for tags - html elements that are assumed to be one character wide (to a text editor), so preferrably images -->
<!-- If includeTags is true, the tags will be listed above the editor and, when clicked, will be added to the editor at the last cursor position and the -->
<!-- tagClass will be added (since quilljs strips any class from the raw html string) -->
<!-- Props: -->
<!--   value        - the variable containing the value of the input (required through v-model) -->
<!--                  IMPORTANT NOTE: this should be passed as v-model="<variable_name>", NOT value="<variable_name>" or v-bind:value="<variable_name>" -->
<!--   placeholder  - the text displayed in the editor when there is no content -->
<!--   includeTags  - a boolean that determines whether the clickable tags are displayed (see editor for more information); default is false -->
<!--   tags         - an optional array of raw html strings that will be insterted into the editor on click (only if includeTags is true) -->
<!--                  Note: these must be images for the tagClass to be added (TODO if needed - add support for other tag types) -->
<!--   tagClass     - an optional class that is added to the tags to allow them to be formatted with css -->
<template>
	<div class="editor main-editor">
		<div v-if="includeTags" class="variables-container px-3 pb-2">
			<div v-t="`${t.peopleAttributes}:`" class="variables-text"></div>
			<div
				v-for="tag in tags"
				:key="tag.id"
				v-dompurify-html="tag"
				class="contact-variables p-2 m-1 text-sm"
				@click="tagClick(tag)"
			></div>
			<div v-t="`(${t.clickToAdd})`" class="variables-text small"></div>
		</div>
		<div class="quillWrapper" :class="{ 'hide-toolbar': removeToolBox }">
			<!-- add uniqueSuffix to support multiple editors on the same page followed -->
			<!-- example from http://quilljs.com/playground/#class-vs-inline-style -->
			<div :id="'toolbar-container' + uniqueSuffix" class="tollbar-container">
				<span class="ql-formats">
					<select class="ql-size">
						<option value="10px">Small</option>
						<option selected>Normal</option>
						<option value="18px">Large</option>
						<option value="32px">Huge</option>
					</select></span
				><span class="ql-formats">
					<button class="ql-bold"></button>
					<button class="ql-italic"></button>
					<button class="ql-underline"></button>
					<button class="ql-link"></button></span
				><span class="ql-formats">
					<button class="ql-list" value="ordered"></button>
					<button class="ql-list" value="bullet"></button>
					<select class="ql-align"></select></span
				><span class="ql-formats"> <button class="ql-clean"></button></span>
			</div>
			<div
				:id="'quill-editor-container' + uniqueSuffix"
				class="quill-editor-container"
				@keydown.enter="onEnter"
			></div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

// import styles for inline styles
var SizeStyle = Quill.import('attributors/style/size')
var AlignStyle = Quill.import('attributors/style/align')
Quill.register(SizeStyle, true)
Quill.register(AlignStyle, true)

export default {
	name: 'Editor',
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			default: '',
			type: String,
		},
		//TODO - not sure if this is the best place, or having separate components with mixins
		includeTags: {
			default: false,
			type: Boolean,
		},
		// the class that will be added to each tag to allow for formatting
		tagClass: {
			type: String,
			default: '',
		},
		removeToolBox: {
			type: Boolean,
			default: false,
		},
		disableEnter: {
			type: Boolean,
			default: false,
		},
		getTextOnly: {
			type: Boolean,
			default: false,
		},
		uniqueId: {
			type: String,
			default: '1',
		},
	},
	emits: ['update:value'],

	data() {
		return {
			// those are the variable tags available in the body of the mail
			tags: [
				this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.FIRSTNAME'),
				this.$t('FORM.AUTO_SEND.EDITOR.CONTACT_VARIABLE.SURNAME'),
			],
			uniqueSuffix: '-' + this.uniqueId,

			quill: null,
			editor: null,
			genderButtonHTML: '',
			firstnameButtonHTML: '',
			surnameButtonHTML: '',

			curSelection: {
				index: 0,
				length: 0,
			},
		}
	},
	computed: {
		t() {
			const prefix = 'FORM.AUTO_SEND.EDITOR'
			return {
				peopleAttributes: this.$t(`${prefix}.PEOPLE_ATTRIBUTES`),
				clickToAdd: this.$t(`${prefix}.CLICK_TO_ADD`),
			}
		},
	},

	watch: {
		value(val) {
			if (val != this.editor.innerHTML && !this.quill.hasFocus()) {
				this.editor.innerHTML = val
			}
		},
	},

	mounted() {
		this.initializeEditor()
		this.handleUpdatedEditor()
	},
	methods: {
		initializeEditor() {
			this.setQuillElement()
			this.setEditorElement()
			this.checkForInitialContent()
			// make sure the class is added to the clickable tags in the top line, even if there is no content
			$(this.$el).find('img').addClass(this.tagClass)
		},

		setQuillElement() {
			this.quill = new Quill('#quill-editor-container' + this.uniqueSuffix, {
				modules: {
					toolbar: '#toolbar-container' + this.uniqueSuffix,
				},
				placeholder: this.placeholder,
				theme: 'snow',
			})

			let vueComponent = this
			this.quill.on('selection-change', function (/*range, oldRange, source*/) {
				vueComponent.updateSelection(vueComponent.quill.getSelection())
			})
			$(this.$el)
				.find('.ql-editor')
				.keyup(function () {
					vueComponent.updateSelection(vueComponent.quill.getSelection())
				})
		},

		updateSelection(selection) {
			if (selection) {
				this.curSelection = selection
			}
		},

		setEditorElement() {
			this.editor = $(this.$el).find(
				'#quill-editor-container' + this.uniqueSuffix + ' .ql-editor'
			)[0]
		},

		checkForInitialContent() {
			this.editor.innerHTML = this.value || ''
		},

		handleUpdatedEditor() {
			this.quill.on('text-change', () => {
				// add the class to any tags that have been added
				$(this.$el).find('img').addClass(this.tagClass)
				if (this.getTextOnly) {
					const textOnly = this.quill.getText()
					let textOnlyCleaned
					if (textOnly.length) {
						textOnlyCleaned = textOnly.slice(0, -1)
					}
					this.$emit('update:value', textOnlyCleaned)
				} else {
					this.$emit('update:value', this.editor.innerHTML)
				}

				//? remove the variable button if text is not perfectly matching any tags
				const variableElements = this.quill.container.querySelectorAll('s')
				for (let variableElement of variableElements) {
					if (!this.tags.includes(variableElement.innerText)) {
						if (getComputedStyle(variableElement).backgroundColor === 'rgb(0, 209, 156)') {
							const unstyledElement = document.createElement('span')
							unstyledElement.innerText = variableElement.innerText
							variableElement.parentNode.replaceChild(unstyledElement, variableElement)
						}
					}
					variableElement.classList.add('autosend-img-tag')
				}
			})
		},

		tagClick(tag) {
			const length = tag.length
			let espaceChar = '<span> </span>' // this is needed, do not remove it pls
			this.quill.clipboard.dangerouslyPasteHTML(this.curSelection.index, `<s>${tag}</s>`, {
				code: true,
				'font-style': 'normal',
				padding: '0.5rem 0.5rem',
				bottom: '0.5rem',
				'background-color': '#00d19c',
				'border-radius': '3rem',
				color: 'white',
				'font-weight': 500,
				position: 'relative',
				margin: '0 0.25rem',
				'font-size': '1.25rem',
				'line-height': 'inherit',
			})
			this.quill.clipboard.dangerouslyPasteHTML(this.curSelection.index, espaceChar)
			this.quill.setSelection(this.curSelection.index + length + 1)
			this.quill.clipboard.dangerouslyPasteHTML(this.curSelection.index, espaceChar)
			this.curSelection = this.quill.getSelection()
			// the tag takes up one space, so move cursor over to the right of the inserted tag
		},

		onEnter() {
			if (this.disableEnter) {
				let text = this.quill.getText()
				this.quill.setText(text.replace(/\r?\n|\r/g, ''))
				text = this.quill.getText()
				this.quill.setSelection(text.length)
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/general/editor.scss';

.ql-toolbar.ql-snow {
	border-radius: 6px 6px 0 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border-radius: 0 0 6px 6px;
	img {
		height: 2.5rem;
		display: inline;
		padding: 0.25rem 0;
	}
}
.ql-editor s,
.contact-variables,
.autosend-img-tag {
	font-style: normal;
	background-color: #ff387e;
	border-radius: 3rem;
	text-decoration: none;
	@apply text-sm p-2 font-medium relative text-white whitespace-nowrap;
}
</style>
