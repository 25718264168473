<template>
	<div
		:style="{ backgroundColor: bgColor }"
		class="bg-gray-300 rounded-full text-white"
	>
		<svg
			v-if="type === 'available_holidays'"
			:width="size"
			:height="size"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M5.17879 1.36067C5.17037 1.36545 5.16196 1.37027 5.15355 1.37513C5.14514 1.37998 5.13674 1.38487 5.12837 1.38977C0.286402 4.20345 -1.36863 10.4067 1.43326 15.2597L2.15675 16.5128C2.43289 16.9911 3.04448 17.155 3.52277 16.8788L10.4935 12.8543L10.5 12.8656L14.5 19.7938C14.7761 20.2721 15.3877 20.436 15.866 20.1599C16.3443 19.8837 16.5082 19.2721 16.232 18.7938L12.232 11.8656L12.2254 11.8543L19.3956 7.71465C19.8739 7.4385 20.0378 6.82691 19.7616 6.34862L19.0381 5.0955C16.2362 0.242444 10.0365 -1.42584 5.17879 1.36067ZM6.13698 3.11683C6.14252 3.11362 6.14806 3.11041 6.15361 3.10721C6.15913 3.10402 6.16465 3.10084 6.17018 3.09767C7.28912 2.46353 8.71215 2.84948 9.35642 3.96538L12.4897 9.39232L8.42859 11.737L5.29535 6.31004C4.65109 5.19415 5.02835 3.7688 6.13698 3.11683ZM3.10965 6.19089C1.75458 8.61842 1.67424 11.6771 3.16531 14.2597L3.3888 14.6468L6.69657 12.737L3.5633 7.31004C3.35589 6.95079 3.20561 6.57423 3.10965 6.19089ZM14.2217 8.39237L11.0885 2.96538C10.881 2.60612 10.6301 2.28769 10.346 2.01291C13.1259 2.05311 15.815 3.51287 17.3061 6.0955L17.5296 6.48259L14.2217 8.39237Z"
				:fill="iconColor"
			/>
		</svg>
		<svg
			v-if="type === 'available_weekend'"
			:width="size"
			:height="size"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V2H13V1C13 0.447715 13.4477 0 14 0C14.5523 0 15 0.447715 15 1V2H18C19.1046 2 20 2.89543 20 4V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V4C0 2.89543 0.895431 2 2 2H5V1ZM13 4V5C13 5.55228 13.4477 6 14 6C14.5523 6 15 5.55228 15 5V4H18V18H2V4H5V5C5 5.55228 5.44772 6 6 6C6.55228 6 7 5.55228 7 5V4H13ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5ZM14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
				:fill="iconColor"
			/>
		</svg>
		<svg
			v-if="type === 'available_week_morning'"
			:width="size"
			:height="size"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.8424 9.86043C17.901 9.91878 17.9401 9.96529 17.9657 10C17.9401 10.0347 17.901 10.0812 17.8424 10.1396C17.7686 10.2131 17.6749 10.2931 17.5597 10.3774C17.1958 10.1727 16.6374 10.0015 16.0049 9.86663L15.938 9.85257C15.8177 9.8276 15.6948 9.80393 15.5703 9.78158C14.2217 9.53952 12.4273 9.40002 10.5 9.40002C8.57271 9.40002 6.7783 9.53952 5.42971 9.78158C5.30517 9.80393 5.18231 9.8276 5.06195 9.85257L4.99509 9.86663C4.36255 10.0015 3.80424 10.1727 3.44029 10.3774C3.32511 10.2931 3.23143 10.2131 3.15756 10.1396C3.09895 10.0812 3.05991 10.0347 3.03427 10C3.05991 9.96528 3.09895 9.91878 3.15756 9.86043C3.39708 9.622 3.845 9.31531 4.5586 9.01484C5.97767 8.41734 8.07703 8 10.5 8C12.923 8 15.0223 8.41734 16.4414 9.01484C17.155 9.31531 17.6029 9.622 17.8424 9.86043ZM1 10C1 7.79086 5.25329 6 10.5 6C15.7467 6 20 7.79086 20 10H21C22.6569 10 24 11.3431 24 13V15.5C24 17.1569 22.6569 18.5 21 18.5H19.6016C18.5518 21.6939 15.5452 24 12 24H9C4.58172 24 1 20.4183 1 16V10ZM3 12.4554V16C3 19.3137 5.68629 22 9 22H12C15.3137 22 18 19.3137 18 16V12.4554C16.2617 13.3954 13.5485 14 10.5 14C7.45147 14 4.73831 13.3954 3 12.4554ZM21 16.5H19.9846C19.9948 16.3346 20 16.1679 20 16V12H21C21.5523 12 22 12.4477 22 13V15.5C22 16.0523 21.5523 16.5 21 16.5Z"
				:fill="iconColor"
			/>
			<path
				d="M11 1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V1Z"
				:fill="iconColor"
			/>
			<path
				d="M8 1C8 0.447715 8.44772 0 9 0C9.55228 0 10 0.447715 10 1V4C10 4.55228 9.55228 5 9 5C8.44772 5 8 4.55228 8 4V1Z"
				:fill="iconColor"
			/>
		</svg>
		<svg
			v-if="type === 'available_week_night'"
			:width="size"
			:height="size"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2 10C2 5.81018 5.2209 2.37264 9.32172 2.02835C8.48106 3.49292 8 5.19092 8 7C8 11.378 10.8127 15.0979 14.7299 16.4528C13.4047 17.4261 11.7697 18 10 18C5.58172 18 2 14.4183 2 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C13.0055 20 15.7022 18.6728 17.5339 16.576C17.7759 16.2988 17.8463 15.9112 17.7169 15.5667C17.5876 15.2222 17.2796 14.9765 16.9149 14.9272C13.0103 14.3983 10 11.0499 10 7C10 4.98528 10.7435 3.14667 11.9724 1.73976C12.2145 1.46263 12.2848 1.07496 12.1555 0.730463C12.0262 0.385971 11.7181 0.140325 11.3535 0.0909404C10.9104 0.0309311 10.4585 0 10 0ZM16.9174 2.36691C16.7364 1.8777 16.0444 1.8777 15.8634 2.36691L15.1141 4.39175C15.0572 4.54556 14.9359 4.66683 14.7821 4.72374L12.7573 5.473C12.2681 5.65403 12.2681 6.34597 12.7573 6.527L14.7821 7.27626C14.9359 7.33317 15.0572 7.45444 15.1141 7.60825L15.8634 9.63309C16.0444 10.1223 16.7364 10.1223 16.9174 9.63309L17.6666 7.60825C17.7236 7.45444 17.8448 7.33317 17.9986 7.27626L20.0235 6.527C20.5127 6.34597 20.5127 5.65403 20.0235 5.473L17.9986 4.72374C17.8448 4.66683 17.7236 4.54556 17.6666 4.39175L16.9174 2.36691Z"
				:fill="iconColor"
			/>
		</svg>
		<svg
			v-if="type === 'available_all_the_time'"
			:width="size"
			:height="size"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6 0C5.44772 0 5 0.447715 5 1V2H2C0.895431 2 0 2.89543 0 4V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V4C20 2.89543 19.1046 2 18 2H15V1C15 0.447715 14.5523 0 14 0C13.4477 0 13 0.447715 13 1V2H7V1C7 0.447715 6.55228 0 6 0ZM13 5V4H7V5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5V4H2V18H18V4H15V5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5ZM10.2071 14.7073L15.2071 9.70725C15.5981 9.31625 15.5981 8.68425 15.2071 8.29325C14.8161 7.90225 14.1841 7.90225 13.7931 8.29325L9.44809 12.6383L6.65109 10.2413C6.23209 9.88125 5.60109 9.92925 5.24109 10.3503C4.88109 10.7692 4.93009 11.3993 5.34909 11.7592L8.84909 14.7593C9.03709 14.9202 9.26909 15.0002 9.50009 15.0002C9.75609 15.0002 10.0131 14.9012 10.2071 14.7073Z"
				:fill="iconColor"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'AvailibilitiesIcon',
	props: {
		type: {
			type: String,
			required: true,
		},
		bgColor: {
			type: String,
			required: false,
			default: 'transparent',
		},
		size: {
			type: Number,
			required: false,
			default: 17,
		},
		iconColor: {
			type: String,
			required: false,
			default: 'black',
		},
	},
}
</script>

<style></style>
