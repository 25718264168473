/**
 * @store @extension
 */
import cloneDeep from 'lodash/cloneDeep'
import store from '../stateStore'
import session_store from '../../models/session_store'
import { SET_STRIPE_SYNC } from './_mutation-types'

import { get, set } from 'lodash'

type ExtensionState = {
	creationStatus: string
	deletionStatus: string
	stripeSync: StripeSync | null
}

const state: ExtensionState = {
	creationStatus: 'init',
	deletionStatus: 'init',
	stripeSync: null,
}

type PayloadExtension = {
	actionType: string
	api_key: string
	emailing: string
}

type NationBuilderSync = {
	actionType: string
	nation: string
	group: string
}

type StripeSync = {
	account_id: string
	status: string
}

const mutations = {
	[SET_STRIPE_SYNC]: (state: ExtensionState, payload: StripeSync) => {
		state.stripeSync = payload
	},
}

/**
 *
 * @param group
 * @param payload
 */
const setupBrevoSync = (group, payload: PayloadExtension): void => {
	const { actionType, api_key, emailing } = payload

	switch (actionType) {
		case 'activating': {
			set(group, 'sendinblue_apikey', api_key)
			set(group, 'mailjetapisecretkey', api_key)
			set(group, 'mailjetapipublickey', emailing)
			break
		}

		case 'desactivating': {
			set(group, 'sendinblue_apikey', '')
			set(group, 'mailjetapisecretkey', '')
			set(group, 'mailjetapipublickey', '')
			break
		}

		default: {
			break
		}
	}
}

const setupNationBuilderSync = (group, payload: NationBuilderSync): void => {
	const { actionType, nation } = payload

	switch (actionType) {
		case 'activating': {
			set(group, 'nationbuilder_nation', nation)
			break
		}

		case 'desactivating': {
			set(group, 'nationbuilder_nation', '')
			break
		}

		default: {
			break
		}
	}
}

const actions = {

	/**
	 * @param commit
	 * @param dispatch
	 * @param payload
	 * @returns {Promise<{isActivated: boolean, errorStatus: string}>}
	 * @description
	 * @description actionUpdateAnExtension
	**/
	async actionUpdateAnExtension({ commit, dispatch }, payload) {
		const isActivated = payload.actionType === 'activating'

		const group = cloneDeep(store.getters.info_campagne)

		let redirectUrl

		// For each extension 'type' we need to add or remove one or many key(s)
		if (get(payload, 'nation')) {
			setupNationBuilderSync(group, payload)
			if(payload.actionType === 'desactivating') {
				await session_store.deleteNationBuilderSync(payload.nation)
			} else {
				const response = await session_store.createNationBuilderSync(payload.nation)
				const getRedirectUrl = get(response, 'body.data', '')
				redirectUrl = getRedirectUrl
			}
		}

		if (get(payload, 'emailing', '') === 'SENDINBLUE') {
			setupBrevoSync(group, payload)
		}

		let response: any
		let errorStatus
		await session_store.updateMyGroup({ group }).then(async () => {
			response = await session_store.mygroup()
		}).catch((error) => {
			errorStatus = error.status
		})

		if(redirectUrl){
			globalThis.location.assign(redirectUrl)
		}

		commit('SET_CAMPAIGN_INFOS', response, { root: true })
		dispatch('@group/actionSetGroupCampaign', response, { root: true })

		if(get(payload, 'emailing', '') === 'SENDINBLUE') {
			dispatch('@emailer/actionResetState', '', { root: true })
			dispatch('@emailer/actionSetEmailerAccountData', '', { root: true })
			dispatch('@emailer/actionGetSenders', '', { root: true })
			dispatch('@emailer/actionGetEmailsBalance', get(payload, 'emailing', '') !== 'SENDINBLUE', { root: true })
		}

		return { isActivated: isActivated, errorStatus }
	},

	/**
	 * Retrieves the Stripe synchronization status
	 * @param {Object} context - The Vuex context with commit method
	 * @returns {Promise<void>} A promise that resolves once the sync status is retrieved
	 */
	async actionGetStripeSync({ commit }: { commit: Function }): Promise<void> {
		const res = await session_store.getStripeSync()
		commit('SET_STRIPE_SYNC', res)
	},

	/**
	 * Initializes the Stripe synchronization
	 * @returns {Promise<void>} A promise that resolves once the sync is created
	 */
	async actionSyncStripe(): Promise<void> {
		await session_store.createStripeSync()
	},

	/**
	 * Removes the Stripe synchronization
	 * @param {Object} context - The Vuex context with commit method
	 * @returns {Promise<void>} A promise that resolves once the sync is deleted
	 */
	async actionUnSyncStripe({ commit }: { commit: Function }): Promise<void> {
		const res = await session_store.deleteStripeSync()
		commit('SET_STRIPE_SYNC', { status: res })
	},
}

const getters = {
	getStripeSync: (state) => state.stripeSync?.status,
}

export const extensionModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
