import * as turf from '@turf/turf'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import set from 'lodash/set'
import { clone } from 'lodash'
// eslint-disable-next-line
import moment from 'moment'
import { getAllUniqUsersFromAction } from '@quorumsco/quorum-helpers/lib/actions/index'
import { firstBy } from 'thenby'
import actionTypology from '../../mixins/actionTypology'
import { ACTION } from '../../constants'
import actionStore from '../../models/action_store'
import contactStore from '../../models/contact_store'
import contactRequester from '../../models/contact_store'
import {
	formatOfFutureObjectForSearching,
	constructQueryFromStoreAdaptator,
	constructQueryFromStore,
} from './utils/searchTools'
import createFilterFromParamsObject from './utils/createFilterFromParamsObject'
import { getDateFromUTCToZoned } from '@/utils/dateTimeZone'
import { getCoverByActionType } from '@/utils/ActionCovers'

import {
	ARCHIVE_ACTION,
	SET_FORCE_EMPTY_ACTION,
	SET_ACTION_CREATION_STATE,
	SET_NEW_ACTION_STEPS,
	SET_ACTION_BLOCK_CITIES_TERRITORIES,
	SET_ASSIGNMENT_TYPE,
	SET_ACTION_RESET_ON_CLOSE_MODAL,
	SET_ACTION_NEW_SEARCH_POLYGON,
	SET_ACTION_NEW_SEARCH_TAGS,
	SET_ACTION_NEW_SEARCH_FILTERS,
	SET_ACTION_NEW_ADVANCED_SEARCH_FILTERS,
	SET_ACTION_RESET_ADVANCED_SEARCH_FILTERS,
	SET_ACTION_TOTAL_HIT,
	SET_NEW_ACTION_TOTAL_HIT,
	SET_NEW_ACTION_NEVER_MET_HIT,
	SET_NEW_ACTION_ABSENT_HIT,
	SET_NEW_ACTION_TYPOLOGY,
	SET_NEW_ACTION_GLOBAL_TYPE,
	SET_ACTION_NEW_ADDRESS,
	SET_NEW_ACTION_MEETING_POINT,
	RESET_NEW_ACTION_MEETING_POINT,
	SET_NEW_ACTION_ONLY_TAKE_STREETS,
	SET_NEW_ACTION_DATA_OBJECT_VALUE,
	SET_NEW_ACTION_DATA_BRIEF,
	SET_NEW_ACTION_PRECISION,
	SET_SKIP_NAVIGATION_GUARD_BEFORE_ROUTE_LEAVE_ACTION_CREATION,
	SET_STATUS_RENOTIFY,
	RESET_NEW_ACTION_STORE,
	SET_CONTACTS_ACTION,
	SET_ACTION_CONTACT_LIST_ADVANCED_SEARCH,
	SET_ACTION_STACK,
	HANDLE_ACTION_DATA_LEADERS,
	NAVIGATE_TO_ACTION,
} from './_mutation-types'
import orderBy from 'lodash/orderBy'

export const STATUS_RENOTIFY = {
	NONE: 'NONE',
	REQUESTED: 'REQUESTED',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
}

let last_query = {}

// Index is mandatory for stepper
const STEPS = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'PLACE', index: 1, route: 'place' },
	{ title: 'NAME_SCHEDULE', index: 2, route: 'name_schedule' },
	{ title: 'MORE_INFORMATION', index: 3, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 4, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 5, route: 'assignment' },
]

const MINIMAL_STEPS = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'NAME_SCHEDULE', index: 1, route: 'name_schedule' },
	{ title: 'MORE_INFORMATION', index: 2, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 3, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 4, route: 'assignment' },
]

const MINIMAL_STEPS_WITH_MESSAGES_TEMPLATES = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'NAME_SCHEDULE', index: 1, route: 'name_schedule' },
	{ title: 'MESSAGES_TEMPLATES', index: 2, route: 'about_message' },
	{ title: 'MORE_INFORMATION', index: 3, route: 'more_information' },
	{ title: 'SELECT_MEMBERS', index: 4, route: 'assignment' },
	// { title: 'SELECT_MEMBERS', index: 4, route: 'select_members' },
]

const MINIMAL_STEPS_WITH_MEETING_POINT = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'NAME_SCHEDULE', index: 1, route: 'name_schedule' },
	{ title: 'PLACE', index: 2, route: 'place' },
	{ title: 'MORE_INFORMATION', index: 3, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 4, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 5, route: 'assignment' },
]

const STEPS_WITH_PRECISION = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'TARGET', index: 1, route: 'target' },
	{ title: 'PRECISION', index: 2, route: 'precision' },
	{ title: 'NAME_SCHEDULE', index: 3, route: 'name_schedule' },
	{ title: 'PLACE', index: 4, route: 'place' },
	{ title: 'MORE_INFORMATION', index: 5, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 6, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 7, route: 'assignment' },
]
const STEPS_WITH_PRECISION_WITHOUT_MEETING_POINT = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'TARGET', index: 1, route: 'target' },
	{ title: 'PRECISION', index: 2, route: 'precision' },
	{ title: 'NAME_SCHEDULE', index: 3, route: 'name_schedule' },
	{ title: 'MORE_INFORMATION', index: 4, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 5, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 6, route: 'assignment' },
	// { title: 'SELECT_MEMBERS', index: 6, route: 'select_members' },
]

const STEP_FILTER_WITHOUT_PRECISION = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'FILTER_CONTACTS', index: 1, route: 'filter_contacts' },
	{ title: 'NAME_SCHEDULE', index: 2, route: 'name_schedule' },
	{ title: 'MORE_INFORMATION', index: 3, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 4, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 5, route: 'assignment' },
]

const STEP_GOTV_CANVASSING = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'SELECT_TAGS', index: 1, route: 'select_tags' },
	{ title: 'TARGET', index: 2, route: 'target' },
	{ title: 'NAME_SCHEDULE', index: 3, route: 'name_schedule' },
	{ title: 'PLACE', index: 4, route: 'place' },
	{ title: 'MORE_INFORMATION', index: 5, route: 'more_information' },
	{ title: 'SELECT_MEMBERS', index: 6, route: 'assignment' },
	// { title: 'SELECT_MEMBERS', index: 6, route: 'select_members' },
]

const STEP_GOTV_CALLING = [
	{ title: 'TYPE', index: 0, route: 'type' },
	{ title: 'SELECT_TAGS', index: 1, route: 'select_tags' },
	{ title: 'TARGET', index: 2, route: 'target' },
	{ title: 'NAME_SCHEDULE', index: 3, route: 'name_schedule' },
	{ title: 'MORE_INFORMATION', index: 4, route: 'more_information' },
	{ title: 'SELECT_MEMBERS_WHO', index: 5, route: 'assignment_who' },
	{ title: 'SELECT_MEMBERS', index: 6, route: 'assignment' },
]

const cleanAnActionAddresses = (action) => {
	if (action.Addresses) {
		if (Array.isArray(action.Addresses)) {
			if (action.Addresses.length) {
				action.Addresses = action.Addresses.filter((anAddress) => {
					return typeof anAddress === 'object' && !isEmpty(anAddress)
				})
			}
			else {
				delete action.Addresses
			}
		}
	}
}

function cleanEmptyStreetsAttribute(arrayAddresses) {
	if (Array.isArray(arrayAddresses)) {
		if (arrayAddresses.length) {
			arrayAddresses.forEach((anAddress) => {
				unset(anAddress, 'empty')
			})
		}
	}
}

/**
 * @func addAnswerToASearchFilter
 * @param {string} currentFilter
 * @param {string} answerToAdd
 */
function addAnswerToASearchFilter(currentFilter, answerToAdd) {
	if (!currentFilter.includes(answerToAdd)) currentFilter.push(answerToAdd)
}

/**
 * @func removeAnswerToASearchFilter
 * @param {string} currentFilter
 * @param {string} answerToRemove
 */
function removeAnswerToASearchFilter(currentFilter, answerToRemove) {
	if (currentFilter.includes(answerToRemove)) {
		const indexOfAnswer = currentFilter.findIndex((aFilterParam) => aFilterParam === answerToRemove)
		currentFilter.splice(indexOfAnswer, 1)
	}
}

function formatWithAllowedFieldsOnly(arrayOfDataObjects, allowedFields) {
	// console.log('arrayOfDataObjects', arrayOfDataObjects)
	// doc this function basically loops over an array and only keeps the allowed fields on the object
	// "Users": [
	//   {
	//     "surname": "Duroy",
	//     "mail": "benjamin@gmail.com",
	//     "id": 63,
	//   },
	//   {
	//     "surname": "Bach",
	//     "mail": "feb.barre22@icloud.com",
	//     "id": 64,
	//   },
	//   {
	//     "surname": "Dival",
	//     "mail": "ronanlegoaziou@gmail.com",
	//     "id": 162,
	// 	},
	// ]
	// after formatWithAllowedFieldsOnly(Users, ['id'])
	// >> [{"id": 63 }, {"id": 64 }, {"id": 162 }]
	return arrayOfDataObjects.reduce((finalArrayAccumulator, singleDataObject) => {
		finalArrayAccumulator.push(
			Object.keys(singleDataObject)
				.filter((key) => allowedFields.includes(key))
				.reduce((acc, key) => {
					return {
						...acc,
						[key]: singleDataObject[key],
					}
				}, {}),
		)
		return finalArrayAccumulator
	}, [])
}

function populateResponseWithLocalTeamUsers(globalTeams, response) {
	// ? the back does not send a list of Teams[<id>].userswithactive[<array of users>] on an action patch anymore
	const { Teams: teamsToPopulate } = response
	for (let teamToPopulate of teamsToPopulate) {
		const globalTeamUserList = globalTeams.find((globalTeam) => globalTeam.id === teamToPopulate.id)
		if (globalTeamUserList) {
			teamToPopulate['userswithactive'] = globalTeamUserList.users
		}
	}
	response.Teams = teamsToPopulate
}

const getDefaultState = () => {
	return {
		actionCreationState: '',
		leadersMaxLength: 3,
		forceEmptyAction: false,
		isActionLoading: false,
		isActionCurrentlyEdited: false,
		actionDetailLocalComponents: {},
		isCreating: false,
		activeStep: {},
		entryPoint: '',
		showDetail: false,
		needToBlockTargetCitiesAndTerritories: false,
		resetActionModuleOnClose: false,
		totalHitsFound: 0,
		totalNeverMetHitsFound: 0,
		totalAbsentHitsFound: 0,
		skipBeforeRouteLeaveActionCreation: false,
		newAction: {
			leaders: [],
			type: '',
			globalType: '',
			assignmentType: '',
			title: '',
			timezone: '',
			comment: '',
			link: '',
			links: [],
			goal: 0,
			goal_forms: 0,
			start: null,
			end: null,
			Addresses: [{}],
			usersIds: [],
			teamsIds: [],
			firstTimer: true,
			formEnabled: true,
			created_by: null,
			typeFormDisabledAt: false,
			onlyStreetsEmptyStreets: false,
			onlyStreetsAllStreets: false,
			onlyPolygonUnknowAddresses: false,
			onlyPolygonCombineContactsAndUnknowAddresses: false,
			publicAction: false,
			registration_link: '',
			cover_img: {
				background_size_ratio: 0,
				cover_img_x: 0,
				cover_img_y: 0,
				url: '',
			},
			/**
			 * Filters
			 */
			isFilterAbsentOrNeverSeenSelected: 'all',
			isFilterNeverMet: 'all',
			isFilterMobileOrPhoneSelected: {},
			search: {
				query: '',
				polygon: [],
				questions: [],
				filter: ['0', 'street', '1000000', '0', '', '', '', 'surname', '', '', '', ''],
				tags_filter: [],
				only_duplicates: false,
				polling_station_included: [],
				address_included: [],
				address_excluded: [],
				advanced_search: undefined,

			},
		},
		actionToNavigate: null,
		steps: STEPS,

		actions: [],
		filteredActions: [],
		activeAction: {},
		actionToEdit: {},
		statusReNotify: STATUS_RENOTIFY.NONE,
		contactsAction: [],
		actionContactList: [],
		totalActionHitsFound: 0,
		actionsStack: 0,
		stackIncrementRunning: false,
		stopStackLoop: false,
	}
}

const state = getDefaultState

const mutations = {
	ADD_NEW_ACTION: (state, payload) => {
		state.actions = [...[payload], ...state.actions]
	},

	DELETE_ACTION: (state, payload) => {
		let actionToDelete
		state.actions.map((action, index) => {
			if (action.ID === payload.ID) {
				actionToDelete = index
			}
		})
		state.actions.splice(actionToDelete, 1)
	},

	SET_ENTRY_POINT: (state, route = null) => {
		// If route, you are entering the tunnel. If not, reset case
		state.entryPoint = route ? route : ''
	},

	REORDER_STEPS: (state, stepTitle) => {
		// annoying function wo/ any real usage (IMO, we're simply using our router for the steps)
		let from
		state.steps.map((step, index) => {
			if (step.title === stepTitle) {
				from = index
			}
		})

		state.steps.unshift(state.steps.splice(from, 1)[0])
		state.steps.map((step, index) => {
			step.index = index
		})
	},

	SET_NEW_ACTION: (state, payload) => {
		state.newAction = payload
	},

	/**
	 * @func RESET_NEW_ACTION_STORE
	 * This mutation empty the current newAction state
	 * and replace it by the default state of the newAction
	 */
	[RESET_NEW_ACTION_STORE]: (currentState) => {
		const initialState = getDefaultState()
		currentState.newAction = cloneDeep(initialState.newAction)
		currentState.totalHitsFound = 0
		currentState.totalNeverMetHitsFound = 0
		currentState.totalAbsentHitsFound = 0
		currentState.skipBeforeRouteLeaveActionCreation = false
		currentState.isCreating = false
		currentState.steps = STEPS
	},

	SET_ACTIVE_STEP: (state, stepTitle = null) => {
		state.activeStep = stepTitle
			? state.steps.filter((step) => step.title === stepTitle)[0]
			: state.steps[0]
	},

	SET_ACTIONS: (state, payload) => {
		function setDateFromUTC(action) {
			if (action.start) {
				action.start = getDateFromUTCToZoned(action.start)
			}
			if (action.end) {
				action.end = getDateFromUTCToZoned(action.end)
			}
		}

		payload.forEach(setDateFromUTC)
		payload.forEach((anAction) => {
			anAction.cover_img = getCoverByActionType(anAction)
		})

		state.actions = payload
		state.filteredActions = orderBy(payload, ['CreatedAt'], ['desc'])
	},

	SET_ACTION_TO_EDIT: (state, action) => {
		/**
		 * Since the github issue, is not needed to do a shallow copy
		 * https://github.com/vuejs/vue/issues/158
		 */
		state.actionToEdit = action
	},

	[SET_ASSIGNMENT_TYPE]: (state, payload) => {
		state.newAction.assignmentType = payload
	},
	[SET_ACTION_BLOCK_CITIES_TERRITORIES]: (state, payload) => {
		state.needToBlockTargetCitiesAndTerritories = payload
	},
	[SET_ACTION_RESET_ON_CLOSE_MODAL]: (state, payload) => {
		state.resetActionModuleOnClose = payload
	},

	EMPTY_ACTION_TO_EDIT: (state) => {
		state.actionToEdit = {}
	},

	SET_FILTERED_ACTIONS: (state, payload) => {
		state.filteredActions = orderBy(payload, ['CreatedAt'], ['desc'])
	},

	SET_FOCUS_STATE: (state, payload) => {
		payload.focus = !payload.focus
	},

	START_LOADING: (state) => {
		state.isActionLoading = true
	},

	STOP_LOADING: (state) => {
		state.isActionLoading = false
	},

	UPDATE_ACTION_BY_ID: (state, payload) => {
		let indexToSplice
		let oldAction
		state.actions.forEach((action, index) => {
			if (action.ID === payload.ID) {
				indexToSplice = index
				oldAction = action
			}
		})

		if (Array.isArray(payload.Teams)) {
			payload.Teams = Array.isArray(oldAction.Teams)
				? oldAction.Teams.length === payload.Teams.length
					? oldAction.Teams
					: payload.Teams
				: payload.Teams
		}
		state.actions.splice(indexToSplice, 1, {
			...payload,
		})
	},

	IS_CREATING: (state) => {
		state.isCreating = true
	},

	HAS_CREATED_ACTION: (state) => {
		state.isCreating = false
	},

	SET_ACTIVE_ACTION: (state, action) => {
		state.activeAction = clone(action)
		if (action.Users) {
			state.activeAction.Users = clone(action.Users)
		}
	},

	EMPTY_ACTIVE_ACTION: (state) => {
		state.activeAction = {}
	},

	DISPLAY_DETAIL: (state) => {
		state.showDetail = true
	},

	HIDE_DETAIL: (state) => {
		state.showDetail = false
	},

	[SET_NEW_ACTION_STEPS]: (state, payload) => {
		state.steps = payload
	},

	[SET_ACTION_BLOCK_CITIES_TERRITORIES]: (state, payload) => {
		state.needToBlockTargetCitiesAndTerritories = payload
	},

	[SET_ACTION_RESET_ON_CLOSE_MODAL]: (state, payload) => {
		state.resetActionModuleOnClose = payload
	},

	[SET_NEW_ACTION_ONLY_TAKE_STREETS]: (state, payload) => {
		state.newAction.onlyTakeStreetsForCreation = payload
	},

	[SET_NEW_ACTION_DATA_OBJECT_VALUE]: (state, payload) => {
		const { key, value } = payload

		if (payload.key === 'link') {
			set(state.newAction, 'link', payload.link)
			return
		}
		if (payload.key === 'links') {
			state.newAction.links.push(payload.link)
			return
		}
		if (payload.key === 'remove-link') {
			set(state.newAction, 'link', '')
			return
		}
		if (payload.key === 'remove-links') {
			const index = state.newAction.links.findIndex((aLink) => aLink === payload.link)
			if (index !== -1) {
				state.newAction.links.splice(index, 1)
				return
			}
		}
		if (value || typeof value === 'boolean' || typeof value === 'number') {
			set(state.newAction, key, value)
		}
		else {
			set(state.newAction, key, null)
		}
	},

	[SET_NEW_ACTION_PRECISION]: (state, payload) => {
		const { newAction } = state
		switch (payload) {
			case 'onlyStreetsEmptyStreets':
				newAction.onlyStreetsEmptyStreets = true
				break
			case 'onlyStreetsAllStreets':
				newAction.onlyStreetsAllStreets = true
				break
			case 'onlyPolygonUnknowAddresses':
				newAction.onlyPolygonUnknowAddresses = true
				break
			case 'onlyPolygonCombineContactsAndUnknowAddresses':
				newAction.onlyPolygonCombineContactsAndUnknowAddresses = true
				break
			case 'emptyAction':
				break
			case 'onlyPolygonIsTooBig':
				newAction.onlyPolygonCombineContactsAndUnknowAddresses = false
				break

			default:
				newAction.onlyStreetsEmptyStreets = false
				newAction.onlyStreetsAllStreets = false
				newAction.onlyPolygonUnknowAddresses = false
				newAction.onlyPolygonCombineContactsAndUnknowAddresses = false
				break
		}
	},

	[SET_ACTION_CREATION_STATE]: (currentState, payload) => {
		switch (payload) {
			case 'active':
			case 'inactive':
			case 'reset':
			case 'submitted':
				currentState.actionCreationState = payload
				break

			default:
				currentState.actionCreationState = ''
				break
		}
	},

	[SET_FORCE_EMPTY_ACTION]: (state, boolean) => {
		state.forceEmptyAction = boolean
	},

	SET_IS_ACTION_CURRENTLY_EDITED: (state, boolean) => {
		state.isActionCurrentlyEdited = boolean
	},

	SET_ACTION_DETAIL_LOCAL_COMPONENTS: (state, object) => {
		state.actionDetailLocalComponents = object
	},
	[SET_ACTION_NEW_SEARCH_POLYGON]: (currentState, payload) => {
		currentState.newAction.search.polygon = payload
	},
	[SET_ACTION_NEW_SEARCH_TAGS]: (currentState, payload) => {
		currentState.newAction.search.tags_filter = payload
	},
	[SET_ACTION_NEW_SEARCH_FILTERS]: (currentState, payload) => {
		currentState.newAction.search.filter = payload
	},
	[SET_ACTION_NEW_ADVANCED_SEARCH_FILTERS]: (currentState, payload) => {
		currentState.newAction.search.advanced_search = payload
	},
	[SET_ACTION_RESET_ADVANCED_SEARCH_FILTERS]: (currentState) => {
		delete currentState.newAction.search.advanced_search
	},
	[SET_NEW_ACTION_TOTAL_HIT]: (currentState, payload) => {
		currentState.totalHitsFound = payload
	},
	[SET_NEW_ACTION_NEVER_MET_HIT]: (currentState, payload) => {
		currentState.totalNeverMetHitsFound = payload
	},
	[SET_NEW_ACTION_ABSENT_HIT]: (currentState, payload) => {
		currentState.totalAbsentHitsFound = payload
	},
	[SET_NEW_ACTION_TYPOLOGY]: (currentState, payload) => {
		currentState.newAction.type = payload
	},
	[SET_NEW_ACTION_GLOBAL_TYPE]: (currentState, payload) => {
		currentState.newAction.globalType = payload
	},
	[SET_ACTION_NEW_ADDRESS]: (currentState, payload) => {
		const { index, key, value } = payload
		currentState.newAction.Addresses[index][key] = value
	},
	[SET_NEW_ACTION_MEETING_POINT]: (currentState, payload) => {
		const { index, value } = payload
		currentState.newAction.Addresses[index] = value
	},
	[RESET_NEW_ACTION_MEETING_POINT]: (currentState) => {
		currentState.newAction.Addresses = [{}]
	},
	[SET_NEW_ACTION_DATA_BRIEF]: (currentState, payload) => {
		const { newAction } = currentState
		if (newAction.forms) {
			if (newAction.forms.length) {
				newAction.forms = newAction.forms.filter((aForm) => aForm.name !== 'BRIEF')
				if (payload) {
					newAction.forms.push(payload)
				}
			}
		}
		else {
			newAction.forms = [payload]
		}
	},

	HANDLE_ACTION_DATA_LEADERS: (currentState, payload) => {
		const { newAction } = currentState
		newAction.leaders = payload
	},

	REMOVE_AN_ACTION_DATA_LEADER: (currentState, payload) => {
		const { newAction } = currentState
		const actionLeaderIndex = newAction.leaders.findIndex((aLeader) => aLeader.id === payload.id)
		if (actionLeaderIndex !== -1) {
			newAction.leaders.splice(actionLeaderIndex, 1)
		}
	},

	[SET_SKIP_NAVIGATION_GUARD_BEFORE_ROUTE_LEAVE_ACTION_CREATION]: (
		currentState,
		payload = false,
	) => {
		currentState.skipBeforeRouteLeaveActionCreation = payload
	},

	[SET_STATUS_RENOTIFY]: (state, payload) => {
		state.statusReNotify = payload
	},

	[SET_CONTACTS_ACTION]: (state, payload) => {
		state.contactsAction = payload
	},

	[SET_ACTION_CONTACT_LIST_ADVANCED_SEARCH]: (currentState, payload) => {
		currentState.actionContactList = payload
	},
	[SET_ACTION_TOTAL_HIT]: (currentState, payload) => {
		currentState.totalActionHitsFound = payload
	},

	[SET_ACTION_STACK]: async (currentState, countToAdd = null) => {
		if (currentState.stackIncrementRunning && !countToAdd) return

		const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
		if (currentState.stackIncrementRunning && !countToAdd) return

		const incrementStack = async (start, end) => {
			let index = start
			while (index < end && !currentState.stopStackLoop) {
				await sleep(100) // Delay of 200ms for each item
				currentState.actionsStack = index + 1 // Increment actionsStack by 1
				index++
			}
		}

		if (countToAdd) {
			if (currentState.stackIncrementRunning) currentState.stopStackLoop = true
			currentState.stackIncrementRunning = true
			await sleep(100)
			currentState.stopStackLoop = false
			currentState.actionsStack = 0
			const start = 0
			const end = countToAdd
			await incrementStack(start, end)
		}
		else {
			currentState.stackIncrementRunning = true
			const start = currentState.actionsStack
			const end = currentState.actionsStack + 30
			await incrementStack(start, end)
		}

		currentState.stackIncrementRunning = false
	},

	[ARCHIVE_ACTION]: (currentState, actionIDToArchive) => {
		currentState.actions.forEach((action) => {
			if (action.ID === actionIDToArchive) {
				action.status = 'archived'
			}
		})
	},

	[NAVIGATE_TO_ACTION]: (currentState, actionID) => {
		currentState.actionToNavigate = actionID
	},
}

const actions = {
	actionSetStep: ({ commit }, stepTitle) => {
		commit('SET_ACTIVE_STEP', stepTitle)
	},
	actionSetAssignmentType: ({ commit }, assignmentType) => {
		commit('SET_ASSIGNMENT_TYPE', assignmentType)
	},

	actionResetFlow: ({ commit, dispatch }, onlyAction = false) => {
		commit('SET_ACTIVE_STEP')
		commit('SET_ENTRY_POINT')
		commit(RESET_NEW_ACTION_STORE)
		if (!onlyAction) {
			commit('@filter/SET_POLYGON', [], { root: true })
			dispatch('@kpi/GENERATE_KPI', null, { root: true })
			dispatch('UPDATE_LOCATION_SUMMARY_GEOHASH', null, { root: true })
		}
	},

	actionCreateNewFieldAction: ({ commit, dispatch, state, rootState }, actionSearch) => {
		const { action, payload, needToBeEmptyAction } = actionSearch

		let rumbledId = -1
		if (state.actions.length) {
			rumbledId = state.actions[state.actions.length - 1].ID + 1
		}

		// check this one
		// if (action.Addresses?.length && Array.isArray(action.Addresses)) {
		// 	action.Addresses = action.Addresses.filter((anAddress) => {
		// 		typeof anAddress === 'object' && !isEmpty(anAddress)
		// 	})
		// }
		cleanAnActionAddresses(action)

		commit('IS_CREATING')
		commit('ADD_NEW_ACTION', {
			...cloneDeep(action),
			ID: rumbledId,
			name: action.title,
			type_data: action.type,
			pitch: action.comment,
			notBaked: true,
			status: ACTION.STATUS.TODO,
			cover_img: getCoverByActionType(action),

		})
		dispatch('filterActions', last_query)

		/**
		 * If my action have the option precision
		 * onlyStreetsEmptyStreets or onlyStreetsAllStreets
		 */
		if (action.onlyStreetsEmptyStreets || action.onlyStreetsAllStreets) {
			let { addressIncluded } = payload
			if (addressIncluded.length) {
				const emptyStreets = addressIncluded.filter((anAddress) => anAddress.empty)
				addressIncluded = addressIncluded.filter((anAddress) => !anAddress.empty)
				cleanEmptyStreetsAttribute(emptyStreets)
				cleanEmptyStreetsAttribute(addressIncluded)
				action.unknow_address_included = [...emptyStreets]
			}
			if (action.onlyStreetsEmptyStreets) {
				delete payload.addressIncluded
			}
			if (action.onlyStreetsAllStreets) {
				payload.addressIncluded = addressIncluded
				cleanEmptyStreetsAttribute(addressIncluded)
			}
		}

		const cloneDeepCopyOfPayloadForActionCreation = cloneDeep({
			...action,
			name: action.title.charAt(0).toUpperCase() + action.title.slice(1),
			search: {
				...action.search,
				only_unknow_addresses: action.onlyPolygonUnknowAddresses || action.onlyStreetsEmptyStreets,
				combine_contacts_and_unknow_addresses:
					action.onlyPolygonCombineContactsAndUnknowAddresses || action.onlyStreetsAllStreets,
				query: action.search.query || rootState['@filter'].query,
				tags: action.search.tags_filter || rootState['@filter'].tags_filter,
				only_duplicates: action.search.duplicates_filter || rootState['@filter'].duplicates_filter,
				...formatOfFutureObjectForSearching(payload),
			},
		})

		if (needToBeEmptyAction) {
			cloneDeepCopyOfPayloadForActionCreation.search.filter[2] = String(0)
		}

		/**
		 * Delete the options used to create the action
		 * to avoid to send it to the backend.
		 * The object is allready set and format with the options
		 * with the part of the code above
		 */
		delete cloneDeepCopyOfPayloadForActionCreation.onlyStreetsEmptyStreets
		delete cloneDeepCopyOfPayloadForActionCreation.onlyStreetsAllStreets
		delete cloneDeepCopyOfPayloadForActionCreation.onlyPolygonUnknowAddresses
		delete cloneDeepCopyOfPayloadForActionCreation.onlyPolygonCombineContactsAndUnknowAddresses
		delete cloneDeepCopyOfPayloadForActionCreation.isFilterAbsentOrNeverSeenSelected
		delete cloneDeepCopyOfPayloadForActionCreation.isFilterNeverMet
		delete cloneDeepCopyOfPayloadForActionCreation.isFilterMobileOrPhoneSelected
		delete cloneDeepCopyOfPayloadForActionCreation.tags_filter

		const needToDisableAFormClassic = !!action.typeFormDisabledAt
		/**
		 * It will create the action
		 */

		actionStore.createAction(cloneDeepCopyOfPayloadForActionCreation).then((action) => {
			setTimeout(() => {
				commit('HAS_CREATED_ACTION')
				/**
				 * Will dispatch an action present in the @search module
				 * to reset the state of the module after the creation
				 * The 3rd argument { root: true }, is used to access
				 * to the global store of Vuex
				 * More info : https://vuex.vuejs.org/guide/modules.html#accessing-global-assets-in-namespaced-modules
				 */
				dispatch(
					'@search/actionResetSearchModuleToInitialState',
					{ exceptAdvancedSearch: false },
					{
						root: true,
					},
				)
				dispatch('actionRetrieveCampaignActions', true)
				if (action.ID && needToDisableAFormClassic) {
					actionStore.disableFormForAnAction({ action_id: action.ID, type: 'FORM' })
				}
				commit('NAVIGATE_TO_ACTION', action?.ID)
			}, 1000)
		})
	},

	actionRetrieveActionById: async ({ commit }, actionId) => {
		await actionStore.getActionById(actionId)
	},

	actionRetrieveCampaignActions: ({ commit, dispatch }, avoidLoading = false) => {
		if (!avoidLoading) {
			commit('START_LOADING')
		}

		return actionStore
			.getActions()
			.then((res) => {
				let actions
					= res.ActionsAggreg?.flatMap((aggreg) => {
						aggreg.Action.target = aggreg.NbContacts
						aggreg.Action.visited = aggreg.NbVisites
						return aggreg.Action
					}).sort(firstBy('CreatedAt', -1)) || []
				commit('SET_ACTIONS', actions)
				commit('STOP_LOADING')
				dispatch('filterActions', last_query)
				return res
			})
			.catch((e) => {
				console.warn(e)
				commit('STOP_LOADING')
			})
	},

	filterActions: (store, query) => {
		last_query = query
		let localQuery = {}
		Object.keys(query).length === 0
			? (localQuery.actionTypeQuery = 'running')
			: (localQuery = query)

		store.commit(
			'SET_FILTERED_ACTIONS',
			cloneDeep(store.state.actions)
				?.filter((action) => {
					action.focus = !!action.focus
					const matchWithText = query.text
						? action.name?.toLowerCase().indexOf(query.text?.toLowerCase()) > -1
						: true

					if (localQuery.actionTypeQuery === 'running') {
						const isTodoOrProgress
							= (action.status === ACTION.STATUS.TODO
							|| action.status === ACTION.STATUS.INPROGRESS)
							&& action.status !== ACTION.STATUS.FINISHED
							&& (!action.end || (action.end && moment(new Date()).isBefore(action.end)))
						return matchWithText && isTodoOrProgress
					}
					if (localQuery.actionTypeQuery === 'exceeded') {
						if (action.status !== ACTION.STATUS.FINISHED && action.end) {
							return matchWithText && moment(new Date()).isAfter(action.end)
						}
						return false
					}
					if (localQuery.actionTypeQuery === 'finished') {
						return matchWithText && action.status === ACTION.STATUS.FINISHED
					}
					return matchWithText
				})
				.sort(
					firstBy((v1, v2) => {
						if (localQuery.actionTypeQuery === 'running') {
							if (v1.start && v2.start) {
								if (moment(v1.end).isBefore(new Date()) && moment(v2.end).isBefore(new Date())) {
									if (moment(v2.end).isBefore(v1.end)) return 1
									if (moment(v2.end).isAfter(v1.end)) return -1
								}
								if (moment(v1.end).isBefore(new Date()) && !moment(v2.end).isBefore(new Date())) {
									return -1
								}
								if (moment(v2.end).isBefore(new Date()) && !moment(v1.end).isBefore(new Date())) {
									return 1
								}
								const v1FromNow = moment(v1.start)
								const v2FromNow = moment(v2.start)
								if (v2FromNow.isBefore(v1FromNow)) return 1
								if (v2FromNow.isAfter(v1FromNow)) return -1
							}
							if (v1.start && !v2.start) {
								return -1
							}
							if (v2.start && !v1.start) {
								return 1
							}
							return 0
						}
						return 0
					}),
				),
		)
	},

	actionUpdateCampaignAction: ({ commit, dispatch, rootGetters }, anAction) => {
		const action = cloneDeep(anAction)
		const interestingFields = [
			'end',
			'enveloppe',
			'Facts',
			'fields',
			'goal',
			'group_id',
			'ID',
			'link',
			'links',
			'name',
			'pitch',
			'polygon',
			'query',
			'start',
			'status',
			'type_data',
			'notify',
			'visited',
			'target',
			'publicAction',
			'registration_link',
			'cover_img',
			'leaders',
			'time_zone',
		]

		let actionArray = []
		actionArray.push(action)
		const actionToUpdate = formatWithAllowedFieldsOnly(actionArray, interestingFields)?.[0]

		if (action?.name) {
			actionToUpdate.name = action.name.charAt(0).toUpperCase() + action.name.slice(1)
		}
		if (action?.Users?.length && Array.isArray(action?.Users)) {
			actionToUpdate.users = formatWithAllowedFieldsOnly(action.Users, ['id'])
		}
		if (action?.Teams?.length && Array.isArray(action?.Teams)) {
			actionToUpdate.teams = formatWithAllowedFieldsOnly(action.Teams, ['id'])
		}
		if (action?.leaders?.length && Array.isArray(action?.leaders)) {
			actionToUpdate.leaders = formatWithAllowedFieldsOnly(action.leaders, ['id'])
		}

		if (action?.cover_img)
			if (action?.cover_img?.index) {
				if (!action?.cover_img?.url.includes('http')) {
					actionToUpdate.cover_img.url = action?.cover_img?.index
				}

				delete actionToUpdate?.cover_img?.index
			}
			else {
				if (action?.cover_img?.url.includes('/static/')) {
					delete actionToUpdate.cover_img
				}
			}

		if (action?.link && action?.links) {
			actionToUpdate.links = action?.links.filter((link) => link !== action.link)
		}

		// ? Clean *Addresses* of an action if one address is empty (like empty object)
		// not sure if this function is still working/used, probably not...@kos
		if (action?.Addresses?.length && Array.isArray(action?.Addresses)) {
			actionToUpdate.addresses = formatWithAllowedFieldsOnly(action.Addresses, [
				'infos',
				'addition',
				'city',
				'street',
				'country',
				'latitude',
				'longitude',
				'housenumber',
				'postalcode',
			])
		}

		if (action?.Forms?.length && Array.isArray(action?.Forms)) {
			actionToUpdate.Forms = formatWithAllowedFieldsOnly(action?.Forms, [
				'city',
				'housenumber',
				'postalcode',
				'street',
				'id',
				'type',
				'section',
				'activate',
				'order',
				'activate_auto_send',
				'activate_auto_send_sms',
				'label',
				'group_id',
				'refvalues',
			])
		}

		return actionStore.update(actionToUpdate).then((response) => {
			const globalTeams = rootGetters['@team/getTeams']
			if (action.Teams?.length && Array.isArray(action.Teams)) {
				populateResponseWithLocalTeamUsers(globalTeams, response)
			}
			commit('UPDATE_ACTION_BY_ID', {
				...response,
				target: actionToUpdate.target,
				visited: actionToUpdate.visited,
				cover_img: getCoverByActionType(response),
			}) // Update value

			commit('SET_ACTION_TO_EDIT', {}) // Reset value
			dispatch('filterActions', last_query)
		})
	},

	actionUpdateActionById: async ({ commit }, action) => {
		await commit('UPDATE_ACTION_BY_ID', action)
	},

	closeOneAction: async ({ dispatch }, action) => {
		const actionStoreResponse = await actionStore.close(action)
		await dispatch('actionUpdateActionById', actionStoreResponse)
		await dispatch('filterActions', last_query)
	},

	reopenOneAction: async ({ dispatch }, action) => {
		const actionStoreResponse = await actionStore.open(action)
		await dispatch('actionUpdateActionById', actionStoreResponse)
		await dispatch('filterActions', last_query)
	},

	actionArchiveAction: ({ dispatch, state, commit }, actionToArchive) => {
		return new Promise((resolve, reject) => {
			actionStore
				.archive(actionToArchive)
				.then(() => {
					/**
					 * After the update of the action, we want to
					 * update too locally the action now archived
					 * to avoid a backend call to get the action.
					 */
					commit('ARCHIVE_ACTION', actionToArchive.ID)
					dispatch('filterActions', last_query)
					resolve() // Resolve the promise when done
				})
				.catch((err) => {
					console.warn(err)
					reject(err) // Reject the promise if an error occurs
				})
		})
	},

	/**
	 * @action getAutocomplete
	 * @param {Object} query,allowUnknowAddresses is true, search on unknow addresses
	 */
	getAutocomplete: (
		store,
		{ query, withPhone = false, allowUnknowAddresses: allowUnknowAddresses = false },
	) => {
		return actionStore.searchAddresses(query, withPhone, allowUnknowAddresses)
	},

	getEnveloppe: (store, { query = '', fields = [], polygon = [] }) => {
		/*
		 * The excluded_nevada option is used to avoid
		 * the display of an action in the US if a contact/s
		 * do not have a valid address according to the backend.
		 * The precision geohash, will be remove later.
		 * For the moment, the value 7 is provided.
		 * If you have an access to the Quorum Slack,
		 * you can take a look at the precision geohash
		 * values availables here :
		 * https://quorum-hq.slack.com/files/U04K5HY02/FV4U92Q8M/image.png
		 */

		return actionStore.getContactsLocationSummary(query, fields, polygon, 7, true).then((res) => {
			let enveloppe = {}
			let points = []
			if (Array.isArray(res.data[0].Map)) {
				points = res.data[0].Map.map((point) => turf.point(point.slice(0, 2).reverse())) || []
			}
			if (points.length) {
				enveloppe = turf.convex(turf.featureCollection(points))
			}
			return enveloppe
		})
	},

	actionSetBlockCitiesAndTerritories: ({ commit }, payload) => {
		commit(SET_ACTION_BLOCK_CITIES_TERRITORIES, payload)
	},

	actionSetResetActionModuleOnCloseModal: ({ commit }, payload) => {
		commit(SET_ACTION_RESET_ON_CLOSE_MODAL, payload)
	},

	resetState: ({ commit }) => {
		commit('HIDE_DETAIL')
		commit('STOP_LOADING')
		commit('SET_ENTRY_POINT')
		commit('SET_ACTIONS', [])
		commit('RESET_NEW_ACTION_STORE')
		commit('HAS_CREATED_ACTION')
		commit('EMPTY_ACTIVE_ACTION')
		commit('EMPTY_ACTION_TO_EDIT')
	},

	actionSetEntryPoint({ commit }, payload) {
		commit('SET_ENTRY_POINT', payload)
	},

	actionSetActiveAction({ commit }, payload) {
		commit('SET_ACTIVE_ACTION', payload)
	},
	actionDisplayDetail({ commit }) {
		commit('SET_DISPLAY_DETAIL')
	},
	actionSetFocusState({ commit }, payload) {
		commit('SET_FOCUS_STATE', payload)
	},

	actionUpdateActionEditionStatus: ({ commit }, boolean) => {
		commit('SET_IS_ACTION_CURRENTLY_EDITED', boolean)
	},
	actionUpdateForceEmptyAction: ({ commit }, boolean) => {
		commit(SET_FORCE_EMPTY_ACTION, boolean)
	},
	actionUpdateActionDetailFormValidState: ({ commit }, object) => {
		commit('SET_ACTION_DETAIL_LOCAL_COMPONENTS', object)
	},

	actionSetNewActionPolygon({ commit }, payload) {
		commit(SET_ACTION_NEW_SEARCH_POLYGON, payload)
	},
	actionSetNewActionTags({ commit }, payload) {
		commit(SET_ACTION_NEW_SEARCH_TAGS, payload)
	},

	actionSetNewAction({ commit }, payload) {
		commit('SET_NEW_ACTION', payload)
	},

	actionSetNewActionGlobalType({ commit }, payload) {
		commit('SET_NEW_ACTION_GLOBAL_TYPE', payload)
	},

	/**
	 * @func actionSetNewActionType
	 */
	actionSetNewActionType({ commit, state }, payload) {
		commit(SET_NEW_ACTION_TYPOLOGY, payload)
		if (payload === ACTION.TYPES.CALL) commit(SET_NEW_ACTION_STEPS, STEP_FILTER_WITHOUT_PRECISION)
		else if (payload === ACTION.TYPES.EVENT && state.newAction.globalType === 'online') commit(SET_NEW_ACTION_STEPS, MINIMAL_STEPS)
		else if (payload === ACTION.TYPES.OTHER && state.newAction.globalType === 'online') commit(SET_NEW_ACTION_STEPS, MINIMAL_STEPS)
		else if (payload === ACTION.TYPES.OTHER && state.newAction.globalType === 'offline') commit(SET_NEW_ACTION_STEPS, MINIMAL_STEPS_WITH_MEETING_POINT)
		else if (payload === ACTION.TYPES.SHARING) commit(SET_NEW_ACTION_STEPS, MINIMAL_STEPS_WITH_MESSAGES_TEMPLATES)
		else if (payload === ACTION.TYPES.GOTVCALLING) {
			commit(SET_NEW_ACTION_STEPS, STEP_GOTV_CALLING)
		}
		else if (payload === ACTION.TYPES.GOTVCANVASSING) {
			commit(SET_NEW_ACTION_STEPS, STEP_GOTV_CANVASSING)
		}
		else if (
			actionTypology.methods.searchOnBaseFunc(payload)
		) {
			if (actionTypology.methods.useMeetingPoint(payload)) {
				commit(SET_NEW_ACTION_STEPS, STEPS_WITH_PRECISION)
			}
			else {
				commit(SET_NEW_ACTION_STEPS, STEPS_WITH_PRECISION_WITHOUT_MEETING_POINT)
			}
		}
		else commit(SET_NEW_ACTION_STEPS, STEPS)
	},

	/**
	 * @func actionSetStepForPublicActions
	 */
	actionSetStepForPublicActions({ commit, state }, payload) {
		const stepsWithoutSelectMembers = state.steps.filter(
			(aStep) => aStep.route !== 'select_members',
		)
		commit(SET_NEW_ACTION_STEPS, stepsWithoutSelectMembers)
	},

	/**
	 * @func actionSetNewActionFilters
	 * @param {object|array} payload
	 * @desc The idea behind this function is to create a perfect
	 * filter for the new action without do direct mutation in the component
	 */
	actionSetNewActionFilters({ commit, state }, payload) {
		if (Array.isArray(payload)) commit(SET_ACTION_NEW_SEARCH_FILTERS, payload)
		else
			commit(
				SET_ACTION_NEW_SEARCH_FILTERS,
				createFilterFromParamsObject(state.newAction.search.filter, payload),
			)
	},

	actionSetNewAdvancedSearchFilters({ commit }, payload) {
		if (payload) commit(SET_ACTION_NEW_ADVANCED_SEARCH_FILTERS, payload)
	},

	actionResetAdvancedSearchFilters({ commit }) {
		commit(SET_ACTION_RESET_ADVANCED_SEARCH_FILTERS)
	},
	/**
	 * @func actionGenerateKpiFromAction
	 * @param {object|array} payload
	 * @desc We would like to use a local totalHits only for
	 * the action creation to don't modify the global total_hit
	 */
	actionGenerateKpiFromAction({ commit, state, rootGetters, dispatch }, payload = null) {
		contactStore
			.kpi(
				constructQueryFromStoreAdaptator(
					rootGetters,
					state.newAction.search.query,
					state.newAction.search.filter,
					state.newAction.search.polygon,
					state.newAction.search.tags_filter,
					state.newAction.search.duplicates_filter,
					formatOfFutureObjectForSearching(payload),
					9,
					!isEmpty(state.newAction.search.advanced_search)
						? state.newAction.search.advanced_search.build()
						: null,
				),
			)
			.then((res) => {
				const { kpi } = res
				if (kpi && kpi[0].KpiReplies !== undefined) {
					if (payload.absentOrNeverMetStatus && payload.absentOrNeverMetStatus === 'never') {
						commit(SET_NEW_ACTION_NEVER_MET_HIT, kpi[0].KpiReplies[0].Doc_count || 0)
					}
					else if (
						payload.absentOrNeverMetStatus
						&& payload.absentOrNeverMetStatus === 'absent'
					) {
						commit(SET_NEW_ACTION_ABSENT_HIT, kpi[0].KpiReplies[0].Doc_count || 0)
					}
					else {
						commit(SET_NEW_ACTION_TOTAL_HIT, kpi[0].KpiReplies[0].Doc_count || 0)
					}
					// commit(SET_NEW_ACTION_NEVER_MET_HIT, kpi[0].KpiReplies[0].Doc_count)
					// We test if the filter is the number results is set to 0.
					if (state.newAction.search.filter[2] === '0' && !state.newAction.search.advanced_search) {
						if (payload.absentOrNeverMetStatus && payload.absentOrNeverMetStatus === 'never') {
							dispatch('actionOverrideKpiNeverMetHit')
						}
						else if (
							payload.absentOrNeverMetStatus
							&& payload.absentOrNeverMetStatus === 'absent'
						) {
							dispatch('actionOverrideKpiAbsentHit')
						}
						else {
							dispatch('actionOverrideKpiTotalHit')
						}
					}
				}
				// 	if (state.newAction.search.filter[2] === '0' && !state.newAction.search.advanced_search)
				// 		dispatch('actionOverrideKpiTotalHit')
				// (state.forceEmptyAction === true
				// }
			})
	},

	/**
	 * @func actionOverrideKpiTotalHit
	 * @param {number} payload
	 * @desc We set new action total hit for action
	 * creation. ONLY USE IT when the total hit from KPI
	 * not provide 0.
	 */
	actionOverrideKpiTotalHit({ commit }, payload = 0) {
		commit(SET_NEW_ACTION_TOTAL_HIT, payload)
	},
	/**
	 * @func actionOverrideKpiNeverMetHit
	 * @param {number} payload
	 * @desc We set new action total hit for action
	 * creation. ONLY USE IT when the total hit from KPI
	 * not provide 0.
	 */
	actionOverrideKpiNeverMetHit({ commit }, payload = 0) {
		commit(SET_NEW_ACTION_NEVER_MET_HIT, payload)
	},
	/**
	 * @func actionOverrideKpiAbsentHit
	 * @param {number} payload
	 * @desc We set new action total hit for action
	 * creation. ONLY USE IT when the total hit from KPI
	 * not provide 0.
	 */
	actionOverrideKpiAbsentHit({ commit }, payload = 0) {
		commit(SET_NEW_ACTION_ABSENT_HIT, payload)
	},
	/**
	 * @func actionGenerateLocationSummaryGeohashFromAction
	 * @param {object|array} payload
	 * @desc We would like to use LocationSummaryGeohash locally
	 * to avoid unespected effects. For the moment, it's not totally local.
	 * because we commit on the global store.
	 */
	actionGenerateLocationSummaryGeohashFromAction({ commit, state, rootGetters }, payload = null) {
		contactStore
			.locationSummaryGeoHash(
				constructQueryFromStoreAdaptator(
					rootGetters,
					state.newAction.search.query,
					state.newAction.search.filter,
					state.newAction.search.polygon,
					state.newAction.search.tags_filter,
					state.newAction.search.duplicates_filter,
					formatOfFutureObjectForSearching(payload),
					8,
					!isEmpty(state.newAction.search.advanced_search)
						? state.newAction.search.advanced_search.build()
						: null,
				),
			)
			.then((res) => {
				commit('SET_LOCATIONS_SUMMARY_GEOHASH', res.contacts, { root: true })
				commit('SET_GEOHASH_ARRAY', res.data, { root: true })
			})
	},

	/**
	 * @func actionCreationState
	 */
	actionSetActionCreationState({ commit }, payload = null) {
		commit(SET_ACTION_CREATION_STATE, payload)
	},

	actionEditNewAddressesAction({ commit }, payload) {
		commit(SET_ACTION_NEW_ADDRESS, payload)
	},

	actionSetNewActionMeetingPoint({ commit }, payload) {
		commit(SET_NEW_ACTION_MEETING_POINT, payload)
	},

	actionResetActionMeetingPoint({ commit }) {
		commit(RESET_NEW_ACTION_MEETING_POINT)
	},

	actionSetNewActionOnlyTakeStreetsState({ commit }, payload) {
		commit(SET_NEW_ACTION_ONLY_TAKE_STREETS, payload)
	},

	actionEditNewActionObject({ commit }, payload) {
		commit(SET_NEW_ACTION_DATA_OBJECT_VALUE, payload)
	},

	actionAddNewActionBrief({ commit }, payload) {
		commit(SET_NEW_ACTION_DATA_BRIEF, payload)
	},

	actionHandleNewActionLeaders({ commit }, payload) {
		commit(HANDLE_ACTION_DATA_LEADERS, payload)
	},

	actionSetNewActionPrecision({ commit }, payload) {
		commit(SET_NEW_ACTION_PRECISION, payload)
	},

	/**
	 * @func actionSetNewActionSelectedNotPresentOrNotVisited
	 * @param {payload} Boolean allow and modify current new action filter
	 */
	actionSetNewActionSelectedNotPresentOrNotVisited(
		{ state, commit, dispatch, rootGetters },
		payload,
	) {
		let formattedQueryNotRefused,
			formattedQueryNotPresent = '',
			formattedQueryOnlyAbsent,
			formattedQueryOnlyNeverVisited

		// We need to access to the form presence
		// to get the values and use it in the filter
		const forms_presence = rootGetters['@form/getNamePresence']

		const { filter } = state.newAction.search

		// Always deep clone the filter to avoid bugs
		const deepCloneFilter = cloneDeep(filter)

		/**
		 * formatRequestAbsentRefusedValuesForQuery
		 * @desc based on form_presence, we will set
		 * the values in the component, to have a formatted
		 * string usable by the search in backend
		 */
		if (forms_presence.length) {
			const localFormPresence = forms_presence[0]
			formattedQueryNotRefused = [
				localFormPresence.type.toUpperCase(),
				localFormPresence.id,
				false,
				localFormPresence.refvalues.filter((refvalue) => refvalue.value === 'refus')[0].id,
			].join('/')

			formattedQueryOnlyAbsent = [
				localFormPresence.type.toUpperCase(),
				localFormPresence.id,
				true,
				localFormPresence.refvalues.filter((refvalue) => refvalue.value === 'absent')[0].id,
			].join('/')

			formattedQueryOnlyNeverVisited = [
				localFormPresence.type.toUpperCase(),
				localFormPresence.id,
				false,
				// localFormPresence.refvalues.filter((refvalue) => refvalue.value === 'absent')[0].id,
			].join('/')

			formattedQueryNotPresent = [
				localFormPresence.type.toUpperCase(),
				localFormPresence.id,
				false,
				// localFormPresence.refvalues.filter((refvalue) => refvalue.value === 'absent')[0].id,
			].join('/')

			formattedQueryNotPresent = [
				localFormPresence.type.toUpperCase(),
				localFormPresence.id,
				false,
				localFormPresence.refvalues.filter((refvalue) => refvalue.value === 'present')[0].id,
			].join('/')
		}

		/**
		 * If the payload is true, we want to add the specific answers
		 * to the current filter
		 */

		if (payload.absentOrNeverMetStatus === 'absent') {
			addAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyAbsent)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryNotPresent)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryNotRefused)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyNeverVisited)
			commit(SET_NEW_ACTION_DATA_OBJECT_VALUE, {
				key: 'isFilterAbsentOrNeverSeenSelected',
				value: 'absent',
			})
		}
		if (payload.absentOrNeverMetStatus === 'never') {
			addAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyNeverVisited)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyAbsent)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryNotPresent)
			commit(SET_NEW_ACTION_DATA_OBJECT_VALUE, {
				key: 'isFilterAbsentOrNeverSeenSelected',
				value: 'never',
			})
		}
		if (payload.absentOrNeverMetStatus === 'neverAndAbsent') {
			addAnswerToASearchFilter(deepCloneFilter, formattedQueryNotPresent)
			addAnswerToASearchFilter(deepCloneFilter, formattedQueryNotRefused)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyAbsent)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyNeverVisited)
			commit(SET_NEW_ACTION_DATA_OBJECT_VALUE, {
				key: 'isFilterAbsentOrNeverSeenSelected',
				value: 'neverAndAbsent',
			})
		}

		/**
		 * If the payload is false, we want to remove and do not add
		 * the specific answers to the filter
		 */
		if (payload.absentOrNeverMetStatus === 'all') {
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryNotPresent)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryNotRefused)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyNeverVisited)
			removeAnswerToASearchFilter(deepCloneFilter, formattedQueryOnlyAbsent)
			commit(SET_NEW_ACTION_DATA_OBJECT_VALUE, {
				key: 'isFilterAbsentOrNeverSeenSelected',
				value: 'all',
			})
		}

		/**
		 * Once my filter is set and commited, I would like
		 * to have the new kpis to see how much
		 * contacts are selected.
		 */
		dispatch('actionSetNewActionFilters', deepCloneFilter).then(() => {
			const {
				'@search/getSearchAddressIncluded': getSearchAddressIncluded,
				'@search/getSearchPollingStationsIncluded': getSearchPollingStationsIncluded,
			} = rootGetters

			if (payload.isForMountedDetectionValue) {
				dispatch('actionGenerateKpiFromAction', {
					addressIncluded: getSearchAddressIncluded,
					pollingStationsIncluded: getSearchPollingStationsIncluded,
					absentOrNeverMetStatus: payload.absentOrNeverMetStatus,
				})
			}
			else {
				dispatch('actionGenerateKpiFromAction', {
					addressIncluded: getSearchAddressIncluded,
					pollingStationsIncluded: getSearchPollingStationsIncluded,
				})
			}
		})
	},

	/**
	 * @func actionSetNewActionParamsFromType
	 * @desc This function is used in the precision step.
	 * Once is actived, we do specific changes based
	 * on the action type.
	 */
	actionSetNewActionParamsFromType({ dispatch, state }) {
		const { type } = state.newAction
		let paramsFilter = {}
		if (type === ACTION.TYPES.CALL) {
			/**
			 * If the action type is CALL, we want to disable the checkbox
			 * if is the filter for the mobile was allready SET or on ONLYMOBILE.
			 */
			let displayMobileCheckbox,
				displayPhoneCheckbox,
				phoneOnly = false
			switch (state.newAction.search.filter[11]) {
				case 'ONLYMOBILE':
					displayMobileCheckbox = true
					displayPhoneCheckbox = true
					phoneOnly = true
					break
				case 'SET':
				default:
					displayPhoneCheckbox = true
					phoneOnly = true
					break
			}

			// If the action type is CALL, we would like
			// to set the phone_filter
			if (displayPhoneCheckbox) {
				if (displayMobileCheckbox) paramsFilter.phone_filter = phoneOnly ? 'ONLYMOBILE' : ''
				else paramsFilter.phone_filter = phoneOnly ? 'SET' : ''
			}
			else {
				delete paramsFilter.phone_filter
			}
			dispatch('actionEditNewActionObject', {
				key: 'isFilterMobileOrPhoneSelected',
				value: {
					displayMobileCheckbox,
					displayPhoneCheckbox,
					phoneOnly,
				},
			})
		}
		else {
			/**
			 * Very important code. Do not delete it unti you understand the meaning
			 * It will set the nb_results depending of the current action
			 * selected.
			 */
			paramsFilter.nb_results = 100000

			if (actionTypology.methods.shouldCreateEmptyAction(type) === true) {
				paramsFilter.nb_results = 0
			}
		}

		const getOldFormFilterData = state.newAction.search.filter[12]
			? state.newAction.search.filter.slice(12, state.newAction.search.filter.length)
			: []
		if (getOldFormFilterData.length) {
			paramsFilter.form_filter_data = getOldFormFilterData
		}

		dispatch('actionSetNewActionFilters', paramsFilter)
	},

	/**
	 * @func actionResetNewActionFilterAndSearchSystem
	 * @desc This function is really important to clean
	 * in a proper way the action creation tunnel.
	 */
	actionResetNewActionFilterAndSearchSystem({ dispatch, state, commit }) {
		dispatch('actionSetResetActionModuleOnCloseModal')
		dispatch('@filter/actionResetFiltersAndPolygonForSearch', null, { root: true })

		const params = {
			sorting: 'surname',
			searchType: 'all',
			sexe_filter: '',
			email_filter: '',
			age_filter: '',
			bdv_filter: '',
			result_index: 0,
			query: '',
		}
		dispatch('@filter/actionSetFiltersForSearch', params, { root: true })
		dispatch('SEARCH_CONTACT', null, { root: true })
		commit(SET_CONTACTS_ACTION, [])
		dispatch(
			'@search/actionResetSearchModuleToInitialState',
			{ exceptAdvancedSearch: state.entryPoint === 'actions' ? false : true },
			{ root: true },
		)
		dispatch('actionResetFlow', state.needToBlockTargetCitiesAndTerritories)
		dispatch('actionSetResetActionModuleOnCloseModal', false)
	},

	actionSkipNavigationGuardBeforeRouteLeave({ commit }, payload) {
		commit(SET_SKIP_NAVIGATION_GUARD_BEFORE_ROUTE_LEAVE_ACTION_CREATION, payload)
	},
	actionReNotify({ commit, state }, payload) {
		commit(SET_STATUS_RENOTIFY, STATUS_RENOTIFY.REQUESTED)
		const timeout = setTimeout(() => commit(SET_STATUS_RENOTIFY, STATUS_RENOTIFY.FAILED), 3000)
		actionStore.renotify(payload).then((res) => {
			if (state.statusReNotify === STATUS_RENOTIFY.REQUESTED)
				if (res && res.accepted) commit(SET_STATUS_RENOTIFY, STATUS_RENOTIFY.SUCCESS)
				else commit(SET_STATUS_RENOTIFY, STATUS_RENOTIFY.FAILED)
			clearTimeout(timeout)
		})
	},

	actionSearchContact({ commit, state, rootGetters }, payload = null) {
		contactStore
			.find(
				constructQueryFromStoreAdaptator(
					rootGetters,
					state.newAction.search.query,
					state.newAction.search.filter,
					state.newAction.search.polygon,
					state.newAction.search.tags_filter,
					state.newAction.search.duplicates_filter,
					formatOfFutureObjectForSearching(payload),
					null,
					!isEmpty(state.newAction.search.advanced_search)
						? state.newAction.search.advanced_search.build()
						: null,
				),
			)
			.then((res) => {
				commit(SET_CONTACTS_ACTION, res)
			})
	},

	actionFetchContactActiontListFromAdvancedSearch({ commit, rootGetters }, payload) {
		const buildedQuery = {
			...constructQueryFromStore(rootGetters),
			advanced_search: payload,
		}
		contactRequester.find(buildedQuery).then((res) => {
			commit(SET_ACTION_CONTACT_LIST_ADVANCED_SEARCH, res)
		})
		contactRequester.locationSummaryGeoHash(buildedQuery).then((res) => {
			commit('SET_LOCATIONS_SUMMARY_GEOHASH', res.contacts, { root: true })
			commit('SET_GEOHASH_ARRAY', res.data, { root: true })
		})
		contactRequester.kpi(buildedQuery).then((res) => {
			commit(SET_ACTION_TOTAL_HIT, res.kpi[0].KpiReplies[0].Doc_count || 0)
		})
	},
	actionSetFilteredActions({ commit }, payload) {
		commit('SET_FILTERED_ACTIONS', payload)
	},

	actionSetActionStack({ commit }, payload) {
		if (payload) commit('START_LOADING')
		commit(SET_ACTION_STACK, payload)
		if (payload) {
			setTimeout(() => {
				commit('STOP_LOADING')
			}, 500)
		}
	},

	actionResetActionToNavigate({ commit }) {
		commit('NAVIGATE_TO_ACTION', null)
	},
}

const getters = {
	getActionCreationState: (state) => state.actionCreationState,
	actions: (state) => state.actions,
	getActions: (state) => state.actions,
	actionToEdit: (state) => state.actionToEdit,
	activeAction: (state) => state.activeAction,
	activeActionUsers: (state) => getAllUniqUsersFromAction(state.activeAction),
	activeActionUsersConfirmed: (state) => getAllUniqUsersFromAction(state.activeAction, true),
	activeStep: (state) => state.activeStep,
	entryPoint: (state) => state.entryPoint,
	showDetail: (state) => state.showDetail,
	filteredActions: (state) => state.filteredActions,
	isActionLoading: (state) => state.isActionLoading,
	isCreating: (state) => state.isCreating,
	getIsActionCurrentlyEdited: (state) => state.isActionCurrentlyEdited,
	getActionsDetailFormsValidations: (state) => state.actionDetailLocalComponents,
	getNewAction: (state) => state.newAction,
	newAction: (state) => state.newAction,
	steps: (state) => state.steps,
	getTotalHitsFound: (state) => state.totalHitsFound,
	getTotalNeverMetHitsFound: (state) => state.totalNeverMetHitsFound,
	getTotalAbsentHitsFound: (state) => state.totalAbsentHitsFound,
	getSkipBeforeRouteLeaveActionCreation: (state) => state.skipBeforeRouteLeaveActionCreation,
	getNeedToBlockTargetCitiesAndTerritories: (state) => state.needToBlockTargetCitiesAndTerritories,
	getResetActionModuleOnClose: (state) => state.resetActionModuleOnClose,
	getNewActionOnlyTakeStreetsState: (state) => state.newAction.onlyTakeStreetsForCreation,
	getNewActionIsFilterAbsentOrNeverSeenSelected: (state) =>
		state.newAction.isFilterAbsentOrNeverSeenSelected,
	getNewActionIsFilterNeverMet: (state) => state.newAction.isFilterNeverMet,
	getNewActionisFilterMobileOrPhoneSelected: (state) =>
		state.newAction.isFilterMobileOrPhoneSelected,
	getIsNewActionCreationShouldBeEmpty: (state) => state.newAction.search.filter[2] <= 0,
	getStatusReNotify: (state) => state.statusReNotify,
	getContactsAction: (state) => state.contactsAction,
	getActionContactList: (state) => state.actionContactList,
	getTotalActionHit: (state) => state.totalActionHitsFound,
	getActionsStack: (state) => state.actionsStack,
	getNewActionPolygon: (state) => state?.newAction?.search?.polygon,
	getNewActionLeaders: (state) => state?.newAction?.leaders,
	getNewActionLeadersMaxLength: (state) => state?.newAction?.leadersMaxLength,
	getNewActionAssignmentType: (state) => state?.newAction?.assignmentType,

	getActionToNavigate: (state) => state.actionToNavigate,
}

export const actionModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
