export const isSoonConstant = {
	ACTION_TYPE: {
		// OPEN_OFFICE_HOURS: {
		// 	color: '#958f8f2b',
		// 	label: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.OPEN_OFFICE_HOURS',
		// 	value: '',
		// 	image: '/images/actions/openOfficeHours.png',
		// },
		// PUBLIC_MEETING: {
		// 	color: '#958f8f2b',
		// 	label: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.PUBLIC_MEETING',
		// 	value: '',
		// 	image: '/images/actions/public_meeting.png',
		// },
	},
	TAB: {
		/// Liste des menus possible dans la web app ///
		FORM: {
			PETITION: {
				label: 'SURVEY.PETITION',
			},
		},
	},
	EXPORT_CONTACT_DETAILS: {
		color: '#958f8f2b',
		label: 'CONTACT.EDIT.EXPORT_CARD',
		value: '',
		image: '',
	},
	CREATE_MAIL_CONTACT_DETAILS: {
		color: '#958f8f2b',
		label: 'CONTACT.EDIT.CREATE_AN_EMAIL',
		value: '',
		image: '',
	},
	EMAIL_CONTACT_DETAILS: {
		color: '#958f8f2b',
		label: 'CONTACT.EDIT.ROUTE_EMAILS',
		value: '',
		image: '',
	},

	MENU: {},
}
