<template>
	<div class="auto-send-popover-preview flex flex-col">
		<div class="flex flex-col border border-gray-200 w-full rounded-lg">
			<div class="flex flex-col mt-2 pb-2 gap-2 border-b border-gray-200">
				<div class="px-5 flex items-start gap-2">
					<span class="text-gray-500">{{ $t('FORM.AUTO_SEND.PREVIEW.FROM') }}</span>
					<span class="flex flex-1 items-center gap-1"
						><p class="w-auto truncate" style="min-width: 0">
							<template v-if="autosend_sender">
								<span :title="computedGetSenderFormated">
									{{ `<${computedGetSenderFormated}>` }}
								</span>
							</template>
							<template v-else>
								<span :title="computedGetBasicSender">
									{{ `<${computedGetBasicSender}>` }}
								</span>
							</template>
						</p>
						<el-tooltip popper-class="rounded-xl" placement="top" :visible-arrow="false">
							<template #content>
								<span
									v-dompurify-html="$t('SETTINGS.AUTOSEND_CONF.MESSAGE_PREVIEW.NO_REPLY')"
								></span>
							</template>
							<span class="qi-infos"></span> </el-tooltip
					></span>
				</div>
				<span class="border-b border-gray-200 gap-2"></span>
				<div class="px-5 flex items-start gap-2">
					<span class="text-gray-500">{{ $t('FORM.AUTO_SEND.PREVIEW.SUBJECT') }}</span>
					<span class="flex flex-1 items-center gap-1"
						>{{ autosend_subject }}
						<el-tooltip popper-class="rounded-xl" placement="bottom" :visible-arrow="false">
							<template #content>
								<div>
									<span
										v-dompurify-html="$t('FORM.AUTO_SEND.PREVIEW.SUBJECT_TOOLTIP')"
										style="font-size: 12px"
									></span>
								</div>
							</template>
							<span class="qi-infos"></span>
						</el-tooltip>
					</span>
				</div>
			</div>
			<div class="px-5 flex flex-col mt-4 pb-4 gap-2">
				<div v-dompurify-html="autoSendHeaderFormated" class="auto-send-preview-header"></div>
				<div
					v-t="t.previous"
					class="auto-send-dummy-section bg-gray-100 rounded-lg px-3 py-2 w-fit my-1"
				></div>
				<div
					v-if="methodGetCharactersCount(message) > 0"
					v-dompurify-html="message"
					class="ql-editor p-0 rounded-lg px-3 py-2 w-fit"
				></div>
				<div v-else class="p-0 rounded-lg px-3 py-2 w-fit bg-gray-200">
					{{ $t('FORM.AUTO_SEND.PREVIEW.CONTENT') }}
				</div>
				<div
					v-t="t.next"
					class="auto-send-dummy-section auto-send-preview-section bg-gray-100 rounded-lg px-3 py-2 w-fit my-1"
				></div>
				<div v-dompurify-html="autoSendFooterFormated" class=""></div>
			</div>
		</div>
		<div v-if="userConnected?.role_data?.access?.includes('settings:delete')">
			<div
				v-if="
					methodGetCharactersCount(autoSendFooterFormated) > 0 &&
					methodGetCharactersCount(autoSendHeaderFormated) > 0
				"
				class="pt-5 flex justify-center"
			>
				<router-link :to="{ name: 'autosend-settings' }">
					<span
						class="flex items-center justify-center gap-2 bg-gray-100 px-4 py-1 rounded-lg w-full bold"
					>
						<i class="qi-cog"></i>
						<span>{{ $t('FORM.AUTO_SEND.PREVIEW.SETTINGS') }}</span>
					</span>
				</router-link>
			</div>
			<div v-else class="pt-5 flex justify-center">
				<router-link :to="{ name: 'autosend-settings' }">
					<span
						class="flex items-center justify-center gap-2 bg-yellow-100 px-4 py-1 rounded-lg w-full bold"
					>
						<i class="gui-warning"></i>
						<span>{{ $t('FORM.AUTO_SEND.PREVIEW.SETTINGS_WARNING') }}</span>
					</span>
				</router-link>
			</div>
		</div>
		<div
			v-else
			class="flex items-center justify-center gap-2 bg-gray-100 px-4 py-1 mt-5 rounded-lg w-full bold"
		>
			<span>
				{{ $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE_EMAIL') }}
			</span>
		</div>
		<!-- <div class="auto-send-email-container"></div> -->
	</div>
</template>

<script>
import { autosend } from '../../mixins/autosend-mixin.js'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'AutoSendPreview',

	mixins: [autosend],

	props: {
		message: { type: String, default: '' },
	},

	computed: {
		...mapGetters('@form', ['getActiveStaticKeysAvailables']),
		...mapGetters({
			info_campagne: 'info_campagne',
			autosend_header: 'autosend_header',
			autosend_footer: 'autosend_footer',
			autosend_subject: 'autosend_subject',
			autosend_sender: 'autosend_sender',
			userConnected: 'userConnected',
		}),

		t() {
			const prefix = 'FORM.AUTO_SEND.PREVIEW'
			return {
				previous: this.$t(`${prefix}.PREVIOUS`),
				next: this.$t(`${prefix}.NEXT`),
			}
		},

		computedGetVarLabels() {
			return this.getActiveStaticKeysAvailables
				.filter((key) => key.type === 'firstname' || key.type === 'surname')
				.map((resutl) => ({
					label: resutl.label,
					type: resutl.type,
				}))
		},

		computedGetFirstnameLabel() {
			const firstname = this.computedGetVarLabels.find((key) => key.type === 'firstname')
			return firstname
				? {
						label: firstname?.label,
						type: firstname?.type,
					}
				: {
						label: this.$t('SETTINGS.AUTOSEND_CONF.FIELD_DELETED'),
						type: 'deleted_field',
					}
		},

		computedGetSurnameLabel() {
			const surname = this.computedGetVarLabels.find((key) => key.type === 'surname')
			return surname
				? {
						label: surname?.label,
						type: surname?.type,
					}
				: {
						label: this.$t('SETTINGS.AUTOSEND_CONF.FIELD_DELETED'),
						type: 'deleted_field',
					}
		},

		autoSendHeaderFormated() {
			return this.autosend_header
				.replaceAll(
					'$firstname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetFirstnameLabel.type}" data-label="${this.computedGetFirstnameLabel.label}">${this.computedGetFirstnameLabel.label}</span>`
				)
				.replaceAll(
					'$surname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetSurnameLabel.type}" data-label="${this.computedGetSurnameLabel.label}">${this.computedGetSurnameLabel.label}</span>`
				)
		},

		autoSendFooterFormated() {
			return this.autosend_footer
				.replaceAll(
					'$firstname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetFirstnameLabel.type}" data-label="${this.computedGetFirstnameLabel.label}">${this.computedGetFirstnameLabel.label}</span>`
				)
				.replaceAll(
					'$surname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetSurnameLabel.type}" data-label="${this.computedGetSurnameLabel.label}">${this.computedGetSurnameLabel.label}</span>`
				)
		},

		computedGetBasicSender() {
			const stripEmojis = (campagneName) => {
				return campagneName
					.replace(
						/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
						''
					)
					.replace(/\s+-/g, '')
					.trim()
			}
			const campagne_name = stripEmojis(this.info_campagne.name.slice())

			return `no_reply_${campagne_name.replaceAll(' ', '_')}@qomon.app`
		},

		computedGetSenderFormated() {
			const stripEmojis = (email) => {
				return email
					.replace(
						/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
						''
					)
					.replace(/\s+-/g, '')
					.trim()
			}
			const email = stripEmojis(this.autosend_sender.slice())

			return `${email.replaceAll(' ', '_')}`
		},
	},

	mounted() {
		this.getAutosend()
	},

	methods: {
		...mapActions({
			getAutosend: 'SETUP_AUTOSEND',
		}),

		methodGetCharactersCount(htmlToCount) {
			return new DOMParser().parseFromString(htmlToCount, 'text/html').documentElement.textContent
				.length
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/form/auto-send/auto-send-preview.scss';

.mention {
	@apply bg-gray-100 text-pink-strong;
	border-radius: 0.5rem;
	padding: 0.2rem 0.4rem;
	box-decoration-break: clone;
}
</style>
