<template>
	<a
		:class="aggreggClass"
		@click="shouldEmit"
	>
		<span v-if="icon"><i :class="`${icon.includes('gui') ? icon: `qi-${icon}`}`" />&nbsp;<slot /></span>
		<slot v-else />
		<el-tooltip
			v-if="selectorContent && !disabled"
			placement="top"
			:content="selectorContent.tooltipMessage"
		>
			<el-dropdown
				trigger="click"
				@command="handleCommand"
			>
				<span
					class="hover:bg-pink-300 flex items-center justify-center absolute -top-3.5 -right-6 px-1 rounded-full"
					@click="handleClick"
				>
					<i class="gui-arrow_down text-white text-xl" />
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="option_one">{{ selectorContent.option_one }}</el-dropdown-item>
						<el-dropdown-item command="option_two">{{ selectorContent.option_two }}</el-dropdown-item>
						<el-dropdown-item command="option_three">{{
							selectorContent.option_three
						}}</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</el-tooltip>
	</a>
</template>

<script>
/* eslint-disable */
export default {
	props: {
		link: {
			type: Boolean,
			default: false,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		info: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		warning: {
			type: Boolean,
			default: false,
		},
		danger: {
			type: Boolean,
			default: false,
		},
		ghost: {
			type: Boolean,
			default: false,
		},
		square: {
			type: Boolean,
			default: true,
		},
		small: {
			type: Boolean,
			default: false,
		},
		noLinkBorderBottom: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: '',
		},
		customClass: {
			type: String,
			default: '',
		},
		selectorContent: {
			type: Object,
			default: () => null,
		},
	},

	computed: {
		aggreggClass() {
			if (this.link) {
				return (
					(this.secondary ? 'q-link secondary' : 'q-link') +
					' ' +
					(this.noLinkBorderBottom ? 'no-link-border-bottom' : '') +
					' ' +
					(this.disabled ? 'disabled' : '') +
					' ' +
					(this.customClass ? this.customClass : '')
				)
			}
			return [
				'q-button',
				this.small ? 'small' : '',
				this.ghost ? 'ghost' : '',
				this.square ? 'square' : '',
				this.disabled ? 'disabled' : '',
				this.customClass,
				this.danger
					? 'danger'
					: this.warning
					? 'warning'
					: this.success
					? 'success'
					: this.info
					? 'info'
					: '',
			].join(' ')
		},
	},

	emits: ['click'],

	methods: {
		shouldEmit() {
			if (!this.disabled) {
				this.$emit('click')
			}
		},

		handleClick(event) {
			event.stopPropagation()
			event.preventDefault()
		},

		handleCommand(command) {
			this.$emit('newCommand', command)
		},
	},
}
</script>
