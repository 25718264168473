const modulesInstancesMixin = {
	props: {
		instanciedModuleId: {
			type: String,
			default: '',
		},
	},

	methods: {
		getInstanceRegisteredByModule(moduleName, instanciedModuleId) {
			if (!instanciedModuleId || instanciedModuleId.length === 0) {
				return moduleName
			}
			return `${moduleName}-${instanciedModuleId}`
		},
	},
}

export default modulesInstancesMixin
