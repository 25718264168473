<template>
	<div v-if="getIsMessageBirdEnabled">
		<div class="bg-white pb-5">
			<sms-originator class="border-t-4 border-pink-lighted"></sms-originator>
			<div class="border-t-4 border-pink-lighted px-7 py-7">
				<h2 class="text-sm text-gray-dark uppercase mb-3">
					{{ $t('SETTINGS.SMS.PARAMETERS_SECTION_TITLE') }}
				</h2>
				<span class="flex items-center gap-3">
					<i class="gui-infos color-part-one color-part-two text-lg"></i>
					<span class="text-gray-dark text-sm">{{
						$t('SETTINGS.SMS.PARAMETERS_SECTION_HELPER')
					}}</span>
				</span>
			</div>
			<sms-parameters-selector
				v-for="(parameter, index) in computedGetParameters"
				:key="index"
				:parameter="parameter"
				:is-support-button="!parameter.isActive"
				:trigger-intercom-with-pre-populated-message="() => openChat"
				@changeIsActiveState="changeIsActiveState"
			></sms-parameters-selector>
		</div>
	</div>
	<div v-else>
		<container-packer
			access="dashboard_sms"
			main
			small-padding
			:handler-main="openChat"
		></container-packer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openChat } from 'src/extensions/support'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		SmsOriginator: defineAsyncComponent(() => import('./SmsOriginator.vue')),
		SmsParametersSelector: defineAsyncComponent(() => import('./sms-parameters-selector.vue')),
		ContainerPacker: defineAsyncComponent(() => import('../../packs/containerPacker.vue')),
	},

	computed: {
		...mapGetters(['getIsMessageBirdEnabled', 'getDoubleOptInAccess']),
		...mapGetters('@packs', ['getAccessStatusSurveyAutoSms']),

		computedGetParameters() {
			return [
				{
					id: 0,
					icon: `<i class="gui-message" style="font-size: 2rem"></i>`,
					content: {
						title: this.$t('SETTINGS.SMS.FEATURE_LIST.SEND_SMS.TITLE'),
						text: this.$t('SETTINGS.SMS.FEATURE_LIST.SEND_SMS.TEXT'),
					},
					isActive: this.getIsMessageBirdEnabled,
					isSupportButton: !this.getIsMessageBirdEnabled,
				},
				{
					id: 1,
					icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.3337 29.3333H5.33366V2.66667H21.3337V9.33333H24.0003V2.66667C24.0003 1.19467 22.8057 0 21.3337 0H5.33366C3.86166 0 2.66699 1.19467 2.66699 2.66667V29.3333C2.66699 30.8053 3.86166 32 5.33366 32H21.3337C22.8057 32 24.0003 30.8053 24.0003 29.3333V25.3333H21.3337V29.3333ZM10.667 4C9.93061 4 9.33366 4.59695 9.33366 5.33333C9.33366 6.06971 9.93061 6.66667 10.667 6.66667H16.0003C16.7367 6.66667 17.3337 6.06971 17.3337 5.33333C17.3337 4.59695 16.7367 4 16.0003 4H10.667ZM12.0003 26.6667C12.0003 25.9303 12.5973 25.3333 13.3337 25.3333C14.07 25.3333 14.667 25.9303 14.667 26.6667C14.667 27.403 14.07 28 13.3337 28C12.5973 28 12.0003 27.403 12.0003 26.6667Z" fill="#222230"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.249 17.8911L21.4957 22.4911C21.957 22.8951 22.665 22.5578 22.665 21.9324L22.665 18.6671L29.333 18.6671L29.333 16.0004L22.665 16.0004L22.665 12.7338C22.665 12.1098 21.957 11.7711 21.4957 12.1751L16.249 16.7751C15.9157 17.0671 15.9157 17.5991 16.249 17.8911" fill="#05B66F"/></svg>`,
					content: {
						title: this.$t('SETTINGS.SMS.FEATURE_LIST.SMS_AUTO.TITLE'),
						text: this.$t('SETTINGS.SMS.FEATURE_LIST.SMS_AUTO.TEXT'),
					},
					isActive: this.getAccessStatusSurveyAutoSms === 'enabled',
					isSupportButton: this.getAccessStatusSurveyAutoSms !== 'enabled',
				},
				{
					id: 2,
					icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_8542_10030)"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.3337 29.3333H5.33366V2.66667H21.3337V9.33333H24.0003V2.66667C24.0003 1.19467 22.8057 0 21.3337 0H5.33366C3.86166 0 2.66699 1.19467 2.66699 2.66667V29.3333C2.66699 30.8053 3.86166 32 5.33366 32H21.3337C22.8057 32 24.0003 30.8053 24.0003 29.3333V25.3333H21.3337V29.3333ZM10.667 4C9.93061 4 9.33366 4.59695 9.33366 5.33333C9.33366 6.06971 9.93061 6.66667 10.667 6.66667H16.0003C16.7367 6.66667 17.3337 6.06971 17.3337 5.33333C17.3337 4.59695 16.7367 4 16.0003 4H10.667ZM12.0003 26.6667C12.0003 25.9303 12.5973 25.3333 13.3337 25.3333C14.07 25.3333 14.667 25.9303 14.667 26.6667C14.667 27.403 14.07 28 13.3337 28C12.5973 28 12.0003 27.403 12.0003 26.6667Z" fill="#222230"/><path fill-rule="evenodd" clip-rule="evenodd" d="M28.0499 15.9388L24.161 19.8277C24.0101 19.9786 23.8102 20.0556 23.6111 20.0556C23.4314 20.0556 23.251 19.9933 23.1048 19.8681L20.3826 17.5348C20.0567 17.2548 20.0186 16.7648 20.2986 16.4389C20.5786 16.1115 21.0693 16.0741 21.3952 16.3541L23.5707 18.2185L26.9501 14.839C27.2542 14.5349 27.7458 14.5349 28.0499 14.839C28.354 15.1431 28.354 15.6347 28.0499 15.9388ZM24 10.3333C20.1337 10.3333 17 13.467 17 17.3333C17 21.1997 20.1337 24.3333 24 24.3333C27.8663 24.3333 31 21.1997 31 17.3333C31 13.467 27.8663 10.3333 24 10.3333Z" fill="#05B66F"/></g><defs><clipPath id="clip0_8542_10030"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>`,
					content: {
						title: this.$t('SETTINGS.SMS.FEATURE_LIST.DOUBLE_OPT_IN.TITLE'),
						text: this.$t('SETTINGS.SMS.FEATURE_LIST.DOUBLE_OPT_IN.TEXT'),
					},
					isActive: this.getIsMessageBirdEnabled && this.getDoubleOptInAccess,
					isSupportButton: !this.getIsMessageBirdEnabled || !this.getDoubleOptInAccess,
				},
			]
		},
	},

	methods: {
		openChat,

		changeIsActiveState(id) {
			const parameter = this.parameters.find((parameter) => parameter.id === id)
			parameter.isActive = !parameter.isActive
		},
	},
}
</script>

<style></style>
