<template>
	<component :is="tag" v-if="computedEnabled">
		<slot></slot>
	</component>
	<component
		:is="tag"
		v-else-if="computedLocked"
		class="box-border w-full h-full"
		@click.stop.prevent="methodPromotedDisplayAlert"
	>
		<span class="opacity-50"
			><span v-if="!noIcon" class="mr-1"><i class="gui-lock relative top-0.5"></i></span
			><slot></slot
		></span>
	</component>
	<component :is="tag" v-else-if="computedPromoted" class="box-border w-full h-full">
		<span v-if="badge" :class="noOpacity ? 'mb-1' : 'opacity-50 hover:opacity-100 mb-1'"
			><slot></slot><badge :xl="xl" :type="badge"></badge
		></span>
		<span v-else class="opacity-50 flex"
			><span v-if="!noIcon" class="mr-1"><i class="gui-lock relative top-0.5"></i></span
			><slot></slot
		></span>
	</component>
</template>

<script>
import { defineAsyncComponent, h } from 'vue'

export default {
	components: {
		Badge: defineAsyncComponent(() => import('./badge')),
	},
	props: {
		status: {
			type: String,
			required: true,
		},
		noIcon: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
			default: 'div',
			required: false,
		},
		badge: {
			type: String,
			default: '',
		},
		noOpacity: {
			type: Boolean,
			default: false,
		},
		xl: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		computedEnabled() {
			return this.status === 'enabled'
		},
		computedPromoted() {
			return this.status === 'promoted'
		},
		computedLocked() {
			return this.status === 'locked'
		},
		computedDisabled() {
			return this.status === 'disabled'
		},
		computedHidden() {
			return this.status === 'hidden'
		},
	},

	methods: {
		methodPromotedDisplayAlert() {
			this.$message({
				message: h('div', { class: 'pl-4 pr-10' }, [
					h('span', { class: 'bold text-sm' }, this.$t('_COMMON.PACKS.FEATURE_LOCKED')),
					h('br'),
					h(
						'span',
						{ class: 'bold text-sm' },
						this.$t('_COMMON.PACKS.FEATURE_LOCKED_CONTACT_TEAM')
					),
				]),
				iconClass: 'qi-lock text-pink-main text-xl',
				showClose: true,
				customClass: 'bg-gray-100',
			})
		},
	},
}
</script>

<style></style>
