<template>
	<el-dialog
		id="dialogCustom"
		v-model="computedVisibleDialog"
		:title="title"
		class="custom-dialog-class pt-0-body pb-0-body"
		:append-to-body="appendToBody"
		@close="closeDialog"
	>
		<hr class="border-0 border-b">
		<slot />

		<div
			class="flex flex-row"
			:class="tooltip ? 'justify-between' : 'justify-end'"
		>
			<div v-if="tooltip">
				<el-tooltip
					:disabled="!tooltip.tooltipContent"
					class="item pt-5"
					effect="dark"
					placement="bottom-start"
					popper-class="custom-tooltip"
				>
					<template #content>
						<div>
							<div>
								<span v-if="tooltip.tooltipContent">{{ tooltip.tooltipContent }}</span>
								<span v-else />
							</div>
						</div>
					</template>
					<div class="cursor-pointer text-pink-main">
						<div
							class="flex items-center gap-1"
							@click="tooltip.targetExternalLink ? openInNewTab(tooltip.targetExternalLink) : ''"
						>
							<i
								v-if="tooltip.icon"
								:class="tooltip.icon"
							/>
							<span v-if="tooltip.desc">
								{{ tooltip.desc }}
							</span>
						</div>
					</div>
				</el-tooltip>
			</div>

			<div class="flex justify-end custom_dialog items-center">
				<p
					class="pr-4 cursor-pointer"
					@click="cancel()"
				>
					{{ $t(cancelCta) }}
				</p>
				<!-- <el-button type="primary" @click="cancel()">{{ $t(cancelCta) }} </el-button> -->
				<a
					class="q-button square"
					:class="disabledConfirm ? 'disabled' : ''"
					@click="confirm()"
				>{{
					confirmLabel.length ? confirmLabel : $t(confirmCta)
				}}</a>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { openChat } from '/src/extensions/support'

/**
 * @component Users
 * @description Display all the users list
 *
 * @fires  {@link component:Users~change change}
 *
 * @deprecated file used some old functions, such as
 * `setFilter`,
 * `addSort`,
 * `handlePagination`,
 * `changeOrder`,
 * `export_data`.
 *
 * See at the end of the file
 */
export default {
	name: 'DeleteDialog',

	props: {
		confirm: {
			type: Function,
			default: () => {},
			// required: false,
		},
		cancel: {
			type: Function,
			default: () => {},
			// required: false,
		},
		visibleDialog: {
			type: Boolean,
			default: false,
		},
		closeDialog: {
			type: Function,
			default: () => {},
		},
		tooltip: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		confirmCta: {
			type: String,
			default: '_COMMON.DELETE',
		},
		cancelCta: {
			type: String,
			default: '_COMMON.CANCEL',
		},

		confirmLabel: {
			type: String,
			default: '',
		},
		appendToBody: {
			type: Boolean,
			default: false,
		},
		isIntercomHelp: {
			type: Boolean,
			default: false,
		},
		disabledConfirm: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visibleDialog'],
	data() {
		return {}
	},

	computed: {
		computedVisibleDialog: {
			get() {
				return this.visibleDialog
			},
			set(value) {
				return this.$emit('update:visibleDialog', value)
			},
		},
	},
	methods: {
		openInNewTab(url) {
			if (isIntercomHelp) {
				this.methodOpenChat()
				return
			}
			window.open(url, '_blank', 'noreferrer')
		},

		methodOpenChat() {
			openChat()
		},
	},
}
</script>

<style lang="sass">

#dialogCustom.custom-dialog-class
	border-radius: 20px!important
	padding: 2rem
	.el-dialog__header
		padding-top: 0px
	.el-dialog__headerbtn
		display: none
	@media (min-width: 1201px)
		max-width: 45vw!important

	@media (max-width: 1200px)
		width: 70%!important

	@media (max-width: 768px)
		width: 100%!important
</style>

<style lang="scss">
@import '../../../../assets/scss/structure/grid.scss';
@import '../../../../assets/dequorum/style/variables.sass';

.custom-tooltip {
	width: 200px !important;
}
.custom-tooltip .popper__arrow {
	left: 30px !important;
}
.custom-tooltip .el-tooltip__popper {
	max-width: 100px;
}
#user-container {
	padding: $spacing;
}

.infoholder {
	// height: 1.5em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.individus {
	.q-card {
		.subtitle {
			display: flex;
			width: fit-content;
		}
	}
}

// WATCH OUT ! Basically the same css is used in
// command center / leaflet
.avatar {
	.avatar-default,
	img {
		height: 100%;
		width: 100%;
		border-radius: 50%;
		margin-top: 0.5em;
	}
	.avatar-default {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1em;
		padding: 0.2em;
	}
}
</style>
