<template>
	<validation-field
		v-slot="{ field, errors, meta }"
		v-model="computedValue"
		:name="inputData.name"
		:rules="{ required: inputData.isRequired }"
		as="div"
		:class="`relative ${inputData.customCss || ''}`"
	>
		<div class="w-full flex justify-between items-center">
			<label
				:for="inputData.name"
				class="mb-1"
				:class="{
					bold: inputData.black,
					'text-pink-main text sm': !inputData.black,
					'text-gray-medium': inputData.isDisabled,
				}"
			>
				{{ inputData.label }}
			</label>

			<el-select
				v-if="inputData.importance && !inputData.isDisabled"
				:model-value="mandatory ? 'mandatory' : hidden ? 'hidden' : 'optionnal'"
				class="select-importance ml-auto w-32"
				style="min-width: 128px"
				@change="changeFormInputPriority"
			>
				<div v-for="option in formOptions" :key="option.value">
					<el-option
						v-if="
							option.value !== 'optionnal' ||
								(option.value === 'optionnal' &&
									['consent_email', 'consent_mobile'].includes(inputData.name))
						"
						:label="
							option.value === 'mandatory' && inputData.name === 'privacy_policy'
								? $t('CREATE_FORM.VISIBLE')
								: option.value === 'hidden' && inputData.name === 'privacy_policy'
									? $t('CREATE_FORM.HIDDEN2')
									: option.label
						"
						:value="option.value"
						class="text-sm flex items-center gap-x-2 justify-between"
					>
						<span style="float: left">
							{{
								option.value === 'mandatory' && inputData.name === 'privacy_policy'
									? $t('CREATE_FORM.VISIBLE')
									: option.value === 'hidden' && inputData.name === 'privacy_policy'
										? $t('CREATE_FORM.HIDDEN2')
										: option.label
							}}
						</span>
						<i
							v-if="
								(option.value === 'mandatory' && mandatory) ||
									(option.value === 'hidden' && hidden) ||
									(option.value === 'optionnal' && !mandatory && !hidden)
							"
							class="gui-yes text-lg"
						/>
					</el-option>
				</div>
			</el-select>
		</div>

		<p v-if="inputData.desc" class="mt-2 mb-3">{{ inputData.desc }}</p>

		<div class="flex flex-row relative">

			<el-input
				v-if="inputData.type !== 'date'"
				v-bind="field"
				v-model="computedValue"
				:placeholder="inputData.placeholder"
				:type="inputData.type ? inputData.type : 'text'"
				:name="inputData.name"
				:maxlength="inputData.wordLimit"
				:min="0"
				:max="1000000000"
				:show-word-limit="true"
				:autosize="true"
				resize="none"
				:disabled="inputData.isDisabled"
				class="form-input"
				:class="{ 'form-invalid-input': errors.length }"
			>
			</el-input>

			<el-date-picker
				v-else
				v-bind="field"
				v-model="computedValue"
				type="date"
				:placeholder="inputData.placeholder"
				:name="inputData.name"
				:shortcuts="pickerOptions.shortcuts"
				:disabled-date="pickerOptions.disabledDate"
				:cell-class-name="pickerOptions.cellClassName"
				:format="methodFormatDatePicker()"
				value-format="YYYY-MM-DD"
				class="form-date-input"
				:class="{ 'form-invalid-input': errors.length }"
			>
			</el-date-picker>
			<!-- :format="$i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'" -->
		</div>

		<div v-if="inputData.resetFunction" class="text-right">
			<span
				class="text-xs text-gray-600 hover:opacity-75 cursor-pointer"
				@click="() => inputData.resetFunction()"
			>{{ $t('_COMMON.RESET') }}</span
			>
		</div>

		<transition name="fade-in" mode="out-in">
			<p
				v-if="errors.length && meta.touched"
				class="absolute flex text-sm text-gray-strong top-auto -bottom-11 pb-6 mt-2 whitespace-nowrap"
			>
				<i class="gui-no text-gray-dark mr-1" style="margin-top: 3px" />
				{{ errors[0] }}
			</p>
		</transition>

		<transition name="fade-in" mode="out-in">
			<p
				v-if="
					!inputData.isDisabled &&
						((mandatory && inputData.mandatoryAlert) || (hidden && inputData.hiddenAlert))
				"
				class="relative flex text-sm text-red-500 mt-2"
			>
				<i class="gui-infos flex text-2xl text-red-500 -mt-1 mr-1" />
				{{ mandatory ? inputData.mandatoryAlert : inputData.hiddenAlert }}
			</p>
		</transition>
	</validation-field>
</template>

<script>
import { Field as ValidationField } from 'vee-validate'
import { getDisplayDateFromLocalStorage } from '../../../utils/dateFnsHelper'

export default {
	components: {
		ValidationField,
	},
	props: {
		inputData: {
			type: Object,
			default() {
				return {
					type: '',
					name: '',
					label: '',
					desc: '',
					placeholder: '',
					customCss: '',
					isRequired: false,
					resetFunction: null,
				}
			},
			required: true,
		},
		value: { type: [String, Number, Date], default: '', required: false },
		mandatory: { type: Boolean, default: false, required: false },
		hidden: { type: Boolean, default: false, required: false },
	},
	emits: ['update:value', 'update:mandatory', 'update:hidden'],

	data() {
		return {
			formOptions: [
				{ value: 'mandatory', label: this.$t('_COMMON.MANDATORY') },
				{ value: 'optionnal', label: this.$t('CREATE_FORM.OPTIONAL') },
				{ value: 'hidden', label: this.$t('CREATE_FORM.HIDDEN') },
			],
			pickerOptions: {
				disabledDate(time) {
					return time?.getTime() < Date.now()
				},
			},
		}
	},

	computed: {
		computedValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('update:value', val)
			},
		},
		computedMandatory: {
			get() {
				return this.mandatory
			},
			set(val) {
				this.$emit('update:mandatory', val)
			},
		},
		computedHidden: {
			get() {
				return this.hidden
			},
			set(val) {
				this.$emit('update:hidden', val)
			},
		},
	},

	methods: {

		methodFormatDatePicker() {
			return getDisplayDateFromLocalStorage().toUpperCase()
		},

		changeFormInputPriority(priority) {
			switch (priority) {
				case 'mandatory':
					this.computedMandatory = true
					this.computedHidden = false
					break
				case 'hidden':
					this.computedMandatory = false
					this.computedHidden = true
					break
				case 'optionnal':
					this.computedMandatory = false
					this.computedHidden = false
					break
				default:
					break
			}
		},
	},
}
</script>

<style>
.form-input .el-input__wrapper,
.form-date-input .el-input__wrapper {
	height: 40px;
}

.form-input input::-webkit-outer-spin-button,
.form-input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.form-input input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}

.form-input .el-input__count {
	position: relative !important;
	top: unset !important;
	bottom: -32px !important;
	margin-left: auto;
	color: #72727b !important;
	max-width: max-content;
}

.form-input.el-textarea .el-input__count {
	bottom: -4px !important;
}

.form-input .el-input__count-inner {
	background-color: transparent !important;
}

.right-placeholder .form-input input.el-input__inner::placeholder {
	position: absolute;
	top: 10px;
	right: 40px;
}

.params-bar .form-date-input {
	width: 100%;
}

.params-bar .select-importance .el-select__wrapper {
	@apply shadow-none hover:shadow-none;
}
.params-bar .select-importance .el-select__wrapper .el-select__placeholder {
	@apply text-sm;
}

.params-bar .select-importance .el-input__wrapper,
.params-bar .select-importance .el-input.is-disabled .el-input__wrapper {
	@apply text-sm shadow-none hover:shadow-none;
}
.params-bar .select-importance .el-input__wrapper.is-focus {
	box-shadow: none !important;
}

.params-bar .el-textarea__inner {
	min-height: 99px !important;
}
</style>
