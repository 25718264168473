<template>
	<div class="rounded-lg bg-gray-200 py-2">
		<div class="flex flex-row">
			<div
				v-if="disabledLabel"
				:key="1"
				:class="{ selected: !toggled && activable }"
				class="toogle pointer"
				@click="methodClickToggle(false, activable)"
			>
				{{ disabledLabel }}
			</div>
			<div
				v-if="enabledLabel"
				:key="2"
				:class="{ selected: toggled && activable }"
				class="toogle pointer"
				@click="methodClickToggle(true, activable)"
			>
				{{ enabledLabel }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		activable: {
			type: Boolean,
			default: false,
		},
		toggled: {
			type: Boolean,
			default: false,
			required: true,
		},
		disabledLabel: {
			type: String,
			default: '',
			required: true,
		},
		enabledLabel: {
			type: String,
			default: '',
			required: true,
		},
	},

	emits: ['update:activable', 'update:toggled'],

	methods: {
		methodClickToggle(toggled, activable) {
			switch (toggled) {
				case false:
					if (activable && this.toggled === false) {
						this.$emit('update:activable', false)
					} else {
						this.$emit('update:activable', true)
					}
					this.$emit('update:toggled', toggled)
					break

				case true:
					if (activable && this.toggled === true) {
						this.$emit('update:activable', false)
					} else {
						this.$emit('update:activable', true)
					}
					this.$emit('update:toggled', toggled)
					break

				default:
					break
			}
		},
	},
}
</script>

<style lang="sass" scoped>
@keyframes slidein
	from
		transform: translateX(0.2rem)
	50%
		transform: translateX(-0.2rem)
	to
		transform: translateX(0rem)

.toogle
	transition: all 0.5s slidein
	@apply rounded-lg py-2 px-4 mx-2 text-sm text-gray-600
	&.selected
		@apply shadow text-black
		background-color: white
		animation-duration: 0.7s
		animation-name: slidein
	&:hover
		opacity: 0.7
</style>
