<template>
	<div id="welcome-container" class="welcome-container">
		<el-dialog
			top="7rem"
			append-to-body
			:title="$t('CREATE_FORM.LINK_MODAL.TITLE')"
			:model-value="true"
			:width="dialogWidth"
			:show-close="false"
			class="px-5"
		>
			<template #header>
				<div class="flex flex-row justify-between w-full items-center">
					<span style="font-size: 21px" class="font-title text-xl">
						{{ $t('WELCOME.MODAL_TITLE') }}
					</span>
					<span>
						{{ computedCurrentStep }}
					</span>
				</div>
				<el-divider class="mt-3"></el-divider>
			</template>

			<form name="welcome-form" @submit="methodNext">
				<component
					:is="computedGetComponentNameByStep"
					v-model:value="computedValue"
					@updateValidation="methodUpdateValidation"
				/>

				<div
					class="dialog-footer flex mt-20"
					:class="step !== 0 ? ' justify-between' : ' justify-end'"
				>
					<button
						v-if="step !== 0"
						type="button"
						class="flex items-center gap-1 cursor-pointer"
						:disabled="isWaitingRes"
						@click="methodBack"
					>
						<i class="gui-back text-2xl mt-0.5"></i>
						{{ $t('_COMMON.PREVIOUS') }}
					</button>

					<button
						class="q-button font-bold w-40"
						:class="{
							'ghost disabled': !computedValue || computedValue.length === 0 || isWaitingRes,
						}"
						:disabled="!computedValue || computedValue.length === 0 || isWaitingRes"
						type="submit"
					>
						{{ step < 2 ? $t('_COMMON.NEXT') : $t('_COMMON.FINISH_SPACE_SETTINGS') }}
					</button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ActionZone: defineAsyncComponent(() => import('./actionZone.vue')),
		TeamSize: defineAsyncComponent(() => import('./teamSize.vue')),
		ValuableFeatures: defineAsyncComponent(() => import('./valuableFeatures.vue')),
	},
	data() {
		return {
			component: ['ActionZone', 'TeamSize', 'ValuableFeatures'],
			step: 0,
			validStep: true,
			dialogWidth: '',
			data: {
				ActionZone: null,
				TeamSize: null,
				ValuableFeatures: null,
			},
			isWaitingRes: false,
		}
	},

	computed: {
		...mapGetters('@welcome', [
			'getStatusMapRootLabel',
			'getStatusOrganisationSize',
			'getStatusFeaturesOfInterest',
		]),

		computedValue: {
			get() {
				return this.data[this.computedGetComponentNameByStep]
			},
			set(newValue) {
				this.data[this.computedGetComponentNameByStep] = newValue
			},
		},

		computedGetComponentNameByStep() {
			return this.component[this.step]
		},

		computedAllGettersStatus() {
			return [
				this.getStatusMapRootLabel,
				this.getStatusOrganisationSize,
				this.getStatusFeaturesOfInterest,
			]
		},

		computedCurrentStep() {
			return `${this.step + 1} / ${this.computedMaxStep}`
		},

		computedMaxStep() {
			return 3
		},
	},

	watch: {
		getStatusMapRootLabel() {
			this.methodHandleStatusSuccessOrError()
		},

		getStatusOrganisationSize() {
			this.methodHandleStatusSuccessOrError()
		},

		getStatusFeaturesOfInterest() {
			this.methodHandleStatusSuccessOrError()
		},
	},

	mounted() {
		this.methodGiveWidthWithGetScreen()
		window.addEventListener('resize', this.methodGiveWidthWithGetScreen)
	},
	unmounted() {
		window.removeEventListener('resize', this.methodGiveWidthWithGetScreen)
	},

	methods: {
		...mapActions('@settings', ['actionSetCampaignInfos']),
		...mapActions('@welcome', [
			'actionSetMapRootLabel',
			'actionSetOrganisationSize',
			'actionSetFeaturesOfInterest',
		]),

		methodUpdateValue(key, value) {
			this[key] = value
		},

		methodUpdateValidation(newValue) {
			this.validStep = newValue
		},

		methodBack() {
			if (this.step !== 0) this.step--
		},

		methodNext(e) {
			e.preventDefault()

			if (this.step === 2 && this.validStep && !this.isWaitingRes) {
				this.isWaitingRes = true

				this.actionSetMapRootLabel({ group: { data_map_root_label: this.data.ActionZone } })
				this.actionSetOrganisationSize({ organisation_size: this.data.TeamSize })
				this.actionSetFeaturesOfInterest({ features_of_interest: this.data.ValuableFeatures })
			}

			if (this.step !== 2 && this.validStep && !this.isWaitingRes) {
				this.step++
			}
		},

		methodGiveWidthWithGetScreen() {
			const screenSize = window.innerWidth

			if (screenSize < 800) {
				this.dialogWidth = '90%'
			}
			if (screenSize > 800) {
				this.dialogWidth = '60%'
			}
			if (screenSize > 1100) {
				this.dialogWidth = '50%'
			}
			if (screenSize > 1300) {
				this.dialogWidth = '37%'
			}
			if (screenSize > 1600) {
				this.dialogWidth = '40%'
			}
			if (screenSize > 1850) {
				this.dialogWidth = '35%'
			}
		},

		methodHandleStatusSuccessOrError() {
			if (this.computedAllGettersStatus.every((status) => status === 'success')) {
				this.actionSetCampaignInfos()
				this.$router.push({ name: 'command-center' })
			}

			if (this.computedAllGettersStatus.includes('error')) {
				this.$message({
					message: this.$t('PETITIONS.ERROR.GENERAL'),
					duration: 3000,
					type: 'error',
					showClose: true,
				})
				this.isWaitingRes = false
			}
		},
	},
}
</script>

<style lang="scss">
.welcome-container {
	position: absolute;
	z-index: 501; // to display above the navbar arrow
	width: 100vw;
	height: 100vh;
	inset: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
