<template>
	<div class="q-modal overflow-hidden" :class="{ 'is-active': actuallyActive }">
		<div class="overlay" @click="resetAction"></div>
		<div
			class="q-modal-content rounded-xl"
			:class="{
				'md:w-1/2 w-5/6': !large && !small,
				'md:w-2/3 w-5/6': large,
				'md:w-1/3 w-5/6': !large && small,
				spread: spread,
			}"
			:style="'overflow:' + overflow + '; height:' + height"
		>
			<div class="q-modal-close q-p-t qi-close qi-2x" @click="resetAction"></div>
			<div class="q-card-top">
				<div class="content">
					<slot name="title"></slot>
				</div>
			</div>
			<div class="q-card-content">
				<slot name="content"></slot>
			</div>
			<div v-if="$slots.footer" class="q-card-bottom" style="margin-top: 20px">
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',

	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		resetAction: {
			type: Function,
			default: function () {
				this.actuallyActive = false
			},
		},
		large: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		spread: {
			type: Boolean,
			default: false,
		},
		overflow: {
			type: String,
			default: 'visible',
		},
		height: {
			type: String,
			default: 'auto',
		},
	},

	data() {
		return { actuallyActive: false }
	},

	watch: {
		isActive(value) {
			this.actuallyActive = value
		},
	},

	mounted() {
		this.actuallyActive = this.isActive
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/dequorum/style/variables.sass';
@import '../../assets/dequorum/style/general/inherited_variables.sass';

.q-modal-content {
	@apply p-4;
	max-height: calc(95vh - 20px);

	.q-card-top {
		background: $background;
	}
}
</style>
