<template>
	<popover class="auto-send-help-popover-container" @closePopover="closePopover()">
		<template #popover-content>
			<div class="auto-send-help-popover">
				<i
					class="fa fa-times close-icon icon icon-clickable"
					aria-hidden="true"
					@click="closePopover"
				></i>
				<div class="auto-send-help-header">{{ t.title }}</div>
				<div class="auto-send-help-body">
					<div class="auto-send-help-content-container">
						<div
							class="auto-send-help-page-control-container"
							:class="{ inactive: helpIndex == 0 }"
							@click="decrementIndex()"
						>
							<div class="auto-send-help-page-control">
								<i class="fa fa-chevron-left icon icon-move-page"></i>
							</div>
						</div>
						<div class="auto-send-help-text">{{ t.contents[helpIndex] }}</div>
						<div
							class="auto-send-help-page-control-container"
							:class="{ inactive: helpIndex == t.contents.length - 1 }"
							@click="incrementIndex()"
						>
							<div class="auto-send-help-page-control">
								<i class="fa fa-chevron-right icon icon-move-page"></i>
							</div>
						</div>
					</div>
					<div class="auto-send-help-page-view">
						<div
							v-for="(text, index) in t.contents"
							:key="text.id"
							class="page-dot"
							:class="{ selected: helpIndex == index }"
							@click="helpIndex = index"
						></div>
					</div>
				</div>
			</div>
		</template>
	</popover>
</template>

<script>
import $ from 'jquery'
import Popover from '../../general/popover.vue'
import { nextTick } from 'vue'

export default {
	components: {
		Popover,
	},
	emits: ['closePopover'],

	data: function () {
		return {
			helpIndex: 0,
		}
	},

	computed: {
		t() {
			const prefix = 'FORM.AUTO_SEND.HELP'
			return {
				title: this.$t(`${prefix}.TITLE`),
				contents: [
					this.$t(`${prefix}.CONTENT1`),
					this.$t(`${prefix}.CONTENT2`),
					this.$t(`${prefix}.CONTENT3`),
				],
			}
		},
	},

	mounted: function () {
		this.fixFormatting()
	},

	methods: {
		fixFormatting: function () {
			// don't want the height changing as user's go through the help, so fix the height of the body to the max height of all the pages
			this.fixBodyHeight(0, 0)
		},

		// this function fixes the height of auto-send-help-body to the max height of any of its possible contents
		// in order to find the max height, must wait for the nextTick so that the rendered index's height can be measured
		// so, use a recursive function that should be called with fixBodyHeight(0, 0).
		fixBodyHeight: function (index, maxHeight) {
			this.helpIndex = index
			nextTick(() => {
				maxHeight = Math.max(maxHeight, $('.auto-send-help-body').height())
				index++
				if (index < this.t.contents.length) {
					this.fixBodyHeight(index, maxHeight)
				} else {
					this.helpIndex = 0
					$('.auto-send-help-body').height(maxHeight)
				}
			})
		},

		closePopover: function () {
			this.$emit('closePopover')
		},

		decrementIndex: function () {
			this.helpIndex = Math.max(0, this.helpIndex - 1)
		},

		incrementIndex: function () {
			this.helpIndex = Math.min(this.t.contents.length - 1, this.helpIndex + 1)
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/scss/form/auto-send/auto-send-help.scss';
</style>
