<template>
	<div class="section">
		<div class="section-title">
			{{ $t('SETTINGS.PROFILE.AVAILABILITY.TITLE') }}
		</div>
		<selector-profile
			v-for="(avail, index) in availibilitiesStrings"
			:key="avail"
			:value="methodCheckForAnAvailability(avail)"
			:string-value="avail"
			:bg-color="availabilitiesBgColor[index]"
			:disabled="computedIsDisabled"
			:title="mappingWording[avail]"
			is-checkbox
			@change="methodAvailabilityChange"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectorProfile from './selector-profile.vue'
import { cloneDeep, isEqual } from 'lodash'

export default {
	components: {
		SelectorProfile,
	},
	props: {
		saveChange: {
			type: Number,
			required: true,
		},
	},
	emits: ['unSaveChange'],
	data() {
		return {
			availabilities: '',

			message: undefined,

			availibilitiesStrings: [
				'available_week_morning',
				'available_week_night',
				'available_weekend',
				'available_holidays',
				'available_all_the_time',
			],

			availabilitiesBgColor: ['#FFF8FC', '#ECEDFD', '#E1F7EB', '#FBF8F6', '#E5F8FF'],

			mappingWording: {
				available_week_night: this.$t('SETTINGS.PROFILE.AVAILABILITY.WEEK_NIGHT'),
				available_weekend: this.$t('SETTINGS.PROFILE.AVAILABILITY.WEEKEND'),
				available_week_morning: this.$t('SETTINGS.PROFILE.AVAILABILITY.MORNING'),
				available_holidays: this.$t('SETTINGS.PROFILE.AVAILABILITY.HOLIDAYS'),
				available_all_the_time: this.$t('SETTINGS.PROFILE.AVAILABILITY.ALL_THE_TIME'),
			},

			allDisabled: false,
		}
	},
	computed: {
		...mapGetters(['userConnected']),

		computedIsDisabled() {
			return this.allDisabled
		},
	},
	watch: {
		saveChange: {
			handler() {
				const user = cloneDeep(this.userConnected)

				let statusValue = !this.availabilities ? 'not_set' : this.availabilities
				user.status = statusValue

				// Update in the store the user connected to see the update before the next refresh
				this.actionSetUserConnected(user)
				this.updateUser(user)
					.then(() => {
						this.message = this.$message({
							message: this.$t('SETTINGS.PROFILE.MESSAGE.UPDATE_SUCCESS'),
							type: 'success',
							showClose: true,
						})
						this.$emit('unSaveChange', false)
					})
					.catch(() => {
						this.message = this.$message({
							type: 'error',
							duration: 5000,
							showClose: true,
							center: true,
							message: this.$t('SETTINGS.PROFILE.AVAILABILITY.MESSAGE_ERROR'),
						})
					})
			},
		},
	},
	async mounted() {
		this.availabilities = this.userConnected?.status || ''
		if (this.userConnected.status?.includes('available_all_the_time')) this.allDisabled = true
	},
	methods: {
		...mapActions('@user', ['updateUser']),
		...mapActions(['actionSetUserConnected']),

		methodCheckForAnAvailability(avail) {
			const index = this.availabilities.split(',').findIndex((item) => item === avail)
			return index !== -1
		},

		methodStringConstructor(obj) {
			let availibilitiesArray = this.availabilities === '' ? [] : this.availabilities.split(',')
			if (obj.value === 'available_all_the_time') {
				if (this.allDisabled) {
					this.allDisabled = false
					availibilitiesArray = []
					return availibilitiesArray.join(',')
				}
				this.allDisabled = true
				availibilitiesArray = []
				availibilitiesArray.push(obj.value)
				return availibilitiesArray.join(',')
			}
			const index = availibilitiesArray.findIndex((item) => item === obj.value)
			if (index !== -1) {
				availibilitiesArray.splice(index, 1)
				return availibilitiesArray.join(',')
			}
			availibilitiesArray.push(obj.value)
			if (availibilitiesArray.length === 1) {
				return availibilitiesArray.join()
			}
			return availibilitiesArray.join(',')
		},

		methodAvailabilityChange(event) {
			if (this.message !== undefined) this.message.close()
			this.availabilities = this.methodStringConstructor(event)
			const isUnsaveChange = !isEqual(this.availabilities, this.userConnected.status)
			this.$emit('unSaveChange', isUnsaveChange)
		},
	},
}
</script>

<style lang="sass" scoped>
.section
    @apply mt-1 px-8 pt-8 pb-4 bg-white
    .section-title
        @apply text-sm text-gray-dark uppercase mb-6
</style>
