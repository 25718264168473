/**
 * @store @issueCenter
 */
import Module from './ModuleFactory'
import contactStore from '../../models/contact_store'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/unionBy'
import {
	constructQueryFromStoreAdaptator,
	formatOfFutureObjectForSearching,
} from '../modules/utils/searchTools'
import { SET_TASKS, RESET_STATE, SET_IS_LOADING_TASKS } from './_mutation-types'

const fieldsBuilder = (groupId, pageSize, page, questions, withEmail = false) => {
	return [
		groupId.toString(),
		'all',
		pageSize.toString(),
		page.toString(),
		'',
		'',
		'',
		'lastchange',
		'false',
		'',
		withEmail ? 'SET' : '',
		questions.toString(),
	]
}

const getDefaultState = () => {
	return {
		isLoadingSupport: false /** @member {Boolean} isLoadingSupport */,
		isLoadingTasks: false /** @member {Boolean} isLoadingTasks */,

		numberOfSupport: 0 /** @member {Number} numberOfSupport */,
		numberOfTasks: 0 /** @member {Number} numberOfTasks */,

		support: [] /** @member {Array} support */,
		tasks: [] /** @member {Array} tasks */,

		supportPage: 0 /** @member {Number} [supportPage=1]*/,
		supportPageSize: 15 /** @member {Number} [supportPageSize=15]*/,
		tasksPage: 0 /** @member {Number} [tasksPage=1]*/,
		tasksPageSize: 100000 /** @member {Number} [tasksPageSize=100000]*/,
	}
}

const state = getDefaultState

const mutations = {
	[RESET_STATE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	START_LOADING(state) {
		state.isLoadingTasks = true
		state.isLoadingSupport = true
	},

	[SET_TASKS]: (state, payload) => {
		state.tasks = payload
	},

	[SET_IS_LOADING_TASKS]: (state, payload) => {
		state.isLoadingTasks = payload
	},
}

const actions = {
	resetState({ commit }) {
		commit(RESET_STATE)
	},
	/**
	 * @action getTasks
	 * @desc return the array of tasks
	 */
	async actionGetTasks({ commit, rootState, rootGetters }, payload = {}) {
		let actionForm = rootState['@form'].action.map((form) => {
			if (form.refvalues) {
				const formId = form.id
				const formType = form.type
				const foundARefValueHere = form.refvalues.map((aRefValue) => {
					if (aRefValue.id)
						return {
							...aRefValue,
							formId,
							formType,
						}
					return false
				})
				if (foundARefValueHere) return foundARefValueHere
			}
			return false
		})
		actionForm = flatten(actionForm)
		const promiseQueue = []
		/* eslint-disable */
		const groupId = rootState.userConnected.selected_group_id
		const { tasksPageSize, tasksPage } = state
		const { withEmail } = payload
		/* eslint-enable */
		commit('SET_IS_LOADING_TASKS', true)
		if (actionForm) {
			// const actionRef = actionForm.refvalues.find(ref => ref.value === 'Action à mener')
			let finalContacts = []
			for await (const aRefValue of actionForm) {
				const query = [aRefValue.formType, aRefValue.formId, 'TRUE', aRefValue.id].join('/')
				const fields = fieldsBuilder(0, 10000, 0, query)
				const result = await contactStore.find(
					constructQueryFromStoreAdaptator(
						rootGetters,
						'',
						fields,
						[],
						[],
						false,
						formatOfFutureObjectForSearching(payload)
					)
				)
				finalContacts.push(result)
			}
			finalContacts = uniqBy(flatten(finalContacts), 'id')
			commit('SET_TASKS', finalContacts)
		}
		Promise.all(promiseQueue).finally(() => {
			commit('SET_IS_LOADING_TASKS', false)
		})
	},

	/**
	 * @action getTotalByRef
	 * @param {Refvalue} payload the refvalue to work on
	 * @desc will fetch KPI for a specific question
	 * @returns {Number}
	 */
	async getTotalByRef({ rootState }, payload = {}) {
		const { form_id: formId, id: refId } = payload
		const { selected_group_id: groupId } = rootState.userConnected
		const query = ['RADIO', formId, 'TRUE', refId].join('/')
		const fields = fieldsBuilder(groupId, 10000, 0, query)
		const result = await contactStore.kpi('', fields)
		const number = result.kpi[0].KpiReplies.find((reply) => reply.Key === 'total').Doc_count

		return number
	},

	actionSetTasks: ({ commit }, tasks) => {
		commit('SET_TASKS', tasks)
	},
}

const getters = {
	getTasks: (state) => state.tasks,
	getIsLoadingTasks: (state) => state.isLoadingTasks,
}

export const issueCenter = new Module({
	state,
	mutations,
	actions,
	getters,
})
