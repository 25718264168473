<template>
	<div class="shimmer">
		<span class="slot"><slot></slot></span>
		<div class="shimmered"></div>
	</div>
</template>

<style scoped>
.slot {
	position: absolute;
	width: 100%;
	text-align: center;
	top: -0.2em;
	font-size: 0.75em;
}
</style>
