import { cloneDeep } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

const mixinOnlineFormsMethods = {
	computed: {
		...mapGetters('@onlineForm', ['getOnlineFormsSearch']),
	},
	methods: {
		...mapActions('@onlineForm', ['actionSetOnlineFormsSearchLength']),

		mixinMethodFilterOnlineForms(form) {
			let cloneForm = cloneDeep(form)

			const sortByDate = (data = []) =>
				data.sort((a, b) => {
					const dateA = new Date(a.UpdatedAt)
					const dateB = new Date(b.UpdatedAt)
					return dateB - dateA
				})

			let resultOrderByDate = sortByDate(cloneForm)

			if (resultOrderByDate && resultOrderByDate.length) {
				let archivedForms = resultOrderByDate.filter((f) => f.archived)
				let filteredForms

				let filteredBy = cloneDeep(this.getOnlineFormsSearch.categories)
				if (filteredBy.includes('draft')) {
					filteredBy.push('failedPublish', 'publishing')
				}

				if (filteredBy.includes('published')) {
					filteredBy.push('failedUnpublish', 'unpublishing')
				}

				if (
					this.getOnlineFormsSearch.categories.length > 0 &&
					this.getOnlineFormsSearch.categories[0] !== 'all'
				) {
					filteredForms = resultOrderByDate
						.filter((f) => filteredBy.includes(f.state))
						.filter((f) => !f.archived)

					if (filteredBy.includes('archived')) {
						filteredForms = [...filteredForms, ...archivedForms]
					}
				} else {
					filteredForms = resultOrderByDate.filter((f) => !f.archived)
					filteredForms = [...filteredForms, ...archivedForms]
				}

				if (this.getOnlineFormsSearch.text.length) {
					filteredForms = filteredForms.filter((f) =>
						f.title?.toLowerCase().includes(this.getOnlineFormsSearch.text.toLowerCase())
					)
				}

				return filteredForms
			}
		},
	},
}

export default mixinOnlineFormsMethods
