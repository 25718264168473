import api_key_store from '../../models/api_key_store'
import {
	RESET_API_KEY_STORE,
	SET_API_KEYS,
	SET_STATUS_POST_API_KEY,
	SET_STATUS_DELETE_API_KEY,
} from './_mutation-types'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'
/**
 * @store @apiKey
 * @namespaced
 * @description Api keys related info and store module
 */

const getDefaultState = () => {
	return {
		apiKeys: [],
		statusPostApiKey: 'init',
		statusDeleteApiKey: 'init',
	}
}
const state = getDefaultState

const mutations = {
	[RESET_API_KEY_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_API_KEYS]: (state, res) => {
		state.apiKeys = res
	},
	[SET_STATUS_POST_API_KEY]: (state, res) => {
		state.statusPostApiKey = res
	},
	[SET_STATUS_DELETE_API_KEY]: (state, res) => {
		state.statusDeleteApiKey = res
	},
}

const actions = {
	actionResetApiKey({ commit }) {
		commit(RESET_API_KEY_STORE)
	},

	actionRetrieveApiKeysList: ({ commit }) => {
		return api_key_store
			.getListApiKeys()
			.then((res) => {
				commit(SET_API_KEYS, res)
			})
			.catch(() => {})
	},

	actionAddApiKey: ({ commit, state }, payload) => {
		commit(SET_STATUS_POST_API_KEY, 'init')
		commit(SET_STATUS_POST_API_KEY, 'loading')

		return api_key_store
			.addApiKey(payload)
			.then((res) => {
				commit(SET_STATUS_POST_API_KEY, 'success')
				// Add api key to the list
				let clonedApiKeys = cloneDeep(state?.apiKeys)
				clonedApiKeys.push(res)
				commit(SET_API_KEYS, clonedApiKeys)
			})
			.catch(({ status }) => {
				if (status == 401) {
					commit(SET_STATUS_POST_API_KEY, 'passwordError')
				}
				else {
					commit(SET_STATUS_POST_API_KEY, 'error')
				}
			})
	},

	actionDeleteApiKey: ({ commit, state }, accessToken) => {
		commit(SET_STATUS_DELETE_API_KEY, 'loading')

		return api_key_store
			.deleteApiKey(accessToken)
			.then(() => {
				commit(SET_STATUS_DELETE_API_KEY, 'success')
				// Remove api key from the list
				let clonedApiKeys = cloneDeep(state?.apiKeys)
				remove(clonedApiKeys, (apiKey) => apiKey?.access_token === accessToken)
				commit(SET_API_KEYS, clonedApiKeys)
			})
			.catch(() => {
				commit(SET_STATUS_DELETE_API_KEY, 'error')
			})
	},
}

const getters = {
	getApiKeys: (state) => state.apiKeys,
	getStatusPostApiKey: (state) => state.statusPostApiKey,
	getStatusDeleteApiKey: (state) => state.statusDeleteApiKey,
}

export const apiKeyModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
