import { $request } from './requester.ts'
import { transactionSerializer } from './transactionSerializer.ts'

export default {
	async getTransactions(limit, offset) {
		return $request('GET', `/transactions?limit=${limit}&offset=${offset}`).then((res: any) => {
			if (res.body?.data) return res.body
			return []
		}).catch((err: Error) => {
			throw new Error(err)
		})
	},

	async postSearchTransactions(data, limit, offset) {
		return $request('POST', `/search/transactions?limit=${limit}&offset=${offset}`, data).then(
			(res: any) => {
				if (res.body?.data || res.body?.data === null) return res.body
				return []
			},
		).catch((err: Error) => {
			throw new Error(err)
		})
	},

	async patchTransaction(id: number, data) {
		return $request('PATCH', `/transactions/${id}`, data).then((res: any) => res.body.data)
	},

	async deleteTransaction(id) {
		return $request('DELETE', `/transactions/${id}`).then((res: any) => {
			return res.body.data
		})
	},
	async deleteTransactions(ids) {},

	async postCreateTransaction(data: TransactionItem) {
		const serizalizedTransaction = transactionSerializer(data)
		return $request('POST', '/transaction_bundles', serizalizedTransaction).then(
			(res: any) => res.body.data,
		)
	},

	async patchTransactionBundle(id: number, data: TransactionItem) {
		const serizalizedTransaction = transactionSerializer(data)

		return $request('PATCH', `/transaction_bundles/${id}`, serizalizedTransaction).then(
			(res: any) => res.body.data,
		)
	},

	async deleteTransactionBundle(id: number) {
		return $request('DELETE', `/transaction_bundles/${id}`).then((res: any) => res.body.data)
	},

	async getTransactionBundleId(id: number) {
		return $request('GET', `/transaction_bundles/${id}`).then((res: any) => {
			return res.body.data
		})
	},

	async getTransactionsSettings() {
		return $request('GET', `/transaction_settings`).then((res: any) => {
			return res.body.data
		})
	},

	async getTransactionsStatuses() {
		return $request('GET', `/transaction_statuses`).then((res: any) => {
			return res.body.data
		})
	},

	async getTransactionsStatusesMotifs() {
		return $request('GET', `/transaction_status_motifs`).then((res: any) => {
			return res.body.data
		})
	},

	async getTransactionHistoryById(id: number) {
		return $request('GET', `/transaction_bundles/${id}/history`).then((res: any) => {
			return res.body.data
		})
	},

	deleteMembershipOrDonation(id: number, type: string) {
		return $request('DELETE', `/${type}/${id}`)
	},

	getMembershipGlobalInfos() {
		return $request('GET', `/membership_global_infos`).then((res) => res.body.data)
	},
}
