<template>
	<div class="flex flex-col" :style="`width: ${width}`">
		<div class="text-sm text-pink-main mb-1 whitespace-pre">{{ title }}</div>

		<validation-field
			v-slot="{ field, errors, meta }"
			v-model="computedValue"
			:name="name"
			:rules="rules"
		>
			<div class="relative flex items-center">
				<slot>
					<el-input
						v-bind="field"
						v-model="computedValue"
						:disabled="disabled"
						:placeholder="placeholder"
						class="editable-label-input"
						:type="showPassword && secret ? 'text' : secret ? 'password' : 'text'"
					>
						<template v-if="secret" #suffix>
							<div class="secret-icon" @click="showPassword = !showPassword">
								<img
									class="w-6 pr-2"
									:src="
										showPassword
											? '/static/images/svg/open_eye.svg'
											: '/static/images/svg/closed_eye.svg'
									"
								/>
							</div>
						</template>
					</el-input>
				</slot>

				<i
					v-if="checkIcon && meta.valid && meta.touched"
					class="gui-yes text-lg absolute -right-8 my-auto"
				/>
			</div>

			<div style="min-height: 1.5rem" class="mt-2">
				<transition v-if="errors.length && meta.touched" name="fade-in" mode="out-in">
					<div class="flex items-center gap-1 text-sm text-gray-dark">
						<i class="gui-no text-gray-dark" />
						<span>{{ errors[0] }}</span>
					</div>
				</transition>
			</div>
		</validation-field>
	</div>
</template>

<script>
import { Field as ValidationField } from 'vee-validate'
import '/src/utils/veeValidate'

export default {
	components: { ValidationField },
	props: {
		value: {
			type: String,
			required: false,
			default: '',
		},
		title: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			default: '500px',
		},
		name: {
			type: String,
			required: false,
			default: '',
		},
		rules: {
			type: [String, Object],
			required: false,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		secret: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		checkIcon: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:value'],
	data() {
		return {
			showPassword: false,
		}
	},
	computed: {
		computedValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('update:value', value)
			},
		},
	},
}
</script>

<style lang="scss">
.editable-label-input {
	&.el-select input.el-input__inner,
	&.el-input input.el-input__inner {
		border-radius: 10px;
	}
}
.secret-icon {
	height: 40px;
	line-height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
</style>
