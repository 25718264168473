<template>
	<mobile class="sticky top-24 mt-20">
		<template #header>
			<div class="q-row q-p-lg bg-green-main text-white unset space-between q-fullwidth">
				<div class="q-v-spacer q-fullwidth"></div>
				<div class="qi-back qi-2x" @click="previousIndex"></div>
				<h3 class="no-m">{{ index + 1 }}/{{ formSortedByOrder.length }}</h3>
				<div class="qi-close qi-2x"></div>
			</div>
		</template>
		<template v-if="currentQuestion">
			<div class="q-p">
				<div class="q-row unset">
					<div class="qi-document qi-2x q-m-r"></div>
					<span>{{ t.showQuestions }}</span>
				</div>
				<div v-if="currentQuestion.filterbyidrefvalue" class="q-m-t q-row q-bg-warning">
					<div class="qi-filters"></div>
					<small>{{ t.onlyIf }}</small>
				</div>
				<h3 class="mt-4 px-2">{{ currentQuestion.label }}</h3>
				<div class="q-v-spacer"></div>

				<template v-if="currentQuestion.type === 'checkbox'">
					<div class="q-fullwidth">
						<div class="flex flex-wrap gap-2">
							<div
								v-for="ref in currentQuestion.refvalues"
								:key="ref.id"
								class="q-is-primary border border-pink-main rounded-lg flex justify-center items-center"
								style="width: calc(50% - 8px); height: 77px"
							>
								<div
									class="p-2 text-sm text-center overflow-ellipsis overflow-hidden vertical-truncate"
								>
									{{ ref.label }}
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else-if="currentQuestion.type === 'range'">
					<div class="q-m-lg">
						<el-slider
							:max="currentQuestion.refvalues[0].max"
							:min="currentQuestion.refvalues[0].min"
							:step="currentQuestion.refvalues[0].step"
						></el-slider>
					</div>
				</template>
				<template v-else-if="currentQuestion.type === 'radio'">
					<div v-for="ref in currentQuestion.refvalues" :key="ref.id" class="q-m-lg">
						<el-radio class="radios-mobile" :label="ref.label"></el-radio>
						<hr />
					</div>
				</template>
				<template v-else-if="currentQuestion.type === 'signature'">
					<div class="w-full bg-gray-100 h-80 flex justify-center items-center rounded-lg">
						<img
							class="w-1/2"
							src="../../../../static/images/signature_exemple.png"
							alt="signature exemple"
						/>
					</div>
				</template>
				<template v-else-if="currentQuestion.type === 'photos'">
					<div class="w-full flex justify-center items-center">
						<img
							class="w-full rounded-lg"
							src="../../../../static/images/photo-placeholder.jpg"
							alt="picture exemple"
						/>
					</div>
				</template>
				<template v-else>
					<el-input placeholder=""></el-input>
				</template>
			</div>
		</template>
		<template #footer>
			<div class="center q-fullwidth">
				<q-button class="q-m-lg" ghost @click="nextIndex">{{ t.nextQuestion }}</q-button>
			</div>
		</template>
	</mobile>
</template>
<script>
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import mobile from '../mobile-container.vue'
import _ from 'lodash'

export default {
	components: { mobile },

	props: {
		forms: {
			default: () => [],
		},
	},

	data() {
		return { index: 0 }
	},

	computed: {
		...mapGetters('@form', ['form', 'formCond']),

		t() {
			const prefix = 'SETTINGS.FORM_BUILDER'
			return {
				nextQuestion: this.$t(`${prefix}.NEXT_QUESTION`),
				showQuestions: this.$t(`${prefix}.SHOW_QUESTIONS`),

				onlyIf: this.$t(`${prefix}.ONLY_IF`, {
					answer: this.getAnswerLabel(this.currentQuestion),
				}),
			}
		},

		formSortedByOrder() {
			return _.cloneDeep(this.forms.length ? this.forms : this.form.concat(this.formCond)).sort(
				firstBy('order')
			)
		},

		currentQuestion() {
			return this.formSortedByOrder[this.index]
		},
	},

	methods: {
		previousIndex() {
			if (this.index > 0) {
				this.index--
			}
		},

		nextIndex() {
			if (this.index < this.formSortedByOrder.length - 1) {
				this.index++
			}
		},

		getAnswerLabel(question) {
			if (question) {
				const { filterbyidrefvalue } = question
				const ref = this.formSortedByOrder
					.flatMap((question) => question.refvalues)
					.filter((ref) => ref)
					.find((ref) => ref.id === filterbyidrefvalue)
				if (ref) {
					return ref.label
				}
			}
			return 'N/A'
		},
	},
}
</script>
<style lang="sass">
.q-hide-scroll-content.q-container.q-p.q-col
	justify-content: flex-start
label.radios-mobile
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	width: 100%
</style>
<style lang="scss" scoped>
.vertical-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
</style>
