<template>
	<action-creation-helper-container
		less-padding-at-top-and-bottom
		:header-height="headerHeight"
	>
		<div class="h-full w-full flex items-center justify-center relative">
			<div class="flex flex-col items-center justify-center rounded-2xl w-full xs:w-10/12 sm:w-8/12 md:w-5/12 2xl:w-5/12 gap-10">
				<div class="flex w-full justify-start items-center">
					<div class="flex w-full justify-start items-center gap-4">
						<div class="w-11 h-11 rounded-full flex items-center justify-center bg-gray-100">
							<i class="gui-pen text-2xl" />
						</div>
						<span class="font-title text-3xl">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_TITLE') }}</span>
					</div>
				</div>
				<div class="flex flex-col gap-3 w-full">
					<label
						v-t="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_LABEL')"
						class="bold"
					/>
					<span class="text-gray-strong">{{ t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_HELPER') }}</span>
				</div>
				<div
					id="action_message"
					class="flex flex-col w-full border border-gray-200 rounded-2xl overflow-hidden pt-1"
				>
					<el-input
						v-model="fromVuexCommentAction"
						type="textarea"
						:autosize="{ minRows: 4 }"
						:placeholder="t('COMMUNICATIONS.GLOBAL_NOTIFICATION.PLACEHOLDER_BODY_NOTIFICATION')"
					/>
					<el-divider class="my-0 w-[96%] mx-auto" />

					<div class="flex gap-3 items-center py-2 px-4">
						<el-tooltip
							popper-class="rounded-xl"
							:show-arrow="false"
							placement="bottom"
							:content="t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_SEE_TEMPLATE_CTA')"
						>
							<div
								@click="methodHandleMessagesTemplatesDialogVisibility(true)"
								class="bg-gray-100 w-9 h-9 justify-center rounded-full flex items-center gap-1 cursor-pointer transition-all hover:bg-gray-200"
							>
								<i class="gui-template text-xl -top-[1px] -right-[1px]" />
							</div>
						</el-tooltip>
					</div>
				</div>
			</div>
			<el-dialog
				v-model="isMessagesTemplatesDialogVisible"
				:title="t('SETTINGS.FORM_BUILDER.CHOOSE_TEMPLATE')"
				:width="dialogWidth"
			>
				<div
					class="w-full border-t border-gray-200 "
					v-if="methodSplitBabelTemplateMessage()"
				>
					<div class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-10 h-[350px] overflow-auto p-7">
						<div
							v-for="(aTemplateMessage, index) in methodSplitBabelTemplateMessage()"
							:key="index"
							:class="{ 'border-green-main': choosenTemplateMessage === aTemplateMessage }"
							class="message_card cursor-pointer px-5 pt-3 py-10 flex flex-col justify-start items-start text-left bg-white rounded-[20px] border border-gray-lighted"
							@click="choosenTemplateMessage = aTemplateMessage"
						>
							<p
								v-html="methodReplaceLinkByHtml(aTemplateMessage)"
								class="text-left text-black"
							/>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="flex items-center justify-end gap-5">
						<span
							class="cursor-pointer transition-all text-black hover:opacity-75"
							@click="methodHandleMessagesTemplatesDialogVisibility(false)"
						>
							{{ $t('_COMMON.CANCEL') }}
						</span>
						<q-button
							@click="methodUseTemplateMessage"
						>
							{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_TEMPLATES_DIALOG_CTA') }}
						</q-button>
					</div>
				</template>
			</el-dialog>
		</div>
	</action-creation-helper-container>
</template>

<script setup>
import { defineAsyncComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const ActionCreationHelperContainer = defineAsyncComponent(() => import('../ActionCreationHelperContainer'))

const props = defineProps({
	headerHeight: {
		type: Number,
		default: 0,
	},
})

const { t } = useI18n()
const store = useStore()

const isMessagesTemplatesDialogVisible = ref(false)
const arrOfTemplateMessageBabel = ref([])
const dialogWidth = ref('60%')
const choosenTemplateMessage = ref('')

const emit = defineEmits([
	'update:nextValidatorBoolean',
	'update:errorMessage',
])

/// computed

const fromVuexCommentAction = computed({
	get() {
		const { comment } = store.getters['@action/newAction']
		return comment
	},
	set(value) {
		store.dispatch('@action/actionEditNewActionObject', { key: 'comment', value })
	},
})

const computedGetValidator = computed(() => {
	return !!fromVuexCommentAction.value
})

/// watchers

watch(computedGetValidator, (newValue, oldValue) => {
	emit('update:nextValidatorBoolean', newValue)
	if (oldValue === true && newValue === false) {
		emit('update:errorMessage', t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_ERROR_MESSAGE'))
	}
})

const methodHandleMessagesTemplatesDialogVisibility = (value) => {
	isMessagesTemplatesDialogVisible.value = value
}

const methodSplitBabelTemplateMessage = () => {
	const fullString = t('ACTION.DETAIL.SHARING_MESSAGE_TEMPLATE')
	return (arrOfTemplateMessageBabel.value = fullString.split('|'))
}

const autoCompleteMessage = (value) => {
	store.dispatch('@action/actionEditNewActionObject', { key: 'comment', value })
}

const updateDialogWidth = () => {
	const windowWidth = window.innerWidth
	if (windowWidth < 768) {
		dialogWidth.value = '90%'
	}
	else if (windowWidth < 1024) {
		dialogWidth.value = '80%'
	}
	else {
		dialogWidth.value = '60%'
	}
}

const methodUseTemplateMessage = () => {
	autoCompleteMessage(choosenTemplateMessage.value)
	methodHandleMessagesTemplatesDialogVisibility(false)
}

const methodReplaceLinkByHtml = (value) => {
	/// Capture all the text between [] and replace it by a span with a class
	const regex = /\[([^\]]+)\]/g
	return value.replace(regex, (match) => `<span class="template_mesage_highlighted">${match}</span>`)
}

onMounted(() => {
	store.dispatch('@action/actionSetStep', 'MESSAGES_TEMPLATES')
	updateDialogWidth()
	window.addEventListener('resize', updateDialogWidth)
	emit('update:nextValidatorBoolean', computedGetValidator.value)
	emit('update:errorMessage', t('ACTION.CALL_TO_ACTION.INFORMATIONS.SHARING_CONTENT_ERROR_MESSAGE'))
})

onUnmounted(() => {
	window.removeEventListener('resize', updateDialogWidth)
})
</script>

<style lang="scss">
#action_message {
	.el-textarea {
		border: none;
		box-shadow: none;
		outline: none;
	}
	.el-textarea__inner {
		border: none;
		box-shadow: none;
		outline: none;
		resize: none;
	}
}

.message_card {
	transition: all 0.3s ease;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -7px,
	rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -2px,
		rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	}
}
.template_mesage_highlighted {
	color: #3148E5;
}
</style>
