export const contactNotesRoute = 'contact-details-forms-notes'
export const contactInfosRoute = 'contact-details-forms-infos'
export const contactOpportunitiesRoute = 'contact-details-forms-opportunity'
export const contactFormsRoute = 'contact-details-forms-classic'
export const contactHistoryRoute = 'contact-details-history'
export const contactStatusRoute = 'contact-details-forms-status'
export const contactInteractionsRoute = 'contact-details-interactions'
export const contactEmailsRoute = 'contact-details-emails'
export const contactReceivedEmailsRoute = 'contact-details-received-emails'
export const contactEmailViewRoute = 'contact-details-email-view'
export const contactEmailEditorRoute = 'contact-details-editor-view'
export const contactDonationMembershipRoute = 'contact-details-donation-membership'
import { methodIsAccessGranted } from '@/utils/RoleUtils'
import { storeToRefs } from 'pinia'
import { useTransactionStore } from '@/stores/transactionTunnel'
import { controllerPackRedirection } from './utils/routerController'
import store from '@/store/stateStore'

const children = (rootName: String) => {
	return [
		{
			path: `contacts`,
			name: `${rootName}-contacts`,
			props: true,
			beforeEnter: (to: any, from: any, next: any) => {
				const transactionStore = useTransactionStore()
				const { currentTransaction } = storeToRefs(transactionStore)

				const userConnected = store.getters['userConnected']
				// Role access check in case of edit mode
				if (
					rootName != 'transaction-edit'
					|| (methodIsAccessGranted('transaction:write')
					&& (methodIsAccessGranted('transaction_all:write')
					|| userConnected.id == currentTransaction.value.user_id))
				) {
					next()
				}
				else {
					next(`${rootName}-synthesis`)
				}
			},
			component: () =>
				import(
					'../components/donation-and-membership/createTransactionTunnel/newTransactionContacts.vue'
				),
		},
		{
			path: `contact-details`,
			name: `${rootName}-contact-details`,
			props: true,
			beforeEnter: (to: any, from: any, next: any) => {
				const transactionStore = useTransactionStore()
				const { currentTransaction } = storeToRefs(transactionStore)

				const userConnected = store.getters['userConnected']
				// Role access check in case of edit mode
				if (
					rootName != 'transaction-edit'
					|| (methodIsAccessGranted('transaction:write')
					&& (methodIsAccessGranted('transaction_all:write')
					|| userConnected.id == currentTransaction.value.user_id))
				) {
					next()
				}
				else {
					next(`${rootName}-synthesis`)
				}
			},
			component: () =>
				import(
					'../components/donation-and-membership/createTransactionTunnel/newTransactionContactsDetails.vue'
				),
			children: [
				{
					name: `${rootName}-${contactInfosRoute}`,
					path: 'infos',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsInfos.vue'
						),
					props: true,
				},

				{
					name: `${rootName}-${contactNotesRoute}`,
					path: 'notes',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsNotes.vue'
						),
					props: true,
				},

				{
					name: `${rootName}-${contactOpportunitiesRoute}`,
					path: 'opportunities',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms.vue'
						),
					props: true,
				},
				{
					name: `${rootName}-${contactFormsRoute}`,
					path: 'forms',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms.vue'
						),
					props: true,
				},
				// {
				// 	path: '/survey/online-forms',
				// 	name: 'online-forms',
				// 	component: SurveyOnlineForms,
				// },
				{
					name: `${rootName}-${contactStatusRoute}`,
					path: 'status',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsForms.vue'
						),
					props: true,
				},
				{
					name: `${rootName}-${contactHistoryRoute}`,
					path: 'history',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsHistory.vue'
						),
					props: true,
				},
				{
					name: `${rootName}-${contactInteractionsRoute}`,
					path: 'interactions',
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsInteractions.vue'
						),
					props: true,
				},
				{
					name: `${rootName}-${contactEmailsRoute}`,
					path: 'emails',
					redirect: { name: `${rootName}-${contactReceivedEmailsRoute}` },
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/contactDetailsInteractions.vue'
						),
					props: true,
					children: [
						{
							name: `${rootName}-${contactReceivedEmailsRoute}`,
							path: 'received',
							component: () =>
								import(
									'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/receivedEmails.vue'
								),
							props: true,
						},
						{
							name: `${rootName}-${contactEmailViewRoute}`,
							path: 'view/:emailId',
							component: () =>
								import(
									'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/emailView.vue'
								),
							props: true,
						},
						{
							name: `${rootName}-${contactEmailEditorRoute}`,
							path: 'editor',
							component: () =>
								import(
									'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/emailsComponents/emailEditorView.vue'
								),
							props: true,
						},
					],
				},
				{
					name: `${rootName}-${contactDonationMembershipRoute}`,
					path: 'donation-membership',
					beforeEnter: (to, from, next) => {
						controllerPackRedirection(next, 'statusWebAccessDonationsMemberships')
					},
					component: () =>
						import(
							'../components/contact-module/contactDetails/contactDetailsForms/derivedByRouter/ContactDetailsMembershipDonation.vue'
						),
					props: true,
				},
			],
		},
		{
			path: `informations`,
			name: `${rootName}-informations`,
			props: true,
			beforeEnter: (to: any, from: any, next: any) => {
				const transactionStore = useTransactionStore()
				const { currentTransaction } = storeToRefs(transactionStore)

				const userConnected = store.getters['userConnected']
				// Role access check in case of edit mode
				if (
					rootName != 'transaction-edit'
					|| (methodIsAccessGranted('transaction:write')
					&& (methodIsAccessGranted('transaction_all:write')
					|| userConnected.id == currentTransaction.value.user_id))
				) {
					next()
				}
				else {
					next(`${rootName}-synthesis`)
				}
			},
			component: () =>
				import(
					'../components/donation-and-membership/createTransactionTunnel/newTransactionInformations.vue'
				),
		},
		{
			path: `synthesis`,
			name: `${rootName}-synthesis`,
			component: () =>
				import(
					'../components/donation-and-membership/createTransactionTunnel/newTransactionSynthesis.vue'
				),
		},
	]
}

export const CreateTransactionTunnel = {
	meta: { title: 'TRANSACTIONS.META.CREATE_TUNNEL' },
	path: `/transaction`,
	name: 'transaction',
	redirect: { name: 'transaction-contacts' },
	props: true,
	component: () =>
		import(
			'../components/donation-and-membership/createTransactionTunnel/newTransactionMainContainer.vue'
		),
	beforeEnter: (to: object, from: object, next: Function) => {
		controllerPackRedirection(next, 'statusWebAccessDonationsMemberships')
	},
	children: [...children('transaction')],
}

export const AccessToTransactionById = {
	meta: { title: 'TRANSACTIONS.META.TRANSACTION_BY_ID' },
	path: `/transaction/:transactionBundleId`,
	name: 'transaction-edit',
	props: true,
	component: () =>
		import(
			'../components/donation-and-membership/createTransactionTunnel/newTransactionMainContainer.vue'
		),
	beforeEnter: (to: object, from: object, next: Function) => {
		controllerPackRedirection(next, 'statusWebAccessDonationsMemberships')
	},
	children: [...children('transaction-edit')],
}

export const CreateLotTunnel = {
	path: `/lot`,
	name: 'lot',
	props: true,
	redirect: { name: 'lot-contacts' },
	component: () =>
		import(
			'../components/donation-and-membership/createTransactionTunnel/newTransactionMainContainer.vue'
		),
	// beforeEnter: (to: object, from: object, next: Function) => {},
	children: [...children('lot')],
	// children: [...children('lot')],
}

export const DonationAndMembershipAnalyse = {
	path: 'analyzes',
	name: 'donation-and-membership-analyzes',
	// redirect: { name: 'transactions-listing' },
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (methodIsAccessGranted('transaction_dashboard:read')) {
			next(true)
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () => import('../components/donation-and-membership/AnalyzesDonationsMemberships.vue'),

	// children: [
	// 	{
	// 		path: '/transaction/listing',
	// 		name: 'transactions-listing',
	// 		component: () =>
	// 			import('../components/donation-and-membership/transactions/TransactionsListing.vue'),
	// 		props: true,
	// 	},
	// ],
}

export const DonationAndMembershipCustomAnalyse = {
	path: 'custom-analyzes',
	name: 'donation-and-membership-custom-analyzes',
	// redirect: { name: 'transactions-listing' },
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (methodIsAccessGranted('transaction_dashboard:read')) {
			next(true)
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () => import('../components/donation-and-membership/CustomAnalyzesDonationsMemberships.vue'),

	// children: [
	// 	{
	// 		path: '/transaction/listing',
	// 		name: 'transactions-listing',
	// 		component: () =>
	// 			import('../components/donation-and-membership/transactions/TransactionsListing.vue'),
	// 		props: true,
	// 	},
	// ],
}

export const BatchesList = {
	path: `batches`,
	name: 'donation-and-membership-batches',
	// redirect: { name: 'transactions-listing' },
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (
			methodIsAccessGranted('transaction:write')
			|| methodIsAccessGranted('transaction_all:read')
		) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessBatchs')
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () => import('../components/donation-and-membership/batches/BatchesListing.vue'),
}

export const AccessToBatchById = {
	path: `batch/:batchId`,
	name: 'donation-and-membership-batch-by-id',
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (
			methodIsAccessGranted('transaction:write')
			|| methodIsAccessGranted('transaction_all:read')
		) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessBatchs')
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () => import('../components/donation-and-membership/batches/batchById.vue'),
}

export const TransactionsList = {
	path: `transactions`,
	name: 'transactions',
	// redirect: { name: 'transactions-listing' },
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (
			methodIsAccessGranted('transaction:write')
			|| methodIsAccessGranted('transaction_all:read')
		) {
			next(true)
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () =>
		import('../components/donation-and-membership/transactions/TransactionsListing.vue'),

	// children: [
	// 	{
	// 		path: '/transaction/listing',
	// 		name: 'transactions-listing',
	// 		component: () =>
	// 			import('../components/donation-and-membership/transactions/TransactionsListing.vue'),
	// 		props: true,
	// 	},
	// ],
}

export const TransactionsExport = {
	path: `transactions-export`,
	name: 'transactions-export',
	// redirect: { name: 'transactions-listing' },
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		// Role access check
		if (
			methodIsAccessGranted('transaction:write')
			|| methodIsAccessGranted('transaction_all:read')
		) {
			next(true)
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	component: () =>
		import('../components/donation-and-membership/transactions/TransactionsExports.vue'),

	// children: [
	// 	{
	// 		path: '/transaction/listing',
	// 		name: 'transactions-listing',
	// 		component: () =>
	// 			import('../components/donation-and-membership/transactions/TransactionsListing.vue'),
	// 		props: true,
	// 	},
	// ],
}

export const donationAndMembershipListingsContainer = {
	meta: { title: 'TRANSACTIONS.META.LISTING' },
	path: `/donation-and-membership`,
	name: 'donation-and-membership',
	redirect: () => {
		return {
			name: methodIsAccessGranted('transaction_dashboard:read')
				? 'donation-and-membership-analyzes'
				: 'transactions',
		}
	},
	// props: true,
	beforeEnter: (to: any, from: any, next: any) => {
		controllerPackRedirection(next, 'statusWebAccessDonationsMemberships')
	},
	component: () => import('../components/donation-and-membership/listingsContainer.vue'),

	children: [DonationAndMembershipAnalyse, DonationAndMembershipCustomAnalyse, BatchesList, TransactionsList, TransactionsExport, AccessToBatchById],
}
