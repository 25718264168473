import { controllerPackRedirection } from './utils/routerController'
import store from './../store/stateStore'

const PetitionMarketing = {
	title: 'MENU.PETITION',
	path: 'petitions',
	name: 'packer-petitions',
	component: () => import('../components/petition-module/marketing/PetitionPromoted.vue'),
	props: true,
	soon: true,
	opacity: true,
	isTooltipDisabled: true,
}

/**
 * To delete maybe later
 * depending on petition packs
 */
export const PetitionsRoutesPromoted = {
	path: '/promoted',
	name: 'promoted-petitions',
	component: () => import('../components/petition-module/marketing/PetitionMarketingMain.vue'),
	redirect: () => {
		return { name: 'packer-petitions' }
	},
	children: [PetitionMarketing],
	props: true,
}

export const PetitionsRoutes = {
	meta: { title: 'MENU.PETITION' },
	title: 'PETITIONS.TITLE',
	path: '/petitions',
	name: 'petitions',
	redirect: '/petitions/listing',
	props: true,
	component: () => import('../components/petition-module/petitionMain.vue'),
	beforeEnter: (to: object, from: object, next: Function) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('petition:publish')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessPetitions', 'command-center', [
				'enabled',
				'promoted',
			])
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: 'listing',
			name: 'listing',
			component: () => import('../components/create-form-components/list/formsListing.vue'),
		},
		{
			title: 'PETITIONS.VIEW.TITLE',
			path: 'view',
			name: 'view',
			component: () => import('../components/petition-module/view/petitionView.vue'),
		},
		{
			title: 'PETITIONS.CREATE.TITLE',
			path: 'create',
			name: 'petition-create',
			redirect: { name: 'create-base' },
			component: () => import('../components/petition-module/create-tunnel/createPetitionMain.vue'),
			children: [
				{
					title: 'PETITIONS.CREATE.BASE.TITLE',
					path: 'base',
					name: 'create-base',
					component: () =>
						import('../components/petition-module/create-tunnel/createPetitionBase.vue'),
				},
				{
					title: 'PETITIONS.CREATE.CATEGORIES.TITLE',
					path: 'categories',
					name: 'create-categories',
					component: () =>
						import('../components/petition-module/create-tunnel/createPetitionCategories.vue'),
				},
				{
					title: 'PETITIONS.CREATE.INFOS.TITLE',
					path: 'infos',
					name: 'create-infos',
					component: () =>
						import('../components/petition-module/create-tunnel/createPetitionInfos.vue'),
				},
				{
					title: 'PETITIONS.CREATE.SIGNATORIES.TITLE',
					path: 'signatories',
					name: 'create-signatories',
					component: () =>
						import('../components/petition-module/create-tunnel/createPetitionSignatories.vue'),
				},
			],
		},
	],
}
