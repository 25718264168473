<template>
	<div class="h-screen relative">
		<div class="w-full bg-white">
			<div class="px-8 pt-6 pb-4">
				<tab-with-number
					:title="$t('SETTINGS.PROFILE.HEADER.ACCOUNT')"
					selected
				/>
			</div>
		</div>
		<div class="px-8 pt-8">
			<div class="w-full bg-white p-5 sticky top-0 z-50">
				<div class="flex justify-between">
					<div class="flex flex-col md:flex-row">
						<tab-with-number
							v-for="(tab, index) in tabs"
							:key="index"
							:title="methodGetTitleTabs(tab.name)"
							:selected="$route.name === tab.name"
							font-size="1.1rem"
							class="mr-1 lg:mr-4 lg:p-3"
							@tab-click="goto(tab)"
						/>
					</div>
					<template v-if="loading">
						<div class="h-fit self-center mr-8">
							<i class="fa fa-circle-o-notch fa-spin text-pink-main" />
						</div>
					</template>
					<template v-else>
						<q-button
							v-if="computedShowResetAndSaveBtn"
							:disabled="!hasChangeToSave"
							class="flex items-center"
							@click="saveChange += 1"
						>
							{{ $t('_COMMON.SAVE') }}
						</q-button>
					</template>
				</div>
				<p
					v-if="Object.keys(description).includes($route.name)"
					class="py-5 px-3"
					v-html="description[$route.name]"
				/>
			</div>
			<div class="mt-1 bg-white">
				<router-view
					class="bck-color"
					:save-change="saveChange"
					:notifications-preferences="getNotificationsPreferences"
					:email-accounts="getUserEmailAccounts"
					@un-save-change="hasChangeToSave = $event"
					@loading="loading = $event"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},
	data() {
		return {
			tabs: [
				{
					// label: this.$t('SETTINGS.PROFILE.HEADER.INFORMATION'),
					path: '/profile/info',
					name: 'profile-info',
				},
				{
					// label: this.$t('SETTINGS.PROFILE.HEADER.PASSWORD'),
					path: '/profile/password',
					name: 'profile-password',
				},
				{
					// label: this.$t('SETTINGS.PROFILE.HEADER.AVAILABILITY'),
					path: '/profile/status',
					name: 'profile-status',
				},
				{
					// label: this.$t('SETTINGS.PROFILE.HEADER.NOTIFICATION'),
					path: '/profile/notif',
					name: 'profile-notif',
				},
				{
					// label: this.$t('SETTINGS.PROFILE.HEADER.EMAILS'),
					path: '/profile/emails',
					name: 'profile-emails',
				},
			],
			tabsWithDefaultAndSaveBtn: ['profile-info', 'profile-password', 'profile-status'],

			hasChangeToSave: false,
			loading: false,
			saveChange: 0,

			description: {
				'profile-status': this.$t('SETTINGS.PROFILE.AVAILABILITY.DESCRIPTION'),
				'profile-notif': this.$t('SETTINGS.PROFILE.NOTIFICATION.DESCRIPTION'),
			},
		}
	},
	computed: {
		...mapGetters('@user', ['getNotificationsPreferences', 'getUserEmailAccounts']),

		computedShowResetAndSaveBtn() {
			return this.tabsWithDefaultAndSaveBtn.includes(this.$route.name)
		},
	},
	mounted() {
		this.actionFetchAllNotificationsPreferences()
	},
	methods: {
		...mapActions('@user', ['actionFetchAllNotificationsPreferences']),

		goto(tab) {
			this.$router.push({ name: tab.name }).catch(() => {})
		},
		methodGetTitleTabs(routeName) {
			switch (routeName) {
				case 'profile-info':
					return this.$t('SETTINGS.PROFILE.HEADER.INFORMATION')
				case 'profile-password':
					return this.$t('SETTINGS.PROFILE.HEADER.PASSWORD')
				case 'profile-status':
					return this.$t('SETTINGS.PROFILE.HEADER.AVAILABILITY')
				case 'profile-notif':
					return this.$t('SETTINGS.PROFILE.HEADER.NOTIFICATION')
				case 'profile-emails':
					return this.$t('SETTINGS.PROFILE.HEADER.EMAILS')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.bck-color {
	background-color: $pink-lighted;
}
</style>
<style scoped lang="sass">
.secondary-text
	@apply text-sm text-gray-400

.basic-black
	border-color: black
</style>
