<template>
	<div class="q-col bg-white">
		<div class="flex w-full">
			<div
				class="flex flex-col flex-1 px-20 py-20 scroll-if-too-height"
				style="flex-grow: 1.5"
			>
				<div class="max-w-screen-sm">
					<div class="pb-8">
						<span class="text-3xl font-title text-center">{{ $t('ACTION.CALL_TO_ACTION.SELECT_CONTACT.TITLE') }}</span>
					</div>
					<div>
						<div class="flex flex-wrap flex-row">
							<div
								v-for="selector in dataSelectors"
								:key="selector.id"
								class="mr-3 w-full lg:w-2/5"
							>
								<p
									class="mt-5 mb-2 w-full"
									:class="selector.disabled ? 'text-gray-700' : 'text-pink-strong'"
								>
									{{ selector.attrLabel }}
								</p>
								<el-select
									v-if="selector.type === 'select'"
									v-model="selector.value"
									class="w-full"
									clearable
									filterable
									:multiple="selector.multiple"
									:disabled="selector.disabled || disabledFilters"
									@change="methodHandleFilterChange(selector.formId, selector.value, selector.id)"
									popper-class="rounded-xl"
								>
									<el-option
										class="rounded-lg mx-1"
										v-for="(option, index) in selector.options"
										:key="index"
										:label="option.label"
										:value="option.value"
									/>
								</el-select>
								<el-input
									v-if="selector.type === 'input'"
									v-model="selector.value"
									clearable
									@change="methodHandleFilterChange"
								/>
								<el-date-picker
									v-if="selector.type === 'date'"
									v-model="selector.value"
									class="custom-class-width-datepicker"
									type="date"
									:format="methodFormatDatePicker()"
									:placeholder="$t('CONTACT.HEADER.DATE_PLACEHOLDER')"
									:disabled="disabledFilters"
									@change="methodHandleFilterChange"
									popper-class="rounded-xl"
								/>
							</div>
						</div>

						<div class="w-full mr-3 border-top">
							<action-creation-address-filter
								:disabled="computedIsEntryPointWithPolygon || disabledFilters"
								:with-phone="true"
								@change="methodHandleFilterChange"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				id="mapSection"
				class="flex-1 border-left p-3"
			>
				<div class="flex flex-col h-full">
					<action-tabs-on-right-side
						:title="$t('ACTION.CALL_TO_ACTION.RESULT')"
						:list-data="{
							title: this.$t('ACTION.DETAIL.RIGHT_TABS.RIGHT_TABS_TITLE'),
							type: 'map',
							disabledTurf: this.disabledTurf || computedIsEntryPointWithPolygon,
							triggerNewSearchData: this.methodHandleFilterChange,
							icon: '/static/images/svg/map.svg',
						}"
						:override-tab-selected="focusATab"
						:action-type="newAction.type"
						:total-hit-kpi="getTotalHitsFound"
						@focused-tab-change="(tab) => methodHandleKpiTabChange(tab)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import beforeLeaveRouteHelperGoBack from '../navigation-guards/beforeLeaveRouteHelperGoBack'
import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import { defineAsyncComponent } from 'vue'
import { getDisplayDateFromLocalStorage } from '../../../../utils/dateFnsHelper'

export default {
	components: {
		ActionCreationAddressFilter: defineAsyncComponent(
			() => import('./ActionCreationAddressFilter.vue'),
		),
		ActionTabsOnRightSide: defineAsyncComponent(() => import('../ActionTabsOnRightSide.vue')),
	},

	beforeRouteLeave(to, from, next) {
		if (beforeLeaveRouteHelperGoBack(to)) {
			this.allowMessage = false
			this.actionSetNewActionFilters({})

			if (!this.computedIsEntryPointWithPolygon) {
				this.actionSetNewActionPolygon(this.polygonSaved)
			}

			if (this.entryPoint !== 'contact-list') {
				this.actionResetAdvancedSearchFilters()
				this.actionResetPollingStationIncluded()
				this.actionSetNewActionTags([])
			}

			this.actionResetStateTags()
			this.actionResetAddressIncluded()

			this.actionGenerateLocationSummaryGeohashFromAction()
			this.actionOverrideKpiTotalHit(0)
		}

		next()
	},

	emits: ['update:nextValidatorBoolean', 'update:errorMessage'],

	data() {
		return {
			dataSelectors: [],
			localFormsFilter: [],
			maxBound: 25000,
			filter: {
				searchType: 'all',
				nb_results: '25000',
				presence_filter: [],
			},
			disabledTurf: false,
			disabledFilters: false,
			contactList: [],
			isListDisplayed: true,
			allowMessage: true,
			polygonSaved: undefined,
			focusATab: 'list',
		}
	},
	computed: {
		...mapGetters(['userConnected']),
		...mapGetters('@filter', [
			'getIsFilterModifiedFromOrigin',
			'lastchange_filter',
			'tags_filter',
			'form_filter_data',
		]),
		...mapGetters('@contact', ['getAllPollingStationsWithMissingTransformed']),
		...mapGetters('@tags', ['getTags', 'getIsLoading']),
		...mapGetters('@form', ['getFormsStatus', 'getNamePresence']),
		...mapGetters('@action', ['getTotalHitsFound', 'entryPoint', 'newAction', 'getContactsAction']),
		...mapGetters('@search', [
			'getSearchAddressIncluded',
			'getSearchPollingStationsIncluded',
			'getAdvancedSearchQuery',
		]),

		computedErrorMessage() {
			if (this.allowMessage) {
				return this.getTotalHitsFound > this.maxBound
					? this.$t('ACTION.CALL_TO_ACTION.TARGET.UPPER_BOUND_REACHED', {
						count: this.maxBound,
					})
					: this.$t('ACTION.CALL_TO_ACTION.TARGET.ERROR')
			}

			return undefined
		},

		computedGetValidator() {
			return this.getTotalHitsFound !== 0 && this.getTotalHitsFound <= this.maxBound
		},

		computedIsEntryPointWithPolygon() {
			return this.entryPoint === 'profiles' || this.entryPoint === 'command-center'
		},
	},
	watch: {

		getIsLoading: {
			handler(newVal) {
				if (!newVal) {
					this.dataSelectors.find((selector) => selector.attr === 'tags_filter').options
						= this.getTags.map((word) => {
							return { label: word.name, value: word.name }
						})
				}
			},
			immediate: false,
		},

		getContactsAction: {
			handler(newVal) {
				if (newVal) {
					this.contactList = newVal.reduce((allNames, contact) => {
						allNames.push({
							firstname: contact.firstname || '-',
							surname: contact.surname || '-',
						})

						return allNames
					}, [])

					if (newVal.length === 0) this.focusATab = 'map'
					else this.focusATab = 'list'
				}
			},
		},

		computedGetValidator: {
			handler(newValue, oldValue) {
				this.$emit('update:nextValidatorBoolean', newValue)
				if (oldValue === true && newValue === false) this.$emit('update:errorMessage', this.computedErrorMessage)
			},
			immediate: true,
		},
	},
	mounted() {
		this.actionSetStep('FILTER_CONTACTS')

		this.methodInitView()
	},

	methods: {
		...mapActions('@tags', ['actionGetTags', 'actionResetStateTags']),
		...mapActions('@search', [
			'actionResetAddressIncluded',
			'actionResetPollingStationIncluded',
			'actionReplacePollingStationIncluded',
		]),
		...mapActions('@action', [
			'actionSetStep',
			'actionSetNewActionFilters',
			'actionGenerateKpiFromAction',
			'actionGenerateLocationSummaryGeohashFromAction',
			'actionSetNewActionPolygon',
			'actionOverrideKpiTotalHit',
			'actionSetNewActionTags',
			'actionSetNewAdvancedSearchFilters',
			'actionResetAdvancedSearchFilters',
			'actionSearchContact',
		]),

		methodInitView() {
			this.polygonSaved = cloneDeep(this.newAction.search.polygon)
			this.disabledTurf = this.polygonSaved?.length > 0

			if (!this.getTags.length) {
				this.actionGetTags()
			}

			this.filter.group_id = this.userConnected.selected_group_id.toString()

			this.methodInitDataSelector()

			// Je recupere les filtres de this.newAction.search.filter  et les push dans this.filter.localFormsFilterData
			this.filter.localFormsFilterData = []
			this.newAction.search.filter.forEach((anItem, i) => {
				if (i > 11) {
					this.filter.localFormsFilterData.push(anItem)
				}
			})

			if (this.getAdvancedSearchQuery !== null) {
				const advancedSearchClone = cloneDeep(this.getAdvancedSearchQuery)
				const phoneOnlyNode = new QueryNode('$at_least_one')
				const phoneOnlyConditionFix = new QueryNode('$condition')
				const phoneOnlyConditionMobile = new QueryNode('$condition')

				phoneOnlyConditionFix.setAttr('phone')
				phoneOnlyConditionMobile.setAttr('mobile')
				phoneOnlyConditionMobile.setOpe('ext')
				phoneOnlyConditionFix.setOpe('ext')
				phoneOnlyNode.addChild(phoneOnlyConditionMobile)
				phoneOnlyNode.addChild(phoneOnlyConditionFix)

				const newMainQueryNode = new QueryNode('$all')

				newMainQueryNode.addChild(advancedSearchClone.query)
				newMainQueryNode.addChild(phoneOnlyNode)
				advancedSearchClone.query = newMainQueryNode

				this.actionSetNewAdvancedSearchFilters(advancedSearchClone)

				this.disabledTurf = true
				this.disabledFilters = true
			}
			else {
				this.dataSelectors.find((selector) => selector.attr === 'tags_filter').value
				= this.newAction.search.tags_filter

				this.dataSelectors.find((selector) => selector.attr === 'lastchange_filter').value
				= this.newAction.search.filter[9]

				/**
			 * ForEach the forms filters to set the value of form status in the
			 * UI
			 */
				this.form_filter_data.find((aFormFilter) => {
					if (this.getFormsStatus.length && this.getFormsStatus[0].refvalues?.length) {
						const getSplittedId = aFormFilter.split('/')

						if (this.getFormsStatus[0].id === Number(getSplittedId[1])) {
							this.getFormsStatus[0].refvalues.forEach((aFormRefValue) => {
								if (aFormRefValue.id === Number(getSplittedId[3])) {
									this.dataSelectors.forEach((aDataSelector) => {
										if (aDataSelector.attr === 'form_filter_data') {
											aDataSelector.value = aFormFilter
										}
									})
								}
							})
						}
					}

					if (this.getNamePresence.length && this.getNamePresence[0].refvalues?.length) {
						const getSplittedId = aFormFilter.split('/')

						if (this.getNamePresence[0].id === Number(getSplittedId[1])) {
							if (getSplittedId[2] === 'false') {
								this.dataSelectors.forEach((aDataSelector) => {
									if (aDataSelector.attr === 'presence_filter') {
										aDataSelector.value = this.$t('CONTACT.HEADER.NOT_VISITED')
									}
								})
							}
							else {
								this.getNamePresence[0].refvalues.forEach((aFormRefValue) => {
									if (aFormRefValue.id === Number(getSplittedId[3])) {
										this.dataSelectors.forEach((aDataSelector) => {
											if (aDataSelector.attr === 'presence_filter') {
												aDataSelector.value = this.$t(
												`CONTACT.HEADER.${aFormRefValue.value.toUpperCase()}`,
												)
											}
										})
									}
								})
							}
						}
					}
				})

				this.dataSelectors.find((selector) => selector.attr === 'polling_station_included').value
				= this.getSearchPollingStationsIncluded[0]
			}

			this.methodHandleFilterChange()
		},

		methodFormatDatePicker() {
			return getDisplayDateFromLocalStorage().toUpperCase()
		},
		methodHandleFilterChange(formIdSelected, currentSelectedValue, selectorId) {
			const editClonedFormsFilters = (clonedFormsFilters, formIdSelected, selectorId) => {
				if ((formIdSelected, selectorId)) {
					let indexNeedToDelete = clonedFormsFilters.findIndex((aClonedFormFilter) =>
						aClonedFormFilter.includes(`RADIO/${formIdSelected}`),
					)

					if (indexNeedToDelete > -1) {
						clonedFormsFilters.splice(indexNeedToDelete, 1)

						if (currentSelectedValue !== '') clonedFormsFilters.push(currentSelectedValue)
					}
					else if (
						currentSelectedValue !== ''
						&& currentSelectedValue.includes(`RADIO/${formIdSelected}`)
					) {
						clonedFormsFilters.push(currentSelectedValue)
					}
				}

				return clonedFormsFilters
			}

			let clonedFormsFilters = cloneDeep(this.filter.localFormsFilterData)

			this.dataSelectors.forEach((selector) => {
				switch (selector.attr) {
					case 'polling_station_included':
						if (selector.value) this.actionReplacePollingStationIncluded([selector.value])
						else this.actionResetPollingStationIncluded()

						break
					case 'tags_filter':
						this.actionSetNewActionTags(selector.value)
						break
					case 'presence_filter':
					case 'form_filter_data':
						clonedFormsFilters = editClonedFormsFilters(
							clonedFormsFilters,
							formIdSelected,
							selectorId,
						)

						break
					case 'lastchange_filter':
					case 'phone_filter':
						if (selector.value) {
							this.filter[selector.attr] = JSON.stringify(selector.value).replaceAll('"', '')
						}
						else this.filter[selector.attr] = ''

						break
				}
			})

			const addressIncluded = this.getSearchAddressIncluded
			const pollingStationsIncluded = this.getSearchPollingStationsIncluded

			// si une requete de type présence à été fournis dans les filtres on l'ajoute au filtre

			this.filter.localFormsFilterData = clonedFormsFilters.filter((e) => e.length)

			this.actionSetNewActionFilters({
				...this.filter,
				form_filter_data: this.filter.localFormsFilterData,
			})

			this.actionGenerateKpiFromAction({ addressIncluded, pollingStationsIncluded })
			this.actionGenerateLocationSummaryGeohashFromAction({
				addressIncluded,
				pollingStationsIncluded,
			})
			this.actionSearchContact({ addressIncluded, pollingStationsIncluded })
		},

		methodHandleKpiTabChange(tab) {
			/**
			 * action to trigger when the map is shown in order to
			 * generate heatmap over the map
			 */
			if (tab === 'map') {
				const addressIncluded = this.getSearchAddressIncluded
				const pollingStationsIncluded = this.getSearchPollingStationsIncluded

				this.actionGenerateKpiFromAction({ addressIncluded, pollingStationsIncluded })
				this.actionGenerateLocationSummaryGeohashFromAction({
					addressIncluded,
					pollingStationsIncluded,
				})
			}
		},

		optionIfMobileOnlyOrNot() {
			if (this.newAction.search.filter[11] === 'SET') {
				return [
					{
						label: this.$t('CONTACT.HEADER.FILTERS.MOBILE.PHONE_FILLED'),
						value: this.newAction.search.filter[11],
					},
				]
			}

			if (this.newAction.search.filter[11] === 'ONLYMOBILE') {
				return [
					{
						label: this.$t('CONTACT.HEADER.FILTERS.MOBILE.MOBILE_FILLED'),
						value: this.newAction.search.filter[11],
					},
				]
			}

			return [{ label: 'default', value: 'SET' }]
		},

		methodInitDataSelector() {
			this.dataSelectors.push({
				id: 1,
				type: 'select',
				attr: 'phone_filter',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.PHONE'),
				options: this.optionIfMobileOnlyOrNot(),
				value: this.newAction.search.filter[11] ? this.newAction.search.filter[11] : 'SET',
				disabled: false,
			})

			this.dataSelectors.push({
				id: 2,
				type: 'select',
				attr: 'polling_station_included',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.TERRITORIES'),
				options: this.getAllPollingStationsWithMissingTransformed.map((station) => {
					return { label: station.label, value: station.value }
				}),
				value: undefined,
			})

			this.dataSelectors.push({
				id: 3,
				type: 'select',
				attr: 'tags_filter',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.TAGS'),
				options: this.getTags.map((word) => {
					return { label: word.name, value: word.name }
				}),
				value: undefined,
				multiple: true,
			})

			const statusOptions = []
			const statusFormId = this.getFormsStatus.length ? this.getFormsStatus[0].id : null

			this.getFormsStatus.forEach((formStatus) => {
				if (formStatus.type === 'radio' || formStatus.type === 'checkbox') {
					formStatus.refvalues.forEach((status) => {
						statusOptions.push({
							label: status.label,
							value: `RADIO/${status.form_id}/TRUE/${status.id}`,
						})
					})
				}
			})
			this.dataSelectors.push({
				id: 4,
				type: 'select',
				attr: 'form_filter_data',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.STATUS'),
				formId: statusFormId,
				options: statusOptions,
				value: undefined,
			})

			this.dataSelectors.push({
				id: 5,
				type: 'date',
				attr: 'lastchange_filter',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.DATE'),
				value: undefined,
			})

			const contacteOptions = []
			let contacteFormId = this.getNamePresence ? this.getNamePresence[0].id : null

			this.getNamePresence.forEach((formPresence) => {
				formPresence.refvalues.forEach((aPresence) => {
					contacteOptions.push({
						label: this.$t(`CONTACT.HEADER.${aPresence.value.toUpperCase()}`),
						value: `RADIO/${aPresence.form_id}/TRUE/${aPresence.id}`,
					})
				})
			})
			contacteOptions.push({
				label: this.$t('CONTACT.HEADER.NOT_VISITED'),
				value: `RADIO/${this.getNamePresence[0].id}/FALSE`,
			})
			this.dataSelectors.push({
				id: 6,
				type: 'select',
				attr: 'presence_filter',
				attrLabel: this.$t('CONTACT.HEADER.FILTERS.CONTACT'),
				formId: contacteFormId,
				options: contacteOptions,
				value: undefined,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.border-top {
	margin-top: 1.25rem;
	border-top: 1px solid #e4e4e4;
}
.scroll-if-too-height {
	height: calc(100vh - 7rem);
	overflow-y: auto;
}
#mapSection {
	/* 6em is the height of the header */
	height: calc(100vh - 7rem);
	@media all and (max-width: 568px) {
		height: 30vh;
	}
	@media all and (min-width: 569px) {
		height: 100%vh;
	}
}
.border-left {
	border-left: 1px solid #e4e4e4;
	padding-left: 1rem;
}
</style>
<style lang="sass">

.custom-class-width-datepicker.el-date-editor.el-input
	@apply w-full
</style>
