<template>
	<div>
		<el-tooltip
			:disabled="!tooltipContent.length"
			:content="tooltipContent"
			popper-class="rounded-xl"
			placement="top"
			:show-arrow="false"
		>
			<span
				:class="[customClass, {'cursor-pointer': isCollapsable}, {'justify-between': !littleAndStackedCollapseTitle}]"
				class="flex items-center pb-1 gap-2"
				@click="(event) => isCollapsable ? methodHandleCollapse(event) : null"
			>
				<slot
					v-if="$slots?.title"
					name="title"
				/>
				<template v-else>
					{{ title }}
				</template>
				<i
					v-if="isCollapsable"
					:class="`text-2xl pointer-events-none ${isOpen ? 'gui-arrow_up' : 'gui-arrow_down'}`"
				/>
			</span>
		</el-tooltip>
		<div
			ref="collapseContent"
			:class="isFirstContentVisible ? 'collapse__content__min_height' : 'collapse__content'"
		>
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: { type: String, default: '', required: false },
		customClass: { type: String, default: '' },
		isFirstContentVisible: { type: Boolean, default: false },
		isCollapsable: { type: Boolean, default: true },
		littleAndStackedCollapseTitle: { type: Boolean, default: false },
		tooltipContent: { type: String, default: '' },
	},

	data() {
		return {
			isOpen: false,
			collapseContent: null,
		}
	},
	mounted() {
		window.addEventListener('resize', this.resizeOpenedCollapseContent)
	},
	unmounted() {
		window.removeEventListener('resize', this.resizeOpenedCollapseContent)
	},
	methods: {
		methodHandleCollapse(e) {
			this.isOpen = !this.isOpen

			this.collapseContent = e.target.nextElementSibling
			if (this.collapseContent.style.maxHeight) {
				this.collapseContent.style.maxHeight = null
			}
			else {
				this.collapseContent.style.maxHeight = this.collapseContent.scrollHeight + 'px'
			}
		},
		resizeOpenedCollapseContent() {
			if (this.isOpen && this.collapseContent) {
				this.collapseContent.style.maxHeight = this.collapseContent.scrollHeight + 'px'
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.collapse__content {
	max-height: 0rem;
	overflow: hidden;
	transition: max-height 0.6s ease;
}

.collapse__content__min_height {
	max-height: 4rem;
	overflow: hidden;
	transition: max-height 0.6s ease;
}
</style>
