<template>
	<div class="web-integration">
		<div class="flex justify-between mb-6">
			<span class="bold">{{ $t('CREATE_FORM.WEB_INTEGRATION.HEADING') }}</span>
			<el-switch
				v-model="computedForm.header"
				:inactive-text="$t('CREATE_FORM.WEB_INTEGRATION.SHOWED')"
			/>
		</div>

		<div class="flex justify-between items-center flex-wrap mb-6">
			<p class="bold whitespace-nowrap">
				{{ $t('CREATE_FORM.WEB_INTEGRATION.WIDTH') }}
			</p>

			<div class="flex gap-x-1">
				<el-input-number
					v-model="computedForm.width.value"
					:controls="false"
					:max="maxWidthValue"
				/>

				<el-select
					v-model="computedForm.width.type"
					@change="setMaxSizeValue"
				>
					<el-option
						v-for="item in unitOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
		</div>

		<div class="flex justify-between items-center flex-wrap mb-6">
			<p class="bold">
				{{ $t('CREATE_FORM.WEB_INTEGRATION.HEIGHT') }}
			</p>

			<div class="flex gap-x-1">
				<el-input-number
					v-model="computedForm.height.value"
					:controls="false"
					:max="maxHeightValue"
				/>

				<el-select
					v-model="computedForm.height.type"
					@change="setMaxSizeValue"
				>
					<el-option
						v-for="item in unitOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
		</div>

		<!-- V2
		<div class="pb-14">
			<p>
				<span class="bold">Transparence du fond</span> <span>{{ computedForm.transparency }}%</span>
			</p>
			<el-slider v-model="computedForm.transparency" color="#05B66F" />
		</div> -->

		<p class="border-t pt-8 text-gray-strong mb-2">
			{{ $t('CREATE_FORM.WEB_INTEGRATION.PLACE') }}
		</p>
		<div class="block mb-8">
			<a
				href="http://help.qomon.com/"
				class="text-pink-main"
			>
				{{ $t('CREATE_FORM.WEB_INTEGRATION.LINK') }}
			</a>
		</div>

		<div class="bg-black mb-8 p-3 rounded-lg">
			<code
				ref="embedCode"
				class="bg-black text-white whitespace-normal"
			>
				{{
					`&lt;iframe src="${computedUrl}${slug}/?${computedGetEmbedIframeStr}${
						!form.header ? `&target=0` : ``
					}" width="${form.width.value}${form.width.type}" height="${form.height.value}${
						form.height.type
					}" allow="clipboard-read; clipboard-write;" marginheight="0" frameborder="0">&lt;/iframe>`
				}}
			</code>
		</div>
		<div
			class="dialog-footer ml-auto"
			style="width: max-content"
		>
			<button
				v-if="webIntegration?.backBtn"
				type="button"
				class="q-button basic-black"
				@click="computedWebIntegration = { show: false }"
			>
				{{ $t('_COMMON.BACK') }}
			</button>
			<button
				type="button"
				class="q-button copy-cta transition-all"
				@click="() => copy($refs.embedCode.innerText)"
			>
				{{ $t('CREATE_FORM.WEB_INTEGRATION.COPY') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		form: { type: Object, default: () => {}, required: true },
		formType: { type: String, default: '', required: false },
		slug: { type: String, default: '', required: true },
		copy: { type: Function, default: () => {}, required: true },
		webIntegration: { type: Object, default: () => {}, required: false },
	},
	emits: ['update:form', 'update:web-integration'],
	data() {
		return {
			maxWidthValue: 0,
			maxHeightValue: 0,
			unitOptions: [
				{
					value: 'px',
					label: 'px',
				},
				{
					value: '%',
					label: '%',
				},
			],
		}
	},
	computed: {
		...mapGetters(['subscription']),
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
		computedUrl() {
			return this.formType === 'online-forms'
				? // eslint-disable-next-line no-undef
				ONLINE_FORMS_ENDPOINT_URL
				: // eslint-disable-next-line no-undef
				PETITIONS_ENDPOINT_URL
		},
		computedWebIntegration: {
			get() {
				return this.webIntegration
			},
			set(val) {
				this.$emit('update:web-integration', val)
			},
		},
		computedGetEmbedIframeStr() {
			const { package_name } = this.subscription
			switch (package_name) {
				case 'premium':
				case 'organisation':
					return 'embed=1&footer=0'

				default:
					return 'embed=1'
			}
		},
	},
	beforeMount() {
		this.setSizeIfNoValue()
		this.setMaxSizeValue()
	},
	methods: {
		setSizeIfNoValue() {
			if (this.form.width.value == 0) {
				this.computedForm.width.value = 100
				this.computedForm.width.type = '%'
			}

			if (this.form.height.value == 0) {
				this.computedForm.height.value = 100
				this.computedForm.height.type = '%'
			}
		},

		setMaxSizeValue() {
			if (this.computedForm.width.type === '%') {
				this.computedForm.width.value > 100 && (this.computedForm.width.value = 100)
				this.maxWidthValue = 100
			}
			else {
				this.maxWidthValue = Infinity
			}

			if (this.computedForm.height.type === '%') {
				this.computedForm.height.value > 100 && (this.computedForm.height.value = 100)
				this.maxHeightValue = 100
			}
			else {
				this.maxHeightValue = Infinity
			}
		},
	},
}
</script>

<style lang="scss">
.copy-cta {
	color: black !important;
	border-color: #6ed39d !important;
	background-color: transparent !important;
	&:hover {
		color: white !important;
		border-color: #6ed39d !important;
		background-color: #6ed39d !important;
	}
}

.web-integration {
	.el-switch__label {
		color: unset;

		& span {
			white-space: nowrap;
		}
	}

	.el-input-number,
	.el-select {
		max-width: 100px;
	}

	.el-slider__bar {
		background-color: #05b66f;
	}
	.el-slider__button {
		border-color: #05b66f;
	}
}
</style>
