<template>
	<div class="flex flex-row h-full">
		<action-creation-helper-container
			v-model:clicked-at-least-once="computedClickedAtLeastOnce"
			:visible-action-tabs-on-right-side="
				getTotalHitsFound > 0 && isUserChooseOption !== 'onlyPolygonUnknowAddresses'
			"
			small-margin-after-title
			:action-helper-container-is-from="'action-creation-precision'"
			:is-conditional-rendering-sidebar-active="false"
			:header-height="headerHeight"
		>
			<action-creation-choose-target-precision-module
				v-model:is-user-choose-option="isUserChooseOption"
				v-model:select-contacts-absent-or-not-visited="selectContactsAbsentOrNotVisited"
				v-model:clicked-at-least-once="computedClickedAtLeastOnce"
				v-model:select-contacts-only-with-phone-number-or-mobile="
					selectContactsOnlyWithPhoneNumberOrMobile
				"
				:close-action-creation-event="leaveActionCreationClick"
				:action-set-new-action-selected-not-present-or-not-visited="
					actionSetNewActionSelectedNotPresentOrNotVisited
				"
				:action-set-new-action-selected-never-met="actionSetNewActionSelectedNeverMet"
				:title-wording-information-by-option-selected="titleWordingInformationByOptionSelected"
				:desc-wording-information-by-option-selected="descWordingInformationByOptionSelected"
				:action-type="newAction.type"
			/>

			<template #visibleActionTabsOnRightSide>
				<div
					v-if="checkIfComputedCountStreetNotEmpty"
					class="flex p-3 border-l border-gray-200"
					:class="checkIfComputedCountStreetNotEmpty ? 'w-2/3 flex-grow ' : ''"
				>
					<action-tabs-on-right-side
						:title="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.AREA')"
						:list-data="{
							title: this.$t('ACTION.DETAIL.RIGHT_TABS.LEFT_TABS_TITLE'),
							titleOfColumnList: [
								$tc('ACTION.CALL_TO_ACTION.STREET', checkIfComputedCountStreetNotEmpty.length),
								$tc('ACTION.CALL_TO_ACTION.CONTACT', countIfListHasManyContact),
							],
							type: 'list',
							data: computedCountStreet,
							icon: '/static/images/svg/list_paper.svg',
						}"
						:action-type="newAction.type"
						style="max-height: 80vh"
						:total-hit-kpi="getTotalHitsFound"
					/>
				</div>
			</template>

			<template #sidebar-header>
				{{
					$t('ACTION.CALL_TO_ACTION.INFORMATIONS.ADDITIONNAL_HELP_TITLE_STEP_CREATION')
				}}
			</template>
			<template #sidebar-content>
				<div
					v-t="titleWordingInformationByOptionSelected"
					class="mb-4 bold text-lg"
				/>
				<div>
					<p
						v-dompurify-html="descWordingInformationByOptionSelected"
						class="text-lg"
					/>
				</div>
			</template>
		</action-creation-helper-container>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import beforeLeaveRouteHelperGoBack from '../navigation-guards/beforeLeaveRouteHelperGoBack'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ActionCreationChooseTargetPrecisionModule: defineAsyncComponent(() =>
			import('./ActionCreationChooseTargetPrecisionModule'),
		),
		ActionCreationHelperContainer: defineAsyncComponent(() =>
			import('../ActionCreationHelperContainer'),
		),
		ActionTabsOnRightSide: defineAsyncComponent(() => import('../ActionTabsOnRightSide.vue')),
	},
	beforeRouteLeave(to, from, next) {
		if (beforeLeaveRouteHelperGoBack(to)) {
			if (!this.form_filter_data.length) {
				this.actionSetNewActionFilters({})
			}
		}
		next()
	},

	emits: ['update:nextValidatorBoolean', 'update:nextFunction', 'update:errorMessage'],

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			clickedAtLeastOnce: false,
			selectedContacts: [],
			isUserChooseOption: '',
			selectContactsAbsentOrNotVisited: 'all',
			selectContactsOnlyWithPhoneNumberOrMobile: false,
		}
	},

	computed: {
		...mapGetters('@search', ['getSearchAddressIncluded', 'getSearchPollingStationsIncluded']),
		...mapGetters('@filter', ['form_filter_data', 'polygon']),
		...mapGetters('@action', [
			'newAction',
			'getTotalHitsFound',
			'getTotalNeverMetHitsFound',
			'getNewActionIsFilterAbsentOrNeverSeenSelected',
			'getContactsAction',
			'entryPoint',
		]),

		...mapGetters('@search', ['getIsBuildEmptyAddressesIsFetched']),
		actionTypeLabel() {
			const { type } = this.newAction
			if (type) return this.$t(`ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.${type.toUpperCase()}`)
			return ''
		},

		computedClickedAtLeastOnce: {
			get() {
				return this.clickedAtLeastOnce
			},
			set(value) {
				this.clickedAtLeastOnce = value
			},
		},
		checkIfComputedCountStreetNotEmpty: {
			get() {
				if (
					this.selectedContacts.length
					&& Object.keys(this.computedCountStreet).length
					&& this.isUserChooseOption != 'onlyStreetsEmptyStreets'
				) {
					return true
				}
				else {
					return false
				}
			},
			set() {},
		},

		countIfListHasManyContact() {
			let count = 0
			if (this.checkIfComputedCountStreetNotEmpty) {
				this.computedCountStreet.forEach((element) => {
					if (element) {
						if (element.contact > 1) {
							count = 2
						}
						else {
							count++
						}
					}
				})
			}
			return count
		},

		computedCountStreet() {
			let arrAllStreetFinded = []
			let normalizedStreet = ''

			this.selectedContacts.forEach((aContact) => {
				if (aContact.address.street) {
					normalizedStreet = aContact.address.street
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')

					normalizedStreet = normalizedStreet.toLowerCase()
					normalizedStreet = normalizedStreet.charAt(0).toUpperCase() + normalizedStreet.slice(1)
					arrAllStreetFinded.push(normalizedStreet)
				}
			})
			const numberOfOcc = arrAllStreetFinded.reduce((acc, address) => {
				const alreadyAdded = acc.some((aAddress) => {
					if (aAddress.street?.replaceAll(' ', '') === address.replaceAll(' ', '')) {
						aAddress.contact += 1
						return true
					}
					return false
				})
				if (!alreadyAdded)
					acc.push({
						street: address,
						contact: 1,
					})
				return acc
			}, [])

			return numberOfOcc
		},

		/**
		 * We use this computed to know if the polygon selected is empty.
		 * If it's the case, the action seems to be empty : 0 contacts
		 * in the polygon provided. So we will use this computed as
		 * a computed to know if we need to create an empty action
		 * with a different wording to explain it to the user.
		 */
		isEmptyPolygonSelected() {
			if (this.getTotalHitsFound <= 0) {
				if (!isEmpty(this.newAction.search.polygon)) {
					return true
				}
			}
			return false
		},
		computedGetValidator() {
			return !!this.isUserChooseOption
		},
		titleWordingInformationByOptionSelected() {
			switch (this.isUserChooseOption) {
				case 'onlyContacts':
					if (this.isEmptyPolygonSelected)
						return this.$t('ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_EMPTY_CREATE_EMPTY_ACTION')
					return this.$t('ACTION.CALL_TO_ACTION.PRECISION.ZONE_WITH_CONTACTS_ONLY')

				case 'onlyStreetsEmptyStreets':
				case 'onlyPolygonUnknowAddresses':
					return this.$t('ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_EMPTY_STREETS_ONLY')

				case 'onlyStreetsAllStreets':
				case 'onlyPolygonCombineContactsAndUnknowAddresses':
					return this.$t(
						'ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_WITH_EMPTY_STREETS_AND_CONTACTS',
					)

				default:
					return ''
			}
		},
		descWordingInformationByOptionSelected() {
			switch (this.isUserChooseOption) {
				case 'onlyContacts':
					if (this.isEmptyPolygonSelected)
						return this.$t(
							'ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_EMPTY_CREATE_EMPTY_ACTION_DESC',
						)
					return this.$t('ACTION.CALL_TO_ACTION.PRECISION.ZONE_WITH_CONTACTS_ONLY_DESC')

				case 'onlyStreetsEmptyStreets':
				case 'onlyPolygonUnknowAddresses':
					return this.$t('ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_EMPTY_STREETS_ONLY_DESC')

				case 'onlyStreetsAllStreets':
				case 'onlyPolygonCombineContactsAndUnknowAddresses':
					return this.$t(
						'ACTION.CALL_TO_ACTION.PRECISION.ZONE_POLYGON_WITH_EMPTY_STREETS_AND_CONTACTS_DESC',
					)

				default:
					return ''
			}
		},
	},
	watch: {
		selectContactsAbsentOrNotVisited(value) {
			this.actionSetNewActionSelectedNotPresentOrNotVisited({ absentOrNeverMetStatus: value })
			this.methodFetchAddressContactAsideBlocPrecisionPage()
		},

		getContactsAction: {
			handler(newVal) {
				this.selectedContacts = newVal
			},
		},

		computedGetValidator: {
			handler(newVal, oldVal) {
				this.$emit('update:nextValidatorBoolean', newVal)
				if (oldVal === true && newVal === false) this.$emit('update:errorMessage', this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.CHOOSE_OPTION_BEFORE_CONTINUE'))
			},
		},

	},
	/**
	 * Sorry for the shortcut and the use of the beforeMount.
	 * With this process, the selectContacts works correctly with
	 * the q-check in the child. Without this code, the selectContacts
	 */
	beforeMount() {
		if (!this.form_filter_data.length) {
			this.selectContactsAbsentOrNotVisited = this.getNewActionIsFilterAbsentOrNeverSeenSelected
		}
	},
	mounted() {
		this.actionSetStep('PRECISION')
		this.methodFetchAddressContactAsideBlocPrecisionPage()
		this.$emit('update:nextFunction', this.setOptionPrecisionForNewAction)
	},
	methods: {
		...mapActions('@action', [
			'actionSetStep',
			'actionSetNewActionPrecision',
			'actionSetNewActionSelectedNotPresentOrNotVisited',
			'actionSetNewActionSelectedNeverMet',
			'actionSetNewActionFilters',
			'actionSearchContact',
		]),

		...mapActions('@search', ['actionResetAddressIncluded']),

		methodFetchAddressContactAsideBlocPrecisionPage: debounce(function () {
			const addressIncluded = this.getSearchAddressIncluded
			const pollingStationsIncluded = this.getSearchPollingStationsIncluded
			this.actionSearchContact({ addressIncluded, pollingStationsIncluded })
		}, 300),

		leaveActionCreationClick() {
			const beforeEraseEntryPoint = this.entryPoint
			let options = { name: beforeEraseEntryPoint, query: this.$route.query }
			if (this.polygon.length && beforeEraseEntryPoint != 'profiles') options.query = { ...options.query, savePolygon: true }
			this.$router.push(options)
		},
		/**
		 * @func setOptionPrecisionForNewAction
		 * @desc once the user use the next button,
		 * set the option in vuex to create the action
		 * later to know how to create the action
		 */
		setOptionPrecisionForNewAction() {
			/**
			 * Possible values for this.userChooseOption
			 * onlyContacts
			 * onlyStreetsEmptyStreets
			 * onlyStreetsAllStreets
			 * onlyPolygonUnknowAddresses
			 * onlyPolygonCombineContactsAndUnknowAddresses
			 */
			switch (this.isUserChooseOption) {
				case 'onlyStreetsEmptyStreets':
				case 'onlyStreetsAllStreets':
				case 'onlyPolygonUnknowAddresses':
				case 'onlyPolygonCombineContactsAndUnknowAddresses':
				case 'onlyContacts':
				case 'emptyAction':
				case 'onlyPolygonIsTooBig':
				case '':
					this.actionSetNewActionPrecision(this.isUserChooseOption)
					break
				default:
					break
			}
		},
	},
}
</script>
