<template>
	<div>
		<button
			type="button"
			class="flex items-center gap-x-2 w-full h-14 border rounded-xl mb-1 font-medium px-3"
			:class="{ 'border-green-medium': isNetworkActive('custom') }"
			@click="() => toggleSocialButton('custom')"
		>
			<span class="rounded-full bg-gray-light w-8 h-8 flex items-center justify-center --custom">
				<i class="gui-link text-xl" />
			</span>
			{{ $t('ONLINE_FORMS.NETWORKS.CUSTOM_LINK') }}
			<i
				v-if="isNetworkActive('custom')"
				class="gui-yes text-2xl ml-auto"
			/>
		</button>

		<el-divider />

		<button
			v-for="network in socialNetworks"
			:key="network"
			type="button"
			class="flex items-center gap-x-2 w-full h-14 border rounded-xl mb-1 font-medium px-3"
			:class="{ 'border-green-medium': isNetworkActive(network) }"
			@click="() => toggleSocialButton(network)"
		>
			<span
				class="rounded-full w-8 h-8 flex items-center justify-center"
				:class="`--${network}`"
				:style="`background-color: ${mainColor};--social-button-size: 34px;`"
			>
				<social-button
					v-model:online-form="computedForm"
					:network="{ name: network.toLocaleLowerCase(), full: true, label: '' }"
					width="34px"
					height="34px"
				/>
			</span>
			{{ network }}

			<i
				v-if="isNetworkActive(network)"
				class="gui-yes text-2xl ml-auto"
			/>
		</button>

		<div class="flex justify-center">
			<button
				type="button"
				class="q-button whitespace-nowrap mt-8 hover:opacity-60"
				style="min-width: 160px"
				@click="() => actionSetParamsBar({ show: true, selection: 'share' })"
			>
				{{ $t('_COMMON.NEXT') }}
			</button>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
	components: {
		SocialButton: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/socialButton.vue'),
		),
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
	},
	emits: ['update:form'],
	data() {
		return {
			isAddingNetwork: false,

			share: [],
			socialNetworks: [
				'Bluesky',
				'Facebook',
				'Instagram',
				'Linkedin',
				'Mastodon',
				'Messenger',
				'Snapchat',
				'Telegram',
				'Threads',
				'Tiktok',
				'Twitch',
				'Wechat',
				'Whatsapp',
				'X',
				'Youtube',
			],
			mainColor: undefined,
			secondColor: undefined,
		}
	},
	computed: {
		...mapGetters('@createForm', ['paramsBar']),
		...mapGetters('@onlineForm', ['socialNetworkOptions']),

		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
	},

	beforeMount() {
		this.share = cloneDeep(this.form.share)
	},
	methods: {
		...mapActions('@createForm', ['actionSetParamsBar']),
		...mapActions('@onlineForm', ['actionSetSocialsocialNetworkOptions']),

		toggleSocialButton(network) {
			const networkLowerCase = network.toLowerCase()

			if (this.isNetworkActive(network)) {
				this.share = this.share.filter((s) => s.name !== networkLowerCase)
			}
			else {
				const networkLabel
					= network === 'custom' ? this.$t('ONLINE_FORMS.NETWORKS.CUSTOM_LINK') : network

				const newNetwork = {
					name: networkLowerCase,
					link: '',
					color: this.socialNetworkOptions.color,
					full: this.socialNetworkOptions.full,
					label: this.socialNetworkOptions.label ? networkLabel : '',
				}

				this.share.push(newNetwork)
			}
			this.computedForm.share = this.share
		},

		isNetworkActive(network) {
			return this.share.find((s) => s.name === network.toLowerCase())
		},
	},
}
</script>

<style scoped>
.--Instagram {
	background: radial-gradient(
			51.8% 49.8% at 36.25% 96.55%,
			#ffd600 0%,
			#ff6930 48.44%,
			#fe3b36 73.44%,
			rgba(254, 59, 54, 0) 100%
		),
		radial-gradient(
			182.65% 122.8% at 84.5% 113.5%,
			#ff1b90 24.39%,
			#f80261 43.67%,
			#ed00c0 68.85%,
			#c500e9 77.68%,
			#7017ff 89.32%
		);
}

.--Messenger {
	background: linear-gradient(74.53deg, #2771e9 0.04%, #792ee7 27.39%, #a82acc 58%, #d5504b 90.58%);
}
</style>
