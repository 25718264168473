<template>
	<div id="affectations-users" ref="el" class="w-full flex flex-row gap-x-4">
		<span
			v-for="aUser in computedGetUsersByIdList"
			:key="aUser?.id"
			class="flex flex-row gap-x-2 items-center"
		>
			<img v-if="aUser.avatar" :src="aUser?.avatar" class="w-4 h-4 rounded-full" />
			<span>{{ methodFormatUsername(aUser) }}</span>
		</span>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'

const el = ref(null)
const { width } = useElementSize(el)

const props = defineProps<{ idList: number | null; allUsers: Array<object> }>()

const computedGetUsersByIdList = computed(() => {
	return props.allUsers.filter((user: any) => props.idList === user.role_data?.saved_filters_id)
})

const methodFormatUsername = (user: any) => {
	if (!user) return ''
	return `${user?.firstname} ${user?.surname ? user?.surname[0] : ''}.`
}
</script>

<style scoped></style>
