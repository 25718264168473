<template>
	<div class="params-bar__share-part">
		<p class="text-pink-main text-sm mb-3">
			{{ param.link.label }}
		</p>

		<button
			type="button"
			class="w-full relative flex"
			@click="() => copy(`${computedUrl}${computedForm.slug}/`)"
		>
			<el-input
				v-model="computedForm.slug"
				name="slug"
				type="url"
				disabled
				class="cursor-pointer"
			>
				<template #prepend>
					<span
						class="text-gray-strong truncate ..."
						style="font-size: 13px"
					>
						{{ computedUrl }}
					</span>
				</template>
			</el-input>
		</button>

		<button
			type="button"
			class="text-pink-main pl-5 mb-8 cursor-pointer hover:opacity-60"
			style="font-size: 13px"
			@click="() => copy(`${computedUrl}${computedForm.slug}/`)"
		>
			{{ param.link.cta }}
		</button>

		<div
			v-for="(network) in computedNetworksOrderByName"
			:key="network.name"
		>
			<template v-if="computedForm.share.find((s) => network.name.includes(s.name))">
				<div
					v-if="network.title"
					class="flex justify-between pt-5 mb-5 border-t"
				>
					<p class="font-bold flex items-center gap-x-3">
						<i
							class="bg-gray-light rounded-full w-8 h-8 flex justify-center items-center"
							:class="methodSetNetworkClasses(network.name)"
						/>
						{{ network.title }}
					</p>
					<el-switch
						v-model="computedForm.share.find((s) => network.name.includes(s.name)).show"
						:inactive-text="
							computedForm.share.find((s) => network.name.includes(s.name)).show
								? $t('CREATE_FORM.SETTINGS.SHARE.SHOWED')
								: $t('CREATE_FORM.SETTINGS.SHARE.HIDDEN')
						"
						class="w-38"
						style="margin-top: 3px"
					/>
				</div>

				<CreateFormInput
					v-if="
						networksWithText.includes(network.name) &&
							computedForm.share.find((s) => network.name.includes(s.name)).show
					"
					v-model:value="computedForm.share.find((s) => network.name.includes(s.name)).text"
					:input-data="network"
				/>

				<CreateFormInput
					v-if="
						network.name === 'email_object' &&
							computedForm.share.find((s) => network.name.includes(s.name)).show
					"
					v-model:value="computedForm.share.find((s) => network.name.includes(s.name)).subject"
					:input-data="network"
				/>

				<CreateFormInput
					v-if="
						network.name === 'email_message' &&
							computedForm.share.find((s) => network.name.includes(s.name)).show
					"
					v-model:value="computedForm.share.find((s) => network.name.includes(s.name)).body"
					:input-data="network"
				/>
			</template>
		</div>

		<button
			v-if="param.cta"
			type="button"
			:disabled="isLoading"
			class="q-button block mx-auto"
			:class="{ disabled: isLoading }"
			style="min-width: 160px"
			@click="param.cta.onClick"
		>
			{{ param.cta.text }}
		</button>
	</div>
</template>

<script>
import CreateFormInput from '../inputs/createFormInput.vue'
import { orderBy } from 'lodash'

export default {
	components: { CreateFormInput },
	props: {
		param: { type: Object, default: () => {}, required: true },
		form: { type: Object, default: () => {}, required: true },
		formType: { type: String, default: '', required: true },
		copy: { type: Function, default: () => {}, required: true },
		isLoading: { type: Boolean, default: false, required: true },
	},
	emits: ['update:form'],
	data() {
		return {
			networksWithText: ['x', 'twitter', 'whatsapp', 'telegram', 'threads', 'bluesky', 'mastodon'],
		}
	},
	computed: {
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},

		computedUrl() {
			// eslint-disable-next-line no-undef
			return PETITIONS_ENDPOINT_URL
		},

		computedNetworksOrderByName() {
			let sorted = orderBy(this.param.networks, ['title'])

			// Put email message below email object
			const emailObjectIndex = sorted.findIndex((item) => item.name === 'email_object')
			const emailMessageIndex = sorted.findIndex((item) => item.name === 'email_message')

			if (emailObjectIndex !== -1 && emailMessageIndex !== -1) {
				// Remove email_message from its current position
				const [emailMessage] = sorted.splice(emailMessageIndex, 1)
				// Insert email_message right after email_object
				sorted.splice(emailObjectIndex + 1, 0, emailMessage)
			}

			// Put the url at the end
			const urlIndex = sorted.findIndex((item) => item.name === 'url')
			if (urlIndex !== -1) {
				const [url] = sorted.splice(urlIndex, 1)
				sorted.push(url)
			}

			return sorted
		},
	},

	methods: {
		methodSetNetworkClasses(networkName) {
			switch (networkName) {
				case 'email_object':
					return 'gui-mail text-xl'
				case 'x':
				case 'twitter':
					return 'gui-x text-sm'
				case 'url':
					return 'qi-share text-xl'
				default:
					return `gui-${networkName} text-xl`
			}
		},
	},
}
</script>

<style lang="scss">
.params-bar__share-part {
	.el-input-group {
		display: flex;

		&__prepend {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			overflow-x: hidden;
			text-overflow: ellipsis;
			display: flex;
			align-items: center;
		}
	}

	.el-input__wrapper {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	.el-input__count {
		display: block;
		top: 0px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
