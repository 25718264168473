import { $request } from './requester'

export default {
	async getListApiKeys() {
		return $request('GET', '/access_token').then((res) => res.body.data)
	},

	async addApiKey(data) {
		return $request('POST/FORM', '/access_token', data).then((res) => res.body.data)
	},

	async deleteApiKey(accessToken) {
		return $request('DELETE', `/access_token?access_token=${accessToken}`).then((res) => res)
	},
}
