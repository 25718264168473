<template>
	<div class="flex flex-col items-center">
		<h1 class="gtDisplayBold text-center text-4xl mb-5">
			{{ $t(`APP.SIGNUP.GO`) }}
		</h1>
		<div v-if="computedIsWhiteLabel" class="flex items-center justify-center mt-2 mb-8">
			<img class="w-24" :src="computedGetImageWhiteLabel" />
		</div>
		<p class="mb-10 bold">
			{{ $t(`APP.SIGNUP.ADD_CODE`) }}
		</p>

		<signup-input v-model:input-data="computedGroupInvitation" />

		<transition-group
			v-if="computedGroupInvitation.error"
			name="fade-in"
			mode="out-in"
			class="-mt-3 mb-4 block w-full"
		>
			<div :key="computedGroupInvitation.error" class="flex text-sm text-gray-strong">
				<i class="gui-no text-gray-strong mr-1" style="margin-top: 3px" />
				<span>{{ computedGroupInvitation.error }}</span>
			</div>
		</transition-group>

		<collapse :title="$t(`APP.SIGNUP.FIND_CODE`)" class="mt-7 mb-3 sm:mb-2">
			<p class="text-gray-strong text-sm">
				{{ $t(`APP.SIGNUP.JOIN_GROUP`) }}
			</p>

			<p class="text-gray-strong text-sm pt-3 pb-8">
				{{ $t(`APP.SIGNUP.CONTACT_TEAM`) }}
				<a class="underline cursor-pointer hover:text-pink-main" @click="openChat">
					{{ $t(`APP.SIGNUP.CONTACT_TEAM_CTA`) }}</a
				>.
			</p>
		</collapse>

		<collapse v-if="!computedIsWhiteLabel" :title="$t(`APP.SIGNUP.NO_GROUP_ON_QOMON`)">
			<p class="text-gray-strong text-sm">
				{{ $t(`APP.SIGNUP.NO_GROUP`) }}
				<a
					:href="$t(`APP.SIGNUP.URLS.DEMO`)"
					target="_blank"
					class="underline hover:text-pink-main"
				>
					{{ $t(`APP.SIGNUP.NO_GROUP_CTA`) }}</a
				>.
			</p>
		</collapse>
	</div>
</template>

<script>
import SignupInput from './signupInput.vue'
import Collapse from '../../general/collapse-panel.vue'
import { openChat } from '../../../extensions/support'
import store from '../../../store/stateStore'

export default {
	components: {
		SignupInput,
		Collapse,
	},

	// beforeRouteEnter(to, from, next) {
	// 	const allowedRoutes = ['login', 'login-auth0-callback', 'add-account-details']

	// 	if (
	// 		from.name === 'login' &&
	// 		Object.keys(store.getters['@authentication/getCredentialsEntered']).length === 0
	// 	) {
	// 		next('login')
	// 	} else if (!allowedRoutes.includes(from.name)) {
	// 		next('login')
	// 	} else {
	// 		next()
	// 	}
	// },

	props: {
		groupInvitation: { type: Object, required: true },
	},
	emits: ['update:group-invitation'],

	computed: {
		computedGroupInvitation: {
			get() {
				return this.groupInvitation
			},
			set(value) {
				this.$emit('update:group-invitation', value)
			},
		},

		computedGroupInvitationCode() {
			return this.groupInvitation.value
		},

		computedIsWhiteLabel() {
			return !!XCUSTOM_USER_AGENT
		},

		computedGetImageWhiteLabel() {
			if (XCUSTOM_USER_AGENT === 'kennedy') return '/static/logo/RFKActivate2024.svg'
			return ''
		},
	},

	watch: {
		computedGroupInvitationCode(value) {
			this.computedGroupInvitation.error = ''
		},
	},

	methods: {
		openChat,
	},
}
</script>
