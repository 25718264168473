<template>
	<div
		:key="question.order"
		class="q-card elastic clickable"
		:class="
			getSynchronizationType && getSynchronizationType.type === 'nationbuilder' ? 'pt-4' : 'pt-10'
		"
	>
		<div
			v-if="getSynchronizationType && getSynchronizationType.type === 'nationbuilder'"
			class="ml-2 mb-8"
		>
			<span
				v-t="$t(`SETTINGS.FORM_BUILDER.${getSynchronizationType.type.toUpperCase()}_TO_ENABLE`)"
			/>
			<el-tooltip
				class="item"
				effect="dark"
				placement="top"
				:visible-arrow="false"
			>
				<template #content>
					<div>
						{{
							$t(
								`SETTINGS.FORM_BUILDER.${getSynchronizationType.type.toUpperCase()}_SWITCH_EXPLANATION`
							)
						}}
					</div>
				</template>
				<span class="q-p-sm-l pointer qi-infos offset-qi-infos q-is-info" />
			</el-tooltip>
			<el-switch
				id="tag-for-nb"
				v-model="computedQuestion.tag_for_nb"
				class="ml-2"
				@change="allowButtonUpdate"
			/>
		</div>
		<div
			class="flex items-center"
			:class="{ 'pt-6': computedQuestion.label }"
		>
			<div
				v-if="statusFormsType === 'all'"
				class="action qi-order qi-2x"
			/>
			<div
				v-else
				style="width: 10px"
			/>
			<el-select
				v-if="statusFormsType === 'all'"
				v-model="computedQuestion.type"
				style="max-width: 200px"
				@change="updateQuestionType($event, question)"
			>
				<el-option
					v-for="aType in i18nSortedQuestionTypes"
					:key="aType.id"
					:value="aType.type"
					:label="aType.label"
				/>
			</el-select>
			<div
				v-else
				style="
					border: solid;
					border-width: 1px;
					border-radius: 5px;
					height: 40px;
					width: 186px;
					display: flex;
					color: rgb(226, 232, 240);
				"
			>
				<span style="padding-top: 8px; padding-left: 15px; color: #606266">{{
					t.questionType(statusFormsType)
				}}</span>
			</div>
			<div class="expander q-m-lg-l q-m-lg-r relative grow">
				<label
					for="question_label"
					class="absolute -top-5 text-sm text-gray-400"
				>
					{{ t.question }}
				</label>
				<el-input
					id="question_label"
					v-model="computedQuestion.label"
					@input="updateCurrentForm()"
					@focus="focus"
				/>
			</div>

			<div
				v-if="!isUniqueStatus"
				class="flex flex-row"
			>
				<div
					v-if="
						question.type === 'text' ||
							question.type === 'date' ||
							question.type === 'range' ||
							question.type === 'photos' ||
							question.type === 'numeric' ||
							question.type === 'qr_code' ||
							question.type === 'signature'
					"
					class="flex flex-row"
				>
					<div
						v-for="(ref, refi) in question.refvalues"
						:key="ref.id || refi"
						class="q-row pr-2 mr-4"
					>
						<automatic-mail
							:a-ref-of-question="ref"
							:question="question"
							:question-index="questionIndex"
							:section-index="sectionIndex"
							:auto-send-toggle-click="
								() => {
									autoSendToggleClick(sectionIndex, questionIndex, refi)
								}
							"
						/>

						<automatic-sms
							:a-ref-of-question="ref"
							:question="question"
							:question-index="questionIndex"
							:section-index="sectionIndex"
							:auto-send-sms-toggle-click="
								() => {
									autoSendSmsToggleClick(sectionIndex, questionIndex, refi)
								}
							"
						/>
					</div>
				</div>

				<q-filter
					v-model="computedQuestion.filterbyidrefvalue"
					:state="computedQuestion.filterbyidrefvalue"
					:color="getColorById(question.filterbyidrefvalue)"
					@change="$emit('conditionnalize', question)"
				>
					<i class="fa fa-link" />
				</q-filter>
			</div>
			<div
				class="action danger qi-close qi-2x ml-3 pr-1"
				:class="{
					'absolute top-2.5 right-2.5':
						getSynchronizationType.type && getSynchronizationType.type === 'nationbuilder',
				}"
				@click="removeQuestion"
			/>
		</div>
		<div
			v-if="
				question.type !== 'text' &&
					question.type !== 'date' &&
					question.type !== 'photos' &&
					question.type !== 'numeric' &&
					question.type !== 'qr_code' &&
					question.type !== 'signature'
			"
			class="q-card-content gap-2 pt-4"
		>
			<template v-if="question.type !== 'text' && question.type !== 'date'">
				<template v-if="question.type === 'range'">
					<div class="relative">
						<span class="absolute left-2.5 -top-3 text-sm text-gray-400">
							{{ t.min }}
						</span>
						<el-input
							v-model="computedQuestion.refvalues[0].min"
							class="q-m"
							type="number"
							:invalid="isNaN(question.refvalues[0].min)"
							@input="updateCurrentForm('refvalues')"
						>
							<span>
								{{ t.mustBeNumber }}
							</span>
						</el-input>
					</div>

					<div class="relative">
						<span class="absolute left-2.5 -top-3 text-sm text-gray-400">
							{{ t.step }}
						</span>

						<el-input
							v-model="computedQuestion.refvalues[0].step"
							class="q-m"
							type="number"
							:invalid="isNaN(question.refvalues[0].step)"
							@input="updateCurrentForm('refvalues')"
						>
							<span>
								{{ t.mustBeNumber }}
							</span>
						</el-input>
					</div>
					<div class="relative">
						<span class="absolute left-2.5 -top-3 text-sm text-gray-400">
							{{ t.max }}
						</span>

						<el-input
							v-model="computedQuestion.refvalues[0].max"
							class="q-m"
							type="number"
							:invalid="isNaN(question.refvalues[0].max)"
							@input="updateCurrentForm('refvalues')"
						>
							<span>
								{{ t.mustBeNumber }}
							</span>
						</el-input>
					</div>
				</template>
				<div
					v-else
					class="q-fullwidth"
				>
					<div
						v-for="(ref, refi) in computedQuestion.refvalues"
						:key="ref.id || refi"
						class="q-row"
					>
						<span>
							<el-tooltip
								v-if="$route.query.q === 'ACTION'"
								placement="top"
								:content="t.tooltipAction"
							>
							<el-switch
									v-model="ref.followed"
									:checked="ref.followed === true"
									@change="follow"
								/>
							</el-tooltip>
						</span>

						<div
							class="q-card elastic expander"
							:pinable="false"
						>
							<div class="q-row space-between">
								<div
									v-if="question.name === 'STATUS'"
									class="custom-picker"
								>
									<el-color-picker
										v-model="ref.color"
										size="small"
										:predefine="predefineColors"
										@change="$emit('handleNeedAPublish', true)"
									/>
								</div>
								<div
									v-if="filterSomeone(ref)"
									class="square-caption-display"
									:style="`background-color: #${getColorById(ref.id)}`"
								/>
								<div class="expander q-m-lg-l q-m-lg-r flex gap-4 items-center w-full">
									<el-input
										v-model="ref.label"
										:class="
											getSynchronizationType && getSynchronizationType.type && question.tag_for_nb
												? 'w-7/12'
												: 'w-full'
										"
										@input="updateCurrentForm('refvalues')"
										@focus="focus"
										@focusout="focus"
									/>
									<div
										v-if="
											getSynchronizationType && getSynchronizationType.type && question.tag_for_nb
										"
										class="flex flex-grow"
									>
										<el-dropdown
											v-if="currentFormNameFromRoute === 'status'"
											size="mini"
											@command="handleCommand($event, ref)"
										>
											<small>
												{{ ref.type ? t.types[ref.type] : t.typeTitle }}
												<span class="qi-arrow-down" />
											</small>
											<template #dropdown>
												<el-dropdown-menu>
													<el-dropdown-item command="">
														{{ t.types.none }}
													</el-dropdown-item>
													<el-dropdown-item
														v-for="type2 in types"
														:key="type2.id"
														:command="type2"
													>
														{{ t.types[type2] }}
													</el-dropdown-item>
												</el-dropdown-menu>
											</template>
										</el-dropdown>
									</div>
								</div>

								<automatic-mail
									:a-ref-of-question="ref"
									:question="question"
									:question-index="questionIndex"
									:section-index="sectionIndex"
									:auto-send-toggle-click="
										() => {
											autoSendToggleClick(sectionIndex, questionIndex, refi)
										}
									"
								/>

								<automatic-sms
									:a-ref-of-question="ref"
									:question="question"
									:question-index="questionIndex"
									:section-index="sectionIndex"
									:auto-send-sms-toggle-click="
										() => {
											autoSendSmsToggleClick(sectionIndex, questionIndex, refi)
										}
									"
								/>

								<div
									v-if="
										(question.type === 'checkbox' && question.refvalues.length > 2) ||
											question.type !== 'checkbox'
									"
									class="action danger qi-close qi-2x ml-2"
									@click="showWarningDeleteResponse(refi)"
								/>
							</div>
						</div>
						<div
							v-if="!isUniqueStatus"
							class="q-m-l"
						>
							<q-filter
								v-model="ref.filterbyidrefvalue"
								:state="ref.filterbyidrefvalue"
								:color="getColorById(ref.filterbyidrefvalue)"
								@change="$emit('conditionnalize-ref', ref)"
							>
								<i class="fa fa-link" />
							</q-filter>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div
			v-if="
				question.type !== 'text' &&
					question.type !== 'date' &&
					question.type !== 'range' &&
					question.type !== 'photos' &&
					question.type !== 'numeric' &&
					question.type !== 'qr_code' &&
					question.type !== 'signature'
			"
			class="q-fullwidth q-p center q-card-footer"
		>
			<q-button
				icon="plus"
				link
				secondary
				@click="addResponse()"
			>
				{{ t.addAnswer }}
			</q-button>
		</div>
	</div>
</template>

<script>
import { templatesDictionnary } from './templatesDictionnary'
import { firstBy } from 'thenby'
import { mapGetters, mapActions } from 'vuex'
import { getColorById } from './color'
import { mappingStatutColor } from '../../contact-module/contactDetails/contactStatusColors'
import { defineAsyncComponent } from 'vue'
import { cloneDeep, isEqual } from 'lodash'

export default {
	components: {
		automaticMail: defineAsyncComponent(() => import('../form-builder/automatic-mail.vue')),
		automaticSms: defineAsyncComponent(() => import('../form-builder/automatic-sms.vue')),
	},

	props: {
		question: {
			type: Object,
			required: true,
		},
		questionIndex: {
			type: Number,
			required: true,
		},
		sectionIndex: {
			type: Number,
			required: true,
		},
		forms: {
			type: Array,
			default: () => [],
		},
		currentFormNameFromRoute: {
			type: String,
			required: false,
			default: '',
		},
		isUniqueStatus: {
			type: Boolean,
			required: false,
			default: false,
		},
		statusFormsType: {
			type: String,
			required: false,
			default: 'all',
		},
	},
	emits: [
		'conditionnalize',
		'handleNeedAPublish',
		'conditionnalize-ref',
		'focus',
		'update',
		'remove',
		'autoSendToggle',
		'autoSendSmsToggle',
		'update:question',
	],
	data() {
		return {
			backupQuestion: {},
			color: 'rgba(255, 69, 0, 0.68)',
			predefineColors: [
				// rose
				'#F9E8E8',
				'#ffd0d0',
				'#FF8383',
				'#ff6a6a',

				// noir
				'#C8C7C7',
				'#858585',
				'#000000',
				// violet
				'#efe2fc',
				'#C99BF4',
				'#A660E7',
				// blue
				'#c2edfb',
				'#4acaf4',
				'#0958A3',
				//   green
				'#0ff89b',
				'#04C176',
				'#048451',
				// jaune
				'#FFE383',
				'#FFD622',
				'#F4C90E',

				'#F7BA65',
			],
		}
	},

	computed: {
		...mapGetters('@form', ['form', 'formCond', 'backupFormName', 'isFormModified']),
		...mapGetters(['sections', 'getSynchronizationType']),
		...mapGetters('@packs', [
			'getAccessStatusFormsTypologiesQRCode',
			'getAccessStatusSurvey',
			'getAccessStatusSurveyAutoEmail',
		]),
		...mapGetters('@group', ['getGroupLocale']),

		t() {
			const prefix = 'SETTINGS.FORM_BUILDER'
			const prefixCommon = '_COMMON'
			return {
				min: this.$t(`${prefix}.MIN`),
				max: this.$t(`${prefix}.MAX`),
				step: this.$t(`${prefix}.STEP`),
				type: this.$t(`${prefix}.TYPE`),
				typeTitle: this.$t(`${prefix}.TYPES.NB_STATUS_TITLE`),
				question: this.isNormalFrom ? this.$t(`${prefix}.QUESTION`) : this.$t(`${prefix}.FIELD`),
				addAnswer: this.isNormalFrom
					? this.$t(`${prefix}.ADD_ANSWER`)
					: this.$t(`${prefix}.ADD_SUBFIELD`),
				mustBeNumber: this.$t(`${prefix}.MUST_BE_NUMBER`),
				tooltipSupport: this.$t(`${prefix}.TOOLTIP_SUPPORT`),
				tooltipAction: this.$t(`${prefix}.TOOLTIP_ACTION`),
				mailAutoActived: this.$t(`${prefix}.MAIL_AUTO_ACTIVED`),
				mailAutoDesactived: this.$t(`${prefix}.MAIL_AUTO_DESACTIVED`),
				deleteAnswer: this.$t(`${prefix}.DELETE_ANSWER`),
				deleteAnswerConfirmed: this.$t(`${prefix}.DELETE_ANSWER_CONFIRMED`),
				deleteAnswerPart: this.$t(`${prefix}.DELETE_ANSWER_INFO_PART`),
				types: {
					none: this.$t(`${prefix}.TYPES.NB_STATUS.NONE`),
					// STATUS
					// prospect: this.$t(`${prefix}.TYPES.STATUS.PROSPECT`),
					enforceSupport: this.$t(`${prefix}.TYPES.NB_STATUS.SUPPORT`),
					uncertain: this.$t(`${prefix}.TYPES.NB_STATUS.UNCERTAIN`),
					enforceSupportWeak: this.$t(`${prefix}.TYPES.NB_STATUS.SUPPORTWEAK`),
					// member: this.$t(`${prefix}.TYPES.STATUS.MEMBER`),
					// avoid: this.$t(`${prefix}.TYPES.STATUS.AVOID`),
					opponentWeak: this.$t(`${prefix}.TYPES.NB_STATUS.OPPONENTWEAK`),
					opponent: this.$t(`${prefix}.TYPES.NB_STATUS.OPPONENT`),
					// TASKS
					phone: this.$t(`${prefix}.TYPES.TASKS.PHONE`),
					mail: this.$t(`${prefix}.TYPES.TASKS.MAIL`),
					invite: this.$t(`${prefix}.TYPES.TASKS.INVITE`),
					report: this.$t(`${prefix}.TYPES.TASKS.REPORT`),
					meet: this.$t(`${prefix}.TYPES.TASKS.TO_MEET`),
					recontact: this.$t(`${prefix}.TYPES.TASKS.RECONTACT`),
					other: this.$t(`${prefix}.TYPES.TASKS.OTHER`),
				},
				delete: this.$t(`${prefixCommon}.DELETE`),
				cancel: this.$t(`${prefixCommon}.CANCEL`),

				questionType: (type) =>
					this.$t(`${prefix}.QUESTIONS_TYPE.${type.toString().toUpperCase()}`),
			}
		},

		computedQuestion: {
			get() {
				return this.question
			},
			set(value) {
				this.$emit('update:question', value)
			},
		},

		computedRenderTemplatesDictionnary() {
			const locale = this.getGroupLocale
			return templatesDictionnary(locale)
		},

		isNormalFrom() {
			return this.$route.name !== 'lead-form'
		},

		/**
		 * @member {String[]} i18nSortedQuestionTypes
		 * @desc translate the types and order them for the given locale
		 */
		i18nSortedQuestionTypes() {
			let questionTypes = [
				'text',
				'checkbox',
				'radio',
				'date',
				'range',
				'photos',
				'numeric',
				'qr_code',
				'signature',
			]
			if (this.getAccessStatusFormsTypologiesQRCode === 'disabled')
				questionTypes = questionTypes.filter((aQuestionType) => aQuestionType !== 'qr_code')
			return questionTypes
				.map((q) => {
					return {
						type: q,
						label: this.t.questionType(q),
					}
				})
				.sort(firstBy('label'))
		},

		types() {
			const groupLocale = this.getGroupLocale
			const locale = groupLocale === 'fr' || groupLocale === 'en' ? groupLocale : 'en'
			if (this.$route.query.q === 'ACTION') {
				const template = this.computedRenderTemplatesDictionnary[`template_nb_action_${locale}`]
				return template[0].refvalues.map((aRefValue) => aRefValue.type)
			}
			else if (this.$route.query.q === 'STATUS') {
				const template = this.computedRenderTemplatesDictionnary[`template_nb_status_${locale}`]
				return template[0].refvalues.map((aRefValue) => aRefValue.type)
			}
			return []
		},
	},

	mounted() {
		this.$emitter.on('update-cond-pin', () => {
			this.$forceUpdate()
		})
		if (this.statusFormsType !== 'all') {
			this.computedQuestion.type = this.statusFormsType
			this.updateQuestionType(this.statusFormsType, this.question)
		}

		this.backupQuestion = cloneDeep(this.question)
	},

	methods: {
		...mapActions('@form', ['changeTheModeOfThePublish']),
		getColorById,
		allowButtonUpdate() {
			this.changeTheModeOfThePublish(true)
		},
		focus(event) {
			if (event.type === 'text') {
				this.$emit('focus', event.value)
			}
		},

		follow() {
			this.updateCurrentForm('refvalues')
		},

		handleCommand(command, ref) {
			ref.type = command
			if (mappingStatutColor && ref?.type && mappingStatutColor[ref?.type]) {
				ref.color = mappingStatutColor[ref?.type]
			}

			this.$emit('handleNeedAPublish', true)
			this.$forceUpdate()
		},

		/**
		 * @method updateQuestionType
		 * @param {String} value new type to handle
		 * @param {Object} question the question to edit
		 */
		updateQuestionType(value, question) {
			switch (value) {
				case 'range':
					question.refvalues = [
						{
							min: 1,
							max: 10,
							step: 1,
						},
					]
					break
				case 'checkbox':
					if (!question.refvalues[0].label) {
						question.refvalues = [
							{
								label: '#1',
								order: 0,
							},
							{
								label: '#2',
								order: 1,
							},
						]
					}
					break
				case 'radio':
					if (!question.refvalues[0].label) {
						question.refvalues = [
							{
								label: '#1',
								order: 0,
							},
						]
					}
					break
				default:
					question.refvalues = [{}]
					break
			}
			this.updateCurrentForm('type')
			this.$emit('update')
		},

		removeQuestion() {
			this.$emit('remove', this.sectionIndex, this.questionIndex)
		},

		/**
		 * @method addResponse
		 * @desc push new text question to the form-builder
		 */
		addResponse() {
			const firstValueLabel = `#${this.sectionIndex + 1}.${this.questionIndex + 1}.${
				this.question.refvalues.length + 1
			}`
			this.computedQuestion.refvalues.push({
				label: firstValueLabel,
				value: firstValueLabel,
				order: this.question.refvalues.length,
			})
			this.updateCurrentForm('refvalues')
		},

		/**
		 * @method showWarningDeleteResponse
		 * @desc show a warning before delete a response
		 */
		showWarningDeleteResponse(index) {
			this.$emit('focus', true)
			/**
			 * Set Timout is important for the first try
			 * of deleting a response.
			 */
			setTimeout(() => {
				this.$msgbox({
					title: this.t.deleteAnswer,
					customClass: 'custom_message_box',
					message: this.t.deleteAnswerPart,
					dangerouslyUseHTMLString: true,
					distinguishCancelAndClose: true,
					confirmButtonText: this.t.delete,
					showCancelButton: true,
					cancelButtonText: this.t.cancel,
				})
					.then(() => {
						this.$message({
							type: 'info',
							message: this.t.deleteAnswerConfirmed,
							showClose: true,
						})
						this.removeResponse(index)
					})
					.catch(() => null)
			}, 0)
		},

		/**
		 * @method removeResponse
		 * @desc delete one question of a given section
		 */
		removeResponse(index) {
			this.computedQuestion.refvalues.splice(index, 1)
			this.updateCurrentForm('refvalues')
			this.$emit('update')
		},

		updateCurrentForm(field = 'label') {
			const scoppedFindFormId = (form) =>
				this.question.id ? form.id === this.question.id : form.order === this.question.order

			const currentQuestion = this.forms.find(scoppedFindFormId)

			if (field === 'type') {
				currentQuestion.refvalues = this.question.refvalues
			}
			if (field === 'refvalues' && this.question.type === 'range') {
				this.computedQuestion.refvalues[0].min = Number(this.question.refvalues[0].min)
				this.computedQuestion.refvalues[0].max = Number(this.question.refvalues[0].max)
				this.computedQuestion.refvalues[0].step = Number(this.question.refvalues[0].step)
			}
			else if (field === 'refvalues') {
				if (currentQuestion.refvalues) {
					currentQuestion.refvalues.forEach((aRefValue) => {
						if (!aRefValue.id && !aRefValue.form_id) {
							aRefValue.value = aRefValue.label
						}
					})
				}
			}

			this.$emit('update', null, true)
			currentQuestion[field] = this.question[field]
			this.$emit('handleNeedAPublish', !isEqual(this.backupQuestion, this.question))
		},

		/**
		 * @method filterSomeone
		 * @param {Object} refvalue the refvalue to look for
		 * @returns {Boolean} wether someone is filtered by this ref, or not
		 */
		filterSomeone(refvalue) {
			if (refvalue) {
				return (
					refvalue.id
					&& this.forms.some((question) => {
						if (question.refvalues) {
							return (
								question.filterbyidrefvalue === refvalue.id
								|| question.refvalues.some((ref) => ref.filterbyidrefvalue === refvalue.id)
							)
						}
					})
				)
			}
		},
		autoSendToggleClick(sectionIndex, questionIndex, optionIndex) {
			let event = new CustomEvent('autoSendToggle', {
				detail: {
					sectionIndex: sectionIndex,
					questionIndex: questionIndex,
					optionIndex: optionIndex,
				},
			})
			this.$emit('autoSendToggle', event)
		},
		autoSendSmsToggleClick(sectionIndex, questionIndex, optionIndex) {
			let event = new CustomEvent('autoSendSmsToggle', {
				detail: {
					sectionIndex: sectionIndex,
					questionIndex: questionIndex,
					optionIndex: optionIndex,
				},
			})
			this.$emit('autoSendSmsToggle', event)
		},
	},
}
</script>

<style lang="sass">
.square-caption-display
	display: inline-block
	height: 1em
	width: 1em
	margin-right: 0.25em
	margin-left: 0.25em
	vertical-align: text-bottom
	border-radius: 4px
	background-color: rgba(#bebebe, 0.5)
.offset-qi-infos:before
	vertical-align: 50% !important
</style>
<style lang="sass">
.custom-picker
	.el-color-picker__trigger, .el-color-picker__color-inner, .el-color-picker__color
		@apply rounded-full
	.el-color-picker__color
		@apply  border-0
	.el-color-svpanel
		@apply hidden

.custom-picker .el-icon.el-color-picker__icon
	@apply hidden
</style>
