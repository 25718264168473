<template>
	<div class="contacts">
		<div
			v-if="isLoading"
			class="q-container flex items-center"
		>
			<div class="center q-fullwidth">
				<loader size="10em" />
			</div>
		</div>
		<iframe
			id="external-iframe-dashboard"
			ref="extiframe"
			frameborder="0"
			width="100%"
			height="100%"
			allowtransparency
			@load="load($refs.extiframe)"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '../general/loader.vue'
import { metabaseCrypto } from '../mixins/metabase-crypto-mixin'

import { METABASE } from '../../constants'

export default {
	name: 'Contacts',
	components: { Loader },
	mixins: [metabaseCrypto],

	data() {
		return {
			METABASE_SITE_URL: METABASE.URL,
		}
	},
	computed: {
		...mapGetters(['userConnected', 'isLoading', 'info_campagne']),
		iframeUrlMetabaseDashboard() {
			const isFr = this.$i18n.locale.substring(0, 2) === 'fr'
			const isUsa = XCUSTOM_USER_AGENT === 'kennedy'

			let dashboardId
			if (isUsa) dashboardId = 74
			else {
				dashboardId = isFr ? 823 : 822
			}

			var payload = {
				resource: {
					dashboard: dashboardId,
				},
				params: {
					id: this.userConnected.selected_group_id[0],
				},
			}
			return this.generateURL(payload, 'dashboard', this.info_campagne.metabase_api_key)
		},
	},
	mounted() {
		this.actionToggleLoadingState(true)
		this.$refs.extiframe.src = this.iframeUrlMetabaseDashboard
	},
	methods: {
		...mapActions(['actionToggleLoadingState']),
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/analyse/contacts.scss';
</style>
