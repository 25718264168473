<template>
	<div :class="{ 'h-full': $route.name !== 'actions-list' }">
		<delete-dialog
			:cancel="() => closeDeleteDialog()"
			:confirm="() => confirmDeleteAction()"
			:close-dialog="() => (dialogConfirmDeleteActionVisible = false)"
			:visible-dialog="dialogConfirmDeleteActionVisible"
			:title="`${$t('ACTION.LIST.DELETE_DIALOG.DELETE_ACTION')}`"
		>
			<div class="custom_dialog w-full">
				<div class="flex flex-col py-3">
					<p class="pb-2">
						{{ $t('ACTION.LIST.DELETE_DIALOG.WILL_REMOVE_ACTION') }}
					</p>

					<div id="team-users-dialog">
						<div class="flex flex-col w-full">
							<div
								v-if="actionToDelete"
								class="w-fit flex flex-row border bg-purple-light border-purple-dark rounded-xl m-1 items-center justify-center p-2 border"
								:class="
									actionToDelete.type_data
										? `bg-actionType-${actionToDelete.type_data}-light border-actionType-${actionToDelete.type_data}-main`
										: ''
								"
							>
								<div
									v-if="displayDateTime(actionToDelete)"
									class="w-10 text-center border-1 rounded-lg bg-white"
								>
									<div
										class="border-b rounded-t-lg text-black-main text-sm"
										:class="
											actionToDelete.type_data
												? `bg-actionType-${actionToDelete.type_data}-main`
												: ''
										"
									>
										{{ displayMonth(actionToDelete) }}
									</div>
									<div class="text-2xl bold text-black-main">
										{{ displayDayInMonth(actionToDelete) }}
									</div>
								</div>
								<template v-if="actionToDelete.name">
									<div class="flex flex-col pl-2">
										<p>
											{{ $t(`ACTION.LIST.TYPE.${actionToDelete.type_data.toUpperCase()}`) }}
										</p>
										<p class="bold">
											{{ actionToDelete.name }}
										</p>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class="py-3">
						<div class="border p-2 border-zinc-200 border relative rounded-lg p-4">
							<div class="p-3 rounded-lg">
								<ul class="relative">
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>
										{{ $t('ACTION.LIST.DELETE_DIALOG.NO_LONGER_ACCESSIBLE') }}
									</li>
									<li class="relative pl-5 py-1">
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										/>
										{{ $t('ACTION.LIST.DELETE_DIALOG.DATA_COLLECTED') }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</delete-dialog>

		<!--//? menu appearing at the top with the search field -->
		<div
			v-if="$route.name === 'actions-list' || computedIsActionDetails"
			class="qview"
		>
			<div
				v-show="!computedIsAssignmentEditing"
				id="actions-header"
				:class="computedIsActionDetails ? 'flex items-center flex-shrink-0 bg-white box-border' : 'q-header'"
				class=" border-none"
			>
				<action-header-menu
					:reset-search-on-before-leave="computedResetSearchOnBeforeLeave"
					:is-showing-action-details="isShowingActionDetails"
					:display-date-time="displayDateTime"
					:display-day-in-month="displayDayInMonth"
					:display-month="displayMonth"
					:open-delete-dialog="openDeleteDialog"
					:close-delete-dialog="closeDeleteDialog"
					@toggle:show-hide-action-details="actionToggleActionList($event)"
					@toggle:toggle-action-header="methodToggleActionHeader"
				/>
			</div>

			<div class="scrollable-qview-main overflow-y-auto">
				<div class="w-full">
					<router-view v-slot="{ Component }">
						<transition
							name="slide-up-faster"
							mode="out-in"
						>
							<keep-alive include="ActionList">
								<component
									:is="Component"
									ref="list"
									v-model:is-assignment-editing="isAssignmentEditing"
									class=""
									:display-date-time="displayDateTime"
									:display-day-in-month="displayDayInMonth"
									v-model:dialog-confirm-delete-action-visible="dialogConfirmDeleteActionVisible"
									:open-delete-dialog="openDeleteDialog"
									:close-delete-dialog="closeDeleteDialog"
									:display-month="displayMonth"
									@toggle:toggle-action-header="methodToggleActionHeader"
									@toggle:show-hide-action-details="actionToggleActionList($event)"
									@cancel-action-initiated="bringBackBackupDataAfterCancel"
								/>
							</keep-alive>
						</transition>
					</router-view>
				</div>
			</div>
		</div>
		<div
			v-else
			id="callToAction"
			class="h-full overflow-hidden"
		>
			<router-view />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import actionHeaderMenu from './actions/actionHeaderMenu.vue'
import callToAction from './callToAction.vue'
import deleteDialog from '.././user_management-module/user-list/sub-components/deleteDialog.vue'
import { isEqual, cloneDeep } from 'lodash'
import { formatCustomDate, formatRelativeDate, formatTime, getDisplayDateFromLocalStorage } from '../../utils/dateFnsHelper'
import { differenceInCalendarDays } from 'date-fns'
import { formatDate } from '@quorumsco/quorum-helpers/lib/date/formatDate'
/**
 * @component Action
 * @route /actions
 * @description The Action root. It just call different components
 *
 */
export default {
	components: {
		actionHeaderMenu,
		callToAction,
		deleteDialog,
	},
	beforeRouteLeave(to, from, next) {
		this.resetSearchOnBeforeLeave = true

		next()
	},
	data() {
		return {
			dataForChildren: '',
			backupDataForChildren: '',
			isShowingActionDetails: false,
			resetSearchOnBeforeLeave: false,
			isActionsListNeedToBeLoad: true,
			isAssignmentEditing: false,
			dialogConfirmDeleteActionVisible: false,
			actionToDelete: null,
		}
	},

	computed: {
		...mapGetters('@action', [
			'showDetail',
			'activeAction',
			'getIsActionCurrentlyEdited',
			'filteredActions',

		]),
		...mapGetters(['windowIsMobile', 'getActionID']),
		...mapGetters('@action', ['actions']),
		componentActionListOrDetail() {
			return this.isShowingActionDetails ? 'actionDetail' : 'actionList'
		},

		computedIsAssignmentEditing() {
			return this.isAssignmentEditing
		},

		computedResetSearchOnBeforeLeave() {
			return this.resetSearchOnBeforeLeave
		},

		dataToPassToChildren: {
			get() {
				return this.dataForChildren
			},
			set(newValue) {
				this.dataForChildren = newValue
			},
		},
		backupDataInCaseOfCancel: {
			get() {
				return this.backupDataForChildren
			},
			set(newValue) {
				this.backupDataForChildren = newValue
			},
		},
		vuexIsActionCurrentlyEdited: {
			get() {
				return this.getIsActionCurrentlyEdited
			},
			set(newValue) {
				this.actionUpdateActionEditionStatus(newValue)
			},
		},

		computedIsActionDetails() {
			return this.$route.name === 'action-details-informations' || this.$route.name === 'action-details-stats'
		},
	},
	watch: {
		$route(to, from) {
			if (this.isAssignmentEditing) {
				this.isAssignmentEditing = false
				this.actionSetMenuVisibility(true)
			}

			if (from.name === 'action-details-informations') {
				this.vuexIsActionCurrentlyEdited = false
			}

			if (to.name === 'actions-list') {
				this.isShowingActionDetails = false
			}
			if (to.name === 'action-details-informations' || to.name === 'action-details-stats') this.isShowingActionDetails = true
		},
	},

	methods: {
		...mapActions('@action', [
			'actionUpdateCampaignAction',
			'actionSetActiveAction',
			'actionUpdateActionEditionStatus',
			'actionArchiveAction',
		]),

		...mapActions('@navBar', ['actionSetMenuVisibility', 'actionSetMenuCollapse']),
		openDeleteDialog(action) {
			let currentAction
			if (action) {
				currentAction = cloneDeep(action)
			}
			else {
				const findedAction = this.methodFindCurrentAction()
				if (findedAction) {
					currentAction = cloneDeep(findedAction)
				}
			}

			if (currentAction) {
				this.actionToDelete = currentAction
				this.dialogConfirmDeleteActionVisible = !this.dialogConfirmDeleteActionVisible
			}
		},

		closeDeleteDialog() {
			this.dialogConfirmDeleteActionVisible = false
			// this.$message({
			// 	type: 'info',
			// 	message: this.$t('ACTION.LIST.DELETE_CANCELED'),
			// 	showClose: true,
			// })
			this.actionToDelete = null
		},

		handleFocus(event) {
			this.$refs.list.focus = event
		},

		handleToggle(event) {
			this.$refs.list.toggleFocus(event)
		},

		async backupActionDetailsData(action = null) {
			if (action && !this.vuexIsActionCurrentlyEdited) {
				// ? on arrival on details
				this.dataToPassToChildren = this.backupDataInCaseOfCancel = action
			}
			else if (action && this.vuexIsActionCurrentlyEdited) {
				// ? after the update
				this.backupDataInCaseOfCancel = this.dataToPassToChildren
			}
			else {
				// ? when leaving the details
				if (!isEqual(this.dataToPassToChildren, this.backupDataInCaseOfCancel)) {
					this.dataToPassToChildren = this.backupDataInCaseOfCancel
				}
			}
		},

		bringBackBackupDataAfterCancel() {
			// ? on details edition, when pressing the 'cancel' button
			this.dataToPassToChildren = this.backupDataInCaseOfCancel
		},

		actionToggleActionList(action = null) {
			if (!this.isShowingActionDetails) {
				this.$router.push({
					name: 'actions-list',
					query: { actionTypeQuery: 'running' },
					params: { isActionsListNeedToBeLoad: this.isActionsListNeedToBeLoad },
				})

				if (this.isActionsListNeedToBeLoad) {
					this.isActionsListNeedToBeLoad = false
				}

				return
			}

			if (!action) this.vuexIsActionCurrentlyEdited = false
		},

		methodFindCurrentAction() {
			const currentActionId = this.getActionID
			const findedAction = this.actions.find((action) => action.ID === currentActionId)
			if (findedAction) {
				return findedAction
			}
			else {
				return null
			}
		},
		methodToggleActionHeader() {
			this.isShowingActionDetails = !this.isShowingActionDetails
		},

		confirmDeleteAction() {
			this.actionArchiveAction(this.actionToDelete)
				.then(() => {
					this.$message({
						type: 'success',
						message: this.$t(`ACTION.LIST.DELETE_SUCCESS`, { name: this.actionToDelete.name }),
						showClose: true,
					})
					this.actionToDelete = null
				})
				.catch((e) => {
					console.warn(e)
					this.$message({
						showClose: true,
						type: 'error',
						message: this.$t(`ACTION.LIST.UPDATE_ERROR`, { name: this.actionToDelete.name }),

					})
					this.actionToDelete = null
				})

			if (this.$route.name === 'action-details-informations') {
				this.$router.push({ name: 'actions-list' })
			}

			this.dialogConfirmDeleteActionVisible = false
		},

		displayDateTime(action, onlyDate = false, ignoredIsToday = false) {
			const { start, end } = action

			if (start) {
				const startDate = new Date(start)

				if (!end) return formatRelativeDate(startDate)
				else {
					const endDate = new Date(end)

					if (isEqual(startDate, endDate)) {
						if (onlyDate) {
							return {
								start: formatRelativeDate(startDate),
							}
						}
						return formatRelativeDate(startDate)
					}

					const isToday = !differenceInCalendarDays(startDate, new Date())

					if (isToday && !ignoredIsToday) {
						if (onlyDate) {
							return {
								start: formatTime(startDate),
								end: formatTime(endDate),
							}
						}
						return `${formatTime(startDate)} - ${formatTime(endDate)}`
					}
					if (onlyDate) {
						return {
							start: formatDate(new Date(startDate), getDisplayDateFromLocalStorage()),
							end: formatDate(endDate, getDisplayDateFromLocalStorage()),
						}
					}
					return `${formatDate(new Date(startDate), getDisplayDateFromLocalStorage())} <i class="gui-next" /> ${formatDate(endDate, getDisplayDateFromLocalStorage())}`
				}
			}

			return null
		},

		displayDayInMonth(action) {
			const { start } = action

			if (start) {
				return formatCustomDate(new Date(start), 'dd')
			}

			return null
		},

		displayMonth(action) {
			const { start } = action

			if (start) {
				return formatCustomDate(new Date(start), 'MMM', this.$i18n.locale).toUpperCase()
			}

			return null
		},
	},

	mounted() {
		this.$nextTick(() => {
			const routeName = this.$route.name
			if (routeName === 'actions-list') {
				this.isShowingActionDetails = false
			}
			if (routeName === 'action-details-informations' || routeName === 'action-details-stats') this.isShowingActionDetails = true
		})
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/animations.scss';

.qview-header,
#actions-header {
	position: relative;
	padding: 2 * 10px;
	z-index: 20;
}

#actions-container {
	height: 100%;
}
.pure-alternative2 {
	position: absolute;
	right: 0;
	width: 21%;
	max-height: 87vh;
	overflow: hidden;
}
</style>
