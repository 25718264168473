import {
	RESET_TURF_STORE,
	SET_SELECTED_LAYER,
	SET_LAYER_LIST,
} from './_mutation-types'
import { cloneDeep, findIndex } from 'lodash'
import turf_store from '../../models/turf_store'
import { polygon } from '@turf/turf'
/**
 * @store @turf
 * @namespaced
 * @description Turf related info and store module
 */

// Transform the multiple polygon into a polygon
const transformMultipolygonIntoPolygon = (turf) => {
	if (turf.geometry) {
		turf.geometry = polygon(turf.geometry.coordinates[0]).geometry
	}
}

const getDefaultState = () => {
	return {
		selectedLayer: {},
		layerList: [],
	}
}
const state = getDefaultState

const mutations = {
	[RESET_TURF_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_LAYER_LIST]: (state, res) => {
		state.layerList = res
	},
	[SET_SELECTED_LAYER]: (state, res) => {
		state.selectedLayer = res
	},
}

const actions = {
	actionResetTurf({ commit }) {
		commit(RESET_TURF_STORE)
	},

	async actionAddTurf({ commit, state }, payload) {
		payload = { ...payload, layer_id: state.selectedLayer.id }
		return turf_store
			.addTurf(payload)
			.then((res) => {
				let clonedSelectedLayer = cloneDeep(state.selectedLayer)

				transformMultipolygonIntoPolygon(res)

				clonedSelectedLayer.turfs[res.code] = res
				commit(SET_SELECTED_LAYER, clonedSelectedLayer)
				return res
			})
	},

	actionUpdateTurf({ commit, state }, payload) {
		return turf_store
			.updateTurf({ turfId: payload.id, data: payload })
			.then((res) => {
				let clonedSelectedLayer = cloneDeep(state.selectedLayer)

				transformMultipolygonIntoPolygon(res)

				clonedSelectedLayer.turfs[res.code] = res
				commit(SET_SELECTED_LAYER, clonedSelectedLayer)
			})
	},

	actionDeleteTurf({ commit, state }, turf) {
		return turf_store
			.deleteTurf(turf.id)
			.then(() => {
				let clonedSelectedLayer = cloneDeep(state.selectedLayer)
				delete clonedSelectedLayer.turfs[turf.code]
				commit(SET_SELECTED_LAYER, clonedSelectedLayer)
			})
			.catch(() => {})
	},

	actionRetrieveLayersList({ commit }) {
		return turf_store
			.getLayers()
			.then(async (res) => {
				commit(SET_LAYER_LIST, res)
			})
			.catch(() => {})
	},

	async actionAddLayer({ commit, state }, payload) {
		return turf_store.addLayer({ name: payload.name, parent: payload.parent }).then((res) => {
			Object.values(res.turfs).forEach((turf) => {
				transformMultipolygonIntoPolygon(turf)
			})
			let clonedLayers = cloneDeep(state.layerList)
			clonedLayers.push(res)

			commit(SET_LAYER_LIST, clonedLayers)
			commit(SET_SELECTED_LAYER, res)
		})
	},

	actionUpdateLayer({ commit, state }, payload) {
		// Remove turfs attributes if there is one
		delete payload.turfs

		return turf_store.updateLayer({ layerId: payload.id, layerData: payload }).then((res) => {
			let clonedLayers = cloneDeep(state.layerList)
			let layerIndex = findIndex(clonedLayers, ['id', res.id])
			clonedLayers[layerIndex] = res
			commit(SET_LAYER_LIST, clonedLayers)

			// On the selected layer do not override the turfs because they will not be retrieve with this request (and they don't need to be, only the other infos of the layer can be modified)
			// The update on a layer can only be done on edit mode so the layer updated will be the one selected
			commit(SET_SELECTED_LAYER, { ...res, turfs: state.selectedLayer.turfs })
		})
	},

	actionDeleteLayer({ commit, dispatch }, layerId) {
		return turf_store
			.deleteLayer(layerId)
			.then(async () => {
				// Retrieve the layers from the back in case a parent have been deleted and the other layers have been updated
				await dispatch('actionRetrieveLayersList')
				// Reset the selected layers
				commit(SET_SELECTED_LAYER, {})
			})
			.catch(() => {})
	},

	async actionSetLayerSelected({ commit }, layerId) {
		return turf_store.getLayer(layerId).then((res) => {
			Object.values(res.turfs).forEach((turf) => {
				transformMultipolygonIntoPolygon(turf)
			})
			commit(SET_SELECTED_LAYER, res || {})
		})
	},
}

const getters = {
	getTurfs: (state) => state.selectedLayer?.turfs,
	getLayer: (state) => state.selectedLayer,
	getLayerList: (state) => state.layerList,
}

export const turfModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
