/**
 * Duplicates related routes
 *
 *
 * @rootPath {/duplicates}
 */

export default {
	meta: { title: '_COMMON.MERGE' },
	path: '/duplicates',
	name: 'duplicates',
	component: () => import('../components/duplicates-module/duplicatesRouter.vue'),
	props: { default: true },
	children: [
		{
			path: '/duplicates/preview',
			name: 'duplicates-preview',
			component: () => import('../components/duplicates-module/duplicatesPreview.vue'),
			props: { default: true },
		},
		{
			path: '/duplicates/merge',
			name: 'duplicates-merge',
			component: () => import('../components/duplicates-module/duplicatesMerge.vue'),
			props: { default: true },
		},
	],
}
