import { LOCAL_DEV_DATA_TEAM_MODE_ENABLED } from '../constants'
import { api, public_api, local_territory_api } from './common'
import { handleErrors } from '../middlewares/interceptor'
let request = require('superagent')

// When the data team is developping locally, we need to remap some routes

export const onError = (e) => {
	let code, message
	try {
		if (e.response.body) {
			code = e.response.statusCode
			message = e.response.body
		}
	}
	catch (e) {
		void e
	}
	handleErrors(code || e.statusCode, e.response, message)
	throw e // do so to send e in next catch()
}

const getApiUrl = (isPublic) => {
	if (LOCAL_DEV_DATA_TEAM_MODE_ENABLED) {
		return local_territory_api
	}
	else {
		return `${isPublic ? public_api : api}/territory-api`
	}
}

export const $request = (method, url, data = null, customOnError = onError, isPublic = false) => {
	switch (method) {
		case 'GET':
		default: {
			const req = request(method, `${getApiUrl(isPublic)}${url}`)
				.withCredentials()
				.set({
					Accept: 'application/json',
				})
				.query(data)
			req.catch(customOnError)
			return req
		}
		case 'POST': {
			const req = request('POST', `${getApiUrl(isPublic)}${url}`)
				.set('Content-Type', 'application/json')
				.set({
					Accept: 'application/json',
				})
				.send(data)
			req.catch(customOnError)
			return req
		}
	}
}
