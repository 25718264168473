import deburr from 'lodash/deburr'
import trim from 'lodash/trim'

export function transformAndDeduplicateCities(cities) {
	// Helper function to capitalize the first letter of each word
	function capitalizeWords(str) {
		return str.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
	}

	// Create a set to store unique city names
	const uniqueCities: Set<string> = new Set()

	// Process each city in the input array
	cities.forEach((city) => {
		// Skip non-city entries
		if (!isNaN(city) || city.includes('@')) {
			return
		}

		// Replace hyphens with spaces and normalize the city name
		const normalizedCity = capitalizeWords(city.replace(/-/g, ' '))
		// Remove accents from the city name
		const deburredCity = deburr(normalizedCity)
		const trimmedCity = trim(deburredCity)

		// Add the normalized city to the set
		uniqueCities.add(trimmedCity)
	})

	// Convert the set back to an array and return it
	const arrayCities: string[] = Array.from(uniqueCities)
	arrayCities.sort((a, b) => a.localeCompare(b))
	return arrayCities
}

// Helper function to normalize a city name for searching
function normalizeCityName(city) {
	return city.replace(/-/g, ' ').split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}

// Function to search for a city in the transformed cities array
export function searchCity(transformedCities, cityName): boolean {
	const normalizedCity = normalizeCityName(cityName)
	return transformedCities.includes(normalizedCity)
}
