<template>
	<a
		:class="`q-filter ${!!state || isActive ? 'active' : ''}`"
		:style="!!state ? `background-color: #${color}; border-color: #${color}` : ''"
		@click.prevent="sendToggle()"
		><slot></slot
	></a>
</template>

<script>
export default {
	model: {
		prop: 'state',
		event: 'change',
	},

	props: ['active', 'state', 'color'],

	emits: ['toggle', 'change'],

	data() {
		return {
			isActive: false,
		}
	},

	created() {
		this.isActive = this.active
	},

	methods: {
		sendToggle() {
			if (this.active !== undefined) {
				this.$emit('toggle')
				this.isActive = !this.isActive
			} else {
				this.$emit('change', this.state)
			}
		},
	},
}
</script>

<style lang="css"></style>
