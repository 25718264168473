<template>
	<div class="">
		<div class="section">
			<div class="flex flex-row">
				<div>
					<template v-if="editedUser.avatar && editedUser.avatar !== 'null' && editedUser.avatar !== 'not_set'">
						<img
							:src="editedUser.avatar"
							alt="profile photo"
							class="my-avatar"
						>
					</template>
					<template v-else>
						<div class="my-avatar-empty">
							{{ computedUserInitials }}
						</div>
					</template>
					<div class="flex justify-center mt-1">
						<span
							class="text-sm text-pink-main cursor-pointer hover:underline p-1"
							@click="isAvatarDialogVisible = true"
						>
							{{ $t('SETTINGS.PROFILE.INFORMATION.EDIT_PICTURE') }}
						</span>
					</div>
				</div>
				<div class="flex flex-col flex-grow pl-6">
					<div class="flex flex-grow justify-between">
						<div class="flex flex-col">
							<span class="font-bold text-4xl mb-1">
								{{ userConnected.firstname }} {{ userConnected.surname }}
							</span>
							<div
								class="w-fit px-2 py-0.5 rounded-lg tracking-tight"
								:style="[
									{ color: userConnected.role_data.color },
									{ backgroundColor: userConnected.role_data.color + '15' },
								]"
							>
								{{ methodRetrieveNameAccordingType(userConnected.role_data) }}
							</div>
						</div>
						<div class="text-sm text-right">
							<span class="text-gray-dark">
								{{ $t('SETTINGS.PROFILE.INFORMATION.MEMBER_SINCE') }}
							</span>
							<br>
							<span>
								{{ computedUserInscriptionDate }}
							</span>
						</div>
					</div>
					<hr class="divider-sm">
					<div class="flex flex-col md:flex-row gap-2">
						<input-profile
							:title="$t('SETTINGS.PROFILE.LANGUAGE')"
							width="12rem"
						>
							<el-select
								:model-value="$i18n.locale.substring(0, 2)"
								class="editable-label-input"
								@change="methodLangChanged"
							>
								<el-option
									v-for="lang in computedGetOptionsLanguages"
									:key="lang.value"
									:label="lang.label"
									:value="lang.value"
								/>
							</el-select>
						</input-profile>

						<!-- date display -->

						<div
							class="flex flex-col"
							:style="`width: 12rem`"
						>
							<div class="text-sm text-pink-main mb-1 whitespace-pre">
								{{ $t('SETTINGS.PROFILE.INFORMATION.DATE_FORMAT') }}
							</div>
							<el-select
								v-model="currentDateFormat"
								class="editable-label-input uppercase"
								popper-class="uppercase"
								@change="methodHandleDateFormat"
							>
								<el-option
									v-for="aFormat in dateFormats"
									:key="aFormat"
									:label="aFormat"
									:value="aFormat"
								/>
							</el-select>
						</div>

						<!-- hours display -->

						<div
							class="flex flex-col"
							:style="`width: 12rem`"
						>
							<div class="text-sm text-pink-main mb-1 whitespace-pre">
								{{ $t('SETTINGS.PROFILE.INFORMATION.HOURS_FORMAT') }}
							</div>
							<el-select
								:model-value="methodDisplayHoursFormat(currentHoursFormat)"
								class="editable-label-input"

								@change="methodHandleHoursFormat"
							>
								<el-option
									v-for="aFormat in hoursFormats"
									:key="aFormat"
									:label="methodDisplayHoursFormat(aFormat)"
									:value="aFormat"
								/>
							</el-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section-title">
				{{ $t('SETTINGS.PROFILE.INFORMATION.CONTACTS') }}
			</div>
			<input-profile
				v-model:value="editedUser.mail"
				disabled
				:title="$t('_COMMON.EMAIL')"
				width="18rem"
			/>
			<div class="flex flex-row mt-6">
				<input-profile
					v-model:value="editedUser.phone"
					:title="$t('SETTINGS.CONTACT.MOBILE')"
					width="18rem"
				/>
				<!-- <input-profile
					v-model="editedUser.mobile"
					:title="$t('SETTINGS.CONTACT.MOBILE')"
					width="18rem"
					class="ml-8"
				/> -->
			</div>
		</div>
		<div class="section">
			<div class="section-title">
				{{ $t('SETTINGS.PROFILE.INFORMATION.BIRTHDATE') }}
			</div>
			<input-profile
				:title="$t('SETTINGS.CONTACT.BIRTHDATE')"
				width="15rem"
			>
				<el-date-picker
					v-model="editedUser.birthdate"
					:format="methodFormatBirthdate()"
					class="editable-label-input"
					:prefix-icon="getIcon('gui-calendar')"
				/>
			</input-profile>
		</div>
		<div class="section">
			<div class="section-title">
				{{ $t('_COMMON.ADDRESS_TITLE') }}
			</div>
			<!-- <div class="flex flex-row mt-6">
				<input-profile
					v-model="building"
					:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.BUILDING')"
					width="8rem"
				/>
				<input-profile
					v-model="floor"
					:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.FLOOR')"
					width="5rem"
					class="ml-8"
				/>
				<input-profile
					v-model="door"
					:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.DOOR')"
					width="5rem"
					class="ml-8"
				/>
			</div> -->
			<div class="flex flex-row mt-6">
				<input-profile
					v-model:value="editedUser.address"
					:title="$t('_COMMON.ADDRESS')"
					width="32rem"
				/>
				<!-- <input-profile
					v-model="street"
					:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.STREET')"
					width="15rem"
					class="ml-8"
				/> -->
			</div>
			<!-- <input-profile
				v-model="additionnal"
				:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.ADDITION')"
				width="15rem"
				class="mt-6"
			/> -->
			<hr class="divider">
			<div class="flex flex-row">
				<input-profile
					v-model:value="editedUser.postal"
					:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.POSTALCODE')"
					width="15rem"
				/>
				<input-profile
					v-model:value="editedUser.city"
					:title="$t('SETTINGS.PROFILE.INFORMATION.CITY_REQUIRED')"
					width="15rem"
					class="ml-8"
				/>
			</div>
			<!-- <input-profile
				v-model="country"
				:title="$t('SETTINGS.CONTACT.ADDRESS_LABELS.COUNTRY')"
				width="15rem"
				class="mt-6"
			/> -->
		</div>
		<div class="section">
			<div class="section-title">
				{{ $t('SETTINGS.PROFILE.INFORMATION.DELETE_ACCOUNT') }}
			</div>
			<div class="flex justify-between pr-10">
				<span class="flex items-center text-gray-400">
					{{ $t('SETTINGS.PROFILE.INFORMATION.DELETE_ACCOUNT_DESCRIPTION') }}
				</span>
				<span id="delete-btn">
					<a href="mailto:support@qomon.com?subject=Delete my account">
						{{ $t('SETTINGS.PROFILE.INFORMATION.DELETE_ACCOUNT') }}
					</a>
				</span>
			</div>
		</div>

		<avatar-dialog
			v-model:visible="isAvatarDialogVisible"
			:user-initials="computedUserInitials"
			:original-avatar="userConnected.avatar"
			:edited-user="editedUser"
			@new-avatar="editedUser.avatar = $event"
		/>
	</div>
</template>

<script>
import { h } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEqual } from 'lodash'
import { setStore } from 'src/middlewares/storage'
import { defineLocaleSpecific } from 'src/middlewares/i18nizer'
import InputProfile from './input-profile.vue'
import AvatarDialog from './avatar-dialog.vue'
import dayjs from 'dayjs'
import {
	formatCustomDate,
	getDisplayDateFromLocalStorage,
	getDisplayHoursFromLocalStorage,
} from '../../../utils/dateFnsHelper'
import { methodRetrieveNameAccordingType } from '@/utils/RoleUtils.ts'

export default {
	components: {
		InputProfile,
		AvatarDialog,
	},
	beforeRouteLeave(to, from, next) {
		// prevent leave route if there are some unsaved changes
		if (this.hasChanged && to.name !== 'login') {
			this.$confirm(
				this.$t('SETTINGS.CONTACT.CONFIRM_BOX.DESCRIPTION'),
				this.$t('SETTINGS.CONTACT.CONFIRM_BOX.TITLE'),
				{
					confirmButtonText: this.$t('SETTINGS.CONTACT.CONFIRM_BOX.BTN_OK'),
					cancelButtonText: this.$t('_COMMON.CANCEL'),
					customClass: 'custom_message_box',
				},
			)
				.then(() => {
					this.$emit('unSaveChange', false)
					next()
				})
				.catch(() => next(false))
		}
		else {
			this.$emit('unSaveChange', false)
			next()
		}
	},
	props: {
		saveChange: {
			type: Number,
			required: true,
		},
	},
	emits: ['unSaveChange', 'loading'],
	data() {
		return {
			editedUser: {},
			currentDateFormat: 'dd/MM/yyy',
			currentHoursFormat: 'HH:mm',
			dateFormats: ['dd/MM/yyyy', 'MM/dd/yyyy'],
			hoursFormats: ['HH:mm', 'hh:mm A'],

			// not in userConnected
			building: '',
			door: '',
			floor: '',
			number: '',
			street: '',
			country: '',
			additionnal: '',
			mobile: '',

			hasChanged: false,

			isAvatarDialogVisible: false,
		}
	},
	computed: {
		...mapGetters(['userConnected', 'info_campagne']),

		computedUserInitials() {
			if (this.userConnected && this.userConnected.firstname && this.userConnected.surname) {
				return (
					this.userConnected.firstname.substring(0, 1).toUpperCase()
					+ this.userConnected.surname.substring(0, 1).toUpperCase()
				)
			}

			return ''
		},

		computedGetOptionsLanguages() {
			return [
				{
					value: 'en',
					label: this.$t('_COMMON.LOCALES.en'),
				},
				{
					value: 'fr',
					label: this.$t('_COMMON.LOCALES.fr'),
				},
			]
		},

		computedUserInscriptionDate() {
			return this.userConnected ? formatCustomDate(this.userConnected.created, 'PPP') : ''
		},
	},
	watch: {
		editedUser: {
			handler(value) {
				this.hasChanged = !isEqual(value, this.userConnected)
				this.$emit('unSaveChange', this.hasChanged)
			},
			deep: true,
		},
		saveChange: {
			handler() {
				this.$emit('loading', true)
				this.actionSaveUserConnected(cloneDeep(this.editedUser))
					.then(() => {
						this.$message({
							message: this.$t('SETTINGS.PROFILE.MESSAGE.UPDATE_SUCCESS'),
							type: 'success',
							showClose: true,
						})
						this.editedUser = cloneDeep(this.userConnected)
					})
					.catch((error) => {
						this.$message({
							message: this.$t('SETTINGS.PROFILE.INFORMATION.MESSAGE'),
							type: 'error',
							showClose: true,
						})
					})
					.finally(() => {
						this.$emit('loading', false)
					})
			},
		},
	},
	mounted() {
		localStorage.getItem('dateFormat') ? this.currentDateFormat = localStorage.getItem('dateFormat') : this.currentDateFormat = 'DD/MM/YYYY'
		localStorage.getItem('hoursFormat') ? this.currentHoursFormat = localStorage.getItem('hoursFormat') : this.currentHoursFormat = getDisplayHoursFromLocalStorage()
		this.editedUser = cloneDeep(this.userConnected)
	},
	methods: {
		...mapActions(['actionSaveUserConnected']),
		methodRetrieveNameAccordingType,

		methodDisplayHoursFormat(value) {
			let display = value === 'HH:mm' ? this.$t('SETTINGS.PROFILE.INFORMATION.HOURS_FORMAT_24') : this.$t('SETTINGS.PROFILE.INFORMATION.HOURS_FORMAT_12')
			return display
		},
		methodFormatBirthdate() {
			return this.currentDateFormat.toUpperCase()
			// console.log(localStorage.getItem('dateFormat'))
		},
		methodHandleDateFormat(value) {
			localStorage.setItem('dateFormat', value)
		},

		methodHandleHoursFormat(value) {
			this.currentHoursFormat = value
			localStorage.setItem('hoursFormat', value)
		},

		getIcon(iconName) {
			return h('i', { class: iconName })
		},

		async methodLangChanged(value) {
			if (value) {
				this.$i18n.locale = value
				await setStore('LANG', value)
				// Locale for the el-datepicker
				dayjs.locale(value)
				defineLocaleSpecific(this.vuexPacksGetLocaleSpecific)
				this.$parent.$forceUpdate()
			}
		},
	},
}
</script>

<style lang="sass" scoped>
@import 'src/assets/scss/shared/shared-constants.scss'

.my-avatar
	@apply rounded-full text-6xl text-center
	width: 9rem
	height: 9rem
	object-fit: cover

.my-avatar-empty
		width: 9rem
		height: 9rem
		@apply rounded-full text-6xl text-center bg-gray-100 flex items-center justify-center

.role-badge
	@apply text-sm bg-pink-medium rounded-md w-fit font-bold
	padding: 0.12rem 0.4rem

.section
	@apply mt-1 p-8 bg-white
	.section-title
		@apply text-sm text-gray-dark uppercase mb-6

#delete-btn
	border: 1px solid #222230
	border-radius: 60px
	padding: 0.4rem 0.75rem
	font-size: 13px
	cursor: pointer
	&:hover
		background-color: $pink-main
		border-color: transparent
		color: white

.divider, .divider-sm
	border-color: transparent
	border-top: 1px solid #ebebeb
	margin: 1.5rem 0
.divider-sm
	margin: 1rem 0
</style>
