function checkRgbValues(r, g, b) {
	if (
		isNaN(r) ||
		isNaN(g) ||
		isNaN(b) ||
		r < 0 ||
		r > 255 ||
		g < 0 ||
		g > 255 ||
		b < 0 ||
		b > 255
	) {
		throw new Error(`Inconsitent RGB value in (${r}, ${g}, ${b})`)
	}
}
/**
 * Converts a hex color value to a the RGB space,
 * Where R, G, B are in [0, 255]
 *
 * @param {String} hexStr
 * @returns {number[]}
 */
export function hexToRgb(hexStr) {
	if (hexStr.at(0) !== '#') {
		hexStr = `#${hexStr}`
	}
	const r = parseInt(hexStr.substring(1, 3), 16)
	const g = parseInt(hexStr.substring(3, 5), 16)
	const b = parseInt(hexStr.substring(5, 7), 16)
	checkRgbValues(r, g, b)
	return [r, g, b]
}

/**
 * Converts an RGB value to its Hex representation
 *
 * @param r {Number} Red value in [0, 255]
 * @param g {Number} Green value in [0, 255]
 * @param b {Number} Blue value in [0, 255]
 * @returns {String}
 */
export function rgbToHex(r, g, b) {
	checkRgbValues(r, g, b)
	const toHex = (x) => {
		let hex = Math.round(x).toString(16)
		return hex.length === 1 ? `0${hex}` : hex
	}
	return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

/**
 * Convert RGB values to HSL space.
 * Inspired by https://stackoverflow.com/a/3732187
 *
 * @param r Red value in [0, 255]
 * @param g Red value in [0, 255]
 * @param b Red value in [0, 255]
 *
 * @returns {number[]} H in [0, 360[, S in [0, 100], L in [0, 100]
 */
export function rgbToHsl(r, g, b) {
	r /= 255
	g /= 255
	b /= 255
	const max = Math.max(r, g, b),
		min = Math.min(r, g, b)
	let h,
		s,
		l = (max + min) / 2

	if (max === min) {
		h = s = 0 // achromatic
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0)
				break
			case g:
				h = (b - r) / d + 2
				break
			case b:
				h = (r - g) / d + 4
				break
		}
		h /= 6
	}

	return [Math.round((360 * h) % 360), Math.round(100 * s), Math.round(100 * l)]
}

/**
 * Converts an HSL color value to Hex in [0, 255]. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 *
 *
 * @param   {number}  h       The hue in [0, 360]
 * @param   {number}  s       The saturation in [0, 100]
 * @param   {number}  l       The lightness in [0, 100]
 * @return  {number[]}        The Hex representation
 */
export function hslToRgb(h, s, l) {
	h /= 360
	s /= 100
	l /= 100
	let r, g, b
	if (s === 0) {
		r = g = b = l // achromatic
	} else {
		const hue2rgb = (p, q, t) => {
			if (t < 0) t += 1
			if (t > 1) t -= 1
			if (t < 1 / 6) return p + (q - p) * 6 * t
			if (t < 1 / 2) return q
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
			return p
		}
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s
		const p = 2 * l - q
		r = hue2rgb(p, q, h + 1 / 3)
		g = hue2rgb(p, q, h)
		b = hue2rgb(p, q, h - 1 / 3)
	}
	r = Math.round(r * 255)
	g = Math.round(g * 255)
	b = Math.round(b * 255)
	checkRgbValues(r, g, b)
	return [r, g, b]
}

/**
 * Performs a luminance shift (HSL color space) on Hex color.
 *
 * @param {String} hexColor
 * @param {Number} luminanceShift
 * @param {Number} maxLuminanceValue
 * @returns {String}
 */
export function luminanceShiftHexColor(hexColor, luminanceShift, maxLuminanceValue = 100) {
	let outColor = hexColor
	try {
		const [h, s, l] = rgbToHsl(...hexToRgb(hexColor))
		const newL = Math.min(Math.max(0, l + luminanceShift), maxLuminanceValue)
		outColor = rgbToHex(...hslToRgb(h, s, newL))
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(`Failed to compute border color for color: ${hexColor}`)
		void e
	}
	return outColor
}

// Return the brightness to use (1 or 0) according the color of entrance
export function getBrightnessFromColor(color) {
	if (color) {
		const isHex = color.includes('#')
		const isRgb = color.includes('rgb')

		let red = ''
		let green = ''
		let blue = ''

		if (isHex) {
			const m = color.substr(1).match(color.length == 7 ? /(\S{2})/g : /(\S{1})/g)
			if (m) {
				red = parseInt(m[0], 16) & 0xff
				green = parseInt(m[1], 16) & 0xff
				blue = parseInt(m[2], 16) & 0xff
			}
		}
		if (isRgb) {
			const m = color.match(/(\d+){3}/g)
			if (m) {
				red = m[0] & 0xff
				green = m[1] & 0xff
				blue = m[2] & 0xff
			}
		}

		const luma = 0.299 * red + 0.587 * green + 0.114 * blue // per ITU-R BT.709
		if (luma < 150) {
			return 1
		} else {
			return 0
		}
	}
}
