<template>
	<fieldset>
		<p class="mb-5 bold">{{ radioData.title }}</p>

		<label
			v-for="(input, index) in radioData.inputs"
			:key="index"
			class="flex items-center gap-x-5 h-auto border mb-3 px-3 py-5 rounded-2xl cursor-pointer"
			:class="{ 'border-green-medium': value === input.value }"
			:for="input.name"
		>
			<input
				:id="input.name"
				v-model="computedValue"
				:name="input.name"
				:value="input.value"
				type="radio"
				class="appearance-none"
			/>

			<i :class="`text-2xl ${value === input.value ? 'gui-radio_on' : 'gui-radio_off'}`" />

			<div class="w-2/3">
				<p class="bold">{{ input.label }}</p>
				<p class="text-sm">
					{{ input.text }}
				</p>
			</div>

			<div
				v-if="input.badge"
				class="bg-green-lighted text-green-main text-xs rounded-md px-1 pt-px ml-auto flex items-center"
				style="height: max-content"
			>
				<i class="gui-stars color-part-one color-part-two"></i>
				<span class="whitespace-nowrap"> {{ input.badge }} </span>
			</div>
		</label>
	</fieldset>
</template>

<script>
export default {
	props: {
		radioData: { type: Object, default: () => {}, required: true },
		value: { type: String, default: '', required: true },
	},
	emits: ['update:value'],
	computed: {
		computedValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('update:value', val)
			},
		},
	},
}
</script>
