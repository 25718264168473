<template>
	<img
		:class="{'m-auto': isMargin}"
		:style="`width: ${size}`"
		src="/static/images/icon_quorum_pink_animated.svg"
	>
</template>

<script>
export default {
	props: {
		size: {
			type: [String, Number],
			default: '5em',
		},
		isMargin: {
			type: Boolean,
			default: true,
		},
	},
}
</script>
