import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

/**
 * Helper classes to store information about the parent territories
 * - What is their scale / layer
 * - What are their ids
 */
export class ParentContoursInfo {
	// cannot make them really private as there is a restriction
	// https://github.com/babel/babel/issues/9831
	_scale: string
	_ids: Set<string>

	/**
	 * @param {string} scale
	 * @param {Set.<String>} ids
	 */
	constructor({ scale, ids }) {
		this._scale = scale
		// we make sure to cast to a proper set
		this._ids = typeof ids === 'string' ? new Set([ids]) : new Set(ids)
	}

	get scale(): string {
		return this._scale
	}

	get ids(): Set<string> {
		return this._ids
	}

	get key(): string {
		return `
			- scale: ${this.scale}
			- administrative_ids: ${this.ids}
		`
	}

	get scaleOrLayerId(): string {
		return this._scale
	}

	equals = (other: any): boolean => {
		return other instanceof ParentContoursInfo && this.scale === other.scale && isEqual(this.ids, other.ids)
	}
}

export class ParentTurfsInfo {
	layerId: number
	ids: Set<number>

	constructor({ layerId, ids }) {
		this.layerId = layerId
		this.ids = ids
	}

	get key(): string {
		return `
			- layer_id: ${this.layerId}
			- turf_ids: ${this.ids}
		`
	}

	get scaleOrLayerId(): number {
		return this.layerId
	}

	equals = (other: any): boolean => {
		return other instanceof ParentTurfsInfo && this.layerId === other.layerId && isEqual(this.ids, other.ids)
	}
}

type ParentTerritoriesInfo = ParentTurfsInfo | ParentContoursInfo

// Displayed Info
export class DisplayedContoursInfo {
	scale: string

	constructor(scale: string) {
		this.scale = scale
	}

	get key(): string {
		return `
			- scale: ${this.scale}
		`
	}

	equals = (other: any): boolean => {
		return other instanceof DisplayedContoursInfo && this.scale === other.scale
	}
}

export class DisplayedTurfsInfo {
	layerId: number

	constructor(layerId: number) {
		this.layerId = layerId
	}

	get key(): string {
		return `
			- layer_id: ${this.layerId}
		`
	}

	equals = (other: any): boolean => {
		return other instanceof DisplayedTurfsInfo && this.layerId === other.layerId
	}
}

type DisplayedTerritoriesInfo = DisplayedContoursInfo | DisplayedTurfsInfo

type GeoContextPayload = {
	geo_root_alias: string
	parents: { scale: string, administrative_ids: string[] } | { layer_id: number, turf_ids: number[] }
	displayed: { scale: string } | { layer_id: number }
}

/**
 * Helper class to characterize the GeoContext at stake
 */
class GeoContext {
	#config

	/**
	 * @param geoRootAlias Alias of the geo root (usually country code)
	 * @param displayed Information (scale/layer) about the displayed territories
	 * @param parents Information (scale/layer & ids) about the parents territory·ies
	 * @param label Label associated with the geoContext
	 * @param highlightedTerritoryId If a specific territory is selected
	 */
	constructor(
		{
			geoRootAlias,
			displayed,
			parents,
			label,
			highlightedTerritoryId = null,
		}: {
			geoRootAlias: string
			displayed: DisplayedTerritoriesInfo
			parents: ParentTerritoriesInfo
			label: string
			highlightedTerritoryId: string | number
		}) {
		if (displayed instanceof DisplayedContoursInfo && (typeof displayed.scale === 'undefined' || displayed.scale === '')) {
			throw Error('Unknown displayed contours scale, that\'s not possible')
		}
		this.#config = { displayed, parents, geoRootAlias, highlightedTerritoryId, label: label || '' }
	}

	get asClonedObj() {
		return cloneDeep(this.#config)
	}

	/**
	 * Helper to change displayed territories
	 *
	 * @param newDisplayedTerritories
	 * @return {GeoContext}
	 */
	withNewDisplayedTerritories(newDisplayedTerritories: DisplayedTerritoriesInfo): GeoContext {
		return new GeoContext({
			...this.asClonedObj,
			displayed: newDisplayedTerritories,
		})
	}

	/**
	 * Helper to change displayed territories
	 *
	 * @param displayedId
	 * @return {GeoContext}
	 */
	withNewDisplayedId(displayedId: number | string): GeoContext {
		const newDisplayedTerritories = typeof displayedId === 'string' ? new DisplayedContoursInfo(displayedId) : new DisplayedTurfsInfo(displayedId)
		return this.withNewDisplayedTerritories(newDisplayedTerritories)
	}

	/**
	 * Helper to zoom to a different territory
	 */
	zoomTo({ newDisplayedTerritories, newParentTerritoryId, newLabel }): GeoContext {
		const displayed = this.#config.displayed
		return new GeoContext({
			...this.asClonedObj,
			parents: displayed instanceof DisplayedContoursInfo
				? new ParentContoursInfo({ scale: displayed.scale, ids: [newParentTerritoryId] })
				: new ParentTurfsInfo({ layerId: displayed.layerId, ids: [newParentTerritoryId] }),
			label: newLabel,
			displayed: newDisplayedTerritories,
			highlightedTerritoryId: null,
		})
	}

	/**
	 * Helper to highlight a specific territory
	 */
	highlightTerritory({ territoryId, newLabel }: { territoryId: number | string, newLabel: string }): GeoContext {
		return new GeoContext({
			...this.asClonedObj,
			highlightedTerritoryId: territoryId,
			label: newLabel,
		})
	}

	get displayed(): DisplayedTerritoriesInfo {
		return this.#config.displayed
	}

	get parents(): ParentTerritoriesInfo {
		return this.#config.parents
	}

	get label(): string {
		return this.#config.label
	}

	get geoRootAlias(): string {
		return this.#config.geoRootAlias
	}

	get highlightedTerritoryId(): string | number {
		return this.#config.highlightedTerritoryId
	}

	/*
	 * Key of the geo context
	 */
	get key(): string {
		return (
			`parents: ${this.parents.key}\n`
			+ `displayed: ${this.displayed.key}\n`
			+ `highlightedTerritoryId: ${this.highlightedTerritoryId}`
		)
	}

	/**
	 * Helper to generate the proper API geo_context parameters
	 */
	asApiGeoContextPayload(ignoreHighlighted: boolean = false): GeoContextPayload {
		// make sure we are deterministic not to fill the cache with too much stuff
		const parentIds = [...this.parents.ids]
		parentIds.sort()

		const parents = this.highlightedTerritoryId !== null && ignoreHighlighted === false
			? (
					this.displayed instanceof DisplayedTurfsInfo
						? { layer_id: this.displayed.layerId, turf_ids: [<number> this.highlightedTerritoryId] }
						: {	scale: this.displayed.scale,	administrative_ids: [<string> this.highlightedTerritoryId] }
				)
			: (
					this.parents instanceof ParentTurfsInfo
						? { layer_id: this.parents.layerId, turf_ids: <number[]> parentIds }
						: {	scale: this.parents.scale,	administrative_ids: <string[]> parentIds }
				)

		const displayed = this.displayed instanceof DisplayedTurfsInfo
			? { layer_id: this.displayed.layerId }
			: { scale: this.displayed.scale }

		return { geo_root_alias: this.geoRootAlias, parents, displayed }
	}

	/**
	 * Helper to tell if the highlighted territory has changed
	 * While staying in the same general geo-context.
	 */
	highlightedTerritoryHasChangedSince = (oldGeoContext: GeoContext): boolean =>
		this.highlightedTerritoryId !== null
		|| (
			oldGeoContext !== null
			&& typeof oldGeoContext !== 'undefined'
			&& this.displayed.equals(oldGeoContext.displayed)
			&& this.parents.equals(oldGeoContext.parents)
		)
}

export default GeoContext
