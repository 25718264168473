import { defineStore } from 'pinia'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { RemovableRef } from '@vueuse/core'
import TransactionsRequester from '@/requesters/transactions'
import { useGivesStore } from '@/stores/gives'
import { transactionBundleDeserializer } from '@/requesters/transactionSerializer'
import { ElMessage } from 'element-plus'
import { historyTransformer } from '@/stores/TransactionHistoryTransformer.mjs'

const stateDatabaseId = 'databaseId'
const statePaymentTypology = 'paymentTypology'
const stateChequeNumber = 'chequeNumber'
const stateReference = 'reference'
const stateIban = 'iban'
const stateCreatedAt = 'createdAt'
const stateDate = 'date'
const stateCurrency = 'currency'
const stateRecurrence = 'recurrence'
const stateAmount = 'amount'
const stateCampaignCode = 'campaignCode'
const stateStatusId = 'status_id'
// const stateMainContactId = 'mainContactId'
const stateMainContact = 'mainContact'
const benefitContactId = 'benefitContactId'
const benefitsContacts = 'benefitsContacts'
const stateBatchId = 'batchId'
const isNestedTunnel = 'isNestedTunnel'
const stateUserId = 'user_id'
const stateTempBatchId = 'tempBatchId'
const stateBatch = 'batch'
const stateComment = 'comment'
const stateCommentDate = 'comment_date'
const stateMotifId = 'motif_id'
const stateUnpaidAmount = 'unpaid_amount'
const stateReimbursedAmount = 'reimbursed_amount'
const tunnelRoutesHistory = 'tunnelRoutesHistory'
const stateBundleId = 'bundleId'

export const stateKeys = {
	stateDatabaseId,
	stateChequeNumber,
	stateReference,
	statePaymentTypology,
	stateIban,
	stateCreatedAt,
	stateDate,
	stateCurrency,
	stateRecurrence,
	stateAmount,
	stateCampaignCode,
	stateMainContact,
	stateStatusId,
	benefitContactId,
	benefitsContacts,
	stateBatchId,
	isNestedTunnel,
	stateUserId,
	stateTempBatchId,
	stateBatch,
	stateComment,
	stateCommentDate,
	stateMotifId,
	stateUnpaidAmount,
	stateReimbursedAmount,
	tunnelRoutesHistory,
	stateBundleId,
}

export const stateKeysPaymentDetails = {
	stateChequeNumber,
	stateReference,
	stateIban,
}

interface benefitsContactsInterface {
	contactId: string
	contactFirstname: string
	contactLastname: string
	contactCounty: string
	donation: Array<Object>
	membership: Array<Object>
}
interface mainContact {
	mainContactId: string
	mainContactFirstname: string
	mainContactLastname: string
	mainContactMembershipNumber: string
	mainContactCounty: string
}

interface Transaction {
	databaseId: number
	paymentTypology: string
	chequeNumber: string
	reference: string
	iban: string
	date: string
	currency: string
	recurrence: string
	amount: number
	campaignCode: string
	status_id: number
	// mainContactId: RemovableRef<null>
	mainContact: mainContact
	benefitContactId: null
	benefitsContacts: Array<benefitsContactsInterface>
	batchId: number
	isNestedTunnel: boolean
	// For user's transactions only in role check
	user_id: number
	tempBatchId: number
	reimbursed_amount: number
	unpaid_amount: number
}

interface transactionState {
	currentTransaction: RemovableRef<Transaction>
	tunnelRoutesHistory: RemovableRef<Array<any>>
	transactionBundleFreezed: Object
	transactionHistory: Array<object>
	history_loading_status: String
	transaction_request_status: String
	lastRoutePathBeforeTunnel: String
}

const initialState = () => {
	return {
		[stateDatabaseId]: null,
		[statePaymentTypology]: '',
		[stateChequeNumber]: '',
		[stateReference]: '',
		[stateIban]: '',
		[stateCreatedAt]: null,
		[stateDate]: null,
		[stateCurrency]: '',
		[stateRecurrence]: '',
		[stateAmount]: 0,
		[stateCampaignCode]: '',
		[stateStatusId]: 0,
		[stateMainContact]: {
			mainContactId: '',
			mainContactFirstname: '',
			mainContactLastname: '',
			mainContactMembershipNumber: '',
			mainContactCounty: '',
		},
		[benefitContactId]: null,
		[benefitsContacts]: {},
		[stateBatchId]: null,
		[isNestedTunnel]: false,
		[stateUserId]: 0,
		[stateTempBatchId]: null,
		[stateBatch]: null,
		[stateComment]: null,
		[stateCommentDate]: null,
		[stateMotifId]: null,
		[stateUnpaidAmount]: null,
		[stateReimbursedAmount]: null,
	}
}

export const useTransactionStore = defineStore('transactionTunnel', {
	state: (): transactionState => {
		return {
			currentTransaction: initialState(),
			tunnelRoutesHistory: [],
			transactionBundleFreezed: null,
			transactionHistory: [],
			history_loading_status: 'default',
			transaction_request_status: 'default',
			lastRoutePathBeforeTunnel: '',
		}
	},

	actions: {
		actionCleanStore() {
			this.actionCleanMainContactId()
			this.actionCleanBenefitsContacts()
			this.transactionHistory = []
			Object.assign(this.currentTransaction, initialState())
		},

		actionCleanMainContactId() {
			this.currentTransaction[stateMainContact] = initialState().stateMainContact
		},

		actionCleanBenefitsContacts() {
			this.currentTransaction[benefitsContacts] = {}
		},

		actionSetMainContact(contact: mainContact) {
			this.currentTransaction[stateMainContact] = contact
		},

		actionSetBenefitsContacts(benefitsContactsData: benefitsContactsInterface) {
			this.currentTransaction[benefitsContacts] = benefitsContactsData
		},

		actionSetInformations(informations: Object) {
			Object.keys(this.currentTransaction).map((key) => {
				if (key in informations) {
					this.currentTransaction[key] = informations[key]
				}
			})
		},

		actionDeserializeTransactionBundle(bundle: Object) {
			const giveStore = useGivesStore()
			const donations = giveStore.getDonationsList
			const memberships = giveStore.getMembershipsList
			const transactionSerialized = transactionBundleDeserializer(
				bundle,
				donations,
				memberships,
			)
			return transactionSerialized
		},

		async actionRequestGetTransaction(id: number, fallBackRouting: Function, t: Function) {
			this.transaction_request_status = 'loading'

			try {
				const response = await TransactionsRequester.getTransactionBundleId(id)
				const transactionSerialized = this.actionDeserializeTransactionBundle(response)

				const mergedStore = merge(this.currentTransaction, transactionSerialized)

				/// ⏬ Used in newTransactionMainContainer to compare both bundle to disabled save button
				this.transactionBundleFreezed = cloneDeep(mergedStore)
				this.currentTransaction = mergedStore
				this.transaction_request_status = 'default'
			}
			catch (error) {
				if (fallBackRouting) {
					fallBackRouting()
				}
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
				this.transaction_request_status = 'error'
				throw error
			}
		},

		actionSetBatchMode(batchId: Number) {
			this.currentTransaction[stateBatchId] = batchId
			this.currentTransaction[stateTempBatchId] = batchId
		},

		actionSetCurrentTransactionStatus(status: Object) {
			this.currentTransaction[stateStatusId] = status.status_id
			this.currentTransaction[stateReimbursedAmount] = status.reimbursed_amount
			this.currentTransaction[stateUnpaidAmount] = status.unpaid_amount
			this.currentTransaction[stateMotifId] = status.motif_id
			this.currentTransaction[stateCommentDate] = status.comment_date
			this.currentTransaction[stateComment] = status.comment
		},

		async actionPatchTransactionLocalyOnly(data: Object) {
			Object.keys(data).map((key) => {
				if (key in this.currentTransaction) {
					this.currentTransaction[key] = data[key]
				}
			})
			return true
		},

		async actionGetTransactionHistoryById() {
			this.history_loading_status = 'loading'

			try {
				const response = await TransactionsRequester.getTransactionHistoryById(
					this.currentTransaction[stateBundleId],
				)

				if (response) {
					const responseItems = await historyTransformer(response)

					this.transactionHistory = responseItems
					this.history_loading_status = 'default'
				}
				this.history_loading_status = 'error'
				return
			}
			catch (error) {
				this.history_loading_status = 'error'
				console.log(error)
			}
		},

		async actionSetLastRoutePathBeforeTunnel(path: String, router: Object) {
			const splittedRoute = path.split('/')

			/// Si on refresh le tunnel, pour ne pas set la même route et ne plus pouvoir leave
			if (splittedRoute[1] === 'transaction') {
				this.lastRoutePathBeforeTunnel = '/donation-and-membership/transactions'

				return
			}

			this.lastRoutePathBeforeTunnel = router?.options?.history?.state?.back
		},
	},

	getters: {
		getTransactionHistoryLoadingStatus() {
			return this.history_loading_status
		},

		getTransactionLoadingStatus() {
			return this.transaction_request_status
		},

		getLastRoutePathBeforeTunnel() {
			return this.lastRoutePathBeforeTunnel
		},
	},
})
