<template>
	<div id="login-vue-recover">
		<div class="container">
			<div class="logo-container"><img src="/static/images/qomon/black.svg" alt="Qomon" /></div>
			<form v-if="activated" class="form-signin">
				<div class="center">
					<h2 class="center">{{ t.validation }}</h2>
				</div>
			</form>
			<form v-else class="form-signin">
				<div class="center">
					<h2 class="center">{{ t.pendingValidation }}</h2>
					<p v-if="hasError" class="text-recover action" @click="validate">{{ t.retry }}</p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import session_store from '../../models/session_store.js'

/**
 * @component App/Validation
 * @route /validationuser
 */
export default {
	data() {
		return {
			hasError: false,
			activated: false,
		}
	},

	computed: {
		t() {
			let prefix = 'APP.VALIDATION'
			return {
				pendingValidation: this.$t(`${prefix}.PENDING_TEXT`, {
					email: this.$route.query.mail,
				}),
				validation: this.$t(`${prefix}.VALIDATION`, {
					email: this.$route.query.mail,
				}),
				nextStep: this.$t(`${prefix}.NEXT`),
				nextLink: this.$t(`${prefix}.NEXT_LINK`),
				retry: this.$t(`${prefix}.RETRY`),
				messages: {
					success: this.$t(`${prefix}.MESSAGES.SUCCESS`),
					failure: this.$t(`${prefix}.MESSAGES.ERROR`),
				},
			}
		},
	},

	created() {
		this.validate()
	},

	methods: {
		validate() {
			this.hasError = false
			session_store.validate(this.$route.query).then(
				() => {
					this.activated = true
					this.$message({
						message: this.t.messages.success,
						type: 'success',
						showClose: true,
					})
				},
				(err) => {
					this.$message({
						message: this.t.messages.failure,
						type: 'error',
						showClose: true,
					})
					console.warn(err)
					this.hasError = true
				}
			)
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/app/login-recover.scss';
</style>
