<template>
	<div ref="campaignSettingsContainer" class="px-2 pt-2 h-full">
		<div ref="campaignSettingHeader" class="w-full bg-white p-5 z-40 sticky top-0 menu-shadow">
			<div class="flex">
				<div
					v-for="(tab, index) in computedGetTabs"
					:key="index"
					:class="{
						'cursor-not-allowed pointer-events-none': tab.isSoon,
					}"
					class="flex"
				>
					<tab-with-number
						v-if="tab.isAuthorized"
						:title="tab.title"
						:selected="tab.selected"
						:status-pack="tab.status"
						:badge="tab.badge"
						:tag="tab.tag"
						:no-opacity="tab.noOpacity"
						:is-soon="tab.isSoon"
						:count="tab.count"
						font-size="1.1rem"
						class="p-3"
						@tab-click="goto(tab)"
					/>
				</div>
			</div>
		</div>
		<div class="mt-1">
			<router-view
				:style="{ height: tableHeight || methodComputeTableHeight }"
				class="overflow-auto"
				:table-height="tableHeight"
				@need-table-height="() => methodComputeTableHeight()"
			></router-view>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { nextTick } from 'vue'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},
	data() {
		return {
			tabSelected: 'campaign',
			tableHeight: '',
		}
	},
	computed: {
		...mapGetters('@invoice', ['getNumberOfInvoices']),
		...mapGetters('@packs', ['getAccessWebSegmentsLimitation']),
		...mapGetters(['userConnected']),

		computedGetTabs() {
			return [
				{
					title: this.$t('MENU.MY_SPACE'),
					name: 'campaign',
					selected: this.tabSelected === 'campaign',
					status: 'enabled',
					isSoon: false,
					isAuthorized: true,
				},
				{
					title: this.$t('SETTINGS.ROLE_MANAGEMENT.TITLE'),
					name: 'role-management-settings',
					status: 'enabled',
					selected: this.tabSelected === 'role-management-settings',
					tag: 'span',
					isAuthorized: this.userConnected?.role_data?.access?.includes('settings.roles:delete'),
				},
				{
					title: this.$t('SETTINGS.SEGMENTS.TITLE'),
					name: 'segments-main',
					status: 'enabled',
					selected: this.tabSelected === 'segments-main',
					tag: 'span',
					isAuthorized: this.getAccessWebSegmentsLimitation === 'enabled',
				},
				{
					title: this.$t('SETTINGS.INVOICE.TITLE'),
					name: 'invoices-params',
					status: 'enabled',
					selected: this.tabSelected === 'invoices-params',
					tag: 'span',
					badge: 'premium',
					isAuthorized: this.userConnected?.role_data?.access?.includes('settings.billing:delete'),
				},
				{
					title: this.$t('SETTINGS.HEADER.PRIVACY_POLICY'),
					name: 'privacy-policy-settings',
					status: 'enabled',
					selected: this.tabSelected === 'privacy-policy-settings',
					tag: 'span',
					badge: 'premium',
					isAuthorized: true,
				},
			].sort((a, b) => {
				return ('' + a.status).localeCompare(b.status)
			})
		},
	},
	watch: {
		'$route.name': {
			handler: function (newVal) {
				this.tabSelected = newVal
			},
			deep: true,
			immediate: true,
		},
	},
	updated() {
		this.methodComputeTableHeight()
	},
	mounted() {
		this.tabSelected = this.$route.name
		this.actionFetchInvoices()
		this.methodComputeTableHeight()
		window.addEventListener('resize', this.methodComputeTableHeight)
	},
	unmounted() {
		window.removeEventListener('resize', this.methodComputeTableHeight)
	},
	methods: {
		...mapActions('@invoice', ['actionFetchInvoices']),

		methodComputeTableHeight() {
			nextTick(() => {
				if (this.$refs.campaignSettingsContainer && this.$refs.campaignSettingHeader)
					this.tableHeight = `calc(${
						this.$refs?.campaignSettingsContainer?.offsetHeight -
						this.$refs?.campaignSettingHeader?.offsetHeight
					}px - 1rem)`
			})
		},

		goto(tab) {
			this.$router.push({ name: tab.name, query: tab.query })
		},
	},
}
</script>

<style lang="scss" scoped>
.menu-shadow {
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px;
}
</style>
