<template>
	<div class="h-full flex flex-col mx-6">
		<div>
			<p
				class="text-4xl my-8 font-title w-full"
			>
				{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAIN_TITLE') }}
			</p>
		</div>
		<div class="gap-3 flex flex-col flex-grow items-center ">
			<div
				v-for="(aSelectionType, index) in selectionTypes.allValues"
				:key="index"
				class="flex w-full cursor-pointer"
				@click="setAssignmentTypeAction(aSelectionType.value)"
			>
				<div
					class="h-40 flex w-full border rounded-lg items-center"
					v-if="!aSelectionType.disabled"
				>
					<div class="flex flex-row items-center h-full  flex-grow">
						<div class="h-full flex items-center mx-6">
							<i
								class="text-2xl"
								:class="aSelectionType.value !== selectionTypes.currentValue ? 'gui-radio_off' : 'gui-radio_on'"
							/>
						</div>

						<div class="flex h-full flex-grow  flex-col items-start justify-center">
							<p class="title">
								{{ aSelectionType.label }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, defineEmits, ref, computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const getAccessAssignementAllUsers = computed(() => store.getters['@packs/getAccessAssignementAllUsers']) === 'enabled'
const getAccessAssignementOpen = computed(() => store.getters['@packs/getAccessAssignementOpen']) === 'enabled'

const selectionTypes = ref({
	allValues: [
		{ value: 'customSelection', label: computed(() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.CUSTOM_SELECTION')), icon: 'gui-ministars text-xl', disabled: false },
		{ value: 'allUsers', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.ALL_USERS')), icon: '', disabled: computed(() => getAccessAssignementAllUsers) },
		{ value: 'open', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.OPEN')), icon: '', disabled: computed (() => getAccessAssignementOpen) },
	],
	currentValue: 'customSelection',
})

import { useStore } from 'vuex'

const store = useStore()
const getNewActionAssignmentType = computed(() => store.getters['@action/getNewActionAssignmentType'])

const emit = defineEmits(['update:nextValidatorBoolean'])

onMounted(() => {
	emit('update:nextValidatorBoolean', true)

	setStep()
})

const setStep = (value) => {
	store.dispatch('@action/actionSetStep', 'SELECT_MEMBERS_WHO')
}

const setAssignmentTypeAction = (value) => {
	selectionTypes.value.currentValue = value
	store.dispatch('@action/actionSetAssignmentType', value)
}

onMounted(() => {
	if (getNewActionAssignmentType.value) {
		selectionTypes.value.currentValue = getNewActionAssignmentType.value
	}
})

</script>

<style lang="scss" scoped>
</style>
