/**
 * @store @docs
 */
import Module from './ModuleFactory'
import documentsStore from '../../models/documents_store'
import { id } from 'date-fns/locale'

/**
 * Sort by id and set default subject and change '' to null for category
 */
const sortByOldestId = (array) => {
	array.sort((a, b) => {
		if (a.id < b.id) return 1
		else return -1
	})

	// Remove empty values abd change subject
	const emptyCategories = ['', ' ', undefined, 'empty', null]
	array.forEach((doc) => {
		doc.subject = doc.subject ? doc.subject : ''
		if (emptyCategories.includes(doc.category)) {
			doc.category = null
		}
	})
}

export const state = {
	docs: [],
	loading: false,
	categories: [],
	docsCategories: [],
}
export const mutations = {
	SET_DOCS(state, payload) {
		state.docs = payload
	},
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_CATEGORIES(state, payload) {
		state.categories = payload
	},
	SET_DOCS_CATEGORIES(state, payload) {
		state.docsCategories = payload
	},
}

export const actions = {
	/**
	 * Retrieve docs from the backend
	 * @action actionFetchDocs
	 */
	async actionFetchDocs({ commit }) {
		const docs = await documentsStore.getDocuments()
		sortByOldestId(docs)
		commit('SET_DOCS', docs)
	},

	/**
	 * Add doc to backend
	 * @action actionFetchDocs
	 * @property {Array} payload List of files
	 */
	async actionAddDocs({ dispatch, commit, state }, payload) {
		commit('SET_LOADING', true)

		var type = 'success'
		let docInfo = {}
		const { files, notify, docType, urlName, docCategoryId, cover, category, is_a_sms_file } = payload
		if (docType === 'link') {
			const recognizedTypes = [
				'docs.google',
				'drive.google',
				'1drv',
				'dropbox',
				'icloud',
				'sharepoint',
				'youtube',
			]
			const index = recognizedTypes.findIndex((i) => {
				if (files.includes(i)) return true
				return false
			})
			let correctType

			if (index === -1) {
				correctType = 'other_link'
			}
			else {
				switch (recognizedTypes[index]) {
					case 'docs.google':
						correctType = 'google_docs'
						break
					case 'drive.google':
						correctType = 'google_drive'
						break
					case '1drv':
						correctType = 'one_drive'
						break
					case 'dropbox':
						correctType = 'dropbox'
						break
					case 'icloud':
						correctType = 'icloud'
						break
					case 'sharepoint':
						correctType = 'sharepoint'
						break
					case 'youtube':
						correctType = 'youtube'
						break
				}
			}
			const file = { file: '' }

			const params = {
				private: false, // TODO: Publish
				url: files,
				category: category,
				subject: urlName,
				cover: cover,
				doc_category_id: docCategoryId,
				type: correctType, // Set type of url
				minimum_role: 'user', // TODO: Set to appropriate value
				notify,
				is_a_sms_file,
			}

			if (!params.doc_category_id) {
				delete params.doc_category_id
			}

			if (!params.is_a_sms_file) {
				delete params.is_a_sms_file
			}

			await documentsStore
				.uploadDocument(params, file, 'link')
				.then((res) => {
					type = res?.body?.status == 'success' ? type : 'error'
					docInfo = res?.body?.data
				})
				.catch((error) => {
					console.error(error)
					type = error.status === 413 ? 'errorTooLarge' : 'error'
				})
		}
		else {
			await Promise.all(
				Array.from(files).map(async (file) => {
					const filePayload = { file }

					let subject = urlName.length ? urlName : file.name
					if (!subject?.length) {
						// If not subject (by chance, shouldn't happen), set name as Unnamed
						var array = state.docs
							.filter((doc) => doc?.subject?.startsWith('Unnamed '))
							.map((doc) => {
								try {
									var result = parseInt(doc.subject.replace('Unnamed ', ''))
									return result ? result : -1
								}
								catch {
									return -1
								}
							})
						var max = Math.max(array)
						var index
						for (let i = 1; i <= max + 1; i++) {
							if (!array.includes(i)) {
								index = i
								break
							}
						}
						subject = `Unnamed ${index}`
					}

					let newSubject = files.length > 1 ? file.name : subject

					let newCover = files.length > 1 ? '' : cover
					let newDocCategoryId = files.length > 1 ? '' : docCategoryId

					const params = {
						private: false,
						category: category,
						subject: newSubject,
						cover: newCover,
						doc_category_id: newDocCategoryId,
						type: file.type,
						minimum_role: 'user',
						notify,
					}

					if (params.doc_category_id === null) {
						delete params.doc_category_id
					}

					await documentsStore
						.uploadDocument(params, filePayload, 'file')
						.then((res) => {
							type = res?.body?.status == 'success' ? type : 'error'
							docInfo = res?.body?.data
						})
						.catch((error) => {
							console.error(error)
							type = error.status === 413 ? 'errorTooLarge' : 'error'
						})
				}),
			).catch((e) => {
				console.error(e)
				type = 'error'
			})
		}
		await dispatch('actionFetchDocs')
		commit('SET_LOADING', false)
		return { type, docInfo, action: 'add', number: files.length }
	},

	/**
	 * Send a notification for a document
	 * @param {object} payload Id of the document
	 * @returns success or error
	 */
	async actionNotifyDocument({}, payload) {
		let type = 'success'
		await documentsStore
			.notifyDocument(payload)
			.then(() => {
				type = 'success'
			})
			.catch(() => {
				type = 'error'
			})
		return type
	},

	/**
	 * Update doc in backend
	 * @action updateDoc
	 * @property {Object} payload Document to update
	 */
	async updateDoc({ dispatch }, payload) {
		var type
		if (!payload.category) {
			payload.category = 'empty'
		}

		if (!payload.doc_category_id) {
			delete payload.doc_category_id
		}
		await documentsStore
			.updateDocument(payload)
			.then(async (res) => {
				await dispatch('actionFetchDocs')
				type = res?.body?.status == 'success' ? 'success' : 'error'
			})
			.catch((error) => {
				console.error(error)
				type = 'error'
			})
		return { type, action: 'update' }
	},

	async downloadDoc(payload) {
		var type
		var result
		await documentsStore
			.downloadDocument(payload)
			.then(async (res) => {
				result = res
			})
			.catch((error) => {
				console.error(error)
				type = 'error'
			})
		return { type, action: 'download', result }
	},

	/**
	 * Delete doc
	 * @action deleteDoc
	 * @property {String} payload Id of document to delete
	 */
	async deleteDoc({ dispatch, commit }, payload) {
		commit('SET_LOADING', true)
		var type
		await documentsStore
			.deleteDocument(payload)
			.then(async (res) => {
				await dispatch('actionFetchDocs')
				type = res.status === 200 ? 'success' : 'error'
			})
			.catch((error) => {
				console.error(error)
				type = 'error'
			})
		commit('SET_LOADING', false)
		return { type, action: 'delete', number: 1 }
	},

	/**
	 * Delete docS
	 * @action deleteDocs
	 * @property {Array} payload Ids of documents to delete
	 */
	async deleteDocs({ dispatch, commit }, payload) {
		commit('SET_LOADING', true)
		var result = {}
		var type = 'success'
		await Promise.all(
			payload.map(async (id) => {
				await documentsStore
					.deleteDocument(id)
					.then((res) => {
						type = res.status === 200 ? type : 'error'
					})
					.catch((error) => {
						console.error(error)
						type = 'error'
					})
			}),
		)
			.then(async () => {
				await dispatch('actionFetchDocs')
				result = { type, action: 'delete', number: payload.length }
			})
			.catch((e) => {
				console.error(e)
				result = { type: 'error', action: 'delete' }
			})
		commit('SET_LOADING', false)
		return result
	},

	actionDeleteImageUploaded: async ({}, payload) => {
		return documentsStore.deletePhotoUploaded(payload)
	},

	/**
	 * Fetch possible categories
	 * @action actionFetchCategories
	 */
	async actionFetchCategories({ commit }) {
		const categories = await documentsStore.getCategories()

		commit('SET_CATEGORIES', ['internal', 'external'])
		commit('SET_DOCS_CATEGORIES', categories || [])
	},
	async actionAddDocsCategory({ commit }, payload) {
		const newCategory = await documentsStore.addDocsCategory(payload)
		const newCategories = [...state.docsCategories, newCategory]

		commit('SET_DOCS_CATEGORIES', newCategories)
		return newCategory
	},
	async actionDeleteDocsCategory({ commit }, id) {
		const newCategory = await documentsStore.deleteDocsCategory(id)
		const newCategories = state.docsCategories.filter((category) => category.id !== id)

		commit('SET_DOCS_CATEGORIES', newCategories)
		return newCategory
	},
	async actionPatchDocsCategory({ commit }, payload) {
		const newCategory = await documentsStore.patchDocsCategory(payload)

		// const newCategories = state.docsCategories.filter((category) => category.id !== id)

		if (newCategory) {
			const newCategories = state.docsCategories.map((category) =>
				category.id === newCategory.id ? newCategory : category,
			)
			commit('SET_DOCS_CATEGORIES', newCategories)
		}

		return newCategory
	},
	async actionPatchDocsCategories({ commit }, payload) {
		const newCategories = await documentsStore.patchDocsCategories(payload)

		// const newCategories = state.docsCategories.filter((category) => category.id !== id)

		commit('SET_DOCS_CATEGORIES', newCategories)

		return newCategories
	},
}

const getters = {
	docs: (state) => state.docs,
	loading: (state) => state.loading,
	categories: (state) => state.categories,
	docsCategories: (state) => state.docsCategories,
}

export const docs = new Module({
	state,
	getters,
	actions,
	mutations,
})
