<template>
	<el-dialog id="segment-dialog" v-model="displayModal" width="80%">
		<div class="flex flex-col gap-y-6">
			<div>
				<div class="flex flex-row gap-x-6">
					<TabWithNumber
						:title="
							computedIsSegmentCreation
								? t('SETTINGS.SEGMENTS.MODAL.TITLE')
								: t('SETTINGS.SEGMENTS.MODAL.FILTERS')
						"
						:selected="tabSelected === 'filters'"
						@tab-click="tabSelected = 'filters'"
					/>
					<!-- <TabWithNumber
						:title="t('SETTINGS.SEGMENTS.MODAL.INFORMATION')"
						:selected="tabSelected === 'infos'"
						@tab-click="tabSelected = 'infos'"
					/> -->
				</div>
				<div class="flex-col flex text-xl gap-y-2">
					<el-divider></el-divider>
					<div :style="'margin-left: 10rem;'" class="flex flex-col gap-y-1 w-1/3">
						<label class="text-pink-main text-sm">{{ t('SETTINGS.SEGMENTS.MODAL.NAME') }}</label>
						<el-input v-model="refLocalSegmentToEdit.title"></el-input>
					</div>
				</div>
			</div>
			<div v-show="tabSelected === 'filters'">
				<ContainerFilter
					v-if="refRegisteredModules"
					:force-query="refLocalSegmentToEdit?.deserializedPayload"
					:reset-search="refResetAdvancedSearch"
					@update="methodDispatchKpiLocalContactsFromAdvancedSearch"
				/>
			</div>
			<!-- <div
				v-if="!computedIsSegmentCreation"
				v-show="tabSelected === 'infos'"
				class="flex flex-col gap-y-2 w-1/2"
			>
				<label class="text-pink-main text-sm">{{ t('SETTINGS.SEGMENTS.MODAL.NAME') }}</label>
				<el-input v-model="refLocalSegmentToEdit.title"></el-input>
			</div> -->
			<div class="flex flex-row items-center justify-between">
				<span
					v-show="tabSelected === 'filters'"
					class="text-gray-500 hover:opacity-75 cursor-pointer"
					@click="methodResetAdvancedFilter"
					>{{ t('SETTINGS.SEGMENTS.MODAL.REINITIATE') }}
				</span>
				<span v-show="tabSelected === 'infos'"></span>

				<div class="flex flex-row gap-x-8 items-center justify-center">
					<span class="">{{
						t('SETTINGS.SEGMENTS.MODAL.CONTACTS', computedGetNumberContactsFoundsAdvancedSearchKPI)
					}}</span>
					<el-tooltip
						:disabled="computedIsTitleCompleted"
						placement="top"
						:content="t('SETTINGS.SEGMENTS.MODAL.REQUIRED')"
					>
						<q-button
							:disabled="computedIsBuildedSegmentEqual || !computedIsTitleCompleted"
							@click="methodUpdateSegmentSelected"
						>
							<span v-if="computedIsSegmentCreation">{{ t('_COMMON.CREATE') }}</span>
							<span v-else>{{ t('_COMMON.TO_UPDATE') }}</span></q-button
						>
					</el-tooltip>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import { ElMessage } from 'element-plus'
import { searchModule } from '@/store/modules/search-module'
import { filterModule } from '@/store/modules/filter_module'
import { kpiModule } from '@/store/modules/kpi_module'
import { formModule } from '@/store/modules/form-module'
import TabWithNumber from '@/components/general/tabWithNumber.vue'
import ContainerFilter from '@/components/filters-module/containerFilter.vue'

const store = useStore()
const { t } = useI18n()

const displayModal = defineModel('displayModal', {
	type: Boolean,
})

const refLocalSegmentToEdit = ref<SegmentItem | null>(null)
const refInstanciedModuleId = ref<string>('')
const refResetAdvancedSearch = ref<number>(0)
const refRegisteredModules = ref<boolean>(false)
const refQueryBuildedDispatched = ref<object>({})
const tabSelected = ref<string>('filters')

const props = defineProps({
	segmentSelected: {
		type: Object as () => SegmentItem | null,
		default: null,
	},
})

const computedGetNumberContactsFoundsAdvancedSearchKPI = computed(
	() =>
		store.getters[`@search-${refInstanciedModuleId.value}/getNumberContactsFoundsAdvancedSearchKPI`]
)

const computedIsSegmentCreation = computed(() => {
	return props?.segmentSelected?.id === 0
})

const computedIsTitleCompleted = computed(() => {
	return refLocalSegmentToEdit?.value?.title !== ''
})

const computedIsBuildedSegmentEqual = computed(() => {
	return (
		isEqual(
			refQueryBuildedDispatched?.value?.query,
			props.segmentSelected?.deserializedPayload.parse()
		) && refLocalSegmentToEdit?.value?.title === props.segmentSelected?.title
	)
})

watch(
	() => props.segmentSelected,
	() => {
		if (props.segmentSelected) {
			refLocalSegmentToEdit.value = cloneDeep(props.segmentSelected)
		}
	},
	{ immediate: true }
)

onBeforeMount(() => {
	const instanciedModuleId = uuidv4()
	var promiseSearchInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@search-${instanciedModuleId}`, searchModule))
	})

	var promiseFilterInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@filter-${instanciedModuleId}`, filterModule))
	})
	var promiseKpiInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@kpi-${instanciedModuleId}`, kpiModule))
	})
	var promiseFormInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@form-${instanciedModuleId}`, formModule))
	})

	const promises = [
		promiseSearchInstance,
		promiseFilterInstance,
		promiseKpiInstance,
		promiseFormInstance,
	]

	Promise.all(promises)
		.then(() => {
			refRegisteredModules.value = true
			refInstanciedModuleId.value = instanciedModuleId
		})
		.catch((err) => {
			console.log(err)
		})
})

const methodDispatchKpiLocalContactsFromAdvancedSearch = (queryBuilded: any) => {
	refQueryBuildedDispatched.value = queryBuilded
	if (refInstanciedModuleId.value && !isEmpty(refQueryBuildedDispatched.value)) {
		store.dispatch(
			`@search-${refInstanciedModuleId.value}/actionKpiLocalContactsFromAdvancedSearch`,
			queryBuilded
		)
	}
}

const methodUpdateSegmentSelected = async () => {
	refLocalSegmentToEdit.value.payload = JSON.stringify(refQueryBuildedDispatched.value.query)
	refLocalSegmentToEdit.value.is_segment = true
	if (refLocalSegmentToEdit.value.id === 0) {
		await store.dispatch('@lists/actionCreateList', refLocalSegmentToEdit.value)
	} else {
		await store.dispatch('@lists/actionUpdateList', refLocalSegmentToEdit.value)
	}
	displayModal.value = false
	ElMessage({
		message:
			refLocalSegmentToEdit.value.id === 0
				? t('SETTINGS.SEGMENTS.MODAL.CREATED')
				: t('SETTINGS.SEGMENTS.MODAL.UPDATED'),
		type: 'success',
		showClose: true,
	})
}

const methodResetAdvancedFilter = () => {
	refResetAdvancedSearch.value++
}
</script>

<style>
#segment-dialog .el-dialog__body {
	padding-top: 10px;
}
</style>
