<template>
	<!-- pug broke ? super huge file... -->
	<div id="editor-container">
		<div id="type-selector-container" class="selector-container">
			<div class="selector-container-shadow"></div>
			<div class="selector-content-container">
				<template v-for="option in questionTypes" :key="option.id">
					<div v-if="option.type == 'barrier'" class="type-selector-break"></div>
					<div
						v-else
						class="type-selector-option-container"
						@mouseenter="typeSelectorMouseEnter($event)"
						@mouseleave="typeSelectorMouseLeave($event)"
					>
						<div
							:id="option.type + '-selector-option'"
							class="type-selector-option clickable"
							@click="selectorClick(option, $event)"
						>
							<div class="type-selector-option-text">
								{{ getQuestionTypeText(option.type) }}
							</div>
							<div
								class="webapp-icons-24px-arrow-right"
								:class="{ placeholder: !option.subOptions }"
								aria-hidden="true"
							></div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div id="editable-forms">
			<template v-if="sections.length">
				<div v-for="(section, sectionIndex) in sections" :key="section.id" class="section">
					<div v-if="!limitedEdit" class="section-controls section-controls-top">
						<div
							aria-hidden="true"
							:class="{ inactive: sectionIndex <= 0 }"
							class="webapp-arrow-top move-section-icon move-section-up-icon icon icon-clickable"
							@mouseenter="arrowMouseEnter($event)"
							@mouseleave="arrowMouseLeave($event)"
							@click="moveSectionUp(sectionIndex)"
						></div>
						<div
							class="arrow-description up-arrow-description"
							@mouseenter="arrowMouseEnter($event)"
							@mouseleave="arrowMouseLeave($event)"
							@click="moveSectionUp(sectionIndex)"
						>
							{{ t.moveUp }}
						</div>
					</div>
					<div class="section-header-container">
						<div class="minimize-container">
							<div
								class="icon icon-clickable icon-section"
								aria-hidden="true"
								:class="[
									section.addedOptions.minimized
										? 'webapp-icons-24px-arrow-right'
										: 'webapp-icons-24px-arrow-down',
									{ 'move-up-spacing': !limitedEdit },
								]"
								:click="minimizeClick(sectionIndex, $event)"
							></div>
						</div>
						<div :class="{ 'move-up-spacing': !limitedEdit }" class="section-title">
							<text-input
								v-model:value="section.addedOptions.name"
								:title="t.sectionName"
								:placeholder="t.sectionName"
								:minimized="section.addedOptions.minimized"
								:editable="!limitedEdit"
							></text-input>
						</div>
					</div>
					<div
						v-if="!limitedEdit"
						class="webapp-icons-24px-close close-icon close-section icon icon-clickable icon-question"
						aria-hidden="true"
						@mouseenter="closeMouseEnter($event)"
						@mouseleave="closeMouseLeave($event)"
						@click="closeClick(sectionIndex, -1, -1)"
					></div>
					<div class="questions">
						<ul v-sortablejq="makeQuestionsSortable" class="sortable-questions">
							<li
								v-for="(question, questionIndex) in section.questions"
								:key="question.id"
								:class="[
									{ default: question.name == 'STATIC', minimized: section.addedOptions.minimized },
									question.type,
								]"
								class="question"
							>
								<div
									v-if="!limitedEdit"
									class="webapp-icons-24px-close close-icon close-question icon icon-clickable icon-question"
									aria-hidden="true"
									@mouseenter="closeMouseEnter($event)"
									@mouseleave="closeMouseLeave($event)"
									@click="closeClick(sectionIndex, questionIndex, -1)"
								></div>
								<div class="rest-of-container">
									<div
										v-if="!limitedEdit"
										class="drag-icon-container question-controls"
										:class="{ minimized: section.addedOptions.minimized }"
									>
										<div class="webapp-option-point-copy" aria-hidden="true"></div>
									</div>
									<div class="question-header-container" :class="{ 'top-element': limitedEdit }">
										<div
											v-if="!limitedEdit"
											class="question-type-select"
											:class="{ minimized: section.addedOptions.minimized }"
											@click="questionTypeSelectClick(sectionIndex, questionIndex, $event)"
										>
											<div class="question-type-text">{{ getQuestionTypeText(question.type) }}</div>
											<div
												class="webapp-icons-24px-arrow-down select-arrow icon icon-question"
												aria-hidden="true"
											></div>
										</div>
										<div
											class="item-text question-title"
											:class="{ minimized: section.addedOptions.minimized }"
											@click="section.addedOptions.minimized = false"
										>
											<text-input
												v-model:value="question.label"
												:title="t.question"
												:placeholder="t.question"
												:minimized="section.addedOptions.minimized"
												:editable="!limitedEdit"
											></text-input>
										</div>
									</div>
									<template v-if="question.type == 'text'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else-if="question.type == 'checkbox' || question.type == 'radio'">
										<div
											class="question-content list-inputs-container"
											:class="[
												{ minimized: section.addedOptions.minimized },
												question.type + '-sortable',
											]"
										>
											<div v-sortablejq="makeOptionsSortable" class="options-container">
												<div
													v-for="(option, optionIndex) in question.refvalues"
													:key="option.id"
													class="option"
													:class="question.type + '-option'"
												>
													<div
														v-if="!limitedEdit"
														class="webapp-option-point drag-vert drag icon icon-option"
														aria-hidden="true"
													></div>
													<div
														class="edit-option-icon option-icon"
														:class="question.type + '-icon'"
													></div>
													<text-input
														v-model:value="option.label"
														class="option-title"
														title=""
														placeholder="Option"
														:editable="!limitedEdit"
														:show-line="true"
													>
														<template #line-content>
															<div
																class="auto-send-toggle"
																@click="
																	autoSendToggleClick(sectionIndex, questionIndex, optionIndex)
																"
															>
																<div
																	v-if="option.activate_auto_send == 'true'"
																	class="auto-send-activated auto-send-button text-green-main"
																>
																	{{ t.mailAutoActived }}
																	<div
																		class="webapp-icons-24px-mail icon mail-icon"
																		aria-hidden="true"
																	></div>
																</div>
																<div v-else class="auto-send-deactivated auto-send-button">
																	{{ t.mailAutoDesactived }}
																	<div
																		class="webapp-icons-24px-no-email icon mail-icon"
																		aria-hidden="true"
																	></div>
																</div>
															</div>
														</template>
													</text-input>
													<div
														v-if="!limitedEdit"
														class="webapp-icons-24px-close close-icon close-option icon icon-clickable icon-question"
														aria-hidden="true"
														@mouseenter="closeMouseEnter($event)"
														@mouseleave="closeMouseLeave($event)"
														@click="closeClick(sectionIndex, questionIndex, optionIndex)"
													></div>
												</div>
											</div>
											<div
												v-if="!limitedEdit"
												class="option add-option"
												@click="addOptionClick(sectionIndex, questionIndex, $event)"
											>
												<i
													class="fa fa-ellipsis-v drag-vert drag icon icon-option placeholder"
													aria-hidden="true"
												></i>
												<div
													class="edit-option-icon option-icon"
													:class="question.type + '-icon'"
												></div>
												<div
													class="add-option-text option-text"
													@mouseenter="addOptionMouseEnter($event)"
													@mouseleave="addOptionMouseLeave($event)"
												>
													{{ t.addOption }}
												</div>
												<div
													class="webapp-icons-24px-closeclose-icon close-option icon icon-clickable icon-question placeholder"
													aria-hidden="true"
												></div>
											</div>
										</div>
									</template>
									<template v-else-if="question.type == 'date'">
										<div class="question-content spacing item-text disabled-input date-input">
											<i class="fa fa-calendar icon calendar-icon" aria-hidden="true"></i>
										</div>
									</template>
									<template v-else-if="question.type == 'range'">
										<div
											class="question-content range-question-content spacing"
											:class="{ minimized: section.addedOptions.minimized }"
										>
											<div class="range-slider-container">
												<input
													class="range-slider"
													type="range"
													min="0"
													max="2"
													value="1"
													disabled
												/>
											</div>
											<div class="range-input-container range-input-container-min">
												<div class="range-div range-div-min">{{ t.min }}:</div>
												<input
													v-model="question.refvalues[0].min"
													:disabled="limitedEdit"
													class="range-input range-input-min"
													type="number"
													:placeholder="t.min"
													@keyup="rangeNumberCheckKeyup($event)"
												/>
											</div>
											<div class="range-input-container range-input-container-step">
												<div class="range-div range-div-step">{{ t.increment }}:</div>
												<input
													v-model="question.refvalues[0].step"
													:disabled="limitedEdit"
													class="range-input range-input-step"
													type="number"
													:placeholder="t.increment"
													@keyup="rangeNumberCheckKeyup($event)"
												/>
											</div>
											<div class="range-input-container range-input-container-max">
												<div class="range-div range-div-max">{{ t.max }}:</div>
												<input
													v-model="question.refvalues[0].max"
													:disabled="limitedEdit"
													class="range-input range-input-max"
													type="number"
													:placeholder="t.max"
													@keyup="rangeNumberCheckKeyup($event)"
												/>
											</div>
										</div>
									</template>
									<template v-else-if="question.type == 'surname'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else-if="question.type == 'married_name'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else-if="question.type == 'firstname'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else-if="question.type == 'gender'">
										<div
											class="question-content radio-sortable list-inputs-container"
											:class="{ minimized: section.addedOptions.minimized }"
										>
											<div id="option-gender-male" class="option-default option">
												<div class="radio-icon edit-option-icon option-icon"></div>
												H
											</div>
											<div class="option-default option">
												<div class="radio-icon edit-option-icon option-icon"></div>
												F
											</div>
										</div>
									</template>
									<template v-else-if="question.type == 'age'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else-if="question.type == 'address'">
										<div
											class="question-content spacing"
											:class="{ minimized: section.addedOptions.minimized }"
										>
											<div class="double-input double-input-left item-text disabled-input"></div>
											<div class="double-input double-input-right item-text disabled-input"></div>
										</div>
									</template>
									<template v-else-if="question.type == 'city'">
										<div
											class="question-content spacing"
											:class="{ minimized: section.addedOptions.minimized }"
										>
											<div class="double-input double-input-left item-text disabled-input"></div>
											<div class="double-input double-input-right item-text disabled-input"></div>
										</div>
									</template>
									<template v-else-if="question.type == 'mail'">
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
									<template v-else>
										<div
											class="question-content spacing item-text disabled-input"
											:class="{ minimized: section.addedOptions.minimized }"
										></div>
									</template>
								</div>
							</li>
						</ul>
						<div
							v-if="!limitedEdit"
							:class="{ minimized: section.addedOptions.minimized }"
							class="add add-question"
							@click="addQuestionClick(sectionIndex)"
						>
							<div class="add-question-text">
								<div class="webapp-icons-24px-plus icon add-icon"></div>
								&nbsp;{{ t.addQuestion }}
							</div>
							<div v-sortablejq="makeAddQuestionSortable" class="add-question-target">
								<div>{{ t.placeholder }}</div>
							</div>
						</div>
					</div>
					<div v-if="!limitedEdit" class="section-controls section-controls-bottom">
						<div
							class="webapp-arrow-back move-section-icon move-section-down-icon icon icon-clickable"
							aria-hidden="true"
							:class="{ inactive: sectionIndex >= sections.length - 1 }"
							@mouseenter="arrowMouseEnter($event)"
							@mouseleave="arrowMouseLeave($event)"
							@click="moveSectionDown(sectionIndex)"
						></div>
						<div
							class="arrow-description down-arrow-description"
							@mouseenter="arrowMouseEnter($event)"
							@mouseleave="arrowMouseLeave($event)"
							@click="moveSectionDown(sectionIndex)"
						>
							{{ t.moveDown }}
						</div>
					</div>
				</div>
				<div v-if="!limitedEdit" class="add add-section" @click="addSectionClick">
					<div class="add-section-title">
						<div class="webapp-icons-24px-plus icon add-icon"></div>
						&nbsp;{{ t.addSection }}
					</div>
					<div v-sortablejq="makeAddSectionSortable" class="add-section-target add-text">
						<div>{{ t.placeholder }}</div>
					</div>
				</div>
			</template>
			<div v-else key="empty-sections-container" class="empty-sections-container">
				<div class="empty-sections">
					<div class="empty-image"></div>
					<div class="empty-title">{{ t.emptyState.title }}</div>
					<div class="empty-description">
						{{ t.emptyState.Tanya }}<br /><br />{{ t.emptyState.useTemplate }}
					</div>
					<div class="button button-primary" @click="addSectionClick">
						{{ t.emptyState.add }}
					</div>
				</div>
			</div>
		</div>
		<div id="undo-popup" class="undo-section">
			<span id="undo-popup-text" class="undo-span undo-text"></span>
			<span id="undo-popup-button" class="undo-span undo-button button" @click="undoButtonClick">
				{{ t.cancel }}
			</span>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
import app from '../../appCreation'

app.directive('sortablejq', {
	mounted: function (el, binding) {
		binding.value($(el))
	},
})

export default {
	name: 'FormEditor',

	components: {
		'text-input': require('../general/text-input.vue'),
	},
	// limitedEdit is a boolean; if true, it limits what on the page can be edited
	// currently, you can't change anything in a form, except for change the auto-send
	props: ['title', 'sections', 'limitedEdit'],
	emits: ['autoSendToggle', 'update:sections'],
	data: function () {
		return {
			selectorWidth: '',

			// not sure if best solution to store these here, but it means the click even doesn't need to be updated on every selector click
			curSelectorSectionIndex: 0,
			curSelectorQuestionIndex: 0,

			// Close/Undo data
			lastClosedEl: {},
			lastClosedSectionIndex: -1,
			lastClosedQuestionIndex: -1,
			lastClosedOptionIndex: -1,
			popupDisappearTimer: null,

			// sortable
			originalIndices: {},

			questionTypes: [
				//  deprecated
				// {
				// 	type: 'identity_field',
				// 	subOptions: [
				// 		{ type: 'surname', unique: true },
				// 		{ type: 'married_name', unique: true },
				// 		{ type: 'firstname', unique: true },
				// 		{ type: 'gender', unique: true },
				// 		{ type: 'age', unique: true },
				// 		{ type: 'barrier', unique: true },
				// 		{ type: 'address', unique: true },
				// 		{ type: 'city', unique: true },
				// 		{ type: 'barrier', unique: true },
				// 		{ type: 'mail', unique: true },
				// 		{ type: 'phone', unique: true },
				// 		{ type: 'mobile', unique: true }
				// 	]
				// },
				// { type: 'barrier' },
				{ type: 'text' },
				{ type: 'checkbox' },
				{ type: 'radio' },
				{ type: 'date' },
				{ type: 'range' },
			],
		}
	},

	computed: {
		...mapGetters(['info_campagne']),

		t() {
			const prefix = 'FORM.EDITOR'
			return {
				min: this.$t(`${prefix}.MIN`),
				max: this.$t(`${prefix}.MAX`),
				cancel: this.$t('_COMMON.CANCEL'),
				moveUp: this.$t(`${prefix}.MOVE_UP`),
				option: this.$t(`${prefix}.OPTION`),
				section: this.$t(`${prefix}.SECTION`),
				moveDown: this.$t(`${prefix}.MOVE_DOWN`),
				question: this.$t(`${prefix}.QUESTION`),
				increment: this.$t(`${prefix}.INCREMENT`),
				addOption: this.$t(`${prefix}.ADD_OPTION`),
				addSection: this.$t(`${prefix}.ADD_SECTION`),
				sectionName: this.$t(`${prefix}.SECTION_NAME`),
				addQuestion: this.$t(`${prefix}.ADD_QUESTION`),
				placeholder: this.$t(`${prefix}.PLACEHOLDER`),
				mailAutoActived: this.$t(`${prefix}.MAIL_AUTO_ACTIVED`),
				mailAutoDesactived: this.$t(`${prefix}.MAIL_AUTO_DESACTIVED`),
				emptyState: {
					add: this.$t(`${prefix}.EMPTY_STATE.ADD`),
					title: this.$t(`${prefix}.EMPTY_STATE.TITLE`),
					Tanya: this.$t(`${prefix}.EMPTY_STATE.TANYA`),
					useTemplate: this.$t(`${prefix}.EMPTY_STATE.USE_TEMPLATE`),
				},
				questionType: (type) =>
					type && this.$te(`${prefix}.QUESTION_TYPE.${type}`)
						? this.$t(`${prefix}.QUESTION_TYPE.${type}`)
						: '',
			}
		},

		computedSections: {
			get() {
				return this.sections
			},
			set(newValue) {
				this.$emit('update:sections', newValue)
			},
		},
	},

	mounted() {
		this.initializeEnvironment()
	},

	methods: {
		/* Initialization and general functions */
		// Some css styling has to be dynamically set; this is a wrapper function for all of those necessary operations
		//
		initializeEnvironment: function () {
			this.setupSelector()
			this.setupUndoPopup()
			this.setupSelectorDisappear()
		},

		// This sets up dynamic formatting ofr the selectors
		//
		// A couple things need to be done - most importantly, the width of the selector needs to be found:
		// So that the selector width doesn't change while going through different menus, find the
		// max width of all the texts and set the widht of the selector statically to that max width
		// selectorWidth also sets the width of all the question selects (so that all the widths match)
		// After doing this, the selectors should be hidden
		setupSelector: function () {
			// css style on all properties is border-box, so have to add border to size
			let width = Math.max.apply(
				null,
				$('.selector-container')
					.map(function () {
						return $(this).width()
					})
					.get()
			)

			this.selectorWidth = width

			// after setting the width, can set the display of identite to none (since it's a sub-menu)
			$('.selector-container').css('display', 'none')
			// also set the sub option's position (must account for border size)
			$('.sub-options').css(
				'left',
				this.selectorWidth - parseInt($('.selector-content-container').css('border-width')) + 'px'
			)
		},

		// function containing any initial dynamic css that must be applied to the undo popup once
		setupUndoPopup: function () {
			// format undo popups' initiafl position based on its height
			$('#undo-popup').css('bottom', '-' + $('#undo-popup').outerHeight() + 'px')
		},

		// whenever there is a click anywhere, the selector should go away; the only exceptions
		// are when the click comes from a type-selector-option, or a question-type-select div
		setupSelectorDisappear: function () {
			$(document).click(function (event) {
				if (
					!$(event.target).closest('.type-selector-option').length &&
					!$(event.target).closest('.question-type-select').length
				) {
					$('.selector-container').css('display', 'none')
					$('.type-selector-option-container.clicked').removeClass('hover').removeClass('clicked')
				}
			})
		},

		// since getting the Jquery object $(this) from an event is used a lot,  this function makes this operation
		// more maintainable if, for example, some browser's event's target is contained in a different field
		getThisFromEvent(event) {
			return $(event.currentTarget)
		},

		/* General UI functions */
		// gives focus to the input in the first text-input component contained in the textInputContainer object
		// textInputContainer can be either a DOM object or a Jquery object
		focusOnTextInput: function (textInputContainer) {
			$($(textInputContainer).find('input.text-input')[0]).focus()
		},

		// gives focus to the input at the given section, question and option index
		// only sectionIndex is required, the others can be set to -1 or omitted
		focusOnTextInputAtIndex: function (sectionIndex, questionIndex, optionIndex) {
			questionIndex = typeof questionIndex !== 'undefined' ? questionIndex : -1
			optionIndex = typeof optionIndex !== 'undefined' ? optionIndex : -1

			let el = $('#editable-forms').find('.section')[sectionIndex]
			if (questionIndex >= 0) {
				el = $(el).find('li.question')[questionIndex]
				if (optionIndex >= 0) {
					el = $(el).find('.option')[optionIndex]
				}
			}
			// the desired text-input should be the first text-input at this el's level
			this.focusOnTextInput(el)
		},

		/* JSON methods and helpers */
		// get the JSON object for a new, empty, section
		getNewSectionJson: function () {
			return {
				addedOptions: {
					name: '',
					minimized: false,
				},
				questions: [],
			}
		},

		// get the JSON object for a new, empty, question based on the question type and containing sectionJson that it
		// will be added to
		getNewQuestionJson: function (type, sectionJson) {
			let isStatic = this.isStatic(type)
			let newQuestion = {
				group_id: this.info_campagne.id,
				label: '',
				name: isStatic ? 'STATIC' : 'FORM',
				order: -1, // order doesn't matter for display; it will be fixed before API call
				section: sectionJson.addedOptions.name,
				type: type,
				activate: 'false', // TODO - is this right? - stored as string on DB
				activate_auto_send: null, // TODO - is this right? - stored as string on DB - null is false
			}

			if (!isStatic) {
				newQuestion.refvalues = this.getNewRefvalues(type)
			}

			return newQuestion
		},

		// get the JSON object for a new, empty, option (refvalue)
		getNewOptionJson: function () {
			return {
				group_id: this.info_campagne.id,
				label: '',
				value: '',
				order: -1, // order doesn't matter for display; it will be fixed before API call
				activate_auto_send: null,
			}
		},

		// gets the JSON array for a new, empty, refvalues based on a question type
		getNewRefvalues: function (type) {
			let refvalues = [
				{
					group_id: this.info_campagne.id,
					label: '',
					value: '',
					auto_send: '',
				},
			]
			if (type == 'text' || type == 'date') {
				return refvalues
			} else if (type == 'range') {
				refvalues[0].max = 0
				refvalues[0].min = 0
				refvalues[0].step = 0
				return refvalues
			} else {
				refvalues[0].order = 1
				refvalues.activate_auto_send = ''
				return refvalues
			}
		},

		// determine if a questionType is static (defualt)
		isStatic: function (type) {
			return $.inArray(type, ['text', 'checkbox', 'radio', 'date', 'range']) == -1
		},

		/* Add methods */
		// "Add Question" button click handler - add a question to the section at the given index
		addQuestionClick: function (index) {
			// default behaviour is to add a text question
			let newQuestion = this.getNewQuestionJson('text', this.sections[index])
			this.computedSections[index].questions.push(newQuestion)
			nextTick(() => {
				// give question name's input focus
				this.focusOnTextInputAtIndex(index, this.sections[index].questions.length - 1)
			})
		},

		// "Add Section" button click handler - add a new section
		addSectionClick: function () {
			this.computedSections.push(this.getNewSectionJson())
			let index = this.sections.length - 1
			// default behaviour is to add a text question to the new section
			let newQuestion = this.getNewQuestionJson('text', this.sections[index])
			this.computedSections[index].questions.push(newQuestion)
			nextTick(() => {
				// give section name's input focus
				this.focusOnTextInputAtIndex(index)
			})
		},

		/*  Close methods */
		// close X icon mouseenter handler - change the color of the selected section/question/option and all children to red
		closeMouseEnter: function (event) {
			// section headers
			let closeColorHeader = '#FF4949'
			this.getThisFromEvent(event)
				.parent()
				.find('.section-controls-top')
				.css('background-color', closeColorHeader)
			this.getThisFromEvent(event)
				.parent()
				.find('.section-header-container')
				.css('background-color', closeColorHeader)

			// section
			let closeSectionColor = '#F1A9A0'
			this.getThisFromEvent(event).parent().css('background-color', closeSectionColor)
			this.getThisFromEvent(event)
				.parent()
				.find('.add-question-text')
				.css('background-color', closeSectionColor)

			// question
			let closeQuestionColor = '#FF8373'
			// add back parent for closing a question or option
			this.getThisFromEvent(event)
				.parent()
				.find('.question')
				.addBack('.question')
				.css('background-color', closeQuestionColor)
			this.getThisFromEvent(event)
				.parent()
				.find('.question-type-select')
				.css('background-color', closeQuestionColor)
			this.getThisFromEvent(event)
				.parent()
				.find('.option')
				.addBack('.option')
				.css('background-color', closeQuestionColor)
		},

		// close X icon mouseleave handler - change the color of the selected section/question/option and all children back to normal
		closeMouseLeave: function (event) {
			// section headers
			this.getThisFromEvent(event)
				.parent()
				.find('.section-controls-top')
				.css('background-color', '')
			this.getThisFromEvent(event)
				.parent()
				.find('.section-header-container')
				.css('background-color', '')

			// section
			this.getThisFromEvent(event).parent().css('background-color', '')
			this.getThisFromEvent(event).parent().find('.add-question-text').css('background-color', '')

			// question
			this.getThisFromEvent(event).parent().find('.question').css('background-color', '')
			this.getThisFromEvent(event)
				.parent()
				.find('.question-type-select')
				.css('background-color', '')
			this.getThisFromEvent(event).parent().find('.option').css('background-color', '')
		},

		// close X icon click handler - remove the selected section/question/option and bring up the undo-popup
		closeClick: function (sectionIndex, questionIndex, optionIndex) {
			// get the removedEl and undoText for the undoPopup, and splice it from this.sections to remove it
			let removedEl = {}
			let undoText = ''
			if (optionIndex >= 0) {
				removedEl = this.computedSections[sectionIndex].questions[questionIndex].refvalues.splice(
					optionIndex,
					1
				)[0]
				undoText = this.t.option //'Option'
			} else if (questionIndex >= 0) {
				removedEl = this.computedSections[sectionIndex].questions.splice(questionIndex, 1)[0]
				undoText = this.t.question //'Question'
			} else if (sectionIndex >= 0) {
				removedEl = this.computedSections.splice(sectionIndex, 1)[0]
				undoText = this.t.section //'Section'
			}

			// set variables that will be used if the remove is undone
			this.lastClosedEl = removedEl
			this.lastClosedSectionIndex = sectionIndex
			this.lastClosedQuestionIndex = questionIndex
			this.lastClosedOptionIndex = optionIndex

			this.undoAppear(this.t.delete(undoText))
		},

		/* Undo methods */
		// Slides the undo-popup up from the bottom of the screen and start the timer for it to disapppear
		undoAppear: function (text) {
			$('#undo-popup-text').html(text)
			$('#undo-popup').css('display', 'block')
			$('#undo-popup').css(
				'left',
				$('#editor-container').offset().left +
					($('#editor-container').width() - $('#undo-popup').width()) / 2 +
					'px'
			)
			$('#undo-popup').animate(
				{
					bottom: '0',
				},
				250,
				function () {
					$('#undo-popup').css('bottom', '0')
				}
			)

			this.undoTimerForDisappear()
		},

		// Sets the timer for the undo-popup to disappear, and clears any ongoing timer.
		// This means that the undo-popup will only disappear after the timer has completely run out,
		// without any item being removed during that time
		undoTimerForDisappear: function () {
			let vueComponent = this
			let setTimer = function () {
				// if there is an ongoing timer, clear it
				if (vueComponent.popupDisappearTimer) {
					clearInterval(vueComponent.popupDisappearTimer)
				}

				// start a new timer; when it's run out, make the undo-popup disappear
				vueComponent.popupDisappearTimer = setInterval(function () {
					vueComponent.undoDisappear()
				}, 5000)
			}

			setTimer()
		},

		// Slides the undo-popup down below the screen and cleans up the timer
		undoDisappear: function () {
			let height = '-' + $('#undo-popup').outerHeight() + 'px'

			$('#undo-popup').animate(
				{
					bottom: height,
				},
				250,
				function () {
					$('#undo-popup').css('bottom', height)
					$('#undo-popup').css('display', 'none')
				}
			)

			clearInterval(this.popupDisappearTimer)
		},

		// "Undo" button click handler - undo the last item removal
		undoButtonClick: function () {
			// make the undo-popup go away
			this.undoDisappear()

			// splice the lastClosedEl back into this.sections based into where it was removed from
			if (this.lastClosedOptionIndex >= 0) {
				this.computedSections[this.lastClosedSectionIndex].questions[
					this.lastClosedQuestionIndex
				].refvalues.splice(this.lastClosedOptionIndex, 0, this.lastClosedEl)
			} else if (this.lastClosedQuestionIndex >= 0) {
				this.computedSections[this.lastClosedSectionIndex].questions.splice(
					this.lastClosedQuestionIndex,
					0,
					this.lastClosedEl
				)
			} else if (this.lastClosedSectionIndex >= 0) {
				this.computedSections.splice(this.lastClosedSectionIndex, 0, this.lastClosedEl)[0]
			}

			let vueComponent = this
			nextTick(() => {
				// give focus to re-added element, once it's been put back in the DOM
				this.focusOnTextInputAtIndex(
					vueComponent.lastClosedSectionIndex,
					vueComponent.lastClosedQuestionIndex,
					vueComponent.lastClosedOptionIndex
				)
			})
		},

		/* Selector methods */
		// question-type-select click handler - open up the selector
		questionTypeSelectClick: function (sectionIndex, questionIndex, event) {
			this.positionMainSelector(this.getThisFromEvent(event), event)
			// setup variables used to know where in this.sections to work
			this.curSelectorSectionIndex = sectionIndex
			this.curSelectorQuestionIndex = questionIndex
		},

		// Lots of css manipulation to correctly position the selector absolutely on the page
		positionMainSelector: function (thisVar) {
			let selector = $('#type-selector-container')
			let selectorHeight = selector.outerHeight()

			// want the position relative to #editor-container
			let offset = thisVar.offset()
			let offsetParent = $('#editor-container').offset()

			let posY = offset.top - offsetParent.top
			let posX = offset.left - offsetParent.left

			// Want posX to be calculated from the right, it results in better performance on zoom
			posX = $('#editor-container').width() - (posX + thisVar.outerWidth())

			// if the page is scrolled too low, want the search to go up, not down
			if (offset.top + selectorHeight - $(window).scrollTop() > $(window).height()) {
				posY = $('#editor-container').outerHeight(true) - (posY + thisVar.outerHeight())
				selector.css('top', '')
				selector.css('bottom', posY + 'px')
			} else {
				selector.css('top', posY + 'px')
				selector.css('bottom', '')
			}

			selector.css('right', posX + 'px')
			selector.css('display', '')
		},

		typeSelectorMouseEnter: function (event) {
			this.getThisFromEvent(event).find('.sub-options').css('display', '')
			$(this.getThisFromEvent(event).find('.type-selector-option')[0]).addClass('hover')

			// make sure menu doesn't overflow
			let selector = $(this.getThisFromEvent(event).find('.sub-options')[0])
			let selectorHeight = selector.outerHeight()

			let offset = this.getThisFromEvent(event).offset()

			// if the page is scrolled too low, want the search to go up, not down
			if (offset.top + selectorHeight - $(window).scrollTop() > $(window).height()) {
				selector.css('top', '')
				selector.css('bottom', '0')
			} else {
				selector.css('top', '0')
				selector.css('bottom', '')
			}
		},

		typeSelectorMouseLeave: function (event) {
			if (this.getThisFromEvent(event).attr('class').indexOf('clicked') == -1) {
				this.getThisFromEvent(event).find('.sub-options').css('display', 'none')
				$(this.getThisFromEvent(event).find('.type-selector-option')[0]).removeClass('hover')
			}
		},

		// selector click handler - change the selected question's type
		selectorClick: function (option, event) {
			// some default selectors may be inactive; ensure the selected selector is active
			if (
				this.getThisFromEvent(event).attr('class').indexOf('inactive') == -1 &&
				!option.subOptions
			) {
				let question =
					this.sections[this.curSelectorSectionIndex].questions[this.curSelectorQuestionIndex]
				// the only part of the question that can be consistently saved from type to type is the question's label
				let label = question.label

				// create a new questions so that all observers are initialized correctly
				this.computedSections[this.curSelectorSectionIndex].questions.splice(
					this.curSelectorQuestionIndex,
					1,
					this.getNewQuestionJson(option.type, this.sections[this.curSelectorSectionIndex])
				)
				// save the label of the old question
				this.computedSections[this.curSelectorSectionIndex].questions[
					this.curSelectorQuestionIndex
				].label = label

				// give question name's input focus
				this.focusOnTextInputAtIndex(this.curSelectorSectionIndex, this.curSelectorQuestionIndex)
				$('.selector-container').css('display', 'none')
				$('.type-selector-option-container.clicked').removeClass('hover').removeClass('clicked')
			} else if (
				this.getThisFromEvent(event).attr('class').indexOf('inactive') == -1 &&
				option.subOptions
			) {
				// when clicked, the typeSelectorMouseLeave function shouldn't run; add an identifying class to the parent
				// (the parent has the event attached to it)
				this.getThisFromEvent(event).parent().toggleClass('clicked')
			}
		},

		// Determine if a default selector should be inactive based on if the selector's question type already
		// exists in the form (since default questions should be unique)
		selectorInactive: function (type) {
			// try to find the default question in the form
			for (let i = 0; i < this.sections.length; i++) {
				for (let j = 0; j < this.sections[i].questions.length; j++) {
					if (type == this.sections[i].questions[j].type) {
						// if found, then the selector should be inactive
						return true
					}
				}
			}
			// if the default question can't be found, then the selector should not be inactive
			return false
		},

		/* Section controls */
		// arrow icon mouseenter handler - make the description visible and highlight the arrow
		arrowMouseEnter: function (event) {
			if (
				this.getThisFromEvent(event)
					.parent()
					.find('.move-section-icon')
					.attr('class')
					.indexOf('inactive') == -1
			) {
				this.getThisFromEvent(event)
					.parent()
					.find('.arrow-description')
					.css('visibility', 'visible')
				this.getThisFromEvent(event).parent().find('.move-section-icon').addClass('hover')
			}
		},

		// arrow icon mouseleave handler - hide the description visible and un-highlight the arrow
		arrowMouseLeave: function (event) {
			this.getThisFromEvent(event).parent().find('.arrow-description').css('visibility', '')
			this.getThisFromEvent(event).parent().find('.move-section-icon').removeClass('hover')
		},

		// move the section at sectionIndex down one section
		moveSectionDown: function (sectionIndex) {
			if (sectionIndex < this.sections.length - 1) {
				// A simply re-assigning of the array indices is not detected by vue; use splice instead
				let curSection = this.computedSections.splice(sectionIndex, 1)
				this.computedSections.splice(sectionIndex + 1, 0, curSection[0])
			}
		},

		// move the section at sectionIndex up one section
		moveSectionUp: function (sectionIndex) {
			if (sectionIndex > 0) {
				// A simply re-assigning of the array indices is not detected by vue; use splice instead
				let curSection = this.computedSections.splice(sectionIndex, 1)
				this.computedSections.splice(sectionIndex - 1, 0, curSection[0])
			}
		},

		// minimize icon click - minimize the section
		minimizeClick: function (sectionIndex) {
			// all the formatting is controlled by the section's minimized field (with dynamic classes in Vue and associated css),
			// so only need to toggle this field
			this.computedSections[sectionIndex].addedOptions.minimized =
				!this.sections[sectionIndex].addedOptions.minimized
		},

		/* Sortable methods and helpers */
		// Makes the passed optionVar sortable using JqueryUI's sortable function
		makeOptionsSortable: function (optionVar) {
			let vueComponent = this
			optionVar.sortable({
				start: function (event, ui) {
					// set the height of the placeholder
					let height = $(ui.item).outerHeight(true)
					$('.ui-sortable-placeholder').css('height', height)
					// initialize the original indices of the dragged option
					vueComponent.originalIndices = vueComponent.getIndicesFromItem(ui.item)
				},
				placeholder: 'ui-sortable-placeholder',
				axis: 'y',
				handle: '.drag-vert',

				// TODO - containment is acting very strange; should be thisVar (or even lower) but seems
				// to only work when containment element is higher up the tree
				containment: optionVar.parent(),
				tolerance: 'pointer',

				stop: function (event, ui) {
					// reorder this.sections so that it matches the new dragged order
					vueComponent.reorderSectionsForDrag(ui.item)
				},
			})
		},

		// Makes the passed questionVar sortable using JqueryUI's sortable function
		makeQuestionsSortable: function (questionVar) {
			let vueComponent = this
			questionVar.sortable({
				start: function (event, ui) {
					// set the height of the placeholder
					vueComponent.setUiSortableElementSizes($(ui.item))
					// initialize the original indices of the dragged option
					vueComponent.originalIndices = vueComponent.getIndicesFromItem(ui.item)
				},

				axis: 'y',
				connectWith: '.sortable-questions, .add-section-target, .add-question-target',
				handle: '.drag-icon-container',
				revert: true,
				tolerance: 'pointer',

				helper: 'clone',
				// should be #editor-container, but there is a bug in jqueryUI when a containing div has
				// position:relative
				// since #editor-containr needs relative positioning for the type-selector, attach the
				// helper to the next div up
				appendTo: '#form-container',
				containment: '#form-container',

				change: function () {
					$('.ui-sortable-placeholder').css('display', '')
				},

				stop: function (event, ui) {
					// reorder this.sections so that it matches the new dragged order
					vueComponent.reorderSectionsForDrag(ui.item)

					// can't put this in receive of add's sortable, because we still need ui.item in the DOM
					// for this stop function (to reorder)
					// so, cleanup the DOM here
					if ($(ui.item).parent().parent().attr('class').indexOf('add') != -1) {
						$(ui.item).remove()
					}
				},
			})
		},

		// Makes the passed addQuestionVar sortable using JqueryUI's sortable function so that it can receive a question
		// This is necessary to be able to add a question to an empty seciton
		makeAddQuestionSortable: function (addQuestionVar) {
			addQuestionVar.sortable({
				over: function () {
					$('.ui-sortable-placeholder').css('margin-top', '0')
				},

				connectWith: '.sortable-questions',
			})
		},

		// Makes the passed addSectionVar sortable using JqueryUI's sortable function so that you can add a section by dragging
		// a question onto the button
		makeAddSectionSortable: function (addSectionVar) {
			addSectionVar.sortable({
				connectWith: '.sortable-questions',
			})
		},

		// Gets the DOM item's section, question and option index in this.sections
		// Note that the item should be either a question or option (those are the only sortable things)
		getIndicesFromItem: function (item) {
			let indices = {
				sectionIndex: -1,
				questionIndex: -1,
				optionIndex: -1,
			}

			let questionVar = $(item)

			if ($(item).attr('class').indexOf('option') != -1) {
				// if questionVar is actually an option, fix questionVar and get the option's index
				questionVar = questionVar.parent().parent().parent().parent()
				indices.optionIndex = $(item).index()
			}

			// deal with newQuestion / newSection
			let potentialAddVar = questionVar.parent().parent()
			if (potentialAddVar.attr('class').indexOf('add-question') != -1) {
				// item is in add-question
				// if it's in add-question, it's nested one level deeper, so add a .parent()
				indices.sectionIndex = questionVar.parent().parent().parent().parent().index()
				// the question will always at the end
				indices.questionIndex = this.sections[indices.sectionIndex].questions.length
			} else if (potentialAddVar.attr('class').indexOf('add-section') != -1) {
				// item is in add-section
				// for a new section, the question will always be first
				indices.questionIndex = 0
				// and the section will always be last
				indices.sectionIndex = this.sections.length
			} else {
				// item is just in a normal section
				indices.questionIndex = questionVar.index()
				indices.sectionIndex = questionVar.parent().parent().parent().index()
			}

			return indices
		},

		// Fix this.sections' ordering to match the item's new dragged position
		//
		// After a drag, the DOM is changed but this.sections doesn't reflect the change.
		// This is a problem because we consider this.sections to be the source of truth, and any
		// operation that causes the DOM to be re-rendered will revert the changes made by the drag
		// This functions fixes the discrepency by reordering the questions in this.sections according to
		// the dragged question's new position based on the DOM
		reorderSectionsForDrag: function (draggedItem) {
			let vueComponent = this

			// since the DOM will be completely flusehd, save the scrollbar position
			// for this component, the scrollbar is on the #scroll-container
			let scrollPos = $('#scroll-container').scrollTop()

			let newIndices = vueComponent.getIndicesFromItem(draggedItem)
			// deal with add-question/add-section
			if (newIndices.sectionIndex >= this.sections.length) {
				this.computedSections.push(this.getNewSectionJson())
			}
			if (newIndices.optionIndex != -1) {
				// draggedItem is an option
				let section = vueComponent.sections[vueComponent.originalIndices.sectionIndex]
				let question = section.questions[vueComponent.originalIndices.questionIndex]
				// remove the option from its old position and add it to its new position
				let movedOption = question.refvalues.splice(vueComponent.originalIndices.optionIndex, 1)[0]
				vueComponent.sections[newIndices.sectionIndex].questions[
					newIndices.questionIndex
				].refvalues.splice(newIndices.optionIndex, 0, movedOption)
			} else {
				// draggedItem is a question
				let section = vueComponent.sections[vueComponent.originalIndices.sectionIndex]
				// remove the question from its old position and add it to its new position
				let movedQuestion = section.questions.splice(
					vueComponent.originalIndices.questionIndex,
					1
				)[0]
				vueComponent.sections[newIndices.sectionIndex].questions.splice(
					newIndices.questionIndex,
					0,
					movedQuestion
				)
			}

			/* TODO - the above *should* be all that's required (I think) but it doesn't work -
			 * after certain reorders, the questions display in the wrong order.
			 * Before running this function, the questions display correctly, and at this point in
			 * the function, this.sections is correct.  However, I believe in the interaction between
			 * re-rendering this.sections (Vue) and manually changing the order (jqueryUI) something
			 * goes wrong, so even though this.sections is correct now it doesn't display correctly
			 *
			 * Using jqueryUI here is somewhat an anti-pattern, because re-orders would ideally be
			 * handled by changing this.sections, not manipulating the DOM.
			 * However, I've tried other sortable libraries and none of them look as good (cursor,
			 * elements actually dragging, more options) so I've decided to stick with jqueryUI
			 *
			 * Fix to the problem: force Vue to re-render the affected sections by resetting the entire
			 * this.sections variable (not seen by user)
			 * Note: I tried just updating the affected sections, but it caused bugs for templates
			 * when there were other sections, I think defualt (templated) sections are observed differently,
			 * could be a TODO
			 */

			let sections = vueComponent.sections.splice(0, vueComponent.sections.length)
			nextTick(() => {
				// avoid setting this.sections directly since it's a prop (Vue complains)
				for (let i = 0; i < sections.length; i++) {
					this.computedSections.push(sections[i])
				}

				nextTick(() => {
					// reset the scroll position
					$('#scroll-container').scrollTop(scrollPos)
				})
			})
		},

		// set the placeholder height to match the dragged item
		setUiSortableElementSizes: function (item) {
			$('.ui-sortable-placeholder').height(item.height())
		},

		/* Question content methods */
		// "Add Option" button mouseenter handler - remove opacity and add underline
		addOptionMouseEnter: function (event) {
			this.getThisFromEvent(event).parent().css('opacity', '1')
			this.getThisFromEvent(event).css('border-bottom', '1px solid rgba(0,0,0,0.5)')
		},

		// "Add Option" button mouseleave handler - reset opacity and remove underline
		addOptionMouseLeave: function (event) {
			this.getThisFromEvent(event).parent().css('opacity', '')
			this.getThisFromEvent(event).css('border-bottom', '')
		},

		// "Add Option" button click handler - add an option at the given index
		addOptionClick: function (sectionIndex, questionIndex, event) {
			let type = 'checkbox'
			if (this.getThisFromEvent(event).find('.option-icon').attr('class').indexOf('radio') != -1) {
				type = 'radio'
			}

			let newOption = this.getNewOptionJson()

			this.computedSections[sectionIndex].questions[questionIndex].refvalues.push(newOption)

			// After the DOM has been updated, give the new option's input focus
			let vueComponent = this
			nextTick(() => {
				// give the new option focus
				let optionIndex =
					vueComponent.sections[sectionIndex].questions[questionIndex].refvalues.length - 1
				this.focusOnTextInputAtIndex(sectionIndex, questionIndex, optionIndex)
			})
		},

		// Check that range's inputs are numers; if not, highlight them in red
		rangeNumberCheckKeyup: function (event) {
			let num = this.getThisFromEvent(event).val()
			if ((!isNaN(parseFloat(num)) && isFinite(num)) || num == '') {
				this.getThisFromEvent(event).css('color', '')
			} else {
				this.getThisFromEvent(event).css('color', 'red')
			}
		},

		/* Auto Send functions */
		// auto-send-toggle click handler - toggles the value of the option's auto_send_active field
		autoSendToggleClick: function (sectionIndex, questionIndex, optionIndex) {
			let event = new CustomEvent('autoSendToggle', {
				detail: {
					sectionIndex: sectionIndex,
					questionIndex: questionIndex,
					optionIndex: optionIndex,
				},
			})
			this.$emit('autoSendToggle', event)
		},

		/**
		 * @method getQuestionTypeText
		 * @param {String} type
		 *
		 * @returns {Strings}
		 */
		getQuestionTypeText(type) {
			return this.t.questionType(type.toUpperCase())
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/form/form-editor.scss';
</style>
