<template>
	<el-dialog
		v-model="isAvatarDialogVisible"
		:title="$t('SETTINGS.PROFILE.INFORMATION.DIALOG_AVATAR.TITLE')"
		:width="shouldShowComparison ? '450px' : '325px'"
		@closed="handleDialogClose"
		class="transition-all duration-300"
	>
		<div class="flex flex-column gap-5 items-center">
			<!-- Mode comparaison -->
			<div
				v-if="shouldShowComparison"
				class="flex items-center gap-7"
			>
				<!-- Avatar actuel -->
				<div class="flex flex-col items-center relative">
					<template v-if="previousAvatar">
						<img
							:src="previousAvatar"
							alt="current avatar"
							class="avatar-img absolute z-10 blur-effect"
						>
						<img
							:src="previousAvatar"
							alt="current avatar"
							class="avatar-img z-20"
						>
					</template>
					<template v-else>
						<div class="my-avatar-empty">
							{{ userInitials }}
						</div>
					</template>
				</div>

				<i class="gui-next text-3xl text-black" />

				<!-- Nouvelle preview -->
				<div class="flex flex-col items-center relative">
					<img
						:src="previewImage"
						alt="new avatar preview"
						class="avatar-img absolute z-10 blur-effect"
					>
					<img
						:src="previewImage"
						alt="new avatar preview"
						class="avatar-img z-20 new-preview"
					>
				</div>
			</div>

			<!-- Mode simple -->
			<template
				v-else
			>
				<template
					v-if="displayedAvatar"
				>
					<img
						:src="displayedAvatar"
						alt="current avatar"
						class="avatar-img new-preview"
					>
				</template>
				<template v-else>
					<div class="my-avatar-empty">
						{{ userInitials }}
					</div>
				</template>
			</template>

			<!-- Actions -->
			<label for="files">
				<div class="text-pink-main cursor-pointer hover:underline p-2 mt-2">
					{{ $t('SETTINGS.PROFILE.INFORMATION.DIALOG_AVATAR.CHOICE') }}
				</div>
			</label>
			<input
				id="files"
				ref="fileInput"
				accept="image/jpeg,image/jpg,image/png"
				style="display: none"
				type="file"
				@input="methodPickFile"
			>
			<span
				v-if="extensionNotValid"
				class="text-sm pt-2 text-red-500"
			>
				{{ $t('SETTINGS.PROFILE.INFORMATION.DIALOG_AVATAR.FORMAT_MESSAGE') }}
				{{ computedValidImageFormat }}
			</span>

			<div class="flex justify-end items-center w-full gap-4 mt-6 mb-2">
				<span
					v-if="canDeleteAvatar"
					class="cursor-pointer hover:text-pink-main transition-all"
					@click="methodDeleteAvatar"
				>{{ $t('SETTINGS.PROFILE.INFORMATION.DIALOG_AVATAR.DELETE_CTA') }}</span>
				<q-button
					:disabled="!hasChanges"
					class="self-end"
					@click="methodUploadImage"
				>
					{{ $t('SETTINGS.PROFILE.INFORMATION.EDIT_PICTURE') }}
				</q-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>

export default {
	props: {
		originalAvatar: {
			type: String,
			default: 'null',
		},
		userInitials: {
			type: String,
			required: true,
		},
		visible: {
			type: Boolean,
			required: true,
		},
		editedUser: {
			type: Object,
			required: true,
		},
	},
	emits: ['update:visible', 'newAvatar'],
	data() {
		return {
			fileTypes: ['jpg', 'jpeg', 'png'],
			extensionNotValid: false,
			previewImage: null,
			previousAvatar: null,
			isAvatarDialogVisible: false,
		}
	},
	computed: {
		displayedAvatar() {
			return this.previewImage || this.currentAvatar
		},
		currentAvatar() {
			if (this.editedUser && this.editedUser?.avatar !== 'null' && this.editedUser?.avatar !== 'not_set') {
				return this.editedUser.avatar
			}
			return null
		},
		shouldShowComparison() {
			return this.previewImage && this.previousAvatar !== this.previewImage
		},
		hasChanges() {
			return this.previewImage !== null && this.previewImage !== this.currentAvatar
		},
		canDeleteAvatar() {
			return this.currentAvatar && !this.previewImage
		},
		computedValidImageFormat() {
			return this.fileTypes.join(', ')
		},
	},
	watch: {
		isAvatarDialogVisible(newVal) {
			if (newVal) {
				this.previousAvatar = this.currentAvatar
			}
			this.$emit('update:visible', newVal)
		},
		visible(newVal) {
			this.isAvatarDialogVisible = newVal
		},
	},
	methods: {
		handleDialogClose() {
			this.previewImage = null
			this.previousAvatar = null
			this.extensionNotValid = false
		},
		methodPickFile() {
			this.previewImage = null
			const input = this.$refs.fileInput
			const file = input.files[0]

			if (!file) return

			const extension = file.name.split('.').pop().toLowerCase()
			const isImage = this.fileTypes.includes(extension)

			if (isImage) {
				const reader = new FileReader()
				reader.onload = (e) => {
					if (!this.previousAvatar) {
						this.previousAvatar = this.currentAvatar
					}
					this.previewImage = e.target.result
				}
				reader.readAsDataURL(file)
			}
			else {
				this.extensionNotValid = true
			}
		},
		methodUploadImage() {
			this.$emit('newAvatar', this.previewImage)
			this.$emit('update:visible', false)
			this.extensionNotValid = false
		},
		methodDeleteAvatar() {
			this.$emit('newAvatar', 'not_set')
			this.previewImage = null
			this.$emit('update:visible', false)
		},
	},
}
</script>

<style scoped lang="scss">
.avatar-img {
	@apply rounded-full;
	min-width: 9rem;
	max-width: 9rem;
	min-height: 9rem;
	max-height: 9rem;
	object-fit: cover;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.new-preview {
	animation: scaleIn 0.4s cubic-bezier(0.18, 1.64, 0.58, 1);
}

@keyframes scaleIn {
	from {
		transform: scale(0.6);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

.my-avatar-empty {
	@apply rounded-full text-6xl text-center bg-gray-100 flex items-center justify-center;
	width: 9rem;
	height: 9rem;
}

.blur-effect {
	filter: blur(30px);
	transform: scale(1.3);
	opacity: 0.7;
}

</style>
