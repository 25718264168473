import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-1" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center p-8 bg-white gap-x-6" }
const _hoisted_3 = { class: "w-full p-8 bg-white" }
const _hoisted_4 = { class: "w-full rounded-sm" }
const _hoisted_5 = { class: "w-full align-middle" }
const _hoisted_6 = { class: "w-full align-middle" }
const _hoisted_7 = { class: "flex items-center justify-end pr-4" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]

import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import cloneDeep from 'lodash/cloneDeep'
import { formatCustomDate } from '@/utils/dateFnsHelper'
import { ElMessage } from 'element-plus'
import SegmentModalEdition from './SegmentModalEdition.vue'
import SegmentUsersAffectation from './SegmentUsersAffectation.vue'
import SegmentDeleteModal from './SegmentDeleteModal.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentsMain',
  setup(__props) {

const store = useStore()
const { t } = useI18n()

const segmentSelected = ref<SegmentItem | null>(null)
const showModalSegment = ref(false)
const showDeleteModal = ref(false)

const getSegments = computed(() => store.getters['@lists/getSegments'])
const getAllUsers = computed(() => store.getters.getAllUsers)
const getTags = computed(() => store.getters['@tags/getTags'])

onMounted(() => {
	store.dispatch('@lists/actionFetchLists')
	if (!getTags.value.length) {
		store.dispatch('@tags/actionGetTags')
	}
})

/**
 * Watcher
 * @desc Watcher for the showModalSegment value
 * @desc If the value is false, the segment selected is set to null
 * @param {boolean} value
 */
watch(showModalSegment, (value: boolean) => {
	if (value === false) {
		segmentSelected.value = null
	}
})

const methodSetCellClickSegment = (row: any) => {
	const { payload: segment } = row

	if (segment) {
		const clonedSegment = cloneDeep(row)
		const deserializedPayload = QueryNode.deserialize(segment)

		clonedSegment.deserializedPayload = deserializedPayload

		segmentSelected.value = clonedSegment
		showModalSegment.value = true
	}
}

const methodInitNewSegmentCreation = () => {
	const rootQueryNode = new QueryNode('$all')
	const rootQuerySecond = new QueryNode('$all')
	const firstChild = new QueryNode('$condition')
	const secondChild = new QueryNode('$condition')

	rootQueryNode.addChild(rootQuerySecond)
	rootQuerySecond.addChild(firstChild)
	rootQuerySecond.addChild(secondChild)

	segmentSelected.value = {
		id: 0,
		title: '',
		payload: '',
		deserializedPayload: rootQueryNode,
	}

	showModalSegment.value = true
}

const methodFormatDate = (row: SegmentItem) => {
	let date = row?.updated_at ? new Date(row.updated_at) : row?.updated_at

	return date ? formatCustomDate(date) : '-'
}

const methodGetMember = (userId) => {
	const user = getAllUsers.value.find((user) => user.id === userId)

	return user ? `${user.firstname} ${user.surname}` : t('CONTACT.LIST.USER_UNKNOWN', { id: userId })
}

const methodDeleteSegmentModal = (row: SegmentItem) => {
	segmentSelected.value = row
	showDeleteModal.value = true
}

const methodDeleteSegment = async () => {
	await store.dispatch('@lists/actionRemoveList', segmentSelected.value)
	showDeleteModal.value = false
	ElMessage({
		message: t('SETTINGS.SEGMENTS.MODAL.DELETE.SUCCESS'),
		type: 'success',
		showClose: true,
	})
}

return (_ctx: any,_cache: any) => {
  const _component_q_button = _resolveComponent("q-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (segmentSelected.value)
      ? (_openBlock(), _createBlock(SegmentModalEdition, {
          key: 0,
          "display-modal": showModalSegment.value,
          "onUpdate:displayModal": _cache[0] || (_cache[0] = ($event: any) => ((showModalSegment).value = $event)),
          "segment-selected": segmentSelected.value
        }, null, 8, ["display-modal", "segment-selected"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_unref(t)('SETTINGS.SEGMENTS.DESCRIPTION')), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_q_button, { onClick: methodInitNewSegmentCreation }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('SETTINGS.SEGMENTS.CREATE_SEGMENT')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_table, {
          data: getSegments.value,
          stripe: "",
          style: {"width":"100%"},
          class: "w-full mb-8 dynamic-lists-table",
          "header-cell-class-name": "tableHeaderCellStyle",
          "row-class-name": "tableRowStyle cursor-pointer",
          "cell-class-name": "tableRowCellStyle",
          onCellClick: methodSetCellClickSegment
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "title",
              label: _unref(t)('SETTINGS.SEGMENTS.TABLE.NAME'),
              width: "300"
            }, null, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "updated_at",
              label: _ctx.$t('SETTINGS.SEGMENTS.TABLE.LAST_CHANGE'),
              width: "200"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(methodFormatDate(row)), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              "min-width": "200",
              prop: "user_id",
              align: "left",
              label: _ctx.$t('CONTACT.DYNAMIC_LISTS.MODIFIED_BY')
            }, {
              default: _withCtx(({ row }) => [
                _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createTextVNode(_toDisplayString(methodGetMember(row.user_id)), 1)
                ])), [
                  [_directive_tooltip]
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              "min-width": "600",
              prop: "id",
              align: "left",
              label: _unref(t)('SETTINGS.SEGMENTS.TABLE.ASSIGNED_TO')
            }, {
              default: _withCtx(({ row }) => [
                _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createVNode(SegmentUsersAffectation, {
                    "id-list": row.id,
                    "all-users": getAllUsers.value
                  }, null, 8, ["id-list", "all-users"])
                ])), [
                  [_directive_tooltip]
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              "min-width": "90",
              align: "right"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", {
                    class: "flex items-center p-3 pointer text-lg hover-scale",
                    onClick: _withModifiers(
										() => {
											methodSetCellClickSegment(row)
										}
									, ["stop"])
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("i", { class: "gui-edit text-green-main" }, null, -1)
                  ]), 8, _hoisted_8),
                  _cache[4] || (_cache[4] = _createElementVNode("i", {
                    class: "bg-gray-light",
                    style: {"width":"1px","height":"24px"}
                  }, null, -1)),
                  _createElementVNode("span", {
                    class: "flex items-center p-3 pointer text-lg hover-scale",
                    onClick: _withModifiers(
										() => {
											methodDeleteSegmentModal(row)
										}
									, ["stop"])
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "gui-delete text-gray-dark" }, null, -1)
                  ]), 8, _hoisted_9)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(SegmentDeleteModal, {
          "display-modal": showDeleteModal.value,
          "onUpdate:displayModal": _cache[1] || (_cache[1] = ($event: any) => ((showDeleteModal).value = $event)),
          onDelete: methodDeleteSegment,
          "segment-selected-title": segmentSelected.value?.title || ''
        }, null, 8, ["display-modal", "segment-selected-title"])
      ])
    ])
  ]))
}
}

})