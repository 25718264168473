<template>
	<div>
		<TiptapCodeEditor
			class="mb-6"
			v-model:code="form.custom_code_header"
			:title="t('CREATE_FORM.CUSTOM_CODE.HEADER.TITLE')"
			:description="t('CREATE_FORM.CUSTOM_CODE.HEADER.SUBTITLE')"
		/>
		<TiptapCodeEditor
			class="mb-6"
			v-model:code="form.custom_code_footer"
			:title="t('CREATE_FORM.CUSTOM_CODE.FOOTER.TITLE')"
			:description="t('CREATE_FORM.CUSTOM_CODE.FOOTER.SUBTITLE')"
		/>
	</div>
</template>

<script setup>
import TiptapCodeEditor from '@/components/general/TiptapCodeEditor.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const form = defineModel('form', {
	type: Object,
	default: () => {},
})

</script>

<style scoped>

</style>
