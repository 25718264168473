export default {
	directives: {
		tooltip: {
			bind: function(el) {
				el.style.textOverflow = 'ellipsis'
				el.style.display = 'inline-block'
				el.style.whiteSpace = 'nowrap'
				el.style.overflow = 'hidden'

				el.addEventListener('mouseover', function(evt) {
					let targetEl = evt.target
					if (targetEl.offsetWidth < targetEl.scrollWidth) {
						/**
						 * Apply a trim to clean the value
						 * If not, the value will be with weird spaces
						 */
						const textContent = targetEl.textContent.trim()
						targetEl.setAttribute('title', textContent)
					} else {
						targetEl.hasAttribute('title') && targetEl.removeAttribute('title')
					}
				})
			},
		},
	},
}
