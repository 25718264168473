import { $request } from './requester'

const mock = [
	{
		created_at: '30/11/2021',
		total: '700',
		status: 'to_pay',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/10/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/09/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/08/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/11/2021',
		total: '700',
		status: 'to_pay',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/10/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/09/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/08/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/11/2021',
		total: '700',
		status: 'to_pay',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/10/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/09/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/08/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/11/2021',
		total: '700',
		status: 'to_pay',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/10/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/09/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
	{
		created_at: '30/08/2021',
		total: '700',
		status: 'payed',
		invoice: 'https://blog.didaxis.fr/wp-content/uploads/2021/01/giphy-10.gif',
	},
]

export default {
	fecthAllInvoices() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(mock)
			}, 150)
		})
		// return $request('GET', '/invoices-list')
		// 	.then((res) => {
		// 		return res.body.data
		// 	})
		// 	.catch(() => {
		// 		throw new Error('Impossible to get the list of invoices')
		// 	})
	},
}
