/**
 * @store @contact
 */
import { firstBy } from 'thenby'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import contact_store from '../../models/contact_store'
import documents_store from '../../models/documents_store'
import { i18n } from '../../middlewares/i18nizer'
import {
	formatOfFutureObjectForSearching,
	constructQueryFromStoreAdaptator,
} from './utils/searchTools'
import {
	setOnGoingSanctionStore,
} from './utils/sanctionsTools'
import {
	RESET_STATE,
	GET_NOTES_INTO_CONTACT_STORE_MODULE,
	SET_NEW_NOTE_INTO_CONTACT_STORE_MODULE,
	SET_ACTIVE_CONTACT_BACKUP,
	SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING,
	SET_IS_FETCHING_CURRENT_CONTACT,
	CONTACT_MEMBERSHIP_INFOS_STATUS,
	CONTACT_MEMBERSHIP_INFOS,
	SANCTIONS_TYPES_STATUS,
	SANCTIONS_TYPES_LIST,
	SANCTION_AUTHORITIES_LIST_STATUS,
	SANCTION_AUTHORITIES_LIST,
	CONTACT_SANCTIONS_STATUS,
	CONTACT_SANCTIONS,
	SET_LOADING_UPDATE_CONTACT,
	SET_CONTACT_WAS_CREATED,
	SET_CONTACT_WAS_UPDATED,
	SET_CONTACT_CREATION_STATUS,
	GET_HISTORY_INTO_CONTACT_STORE_MODULE,
	SET_LOADING_HISTORY_CONTACT,
	SET_INTERACTIONS_CONTACT,
	RESET_INTERACTIONS_CONTACT,
	SET_LOADING_INTERACTION_CONTACT,
	UPDATE_INTERACTION_CONTACT,
	DELETE_INTERACTION_CONTACT,
	SET_LOCALY_NOTE_IN_INTERACTION,
	UPDATE_LOCALY_NOTE_IN_INTERACTION,
	DELETE_NOTES_LOCALLY_INTO_CONTACT_STORE_MODULE,
	SET_CONTACT_EMAILS_LIST_LOADING,
	SET_CONTACT_EMAILS_LIST,
	ADD_LOCALLY_NEW_EMAIL_TO_LIST,
	MARK_EMAIL_AS_READ,
	SET_REQUEST_LIMIT,
	SET_NEW_COUNTER_LIMIT,
	SET_INTERACTION_ATTACHMENTS,
	DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS,
	DELETE_INTERACTION_ATTACHMENTS,
	SET_UPLOADING_INTERACTION,
	SET_MASSIVE_UPDATE_TOAST,
	REMOVE_EMPTY_CONTACT_BASE_VIEW_WHEN_TAG_REMOVAL,
	SET_CONTACT_AGE_CATEGORIES,
	ADD_POLLING_STATION,
	SET_CONTACT_FILTERS_FOR_SMS_EMAILS,
	SET_CONTACT_TRANSACTION_INFOS,
} from './_mutation-types'

import { useInteractionsStore } from '../../stores/interactions'

/**
 * This function is used to clean the S3 Storage where the photos of formdatas
 * were stored. There is no UX visual returns of the delete of a photo.
 * So it's handled here to simplify the code. It will just delete a photo
 */
export const specificActionDeletePhotoUploaded = (photoURL) => {
	contact_store.deletePhotoUploaded(photoURL)
}

const getDefaultState = () => {
	return {
		blurred: false /** @member {Boolean} blurred */,
		loadingExport: false /** @member {Boolean} loadingExport */,
		isCreatingContact: false /** @member {Boolean} isCreatingContact */,
		showDetailedAnswers: false /** @member {Boolean} showDetailedAnswers */,
		showContactsFilters: false /** @member {Boolean} showContactsFilters */,
		isRemovingContactMainView: false,
		activeContactBackup: {},
		activeContact: {} /** @member {Contact}^ activeContact */,
		activeContactNotes: [] /** @member {Contact} activeContactNotes */,
		activeContactHistory: [] /** @member {Contact} activeContactHistory */,
		activeContactInteractions: [] /** @member {Contact} activeContactInteractions */,
		tags: [] /** @member {Tag[]} tags */,
		allPollingStations: [] /** @member {Array} allPollingStations */,
		ageCategories: [] /** @member {Array} allPollingStations */,
		genderValues: [
			{
				id: 1,
				value: 'F',
				label: 'female',
			},
			{
				id: 2,
				value: 'M',
				label: 'male',
			},
			{
				id: 3,
				value: 'O',
				label: 'other',
			},
		],
		clientDetailsModal: false,
		numberSelectedOnPage: 0,
		isFetchingCurrentContact: false,
		contactMembershipInfosStatus: 'init',
		contactMembershipInfos: {},
		sanctionsTypesList: [],
		sanctionsTypesListStatus: 'init',
		sanctionAuthoritiesList: [],
		sanctionAuthoritiesListStatus: 'init',
		contactSanctionsStatus: 'init',
		contactSanctions: {},
		isLoadingUpdateContact: false,
		isContactWasCreated: false,
		contactCreationStatus: 'init',
		isLoadingHistoryContact: 'init',
		isLoadingInteractionContact: 'init',
		isContactWasUpdated: false,
		contactEmailsListLoading: false,
		contactEmailsList: [],
		isRefreshRequestLimited: false,
		counterLimit: 0,
		actualAttachmentsForNewInteraction: [],
		isAttachmentsInteractionUploading: 'default',
		massiveUpdateToastDisplayed: false,
		removeEmptyContactBaseViewWhenTagRemoval: false,
		contactFiltersForSmsEmails: {},
		contactTransactionInfos: {},
	}
}

const state = getDefaultState()

const mutations = {
	/**
	 * @mutation SET_BLURRED
	 * @param {Boolean} payload new state
	 */
	SET_BLURRED: (state, payload) => {
		state.blurred = payload
	},

	/**
	 * @mutation FEED_TAGS_COLLECTION
	 * @desc add tag to the collection, but not if it already exist
	 * @param {Array} payload array of tags
	 */
	FEED_TAGS_COLLECTION: (state, payload) => {
		payload.forEach((pTag) => {
			const hasDifferentId = (tag) => tag.name !== pTag.name
			if (state.tags.every(hasDifferentId)) {
				state.tags.push({
					...pTag,
					appearance_count: pTag.appearance_count !== undefined ? pTag.appearance_count : 1,
				})
			}
			else {
				state.tags.forEach((tag) => {
					if (tag.name === pTag.name) {
						tag.color = pTag.color
					}
				})
			}
		})
		state.tags.sort(firstBy('appearance_count', -1))
	},

	/**
	 * @mutation RESET_TAGS_COLLECTION
	 * @desc empty tags array
	 */
	RESET_TAGS_COLLECTION: (state) => {
		state.tags = []
	},

	/**
	 * @mutation SET_LOADING_EXPORT
	 * @desc change the loadingExport status
	 * @param {Boolean} [res=false]
	 */
	SET_LOADING_EXPORT: (state, res = false) => {
		state.loadingExport = res
	},

	/**
	 * @mutation SET_ACTIVE_CONTACT
	 * @param {Contact} activeContact - the contact to put
	 * @deprecated should not user `getDate`, `getDate`. Pay attention to date handling:
	 * weird behavior when updating forms...
	 */
	SET_ACTIVE_CONTACT: (state, activeContact) => {
		const futureContact = activeContact

		state.activeContact = {
			...futureContact,
		}
	},

	/**
	 * @mutation DELETE_ACTIVE_CONTACT
	 * @desc empty activeContact and form_data
	 */
	DELETE_ACTIVE_CONTACT: (state) => {
		state.activeContact = {}
		state.form_data = {}
	},
	CLOSE_CLIENT_DETAILS_MODAL: (state) => {
		state.clientDetailsModal = false
	},
	/**
	 * @mutation SET_CREATING_CONTACT
	 * @param {Boolean} payload
	 */
	SET_CREATING_CONTACT: (state, payload) => {
		state.isCreatingContact = payload
		state.clientDetailsModal = true
	},

	/**
	 * @mutation SHOW_DETAILED_ANSWER
	 * @deprecated not used
	 */
	SHOW_DETAILED_ANSWER: (state) => {
		state.showDetailedAnswers = true
	},

	/**
	 * @mutation HIDE_DETAILED_ANSWER
	 */
	HIDE_DETAILED_ANSWER: (state) => {
		state.showDetailedAnswers = false
	},

	/**
	 * @mutation IS_SHOWING_FILTERS
	 */
	IS_SHOWING_FILTERS: (state, payload) => {
		state.showContactsFilters = payload
	},

	/**
	 * @mutation SET_ALL_POLLING_STATIONS
	 * @desc `/kpi` give polling stations only matching the filters.
	 * On first call, save all the polling stations once and for all here
	 *
	 * @param {Array} payload - array of polling stations names
	 */
	SET_ALL_POLLING_STATIONS: (state, payload) => {
		state.allPollingStations = payload
	},

	/**
	 * @mutation RESET_ALL_POLLING_STATIONS
	 */
	RESET_ALL_POLLING_STATIONS: (state) => {
		state.allPollingStations = []
	},

	ADD_POLLING_STATION: (state, payload) => {
		state.allPollingStations.push(payload)
	},

	SET_NUMBER_SELECTED_ON_PAGE: (state, payload) => {
		switch (payload) {
			case 1:
				state.numberSelectedOnPage += 1
				break
			case -1:
				state.numberSelectedOnPage -= 1
				break
			case 0:
				state.numberSelectedOnPage = 0
				break
			default:
				state.numberSelectedOnPage = parseInt(payload)
		}
	},

	[SET_ACTIVE_CONTACT_BACKUP]: (state, payload) => {
		if (!payload || payload == undefined) {
			state.activeContactBackup = {}
			state.activeContactBackup.formdatas = []
			state.activeContactBackup.custom_fields = []
			return
		}
		if (!payload?.formdatas) payload.formdatas = []
		if (!payload?.custom_fields) payload.custom_fields = []
		state.activeContactBackup = payload
	},

	[GET_NOTES_INTO_CONTACT_STORE_MODULE]: (state, payload) => {
		const notesCloned = payload
		notesCloned.sort((v1, v2) => {
			return v2.id - v1.id
		})
		state.activeContactNotes = notesCloned
	},

	[SET_NEW_NOTE_INTO_CONTACT_STORE_MODULE]: (state, payload) => {
		const { activeContactNotes } = state
		activeContactNotes.unshift(payload)
	},

	[SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING]: (state, payload) => {
		state.isRemovingContactMainView = payload
	},

	[SET_LOADING_UPDATE_CONTACT]: (state, payload) => {
		state.isLoadingUpdateContact = payload
	},

	[SET_CONTACT_WAS_CREATED]: (state, payload) => {
		state.isContactWasCreated = payload
	},

	[SET_CONTACT_WAS_UPDATED]: (state, payload) => {
		state.isContactWasUpdated = payload
	},

	[SET_CONTACT_CREATION_STATUS]: (state, payload) => {
		state.contactCreationStatus = payload
	},

	[SET_IS_FETCHING_CURRENT_CONTACT]: (state, payload) => {
		state.isFetchingCurrentContact = payload
	},
	[CONTACT_MEMBERSHIP_INFOS_STATUS]: (state, payload) => {
		state.contactMembershipInfosStatus = payload
	},
	[CONTACT_MEMBERSHIP_INFOS]: (state, payload) => {
		state.contactMembershipInfos = payload
	},
	[SANCTIONS_TYPES_LIST]: (state, payload) => {
		state.sanctionsTypesList = payload
	},
	[SANCTIONS_TYPES_STATUS]: (state, payload) => {
		state.sanctionsTypesListStatus = payload
	},
	[SANCTION_AUTHORITIES_LIST]: (state, payload) => {
		state.sanctionAuthoritiesList = payload
	},
	[SANCTION_AUTHORITIES_LIST_STATUS]: (state, payload) => {
		state.sanctionAuthoritiesListStatus = payload
	},
	[CONTACT_SANCTIONS_STATUS]: (state, payload) => {
		state.contactSanctionsStatus = payload
	},
	[CONTACT_SANCTIONS]: (state, payload) => {
		state.contactSanctions = payload
	},

	[GET_HISTORY_INTO_CONTACT_STORE_MODULE]: (state, payload) => {
		state.activeContactHistory = payload
	},

	[SET_LOADING_HISTORY_CONTACT]: (state, payload) => {
		state.isLoadingHistoryContact = payload
	},

	[SET_INTERACTIONS_CONTACT]: (state, payload) => {
		state.activeContactInteractions = payload
	},

	[RESET_INTERACTIONS_CONTACT]: (state, payload) => {
		state.activeContactInteractions = payload
	},

	[SET_INTERACTION_ATTACHMENTS]: (state, payload) => {
		if (Object.prototype.hasOwnProperty.call(payload, 'replaceArray')) {
			state.actualAttachmentsForNewInteraction = payload.files
			return
		}
		state.actualAttachmentsForNewInteraction.push(payload)
	},

	[DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS]: (state, payload) => {
		payload.attachmentId.forEach((attachmentId) => {
			const index = state.actualAttachmentsForNewInteraction.findIndex(
				(attachment) => attachment.id === attachmentId,
			)
			state.actualAttachmentsForNewInteraction.splice(index, 1)
			if (payload.interactionId) {
				const interactionIndex = state.activeContactInteractions.findIndex(
					(interaction) => interaction.id === payload.interactionId,
				)
				const interactionAttachmentIndex = state.activeContactInteractions[
					interactionIndex
				].files?.findIndex((attachment) => attachment.id === attachmentId)
				state.activeContactInteractions[interactionIndex].files?.splice(
					interactionAttachmentIndex,
					1,
				)
			}
		})
	},

	[DELETE_INTERACTION_ATTACHMENTS]: (state) => {
		state.actualAttachmentsForNewInteraction = []
	},

	[SET_UPLOADING_INTERACTION]: (state, payload) => {
		state.isAttachmentsInteractionUploading = payload
	},

	[SET_LOADING_INTERACTION_CONTACT]: (state, payload) => {
		state.isLoadingInteractionContact = payload
	},

	[SET_LOCALY_NOTE_IN_INTERACTION]: (state, payload) => {
		state.activeContactInteractions.push(payload)
	},

	[UPDATE_LOCALY_NOTE_IN_INTERACTION]: (state, payload) => {
		const index = state.activeContactInteractions.findIndex(
			(interaction) => interaction.id == payload.id,
		)
		state.activeContactInteractions.splice(index, 1, payload)
	},

	[DELETE_NOTES_LOCALLY_INTO_CONTACT_STORE_MODULE]: (state, noteId) => {
		const index = state.activeContactNotes.findIndex((note) => note.id == noteId)
		state.activeContactNotes.splice(index, 1)
	},

	[UPDATE_INTERACTION_CONTACT]: (state, payload) => {
		const index = state.activeContactInteractions.findIndex(
			(interaction) => interaction.id == payload.id,
		)
		if (index === -1) {
			state.activeContactInteractions.push(payload)
			return
		}
		state.activeContactInteractions.splice(index, 1, payload)
	},

	[DELETE_INTERACTION_CONTACT]: (state, payload) => {
		const index = state.activeContactInteractions.findIndex(
			(interaction) => interaction.id == payload,
		)
		state.activeContactInteractions.splice(index, 1)
	},

	[SET_CONTACT_EMAILS_LIST_LOADING]: (state, payload) => {
		state.contactEmailsListLoading = payload
	},

	[SET_CONTACT_EMAILS_LIST]: (state, payload) => {
		state.contactEmailsList = payload
	},

	[ADD_LOCALLY_NEW_EMAIL_TO_LIST]: (state, payload) => {
		const temporaryEmail = {
			created: new Date(Date.now()),
			sender: payload.sender,
			subject: payload.subject,
			object: payload.object,
			receiver: payload.receiver,
			type: 'received',
			imap_message_id: 'AAAABAAAgY4',
			email_engine_id: payload.emailEngineId,
			unseen: false,
		}
		state.contactEmailsList.push(temporaryEmail)
	},

	[MARK_EMAIL_AS_READ]: (state, payload) => {
		const index = state.contactEmailsList.findIndex((email) => email.imap_message_id === payload)
		delete state.contactEmailsList[index].unseen
	},

	[SET_REQUEST_LIMIT]: (state, payload) => {
		state.isRefreshRequestLimited = payload
	},

	[SET_NEW_COUNTER_LIMIT]: (state, payload) => {
		if (payload === 1) {
			state.counterLimit -= payload
		}
		else {
			state.counterLimit = payload
		}
	},

	[SET_MASSIVE_UPDATE_TOAST]: (state, payload) => {
		state.massiveUpdateToastDisplayed = payload
	},

	[REMOVE_EMPTY_CONTACT_BASE_VIEW_WHEN_TAG_REMOVAL]: (state, payload) => {
		state.removeEmptyContactBaseViewWhenTagRemoval = payload
	},

	[SET_CONTACT_AGE_CATEGORIES]: (state, payload) => {
		state.ageCategories = payload
	},

	[SET_CONTACT_FILTERS_FOR_SMS_EMAILS]: (state, payload) => {
		state.contactFiltersForSmsEmails = payload
	},

	[SET_CONTACT_TRANSACTION_INFOS]: (state, payload) => {
		state.contactTransactionInfos = payload
	},

	[RESET_STATE]: (state) => {
		Object.assign(state, getDefaultState())
	},
}

const actions = {
	/**
	 * @action getTags
	 * @desc get all the tags from contact_store
	 * @see {@link model:Contact.getTags getTags}
	 */
	getTags({ commit }) {
		contact_store.getTags().then((res) => {
			commit('FEED_TAGS_COLLECTION', res || [])
		})
	},

	getContactById(_store, id) {
		return contact_store.getContactById(id)
	},

	/**
	 * @action addContact
	 * @desc only use to dispatch some commits.
	 * SET_CREATING_CONTACT
	 * HIDE_DETAILED_ANSWER
	 * EDIT
	 * DELETE_ACTIVE_CONTACT
	 */
	addContact({ commit }) {
		commit('SET_CREATING_CONTACT', true)
		commit('HIDE_DETAILED_ANSWER')
		commit('EDIT', false, { root: true })
		commit('DELETE_ACTIVE_CONTACT')
		commit('SET_ACTIVE_CONTACT', {
			address: {},
		})
	},

	/**
	 * @action actionUpdateAContact
	 * @desc actionUpdateAContact a contact
	 * @param {Contact} contact
	 * @see {@link model:Contact.updateContact updateContact}
	 * @return {Contact}
	 */
	actionUpdateAContact(_store, contact) {
		return contact_store.updateContact(contact)
	},

	actionContactsMassiveUpdate({ rootGetters, commit }, payload) {
		const query = rootGetters['@search/querySearchConstructed']

		if (!payload?.contact_ids) {
			payload.search = query
		}

		if (payload?.search) {
			const total_hit = payload?.total_hit ? payload.total_hit : rootGetters.total_hit
			payload.search.fields[2] = total_hit.toString()
			if (Object.prototype.hasOwnProperty.call(payload.search, 'advanced_search')) {
				payload.search.advanced_search.per_page = total_hit
			}
		}
		delete payload.total_hit
		contact_store.contactsMassiveUpdate(payload).then(() => {
			commit(SET_MASSIVE_UPDATE_TOAST, true)
			setTimeout(() => {
				commit(SET_MASSIVE_UPDATE_TOAST, false)
			}, 0)
		})
	},

	/**
	 * @action saveNewContact
	 * @param {Contact} contact the new contact to save
	 * @see {@link model:Contact.saveContact saveContact}
	 *
	 * @return {Contact}
	 */
	async saveNewContact({ commit, dispatch, state }, contact) {
		commit(SET_LOADING_UPDATE_CONTACT, true)
		const result = await contact_store
			.saveContact(contact)
			.then(async (contactUpdated) => {
				commit('SET_ACTIVE_CONTACT', contactUpdated)
				await state.activeContactNotes.forEach(async (note) => {
					return await contact_store.fetchAsyncPostNotesForContact(
						contactUpdated.id,
						note.noteContent,
					)
				})
				dispatch('actionFetchAsyncNotesFromContact', contactUpdated.id)
				dispatch('actionFetchAsyncHistoryFromContact', contactUpdated.id)
				commit(SET_ACTIVE_CONTACT_BACKUP, contactUpdated)
				commit(SET_LOADING_UPDATE_CONTACT, false)
				commit(SET_CONTACT_WAS_CREATED, true)
				return contactUpdated
			})
			.catch((error) => {
				console.error(error)
				commit(SET_LOADING_UPDATE_CONTACT, false)
			})
		return result
	},

	async actionCreateNewContactOnly({ commit }, contact) {
		commit(SET_CONTACT_CREATION_STATUS, 'creation')
		let response = null
		await contact_store
			.saveContact(contact)
			.then((res) => {
				commit(SET_CONTACT_CREATION_STATUS, 'created')
				response = res
			})
			.catch(() => {
				commit(SET_CONTACT_CREATION_STATUS, 'error')
			})
		commit(SET_CONTACT_CREATION_STATUS, 'init')
		return response
	},

	/**
	 * @action deleteOneContact
	 * @param {Number} id
	 * @see {@link model:Contact.deleteContact deleteContact}
	 */
	deleteOneContact(_store, id) {
		return contact_store.deleteContact(id)
	},

	/**
	 * @action downloadExtract
	 * @param {Number[]} [ids=[]] list of given id to filter extraction on
	 * @see {@link model:Contact.extract extract}
	 */
	downloadExtract({ rootState, rootGetters }, payload) {
		const query = constructQueryFromStoreAdaptator(
			rootGetters,
			rootState['@filter'].query,
			rootState['@filter'].filter,
			rootState['@filter'].polygon,
			rootState['@filter'].tags_filter,
			rootState['@filter'].duplicates_filter,
			formatOfFutureObjectForSearching(payload),
			null,
			null,
			payload?.ids ?? [],
		)

		if (query?.advanced_search?.per_page > 0) {
			query.advanced_search.per_page = rootState.numberSelectedOnPage
		}

		if (payload?.ids?.length) {
			delete query.advanced_search
		}

		return contact_store.extract(query)
	},

	resetState({ commit }) {
		commit(RESET_STATE)
	},

	actionUpdateNumberSelectedOnPage({ commit }, payload) {
		commit('SET_NUMBER_SELECTED_ON_PAGE', payload)
	},

	/**
	 * @action setNewActiveContact
	 */
	actionSetNewActiveContact({ commit }, payload) {
		commit('SET_ACTIVE_CONTACT', payload)
	},

	actionFetchAsyncNotesFromContact({ commit }, id) {
		return contact_store.fetchAsyncNotesFromContact(id).then((contact) => {
			commit(GET_NOTES_INTO_CONTACT_STORE_MODULE, contact.notes)
			return contact.notes
		})
	},

	actionUpdateAsyncNoteFromContact({ commit, dispatch, state }, editedNote) {
		const payload = {
			contactId: state.activeContact.id,
			noteId: editedNote.id,
			noteData: {
				note: {
					data: editedNote.comments,
					only_super_admin: editedNote.only_super_admin || false,
				},
			},
		}
		if (Object.prototype.hasOwnProperty.call(editedNote, 'pinned'))
			payload.noteData.note.pinned = editedNote.pinned

		contact_store.updateAsyncNoteFromContact(payload).then(() => {
			contact_store.fetchAsyncNotesFromContact(state.activeContact.id).then((res) => {
				commit(GET_NOTES_INTO_CONTACT_STORE_MODULE, res.notes)
				const payload = {
					notes: res.notes,
					mission: 'edit',
				}
				dispatch('actiontransformNotesInInteractions', payload)
			})
		})
	},

	actiontransformNotesInInteractions({ commit, rootGetters }, payload) {
		const { notes, mission } = payload
		if (isArray(notes)) {
			notes.forEach((note) => {
				const noteToTransform = {
					contact_id: note.contact_id,
					created_at: note.created_at,
					date_of_event: note.created_at,
					comments: note.data,
					id: note.id,
					pinned: note?.pinned || false,
					group_id: note.group_id,
					only_super_admin: note.only_super_admin,
					user: rootGetters.userConnected,
					user_id: rootGetters.userConnected.id,
					type: 'comment',
					sub_type: 'incoming',
					is_note: true,
				}
				if (mission === 'edit') {
					commit(UPDATE_LOCALY_NOTE_IN_INTERACTION, noteToTransform)
				}
				if (mission === 'creation') {
					commit(SET_LOCALY_NOTE_IN_INTERACTION, noteToTransform)
				}
			})
		}
		else {
			const noteToTransform = {
				contact_id: notes.contact_id,
				created_at: notes.created_at,
				date_of_event: notes.created_at,
				comments: notes.data,
				id: notes.id,
				pinned: notes?.pinned || false,
				only_super_admin: notes.only_super_admin,
				group_id: notes.group_id,
				user: rootGetters.userConnected,
				user_id: rootGetters.userConnected.id,
				type: 'comment',
				sub_type: 'incoming',
				is_note: true,
			}
			if (mission === 'edit') {
				commit(UPDATE_LOCALY_NOTE_IN_INTERACTION, noteToTransform)
			}
			if (mission === 'creation') {
				commit(SET_LOCALY_NOTE_IN_INTERACTION, noteToTransform)
			}
		}
	},

	actionFetchAsyncHistoryFromContact({ commit }, id) {
		commit(SET_LOADING_HISTORY_CONTACT, 'loading')
		contact_store.fetchAsyncHistoryFromContact(id).then((history) => {
			commit(GET_HISTORY_INTO_CONTACT_STORE_MODULE, history)
			commit(SET_LOADING_HISTORY_CONTACT, 'loaded')
		})
	},

	actionSetLoadingHistoryContact({ commit }) {
		commit(SET_LOADING_HISTORY_CONTACT, 'loaded')
	},

	actionFetchAsyncContact({ dispatch, commit }, idContact) {
		commit(SET_IS_FETCHING_CURRENT_CONTACT, true)
		contact_store.getContactById(idContact).then((contactFound) => {
			dispatch('actionSetNewActiveContact', contactFound.contact)
			dispatch('actionFetchAsyncHistoryFromContact', idContact)
			dispatch('actionFetchAsyncInteractionsFromContact', {
				idContact,
				mission: 'getNotesToo',
			})
			commit(SET_ACTIVE_CONTACT_BACKUP, contactFound.contact)
			commit(SET_IS_FETCHING_CURRENT_CONTACT, false)
		})
	},

	async actionFetchAsyncContactMembershipInfos({ dispatch, commit }, idContact) {
		commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'loading')
		try {
			const contactFound = await contact_store.getContactMembershipInfos(idContact)
			commit(CONTACT_MEMBERSHIP_INFOS, contactFound)
			commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'success')
		}
		catch (error) {
			commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'error')
		}
	},
	// async actionFetchAsyncContactTransactionsSanctions({ dispatch, commit }, idContact) {
	// 	commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'loading')
	// 	try {
	// 		const contactFound = await contact_store.getContactMembershipInfos(idContact)
	// 		commit(TRANSACTIONS_SANCTIONS, contactFound)
	// 		// commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'success')
	// 	} catch (error) {
	// 		// commit(CONTACT_MEMBERSHIP_INFOS_STATUS, 'error')
	// 	}
	// },
	async actionFetchAsyncSanctionsTypes({ dispatch, commit }) {
		commit(SANCTIONS_TYPES_STATUS, 'loading')
		try {
			const sanctionsTypesList = await contact_store.fetchContactsSanctionsList()
			commit(SANCTIONS_TYPES_LIST, sanctionsTypesList)
			commit(SANCTIONS_TYPES_STATUS, 'success')
		}
		catch (error) {
			commit(SANCTIONS_TYPES_STATUS, 'error')
		}
	},
	async actionFetchAsyncSanctionAuthorities({ dispatch, commit }) {
		commit(SANCTION_AUTHORITIES_LIST_STATUS, 'loading')
		try {
			const sanctionAuthoritiesList = await contact_store.fetchSanctionAuthoritiesList()
			commit(SANCTION_AUTHORITIES_LIST, sanctionAuthoritiesList)
			commit(SANCTION_AUTHORITIES_LIST_STATUS, 'success')
		}
		catch (error) {
			commit(SANCTION_AUTHORITIES_LIST_STATUS, 'error')
		}
	},
	async actionFetchAContactSanctions({ dispatch, commit }, idContact) {
		commit(CONTACT_SANCTIONS_STATUS, 'loading')
		try {
			const contactSanctionsList = await contact_store.fetchAContactSanctions(idContact)

			if (!contactSanctionsList || contactSanctionsList === null) {
				commit(CONTACT_SANCTIONS, [])
			}
			else {
				commit(CONTACT_SANCTIONS, contactSanctionsList)
			}
			commit(CONTACT_SANCTIONS_STATUS, 'success')
		}
		catch (error) {
			commit(CONTACT_SANCTIONS_STATUS, 'error')
		}
	},
	async actionPostContactSanction({ dispatch, commit }, payload) {
		commit(CONTACT_SANCTIONS_STATUS, 'loading')
		try {
			const newSanction = await contact_store.postSanction(payload)

			const contactSanctionsList = cloneDeep(state.contactSanctions) || {}

			if (!contactSanctionsList.sanctions) {
				contactSanctionsList.sanctions = []
			}

			contactSanctionsList.sanctions.push(newSanction)
			contactSanctionsList.ongoing_sanction = setOnGoingSanctionStore(contactSanctionsList.sanctions)

			commit(CONTACT_SANCTIONS, contactSanctionsList)
			commit(CONTACT_SANCTIONS_STATUS, 'success')
			return 'success'
		}
		catch (error) {
			commit(CONTACT_SANCTIONS_STATUS, 'error')
			return 'error'
		}
	},
	async actionDeleteContactSanction({ dispatch, commit }, idSanction) {
		commit(CONTACT_SANCTIONS_STATUS, 'loading')
		try {
			let contactSanctionsList = cloneDeep(state.contactSanctions)

			contactSanctionsList.sanctions = contactSanctionsList.sanctions.filter(
				(sanction) => sanction.id !== idSanction,
			)

			if (contactSanctionsList?.ongoing_sanction?.id === idSanction) {
				contactSanctionsList.ongoing_sanction = setOnGoingSanctionStore(contactSanctionsList.sanctions)
			}

			const aContactSanctionDeleted = await contact_store.deleteSanction(idSanction)
			commit(CONTACT_SANCTIONS, contactSanctionsList)
			commit(CONTACT_SANCTIONS_STATUS, 'success')

			return 'success'
		}
		catch (error) {
			commit(CONTACT_SANCTIONS_STATUS, 'error')
			return 'error'
		}
	},
	async actionPatchContactSanction({ dispatch, commit }, payload) {
		const { data, sanctionId } = payload

		commit(CONTACT_SANCTIONS_STATUS, 'loading')

		try {
			const UpdatedSanction = await contact_store.patchSanction(sanctionId, data)
			const contactSanctionsList = cloneDeep(state.contactSanctions) || {}

			// udates sanctions.sanctions list
			let findedSanctionIndex = contactSanctionsList.sanctions.findIndex(
				(sanction) => sanction.id === sanctionId,
			)
			contactSanctionsList.sanctions[findedSanctionIndex] = UpdatedSanction

			// update ongoingSanction

			contactSanctionsList.ongoing_sanction = setOnGoingSanctionStore(contactSanctionsList.sanctions)

			commit(CONTACT_SANCTIONS, contactSanctionsList)
			commit(CONTACT_SANCTIONS_STATUS, 'success')
			return 'success'
		}
		catch (error) {
			commit(CONTACT_SANCTIONS_STATUS, 'error')
			return 'error'
		}
	},

	actionFetchAsyncDefaultContact({ dispatch, commit }) {
		dispatch('actionSetNewActiveContact', {})
		commit(SET_ACTIVE_CONTACT_BACKUP, {})
	},

	/**
	 * @func actionFetchPostNotesForContact
	 * @desc Add a note (POST) on a contact
	 */
	actionFetchPostNotesForContact({ commit, dispatch, rootState }, payload) {
		const { idContact, noteContent, isPinned, only_super_admin } = payload
		contact_store
			.fetchAsyncPostNotesForContact(idContact, noteContent, isPinned, only_super_admin)
			.then((noteAdded) => {
				const futurNoteToConcat = cloneDeep(noteAdded.note)
				futurNoteToConcat.user = rootState.userConnected
				const payload = {
					notes: noteAdded.note,
					mission: 'creation',
				}
				dispatch('actiontransformNotesInInteractions', payload)
				commit(SET_NEW_NOTE_INTO_CONTACT_STORE_MODULE, futurNoteToConcat)
				dispatch('actionFetchAsyncHistoryFromContact', idContact)
			})
	},

	/**
	 * @func actionResetNotesFromContact
	 * @desc Reset the notes of the contact
	 */
	actionResetNotesFromContact({ commit }) {
		commit(GET_NOTES_INTO_CONTACT_STORE_MODULE, [])
	},
	/**
	 * @func actionResetNotesFromContact
	 * @desc Reset the notes of the contact
	 */
	actionResetHistoryFromContact({ commit }) {
		commit(GET_HISTORY_INTO_CONTACT_STORE_MODULE, [])
		commit(SET_LOADING_HISTORY_CONTACT, 'init')
	},

	/**
	 * @func actionDeleteAContact
	 * @desc Remove a contact from the main view of a contact
	 */
	actionDeleteAContact({ commit }, payload) {
		const idContact = payload
		commit(SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING, true)
		contact_store.deleteContact(idContact).then(() => {
			commit(SET_REMOVING_ACTIVE_CONTACT_MAIN_VIEW_LOADING, false)
		})
	},

	/**
	 * @func actionSaveAContact
	 * @desc Update a contact given
	 */
	actionSaveAContact({ commit, dispatch }, payload) {
		const contact = payload
		const contactId = payload.id
		commit(SET_LOADING_UPDATE_CONTACT, true)
		contact_store
			.updateContact(contact)
			.then((contactUpdated) => {
				commit('SET_ACTIVE_CONTACT', contactUpdated)
				dispatch('actionFetchAsyncHistoryFromContact', contactId)
				commit(SET_ACTIVE_CONTACT_BACKUP, contactUpdated)
				commit(SET_LOADING_UPDATE_CONTACT, false)
				commit(SET_CONTACT_WAS_UPDATED, true)
			})
			.catch(() => commit(SET_LOADING_UPDATE_CONTACT, false))
	},

	actionResetContactBooleanWasUpdated({ commit }) {
		commit(SET_CONTACT_WAS_UPDATED, false)
		commit(SET_CONTACT_WAS_CREATED, false)
	},

	actionResetActiveContact({ commit }, payload) {
		commit('SET_ACTIVE_CONTACT', cloneDeep(payload))
		commit(SET_ACTIVE_CONTACT_BACKUP, cloneDeep(payload))
	},

	actionFetchAsyncInteractionsFromContact({ state, commit, dispatch }, payload) {
		commit(SET_LOADING_INTERACTION_CONTACT, 'loading')
		const idContact = get(payload, 'idContact', state.activeContact.id)
		const finalInteractionsArray = []
		contact_store.fetchAsyncInteractionsFromContact(idContact).then((contactInteractions) => {
			if (payload.mission === 'getNotesToo') {
				dispatch('actionFetchAsyncNotesFromContact', idContact).then((notes) => {
					notes.forEach((note) => {
						finalInteractionsArray.push({
							contact_id: note.contact_id,
							created_at: note.created_at,
							date_of_event: note.created_at,
							comments: note.data,
							id: note.id,
							group_id: note.group_id,
							user: note.user,
							user_id: note?.user?.id,
							only_super_admin: note.only_super_admin,
							type: 'comment',
							sub_type: 'incoming',
							is_note: true,
							pinned: note?.pinned || false,
						})
					})

					contactInteractions.forEach((interaction) => {
						finalInteractionsArray.push(interaction)
					})

					commit(SET_INTERACTIONS_CONTACT, finalInteractionsArray)
					commit(SET_LOADING_INTERACTION_CONTACT, 'loaded')
				})
			}
			else {
				contactInteractions.forEach((interaction) => {
					finalInteractionsArray.push(interaction)
				})

				commit(SET_INTERACTIONS_CONTACT, finalInteractionsArray)
				commit(SET_LOADING_INTERACTION_CONTACT, 'loaded')
			}
		})
	},

	actionResetInteractionsFromContact({ commit }) {
		commit(RESET_INTERACTIONS_CONTACT, [])
	},

	actionPostAsyncNewContactInteraction({ dispatch, state }, payload) {
		const { newInteraction, contactId } = payload
		if (newInteraction.type === 'comment') {
			const note = {
				idContact: contactId,
				only_super_admin: newInteraction.only_super_admin || false,
				noteContent: newInteraction.comments,
			}
			dispatch('actionFetchPostNotesForContact', note)
			return
		}
		contact_store
			.postAsyncInteractionForContact(state.activeContact.id, newInteraction)
			.then(() => {
				dispatch('actionFetchAsyncInteractionsFromContact', {
					mission: 'getNotesToo',
				})
			})
			.catch(() => { })
	},

	actionUpdateAsyncContactInteraction({ dispatch, state }, payload) {
		const newInteraction = payload

		// Remove the temporary ids on interactions tags
		if (newInteraction?.tags?.length) {
			newInteraction.tags = newInteraction.tags.map((tag) => {
				if (typeof tag.id === 'string') {
					delete tag.id
				}
				return tag
			})
		}

		const interactionsStore = useInteractionsStore()

		contact_store
			.updateAsyncInteractionForContact(state.activeContact.id, newInteraction)
			.then(() => {
				interactionsStore.actionRequestGetTagsInteractions()
				dispatch('actionFetchAsyncInteractionsFromContact', {
					mission: 'getNotesToo',
				})
			})
			.catch(() => { })
	},

	actionAddTagsFromContactInteraction({ state, commit }, payload) {
		const { interactionId, tags } = payload
		const allInteractions = cloneDeep(state.activeContactInteractions)

		const getInteraction = allInteractions.find((interaction) => interaction.id === interactionId)
		getInteraction.tags = tags

		commit(SET_INTERACTIONS_CONTACT, allInteractions)

		contact_store
			.updateAsyncInteractionForContact(state.activeContact.id, getInteraction)
			.catch(() => { })
	},

	actionRemoveTagFromContactInteraction({ commit, state }, payload) {
		const { interactionId, tagId } = payload
		const allInteractions = cloneDeep(state.activeContactInteractions)

		const getInteraction = allInteractions.find((interaction) => interaction.id === interactionId)
		getInteraction.tags = getInteraction.tags.filter((tag) => tag.id !== tagId)

		commit(SET_INTERACTIONS_CONTACT, allInteractions)

		contact_store
			.updateAsyncInteractionForContact(state.activeContact.id, getInteraction)
			.catch(() => { })
	},

	async actionDeleteAsyncContactInteraction({ commit, dispatch, state }, payload) {
		await Promise.all(
			Array.from(payload).map(async (interaction) => {
				if (interaction.type === 'comment') {
					await contact_store
						.fetchAsyncDeleteNoteFromContact(state.activeContact.id, interaction.id)
						.then(() => {
							commit(DELETE_NOTES_LOCALLY_INTO_CONTACT_STORE_MODULE, interaction.id)
							commit(DELETE_INTERACTION_CONTACT, interaction.id)
						})
				}
				else {
					await contact_store
						.deleteAsyncContactInteraction(state.activeContact.id, interaction.id)
						.then(() => {
							commit(DELETE_INTERACTION_CONTACT, interaction.id)
						})
				}
			}),
		)
			.then(() => {
				dispatch('actionFetchAsyncInteractionsFromContact', {
					mission: 'getNotesToo',
				})
			})
			.catch(() => { })
	},

	async actionUploadAttachmentsToInteraction({ commit }, payload) {
		if (payload.addFromMounted) {
			const payloadFromMounted = {
				replaceArray: true,
				files: payload.files,
			}
			commit(SET_INTERACTION_ATTACHMENTS, payloadFromMounted)
			return
		}
		commit(SET_UPLOADING_INTERACTION, 'uploading')
		await Promise.all(
			Array.from(payload).map(async (file) => {
				const params = {
					file,
					is_interaction: true,
				}
				await contact_store
					.getAttachmentsIDForInteraction(params)
					.then((res) => {
						commit(SET_UPLOADING_INTERACTION, 'default')
						commit(SET_INTERACTION_ATTACHMENTS, res)
					})
					.catch((error) => {
						commit(SET_UPLOADING_INTERACTION, 'default')
						console.error(error)
					})
			}),
		).catch((e) => {
			console.error(e)
		})
	},

	async actionDispatchInteractionAttachmentsDeletion({ state, dispatch }, payload) {
		if (payload) {
			if (payload.interactionId) {
				if (payload.attachmentId) {
					await dispatch('actionDeleteInteractionAttachments', {
						commit: DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS,
						params: {
							attachmentId: payload.attachmentId,
							interactionId: payload.interactionId,
						},
					})
					return
				}
				await dispatch('actionDeleteInteractionAttachments', {
					commit: DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS,
					params: {
						attachmentId: state.actualAttachmentsForNewInteraction.map((file) => file.id),
						interactionId: payload.interactionId,
					},
				})
				return
			}
			await dispatch('actionDeleteInteractionAttachments', {
				commit: DELETE_ONE_OR_MANY_INTERACTION_ATTACHMENTS,
				params: {
					attachmentId: payload.attachmentId,
				},
			})
			return
		}
		await dispatch('actionDeleteInteractionAttachments', {
			commit: DELETE_INTERACTION_ATTACHMENTS,
			params: {
				attachmentId: state.actualAttachmentsForNewInteraction.map((file) => file.id),
			},
		})
		return
	},

	async actionDeleteInteractionAttachments({ commit }, payload) {
		await Promise.all(
			Array.from(payload.params.attachmentId).map(async (attachment) => {
				await documents_store
					.deleteDocument(attachment)
					.then(() => {
						commit(payload.commit, payload.params)
					})
					.catch((err) => {
						console.log(err)
					})
			}),
		).catch((err) => {
			console.error(err)
		})
	},

	actionGetContactEmailsList({ commit }, activeContactId) {
		commit(SET_CONTACT_EMAILS_LIST_LOADING, true)
		contact_store
			.getContactEmailsList(activeContactId)
			.then((emails) => {
				commit(SET_CONTACT_EMAILS_LIST, emails)
				commit(SET_CONTACT_EMAILS_LIST_LOADING, false)
			})
			.catch(() => commit(SET_CONTACT_EMAILS_LIST_LOADING, false))
	},

	actionResetContactEmailsList({ commit }) {
		commit(SET_CONTACT_EMAILS_LIST, [])
	},

	actionUpdateEmailAsRead({ commit }, payload) {
		const { imapMessageId, emailEngineId } = payload
		contact_store
			.updateEmailAsRead(imapMessageId, emailEngineId)
			.then(() => {
				commit(MARK_EMAIL_AS_READ, imapMessageId)
			})
			.catch(() => { })
	},
	actionSendEmailToContact({ state, commit }, payload) {
		contact_store
			.sendEmailToContact(payload, state.activeContact.id)
			.then(() => {
				commit(ADD_LOCALLY_NEW_EMAIL_TO_LIST, payload)
			})
			.catch(() => {
				return false
			})
	},

	async actionGetAttachment({ }, payload) {
		const { emailEngineId, attachmentId, onlyDownload } = payload
		await contact_store.getAttachment(emailEngineId, attachmentId, onlyDownload).catch((err) => {
			console.log(err)
		})
	},

	actionUpdateRefreshRequestLimit({ commit }, isLimited) {
		commit(SET_REQUEST_LIMIT, isLimited)
	},

	actionHandleRequestLimitCounter({ state, commit, dispatch }, counterLimit) {
		commit(SET_NEW_COUNTER_LIMIT, counterLimit)
		dispatch('actionUpdateRefreshRequestLimit', true)

		setTimeout(() => {
			dispatch('actionUpdateRefreshRequestLimit', false)
		}, counterLimit * 1000)
		const timer = setInterval(() => {
			commit(SET_NEW_COUNTER_LIMIT, 1)
			if (!state.isRefreshRequestLimited) {
				clearInterval(timer)
				commit(SET_NEW_COUNTER_LIMIT, counterLimit)
			}
		}, 1000)
	},

	actionRemoveEmptyContactBaseViewWhenTagRemoval({ commit }, payload) {
		commit(REMOVE_EMPTY_CONTACT_BASE_VIEW_WHEN_TAG_REMOVAL, payload)
	},

	actionFetchAgeCategories({ commit }) {
		contact_store.fetchGetCategories().then(({ age_categories }) => {
			commit(SET_CONTACT_AGE_CATEGORIES, age_categories || [])
		})
	},

	actionAddPollingStation({ commit }, payload) {
		commit(ADD_POLLING_STATION, payload)
	},

	actionSetContactFiltersForSmsEmails({ commit }, payload) {
		commit(SET_CONTACT_FILTERS_FOR_SMS_EMAILS, payload)
	},

	async actionFetchAsyncContactTransactionInfos({ commit }, idContact) {
		const transactionsContact = await contact_store.getContactTransactionInfos(idContact)
		commit(SET_CONTACT_TRANSACTION_INFOS, transactionsContact)
	},

	async actionGetLightContact({ }, arrayContactId) {
		let arrayLightContact = await contact_store.fetchLightContacts(arrayContactId)
		return arrayLightContact
	},
}

const getters = {
	tags: (state) => state.tags,
	blurred: (state) => state.blurred,
	getIsRemovingContactMainView: (state) => state.isRemovingContactMainView,
	activeContact: (state) => state.activeContact,
	getActiveContactBackup: (state) => state.activeContactBackup,
	getActiveContactNotes: (state) => state.activeContactNotes,
	getActiveContactHistory: (state) => state.activeContactHistory,
	getActiveContactInteractions: (state) =>
		state.activeContactInteractions.filter((interaction) =>
			Object.prototype.hasOwnProperty.call(interaction, 'date_of_event'),
		),
	getActiveContactEmailInteractions: (state) =>
		state.activeContactInteractions.filter((interaction) => interaction.type === 'email'),
	getIsLoadingUpdateContact: (state) => state.isLoadingUpdateContact,
	getIsContactWasCreated: (state) => state.isContactWasCreated,
	getIsContactWasUpdated: (state) => state.isContactWasUpdated,
	getContactCreationStatus: (state) => state.contactCreationStatus,
	getIsFetchingCurrentContact: (state) => state.isFetchingCurrentContact,
	getContactMembershipInfos: (state) => state.contactMembershipInfos,
	getSanctionsTypesList: (state) => state.sanctionsTypesList,
	getSanctionAuthoritiesList: (state) => state.sanctionAuthoritiesList,
	getContactSanctionsStatus: (state) => state.contactSanctionsStatus,
	getContactSanctions: (state) => state.contactSanctions,
	getIsLoadingHistoryContact: (state) => state.isLoadingHistoryContact,
	getIsLoadingInteractionsContact: (state) => state.isLoadingInteractionContact,
	loadingExport: (state) => state.loadingExport,
	isCreatingContact: (state) => state.isCreatingContact,
	getAllPollingStations: (state) => state.allPollingStations,
	// Used to filter a territory with the 'missing' element
	getAllPollingStationsWithMissingTransformed: (state) => {
		let clonedPollingStations = cloneDeep(state.allPollingStations)
		clonedPollingStations.forEach((pollingStation) => {
			if (pollingStation.value === 'missing') {
				pollingStation.label = i18n.global.t('CONTACT.HEADER.FILTERS.MISSING')
			}
		})
		return clonedPollingStations
	},
	// Used to select a territory without having the 'missing' element
	getAllPollingStatonsWithoutMissing: (state) =>
		state.allPollingStations.filter((pollingStation) => pollingStation.value !== 'missing'),
	showDetailedAnswers: (state) => state.showDetailedAnswers,
	showContactsFilters: (state) => state.showContactsFilters,
	clientDetailsModal: (state) => state.clientDetailsModal,
	getNumberSelectedOnPage: (state) => state.numberSelectedOnPage,
	isContactEmailListLoading: (state) => state.contactEmailsListLoading,
	getContactEmailsList: (state) => state.contactEmailsList,
	isRefreshRequestLimited: (state) => state.isRefreshRequestLimited,
	getCounterLimit: (state) => state.counterLimit,
	getActualAttachmentsForNewInteraction: (state) => state.actualAttachmentsForNewInteraction,
	isAttachmentsInteractionUploading: (state) => state.isAttachmentsInteractionUploading,
	getMAssiveUpdateToastState: (state) => state.massiveUpdateToastDisplayed,
	getRemoveEmptyContactBaseViewWhenTagRemoval: (state) =>
		state.removeEmptyContactBaseViewWhenTagRemoval,
	getAgeCategories: (state) => state.ageCategories,
	getGenderValues: (state) => state.genderValues,
	contactFiltersForSmsEmails: (state) => state.contactFiltersForSmsEmails,
	getContactTransactionInfos: (state) => state.contactTransactionInfos,
}

export const contactModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
