import exportStore from '../../models/export_store'
import { constructQueryFromStore } from './utils/searchTools'

import { FETCH_ALL_EXPORTS, SET_EXPORTS_TEMPLATES, SET_EXPORT_TEMPLATE, SET_EXPORTS_TEMPLATES_SAVE_STATUS, SET_EXPORTS_TEMPLATES_DELETE_STATUS, DELETE_A_EXPORT_TEMPLATE } from './_mutation-types'
import { isArray } from 'lodash'

const getDefaultState = () => {
	return {
		listExports: [],
		limitExportContact: 100000,
		exportsTemplates: [],
		exportsTemplatesSaveStatus: 'default',
		exportsTemplatesDeleteStatus: 'default',
	}
}

const state = getDefaultState

const mutations = {
	[FETCH_ALL_EXPORTS]: (state, payload) => {
		state.listExports = payload
	},

	[SET_EXPORTS_TEMPLATES]: (state, payload) => {
		state.exportsTemplates = payload
	},

	[SET_EXPORT_TEMPLATE]: (state, payload) => {
		state.exportsTemplates.push(payload)
	},

	[SET_EXPORTS_TEMPLATES_SAVE_STATUS]: (state, payload) => {
		state.exportsTemplatesSaveStatus = payload
	},

	[SET_EXPORTS_TEMPLATES_DELETE_STATUS]: (state, payload) => {
		state.exportsTemplatesDeleteStatus = payload
	},

	[DELETE_A_EXPORT_TEMPLATE]: (state, payload) => {
		const index = state.exportsTemplates.findIndex((template) => template.ID === payload)
		if (index !== -1) {
			state.exportsTemplates.splice(index, 1)
		}
	},
}

const actions = {
	actionFetchExports: ({ commit }) => {
		exportStore.fecthAllExport().then((payload) => {
			commit(FETCH_ALL_EXPORTS, payload)
		})
	},

	actionStartExport: async ({ rootGetters }, payload) => {
		// blow up the per_page search limit for export
		const classicSearch = {
			...constructQueryFromStore(rootGetters),
		}
		classicSearch.fields[2] = String(payload.nb_result)
		if (!payload.ids?.length && classicSearch.advanced_search) {
			classicSearch.advanced_search.per_page = payload.nb_result
		}

		const data = {
			format: payload.format,
			mapping: payload.mapping,
			direct_mail: payload.direct_mail,
			search_request: {
				contact_ids: payload.ids,
				...classicSearch,
			},
			direct_mail_configuration: payload.directMailConfiguration || {},
		}

		if (payload?.blacklistedContactIncluded) {
			data.filter_blacklist = !payload.blacklistedContactIncluded
		}
		if (payload?.npaiContactsIncluded) {
			data.filter_invalid_address = !payload.npaiContactsIncluded
		}

		return exportStore
			.startNewExport(data)
			.then((res) => {
				return res
			})
			.catch((err) => {
				return err
			})
	},

	actionGetExportTemplates: ({ commit }) => {
		exportStore.getExportTemplates().then((payload) => {
			commit(SET_EXPORTS_TEMPLATES, payload)
		})
	},

	actionSaveExportTemplate: ({ commit }, payload) => {
		exportStore.saveExportTemplate(payload)
			.then((payload) => {
				commit(SET_EXPORT_TEMPLATE, payload)
				commit(SET_EXPORTS_TEMPLATES_SAVE_STATUS, 'success')
			})
			.catch((err) => {
				commit(SET_EXPORTS_TEMPLATES_SAVE_STATUS, 'error')
			})
		setTimeout(() => {
			commit(SET_EXPORTS_TEMPLATES_SAVE_STATUS, 'default')
		}, 1000)
	},

	actionDeleteExportTemplate: ({ commit }, payload) => {
		exportStore.deleteExportTemplate(payload)
			.then(() => {
				commit(DELETE_A_EXPORT_TEMPLATE, payload)
				commit(SET_EXPORTS_TEMPLATES_DELETE_STATUS, 'success')
			})
			.catch((err) => {
				commit(SET_EXPORTS_TEMPLATES_DELETE_STATUS, 'error')
			})
		setTimeout(() => {
			commit(SET_EXPORTS_TEMPLATES_DELETE_STATUS, 'default')
		}, 1000)
	},
}

const getters = {
	getListExports: (state) => state.listExports,
	getLimitExportContact: (state) => state.limitExportContact,
	getExportsTemplates: (state) => state.exportsTemplates,
	getExportsTemplatesSaveStatus: (state) => state.exportsTemplatesSaveStatus,
	getExportsTemplatesDeleteStatus: (state) => state.exportsTemplatesDeleteStatus,
}

export const exportModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
