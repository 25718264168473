/**
 * App-module routes
 *
 * handle mostly publics routes
 *
 * @rootPath {none}
 */

import PublicPageContainer from 'components/app-module/PublicPageContainer.vue'
import Download from 'components/app-module/download.vue'
import Login from 'components/app-module/Login.vue'
import LoginAuth0Loading from 'components/app-module/LoginAuth0Loading.vue'
import RecoverPassword from 'components/app-module/RecoverPassword.vue'
import Validation from 'components/app-module/validation.vue'
import WaitingPage from 'components/app-module/WaitingPage.vue'
import senderValidated from 'components/app-module/bulk-email-outside-page/senderValidated.vue'
import SignupRoutes from './signup-route'

export default [
	{
		path: '/public',
		name: 'public-page',
		children: [
			{
				meta: { title: 'APP.RECOVER.LOGIN' },
				path: '/login',
				name: 'login',
				component: Login,
			},
			{
				meta: { title: 'APP.RECOVER.LOGIN' },
				path: '/login-auth0-callback',
				name: 'login-auth0-callback',
				component: LoginAuth0Loading,
			},
			{
				meta: { title: 'APP.RECOVER.TITLE' },
				path: '/recover',
				name: 'recover',
				component: RecoverPassword,
			},
			{
				meta: { title: '_COMMON.VALIDATE' },
				path: '/validationuser',
				name: 'validation',
				component: Validation,
			},
			{
				meta: { title: '_COMMON.DOWNLOAD' },
				path: '/download',
				name: 'download',
				component: Download,
			},
			{
				meta: { title: '_COMMON.LOADING' },
				path: '/waiting-page/:campaignId',
				name: 'waiting-page',
				component: WaitingPage,
			},
			{
				meta: { title: 'Adresse validée !' },
				path: '/bulk-email/validated',
				name: 'bulk-email-validated',
				component: senderValidated,
			},
			SignupRoutes,
		],
		component: PublicPageContainer,
	},
]
