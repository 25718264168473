<template>
	<div class="flex flex-auto min-h-0 bg-pink-lighted">
		<q-loader
			v-if="statusLists === 'loading'"
			class="justify-self-center self-center"
			size="25%"
		/>
		<div
			v-else
			class="grid w-full min-h-0 overflow-hidden"
		>
			<div
				v-if="computedNbOfLists === 0"
				class="flex flex-row w-full min-h-0 justify-between"
				style="grid-area: 1 / 1"
			>
				<div class="px-28 py-28">
					<div class="text-pink-main text-4xl font-bold mb-2">
						{{ $t('CONTACT.DYNAMIC_LISTS.EMPTY') }}
					</div>
					<div class="text-4xl font-bold">
						{{ $t('CONTACT.DYNAMIC_LISTS.SAVE') }}
					</div>
					<div class="mt-8">
						{{ $t('CONTACT.DYNAMIC_LISTS.DESCRIPTION_WHEN_EMPTY') }}
					</div>
					<q-button
						class="mt-16"
						@click="showCreationModal = true"
					>
						{{ $t('CONTACT.DYNAMIC_LISTS.CREATE_A_LIST') }}
					</q-button>
				</div>
				<img src="/static/images/deco-dynamic-lists.png">
			</div>
			<div
				v-else
				class="flex flex-col min-h-0 overflow-x-auto"
				:class="{ blurred: showCreationModal || showEditModal || showDeletionModal }"
				style="grid-area: 1 / 1"
			>
				<div
					class="my-8 mx-4"
					style="overflow-y: scroll"
				>
					<el-table
						v-if="favoriteLists.length > 0"
						class="w-full mb-8 dynamic-lists-table"
						:data="favoriteLists.sort(methodSortLists)"
						header-cell-class-name="tableHeaderCellStyle"
						:row-class-name="(scope) => `tableRowStyle ${methodStripedRow(scope)}`"
						cell-class-name="tableRowCellStyle"
						@row-click="methodOpenList($event.id)"
					>
						<!-- PIN -->
						<el-table-column
							width="70"
							align="center"
						>
							<!-- eslint-disable vue/no-unused-vars -->
							<template #header="scope">
								<div style="transform: translateX(calc(35px - 0.5rem)); white-space: nowrap">
									{{
										$tc('CONTACT.DYNAMIC_LISTS.FAVORITE_COUNT', 1, { count: favoriteLists.length })
									}}
								</div>
							</template>
							<!-- eslint-enable vur/no-unused-vars -->
							<template #default="{ row }">
								<div
									class="hover-scale"
									@click.stop="methodSwitchFavorite(row)"
								>
									<el-icon class="text-xl text-green-main align-middle">
										<star-filled class="text-xl" />
									</el-icon>
								</div>
							</template>
						</el-table-column>

						<!-- TITLE -->
						<el-table-column
							min-width="130"
							prop="name"
							align="left"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="text-black w-full align-middle"
								>
									{{ row.title }}
								</span>
							</template>
						</el-table-column>

						<!-- DATE -->
						<el-table-column
							min-width="80"
							:label="$t('CONTACT.DYNAMIC_LISTS.DATE')"
							prop="created_at"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="w-full align-middle"
								>
									{{ methodDateFormat(row.created_at) }}
								</span>
							</template>
						</el-table-column>

						<!-- MEMBER -->
						<el-table-column
							min-width="110"
							prop="user_id"
							align="left"
							:label="$t('CONTACT.DYNAMIC_LISTS.MODIFIED_BY')"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="w-full px-1 align-middle"
								>
									{{ methodGetMember(row.user_id) }}
								</span>
							</template>
						</el-table-column>

						<!-- TOOLS -->
						<el-table-column
							min-width="90"
							align="right"
						>
							<template #default="{ row }">
								<div class="flex items-center justify-end pr-4">
									<span
										class="flex items-center p-3 pointer text-lg hover-scale"
										@click.stop="
											() => {
												editList = row
												showEditModal = true
											}
										"
									>
										<i class="gui-edit text-green-main" />
									</span>
									<i
										class="bg-gray-light"
										style="width: 1px; height: 24px"
									/>
									<span
										class="flex items-center p-3 pointer text-lg hover-scale"
										@click.stop="
											() => {
												deletionList = row
												showDeletionModal = true
											}
										"
									>
										<i class="gui-delete text-gray-dark" />
									</span>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<el-table
						v-if="nonFavoriteLists.length > 0"
						class="w-full mb-12 dynamic-lists-table"
						:data="nonFavoriteLists.sort(methodSortLists)"
						header-cell-class-name="tableHeaderCellStyle"
						:row-class-name="(scope) => `tableRowStyle ${methodStripedRow(scope)}`"
						cell-class-name="tableRowCellStyle"
						@row-click="methodOpenList($event.id)"
					>
						<el-table-column
							width="70"
							align="center"
						>
							<template #default="{ row }">
								<div
									class="py-2"
									:class="`${
										favoriteLists.length >= 4
											? 'cursor-not-allowed text-gray-300'
											: 'hover-scale text-gray-dark '
									}`"
									@click.stop="methodSwitchFavorite(row)"
								>
									<el-icon class="text-xl align-middle">
										<star />
									</el-icon>
								</div>
							</template>
						</el-table-column>

						<!-- TITLE -->
						<el-table-column
							min-width="130"
							:label="$t('_COMMON.NAME')"
							prop="name"
							align="left"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="text-black w-full align-middle"
								>
									{{ row.title }}
								</span>
							</template>
						</el-table-column>

						<!-- DATE -->
						<el-table-column
							min-width="80"
							:label="$t('CONTACT.DYNAMIC_LISTS.DATE')"
							prop="created_at"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="w-full align-middle"
								>
									{{ methodDateFormat(row.created_at) }}
								</span>
							</template>
						</el-table-column>

						<!-- MEMBER -->
						<el-table-column
							min-width="110"
							:label="$t('CONTACT.DYNAMIC_LISTS.MODIFIED_BY')"
							prop="user_id"
							align="left"
						>
							<template #default="{ row }">
								<span
									v-tooltip
									class="w-full px-1 align-middle"
								>
									{{ methodGetMember(row.user_id) }}
								</span>
							</template>
						</el-table-column>

						<!-- TOOLS -->
						<el-table-column
							min-width="90"
							align="right"
						>
							<template #default="{ row }">
								<div class="flex items-center justify-end pr-4">
									<span
										class="flex items-center p-2 pointer text-lg hover-scale"
										@click.stop="
											() => {
												editList = row
												showEditModal = true
											}
										"
									>
										<i class="gui-edit text-green-main" />
									</span>
									<i
										class="bg-gray-light"
										style="width: 1px; height: 24px"
									/>
									<span
										class="flex items-center p-2 pointer text-lg hover-scale"
										@click.stop="
											() => {
												deletionList = row
												showDeletionModal = true
											}
										"
									>
										<i class="gui-delete text-gray-dark" />
									</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>

			<modal-create-list
				v-if="showCreationModal"
				v-model:show-modal="showCreationModal"
				style="grid-area: 1 / 1"
			/>
			<modal-edit-list
				v-if="showEditModal"
				v-model:show-modal="showEditModal"
				style="grid-area: 1 / 1"
				:list="editList"
				@updated="methodUpdateList($event)"
			/>
			<modal-deletion-list
				v-if="showDeletionModal"
				v-model:show-modal="showDeletionModal"
				style="grid-area: 1 / 1"
				:list="deletionList"
				@remove-list="methodRemoveList($event)"
			/>
		</div>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import tooltipMixin from '/src/mixins/tooltipMixin.js'
import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/format'
import { formatCustomDate, formatRelativeDate, formatTime } from '../../../utils/dateFnsHelper'
import parseISO from 'date-fns/parseISO'
import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import QueryBuilder from '@quorumsco/quorum-helpers/lib/filter/QueryBuilder'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'DynamicLists',
	components: {
		ModalCreateList: defineAsyncComponent(() => import('./modal-create-list.vue')),
		ModalDeletionList: defineAsyncComponent(() => import('./modal-deletion-list.vue')),
		ModalEditList: defineAsyncComponent(() => import('./modal-edit-list.vue')),
		QLoader: defineAsyncComponent(() => import('/src/components/general/loader.vue')),
	},
	mixins: [tooltipMixin],
	props: {
		showCreationListDialog: {
			type: Number,
			default: 0,
		},
	},
	emits: ['select-list'],
	data() {
		return {
			showCreationModal: false,
			showEditModal: false,
			showDeletionModal: false,
			editList: {},
			deletionList: {},
		}
	},
	computed: {
		...mapGetters(['getAllUsers']),
		...mapGetters('@filter', ['sorting', 'nb_results', 'polygon', 'order']),
		...mapGetters('@lists', ['lists', 'statusLists', 'favoriteLists', 'nonFavoriteLists']),
		...mapGetters('@search', [
			'getSearchAddressIncluded',
			'getSearchPollingStationsIncluded',
			'pageAdvancedSearch',
		]),
		computedNbOfLists() {
			return this.favoriteLists.length + this.nonFavoriteLists.length
		},
	},
	watch: {
		showCreationListDialog: {
			handler() {
				this.showCreationModal = true
			},
			immediate: false,
		},
	},
	mounted() {
		this.actionFetchLists()
	},
	methods: {
		...mapActions('@lists', ['actionFetchLists', 'actionUpdateList']),
		...mapActions('@search', [
			'actionSetAdvancedSearch',
			'actionKpiLocalContactsFromAdvancedSearch',
		]),
		...mapActions({
			actionUpdateLocationSummaryGeoHash: 'UPDATE_LOCATION_SUMMARY_GEOHASH',
		}),

		methodGetMember(userId) {
			const user = this.getAllUsers.find((user) => user.id === userId)

			return user
				? `${user.firstname} ${user.surname}`
				: this.$t('CONTACT.LIST.USER_UNKNOWN', { id: userId })
		},
		methodOpenList(listId) {
			this.$emit('select-list', listId)
			this.$nextTick(() => {
				// Save polygon if there is one
				let options = { name: 'contact-list', query: { list: listId } }
				if (this.polygon.length) options.query = { ...options.query, savePolygon: true }

				this.$router.push(options)
			})
		},
		methodSwitchFavorite(list) {
			if (list.is_favorite || (!list.is_favorite && this.favoriteLists.length < 4)) {
				const cloneList = cloneDeep(list)

				cloneList.is_favorite = !cloneList.is_favorite
				this.actionUpdateList(cloneList)
			}
		},
		methodSortLists(aList, bList) {
			return (new Date(aList.created_at) < new Date(bList.created_at)) * 2 - 1
		},
		methodDateFormat(aDate) {
			return formatCustomDate(parseISO(aDate))
		},
		methodStripedRow({ rowIndex }) {
			if (rowIndex % 2 === 1) {
				return 'rowStripeDark'
			}

			return ''
		},
		methodRemoveList(id) {
			if (id === this.$route?.query.list) {
				this.$emit('select-list', -1)
			}
		},
		methodUpdateList(newQuery) {
			if (this.editList.id === this.$route?.query.list && newQuery) {
				const queryParsed = QueryNode.deserialize(JSON.stringify(newQuery))

				if (queryParsed.isNode()) {
					const advancedFilter = this.methodConstructQueryBuilderInstance(queryParsed)

					this.actionSetAdvancedSearch(advancedFilter)

					if (Object.keys(advancedFilter).length) {
						this.actionKpiLocalContactsFromAdvancedSearch(advancedFilter.build())
						const addressIncluded = this.getSearchAddressIncluded
						const pollingStationsIncluded = this.getSearchPollingStationsIncluded

						this.actionGenerateKpi({ addressIncluded, pollingStationsIncluded })
						this.actionUpdateLocationSummaryGeoHash({
							addressIncluded,
							pollingStationsIncluded,
						})
					}
				}
			}
		},
		methodConstructQueryBuilderInstance(query) {
			return new QueryBuilder(
				query.parse(),
				this.nb_results,
				this.pageAdvancedSearch,
				this.sorting,
				this.order,
			)
		},
	},
}
</script>

<style lang="sass">
@import 'src/assets/scss/shared/shared-constants.scss'

$table-white: #FDFBFA
.el-table.dynamic-lists-table
	background-color: transparent
	.tableHeaderCellStyle
		height: 52px
		background: transparent
		overflow-x: visible
		overflow-y: unset
		.cell
			text-transform: uppercase
			font-weight: normal
			font-size: 0.9rem
			padding: 0
			overflow-x: visible
			overflow-y: unset

	.tableRowStyle
		cursor: pointer
		height: 40px
		&:hover > td.tableRowCellStyle
			transition: background-color 50ms ease-in
			background-color:  darken($table-white, 2%)

	.tableRowCellStyle
		padding: 0
		.cell
			padding: 0

	.rowStripeDark
		background-color: $table-white

	.hover-scale
		transform: scale(1)
		transition: all 150ms ease-in-out
		&:hover
			transform: scale(1.15)
</style>

<style lang="sass" scoped>
.blurred
	filter: blur(2px) grayscale(1)
</style>
