<template>
	<div class="tab-select-container">
		<div v-for="(tab, tabIndex) in tabs" :key="tab.id" class="tab" @click="tabClick(tabIndex)">
			{{ tab }}
		</div>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'TabSelect',

	props: ['value', 'tabs'],
	emits: ['update:value'],

	computed: {
		hasButtonsAndControls: function () {
			return this.$slots['controls-container'] && this.$slots['buttons-container']
		},
	},

	mounted: function () {
		this.tabClick(this.value)
	},

	methods: {
		tabClick: function (tabIndex) {
			$('.tab.active').removeClass('active')
			$($('.tab')[tabIndex]).addClass('active')
			this.$emit('update:value', tabIndex)
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/general/tab-select.scss';
</style>
