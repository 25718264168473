import {
	RESET_KPI_STORE,
	SET_INSTANCIED_MODULE_ID,
	SET_KPI,
	SET_KPIS_CARTO_ARE_VALID,
	SET_KPIS_CARTO_CONTACTS,
	SET_KPIS_CARTO_PRESENCE,
	SET_KPIS_CARTO_STATUS,
	SET_KPIS_LOADING,
	SET_EMAILS_MISSING_KPI,
} from './_mutation-types'
import contact_store from '../../models/contact_store'
import {
	constructQueryFromStoreAdaptator,
	formatOfFutureObjectForSearching,
} from './utils/searchTools'
import get from 'lodash/get'
import modulesInstancesMixin from '../../components/mixins/modules-instances-mixin'

const getDefaultState = () => {
	return {
		kpi: [],
		kpisCartoAreValid: false,
		kpisCartoPresence: [],
		kpisCartoStatus: [],
		kpisCartoContacts: 0,
		loadingKpis: false,
		instanceId: '',
		emailsMissingKpi: null,
	}
}

const state = getDefaultState

const mutations = {
	[RESET_KPI_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},

	[SET_KPI]: (state, res) => {
		state.kpi = res
	},

	[SET_KPIS_CARTO_ARE_VALID]: (state, payload) => {
		state.kpisCartoAreValid = payload
	},

	[SET_KPIS_CARTO_STATUS]: (state, payload) => {
		state.kpisCartoStatus = payload
	},

	[SET_KPIS_CARTO_PRESENCE]: (state, payload) => {
		state.kpisCartoPresence = payload
	},

	[SET_KPIS_CARTO_CONTACTS]: (state, payload) => {
		state.kpisCartoContacts = payload
	},

	[SET_KPIS_LOADING]: (state, payload) => {
		state.loadingKpis = payload
	},

	[SET_INSTANCIED_MODULE_ID]: (state, res) => {
		state.instanceId = res
	},

	[SET_EMAILS_MISSING_KPI]: (state, res) => {
		state.emailsMissingKpi = res
	},
}

const actions = {
	actionResetKpiState: async ({ commit }) => {
		commit(RESET_KPI_STORE)
	},

	GENERATE_KPI: async ({ state, commit, dispatch, rootGetters, rootState }, payload = null) => {
		if (!rootState.active_request && rootState['@action'].entryPoint === '') {
			dispatch(
				`${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
					'@filter',
					state.instanceId,
				)}/actionSetFiltersForSearch`,
				{},
				{ root: true },
			)
		}

		const filterName = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)

		const { query, filter, polygon, polygons, tags_filter, duplicates_filter } = rootState[filterName]

		await contact_store
			.kpi(
				constructQueryFromStoreAdaptator(
					rootGetters,
					query,
					filter,
					polygon,
					tags_filter,
					duplicates_filter,
					formatOfFutureObjectForSearching(payload),
					8,
					null,
					null,
					state.instanceId,
					polygons,
				),
			)
			.then((res) => {
				const { kpi } = res

				if (kpi && kpi[0].KpiReplies !== undefined) {
					commit(SET_KPI, kpi)
					commit('TOTAL_HIT', state.kpi[0].KpiReplies[0].Doc_count, { root: true })
				}
			})
	},

	async actionGetKpiPresenceStatus({ state, dispatch, rootGetters, rootState }, payload = null) {
		if (!rootState.active_request && rootState['@action'].entryPoint === '') {
			dispatch(
				`${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
					'@filter',
					state.instanceId,
				)}/actionSetFiltersForSearch`,
				{},
				{ root: true },
			)
		}

		const filterName = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)

		const { query, filter, polygon, tags_filter, duplicates_filter } = rootState[filterName]

		let res = await contact_store
			.kpiPresenceStatus(
				constructQueryFromStoreAdaptator(
					rootGetters,
					query,
					filter,
					polygon,
					tags_filter,
					duplicates_filter,
					formatOfFutureObjectForSearching(payload),
					8,
					null,
					null,
					state.instanceId,
				),
			)

		return res
	},

	async actionGenerateKpisCarto({ state, commit, dispatch, rootGetters, rootState }, payload) {
		commit('SET_KPIS_LOADING', true)

		if (!rootState.active_request && rootState['@action'].entryPoint === '') {
			dispatch(
				`${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
					'@filter',
					state.instanceId,
				)}/actionSetFiltersForSearch`,
				{},
				{ root: true },
			)
		}

		const filterName = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)

		const { query, filter, polygon, polygons, tags_filter, duplicates_filter } = rootState[filterName]
		const { contactsStatusOptions, contactsVisitStatesOptions } = payload

		const querySearch = constructQueryFromStoreAdaptator(
			rootGetters,
			query,
			filter,
			polygon,
			tags_filter,
			duplicates_filter,
			formatOfFutureObjectForSearching(payload),
			8,
			null,
			null,
			state.instanceId,
			polygons,
		)

		await dispatch('actionGetKpiPresenceStatus', payload).then(async (res) => {
			if (!Array.isArray(res?.kpi)) {
				const resKpi = await contact_store.kpi(querySearch)
				const totalKpi = get(resKpi, 'kpi[0].KpiReplies[0].Doc_count')

				commit('SET_KPIS_CARTO_CONTACTS', totalKpi)
				commit('SET_KPIS_CARTO_PRESENCE', 0)
				commit('SET_KPIS_CARTO_STATUS', 0)
				// if the request fails for some backend related reason
				// (multiple contact forms, etc.)
				// we need to know about it and hide the relevant components
				commit('SET_KPIS_CARTO_ARE_VALID', true)
			}
			else {
				commit('SET_KPIS_CARTO_ARE_VALID', true)
				const resPresence = res?.kpi[1]?.KpiReplies ? res?.kpi[1]?.KpiReplies : []
				const resStatus = res?.kpi[2]?.KpiReplies ? res?.kpi[2]?.KpiReplies : []
				const _kpisCartoContacts = res.kpi[0].KpiReplies[0].Doc_count
				const _kpisCartoPresence = []
				const _kpisCartoStatus = []

				resPresence.forEach((kpiFilt) => {
					const filter = contactsVisitStatesOptions.find((filt2) => {
						return kpiFilt.Key === filt2.label
					})

					if (filter) {
						_kpisCartoPresence.push({ ...filter, count: kpiFilt.Doc_count })
					}
				})
				resStatus.forEach((kpiStat) => {
					const stat = contactsStatusOptions.find((stat2) => {
						return kpiStat.Key === stat2.label
					})

					if (stat) {
						_kpisCartoStatus.push({ ...stat, count: kpiStat.Doc_count })
					}
				})
				commit('SET_KPIS_CARTO_CONTACTS', _kpisCartoContacts)
				commit('SET_KPIS_CARTO_PRESENCE', _kpisCartoPresence)
				commit('SET_KPIS_CARTO_STATUS', _kpisCartoStatus)

				// Set kpi to get the information of missing emails
				await contact_store.kpi(querySearch).then((res) => {
					const { kpi } = res

					if (kpi && kpi[0].KpiReplies !== undefined) {
						commit(SET_KPI, kpi)
					}
				})
			}

			commit('SET_KPIS_LOADING', false)
		})
	},

	actionSetInstanceId({ commit }, payload) {
		commit(SET_INSTANCIED_MODULE_ID, payload)
	},

	actionGetContactsSearchEmailsKpi: async ({ commit }, payload) => {
		await contact_store.getContactsSearchEmailsKpi(payload)
			.then((res) => {
				commit(SET_EMAILS_MISSING_KPI, res)
			})
			.catch((err) => err)
	},

	actionResetEmailMissingKpi({ commit }) {
		commit(SET_EMAILS_MISSING_KPI, null)
	},

	async actionGetKpiFromPolygon({ state, rootGetters, rootState }, polygonToProvide) {
		const filterName = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)

		const { query, filter, tags_filter, duplicates_filter } = rootState[filterName]

		let totalKpi = 0
		let missingEmailKpi = 0
		let missingPhoneKpi = 0
		let res = await contact_store
			.kpi(
				constructQueryFromStoreAdaptator(
					rootGetters,
					query,
					filter,
					polygonToProvide,
					tags_filter,
					duplicates_filter,
					formatOfFutureObjectForSearching(null),
					8,
					null,
					null,
					state.instanceId,
				),
			)
		totalKpi = get(res, 'kpi[0].KpiReplies[0].Doc_count') || 0
		missingEmailKpi = get(res, 'kpi[5].KpiReplies[0].Doc_count') || 0
		missingPhoneKpi = get(res, 'kpi[6].KpiReplies[0].Doc_count') || 0

		return { totalKpi, missingEmailKpi, missingPhoneKpi }
	},
}

const getters = {
	kpi: (state) => state.kpi,
	getKPIEmailsMissing: (state) => state.kpi[5],
	getKPIPhoneMissing: (state) => state.kpi[6],
	getKpisCartoAreValid: (state) => state.kpisCartoAreValid,
	getKpisCartoContacts: (state) => state.kpisCartoContacts,
	getKpisCartoStatus: (state) => state.kpisCartoStatus,
	getKpisCartoPresence: (state) => state.kpisCartoPresence,
	getLoadingKpis: (state) => state.loadingKpis,
	getKPISendingEmailsMissing: (state) => state.emailsMissingKpi,
}

export const kpiModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
