<template>
	<div>
		<!-- ANCHOR - Title -->
		<div
			v-if="title"
			class="mb-1 bold"
		>
			{{ title }}
		</div>
		<!-- ANCHOR - Description -->
		<p
			v-if="description"
			class="mt-2 mb-3"
		>
			{{ description }}
		</p>
		<!-- ANCHOR - Editor -->
		<EditorContent
			:editor="editor"
		/>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { createLowlight, common } from 'lowlight'
import 'highlight.js/styles/atom-one-dark.css'

const editor = ref<Editor>()
const lowlight = createLowlight(common)

const CodeBlockLowlightCustomClass = CodeBlockLowlight.extend({
	// Redefine the html of a code block of CodeBlockLowlight so the hljs class is added in the code part for the highlight.js styles to work and add custom class too
	renderHTML() {
		return ['pre', ['code', { class: 'hljs rounded-lg text-sm h-52' }, 0]]
	},
	// Redefine some shortcuts we don't want to have
	addKeyboardShortcuts() {
		return {
			'Mod-Enter': () => { return true },
			// Allow tab inside codeBlock (https://github.com/ueberdosis/tiptap/issues/1153#issuecomment-946693201)
			'Tab': () => {
				if (editor.value.isActive('codeBlock')) {
					return editor.value.commands.insertContent('\t')
				}
			},
		}
	},
})

// ANCHOR - Props
interface Props {
	code: string
	title?: string
	description?: string
}

const props = defineProps<Props>()

// ANCHOR - Emit
const emit = defineEmits(['update:code'])

// ANCHOR - Mounted
onMounted(() => {
	editor.value = new Editor({
		extensions: [
			Document,
			Text,
			CodeBlockLowlightCustomClass.configure({
				lowlight,
			}),
		],
		editorProps: {
			attributes: {
				spellcheck: 'false',
				// Add rounded class for the editor for the focus state
				class: 'rounded-lg',
			},
		},
		onCreate: ({ editor }) => {
			// Init the editor with a code block and with the content if there is one
			editor.commands.setContent(
				props.code
					? {
							type: 'codeBlock',
							content: [
								{
									type: 'text',
									text: props.code,
								},
							],
						}
					: {
							type: 'codeBlock',
							content: [],
						},
			)
		},
		onUpdate: ({ editor }) => {
			const json = editor.getJSON()
			const isCodeBlock = json.content.length && json.content[0].type === 'codeBlock'

			// If the code block is deleted, create a new one
			if (!isCodeBlock) {
				editor.commands.setCodeBlock()
			}

			// Keep only the first editor code block if there is another one created by accident
			if (json.content.length > 1) {
				editor.commands.setContent(json.content[0])
			}

			emit('update:code', editor.getText())
		},

	})
})

// ANCHOR - Unmount
onBeforeUnmount(() => {
	editor.value?.destroy()
})

</script>
