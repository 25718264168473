import { $request } from './requester'

export default {
	async getListProfiles() {
		return $request('GET', '/insights').then((res) => res.body.data)
	},

	async getProfileKPIAvaiblabeContacts(statusListIds, omitSavedFilters = true) {
		return $request('GET', '/insights/kpi/available_contacts', {
			refvalues: JSON.stringify(statusListIds),
			omit_saved_filters: omitSavedFilters,
		}).then((res) => res.body.data)
	},

	async getProfile(profileId) {
		return $request('GET', `/insights/${profileId}`).then((res) => res.body.data)
	},

	async createProfile(data) {
		try {
			return $request('POST', '/insights', data).then((res) => res.body.data)
		}
		catch (error) {
			throw new Error(error)
		}
	},

	async updateProfile(data, profileId) {
		return $request('PATCH', `/insights/${profileId}`, data).then((res) => res.body.data)
	},

	async reloadProfile(profileId) {
		try {
			const res = await $request('POST', `/insights/${profileId}/reload`)
			return res.body.data
		}
		catch (error) {
			if (error.response) {
				const { status, body } = error.response
				const reason = body?.data?.reason

				if (reason) {
					throw new Error(reason)
				}
				else {
					throw new Error('unknown_error')
				}
			}

			return null
		}
	},

	async deleteProfile(profileId) {
		return $request('DELETE', `/insights/${profileId}`).then((res) => res)
	},
}
