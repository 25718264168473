import { $request } from './requester'
import api from '/src/api_constants.js'

export default {
	async uploadImg(img) {
		// Convert base64 to Blob
		const byteCharacters = atob(img.split(',')[1] || img)
		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)
		const blob = new Blob([byteArray], { type: 'image/jpg' })

		// Create FormData and append the blob as a file
		const formData = new FormData()
		formData.append('file', blob, 'image.jpg')

		// Send the form data via fetch
		try {
			const response = await fetch(`${api[0].LOCAL}/photos`, {
				method: 'POST',
				body: formData,
			})

			const text = await response.json()
			return text
		}
		catch (error) {
			console.error('Error uploading image:', error)
		}
	},

}
