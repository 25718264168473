import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-browser.prod.js'

import { getStore, setStore } from '../middlewares/storage'
import I18NService from '@quorumsco/i18n'
import Translations from '@quorumsco/babel'
import en from '@quorumsco/babel/translations/web/en.json'
import fr from '@quorumsco/babel/translations/web/fr.json'
import es from '@quorumsco/babel/translations/web/es.json'
import de from '@quorumsco/babel/translations/web/de.json'
import ro from '@quorumsco/babel/translations/web/ro.json'
import nl from '@quorumsco/babel/translations/web/nl.json'
import pt from '@quorumsco/babel/translations/web/pt.json'

const detectLocale = () => getStore('LANG') || navigator.language || navigator.userLanguag

// For EN/FR classic rules rewrited because of default language english
// https://github.com/kazupon/vue-i18n/blob/v8.x/src/index.js#L144
function pluralizationChoicesDefault(choice, choicesLength) {
	if (choice === 0) {
		return 0
	}

	if (choicesLength === 2) {
		if (choice === 0 || choice === 1) {
			return 0
		} else {
			return 1
		}
	}

	if (choicesLength === 3) {
		if (choice === 0) return 0
		if (choice === 1) return 1
		return 2
	}

	return 0
}

//Vue.use(VueI18n)

export const i18n = createI18n({
	locale: detectLocale(),
	allowComposition: true,
	fallbackLocale: {
		default: ['en', 'fr'],
	},
	messages: {
		en,
		fr,
		es,
		de,
		ro,
		nl,
		pt,
	},
	silentTranslationWarn: true, // https://github.com/kazupon/vue-i18n/issues/201
	pluralizationRules: {
		en: pluralizationChoicesDefault,
		fr: pluralizationChoicesDefault,
		es: pluralizationChoicesDefault,
		de: pluralizationChoicesDefault,
		ro: pluralizationChoicesDefault,
		nl: pluralizationChoicesDefault,
		pt: pluralizationChoicesDefault,
	},
})

// surcharge user's locale w/ something more specific after he's logged-in
const defineLocaleSpecific = async (localeSpecificFromBack, platform = 'web') => {
	const i18nService = new I18NService(Translations)

	// eslint-disable-next-line no-undef
	const defaultSpecificPackage = localeSpecificFromBack || XCUSTOM_USER_AGENT || ''

	try {
		const module = await i18nService.getJSONLanguageForApplications(
			detectLocale(),
			defaultSpecificPackage,
			platform
		)
		i18n.global.setLocaleMessage(module.path, module.content)
		i18n.global.locale = module.path
		// Changes on the lang attribute to set
		// the language from the module
		setStore('LANG', module.path)
		setStore('localeSpecific', defaultSpecificPackage)
	} catch (err) {
		throw new Error(err)
	}
}

export { defineLocaleSpecific }
