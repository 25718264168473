// module for the state store for sms
import get from 'lodash/get'
import communication_store from './../../models/communication_store.js'
import {
	SET_SMS_SENT,
	SET_NEW_SMS_SENT,
	SET_MESSAGE_BIRD_BALANCE,
	SET_MESSAGE_BIRD_STATUS,
	SET_STATUS_POST_SMS,
	SET_STATUS_POST_TEST_SMS,
	RESET_SMS_STORE,
	SET_TEST_DIALOG_VISIBLE,
	SET_RECAP_DIALOG_VISIBLE,
	SET_IS_READY_TO_BE_SENT,
	SET_IS_SENDING_DIALOG_VISIBLE,
	SET_MESSAGING_PROFILES,
} from '../modules/_mutation-types'

const getDefaultState = () => {
	return {
		smsSent: [],
		messageBirdBalance: 0,
		messageBirdMMSBalance: 0,
		messageBirdEnabled: false,
		statusPostSMS: 'init',
		statusPostTestSMS: 'init',
		testDialogVisible: false,
		recapDialogVisible: false,
		isReadyToBeSent: false,
		isSendingDialogVisible: false,
		messagingProfiles: null,
	}
}

const state = getDefaultState

const mutations = {
	[SET_SMS_SENT]: (state, payload) => {
		state.smsSent = payload
	},

	[SET_NEW_SMS_SENT]: (state, payload) => {
		state.smsSent.push(payload)
	},

	[SET_MESSAGE_BIRD_BALANCE]: (state, payload) => {
		const { balance, balance_mms } = payload
		state.messageBirdBalance = balance
		state.messageBirdMMSBalance = balance_mms
	},

	[SET_MESSAGE_BIRD_STATUS]: (state, payload) => {
		state.messageBirdEnabled = payload
	},

	[SET_STATUS_POST_SMS]: (state, payload) => {
		state.statusPostSMS = payload
	},

	[SET_STATUS_POST_TEST_SMS]: (state, payload) => {
		state.statusPostTestSMS = payload
	},

	[RESET_SMS_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},

	[SET_TEST_DIALOG_VISIBLE]: (state, payload) => {
		state.testDialogVisible = payload
	},

	[SET_RECAP_DIALOG_VISIBLE]: (state, payload) => {
		state.recapDialogVisible = payload
	},

	[SET_IS_READY_TO_BE_SENT]: (state, payload) => {
		state.isReadyToBeSent = payload
	},

	[SET_IS_SENDING_DIALOG_VISIBLE]: (state, payload) => {
		state.isSendingDialogVisible = payload
	},

	[SET_MESSAGING_PROFILES]: (state, payload) => {
		if (payload?.Profiles) state.messagingProfiles = payload.Profiles
	},
}

const actions = {
	actionSendSms: ({ commit }, payload) => {
		commit(SET_STATUS_POST_SMS, 'loading')
		communication_store
			.sendSms(payload)
			.then((res) => {
				commit(SET_STATUS_POST_SMS, 'success')
				commit(SET_NEW_SMS_SENT, res)
			})
			.catch(() => {
				commit(SET_STATUS_POST_SMS, 'error')
			})
	},

	actionSendTestSms: async ({ commit }, payload) => {
		try {
			await communication_store.sendTestSms(payload)
			commit(SET_STATUS_POST_TEST_SMS, 'success')
		}
		catch (error) {
			commit(SET_STATUS_POST_TEST_SMS, 'error')
		}
		setTimeout(() => {
			commit(SET_STATUS_POST_TEST_SMS, 'init')
		}, 500)
	},

	/**
	 * Refactor this function to avoid connection directly with
	 * the component
	 */
	actionGetSmsAnalytics: () => {
		return communication_store
			.getUsageSmsDb()
			.then((res) => {
				return res
				// commit('SET_SMS_SENT', res)
			})
			.catch((error) => error)
	},
	async actionGetMessageBirdBalance({ commit }) {
		const messageBalance = await communication_store.actionGetMessageBirdBalance()
		try {
			commit(SET_MESSAGE_BIRD_BALANCE, messageBalance)
		}
		catch (e) {
			console.error(e)
		}
	},
	async actionCheckIfMessageBirdEnabled({ commit, rootState }) {
		const isMessageBirdEnabled = get(rootState, 'info_campagne.sms_authtoken')
		commit(SET_MESSAGE_BIRD_STATUS, isMessageBirdEnabled === 'IsSet')
	},

	actionResetSMState: async ({ commit }) => {
		commit(RESET_SMS_STORE)
	},

	actionSetTestDialogVisible: ({ commit }, payload) => {
		commit(SET_TEST_DIALOG_VISIBLE, payload)
	},

	actionSetRecapDialogVisible: ({ commit }, payload) => {
		commit(SET_RECAP_DIALOG_VISIBLE, payload)
	},

	actionSetIsReadyToBeSent: ({ commit }, payload) => {
		commit(SET_IS_READY_TO_BE_SENT, payload)
	},

	actionSetIsSendingDialogVisible: ({ commit }, payload) => {
		commit(SET_IS_SENDING_DIALOG_VISIBLE, payload)
	},

	actionGetMessagingProfiles: async ({ commit }) => {
		communication_store.getMessagingProfiles()
			.then((messagingProfiles) => {
				commit(SET_MESSAGING_PROFILES, messagingProfiles)
			})
			.catch((error) => {
				console.error(error)
			})
	},
}

const getters = {
	getSmsSent: (state) => state.smsSent,
	getMessageBirdBalance: (state) => state.messageBirdBalance,
	getMessageBirdMMSBalance: (state) => state.messageBirdMMSBalance,
	getIsMessageBirdEnabled: (state) => state.messageBirdEnabled,
	getStatusPostSMS: (state) => state.statusPostSMS,
	getStatusPostTestSms: (state) => state.statusPostTestSMS,
	getTestDialogVisible: (state) => state.testDialogVisible,
	getRecapDialogVisible: (state) => state.recapDialogVisible,
	getIsReadyToBeSent: (state) => state.isReadyToBeSent,
	getIsSendingDialogVisible: (state) => state.isSendingDialogVisible,
	getMessagingProfiles: (state) => state.messagingProfiles,
}

export const smsModule = {
	state,
	mutations,
	getters,
	actions,
}
