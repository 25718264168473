import { mapGetters } from 'vuex'
/**
 * @mixin ContactBadges
 * @desc group functions that tells the lead status of a contact by its formdatas
 */
export default {
	computed: {
		...mapGetters('@form', { status: 'status' }),

		/**
		 * @computed {Boolean} ContactBadges~support
		 * @readonly
		 */
		support() {
			return this.isSupport(this.activeContact)
		},

		/**
		 * @computed {Boolean} ContactBadges~avoid
		 * @readonly
		 */
		avoid() {
			return this.toAvoid(this.activeContact)
		},

		/**
		 * @computed {Boolean} ContactBadges~prospect
		 * @readonly
		 */
		prospect() {
			return this.isProspect(this.activeContact)
		},

		/**
		 * @computed {Boolean} ContactBadges~uncertain
		 * @readonly
		 */
		uncertain() {
			return this.isUncertain(this.activeContact)
		},

		/**
		 * @computed {Boolean} ContactBadges~member
		 * @readonly
		 */
		member() {
			return this.isMember(this.activeContact)
		},

		/**
		 * @computed {Boolean} ContactBadges~hasNoAddress
		 * @readonly
		 */
		hasNoAddress() {
			return this.isInNorthPole(this.activeContact)
		},
	},

	methods: {
		/**
		 * @method ContactBadges#getBooleanOutOfSupportFormdatas
		 * @private
		 * @param {Contact} contact - the contact to perform request on
		 * @param {Array} [contact.formdatas] - the answers to all forms
		 * @param {String} refType - the refvalue.type we are looking for
		 * @param {String} [questionType=radio] - the question.type we are looking for
		 * @description will extract from contact.formdatas the according refType and questionType
		 *
		 * @returns {Boolean} true if a matching formdata exist, false otherwise
		 */
		getBooleanOutOfSupportFormdatas(contact, refType, questionType = 'radio') {
			if (Array.isArray(this.status) && this.status) {
				if (contact.formdatas && this.status.length) {
					const supportQuestion = this.status.find((question) => question.type === questionType)
					if (supportQuestion) {
						const supportAnswer = supportQuestion.refvalues.filter((ref) => ref.type === refType)
						const supportIds = supportAnswer.map((answer) => answer.id)
						const formdata = contact.formdatas.find(
							(formdata) => ~supportIds.indexOf(formdata.form_ref_id),
						)
						return !!formdata
					}
				}
			}
			return false
		},

		/**
		 * @method ContactBadges#isASupport
		 * @param {Contact} contact - the contact to perform request on
		 *
		 * @returns {Boolean} true if it is a support, false otherwise
		 */
		isSupport(contact) {
			return (
				this.getBooleanOutOfSupportFormdatas(contact, 'support')
				|| this.getBooleanOutOfSupportFormdatas(contact, 'enforceSupport')
			)
		},

		/**
		 * @method ContactBadges#toAvoid
		 * @param {Contact} contact - the contact to perform request on
		 *
		 * @returns {Boolean}
		 */
		toAvoid(contact) {
			return this.getBooleanOutOfSupportFormdatas(contact, 'avoid')
		},

		/**
		 * @method ContactBadges#isProspect
		 * @param {Contact} contact - the contact to perform request on
		 *
		 * @returns {Boolean}
		 */
		isProspect(contact) {
			return this.getBooleanOutOfSupportFormdatas(contact, 'prospect')
		},

		/**
		 * @method ContactBadges#isUncertain
		 * @param {Contact} contact - the contact to perform request on
		 *
		 * @returns {Boolean}
		 */
		isUncertain(contact) {
			return this.getBooleanOutOfSupportFormdatas(contact, 'uncertain')
		},

		/**
		 * @method ContactBadges#IsMember
		 * @param {Contact} contact - the contact to perform request on
		 *
		 * @returns {Boolean}
		 */
		isMember(contact) {
			return this.getBooleanOutOfSupportFormdatas(contact, 'member')
		},

		/**
		 * @method ContactBadges#isInNorthPole
		 * @param {Contact} contact - the contact to perform request on
		 * @param {Object} [contact.address] - the address of the contact
		 * @description will extract from contact.address the latitude and the longitude
		 * If match values, it is in North Pole and so has no location
		 *
		 * @returns {Boolean}
		 */
		isInNorthPole(contact) {
			return contact.address.latitude === '89.900000' && contact.address.longitude === '0.100000'
		},
	},
}
