import Actions from '../components/action-module/actions.vue'
import { controllerPackRedirection } from './utils/routerController'
import CTARoutes from './_callToActionRoutes'
import store from './../store/stateStore'

export default {
	meta: { title: 'MENU.ACTIONS' },
	path: 'actions',
	name: 'actions',
	redirect: { name: 'actions-list' },
	component: Actions,
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('action:write')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessActions', 'promoted-actions', ['enabled'])
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			name: 'actions-list',
			path: 'list',
			component: () => import('components/action-module/actions/actionList.vue'),
			props: true,
		},
		{
			name: 'action-details',
			path: ':actionId',
			component: () => import('components/action-module/actions/action-detail/ActionDetailContainer.vue'),
			redirect: { name: 'action-details-informations' },
			props: true,
			children: [
				{
					name: 'action-details-informations',
					path: 'informations',
					component: () => import('components/action-module/actions/action-detail/ActionDetail.vue'),
					props: true,
				},
				{
					name: 'action-details-stats',
					path: 'statistics',
					component: () => import('components/action-module/actions/action-detail/ActionDetailStats.vue'),
					props: true,
				},
			],
		},
		...CTARoutes('/actions', 'actions'),
	],
}
