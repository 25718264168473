<template>
	<div class="flex flex-col gap-4">
		<div class="top-layout-container">
			<div class="left">
				<div class="horizontal-rectangle bg-white rounded-3xl px-7 flex justify-between relative">
					<div class="flex gap-8 items-center">
						<div class="flex items-center justify-center">
							<svg
								width="68"
								height="69"
								viewBox="0 0 68 69"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									y="0.5"
									width="68"
									height="68"
									rx="34"
									fill="#EBEDFD"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M22.0002 42.4993V29.358L33.3522 35.6647C33.5548 35.7767 33.7775 35.8327 34.0002 35.8327C34.2228 35.8327 34.4455 35.7767 34.6482 35.6647L46.0002 29.358V42.4993H22.0002ZM43.2548 27.8327L34.0002 32.974L24.7455 27.8327H43.2548ZM46.0002 25.166H22.0002C20.5282 25.166 19.3335 26.3607 19.3335 27.8327V42.4993C19.3335 43.9713 20.5282 45.166 22.0002 45.166H46.0002C47.4722 45.166 48.6668 43.9713 48.6668 42.4993V27.8327C48.6668 26.3607 47.4722 25.166 46.0002 25.166Z"
									fill="#222230"
								/>
							</svg>
						</div>
						<div
							v-if="computedIsElasticEmail"
							class="flex flex-col justify-start items-start gap-1"
						>
							<h1 class="font-title-bold text-4xl">
								{{ computedGetEmailsBalance?.credits || 0 }}
							</h1>
							<span class="font-sans text-gray-strong text-sm">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.SUBTITLE')
							}}</span>
							<span
								v-if="userConnected?.role_data?.access?.includes('settings.billing:delete')"
								class="text-pink-strong text-sm cursor-pointer transform transition-transform hover:scale-105"
								@click="router.push({ name: 'invoices-params' })"
							>{{
								t(
									'COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.ADJUST_PLAN'
								)
							}}
							</span>
						</div>

						<div
							v-else-if="computedIsBrevo"
							class="flex flex-col justify-start items-start gap-1"
						>
							<h1 class="font-title-bold text-4xl">
								{{ computedBrevoBalance || 0 }}
							</h1>
							<span class="font-sans text-gray-strong text-sm">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.SUBTITLE')
							}}</span>
							<a
								target="_blank"
								rel="noopener noreferrer"
								class="text-pink-strong text-sm cursor-pointer transform transition-transform hover:scale-105"
								href="https://app.brevo.com/billing/account/plans/subscriptions"
							>Add more credits
							</a>
							<span class="text-gray-strong text-sm italic">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.BREVO_ACTIVATED.SUBTITLE')
							}}</span>
						</div>
						<div
							v-else
							class="flex flex-col justify-start items-start gap-1"
						>
							<span class="font-title text-lg">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.MAILJET.TITLE')
							}}</span>
						</div>
					</div>
					<div
						v-if="computedIsElasticEmail"
						class="flex items-center justify-center"
					>
						<el-tooltip
							v-if="computedIsMessageBirdEnabled"
							:disabled="methodIsAccessGranted('communication.email:delete')"
							placement="top"
							:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
						>
							<q-button
								id="demo-tour-send-campaign"
								class="basic-black"
								:disabled="!methodIsAccessGranted('communication.email:delete')"
								@click="
									() => {
										methodGoToContactBase()
									}
								"
							>
								{{
									t(
										'COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.SEND_CAMPAIGN'
									)
								}}
							</q-button>
						</el-tooltip>
					</div>
					<div
						v-if="!computedIsElasticEmail && !computedIsBrevo"
						class="flex items-center justify-center"
					>
						<el-tooltip
							:disabled="userConnected?.role_data?.access?.includes('settings:delete')"
							placement="top"
							:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
						>
							<q-button
								:disabled="!userConnected?.role_data?.access?.includes('settings:delete')"
								class="basic-black"
								@click="openChat"
							>
								{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.MAILJET.CTA') }}
							</q-button>
						</el-tooltip>
					</div>
					<el-tooltip
						v-if="computedIsElasticEmail"
						:disabled="userConnected?.role_data?.access?.includes('settings:delete')"
						placement="top"
						:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
					>
						<div
							class="px-3 py-1 absolute top-2 right-2"
							:class="
								userConnected?.role_data?.access?.includes('settings:delete')
									? 'little-gray-cta'
									: 'little-gray-cta-disabled'
							"
							@click="
								userConnected?.role_data?.access?.includes('settings:delete')
									? methodGoTo({ name: 'bulk-emails' })
									: ''
							"
						>
							<span>{{
								t(
									'COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.MANAGE_SENDERS'
								)
							}}</span>
							<i class="gui-settings" />
						</div>
					</el-tooltip>
					<div
						v-else-if="computedIsBrevo"
						placement="top"
					>
						<a
							class="px-3 py-1 absolute top-2 right-2 little-gray-cta"
							href="https://app.brevo.com/senders/list"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span>{{
								t(
									'COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_CARD.ELASTIC_EMAIL_ACTIVATED.MANAGE_SENDERS'
								)
							}}</span>
							<i class="gui-settings" />
						</a>
					</div>
				</div>
				<div class="horizontal-rectangle bg-white rounded-3xl px-7 flex justify-between relative">
					<div class="flex gap-8 items-center">
						<div class="flex items-center justify-center">
							<svg
								width="68"
								height="69"
								viewBox="0 0 68 69"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									y="0.5"
									width="68"
									height="68"
									rx="34"
									fill="#EBEDFD"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M26.9998 46.7228H40.9998V22.2783H26.9998V46.7228ZM40.9998 19.834H26.9998C25.7118 19.834 24.6665 20.9291 24.6665 22.2784V46.7229C24.6665 48.0722 25.7118 49.1673 26.9998 49.1673H40.9998C42.2878 49.1673 43.3332 48.0722 43.3332 46.7229V22.2784C43.3332 20.9291 42.2878 19.834 40.9998 19.834ZM29.9998 25.1673C29.9998 24.4309 30.5968 23.834 31.3332 23.834H36.6665C37.4029 23.834 37.9998 24.4309 37.9998 25.1673C37.9998 25.9037 37.4029 26.5007 36.6665 26.5007H31.3332C30.5968 26.5007 29.9998 25.9037 29.9998 25.1673ZM33.9998 42.5007C33.2635 42.5007 32.6665 43.0976 32.6665 43.834C32.6665 44.5704 33.2635 45.1673 33.9998 45.1673C34.7362 45.1673 35.3332 44.5704 35.3332 43.834C35.3332 43.0976 34.7362 42.5007 33.9998 42.5007Z"
									fill="#222230"
								/>
							</svg>
						</div>
						<div class="flex flex-col justify-start items-start gap-1">
							<h1 class="font-title-bold text-4xl">
								{{ computedMessageBirdBalance }}
							</h1>
							<span class="font-sans text-gray-strong text-sm">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.SUBTITLE')
							}}</span>
							<span
								v-if="
									computedIsMessageBirdEnabled &&
										userConnected?.role_data?.access?.includes('settings.billing:delete')
								"
								class="text-pink-strong text-sm cursor-pointer transform transition-transform hover:scale-105"
								@click="
									openChatWithPrePopulatedMessage(computedGetIntercomPrePopulatedMessage, false)
								"
							>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.ADJUST_PLAN') }}</span>
							<span
								v-else-if="!computedIsMessageBirdEnabled"
								class="text-pink-strong text-sm"
							><a
								target="_blank"
								href="https://help.qomon.com/fr/articles/3532292-activer-les-sms-sur-mon-espace-qomon"
							>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.HELP') }}</a></span>
						</div>
					</div>
					<div class="flex items-center justify-center">
						<el-tooltip
							v-if="computedIsMessageBirdEnabled"
							:disabled="methodIsAccessGranted('communication.sms:delete')"
							placement="top"
							:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
						>
							<q-button
								:disabled="!methodIsAccessGranted('communication.sms:delete')"
								class="basic-black"
								@click="methodGoTo({ name: 'contacts', query: { phone: 'ONLYMOBILE' } })"
							>
								{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.SEND_SMS') }}
							</q-button>
						</el-tooltip>
						<el-tooltip
							v-else
							:disabled="userConnected?.role_data?.access?.includes('settings:delete')"
							placement="top"
							:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
						>
							<q-button
								:disabled="!userConnected?.role_data?.access?.includes('settings:delete')"
								@click="methodEnableSms"
							>
								{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.ACTIVATE_SMS') }}
							</q-button>
						</el-tooltip>
					</div>
					<el-tooltip
						v-if="computedIsMessageBirdEnabled"
						:disabled="userConnected?.role_data?.access?.includes('settings:delete')"
						placement="top"
						:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
					>
						<div
							class="px-3 py-1 absolute top-2 right-2"
							:class="
								userConnected?.role_data?.access?.includes('settings:delete')
									? 'little-gray-cta'
									: 'little-gray-cta-disabled'
							"
							@click="
								userConnected?.role_data?.access?.includes('settings:delete')
									? methodGoTo({ name: 'sms' })
									: ''
							"
						>
							<span>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.SMS_CARD.SMS_SETTINGS') }}</span>
							<i class="gui-settings" />
						</div>
					</el-tooltip>
				</div>
			</div>
			<div class="right">
				<div class="vertical-rectangles">
					<div
						class="vertical-rectangle overflow-hidden bg-white rounded-3xl py-5 flex flex-col items-center justify-between text-center relative"
					>
						<svg
							:class="{ 'ringing-bell': router.currentRoute.value.query?.fromNotification }"
							width="68"
							height="68"
							viewBox="0 0 68 68"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="68"
								height="68"
								rx="34"
								fill="#E1F6EB"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M42.2183 33.3359L39.8094 31.1612C39.1519 30.5672 38.6257 29.8344 38.271 29.0156L36.3943 25.18C34.9554 22.5453 32.3251 20.9352 29.5194 20.7677C29.5051 20.6266 29.4878 20.4849 29.4502 20.3445C29.0689 18.9214 27.6395 18.0689 26.2576 18.4392C24.8757 18.8095 24.0641 20.2624 24.4454 21.6855C24.483 21.8259 24.5389 21.9573 24.5971 22.0866C22.2511 23.6345 20.7795 26.3437 20.8507 29.3449L21.1419 33.6054C21.2442 34.4918 21.1561 35.3891 20.8837 36.2324L19.8849 39.3201C19.2346 41.3289 21.0418 43.3031 23.0406 42.7675L27.2984 41.6266C28.4513 43.6933 30.8348 44.8201 33.1595 44.1972C35.4841 43.5743 36.9849 41.4067 36.9512 39.0401L41.2078 37.8996C43.2079 37.3637 43.7846 34.7507 42.2183 33.3359ZM23.3477 37.103L22.3488 40.1907L40.5176 35.3238L38.1109 33.1472C37.1733 32.301 36.4089 31.2359 35.9024 30.0672L34.0799 26.3564C32.767 24.0579 30.1352 22.9217 27.638 23.5908C25.1407 24.2599 23.4297 26.5598 23.4432 29.2065L23.7202 33.3314C23.8647 34.5971 23.7366 35.9014 23.3477 37.103ZM29.9708 40.9105C30.6101 41.5605 31.5471 41.8685 32.4693 41.6214C33.3914 41.3743 34.0501 40.6388 34.2788 39.7562L29.9708 40.9105ZM25.8594 44.9357C25.192 44.6245 24.3987 44.9132 24.0875 45.5806L23.524 46.789C23.2128 47.4564 23.5016 48.2497 24.169 48.5609C24.8363 48.8721 25.6297 48.5834 25.9409 47.916L26.5044 46.7076C26.8156 46.0402 26.5268 45.2469 25.8594 44.9357ZM40.2765 43.4791C39.7558 42.9584 39.7558 42.1142 40.2765 41.5935C40.7972 41.0728 41.6414 41.0728 42.1621 41.5935L43.1049 42.5363C43.6256 43.057 43.6256 43.9012 43.1049 44.4219C42.5842 44.9426 41.74 44.9426 41.2193 44.4219L40.2765 43.4791ZM34.3041 45.1336C33.5928 45.3242 33.1707 46.0553 33.3613 46.7666L33.7064 48.0545C33.897 48.7658 34.6281 49.1879 35.3394 48.9973C36.0506 48.8067 36.4728 48.0756 36.2822 47.3643L35.9371 46.0764C35.7465 45.3651 35.0154 44.943 34.3041 45.1336Z"
								fill="#222230"
							/>
							<ellipse
								cx="44.6667"
								cy="22.6667"
								rx="4.66667"
								ry="4.66667"
								fill="#05B66F"
							/>
						</svg>
						<div class="flex flex-col justify-center items-center gap-4 px-2">
							<span class="font-title text-2xl">
								{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.NOTIFICATION_CARD.TITLE') }}</span>
							<span>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.NOTIFICATION_CARD.SUBTITLE') }}</span>
						</div>

						<q-button
							:class="{ 'opacity-0 pointer-events-none': !computedIsGlobalNotificationEnabled }"
							class="basic-black"
							@click="router.push({ name: 'global-notification' })"
						>
							{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.NOTIFICATION_CARD.CTA') }}
						</q-button>
						<a
							v-if="!computedIsGlobalNotificationEnabled"
							target="_blank"
							href="https://help.qomon.com/fr/articles/5290963-comment-animer-sa-communaute-a-l-aide-des-notifications"
							class="absolute top-0 left-0 pb-5 w-full h-full bg-white flex flex-col justify-between bg-opacity-50 cursor-pointer"
							@click="analytics.track(TRACKING.notification_explored)"
						>
							<div class="flex items-center justify-end pr-6 pt-6">
								<div class="w-8 h-7 rounded-lg bg-yellow-300 flex items-center justify-center">
									<i class="gui-stars text-xl" />
								</div>
							</div>
							<div class="flex flex-col items-center justify-center">
								<i class="gui-lock text-5xl" />
								<span class="text-pink-strong text-sm">{{
									t('COMMUNICATIONS.NEW_GENERAL_VIEW.NOTIFICATION_CARD.HELP')
								}}</span>
							</div>
						</a>
					</div>
					<div
						class="vertical-rectangle bg-white rounded-3xl py-5 flex flex-col items-center text-center gap-7 relative"
					>
						<svg
							width="68"
							height="68"
							viewBox="0 0 68 68"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="68"
								height="68"
								rx="34"
								fill="#E5F8FF"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M30.684 35.1647L20.6667 28.858V41.9993H42V44.666H20.6667C19.1933 44.666 18 43.4713 18 41.9993V27.3327C18 25.8607 19.1933 24.666 20.6667 24.666H42C43.472 24.666 44.6667 25.8607 44.6667 27.3327L42 28.858L31.9813 35.1647C31.7787 35.2767 31.5573 35.3327 31.3333 35.3327C31.1093 35.3327 30.888 35.2767 30.684 35.1647ZM31.3333 32.474L39.2533 27.3327H23.412L31.3333 32.474Z"
								fill="#222230"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M36.916 35.8903L42.1627 40.4903C42.624 40.8943 43.332 40.557 43.332 39.9317L43.332 36.6663L50 36.6663L50 33.9997L43.332 33.9997L43.332 30.733C43.332 30.109 42.624 29.7703 42.1627 30.1743L36.916 34.7743C36.5827 35.0663 36.5827 35.5983 36.916 35.8903Z"
								fill="#05B66F"
							/>
						</svg>

						<div class="flex flex-col justify-center items-center gap-4">
							<span class="font-title text-2xl">
								{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.INBOX_MAIL_CARD.TITLE') }}
							</span>
							<span class="w-10/12">{{
								t('COMMUNICATIONS.NEW_GENERAL_VIEW.INBOX_MAIL_CARD.SUBTITLE')
							}}</span>
						</div>
						<div class="flex items-center justify-center gap-2">
							<q-button
								v-if="!computedIsAtLeastOneBoxConnected"
								class="basic-black"
								@click="methodGoTo({ name: 'profile-emails' })"
							>
								{{
									t('COMMUNICATIONS.NEW_GENERAL_VIEW.INBOX_MAIL_CARD.CTA_UNACTIVATED')
								}}
							</q-button>
							<template v-else>
								<i class="gui-yes text-2xl" />
								<span class="text-green-medium">{{
									t('COMMUNICATIONS.NEW_GENERAL_VIEW.INBOX_MAIL_CARD.CTA_ACTIVATED')
								}}</span>
							</template>
						</div>
						<div
							v-if="computedIsAtLeastOneBoxConnected"
							class="little-gray-cta absolute top-2 right-2 p-2"
							@click="methodGoTo({ name: 'profile-emails' })"
						>
							<i class="gui-settings" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="emails-auto-settings bg-white rounded-3xl px-10 py-6 flex items-center justify-between relative"
		>
			<div
				:class="{ 'pl-7': !computedGetIsAutomaticEmailsEnabled }"
				class="flex flex-col items-start justify-start"
			>
				<h1 class="font-title text-2xl">
					{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_AUTO_CARD.TITLE') }}
				</h1>
				<p class="emails-auto-settings-paragraph w-10/12">
					{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_AUTO_CARD.SUBTITLE') }}
				</p>
			</div>
			<div
				class="flex justify-end"
				style="min-width: 230px"
			>
				<el-tooltip
					v-if="computedGetIsAutomaticEmailsEnabled"
					:disabled="userConnected?.role_data?.access?.includes('settings:delete')"
					placement="top"
					:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
				>
					<div
						class="px-3 py-1"
						:class="
							userConnected?.role_data?.access?.includes('settings:delete')
								? 'little-gray-cta'
								: 'little-gray-cta-disabled'
						"
						@click="
							userConnected?.role_data?.access?.includes('settings:delete')
								? methodGoTo({ name: 'autosend-settings' })
								: ''
						"
					>
						<span>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_AUTO_CARD.SETTINGS') }} </span>
						<i class="gui-settings" />
					</div>
				</el-tooltip>
			</div>
			<div
				v-if="!computedGetIsAutomaticEmailsEnabled"
				class="absolute rounded-3xl top-0 left-0 pl-3 pt-3 pb-5 w-full h-full bg-white flex justify-between bg-opacity-50 cursor-pointer"
				@click="methodRedirectToEmailsAutoHelpCenter"
			>
				<div class="w-8 h-7 rounded-lg bg-yellow-300 flex items-center justify-center">
					<i class="gui-stars text-xl" />
				</div>
				<div class="flex items-center justify-center pr-20 gap-5">
					<i class="gui-lock text-5xl" />
					<a
						class="text-pink-strong relative -mb-2"
						target="_blank"
						href="https://help.qomon.com/fr/articles/3451811-parametrer-un-email-automatique"
					>{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.EMAIL_AUTO_CARD.HELP') }}</a>
				</div>
			</div>
		</div>
		<div class="bg-white rounded-3xl px-10 py-10 flex items-center justify-between">
			<div class="flex flex-col items-start justify-start gap-10 flex-1">
				<span class="font-title text-4xl">
					{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.CONNECT_CARD.TITLE') }}
				</span>
				<el-tooltip
					:disabled="userConnected?.role_data?.access?.includes('settings.connect:delete')"
					placement="top"
					:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
				>
					<q-button
						:disabled="!userConnected?.role_data?.access?.includes('settings.connect:delete')"
						class="basic-black"
						@click="router.push({ name: 'connect' })"
					>
						{{ t('COMMUNICATIONS.NEW_GENERAL_VIEW.CONNECT_CARD.CTA') }}
					</q-button>
				</el-tooltip>
			</div>
			<img
				style="width: 35%; max-width: 500px"
				src="/static/images/vendors/extensionsPreview.png"
				alt=""
			>
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, onMounted, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { openChat, openChatWithPrePopulatedMessage } from '../../extensions/support'
import { methodIsAccessGranted } from '@/utils/RoleUtils'
import analytics, { TRACKING } from '../../external/analytics'

const store = useStore()
const { t } = useI18n()
const router = useRouter()
/// GETTERS
const computedMessageBirdBalance = computed(() => store.getters['getMessageBirdBalance'])
const computedIsMessageBirdEnabled = computed(() => store.getters['getIsMessageBirdEnabled'])
const computedIsGlobalNotificationEnabled = computed(
	() => store.getters['@packs/getAccessStatusGlobalNotification'] === 'enabled',
)
const computedGetIsAutomaticEmailsEnabled = computed(
	() => store.getters['@packs/getAccessStatusSurveyAutoEmail'] === 'enabled',
)
const computedIsAtLeastOneBoxConnected = computed(() => store.getters['@user/isEmailBoxConnected'])
const computedIsElasticEmail = computed(() => store.getters['@group/getEmailerAPIKey'] === 'QOMON')
const computedIsBrevo = computed(() => store.getters['isBulkedEmailsActivated'])
const computedGetEmailsBalance = computed(() => store.getters['@emailer/getEmailerAccount'])
const computedBrevoBalance = computed(() => store.getters['@emailer/getEmailsBalance'])
const userConnected = computed(() => store.getters['userConnected'])

/// ACTIONS
const actionCheckIfMessageBirdEnabled = () => store.dispatch('actionCheckIfMessageBirdEnabled')
const actionGetMessageBirdBalance = () => store.dispatch('actionGetMessageBirdBalance')
const actionSetEmailerAccountPricesAndSubscriptionData = () =>
	store.dispatch('@emailer/actionSetEmailerAccountPricesAndSubscriptionData')
const actionGetEmailsBalance = () =>
	store.dispatch('@emailer/actionGetEmailsBalance')

/// COMPUTED

const computedGetIntercomPrePopulatedMessage = computed(() => {
	return computedIsMessageBirdEnabled.value
		? t('SETTINGS.SMS.INTERCOM_MESSAGES.ENABLE_SMS')
		: t('SETTINGS.SMS.INTERCOM_MESSAGES.TOP_UP_SMS')
})

/// METHODS
methodIsAccessGranted
const methodRedirectToEmailsAutoHelpCenter = () => {
	analytics.track(TRACKING.email_explored)
	window.open(
		'https://help.qomon.com/fr/articles/3451811-parametrer-un-email-automatique',
		'_blank',
	)
}

const methodGoTo = (route) => {
	const { name, query } = route
	router.push({ name, query })
}

const methodGoToContactBase = () => {
	const route = {
		name: 'contacts',
		query: { mail: 'SET' },
	}
	const hasDemoTourAlreadyHappened = window.localStorage.getItem('is-demo-tour-activated')
	if (!hasDemoTourAlreadyHappened) route.query['demo_tour'] = 'bulk-emails'
	methodGoTo(route)
}

const methodEnableSms = () => {
	analytics.track(TRACKING.sms_enabled)
	openChatWithPrePopulatedMessage(computedGetIntercomPrePopulatedMessage.value, false)
}

onMounted(async () => {
	await nextTick()
	actionCheckIfMessageBirdEnabled()
	if (computedIsMessageBirdEnabled.value) actionGetMessageBirdBalance()
	if (computedIsElasticEmail.value) {
		actionSetEmailerAccountPricesAndSubscriptionData()
	}
	actionGetEmailsBalance()
})
</script>

<style scoped>
.little-gray-cta {
	@apply bg-gray-50 flex items-center gap-2 text-black text-sm rounded-2xl w-fit cursor-pointer transition-all hover:bg-gray-200;
}

.little-gray-cta-disabled {
	@apply bg-gray-50 flex items-center gap-2 text-black text-sm rounded-2xl w-fit cursor-not-allowed;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.little-gray-cta:hover i {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

@media (min-width: 0) and (max-width: 470px) {
	.top-layout-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.horizontal-rectangle {
		height: 50%; /* Each horizontal rectangle is 50% of the left side's height */
		background-color: white; /* Add your preferred background color */
		padding: 30px;
		flex-direction: column;
		gap: 10px;
	}

	.left {
		width: 100%; /* Left side takes up 50% of the available space */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
	}

	.right {
		display: flex;
		width: 100%;
		gap: 16px;
	}

	.vertical-rectangles {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.vertical-rectangle {
		width: 100%;
	}

	.emails-auto-settings {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.emails-auto-settings-paragraph {
		width: 100%;
	}
}

@media (min-width: 471px) and (max-width: 590px) {
	.top-layout-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.horizontal-rectangle {
		height: 50%; /* Each horizontal rectangle is 50% of the left side's height */
		background-color: white; /* Add your preferred background color */
		padding: 30px;
		flex-direction: column;
		gap: 10px;
	}

	.left {
		width: 100%; /* Left side takes up 50% of the available space */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
	}

	.right {
		display: flex;
		width: 100%;
		gap: 16px;
	}

	.vertical-rectangles {
		display: flex;
		gap: 16px;
	}

	.vertical-rectangle {
		width: 50%;
	}

	.emails-auto-settings {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.emails-auto-settings-paragraph {
		width: 100%;
	}
}

@media (min-width: 591px) and (max-width: 1320px) {
	.top-layout-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.left {
		width: 100%; /* Left side takes up 50% of the available space */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
	}

	.horizontal-rectangle {
		height: 50%; /* Each horizontal rectangle is 50% of the left side's height */
		background-color: white; /* Add your preferred background color */
		padding: 30px;
	}

	.right {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.vertical-rectangles {
		display: flex;
		gap: 16px;
		justify-content: center;
	}
	.vertical-rectangle {
		background-color: white; /* Add your preferred background color */

		width: 50%;
	}
}

@media screen and (min-width: 1321px) {
	.top-layout-container {
		display: flex;
		gap: 16px;
	}

	.left {
		width: 100%; /* Left side takes up 50% of the available space */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
	}

	.horizontal-rectangle {
		height: 50%; /* Each horizontal rectangle is 50% of the left side's height */
		background-color: white; /* Add your preferred background color */
	}

	.right {
		flex: 1; /* Right side takes up 50% of the available space */
		display: flex;
		justify-content: space-between;
	}

	.vertical-rectangles {
		display: grid;
		grid-template-columns: repeat(2, 1fr); /* Create two columns for the vertical rectangles */
		grid-gap: 16px; /* Optional gap between vertical rectangles */
	}

	.vertical-rectangle {
		flex: 1; /* Each vertical rectangle takes up equal space */
		background-color: white; /* Add your preferred background color */
		width: 254px;
		max-width: 254px;
		min-width: 254px;
	}
}

.ringing-bell {
	animation: ring 0.5s ease-in-out;
	animation-iteration-count: 2;
}

@keyframes ring {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(30deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>
