<template>
	<div id="login-vue-recover">
		<div class="container">
			<div class="logo-container">
				<img src="/static/images/qomon/black.svg" alt="Qomon" />
			</div>
			<div class="bg-white p-8 rounded-xl border border-gray-100">
				<div class="flex flex-col items-center justify-center">
					<img v-if="computedGetImgApp" class="w-20 mb-4" :src="computedGetImgApp" />
					<div class="center px-8" v-html="$t('APP.LOGIN.ERRORS.NO_PERMISSION')"></div>
					<p class="app-logo-store mt-4 sm:hidden flex">
						<a target="_blank" :href="computedGetAndroidLink"
							><img src="/static/images/playstore.png" alt="Get it on Google Play" /></a
						><a target="_blank" :href="computedGetIOSLink"
							><img src="/static/images/appstore.png" alt="smartphone Qomon" />
						</a>
					</p>
					<div class="flex w-full flex-col justify-center items-center mt-12 mb-4 sm:flex hidden">
						<div class="flex flex-col items-center justify-center">
							<img class="w-40" :src="computedGetQRCodeLink" />
						</div>
						<div class="mt-2 center">
							<div class="text-xl bold">{{ $t('APP.DOWNLOAD.APP') }}</div>
							<div class="center px-8 mt-1">{{ $t('APP.ALL_SET') }}</div>
						</div>
					</div>
					<p style="text-align: center" class="mt-16">
						<router-link class="text-pink-main q-link" :to="{ name: 'login' }"
							>← {{ $t('APP.RECOVER.LOGIN') }}</router-link
						>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		computedGetIOSLink() {
			return (
				this.$t('APP.DOWNLOAD.IOS') ||
				'https://apps.apple.com/fr/app/qomon-causes-campagnes/id1186778175'
			)
		},

		computedGetAndroidLink() {
			return (
				this.$t('APP.DOWNLOAD.ANDROID') ||
				'https://play.google.com/store/apps/details?id=com.quorumapps.quorum128598'
			)
		},

		computedGetQRCodeLink() {
			if (XCUSTOM_USER_AGENT === 'kennedy') return '/static/logo/QRCodeKennedy.png'
			return '/static/images/qomon/download_qrcode_qomon_app.png'
		},

		computedGetImgApp() {
			if (XCUSTOM_USER_AGENT === 'kennedy') return '/static/logo/RFKActivate2024.svg'
			return ''
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/app/login-recover.scss';
.app-logo-store {
	display: flex;
	justify-content: center;
	a {
		width: auto;
		margin-right: $spacing-small;
		max-width: 150px;
		img {
			width: 100%;
		}
	}
}
</style>
