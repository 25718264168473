import { configure } from 'vee-validate'
import { defineRule } from 'vee-validate'
import { email, required } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { i18n } from '../middlewares/i18nizer'

// ? substring to prevent the loading of en_GB, which is obviously not available
import(`@vee-validate/i18n/dist/locale/${i18n.global.locale.substring(0, 2)}.json`).then(
	(locale) => {
		localize(i18n.global.locale, locale)
	},
)

defineRule('email', email)
defineRule('required', required)
configure({
	generateMessage: (context) => {
		if (context.rule.name == 'email') {
			return i18n.global.t('VEE_VALIDATE.EMAIL')
		}
		else if (context.rule.name == 'required') {
			return i18n.global.t('VEE_VALIDATE.REQUIRED')
		}
	},
})

defineRule('code_invitation', (value) => {
	const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\u00C0-\u017F]+)$/
	if (regex.test(value)) return true

	return i18n.global.t('VEE_VALIDATE.ENTITY_MUST_CONTAIN', {
		entity: i18n.global.t('VEE_VALIDATE.INVITATION_CODE_ENTITY'),
		// this is not really the correct wording but I do care of the input before submit
		correctFormat: i18n.global.t('APP.SETTINGS.PATTERN'),
	})
})

defineRule('confirmed', (value, [target]) => {
	if (value === target) return true
	return i18n.global.t('VEE_VALIDATE.CONFIRMED')
})

defineRule('is_not', (value, [target]) => {
	if (value !== target) return true
	return i18n.global.t('VEE_VALIDATE.IS_NOT')
})

defineRule('max', (value, [target]) => {
	if (value.length <= target) return true
	return i18n.global.t('VEE_VALIDATE.MAXIMUM', { amount: target })
})

defineRule('max_value', (value, [target]) => {
	if (value <= target || !value) return true
	return i18n.global.t('VEE_VALIDATE.MAX_VALUE', { amount: target })
})

defineRule('min', (value, [target]) => {
	if (value.length >= target) return true
	return i18n.global.t('VEE_VALIDATE.MINIMUM', { amount: target })
})

defineRule('phone_number', (value) => {
	if (!value || /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value)) return true
	return i18n.global.t('VEE_VALIDATE.PHONE_PATTERN')
})

defineRule('regex', (value, [target], ctx) => {
	if (!target.test(value)) return false

	if (ctx.name === 'originator') {
		return i18n.global.t('VEE_VALIDATE.WRONG_REGEX_FORMAT', {
			correctFormat: i18n.global.t('SMS.ORIGINATOR.PATTERN'),
		})
	}
	if (ctx.name === 'sms-body') {
		return i18n.global.t(`VEE_VALIDATE.BITLY_DETECTED`)
	}
	return 'Format invalid'
})

defineRule('strong_password', (value) => {
	// validates if at least: 1 capital letter, 1 lowercase, 1 number and 8 characters
	if (/^(?=.*[0-9])(?=.*[!?;,:ùé°¨^è@#$%^&*)(+=._-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g.test(value)) {
		return true
	}
	return i18n.global.t('VEE_VALIDATE.ENTITY_MUST_CONTAIN', {
		entity: i18n.global.t('APP.RECOVER.ENTITY'),
		correctFormat: i18n.global.t('APP.RECOVER.PATTERN'),
	})
})

defineRule('unique', (value, [target]) => {
	if (!target?.includes(value)) return true
	return i18n.global.t('VEE_VALIDATE.EXISTING_EMAIL')
})
