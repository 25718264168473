<template>
	<a
		@mouseover="isOptionsSlotsVisible = true"
		@mouseleave="isOptionsSlotsVisible = false"
		class="relative"
		:class="['q-card', color, active ? 'is-focused' : '', clickable ? 'clickable' : '', actionCard ? 'action-card' : '']"
	>
		<!-- Slots overlay -->
		<div
			v-if="$slots.overlay"
			:class="`q-card-overlay ${
				!clickableOverlay && overlayNotAvailable ? 'option-not-available' : ''
			}`"
		>
			<div class="content">
				<slot name="overlay" />
			</div>
		</div>
		<!-- Slots cover -->
		<div
			class="q-card-cover"
			@click="$emit('click', $event)"
			v-if="$slots.cover"
		>
			<div class="z-[9]">
				<slot name="cover" />
			</div>
			<div
				v-if="$slots.customBadge"
				class="custom-badge"
			>
				<slot name="customBadge" />
			</div>
		</div>
		<!-- Slots pin -->
		<div>
			<div
				v-if="pinable"
				:class="`q-card-pin q-control ${!$slots.customPin ? 'classic': ''} ${clickableOverlay ? 'clickable-overlay' : ''}`"
			>
				<template v-if="$slots.customPin">
					<slot
						name="customPin"
					/>
				</template>
				<input
					v-else
					ref="check"
					type="checkbox"
					class="light "
					:checked="active"
					@change="$emit('change', $refs.check.checked)"
					@click="$emit('pinned')"
				>
			</div>
			<div
				v-if="$slots.options && isOptionsSlotsVisible"
				@mouseover="isOptionsVisible = true"
				@mouseleave="isOptionsVisible = false"
				class="absolute z-30 top-2 right-2 flex flex-col items-end justify-end "
			>
				<i
					class="transition-all qi-more bg-white w-8 h-8 flex justify-center items-center text-lg font-black mb-1 rounded-full hover:text-pink-main"
				/>
				<Transition name="vertical-slide">
					<div
						v-if="isOptionsVisible"
						style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
						class="bg-white w-full rounded-lg p-2"
					>
						<div
							class="cursor-pointer"
						>
							<slot name="options" />
						</div>
					</div>
				</Transition>
			</div>
			<div
				v-if="$slots.customOptions"
				style=" transform: translateY(-10px) scale(0.9);transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);"
				class="customOptions opacity-0  absolute z-30 top-2.5 right-2.5 flex items-end justify-end gap-3"
			>
				<slot name="customOptions" />
			</div>
		</div>
		<!-- Slots options -->
		<!-- <div
			v-if="$slots.options"
			class="q-card-options"
		>
			<div :class="`q-card-o-icon flex-options qi-more ${whiteOptions ? 'white' : ''}`">
				<div class="q-card-o-content">
					<slot name="options" />
				</div>
			</div>
		</div> -->
		<!-- Slots content -->
		<div
			class="z-[15]"
			:class="`${cardFullHeight ? 'q-card-container height-full' : 'q-card-container'} ${
				!noFlex ? 'q-flex' : ''
			}`"
			@click="$emit('click', $event)"
		>
			<div
				v-if="$slots.header"
				class="q-card-top"
			>
				<div class="content z-[15]">
					<slot name="header" />
				</div>
			</div>
			<div
				v-if="$slots.content"
				:class="`q-card-content h-full ${expandContent ? 'q-container' : ''}`"
			>
				<slot name="content" />
			</div>
			<!-- Slots footer -->
			<div v-if="$slots.footer">
				<slot name="footer" />
			</div>
		</div>
	</a>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: 'white',
		},
		clickable: {
			type: Boolean,
			default: true,
		},
		clickableOverlay: {
			type: Boolean,
			default: false,
		},
		pinable: {
			type: Boolean,
			default: true,
		},
		active: {
			type: Boolean,
			default: false,
		},
		expandContent: {
			type: Boolean,
			default: false,
		},
		whiteOptions: {
			type: Boolean,
			default: false,
		},
		noFlex: {
			type: Boolean,
			default: false,
		},
		cardFullHeight: {
			type: Boolean,
			default: false,
		},
		overlayNotAvailable: {
			type: Boolean,
			default: false,
		},
		actionCard: {
			type: Boolean,
			default: false,
		},
		ongoing: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isOptionsVisible: false,
			isOptionsSlotsVisible: false,
		}
	},

	emits: ['change', 'pinned', 'click'],
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/animations.scss';
@import 'src/assets/scss/shared/shared-constants.scss';
.white {
	border-color: $white;
}
.black {
	border-color: $black-quorum;
}
.blue {
	border-color: $blue-quorum;
}
.green {
	border-color: $green-quorum;
}
.pink {
	border-color: $pink-quorum;
}
</style>
