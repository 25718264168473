<template>
	<!-- This is a formatted text input component and functions as an input (accepts a v-model) -->
	<!-- Props: -->
	<!--   value        - the variable containing the value of the input (required through v-model) -->
	<!--                  IMPORTANT NOTE: this should be passed as v-model="<variable_name>", NOT value="<variable_name>" or v-bind:value="<variable_name>" -->
	<!--   title        - the text displayed above the input field (optional).   -->
	<!--                  Note that if title and placeholder match, and the value is empty, then title will not be displayed (so the text is not duplicated) -->
	<!--   placeholder  - the placeholder of the input (optional) -->
	<!--   minimized    - a boolean value that attaches a minimize class to elements if true, allowing the parent component to control what is displayed -->
	<!--                  (default is to hide the title and underline) -->
	<!--   editable     - a boolean value that indicates whether the value is editable or static -->
	<!--   showLine     - a boolean value that indicates if the line should be visible; defaults to the editable prop -->
	<!-- Slots: -->
	<!--   line-content - optional content to appear on the same line as the input value (also above the underline); by default appears on the right -->
	<div class="text-input-container">
		<div class="text-input-title" :class="{ minimized: minimized }">{{ title }}</div>
		<div class="input-line-container">
			<input
				v-if="editable"
				class="text-input"
				type="text"
				:class="{ minimized: minimized }"
				:placeholder="placeholder"
				:value="value"
				@input="valueChange($event)"
				@focus="inputFocus()"
				@blur="inputBlur()"
				@keyup.enter="$event.currentTarget.blur()"
			/>
			<div v-else class="text-input" :class="{ minimized: minimized }">{{ value }}</div>
			<div class="line-content">
				<slot name="line-content"></slot>
			</div>
		</div>
		<div v-if="lineVisible" class="text-input-border" :class="{ minimized: minimized }"></div>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'TextInput',

	props: {
		value: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		minimized: {
			default: false,
			type: Boolean,
		},
		editable: {
			default: true,
			type: Boolean,
		},
		showLine: {
			default: null,
			type: Boolean,
		},
	},
	emits: ['update:value'],

	computed: {
		hasButtonsAndControls: function () {
			return this.$slots['controls-container'] && this.$slots['buttons-container']
		},

		lineVisible: function () {
			// is equal to showLine if given, otherwise defaults to this.editable
			return this.showLine != null ? this.showLine : this.editable
		},
	},

	mounted: function () {
		this.setTitleVisibility(this.value)
	},

	methods: {
		// Underline and select an input when it gains focus
		inputFocus: function () {
			$(this.$el).find('.text-input-border').addClass('active')
			$(this.$el).find('.text-input-title').addClass('active')
			$(this.$el).find('input.text-input').select()
		},

		// Fade underline when an input loses focus
		inputBlur: function () {
			$(this.$el).find('.text-input-border').removeClass('active')
			$(this.$el).find('.text-input-title').removeClass('active')
		},

		valueChange: function (event) {
			let value = event.target.value
			this.setTitleVisibility(value)
			this.$emit('update:value', value)
		},

		// sets whether the text-input-title is visible
		setTitleVisibility: function (value) {
			// don't show the title if the placeholder is showing and they are the same string
			if (!value && this.title == this.placeholder) {
				$($(this.$el).find('.text-input-title')[0]).addClass('invisible')
			} else {
				$($(this.$el).find('.text-input-title')[0]).removeClass('invisible')
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/general/text-input.scss';
</style>
