/**
 * Settings related routes
 *
 *
 * @rootPath {/parametre}
 */

import { controllerPackRedirection } from './utils/routerController'
import store from '../store/stateStore'
import Settings from 'components/settings-module/settings.vue'
import CampaignSettings from 'components/settings-module/campaign-settings.vue'
import Campaign from 'components/settings-module/campaign.vue'
import ContactsSettings from 'components/settings-module/contacts/contacts-settings.vue'
import InvoicesParams from 'components/settings-module/invoices/invoices-parameters.vue'
import AutosendSettings from 'components/settings-module/autosend-settings.vue'
import SmsSettings from 'components/communications-module/sms/sms-settings.vue'
import ContactFormSettings from 'components/settings-module/contacts/contact-form-settings.vue'
import Tags from 'components/settings-module/contacts/tags-settings.vue'
import MultiFormBuilder from 'components/settings-module/multi-form-builder.vue'
import FormBuilder from 'components/form-module/form-builder.vue'
import CommunicationSettings from 'components/settings-module/communication/communication-settings.vue'
import BulkedEmails from 'components/settings-module/bulkEmails.vue'
import RoleManagement from 'components/settings-module/role-management/roleManagement.vue'
import MobileAppSettings from '../components/settings-module/moblie-app/MobileAppSettings.vue'
import GeneralMobileAppSettings from '../components/settings-module/moblie-app/GeneralMobileAppSettings.vue'
import ActionMobileAppSettings from '../components/settings-module/moblie-app/ActionMobileAppSettings.vue'
import SegmentsMain from 'components/settings-module/segments/SegmentsMain.vue'

export default {
	meta: { title: 'MENU.SETTINGS' },
	path: '/settings',
	name: 'settings',
	redirect: { name: 'campaign-settings' },
	component: Settings,
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('settings:delete')) {
			next()
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: 'space-settings',
			name: 'campaign-settings',
			component: CampaignSettings,
			redirect: { name: 'campaign' },
			children: [
				{
					path: 'campaign',
					name: 'campaign',
					component: Campaign,
				},
				{
					path: 'role-management',
					name: 'role-management-settings',
					component: RoleManagement,
					beforeEnter: (to, from, next) => {
						// Role access check
						if (
							store.getters['userConnected']?.role_data?.access?.includes('settings.roles:delete')
						) {
							next()
						}
						else {
							next({ name: 'command-center', replace: true })
						}
					},
				},
				{
					path: 'segments',
					name: 'segments-main',
					component: SegmentsMain,
					beforeEnter(to, from, next) {
						controllerPackRedirection(next, 'statusWebAccessSegmentsLimitation')
					},
				},
				{
					path: 'params',
					name: 'invoices-params',
					component: InvoicesParams,
					beforeEnter: (to, from, next) => {
						// Role access check
						if (
							store.getters['userConnected']?.role_data?.access?.includes('settings.billing:delete')
						) {
							next()
						}
						else {
							next({ name: 'command-center', replace: true })
						}
					},
				},
				{
					path: 'privacy-policy',
					name: 'privacy-policy-settings',
					component: () => import('components/settings-module/privacy-policy/privacy-policy.vue'),
				},
			],
		},
		{
			path: 'contact',
			name: 'contact',
			component: ContactsSettings,
			redirect: { name: 'form-settings' },
			children: [
				{
					path: 'form',
					name: 'form-settings',
					component: ContactFormSettings,
				},
				{
					path: 'tags',
					name: 'tags-settings',
					component: Tags,
				},
				{
					path: 'lead-form',
					name: 'lead-form',
					component: MultiFormBuilder,
					beforeEnter(to, from, next) {
						if (to?.query?.q === 'ACTION') {
							controllerPackRedirection(next, 'statusWebAccessTasks')
						}
						else {
							next(true)
						}
					},
				},
			],
		},
		{
			path: 'mobile-app',
			name: 'mobile-app',
			component: MobileAppSettings,
			redirect: { name: 'general-mobile-app' },
			children: [
				{ path: 'general', name: 'general-mobile-app', component: GeneralMobileAppSettings },
				{ path: 'action', name: 'action-mobile-app', component: ActionMobileAppSettings },
			],
		},
		{
			path: 'communication',
			name: 'communication',
			component: CommunicationSettings,
			redirect: { name: 'bulk-emails' },
			children: [
				{
					path: 'sms',
					name: 'sms',
					component: SmsSettings,
				},
				{
					path: 'mailauto',
					name: 'autosend-settings',
					component: AutosendSettings,
					beforeEnter: (to, from, next) => {
						controllerPackRedirection(next, 'statusWebAccessSurveyAutoEmail')
					},
				},
				{
					path: 'bulk-emails',
					name: 'bulk-emails',
					component: BulkedEmails,
					// beforeEnter: (to, from, next) => {
					// 	controllerPackRedirection(next, 'statusWebAccessSurveyBulkedEmail')
					// },
				},
			],
		},
		{
			path: 'extensions',
			name: 'extensions',
			component: () => import('components/settings-module/extensions/extensions-header.vue'),
			redirect: { name: 'connect' },
			beforeEnter: (to, from, next) => {
				// Role access check
				if (
					store.getters['userConnected']?.role_data?.access?.includes('settings.connect:delete')
				) {
					next()
				}
				else {
					next({ name: 'command-center', replace: true })
				}
			},
			children: [
				{
					path: 'connect',
					name: 'connect',
					component: () => import('components/settings-module/extensions/extensions-connect.vue'),
				},
				{
					path: 'zapier-apps',
					name: 'zapier-apps',
					component: () => import('components/settings-module/extensions/zapier/zapier-apps.vue'),
				},
				{
					path: 'zapier-manager',
					name: 'zapier-manager',
					component: () =>
						import('components/settings-module/extensions/zapier/zapier-manager.vue'),
				},
			],
		},
		/**
		 * @deprecated
		 * @Alexy: elle n'est là que pour voir à quoi ça ressemblait ! Il faut remettre les fonctionnalité dans MultiFormBuilder
		 */

		{
			path: 'old-form',
			name: 'old-form',
			component: FormBuilder,
		},
	],
}
