<template>
	<div>
		<el-dialog
			id="dialogCustom"
			:model-value="computedDialogOrderCategoriesVisible"
			:title="$t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.TITLE')"
			class="custom-dialog-class pt-0-body pb-0-body dialog-drag-doc-category underline-header"
			:append-to-body="true"
			:show-close="false"
			@close="closeDialog"
		>
			<hr class="mb-4 mx-16" />
			<div class="">
				<Draggable
					v-model="localDocsCategories"
					:group="{ name: 'docCategory', put: true, pull: false }"
					item-key="id"
					:empty-insert-threshold="400"
					class="w-full flex flex-col py-8"
					@end="methodEndDrag"
				>
					<template #item="{ element, index }">
						<div
							id="aRow"
							class="list-group-item w-full flex doc-category-draggable"
							@mouseover="currentRowIndexHovered = index"
							@mouseleave="currentRowIndexHovered = null"
						>
							<draggable-row-container
								:border-default-class="'border-gray-200'"
								:active-row="currentRowIndexHovered === index"
								drag-trigger="w-0 relative absolute-icon mt-0"
								:custom-class-slot-container="'py-2 custom-class-docs-categories rounded-xl border border-gray-200 hover:border-pink-main my-1'"
							>
								<div class="px-2">
									<el-input
										v-model="element.label"
										placeholder=""
										class="link-prefix-icon"
										size="small"
										:maxlength="30"
									>
									</el-input>
								</div>
							</draggable-row-container>
						</div>
					</template>
				</Draggable>
			</div>

			<div class="flex justify-end custom_dialog items-center py-6 px-16">
				<p class="pr-4 cursor-pointer" @click="closeDialog">
					{{ $t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.CANCEL') }}
				</p>

				<el-tooltip
					class="box-item"
					effect="dark"
					:disabled="!computedDisabledConfirm"
					:content="
						$t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.DISABLED_CONFIRM.TOOLTIP.LABEL_EMPTY')
					"
					placement="top"
				>
					<a
						class="q-button square"
						:class="computedDisabledConfirm === true ? 'disabled' : ''"
						@click="methodConfirm"
					>
						<span> {{ $t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.CONFIRM') }} </span>
					</a>
				</el-tooltip>
			</div>

			<div class="flex flex-row gap-2 help-container">
				<span class="text-2xl gui-infos flex" />
				<div class="mb-4 mt-1">
					<p class="bold">{{ $t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.HELP.LINE_ONE') }}</p>
					<div class="">
						<div class="flex flex-col">
							<div class="flex flex-row items-center">
								<i class="gui-next text-2xl pr-2"></i>
								<p class="text-gray-medium">
									{{ $t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.HELP.LINE_TWO') }}
								</p>
							</div>
							<div class="flex flex-row items-center">
								<i class="gui-next text-2xl pr-2"></i>
								<p class="text-gray-medium">
									{{ $t('SHARING2.DOCS.CATEGORIES.EDIT_ORDER_DIALOG.HELP.LINE_THREE') }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
import { UploadFilled } from '@element-plus/icons-vue'
import { ref, defineProps, defineEmits, computed, defineAsyncComponent, onMounted } from 'vue'
import Draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
// props
const props = defineProps({
	docsCategories: {
		type: Array,
		default: () => [],
	},
	dialogOrderCategoriesVisible: {
		type: Boolean,
		default: false,
	},
	methodPatchCategories: {
		type: Function,
		default: () => {},
	},
})

// component

const draggableRowContainer = defineAsyncComponent(() =>
	import('../create-form-components/paramsBar/draggableRowContainer.vue')
)

// emits

const emit = defineEmits(['update:dialogOrderCategoriesVisible'])

// data

let localDocsCategories = ref([])
let currentRowIndexHovered = ref(null)

// computed

const computedDisabledConfirm = computed(() => {
	return cloneDeep(localDocsCategories.value).some(
		(aDocCategory) => aDocCategory.label.trim() === ''
	)
})

const computedDialogOrderCategoriesVisible = computed({
	get() {
		return props.dialogOrderCategoriesVisible
	},
	set(value) {
		emit('update:dialogOrderCategoriesVisible', value)
	},
})

// Mounted
onMounted(() => {
	localDocsCategories.value = cloneDeep(props.docsCategories)
})

// methods

const closeDialog = () => {
	computedDialogOrderCategoriesVisible.value = false
}

const methodEndDrag = () => {
	localDocsCategories.value.forEach((aCategory, aIndex) => {
		aCategory.order = aIndex + 1
	})
	return localDocsCategories
}
const methodConfirm = () => {
	if (computedDisabledConfirm.value === false) {
		props.methodPatchCategories(localDocsCategories.value)
		closeDialog()
	}
}
</script>

<style lang="scss">
.custom-class-docs-categories .el-input__wrapper {
	box-shadow: none;
}
.doc-category-draggable .absolute-icon > i {
	margin-top: 0px !important;
}

.doc-category-draggable {
	padding-left: 6rem !important;
	padding-right: 4.5rem !important;
}

.link-prefix-icon svg {
	display: none !important;
}

.underline-header .el-dialog__body {
	padding-top: 0px;
}

#dialogCustom.custom-dialog-class.dialog-drag-doc-category {
	@apply px-0;
}

.link-prefix-icon .el-input__prefix-inner::after {
	display: block !important;
	color: black;
	top: 0;
	left: 10px;
	position: absolute;
	content: '\e92f';
	font-family: 'icomoon';
}

.doc-category-draggable.list-group-item i {
	@apply opacity-100 pointer-events-auto text-gray-400;
}

.dialog-drag-doc-category .el-dialog__body,
.dialog-drag-doc-category .el-dialog__header {
	@apply px-0 mr-0;
}

#dialogCustom.custom-dialog-class.dialog-drag-doc-category .el-dialog__header {
	padding-left: 4.5rem;
	padding-right: 4.5rem;
}
#dialogCustom.custom-dialog-class.dialog-drag-doc-category .help-container {
	padding-left: 4.5rem;
	padding-right: 4.5rem;
}

.dialog-drag-doc-category .el-dialog__headerbtn {
	@apply m-2;
}

.dialog-drag-doc-category {
	@media (max-width: 768px) {
		width: 90% !important;
	}
}
</style>
