/**
 * @method getAvailableData
 * @memberof store:@commandCenter
 * @param {Object} archi the data to work on
 * @param {string} scale scale at which the data should be displayed
 *
 * @return {Array} an array of available variable_names to query on
 */
export const getAvailableDatasetsAndVariablesNames = (archi, scale) => {
	if (archi.type === 'variable') {
		if (archi.data === true) {
			const variableInfo = {
				variable_name: archi.variable_name,
				dataset_name: archi.dataset_name,
			}
			if (Array.isArray(archi.possible_scales_data)) {
				if (archi.possible_scales_data.includes(scale)) {
					return variableInfo
				}
			} else if (Array.isArray(archi.computed_possible_scales)) {
				// Fallback to the configured scales
				if (archi.computed_possible_scales.includes(scale)) {
					return variableInfo
				}
			} else {
				// if we don't have explicit info, we consider that we can display it.
				return variableInfo
			}
		}
	} else if (archi.elements) {
		return archi.elements
			.flatMap((el) => getAvailableDatasetsAndVariablesNames(el, scale))
			.filter((data) => data)
	} else if (Array.isArray(archi)) {
		return archi
			.flatMap((el) => getAvailableDatasetsAndVariablesNames(el, scale))
			.filter((data) => data)
	}
}
