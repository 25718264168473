<template>
	<div class="no-border-collapse">
		<el-collapse v-model="activeNames">
			<!--  link to redirect to settings if no categories displayed -->
			<template v-if="!computedCategoriesDisplayed">
				<p>{{ $t('ONLINE_FORMS.EMPTY_CATEGORIES') }}.</p>
				<p
					v-if="userConnected?.role_data?.access?.includes('settings:delete')"
					class="text-pink-main cursor-pointer"
					@click="$router.push({ name: 'form-settings' })"
				>
					{{ $t('ONLINE_FORMS.EMPTY_CATEGORIES_REDIRECT') }}
				</p>
				<p v-else>
					{{ $t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE') }}
				</p>
			</template>

			<div
				v-for="(aCategory, index) in computedDragCategories"
				:key="index"
			>
				<el-collapse-item
					v-if="displayCategoriesCondition(aCategory)"
					:name="aCategory.type"
					class="force-rotate-dropdown-arrow"
				>
					<template #title>
						<span class="bold">
							{{ aCategory.title }}
						</span>
					</template>
					<Draggable
						v-model="aCategory.content"
						:group="{ name: 'fields', pull: 'clone', put: false }"
						:sort="false"
						item-key="type"
						v-bind="{ group: 'description' }"
						class="px-3"
						@start="computedStasticDraggableElementIsMoving = true"
						@end="computedStasticDraggableElementIsMoving = false"
					>
						<template #item="{ element }">
							<div
								@mousedown="onlineFormsAlreadyHasContainType(element.type) && disableDrag($event)"
								v-if="
									(element.type === 'address' && element?.fields?.length) ||
										element.type !== 'address'
								"
							>
								<div
									v-if="!onlineFormsAlreadyHasContainType(element.type)"
									class="m-2 p-2 rounded-md"

									:class="

										onlineFormsAlreadyHasContainType(element.type)
											? 'bg-gray-100 disabled  cursor-not-allowed'
											: 'bg-green-lighted enabledDrag'
									"
									@click="addFieldOnClick(element)"
								>
									<p
										v-if="
											aCategory.type === 'informations' ||
												aCategory.type === 'details' ||
												aCategory.type === 'pollingstation' ||
												element.custom_id
										"
									>
										{{ element.label }}
									</p>

									<p
										v-else
										class="text-bold"
									>
										{{ $t(`_COMMON.${element.type?.toUpperCase()}`) }}
									</p>
								</div>

								<el-tooltip
									v-else
									class="item"
									effect="dark"
									:content="$t('ONLINE_FORMS.DRAGGABLE_ITEM_TOOLTIP')"
									placement="left"
								>
									<div
										class="m-2 p-2 rounded-md"
										:class="
											onlineFormsAlreadyHasContainType(element.type)
												? 'bg-gray-100 disabled cursor-not-allowed'
												: 'bg-green-lighted enabledDrag'
										"
										@click="addFieldOnClick(element)"
									>
										<p
											v-if="
												aCategory.type === 'informations' ||
													aCategory.type === 'details' ||
													aCategory.type === 'pollingstation' ||
													element.custom_id
											"
										>
											{{ element.label }}
										</p>

										<p
											v-else
											class="text-bold"
										>
											{{ $t(`_COMMON.${element.type?.toUpperCase()}`) }}
										</p>
									</div>
								</el-tooltip>
							</div>
						</template>
					</Draggable>
				</el-collapse-item>

				<!-- address is last item and we need to hide divider if is the last item -->
				<el-divider
					v-if="!activeNames.includes(aCategory.type)"
					class="my-0"
				/>
			</div>
		</el-collapse>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import { cloneDeep, pickBy } from 'lodash'

export default {
	components: {
		Draggable,
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
		stasticDraggableElementIsMoving: {
			type: Boolean,
			default: false,
		},
		dragCategories: { type: Object, default: () => {} },
		addOrderKey: {
			type: Function,
			default: () => {},
		},
	},
	emits: ['update:form', 'update:stasticDraggableElementIsMoving'],
	data() {
		return {
			activeNames: ['informations', 'details', 'address', 'pollingstation'],
			categoriesDisplayed: false,
		}
	},

	computed: {
		computedCategoriesDisplayed() {
			return this.categoriesDisplayed
		},
		...mapGetters('@form', ['getFormsStatic']),
		...mapGetters(['userConnected']),
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
		computedStasticDraggableElementIsMoving: {
			get() {
				return this.stasticDraggableElementIsMoving
			},
			set(val) {
				this.$emit('update:stasticDraggableElementIsMoving', val)
			},
		},

		computedDragCategories() {
			// don't display categoris who has hidden property (gender categories)
			return cloneDeep(pickBy(this.dragCategories, (value) => !value.hidden))
		},
	},

	methods: {

		disableDrag(event) {
			event.stopPropagation()
			event.preventDefault()
		},

		displayCategoriesCondition(aCategory) {
			if (aCategory.type === 'address') {
				if ((aCategory && aCategory.content[0]?.fields?.length) || aCategory.content.length > 1) {
					this.categoriesDisplayed = true
					return (
						(aCategory.content[0].fields && aCategory.content[0].fields.length)
						|| aCategory.content.length > 1
					)
				}
			}
			else {
				if (aCategory.content && aCategory.content.length) {
					this.categoriesDisplayed = true
				}
				return aCategory.content && aCategory.content.length
			}
		},

		addFieldOnClick(aValue) {
			if (!this.onlineFormsAlreadyHasContainType(aValue.type)) {
				if (this.computedForm.fields) {
					this.computedForm.fields = [...this.computedForm.fields, aValue]
				}
				else {
					this.computedForm.fields = [aValue]
				}

				if (this.computedForm && this.computedForm.fields) {
					this.computedForm = this.addOrderKey(cloneDeep(this.computedForm))
				}
			}
		},

		onlineFormsAlreadyHasContainType(typeFinded) {
			if (this.form && this.form.fields) {
				return this.form.fields.some((element) => element.type === typeFinded)
			}
		},
	},
}
</script>

<style lang="scss">
.no-border-collapse .el-collapse-item__wrap,
.no-border-collapse .el-collapse,
.no-border-collapse .el-collapse-item__header {
	border: 0px;
}
</style>
<style lang="scss" scoped>
.force-rotate-dropdown-arrow i.el-icon-arrow-right {
	transform: rotate(90deg);
}
.force-rotate-dropdown-arrow .is-active i.el-icon-arrow-right {
	transform: rotate(-90deg);
}

.enabledDrag:hover {
	animation: dragAnim 2s ease 0s infinite normal forwards;
	@apply cursor-move;
}

@keyframes dragAnim {
	0% {
		transform: translate(0);
	}

	50% {
		transform: translate(-15px, 0px);
	}

	100% {
		transform: translate(0);
	}
}
</style>
