import get from 'lodash/get'
import store from '../../store/stateStore'
import sessionStore from '../../models/session_store'
import router from '../../router'

/**
 * @description Goes to next route if subscription paid.
 * Goes to suspended-access route if subscription unpaid.
 */
export const beforeNextControlIfSuspendedAccess = async (to, from, next) => {
	const subscription = await sessionStore.getSubscription()
	store.dispatch('actionSetSubscription')

	if (!subscription?.suspended && to.path === '/suspended-access') {
		next('/')
	}
	if (!subscription?.suspended || to.path === '/suspended-access') {
		next(true)
	} else {
		router.push({ name: 'suspended-access' })
		next({ name: 'suspended-access' })
	}
}

export const getterControllerStorePackCPR = () => {
	const CPR = get(store, 'getters.@packs/getCustomerPackRights')
	return CPR
}

export const controllerPackRedirection = (
	next,
	featureFunction,
	routeNameRedirection = 'command-center',
	statusEnabled = ['enabled', 'promoted', 'hidden']
) => {
	const CPR = getterControllerStorePackCPR()
	if (statusEnabled.includes(CPR[featureFunction]())) {
		next()
	} else {
		next({ name: routeNameRedirection, replace: true })
	}
}
