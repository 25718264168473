<template>
	<div>
		<iframe
			v-if="personnalizedData"
			id="external-metabase"
			ref="extiframe0"
			:src="personnalizedData"
			frameborder="0"
			width="100%"
			height="100%"
			allowtransparency
			visibility="hidden"
			@load="load($refs.extiframe0)"
		></iframe>
		<div v-else class="px-8 py-6">
			<container-packer
				class="bg-white"
				access="custom_dashboard"
				small-padding
				main
				:handler-main="() => methodDisplayUpsellPopin(true)"
			></container-packer>
			<popin-upsell
				v-model:is-pop-up-shown="computedIsUpsellPopinShown"
				source="Custom Dashboard"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
export default {
	components: {
		ContainerPacker: defineAsyncComponent(() => import('../packs/containerPacker.vue')),
		PopinUpsell: defineAsyncComponent(() => import('../packs/upsellPopin.vue')),
	},
	data() {
		return {
			isUpsellPopinShown: false,
		}
	},
	computed: {
		...mapGetters(['info_campagne']),

		t() {
			const prefix = 'ANALYTICS.DETAIL'
			return {
				title: this.$t(`${prefix}.TITLE`),
				request: this.$t(`${prefix}.REQUEST`),
			}
		},

		personnalizedData() {
			return this.info_campagne.dashboardUrl
		},
		computedIsUpsellPopinShown: {
			get() {
				return this.isUpsellPopinShown
			},
			set(val) {
				this.isUpsellPopinShown = val
			},
		},
	},
	methods: {
		methodDisplayUpsellPopin(val) {
			this.computedIsUpsellPopinShown = val
		},
	},
}
</script>
