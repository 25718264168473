import cloneDeep from 'lodash/cloneDeep'

export default function getUsersPopulatedByTeams(usersArray = [], teamsArray = []) {
	const usersCloned = cloneDeep(usersArray)

	if (!teamsArray.length) {
		return usersCloned
	}

	const teamCloned = cloneDeep(teamsArray)

	teamCloned.forEach((aTeam) => {
		const { users } = aTeam
		if (Array.isArray(users)) {
			users.forEach((aTeamUser) => {
				const userFound = usersCloned.find((aUser) => aUser.id === aTeamUser.id)
				if (userFound && aTeam.users && aTeam.users.length) {
					aTeam.users.sort((userA, userB) => userA?.avatar - userB?.avatar)
					if (!userFound.teams) {
						userFound.teams = [aTeam]
						return
					}
					userFound.teams.push(aTeam)
				}
			})
		}
	})

	return usersCloned
}
