import cloneDeep from 'lodash/cloneDeep'
import massiveManipStore from '../../models/massive_manip_store'
import { constructQueryFromStore } from './utils/searchTools'

import { FETCH_ALL_MASSIVE_MANIPULATION } from './_mutation-types'
import { FETCH_ALL_MASSIVE_CONTACT_MODIFICATIONS } from './_mutation-types'

const getDefaultState = () => {
	return {
		listManipulations: [],
		listContactModifications: [],
	}
}

const state = getDefaultState

const mutations = {
	[FETCH_ALL_MASSIVE_MANIPULATION]: (state, payload) => {
		state.listManipulations = payload
	},
	[FETCH_ALL_MASSIVE_CONTACT_MODIFICATIONS]: (state, payload) => {
		state.listContactModifications = payload
	},
}

const actions = {
	actionFetchMassiveManipulations: ({ commit }) => {
		massiveManipStore.fetchAllManip().then((payload) => {
			commit(FETCH_ALL_MASSIVE_MANIPULATION, payload)
		})
	},
	actionFetchMassiveContactModifications: ({ commit }) => {
		massiveManipStore.fetchAllContactModifications().then((payload) => {
			commit(FETCH_ALL_MASSIVE_CONTACT_MODIFICATIONS, payload)
		})
	},
	actionStartMassiveDeletion: async ({ rootGetters }, payload) => {
		// blow up the per_page search limit
		const classicSearch = {
			...cloneDeep(constructQueryFromStore(rootGetters)),
		}

		classicSearch.fields[2] = String(payload.nb_result)
		if (!payload.ids?.length && classicSearch.advanced_search) {
			classicSearch.advanced_search.per_page = payload.nb_result
		}

		const data = {
			search_request: {
				contact_ids: payload.ids,
				...classicSearch,
			},
		}

		return massiveManipStore.startMassiveDeletion(data).then((res) => {
			return res.body.status === 'success'
		})
	},
}

const getters = {
	getListMassiveManipulations: (state) => state.listManipulations,
	getListMassiveContactModifications: (state) => state.listContactModifications,
}

export const massiveManipModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
