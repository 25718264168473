<template>
	<div class="h-full q-fullwidth pl-4 flex items-center w-full">
		<template v-for="(tab, index) in computedGetTabs" :key="index">
			<el-tooltip :disabled="tab.isTooltipDisabled" :content="t.disabled">
				<div>
					<tab-with-number
						:key="index + 1"
						:title="tab.title"
						:selected="tab.selected"
						:status-pack="tab.status"
						:is-soon="tab.isSoon"
						:click-blocked="tab.isClickBlocked"
						font-size="1.25rem"
						class="mr-10 cursor-pointer"
						:count="tab.count"
						@tabClick="methodGoTo(tab.name)"
					>
					</tab-with-number>
				</div>
			</el-tooltip>
		</template>
		<div class="flex flex-grow justify-end mr-5">
			<button
				v-if="$route.name === 'online-forms'"
				class="q-button"
				@click="
					actionSetMenuVisibility(false),
						$router.push({
							name: 'online-forms-create',
						})
				"
			>
				{{ $t('ONLINE_FORMS.CREATE_FORM') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},
	props: {},

	computed: {
		...mapGetters('@packs', ['getStatusSurveyAccessAdmin', 'getStatusSurveyDashboard']),
		...mapGetters('@form', ['forms']),
		...mapGetters('@navBar', ['isMenuVisible']),

		t() {
			const prefix = 'SURVEY'
			return {
				disabled: this.$t(`${prefix}.DISABLED`),
			}
		},

		computedCanAccessToDashboardSurvey() {
			return this.getStatusSurveyDashboard
		},

		computedGetTabs() {
			return [
				{
					title: this.$t('SURVEY.SURVEY'),
					name: 'survey',
					status: this.computedCanAccessToDashboardSurvey,
					selected:
						this.$route.name === 'survey-builder' ||
						this.$route.name === 'online-survey' ||
						this.$route.name === 'survey-results-general' ||
						this.$route.name === 'survey-results-detailed',
					isTooltipDisabled: this.forms.length > 0 ? true : false,
				},
			]
		},
	},

	methods: {
		...mapActions('@navBar', ['actionSetMenuVisibility']),
		methodGoTo(name) {
			this.$router.push({ name: name })
		},
	},
}
</script>

<style lang="scss" scoped>
.soon-icon {
	color: rgb(242, 152, 201);

	&::before {
		color: rgb(244, 155, 204);
	}
}
</style>
