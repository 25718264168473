<template>
	<div class="flex-col">
		<h1 class="gtDisplayBold text-center text-4xl mb-5">
			{{ $t(`APP.SIGNUP.ADD_INFOS`) }}
		</h1>
		<p class="text-center mb-10">
			{{ $t(`APP.SIGNUP.FILL_ADDRESS`) }}
		</p>

		<div
			class="imageContainer mt-6 mb-2 mx-auto w-max flex flex-col justify-center items-center bg-gray-50 p-2 rounded-lg cursor-pointer hover:bg-gray-100"
			@click="isImageUploaderVisible = !isImageUploaderVisible"
		>
			<img
				v-if="computedAvatar.length > 0"
				:src="computedAvatar"
				class="relative w-32 h-32 rounded-lg object-cover object-center"
			>
			<p
				v-else-if="computedUserInitials?.length > 0"
				class="text-gray-dark text-2xl font-black w-28 h-28 rounded-full bg-white flex justify-center items-center"
			>
				{{ computedUserInitials }}
			</p>

			<img
				v-else
				src="/static/images/qomon/pink-shorted.svg"
				alt=""
				class="w-28 h-28 p-6"
			>
		</div>
		<p
			class="text-pink-main text-sm text-center cursor-pointer pb-8 hover:opacity-70 hover:underline"
			@click="isImageUploaderVisible = !isImageUploaderVisible"
		>
			{{ $t(`APP.SIGNUP.ADD_PHOTO`) }}
		</p>
		<image-uploader
			v-if="isImageUploaderVisible"
			:is-visible="isImageUploaderVisible"
			:only-upload="true"
			:img="computedAvatar"
			:title="$t(`APP.SIGNUP.SMILE`)"
			:subtitle="$t(`APP.SIGNUP.ADD_PHOTO_SUBTITLE`)"
			:first-tab-wording="$t(`APP.SIGNUP.PHOTO`)"
			:get-base64="true"
			:is-just-url-wanted="true"
			:which-module="'settings'"
			custom-class="signup-image-uploader"
			@create-image-from-url="methodCreateAvatar"
			@close-image-uploader="isImageUploaderVisible = false"
			@delete-space-profil-picture="methodDeleteSpaceProfilPicture"
		/>

		<div class="flex flex-wrap justify-center">
			<signup-input
				v-for="(input, index) in computedUserData"
				:key="index"
				v-model:input-data="computedUserData[index]"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SignupInput from './signupInput.vue'
import ImageUploader from '../../general/image-uploader.vue'

export default {
	components: {
		SignupInput,
		ImageUploader,
	},

	beforeRouteEnter(to, from, next) {
		const allowedRoutes = ['create-account', 'validate-email', 'login-auth0-callback', 'join-a-group']

		if (!allowedRoutes.includes(from.name)) {
			next('login')
		}
		else {
			next()
		}
	},

	props: {
		userData: { type: Array, required: true },
		userAvatar: { type: String, default: '' },
	},
	emits: ['update:user-avatar'],

	data() {
		return {
			isImageUploaderVisible: false,
		}
	},

	computed: {
		...mapGetters('@authentication', ['getCredentialsEntered']),

		computedUserData() {
			const includesKeysAuth0 = ['firstname', 'lastname', 'address', 'zip', 'city', 'phone', 'birthdate']
			const includesKeys = ['address', 'zip', 'city', 'phone', 'birthdate']

			if (this.getCredentialsEntered?.raw_id_token) {
				return this.userData.filter((d) => includesKeysAuth0.includes(d.name))
			}

			return this.userData.filter((d) => includesKeys.includes(d.name))
		},

		computedBreakIndex() {
			return this.userData.findIndex((d) => d.name === 'password') + 1
		},

		computedAvatar: {
			get() {
				return this.userAvatar
			},
			set(value) {
				this.$emit('update:user-avatar', value)
			},
		},
		computedUserInitials() {
			let firstnameInitial = this.userData.find((d) => d.name === 'firstname').value[0]
			let lastnameInitial = this.userData.find((d) => d.name === 'lastname').value[0]

			!firstnameInitial && (firstnameInitial = '')
			!lastnameInitial && (lastnameInitial = '')

			return firstnameInitial + lastnameInitial
		},
	},

	methods: {
		methodCreateAvatar(imgUrl) {
			this.computedAvatar = imgUrl
			this.isImageUploaderVisible = false
		},

		methodDeleteSpaceProfilPicture() {
			this.computedAvatar = ''
		},
	},
}
</script>

<style>
.signup-image-uploader {
	min-width: 300px;
	max-width: 600px;
}
</style>
