/**
 * @file support
 * @author Nicolas Delauney <nicolas@qomon.com>
 * This file aim to centralized support - related functionnality,
 * so when switching from support (Intercom, Zendesk, crisp...)
 * you can do it all at once in this file whereas looking trough the app
 */

export function closeSupport() {
	if (window.Intercom) {
		window.Intercom('shutdown')
	}
}

export function openChat() {
	if (window.Intercom) {
		window.Intercom('show')
	}
}

export function updateSupportSettings(settings) {
	if (window.Intercom) {
		window.Intercom('boot', window.intercomSettings)
		window.Intercom('update', settings)
	}
}

export function openChatWithPrePopulatedMessage(message, isHide) {
	if (window.Intercom && !isHide) {
		window.Intercom('showNewMessage', message)
	}
}

export const helpCenterUrl = 'https://help.qomon.com'
