<template>
	<div class="flex flex-row w-full overflow-hidden flex-grow">
		<div
			class="flex flex-col bg-white px-14 overflow-auto  transition-all"
			:class="cardMoreDetails.visible ? 'w-8/12': 'w-7/12'"
		>
			<p
				class="text-4xl mt-8 font-title"
			>
				{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAIN_TITLE') }}
			</p>
			<p
				class="mb-4 mt-2 text-gray-400 w-full"
			>
				{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAIN_DESC') }}
			</p>

			<div class="flex flex-row gap-2 flex-wrap mb-6 w-full justify-end" />

			<div
				v-if="getNewActionAssignmentType === 'open' || getNewActionAssignmentType === 'allUsers'"
				class="pb-4 pt-2 flex flex-col flex-grow"
			>
				<div class="flex flex-col pb-6 ">
					<p class="font-title-bold text-2xl">
						{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TITLE.${getNewActionAssignmentType.toUpperCase()}`) }}
					</p>
					<p class="text-gray-500 text-md">
						{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SUBTITLE.${getNewActionAssignmentType.toUpperCase()}`) }}
					</p>
				</div>
				<div class="bg-gray-300 flex-grow mb-10 rounded-2xl" />
			</div>

			<template
				v-else
			>
				<template v-if="aiIsUselessCardVisibile && isNewAction && (whichPartDisplay === 'listing' || whichPartDisplay === 'searchListing')">
					<ActionAiAssignmentCard
						:toggle-filters="methodToggleFilters"
						:method-go-to-listing="methodGoToListing"
						:active-filters-length="computedFiltersActiveLength"
						v-model:is-ai-settings="isAiSettings"
						:selected-teams="selectedTeams"
						:selected-users="selectedUsers"
						:method-on-cross-click="()=> aiIsUselessCardVisibile = false"
						complementary-container-classes="rounded-[20px] mb-2"
					>
						<div class="leading-tight">
							<p class="font-title text-xl leading-5">
								<template v-if="disabledAiForActionType.includes(actionType)">
									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_ONE_DISABLED_BY_ACTION') }}
								</template>
								<template v-else>
									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_ONE') }}
								</template>
							</p>
							<p class="text-sm leading-5">
								<template v-if="disabledAiForActionType.includes(actionType)">
									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_TWO_DISABLED_BY_ACTION') }}
								</template>
								<template v-else>
									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.USELESS_AI.LINE_TWO') }}
								</template>
							</p>
						</div>
					</ActionAiAssignmentCard>
				</template>

				<template
					v-if="!isAiSettings"
				>
					<div
						style=""
						class="sticky top-0 w-full z-50 bg-white py-3"
					>
						<div class="flex flex-row">
							<ActionAssignmentSearchAndFilterHeader
								v-model:search-query="searchQuery"
								v-model:search-bar-is-open="searchBarIsOpen"
								v-model:which-part-display="whichPartDisplay"
								v-model:show-filters="showFilters"
								:method-go-to-listing="methodGoToListing"
								:method-go-to-filter-view="methodGoToFilterView"
								:method-open-dialog-for-reset-to-ai="() => {dialogResetToAiVisible = true}"
								:active-filters-length="computedFiltersActiveLength"
								:method-reset-all-own-selection-filters="methodResetAllOwnSelectionFilters"
								:disabled-ai-selection="disabledAiForActionType.includes(actionType) && isEmpty(localMeetingPoint[0])"
								:ai-is-useless="aiIsUseless"
							/>
							<div
								class="flex flex-grow  gap-2 items-center justify-end"
							>
								<ActionAssignmentSelectedCounterCard
									:merged-users-and-users-in-teams-length="methodMergedUsersAndUsersInTeams(selectedUsers, selectedTeams).length || 0"
									:selected-teams="selectedTeams"
									:selected-users="selectedUsers"
									:action-method="methodTriggerCounterCard"
									:container-classes="whichPartDisplay === 'listing' ? 'bg-black text-white cursor-pointer' : 'bg-gray-lighted text-black cursor-pointer border-none'"
									:count-number-classes="whichPartDisplay === 'listing' ? 'opacity-80' : 'text-gray-400'"
								/>
							</div>
						</div>

						<div
							v-if="!isAiSettings && whichPartDisplay !== 'filters'"
							class="flex flex-row items-center w-full justify-end mt-9 mb-6"
						>
							<div class="flex-grow flex items-start justify-start gap-4">
								<span
									@click.stop="methodSetWichListDisplay('users')"
									class="group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer"
									:class="[whichListDisplay === 'users' ? 'bg-black text-white' : 'bg-gray-100']"
								>

									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.USERS') }}

									<span
										class="group-hover:text-white"
										:class="whichListDisplay === 'users' ? 'opacity-80' : 'text-gray-400'"
									>

										{{ computedReturnTabCountNumberUsers }}
									</span>

								</span>
								<span
									@click.stop="methodSetWichListDisplay('teams')"
									class="group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer"
									:class="[whichListDisplay === 'teams' ? 'bg-black text-white' : 'bg-gray-100']"
								>
									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.TEAMS') }}
									<span
										class="group-hover:text-white"
										:class="whichListDisplay === 'users' ? 'opacity-80' : 'text-gray-400'"
									>

										{{ computedReturnTabCountNumberTeams }}
									</span>
								</span>
								<span
									@click.stop="methodSetWichListDisplay('usersAndTeams')"
									class="group px-4 py-2 hover:bg-pink-main rounded-full hover:text-white cursor-pointer"
									:class="[whichListDisplay === 'usersAndTeams' ? 'bg-black text-white' : 'bg-gray-100']"
								>

									{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.TABS.USERS_AND_TEAMS') }}
									<span
										:class="whichListDisplay === 'users' ? 'opacity-80' : 'text-gray-400'"
										class="group-hover:text-white"
									>

										{{ computedReturnTabCountNumberUsersAndTeams }}
									</span>

								</span>
							</div>
							<div
								v-if="(selectedUsers?.length || selectedTeams?.length) && whichPartDisplay === 'listing'"
								class=" cursor-pointer mx-7 hover:opacity-50"
								@click.stop="methodClearAllSelection"
							>
								{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.FILTERS.CLEAR_ALL') }}
							</div>

							<div
								@click.stop="toggleDropdownSort"
								class="flex items-center justify-center"
							>
								<el-dropdown
									trigger="click"
									@visible-change="toggleDropdownSort"
									:visible="dropdownVisibleSort"
									class="rounded-xl"
								>
									<span class="el-dropdown-link flex items-center">
										<i
											v-if="computedCurrentSortOrderValue !=='selectedAt'"
											class="gui-sort_desc inline-block"
											:class="[computedCurrentSortOrderValue === 'asc' || computedCurrentSortOrderValue === 'asc_name' ? 'force-flip-y-icon' : '']"
										/>

										<span class="pl-2 ">

											{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${computedCurrentSortOrderValue.toUpperCase()}`) }}

										</span>

									</span>

									<template #dropdown>
										<el-dropdown-menu class="rounded-xm">
											<span class="text-xs px-3 uppercase text-gray-400">
												{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.SORT_BY') }}
											</span>
											<template
												v-for="(aSortOrder, index) in computedSortOrderValuesByWhichListDisplay"
												:key="index"
											>
												<el-dropdown-item
													class="mx-1 rounded-lg"
													@click.stop="methodTriggerSortOrder(aSortOrder)"
												>
													<i :class="computedCurrentSortOrderValue === aSortOrder ? 'gui-radio_on': 'gui-radio_off'" />

													{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${aSortOrder.toUpperCase()}`) }}

													<!-- {{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${aSortOrder.toUpperCase()}`) }} -->
												</el-dropdown-item>
											</template>

											<template v-if="whichListDisplay !=='teams'">
												<div class="mx-3 items-center justify-center flex">
													<el-divider class="my-2" />
												</div>

												<span class="text-xs px-3 uppercase text-gray-400">
													{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.FROM') }}
												</span>
												<template

													v-for="(aDistanceTargetSort, index) in distanceTargetSort.allValues"
													:key="index"
												>
													<el-dropdown-item
														class="mx-1 rounded-lg"

														:disabled="isEmpty(newActionPolygon) && aDistanceTargetSort === 'polygonCenter' || isEmpty(localMeetingPoint[0]) && aDistanceTargetSort === 'meetingpoint'"
														@click.stop="methodChangeUsersDistanceTarget(aDistanceTargetSort)"
													>
														<div>
															<i :class="distanceTargetSort.currentValue === aDistanceTargetSort ? 'gui-radio_on': 'gui-radio_off'" />

															{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.${aDistanceTargetSort.toUpperCase()}`) }}
														</div>
													</el-dropdown-item>
												</template>
											</template>
											<template v-if="!isEmpty(newActionPolygon) || !isEmpty(localMeetingPoint[0])">
												<div class="">
													<div class="mx-3 items-center justify-center flex">
														<el-divider class="my-2" />
													</div>

													<span class="text-xs px-3 uppercase text-gray-400">
														{{ $t(`ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_LISTING.SORT_BY.UNIT`) }}
													</span>
													<template

														v-for="(aDistanceTargetUnit, index) in distanceTargetUnits.allValues"
														:key="index"
													>
														<el-dropdown-item
															class="mx-1 rounded-lg"

															:disabled="isEmpty(newActionPolygon) && aDistanceTargetUnit === 'polygonCenter' || isEmpty(localMeetingPoint[0]) && aDistanceTargetUnit === 'meetingpoint'"
															@click.stop="methodChangeUsersDistanceUnit(aDistanceTargetUnit)"
														>
															<div>
																<i :class="distanceTargetUnits.currentValue === aDistanceTargetUnit ? 'gui-radio_on': 'gui-radio_off'" />

																{{ $tc(`_COMMON.DISTANCE.${aDistanceTargetUnit.toUpperCase()}.LONG`, 2) }}
															</div>
														</el-dropdown-item>
													</template>
												</div>
											</template>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</div>
						</div>
					</div>

					<div>
						<template v-if="!isAiSettings && showFilters">
							<ActionAssignmentFilters
								v-model:map-range="computedMapRange.currentValue"
								v-model:own-selection-filters="ownSelectionFilters"
								v-model:selection-types="selectionTypes"
								:method-update-circle-from-filter="updateCircleFromFilter"
								:which-part-display="whichPartDisplay"
								:active-filters-length="computedFiltersActiveLength"
								:meeting-point="localMeetingPoint"
								:polygon="computedNewActionPolygon"
								:action-type="actionType"
								:max-range="computedMapRange.maxValue"
							/>

							<ActionAssignmentFilterContent
								:pre-selected-users="computedDiffBeetweenSelectedAndPreselected.diffUsers"
								:pre-selected-teams="computedDiffBeetweenSelectedAndPreselected.diffTeams"
								:method-apply-filters="methodMergePreselectedWithSelectedUsers"
								:method-cancel-filters="methodCancelPreselectedValues"
							/>
						</template>
					</div>
				</template>

				<template v-if="whichPartDisplay === 'searchListing'">
					<ActionAssignmentSearchListing
						v-model:search-query="searchQuery"
						v-model:sort-order="computedCurrentSortOrderValue"
						v-model:search-bar-is-open="searchBarIsOpen"
						v-model:map-range="computedMapRange"
						v-model:selected-users="selectedUsers"
						v-model:map-instance="mapInstance"
						v-model:selection-types="selectionTypes"
						v-model:own-selection-filters="ownSelectionFilters"
						v-model:selected-teams="selectedTeams"
						v-model:active-names="activeNames"
						v-model:selected-action-leaders="selectedActionLeaders"
						:users="usersWithDistances"
						:method-update-leaders-action="methodUpdateLeadersAction"
						:toggle-select-card="toggleSelectCard"
						:method-verify-is-already-check="methodVerifyIsAlreadyCheck"
						:method-verify-if-user-is-locked="methodVerifyIfUserIsLocked"
						:handle-area-change-method="methodAreaRangeChange"
						:method-update-circle-from-filter="updateCircleFromFilter"
						:method-go-to-listing="methodGoToListing"
						:method-display-user-or-team-infos="methodDisplayUserOrTeamInfos"
						:method-close-more-details="methodCloseMoreDetails"
						:method-verify-is-action-leader="methodVerifyIsActionLeader"
						:method-sort-users="methodSortUsers"
						:method-sorted-teams="methodSortedTeams"
						:user-selected-id="cardMoreDetails?.itemId"
						:all-teams="cloneDeep(getTeams)"
						:which-list-display="whichListDisplay"
						:method-sort-users-and-teams="methodSortUsersAndTeams"
						:current-distance-target-unit="distanceTargetUnits.currentValue"
					/>
				</template>
				<template v-if="whichPartDisplay === 'listing'">
					<div
						v-if="isAiSettings"
					>
						<template v-if="!aiIsUseless">
							<ActionAiAssignmentCard
								:toggle-filters="methodToggleFilters"
								:method-go-to-listing="methodGoToListing"
								:active-filters-length="computedFiltersActiveLength"
								v-model:is-ai-settings="isAiSettings"
								:selected-teams="selectedTeams"
								:selected-users="selectedUsers"
								show-counter-card
								:merged-users-and-users-in-teams-length="methodMergedUsersAndUsersInTeams(selectedUsers, selectedTeams).length || 0"
							>
								<div class="leading-tight">
									<p class="font-title text-xl leading-3">
										{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.TITLE.LINE_ONE') }}
									</p>
									<p class="font-title text-xl flex items-center">
										{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.TITLE.LINE_TWO') }}

										<el-tooltip
											effect="dark"
											placement="top"
										>
											<template #content>
												<a
													v-html="t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.TOOLTIP')"
													:href="t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.TOOLTIP_LINK')"
													class="custom-tooltip-link"
													target="_blank"
													style="color: white"
												/>
											</template>

											<i class="pl-2 pt-0.5 gui-infos cursor-help" />
										</el-tooltip>
									</p>
									<p class="text-sm">
										{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.SUBTITLE') }}
									</p>
								</div>
							</ActionAiAssignmentCard>
						</template>
					</div>

					<!-- <div v-if="" /> -->
					<div

						class="relative"
						:class="isAiSettings ? 'fake-border-gradient' : ''"
					>
						<div
							class="relative z-10"
							:class="isAiSettings ? 'px-4 pt-6' : ''"
						>
							<ActionAssignmentListing
								v-model:selected-action-leaders="selectedActionLeaders"
								v-model:search-query="searchQuery"
								v-model:map-range="computedMapRange"
								v-model:which-part-display="whichPartDisplay"
								v-model:selected-users="selectedUsers"
								v-model:map-instance="mapInstance"
								v-model:selection-types="selectionTypes"
								v-model:own-selection-filters="ownSelectionFilters"
								v-model:selected-teams="selectedTeams"
								:selected-users-and-teams="concatenedSelectedTeamsAndUsers"
								v-model:active-names="activeNames"
								:users="usersWithDistances"
								:method-update-leaders-action="methodUpdateLeadersAction"
								:toggle-select-card="toggleSelectCard"
								:method-verify-is-already-check="methodVerifyIsAlreadyCheck"
								:method-verify-is-action-leader="methodVerifyIsActionLeader"
								:handle-area-change-method="methodAreaRangeChange"
								:method-update-circle-from-filter="updateCircleFromFilter"
								:method-display-user-or-team-infos="methodDisplayUserOrTeamInfos"
								:method-close-more-details="methodCloseMoreDetails"
								:user-selected-id="cardMoreDetails?.itemId"
								:which-list-display="whichListDisplay"
								:current-distance-target-unit="distanceTargetUnits.currentValue"
							/>

							<div
								class="flex flex-col text-white rounded-full w-auto justify-end items-end pb-4"
							>
								<q-button
									v-if="isAiSettings"
									class="full-black force-rounded-full"
									@click="methodAddUserOrTeamFromAi"
								>
									<i class="gui-more text-xl pr-2 bold" /> <span class="font-extralight py-1">
										{{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.ADD_USERS_TO_SELECTION') }}
									</span>
								</q-button>

								<span
									v-else
									@click="methodGoToSearchListing"
									class="cursor-pointer mr-4 basic-black q-button ghost square"
								>

									<i class="gui-more pr-1" />  {{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.ADD_MORE') }}
								</span>
							</div>
						</div>
					</div>

					<div
						v-if="isAiSettings"
						class="flex flex-col text-black w-auto justify-end items-center py-5 "
					>
						<span
							class="bold bg-gray-lighted rounded-full rounded-full px-24 py-4 cursor-pointer hover:opacity-60"
							@click="methodOwnSelection"
						>  {{ $t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.CARD.CTA.OWN_SELECTION') }}</span>
					</div>
				</template>
			</template>
		</div>
		<div
			class="bg-white transition-width duration-300 flex-grow relative "
			:class="[whichPartDisplay === 'filters' ? 'disable-cursor-map' : '']"
		>
			<template v-if="usersWithDistances.length">
				<div
					class="w-auto  absolute right-5 bottom-5 z-10 flex flex-row gap-2"
					:class="whichPartDisplay === 'filters' ? 'pointer-events-none cursor-not-allowed opacity-40' : ''"
				>
					<div
						v-if="localMeetingPoint && !isEmpty(localMeetingPoint[0])"
						class="w-10 h-10 flex items-center bg-white rounded-[10px] shadow-lg"
					>
						<el-tooltip
							effect="customized-popper-assignment"
							:content="t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAP.CTA.MEETING_POINT_VIEW')"
							placement="top"
						>
							<p
								@click="adjustZoomAndView(mapInstance, transformPointToMiniPolygon(localMeetingPoint[0]), adjustZoomAndViewCallBack)"

								class="p-2 m-1 flex items-center justify-center hover:bg-pink-main hover:bg-opacity-5 hover:text-pink-main rounded-[10px] cursor-pointer"
								:class="isEqual(currentZoomAndViewCoords, transformPointToMiniPolygon(localMeetingPoint[0])[0]) ? 'bg-pink-main bg-opacity-5 text-pink-main' : ''"
							>
								<i class="gui-pin_colorful" />
							</p>
						</el-tooltip>
					</div>
					<div
						v-if="newActionPolygon && !isEmpty(newActionPolygon)"
						class="w-10 h-10 flex items-center bg-white rounded-[10px] shadow-lg"
					>
						<el-tooltip
							effect="customized-popper-assignment"
							:content="t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.MAP.CTA.POLYGON_VIEW')"
							placement="top"
						>
							<p
								@click="adjustZoomAndView(mapInstance, newActionPolygon, adjustZoomAndViewCallBack)"

								class="p-2 m-1 flex items-center justify-center hover:bg-pink-main hover:bg-opacity-5  hover:text-pink-main rounded-[10px] cursor-pointer"
								:class="isEqual(currentZoomAndViewCoords, newActionPolygon[0]) ? 'bg-pink-main bg-opacity-5 text-pink-main' : ''"
							>
								<i class="qi-turf" />
							</p>
						</el-tooltip>
					</div>
				</div>
				<ActionAssignmentMap
					v-model:map-fully-loaded="mapFullyLoaded"
					v-model:map-instance="mapInstance"
					:action-polygon="newActionPolygon"
					:users="usersWithDistances"
					:which-part-display="whichPartDisplay"
					:toggle-select-card="toggleSelectCard"
					:handle-area-change-method="methodAreaRangeChange"
					:meeting-point="localMeetingPoint"
					v-model:current-zoom-and-view-coords="currentZoomAndViewCoords"
				/>
			</template>
			<Transition name="slide-fade">
				<template v-if="cardMoreDetails.visible">
					<cardDetailsInfos
						:more-details="computedMoreDetails"
						:type="cardMoreDetails.type"
						:method-close-more-details="methodCloseMoreDetails"
					/>
				</template>
			</Transition>
		</div>
	</div>
	<delete-dialog
		:cancel="() => closeDialogResetToAi()"
		:confirm="() => methodResetToAiSelection()"
		:confirm-label="$t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.CONFIRM_CTA')"
		:close-dialog="() => (closeDialogResetToAi())"
		:visible-dialog="dialogResetToAiVisible"
		:title="`${$tc('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.TITLE')}`"
	>
		<div class="py-5">
			<p>
				{{ $tc('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.AI_ASSIGNMENT.RESET_TO_AI_DIALOG.DESCRIPTION') }}
			</p>
		</div>
	</delete-dialog>
</template>

<script setup lang="ts">

import { useStore } from 'vuex'
import { cloneDeep, isEmpty, isNumber, orderBy, intersection, round, uniqBy, isEqual } from 'lodash'
import { addActionPolygon, updateCircle, removeAllMapMouseMoveHandlers, startUpdateCircleFromMap, getCircleData, userIsInsideArea, updateAiPolygonArea, addCheckedClassToSelectedUsers, addLockedClassToSelectedUsers, addCheckedClassToSelectedActionLeaders, resetMarkerCheckedClass, setDotEndOfLineVisibility, removeGrayScaledClassToSelectedUsers, addGrayscaledClassInAllUsers, getCenterOfPolygon, setCircleLayerVisibility, colorActionPolygon, getRightmostPointFromCircle, updateLine, getMaxDistanceFromCenterToPolygon, setLineVisibility, addLockedByTeamClassToUsers, adjustZoomAndView, transformPointToMiniPolygon, setAiLayerVisibility, addUsernameTooltipClassUsers, mappingUnits, methodSetDefaultPolygonBasedOnGeoHashContacts } from '../../../../extensions/maplibregl/members_handlers.ts'
import * as turf from '@turf/turf'
import { getDefaultUnitByGeoCountry } from '@quorumsco/quorum-helpers/lib/actions/index'
import { computed, defineAsyncComponent, provide, nextTick, onMounted, onUnmounted, ref, reactive, watch, defineProps, defineModel, defineEmits, onBeforeUnmount } from 'vue'
import { methodFilterPrivateTeams } from '../../../user_management-module/user-list/sub-components/utils'
import getUsersPopulatedByTeams from '../../../user_management-module/utils/getUsersPopulatedByTeams'
import { is } from 'date-fns/locale'
import { generatePolygon } from '../../../../extensions/maplibregl/polygonGenerator.ts'
import { latLngIsInNorthPole } from '../../../../utils/geo'
import analytics, { TRACKING } from '../../../../external/analytics'
import { ElMessage } from 'element-plus'

const store = useStore()

const emit = defineEmits(['update:nextValidatorBoolean', 'update:nextFunction', 'update:nextLabelButton'])

const users = computed(() => {
	const allUsers = store.getters['users']

	if (!allUsers) {
		return []
	}
	else {
		return allUsers.map((user) => {
			if (props.activeStatus && !isEmpty(props.activeStatus) && props.activeStatus[user.id]) {
				return { ...user, active: props.activeStatus[user.id] }
			}
			else {
				return {
					...user,
					active: null,
				}
			}
		})
	}
})

const getGeoCodageCountry = computed(() => store.getters['@group/getGeocodageCountry'])
const usersWithDistances = computed(() => returnUsersWithDistances())
const dropdownVisibleSort = ref(false)
const whichListDisplay = ref('usersAndTeams')
const userConnected = computed(() => store.getters['userConnected'])
const getTeams = computed(() => methodFilterPrivateTeams(store.getters['@team/getTeams'], userConnected.value))
const newAction = computed(() => store.getters['@action/newAction'])
const localMeetingPoint = ref([])
const actionType = ref([])
const getSearchAddressIncluded = computed(() => store.getters['@search/getSearchAddressIncluded'])
const getSearchPollingStationsIncluded = computed(() => store.getters['@search/getSearchPollingStationsIncluded'])
const getAllowUnknowAddressesOnActionCreation = computed(() => store.getters['@search/getAllowUnknowAddressesOnActionCreation'])
const getNewActionAssignmentType = computed(() => store.getters['@action/getNewActionAssignmentType'])
const getSkipBeforeRouteLeaveActionCreation = computed(() => store.getters['@action/getSkipBeforeRouteLeaveActionCreation'])

const computedGeohashArray = computed(() => store.getters['geohash_array_clean'])
const computedGeohashArrayNotClean = computed(() => store.getters['geohash_array'])
const mapFullyLoaded = ref(false)

import { useRoute, useRouter } from 'vue-router'
const router = useRouter()

const disabledAiSelection = defineModel('disabledAiSelection', {
	type: Boolean,
	default: false,
})

const selectedUsersEdition = defineModel('selectedUsersEdition', {
	type: Boolean,
	default: false,
})

const props = defineProps({
	currentActionData: {
		type: Array,
		default: () => null,
	},
	methodSetCurrentActionFromAssignment: {
		type: Function,
		default: () => {},
	},
	actionDetailMapData: {
		type: Object,
		default: () => {},
	},
	isNewAction: {
		type: Boolean,
		default: true,
	},
	meetingPoint: {
		type: Array,
		default: () => [],
	},
	activeStatus: {
		type: Object,
		default: () => {},
	},

})

const latestListPart = ref('listing')
const dialogResetToAiVisible = ref(false)
const route = useRoute()
const selectedUsers = ref([])
const preSelectedUsers = ref([])
const preSelectedTeams = ref([])
const selectedActionLeaders = ref([])
const sortOrder = ref('asc')
const sortTeamsOrder = ref('asc')
const sortTeamsAndUsersOrder = ref('asc')
const sortOrderValues = ['asc', 'desc', 'asc_name', 'desc_name']

const sortOrderTeamValues = ['asc_name', 'desc_name']
const sortOrderTeamAndUsersValues = ['asc', 'desc', 'asc_name', 'desc_name']
const distanceTargetSort = ref({
	allValues: ['polygonCenter', 'meetingpoint'],
	currentValue: 'meetingpoint',
	mappingKey: {
		polygonCenter: 'distanceToPolygonCenter',
		meetingpoint: 'distanceToMeetingPoint',
	},
})
const distanceTargetUnits = ref({
	allValues: ['km', 'miles'],
	currentValue: 'km',
})

const usersDistancesByTypesCpy = ref([])
const currentZoomAndViewCoords = ref(null)
const lockedUsersAlreadySelectedByTeam = ref({})
const selectedTeams = ref([])
const activeNames = ref(['result-search'])
const mapInstance = ref(null)
const searchQuery = ref('')
const searchBarIsOpen = ref(false)
const cardMoreDetails = ref({
	type: 'user',
	itemId: null,
	visible: false,
})

const aiIsUseless = ref(false)
const aiIsUselessCardVisibile = ref(false)

const aiPolygon = ref(null)
const activeMouseMoveDetection = ref(false)

const showFilters = ref(false)

const whichPartDisplay = ref('listing')
const isAiSettings = ref(true)

import { useI18n } from 'vue-i18n'
import { Feature, Point, Polygon } from '@turf/turf'

const { t } = useI18n()

const selectionTypes = ref({
	allValues: [
		{ value: 'customSelection', label: computed(() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.CUSTOM_SELECTION')), icon: 'gui-ministars text-xl' },
		{ value: 'allUsers', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.ALL_USERS')), icon: '' },
		{ value: 'open', label: computed (() => t('ACTION.CALL_TO_ACTION.ASSIGNMENT_V2.SELECTION_TYPE.OPEN')), icon: '' },
	],
	currentValue: 'customSelection',
})

const usersSuggestionsList = ref([])

const methodSetWichListDisplay = (aValue) => {
	whichListDisplay.value = aValue
	if (aValue === 'usersAndTeams') {
		concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
	}
}

const methodUpdateLeadersAction = (aNewLeader) => {
	let	findedCurrentSelectedUser = selectedUsers.value.find((user) => user.id === aNewLeader.id)
	let aNewLeaderUser = usersWithDistances.value.find((user) => user.id === aNewLeader.id)

	if (!findedCurrentSelectedUser) {
		if (selectedActionLeaders.value.length < leadersMaxLength.value) {
			if (aNewLeaderUser) {
				selectedUsers.value = [...selectedUsers.value, aNewLeaderUser]
			}

			if (aNewLeaderUser?.id) {
				methodUpdateLeadersAction(cloneDeep(aNewLeaderUser))
			}
		}
	}
	else {
		if (methodReturnOnlyIds(selectedActionLeaders.value).includes(aNewLeader.id)) {
			findedCurrentSelectedUser.isActionLeader = false
			selectedActionLeaders.value = selectedActionLeaders.value.filter((leader) => leader.id !== aNewLeader.id)
		}
		else {
			if (selectedActionLeaders.value.length < leadersMaxLength.value) {
				selectedActionLeaders.value = [...selectedActionLeaders.value, aNewLeaderUser]
				findedCurrentSelectedUser.isActionLeader = true
			}
		}
	}

	store.dispatch('@action/actionHandleNewActionLeaders', selectedActionLeaders.value || [])
	methodSetAllMarkerClasses()
}

const toggleDropdownSort = () => {
	dropdownVisibleSort.value = !dropdownVisibleSort.value // Bascule l'affichage
}

const methodSetAllMarkerClasses = () => {
	const allMembersInTeamsIds = selectedTeams.value.flatMap((team) => {
		const memberIds = team.users.map((user) => user.id)
		const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
		return [...memberIds, ...leaderIds]
	})

	const usersInteamsForLockByTeams = usersWithDistances.value.filter((aUser) => allMembersInTeamsIds.includes(aUser.id))

	resetMarkerCheckedClass()
	addCheckedClassToSelectedUsers(selectedUsers.value)
	addCheckedClassToSelectedActionLeaders(selectedActionLeaders.value)
	addLockedByTeamClassToUsers(usersInteamsForLockByTeams)
	removeGrayScaledClassToSelectedUsers(selectedUsers.value)
}

const methodDistanceTargetChange = (aFilter) => {
	removeAllMapMouseMoveHandlers(mapInstance.value)
	activeMouseMoveDetection.value = false
	const valueSelected = cloneDeep(aFilter).currentValue
	let whichCircle = valueSelected === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	let tmpPolygon = valueSelected === 'meetingpoint' ? transformPointToMiniPolygon(localMeetingPoint.value[0]) : newActionPolygon.value
	adjustZoomAndView(mapInstance.value, tmpPolygon, adjustZoomAndViewCallBack)
	switch (valueSelected) {
		case 'meetingpoint':
			activeMouseMoveDetection.value = true
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'visible', whichCircle)
			break
		default:
			activeMouseMoveDetection.value = true
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingPoint')
			setCircleLayerVisibility(mapInstance.value, 'visible', whichCircle)
			break
	}
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue
	startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle, currentDistanceUnitChoice)
}
const methodDistanceUnitChange = (aFilter) => {
	removeAllMapMouseMoveHandlers(mapInstance.value)

	methodAreaRangeChange(0)
	methodAreaRangeChange(0)
	updateCircleFromFilter(0)

	activeMouseMoveDetection.value = false
	activeMouseMoveDetection.value = true

	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	let tmpPolygon = filterDistanceTarget.currentValue === 'meetingpoint' ? transformPointToMiniPolygon(localMeetingPoint.value[0]) : newActionPolygon.value
	adjustZoomAndView(mapInstance.value, tmpPolygon, adjustZoomAndViewCallBack)
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')

	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	const mapRangeConverted = methodConvertUnits(computedMapRange.value.currentValue, currentDistanceUnitChoice)
	computedMapRange.value.currentValue = mapRangeConverted

	startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle, currentDistanceUnitChoice)
	computedMapRange.value.maxValue = methodConvertUnits(100, currentDistanceUnitChoice)
}

const methodSelectTeamOrUser = (aFilter) => {
	const cpyCurrentValue = cloneDeep(aFilter.currentValue)
	methodResetAllOwnSelectionFilters()
	switch (cpyCurrentValue) {
		case 'teamsOnly':
			preSelectedUsers.value = []
			preSelectedTeams.value = getTeams.value

			break
		case 'usersOnly':
			preSelectedTeams.value = []
			preSelectedUsers.value = usersWithDistances.value
			break

		case 'teamsAndUser':
			preSelectedTeams.value = getTeams.value
			preSelectedUsers.value = usersWithDistances.value
			break

		default:
			break
	}
	aFilter.currentValue = cpyCurrentValue
}

const methodAllowAiSelection = () => {
	disabledAiSelection.value = false
}
const methodAreaModification = (aFilter) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	activeMouseMoveDetection.value = false
	let whichCircle
	let tmpPolygon
	switch (aFilter.currentValue) {
		case 'radius':
			activeMouseMoveDetection.value = true
			preSelectedUsers.value = []
			preSelectedTeams.value = []
			setCircleLayerVisibility(mapInstance.value, 'visible')
			setLineVisibility(mapInstance.value, 'visible')
			colorActionPolygon(mapInstance.value, '#000')

			if (filterDistanceTarget.currentValue === 'meetingpoint' && !isEmpty(localMeetingPoint.value[0])) {
				tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
				whichCircle = 'meetingPoint'
			}
			else {
				tmpPolygon = newActionPolygon.value
				whichCircle = 'polygon'
			}

			methodDistanceTargetChange(filterDistanceTarget)
			// startUpdateCircleFromMap(mapInstance.value, tmpPolygon, methodAreaRangeChange, activeMouseMoveDetection, whichCircle)
			preSelectedUsers.value = methodStartFilters()

			break
		case 'actionArea':
			preSelectedTeams.value = []
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#ff387f')
			adjustZoomAndView(mapInstance.value, newActionPolygon.value, adjustZoomAndViewCallBack)

			preSelectedUsers.value = methodStartFilters()
			break

		case 'allUsers':
			preSelectedTeams.value = []
			preSelectedUsers.value = usersWithDistances.value
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#000')
			preSelectedUsers.value = methodStartFilters()
			break

		default:
			setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
			setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
			setLineVisibility(mapInstance.value, 'none')
			colorActionPolygon(mapInstance.value, '#000')
			break
	}
}

const methodOwnSelection = () => {
	selectedTeams.value = []
	selectedUsers.value = cloneDeep(selectedActionLeaders.value)
	showFilters.value = false
	isAiSettings.value = false
	methodGoToSearchListing()
}

const methodClearAllSelection = () => {
	let allSelectedLeadersIds = methodReturnOnlyIds(selectedActionLeaders.value)
	let saveSelectedUsersWhoAreActionLeaders = cloneDeep(selectedUsers.value.filter((aUser) => allSelectedLeadersIds.includes(aUser.id)))
	selectedTeams.value = []
	selectedUsers.value = saveSelectedUsersWhoAreActionLeaders
	selectedActionLeaders.value = []
	const tempLeaders = usersWithDistances.value.filter((aUser) => allSelectedLeadersIds.includes(aUser.id))
	tempLeaders.forEach((element) => {
		methodUpdateLeadersAction(element)
	})

	// selectedActionLeaders.value = []
}

const methodAddUserOrTeamFromAi = () => {
	isAiSettings.value = false
	methodGoToSearchListing()
}

const methodTriggerCounterCard = () => {
	if (whichPartDisplay.value === 'listing') {
		methodGoToSearchListing()
	}
	else {
		methodGoToListing()
	}
}

const methodGoToListing = () => {
	showFilters.value = false
	methodResetAllOwnSelectionFilters()
	whichPartDisplay.value = 'listing'
	searchQuery.value = ''
	searchBarIsOpen.value = false
	setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	setAiLayerVisibility(mapInstance.value, 'none')
	colorActionPolygon(mapInstance.value, '#000')
	methodSetWichListDisplay('usersAndTeams')
}
const methodGoToFilterView = () => {
	latestListPart.value = whichPartDisplay.value
	whichPartDisplay.value = 'filters'
	searchQuery.value = ''
	searchBarIsOpen.value = false
	setCircleLayerVisibility(mapInstance.value, 'visible')
	setAiLayerVisibility(mapInstance.value, 'none')
}

const adjustZoomAndViewCallBack = (aNewZoomAndView) => {
	currentZoomAndViewCoords.value = aNewZoomAndView[0]
}

const methodGoToSearchListing = () => {
	whichPartDisplay.value = 'searchListing'
	searchQuery.value = ''
	searchBarIsOpen.value = true
	setCircleLayerVisibility(mapInstance.value, 'none', 'polygon')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	colorActionPolygon(mapInstance.value, '#000')
}

const methodCloseMoreDetails = () => {
	cardMoreDetails.value.visible = false
	cardMoreDetails.value.itemId = null
	store.commit('@user/SET_ACTIVE_USER', {})
}

const methodMergedUsersAndUsersInTeams = (users, teams) => {
	const mergedUsers = [
		...users,
		...teams.flatMap((team) => team.users || []),
	]

	const uniqueUsers = uniqBy(mergedUsers, 'id')

	return uniqueUsers || 0
}

const methodGetDistanceToFeature = (point: Feature<Point>, feature: Feature<Point> | Feature<Polygon>) => {
	if (!feature.geometry) {
		console.error('Feature has no geometry:', feature)
		return undefined
	}

	if (feature.geometry?.type === 'Point') {
		return turf.distance(point, feature, { units: 'kilometers' })
	}

	if (feature.geometry?.type === 'Polygon') {
		if (turf.booleanPointInPolygon(point, feature)) {
			return 0
		}
		const polyBorder = turf.lineString(feature.geometry.coordinates[0])
		return turf.nearestPointOnLine(polyBorder, point, { units: 'kilometers' }).properties.dist
	}
}

const computedReturnTabCountNumberTeams = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? selectedTeams.value.length : getTeams.value.length
})

const computedReturnTabCountNumberUsers = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? selectedUsers.value.length : users.value.length
})

const computedReturnTabCountNumberUsersAndTeams = computed(() => {
	let isSelectedListing = whichPartDisplay.value === 'listing'

	return isSelectedListing ? (selectedUsers.value.length || 0) + (selectedTeams.value.length || 0) : (users.value.length || 0) + (getTeams.value.length || 0)

	// methodMergedUsersAndUsersInTeams(users.value, getTeams.value).length || 0
})

const computedCurrentSortOrderValue = computed({
	get() {
		switch (whichListDisplay.value) {
			case 'users':
				return sortOrder.value

			case 'teams':
				return sortTeamsOrder.value

			case 'usersAndTeams':
				return sortTeamsAndUsersOrder.value

			default:
				return ''
		}
	},
	set(newValue) {
		switch (whichListDisplay.value) {
			case 'users':
				sortOrder.value = newValue
				break

			case 'teams':
				sortTeamsOrder.value = newValue
				break

			case 'usersAndTeams':
				sortTeamsAndUsersOrder.value = newValue
				break
		}
	},
})

const computedSortOrderValuesByWhichListDisplay = computed(() => {
	let values = []
	switch (whichListDisplay.value) {
		case 'users':
			values = sortOrderValues
			break
			// return sortOrderValues

		case 'teams':
			values = sortOrderTeamValues
			break
			// return sortOrderTeamValues

		case 'usersAndTeams':
			values = sortOrderTeamAndUsersValues
			break
			// return sortOrderTeamAndUsersValues

		default:
			return values
	}
	return methodRemoveDistanceSortPossibilitiesIfNoCenter(values)
})
// sortOrderValues

const computedHasCorrectMeetingPoint = computed(() => {
	return localMeetingPoint.value && !isEmpty(localMeetingPoint.value[0] && localMeetingPoint.value[0].latitude && localMeetingPoint.value[0].longitude)
})

const computedUserDistancesByType = computed(() => {
	const tmpUsersDistancesByTypesCpy = users.value.map((el) => {
		if (!el.location || !el.location.includes(',')) {
			return { ...el, locationError: 'No valid location provided' }
		}

		const [lat, lng] = el.location.split(',')
		const point = turf.point([Number(lng), Number(lat)])

		const userWithDistances = { ...el }
		if (computedHasCorrectMeetingPoint.value) {
			const meetingPointGeoJSON = convertToGeoJSONPoint(localMeetingPoint.value[0])
			userWithDistances.distanceToMeetingPoint = methodGetDistanceToFeature(point, meetingPointGeoJSON)
		}

		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			let polygonFormated = convertToGeoJSONPolygon(newActionPolygon.value)

			userWithDistances.distanceToPolygonCenter = methodGetDistanceToFeature(point, turf.center(polygonFormated))
			userWithDistances.distanceToPolygonBorder = methodGetDistanceToFeature(point, polygonFormated)
		}

		return userWithDistances
	})

	return tmpUsersDistancesByTypesCpy
})

const convertToGeoJSONPoint = (meetingPoint) => {
	if (meetingPoint) {
		return turf.point([parseFloat(meetingPoint.longitude), parseFloat(meetingPoint.latitude)])
	}
	else {
		return null
	}
}

const methodRemoveDistanceSortPossibilitiesIfNoCenter = (sortValues) => {
	const hasPolygon = computedNewActionPolygon.value && !isEmpty(computedNewActionPolygon.value)
	const hasMeetingPoint = computedHasCorrectMeetingPoint.value

	if (!hasPolygon && !hasMeetingPoint) {
		sortOrder.value = 'asc_name'
		sortTeamsOrder.value = 'asc_name'
		sortTeamsAndUsersOrder.value = 'asc_name'

		return sortValues.filter((aValue) => aValue !== 'asc' && aValue !== 'desc')
	}
	else {
		return sortValues
	}
}

const convertToGeoJSONPolygon = (polygon) => {
	const coordinates = polygon.map((point) => [point.lng, point.lat])

	// Fermer le polygone en répétant le premier point à la fin si nécessaire
	if (coordinates[0][0] !== coordinates[coordinates.length - 1][0]
		|| coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
		coordinates.push(coordinates[0])
	}

	return turf.polygon([coordinates])
}

const methodFindSmartDistanceToFeature = (distanceType: string) => {
	const levelDistanceKm = 0.250
	const maxDistanceKm = 25
	const sortedDistances = computedUserDistancesByType.value.map((obj) => obj[distanceType]).sort()
	const rawDistance = computedUserDistancesByType.value.length === 0 ? maxDistanceKm : computedUserDistancesByType.value.length === 1 ? sortedDistances.at(0) : computedUserDistancesByType.value.length > 2 && sortedDistances.at(2) - sortedDistances.at(1) <= levelDistanceKm ? sortedDistances.at(1) + levelDistanceKm : sortedDistances.at(1)

	usersDistancesByTypesCpy.value = cloneDeep(computedUserDistancesByType.value)
	const roundedDistance = Math.ceil(rawDistance / levelDistanceKm) * levelDistanceKm
	return Math.min(maxDistanceKm, roundedDistance)
}
const methodIncreaseFeatureByDistance = (feature: Feature<Point> | Feature<Polygon>, distanceKm: number) => {
	return turf.buffer(feature, distanceKm, { units: 'kilometers' })
}

// const methodSetDefaultPolygonBasedOnGeoHashContacts = (currentUnitValue, geoHashContactArray) => {
// 	if (!disabledAiForActionType.value.includes(actionType.value)) {
// 		if (geoHashContactArray && geoHashContactArray.length) {
// 			let units = mappingUnits(currentUnitValue)
// 			const hashes = geoHashContactArray
// 				.map(([lat, lng, count]) => ({ lat: Number(lat), lng: Number(lng), count }))
// 				.filter(({ lat, lng }) => !latLngIsInNorthPole(lat, lng))

// 			let pointFeatures = null
// 			let center = null
// 			if (hashes.length) {
// 				pointFeatures = hashes.map(({ lat, lng, count }) => turf.point([lng, lat], { count }))
// 			}

// 			if (pointFeatures) {
// 				center = turf.centerMedian(turf.featureCollection(pointFeatures), { weight: 'count' })
// 				pointFeatures.forEach((p) => p.properties.distanceToCenter = turf.distance(p, center, { units: units }))
// 				// We consider the distance to follow a log-normal distribution since all values are positive
// 				// https://en.wikipedia.org/wiki/Log-normal_distribution
// 				const distanceLogs = pointFeatures.map((p) => Math.log(p.properties.distanceToCenter))
// 				// Estimate the parameters mu and sigma of the log-normal distribution
// 				const distanceLogMean = distanceLogs.reduce((a, b) => a + b) / pointFeatures.length
// 				const distanceLogStd = Math.sqrt(distanceLogs.map((d) => Math.pow(d - distanceLogMean, 2)).reduce((a, b) => a + b) / pointFeatures.length)
// 				// Don't accept contacts too far away from the center of mass
// 				const maxAllowedDistanceKm = Math.max(10, Math.exp(distanceLogMean - 0.5 * distanceLogStd))
// 				const filteredPoints = pointFeatures.filter((p) => p.properties.distanceToCenter <= maxAllowedDistanceKm)

// 				if (filteredPoints && filteredPoints.length > 0) {
// 					const polygon = turf.convex(turf.featureCollection(filteredPoints))
// 					if (polygon) {
// 						// Extends the polygon, making it bigger, rounder and better looking :D (subjective)
// 						const buffered = turf.buffer(polygon, 500, { units: 'meters' })
// 						return buffered.geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }))
// 					}
// 				}
// 			}
// 		}
// 	}

// 	return null
// }

const newActionPolygon = ref(null)
const newLeadersAction = ref(store.getters['@action/getNewActionLeaders'] ? store.getters['@action/getNewActionLeaders'] : [])
const leadersMaxLength = ref(store.getters['@action/getNewActionLeadersMaxLength'] ? store.getters['@action/getNewActionLeadersMaxLength'] : 3)

const computedNewActionPolygon = computed(() => {
	return newActionPolygon.value
})

const concatenedSelectedTeamsAndUsers = ref([])
const methodConcatSelectedTeamsAndUsers = () => {
	concatenedSelectedTeamsAndUsers.value = [...selectedTeams.value, ...selectedUsers.value]

	return methodSortUsersAndTeams(concatenedSelectedTeamsAndUsers.value)
}

const disabledAiForActionType = ref(['call', 'event_online', 'share', 'sharing'])
const distanceTarget = ref('center')

const ownSelectionFilters = ref([
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'teamOrUser', values: ['usersOnly', 'teamsOnly', 'teamsAndUser'], currentValue: 'usersOnly', title: '', type: 'card', customMethod: (aFilter) => methodSelectTeamOrUser(aFilter) },
	{ disabledForActionType: ['call', 'event_online', 'share'], usedForFilterCount: true, category: 'selection', values: ['actionArea', 'radius'], currentValue: '', title: 'selection', type: 'card',
		customMethod: (aFilter) => methodAreaModification(aFilter), disabled: computed(() => computedDisabledSelection.value) },
	{
		disabledForActionType: [''], usedForFilterCount: false, category: 'ageRange', values: [18, 25, 35, 45, 55, 65], currentValue: 0, title: '', type: 'slider' },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceType', values: ['distance', 'time'], currentValue: 'distance', title: '', type: 'dropdown' },
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'mapRange', values: [], currentValue: 0, title: '', maxValue: 100 },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceUnit', values: ['km', 'miles'], currentValue: 'km', title: '', type: 'dropdown', customMethod: () => methodDistanceUnitChange() },
	{ disabledForActionType: ['call', 'event_online', 'share', 'sharing'], usedForFilterCount: false, category: 'distanceTarget', values: ['polygonCenter', 'meetingpoint'], currentValue: '', title: '', type: 'dropdown', customMethod: (aFilter) => methodDistanceTargetChange(aFilter) },
	{ disabledForActionType: [''], usedForFilterCount: true, category: 'disponibility', values: ['available_all_the_time', 'available_holidays', 'available_week_morning', 'available_week_night', 'available_weekend'], selectAllValue: 'available_all_the_time', currentValue: [], title: '', isMultiSelect: true, type: 'card', customMethod: (aFilter) => preSelectedUsers.value = methodStartFilters() },
])

const UserAvatar = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/UserAvatar.vue'),
)

const ActionAssignmentListing = defineAsyncComponent(
	() => import('./ActionAssignmentListing.vue'),
)

const cardDetailsInfos = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/sidebars/users/cardDetailsInfos.vue'),
)

const ActionAssignmentSearchListing = defineAsyncComponent(
	() => import('./ActionAssignmentSearchListing.vue'),
)
const ActionAssignmentMap = defineAsyncComponent(
	() => import('./ActionAssignmentMap.vue'),
)

const ActionAssignmentFilters = defineAsyncComponent(
	() => import('./ActionAssignmentFilters.vue'),
)
const ActionAssignmentSearchAndFilterHeader = defineAsyncComponent(
	() => import('./ActionAssignmentSearchAndFilterHeader.vue'),
)
const ActionAiAssignmentCard = defineAsyncComponent(
	() => import('./ActionAiAssignmentCard.vue'),
)
const ActionAssignmentSelectedCounterCard = defineAsyncComponent(
	() => import('./ActionAssignmentSelectedCounterCard.vue'),
)
const ActionAssignmentFilterContent = defineAsyncComponent(
	() => import('./ActionAssignmentFilterContent.vue'),
)

const CardSlideContainer = defineAsyncComponent(
	() => import('../../../general/CardSlideContainer.vue'),
)
const DeleteDialog = defineAsyncComponent(
	() => import('../../../user_management-module/user-list/sub-components/deleteDialog.vue'),
)

const methodUpdateCurrentActionFromAssignment = (data, type) => {
	let dataFormated = data

	if (type === 'users') {
		const allUsersIdsInTeams = selectedTeams.value.flatMap((team) => {
			const memberIds = team.users.map((user) => user.id)
			const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
			return [...memberIds, ...leaderIds]
		})

		const allActionLeaderIds = selectedActionLeaders.value.map((aLeader) => aLeader.id)

		dataFormated = dataFormated.filter((aUser) => !allUsersIdsInTeams.includes(aUser.id) && !allActionLeaderIds.includes(aUser.id))
	}

	props.methodSetCurrentActionFromAssignment(dataFormated, type)
}

const methodUpdateSelectedItemsActionFromAssignment = () => {
	methodUpdateCurrentActionFromAssignment(selectedTeams.value, 'teams')
	methodUpdateCurrentActionFromAssignment(selectedUsers.value, 'users')
	methodUpdateCurrentActionFromAssignment(selectedActionLeaders, 'leaders')
}

watch(selectedUsers, (newSelectedUsers) => {
	if (newSelectedUsers) {
		methodSetAllMarkerClasses()
		selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
		if (!props.isNewAction) {
			methodUpdateSelectedItemsActionFromAssignment()
		}
	}

	concatenedSelectedTeamsAndUsers.value = cloneDeep(methodConcatSelectedTeamsAndUsers())
})

watch(selectedTeams, (newSelectedTeams) => {
	if (newSelectedTeams) {
		methodSetAllMarkerClasses()
		if (!props.isNewAction) {
			methodUpdateSelectedItemsActionFromAssignment()
		}
	}

	concatenedSelectedTeamsAndUsers.value = cloneDeep(methodConcatSelectedTeamsAndUsers())
})
watch(selectedActionLeaders, (newSelectedActionLeaders) => {
	if (!props.isNewAction && newSelectedActionLeaders) {
		methodUpdateSelectedItemsActionFromAssignment()
	}
})

watch(preSelectedUsers, (newPreSelectedUsers, oldPreselectedUsers) => {
	if (newPreSelectedUsers && whichPartDisplay.value === 'filters') {
		const allMembersInTeamsIds = selectedTeams.value.flatMap((team) => {
			const memberIds = team.users.map((user) => user.id)
			const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
			return [...memberIds, ...leaderIds]
		})

		const usersInteamsForLockByTeams = usersWithDistances.value.filter((aUser) => allMembersInTeamsIds.includes(aUser.id))

		const usersWithNewCheck = computedDiffBeetweenSelectedAndPreselected.value.diffUsers
		resetMarkerCheckedClass()
		addUsernameTooltipClassUsers(users.value)
		addCheckedClassToSelectedUsers(selectedUsers.value)
		addLockedClassToSelectedUsers(selectedUsers.value)
		addCheckedClassToSelectedUsers(usersWithNewCheck)
		addLockedByTeamClassToUsers(usersInteamsForLockByTeams)

		selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
	}
})
watch(showFilters, (value) => {
	if (value && value === true && whichPartDisplay.value === 'filters') {
		let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
		if (computedHasCorrectMeetingPoint.value) {
			filterDistanceTarget.currentValue = 'meetingpoint'
		}
		else {
			if (newActionPolygon.value && newActionPolygon.value.length) {
				filterDistanceTarget.currentValue = 'polygonCenter'
			}
		}

		addLockedClassToSelectedUsers(selectedUsers.value)
	}
	else {
		methodSetAllMarkerClasses()
	}
	if (value && value === true) {
		methodRecenterViewFromPolygon()
	}
	methodCloseMoreDetails()
	methodResetAllOwnSelectionFilters()
	methodSetUsersAsDefaultFilters()
	methodSetWichListDisplay('usersAndTeams')
})

watch(mapFullyLoaded, (newVal, oldValue) => {
	if (newVal) {
		if (props.currentActionData || (disabledAiForActionType.value.includes(actionType.value) && (isEmpty(localMeetingPoint.value[0] && isEmpty(newActionPolygon.value))))) {
			isAiSettings.value = false
			methodResetToAiSelection()
			methodStartFromEditSelection()

			if (!props.isNewAction) {
				isAiSettings.value = false
				methodGoToListing()
			}
			else {
				isAiSettings.value = false
				methodGoToSearchListing()
				if (newLeadersAction.value.length) {
					newLeadersAction.value.forEach((aLeader) => {
						methodUpdateLeadersAction(cloneDeep(aLeader))
					})
				}
			}
		}

		if (isAiSettings.value) {
			if (newActionPolygon.value && !isEmpty(newActionPolygon.value) || !isEmpty(localMeetingPoint.value[0])) {
				if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
					addActionPolygon(mapInstance.value, newActionPolygon.value)
				}

				if (!disabledAiSelection.value) {
					methodStartFilters()
					methodResetToAiSelection()
				}
				else {
					methodAllowAiSelection()
					selectedUsers.value = methodStartFilters()
				}
			}
			else {
				if (!disabledAiForActionType.value.includes(actionType.value)) {
					isAiSettings.value = false
					aiIsUseless.value = true
					aiIsUselessCardVisibile.value = true
				}
				methodGoToSearchListing()
			}
		}
	}
	addUsernameTooltipClassUsers(users.value)
	selectedUsers.value = methodSortSelectedUsers()
})

watch(activeMouseMoveDetection, (newValue) => {
	if (!newValue || newValue === false) {
		removeAllMapMouseMoveHandlers(mapInstance.value)
	}
})

// Computed

const computedDisabledSelection = computed(() => {
	const hasPolygon = computedNewActionPolygon.value && !isEmpty(computedNewActionPolygon.value)
	const hasMeetingPoint = computedHasCorrectMeetingPoint.value

	let restrictions = []

	if (!hasPolygon) {
		restrictions.push('actionArea')
	}

	if (!hasPolygon && !hasMeetingPoint) {
		restrictions.push('radius')
	}

	return restrictions
})

const computedFiltersActiveLength = computed(() => {
	const activeFilters = cloneDeep(ownSelectionFilters.value.filter((aFilter) => {
		const currentValue = aFilter.currentValue

		return currentValue !== 0 && String(currentValue).length > 0 && aFilter.usedForFilterCount
	}) || [])
	return activeFilters.length
})
const computedMoreDetails = computed(() => {
	if (cardMoreDetails.value.type === 'user') {
		return usersWithDistances.value.find((user) => user.id === cardMoreDetails.value.itemId) || {}
	}
	else {
		return getTeams.value.find((team) => team.id === cardMoreDetails.value.itemId) || {}
	}
})

const computedDiffBeetweenSelectedAndPreselected = computed(() => {
	let diffTeams = preSelectedTeams.value.filter((aTeam) => !selectedTeams.value.find((selectedTeam) => selectedTeam.id === aTeam.id))
	const idsOfUsersInAllTeams = diffTeams.flatMap((team) => {
		const memberIds = team.users.map((user) => user.id)
		const leaderIds = team.leaders ? team.leaders.map((leader) => leader.id) : []
		return [...memberIds, ...leaderIds]
	})

	let diffUsers = preSelectedUsers.value.filter((aUser) => !selectedUsers.value.find((selectedUser) => selectedUser.id === aUser.id) && !idsOfUsersInAllTeams.includes(aUser.id))

	// return true
	return { diffUsers, diffTeams }
})

const methodRecenterViewFromPolygon = () => {
	if (isAiSettings.value) {
		if (aiPolygon.value && !isEmpty(aiPolygon.value)) {
			const aiPolygonCoordinates = aiPolygon.value.geometry.coordinates[0]
			if (aiPolygonCoordinates && aiPolygonCoordinates.length) {
				const formattedCoordinates = aiPolygonCoordinates.map((coord) => [coord[0], coord[1]])
				adjustZoomAndView(mapInstance.value, formattedCoordinates, adjustZoomAndViewCallBack)
			}
		}
	}
	else {
		if (computedHasCorrectMeetingPoint.value) {
			const meetingPoint = convertToGeoJSONPoint(localMeetingPoint.value[0])
			if (meetingPoint) {
				const coordinates = [
					{
						lng: meetingPoint.geometry.coordinates[0],
						lat: meetingPoint.geometry.coordinates[1],
					},
				]
				adjustZoomAndView(mapInstance.value, coordinates, adjustZoomAndViewCallBack)
			}
		}
		else {
			const polygonCoordinates = cloneDeep(newActionPolygon?.value?.map((point) => [point.lng, point.lat])) || []
			if (polygonCoordinates && polygonCoordinates.length) {
				adjustZoomAndView(mapInstance.value, polygonCoordinates, adjustZoomAndViewCallBack)
			}
		}
	}
}

const submitAction = () => {
	const idsSelectedLeadersActionIds = methodReturnOnlyIds(selectedActionLeaders.value)
	const teamsIds = selectedTeams.value.map((team) => team.id)

	let usersIds = selectedUsers.value.map((user) => user.id)
	usersIds = usersIds.filter((userId) => !idsSelectedLeadersActionIds.includes(userId))

	const addressIncluded = cloneDeep(getSearchAddressIncluded.value)
	const pollingStationsIncluded = cloneDeep(getSearchPollingStationsIncluded.value)
	const allowUnknowAddressesOnActionCreation = cloneDeep(getAllowUnknowAddressesOnActionCreation.value)

	const needToBeEmptyAction = false

	store.dispatch('@action/actionSkipNavigationGuardBeforeRouteLeave', true)

	let clonedNewAction = cloneDeep(newAction.value)
	const payload = {
		needToBeEmptyAction,
		action: {
			...clonedNewAction,
			usersIds,
			teamsIds,
			notify: true,
		},
		payload: {
			addressIncluded,
			pollingStationsIncluded,
			allowUnknowAddressesOnActionCreation,
		},
	}

	store.dispatch('@action/actionCreateNewFieldAction', payload)

	// Track action created
	analytics.track(TRACKING.action_created, { type_action: clonedNewAction.type })

	router.push({
		name: 'actions',
		query: {
			action_created: 1,
		},
	})

	nextTick(() => {
		ElMessage({
			message: t('ACTION.CALL_TO_ACTION.SUBMIT_INFO'),
			type: 'info',
			showClose: true,
		})
	})
}

onMounted(() => {
	const { polygon } = props.actionDetailMapData || []

	let currentGeoCountry = getGeoCodageCountry.value || 'FRA'
	const defaultUnits = getDefaultUnitByGeoCountry(currentGeoCountry)

	if (defaultUnits === 'miles') {
		distanceTargetUnits.value.currentValue = defaultUnits
		let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
		filterDistanceUnit.currentValue = defaultUnits
	}

	const storePolygon = store.getters['@action/getNewActionPolygon']

	if (props.isNewAction) {
		localMeetingPoint.value = newAction.value?.Addresses || []
		actionType.value = newAction.value.type || ''
	}
	else {
		localMeetingPoint.value = props.currentActionData.Addresses || []
		actionType.value = props.currentActionData.type_data || ''
	}

	if (actionType.value === 'event' && (!localMeetingPoint.value[0] || isEmpty(localMeetingPoint.value[0]))) {
		actionType.value = 'event_online'
	}

	newActionPolygon.value = !isEmpty(polygon) && JSON.parse(polygon).length
		? JSON.parse(polygon)
		: !isEmpty(storePolygon)
				? storePolygon
				: methodSetDefaultPolygonBasedOnGeoHashContacts(distanceTargetUnits.value.currentValue, computedGeohashArray.value, disabledAiForActionType.value.includes(actionType.value))
	if (computedHasCorrectMeetingPoint.value) {
		methodChangeUsersDistanceTarget('meetingpoint')
		distanceTargetSort.value.currentValue = 'meetingpoint'
	}
	else {
		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			methodChangeUsersDistanceTarget('polygonCenter')
			distanceTargetSort.value.currentValue = 'polygonCenter'
		}
		else {
			distanceTargetSort.value.currentValue = ''
		}
	}

	emit('update:nextValidatorBoolean', true)
	emit('update:nextFunction', submitAction)

	if (props.isNewAction) {
		emit('update:nextLabelButton', t('ACTION.CALL_TO_ACTION.CREATE'))
	}
})

onBeforeUnmount(() => {
	// users.value = []
	mapFullyLoaded.value = false
	aiIsUseless.value = false
	aiIsUselessCardVisibile.value = false

	aiPolygon.value = null
	activeMouseMoveDetection.value = false

	showFilters.value = false

	whichPartDisplay.value = 'listing'
})

// methods
const closeDialogResetToAi = () => {
	dialogResetToAiVisible.value = false
}

const methodSetUsersAsDefaultFilters = () => {
	if (whichPartDisplay.value === 'filters') {
		let findedTeamAndUserFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'teamOrUser')
		if (findedTeamAndUserFilter && whichPartDisplay.value === 'filters') {
			findedTeamAndUserFilter.currentValue = 'usersOnly'
		}
	}
}

const methodResetToAiSelection = () => {
	selectionTypes.value.currentValue = 'customSelection'
	isAiSettings.value = true
	closeDialogResetToAi()
	showFilters.value = false

	whichPartDisplay.value = 'listing'
	if ((newActionPolygon.value && !isEmpty(newActionPolygon.value)) || (computedHasCorrectMeetingPoint.value)) {
		let resultStartAreaCircleFromAI = []

		if ((computedHasCorrectMeetingPoint.value)) {
			methodChangeUsersDistanceTarget('meetingpoint')
			resultStartAreaCircleFromAI = methodStartAreaCircleFromAi('meetingpoint')
		}

		if (!resultStartAreaCircleFromAI.length) {
			methodChangeUsersDistanceTarget('polygonCenter')
			resultStartAreaCircleFromAI = methodStartAreaCircleFromAi('polygon')
		}

		if (resultStartAreaCircleFromAI.length) {
			aiIsUselessCardVisibile.value = false
		}
		else {
			aiIsUselessCardVisibile.value = true
			aiIsUseless.value = true
			isAiSettings.value = false
			methodGoToSearchListing()
		}

		selectedUsers.value = resultStartAreaCircleFromAI

		const circleData = getCircleData(mapInstance.value)
		const endCoordsOfLine = getRightmostPointFromCircle(circleData)

		if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
			const center = getCenterOfPolygon(newActionPolygon.value)
			updateLine(mapInstance.value, center, endCoordsOfLine)
		}
	}
	else {
		if (props.isNewAction) {
			aiIsUselessCardVisibile.value = true
		}
	}
	selectedActionLeaders.value = []

	if (props.isNewAction) {
		let idsSelectedLeaders = methodReturnOnlyIds(newAction.value.leaders || [])

		const tempLeaders = usersWithDistances.value.filter((aUser) => idsSelectedLeaders.includes(aUser.id))

		tempLeaders.forEach((element) => {
			methodUpdateLeadersAction(element)
		})
	}
	methodRecenterViewFromPolygon()
}
const methodStartFromEditSelection = () => {
	let propsCurrentActionUsersIds = methodReturnOnlyIds(props.currentActionData?.Users || []) || []
	let propsCurrentActionTeamsIds = methodReturnOnlyIds(props.currentActionData?.Teams || []) || []
	let propsCurrentActionLeadersIds = methodReturnOnlyIds(props.currentActionData?.leaders || []) || []

	let allUsers = usersWithDistances.value.filter((aUser) => propsCurrentActionUsersIds.includes(aUser.id))
	let allLeaders = usersWithDistances.value.filter((aUser) => propsCurrentActionLeadersIds.includes(aUser.id))
	let allTeams = getTeams.value.filter((aTeam) => propsCurrentActionTeamsIds.includes(aTeam.id))

	selectedUsers.value = [...allUsers, ...allLeaders]
	selectedTeams.value = allTeams
	selectedActionLeaders.value = allLeaders

	methodGoToListing()
}

const methodStartAreaCircleFromAi = (whichElement = 'meetingpoint') => {
	selectedTeams.value = []
	let circleSizeLog

	if ((computedHasCorrectMeetingPoint.value) && whichElement === 'meetingpoint') {
		let formatedMeetingPoint = convertToGeoJSONPoint(localMeetingPoint.value[0])
		circleSizeLog = methodFindSmartDistanceToFeature('distanceToMeetingPoint')
		aiPolygon.value = methodIncreaseFeatureByDistance	(formatedMeetingPoint, circleSizeLog)
	}

	if ((newActionPolygon.value && !isEmpty(newActionPolygon.value)) && whichElement === 'polygon') {
		circleSizeLog = methodFindSmartDistanceToFeature('distanceToPolygonCenter')
		let polygonFormated = convertToGeoJSONPolygon(newActionPolygon.value)
		aiPolygon.value = methodIncreaseFeatureByDistance	(polygonFormated, circleSizeLog)
	}

	let usersFiltered = cloneDeep(usersWithDistances.value)
	updateAiPolygonArea(mapInstance.value, aiPolygon.value)
	setCircleLayerVisibility(mapInstance.value, 'none')
	setAiLayerVisibility(mapInstance.value, 'visible')

	// selectedUsers.value = methodFilterSelectedUserInsideCircleArea(usersFiltered)

	const aiResult = methodFilterSelectedUserInsideAiPolygon(usersFiltered)

	return aiResult
}

const methodDisplayUserOrTeamInfos = (item, type) => {
	if (cardMoreDetails.value.itemId === item.id && cardMoreDetails.value.type === type) {
		methodCloseMoreDetails()
	}
	else {
		cardMoreDetails.value.visible = true
		cardMoreDetails.value.itemId = item.id
		cardMoreDetails.value.type = type

		let user = usersWithDistances.value.find((aUser) => aUser.id === item.id)
		let team = getTeams.value.find((aTeam) => aTeam.id === item.id)

		if (type === 'user') {
			store.commit('@user/SET_ACTIVE_USER', user)
		}
		else {
			store.commit('@team/SET_ACTIVE_TEAM', team)
			store.commit('@team/DISPLAY_DETAIL')
		}
	}
}

const methodReturnOnlyIds = (arrOfUsers) => {
	return arrOfUsers.map((aUser) => aUser.id)
}

const methodToggleActionLeaderFromTeamInfosSlider = (aUser) => {
	return methodUpdateLeadersAction(aUser)
}

provide('action-leader-from-assignment-slide', (aUser) => methodToggleActionLeaderFromTeamInfosSlider(aUser))

const computedLeadersFull = computed(() => {
	return selectedActionLeaders.value.length >= leadersMaxLength.value
})

provide('leadersFull', computedLeadersFull)

const methodTriggerSortOrder = (selectedValue) => {
	computedCurrentSortOrderValue.value = selectedValue
	selectedUsers.value = methodSortSelectedUsers()
}
const methodToggleFilters = () => {
	showFilters.value = !showFilters.value
}

const methodMergePreselectedWithSelectedUsers = () => {
	selectedUsers.value = [...selectedUsers.value, ...computedDiffBeetweenSelectedAndPreselected.value.diffUsers]
	selectedTeams.value = [...selectedTeams.value, ...computedDiffBeetweenSelectedAndPreselected.value.diffTeams]
	preSelectedUsers.value = []
	preSelectedTeams.value = []

	whichPartDisplay.value = 'listing'
	methodToggleFilters()
}

const methodCancelPreselectedValues = () => {
	preSelectedUsers.value = []
	preSelectedTeams.value = []
	let latestView = latestListPart.value
	whichPartDisplay.value = latestView === 'filters' ? 'listing' : latestListPart.value
	methodToggleFilters()
}

const methodResetAllOwnSelectionFilters = () => {
	ownSelectionFilters.value.forEach((aFilter) => {
		if (aFilter.isMultiSelect) {
			aFilter.currentValue = []
		}
		else {
			if (isNumber(aFilter.currentValue)) {
				aFilter.currentValue = 0
			}
			else {
				if (aFilter.usedForFilterCount) {
					// if (aFilter.category === 'teamOrUser' && whichPartDisplay.value === 'filters') {
					// 	aFilter.currentValue = 'usersOnly'
					// }
					// else {
					aFilter.currentValue = ''
					// }
				}
			}
		}
	})

	preSelectedTeams.value = []
	preSelectedUsers.value = []
	setCircleLayerVisibility(mapInstance.value, 'none')
	setCircleLayerVisibility(mapInstance.value, 'none', 'meetingpoint')
	colorActionPolygon(mapInstance.value, '#000')
}

const methodAreaRangeChange = (range) => {
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	const tmpRange = methodConvertUnits(range, currentDistanceUnitChoice)

	computedMapRange.value.currentValue = round(tmpRange)
	preSelectedUsers.value = methodStartFilters()
}

const methodAddDistanceBetweenUsersAndActionCenter = () => {
	let tmpPolygon
	if (distanceTargetSort.value.currentValue === 'polygonCenter') {
		tmpPolygon = newActionPolygon.value
	}
	else {
		if (!isEmpty(localMeetingPoint.value[0])) {
			tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
		}
	}
	if (tmpPolygon) {
		return getCenterOfPolygon(tmpPolygon)
	}
}

const calculateDistance = (pointOne, pointTwo) => {
	const distance = turf.distance(pointOne, pointTwo, { units: 'kilometers' })
	return Math.round(distance)
}

const methodConvertUnits = (distance, units) => {
	if (units === 'm') {
		return distance * 1000
	}
	else if (units === 'miles') {
		return Math.round(distance * 0.621371)
	}
	else {
		return distance
	}
}

const convertToKilometers = (distance, units) => {
	if (units === 'm') {
		return distance / 1000 // Convertir les mètres en kilomètres
	}
	else if (units === 'miles') {
		return distance / 0.621371 // Convertir les miles en kilomètres
	}
	else {
		return distance // Déjà en kilomètres
	}
}

const returnUsersWithDistances = () => {
	let centerOfAction
	let usersPopulatedByTeams
	// if (newActionPolygon.value) centerOfAction = methodAddDistanceBetweenUsersAndActionCenter()
	if (mapInstance.value === null) {
		usersPopulatedByTeams = getUsersPopulatedByTeams(users.value, getTeams.value)
	}
	else {
		let usersWithDistance = users.value.map((user) => {
			if (!user.location || (!newActionPolygon.value || isEmpty(newActionPolygon.value))) {
				user.selectedAt = Date.now()

				return user
			}
			else {
				const [lng, lat] = user.location.split(',').map(Number).reverse()

				let findedGreatKey = distanceTargetSort.value.mappingKey[distanceTargetSort.value.currentValue]
				let findedUser = usersDistancesByTypesCpy.value.find((aUser) => aUser.id === user.id)

				if (findedUser) {
					let result = Math.round(findedUser[findedGreatKey]) || null

					if (result) {
						result = methodConvertUnits(result, distanceTargetUnits.value.currentValue)
					}

					else {
						user.distance = null
					}
					user.distance = result
				}
				else {
					user.distance = null
				}

				user.selectedAt = Date.now()
				return user
			}
		})

		usersPopulatedByTeams = getUsersPopulatedByTeams(usersWithDistance, getTeams.value)

		const teamUserMap = {}

		selectedTeams.value.forEach((team) => {
			team.users.forEach((user) => {
				if (!teamUserMap[user.id]) {
					teamUserMap[user.id] = []
				}
				teamUserMap[user.id].push(team)
			})
		})

		usersPopulatedByTeams = usersPopulatedByTeams.map((user) => ({
			...user,
			isLockedByTeam: teamUserMap[user.id] || [],
		}))
	}

	usersPopulatedByTeams = methodSortUsers(usersPopulatedByTeams)

	usersSuggestionsList.value = cloneDeep(usersPopulatedByTeams.slice(0, 19))

	return usersPopulatedByTeams
}
const computedMapRange = computed(() => {
	const mapRangeFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'mapRange')

	return mapRangeFilter
})

const methodChangeUsersDistanceTarget = (aValueSelected) => {
	distanceTargetSort.value.currentValue = cloneDeep(aValueSelected)

	selectedUsers.value.forEach((aSelectedUser) => {
		aSelectedUser.distance = usersWithDistances.value.find((aUser) => aUser.id === aSelectedUser.id).distance
	})
	concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
}
const methodChangeUsersDistanceUnit = (aValueSelected) => {
	distanceTargetUnits.value.currentValue = cloneDeep(aValueSelected)

	selectedUsers.value.forEach((aSelectedUser) => {
		aSelectedUser.distance = usersWithDistances.value.find((aUser) => aUser.id === aSelectedUser.id).distance
	})
	concatenedSelectedTeamsAndUsers.value = methodConcatSelectedTeamsAndUsers()
}

const methodSortUsers = (users) => {
	const allSelectedLeadersId = methodReturnOnlyIds(selectedActionLeaders.value)
	let result

	switch (sortOrder.value) {
		case 'selectedAt':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => new Date(user.selectedAt),
				],
				['asc', 'asc', 'desc'],
			)
			break

		case 'asc_name':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.surname,
				],
				['asc', 'asc', 'asc'],
			)
			break

		case 'desc_name':
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.surname,
				],
				['asc', 'asc', 'desc'],
			)
			break

		default:
			// Tri par distance
			result = orderBy(
				users,
				[
					(user) => !allSelectedLeadersId.includes(user.id),
					(user) => !user.distance,
					(user) => user.distance,
				],
				['asc', 'asc', sortOrder.value],
			)
			break
	}

	return result
}

const methodSortedTeams = (teams) => {
	let result
	switch (sortTeamsOrder.value) {
		case 'selectedAt':

			result = orderBy(
				teams,
				[
					(item) => !item.selectedAt,
					(item) => item.selectedAt,
				],
				['desc'],
			)
			break
		case 'asc_name':
			result = orderBy(
				teams,
				[(item) => item.name],
				['asc'],
			)
			break
		case 'desc_name':

			result = orderBy(
				teams,
				[(item) => item.name],
				['desc'],
			)

			break

		default:
			result = orderBy(
				teams,
				[
					(item) => !item.distance,
					(item) => item.distance,
				],
				[
					'asc',
					sortTeamsOrder.value,
				],
			)
			break
	}

	return cloneDeep(result)
}

const methodSortUsersAndTeams = (arrayData) => {
	let result
	let data = arrayData ? arrayData : concatenedSelectedTeamsAndUsers.value

	const leaders = cloneDeep(data.filter((aItem) =>
		selectedActionLeaders.value.find((aLeader) => (aLeader.id === aItem.id) && !aItem.name),
	))

	const selectedUsersWithoutLeaders = data.filter(
		(aItem) =>
			!selectedActionLeaders.value.find((aLeader) => (aLeader.id === aItem.id) && !aItem.name),
	)

	switch (computedCurrentSortOrderValue.value) {
		case 'selectedAt':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(item) => !item.selectedAt,
					(item) => item.selectedAt,
				],
				['desc'],
			)
			break
		case 'asc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(item) => item.surname || item.name],
				['asc'],
			)
			break
		case 'desc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(item) => item.surname || item.name],
				['desc'],
			)

			break

		default:
			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(item) => !item.distance,
					(item) => item.distance,
				],
				[
					'asc',
					computedCurrentSortOrderValue.value,
				],
			)
			break
	}

	return cloneDeep([...leaders, ...result])
}

const methodSortSelectedUsers = () => {
	let result = []
	const leaders = cloneDeep(selectedUsers.value.filter((aUser) =>
		selectedActionLeaders.value.find((aLeader) => aLeader.id === aUser.id),
	))

	const selectedUsersWithoutLeaders = selectedUsers.value.filter(
		(aUser) =>
			!selectedActionLeaders.value.find((aLeader) => aLeader.id === aUser.id),
	)

	switch (sortOrder.value) {
		case 'selectedAt':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.selectedAt],
				['desc'],
			)
			break

		case 'asc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.surname],
				['asc'],
			)
			break

		case 'desc_name':

			result = orderBy(
				selectedUsersWithoutLeaders,
				[(user) => user.surname],
				['desc'],
			)
			break

		default:

			result = orderBy(
				selectedUsersWithoutLeaders,
				[
					(user) => !user.distance,
					(user) => user.distance,
				],
				[
					'asc',
					sortOrder.value,
				],
			)
			break
	}

	return cloneDeep([...leaders, ...result])
}

const methodFilterSelectedUserInsideCircleArea = (usersFiltered) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')
	const circleData = getCircleData(mapInstance.value, whichCircle)

	const circleCoordinate = circleData?.geometry?.coordinates || []
	if ((areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue !== 'allUsers' && areaSelectionFilter.currentValue !== 'actionArea') || isAiSettings.value) {
		return usersFiltered.filter((user) => userIsInsideArea(user, circleCoordinate))
	}
	else {
		return usersFiltered
	}
}
const methodFilterSelectedUserInsideAiPolygon = (usersFiltered) => {
	// let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	// let whichCircle = filterDistanceTarget.currentValue === 'meetingpoint' ? 'meetingPoint' : 'polygon'
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')
	// const circleData = getCircleData(mapInstance.value, whichCircle)

	const coordinates = aiPolygon.value.geometry.coordinates || []

	if ((areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue !== 'allUsers' && areaSelectionFilter.currentValue !== 'actionArea') || isAiSettings.value) {
		return usersFiltered.filter((user) => userIsInsideArea(user, coordinates))
	}
	else {
		return usersFiltered
	}
}

const methodFilterSelectedUserInsideActionArea = (usersFiltered) => {
	const areaSelectionFilter = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'selection')

	if (areaSelectionFilter.currentValue.length && areaSelectionFilter.currentValue === 'actionArea' || isAiSettings.value) {
		const coordinates = newActionPolygon.value.map((point) => [point.lng, point.lat])
		const usersInsideArea = usersWithDistances.value.filter((user) => userIsInsideArea(user, [coordinates]))
		return cloneDeep(usersInsideArea)
	}
	else {
		return cloneDeep(usersFiltered)
	}
}

const methodSelectUser = (isAlreadyCheckedBoolean, user) => {
	const aUserWithDistance = cloneDeep(usersWithDistances.value).find((aUser) => aUser.id === user.id)

	user.selectedAt = Date.now()
	const allTeamSelectedIds = selectedTeams.value.map((team) => team.id)
	const allteamsIdsOfUser = user.teams?.length ? user.teams.map((team) => team.id) : []

	const hasUserInTeam = allTeamSelectedIds.some((id) => allteamsIdsOfUser.includes(id))

	selectionTypes.value.currentValue = 'customSelection'
	isAiSettings.value = false

	if (isAlreadyCheckedBoolean) {
		selectedUsers.value = selectedUsers.value.filter((aUser) => aUser.id !== user.id)
		selectedActionLeaders.value = selectedActionLeaders.value.filter((aUser) => aUser.id !== user.id)
	}
	else {
		if (!hasUserInTeam) {
			if (aUserWithDistance) {
				selectedUsers.value = [...selectedUsers.value, aUserWithDistance]
			}
			else {
				selectedUsers.value = [...selectedUsers.value, user]
			}
		}
	}

	addCheckedClassToSelectedUsers([user])
}

const updateCircleFromFilter = (value) => {
	let filterDistanceTarget = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceTarget')
	let filterDistanceUnit = ownSelectionFilters.value.find((aFilter) => aFilter.category === 'distanceUnit')
	const currentDistanceUnitChoice = filterDistanceUnit.currentValue

	let valueConvertedInKm = convertToKilometers(value, currentDistanceUnitChoice)

	let tmpPolygon
	let whichCircle
	const map = mapInstance.value

	if (filterDistanceTarget.currentValue === 'meetingpoint') {
		tmpPolygon = transformPointToMiniPolygon(localMeetingPoint.value[0])
		whichCircle = 'meetingPoint'
	}
	else {
		whichCircle = 'polygon'
		tmpPolygon = newActionPolygon.value
	}

	updateCircle(map, tmpPolygon, valueConvertedInKm, whichCircle, currentDistanceUnitChoice)
	preSelectedUsers.value = methodStartFilters()

	const circleData = getCircleData(map, whichCircle)
	const endCoordsOfLine = getRightmostPointFromCircle(circleData)

	const center = getCenterOfPolygon(tmpPolygon)
	const lineColor = whichCircle !== 'meetingPoint' ? '#ff387f' : '#BABEF2'
	updateLine(map, center, endCoordsOfLine, lineColor)
	removeAllMapMouseMoveHandlers(mapInstance.value)
}

const methodStartFilters = (aFilter = null) => {
	let usersFiltered = cloneDeep(usersWithDistances.value)
	if (newActionPolygon.value && !isEmpty(newActionPolygon.value)) {
		usersFiltered = methodFilterSelectedUserInsideCircleArea(usersFiltered)
		usersFiltered = methodFilterSelectedUserInsideActionArea(usersFiltered)
	}
	usersFiltered = methodFilterByDisponibilities(usersFiltered)

	return usersFiltered
}
const methodFilterByDisponibilities = (users) => {
	const aFilter = ownSelectionFilters.value.find((filter) => filter.category === 'disponibility')

	if (!aFilter || !aFilter.currentValue.length) {
		return users
	}

	return users.filter((user) => {
		const userStatus = user.status || 'not_set'
		const userDisponibilities = userStatus.split(',')

		return userDisponibilities.includes('available_all_the_time')
			|| userDisponibilities.length === 0
			|| intersection(userDisponibilities, aFilter.currentValue).length > 0
	})
}

const methodSelectTeam = (isAlreadyCheckedBoolean, data) => {
	if (isAlreadyCheckedBoolean) {
		selectedTeams.value = selectedTeams.value.filter((team) => team.id !== data.id)
	}
	else {
		selectedTeams.value = [...selectedTeams.value, data]
	}
}

const toggleSelectCard = (data, type) => {
	let allSelectedLeadersId = methodReturnOnlyIds(selectedActionLeaders.value)
	methodCloseMoreDetails()
	switch (type) {
		case 'user':
			if (!allSelectedLeadersId.includes(data.id)) {
				methodSelectUser(methodVerifyIsAlreadyCheck(data, type), data)
				selectedUsers.value.length ? activeNames.value = ['selected-users', 'result-search'] : activeNames.value = ['result-search']
			}
			else {
				methodUpdateLeadersAction(data)
			}

			break
		case 'team':
			methodSelectTeam(methodVerifyIsAlreadyCheck(data, type), data)
			selectedTeams.value.length ? activeNames.value = ['selected-teams', 'result-search'] : activeNames.value = ['result-search']
			break

		default:
			return
	}
}

const methodVerifyIsActionLeader = (aUser) => {
	return methodReturnOnlyIds(selectedActionLeaders.value).includes(aUser.id)
}

provide('verify-is-action-leader', methodVerifyIsActionLeader)

const methodVerifyIsAlreadyCheck = (data, type = 'user') => {
	let dataIds = []
	switch (type) {
		case 'user':

			dataIds = selectedUsers.value.map((user) => user?.id)
			break
		case 'team':
			dataIds = selectedTeams.value.map((team) => team?.id)
			break
		default:
			break
	}

	return dataIds.includes(data?.id)
}

const methodVerifyIfUserIsLocked = (data) => {
	const dataIds = selectedUsers.value.filter((user) => user.isLockedByTeam && user.isLockedByTeam.length > 0).map((user) => user.id)
	return dataIds.includes(data?.id)
}

</script>

<style>

.el-popper.is-customized-popper-assignment {
  /* Set padding to ensure the height is 32px */
	box-shadow: 0px 4px 4px 0px rgba(34, 34, 48, 0.2) !important;
  padding: 6px 12px;
  background: white;
}

.el-popper.is-customized-popper-assignment .el-popper__arrow::before {
	display:none;
}

.force-flip-y-icon {
	transform: scaleY(-1);
}
.el-tooltip__popper {
  pointer-events: auto; /* Permet de cliquer dans le contenu du tooltip */
}
.custom-tooltip-link, .custom-tooltip-link u{
	@apply text-xs cursor-pointer;
}

</style>

<style lang="scss" scoped>

.fake-border-gradient::before{
	content: '';
	display: block;
	width: 100%;
	height:100%;
	background: linear-gradient(to right, #B9BEF3, #FFC6E7);
	position : absolute;
	border-bottom-left-radius: 20px 20px;
	border-bottom-right-radius: 20px 20px;

}
.fake-border-gradient::after{
	content: '';
	width: calc(100% - 4px);
	height:calc(100% - 2px);
	left: 2px;
	background-color: white;
	position : absolute;
	top:0;
	border-bottom-left-radius: 18px 18px;
	border-bottom-right-radius: 18px 18px;
	z-index:1;
}

.AIMatchingBtn {
	background: linear-gradient(90deg, #B9BEF3 0%, #FFC6E7 100%);
	border: none;
	&:hover {
    background: linear-gradient(90deg, rgba(185, 190, 243, 0.75) 0%, rgba(255, 198, 231, 0.75) 100%);
  }

  &.load {
    &:hover {
      background: linear-gradient(90deg, #B9BEF3 0%, #FFC6E7 100%);
    }

    &::before {
      @apply bg-black;
    }
  }

}

</style>
