<template>
	<div class="params-bar__publish-part">
		<div
			v-for="(choice, index) in param.choices"
			:key="choice.name"
			class="flex justify-between items-center py-3"
			:class="{ 'border-b': index !== param.choices.length - 1 }"
		>
			<p class="font-medium">{{ choice.label }}</p>

			<el-dropdown trigger="click" style="min-width: 120px" @command="methodHandleCommandChoice">
				<span
					class="el-dropdown-link text-black flex justify-between items-center"
					:class="{ 'cursor-default': choice.options.length === 1 }"
					style="font-size: 13px"
				>
					{{
						choice.options.find((o) => o.value === computedForm[choice.name])?.label ||
						choice.options[0].label
					}}
					<el-icon v-if="choice.options.length > 1" class="el-icon--right font-bold"
						><ArrowDown
					/></el-icon>
				</span>

				<template #dropdown>
					<el-dropdown-menu :class="{ hidden: choice.options.length === 1 }">
						<el-dropdown-item
							v-for="option in choice.options"
							:key="option.value"
							:command="{ option: choice.name, value: option.value }"
						>
							{{ option.label }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>

		<div class="mt-10">
			<p class="text-pink-main mb-3" style="font-size: 13px">{{ param.link.label }}</p>

			<p class="text-gray-strong mb-6">{{ param.link.desc }}</p>

			<el-input
				v-model="computedForm.slug"
				class="slug-suffix"
				show-word-limit
				name="slug"
				type="text"
				maxlength="140"
			>
				<template #prepend>
					<span class="text-gray-strong truncate ..." style="font-size: 13px">
						{{ computedUrl }}
					</span>
				</template>
			</el-input>
		</div>
		<button
			type="button"
			class="text-pink-main pl-5 mb-8 cursor-pointer hover:opacity-60"
			style="font-size: 13px"
			@click="() => copy(`${computedUrl}${form.slug}/`)"
		>
			{{ param.link.copyText }}
		</button>
		<button-loader class="mx-auto mb-2" style="max-width: 240px">
			<q-button
				type="button"
				class="overflow-visible w-full"
				:disabled="isPublishing || disableIfFormNotIncludedMail(form)"
				:class="{
					disabled: isPublishing || disableIfFormNotIncludedMail(form),
					load: isPublishing,
				}"
				style="min-width: 160px"
				@click="param.cta.onClick"
			>
				{{ isPublishing ? $t('PETITIONS.STATUS.PUBLISHING') + '...' : param.cta.text }}
			</q-button>
		</button-loader>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		ButtonLoader: defineAsyncComponent(() => import('../../general/button-loader.vue')),
	},
	props: {
		param: { type: Object, default: () => {}, required: true },
		form: { type: Object, default: () => {}, required: true },
		copy: { type: Function, default: () => {}, required: true },
		isPublishing: { type: Boolean, default: false, required: false },
	},
	emits: ['update:form'],

	data() {
		return {
			colors: ['#FF387E', '#F3F4F6'],
			animationRunning: false,
			jsConfetti: null,
		}
	},
	computed: {
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},

		computedUrl() {
			if (this.computedForm.type === 'petition') {
				// eslint-disable-next-line no-undef
				return PETITIONS_ENDPOINT_URL
			} else {
				// eslint-disable-next-line no-undef
				return ONLINE_FORMS_ENDPOINT_URL
			}
		},
	},

	methods: {
		methodHandleCommandChoice({ option, value }) {
			const clonedForm = cloneDeep(this.computedForm)
			clonedForm[option] = value
			this.computedForm = clonedForm
		},

		disableIfFormNotIncludedMail(form) {
			if (form.type === 'online-form') {
				if (form && form.fields) {
					return !form.fields.some((e) => e.type === 'mail')
				} else {
					return true
				}
			} else {
				return false
			}
		},
	},
}
</script>

<style lang="scss">
.slug-suffix .el-input__suffix {
	position: absolute;
	bottom: -20px;
	top: auto;
	height: auto;
}
.slug-suffix .el-input__count {
	color: #72727b !important;
}
.params-bar__publish-part {
	.el-input-group {
		display: flex;

		&__prepend {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			overflow-x: hidden;
			text-overflow: ellipsis;
			display: flex;
			align-items: center;
		}
	}

	.el-input__wrapper {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}
}
</style>
