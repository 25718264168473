/**
 * @mixin formsStatic
 * @desc give some classic mixins for forms static
 */

import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters('@form', ['getFormsStatic']),
	},

	methods: {
		/**
		 * @method methodGetNameFilter
		 * @desc give the right label of the filter
		 */
		mixinMethodGetLabelForm(typology: string): string {
			switch (typology) {
				case 'pollingstation':
					const formPollingStation = this.getFormsStatic.find(
						(aForm) => aForm.type === 'address.pollingstation'
					)
					if (formPollingStation) return formPollingStation.label
					return this.$t('CONTACT.HEADER.TERRITORY_PLACEHOLDER')

				default:
					return ''
			}
		},
	},
}
