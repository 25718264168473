/**
 * Contacts related routes
 *
 *
 * @rootPath {/contacts}
 */

import DetailRoute from './_contactByIdRoute'
import Contacts from 'components/contact-module/contacts.vue'
import ContactList from 'components/contact-module/contact-list/contact-list.vue'
import ContactDynamicLists from 'components/contact-module/contact-dynamic-lists/dynamic-lists.vue'
import store from '../store/stateStore'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

export default {
	meta: { title: 'MENU.CONTACTS' },
	path: '/contacts',
	name: 'contacts',
	component: Contacts,
	redirect: { name: 'contact-list' },
	beforeEnter: (to, from, next) => {
		// Role access check
		if (
			store.getters['userConnected']?.role_data?.access?.includes('contacts:read')
			|| methodIsAccessGranted('communication.email:delete')
			|| methodIsAccessGranted('communication.sms:delete')
		) {
			next()
		}
		else {
			next({ name: 'command-center', replace: true })
		}
	},
	props: (route) => ({
		...route.params,
		displayMode:
			route.query.displayMode
			|| (!methodIsAccessGranted('contacts:read') && (
				methodIsAccessGranted('communication.email:delete')
				|| methodIsAccessGranted('communication.sms:delete'))
				? 'map'
				: 'list'),
	}),
	children: [
		{
			name: 'contact-list',
			path: 'list',
			component: ContactList,
			props: (route) => ({
				...route.params,
				displayMode:
					route.query.displayMode
					|| (!methodIsAccessGranted('contacts:read') && (
						methodIsAccessGranted('communication.email:delete')
						|| methodIsAccessGranted('communication.sms:delete'))
						? 'map'
						: 'list'),
			}),
		},
		{
			name: 'contacts-dynamic-lists',
			path: 'dynamic-lists',
			component: ContactDynamicLists,
			beforeEnter: (to, from, next) => {
				// Role access check
				if (store.getters['userConnected']?.role_data?.access?.includes('contacts.list:delete')) {
					next()
				}
				else {
					next({ name: 'command-center', replace: true })
				}
			},
		},
		/**
		 * Weird behavior if we set this ...DetailRoute in first element
		 * of this array, we can't refresh on contact-list, because this
		 * ContactMainView is used instead of the contact-list
		 */
		...[DetailRoute('/contacts', 'contacts')],
	],
}
