<template>
	<div class="mb-6">
		<!-- Actions -->
		<div v-if="accessMobilePersonalization == 'enabled'" class="section flex flex-col gap-8">
			<span class="text-sm text-gray-dark uppercase">
				{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ACTION_SECTION') }}
			</span>
			<!-- Optimize presence screen -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-cards text-2xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.PRESENCE_TITLE') }}
						</p>
						<p v-dompurify-html="$t('SETTINGS.MOBILE_APP.SECOND_TAB.PRESENCE_DESC')"></p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessDisplayComeBackAndRefusalForms == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessDisplayComeBackAndRefusalForms == 'enabled'
								? $t('SETTINGS.MOBILE_APP.SECOND_TAB.PRESENCE_ENABLED')
								: $t('SETTINGS.MOBILE_APP.SECOND_TAB.PRESENCE_DISABLED')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
			<!-- Never contact again -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-stop text-2xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.NEVER_CONTACT_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.NEVER_CONTACT_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessMobileBlacklistOnRefusal == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessMobileBlacklistOnRefusal == 'enabled'
								? $t('SETTINGS.MOBILE_APP.SECOND_TAB.NEVER_CONTACT_ENABLED')
								: $t('SETTINGS.MOBILE_APP.SECOND_TAB.NEVER_CONTACT_DISABLED')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
		</div>
		<!-- Questionnaire -->
		<div class="section flex flex-col gap-8">
			<span class="text-sm text-gray-dark uppercase">
				{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.SURVEY_SECTION') }}
			</span>
			<!-- Qualify contacts -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-mail text-3xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.QUALIFY_TITLE') }}
						</p>
						<p v-dompurify-html="$t('SETTINGS.MOBILE_APP.SECOND_TAB.QUALIFY_DESC')"></p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessHomeRedirectionAfterAnsweredSurvey == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessHomeRedirectionAfterAnsweredSurvey == 'enabled'
								? $t('_COMMON.DISPLAY')
								: $t('_COMMON.HIDDEN')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
			<!-- Anonymous questionnaire -->
			<div class="flex gap-6 items-center justify-between">
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-face text-3xl p-2 blur-[2px]"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ANONYMOUS_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ANONYMOUS_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<el-switch
						class="flex-shrink-0 mr-4"
						:model-value="accessAnonymousSurvey == 'enabled'"
						disabled
						style="--el-switch-on-color: #05b66f"
						:inactive-text="
							accessAnonymousSurvey == 'enabled' ? $t('_COMMON.ENABLE') : $t('_COMMON.DISABLE')
						"
						@click="openChat"
					/>
				</el-tooltip>
			</div>
			<!-- Ask present -->
			<div
				v-if="accessMobilePersonalization == 'enabled'"
				class="flex gap-6 items-center justify-between"
			>
				<div class="flex gap-6 items-center w-3/4">
					<i class="gui-question text-3xl p-2"></i>
					<div>
						<p class="bold text-lg mb-1">
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ASK_PRESENT_TITLE') }}
						</p>
						<p>
							{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ASK_PRESENT_DESC') }}
						</p>
					</div>
				</div>
				<el-tooltip placement="top" :content="$t('SETTINGS.MOBILE_APP.TOOLTIP_BTN_DISABLED')">
					<div
						class="flex items-center flex-shrink-0 gap-1 hover:opacity-70 cursor-not-allowed"
						@click="openChat"
					>
						<div class="text-sm">
							<span>{{ $t('SETTINGS.MOBILE_APP.SECOND_TAB.ASK_PRESENT_PRESENT') }}</span>
							<span v-if="info_campagne.create_absent_contacts_enabled">{{
								$t('SETTINGS.MOBILE_APP.SECOND_TAB.ASK_PRESENT_ABSENT')
							}}</span>
							<span v-if="info_campagne.create_refused_contacts_enabled">{{
								$t('SETTINGS.MOBILE_APP.SECOND_TAB.ASK_PRESENT_REFUSAL')
							}}</span>
						</div>
						<i class="gui-arrow_down text-2xl mt-0.5"></i>
					</div>
				</el-tooltip>
			</div>
		</div>
		<!-- Helper -->
		<div class="section flex gap-3">
			<i class="gui-infos text-2xl -mt-1"></i>
			<div>
				<div class="bold mb-1">{{ $t('_COMMON.HELP') }}</div>
				<div class="text-gray-strong">
					<span v-dompurify-html="$t('SETTINGS.MOBILE_APP.HELPER_PART_1')"></span>
					<span
						class="text-pink-strong underline cursor-pointer hover:opacity-70"
						@click="openChat"
					>
						{{ $t('SETTINGS.MOBILE_APP.HELPER_PART_2') }}</span
					>
					<span>{{ $t('SETTINGS.MOBILE_APP.HELPER_PART_3') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { openChat } from 'src/extensions/support'
import { computed } from 'vue'
import { useStore } from 'vuex'

// Data
const store = useStore()

// Computed
const accessMobilePersonalization = computed(
	() => store.getters['@packs/getAccessMobilePersonalization']
)
const accessDisplayComeBackAndRefusalForms = computed(
	() => store.getters['@packs/getAccessDisplayComeBackAndRefusalForms']
)
const accessMobileBlacklistOnRefusal = computed(
	() => store.getters['@packs/getAccessMobileBlacklistOnRefusal']
)
const accessHomeRedirectionAfterAnsweredSurvey = computed(
	() => store.getters['@packs/getAccessHomeRedirectionAfterAnsweredSurvey']
)
const accessAnonymousSurvey = computed(() => store.getters['@packs/getAccessAnonymousSurvey'])
const info_campagne = computed(() => store.getters['info_campagne'])

// Method
openChat
</script>

<style lang="sass" scoped>
.section
	@apply w-full bg-white p-8 mt-2
	scroll-margin-top: 20px
</style>
