/**
 * File to initialize segment.com javascript
 * We need to global variables
 * PRODUCTION: true to be effective
 * API_KEY_SEGMENT: which will enable segment
 */
import Intercom from '@intercom/messenger-js-sdk'

function initializeIntercom() {
	Intercom({
		app_id: 'j0zwu1yw',
	})
}

!(function () {
	// eslint-disable-next-line no-undef
	if (PRODUCTION && API_KEY_SEGMENT) {
		window.isSegmentEnabled = false
		var analytics = (window.analytics = window.analytics || [])
		if (!analytics.initialize)
			if (analytics.invoked)
				window.console && console.error && console.error('Segment snippet included twice.')
			else {
				analytics.invoked = !0
				analytics.methods = [
					'trackSubmit',
					'trackClick',
					'trackLink',
					'trackForm',
					'pageview',
					'identify',
					'reset',
					'group',
					'track',
					'ready',
					'alias',
					'debug',
					'page',
					'once',
					'off',
					'on',
					'addSourceMiddleware',
					'addIntegrationMiddleware',
					'setAnonymousId',
					'addDestinationMiddleware',
				]
				analytics.factory = function (e) {
					return function () {
						var t = Array.prototype.slice.call(arguments)
						t.unshift(e)
						analytics.push(t)
						return analytics
					}
				}
				for (var e = 0; e < analytics.methods.length; e++) {
					var key = analytics.methods[e]
					analytics[key] = analytics.factory(key)
				}
				analytics.load = function (key, e) {
					var t = document.createElement('script')
					t.type = 'text/javascript'
					t.async = !0
					t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
					var n = document.getElementsByTagName('script')[0]
					n.parentNode.insertBefore(t, n)
					analytics._loadOptions = e

					t.onerror = function () {
						console.error('Segment failed to load due to network error or blocking.')
						window.isSegmentEnabled = false
						// eslint-disable-next-line no-undef
						if (PRODUCTION) {
							initializeIntercom() // Fallback to Intercom
						}
					}

					t.onload = function () {
						console.log('Segment loaded successfully.')
						window.isSegmentEnabled = true
						// You *could* put a check here, but onload is generally reliable.
						//  if (window.analytics && window.analytics.page && ...) { ... }
					}
				}
				// eslint-disable-next-line no-undef
				analytics._writeKey = API_KEY_SEGMENT
				analytics.SNIPPET_VERSION = '4.15.3'
				// eslint-disable-next-line no-undef
				analytics.load(API_KEY_SEGMENT)
			}
	}
})()
