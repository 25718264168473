import { SET_MENU_VISIBLE, SET_MENU_COLLAPSE } from './_mutation-types'

const state = {
	menuVisible: true,
	isCollapse: false,
}

export const mutations = {
	SET_MENU_VISIBLE: (state, res) => {
		state.menuVisible = res
	},

	SET_MENU_COLLAPSE: (state, res) => {
		state.isCollapse = res
	},
}

export const actions = {
	actionSetMenuVisibility: ({ commit }, payload) => {
		commit(SET_MENU_VISIBLE, payload)
	},

	actionSetMenuCollapse: ({ commit }, payload) => {
		commit(SET_MENU_COLLAPSE, payload)
	},
}

const getters = {
	isMenuVisible: (state) => {
		return state.menuVisible
	},
	isCollapse: (state) => {
		return state.isCollapse
	},
}

export const navBarModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters,
	actions: actions,
}
