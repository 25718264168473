<template>
	<el-dialog
		v-model="visible"
		append-to-body
		:title="title == '' ? $t('MAILING.CREATE_IMAGE_TITLE') : title"
		:width="`${dialogWidth}%`"
		:class="customClass"
		@closed="visible = false"
	>
		<div>
			<div
				v-if="onlyUpload"
				class="flex flex-col justify-center items-center mt-8"
			>
				<div class="w-full">
					<el-upload
						v-if="status === 'init' || status === 'error'"
						v-bind="getBase64 && { httpRequest: getFileMsg }"
						ref="uploader"
						class="upload-demo"
						drag
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:show-file-list="false"
						:action="computedLinkApiImportFile"
						accept=".png,.jpeg,.jpg,.x-icon"
					>
						<div class="mb-4 bg-gray-lighted rounded-full p-2">
							<i class="gui-add_image color-part-two text-4xl" />
						</div>
						<div
							v-if="whichModule === 'materials'"
							class="el-upload__text bold"
						>
							{{ $t('MAILING.IMAGE_UPLOADER.TEXT_MATERIAL') }}
						</div>
						<div
							v-else
							class="el-upload__text bold"
						>
							{{ $t('MAILING.IMAGE_UPLOADER.TEXT') }}
						</div>
					</el-upload>
					<div
						v-if="status === 'loading' && !getBase64"
						class="flex justify-center items-center w-full h-40"
					>
						<loader size="10em" />
					</div>
				</div>
				<div
					v-if="status === 'error' && sizeError"
					v-dompurify-html="
						$t(errorSize, {
							fileSize: fileSize,
							fileSizeMax: fileSizeMax,
						})
					"
					class="error-text mt-4 text-center"
				/>
				<div
					v-if="image.src !== '' && (status === 'success' || getBase64) && !displayCropper"
					id="imageUploaded"
					class="imageUploaded w-60 h-40 bg-center bg-cover bg-no-repeat rounded-lg transition-all relative"
					:style="{ backgroundImage: `url('${image.src}')` }"
				>
					<el-upload
						v-if="isJustUrlWanted"
						v-bind="getBase64 && { httpRequest: getFileMsg }"
						class="upload-demo"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:show-file-list="false"
						:action="computedLinkApiImportFile"
					>
						<div class="el-upload__text">
							<i class="gui-change changeImageUploaded top" />
						</div>
					</el-upload>

					<i
						:class="isJustUrlWanted ? 'top-8' : 'top-0'"
						class="gui-delete deleteImageUploaded"
						@click="methodResetImageUploaded"
					/>
				</div>
				<template v-if="displayCropper">
					<slot />
				</template>
			</div>
			<el-tabs
				v-else
				v-model="activeName"
				stretch
			>
				<el-tab-pane
					v-if="!onlyUrl || (onlyUpload && onlyUrl)"
					:label="firstTabWording ? firstTabWording : $t('MAILING.IMAGE_UPLOADER.TAB_UPLOAD')"
					name="first"
					class="px-4"
				>
					<div class="flex flex-col justify-center items-center mt-8">
						<div class="w-full">
							<el-upload
								v-if="status === 'init' || status === 'error'"
								v-bind="getBase64 && { httpRequest: getFileMsg }"
								ref="uploader"
								class="upload-demo"
								drag
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload"
								:show-file-list="false"
								:action="computedLinkApiImportFile"
								accept=".png,.jpeg,.jpg,.x-icon"
							>
								<div class="mb-4 bg-gray-lighted rounded-full p-2">
									<i class="gui-add_image color-part-two text-4xl" />
								</div>
								<div
									v-if="whichModule === 'materials'"
									class="el-upload__text bold"
								>
									{{ $t('MAILING.IMAGE_UPLOADER.TEXT_MATERIAL') }}
								</div>
								<div
									v-else
									class="el-upload__text bold"
								>
									{{ $t('MAILING.IMAGE_UPLOADER.TEXT') }}
								</div>
							</el-upload>
							<div
								v-if="status === 'loading' && !getBase64"
								class="flex justify-center items-center w-full h-40"
							>
								<loader size="10em" />
							</div>
						</div>
						<div
							v-if="image.src !== '' && (status === 'success' || getBase64) && !displayCropper"
							id="imageUploaded"
							class="imageUploaded w-60 h-40 bg-center bg-cover bg-no-repeat rounded-lg transition-all relative"
							:style="{ backgroundImage: `url('${image.src}')` }"
						>
							<el-upload
								v-if="isJustUrlWanted"
								v-bind="getBase64 && { httpRequest: getFileMsg }"
								class="upload-demo"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload"
								:show-file-list="false"
								:action="computedLinkApiImportFile"
							>
								<div class="el-upload__text">
									<i class="gui-more changeImageUploaded top" />
								</div>
							</el-upload>

							<i
								:class="isJustUrlWanted ? 'top-8' : 'top-0'"
								class="gui-delete deleteImageUploaded"
								@click="methodResetImageUploaded"
							/>
						</div>
						<template v-if="displayCropper">
							<slot />
						</template>
					</div>
					<div
						v-if="status === 'error' && formatError"
						class="error-text mt-4 text-center"
					>
						{{ $t('MAILING.IMAGE_UPLOADER.FORMAT_ERROR') }}
					</div>
					<div
						v-if="status === 'error' && sizeError"
						v-dompurify-html="
							$t(errorSize, {
								fileSize: fileSize,
								fileSizeMax: fileSizeMax,
							})
						"
						class="error-text mt-4 text-center"
					/>
					<div v-if="image.src !== '' && status === 'success' && !isJustUrlWanted">
						<div class="mt-6 text-center">
							<span>
								{{ $t('MAILING.IMAGE_UPLOADER.ACCESSIBILITY_OPTIONS') }}
							</span>
						</div>
						<div class="pt-4">
							<label for="imageTitle">{{ $t('MAILING.IMAGE_TITLE') }}</label>
							<el-input
								id="imageTitle"
								v-model="image.title"
							/>
						</div>
						<div class="pt-4">
							<label for="imageAlt">{{ $t('MAILING.IMAGE_ALT') }}</label>
							<el-input
								id="imageAlt"
								v-model="image.alt"
							/>
						</div>
					</div>
					<div
						v-if="isJustUrlWanted"
						class="mt-6 text-center"
					>
						<template v-if="whichModule === 'petition'">
							<span class="text-sm">
								{{
									$t('MAILING.IMAGE_UPLOADER.INFORMATIONS_SUBTITLE', {
										fileSizeMax: fileSizeMax,
									})
								}}
							</span>
						</template>
						<template v-else-if="whichModule === 'materials'">
							<span class="text-sm">
								{{ $t('MAILING.IMAGE_UPLOADER.MATERIALS') }}
							</span>
						</template>
						<template v-else>
							<span class="bold">{{
								subtitle == '' ? $t('MAILING.IMAGE_UPLOADER.SUBTITLE') : subtitle
							}}</span><br>
							<span class="text-sm">{{
								dimensions ? dimensions : $t('MAILING.IMAGE_UPLOADER.DIMENSIONS')
							}}</span><br>
							<span class="text-sm">
								{{
									files
										? files
										: $t('MAILING.IMAGE_UPLOADER.INFORMATIONS_SUBTITLE', {
											fileSizeMax: fileSizeMax,
										})
								}}
							</span>
						</template>
					</div>
				</el-tab-pane>
				<el-tab-pane
					v-if="!onlyUpload || (onlyUpload && onlyUrl)"
					:label="secondTabWording ? secondTabWording : $t('MAILING.IMAGE_UPLOADER.TAB_URL')"
					name="second"
				>
					<div class="pt-4">
						<label for="image">{{ $t('MAILING.IMAGE_QUESTION') }}</label>
						<el-input
							id="image"
							v-model="image.src"
							class="pt-2"
							@input="debounceLoadImage($event.target.value)"
							@keyup.enter="createImage"
						/>
						<div class="pt-4">
							<label for="imageTitle">{{ $t('MAILING.IMAGE_TITLE') }}</label>
							<el-input
								id="imageTitle"
								v-model="image.title"
							/>
						</div>
						<div class="pt-4">
							<label for="imageAlt">{{ $t('MAILING.IMAGE_ALT') }}</label>
							<el-input
								id="imageAlt"
								v-model="image.alt"
							/>
						</div>
						<div class="pt-4">
							<span class="text-xs"><span class="bold">{{ $t('MAILING.IMAGE_HELP_QUESTION') }}</span>{{ $t('MAILING.IMAGE_HELP') }}</span>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<span
					class="cursor-pointer mr-2"
					@click="methodCloseImageUploader"
				>{{
					$t('MAILING.CANCEL_LINK')
				}}</span>

				<q-button
					v-if="!displayCropper"
					type="primary"
					:disabled="
						(image.src == null || image.src == '' || (status !== 'success' && !getBase64)) &&
							!displayCropper
					"
					@click="createImage"
				>{{ $t('MAILING.CONFIRM_LINK') }}</q-button>
				<template v-if="displayCropper">
					<q-button
						type="primary"
						:disabled="
							(image.src == null || image.src == '' || (status !== 'success' && !getBase64)) &&
								!displayCropper
						"
						@click="methodCropMe()"
					>{{ $t('PETITIONS.BANNER.RESIZE') }}</q-button>
				</template>
			</span>
		</template>
	</el-dialog>
</template>

<script>
import Loader from '../general/loader.vue'
import { debounce } from 'lodash'
import api from '../../api_constants'
import { mapActions } from 'vuex'
import { ref, onMounted } from 'vue'

export default {
	components: {
		Loader,
	},
	props: {
		displayCropper: {
			type: Boolean,
			default: false,
		},
		fileSizeMax: {
			type: Number,
			required: false,
			default: 2,
		},
		fileMaxSizeByte: {
			type: Number,
			default: 2097152,
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
		dimensions: {
			type: String,
			required: false,
			default: '',
		},
		files: {
			type: String,
			required: false,
			default: '',
		},
		errorSize: {
			type: String,
			required: false,
			default: function () {
				return 'MAILING.IMAGE_UPLOADER.SIZE_LIMIT_ERROR_EL_UPLOAD'
			},
		},
		isVisible: {
			type: Boolean,
			required: true,
		},
		onlyUpload: {
			type: Boolean,
			required: false,
			default: false,
		},
		onlyUrl: {
			type: Boolean,
			required: false,
			default: false,
		},
		dialogWidth: {
			type: Number,
			required: false,
			default: 40,
		},
		customClass: {
			type: String,
			required: false,
			default: '',
		},
		isJustUrlWanted: {
			type: Boolean,
			required: false,
			default: false,
		},
		whichModule: {
			type: String,
			required: true,
		},
		img: {
			type: String,
			required: false,
			default: '',
		},
		firstTabWording: {
			type: String,
			required: false,
			default: null,
		},
		secondTabWording: {
			type: String,
			required: false,
			default: null,
		},
		getBase64: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: [
		'closeImageUploader',
		'methodCropImg',
		'createImageFromUrl',
		'deleteSpaceProfilPicture',
		'deletePetitionBanner',
		'deleteMaterialsBanner',
	],

	setup(props) {
		const visible = ref(false)
		const activeName = ref('first')

		onMounted(() => {
			if (props.onlyUpload && props.onlyUrl) activeName.value = 'first'
			if (props.onlyUpload) activeName.value = 'first'
			if (props.onlyUrl) activeName.value = 'second'
			visible.value = props.isVisible
		})
		return { visible, activeName }
	},
	data() {
		return {
			image: {
				src: '',
				width: '',
				alt: '',
				title: '',
			},
			status: 'init',
			sizeError: false,
			formatError: false,
			fileSize: 0,
			units: ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		}
	},
	computed: {
		computedLinkApiImportFile() {
			return `${api[0].LOCAL}/photos`
		},
	},
	watch: {
		isVisible(newVal) {
			this.visible = newVal
		},
		visible(newVal) {
			if (newVal !== this.isVisible) {
				this.methodCloseImageUploader()
			}
		},
	},
	mounted() {
		if (this.img.length > 0) {
			this.image.src = this.img
			this.status = 'success'
		}
		if (this.image.length === 0 && this.whichModule === 'favicon') {
			this.methodResetImageUploaded({})
		}
		this.visible = this.isVisible

		const clickOnInput = () => {
			const input = document.querySelector('.el-upload__input')
			if (input) {
				input.click()
				return
			}
			setTimeout(() => {
				clickOnInput()
			}, 300)
		}
		if (!this.image.src && this.whichModule !== 'materials' && this.whichModule !== 'email') {
			clickOnInput()
		}
	},
	created() {
		this.debounceLoadImage = debounce(async (value) => {
			try {
				this.image.src = value
				let img = await this.loadImage(value)
				this.image.width = img.width
			}
			catch (error) {
				console.log(error)
			}
		}, 400)
	},
	methods: {
		...mapActions('@emailer', ['actionsGetUrlForImgInEmail', 'actionDeleteImageUploaded']),
		...mapActions('@settings', ['actionDeleteSpacePicture']),

		methodCloseImageUploader() {
			if (this.image.src.length > 0 && this.image.src !== this.img && this.isJustUrlWanted) {
				this.methodResetImageUploaded()
			}
			this.$emit('closeImageUploader')
		},
		methodCropMe() {
			this.$emit('methodCropImg')
		},

		/// METHOD FOR BOTH VERSION ///
		prepareImage(attributes) {
			return {
				src: attributes.src,
				alt: attributes.alt == '' ? null : attributes.alt,
				width: attributes.width == '' ? null : parseInt(attributes.width),
				title: attributes.title == '' ? null : attributes.title,
				loading: 'lazy',
			}
		},
		async createImage() {
			if (this.image.src == null || this.image.src == '') {
				return
			}
			if (this.isJustUrlWanted) {
				this.$emit('createImageFromUrl', this.image.src)
				this.methodCloseImageUploader()
				this.methodResetImage()
				return
			}
			const img = {
				object: await this.prepareImage(this.image),
				link: this.image.src,
			}
			this.$emit('createImageFromUrl', img)
			this.methodCloseImageUploader()
			this.methodResetImage()
		},
		/// URL PART ///
		loadImage(src) {
			return new Promise((resolve, reject) => {
				let img = document.createElement('img')
				img.addEventListener('load', () => {
					resolve(img)
				})
				img.addEventListener('error', (error) => reject(error))
				img.src = src
			})
		},
		methodResetImage() {
			this.status = 'init'
			this.image.src = ''
			this.image.title = ''
			this.image.alt = ''
			this.image.width = ''
		},
		/// UPLOAD PART ///

		methodResetImageUploaded(event, imgUrl) {
			if (imgUrl) {
				this.$emit('deleteSpaceProfilPicture', imgUrl)
				return
			}
			const img = document.getElementById('imageUploaded')
			img.classList.remove('imageUploaded')
			img.classList.add('resetImage')

			setTimeout(() => {
				switch (this.whichModule) {
					case 'email':
						this.actionDeleteImageUploaded(this.image.src)
						break
					case 'settings':
						this.$emit('deleteSpaceProfilPicture', this.image.src)
						break
					case 'petition':
						this.$emit('deletePetitionBanner', this.image.src)
						break
					case 'materials':
						this.$emit('deleteMaterialsBanner', this.image.src)
						break
				}
				this.methodResetImage()
			}, 500)
		},

		getSizeFile(x) {
			let l = 0,
				n = parseInt(x, 10) || 0

			while (n >= 1024 && ++l) {
				n = n / 1024
			}

			return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]
		},

		beforeAvatarUpload(file) {
			const authorizedTypes = ['image/png', 'image/jpeg', 'image/x-icon']
			const isAuthorizedType = authorizedTypes.includes(file.type)
			const isFileExceededSizeLimit = file.size > this.fileMaxSizeByte

			if (isFileExceededSizeLimit) {
				this.sizeError = true
				this.status = 'error'
				this.fileSize = this.getSizeFile(file.size)
				return false
			}
			if (!isAuthorizedType) {
				this.formatError = true
				this.status = 'error'
				return false
			}
			if (isAuthorizedType && !isFileExceededSizeLimit) {
				this.status = 'loading'
			}
		},

		async handleAvatarSuccess(res, file) {
			if (this.image.src.length > 0) {
				const imgUrl = this.image.src
				await this.methodResetImageUploaded({}, imgUrl)
			}
			this.image.src = file.response.file_name
			this.$emit('createImageFromUrl', this.image.src)
			this.status = 'success'
		},

		/// BASE 64 PART ///
		getFileMsg(content) {
			this.beforeAvatarUpload(content.file)
			this.getBase64Data(content.file).then((res) => {
				this.image.src = res
			})
		},

		getBase64Data(file) {
			return new Promise(function (resolve, reject) {
				let reader = new FileReader()
				let imgResult = ''
				reader.readAsDataURL(file)
				reader.onload = function () {
					imgResult = reader.result
				}
				reader.onerror = function (error) {
					reject(error)
				}
				reader.onloadend = function () {
					resolve(imgResult)
				}
			})
		},
	},
}
</script>
<style lang="scss">
.upload-demo {
	width: 100%;

	.el-upload--text {
		width: 100%;

		.el-upload-dragger {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
<style lang="scss" scoped>
input[type='file'] {
	display: none;
}
.deleteImageUploaded {
	position: absolute;
	right: -5px;
	opacity: 0;
	transition-property: opacity;
	transition: 0.4s;

	&:hover {
		color: red;
	}
}
.changeImageUploaded {
	position: absolute;
	right: -5px;
	top: -5px;
	opacity: 0;
	transition-property: opacity;
	transition: 0.4s;

	&:hover {
		color: #05b66f;
	}
}
@keyframes popIcon {
	50% {
		transform: scale(1.2);
	}
}
@keyframes depopImage {
	20% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes popImage {
	20% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.04);
	}
	100% {
		transform: scale(1);
	}
}
.resetImage {
	animation: depopImage 0.5s 1;
}
.imageUploaded {
	box-shadow:
		rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
	transition: all 0.4s ease;
	animation: popImage 0.5s 1;
	margin-bottom: 10px;
}

.imageUploaded:hover {
	.deleteImageUploaded {
		opacity: 1;
		padding: 5px;
		font-size: 1.2rem;
		animation: popIcon 0.4s linear 1;
		background-color: rgb(232, 232, 232);
		border-radius: 50%;
		cursor: pointer;
		box-shadow:
			rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
			rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}
	.changeImageUploaded {
		opacity: 1;
		padding: 5px;
		font-size: 1.2rem;
		animation: popIcon 0.4s linear 1;
		background-color: rgb(232, 232, 232);
		border-radius: 50%;
		cursor: pointer;
		box-shadow:
			rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
			rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}
}

.error-text {
	color: rgba(255, 0, 0, 0.734);
}
</style>
