/**
 * @module Duplicates
 */
import contactModel from '../../models/contact_store'
import duplicatesModel from '../../models/duplicates_model'

const MUTATIONS = {
	SET_LOADING_DELETE_DUPLICATES: 'SET_LOADING_DELETE_DUPLICATES',
	SET_DID_UPDATE_MERGED_CONTACT: 'SET_DID_UPDATE_MERGED_CONTACT',
	SET_DID_DELETE_UNMERGED_CONTACTS: 'SET_DID_DELETE_UNMERGED_CONTACTS',
	SET_DID_UNLINK_UNMERGED_CONTACTS: 'SET_DID_UNLINK_UNMERGED_CONTACTS',
	SET_DID_UNLINK_SELECTED_CONTACTS: 'SET_DID_UNLINK_SELECTED_CONTACTS',
	SET_STATUS_CREATION_LINKS: 'SET_STATUS_CREATION_LINKS',
	SET_CONTACTS_TO_UNLINK: 'SET_CONTACTS_TO_UNLINK',
	SET_CONTACT_DUPLICATES: 'SET_CONTACT_DUPLICATES',
	RESET_DUPLICATES_MODULE: 'RESET_DUPLICATES_MODULE',
}

export const ACT_DUPLICATES = {
	actionMergeAndDeleteSelectedContacts: 'actionMergeAndDeleteSelectedContacts',
	actionDeleteNonSelectedMergedContacts: 'actionDeleteNonSelectedMergedContacts',
	actionSaveTheNewContactMerged: 'actionSaveTheNewContactMerged',
	actionUnlinkSelectedContacts: 'actionUnlinkSelectedContacts',
	actionUnlinkNonSelectedMergedContacts: 'actionUnlinkNonSelectedMergedContacts',
	actionResetDuplicatesModule: 'actionResetDuplicatesModule',
	actionSetContactsToUnlink: 'actionSetContactsToUnlink',
	actionSetContactDuplicates: 'actionSetContactDuplicates',
	actionLinkContactsFromDatabase: 'actionLinkContactsFromDatabase',
}

const getDefaultState = () => {
	return {
		didUpdateMergedContact: false,
		didDeleteUnmergedContacts: false,
		didUnlinkUnmergedContacts: false,
		didUnlinkSelectedContacts: false,
		statusCreationLinks: 'init',
		contactsToUnlink: [],
		contactDuplicates: [],
	}
}

const state = getDefaultState

import store from '../stateStore'

const mutations = {
	[MUTATIONS.SET_DID_UPDATE_MERGED_CONTACT]: (currentState, payload) => {
		currentState.didUpdateMergedContact = payload
	},
	[MUTATIONS.SET_DID_DELETE_UNMERGED_CONTACTS]: (currentState, payload) => {
		currentState.didDeleteUnmergedContacts = payload
	},
	[MUTATIONS.SET_DID_UNLINK_UNMERGED_CONTACTS]: (currentState, payload) => {
		currentState.didUnlinkUnmergedContacts = payload
	},
	[MUTATIONS.SET_DID_UNLINK_SELECTED_CONTACTS]: (currentState, payload) => {
		currentState.didUnlinkSelectedContacts = payload
	},
	[MUTATIONS.SET_STATUS_CREATION_LINKS]: (currentState, payload) => {
		currentState.statusCreationLinks = payload
	},
	[MUTATIONS.RESET_DUPLICATES_MODULE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[MUTATIONS.SET_CONTACTS_TO_UNLINK]: (currentState, payload) => {
		currentState.contactsToUnlink = payload
	},
	[MUTATIONS.SET_CONTACT_DUPLICATES]: (currentState, payload) => {
		currentState.contactDuplicates = payload
	},
}

const actions = {
	async [ACT_DUPLICATES.actionDeleteNonSelectedMergedContacts]({ commit }, payload) {
		Promise.all(
			payload.map(async (aContactToDelete) => {
				await contactModel.deleteContact(aContactToDelete.id)
			}),
		).then(() => {
			commit(MUTATIONS.SET_DID_DELETE_UNMERGED_CONTACTS, true)
		})
	},

	async [ACT_DUPLICATES.actionUnlinkNonSelectedMergedContacts]({ commit }, payload) {
		const { originalId, idsToUnlink } = payload
		Promise.all(
			idsToUnlink.map(async (aContactToUnlink) => {
				await duplicatesModel.unlinkContacts([{ id: originalId }, { id: aContactToUnlink }])
			}),
		).then(() => {
			commit(MUTATIONS.SET_DID_UNLINK_UNMERGED_CONTACTS, true)
		})
	},

	async [ACT_DUPLICATES.actionUnlinkSelectedContacts]({ commit }, payload) {
		let pairs = (arr) => arr.map((v, i) => arr.slice(i + 1).map((w) => [v, w])).flat()
		/**
		 * The pairing is a simple function that will create a pair from an array
		 * It will create all the possibilities. If you want more info about that,
		 * go to : https://stackoverflow.com/a/57834210/10160210
		 */
		const pairingIdsToUnlink = pairs(payload)
		/**
		 * This array can be used by the backend to
		 * unlink the contacts. Its an array of array of ids
		 */
		const finalDataToSend = pairingIdsToUnlink.map((aPair) => {
			return [
				{
					id: aPair[0],
				},
				{
					id: aPair[1],
				},
			]
		})
		await duplicatesModel.unlinkContacts(finalDataToSend).then(() => {
			commit(MUTATIONS.SET_DID_UNLINK_SELECTED_CONTACTS, true)
		})
	},

	async [ACT_DUPLICATES.actionSaveTheNewContactMerged]({ commit }, payload) {
		const contactToUpdate = payload
		contactModel.updateContact(contactToUpdate).then(() => {
			commit(MUTATIONS.SET_DID_UPDATE_MERGED_CONTACT, true)
		})
	},

	[ACT_DUPLICATES.actionResetDuplicatesModule]({ commit }) {
		commit(MUTATIONS.RESET_DUPLICATES_MODULE)
	},

	[ACT_DUPLICATES.actionLinkContactsFromDatabase]({ commit }, payload) {
		commit(MUTATIONS.SET_STATUS_CREATION_LINKS, 'loading')
		const payloadToSend = {
			link: {
				contacts: payload.map((aContactId) => {
					return { id: aContactId }
				}),
				type_of_relation: 'ExactDuplicates',
			},
		}
		duplicatesModel
			.linkContacts(payloadToSend)
			.then(() => {
				commit(MUTATIONS.SET_STATUS_CREATION_LINKS, 'success')
			})
			.catch(() => {
				commit(MUTATIONS.SET_STATUS_CREATION_LINKS, 'error')
			})
	},

	async [ACT_DUPLICATES.actionMergeAndDeleteSelectedContacts]({ commit, dispatch }, payload) {
		const { listOfContactsToDelete, finalContactToUpdate, membershipsPayload, interactionsPayload } = payload
		let mandatesFinalContact = finalContactToUpdate.mandates
		delete finalContactToUpdate.mandates

		const queryPromises = []

		queryPromises.push(contactModel.mergeInteractions(interactionsPayload))

		if (membershipsPayload) {
			queryPromises.push(contactModel.mergeMemberships(membershipsPayload))
		}

		queryPromises.push(listOfContactsToDelete.map(async (aContactToDelete) => {
			await contactModel.deleteContact(aContactToDelete.id).then(() => {
				// Delete all mandates

				if (store.getters['@packs/getAccessMandates'] === 'enabled') {
					dispatch(
						'@mandates/actionDeleteAllMandates',
						{
							contactId: aContactToDelete.id,
							saveInStore: false,
						},
						{ root: true },
					)
				}
			})
		}))

		Promise.all(
			queryPromises,
		).then(() => {
			contactModel.updateContact(finalContactToUpdate).then(async (contact) => {
				// Delete all existing mandates

				if (store.getters['@packs/getAccessMandates'] === 'enabled') {
					dispatch(
						'@mandates/actionDeleteAllMandates',
						{
							contactId: contact.id,
							saveInStore: false,
						},
						{ root: true },
					)
				}
				// Add the new mandates into the contact
				if (mandatesFinalContact?.length) {
					Promise.all(
						mandatesFinalContact.map(async (mandate) => {
							if (mandate.gender != contact.gender) {
								let titleMandate = await dispatch(
									'@mandates/actionGetTitle',
									{
										value: mandate.title,
										gender: (!mandate.gender || (mandate.gender !== 'M' && mandate.gender !== 'F')) ? 'O' : mandate.gender,
									},
									{ root: true },
								)

								switch (contact.gender) {
									case 'M':
										mandate.title = titleMandate.masculine
										break
									case 'F':
										mandate.title = titleMandate.feminine
										break
									default:
										mandate.title = titleMandate.neutral
										break
								}
							}
							// Delete gender field for back
							delete mandate.gender
						}),
					).then(
						// Wait for the transformation of each title to be over before send the request
						async () => {
							if (store.getters['@packs/getAccessMandates'] === 'enabled') {
								await dispatch(
									'@mandates/actionCreateMandate',
									{
										contactId: contact.id,
										mandateData: mandatesFinalContact,
										saveInStore: false,
									},
									{ root: true },
								)
							}

							// Wait for the creation before retrieve the mandates informations
							commit(MUTATIONS.SET_DID_UPDATE_MERGED_CONTACT, true)
							commit(MUTATIONS.SET_DID_DELETE_UNMERGED_CONTACTS, true)
						},
					)
				}
				else {
					commit(MUTATIONS.SET_DID_UPDATE_MERGED_CONTACT, true)
					commit(MUTATIONS.SET_DID_DELETE_UNMERGED_CONTACTS, true)
					return
				}
			})
		})
	},

	[ACT_DUPLICATES.actionSetContactsToUnlink]({ commit }, payload) {
		commit(MUTATIONS.SET_CONTACTS_TO_UNLINK, payload)
	},

	[ACT_DUPLICATES.actionSetContactDuplicates]({ commit }, payload) {
		commit(MUTATIONS.SET_CONTACT_DUPLICATES, payload)
	},
}

const getters = {
	getDidUpdateMergeContacts: (state) =>
		state.didUpdateMergedContact && state.didDeleteUnmergedContacts,
	getDidUnlinkSelectedContacts: (state) => state.didUnlinkSelectedContacts,
	getStatusCreationLinks: (state) => state.statusCreationLinks,
	contactsToUnlink: (state) => state.contactsToUnlink,
	contactDuplicates: (state) => state.contactDuplicates,
}

export const duplicatesStore = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
