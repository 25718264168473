import CryptoJS from 'crypto-js'
const secretKey = 'Quorum string for protection'

export const encrypt = (string) => {
	return CryptoJS.AES.encrypt(string, secretKey).toString()
}
export const decrypt = (string) => {
	const bytes = CryptoJS.AES.decrypt(string, secretKey)
	return bytes.toString(CryptoJS.enc.Utf8)
}
