import invoiceStore from 'src/models/invoice_store'

import { FETCH_ALL_INVOICES } from './_mutation-types'

const getDefaultState = () => {
	return {
		listInvoices: [],
	}
}

const state = getDefaultState

const mutations = {
	[FETCH_ALL_INVOICES]: (state, payload) => {
		state.listInvoices = payload
	},
}

const actions = {
	actionFetchInvoices: ({ commit }) => {
		invoiceStore.fecthAllInvoices().then((payload) => {
			commit(FETCH_ALL_INVOICES, payload)
		})
	},
}

const getters = {
	getListInvoices: (state) => state.listInvoices,
	getNumberOfInvoices: (state) => state.listInvoices.length,
}

export const invoiceModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
