import 'quill/dist/quill.snow.css'
import 'src/utils/veeValidate'
// import './assets/scss/element-ui_overide.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as Sentry from '@sentry/vue'
import get from 'lodash/get'
import dayjs from 'dayjs'
import { markRaw } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { createPinia } from 'pinia'
import Packer from '/src/components/packs/packer'
import './assets/css/font-awesome.min.css'
import Dequorum from './assets/dequorum/dequorum'
import { defineLocaleSpecific, i18n } from './middlewares/i18nizer'
import router from './router.js'
import store from './store/stateStore.js'
import mitt from 'mitt'

import app from './appCreation.js'
import installDirectiveTooltip from '@/directives/tooltip'

const ComputedGettersInstance = {
	/// Create a pluggin available inside all components
	/// Allow to add computed getters to the component
	install(Vue) {
		Vue.mixin({
			beforeCreate() {
				let computed = get(this, '$options.computedGettersInstance', null)

				if (computed) {
					if (typeof computed === 'function') {
						computed = computed.call(this, this)
					}

					this.$options.computed = {
						...this.$options.computed,
						...computed,
					}
				}
			},
		})
	},
}

const ActionsMethodsInstance = {
	/// Create a pluggin available inside all components
	/// Allow to add actions methods to the component
	install(Vue) {
		Vue.mixin({
			beforeCreate() {
				let methods = get(this, '$options.actionsMethodsInstance', null)

				if (methods) {
					if (typeof methods === 'function') {
						methods = methods.call(this, this)
					}

					this.$options.methods = {
						...this.$options.methods,
						...methods,
					}
				}
			},
		})
	},
}

const initializeApp = async () => {
	app.config.devtools = process.env.NODE_ENV !== 'production'
	app.config.productionTip = process.env.NODE_ENV !== 'production'

	await import('./middlewares/routesHandler')
	app.component('Packer', Packer)
	app.use(Dequorum)
	app.use(ComputedGettersInstance)
	app.use(ActionsMethodsInstance)
	app.use(VueDOMPurifyHTML, {
		namedConfigurations: {
			target: {
				ADD_ATTR: ['target'],
			},
		},
	})

	await import('./middlewares/interceptor')
	await import('./middlewares/directives')
	await import('./middlewares/fancyTitle')
	await import('./middlewares/filters')
	await import('./middlewares/prototypes')

	for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
		app.component(key, component)
	}

	// Import the correct internationalization file according to the locale
	let localeElement = {}

	await import(`element-plus/dist/locale/${i18n.global.locale.split('-')[0]}.mjs`).then(
		(importedElement) => {
			localeElement = importedElement.default
		},
	).catch(async () => {
		const importedFallbackElement = await import('element-plus/dist/locale/en.mjs')

		localeElement = importedFallbackElement.default
	})
	await import('element-plus').then((ElementPlus) => {
		app.use(ElementPlus, {
			// Maybe don't work anymore
			i18n: (key, value) => i18n.global.t(`VENDORS.ELEMENT_UI.${key}`, value),
			locale: localeElement,
		})
	})

	// Import all locale possible from dayjs
	for (const locale of i18n.global.availableLocales) {
		await import(`dayjs/locale/${locale}.js`)
	}

	// Locale for the el-datepicker
	dayjs.locale(i18n.global.locale)
	await import('./components/badges/Badge.vue').then((Badge) => {
		app.component('QBadge', Badge)
	})

	const pinia = createPinia()

	pinia.use(({ store }) => {
		store.router = markRaw(router)
	})

	app.use(pinia)
	app.use(store)
	app.use(router)
	app.use(i18n)

	installDirectiveTooltip(app)

	defineLocaleSpecific(null)

	 
	if (AUTH_PROVIDER === 'auth0') {
		app.use(
			createAuth0(
				{
					 
					domain: AUTH0_DOMAIN,
					 
					clientId: AUTH0_CLIENT_ID,
					authorizationParams: {
						redirect_uri: `${globalThis.location.origin}/login-auth0-callback`,
						scope: 'openid email profile offline_access',
					},
					useRefreshTokens: true,
				},
				{
					skipRedirectCallback: true,
				},
			),
		)
	}

	if(SENTRY_ENABLED && RELEASE_VERSION){
		Sentry.init({
			app,
			release: RELEASE_VERSION,
			dsn: 'https://b0559d080b214e1b80ed19a69b332d9e@o238278.ingest.us.sentry.io/5373185',
			integrations: [
			],
		})
	}


	app.mount('#app')
}

const emitter = mitt()

app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$store = store

// eslint-disable-next-line no-console
console.info(
	'%c Quorum %c Action Focused %c',
	'background:#FF4270 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #F8F9FA',
	'background:#F8F9FA ; padding: 1px; border-radius: 0 3px 3px 0;  color: #FF4270',
	'background:transparent',
)

initializeApp()
