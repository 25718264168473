/* eslint-disable prettier/prettier */
import { $request } from './requester'

/**
 * @typedef {Object} Invitation
 * @property {Number} id      - unique id
 * @property {Number} group_id - group id of the user
 * @property {String} name    - name of the team
 * @property {User[]} users   - the list of users in this team
 *
 */

/**
 * @model Invitation
 */

export default {
	/**
     * @method getInvitations
     * @description `GET` `/member-invitation`
     * @returns {Promise<Invitation[]>}
     */
	async getInvitations() {
		return $request('GET', '/member-invitations').then((res) => {
			return res.body.data?.invitations
		})
	},

	/**
    * @method sendInvitations
    * @description `POST` `/member-invitation` `Send invitation to another user`
    * @returns {Promise<Invitation[]>}
    */
	async sendEmailInvitations(invitations) {
		return $request('POST', '/member-invitations', invitations).then((res) => {
			return res
		})
	},

	async deleteInvitation(invitation) {
		return $request('DELETE/JSON', `/member-invitations?invitation_id=${invitation.id}`).then((res) => {
			return res
		})
	},
}
