<template>
	<div>
		<div class="section">
			<div class="flex justify-between items-center mb-8">
				<span class="text-sm text-gray-dark uppercase">
					{{ $t('SETTINGS.CAMPAIGN.SETTINGS') }}
				</span>
				<span
					v-if="!editionMode"
					class="flex items-center pointer px-2 rounded-full hover:bg-gray-100"
					@click="editionMode = true"
				>
					<i class="gui-edit text-green-main text-2xl" />
				</span>
				<div v-else>
					<span>
						<q-button
							link
							secondary
							@click="methodCancelEditionMode"
						>
							{{ $t('_COMMON.CANCEL') }}
						</q-button>
					</span>
					<span class="ml-4">
						<q-button
							ghost
							:disabled="!canSaveNewInfo"
							@click="methodSaveInfo"
						>
							{{ $t('_COMMON.SAVE') }}
						</q-button>
					</span>
				</div>
			</div>
			<div>
				<div class="flex flex-col justify-between items-center md:flex-row">
					<div class="flex flex-col">
						<editable-label
							v-model:value="newName"
							:description="$t('APP.SETTINGS.CAMPAIGN.NAME')"
							:edition="editionMode"
							class="mt-2 pb-3"
							@input="methodCanSaveNewInfo"
						>
							<p class="bold text-3xl">
								{{ info_campagne.name }}
							</p>
						</editable-label>

						<editable-label
							v-if="!$route.query.mail || !$route.query.code"
							ref="emailValidator"
							v-model:value="newMail"
							name="code"
							rules="email"
							:description="$t('SETTINGS.CAMPAIGN.REFERY_EMAIL')"
							:edition="editionMode"
							class="mt-1"
							@input="methodCanSaveNewInfo"
						>
							<p class="text-sm text-gray-dark">
								{{ $t('SETTINGS.CAMPAIGN.REFERY_EMAIL') }}
							</p>
							<p class="bold">
								{{ info_campagne.email_referent }}
							</p>
							<div class="pt-4 pr-6 w-full md:w-10/12">
								<span class="text-sm">{{ $t('SETTINGS.CAMPAIGN.REFERY_EMAIL_EXPLANATION') }} </span>
							</div>
						</editable-label>
					</div>
					<div
						class="imageContainer mt-6 w-80 flex flex-col justify-center items-center bg-gray-50 p-2 rounded-lg"
					>
						<div
							v-if="computedGetNewAvatar"
							class="relative"
						>
							<img
								:src="
									computedGetNewAvatar.length > 0
										? computedGetNewAvatar
										: '/static/images/qomon/pink-shorted.svg'
								"
								class="w-32 h-32 rounded-lg object-cover object-center"
							>
						</div>
						<div v-else>
							<img
								src="/static/images/qomon/pink-shorted.svg"
								class="w-32 h-32 rounded-lg object-cover object-center"
								alt="space logo"
							>
						</div>

						<div
							class="py-2"
							@click="isImageUploaderVisible = !isImageUploaderVisible"
						>
							<span
								class="text-pink-main cursor-pointer text-sm hover:opacity-70 hover:underline"
							>{{ $t('SETTINGS.CAMPAIGN.CHOOSE_AVATAR') }}</span>
						</div>
					</div>
				</div>
			</div>
			<hr class="divider">
			<div>
				<editable-label
					v-if="!$route.query.mail || !$route.query.code"
					ref="codeValidator"
					v-model:value="newCode"
					name="code"
					rules="required|min:8|code_invitation|max:30"
					:description="$t('SETTINGS.CAMPAIGN.CODE')"
					:update-instant="true"
					:edition="editionMode"
					class="mt-2"
					@input="methodCanSaveNewInfo"
				>
					<p class="text-sm text-gray-dark mb-4">
						{{ $t('SETTINGS.CAMPAIGN.CODE') }}
					</p>
					<div
						class="code-label bg-green-lighted rounded-full flex justify-between items-center mt-1"
					>
						<span class="mx-6 py-3">{{ info_campagne.code_cause }}</span>
						<div
							class="code-label-clip rounded-full pointer flex items-center justify-center px-4 mr-1 text-sm"
							@click="methodClipCode"
						>
							{{ $t('SETTINGS.CAMPAIGN.COPY_CODE') }}
						</div>
					</div>
					<div class="mt-4">
						<span class="text-sm">
							{{ $t('SETTINGS.CAMPAIGN.CODE_EXPLANATION') }}
						</span>
					</div>
				</editable-label>
			</div>
		</div>
		<!-- <div class="mt-1 section">
			<div class="flex justify-between">
				<span class="text-sm text-gray-dark uppercase">
					{{ $t('SETTINGS.CAMPAIGN.SUBSCRIPTION.TITLE') }}
				</span>
				<q-button class="text-sm" link @click="methodLaunchChat">
					{{ $t('SETTINGS.CAMPAIGN.SUBSCRIPTION.CHANGE') }}
				</q-button>
			</div>
			<div class="flex mt-6">
				<div class="mr-12">
					<p class="text-sm text-gray-dark">
						{{ $t('SETTINGS.CAMPAIGN.SUBSCRIPTION.TYPE') }}
					</p>
					<p class="bold mt-1"><i class="gui-stars color-part-one color-part-two mr-1" />Premium</p>
				</div>
				<div>
					<p class="text-sm text-gray-dark">
						{{ $t('SETTINGS.CAMPAIGN.SUBSCRIPTION.START') }}
					</p>
					<p class="mt-1">18 octobre 2021</p>
				</div>
			</div>
		</div> -->
		<packer
			no-icon
			:status="getSettingsStatusMobileContactsNearby"
		>
			<div class="mt-1 section">
				<span class="text-sm text-gray-dark uppercase">
					{{ $t('SETTINGS.CAMPAIGN.CONFIG_MOBILE') }}
				</span>
				<div class="flex pt-6">
					<i class="gui-flash color-part-two text-3xl px-1 self-center" />
					<div class="switch-container flex justify-between flex-grow ml-8">
						<div class="flex flex-column">
							<span class="bold">
								{{ $t('SETTINGS.CAMPAIGN.AROUND_YOU') }}
							</span>
							<span class="w-5/6">
								{{ $t('SETTINGS.CAMPAIGN.AROUND_YOU_DESCRIPTION') }}
							</span>
						</div>
						<div class="switch">
							<span class="text-sm pr-2">
								{{
									aroundMeEnabled
										? $t('SETTINGS.CAMPAIGN.ACTIVE')
										: $t('SETTINGS.CAMPAIGN.INACTIVE')
								}}
							</span>
							<el-switch
								v-model="aroundMeEnabled"
								@change="methodSaveInfo(true)"
							/>
						</div>
					</div>
				</div>
			</div>
		</packer>
		<div class="mt-1 section">
			<span class="text-sm text-gray-dark uppercase">
				{{ $t('SETTINGS.CAMPAIGN.DUPLICATE_MANAGEMENT.TITLE') }}
			</span>
			<div class="flex pt-6">
				<i
					class="gui-merge text-3xl px-1 self-center"
					:class="{ 'opacity-50': !duplicatesDetection }"
				/>
				<div class="switch-container flex justify-between flex-grow ml-8">
					<div
						class="flex flex-column"
						:class="{ 'opacity-50': !duplicatesDetection }"
					>
						<span class="bold">
							{{ $t('SETTINGS.CAMPAIGN.DUPLICATE_MANAGEMENT.DETECTION') }}
						</span>
						<span class="w-5/6">
							{{ $t('SETTINGS.CAMPAIGN.DUPLICATE_MANAGEMENT.ACTIVATION') }}
						</span>
					</div>
					<div class="switch">
						<q-button
							v-if="!duplicatesDetection"
							@click="methodLaunchChat"
						>
							{{ $t('_COMMON.CONTACT_SUPPORT') }}
						</q-button>
						<div v-else>
							<span class="text-sm pr-2">
								{{ duplicatesDetection && $t('SETTINGS.CAMPAIGN.ACTIVE') }}
							</span>
							<el-switch
								v-model="duplicatesDetection"
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-1 section">
			<span class="text-sm text-gray-dark uppercase flex flex-row gap-x-2 items-center">
				{{ $t('SETTINGS.CAMPAIGN.DELETION.TITLE') }}
				<span class="bg-gray-50 rounded-xl px-2 py-1 text-gray-500 text-xs">
					ID: {{ info_campagne.id }}
				</span>
			</span>
			<div class="delete-container">
				<span class="flex items-end">
					{{ $t('SETTINGS.CAMPAIGN.DELETION.CONFIRMATION') }}
				</span>
				<span
					id="delete-btn"
					@click="methodLaunchChat"
				>
					{{ $t('SETTINGS.CAMPAIGN.DELETION.DELETE') }}
				</span>
			</div>
		</div>
		<image-uploader
			v-if="isImageUploaderVisible"
			:is-visible="isImageUploaderVisible"
			:only-upload="true"
			:img="computedGetNewAvatar"
			:title="$t('SETTINGS.CAMPAIGN.UPLOADER_TITLE')"
			:first-tab-wording="$t('SETTINGS.CAMPAIGN.UPLOADER_FIRST_TAB_TITLE')"
			:is-just-url-wanted="true"
			:which-module="'settings'"
			@create-image-from-url="methodCreateAvatar"
			@close-image-uploader="isImageUploaderVisible = false"
			@delete-space-profil-picture="methodDeleteSpaceProfilPicture"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EditableLabel from '../general/editableLabel.vue'
import ImageUploader from '../general/image-uploader.vue'
import packer from '../packs/packer.vue'
import cloneDeep from 'lodash/cloneDeep'
import { openChat } from '/src/extensions/support'
import '/src/utils/veeValidate'
import { nextTick } from 'vue'

export default {
	components: {
		packer,
		EditableLabel,
		ImageUploader,
	},
	data() {
		return {
			editionMode: false,
			newAvatar: '',
			newName: '',
			newCode: '',
			newMail: '',
			aroundMeEnabled: true,
			canSaveNewInfo: false,
			duplicatesDetection: false,
			isImageUploaderVisible: false,
			isCustomCode: false,
		}
	},

	computed: {
		...mapGetters(['info_campagne']),
		...mapGetters('@packs', ['getSettingsStatusMobileContactsNearby']),

		computedGetNewAvatar() {
			return this.newAvatar
		},
	},

	watch: {
		info_campagne: {
			handler() {
				this.methodSetModificationVariables()
			},
			deep: true,
		},
	},

	mounted() {
		this.methodSetModificationVariables()

		this.aroundMeEnabled = !this.info_campagne.around_me_disabled

		if (this.info_campagne?.code_cause && this.info_campagne?.code_cause.length > 0) {
			const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\u00C0-\u017F]+)$/
			this.isCustomCode = !regex.test(this.info_campagne.code_cause)
		}
		if (Object.prototype.hasOwnProperty.call(this.info_campagne, 'avatar_company'))
			this.newAvatar = this.info_campagne.avatar_company
	},
	methods: {
		...mapActions('@settings', ['actionUpdateInformations', 'actionDeleteSpacePicture']),
		methodClipCode() {
			try {
				navigator.clipboard.writeText(this.info_campagne.code_cause)
				if (navigator.vibrate) {
					const pattern = 100
					navigator.vibrate(pattern)
				}
				this.$message({
					type: 'success',
					duration: 2000,
					showClose: true,
					message: this.$t('SETTINGS.CAMPAIGN.CLIPBOARD_TEXT_SUCCESS'),
				})
			}
			catch (error) {
				this.$message({
					type: 'error',
					duration: 2000,
					showClose: true,
					message: this.$t('SETTINGS.CAMPAIGN.CLIPBOARD_TEXT_ERROR'),
				})
			}
		},
		methodCanSaveNewInfo() {
			// use nextTick to wait newName, newCode, newMail changes prevent to use watchers
			nextTick(async () => {
				const hasChanged
					= this.newName !== this.info_campagne.name
					|| this.newCode !== this.info_campagne.code_cause
					|| this.newMail !== this.info_campagne.email_referent

				const isCodeValid
					= this.isCustomCode && this.newCode === this.info_campagne.code_cause
						? true
						: (await this.$refs.codeValidator.$refs.field.validate()).valid

				const areValid
					= isCodeValid && (await this.$refs.emailValidator.$refs.field.validate()).valid
				this.canSaveNewInfo = hasChanged && areValid
			})
		},
		methodSetModificationVariables() {
			this.newName = this.info_campagne.name
			this.newMail = this.info_campagne.email_referent
			this.newCode = this.info_campagne.code_cause
			this.duplicatesDetection = this.info_campagne.check_doublon
		},

		methodSaveInfo(updateAroundMe = false) {
			const newInfoCampagne = cloneDeep(this.info_campagne)
			if (this.canSaveNewInfo) {
				newInfoCampagne.name = this.newName
				newInfoCampagne.email_referent = this.newMail
				newInfoCampagne.code_cause = this.newCode.toLowerCase()
			}
			if (updateAroundMe) newInfoCampagne.around_me_disabled = !this.aroundMeEnabled
			newInfoCampagne.check_doublon = this.duplicatesDetection

			this.actionUpdateInformations(newInfoCampagne)
				.then(() => {
					this.editionMode = false
				})
				.then(() => {
					this.$message({
						type: 'success',
						message: this.$t('SETTINGS.CAMPAIGN.UPDATE_SUCCESSFUL'),
						showClose: true,
					})
				})
		},
		methodLaunchChat() {
			openChat()
		},

		methodCancelEditionMode() {
			this.editionMode = false
			this.newName = this.info_campagne.name
			this.newCode = this.info_campagne.code_cause
			this.newMail = this.info_campagne.email_referent
		},
		methodCreateAvatar(imgUrl) {
			this.newAvatar = imgUrl
			this.isImageUploaderVisible = false
			const newInfoCampagne = cloneDeep(this.info_campagne)
			newInfoCampagne.avatar_company = imgUrl
			this.actionUpdateInformations(newInfoCampagne).then(() => {
				this.$message({
					type: 'success',
					message: this.$t('SETTINGS.CAMPAIGN.UPDATE_SUCCESSFUL'),
					showClose: true,
				})
			})
		},
		methodDeleteSpaceProfilPicture(imgUrl) {
			const clonedCampaign = cloneDeep(this.info_campagne)
			clonedCampaign.avatar_company = imgUrl
			this.actionDeleteSpacePicture(clonedCampaign)
			this.newAvatar = ''
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.code-label {
	min-width: 14rem;
	max-width: 28rem;
	min-height: 40px;
	height: auto;
	.code-label-clip {
		color: rgba(0, 0, 0, 0.596);
		min-height: 35px;
		border: 1px solid $green-medium;
		transition: all 0.3s ease;
	}
	.code-label-clip:hover {
		background-color: #18533686;
		color: white;
		border-color: transparent;
	}
}
.io-goal-input {
	width: 28rem;
	max-width: 28rem;
	min-height: 40px;
	height: auto;
}
.switch {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 8rem;
}
@media only screen and (max-width: 555px) {
	.switch-container {
		flex-direction: column;
	}
	.switch {
		padding-top: 1.5rem;
		justify-content: flex-start;
	}
	.io-goal-input {
		width: 100% !important;
	}
}
.delete-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 0.5rem 0;
}
@media only screen and (max-width: 660px) {
	.delete-container {
		display: flex;
		flex-direction: column;
	}
	#delete-btn {
		margin-top: 15px;
	}
}
.divider {
	border-color: transparent;
	border-top: 1px solid #ebebeb;
	margin: 1.8rem 0;
}
.section {
	width: 100%;
	background-color: white;
	padding: 2rem;
}
#delete-btn {
	border: 1px solid #222230;
	border-radius: 60px;
	width: fit-content;
	padding: 0.4rem 3rem;
	font-size: 13px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background-color: $pink-main;
		border-color: transparent;
		color: white;
	}
}
.imageContainer:hover {
	.deletePictureIcon {
		opacity: 1;
		transition: all 0.4s ease;
	}
}
.deletePictureIcon {
	transition: all 0.4s ease;
	opacity: 0;
	position: absolute;
	top: -5px;
	right: 0;
	font-size: 15px;
	padding: 10px 13px;
	border-radius: 0.4rem;
	cursor: pointer;
	background-color: white;

	&:hover {
		color: rgba(255, 0, 0, 0.826);
	}
}
</style>
