const defaultContactFormsEnabled = [
	'firstname',
	'surname',
	'gender',
	'age',
	'birthdate',
	'nationality',
	'birthcountry',
	'birthcity',
	'mail',
	'phone',
	'mobile',
	'address.building',
	'address.building_type',
	'address.floor',
	'address.door',
	'address.housenumber',
	'address.street',
	'address.addition',
	'address.city',
	'address.postalcode',
	'address.country',
	'address.pollingstation',
	'address.state',
	'address.county',
]

const staticContactForm = [
	{
		type: 'gender',
		field_type: 'radio',
		label: 'gender',
		name: 'STATIC',
		order: 1,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'firstname',
		field_type: 'text',
		label: 'firstname',
		name: 'STATIC',
		order: 2,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'surname',
		field_type: 'text',
		label: 'surname',
		name: 'STATIC',
		order: 3,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'married_name',
		field_type: 'text',
		label: 'married_name',
		name: 'STATIC',
		order: 4,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'birthdate',
		field_type: 'text',
		label: 'birthdate',
		name: 'STATIC',
		order: 5,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'age',
		field_type: 'text',
		label: 'age',
		name: 'STATIC',
		order: 6,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'nationality',
		field_type: 'text',
		label: 'nationality',
		name: 'STATIC',
		order: 7,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'birthcity',
		field_type: 'text',
		label: 'birthcity',
		name: 'STATIC',
		order: 8,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'birthcountry',
		field_type: 'text',
		label: 'birthcountry',
		name: 'STATIC',
		order: 9,
		group: 'identity',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'mail',
		field_type: 'text',
		label: 'mail',
		name: 'STATIC',
		order: 10,
		group: 'infos',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'mobile',
		field_type: 'text',
		label: 'mobile',
		name: 'STATIC',
		order: 11,
		group: 'infos',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'phone',
		field_type: 'text',
		label: 'phone',
		name: 'STATIC',
		order: 12,
		group: 'infos',
		mandatory: false,
		enabled: false,
		divider: false,
		is_undupe_field: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.building_type',
		field_type: 'text',
		label: 'building_type',
		name: 'STATIC',
		order: 13,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.building',
		field_type: 'text',
		label: 'building',
		name: 'STATIC',
		order: 14,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.floor',
		field_type: 'text',
		label: 'floor',
		name: 'STATIC',
		order: 15,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.door',
		field_type: 'text',
		label: 'door',
		name: 'STATIC',
		order: 16,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.housenumber',
		field_type: 'text',
		label: 'housenumber',
		name: 'STATIC',
		order: 17,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: true,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.street',
		field_type: 'text',
		label: 'street',
		name: 'STATIC',
		order: 18,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.addition',
		field_type: 'text',
		label: 'street',
		name: 'STATIC',
		order: 19,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.state',
		field_type: 'text',
		label: 'state',
		name: 'STATIC',
		order: 20,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
	},
	{
		type: 'address.county',
		field_type: 'text',
		label: 'county',
		name: 'STATIC',
		order: 21,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
	},
	{
		type: 'address.postalcode',
		field_type: 'text',
		label: 'postalcode',
		name: 'STATIC',
		order: 22,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.city',
		field_type: 'text',
		label: 'city',
		name: 'STATIC',
		order: 23,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.country',
		field_type: 'text',
		label: 'country',
		name: 'STATIC',
		order: 24,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: false,
		is_geolocated: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
	{
		type: 'address.pollingstation',
		field_type: 'text',
		label: 'pollingstation',
		name: 'STATIC',
		order: 25,
		group: 'address',
		mandatory: false,
		enabled: false,
		divider: true,
		// is_placeholder: false,
		// placeholder: '',
		// is_description: false,
		// description: '',
		// is_default_value: false,
		// default_value: '',
	},
]

const buildingTypeFilterMapping = {
	houseSubTypes: [
		'address.housenumber',
		'address.street',
		'address.addition',
		'address.postalcode',
		'address.city',
		'address.country',
		'address.state',
		'address.county',
		'address.pollingstation',
	],

	buildingSubTypes: [
		'address.housenumber',
		'address.street',
		'address.addition',
		'address.postalcode',
		'address.city',
		'address.country',
		'address.state',
		'address.county',
		'address.building',
		'address.floor',
		'address.door',
		'address.pollingstation'],

	officeSubTypes: [
		'address.housenumber',
		'address.street',
		'address.addition',
		'address.postalcode',
		'address.city',
		'address.country',
		'address.state',
		'address.county',
		'address.building',
		'address.floor',
		'address.door',
		'address.pollingstation',
	],
}

export default {
	defaultContactFormsEnabled,
	staticContactForm,
	buildingTypeFilterMapping,
}

export { buildingTypeFilterMapping }
