import { $request } from './requester'
/**
 * @model Contact
 */

/**
 * @typedef Tag
 * @global
 * @prop {String} name
 * @prop {String} color
 */

export default {
	/**
	 * GET `/contacts`
	 * @deprecated not used and should not be !
	 * @returns {Contact[]}
	 */
	async list() {
		return $request('GET', '/contacts').then((res) => res.body.contacts)
	},

	/**
	 * POST `/search`
	 * @param {Object} searchQuery query to search on
	 */

	async find(searchQuery) {
		return $request('POST', '/search', searchQuery).then((res) => {
			const { contacts, AddressAggs } = res.body.data
			return contacts ? contacts : AddressAggs ? AddressAggs : 0
		})
	},

	/**
	 * POST `/bulkAddContactsService`
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 * @param {Boolean} only_duplicates duplicates filter
	 * @param {extraPayload[]}
	 */
	async bulkAddContactsService(
		listkeys = [],
		query = '',
		fields,
		polygon = [],
		tags = [],
		only_duplicates = false,
		extraPayload = {},
		contact_ids = null,
	) {
		const payload = { query, fields, polygon, tags, only_duplicates, ...extraPayload }
		if (contact_ids) payload.contact_ids = contact_ids

		return $request('POST', '/newsuscribersmailchimp', {
			listkeys,
			search: {
				Search: payload,
			},
		}).then(() => {
			return true
		})
	},

	/**
	 * POST `/kpi`
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 * @param {Boolean} only_duplicates filter on duplicates
	 */
	async kpi(searchQuery) {
		return $request('POST', '/kpi', searchQuery).then((res) => res.body.data)
	},

	/**
	 * POST `/kpi-presence-status`
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 * @param {Boolean} only_duplicates filter on duplicates
	 */
	async kpiPresenceStatus(searchQuery) {
		return (
			$request('POST', '/kpi-presence-status', searchQuery)
				.then((res) => res.body.data)
				// the request might fail because there are multiple status forms, etc.
				// We don't want to crash under such circumstances
				.catch((e) => {
					// eslint-disable-next-line no-console
					console.warn(e)
					return {}
				})
		)
	},

	/**
	 * POST `/contactsSearchEmailsKpi`
	 * @param {Object} search search
	 */
	async getContactsSearchEmailsKpi(search) {
		return $request('POST', '/emailcontacts', { search: search }).then((res) => res.body.data)
	},

	/**
	 * POST `/aggregation`
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 */
	async aggregation(fields, polygon) {
		return $request('POST', '/aggregation', { fields, polygon })
	},

	/**
	 * POST `/dateAggregation`
	 */
	async dateAggregation() {
		return $request('POST', '/dateAggregation', {})
	},

	/**
	 * POST `/locationSummary`
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 */
	async locationSummary(fields, polygon) {
		return $request('POST', '/locationSummary', { fields, polygon })
	},

	/**
	 * POST `/locationSummaryContactswithAggregationFilter`
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {*} precision
	 */
	async locationSummaryContactswithAggregationFilter(fields, polygon, precisiongeohash) {
		return $request('POST', '/locationSummary', {
			fields,
			polygon,
			precisiongeohash,
		})
	},

	/**
	 * POST `/locationSummaryContactsGeoHash`
	 * @param {String} query query to search on
	 * @param {String[]} fields fields to filter
	 * @param {Point[]} polygon polygon to filter on
	 * @param {Tag[]} tags tag to filter on
	 * @param {Boolean} only_duplicates duplicates filter
	 */

	async locationSummaryGeoHash(searchQuery) {
		return $request('POST', '/locationSummaryContactsGeoHash', searchQuery).then(
			(res) => res.body.data,
		)
	},

	async locationSummaryFactsGeoHash(
		query,
		fields,
		polygon,
		tags,
		only_duplicates = false,
		type_data,
		id,
		status,
		precisiongeohash,
		extraPayload,
	) {
		return $request('POST', '/locationSummaryFactsGeoHash', {
			search: {
				Search: {
					query,
					fields,
					polygon,
					tags,
					only_duplicates,
				},
			},
			type_data,
			id,
			status,
			precisiongeohash,
			...extraPayload,
		})
	},

	/**
	 * GET `/contacts/:id`
	 * @param {Number} id of the contact to get
	 */
	async getContactById(id) {
		return $request('GET', `/contacts/${id}`).then((res) => res.body.data)
	},
	async getContactMembershipInfos(id) {
		return $request('GET', `/v2/memberships/contact/${id}`).then((res) => res.body.data)
	},
	async getContactTransactionInfos(id) {
		return $request('GET', `/contacts/${id}/transactions`).then((res) => res.body.data)
	},

	/**
	 * POST `/contacts`
	 * @param {Contact} contact
	 * @return {Contact}
	 */
	async saveContact(contact) {
		return $request('POST', '/contacts', { contact }).then((res) => {
			return res.body.data.contact
		})
	},

	/**
	 * PATCH `/contacts/:id`
	 * @param {Contact} contact
	 * @return {Contact}
	 */
	async updateContact(contact) {
		return $request('PATCH', `/contacts/${contact.id}`, { contact }).then((res) => {
			return res.body.data.contact
		})
	},

	/**
	 * POST `/metamorph`
	 * @param {Object} payload
	 * Use for contacts massive update with metamorph service.
	 * Send array of object with attr, value && contact_ids/search
	 * @return {Object} status
	 */
	async contactsMassiveUpdate(payload) {
		return $request('POST/WITHOUT_DATA_KEY', '/metamorph', payload)
			.then((res) => {
				res.body.data
			})
			.catch((err) => {
				console.warn(err)
			})
	},

	/**
	 * DELETE `/contacts/:id`
	 * @param {Number} id
	 * @return {*}
	 */
	async deleteContact(id) {
		return $request('DELETE', `/contacts/${id}`)
	},

	/**
	 * POST `/extract`
	 * @param {String} query
	 * @param {Array} filter
	 * @param {Point[]} polygon
	 * @param {Tag[]} tags
	 * @param {Number[]} contact_ids
	 * @param {Boolean} only_duplicates
	 *
	 * @return {Array} array to put into csv and download!
	 */
	async extract(
		query,
		// query,
		// filter,
		// polygon,
		// tags,
		// only_duplicates = false,
		// contact_ids,
		// extraPayload = null
	) {
		// TODO: add duplicates_filter
		return $request('POST', '/extract', query).then((res) => {
			return res.body.data
		})
	},

	/**
	 * POST `/search?only_id=true`
	 * @param {String} query
	 * @param {Array} filter
	 * @param {Point[]} polygon
	 * @deprecated not used
	 *
	 * @return {Contact[]}
	 */
	async getOnlyIds(query, filter, polygon) {
		filter[2] = '1000000'
		return $request('POST', '/search?only_id=true', {
			query,
			fields: filter,
			polygon,
		}).then((res) => {
			return res.body.data
		})
	},

	/**
	 * GET `/tags`
	 * @async
	 * @returns {Tag[]}
	 */
	async getTags() {
		return $request('GET', '/tags').then((res) => {
			return res.body.data
		})
	},

	/**
	 * @func fetchAsyncHistoryFromContact
	 */
	async fetchAsyncHistoryFromContact(id) {
		return $request('GET', `/contacts/${id}/history`).then((res) => {
			return res.body.data
		})
	},

	/**
	 * @func fetchAsyncNotesFromContact
	 */
	async fetchAsyncNotesFromContact(id) {
		return $request('GET', `/contacts/${id}/notes`).then((res) => {
			return res.body.data
		})
	},

	/**
	 * @func updateAsyncNotesFromContact
	 */
	async updateAsyncNoteFromContact(payload) {
		return $request(
			'PATCH',
			`/contacts/${payload.contactId}/notes/${payload.noteId}`,
			payload.noteData,
		).then((res) => {
			return res.body.data
		})
	},

	/**
	 * @func fetchAsyncNotesFromContact
	 */
	async fetchAsyncPostNotesForContact(id, noteContent, isPinned, only_super_admin) {
		return $request('POST', `/contacts/${id}/notes`, {
			note: {
				data: noteContent,
				only_super_admin,
				pinned: isPinned,
			},
		}).then((res) => {
			return res.body.data
		})
	},

	async fetchAsyncDeleteNoteFromContact(idContact, idNote) {
		return $request('DELETE', `/contacts/${idContact}/notes/${idNote}`).then((res) => {
			return res
		})
	},

	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},

	async fetchAsyncInteractionsFromContact(idContact) {
		return $request('GET', `/contacts/${idContact}/interactions`).then((res) => {
			return res.body.data
		})
	},

	async postAsyncInteractionForContact(idContact, newInteraction) {
		return $request('POST', `/contacts/${idContact}/interactions`, newInteraction).then((res) => {
			return res.body.data
		})
	},

	async getAttachmentsIDForInteraction(payload) {
		return $request('POST/MULTIPART', `/uploaddoc`, payload).then((res) => {
			return res.body.data
		})
	},

	async updateAsyncInteractionForContact(idContact, newInteraction) {
		return $request('PATCH', `/contacts/${idContact}/interactions`, newInteraction).then((res) => {
			return res.body.data
		})
	},

	async deleteAsyncContactInteraction(idContact, interactionId) {
		return $request('DELETE', `/contacts/${idContact}/interaction/${interactionId}`).then(
			(res) => {
				return res
			},
		)
	},

	async getContactEmailsList(userId) {
		return $request('GET', `/getemails/${userId}`).then((res) => {
			if (Object.prototype.hasOwnProperty.call(res.body.data, 'Emails')) return res.body.data.Emails
			return []
		})
	},

	async updateEmailAsRead(imapMessageId, emailEngineId) {
		const payload = {
			email: {
				imap_message_id: imapMessageId,
				email_engine_id: emailEngineId,
			},
		}
		return $request('POST', '/seenemail', payload)
	},

	async sendEmailToContact(emailContext, contactId) {
		const payload = {
			email: {
				object: emailContext.object,
				subject: emailContext.subject,
				email_engine_id: emailContext.emailEngineId,
			},
		}
		return $request('POST', `/sendemail/${contactId}`, payload)
	},

	async getAttachment(emailEngineId, attachmentId, onlyDownload = true) {
		const payload = {
			email_acount: {
				email_engine_id: emailEngineId,
			},
			attachment_id: attachmentId,
		}
		$request('POST/BLOB', '/getattachment', payload).then((res) => {
			var blob = res.body
			var filename = ''
			var disposition = res.headers['content-disposition']
			if (disposition && disposition.indexOf('attachment') !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
				var matches = filenameRegex.exec(disposition)
				if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
			}

			if (typeof window.navigator.msSaveBlob !== 'undefined') {
				// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
				window.navigator.msSaveBlob(blob, filename)
			}
			else {
				var URL = window.URL || window.webkitURL
				var downloadUrl = URL.createObjectURL(blob)

				if (filename) {
					// use HTML5 a[download] attribute to specify filename
					var a = document.createElement('a')
					// safari doesn't support this yet
					if (typeof a.download === 'undefined') {
						window.location.href = downloadUrl
					}
					else {
						if (onlyDownload) {
							a.href = downloadUrl
							a.download = filename
							document.body.appendChild(a)
							a.click()
						}
						else {
							window.open(downloadUrl, '_blank')
						}
					}
				}
				else {
					window.location.href = downloadUrl
				}

				setTimeout(function () {
					URL.revokeObjectURL(downloadUrl)
				}, 100) // cleanup
			}
		})
	},

	async filters(payload) {
		return $request('POST/WITHOUT_DATA_KEY', `/advanced-search`, payload).then((res) => {
			return res.body.data
		})
	},

	async fetchGetCategories() {
		return $request('GET', '/getagecategories').then((res) => {
			return res.body.data
		})
	},

	async fetchLightContacts(contactId) {
		return $request('POST', `/contacts_for_display`, contactId).then((res) => {
			return res.body.data
		})
	},
	async fetchContactsSanctionsList() {
		return $request('GET', `/sanction_types`).then((res) => {
			return res.body.data
		})
	},
	async fetchSanctionAuthoritiesList() {
		return $request('GET', `/sanction_authorities`).then((res) => {
			return res.body.data
		})
	},

	async fetchAContactSanctions(id) {
		return $request('GET', `/contacts/${id}/sanctions`).then((res) => res.body.data)
	},

	async postSanction(payload) {
		return $request('POST', `/sanctions`, payload).then((res) => {
			return res.body.data
		})
	},
	async deleteSanction(id) {
		return $request('DELETE', `/sanctions/${id}`).then((res) => {
			return res
		})
	},
	async patchSanction(sanctionId, payload) {
		return $request('PATCH', `/sanctions/${sanctionId}`, payload).then((res) => {
			return res.body.data
		})
	},

	async mergeMemberships(payload) {
		return $request('POST', `/contacts/merge_transactions`, payload).then(() => {
			return true
		})
	},

	async mergeInteractions(payload) {
		return $request('POST', `/contacts/merge_interactions`, payload).then(() => {
			return true
		})
	},
}
