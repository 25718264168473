import { $request } from './requester'
import cloneDeep from 'lodash/cloneDeep'

/**
 * @typedef {Object} Filter
 * @property {Number} id
 * @property {String} title - name of the lists
 * @property {String} created_at
 * @property {String} updated_at
 * @property {Number} user_id - creator
 * @property {String} query - the QueryNode object
 * @property {Boolean} is_favorite
 */

export default {
	/**
	 * `GET` `/filters`
	 * @returns {Filter}
	 */
	async getFilters() {
		return $request('GET', `/filters`).then((res) => {
			if (res.statusCode === 200) return res.body.data
		})
	},

	/**
	 * PATCH `/filters`
	 * @param {Filter} filter to update
	 */
	async updateFilter(filter) {
		return $request('PATCH', `/filters`, filter).then((res) => res.statusCode === 200)
	},

	/**
	 * DELETE `/filter/:id`
	 * @param {Number} id - the filter's id to delete
	 */
	async deleteFilter(filter) {
		return $request('DELETE/JSON', `/filters`, filter).then((res) => res.statusCode === 200)
	},

	/**
	 * POST `/filters`
	 * @param {Filter} filter to add
	 * @returns {Number|String} id of the filter added
	 */
	async addFilter(filter) {
		let cloneFilter = cloneDeep(filter)
		if ('id' in filter) delete cloneFilter.id
		return $request('POST', '/filters', cloneFilter).then((res) => {
			if (res.statusCode === 200) return { filter: res.body.data }
			else return false
		})
	},
}
