<template>
	<div>
		<!-- Section header -->
		<div
			v-if="accessRoles == 'enabled'"
			class="flex items-center bg-white p-8"
		>
			<p class="w-7/12">
				{{ t('SETTINGS.ROLE_MANAGEMENT.DESCRIPTION') }}
			</p>
			<div class="w-5/12 flex justify-end gap-6 roleManagementButton">
				<button
					v-if="isRoleEditing"
					type="button"
					class="text-pink-main hover:opacity-80 flex items-center gap-2"
					@click="
						() => {
							isAddRoleDialog = true
						}
					"
				>
					<i class="gui-more text-xl" />
					<div>{{ t('SETTINGS.ROLE_MANAGEMENT.CREATE_ROLE') }}</div>
				</button>
				<button
					v-if="isRoleEditing"
					type="button"
					class="text-gray-strong hover:opacity-80"
					@click="methodCancelEdit"
				>
					<div>{{ t('_COMMON.CANCEL') }}</div>
				</button>
				<q-button
					:class="isRoleEditing ? '' : 'ghost'"
					@click="methodSaveOrEditButton"
				>
					{{ isRoleEditing ? t('_COMMON.SAVE') : t('SETTINGS.ROLE_MANAGEMENT.MANAGE_BUTTON') }}
				</q-button>
			</div>
		</div>
		<div
			v-else
			class="flex bg-white p-8"
		>
			<div
				class="bg-yellow-50 border border-yellow-200 rounded-3xl w-full py-4 px-6 flex flex-col gap-4"
			>
				<div class="flex gap-3">
					<span class="text-gray-strong">
						{{ t('SETTINGS.ROLE_MANAGEMENT.UPSELL_ACCESS') }}
					</span>
					<div class="bg-white w-fit flex items-center gap-1 px-2 rounded-md">
						<i class="gui-stars text-green-main" />
						<span>{{ t('BADGES.PREMIUM') }}</span>
					</div>
					<div class="bg-white w-fit flex items-center gap-1 px-2 rounded-md">
						<i class="gui-stars text-blue-main" />
						<span>{{ t('BADGES.ORGANIZATION') }}</span>
					</div>
				</div>
				<div class="flex justify-between">
					<div class="text-gray-strong w-fit">
						{{ t('SETTINGS.ROLE_MANAGEMENT.UPSELL') }}
					</div>
					<div>
						<span
							class="text-yellow-dark underline cursor-pointer hover:opacity-80"
							@click="isUpsellPopinShown = true"
						>
							{{ t('_COMMON.LEARN_MORE') }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<!-- Section table -->
		<!-- The if part permite force rerender when the list is updated -->
		<div
			v-if="!isRoleListLoading"
			class="bg-white p-8 mt-1"
		>
			<roleAccessTable
				ref="accessTableRef"
				v-model:roles-list="clonedRoles"
				:is-editing="isRoleEditing"
				:access-list="accessList"
				:access-dependencies="accessDependencies"
				:default-roles="listTemplates"
			/>
		</div>
		<!-- Popup add role -->
		<popupAddRole
			v-model:display-dialog="isAddRoleDialog"
			:default-roles-list="listTemplates"
			:method-add-role="
				(role) => {
					methodAddTempRole(role)
				}
			"
			@scroll-end-table="accessTableRef.methodScrollRight()"
		/>
		<!-- Popup delete role -->
		<popupDeleteRole
			v-for="(roleToDelete, index) in rolesToDelete"
			:key="index"
			v-model:display-dialog="roleToDelete.dialogShown"
			:role="roleToDelete.role"
			:roles-list="clonedRoles"
			:method-delete-role="methodDeleteRole"
		/>
		<!-- Popin for stater -->
		<popin-upsell
			v-model:is-pop-up-shown="isUpsellPopinShown"
			source="Role management"
		/>
	</div>
</template>

<script setup>
import { useStore } from 'vuex'
import { reactive, ref, computed, onMounted, watch } from 'vue'
import roleAccessTable from './roleAccessTable.vue'
import popupAddRole from './popupAddRole.vue'
import popupDeleteRole from './popupDeleteRole.vue'
import { cloneDeep, find, isEqual, differenceBy } from 'lodash'
import PopinUpsell from '../../packs/upsellPopin.vue'
import { useI18n } from 'vue-i18n'

// Data
const store = useStore()
const { t } = useI18n()
let isRoleEditing = ref(false)
let isAddRoleDialog = ref(false)
let clonedRoles = reactive([])
// Variable to force the rerender of the cloned list of roles when modified
let isRoleListLoading = ref(false)
let isUpsellPopinShown = ref(false)
let rolesToDelete = reactive([])

// List of access for the array with a list of section containing a list of access
let accessList = reactive([])
const accessTableRef = ref(null)

// For a specified access, array of other access needed to unlock it and label to display when disabled
let accessDependencies = ref(null)

// Computed
const listRoles = computed(() => store.getters['@roleManagement/getRolesList'])
const listTemplates = computed(() => store.getters['@roleManagement/getTemplatesList'])
const accessRoles = computed(() => store.getters['@packs/getAccessRoles'])
const computedGetAccessWebSegmentsLimitation = computed(() => store.getters['@packs/getAccessWebSegmentsLimitation'])

// Watch
watch(listRoles, () => {
	isRoleListLoading.value = true
	clonedRoles = reactive(cloneDeep(listRoles.value))
	isRoleListLoading.value = false
})

// Mounted
onMounted(() => {
	methodInitializeVariableWithWording()
	methodRetrieveLists()
	methodCollapseMenu()
})

// Method
const methodRetrieveLists = () => {
	store.dispatch('@roleManagement/actionRetrieveLists')
}

const methodAddRole = (role) => {
	store.dispatch('@roleManagement/actionAddRole', role)
}

const methodUpdateRole = (role) => {
	store.dispatch('@roleManagement/actionUpdateRole', role)
}

const methodDeleteRole = (roleId) => {
	store.dispatch('@roleManagement/actionDeleteRole', roleId)
}

const methodCollapseMenu = () => {
	store.dispatch('@navBar/actionSetMenuCollapse', true)
}

const methodInitializeVariableWithWording = () => {
	accessList = reactive([
		{ blockLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.MOBILE.LABEL'), key: 'mobile' },
		{
			blockLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.LABEL'),
			blockSublabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SUBTITLE'),
			key: 'web',
			blockAccess: [
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.LABEL'),
					access: [
						{
							key: 'data_insight:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.INSIGHTS'),
						},
						{
							key: 'carto.turf:read',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.TURF_READ'),
						},
						{
							key: 'carto.turf:write',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.TURF_WRITE'),
						},
						{
							key: 'carto.turf:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.TURF_DELETE'),
						},
						{
							key: 'dashboard:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.DASHBOARD'),
						},
					],
				},
				{
					sectionLabel: t(
						'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.LABEL',
					),
					access: [
						{
							key: 'transaction_dashboard:read',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.DASHBOARD',
							),
						},
						{
							key: 'transaction:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.ADD_TRANSACTION',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.ADD_TRANSACTION_SUBTITLE',
							),
						},
						{
							key: 'membership:min',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.MEMBERSHIP_STATUS',
							),
						},
						{
							key: 'transaction_all:read',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.VIEW_TRANSACTION',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.VIEW_TRANSACTION_SUBTITLE',
							),
						},
						{
							key: 'transaction_all:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.EDIT_TRANSACTION',
							),
						},
						{
							key: 'transaction:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.DELETE_TRANSACTION',
							),
						},
						{
							key: 'transaction_all:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.DELETE_ALL_TRANSACTION',
							),
						},
						{
							key: 'reopen_batch:self',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REOPEN_BATCH',
							),
						},
						{
							key: 'reopen_batch:all',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REOPEN_ALL_BATCH',
							),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.LABEL'),
					access: [
						{
							key: 'contacts:read',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.CONTACT_READ',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.CONTACT_READ_SUBTITLE',
							),
						},
						{
							key: 'contacts:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.CONTACT_WRITE',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.CONTACT_WRITE_SUBTITLE',
							),
						},
						{
							key: 'contacts:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.CONTACT_DELETE',
							),
						},
						{
							key: 'contacts.locked_fields:write',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.LOCKED_FIELDS_EDIT'),
						},
						{
							key: 'contacts.duplicates:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.DUPLICATE'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.DUPLICATE_SUBTITLE',
							),
						},
						{
							key: 'contacts.massive:update',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MASSIVE_UPDATE',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MASSIVE_UPDATE_SUBTITLE',
							),
						},
						{
							key: 'contacts.massive:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MASSIVE_DELETE',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MASSIVE_DELETE_SUBTITLE',
							),
						},
						{
							key: 'contacts.private_interactions:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.PRIVATE_INTERACTIONS',
							),
						},
						{
							key: 'mandate:read',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MANDATE_READ',
							),
						},
						{
							key: 'mandate:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.MANDATE_WRITE',
							),
						},
						// sanction
						{
							key: 'sanction:read',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.SANCTION_READ',
							),
						},
						{
							key: 'sanction:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.SANCTION_WRITE',
							),
						},
						// end sanction
						{
							key: 'export:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.EXPORT'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.EXPORT_SUBTITLE',
							),
						},
						{
							key: 'import:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.IMPORT'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.IMPORT_SUBTITLE',
							),
						},
						{
							key: 'contacts.list:delete',
							label: t(computedGetAccessWebSegmentsLimitation.value === 'enabled' ? 'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.LIST_AND_SEGMENT' : 'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.LIST'),
							sublabel: t(
								computedGetAccessWebSegmentsLimitation.value === 'enabled' ? 'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.LIST_AND_SEGMENT_SUBTITLE' : 'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.LIST_SUBTITLE',
							),
						},
						{
							key: 'task:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.TASK'),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.LABEL'),
					access: [
						{
							key: 'communication:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION_SUBTITLE',
							),
						},
						{
							key: 'communication.email:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION_EMAIL',
							),
						},
						{
							key: 'communication.sender:write',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION_SENDERS_EDIT'),
						},
						{
							key: 'communication.sender:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION_SENDERS_AFFECT'),
						},
						{
							key: 'communication.sms:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.COMMUNICATION_SMS',
							),
						},
						{
							key: 'documents:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.DOCUMENTS',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.DOCUMENTS_SUBTITLE',
							),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.LABEL'),
					access: [
						// Use survey write instead of read so the changes are working
						{
							key: 'survey:write',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.SURVEY_RESULTS',
							),
						},
						{
							key: 'survey:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.SURVEY'),
						},
						{
							key: 'online_form:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.FORM'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.FORM_SUBTITLE',
							),
						},
						{
							key: 'petition:publish',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.PETITION'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.PETITION_SUBTITLE',
							),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.ACTION.LABEL'),
					access: [
						{
							key: 'action:write',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.ACTION.ACCESS.ACTIONS'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.ACTION.ACCESS.ACTIONS_SUBTITLE',
							),
						},
						{
							key: 'action:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.ACTION.ACCESS.ACTIONS_DELETE',
							),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.LABEL'),
					access: [
						// Use members write instead of read so the changes are working
						{
							key: 'members:write',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.MEMBER'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.MEMBER_SUBTITLE',
							),
						},
						{
							key: 'members:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.MEMBER_EDIT',
							),
						},
						{
							key: 'members.teams:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.TEAMS_EDIT'),
						},
						{
							key: 'members.invitation:invite',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.USER_INVITE'),
						},
					],
				},
				{
					sectionLabel: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.LABEL'),
					access: [
						{
							key: 'settings:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.SETTINGS'),
						},
						{
							key: 'settings.roles:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.ROLES'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.ROLES_SUBTITLE',
							),
						},
						{
							key: 'settings.connect:delete',
							label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.CONNECT'),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.CONNECT_SUBTITLE',
							),
						},
						{
							key: 'settings.connect.apikeys:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.CONNECT_API',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.CONNECT_API_SUBTITLE',
							),
						},
						{
							key: 'settings.billing:delete',
							label: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.SETTINGS_BILLING',
							),
							sublabel: t(
								'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.SETTINGS_BILLING_SUBTITLE',
							),
						},
					],
				},
			],
		},
	])

	accessDependencies = ref({
		'communication.sender:write': {
			access: ['communication:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.REQUIRED_COMMUNICATION',
			),
		},
		'communication.sender:delete': {
			access: ['communication:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.REQUIRED_COMMUNICATION',
			),
		},
		'contacts:write': {
			access: ['contacts:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT'),
		},
		'contacts:delete': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'contacts.locked_fields:write': {
			access: ['contacts:read', 'contacts:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE'),
		},
		'contacts.duplicates:delete': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'contacts.massive:update': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'contacts.massive:delete': {
			access: ['contacts:read', 'contacts:write', 'contacts:delete', 'contacts.massive:update'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_DELETE_MASSIVE',
			),
		},
		'export:delete': {
			access: ['contacts:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT'),
		},
		'import:delete': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'contacts.list:delete': {
			access: ['contacts:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT'),
		},
		'task:delete': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'settings.billing:delete': {
			access: ['settings:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.REQUIRED_SETTINGS',
			),
		},
		'settings.roles:delete': {
			access: ['settings:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.REQUIRED_SETTINGS',
			),
		},
		'settings.connect:delete': {
			access: ['settings:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.REQUIRED_SETTINGS',
			),
		},
		'settings.connect.apikeys:delete': {
			access: ['settings:delete', 'settings.connect:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.SETTINGS.ACCESS.REQUIRED_SETTINGS_CONNECT',
			),
		},
		'contacts.private_interactions:delete': {
			access: ['contacts:read', 'contacts:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT_WRITE',
			),
		},
		'survey:delete': {
			access: ['survey:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.FORM.ACCESS.REQUIRED_SURVEY_RESULTS',
			),
		},
		'action:delete': {
			access: ['action:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.ACTION.ACCESS.REQUIRED_ACTIONS'),
		},
		'members:delete': {
			access: ['members:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.REQUIRED_MEMBER'),
		},
		'members.teams:delete': {
			access: ['members:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.REQUIRED_MEMBER'),
		},
		'members.invitation:invite': {
			access: ['members:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.MEMBER.ACCESS.REQUIRED_MEMBER'),
		},
		'transaction:delete': {
			access: ['transaction:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_DELETE_TRANSACTION',
			),
		},
		'transaction_all:read': {
			access: ['membership:min'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_MEMBERSHIP_STATUS',
			),
		},
		'transaction_all:write': {
			access: ['transaction_all:read', 'transaction:write'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_EDIT_TRANSACTION',
			),
		},
		'transaction_all:delete': {
			access: ['transaction_all:read', 'transaction_all:write', 'transaction:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_DELETE_ALL_TRANSACTION',
			),
		},
		'reopen_batch:self': {
			access: ['transaction:write', 'transaction:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_REOPEN_BATCH',
			),
		},
		'reopen_batch:all': {
			access: [
				'transaction_all:read',
				'transaction_all:write',
				'transaction_all:delete',
				'reopen_batch:self',
			],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.DONATION_MEMBERSHIP.ACCESS.REQUIRED_REOPEN_ALL_BATCH',
			),
		},
		'communication.email:delete': {
			access: ['communication:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.REQUIRED_COMMUNICATION',
			),
		},
		'communication.sms:delete': {
			access: ['communication:delete'],
			label: t(
				'SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.COMMUNCATION.ACCESS.REQUIRED_COMMUNICATION',
			),
		},
		'mandate:read': {
			access: ['contacts:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT'),
		},
		'mandate:write': {
			access: ['contacts:read', 'mandate:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_MANDATE_WRITE'),
		},
		'sanction:read': {
			access: ['contacts:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_CONTACT'),
		},
		'sanction:write': {
			access: ['contacts:read', 'sanction:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CONTACT.ACCESS.REQUIRED_SANCTION_WRITE'),
		},
		'carto.turf:write': {
			access: ['carto.turf:read'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.REQUIRED_TURF'),
		},
		'carto.turf:delete': {
			access: ['carto.turf:read', 'carto.turf:write'],
			label: t('SETTINGS.ROLE_MANAGEMENT.ACCESS_LIST.WEB.SECTION.CARTO.ACCESS.REQUIRED_TURF_DELETE'),
		},
	})
}

const methodSaveOrEditButton = () => {
	// When the button is in save mode
	if (isRoleEditing.value) {
		// Iterate through the original list to manage role deleted
		listRoles.value.forEach((role) => {
			let newRole = find(clonedRoles, ['id', role.id])
			// If the role is not present on the new list it have been removed
			if (!newRole) {
				rolesToDelete.push({ role: role, dialogShown: true })
			}
			// If the role is modified, save the changes in the db
			else if (!isEqual(role, newRole)) {
				methodUpdateRole(newRole)
			}
		})
		// Check for new roles and add them
		differenceBy(clonedRoles, listRoles.value, 'id').forEach((newRole) => methodAddRole(newRole))
	}
	isRoleEditing.value = !isRoleEditing.value
}

const methodCancelEdit = () => {
	isRoleEditing.value = false
	// Put backend array role to remove changes
	clonedRoles = reactive(cloneDeep(listRoles.value))
}

const methodAddTempRole = (newRole) => {
	clonedRoles.push(newRole)
}
</script>

<style lang="scss" scoped>
.roleManagementButton {
	height: fit-content;
}
</style>
