import { $request } from './requester'

/**
 * @model Emailer
 * @desc handle associated emailer API (Brevo/EE/other..)
 */
export default {
	/**
	 * GET `/emailer/account`
	 * @description retrieve emailer account data
	 */
	async getEmailerAccountData() {
		return $request('GET', '/emailer/account')
			.catch((error) => {
				return error
			})
			.then((res) => {
				return res.body.data.emailer_account
			})
	},

	/**
	 * GET `/billing/subscription/bulk_email`
	 * @description retrieve emailer subscription
	 */
	async getBulkEmailsSubscription() {
		return $request('GET', '/billing/subscription/bulk_email')
			.catch((error) => {
				return error
			})
			.then((res) => {
				return res.body.data
			})
	},

	/**
	 * POST `/emailer/account`
	 * @description update emailer account
	 */
	async updateEmailerAccount(emailer_account) {
		return $request('PATCH', '/emailer/account', { emailer_account }).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/**
	 * GET `/emailer/templates`
	 * @description retrieve all emailer templates
	 */
	async getEmailerAccountTemplates() {
		return $request('GET', '/emailer/templates').then((res) => {
			return res.body.data.emailer_templates
		})
	},

	/**
	 * GET `/emailer/template?template_id={id}`
	 * @description retrieve emailer template by id
	 */
	async getEmailerAccountTemplateById(templateId) {
		return $request('GET', `/emailer/template?${templateId}`).then((res) => {
			return res.body.data.emailer_template
		})
	},

	/**
	 * POST `/emailer/template
	 * @description create new emailer template
	 */
	async createEmailerAccountTemplate(emailer_template) {
		return $request('POST', '/emailer/template', { emailer_template }).then((res) => {
			return res.body.data?.emailer_templates
		})
	},

	/**
	 * PATCH `/emailer/template
	 * @description edit a template
	 */
	async editEmailerAccountTemplate(emailer_template) {
		return $request('PATCH', '/emailer/template', { emailer_template }).then((res) => {
			return res.body.data?.emailer_templates
		})
	},

	/**
	 * DELETE `/emailer/template/:template_id
	 * @description delete a template
	 */
	async deleteEmailerAccountTemplate(template_id) {
		return $request('DELETE', `/emailer/template/${template_id}`).then((res) => {
			return res.body.data?.emailer_templates
		})
	},

	/**
	 * POST `/billing/subscription/bulk_email/upsell`
	 * @description to upsell
	 */
	async upsell(newVolume) {
		/// Account struc updated with newCrédits volume
		return $request('POST', '/billing/subscription/bulk_email/upsell', newVolume).then((res) => {
			return res.body.data
		})
	},

	/**
	 * POST `/billing/subscription/bulk_email/downsell`
	 * @description to downsell
	 */
	async downsell(newVolume) {
		/// Account struc updated with newCredits volume
		return $request('POST', '/billing/subscription/bulk_email/downsell', newVolume).then((res) => {
			return res.body.data
		})
	},

	/**
	 * POST `/emailer/sender`
	 * @description to create sender
	 */
	async createSender(emailer_sender) {
		/// sender struct updated with new name/email and with account_id
		return $request('POST', '/emailer/sender', { emailer_sender }).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/**
	 * PATCH `/emailer/sender`
	 * @description to update sender(s)
	 */
	async updateSender(emailer_sender) {
		/// sender struct updated with new name/email and with account_id
		return $request('PATCH', '/emailer/sender', { emailer_sender }).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/**
	 * PATCH `/emailer/senders`
	 * @description to update senders
	 */
	async updateSenders(emailer_senders) {
		/// sender struct updated with new name/email and with account_id
		return $request('PATCH', '/emailer/senders', { emailer_senders }).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/**
	 * DELETE `/emailer/sender`
	 * @description to delete a sender(s)
	 */
	async deleteSender(senderId) {
		/// sender struct updated with new name/email and with account_id
		return $request('DELETE', `/emailer/sender/${senderId}`).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/** ^
	 * GET `/billing/prices/bulk_email`
	 * @description to get emailer volume and prices
	 */
	async getPrices() {
		return $request('GET', '/billing/prices/bulk_email').then((res) => {
			return res.body.data
		})
	},

	async beeAuth(payload) {
		return $request('POST/WITHOUT_DATA_KEY', payload.url, payload.ids, {
			'Content-Type': 'application/json',
		}).then((res) => {
			return res.body.data
		})
	},

	async getEmailDomainVerification(id) {
		return $request('GET', `/emailer/domains/${id}/verify`).then((res) => {
			return res.body.data.emailer_domain
		})
	},

	async registerNewDomain(payload) {
		/// sender struct updated with new name/email and with account_id
		return $request('POST', '/emailer/domains', payload).then((res) => {
			return res.body.data.emailer_account
		})
	},

	/**
	 * GET `/emailer/senders`
	 * @description retrieve senders for the current user
	 */
	async getSenders() {
		return $request('GET', '/emailer/senders')
			.then((res) => {
				return res.body.data?.senders
			})
			.catch((error) => {
				return error
			})
	},

	/**
	 * GET `/emailer/allsenders`
	 * @description retrieve all senders
	 */
	async getAllSenders() {
		return $request('GET', '/emailer/allsenders')
			.then((res) => {
				return res.body.data.senders
			})
			.catch((error) => {
				return error
			})
	},

	async getEmailTmpThumbnail(payload) {
		return $request('POST', '/emailer/template/tmp_thumbnail', payload)
			.then((res) => {
				return res.body.data
			})
			.catch((error) => {
				return error
			})
	},
}
