<template>
	<label class="q-radio" :class="{ clickable: !disabled }">
		<input
			:id="name"
			v-model="computedState"
			type="radio"
			:value="value"
			:name="name"
			:class="className"
			:checked="checked || modelValue === value"
			:disabled="disabled"
		/>
		<span class="ml-1" :class="{ bold: modelValue === value, 'alt-font': modelValue !== value }">
			<slot></slot>
		</span>
	</label>
</template>

<script>
export default {
	props: {
		modelValue: { type: [String, Number, Boolean, Object], default: '' },
		value: { type: [String, Number, Boolean], default: '' },
		name: { type: [String, Number], default: '' },
		disabled: { type: Boolean, default: false },
		checked: { type: Boolean, default: false },
		className: { type: [String, Object], default: '' },
	},

	emits: ['update:modelValue'],

	computed: {
		computedState: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},
}
</script>
