import store from './store/stateStore'
import { defineLocaleSpecific } from './middlewares/i18nizer'
import { createRouter, createWebHistory } from 'vue-router'
// Trees of routes:
import ActionRoute from './routes/action-route'
import AnalyseModule from './routes/analyse-route'
// Routes Modules:
// Arrays of routes:
import AppRoute from './routes/app-route'
import SharingRoute from './routes/sharing-route'
import CommunicationsRoute from './routes/communications-route'
import FormRoute from './routes/survey-route'
import { PetitionsRoutes, PetitionsRoutesPromoted } from './routes/petition-route.ts'
import { OnlineFormsRoutes, OnlineFormsPromoted } from './routes/online_forms_route.ts'
import ContactRoute from './routes/contact-route'
import ManagementRoute from './routes/management-route'
import DataRoute from './routes/data-route.ts'
import ImportCreationRoute from './routes/import-creation-route.ts'
import SettingsRoute from './routes/settings-route'
import ProfileRoute from './routes/profile-route'
import UserRoute from './routes/user-route'
import CancelSubscriptionRoutes from './routes/cancel-subscription-routes'
import EditContactFormRoute from './routes/edit_contact_form_route'
import SuspendedAccessRoute from './routes/suspended_access_route.ts'
import WelcomeRoute from './routes/welcome_route'
import {
	CreateLotTunnel,
	CreateTransactionTunnel,
	AccessToTransactionById,
	donationAndMembershipListingsContainer,
} from './routes/donation_and_membership.ts'
import MasterSpaceRoute from './routes/master_space_route.ts'
import ProfilesRoute from './routes/profiles_route'

import DuplicatesRouter from './routes/duplicates-route'

// PACKER
import PackerRouter from './routes/packer-route'
import PromotedRouter from './routes/promoted-route'

// Components
const CommandCenter = () => import('components/command-center-module/CommandCenter.vue')

const Dashboard = () => import('./components/structure-module/Dashboard.vue')

const routes = [
	...AppRoute,

	/* Actual webapp */
	{
		path: '/',
		name: 'dashboard',
		component: Dashboard,
		/**
		 * This beforeEnter is here to download all
		 * the basic content needed by the WebApp
		 * to provide all data.
		 * We cannot force an await on a monted or created,
		 * so we move the global data download here.
		 */
		beforeEnter: async (to, from, next) => {
			const { dispatch } = store
			await dispatch('GLOBAL_DATA')
			await dispatch('@packs/actionFetchLocaleSpecific')
			const localeSpecificFromBackend = store.getters['@packs/getLocaleSpecific']
			localeSpecificFromBackend
				? defineLocaleSpecific(localeSpecificFromBackend)
				: defineLocaleSpecific(null)

			next()
		},
		children: [
			// STRATEGY
			AnalyseModule,
			FormRoute,
			// COMMUNITY
			ContactRoute,

			DuplicatesRouter,
			UserRoute,
			CommunicationsRoute,
			// PETITIONS
			PetitionsRoutes,
			PetitionsRoutesPromoted,
			// Online forms
			OnlineFormsRoutes,
			OnlineFormsPromoted,
			// BRIEFS
			SharingRoute,
			// MOBILISATION
			ActionRoute,
			ManagementRoute,
			// SETTINGS
			ImportCreationRoute,
			DataRoute,
			SettingsRoute,
			SuspendedAccessRoute,
			ProfileRoute,
			// DONATION AND MEMBERSHIP
			CreateLotTunnel,
			CreateTransactionTunnel,
			AccessToTransactionById,
			donationAndMembershipListingsContainer,
			// PROFILES
			ProfilesRoute,
			{
				meta: { title: 'MENU.MAPS' },
				path: 'command-center',
				name: 'command-center',
				component: CommandCenter,
			},
			PackerRouter,
			PromotedRouter,
			CancelSubscriptionRoutes,
			EditContactFormRoute,

			WelcomeRoute,

			MasterSpaceRoute,
		],
	},
]

export default createRouter({
	history: createWebHistory(),
	routes: routes,
})
