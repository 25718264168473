import store from '../store/stateStore'
import { i18n } from '../middlewares/i18nizer'
import { getTimezoneAbr } from '@/utils/dateTimeZone'

import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import formatDuration from 'date-fns/formatDuration'
import intervalToDuration from 'date-fns/intervalToDuration'
import isDate from 'date-fns/isDate'
import isPast from 'date-fns/isPast'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import add from 'date-fns/add'
import isSameDay from 'date-fns/isSameDay'
import sub from 'date-fns/sub'
import isToday from 'date-fns/isToday'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getDay from 'date-fns/getDay'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import set from 'date-fns/set'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

/**
 * Abstraction pattern for date-fns functions start
 */
export const _isSameDay = (dateOne, dateTwo) => {
	return isSameDay(new Date(dateOne), new Date(dateTwo))
}

export const _sub = (date, objToSub) => {
	return sub(new Date(date), { ...objToSub })
}

export const _isToday = (date) => {
	return isToday(new Date(date))
}

export const _getHours = (date) => {
	return getHours(new Date(date))
}
export const _getDay = (date) => {
	return getDay(new Date(date))
}

export const _getMinutes = (date) => {
	return getMinutes(new Date(date))
}

export const _addHours = (date, hours) => {
	return addHours(date, hours)
}

export const _addMinutes = (date, minutes) => {
	return addMinutes(new Date(date), minutes)
}

export const _parseISO = (date) => {
	return parseISO(date.toString())
}

export const _isDate = (date) => {
	return isDate(date)
}

export const _isPast = (date) => {
	return isPast(new Date(date))
}

export const _isAfter = (date, dateToCompare) => {
	return isAfter(new Date(date), new Date(dateToCompare))
}

export const _format = (date, formatStyle, locale) => {
	return format(new Date(date), formatStyle, locale)
}

export const _intervalToDuration = (interval) => {
	return intervalToDuration(interval)
}

export const _formatDuration = (duration, options) => {
	return formatDuration(duration, options)
}

export const _formatRelative = (date, baseDate, options) => {
	return formatRelative(new Date(date), new Date(baseDate), options)
}

export const _add = (date, objToAdd) => {
	return add(new Date(date), objToAdd)
}

export const _set = (date, objToSet) => {
	return set(new Date(date), objToSet)
}

export const _setHours = (date, hours) => {
	return setHours(new Date(date), hours)
}

export const _setMinutes = (date, minutes) => {
	return setMinutes(new Date(date), minutes)
}

/**
 * Abstraction pattern for date-fns functions end
 */

const getLocale = (locale) => {
	try {
		// fr-FR doesn't exist as a date-fns locale
		let formattedLocale = locale === 'fr-FR' ? 'fr' : locale
		return require(`date-fns/locale/${formattedLocale}/index.js`)
	}
	catch (err) {
		return require(`date-fns/locale/en-GB/index.js`)
	}
}

export const getDisplayDateFromLocalStorage = () => {
	if (localStorage.getItem('dateFormat')) {
		return localStorage.getItem('dateFormat')
	}

	let defaultFormatFromBrowserLang = ((navigator.language && navigator.language === 'en-US'))
		? 'MM/dd/yyyy'
		: 'dd/MM/yyyy'

	localStorage.setItem('dateFormat', defaultFormatFromBrowserLang)

	return defaultFormatFromBrowserLang
}

export const getDayFromDate = (date) => {
	const parsedDate = new Date(date)
	if (isNaN(parsedDate)) return ''

	const localeObj = getLocale(i18n.global.locale === 'fr' ? 'fr-FR' : 'en-GB')
	return format(parsedDate, 'eeee', { locale: localeObj })
}

export const getDisplayHoursFromLocalStorage = () => {
	if (localStorage.getItem('hoursFormat')) {
		return localStorage.getItem('hoursFormat')
	}
	else {
		let defaultFormatFromBrowserLang = ((navigator.language && navigator.language === 'en-US' || navigator.language === 'en'))
			? 'hh:mm A'
			: 'HH:mm'

		localStorage.setItem('hoursFormat', defaultFormatFromBrowserLang)

		return defaultFormatFromBrowserLang
	}
}

const getLocalFromLocalStorageSettings = () => {
	return getDisplayDateFromLocalStorage() === 'MM/dd/yyyy' ? 'en-US' : i18n.global.locale === 'fr' ? 'fr-FR' : 'en-GB'
}

const getLangFromNavigator = () => {
	if (i18n.global.locale === 'fr') return i18n.global.locale

	return navigator.language || 'en'
}

const getDateObject = (date = new Date()) => (_isDate(date) ? date : _parseISO(date))

const getGroupDateFormat = () => {
	if (!store?.state?.info_campagne?.geocodage_country) return 'P'

	return getLangFromNavigator() === 'fr-FR' || getLangFromNavigator() === 'fr'
		? 'dd/MM/yyyy'
		: 'MM/dd/yyyy'
}

/**
 * Common helpers
 */

export const isDatePast = (date = new Date()) => {
	const dateToUse = getDateObject(date)

	return _isPast(dateToUse)
}

/**
 * Format helpers
 */

/**
 * @deprecated please use formatCustomDate
 */
export const formatDate = (date, formatStyle = 'dd/MM/yyyy', locale = 'en') => {
	const dateToUse = getDateObject(date)

	return _format(dateToUse, formatStyle, {
		locale: getLocale(getLocalFromLocalStorageSettings()),
	})
}

/**
 * Returns the date in the given format
 * @param {(Date|string)} date
 * @param {string} formatStyle - all formats available here: https://date-fns.org/v2.29.2/docs/format
 * @param {string} locale - locale from navigator by default, give i18n.global.locale if needed to not translate words
 * @return {string}
 */
export const formatCustomDate = (
	date = new Date(),
	formatStyle = getDisplayDateFromLocalStorage(),
	// formatStyle = getGroupDateFormat(),

) => {
	const dateToUse = getDateObject(date)

	return _format(dateToUse, formatStyle, { locale: getLocale(getLocalFromLocalStorageSettings()) })
}

/**
 * Returns the interval between to dates in a nice format
 * @param {(Date|string)} startDate
 * @param {(Date|string)} endDate
 * @param {string[]} formatStyle - all formats available here: https://date-fns.org/v2.29.2/docs/format
 * @param {string} locale - locale from navigator by default, give i18n.global.locale if needed to not translate words
 * @return {string}
 */
export const formatCustomDuration = (
	startDate = new Date(),
	endDate = new Date(),
	formatStyle = ['years', 'months', 'weeks', 'days'],
	locale = getLangFromNavigator(),
) => {
	const startDateToUse = getDateObject(startDate)
	const endDateToUse = getDateObject(endDate)

	return _formatDuration(_intervalToDuration({ start: startDateToUse, end: endDateToUse }), {
		format: formatStyle,
		locale: getLocale(locale),
	})
}

/**
 * Returns the day, month and year of a date
 * @param {(Date|string)} date
 * @returns {string} 07/17/2023 or 17/07/2023
 */
export const formatLongDate = (date = new Date()) => {
	const dateToUse = getDateObject(date)

	return _format(dateToUse, 'P', { locale: getLocale(getLangFromNavigator()) })
}

/**
 * Returns the date in words relative to the given base date
 * @param {(Date|string)} date
 * @param {(Date|undefined)} baseDate
 * @param {boolean} isTimezoneDiplayed
 * @returns {string}
 * Previous 6 days - last Sunday at 4:30 AM ||
 * Last day - yesterday at 4:30 AM ||
 * Same day - today at 4:30 AM or today at 4:30 or aujourd'hui à 4:30 ||
 * Next day - tomorrow at 4:30 AM ||
 * Next 6 days - Sunday at 4:30 AM ||
 * Other - 07/17/2023 or 17/07/2023
 */
export const formatRelativeDate = (date = new Date(), baseDate = new Date(), isTimezoneDiplayed = true) => {
	const dateToUse = getDateObject(date)
	const baseDateToUse = getDateObject(baseDate)

	const dateSentence = formatDateSentence(dateToUse, baseDateToUse, isTimezoneDiplayed)

	if (dateSentence) return dateSentence

	return `${formatRelative(dateToUse, baseDateToUse, { locale: getLocale(getLocalFromLocalStorageSettings()) })} ${isTimezoneDiplayed ? getTimezoneAbr(baseDate) : ''}`
}

const formatDateSentence = (date, baseDate, isTimezoneDiplayed = true) => {
	let locale = getLocale(getLocalFromLocalStorageSettings())

	const dateInI18nLocale = formatRelative(date, baseDate, { locale })
	const dateInArray = dateInI18nLocale.split(' ')
	const isSentence = dateInArray.length > 1

	if (isSentence) {
		dateInArray.pop()
		if (getDisplayDateFromLocalStorage() === 'MM/dd/yyyy') {
			dateInArray.pop()
		}

		const dateSentenceWithoutTime = dateInArray.join(' ')
		return `${dateSentenceWithoutTime} ${formatTime(date)} ${isTimezoneDiplayed ? getTimezoneAbr(baseDate) : ''}`
	}

	return null
}

/**
 * Returns the day and month of a date
 * @param {(Date|string)} date
 * @returns {string} the day and month with i18n locale translation:
 * Jul 17 or 17 juil.
 */
export const formatDayMonthDate = (date = new Date()) => {
	const dateToUse = getDateObject(date)

	let formatStyle = 'dd MMM'

	if (getLocalFromLocalStorageSettings() === 'en-US') formatStyle = 'MMM dd'

	return _format(dateToUse, formatStyle, { locale: getLocale(getLocalFromLocalStorageSettings()) })
}

/**
 * Returns the hours of a date
 * @param {(Date|string)} date
 * @returns {string} 1:00 PM or 13:00
 */
export const formatTime = (date = new Date(), formatStyle = 'H:mm') => {
	const dateToUse = getDateObject(date)

	if (getDisplayHoursFromLocalStorage()) {
		formatStyle = getDisplayHoursFromLocalStorage()
		if (formatStyle === 'hh:mm A') formatStyle = 'hh:mm a'
	}

	return _format(dateToUse, formatStyle, { locale: getLocale(getLocalFromLocalStorageSettings()) })
}

export const is12HoursFormat = () => {
	return getDisplayHoursFromLocalStorage() === 'hh:mm A'
}

/**
 * Allow to add year(s), month(s), week(s),
 * day(s), hour(s),
 * minute(s) or second(s) to a date and return it
 * @param {(Date|string)} date
 * @param {(Object)} timeToAdd
 * @returns {string} date with time added
 */
export const addTimeToDate = (date = new Date(), timeToAdd = {}) => {
	let dateToUse = getDateObject(date)
	const dateWithTimeAdded = _add(dateToUse, timeToAdd)

	return formatLongDate(dateWithTimeAdded)
}

/**
 * Check if an object is a valid Date
 * @param {Object} date object to check, could be a string or a Date for exemple
 * @returns {boolean} true if it's a date false if not
 */
export const isValidDate = (date = null) => {
	return !isNaN(new Date(date))
}

export const getActionDetailDate = (date = new Date()) => {
	const dateToUse = getDateObject(date)
	const formatStyle = 'EEE\'.\' dd MMM'
	const locale = { locale: getLocale(getLocalFromLocalStorageSettings()) }
	const formatedDate = _format(dateToUse, formatStyle, locale)

	if (getLocale(getLocalFromLocalStorageSettings())?.code === 'fr') {
		return formatedDate.replace('.', '')
	}
	return formatedDate
}

/**
 * Retourne une durée relative comme "il y a 5 heures" ou "il y a 15 jours"
 * @param {(Date|string)} date
 * @param {boolean} addSuffix - Ajoute "il y a" ou "dans" selon le contexte
 * @param {string} locale - Locale utilisée pour formater le texte (par défaut, depuis i18n)
 * @returns {string}
 */
export const formatTimeAgo = (date, addSuffix = true) => {
	const localeObj = getLocale(i18n.global.locale === 'fr' ? 'fr-FR' : 'en-GB')
	const dateToUse = getDateObject(date)
	return formatDistanceToNow(dateToUse, { addSuffix, locale: localeObj })
}
