<template>
	<div id="confirm-delete">
		<span v-if="!confirmed" @click="toggleConfirm">
			<slot>{{ t.delete }}</slot>
		</span>
		<span v-else class="action warning" @click="$emit('confirm')">
			<div class="q-is-info">
				<div class="fa fa-exclamation-circle">&nbsp;</div>
				{{ t.confirm }}
			</div>
		</span>
	</div>
</template>

<script>
export default {
	emits: ['confirm'],
	data() {
		return {
			confirmed: false,
		}
	},
	computed: {
		t() {
			return {
				delete: this.$t('_COMMON.DELETE'),
				confirm: this.$t('_COMMON.CONFIRM'),
			}
		},
	},

	methods: {
		toggleConfirm() {
			this.confirmed = true
			setTimeout(() => {
				this.confirmed = false
			}, 2500)
		},
	},
}
</script>
