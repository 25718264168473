import { cloneDeep, find, remove, orderBy } from 'lodash'
import { SET_PROFILES_LIST, RESET_PROFILES_STORE, SET_AUTO_CROSS_ANALYSIS_FOR_MAP, SET_PROFILE_KPI_AVAILABLE_CONTACTS } from './_mutation-types'
import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import QueryBuilder from '@quorumsco/quorum-helpers/lib/filter/QueryBuilder'
import profiles_store from '@/models/profiles_store'
import photosRequester from '@/requesters/photos'
import { ElMessage } from 'element-plus'
import { i18n } from '@/middlewares/i18nizer'

/**
 * @store @profiles
 * @namespaced
 * @description Profiles related info and store module
 */

const getDefaultState = () => {
	return {

		profileKpiAvailableContacts: {},
		profilesList: [],
		autoCrossAnalysisForMap: null,
		profileListState: 'init',
	}
}
const state = getDefaultState

const mutations = {
	[RESET_PROFILES_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_PROFILES_LIST]: (state, payload) => {
		payload = orderBy(payload, ['id'])
		state.profilesList = payload
		state.profileListState = 'ended'
	},

	[SET_AUTO_CROSS_ANALYSIS_FOR_MAP]: (state, payload) => {
		state.autoCrossAnalysisForMap = payload
	},
	[SET_PROFILE_KPI_AVAILABLE_CONTACTS]: (state, payload) => {
		state.profileKpiAvailableContacts = payload
	},
}

const actions = {
	actionResetProfiles({ commit }) {
		commit(RESET_PROFILES_STORE)
	},

	actionRetriveProfilesList({ commit }) {
		state.profileListState = 'loading'
		profiles_store.getListProfiles().then((res) => {
			commit(SET_PROFILES_LIST, res.insights)
		})
	},

	actionGetProfileKPIAvaiblabeContacts({ commit }, payload) {
		let statusListIds = payload?.statusList[0].refvalues.map(
			(status) => status.id,
		) || []
		let omitSavedFilters = payload?.omitSavedFilters || false

		profiles_store.getProfileKPIAvaiblabeContacts(statusListIds, omitSavedFilters).then((res) => {
			commit(SET_PROFILE_KPI_AVAILABLE_CONTACTS, res)
		})
	},

	async actionGetProfileById({}, profileId) {
		return profiles_store.getProfile(profileId).then((res) => {
			return res.insight
		}).catch((error) => {
			return null
			// throw error // Propager l'erreur si nécessaire
		})
	},

	async actionCreateProfile({ commit, state }, profile) {
		try {
			const res = await profiles_store.createProfile(profile)
			let clonedProfilesList = cloneDeep(state.profilesList)
			clonedProfilesList.push(res.insight)
			commit(SET_PROFILES_LIST, clonedProfilesList)
			return res.insight
		}
		catch (error) {
			let babelKey = i18n.global.t('ERRORS.INSIGHT.CREATION.ERROR')
			ElMessage({
				message: babelKey,
				type: 'error',
				duration: 4000,
			})
		}
	},

	async actionCreateProfileCover({ commit, state }, { cover, profileId }) {
		if (!cover) return

		const res = await photosRequester.uploadImg(cover)

		if (await res && res.file_name) {
			let clonedProfilesList = cloneDeep(state.profilesList)
			let targetedProfile = find(clonedProfilesList, ['id', profileId])

			try {
				await profiles_store.updateProfile({ cover_url: res.file_name }, profileId)
				targetedProfile.cover_url = res.file_name
				commit(SET_PROFILES_LIST, clonedProfilesList)
			}
			catch (error) {
				console.log(error)
			}
		}
	},

	actionAddOrRemovePin({ commit, state }, profileId) {
		let clonedProfilesList = cloneDeep(state.profilesList)
		let targetedProfile = find(clonedProfilesList, ['id', profileId])
		targetedProfile.is_pinned = !targetedProfile.is_pinned
		profiles_store.updateProfile({ name: targetedProfile.name, is_pinned: targetedProfile.is_pinned }, profileId).then(() => {
			commit(SET_PROFILES_LIST, clonedProfilesList)
		})
	},

	actionRenameProfile({ commit, state }, { profileId, newName }) {
		let clonedProfilesList = cloneDeep(state.profilesList)
		let targetedProfile = find(clonedProfilesList, ['id', profileId])
		profiles_store.updateProfile({ name: newName, is_pinned: targetedProfile.is_pinned }, profileId).then(() => {
			targetedProfile.name = newName
			commit(SET_PROFILES_LIST, clonedProfilesList)
		})
	},

	async actionRefreshProfile({ dispatch }, profileId) {
		try {
			const res = await profiles_store.reloadProfile(profileId)

			if (!res || !res.insight) {
				throw new Error('error')
			}

			// Retrieve the whole list to get all the new informations and the deleted cover
			dispatch('actionRetriveProfilesList')

			return res.insight
		}
		catch (error) {
			let babelKey = ''
			switch (error.message) {
				case 'profile_not_found':
					babelKey = i18n.global.t('ERRORS["404"]')
					break
				case 'saved_filter_not_found':
					babelKey = i18n.global.t('ERRORS.INSIGHT.UPDATE.SAVED_FILTER_NOT_FOUND')
					break
				case 'partition_too_small':
					babelKey = i18n.global.t('ERRORS.INSIGHT.UPDATE.PARTITION_TOO_SMALL')
					break
				case 'partition_is_empty':
					babelKey = i18n.global.t('ERRORS.INSIGHT.UPDATE.PARTITION_IS_EMPTY')
					break
				default:
					babelKey = i18n.global.t('ERRORS.INSIGHT.DEFAULT')
					break
			}
			ElMessage({
				message: babelKey,
				type: 'error',
				duration: 4000,
			})
		}
	},

	async actionDeleteProfile({ commit, state }, profileId) {
		return profiles_store.deleteProfile(profileId).then(() => {
			let clonedProfilesList = cloneDeep(state.profilesList)
			remove(clonedProfilesList, (profile) => profile?.id === profileId)
			commit(SET_PROFILES_LIST, clonedProfilesList)
		})
	},

	async actionRetrieveListCount({ dispatch }, list) {
		let listCount = 0
		if (list?.payload) {
			const queryParsed = QueryNode.deserialize(list?.payload)
			if (queryParsed.isNode()) {
				const advancedFilter = new QueryBuilder(queryParsed, 50, 0, 'lastname', 'asc')
				listCount = await dispatch('@search/actionGetKpiAdvancedSearch', advancedFilter.build(), { root: true })
			}
		}
		return listCount
	},

	actionSetAutoCrossAnalysisForMap({ commit }, autoCrossAnalysisForMap) {
		commit('SET_AUTO_CROSS_ANALYSIS_FOR_MAP', autoCrossAnalysisForMap)
	},

	actionResetAutoCrossAnalysisForMap({ commit }) {
		commit('SET_AUTO_CROSS_ANALYSIS_FOR_MAP', null)
	},
}

const getters = {
	getProfilesList: (state) => state.profilesList,
	getAutoCrossAnalysisForMap: (state) => state.autoCrossAnalysisForMap,
	getProfileKpiAvailableContacts: (state) => state.profileKpiAvailableContacts,
	getProfileListState: (state) => state.profileListState,
}

export const profilesModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
