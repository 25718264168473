class TreeDisplayerIdsHandler {
	static get initialId() {
		return 'root'
	}

	/**
	 * @type Set.<String>
	 */
	#selectedIds

	/**
	 * @type Map.<String, Set.<String>>
	 */
	#selectedIdsByParentId

	/**
	 * @type Map<String, String>
	 */
	#parentIdById

	/**
	 * @type Map<String, {variable_name: String, dataset_name: String}>
	 */
	#variableInfoById

	/**
	 * Dirty global state to store the info of the last variable that was forced at a given stage
	 *
	 * @type Map<String, {variable_name: String, dataset_name: String}>
	 */
	#lastForcedInfoById

	constructor() {
		this.#selectedIds = new Set()
		this.#selectedIdsByParentId = new Map()
		this.#parentIdById = new Map()
		this.#variableInfoById = new Map()
		this.#lastForcedInfoById = new Map()
	}

	isIdSelected(id) {
		return this.#selectedIds.has(id)
	}

	/**
	 * @param {String} id
	 * @param {String} parentId
	 */
	addSelectedIdWithParentId(id, parentId) {
		this.#selectedIds.add(id)
		this.#parentIdById.set(id, parentId)
		if (!this.#selectedIdsByParentId.has(parentId)) {
			this.#selectedIdsByParentId.set(parentId, new Set())
		}
		this.#selectedIdsByParentId.get(parentId).add(id)
	}

	/**
	 * @param {String} id
	 */
	removeSelectedId(id) {
		this.#selectedIds.delete(id)
		const parentId = this.#parentIdById.get(id)
		this.#parentIdById.delete(id)
		this.#selectedIdsByParentId.get(parentId).delete(id)
	}

	/**
	 * @param {String} id
	 * @param {{variable_name: String, dataset_name: String}} variableInfoForId
	 */
	setVariableInfoForId(id, variableInfoForId) {
		this.#variableInfoById.set(id, variableInfoForId)
	}

	/**
	 * @param {String} id
	 * @returns {boolean}
	 */
	isIdSelectedWithSelectedParents(id) {
		if (id === TreeDisplayerIdsHandler.initialId) {
			return true
		}
		if (this.#selectedIds.has(id)) {
			const parentId = this.#parentIdById.get(id)
			return this.isIdSelectedWithSelectedParents(parentId)
		} else {
			return false
		}
	}

	/**
	 * @param {String} id
	 * @returns {{variable_name: String, dataset_name: String}||{}}
	 */
	getLastForcedInfoForId(id) {
		return this.#lastForcedInfoById.get(id) || {}
	}

	/**
	 * @param {String} id
	 * @param {{variable_name: String, dataset_name: String}} info
	 */
	setLastForcedInfoForId(id, info) {
		this.#lastForcedInfoById.set(id, info)
	}

	/**
	 * @returns {{variable_name: String, dataset_name: String}[]}
	 */
	get selectedVariablesInfo() {
		return [...this.#variableInfoById.entries()]
			.filter(([id]) => this.#selectedIds.has(id))
			.filter(([id]) => this.isIdSelectedWithSelectedParents(id))
			.map(([, variableInfo]) => variableInfo)
	}

	/**
	 * @returns {Map<String, Set.<String>>}
	 */
	get selectedVariablesNamesByDatasetName() {
		const out = new Map()
		this.selectedVariablesInfo.forEach(({ dataset_name, variable_name }) => {
			if (!out.has(dataset_name)) {
				out.set(dataset_name, new Set())
			}
			out.get(dataset_name).add(variable_name)
		})
		return out
	}
}

export default TreeDisplayerIdsHandler
