import { $request } from './requester'

export default {
	/**
	 * GET `/categories`
	 */
	async getCategories() {
		return $request('GET', '/categories').then((res) => {
			return res.body.data.categories
		})
	},

	/**
	 * GET `/online-forms`
	 */
	async getOnlineForms() {
		return $request('GET', '/online-forms').then((res) => res.body.data.online_forms)
	},

	/**
	 * `GET` `/online-forms/:id`
	 * @param {String} id petition_id of the petition to retrieve
	 */
	async getOnlineFormById(id) {
		return $request('GET', `/online-forms/${id}`).then((res) => res.body.data.online_form)
	},

	/**
	 * POST `/online-forms`
	 * @param {Object} data
	 */
	async postOnlineForm(data) {
		// return $request('POSTTEST', 'https://my-cool-projecttt.herokuapp.com/onlineForms', data).then(
		// 	(res) => res.body
		// )
		return $request('POST', '/online-forms', data).then((res) => res.body.data.online_form)
	},

	/**
	 * `PATCH` `/online-forms/:id`
	 */
	async updateOnlineForm({ id, data }) {
		return $request('PATCH', `/online-forms/${id}`, data).then((res) => {
			return res.body.data.online_form
		})
	},

	/**
	 * PATCH `/online-forms/:id/publish`
	 * @param {String} id petition_id of the petition to publish
	 */
	async publishOnlineForm(id) {
		return $request('PATCH', `/online-forms/${id}/publish`)
	},

	/**
	 * PATCH `//online-forms/:id/unpublish`
	 * @param {String} id petition_id of the petition to unpublish
	 */
	async unpublishOnlineForm(id) {
		return $request('PATCH', `/online-forms/${id}/unpublish`)
	},

	/**
	 * `PATCH` `/online-forms/:id/delete`
	 * @param {Number} id petition_id to delete
	 */
	async deleteOnlineForm(id) {
		return $request('PATCH', `/online-forms/${id}/delete`)
	},

	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},
}
