<template>
	<div>
		<div
			class="mb-10"
			v-if="spaceInvitation"
		>
			<h1 class="gtDisplayBold text-center text-4xl">
				{{ $t('APP.LOGIN.FINALIZE_ACCOUNT_CREATION') }}
			</h1>
			<p class="text-center text-grey-800 mt-6">
				{{ $t('APP.LOGIN.INVITED_TO_JOIN') }}<br>
				<span class="gtDisplayBold">{{ spaceInvitation }}</span>
			</p>
		</div>
		<h1
			v-else
			class="gtDisplayBold text-center text-4xl mb-10"
		>
			{{ $t(`APP.SIGNUP.CREATE_ACCOUNT`) }}
		</h1>

		<div class="flex flex-wrap justify-between">
			<signup-input
				v-for="(input, index) in computedUserData"
				:key="index"
				v-model:input-data="computedUserData[index]"
				v-model:by-pass-signup="computedByPassSignup"
			/>
		</div>

		<p class="mt-3 mb-2 w-full text-gray-strong text-sm">
			{{ $t(`APP.SIGNUP.CHOOSE_PASSWORD`) }}
		</p>
		<div class="signup-password-errors flex mb-10">
			<div
				v-for="error in passwordErrors"
				:key="error.rule"
				class="flex flex-wrap justify-center items-center rounded-lg"
			>
				<p class="w-full border-r border-gray-strong text-center text-sm whitespace-nowrap px-1">
					{{ error.title }}
				</p>
				<i
					:class="` w-full text-xl font-black text-center px-1 ${
						error.isValid ? ' qi-check_mark text-green-main' : 'gui-close text-pink-main'
					}`"
				/>
			</div>
		</div>

		<validation-field
			v-slot="{ errors }"
			v-model="cgsChecked"
			type="checkbox"
			:checked-value="true"
			:unchecked-value="false"
			name="cgs"
			as="div"
			class="signup-label-checkbox mx-auto w-full mx-auto"
		>
			<q-check
				name="cgs"
				type="checkbox"
				@change="
					() => {
						cgsChecked = !cgsChecked
						if (!cgsTouched) cgsTouched = true
					}
				"
			>
				{{ $t(`APP.SIGNUP.CGU1`) }}
				<a
					:href="$t(`APP.SIGNUP.URLS.CGU`)"
					target="blank"
					class="underline"
				>
					{{ $t(`APP.SIGNUP.CGU2`) }}
				</a>
				{{ $t(`APP.SIGNUP.CGU3`) }}
				<a
					:href="$t(`APP.SIGNUP.URLS.PRIVACY`)"
					target="blank"
					class="underline"
				>
					{{ $t(`APP.SIGNUP.CGU4`) }}
				</a>
			</q-check>

			<div
				style="min-height: 1.5rem"
				class="mt-2"
			>
				<transition
					v-if="errors.length && cgsTouched"
					name="fade-in"
					mode="out-in"
				>
					<div class="flex text-sm text-gray-strong">
						<i
							class="gui-no text-gray-dark mr-1"
							style="margin-top: 3px"
						/>
						<span>
							{{ errors[0] }}
						</span>
					</div>
				</transition>
			</div>
		</validation-field>
	</div>
</template>

<script>
import SignupInput from './signupInput.vue'
import { Field as ValidationField } from 'vee-validate'

export default {
	components: {
		SignupInput,
		ValidationField,
	},

	beforeRouteEnter(to, from, next) {
		if (from.name === 'add-account-details') {
			next('add-account-details')
		}
		else {
			next()
		}
	},

	props: {
		userData: { type: Array, required: true },
		byPassSignup: { type: Boolean, required: true },
		spaceInvitation: { type: String, required: false, default: '' },
	},
	emits: ['update:by-pass-signup'],

	data() {
		return {
			passwordErrors: [
				{ rule: 'upper', title: 'ABC', isValid: false },
				{ rule: 'lower', title: 'abc', isValid: false },
				{ rule: 'digits', title: '123', isValid: false },
				{ rule: 'specials', title: '!@#', isValid: false },
				{ rule: 'moreThan8', title: `8 ${this.$t(`APP.SIGNUP.CHARACTERS`)}`, isValid: false },
			],
			cgsChecked: false,
			cgsTouched: false,
		}
	},

	computed: {
		computedUserData() {
			return this.userData.slice(0, this.computedBreakIndex)
		},
		computedUserPassword() {
			return this.userData.find((d) => d.name === 'password').value
		},
		computedBreakIndex() {
			return this.userData.findIndex((d) => d.name === 'password') + 1
		},
		computedByPassSignup: {
			get() {
				return this.byPassSignup
			},
			set(value) {
				this.$emit('update:by-pass-signup', value)
			},
		},
	},

	watch: {
		computedUserPassword(value) {
			this.checkPasswordErrors(value)
		},
	},

	mounted() {
		if (this.computedUserData.every((f) => f.value?.length > 0)) {
			this.computedByPassSignup = true
		}

		this.checkPasswordErrors()
	},

	methods: {
		checkPasswordErrors(password) {
			if (!password) {
				password = this.computedUserPassword
			}

			if (password?.length === 0) {
				this.passwordErrors.forEach((e) => (e.isValid = false))
			}

			if (password) {
				this.passwordErrors.find((d) => d.rule === 'upper').isValid = password.match(/[A-Z]/)
				this.passwordErrors.find((d) => d.rule === 'lower').isValid = password.match(/[a-z]/)
				this.passwordErrors.find((d) => d.rule === 'digits').isValid = password.match(/[0-9]/)
				this.passwordErrors.find((d) => d.rule === 'specials').isValid
					= password.match(/[^A-Z0-9]/i)
				this.passwordErrors.find((d) => d.rule === 'moreThan8').isValid = password.length >= 8
			}
		},
	},
}
</script>

<style lang="scss">
.signup-password-errors {
	& div:first-child p {
		padding-left: 0;
	}

	& div:last-child p {
		padding-right: 0;
		border: unset;
	}
}

.signup-label-checkbox {
	& label.q-control.one-liner {
		white-space: unset;
		height: unset;
		color: #72727b;
	}

	& .q-control input[type='checkbox'] {
		@apply absolute;
	}

	& span.align-middle {
		@apply ml-8;
	}
}
</style>
