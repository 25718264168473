<template>
	<div class="qview">
		<div class="z-10 q-header border-none shadow-sm">
			<div class="q-p-lg">
				<analytics-header></analytics-header>
			</div>
		</div>
		<!-- <div class="q-v-spacer-sm"></div> -->
		<div class="qview-main md:p-8 bg-pink-lighted">
			<div class="q-hide-scroll q-fullwidth bg-white">
				<router-view class="q-hide-scroll-content q-container"></router-view>
			</div>
		</div>
	</div>
</template>
<script>
import AnalyticsHeader from './analytics/header.vue'

export default {
	components: {
		AnalyticsHeader,
	},
}
</script>
<style lang="sass"></style>
