import { $request } from './requester'

export default {
	/**
	 * Add a turf
	 * @param {*} turf turf informations with layer_id mandatory
	 * @returns turf with the generated code and all the empty fields
	 */
	async addTurf(turf) {
		return $request('POST/WITHOUT_DATA_KEY', '/turf/turfs', turf).then((res) => res.body)
	},

	/**
	 * Update a turf data
	 * @param {*} object turf id to update and new data to provide
	 * @returns turf object with empty fields
	 */
	async updateTurf({ turfId, data }) {
		return $request('PATCH/WITHOUT_DATA_KEY_NOT_FORM', `/turf/turfs/${turfId}`, data).then((res) => res.body)
	},

	/**
	 * Delete a turf
	 * @param {*} turfId id of the turf to delete
	 * @returns request status
	 */
	async deleteTurf(turfId) {
		return $request('DELETE', `/turf/turfs/${turfId}`).then((res) => res)
	},

	/**
	 * Get all the layers without the turfs
	 * @returns list of layers
	 */
	async getLayers() {
		return $request('GET', '/turf/layers').then((res) => res.body)
	},

	/**
	 * Get a specific the layer with the turfs
	 * @param {*} layerId id of the layer to retrieve
	 * @returns layer wanted
	 */
	async getLayer(layerId) {
		return $request('GET', `/turf/layers/${layerId}`, { also_get_turfs: true }).then((res) => res.body)
	},

	/**
	 * Add a layer
	 * @param {*} layer layer informations with name and parent_layer_id mandatory
	 * @returns layer with all empty fields
	 */
	async addLayer(layer) {
		return $request('POST/WITHOUT_DATA_KEY', '/turf/layers', layer).then((res) => res.body)
	},

	/**
	 * Update a layer
	 * @param {*} layer layer informations
	 * @returns layer with the new fields
	 */
	async updateLayer({ layerData, layerId }) {
		return $request('PATCH/WITHOUT_DATA_KEY_NOT_FORM', `/turf/layers/${layerId}`, layerData).then((res) => res.body)
	},

	/**
	 * Delete a layer
	 * @param {*} layerId id of the layer to delete
	 * @returns request status
	 */
	async deleteLayer(layerId) {
		return $request('DELETE', `/turf/layers/${layerId}`).then((res) => res)
	},
}
