// module for the state store for form builder
import session_store from '../../models/session_store'
import Module from './ModuleFactory'

const state = {}

const actions = {
	actionSetCampaignInfos({ commit }) {
		return session_store.mygroup().then((res) => {
			commit('SET_CAMPAIGN_INFOS', res, { root: true })
		})
	},
	actionSetCampaignInfosCopy({ commit }, payload) {
		commit('SET_CAMPAIGN_INFOS', payload, { root: true })
	},

	actionUpdateInformations({ dispatch }, settings) {
		const group = {
			...settings,
			disable_autourdesoi:
				settings.around_me_disabled !== undefined && settings.around_me_disabled !== null
					? settings.around_me_disabled.toString()
					: null,
		}
		return session_store
			.updateMyGroup({ group })
			.then(() => {
				dispatch('actionSetCampaignInfos')
			})
			.catch((e) => {
				dispatch('actionSetCampaignInfos') // ? what is this for
				throw e
			})
	},

	actionDeleteSpacePicture({ dispatch }, payload) {
		return session_store.deletePhotoUploaded(payload.avatar_company).then(() => {
			payload.avatar_company = ''
			dispatch('actionUpdateInformations', payload)
		})
	},

	actionPostJsonToN8n({}, payload) {
		return session_store.postJsonToN8n(payload)
	},
}

export const settingsModule = new Module({
	state,
	actions,
})
