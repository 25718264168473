/* eslint-disable no-undef */
import get from 'lodash/get'
import sessionModel from '../../models/session_store'

const SET_STATUS_SIGNUP = 'SET_STATUS_SIGNUP'
const SET_STATUS_USER_DETAILS = 'SET_STATUS_USER_DETAILS'
const SET_STATUS_JOIN_CAUSE = 'SET_STATUS_JOIN_CAUSE'
const SET_TOKEN = 'SET_TOKEN'

const trimObjectStrings = (obj) => {
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === 'string') {
			obj[key] = obj[key].trim()
		}
	})
	return obj
}

const getDefaultState = () => {
	return {
		statusSignup: 'init',
		statusUserDetails: 'init',
		statusJoinCause: 'init',

		token: '',
	}
}
const state = getDefaultState

export const mutations = {
	[SET_STATUS_SIGNUP]: (state, payload) => {
		state.statusSignup = payload
	},
	[SET_STATUS_USER_DETAILS]: (state, payload) => {
		state.statusUserDetails = payload
	},
	[SET_STATUS_JOIN_CAUSE]: (state, payload) => {
		state.statusJoinCause = payload
	},

	[SET_TOKEN]: (state, payload) => {
		state.token = payload
	},
}

export const actions = {
	actionSignup: ({ commit }, payload) => {
		const { firstname, locale, surname, mail, password, invitationToken: invitation_token } = trimObjectStrings(payload)

		commit(SET_STATUS_SIGNUP, 'loading')
		sessionModel
			.signup({ firstname, locale, surname, mail, password, invitation_token })
			.then((res) => {
				const getToken = get(res, 'body.data.token', '')
				switch (res.status) {
					case 200:
						localStorage.setItem('token', getToken)
						commit(SET_TOKEN, res.body.data.token)
						commit(SET_STATUS_SIGNUP, 'success')
						break

					case 201:
						localStorage.setItem('token', getToken)
						commit(SET_TOKEN, res.body.data.token)
						commit(SET_STATUS_SIGNUP, 'success_skip_verification')
						break

					default:
						throw new Error('Unknown status')
				}
			})
			.catch((err) => {
				console.warn(err)
				commit(SET_STATUS_SIGNUP, 'error')
			})
	},

	actionPostUserDetails: ({ commit }, payload) => {
		const { token } = payload
		const trimmedPayload = trimObjectStrings(payload)

		const opts = {
			params: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
			catchable: true,
		}

		if (XCUSTOM_USER_AGENT) {
			opts.params['X-Custom-User-Agent'] = XCUSTOM_USER_AGENT
		}

		commit(SET_STATUS_USER_DETAILS, 'loading')
		sessionModel
			.addDetails(trimmedPayload, opts)
			.then(() => {
				commit(SET_STATUS_USER_DETAILS, 'success')
			})
			.catch(() => {
				commit(SET_STATUS_USER_DETAILS, 'error')
			})
	},

	actionPostJoinCause: ({ commit }, payload) => {
		const { code, mail, token } = trimObjectStrings(payload)

		const opts = {
			params: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
			catchable: true,
		}

		commit(SET_STATUS_JOIN_CAUSE, 'loading')
		sessionModel
			.joinCause({ code, mail }, opts)
			.then((res) => {
				commit(SET_STATUS_JOIN_CAUSE, 'success')
			})
			.catch(({ status }) => {
				switch (status) {
					case 404:
						commit(SET_STATUS_JOIN_CAUSE, 'invalid')
						break
					default:
						commit(SET_STATUS_JOIN_CAUSE, 'error')
						break
				}
			})
	},
}

export const getters = {
	getStatusSignup: (state) => state.statusSignup,
	getStatusUserDetails: (state) => state.statusUserDetails,
	getStatusJoinCause: (state) => state.statusJoinCause,

	getToken: (state) => state.token,
}

export const signupModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
