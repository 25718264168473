<template>
	<div>
		<div v-if="getAccessStatusSurveyAutoEmail === 'promoted'">
			<container-packer
				access="email_automatic_survey"
				small-padding
			/>
		</div>
		<div
			v-else
			class="flex justify-center py-2"
		>
			<div
				class="bg-white transition-all duration-300 ease-in w-full sm:w-9/12 md:w-8/12 lg:w-8/12 2xl:w-6/12 rounded-md p-8"
			>
				<div
					class="auto-send-settings bg-white border border-gray-200 w-full rounded-xl flex flex-col mb-6"
				>
					<div class="flex items-center justify-between w-full">
						<div
							v-if="getEmailerSendersLoadingStatus === 'loading'"
							class="p-2 w-full flex justify-center items-center"
						>
							<Loader
								size="50px"
							/>
						</div>
						<div
							v-else
							class="w-full pl-3 pr-5 py-2.5 max-w-full"
						>
							<div class="flex flex-row items-center max-w-full justify-between">
								<div
									:class="computedMissingSendersWarningWithCustomProviders ? 'flex-col gap-1 w-full' : 'flex-row truncate w-auto'"
									class="flex items-start"
								>
									<span
										v-if="computedMissingSendersWarningWithCustomProviders"
										class="bg-yellow-50 text-yellow-500 border border-yellow-500 rounded-lg px-3 py-2.5 text-sm w-full"
									>
										{{ methodGetNoSendersWarningtext() }}
										<span
											v-if="getEmailerAPIKey === 'QOMON'"
											class="text-black underline cursor-pointer"
											@click="$router.push({ name: 'bulk-emails' })"
										>{{ $t('CONTACT.EMAILS.EMAILS_LIST.MISSING_SENDER_LINK') }}</span>
									</span>
									<div
										v-else
										style="min-width: 55px;"
										class="text-gray-400"
									>
										<span>{{ $t('CONTACT.EMAILS.EMAILS_EDITOR.WITH') }}</span>
									</div>
									<p
										class="w-auto truncate"
										style="min-width: 0"
									>
										<template v-if="computedIsSendersWithCustomProviders">
											<span class="font-bold pr-3">{{ computedGetSenderNameByEmailer }}</span>
											<span :title="sender">
												{{ `<${sender}>` }}
											</span>
										</template>
										<template v-if="getEmailerAPIKey !== 'QOMON' && getEmailerAPIKey !== 'SENDINBLUE'">
											<span class="font-bold pr-3">{{ info_campagne.name }}</span>
											<el-popover
												popper-class="rounded-xl"
												class="rounded-xl"
												popper-style="border-radius: 0.75rem"
												placement="top"
												:width="300"
												trigger="click"
											>
												<template #reference>
													<span
														class="hover:bg-gray-50 cursor-pointer rounded-xl p-1"
														:title="sender"
													>
														{{ `<${sender}>` }}
													</span>
												</template>
												<div class="flex flex-col gap-5">
													{{ $t('CONTACT.HEADER.BULK_EMAILS.MODIFY_BASIC_SENDER') }}
													<el-input v-model="sender" />
												</div>
											</el-popover>
										</template>
									</p>
								</div>

								<div
									v-if="
										computedIsSendersWithCustomProviders ||
											getEmailerAPIKey !== 'QOMON' && getEmailerAPIKey !== 'SENDINBLUE'
									"
									class="justify-end items-center flex"
									style="min-width: 180px"
								>
									<el-dropdown
										v-if="computedIsSendersWithCustomProviders"
										placement="bottom"
										popper-class="rounded-xl"
										@command="methodHandleCommand"
									>
										<div class="flex item-center">
											<i class="font-bold text-xl gui-arrow_down" />
										</div>
										<template #dropdown>
											<el-dropdown-menu class="rounded-xl px-1">
												<el-dropdown-item
													v-for="(
														emailSender, index
													) in computedGetValidatedSendersSortedByDefaultFlag"
													:key="index"
													class="py-2 px-3 rounded-lg"
													:class="{ 'text-pink-main': emailSender.email === sender }"
													:command="emailSender.email"
												>
													{{ emailSender.email }}
													<i
														v-if="emailSender?.default_flag"
														class="ml-2 gui-star_rounded text-black"
													/>
												</el-dropdown-item>
												<el-divider class="my-2" />
												<el-dropdown-item
													v-if="getEmailerAPIKey === 'QOMON'"
													class="py-2 px-3 rounded-lg"
													command="add_new_sender"
												>
													<div class="w-full flex justify-end">
														{{ $t('SETTINGS.HEADER.BULK_EMAIL_ADD_SENDER') }}
													</div>
												</el-dropdown-item>
											</el-dropdown-menu>
										</template>
									</el-dropdown>
									<div
										v-if="
											computedIsSendersWithCustomProviders ||
												getEmailerAPIKey !== 'QOMON' && getEmailerAPIKey !== 'SENDINBLUE'
										"
										class="flex items-center gap-3"
									>
										<el-tooltip
											popper-class="rounded-xl"
											placement="left"
											:content="$t('FORM.AUTO_SEND.EDITOR.CC_CTA_TOOLTIP')"
										>
											<div
												:class="ccActivated ? 'text-pink-strong' : 'text-gray-400'"
												class="cursor-pointer ml-3 bg-gray-100 rounded-lg px-1 py-0.5 w-fit transition-colors duration-300"
												@click="methodHandlecc"
											>
												{{ $t('FORM.AUTO_SEND.EDITOR.CC') }}
											</div>
										</el-tooltip>
										<el-tooltip
											popper-class="rounded-xl"
											placement="top"
											:content="$t('FORM.AUTO_SEND.EDITOR.REPLY_TO_CTA_TOOLTIP')"
										>
											<div
												:class="replyToActivated ? 'text-pink-strong' : 'text-gray-400'"
												class="cursor-pointer bg-gray-100 rounded-lg px-1 py-0.5 w-fit transition-colors duration-300"
												@click="methodHandleReplyTo"
											>
												{{ $t('FORM.AUTO_SEND.EDITOR.REPLY_TO') }}
											</div>
										</el-tooltip>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="ccActivated"
						class="pl-3 pr-3 py-0.5 flex justify-between w-full border-t border-gray-200"
					>
						<div
							id="custom-select-container"
							class="flex items-center justify-start w-full"
						>
							<span
								style="min-width: 60px"
								class="text-gray-400"
							>{{ $t('CC') }} &nbsp;&nbsp;</span>
							<el-select
								id="email"
								v-model="mailCC"
								filterable
								multiple
								allow-create
								clearable
								:multiple-limit="1"
								:reserve-keyword="false"
								popper-class="rounded-xl"
								default-first-option
								class="w-full mr-5 -ml-3"
								:placeholder="$t('APP.LOGIN.EMAIL')"
							>
								<el-option
									v-for="(user, index) in getAllUsers"
									:key="index"
									:label="user.mail"
									:value="user.mail"
									class="flex items-center gap-3 h-11 mx-2 hover:bg-pink-50 rounded-lg hover:text-pink-main"
								>
									<img
										v-if="
											user.avatar &&
												user.avatar != null &&
												user.avatar != 'null' &&
												user.avatar != undefined
										"
										:src="user.avatar"
										class="w-8 h-8 rounded-full"
									>
									<div
										v-else
										class="flex justify-center items-center w-8 h-8 rounded-full bg-pink-50 font-title-bold text-black"
									>
										{{
											`${user.firstname.toUpperCase().split('')[0]}${
												user.surname.toUpperCase().split('')[0]
											}`
										}}
									</div>
									<span
										style="max-width: 250px"
										class="truncate"
									>
										{{ user.mail }}
									</span>
								</el-option>
							</el-select>
						</div>
						<el-tooltip
							placement="top"
							popper-class="rounded-xl"
						>
							<template #content>
								<span v-dompurify-html="$t('FORM.AUTO_SEND.EDITOR.TOOLTIP_CC')" />
							</template>
							<span class="flex items-center justify-center"><i
								:class="
									mailCC.length > 0
										? 'bg-yellow-50 hover:bg-yellow-100 text-yellow-500 gui-warning'
										: 'hover:bg-gray-100 text-black gui-infos'
								"
								style="padding-left: 5px; padding-right: 5px"
								class="rounded-full transition-all duration-500 cursor-help text-lg"
							/></span>
						</el-tooltip>
					</div>
					<div
						v-if="replyToActivated"
						class="pl-3 pr-3 py-2.5 flex justify-between w-full border-t border-gray-200"
					>
						<div class="flex items-center justify-start w-full">
							<span
								class="text-gray-400"
								style="min-width: 60px"
							>{{ $t('FORM.AUTO_SEND.EDITOR.REPLY_TO') }} &nbsp;&nbsp;</span>
							<input
								v-model="mailReplyTo"
								class="flex-1 outline-none"
								type="text"
								:placeholder="$t('APP.LOGIN.EMAIL')"
							>
						</div>
						<el-tooltip
							placement="right"
							popper-class="rounded-xl"
						>
							<template #content>
								<span v-dompurify-html="$t('FORM.AUTO_SEND.EDITOR.TOOLTIP_REPLY_TO')" />
							</template>
							<i
								style="padding-left: 5px; padding-right: 5px"
								class="gui-infos hover:bg-gray-100 rounded-full transition-all duration-500 cursor-help text-lg"
							/>
						</el-tooltip>
					</div>
					<div
						class="pl-3 pr-3 py-2.5 flex justify-between w-full border-t border-gray-200 transition-all"
					>
						<div class="flex items-center justify-start w-full">
							<span
								class="text-gray-400"
								style="min-width: 60px"
							>{{ $t('SETTINGS.AUTOSEND_CONF.SUBJECT_TITLE') }} &nbsp;&nbsp;</span>
							<input
								v-model="mailObject"
								class="flex-1 outline-none"
								type="text"
								:placeholder="$t('MAILING.EDITOR_&_PREVIEW_OBJECT')"
							>
						</div>
						<span
							v-if="mailObject.length >= 45"
							style="min-width: 100px"
							class="flex items-center justify-end gap-2 text-sm"
						>
							{{ mailObject.length }} / 50
							<el-tooltip
								v-show="mailObject.length >= 50"
								transition="el-zoom-in-bottom"
								placement="bottom"
								:visible-arrow="false"
								popper-class="rounded-xl"
							>
								<!--  -->
								<template #content>
									<span
										v-dompurify-html="$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.OBJECT_TOOLTIP')"
										class="leading-5"
									/>
								</template>
								<i
									style="padding-left: 5px; padding-right: 5px"
									class="gui-warning text-yellow-400 hover:bg-yellow-100 hover:text-yellow-500 rounded-full transition-all duration-500 cursor-pointer text-lg"
								/>
							</el-tooltip>
						</span>
					</div>
				</div>
				<div class="bg-white border border-gray-200 w-full rounded-xl flex flex-col mt-5 mb-6">
					<tip-tap-editor
						v-model:value="header"
						:index="0"
						:placeholder="t.updateHeaderPlaceholder"
						:is-editor-background-white="false"
						:is-var-templating="true"
						:var-labels="computedGetVarLabels"
						:is-keeping-its-height="false"
						:is-checking-for-var-with-dollar="true"
						:is-editor-have-border="false"
						:show-border-at-hover="false"
						:is-margin-bottom="false"
						:no-image-pasting="true"
					/>
					<div
						class="flex border border-pink-strong rounded-xl border-dashed justify-center items-center py-7 px-5"
						style="width: 97%; margin-left: 1.5%"
					>
						<div class="flex items-center">
							<div class="flex flex-col items-center justify-center text-center gap-2">
								<span>
									{{ t.answersMessages }}
									<el-tooltip
										placement="top"
										popper-class="rounded-xl"
									>
										<template #content>
											{{ t.associatedMessagesTooltip }}
										</template>
										<i class="gui-infos ml-3 text-xl cursor-help" />
									</el-tooltip>
								</span>
								<span class="opacity-60 text-sm">
									{{ $t('SETTINGS.AUTOSEND_CONF.MESSAGE_ANSWERS_PLUS') }}
								</span>
							</div>
						</div>
					</div>
					<tip-tap-editor
						v-model:value="footer"
						:index="1"
						:placeholder="t.updateFooterPlaceholder"
						:is-editor-background-white="false"
						:is-var-templating="true"
						:var-labels="computedGetVarLabels"
						:is-keeping-its-height="false"
						:is-checking-for-var-with-dollar="true"
						:is-editor-have-border="false"
						:show-border-at-hover="false"
						:is-margin-bottom="false"
					/>
				</div>
				<div class="px-3">
					<helper :helper-content="computedHelperContent" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { autosend } from '../mixins/autosend-mixin.js'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent, nextTick } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

import debounce from 'lodash/debounce'

export default {
	name: 'AutosendSettings',

	components: {
		ContainerPacker: defineAsyncComponent(() => import('../packs/containerPacker.vue')),
		TipTapEditor: defineAsyncComponent(() => import('../create-form-components/tipTapEditor.vue')),
		Helper: defineAsyncComponent(() => import('../app-module/helper.vue')),
		Loader: defineAsyncComponent(() => import('../general/loader.vue')),
	},

	mixins: [autosend],

	beforeRouteLeave(to, from, next) {
		this.actionUpdateReducedListState(false)

		next()
	},

	props: {
		saveAutosendSettingsTrigger: {
			type: Number,
			required: true,
			default: 0,
		},
	},

	data() {
		return {
			options: [
				{
					value: 'HTML',
					label: 'HTML',
				},
				{
					value: 'CSS',
					label: 'CSS',
				},
				{
					value: 'JavaScript',
					label: 'JavaScript',
				},
			],
			footer: '',
			header: '',
			loadingSave: false,
			possibleSenders: [],
			sender: '',
			mailObject: '',
			mailCC: [],
			mailReplyTo: '',
			senderIndex: -1,

			ccActivated: true,
			replyToActivated: true,
			surveyUrl: '/settings/form',
			tags: [this.getTag('firstname'), this.getTag('surname')],
			varInterpolationCount: 0,
			clonedSender: '',
		}
	},
	computed: {
		...mapGetters({
			info_campagne: 'info_campagne',
			emailaccs: 'emailaccs',
			autosend_email_acc_id: 'autosend_email_acc_id',
			autosend_subject: 'autosend_subject',
			autosend_header: 'autosend_header',
			autosend_footer: 'autosend_footer',
			autosend_cc: 'autosend_cc',
			autosend_reply_to: 'autosend_reply_to',
			autosend_sender: 'autosend_sender',
			getAllUsers: 'getAllUsers',
		}),
		...mapGetters('@packs', ['getAccessStatusSurveyAutoEmail']),
		...mapGetters('@form', ['getActiveStaticKeysAvailables']),
		...mapGetters('@emailer', ['getValidatedEmailerSenders', 'getEmailerAccountStatus', 'getEmailerSendersLoadingStatus']),
		...mapGetters('@group', ['getEmailerAPIKey', 'getSynchronizationBrevo']),

		t() {
			const prefix = 'SETTINGS.AUTOSEND_CONF'

			return {
				answersMessages: this.$t(`${prefix}.MESSAGE_ANSWERS`),
				associatedMessagesTooltip: this.$t(`${prefix}.ASSOCIATED_MESSAGES_TOOLTIP`, {
					action: 'Questionnaire',
				}),
				explanations: this.$t(`${prefix}.DESC`),
				mailAutoHelp: this.$t(`${prefix}.MAIL_AUTO_HELP`),
				messageComingFrom: this.$t(`${prefix}.MESSAGE_PREVIEW.SENDER`),
				noReplyToSender: this.$t(`${prefix}.MESSAGE_PREVIEW.NO_REPLY`),
				placeholderMiss: this.$t(`${prefix}.PLACEHOLDERS.MISS`),
				placeholderMister: this.$t(`${prefix}.PLACEHOLDERS.MISTER`),
				saveIt: this.$t(`${prefix}.SAVE_IT`),
				subjectPlaceholder: this.$t(`${prefix}.SUBJECT_PLACEHOLDER`),
				subjectTitle: this.$t(`${prefix}.SUBJECT_TITLE`),
				success: this.$t(`${prefix}.SUCCESS`),
				survey: this.$t(`${prefix}.SURVEY`),
				titleAutoSend: this.$t(`${prefix}.TITLE`),
				titleVariables: this.$t(`${prefix}.TITLE_VARIABLES`),
				unsubscribeNotice: this.$t(`${prefix}.UNSUBSCRIBE_NOTICE`),
				updateFooter: this.$t(`${prefix}.UPDATE_FOOTER`),
				updateFooterPlaceholder: this.$t(`${prefix}.UPDATE_FOOTER_PLACEHOLDER`),
				updateHeader: this.$t(`${prefix}.UPDATE_HEADER`),
				updateHeaderPlaceholder: this.$t(`${prefix}.UPDATE_HEADER_PLACEHOLDER`),
			}
		},

		computedIsAnotherEmailerThanElasticEmail() {
			return this.getEmailerAPIKey !== 'QOMON'
		},

		computedGetVarLabels() {
			return this.getActiveStaticKeysAvailables
				.filter((key) => key.type === 'firstname' || key.type === 'surname')
				.map((resutl) => ({
					label: resutl.label,
					type: resutl.type,
				}))
		},

		computedGetFirstnameLabel() {
			const firstname = this.computedGetVarLabels.find((key) => key.type === 'firstname')

			return firstname
				? {
					label: firstname?.label,
					type: firstname?.type,
				}
				: {
					label: this.$t('SETTINGS.AUTOSEND_CONF.FIELD_DELETED'),
					type: 'deleted_field',
				}
		},

		computedGetSurnameLabel() {
			const surname = this.computedGetVarLabels.find((key) => key.type === 'surname')

			return surname
				? {
					label: surname?.label,
					type: surname?.type,
				}
				: {
					label: this.$t('SETTINGS.AUTOSEND_CONF.FIELD_DELETED'),
					type: 'deleted_field',
				}
		},

		computedGetSenderNameByEmailer() {
			if (this.getValidatedEmailerSenders) {
				const sender = this.getValidatedEmailerSenders.find(
					(sender) => sender.email === this.sender,
				)

				return sender?.name ? sender.name : this.info_campagne.name
			}

			return ''
		},

		computedGetValidatedSendersSortedByDefaultFlag() {
			if (this.getValidatedEmailerSenders) {
				return [...this.getValidatedEmailerSenders].sort((a, b) => {
					const aDefaultFlag = a?.default_flag ?? false
					const bDefaultFlag = b?.default_flag ?? false

					return bDefaultFlag - aDefaultFlag
				})
			}

			return []
		},

		computedHelperContent() {
			return [
				{
					title: this.$t('_COMMON.HELP'),
					text:
						this.getEmailerAPIKey === 'QOMON'
							? this.$t('FORM.AUTO_SEND.EDITOR.HELPER_EE')
							: this.$t('FORM.AUTO_SEND.EDITOR.HELPER'),
				},
			]
		},

		computedMissingSendersWarningWithCustomProviders() {
			return (
				!this.getValidatedEmailerSenders
				&& (this.getEmailerAPIKey === 'QOMON'
				&& (this.getEmailerAccountStatus === 'success' || this.getEmailerAccountStatus === 'default')
				|| this.getEmailerAPIKey === 'SENDINBLUE')
			)
		},

		computedIsSendersWithCustomProviders() {
			return (
				this.getValidatedEmailerSenders
				&& (this.getEmailerAPIKey === 'QOMON' || this.getEmailerAPIKey === 'SENDINBLUE')
			)
		},
	},
	watch: {
		// emailaccs() {
		// 	this.setPossibleSenders(this.emailaccs)
		// },
		// autosend_email_acc_id() {
		// 	this.updateSender()
		// },
		autosend_subject() {
			this.mailObject = this.autosend_subject
		},

		autosend_header() {
			this.header = this.autosend_header
				.replaceAll(
					'$firstname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetFirstnameLabel.type}" data-label="${this.computedGetFirstnameLabel.label}">${this.computedGetFirstnameLabel.label}</span>`,
				)
				.replaceAll(
					'$surname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetSurnameLabel.type}" data-label="${this.computedGetSurnameLabel.label}">${this.computedGetSurnameLabel.label}</span>`,
				)
		},
		autosend_footer() {
			this.footer = this.autosend_footer
				.replaceAll(
					'$firstname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetFirstnameLabel.type}" data-label="${this.computedGetFirstnameLabel.label}">${this.computedGetFirstnameLabel.label}</span>`,
				)
				.replaceAll(
					'$surname',
					`<span data-type="mention" class="mention" data-id="${this.computedGetSurnameLabel.type}" data-label="${this.computedGetSurnameLabel.label}">${this.computedGetSurnameLabel.label}</span>`,
				)
		},

		autosend_cc(newValue) {
			if (newValue && newValue?.length > 0) {
				this.mailCC = [newValue]
				this.ccActivated = true
			}
			else this.mailCC = []
		},

		autosend_reply_to() {
			this.mailReplyTo = this.autosend_reply_to
		},

		sender: debounce(function (newValue) {
			this.$store.commit('SET_AUTOSEND_SENDER', newValue)
		}, 350),
		mailObject: debounce(function (newValue) {
			this.$store.commit('SET_AUTOSEND_SUBJECT', newValue)
		}, 350),
		header: debounce(function (newValue) {
			this.$store.commit('SET_AUTOSEND_HEADER', newValue)
		}, 350),

		footer: debounce(function (newValue) {
			this.$store.commit('SET_AUTOSEND_FOOTER', newValue)
		}, 350),

		mailCC: debounce(function (newValue) {
			if (newValue[0]) this.ccActivated = true

			this.$store.commit('SET_AUTOSEND_CC', newValue[0] ? newValue[0] : '')
		}, 350),

		mailReplyTo: debounce(function (newValue) {
			if (newValue !== '') this.replyToActivated = true

			this.$store.commit('SET_AUTOSEND_REPLY_TO', newValue)
		}, 350),

		// senderIndex() {
		// 	if (this.emailaccs.length > this.senderIndex) {
		// 		this.sender = this.emailaccs[this.senderIndex].address
		// 		this.$store.commit('SET_AUTOSEND_EMAIL_ACC_ID', this.emailaccs[this.senderIndex].id)
		// 	}
		// },

		saveAutosendSettingsTrigger(newValue) {
			if (newValue) this.saveTheFormMail()
		},

		getValidatedEmailerSenders(newValue) {
			if (newValue) this.methodSetSender()
		},
	},

	mounted() {
		this.getAutosend()
		this.getEmailaccs()
		this.setupHTML()

		
		this.actionSetEmailerAccountData()
		this.actionGetSenders()

		
		nextTick(() => {
			this.actionUpdateReducedListState(true)
			this.methodSetSender()
		})
	},

	methods: {
		...mapActions({
			getAutosend: 'SETUP_AUTOSEND',
			getEmailaccs: 'GET_EMAILACCS',
			saveAutosend: 'SAVE_AUTOSEND',
		}),

		...mapActions('@form', ['actionUpdateReducedListState']),
		...mapActions('@emailer', ['actionSetEmailerAccountData', 'actionGetSenders']),

		setupHTML() {
			// this.setPossibleSenders(this.emailaccs)
			// this.updateSender()
			this.clonedSender = cloneDeep(this.autosend_sender)
			this.mailObject = this.autosend_subject
			this.header = `<span>${this.autosend_header}</span>`
			this.footer = this.autosend_footer

			if (this.autosend_cc && this.autosend_cc.length > 0) {
				this.mailCC = [cloneDeep(this.autosend_cc)]
				this.ccActivated = true
			}
			else this.mailCC = []

			if (this.autosend_reply_to) {
				this.mailReplyTo = this.autosend_reply_to
				this.replyToActivated = true
			}
		},

		// need to properly set the sender and sender index in both cases where the
		// autosend or emailaccs call returns first
		// setPossibleSenders(emailaccs) {
		// 	let vueComponent = this
		// 	// extract the "address" field from the array of object
		// 	this.possibleSenders = emailaccs.map(function (emailacc, index) {
		// 		if (vueComponent.autosend_email_acc_id == emailacc.id) {
		// 			vueComponent.senderIndex = index
		// 		}
		// 		return emailacc.address
		// 	})
		// },

		// updateSender() {
		// 	for (let i = 0; i < this.emailaccs.length; i++) {
		// 		if (this.autosend_email_acc_id == this.emailaccs[i].id) {
		// 			this.senderIndex = i
		// 			return
		// 		}
		// 	}
		// },

		async saveTheFormMail() {
			this.loadingSave = true
			const isMailjet = this.getEmailerAPIKey !== 'SENDINBLUE' && this.getEmailerAPIKey !== 'QOMON'

			this.saveAutosend(isMailjet)
				.then(() => {
					this.$message({
						type: 'success',
						message: this.t.success,
						showClose: true,
					})
					this.loadingSave = false
				})
				.catch(() => {
					this.$message({
						type: 'error',
						message: this.t.success,
						showClose: true,
					})
				})
		},

		methodSetSender() {
			if(this.getSynchronizationBrevo !== 'setup_needed') {
				this.sender = this.autosend_sender
				return
			}
			if (this.computedIsAnotherEmailerThanElasticEmail && !this.getValidatedEmailerSenders) {
				if (this.getEmailerAPIKey === 'SENDINBLUE') {
					const isSenderEmpty = this.autosend_sender === ''

					isSenderEmpty
						? (this.sender = `no_reply_${this.methodStripEmojis(
							[...this.info_campagne.name],
						).replaceAll(' ', '_')}@qomon.app`)
						: (this.sender = this.methodStripEmojis([...this.autosend_sender]).replaceAll(
							' ',
							'_',
						))

					return
				}

				const senderPreformated = this.methodStripEmojis([...this.info_campagne.name])

				return `no_reply_${senderPreformated.replaceAll(' ', '_')}@qomon.app`
			}

			if (!this.getValidatedEmailerSenders) {
				this.sender = ''
				return
			}

			console.log(this.autosend_sender, this.getValidatedEmailerSenders)

			if (this.autosend_sender.length > 0 && this.getValidatedEmailerSenders.some((sender) => sender.email === this.autosend_sender)) {
				this.sender = this.autosend_sender
				return
			}

			let defaultSender = this.getValidatedEmailerSenders.find((sender) => sender?.default_flag)

			if (!defaultSender)
				defaultSender = this.getValidatedEmailerSenders.find((sender) => sender?.validated)

			this.sender = cloneDeep(defaultSender.email)
		},

		methodHandleCommand(sender) {
			if (sender === 'add_new_sender') {
				this.$router.replace({ name: 'bulk-emails' })
				return
			}

			this.sender = sender
		},

		methodHandlecc() {
			if (this.ccActivated) {
				this.ccActivated = false
				this.mailCC = []

				return
			}

			this.ccActivated = true
		},

		methodHandleReplyTo() {
			if (this.replyToActivated) {
				this.replyToActivated = false
				this.mailReplyTo = ''

				return
			}

			this.replyToActivated = true
		},

		methodStripEmojis(campagneName) {
			return campagneName
				.replaceAll(
					/([\u2700-\u27BF\uE000-\uF8FF\u2011-\u26FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/g,
					'',
				)
				.replaceAll(/\s+-/g, '')
				.trim()
		},

		methodGetNoSendersWarningtext() {
			switch (this.getEmailerAPIKey) {
				case 'QOMON': {
					return this.$t('CONTACT.EMAILS.EMAILS_LIST.MISSING_SENDER')
				}
				case 'SENDINBLUE': {
					return this.$t(
						'CONTACT.EMAILS.EMAILS_LIST.MISSING_SENDER_BREVO',
					)
				}
			}
		},
	},
}
</script>

<style lang="scss">
#custom-select-container {
	.el-select__wrapper {
		border: none !important;
		box-shadow: none !important;
	}
	.el-select__tags-text {
		color: rgb(223, 11, 105) !important;
	}
	.el-select__tags .el-tag--info {
		border-radius: 0.5rem;
		background-color: rgb(243, 244, 246) !important;
	}
}

.auto-send-settings {
	input::placeholder {
		color: #b2bbc8;
		opacity: 1; /* Firefox */
	}

	input::-ms-input-placeholder {
		/* Edge 12 -18 */
		color: #b2bbc8;
	}
}
@import '../../assets/scss/settings/autosend-settings.scss';
@import '../../assets/scss/general/text-input.scss';
.small-padding-bottom {
	padding-bottom: 10vh;
}
.smaller-container {
	height: 70%;
	overflow: auto;
}
.flex-column {
	flex-direction: column;
}
.italic {
	font-style: italic;
}

.autosend-email-preview-container {
	a {
		text-decoration: underline;
	}
}

.autosend-preview-header,
.autosend-preview-footer {
	img {
		height: 2.5rem;
		display: inline;
		padding: 0.25rem 0;
	}
}
.autosend-preview-email-body s {
	text-decoration: none;
}
.editor-right {
	background-color: white !important;
}

.mention {
	@apply bg-gray-100 text-pink-strong;
	border-radius: 0.5rem;
	padding: 0.2rem 0.4rem;
	box-decoration-break: clone;
}
</style>
