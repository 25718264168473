import { get, cloneDeep } from 'lodash'
import { api } from '../models/common'
import { DataTransformer } from '@quorumsco/quorum-helpers/lib/filter/DataTransformer'

type Filters = {
	fields: string[]
	polygon: string[]
	query: string
	tags: string[]
	address_included: string[]
	polling_station_included: string[]
	polling_station_included_missing: boolean
	advancedSearch: object
}

/**
 * This function will verify if the filters are valid by checking the KPI
 * @param filters Filters to be verified
 * @param totalVerification Total verifications
 * @returns True if the filters are valid, false otherwise
 */
export const filtersVerificationByKPI = async (
	filters: Filters,
	totalVerification: number,
	areContactsManuallySelected: Boolean = false
) => {
	const filtersCloned = cloneDeep(filters)
	DataTransformer(filtersCloned)
	const response = await fetch(api + '/kpi', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ data: { ...filtersCloned } }),
	})

	if (response.ok) {
		const data = await response.json()
		const kpiFound = get(data, 'data.kpi[0].KpiReplies[0].Doc_count', 0)
		if (!kpiFound) return false
		return areContactsManuallySelected ? true : kpiFound === totalVerification
	}

	console.error('Error:', response)
	return false
}
