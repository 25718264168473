<template>
	<div
		class="q-control"
		:class="[
			{
				'has-icon-left': icon,
				valid: valid || (!neutral && validate),
				invalid: invalid || (!neutral && !validate),
			},
			{ 'custom-input': newGuiInput },
		]"
		:style="$slots.footer || $slots.warning ? 'margin-bottom: 1.5em' : ''"
	>
		<qi v-if="icon" class="is-left" :icon="icon" />
		<input
			ref="customInput"
			:type="togglePasswordOrDefaultInherited"
			:value="value"
			:placeholder="placeholder"
			:class="[{ 'show-placeholder': !$slots.label }, { 'display-lowercase': displayLowercase }]"
			:maxlength="maxlength"
			:autocomplete="autocomplete"
			:autofocus="autofocus"
			required
			:disabled="disabled"
			@change="$emit('change', $refs.customInput.value)"
			@input="$emit('update:value', $refs.customInput.value)"
			@blur="$emit('blur', $event)"
			@keydown="$emit('keydown', $event)"
			@focus="$emit('focus', { event: $event, type: type, value: true })"
			@focusout="$emit('focusout', { event: $event, type: type, value: false })"
		/>
		<span
			v-if="type === 'password' && passwordCanBeShown"
			class="show-password"
			@click="showPassword = !showPassword"
		>
			{{ $t(!showPassword ? '_COMMON.SHOW' : '_COMMON.HIDE') }}
		</span>
		<label><slot name="label"></slot></label>
		<span class="foot-help">
			<slot v-if="neutral || validate || !$slots.warning" name="footer" />
			<slot v-if="!neutral && !validate" name="warning" />
		</span>
	</div>
</template>

<script>
export default {
	props: {
		newGuiInput: {
			type: Boolean,
			default: false,
		},
		value: [String, Number],
		icon: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: 'Answer ...',
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: false,
		},
		invalid: {
			type: Boolean,
			default: false,
		},
		validator: {
			type: Function,
			default: () => {},
		},
		type: {
			type: String,
			default: 'text',
		},
		maxlength: {
			type: Number,
			default: 524288,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		autocomplete: {
			type: String,
			default: '',
		},
		displayLowercase: {
			type: Boolean,
			required: false,
			default: false,
		},
		passwordCanBeShown: {
			type: Boolean,
		},
	},
	emits: ['change', 'blur', 'keydown', 'focus', 'focusout', 'update:value'],

	data() {
		return {
			showPassword: false,
		}
	},
	computed: {
		neutral() {
			const result = this.validator()
			return !this.value || result === null || result === undefined
		},

		validate() {
			if (this.value) {
				return this.validator()
			}
			return false
		},

		focus() {
			return this.$refs.customInput.focus()
		},
		togglePasswordOrDefaultInherited() {
			if (this.showPassword) {
				return 'text'
			} else {
				return this.type
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.custom-input
	@apply border rounded-xl pt-6 pb-5
	input
		border-bottom: 0px
		@apply px-4
</style>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.custom-input {
	border-color: $light-grey;
}
</style>
