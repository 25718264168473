<template>
	<div
		ref="searchBar"
		class="q-searchbar"
		@keyup.down="selectNext"
		@keyup.up="selectPrev"
	>
		<template v-if="showTags">
			<span
				v-for="(tag, index) in tags"
				:key="(tag.id || tag.type) + tag.label || index"
			>
				<span class="q-tag">
					<span
						v-dompurify-html="renderTags('content', tag)"
						@click="callback(tag)"
					/>
					<span
						class="action qi-close tag-close"
						@click.prevent="removeTag(tag)"
					/>
				</span>
			</span>
		</template>
		<input
			v-model="search"
			:placeholder="placeholder"
			@keyup.enter="() => selectSuggestion(suggestions[selectedIndex])"
			@focus="showAutocomplete"
			@blur="blur"
			@keydown.delete="removeEmpty"
			autocorrect="off"
			autocapitalize="off"
			spellcheck="false"
		>
		<div v-if="$slots.side">
			<slot name="side" />
		</div>

		<div
			ref="autocompleteProps"
			class="autocomplete"
			style="max-height: 30%; overflow-y: auto"
		>
			<span
				v-for="(suggestion, index) in suggestions"
				v-show="suggestions.length"
				:key="suggestion.id"
				v-dompurify-html="decorateSuggestion(suggestion)"
				class="selectable q-p clickable"
				:class="{ selected: selectedIndex === index }"
				@click="() => selectSuggestion(suggestion)"
			/>
		</div>
	</div>
</template>

<script>
import { nextTick } from 'vue'
import { mapActions } from 'vuex'

import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import QueryBuilder from '@quorumsco/quorum-helpers/lib/filter/QueryBuilder'
import modulesInstancesMixin from '../../../components/mixins/modules-instances-mixin'

export default {
	mixins: [modulesInstancesMixin],

	model: {
		prop: 'tags',
		event: 'change',
	},

	props: {
		tags: {
			type: Array,
			required: false,
			default: () => [],
		},
		showTags: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
			default: 'Search ...',
		},
		autocomplete: {
			type: Boolean,
			default: false,
		},
		fetchAutcompletion: {
			type: Function,
			default: () => {},
		},
		decorateSuggestion: {
			type: Function,
			default: (suggestion) => suggestion.value,
		},
		renderTags: {
			type: Function,
			default: (tag) => {
				return tag.label
			},
		},

		showContactsListHandle: {
			type: Function,
			default: () => null,
		},

		instanciedModuleId: {
			type: String,
			default: '',
		},
	},

	emits: ['change', 'update:tags', 'showContactList', 'newFilters'],

	data() {
		return {
			search: '',
			showAutocompletion: false,
			suggestions: [],
			loading: false,
			selectedIndex: -1,
		}
	},

	computed: {
		computedTags: {
			get() {
				return this.tags
			},
			set(newVal) {
				this.$emit('update:tags', newVal)
			},
		},
	},

	watch: {
		search(newVal) {
			if (this.autocomplete && newVal !== '') {
				this.suggestions = []
				this.selectedIndex = 0
				this.loading = true
				this.fetchAutcompletion(newVal, this.renderSuggestions)
			}
			else {
				this.renderSuggestions([])
			}
		},

		showAutocompletion(newVal) {
			if (newVal) {
				this.$refs.autocompleteProps.style.display = 'flex'
				this.$refs.autocompleteProps.style.opacity = 1
			}
			else {
				this.$refs.autocompleteProps.style.display = 'none'
				this.$refs.autocompleteProps.style.opacity = 0
			}
		},
	},

	actionsMethodsInstance() {
		const search = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@search',
			this.instanciedModuleId,
		)
		return {
			...mapActions(search, ['actionSetAdvancedSearch']),
		}
	},

	methods: {
		/**
		 * Add a tag will create a particular
		 * object will be used by the contact-header
		 * The contact header will take it
		 * and use it to display informations and
		 * launch the possible '/search'
		 */
		addTag(sugg) {
			const { type, value, address, count, empty } = sugg
			if (!type && this.autocomplete && this.suggestions.length && this.selectedIndex > -1) {
				const prepareTagToPush = {
					id: this.tags.length,
					label: value,
					type: type || 'default',
					address,
				}

				this.computedTags.push(prepareTagToPush)
			}
			else if (this.search !== '') {
				const prepareTagToPush = {
					id: this.tags.length,
					label: this.search,
					type: type || 'default',
					address,
					count,
					empty,
				}

				this.computedTags.push(prepareTagToPush)
			}

			this.$emit('change', this.tags)
			this.search = ''
		},

		removeEmpty() {
			if (this.search === '') {
				this.computedTags.pop()
			}
		},

		selectSuggestion(sugg) {
			if (sugg) {
				if (sugg.is_advanced) {
					let conditionKey = sugg.type === 'email' ? 'mail' : sugg.type
					let conditionValue = sugg.value
					let conditionOperator = sugg.operator

					const rootQueryNode = new QueryNode('$all')
					const secondRootNode = new QueryNode('$all')
					const conditionNode = new QueryNode(
						'$condition',
						'',
						conditionKey,
						conditionOperator,
						conditionValue,
					)
					rootQueryNode.addChild(secondRootNode)
					secondRootNode.addChild(conditionNode)
					const instanceQueryBuilder = new QueryBuilder(rootQueryNode, 50, 0, 'firstname', 'asc')
					/// Little hack here, just set advanced search
					this.actionSetAdvancedSearch(instanceQueryBuilder)

					/// And just display the 'classic filter' tag
					this.addTag(sugg)
					return
				}
				else {
					this.search = sugg.value
					if (this.simpleDisplay) this.showContactsListHandle(true)
					this.addTag(sugg)
				}
			}
		},

		blur() {
			nextTick(() => {
				setTimeout(() => {
					this.showAutocompletion = false
				}, 250)
			})
		},

		selectNext() {
			if (this.autocomplete && this.suggestions.length) {
				this.selectedIndex = (this.selectedIndex + 1) % this.suggestions.length
			}
		},

		selectPrev() {
			if (this.autocomplete && this.suggestions.length) {
				this.selectedIndex
					= this.selectedIndex === 0 ? this.suggestions.length - 1 : this.selectedIndex - 1
			}
		},

		showAutocomplete() {
			if (this.autocomplete) {
				const boundBox = this.$refs.searchBar.getBoundingClientRect()
				this.showAutocompletion = !!this.suggestions.length
				nextTick(() => {
					if (this.$refs.autocompleteProps) {
						this.$refs.autocompleteProps.style.width = `${boundBox.width}px`
						this.$refs.autocompleteProps.style.top = `${boundBox.top + boundBox.height + 10}px`
						this.$refs.autocompleteProps.style.left = `${boundBox.left}px`
					}
				})
			}
		},

		/**
		 * This function will give the array provided
		 * to display the actuals suggestions provided
		 * by the backend
		 */
		renderSuggestions(array) {
			this.suggestions = array
			this.loading = false
			this.showAutocompletion = !!this.suggestions.length
		},

		callback(tag) {
			if (tag.clickCallback && tag.clickCallback instanceof Function) {
				tag.clickCallback()
			}
			else {
				this.search = tag.label
				this.computedTags.splice(this.tags.indexOf(tag), 1)
				this.$emit('change', this.tags)
			}
		},

		removeTag(tag) {
			// if (tag.clickCallback && tag.clickCallback instanceof Function) {
			// 	tag.clickCallback()
			// } else {
			this.computedTags.splice(this.tags.indexOf(tag), 1)
			this.$emit('change', this.tags)
			// }
		},
	},
}
</script>
