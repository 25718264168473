<template>
	<div v-if="edition" :style="`width: ${width}`">
		<p class="text-sm text-pink-main">{{ description }}</p>
		<validation-field
			v-if="!$route.query.mail || !$route.query.code"
			ref="field"
			v-slot="{ field, errors, meta }"
			v-model="computedValue"
			:name="name"
			:rules="rules"
		>
			<el-input
				v-bind="field"
				v-model="computedValue"
				:placeholder="inputPlaceholder"
				class="mt-1 editable-label-input"
			></el-input>
			<div
				:class="{ invisible: !errors.length || !meta.touched }"
				class="text-sm text-gray-dark"
				style="height: 1.1rem"
			>
				{{ errors[0] }}
			</div>
		</validation-field>
	</div>
	<div v-else>
		<slot></slot>
	</div>
</template>

<script>
import { Field as ValidationField } from 'vee-validate'
import '/src/utils/veeValidate'

export default {
	components: { ValidationField },
	props: {
		value: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: false,
			default: '',
		},
		rules: {
			type: [String, Object],
			required: false,
			default: '',
		},
		edition: {
			type: Boolean,
			required: true,
		},
		placeholder: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		width: {
			type: String,
			default: '500px',
		},
	},
	emits: ['update:value'],
	data() {
		return {
			inputPlaceholder: '',
			inputLabel: '',
		}
	},
	computed: {
		computedValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('update:value', value)
			},
		},
	},
	mounted() {
		this.inputPlaceholder = this.placeholder || this.description
	},
}
</script>

<style lang="scss">
.editable-label-input.el-input input.el-input__inner {
	border-radius: 10px;
}
</style>
