<template>
	<sidebar class="phone-sidebar no-print" :primary="false">
		<template #sidebar-content>
			<div class="phone-container">
				<div class="sidebar-control-container">
					<div
						v-if="showPreview"
						class="show-help-container sidebar-control"
						@click="showPreview = false"
					>
						<i class="fa fa-arrow-left show-arrow"></i>
						<div class="spacing"></div>
						<div class="sidebar-control-text">{{ t.openHelp }}</div>
					</div>
					<div v-else class="show-preview-container sidebar-control" @click="showPreview = true">
						<div class="sidebar-control-text">{{ t.hideHelp }}</div>
						<div class="spacing"></div>
						<i class="fa fa-arrow-right show-arrow"></i>
					</div>
					<div class="barrier"></div>
				</div>
				<div v-if="showPreview" class="phone-centered-content">
					<img class="phone-img" src="../../../static/device-grey.png" alt="Preview" />
					<div class="phone-content-container">
						<div class="phone-content">
							<form-viewer
								:title="title"
								:sections="sections"
								:options="phonePreviewOptions"
							></form-viewer>
						</div>
					</div>
				</div>
				<div v-else class="help-container">
					<div class="help-title">{{ t.survey }}</div>
					<div class="help-sub-header">
						<div class="help-sub-header-p">{{ t.subHeader1 }}</div>
						<div class="help-sub-header-p">{{ t.subHeader2 }}</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.readme.title }}</div>
						<div class="card-content">
							{{ t.readme.content1
							}}<a href="mailto:support@qomon.com?subject=Survey">support@qomon.com </a
							>{{ t.readme.content2 }}
						</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.goodPractice.title }}</div>
						<div class="card-content">
							{{ t.goodPractice.content1 }}<br />{{ t.goodPractice.content2 }}
							<li>{{ t.goodPractice.content3 }}</li>
							<li>{{ t.goodPractice.content4 }}</li>
							<li>{{ t.goodPractice.content5 }}</li>
							<li>{{ t.goodPractice.content6 }}</li>
							<li>{{ t.goodPractice.content7 }}</li>
						</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.autoMail.title }}</div>
						<div class="card-content">{{ t.autoMail.content }}</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.organization.title }}</div>
						<div class="card-content">{{ t.organization.content }}</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.editForm.title }}</div>
						<div class="card-content">{{ t.editForm.content }}</div>
					</div>
					<div class="card">
						<div class="card-title">{{ t.noDigitalTeam.title }}</div>
						<div class="card-content">{{ t.noDigitalTeam.content }}</div>
					</div>
				</div>
			</div>
		</template>
	</sidebar>
</template>

<script>
export default {
	name: 'FormPhoneSidebar',

	components: {
		sidebar: require('../structure-module/sidebar.vue'),
		'form-viewer': require('./form-viewer.vue'),
	},

	props: ['title', 'sections'],

	data: function () {
		return {
			showPreview: true,

			phonePreviewOptions: {
				optionsDisplay: 'rows',
			},
		}
	},

	computed: {
		t() {
			const prefix = 'FORM.SIDEBAR'
			return {
				survey: this.$t(`${prefix}.SURVEY`),
				openHelp: this.$t(`${prefix}.OPEN_HELP`),
				hideHelp: this.$t(`${prefix}.HIDE_HELP`),
				subHeader1: this.$t(`${prefix}.SUB_HEADER1`),
				subHeader2: this.$t(`${prefix}.SUB_HEADER2`),
				readme: {
					title: this.$t(`${prefix}.README.TITLE`),
					content1: this.$t(`${prefix}.README.CONTENT1`),
					content2: this.$t(`${prefix}.README.CONTENT2`),
				},
				goodPractice: {
					title: this.$t(`${prefix}.GOOD_PRACTICE.TITLE`),
					content1: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT1`),
					content2: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT2`),
					content3: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT3`),
					content4: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT4`),
					content5: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT5`),
					content6: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT6`),
					content7: this.$t(`${prefix}.GOOD_PRACTICE.CONTENT7`),
				},
				autoMail: {
					title: this.$t(`${prefix}.AUTO_MAIL.TITLE`),
					content: this.$t(`${prefix}.AUTO_MAIL.CONTENT`),
				},
				organization: {
					title: this.$t(`${prefix}.ORGANIZATION.TITLE`),
					content: this.$t(`${prefix}.ORGANIZATION.CONTENT`),
				},
				editForm: {
					title: this.$t(`${prefix}.EDIT_FORM.TITLE`),
					content: this.$t(`${prefix}.EDIT_FORM.CONTENT`),
				},
				noDigitalTeam: {
					title: this.$t(`${prefix}.NO_DIGITAL_TEAM.TITLE`),
					content: this.$t(`${prefix}.NO_DIGITAL_TEAM.CONTENT`),
				},
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/form/form-sidebar.scss';
</style>
