import forIn from 'lodash/forIn'
import set from 'lodash/set'
import store from '../stateStore'
import CONSTANTS from '../../constants'
import {
	RESET_PACK_STORE,
	SET_LOCALE_SPECIFIC,
	SET_PACK_CUSTOMER_PACK_RIGHTS_INSTANCE,
} from './_mutation-types'
import CustomerPackRights from '@quorumsco/quorum-helpers/lib/pack/customerPackRights'

const getDefaultState = () => {
	return {
		getLocaleSpecific: null,
		customerPackRights: null,
	}
}
const state = getDefaultState

export const mutations = {
	[RESET_PACK_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_LOCALE_SPECIFIC]: (state, locale) => {
		state.getLocaleSpecific = locale
	},
	[SET_PACK_CUSTOMER_PACK_RIGHTS_INSTANCE]: (state, payload) => {
		state.customerPackRights = payload
	},
}

export const actions = {
	resetState: ({ commit }) => {
		commit(RESET_PACK_STORE)
	},
	actionFetchLocaleSpecific: ({ commit }) => {
		commit(SET_LOCALE_SPECIFIC, store.getters['info_campagne'].package_name)
	},
	actionSetCustomerPackRightsInstance: ({ commit, state }, payload) => {
		commit(SET_PACK_CUSTOMER_PACK_RIGHTS_INSTANCE, new CustomerPackRights(payload))
		/**
		 * Add a config and modify the global constants
		 * to filter the array of enabled or disabled actions typologies
		 * CONSTANTS.ACTION.TYPES
		 */
		forIn(CONSTANTS.ACTION.ACTUAL_TYPES, (value, key) => {
			const statusAction = state?.customerPackRights?.statusActionTypology(value)
			switch (statusAction) {
				case 'enabled': {
					CONSTANTS.ACTION.TYPES[key] = value
					if (key in CONSTANTS.ACTION.ACTUAL_ONLINE_TYPES)
						set(CONSTANTS.ACTION.ACTUAL_ONLINE_TYPES, `${key}.label`, value)
					if (key in CONSTANTS.ACTION.ACTUAL_OFFLINE_TYPES)
						set(CONSTANTS.ACTION.OFFLINE_TYPES, `${key}.label`, value)
					break
				}

				default: {
					delete CONSTANTS.ACTION.TYPES[key]
					delete CONSTANTS.ACTION.ONLINE_TYPES[key]
					delete CONSTANTS.ACTION.OFFLINE_TYPES[key]
					break
				}
			}
		})
	},
}

export const getters = {
	getLocaleSpecific: (state) => state.getLocaleSpecific,
	getCustomerPackRights: (state) => state.customerPackRights,
	isEnabledAnalyticsDashboardTab: (state) =>
		state.customerPackRights?.statusAnalyticsDashboardTab() === 'enabled',
	getStatusNumberStatusForms: (state) => state.customerPackRights?.statusNumberStatusForms(),
	getStatusTypeStatusForms: (state) => state.customerPackRights?.statusTypeStatusForms(),
	getStatusSurveyDashboard: (state) => state.customerPackRights?.statusWebDashboardSurvey(),
	getStatusSurveyAccessAdmin: (state) =>
		state.customerPackRights?.statusWebDashboardSurveyAccessAdmin(),
	getIsWebImportContactsFeatureEnabled: (state) =>
		state.customerPackRights?.statusWebImportContacts() === 'enabled',
	getIsEnabledSearchAdvanced: (state) =>
		state.customerPackRights?.statusAdvancedSearch() === 'enabled',
	// Main components
	getAccessStatusActions: (state) => state.customerPackRights?.statusWebAccessActions(),
	getAccessStatusAnalytics: (state) => state.customerPackRights?.statusWebAccessAnalytics(),
	getAccessStatusTasks: (state) => state.customerPackRights?.statusWebAccessTasks(),
	getAccessStatusDocuments: (state) => state.customerPackRights?.statusWebAccessDocuments(),
	getAccessStatusSurvey: (state) => state.customerPackRights?.statusWebAccessSurvey(),
	getAccessStatusMembersTeams: (state) => state.customerPackRights?.statusWebAccessMembersTeams(),
	getAccessStatusMembersInvitations: (state) =>
		state.customerPackRights?.statusWebAccessMembersInvitations() || 'enabled',
	getDisplayStatusWebLabelsMembersBadges: (state) =>
		state.customerPackRights?.statusDisplayLabelsMembersBadges(),
	getAccessStatusSurveyAutoEmail: (state) =>
		state.customerPackRights?.statusWebAccessSurveyAutoEmail(),
	getAccessStatusSurveyAutoSms: (state) => state.customerPackRights?.statusWebAccessSurveyAutoSms(),
	// Settings
	getSettingsStatusMobileContactsNearby: (state) =>
		state.customerPackRights?.statusWebSettingsMobileContactsNearby(),
	getAccessStatusCommunication: (state) => state.customerPackRights?.statusWebAccessCommunication(),
	getAccessStatusMessagesSMS: (state) =>
		state.customerPackRights?.statusWebAccessMessagesSMS() || 'enabled',
	getAccessStatusMMS: (state) => state.customerPackRights?.statusWebAccessMMS(),
	getAccessStatusMessagesEmail: (state) =>
		state.customerPackRights?.statusWebAccessMessagesEmail() || 'enabled',
	getAccessStatusGlobalNotification: (state) =>
		state.customerPackRights?.statusWebAccessGlobalNotification(),
	getAccessStatusSurveyContactDetail: (state) =>
		state.customerPackRights?.statusWebAccessSurveyContactDetail(),
	getAccessStatusFormsTypologiesQRCode: (state) =>
		state.customerPackRights?.statusFormsTypologiesQRCode(state),
	getAccessStatusMailCreationDisplayOptionOnlyStreetsUnknow: (state) =>
		state.customerPackRights?.statusMailCreationDisplayOptionOnlyStreetsUnknow(),
	getAccessStatusExportContacts: (state) =>
		state.customerPackRights?.statusWebAccessExportContacts(),
	getAccessStatusMassiveDeleteContacts: (state) =>
		state.customerPackRights?.statusWebMassiveDeleteContacts(),
	getAccessStatusManagementDuplicatesContacts: (state) =>
		state.customerPackRights?.statusWebManagementDuplicatesContacts(),
	getAccessCartographyDataProfiles: (state) =>
		state.customerPackRights?.statusWebCartographyAccessDataProfiles(),
	getAccessPetitions: (state) => state.customerPackRights?.statusWebAccessPetitions(),
	getAccessOnlineForms: (state) => state.customerPackRights?.statusWebAccessOnlineForms(),
	getAccessDataInsights: (state) => state.customerPackRights?.statusWebAccessDataInsights(),
	getAccessDataProfile: (state) =>
		state.customerPackRights?.statusWebCartographyAccessDataProfiles(),
	getAccessCustomFields: (state) => state.customerPackRights?.statusWebAccessCustomFields(),
	getAccessPetitionCustomization: (state) =>
		state.customerPackRights?.statusCustomisationPetition(),
	getAccessRoles: (state) => state.customerPackRights?.statusWebAccessRoles(),
	getAccessDonationsMemberships: (state) =>
		state.customerPackRights?.statusWebAccessDonationsMemberships(),
	getAccessBatch: (state) => state.customerPackRights?.statusWebAccessBatchs(),
	getAccessMobilePersonalization: (state) =>
		state.customerPackRights?.statusWebAccessMobilePersonalization(),
	getAccessMobileQuickActionAddContact: (state) =>
		state.customerPackRights?.statusMobileQuickActionAddContact(),
	getAccessConsentLaunchSurvey: (state) => state.customerPackRights?.statusConsentLaunchSurvey(),
	getAccessDisplayComeBackAndRefusalForms: (state) =>
		state.customerPackRights?.statusDisplayComeBackAndRefusalForms(),
	getAccessMobileBlacklistOnRefusal: (state) =>
		state.customerPackRights?.statusMobileBlacklistOnRefusal(),
	getAccessHomeRedirectionAfterAnsweredSurvey: (state) =>
		state.customerPackRights?.statusHomeRedirectionAfterAnsweredSurvey(),
	getAccessAnonymousSurvey: (state) => state.customerPackRights?.statusAnonymousSurvey(),
	getAccessMandates: (state) => state.customerPackRights?.statusWebAccessMandates(),
	getAccessMobileQuickActionSurvey: (state) =>
		state.customerPackRights?.statusMobileQuickActionSurvey(),
	getAccessWebSegmentsLimitation: (state) =>
		state.customerPackRights?.statusWebAccessSegmentsLimitation(),
	getAccessStatusActionv2: () => 'enabled',
	getAccessSanctions: (state) => state.customerPackRights?.statusWebAccessTransactionsSanctions(),
	getAccessAutoCompletions: (state) => state.customerPackRights?.statusWebAutocompleteAddressField(),
	getAccessStatusBelongsToGreenpeaceSpace: (state) => {
		return state.customerPackRights?.statusActionsV2()
	},
	getAccessMembersInvitationsWithRole: (state) => state.customerPackRights?.statusWebAccessMembersInvitationsWithRoles(),
	getAccessAssignementMassiveSelection: (state) => state.customerPackRights?.statusWebAccessAssignementMassiveSelection(),
	getAccessAssignementAI: (state) => state.customerPackRights?.statusWebAccessAssignementAI(),
	getAccessAssignementAllUsers: (state) => state.customerPackRights?.statusWebAccessAssignementAllUsers(),
	getAccessAssignementOpen: (state) => state.customerPackRights?.statusWebAccessAssignementOpen(),
	getAccessCustomBorderLayer: (state) => state.customerPackRights?.statusWebAccessCustomBorderLayer(),
	getAccessImportsContactsConsent: (state) => state.customerPackRights?.statusWebAccessImportsContactsConsents(),
	getAccessStatusSendersDistribution: (state) =>
		state.customerPackRights?.statusWebAccessSendersDistribution(),
	getAccessAdvancedFiltersModifiers: (state) => state.customerPackRights?.statusWebAccessAdvancedFiltersModifiers(),
}

export const packsModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
