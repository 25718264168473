export const getActionIconByTypology = (typology: string): { icon: string, size: string } => {
	switch (typology) {
		case 'canvassing':
			return { icon: 'gui-door', size: 'text-2xl' }
		case 'gotvcanvassing':
			return { icon: 'gui-yes_vote', size: 'text-2xl' }
		case 'gotvcalling':
			return { icon: 'gui-yes_vote', size: 'text-2xl' }
		case 'call':
			return { icon: 'gui-phone', size: 'text-3xl' }
		case 'reporting':
			return { icon: 'gui-action', size: 'text-xl' }
		case 'event':
			return { icon: 'gui-event', size: 'text-3xl' }
		case 'event_online':
			return { icon: 'gui-camera_p', size: 'text-2xl' }
		case 'leaflet':
			return { icon: 'gui-handout_mail', size: 'text-xl' }
		case 'poster':
			return { icon: 'gui-handout_mail', size: 'text-xl' }
		case 'challenge':
			return { icon: 'gui-cup', size: 'text-2xl' }
		case 'mail':
			return { icon: 'gui-handout_mail', size: 'text-2xl' }
		case 'static':
			return { icon: 'gui-cup', size: 'text-2xl' }
		case 'other':
			return { icon: 'gui-flash', size: 'text-xl' }
		case 'sharing':
			return { icon: 'gui-answer', size: 'text-2xl' }
		default:
			return { icon: 'gui-action', size: 'text-xl' }
	}
}
