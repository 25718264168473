import Rainbow from 'rainbowvis.js'

const myRainbow = new Rainbow().setSpectrum(
	'8255F5',
	'34A6FB',
	'0F949A',
	'00D19C',
	'DDFB34',
	'FF4270',
	'F6AA3A',
	'EE564A'
)

export function getColorById(id = -1) {
	const x = Math.sin(id++) * 10000
	return myRainbow.colourAt((x - Math.floor(x)) * 100)
}
