var cryptoJS = require('crypto-js')
import { METABASE } from '../../constants'
import store from '../../store/stateStore'

function base64url(source) {
	// Encode in classical base64
	var encodedSource = cryptoJS.enc.Base64.stringify(source)

	// Remove padding equal characters
	encodedSource = encodedSource.replace(/=+$/, '')

	// Replace characters according to base64url specifications
	encodedSource = encodedSource.replace(/\+/g, '-')
	encodedSource = encodedSource.replace(/\//g, '_')

	return encodedSource
}

export const metabaseCrypto = {
	methods: {
		load(extiframe) {
			if (extiframe) {
				extiframe.style.visibility = 'visible'
				extiframe.contentWindow.document.getElementsByClassName('EmbedFrame-footer')
			}
		},

		base64url,

		generateURL(payload, type, secretKey) {
			if (!secretKey) return METABASE.URL + '/embed/' + type + '/' + '#bordered=false&titled=false'

			var header = {
				alg: 'HS256',
				typ: 'JWT',
			}

			var stringifiedHeader = cryptoJS.enc.Utf8.parse(JSON.stringify(header))
			var encodedHeader = base64url(stringifiedHeader)
			var stringifiedData = cryptoJS.enc.Utf8.parse(JSON.stringify(payload))
			var encodedData = base64url(stringifiedData)
			var token = encodedHeader + '.' + encodedData

			var signature = cryptoJS.HmacSHA256(token, secretKey)
			signature = base64url(signature)
			var signedToken = token + '.' + signature
			var iframeUrlMetabase =
				METABASE.URL + '/embed/' + type + '/' + signedToken + '#bordered=false&titled=false'
			/*+'?randon='+Math.random()
				.toString()
				.replace('.', '') // not sure if the Math.random is needed*/
			store.dispatch('actionToggleLoadingState', false)
			return iframeUrlMetabase
		},
	},
}
