import { $request } from './requester'

export default {
	async getPrivacyPolicy() {
		return $request('GET', '/privacy-policy').then((res) => {
			return res.body.data
		})
	},

	async postPrivacyPolicy(data) {
		return $request('POST', '/privacy-policy', data).then((res) => res.body.data)
	},
}
