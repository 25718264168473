<template>
	<div class="h-full">
		<div
			class="w-full bg-white px-7 py-5 z-40 menu-shadow flex flex-col items-start justify-start gap-5"
		>
			<div class="flex items-center">
				<span
					v-for="(tab, index) in computedGetTabs"
					:key="index"
				>
					<TabWithNumber
						v-if="tab.settingsCtaDisplayed"
						:title="tab.title"
						:selected="tab.selected"
						:status-pack="tab.status"
						:badge="tab.badge"
						:tag="tab.tag"
						:is-soon="tab.isSoon"
						:no-opacity="tab.noOpacity"
						font-size="1.25rem"
						class="mr-10 cursor-pointer"
						@tab-click="methodGoTo(tab)"
					/>
				</span>
			</div>
			<span>{{ $t('COMMUNICATIONS.REPORTS_INFO') }}</span>
			<div
				v-if="$route.name === 'automatic-email-dashboard'"
				class="flex items-center gap-2 text-gray-strong"
			>
				<i class="gui-infos text-2xl" />
				<span> {{ $t('COMMUNICATIONS.REPORTS_INFO_EMAIL_CAMPAIGN') }} </span>
			</div>
		</div>
		<RouterView />
	</div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const TabWithNumber = defineAsyncComponent(() => import('../general/tabWithNumber.vue'))
const { t } = useI18n()
const router = useRouter()
const store = useStore()

const computedGetIsMessageBirdEnabled = computed(() => store.getters['getIsMessageBirdEnabled'])

const computedGetTabs = computed(() => {
	return [
		{
			title: t('COMMUNICATIONS.MAIL_AUTO_HEADER'),
			name: 'automatic-email-dashboard',
			status: 'enabled',
			settingsCtaDisplayed: true,
			noOpacity: true,
			badge: 'premium',
			settingsRoute: 'autosend-settings',
			selected: router.currentRoute.value.name === 'automatic-email-dashboard',
		},
		{
			title: t('COMMUNCATIONS.HEADER.SMS'),
			name: 'communications-sms-dashboard',
			status: 'enabled',
			settingsCtaDisplayed: computedGetIsMessageBirdEnabled.value,
			settingsRoute: 'sms',
			selected:
				router.currentRoute.value.name === 'communications-sms'
				|| router.currentRoute.value.name === 'communications-sms-dashboard'
				|| router.currentRoute.value.name === 'communications-sms-analytics',
		},
	].sort((a, b) => {
		return ('' + a.status).localeCompare(b.status)
	})
})

const methodGoTo = (route) => {
	const { name } = route
	router.push({ name })
}
</script>

<style lang="scss" scoped>
.menu-shadow {
	box-shadow: rgba(251, 248, 246, 1) 0px 3px 5px;
}
</style>
