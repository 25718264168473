<template>
	<div v-if="getLoginMode === 'auth0'">
		<LoginKennedy
			:version="computedGetVersionApp"
		/>
	</div>
	<div
		v-else-if="getLoginMode === 'oauth2'"
		id="login-vue"
		ref="login-vue"
		class="relative w-full h-full flex justify-center items-center"
	>
		<div
			v-if="showSplashScreen"
			class="notSupportBrowser"
		>
			<div class="content">
				<a :href="computedMainUrlWebsite"> <img src="/static/images/qomon/black.svg"></a>
				<div class="title">
					{{ $t('APP.BROWSER_SUPPORT.TITLE') }}
				</div>
				<p v-dompurify-html="$t('APP.BROWSER_SUPPORT.SENTENCE')" />
			</div>
		</div>

		<div
			v-else
			class="container"
		>
			<div class="flex flex-col justify-center items-center gap-3 pb-10">
				<div class="logo-container">
					<a
						class="relative"
						:href="computedMainUrlWebsite"
					>
						<img
							src="/static/images/qomon/black.svg"
							alt="Qomon Logo Black"
						>
						<div
							v-if="isBeta"
							class="absolute -top-5 -right-14 w-fit px-2 py-0.5 bg-green-main text-white bold rounded-md"
						>
							{{ $t('SETTINGS.MODE.BETA') }}
						</div>
					</a>
				</div>
				<span
					v-if="isBeta"
					v-html="$t('APP.LOGIN.SUBTITLE_BETA')"
					class="text-sm text-gray-dark"
				/>
				<div
					v-else
					class="user-action-2 mb-1"
				>
					<router-link
						class="text-sm text-gray-dark"
						:to="{ name: 'create-account' }"
					>
						<span> {{ $t('APP.LOGIN.NO_ACCOUNT') }}</span>
						<span class="signup-text underline ml-1">{{ $t('APP.LOGIN.SIGNUP') }}</span>
					</router-link>
				</div>
			</div>
			<form
				class="w-[320px] max-w-[320px] flex flex-col justify-center items-center "
				@submit.prevent="!connecting && actionLogin()"
			>
				<div class="w-full flex flex-col justify-center items-center gap-1.5 form-signin">
					<PublicInput
						:data-test-id="'signin-email-input'"
						v-model:modelValue="username"
						:title="$t('APP.RECOVER.PLACEHOLDERS.EMAIL')"
						:placeholder="$t('APP.LOGIN.EMAIL_PLACEHOLDER')"
						:enter-event="actionLogin"
						:name="'email'"
						:type="'email'"
						:label="$t('_COMMON.MAIL')"
						:auto-complete="'username'"
						:auto-focus="true"
					/>
					<PublicInput
						:data-test-id="'signin-password-input'"
						v-model:modelValue="password"
						:title="$t('APP.RECOVER.PLACEHOLDERS.PASSWORD')"
						:placeholder="$t('APP.LOGIN.PASSWORD_PLACEHOLDER')"
						:enter-event="actionLogin"
						:name="'password'"
						:type="togglePasswordOrDefaultInherited"
						:label="$t('APP.LOGIN.PASSWORD')"
						:required="true"
						:auto-complete="'current-password'"
					>
						<template #right-slot>
							<span
								class="show-password-login"
								:class="{'text-gray-300 pointer-events-none': !password || !password?.length}"
								@click="showPassword = !showPassword"
							>
								<i
									class="text-lg transition-all"
									:class="showPassword ? 'gui-eye_open' : 'gui-eye_dashed'"
								/>
							</span>
						</template>
					</PublicInput>
				</div>
				<div class="w-full flex justify-start">
					<router-link
						class="text-sm text-pink-main transition-all hover:underline pl-2 pt-1"
						:to="{ name: 'recover', query: { username } }"
					>
						{{ $t('APP.LOGIN.FORGOT_PASSWORD') }}
					</router-link>
				</div>
				<button-loader class="w-full mt-8 mb-5">
					<q-button
						data-test-id="signin-button"
						class="login-btn overflow-visible w-full big-vertical-padding"
						:class="{ disabled: connecting, load: connecting, 'pointer-events-none': connecting }"
						type="submit"
						:disabled="connecting || !username || !password"
						autofocus
						@click="actionLogin"
					>
						<span v-if="!connecting">{{ $t('APP.LOGIN.START') }} →</span><span v-else>{{ $t('APP.LOGIN.LOGGING_IN') }}</span>
					</q-button>
				</button-loader>
				<q-check
					v-model="rememberMe"
					class="w-fit center text-gray-dark"
				>
					{{ $t('APP.LOGIN.REMEMBER_ME') }}
				</q-check>
			</form>
			<!-- <div class="max-w-[320px] user-actions">
				<div class="user-action-1">
					<router-link
						class="action"
						:to="{ name: 'recover', query: { username } }"
					>
						{{ $t('APP.LOGIN.FORGOT_PASSWORD') }}
					</router-link>
				</div>
			</div> -->
			<div class="opacity-0 select-none">
				Konami code 😉
			</div>

			<div
				v-if="allowSwitcherAuth0"
				class="text-gray-500 text-center cursor-pointer hover:opacity-75 text-xs"
				@click="actionSetLoginMode('auth0')"
			>
				Login with kennedy24.com
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '../../store/stateStore.js'
import analytics, { TRACKING } from '../../external/analytics'
import { encrypt, decrypt } from '../../middlewares/cryptoScript'
import { clearStore, setStore, getStore } from '../../middlewares/storage'
import { isSupportedBrowser } from '../../middlewares/supportedBrowser'
import constants from 'src/constants'
import { defineAsyncComponent } from 'vue'

const REMEMBER_ME = 'REMEMBER_ME'
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Login',
	store: store,
	editable: {
		default: true,
		type: Boolean,
	},
	components: {
		LoginKennedy: defineAsyncComponent(() => import('./customers/kennedy/LoginKennedy.vue')),
		ButtonLoader: defineAsyncComponent(() => import('../general/button-loader.vue')),
		PublicInput: defineAsyncComponent(() => import('../general/PublicInput.vue')),
	},
	data() {
		return {
			// AUTH METHODS
			allowSwitcherAuth0: false,

			// OTHERS
			type: 'password',
			showPassword: false,
			connecting: false,
			username: null,
			password: null,
			userConnected: {
				username: null,
				password: null,
				connecting: false,
			},
			rememberMe: false,
			showSplashScreen: false,
			isBeta: false,
		}
	},

	props: {
		version: {
			type: String,
			required: true,
		},
	},

	computed: {
		...mapGetters(['info_campagne']),
		...mapGetters({ vuexPacksGetLocaleSpecific: '@packs/getLocaleSpecific' }),
		...mapGetters('@authentication', ['getStatusAuth', 'getLoginMode']),

		togglePasswordOrDefaultInherited() {
			if (this.showPassword) {
				return 'text'
			}
			else {
				return this.type
			}
		},

		computedMainUrlWebsite() {
			return constants.MAIN_URL_WEBSITE
		},

		computedGetVersionApp() {
			return `v${this.version}`
		},
	},
	watch: {
		getStatusAuth(value) {
			switch (value) {
				case 'success':
					if (this.$refs['login-vue'].clientWidth < 768) {
						this.$router.push({ name: 'contacts-analyse' })
					}
					else this.$router.push({ name: 'command-center' })

					this.actionResetAuthenticationModule()
					analytics.track(TRACKING.SIGN_IN, null, this.info_campagne.id)
					break

				case 'failed':
					this.connecting = false
					this.$message({
						message: this.$t('APP.LOGIN.ERRORS.FAILED'),
						showClose: true,
						type: 'error',
						duration: 7000,
					})
					break

				case 'nopermission':
					this.connecting = false
					this.$router.push({ name: 'download' })
					break

				case 'emailnotvalid':
					this.connecting = false
					this.actionSetCredentialsEntered({ email: this.username, password: this.password })
					this.$router.push({ name: 'validate-email' })
					break

				case 'nospacejoined':
					this.connecting = false
					this.actionSetCredentialsEntered({ email: this.username, password: this.password })
					this.$router.push({ name: 'join-a-group' })
					break

				case 'error':
					this.connecting = false
					this.$message({
						message: this.$t('APP.LOGIN.ERRORS.ERROR'),
						showClose: true,
						type: 'error',
						duration: 7000,
					})
					break

				default:
					break
			}
		},
	},
	beforeMount() {
		this.actionSetLoginMode(AUTH_PROVIDER || 'oauth2')
		if (AUTH_PROVIDER === 'auth0') {
			this.allowSwitcherAuth0 = true
		}
	},
	created() {
		this.connecting = false
		if (!isSupportedBrowser) {
			console.warn('browser not supported')
			this.showSplashScreen = true
		}
		else {
			this.initRememberMe()
		}
	},

	methods: {
		...mapActions('@authentication', [
			'actionLoginApplication',
			'actionResetAuthenticationModule',
			'actionSetCredentialsEntered',
			'actionSetLoginMode',
		]),

		actionLogin() {
			if (!this.password || !this.username) return
			this.connecting = true

			this.userConnected = {
				password: this.password,
				username: this.username,
			}

			this.connecting = true

			if (
				this.userConnected.username != ''
				&& this.userConnected.password != ''
				&& this.userConnected.username
				&& this.userConnected.password
			) {
				this.actionLoginApplication(this.userConnected)
			}
		},
		initRememberMe() {
			const rememberMe = getStore(REMEMBER_ME) || null
			if (rememberMe) {
				this.username = rememberMe.username
				this.password = decrypt(rememberMe.password)
				this.rememberMe = true
			}
		},
		checkRememberMe(login) {
			if (this.rememberMe) {
				const saveLogin = {
					username: login.username,
					password: encrypt(login.password),
				}
				setStore(REMEMBER_ME, saveLogin)
			}
			else {
				clearStore(REMEMBER_ME)
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/dequorum/style/variables.sass';
@import '../../assets/scss/app/login.scss';
@import '../../assets/scss/general/text-input-border.scss';
</style>
