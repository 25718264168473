<template>
	<action-creation-helper-container
		less-padding-at-top-and-bottom
		:show-absolute-sidebar="showCoversList"
		:header-height="headerHeight"
	>
		<div class="h-full w-full flex items-center justify-center relative">
			<div class="flex flex-col items-center justify-center rounded-2xl w-full xs:w-10/12 sm:w-8/12 md:w-5/12 2xl:w-5/12 gap-10">
				<div class="flex w-full justify-start items-center">
					<div class="flex w-full justify-start items-center gap-4">
						<div class="w-11 h-11 rounded-full flex items-center justify-center bg-gray-100">
							<i class="gui-door text-2xl" />
						</div>
						<span class="font-title text-3xl">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.TITLE_V2') }}</span>
					</div>
				</div>

				<div class="w-full flex flex-col justify-start items-start gap-2">
					<label
						class="text-pink-strong"
						for="action_title"
					>{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.ACTION_TITLE') }} *</label>
					<el-input
						type="text"
						ref="actionTitleInput"
						v-model="fromVuexTitleAction"
						:maxlength="maxLengthTitleAction"
						:placeholder="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.ACTION_TITLE_PLACEHOLDER')"
						show-word-limit
					/>
				</div>
				<div
					v-if="computedIsActionV2Enabled"
					class="flex flex-col justify-start items-start w-full gap-3"
				>
					<div class="w-full flex flex-col justify-center items-center">
						<div
							class="relative overflow-hidden rounded-t-[20px] flex w-full"
							:class="{'p-2': fromVuexCoverImgAction?.cover_img?.url === '' || !fromVuexCoverImgAction?.cover_img?.url}"
						>
							<FormCover
								ref="action_img_cover"
								id="action_img_cover"
								v-model:form="fromVuexCoverImgAction"
								:is-editing="true"
								:height-ratio-banner="fromVuexCoverImgAction?.cover_img?.url !== '' ? 1.77 : 2"
								:form-type="'action'"
								:cover-position-style="'relative'"
								:background-is-dark="false"
							/>
						</div>

						<div
							class="w-full flex justify-center items-center gap-5 pt-10"
							:class="{'pl-2': fromVuexCoverImgAction?.cover_img?.url === '' || !fromVuexCoverImgAction?.cover_img?.url}"
						>
							<q-button
								@click="methodShowCoversList"
								class="basic-black"
							>
								{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.ACTION_COVER_DEFAULT_CTA') }}
							</q-button>
							<span>{{ $t('CONTACT.FILTERS.OPERATORS.OR') }}</span>
							<q-button
								@click="$refs.action_img_cover.methodHandleImageUploader()"
								class="basic-black"
							>
								{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.ACTION_COVER_IMPORT_CTA') }}
							</q-button>
						</div>
					</div>
				</div>

				<el-divider
					v-if="!computedIsSharingType"
					class="my-0"
				/>
				<div
					class="flex w-full fields flex-col justify-center items-start gap-8"
				>
					<div
						v-if="!computedIsSharingType"
						class="flex flex-col w-full gap-2"
					>
						<label
							class="text-pink-strong"
							for="action_comment"
						>
							{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.COMMENT_LABEL') }}
						</label>
						<el-input
							id="action_comment"
							v-model="fromVuexCommentAction"
							type="textarea"
							rows="3"
							:placeholder="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.COMMENT')"
						/>
					</div>

					<div
						v-if="
							info_campagne?.global_cause &&
								(newAction.type === ACTION.TYPES.EVENT || newAction.type === ACTION.TYPES.SHARING)
						"
						class="border-top flex flex-col justify-center items-center w-full"
					>
						<ToggleActionPublic v-model:public-action="fromVuexPublicAction" />
					</div>
				</div>
			</div>
		</div>
		<template #absolute-sidebar-content>
			<div class="flex flex-col gap-5">
				<div class="flex items-center justify-between flex-wrap">
					<span class="font-title text-xl">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.ACTION_COVERS_LIST', {action_type: actionTypeLabel() }) }}</span>
					<div
						@click="showCoversList = !showCoversList"
						class="cursor-pointer flex items-center hover:bg-gray-10 pr-3"
					>
						<i class="gui-close text-xl" />
					</div>
				</div>
				<div class="grid grid-cols-2 gap-4">
					<div
						v-for="(aCover, index) in computedGetCoversByTypology"
						:key="index"
						@mouseover="hoveredIndex = index"
						@mouseleave="hoveredIndex = null"
						@click="methodSetDefaultCover(aCover, index + 1)"
						class="flex justify-start items-start gap-2 transform transition-all hover:scale-95 cursor-pointer"
					>
						<div class="relative">
							<img
								class="aspect-video rounded-xl transform"
								:src="aCover"
								alt="a banner"
							>
						</div>
					</div>
				</div>
			</div>
		</template>
	</action-creation-helper-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypology } from 'src/mixins'
import { defineAsyncComponent } from 'vue'
import { ACTION } from 'src/constants'
import { getCoversByActionType } from '../../../../utils/ActionCovers.ts'

export default {
	components: {
		ActionCreationHelperContainer: defineAsyncComponent(
			() => import('../ActionCreationHelperContainer'),
		),
		ToggleActionPublic: defineAsyncComponent(
			() => import('../../utilsPublicAction/toggleActionPublic.vue'),
		),
		FormCover: defineAsyncComponent(() => import('../../../create-form-components/formCover.vue')),
	},
	mixins: [actionTypology],

	emits: ['update:nextValidatorBoolean', 'update:errorMessage'],

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
		actionTypeLabel: {
			type: Function,
			default: () => '',
		},
	},

	data() {
		return {
			ACTION,
			maxLengthTitleAction: 70,
			showCoversList: false,
			hoveredIndex: null,
			a: 0,
			b: 0,
		}
	},
	computed: {
		...mapGetters('@action', ['newAction']),
		...mapGetters('@packs', ['getAccessStatusActionv2']),
		...mapGetters(['info_campagne']),
		computedIsTitleCompleted() {
			return this.fromVuexTitleAction ? Boolean(this.fromVuexTitleAction?.length) : false
		},

		fromVuexTitleAction: {
			get() {
				const { title } = this.newAction
				return title
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'title', value })
			},
		},

		fromVuexPublicAction: {
			get() {
				const { publicAction } = this.newAction

				return publicAction
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'publicAction', value })
			},
		},

		fromVuexCoverImgAction: {
			get() {
				return this.newAction
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'cover_img', value })
			},
		},

		fromVuexCommentAction: {
			get() {
				const { comment } = this.newAction
				return comment
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'comment', value })
			},
		},

		computedGetValidator() {
			const isTitleCompleted = this.computedIsTitleCompleted

			if (this.newAction.publicAction) {
				this.actionSetStepForPublicActions()
			}
			else {
				this.actionSetNewActionType(this.newAction.type)
			}

			return isTitleCompleted
		},

		computedErrorMessage() {
			return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.AIM_NEEDED')
		},

		computedActionTypeLabel() {
			const { type } = this.newAction

			if (type) return this.$t(`ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.${type.toUpperCase()}`)

			return ''
		},

		computedIsActionV2Enabled() {
			return this.getAccessStatusActionv2 === 'enabled'
		},

		computedGetCoversByTypology() {
			const { type } = this.newAction
			return getCoversByActionType(type)
		},

		computedIsADefaultCover() {
			if (!this.fromVuexCoverImgAction?.cover_img?.url || !this.fromVuexCoverImgAction?.cover_img?.url?.length) return
			return this.fromVuexCoverImgAction?.cover_img?.url.includes('/static/images')
		},

		computedIsSharingType() {
			return this.newAction.type === ACTION.TYPES.SHARING
		},
	},

	watch: {
		computedGetValidator(newValue, oldValue) {
			this.$emit('update:nextValidatorBoolean', newValue)
			if (oldValue === true && newValue === false) this.$emit('update:errorMessage', this.computedErrorMessage)
		},

	},

	mounted() {
		this.actionSetStep('NAME_SCHEDULE')
		this.$emit('update:nextValidatorBoolean', this.computedGetValidator)
		this.$emit('update:errorMessage', this.computedErrorMessage)
		if (!this.fromVuexCoverImgAction?.cover_img?.url) {
			this.methodSetDefaultCover(this.computedGetCoversByTypology[0], 1)
		}
		this.methosSetFocusOnTitle()
	},

	methods: {
		...mapActions('@action', [
			'actionSetStep',
			'actionEditNewActionObject',
			'actionSetStepForPublicActions',
			'actionSetNewActionType',
		]),

		methosSetFocusOnTitle() {
			if (!this.$refs?.actionTitleInput) {
				setTimeout(() => {
					this.methosSetFocusOnTitle()
				}, 300)
				return
			}
			this.$nextTick(() => {
				this.$refs.actionTitleInput.focus()
			})
		},

		methodShowCoversList() {
			this.showCoversList = !this.showCoversList
		},

		methodSetDefaultCover(coverUrl, index) {
			this.actionEditNewActionObject(
				{
					key: 'cover_img',
					value: {
						background_size_ratio: null,
						cover_img_x: null,
						cover_img_y: null,
						url: coverUrl,
						index: index,
					},
				},
			)
		},
	},
}
</script>

<style lang="sass" scoped>
.border-top
	@apply pt-4
	margin-top: 2rem
	border-top: 1px solid #e4e4e4
.subtitle
	@apply text-sm text-gray-500 mb-4

.custom-message-bloc
	@apply border-pink-main

.custom-message-bloc:hover
	color: white
	transition: 0.5s
	@apply bg-pink-main

	.custom-q-link-hover
		color: white
		text-decoration: underline

.custom-q-link-hover
		text-decoration: none!important

</style>
