<template>
	<div>
		<div :class="['bubbleSoon', customTailwindsClass]">
			<p class="text-gray-700">
				<i
					v-if="!hideIcon"
					class="gui-stars bold text-md"
				/>
				{{ soonText ? soonText : $t('_COMMON.IS_SOON') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		soonText: {
			type: String,
			default: null,
		},

		customTailwindsClass: {
			type: String,
			default: null,
		},
		hideIcon: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},

	computed: {},
	mounted() {},
}
</script>

<style></style>
<style lang="sass" scoped>

.bubbleSoon
    @apply rounded-md text-white text-xs px-2 py-1
.bottom-right
    bottom: 0
    top: auto
    right: 0
    left: auto
.bottom-left
    bottom: 0
    left: 0
    right: auto
    top: auto
.top-left
    top: 0
    bottom: auto
    left: 0
    right: auto
.top-right
    top: 0
    right: 0
    bottom: auto
    left: auto
</style>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';
.bubbleSoon {
	background-color: #ffe383;
}
</style>
