import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex w-full justify-start items-center gap-4" }
const _hoisted_2 = { class: "font-title text-3xl" }
const _hoisted_3 = { class: "text-gray-strong" }
const _hoisted_4 = { class: "flex flex-col gap-4" }
const _hoisted_5 = {
  key: 0,
  class: "ml-1 flex items-center gap-x-8"
}
const _hoisted_6 = { class: "flex flex-row gap-x-2 items-center" }
const _hoisted_7 = { class: "text-gray-strong" }
const _hoisted_8 = ["title"]
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col gap-y-2"
}
const _hoisted_10 = { class: "flex flex-row gap-x-3 items-center" }
const _hoisted_11 = { class: "flex flex-col w-full gap-y-10" }
const _hoisted_12 = { class: "flex flex-col gap-2" }
const _hoisted_13 = { class: "ml-1 text-pink-strong" }
const _hoisted_14 = { class: "flex flex-row gap-x-2 items-center" }
const _hoisted_15 = { class: "flex flex-col gap-2 pb-24" }
const _hoisted_16 = { class: "ml-1 text-pink-strong" }
const _hoisted_17 = { class: "flex flex-row gap-x-2 items-center" }

import { initMapBackgroundTiles } from '@/extensions/maplibregl/init_map.ts'
import { SatelliteControl } from '@/extensions/maplibregl/satellite_control.ts'
import { latLngIsInNorthPole, wrapLatLng } from '@/utils/geo'
import * as DOMPurify from 'dompurify'
import { getBrightnessFromColor } from '@/utils/colors'
import commandStore from '@/models/command_store'
import { getGeoContextFromInfos } from '@/store/modules/command-center'
import { setMeetingPointMarker, removeMeetingPointMarkers } from '../../../../extensions/maplibregl/meeting_point_handlers.ts'
import geoCodingRequester from '@/requesters/geoCodingRequester'

import { methodSetDefaultPolygonBasedOnGeoHashContacts, adjustZoomAndView } from '../../../../extensions/maplibregl/members_handlers.ts'
import { bbox } from '@turf/turf'

import maplibregl from 'maplibre-gl'
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick, defineAsyncComponent } from 'vue'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

import round from 'lodash/round'
import debounce from 'lodash/debounce'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionCreationMeetingPoint',
  props: /*@__PURE__*/_mergeModels({
    address: {},
    fromEdition: { type: Boolean },
    allUniqUsersWithLocation: {},
    actionDetailMapData: {}
  }, {
    "valid": {},
    "validModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['saved'], ["update:valid"]),
  setup(__props: any, { emit: __emit }) {

const dataGeoRootAlias = computed(() => store.getters.dataGeoRootAlias)
const computedGeohashArray = computed(() => store.getters['geohash_array_clean'])
const info_campagne = computed(() => store.getters.info_campagne)
const newActionPolygon = ref(null)

const AutoCompletionSearchBar = defineAsyncComponent(() => import('../../../auto-completion/AutoCompletionSearchBar.vue'))

const methodHandleEmptyState = () => {
	autoCompleteAddressDialogVisible.value = false
}

const formatAddress = ({ houseNumber = '', street = '', postalCode = '', city = '', country = '' }) => {
	// Combine houseNumber and street without a comma
	const addressLine = [houseNumber, street].filter(Boolean).join(' ')

	// Combine postalCode and city without a comma
	const postalCity = [postalCode, city].filter(Boolean).join(' ')

	// Combine all fields with commas as needed
	return [addressLine, postalCity, country].filter(Boolean).join(', ')
}

const props = __props

let geoRootInfos = ref(null)

const query = ref('')
const locationName = ref('')
const locationAdditional = ref('')
const results = ref([])
const loading = ref(false)
const allMarkersInstances = ref([])
const addressSelected = ref(null)
const mapInstance = ref<maplibregl.Map | null>(null)
const mapFullyLoaded = ref(false)
const autocomplete = ref<HTMLElement>(null)
const memberMarkers = ref<maplibregl.Marker[]>([])
const autoCompleteAddressDialogVisible = ref(false)
const store = useStore()
const { locale, t } = useI18n()

const emit = __emit

const valid = _useModel(__props, 'valid')

const getURLBaseMap = computed(() => store.getters['@group/getURLBaseMap'])
const getURLSatelliteMap = computed(() => store.getters['@group/getURLSatelliteMap'])
const getAllUsers = computed(() => props.allUniqUsersWithLocation)
const wrapLngRange = computed(() => store.getters['wrapLngRange'])

const computedAddressSelected = computed(() => addressSelected.value)

const computedGeoContext = computed(() =>
	getGeoContextFromInfos(
		dataGeoRootAlias.value,
		info_campagne.value,
	),
)

const computedAuthorizedScalesWithExtras = computed(() => {
	const { data_parent_territories_scale, data_map_contours_scale, data_authorized_scales }
    = info_campagne.value
	// make sure to get the translations to all the scales we want to handle
	const out = new Set(data_authorized_scales)

	out.add(data_parent_territories_scale)
	out.add(data_map_contours_scale)

	return out
})

const scrollToBottom = () => {
	const container = document.getElementById('action-meeting-point')
	if (!container) return
	container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
}

const getAddressFormated = computed(() => {
	if (!computedAddressSelected.value) {
		return ''
	}
	const houseNumber = get(computedAddressSelected.value, 'housenumber', '')
	const street = get(computedAddressSelected.value, 'street', '')
	const postalCode = get(computedAddressSelected.value, 'postalcode', '')
	const city = get(computedAddressSelected.value, 'city', '')
	const country = get(computedAddressSelected.value, 'country', '')
	return formatAddress({ houseNumber, street, postalCode, city, country })
})

watch(getAddressFormated, (value) => {
	if (value) {
		nextTick(() => {
			scrollToBottom()
		})
	}
})
watch(mapFullyLoaded, (value) => {
	if (value) {
		nextTick(() => {
			if (newActionPolygon.value && !isEmpty(newActionPolygon.value && mapInstance.value)) {
				adjustZoomAndView(mapInstance.value, newActionPolygon.value, () => {})
			}
		})
	}
})

watch(geoRootInfos, (value) => {
	if (value) {
		commandStore
			.getBoundingBox({
				geo_context: computedGeoContext.value.asApiGeoContextPayload(),
			})
			.then((res) => {
				const bounds = new maplibregl.LngLatBounds()
				bounds.extend(bbox(res))
				let camera = mapInstance.value.cameraForBounds(bounds, {
					padding: {
						top: 100,
						left: 100,
						bottom: 100,
						right: 100,
					},
					offset: [0, 0],
				})
				const options = {
					center: camera.center,
					zoom: camera.zoom,
				}
				mapInstance.value.jumpTo(options)
			})
	}
})

const getAddressLatLng = computed(() => {
	if (!addressSelected.value) {
		return null
	}
	const getLat = round(get(addressSelected.value, 'latitude'), 6)
	const getLng = round(get(addressSelected.value, 'longitude'), 6)

	return `${getLat}, ${getLng}`
})

const fetchData = async (query: string, cb: any) => {
	if (!query) {
		results.value = []
		cb([])
		return
	}

	loading.value = true

	try {
		const response = await fetch(`/api/search/addresses?query=${query}&withPhone=false&unknow=true`)
		const data = await response.json()

		if (data.length === 0 || !data || isEmpty(data.length)) {
			cb([{ isEmpty: true }])
		}

		if (data.length === 1) {
			if (isEmpty(data[0])) {
				cb([{ isEmpty: true }])
			}
			else {
				const getFirstOccurence = get(data, '[0].subentities', [])
				results.value = getFirstOccurence
				cb(getFirstOccurence)
			}
		}
		else {
			const occurrences = data.map((item: any) => item.subentities)
			const flattenOccurrences = occurrences.flat()
			results.value = flattenOccurrences
			const filteredFlattendOccurences = flattenOccurrences.filter((item: any) => item?.location?.coordinates.lat && item?.location?.coordinates?.lng)

			// cb(filteredFlattendOccurences)
			if (filteredFlattendOccurences.length === 0 || !filteredFlattendOccurences || !filteredFlattendOccurences.length) {
				cb([{ isEmpty: true }])
			}
			else {
				cb(filteredFlattendOccurences)
			}
		}
	}
	catch (error) {
		console.error('Error fetching data:', error)
		cb([{ isEmpty: true }])
	}
	finally {
		loading.value = false
	}
}

watch(locationName, debounce(() => {
	valid.value = !!locationName.value
}, 1000))

onMounted(async () => {
	const storePolygon = store.getters['@action/getNewActionPolygon']
	const { polygon } = props.actionDetailMapData || []

	if (!get(props.address, 'latitude') || !get(props.address, 'longitude') && !allMarkersInstances.value?.length) {
		newActionPolygon.value = !isEmpty(polygon) && JSON.parse(polygon).length
			? JSON.parse(polygon)
			: !isEmpty(storePolygon)
					? storePolygon
					: methodSetDefaultPolygonBasedOnGeoHashContacts('kilometers', computedGeohashArray.value, false)
	}

	if (!get(props.address, 'latitude') || !get(props.address, 'longitude')) {
		if (!newActionPolygon.value || isEmpty(newActionPolygon.value)) {
			await commandStore
				.getGeoRootInfo({
					geoRootAlias: dataGeoRootAlias.value,
					preferredLanguageLocale: locale.value,
					authorizedScales: [...computedAuthorizedScalesWithExtras.value].toSorted(),
				}).then((newGeoRootInfos) => {
					commandStore.setGeoRootInfo(newGeoRootInfos)
					geoRootInfos.value = newGeoRootInfos
				})
		}
	}
})

onMounted(() => {
	valid.value = false

	// MapBoxDraw
	setTimeout(() => {
		const map = new maplibregl.Map({
			container: 'container',
			center: [-35, 46],
			zoom: 0.88,
			dragRotate: false,
		})

		map.on('click', handleMapClick)

		map.addControl(new maplibregl.NavigationControl({ showCompass: false }), 'bottom-right')
		let coords = null

		// If we have prev coordinates, we center the map on it
		if (get(props.address, 'latitude') && get(props.address, 'longitude')) {
			const latitude = get(props.address, 'latitude')
			const longitude = get(props.address, 'longitude')
			const houseNumber = get(props.address, 'housenumber', '')
			const street = get(props.address, 'street', '')
			const postalCode = get(props.address, 'postalcode', '')
			const city = get(props.address, 'city', '')
			const country = get(props.address, 'country', '')
			const infos = get(props.address, 'infos', '')
			const addition = get(props.address, 'addition', '')

			addressSelected.value = {
				housenumber: houseNumber,
				street,
				postalcode: postalCode,
				city,
				country,
			}

			if (addition) {
				locationAdditional.value = addition
			}

			if (latitude && longitude) {
				addressSelected.value.latitude = Number(latitude)
				addressSelected.value.longitude = Number(longitude)
			}

			locationName.value = formatAddress({ houseNumber, street, postalCode, city, country })

			map.jumpTo({
				center: [longitude, latitude],
				zoom: 15,
			})
		}

		if (getURLBaseMap.value !== getURLSatelliteMap.value) {
			map.addControl(new SatelliteControl(), 'bottom-right')
		}

		initMapBackgroundTiles(map, {
			lightTilesUrl: getURLBaseMap.value.replace('{s}', 'a'),
			satelliteTilesUrl: getURLSatelliteMap.value,
		})

		mapInstance.value = map

		if (mapInstance.value && addressSelected?.value?.longitude && addressSelected?.value?.latitude) {
			coords = {
				latitude: addressSelected.value.latitude,
				longitude: addressSelected.value.longitude,
			}
			setMeetingPointMarker(mapInstance.value, coords, allMarkersInstances.value)
		}

		addMembersToMap(getAllUsers.value, wrapLngRange.value)
		mapFullyLoaded.value = true
	}, 200)
})

onBeforeUnmount(() => {
	if (locationName.value !== getAddressFormated.value) {
		set(computedAddressSelected.value, 'infos', locationName.value)
	}
	if (locationAdditional.value) {
		set(computedAddressSelected.value, 'addition', locationAdditional.value)
	}

	emit('saved', computedAddressSelected.value)
})

const handleSelect = (item: any) => {
	// allMarkersInstances.value = []

	removeMeetingPointMarkers(allMarkersInstances.value)
	const latitude = get(item, 'latitude')
	const longitude = get(item, 'longitude')

	const coords = {
		latitude,
		longitude,
	}

	mapInstance.value?.jumpTo({
		center: [longitude, latitude],
		zoom: 15,
	})

	if (mapInstance.value) {
		setMeetingPointMarker(mapInstance.value, coords, allMarkersInstances.value)
	}

	addressSelected.value = item
	locationName.value = getAddressFormated.value
	valid.value = true
}

const formatFirstLine = (item: any) => {
	const houseNumber = get(item, 'housenumber', '')
	const street = get(item, 'street', '')
	return `${houseNumber} ${street}`.trim()
}

const formatSecondLine = (item: any) => {
	const postalCode = get(item, 'postalcode', '')
	const city = get(item, 'city', '')
	const country = get(item, 'country', '')
	return `${postalCode} ${city}, ${country}`.trim()
}

const removeAddress = () => {
	addressSelected.value = null
	removeMeetingPointMarkers(allMarkersInstances.value)
	valid.value = false
	locationName.value = ''
}

const handleMapClick = async (e: maplibregl.MapMouseEvent) => {
	if (computedAddressSelected.value) {
		const roundedLng = round(Number(e.lngLat.lng), 6)
		const roundedLat = round(Number(e.lngLat.lat), 6)
		const reverseGeoCodingResult = await geoCodingRequester.getReverseGeoCoding(roundedLat, roundedLng)
		// Vérifier si le clic est sur un marqueur de membre
		const target = e.originalEvent.target as HTMLElement
		if (target.closest('.maplibregl-marker')) {
		// Le clic est sur un marqueur, ne rien faire
			return
		}
		// Le clic est sur la carte, ajouter un nouveau point de rencontre
		if (allMarkersInstances.value.length > 0 && reverseGeoCodingResult.address) {
			removeMeetingPointMarkers(allMarkersInstances.value)
		}

		// const roundedLng = round(Number(e.lngLat.lng), 6)
		// const roundedLat = round(Number(e.lngLat.lat), 6)
		// const reverseGeoCodingResult = await geoCodingRequester.getReverseGeoCoding(roundedLat, roundedLng)

		const coords = {
			latitude: roundedLat,
			longitude: roundedLng,
		}

		if (mapInstance.value && reverseGeoCodingResult.address) {
			setMeetingPointMarker(mapInstance.value, coords, allMarkersInstances.value)
		}
		if (reverseGeoCodingResult.address) {
			console.log('reverseGeoCodingResult', reverseGeoCodingResult)
			addressSelected.value = reverseGeoCodingResult.address
		}

		locationName.value = getAddressFormated.value
	}
}

const addMembersToMap = (members, wrapLngRange) => {
	if (members && members?.length) {
		members.forEach((member) => {
			if (member.location) {
				const [lat, long] = member.location.split(',').map(parseFloat)
				if (!latLngIsInNorthPole(lat, long)) {
					const [finalLat, finalLong] = wrapLatLng(lat, long, wrapLngRange)
					let popup = `
					<span class="font-bold text-lg">${DOMPurify.sanitize(member.firstname)} ${DOMPurify.sanitize(
						member.surname,
					)}</span><br>
				`
					if (member.mail) {
						popup += `<br><span class="text-base"><i class="qi-email"></i> ${DOMPurify.sanitize(
							member.mail,
						)} </span>`
					}
					if (member.phone) {
						popup += `<br><span class="text-base"><i class="qi-phone"></i> ${DOMPurify.sanitize(
							member.phone,
						)} </span>`
					}

					let avatarHtml
					if (member.avatar && member.avatar !== 'null') {
						avatarHtml = `<img src="${member.avatar}" /></div>
						`
					}
					else {
						const innerHtml
							= member.firstname && member.surname
								? `<span>${member.firstname.toUpperCase().split('')[0]}${
										member.surname.toUpperCase().split('')[0]
								  }</span>`
								: `<div v-else class="qi-profile-stroke qi-2x"></div>`

						avatarHtml = `
						<div class="avatar-default ${
							getBrightnessFromColor(member.role_data.color) === 0 ? 'text-black' : 'text-white'
						}" style="background-color:${member.role_data.color}">
							${innerHtml}
						</div>
						`
					}

					const el = document.createElement('div')
					el.className = 'maplibregl-command-center-marker-wrapper'
					el.innerHTML = `
						<div class="maplibregl-command-center-marker-icon-tip"></div>
						<div class="maplibregl-command-center-marker-icon-wrapper">
							<div class="avatar">
								${avatarHtml}
							</div>
						</div>
                    `

					const marker = new maplibregl.Marker({ element: el })
						.setLngLat([finalLong, finalLat])
						.setPopup(
							new maplibregl.Popup({
								closeButton: false,
								className: 'maplibregl-command-center-marker-popup',
								offset: [0, -55],
							}).setHTML(popup),
						)

					marker.addTo(mapInstance.value)
					memberMarkers.value.push(marker)

					el.addEventListener('click', marker.togglePopup)
				}
			}
		})
	}
	return
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: "action-meeting-point",
    class: _normalizeClass(["h-full w-full flex justify-center relative pt-10 overflow-auto", {'px-8': _ctx.fromEdition}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col rounded-2xl w-full xs:w-10/12 gap-6", {
				'sm:w-8/12': !_ctx.fromEdition,
				'md:w-5/12': !_ctx.fromEdition,
				'2xl:w-5/12': !_ctx.fromEdition,
			}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-11 h-11 rounded-full flex items-center justify-center bg-gray-100" }, [
          _createElementVNode("i", { class: "gui-pin text-2xl" })
        ], -1)),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.TITLE')), 1)
      ]),
      _createVNode(_unref(AutoCompletionSearchBar), {
        "hight-accuracy": "",
        "select-method": handleSelect,
        "handle-empty-state-method": methodHandleEmptyState,
        "search-bar-style": ''
      }),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.DESCRIPTION')), 1),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex relative z-1 w-full" }, [
          _createElementVNode("div", { class: "flex relative justify-center w-full" }, [
            _createElementVNode("div", {
              id: "container",
              class: "size-map"
            })
          ])
        ], -1)),
        (getAddressLatLng.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "gui-pin text-2xl text-black bold" }, null, -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.LOCALIZATION')) + " " + _toDisplayString(getAddressLatLng.value), 1)
              ]),
              _createElementVNode("span", {
                onClick: removeAddress,
                title: _unref(t)('_COMMON.DELETE'),
                class: "hover:opacity-75 flex gap-x-2 items-center cursor-pointer leading-3 text-black text-xs"
              }, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "gui-delete" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_unref(t)('_COMMON.DELETE')), 1)
              ], 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true)
      ]),
      (getAddressFormated.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.PLACE_LABEL')), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_el_input, {
                      size: "large",
                      placeholder: _unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.PLACE_PLACEHOLDER'),
                      modelValue: locationName.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationName).value = $event)),
                      maxlength: "120",
                      "show-word-limit": ""
                    }, null, 8, ["placeholder", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.ACCESS_DETAILS')), 1),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_el_input, {
                      size: "large",
                      placeholder: _unref(t)('ACTION.CALL_TO_ACTION.INFORMATIONS.PLACE.ACCESS_DETAILS_PLACEHOLDER'),
                      modelValue: locationAdditional.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((locationAdditional).value = $event)),
                      maxlength: "300",
                      type: "textarea",
                      "show-word-limit": ""
                    }, null, 8, ["placeholder", "modelValue"])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})