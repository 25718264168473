import tags_store from '../../models/tags_store'
import { SET_TAGS, SET_IS_LOADING, RESET_TAGS_STORE } from './_mutation-types'

const getDefaultState = () => {
	return {
		tags: [],
		isLoading: false,
	}
}
const state = getDefaultState

const mutations = {
	[SET_TAGS]: (state, payload) => {
		state.tags = payload
	},
	[SET_IS_LOADING]: (state, payload) => {
		state.isLoading = payload
	},
	[RESET_TAGS_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
}
const actions = {
	async actionGetTags({ commit }) {
		commit(SET_IS_LOADING, true)
		return await tags_store
			.getTags()
			.then((res) => {
				commit(SET_TAGS, res || [])
				commit(SET_IS_LOADING, false)
				return {}
			})
			.catch((e) => {
				console.error(e)
				commit(SET_IS_LOADING, false)
				return { type: 'error', action: 'get' }
			})
	},
	async actionAddTag({ commit, dispatch }, payload) {
		var result = { action: 'add' }
		commit(SET_IS_LOADING, true)
		await tags_store
			.addTag(payload)
			.then(() => {
				result.type = 'success'
			})
			.catch((e) => {
				console.error(e)
				result.type = 'error'
			})
		dispatch('actionGetTags')
		return result
	},
	async actionDeleteTag({ commit, dispatch }, payload) {
		var result = { action: 'delete' }
		commit(SET_IS_LOADING, true)
		await tags_store
			.deleteTag(payload)
			.then(() => {
				result.type = 'success'
			})
			.catch((e) => {
				console.error(e)
				result.type = 'error'
			})
		dispatch('actionGetTags')
		return result
	},

	async actionDeleteTags({ commit, dispatch }, payload) {
		var result = { action: 'deleteSeveral', type: 'success', number: payload.length }
		commit(SET_IS_LOADING, true)
		await tags_store
			.deleteTag(payload)
			.then(() => {
				result.type = 'success'
			})
			.catch((error) => {
				console.error(error)
				result.type = 'error'
			})
		dispatch('actionGetTags')
		return result
	},

	async actionRenameTag({ commit, dispatch }, payload) {
		const { originalTag, temporaryTag } = payload
		var result = { action: 'rename' }
		commit(SET_IS_LOADING, true)
		await tags_store
			.updateTag(originalTag, temporaryTag)
			.then(() => {
				result.type = 'success'
			})
			.catch((e) => {
				console.error(e)
				result.type = 'error'
			})
		dispatch('actionGetTags')
		return result
	},

	actionResetStateTags({ commit }) {
		commit(RESET_TAGS_STORE)
	},
}
const getters = {
	getTags: (state) => state.tags,
	getIsLoading: (state) => state.isLoading,
}
export const tagsModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
