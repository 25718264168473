import _ from 'lodash'
import contact_store from '../models/contact_store.js'

const array = [
	{
		id: 75,
		group_id: 1,
		CreatedAt: '2024-02-27T11:05:50.42848Z',
		transaction_bundle_id: 545073,
		target_id: 545101,
		kind: 'transaction',
		new: {
			date: '2024-02-13T23:00:00Z',
			batch: null,
			amount: 1000,
			user_id: 912,
			batch_id: 39,
			currency: 'eur',
			contact_id: 3699327,
			code_campaign: '1234',
			unpaid_amount: 0,
			payment_method: {
				chequeNumber: 'ssss',
			},
			reimbursed_amount: 0,
			payment_method_kind: 'CHE',
			transaction_bundle_id: 545073,
		},
		old: {
			date: '2024-02-19T10:55:27.419Z',
			batch: null,
			amount: 1000,
			user_id: 912,
			batch_id: 39,
			currency: 'eur',
			contact_id: 3699327,
			code_campaign: 'Aurélien',
			unpaid_amount: 0,
			payment_method: {
				chequeNumber: 'sssss',
			},
			reimbursed_amount: 0,
			payment_method_kind: 'CHE',
			transaction_bundle_id: 545073,
		},
	},
	{
		id: 74,
		group_id: 1,
		CreatedAt: '2024-02-27T09:57:01.966579Z',
		transaction_bundle_id: 545073,
		target_id: 545101,
		kind: 'transaction_status',
		new: {
			comment: '',
			motif_id: null,
			status_id: 9,
			comment_date: null,
			delivered_at: null,
			delivery_token: '',
			comment_user_id: null,
		},
		old: {
			comment: '',
			motif_id: null,
			status_id: 1,
			comment_date: null,
			delivered_at: null,
			delivery_token: '',
			comment_user_id: null,
		},
	},
	{
		id: 73,
		group_id: 1,
		CreatedAt: '2024-02-27T09:06:59.948819Z',
		transaction_bundle_id: 545073,
		target_id: 422585,
		kind: 'membership',
		new: {
			amount: 1000,
			currency: 'eur',
			end_date: '2025-01-01T00:00:00Z',
			contact_id: 3708665,
			start_date: '2024-01-01T00:00:00Z',
			membership_price_id: 3,
			transaction_bundle_id: 545073,
		},
		old: null,
	},
	{
		id: 72,
		group_id: 1,
		CreatedAt: '2024-02-27T09:06:59.926998Z',
		transaction_bundle_id: 545073,
		target_id: 545101,
		kind: 'transaction',
		new: {
			date: '2024-02-19T10:55:27.419Z',
			batch: null,
			amount: 1000,
			user_id: 912,
			batch_id: 39,
			currency: 'eur',
			contact_id: 3699327,
			code_campaign: 'Aurélien',
			unpaid_amount: 0,
			payment_method: {
				chequeNumber: 'sssss',
			},
			reimbursed_amount: 0,
			payment_method_kind: 'CHE',
			transaction_bundle_id: 545073,
		},
		old: {
			date: '2024-02-19T10:55:27.419Z',
			batch: null,
			amount: 100000,
			user_id: 15315,
			batch_id: 39,
			currency: 'eur',
			contact_id: 3699327,
			code_campaign: 'Sylvain',
			unpaid_amount: 0,
			payment_method: {
				chequeNumber: 'sssss',
			},
			reimbursed_amount: 0,
			payment_method_kind: 'CHE',
			transaction_bundle_id: 545073,
		},
	},
]

export const historyTransformer = async (historyItems) => {
	const arrayItems = []
	for (const item of historyItems) {
		// console.log('FOR EACH START ---------------------------------------------------')
		let contactToAddToItems = null
		if (item?.new?.contact_id || item?.old?.contact_id) {
			const contactId = item.new?.contact_id ? item.new?.contact_id : item.old?.contact_id
			await contact_store.fetchLightContacts([contactId]).then((response) => {
				if (response) {
					let contact = response[0]
					contactToAddToItems = {
						id: contact?.id,
						firstname: contact?.firstname ? contact.firstname : null,
						surname: contact?.surname ? contact.surname : null,
					}
				}
			})
		}
		if (item.new && item.old) {
			if (contactToAddToItems) {
				item.new['contact'] = contactToAddToItems
				item.old['contact'] = contactToAddToItems
			}
			if (item.kind === 'transaction') {
				_.keys(item.new).forEach((key) => {
					const valueNew = _.get(item.new, key)
					const valueOld = _.get(item.old, key)

					if (typeof valueNew === 'object' && typeof valueOld === 'object') {
						const keys = _.keys(valueNew) || _.keys(valueOld)

						keys.forEach((subkey) => {
							if (!_.isEqual(valueNew[subkey], valueOld[subkey])) {
								arrayItems.push({
									historyDate: item.CreatedAt,
									user: item.user,
									status: 'updated',
									kind: item.kind,
									type: subkey,
									new: valueNew[subkey],
									old: valueOld[subkey],
								})
							}
						})
					}
					else {
						if (!_.isEqual(valueNew, valueOld)) {
							arrayItems.push({
								historyDate: item.CreatedAt,
								user: item.user,
								status: 'updated',
								kind: item.kind,
								type: key,
								new: valueNew,
								old: valueOld,
							})
						}
					}
				})
			}
			else {
				arrayItems.push({
					historyDate: item.CreatedAt,
					user: item.user,
					status: 'updated',
					kind: item.kind,
					new: item.new,
					old: item.old,
				})
			}
		}
		else if (item.new && !item.old) {
			if (contactToAddToItems) {
				item.new['contact'] = contactToAddToItems
			}
			arrayItems.push({
				historyDate: item.CreatedAt,
				user: item.user,
				status: 'created',
				kind: item.kind,
				new: item.new,
			})
		}
		else if (!item.new && item.old) {
			if (contactToAddToItems) {
				item.old['contact'] = contactToAddToItems
			}
			arrayItems.push({
				historyDate: item.CreatedAt,
				user: item.user,
				status: 'deleted',
				kind: item.kind,
				old: item.old,
			})
		}
		// console.log('FIN DE ---------------------------------------------------', item.kind)
	}

	/// Sort array by date
	const historyItemsSorted = arrayItems.sort((a, b) => {
		return new Date(b.historyDate).getTime() - new Date(a.historyDate).getTime()
	})

	/// Remove empty objects and unwanted items
	const finalArrayFiltered = historyItemsSorted.filter((historyItem) => {
		return (
			historyItem.type !== 'batch_id'
			&& historyItem.type !== 'user_id'
			&& historyItem.type !== 'currency'
			&& historyItem.type !== 'contact_id'
			&& Object.keys(historyItem).length > 0
		)
	})
	return finalArrayFiltered
}

// const result = historyTransformer(array)
