import { $request } from './requester'

export default {
	/**
	 * The ids needed to be an array of object with the key id
	 * like [ { id: 111111 }, { id: 222222 } ]
	 */
	async unlinkContacts(ids) {
		return $request('POST', `/links/unlink`, {
			contacts: ids,
		})
	},

	async linkContacts(payload) {
		return $request('POST', `/links`, payload)
	},
}
