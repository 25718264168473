// module for the state store for auto-send emails
import communication_store from './../../models/communication_store.js'

const state = {
	autosend_email_acc_id: -1,
	autosend_sender: '',
	autosend_subject: '',
	autosend_header: '',
	autosend_footer: '',
	autosend_cc: [],
	autosend_reply_to: '',
	autosend_gender_label_homme: '',
	autosend_gender_label_femme: '',
	autosend_gender_label_neutre: '',
	emailaccs: [],
}

const mutations = {
	SET_AUTOSEND_EMAIL_ACC_ID: (state, res) => {
		state.autosend_email_acc_id = res
	},
	SET_AUTOSEND_SENDER: (state, res) => {
		state.autosend_sender = res
	},
	SET_AUTOSEND_SUBJECT: (state, res) => {
		state.autosend_subject = res
	},
	SET_AUTOSEND_HEADER: (state, res) => {
		state.autosend_header = res
	},
	SET_AUTOSEND_FOOTER: (state, res) => {
		state.autosend_footer = res
	},
	SET_AUTOSEND_CC: (state, res) => {
		state.autosend_cc = res
	},
	SET_AUTOSEND_REPLY_TO: (state, res) => {
		state.autosend_reply_to = res
	},
	SET_AUTOSEND_GENDER_LABEL_HOMME: (state, res) => {
		state.autosend_gender_label_homme = res
	},
	SET_AUTOSEND_GENDER_LABEL_FEMME: (state, res) => {
		state.autosend_gender_label_femme = res
	},
	SET_AUTOSEND_GENDER_LABEL_NEUTRE: (state, res) => {
		state.autosend_gender_label_neutre = res
	},
	SET_EMAILACCS: (state, res) => {
		state.emailaccs = res
	},
}

const actions = {
	// NOTE: this should be called whenever the values are needed on a new page; they are not guaranteed to be correct when loading a new page
	SETUP_AUTOSEND: (context) => {
		communication_store.getAutosend().then((res) => {
			context.commit('SET_AUTOSEND_EMAIL_ACC_ID', res.emailacc_id)
			context.commit('SET_AUTOSEND_SUBJECT', res.subject)
			context.commit('SET_AUTOSEND_HEADER', res.header)
			context.commit('SET_AUTOSEND_FOOTER', res.footer)
			context.commit('SET_AUTOSEND_SENDER', res?.sender)
			context.commit('SET_AUTOSEND_CC', res?.cc)
			context.commit('SET_AUTOSEND_REPLY_TO', res?.reply_to)
			context.commit('SET_AUTOSEND_GENDER_LABEL_HOMME', res.gender_label_homme)
			context.commit('SET_AUTOSEND_GENDER_LABEL_FEMME', res.gender_label_femme)
			context.commit('SET_AUTOSEND_GENDER_LABEL_NEUTRE', res.gender_label_neutre)
		})
	},

	SAVE_AUTOSEND: (context, isMailjet) => {
		const header = context.getters.autosend_header
		const footer = context.getters.autosend_footer
		const sender = context.state.autosend_sender
		const cc = context.state.autosend_cc
		const reply_to = context.state.autosend_reply_to

		// Créez une regex pour correspondre à une balise <span> avec l'attribut data-id.
		const regex = /<span[^>]*data-id="([^"]+)"[^>]*>.*?<\/span>/g

		// Utilisez la fonction `replace` pour effectuer le remplacement.
		const modifiedHeader = header.replaceAll(regex, (match, dataId) => {
			return '$' + dataId
		})

		const modifiedFooter = footer.replaceAll(regex, (match, dataId) => {
			return '$' + dataId
		})

		const finalHeader = modifiedHeader.replaceAll('$deleted_field', '')
		const finalFooter = modifiedFooter.replaceAll('$deleted_field', '')

		var autosend = {
			emailacc_id: context.getters.autosend_email_acc_id,
			subject: context.getters.autosend_subject,
			header: finalHeader,
			footer: finalFooter,
			gender_label_homme: context.getters.autosend_gender_label_homme,
			gender_label_femme: context.getters.autosend_gender_label_femme,
			gender_label_neutre: context.getters.autosend_gender_label_neutre,
		}

		if (cc.length !== 0) autosend['cc'] = cc
		if (reply_to !== '') autosend['reply_to'] = reply_to
		if (sender !== '' || !isMailjet) autosend['sender'] = sender

		return new Promise((resolve, reject) => {
			communication_store
				.saveAutosend(autosend)
				.then(() => {
					context.dispatch('SETUP_AUTOSEND')
					resolve()
				})
				.catch(() => reject())
		})
	},

	GET_EMAILACCS: ({ commit }) => {
		communication_store.getEmailaccs().then((res) => {
			commit('SET_EMAILACCS', res)
		})
	},
}

const getters = {
	autosend_email_acc_id: (state) => state.autosend_email_acc_id,
	autosend_subject: (state) => state.autosend_subject,
	autosend_header: (state) => state.autosend_header,
	autosend_footer: (state) => state.autosend_footer,
	autosend_sender: (state) => state.autosend_sender,
	autosend_cc: (state) => state.autosend_cc,
	autosend_reply_to: (state) => state.autosend_reply_to,
	autosend_gender_label_homme: (state) => state.autosend_gender_label_homme,
	autosend_gender_label_femme: (state) => state.autosend_gender_label_femme,
	autosend_gender_label_neutre: (state) => state.autosend_gender_label_neutre,
	emailaccs: (state) => state.emailaccs,
}

// TODO - should initialize state.templates with API call

export const autosendModule = {
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
