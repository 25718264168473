import { acceptHMRUpdate, defineStore } from 'pinia'
import LotsModels from '@/requesters/batchs'
import { amountToFrontend } from '@/requesters/transactionSerializer'
import { methodIsAccessGranted } from '@/utils/RoleUtils'
import store from '../store/stateStore'
import { ElMessage } from 'element-plus'
import apiKey from '@/components/settings-module/extensions/apiKey.vue'
import { getCurrentTimezone } from '@/utils/dateTimeZone'

import { api } from '../../src/models/common'

export const useBatchsStore = defineStore('batchs-store', {
	state: () => ({
		batchStatus: undefined,
		batchId: null,
		batchs: [],
		currentOpenBatch: null,
		totalBatchs: 0,
		statusBatchsList: 'init',
		currentUserOpenenedBatches: null,

	}),
	actions: {
		async actionExportBatch(id: number, format = 'pdf') {
			const timezone = getCurrentTimezone()

			const url = `${api}/export/batch/${id}?format=${format}&tz=${timezone}`

			window.open(url, '_blank')
		},

		async actionRequestGetBatchs(limit = 50, offset = 0, t = () => {}) {
			this.statusBatchsList = 'loading'

			// await new Promise((resolve) => setTimeout(resolve, 5000))
			try {
				const response = await LotsModels.getBatchs(limit, offset)
				if (response?.data && response.data.length > 0) {
					response.data.forEach((aTransaction: TransactionItem) => {
						aTransaction.amount = amountToFrontend(aTransaction.amount)
					})
					this.batchs = this.actionFilterBatchesAccordingRoles(response.data)
					this.totalBatchs = response.total || 0
					this.statusBatchsList = 'success'
				}
			}
			catch (error) {
				this.statusBatchsList = 'error'
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
			}
		},
		async actionRequestUpdateBatch(
			id: number,
			data: any,
			t = () => {},
			updateBatchIntoBatchesList: boolean,
		) {
			try {
				const response = await LotsModels.patchBatch(id, data)

				this.actionGetUserCurrentOpenedBatches()

				if (updateBatchIntoBatchesList) {
					const index = this.batchs.findIndex((batch) => batch.id === id)

					if (index !== -1) {
						this.batchs[index] = response
					}
				}

				this.currentOpenBatch = response
			}
			catch (error) {
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
			}
		},

		async actionFetchCurrentBatch(t = () => {}) {
			this.batchStatus = 'init'

			try {
				const currentBatch = await LotsModels.getCurrentBatch()

				this.batchStatus = 'success'
			}
			catch (err) {
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
				this.batchStatus = 'error'
			}
		},

		async actionCreateBatch(t = () => {}) {
			this.batchId = null

			try {
				const createdBatch = await LotsModels.createNewBatch({})

				this.batchStatus = 'success'
				this.actionGetUserCurrentOpenedBatches()
				this.router.push({
					name: 'donation-and-membership-batch-by-id',
					params: { batchId: createdBatch.id },
				})
			}
			catch (err) {
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
				this.batchStatus = 'error'
			}
		},

		async actionFetchBatchById(batchId: string, data) {
			this.statusBatchsList = 'loading'
			this.batchStatus = 'loading'

			try {
				this.batchStatus = 'success'
				this.currentOpenBatch = await LotsModels.getBatchById(batchId, data)
				this.statusBatchsList = 'success'
			}
			catch (err) {
				this.statusBatchsList = 'error'
				this.batchStatus = 'error'
			}
		},

		async actionRequestSearchBatchs(data = {}, limit = 50, offset = 0, t = () => {}) {
			this.statusBatchsList = 'loading'

			// await new Promise((resolve) => setTimeout(resolve, 5000))
			try {
				const response = await LotsModels.postSearchBatchs(data, limit, offset)

				this.totalBatchs = response.total
				this.batchs = this.actionFilterBatchesAccordingRoles(response.data)
				this.statusBatchsList = 'success'
			}
			catch (error) {
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.DEFAULT'),
					type: 'error',
				})
				this.statusBatchsList = 'error'
			}
		},

		async actionCloseBatch(t = () => {}) {
			this.batchStatus = 'loading'

			try {
				this.currentOpenBatch = await LotsModels.patchBatch(this.getBatchId, { closed: true })
				this.actionGetUserCurrentOpenedBatches()
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.SUCCESS.CLOSING_BATCH'),
					type: 'success',
				})
			}
			catch (err) {
				this.batchStatus = 'error'
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.CLOSING_BATCH'),
					type: 'error',
				})
			}
		},

		async actionReopenBatch(t = () => {}) {
			this.batchStatus = 'loading'

			try {
				this.currentOpenBatch = await LotsModels.patchBatch(this.getBatchId, { closed: false })
				this.actionGetUserCurrentOpenedBatches()
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.SUCCESS.REOPENING_BATCH'),
					type: 'success',
				})
			}
			catch (err) {
				ElMessage({
					message: t('BATCH_BY_ID_VIEW.REQUEST_MESSAGE.ERRORS.REOPENING_BATCH'),
					type: 'error',
				})
				this.batchStatus = 'error'
			}
		},

		// TODO : Role management should be on back side so this will be just a security
		actionFilterBatchesAccordingRoles(batchesList = []) {
			// If the user don't have the role to see all the batches, show only his
			if (!methodIsAccessGranted('transaction_all:read')) {
				return batchesList.filter(
					(batch: any) => batch.user_id == store.getters.userConnected.id,
				)
			}

			return batchesList
		},

		async actionGetUserCurrentOpenedBatches() {
			try {
				const response = await LotsModels.getUserCurrentOpenedBatches()

				this.currentUserOpenenedBatches = response?.length > 0 ? response : null
			}
			catch (error) {
				this.statusBatchsList = 'error'
			}
		},

		async actionResetCurrentOpenBatch() {
			this.currentOpenBatch = null
		},

		async actionUpdateBatchLocallyaAfterTransactionDeletion(bundleId: number, isFromBatchByIdView = false) {
			if (isFromBatchByIdView && this.currentOpenBatch.transaction_count - 1 < 100) {
				this.currentOpenBatch.full = false
			}

			this.currentOpenBatch.transactions
					= this.currentOpenBatch.transactions.filter(
					(aTransaction) => aTransaction.transaction_bundle_id !== bundleId,
				)
		},

	},

	getters: {
		getBatchId: (state) => state.currentOpenBatch?.id,
		getBatchs: (state) => state.batchs,
		getTotalBatchs: (state) => state.totalBatchs,
		getTransactionsSettings: (state) => state.settings,
		getStatusBatchsList: (state) => state.statusBatchsList,
	},
})

if (import.meta.webpackHot) {
	import.meta.webpackHot.accept(acceptHMRUpdate(useBatchsStore, import.meta.webpackHot))
}
