<template>
	<div class="qview">
		<div
			class="flex flex-col h-full"
		>
			<div v-if="!computedIsActualRouteNameContactDetails">
				<contact-header
					v-model:contact-list="contactList"
					v-model:nb-contact-selected="nbContactSelected"
					v-model:all-contacts-selected="allContactsSelected"
					v-model:id-list-selected="idListSelected"
					v-model:contact-table="contactTable"
					v-model:multiselect-menu-disabled-clicked="multiselectMenuDisabledClicked"
					:contact-selected-change="contactSelectedChangeTrigger"
					:hide-dynamic-table="hideDynamicTable"
					:display-mode="computedGetDisplayMode"
					@filter-dialog-state-change="isFilterDialogOpen = !isFilterDialogOpen"
					@show-creation-list-dialog="showCreationListDialogTrigger += 1"
				/>
			</div>

			<router-view v-slot="{ Component }">
				<!-- <keep-alive> -->
				<component
					:is="Component"
					v-model:contact-list="contactList"
					v-model:nb-contact-selected="nbContactSelected"
					v-model:contact-table="contactTable"
					v-model:all-contacts-selected="allContactsSelected"
					:blurred="isFilterDialogOpen"
					:show-creation-list-dialog="showCreationListDialogTrigger"
					:multiselect-menu-disabled-clicked="multiselectMenuDisabledClicked"
					@contact-selected-change="contactSelectedChangeTrigger += 1"
					@select-list="idListSelected = $event"
					@toggle-hide-dynamic-table="hideDynamicTable = $event"
				/>
				<!-- </keep-alive> -->
			</router-view>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import { actionCreationRoutesAvailables } from '../../routes/_callToActionRoutes'
import { routesContacts } from '../../routes/_contactByIdRoute'
import { defineAsyncComponent } from 'vue'
import tableConfig from './contact-list/contact/dynamic-config'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

export default {
	name: 'ContactRouter',
	components: {
		CallToAction: defineAsyncComponent(() => import('../action-module/callToAction.vue')),
		ContactHeader: defineAsyncComponent(() => import('./contact-list/contact/contact-header.vue')),
	},

	beforeRouteLeave(to, from, next) {
		const routesAllowedToKeepSearchAndFilters = [
			'communication-mail-create',
			'communications-sms-create',
			'duplicates-merge',
			'actionstype',
		]
		const { name } = to

		if (routesAllowedToKeepSearchAndFilters.includes(name)) {
			next()
		}
		else {
			if (this.isStreetAddressOrPollingStationsIncluded || this.getAdvancedSearchQuery) {
				this.actionResetSearchModuleToInitialState({ exceptAdvancedSearch: false })
			}
			if (this.getIsFilterModifiedFromOrigin) {
				this.actionResetFiltersAndPolygonForSearch()
			}
			next()
		}
	},

	data() {
		return {
			contactList: [],
			nbContactSelected: 0,
			allContactsSelected: false,
			contactSelectedChangeTrigger: 0,
			isFilterDialogOpen: false,
			showCreationListDialogTrigger: 0,
			idListSelected: -1,
			openListPopUp: false,

			contactTable: {},
			hideDynamicTable: false,

			multiselectMenuDisabledClicked: false,
		}
	},

	computed: {
		...mapGetters(['contacts']),
		...mapGetters('@filter', ['getIsFilterModifiedFromOrigin']),
		...mapGetters('@search', [
			'isStreetAddressOrPollingStationsIncluded',
			'getAdvancedSearchQuery',
		]),
		...mapGetters('@group', ['getIsMasterView']),
		...mapGetters('@packs', ['getAccessDonationsMemberships']),

		getCustomForms() {
			if (this.getIsMasterView === true) {
				return []
			}
			return this.$store.getters['@form/getCustomForms']
		},
		/**
		 * Due to the actual system in place to detect and set the calltoaction
		 * component, we detect the actual route for the contacts.vue
		 */
		computedIsActualRouteNameContactDetails() {
			return routesContacts.includes(this.$route.name)
		},
		computedGetDisplayMode() {
			if (this.$router.currentRoute.value?.query) {
				if (this.$router.currentRoute.value?.query?.displayMode)
					return this.$router.currentRoute.value?.query?.displayMode
				return
			}
			return !methodIsAccessGranted('contacts:read') && (
				methodIsAccessGranted('communication.email:delete')
				|| methodIsAccessGranted('communication.sms:delete'))
				? 'map'
				: 'list'
		},
	},

	watch: {
		contacts: {
			handler(newVal) {
				this.contactList = cloneDeep(newVal)
			},
		},
	},

	beforeMount() {
		this.contactTable = tableConfig(this.$i18n.locale)
		if (this.getCustomForms.length > 0) {
			const clonedLength = cloneDeep(this.contactTable.data.length) + 1
			this.getCustomForms.forEach((customForm, index) => {
				const isFieldAlreadyPresent = this.contactTable.data.find(
					(field) => field.id === customForm.id,
				)
				this.contactTable.data.push({
					id: customForm.id,
					order: clonedLength + index,
					selected: isFieldAlreadyPresent ? isFieldAlreadyPresent.selected : true,
					width: `calc(7rem + 4rem)`,
					content: (item) => item,
					translationKey: customForm.label,
					name: `custom-${customForm.type}`,
				})
			})

			if (this.getAccessDonationsMemberships !== 'enabled') {
				this.contactTable.data = this.contactTable.data.filter(
					(item) => item.name !== 'membership_member',
				)
			}
		}
	},

	mounted() {
		this.actionFetchLists()
	},

	methods: {
		...mapActions('@search', ['actionResetSearchModuleToInitialState']),
		...mapActions('@lists', ['actionFetchLists']),
		...mapActions('@filter', ['actionResetFiltersAndPolygonForSearch']),
	},
}
</script>
