/**
 * Analytics related routes
 *
 *
 * @rootPath {/analyse}
 */
import { getterControllerStorePackCPR, controllerPackRedirection } from './utils/routerController'
import Analytics from 'components/analyse-module/analytics.vue'
import Contacts from 'components/analyse-module/contacts.vue'
import Enquete from 'components/analyse-module/enquete.vue'
import Detail from 'components/analyse-module/detail.vue'
import store from './../store/stateStore'
// import Generation from 'components/analyse-module/generation.vue'

export default {
	meta: { title: 'MENU.DASHBOARD' },
	path: '/analyse',
	name: 'analyse',
	redirect: { name: 'contacts-analyse' },
	component: Analytics,
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('dashboard:delete')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessAnalytics')
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: '/analyse/contact',
			name: 'contacts-analyse',
			component: Contacts,
			beforeEnter: (to, from, next) => {
				const CPR = getterControllerStorePackCPR()
				if (CPR.statusAnalyticsDashboardTab() === 'enabled') {
					next(true)
				} else {
					next({ name: 'analytics-detail', replace: true })
				}
			},
		},
		{
			path: '/analyse/enquete',
			name: 'enquete',
			component: Enquete,
		},
		{
			path: '/analyse/detail',
			name: 'analytics-detail',
			component: Detail,
		},
		// {
		// 	path: '/analyse/generation',
		// 	name: 'generation',
		// 	component: Generation,
		// 	children: CTARoutes('/analyse/generation', 'generation')
		// }
	],
}
