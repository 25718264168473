import { $request } from './requester'

export default {
	async getMandates(contactId) {
		return $request('GET', `/contacts/${contactId}/mandates`).then((res) => res.body.data)
	},

	async createMandate(mandateData, contactId) {
		return $request('POST', `/contacts/${contactId}/mandates`, mandateData).then(
			(res) => res.body.data
		)
	},

	async updateMandate(mandateData, contactId) {
		return $request('PATCH', `/contacts/${contactId}/mandates`, mandateData).then(
			(res) => res.body.data
		)
	},

	async deleteMandate(mandateData, contactId) {
		return $request('DELETE/JSON', `/contacts/${contactId}/mandates`, mandateData).then(
			(res) => res
		)
	},

	async deleteAllMandates(contactId) {
		return $request('DELETE/JSON', `/contacts/${contactId}/all_mandates`).then((res) => res)
	},

	async getTitle(value, gender) {
		return $request('GET', '/mandates/title', {
			value: value,
			gender: gender,
		}).then((res) => res.body.data.title)
	},

	async getTitles(search, gender) {
		return $request('GET', '/mandates/titles', {
			search: search,
			contact_gender: gender,
		}).then((res) => res.body.data)
	},

	async getOrganisations(search) {
		return $request('GET', '/mandates/organisations', {
			search: search,
		}).then((res) => res.body.data)
	},
}
