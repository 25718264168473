/**
 * Profile related routes
 *
 *
 * @rootPath {/parametre}
 */

import ProfileSettings from 'components/settings-module/profile/profile-settings.vue'
import ProfileInformation from 'components/settings-module/profile/profile-information.vue'
import ProfileNotification from 'components/settings-module/profile/profile-notification.vue'
import ProfilePassword from 'components/settings-module/profile/profile-password.vue'
import ProfileStatus from 'components/settings-module/profile/profile-status.vue'
import ProfileEmails from 'components/settings-module/profile/profile-emails.vue'

export default {
	meta: { title: 'SETTINGS.PROFILE.TITLE' },
	path: '/profile',
	name: 'profile',
	redirect: { name: 'profile-info' },
	component: ProfileSettings,
	props: true,
	children: [
		{
			path: '/profile/info',
			name: 'profile-info',
			component: ProfileInformation,
		},
		{
			path: '/profile/notif',
			name: 'profile-notif',
			component: ProfileNotification,
		},
		{
			path: '/profile/status',
			name: 'profile-status',
			component: ProfileStatus,
		},
		{
			path: '/profile/password',
			name: 'profile-password',
			component: ProfilePassword,
		},
		{
			path: '/profile/emails',
			name: 'profile-emails',
			component: ProfileEmails,
		},
	],
}
