import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { cloneDeep, groupBy } from 'lodash'
import CustomerPackRights from '@quorumsco/quorum-helpers/lib/pack/customerPackRights'
// import sortAlphaNumArrayOfObject from '@quorumsco/quorum-helpers/lib/helpers/sortAlphaNumArrayOfObject'
import { firstBy } from 'thenby'
import Vuex from 'vuex'
import { createStore } from 'vuex'
import analytics from '../external/analytics'
import contact_store from '../models/contact_store'
// import forms_store from '../models/forms_store'
import session_store from '../models/session_store'
import user_store from '../models/user_store'
import { actionModule } from './modules/action-module'
import { autosendModule } from './modules/autosend-module'
import { briefs } from './modules/briefs-module'
import { commandCenterModule } from './modules/command-center'
import { commandCenterCaptionModule } from './modules/command-center-caption'
import { contactModule } from './modules/contact-module'
import { tagsModule } from './modules/tags-module'
import { docs } from './modules/document-module'
import { formModule } from './modules/form-module'
import { geohashModule } from './modules/geohash-module'
import { issueCenter } from './modules/issue-center'
import { navBarModule } from './modules/nav-bar-module'
import { settingsModule } from './modules/settings-module'
import { smsModule } from './modules/sms-module'
import { teamModule } from './modules/team-module'
import { userModule } from './modules/user-module'
import { packsModule } from './modules/packs-module'
import { importModule } from './modules/import-module'
import { exportModule } from './modules/export-module'
import { massiveManipModule } from './modules/massive-manip-module'
import { searchModule } from './modules/search-module'
import { listsModule } from './modules/lists-module'
import { mailchimpStore } from './modules/mailchimp-module'
import { duplicatesStore } from './modules/duplicates-module'
import { notificationStore } from './modules/notification-module'
import { groupModule } from './modules/group-module'
import { invitationModule } from './modules/invitation-module'
import { emailerModule } from './modules/emailer_module'
import { invoiceModule } from './modules/invoice-module'
import { extensionModule } from './modules/extension-module'
import { authenticationModule } from './modules/authentication-module'
import { signupModule } from './modules/signup-module'
import { createFormModule } from './modules/create-form-module'
import { petitionModule } from './modules/petition-module'
import { onlineFormModule } from './modules/online_form_module'
import { privacyPolicyModule } from './modules/privacy-policy-module'
import { constructQueryFromStore } from './modules/utils/searchTools'
import { getWrapLngRangeForDataGeoRootCode } from '../utils/geo'
import app from '../appCreation'
import { subscriptionModule } from './modules/subscription-module'
import { welcomeModule } from './modules/welcome_module'
import { tempModule as temporaryModule } from './modules/temp_module'
import { waitingUsersModule } from './modules/waiting-users-module'
import { apiKeyModule } from './modules/api_key_module'
import { roleManagementModule } from './modules/role_management_module'
import { kpiModule } from './modules/kpi_module'
import { filterModule } from './modules/filter_module'
import { masterSpaceModule } from './modules/master_space_module.ts'
import { mandatesModule } from './modules/mandates-module'
import { turfModule } from './modules/turf_module'
import { profilesModule } from './modules/profiles_module'

import { REMOVE_INSTANCIED_MODULE_ID, SET_INSTANCIED_MODULE_ID } from './modules/_mutation-types'

import { useBatchsStore } from '../stores/batchs'
import { useInteractionsStore } from '@/stores/interactions'
import { user } from '@/components/management-module/dynamicTableBuilder'

app.use(Vuex)

const getUsersFiltered = (state, rootState, allUsers = false) => {
	const stateUsers = get(state, 'users', [])
	const isMasterView = get(rootState, '@group.group.masterView', false)

	if (allUsers || !isMasterView) stateUsers

	const localGroupId = get(rootState, '@group.group.id', 0)
	const usersFiltered = stateUsers.filter((user) => user.group_id.includes(localGroupId))

	return usersFiltered
}

const getDefaultGlobalState = () => {
	return {
		authentificate: false,
		contacts: [],
		users: [],
		contactsCount: 0,
		usersCount: 0,
		title: '',
		user: {},
		userConnected: {},
		subscription: {},
		// activeUser: {},
		// templates: [],
		active_request: false,
		errorMessage: '',
		forms: [],
		fromMobileApp: false, // Variable to disable some components from the mobile application
		info_campagne: {},
		is_contact_loading: 'init',
		is_edited: false,
		isLoading: false,
		loadingInfoCampagne: false,
		sort_result: '',
		static: [],
		total_hit: 0,
		contacts_total: undefined,
		windowIsMobile: false, // Variable use to detect in view if the size of the width is "mobile"
		windowIsTablet: false, // Variable use to detect in view if the size of the width is "tablet"
		actionID: null,
		sections: [],
		dataGeoRootAlias: 'FRA',
		wrapLngRange: null,
		instanciedModuleId: [],
	}
}

export const state = getDefaultGlobalState

export const mutations = {
	LOGOUT: (state, payload, dispatch) => {
		Object.assign(state, getDefaultGlobalState())
	},

	LOGIN: (state, user) => {
		state.authentificate = true
		state.userConnected = user
	},

	SET_SUBSCRIPTION: (state, payload) => {
		state.subscription = payload
	},

	UPDATE_USER_2FA: (state, payload) => {
		state.userConnected.two_factor_enable = payload
	},

	UPDATE_LOCAL_USER_ROLE: (state, payload) => {
		if (state.userConnected.role_data.access) {
			state.userConnected.role_data = payload
		}
	},

	// SET_ACTIVE_USER: (state, activeUser) => {
	//  state.activeUser = activeUser
	// },

	// Save an existing user inside the users array
	SAVE_USER: (state, user) => {
		for (let index = 0; index < state.users.length; index++) {
			if (user.id == state.users[index].id) {
				// splice to trigger update
				state.users.splice(index, 1, user)

				return
			}
		}
	},

	SAVE_ACTIVE_USER: (state, activeUser) => {
		for (let index = 0; index < state.users.length; index++) {
			if (activeUser.id == state.users[index].id) {
				// splice to trigger update
				state.users.splice(index, 1, activeUser)

				return
			}
		}

		// if active user is a new user, push it onto the array
		state.users.push(activeUser)
	},
	// DELETE_ACTIVE_USER: state => {
	//  state.activeUser = {}
	// },

	SAVE_USER_CONNECTED: (state, userConnected) => {
		state.userConnected = cloneDeep(userConnected)

		// update state.users if userConnected is in users
		const userIndex = state.users.findIndex((user) => user.id === userConnected.id)
		const temporaryUser = userConnected = {
			...userConnected,
		}

		temporaryUser.group_id = state.users[userIndex].group_id

		state.users[userIndex] = temporaryUser
	},

	SET_CONTACTS: (state, res) => {
		state.contacts = res
		state.contactsCount = res.length
		state.active_request = true
	},

	UPDATE_CONTACT_IN_CONTACTS: (state, contact) => {
		const index = state.contacts.findIndex((storedContact) => storedContact.id === contact.id)

		if (~index) {
			state.contacts = [
				...state.contacts.slice(0, index),
				contact,
				...state.contacts.slice(index + 1),
			]
		}
	},

	UPDATE_USER: (state, user) => {
		const index = state.users.findIndex(({ id }) => id === user.id)
		if (index !== -1) {
			state.users[index] = user
		}
	},

	SET_USERS: (state, res) => {
		const modifiedUsers = cloneDeep(res.users).map((user) => {
			if (user && user.role_data) {
				// Make sure user and user.role_data exist
				user.order = user.role_data.order
			}

			return user
		})

		// Group by the name of the role data or the type if it doesn't exist
		const groupedUsers = groupBy(modifiedUsers, (user) => {
			return user.role_data.name || user.role_data.type
		})

		// Sort each group by firstname and sort the groups by order
		state.users = Object.values(groupedUsers)
			.map((users) => users.sort(firstBy('firstname')))
			.sort((a, b) => a[0].order - b[0].order)
			.flat()

		state.usersCount = res.count
	},

	TOTAL_HIT: (state, res) => {
		state.total_hit = res
	},
	CONTACTS_TOTAL: (state, res) => {
		state.contacts_total = res
	},

	LOADING: (state, payload) => {
		state.isLoading = payload
	},
	CONTACT_LOADING: (state, res) => {
		state.is_contact_loading = res
	},
	EDIT: (state, res) => {
		state.is_edited = res
	},
	SET_FORMS: (state, res) => {
		state.forms = res
	},
	SET_STATIC: (state, res) => {
		state.static = res
	},
	SET_FORM_DATA: (state, res) => {
		state.form_data = res
	},

	// TODO: push it into settings store
	SET_CAMPAIGN_INFOS: (state, res) => {
		state.info_campagne = {
			...res,
			around_me_disabled:
				res.disable_autourdesoi === undefined
					? res.around_me_disabled
					: res.disable_autourdesoi === 'true',
		}

		const dataGeoRootAlias = res.data_geo_root_code

		state.dataGeoRootAlias = dataGeoRootAlias
		state.wrapLngRange = getWrapLngRangeForDataGeoRootCode(dataGeoRootAlias)

		// The data from the backend might not always be cleaned so we remap to 'NO_DATA' in
		// case the field is empty, null, etc.
		state.info_campagne.archi_data_carto = state.info_campagne.archi_data_carto || 'NO_DATA'

		if (
			res.restrict_archi_data_carto_to === null
			// TO REMOVE WHEN THE BACKEND WILL BE DEPLOYED (UNDEFINED)
			|| res.restrict_archi_data_carto_to === undefined
		) {
			const CPR = new CustomerPackRights(res.features)

			switch (CPR.statusWebCartographyAccessDataProfiles()) {
				case 'promoted':
				case 'disabled': {
					state.info_campagne.restrict_archi_data_carto_to = []
					break
				}

				default: {
					state.info_campagne.restrict_archi_data_carto_to = null
					break
				}
			}
		}
	},

	SET_CAMPAGNES: (state, res) => {
		state.campagnes = res
	},

	LOADING_INFO_CAMPAGNE: (state, status = true) => {
		state.loadingInfoCampagne = status
	},

	// CONFIGURATION WINDOW
	SET_IS_MOBILE_WINDOW: (state, payload) => {
		state.windowIsMobile = payload
	},

	SET_IS_TABLET_WINDOW: (state, payload) => {
		state.windowIsTablet = payload
	},

	// CONFIGURATION WINDOW
	IS_FROM_MOBILE_APP: (state, payload) => {
		state.fromMobileApp = payload
	},

	// SET_TEMPLATES : (state, res) => {
	//  state.templates = res;
	// }

	SET_ACTION_ID: (state, payload) => {
		state.actionID = payload
	},

	RESET_ACTION_ID: (state) => {
		state.actionID = null
	},

	SET_SECTIONS: (state, payload) => {
		state.sections = payload
	},

	SET_USER_CONNECTED: (state, payload) => {
		state.userConnected = payload
	},

	[SET_INSTANCIED_MODULE_ID]: (state, payload) => {
		state.instanciedModuleId.push(payload)
	},

	[REMOVE_INSTANCIED_MODULE_ID]: (state, payload) => {
		const instanceIdIndex = state.instanciedModuleId.indexOf(payload)

		if (instanceIdIndex !== -1) state.instanciedModuleId.splice(instanceIdIndex, 1)
	},
}

export const actions = {
	actionToggleLoadingState: ({ commit }, payload) => {
		commit('LOADING', payload)
	},

	// Recherche sur la BDD
	SEARCH_CONTACT: ({ commit, rootGetters }, payload) => {
		const instanceId = payload?.instanceId ? payload.instanceId : ''

		// if (state.query != "" || state.bdv_filter != "" || state.sexe_filter != "" || state.age_filter != "" || state.form_filter) {
		commit('@contact/HIDE_DETAILED_ANSWER')
		commit('EDIT', false)
		commit('CONTACT_LOADING', 'loading')
		contact_store.find(constructQueryFromStore(rootGetters, instanceId)).then((res) => {
			commit('CONTACT_LOADING', 'success')
			commit('SET_CONTACTS', res)
		})
		// };
	},

	actionGetContactSearch: async ({ rootGetters }, payload) => {
		const instanceId = payload?.instanceId ? payload.instanceId : ''
		const res = await contact_store.find(constructQueryFromStore(rootGetters, instanceId))
		return res
	},

	// Informations spécifiques d'un contact donné
	CONTACT_DETAIL: ({ commit }, contact) => {
		contact_store.getContactById(contact.id).then((res) => {
			commit('@contact/SET_ACTIVE_CONTACT', res.contact)
		})
	},

	SEARCH_USER({ commit, rootState }) {
		commit('@user/DELETE_ACTIVE_USER')
		commit('@user/START_LOADING')

		try {
			const isMasterView = get(rootState, '@group.group.masterView', false)

			if (isMasterView) {
				user_store.list(rootState, true).then(async (res) => {
					const response = res
					const allUsersSpaces = res.users
					const allUsersLocal = await user_store.list(rootState, false)

					// Merge role_data object from allUsersLocals to allUsersSpaces
					for (const user of allUsersSpaces) {
						const userLocal = allUsersLocal.users.find((localUser) => localUser.id === user.id)
						if (userLocal) {
							user.role_data = userLocal.role_data
						}
					}

					response.users = allUsersSpaces
					commit('SET_USERS', response)
					commit('@user/STOP_LOADING')
				})
			}
			else {
				user_store.list(state, false).then((res) => {
					commit('SET_USERS', res)
					commit('@user/STOP_LOADING')
				})
			}
		}
		catch (error) {
			console.warn(error)
			commit('@user/STOP_LOADING')
		}
	},

	UPDATE_USER({ commit }, user) {
		commit('UPDATE_USER', user)
	},

	// Informations spécifiques d'un contact donné
	USER_DETAIL({ commit }, user) {
		user_store.getUserById(user.id).then((res) => {
			commit('@user/SET_ACTIVE_USER', res)
		})
	},

	// Récupérer les informations relatives à la campagne, la typologie des formulaies,  la priorisation et les contours des bureaux de voteS
	// (exécuté lors de la connexion)
	GLOBAL_DATA: async ({ dispatch, commit, rootState, state }) => {
		let CPR

		if (isEmpty(state.info_campagne)) {
			await session_store.mygroup().then((res) => {
				commit('LOADING_INFO_CAMPAGNE', false)
				const { userConnected } = rootState

				if (
					Array.isArray(userConnected?.selected_group_id)
					&& userConnected?.selected_group_id.length > 1
				) {
					res.masterView = true
				}

				commit('SET_CAMPAIGN_INFOS', res)
				CPR = new CustomerPackRights(res.features)
				dispatch('@group/actionSetGroupCampaign', res, { root: true })
				dispatch('@packs/actionSetCustomerPackRightsInstance', res.features, { root: true })
				/**
				 * We want to identify some users that refreshing the application
				 * for some reason didn't relogged from the login page
				 * but have access to the WebApplication.
				 * ----------------
				 * Tricky move also the session is like optionnally
				 * In the .session() we check if the user is allrealy or not identified
				 * On the sessionGroup() call, we don't do that verification because
				 * an user can switch from another space but we need to identify him with the group
				 * call.
				 */
				analytics.session(state.userConnected, res)
				analytics.sessionGroup(res)
			})
		}
		else {
			CPR = new CustomerPackRights(state.info_campagne.features)
		}

		await dispatch('@user/actionFetchUserGroups')
		await dispatch('@form/actionGetStaticForms')
		await dispatch('@form/actionGetCustomForms')
		await dispatch('@form/getActionForms')
		await dispatch('@form/getStatusForms')
		await dispatch('@form/getForms')
		await dispatch('@form/getConsentForms')
		await dispatch('@form/getNamePresenceForms')
		await dispatch('@form/getBriefForms')
		await dispatch('@tags/actionGetTags')
		await dispatch('@search/actionFetchAllPollingStations')
		await dispatch('@user/actionGetEmailBoxStatus', { root: true, userId: state.userConnected.id })
		await dispatch('@contact/actionFetchAgeCategories')
		await dispatch('@privacyPolicy/actionGetPrivacyPolicyUrl')
		const batchStore = useBatchsStore()
		const interactionsStore = useInteractionsStore()

		if (state.userConnected?.role_data?.access?.includes('contacts:read')) {
			interactionsStore.actionRequestGetTagsInteractions()
		}

		/// temporary fix for the transaction tunnel issues, to remove in 1 week
		globalThis.localStorage.removeItem('pinia/transactionTunnel')

		if (CPR.statusWebAccessDonationsMemberships() === 'enabled') {
			await batchStore.actionGetUserCurrentOpenedBatches()
		}

		if (CPR.statusWebAccessMandates() === 'enabled') {
			await dispatch('@mandates/actionFetchOrganizations')
			await dispatch('@mandates/actionFetchTitles')
		}
	},

	deleteSession: ({ commit }) => {
		return session_store
			.delSession()
			.then(() => {
				commit('LOGOUT')
				analytics.reset()
			})
			.catch(() => console.error('Session was not deleted properly'))
	},

	actionSetLogin({ commit }, payload) {
		commit('LOGIN', payload)
	},

	async actionSetSubscription({ commit }) {
		return await session_store
			.getSubscription()
			.then((response) => commit('SET_SUBSCRIPTION', response || {}))
			.catch((error) => console.error(error))
	},

	resetAllAppState: ({ commit, dispatch }) => {
		const modulesToReset = [
			{
				name: '@settings',
				action: 'resetState',
			},
			{
				name: '@form',
				action: 'resetState',
			},
			{
				name: '@user',
				action: 'resetState',
			},
			{
				name: '@contact',
				action: 'resetState',
			},
			{
				name: '@action',
				action: 'resetState',
			},
			{
				name: '@briefs',
				action: 'resetState',
			},
			{
				name: '@docs',
				action: 'resetState',
			},
			{
				name: '@team',
				action: 'resetState',
			},
			{
				name: '@commandCenter',
				action: 'resetState',
			},
			{
				name: '@commandCenterCaption',
				action: 'actionResetStateCaption',
			},
			{
				name: '@profiles',
				action: 'actionResetProfiles',
			},
			{
				name: '@tags',
				action: 'actionResetStateTags',
			},
			{
				name: '@issueCenter',
				action: 'resetState',
			},
			{
				name: '@packs',
				action: 'resetState',
			},
			{
				name: '@petition',
				action: 'actionResetPetitions',
			},
			{
				name: '@onlineForm',
				action: 'actionResetOnlineForms',
			},
			{
				name: '@privacyPolicy',
				action: 'actionResetPrivacyPolicy',
			},
			{
				name: '@createForm',
				action: 'actionResetCreateForm',
			},
			{
				name: '@waitingUsers',
				action: 'actionResetWaitingUsers',
			},
			{
				name: '@apiKey',
				action: 'actionResetApiKey',
			},
			{
				name: '@emailer',
				action: 'actionResetState',
			},
			{
				name: '@kpi',
				action: 'actionResetKpiState',
			},
			{
				name: '@search',
				action: 'actionResetSearchModuleToInitialState',
				args: { exceptAdvancedSearch: false },
			},
			{
				name: '@filter',
				action: 'actionResetFiltersAndPolygonForSearch',
			},
			{
				name: '@mandates',
				action: 'actionResetMandates',
			},
			{
				name: '@turf',
				action: 'actionResetTurf',
			},
		]

		for (const aModule of modulesToReset) {
			if (aModule?.args) dispatch(`${aModule.name}/${aModule.action}`, aModule.args)
			else dispatch(`${aModule.name}/${aModule.action}`)
		}

		/* @geohash */
		dispatch('actionResetGeohashModule')
		/* @roleManagement */
		dispatch('@roleManagement/actionResetRoles')

		/* Root store */
		dispatch('actionResetSMState')

		dispatch('@invitation/actionResetStateInvitations')

		commit('LOGOUT')
	},

	actionSetTotalHit({ commit }, payload) {
		commit('TOTAL_HIT', payload)
	},

	actionSetUpdatedContactInContacts: ({ commit }, payload) => {
		commit('UPDATE_CONTACT_IN_CONTACTS', payload)
	},

	actionSetCampaignInfos({ commit, dispatch }, payload) {
		commit('SET_CAMPAIGN_INFOS', payload)
		dispatch('@group/actionSetGroupCampaign', payload, { root: true })
	},

	actionSetActionID({ commit }, payload) {
		commit('SET_ACTION_ID', payload)
	},

	actionResetActionID({ commit }) {
		commit('RESET_ACTION_ID')
	},

	actionSetSections({ commit }, payload) {
		commit('SET_SECTIONS', payload)
	},

	async actionSaveUserConnected({ commit }, payload) {
		// Hack to avoid empty dates
		const payloadStringified = JSON.parse(JSON.stringify(payload))

		delete payloadStringified.CreatedAt
		delete payloadStringified.UpdatedAt
		delete payloadStringified.created
		await user_store.updateUser(payloadStringified).then((res) => {
			commit('SAVE_USER_CONNECTED', payload)
			return res
		})
			.catch((error) => error)
	},

	actionUpdateUser2fa({ commit }, payload) {
		const two_factor_state = {
			two_factor_enable: payload,
		}

		session_store
			.updateUser2fa(two_factor_state)
			.then(() => {
				commit('UPDATE_USER_2FA', payload)
			})
			.catch((error) => console.log(error))
	},

	actionSetUserConnected({ commit }, payload) {
		commit('SET_USER_CONNECTED', payload)
	},

	actionSetInstanciedModuleId({ commit, dispatch }, payload) {
		commit(SET_INSTANCIED_MODULE_ID, payload)
		dispatch(`@filter-${payload}/actionSetInstanceId`, payload, { root: true })
		dispatch(`@search-${payload}/actionSetInstanceId`, payload, { root: true })
		dispatch(`@kpi-${payload}/actionSetInstanceId`, payload, { root: true })
		dispatch(`@form-${payload}/actionSetInstanceId`, payload, { root: true })
	},

	actionRemoveInstanciedModuleId({ commit }, payload) {
		commit(REMOVE_INSTANCIED_MODULE_ID, payload)
	},
}

/**
 * @func getterSynchronizationType
 * @desc Just to know if a synchronization (nationbuilder, citipo, cambuildr, novagouv)
 * is configured and return the type
 */
function getterSynchronizationType(state) {
	const { info_campagne } = state
	const check_which_CRM = new RegExp('^citepo|citipo|cambuildr|novagouv')
	const connectedCRM = {}

	for (const [key, value] of Object.entries(info_campagne)) {
		if (key === 'sendinblue_apikey' && value) {
			connectedCRM.type = 'brevo'
			connectedCRM.ext = 'svg'
		}
		else if (key === 'nationbuilder_nation' && value) {
			const regexArray = String(value.toLowerCase()).match(check_which_CRM)

			if (regexArray) {
				switch (regexArray[0]) {
					case 'quorum': {
						connectedCRM.type = 'nationbuilder'
						connectedCRM.ext = 'svg'
						break
					}
					case 'citipo':
					case 'citepo': {
						connectedCRM.type = 'citipo'
						connectedCRM.ext = 'svg'
						break
					}
					case 'cambuildr': {
						connectedCRM.type = 'cambuildr'
						connectedCRM.ext = 'png'
						break
					}
					case 'novagouv': {
						connectedCRM.type = 'novagouv'
						connectedCRM.ext = 'png'
						break
					}
				}
				// No key from regex detected, fallback to nationbuilder
			}
			else {
				connectedCRM.type = 'nationbuilder'
				connectedCRM.ext = 'svg'
			}
		}
	}

	return connectedCRM
}

const getSynchronizationNationbuilder = (state) => {
	const { info_campagne } = state
	const connectedCRM = {}
	if (info_campagne.nationbuilder_nation) {
		connectedCRM.type = 'nationbuilder'
		connectedCRM.ext = 'svg'
	}
	return connectedCRM
}

export const getters = {
	// User Information
	active_request: (state) => state.active_request,
	activeUser: (state) => state.activeUser,
	authentificate: (state) => state.authentificate,
	user: (state) => state.user,
	getAllUsers: (state, getters, rootState) => getUsersFiltered(state, rootState, true),
	users: (state, getters, rootState) => getUsersFiltered(state, rootState, false),
	userConnected: (state) => state.userConnected,
	subscription: (state) => state.subscription,
	usersCount: (state) => state.usersCount,

	// Contact Information
	contacts: (state) => state.contacts,
	contactsCount: (state) => state.contactsCount,
	is_contact_loading: (state) => state.is_contact_loading,
	contacts_total: (state) => state.contacts_total,

	// Form and Form Data
	forms: (state) => state.forms,

	// App and Window Information
	fromMobileApp: (state) => state.fromMobileApp,
	windowIsMobile: (state) => state.windowIsMobile,
	windowIsTablet: (state) => state.windowIsTablet,

	// Campaign Information
	info_campagne: (state) => state.info_campagne,
	loadingInfoCampagne: (state) => state.loadingInfoCampagne,
	getSynchronizationType: (state) => getterSynchronizationType(state),
	getSynchronizationNationbuilder: (state) => getSynchronizationNationbuilder(state),
	getDoubleOptInAccess: (state) => state.info_campagne.consent_sms_enable,
	isBulkedEmailsActivated: (state) =>
		state.info_campagne.mailjetapipublickey === 'SENDINBLUE'
		&& state.info_campagne.mailjetapisecretkey === 'IsSet',

	// Results and Indexing
	total_hit: (state) => state.total_hit,

	// Loading and Editing
	isLoading: (state) => state.isLoading,
	is_edited: (state) => state.is_edited,

	// Others
	static: (state) => state.static,
	sections: (state) => state.sections,
	dataGeoRootAlias: (state) => state.dataGeoRootAlias,
	wrapLngRange: (state) => state.wrapLngRange,
	getActionID: (state) => state.actionID,

	// Instance
	getInstanciedModuleId: (state) => state.instanciedModuleId,
}

export default createStore({
	state,
	mutations,
	getters,
	actions,
	strict: false,
	// strict: process.env.NODE_ENV !== 'production', //? https://vuex.vuejs.org/guide/strict.html#strict-mode

	modules: {
		'_geohash': geohashModule,
		'_autoSend': autosendModule,
		'_sms': smsModule,
		'@navBar': navBarModule,
		'@authentication': authenticationModule,
		'@action': actionModule,
		'@commandCenter': commandCenterModule,
		'@commandCenterCaption': commandCenterCaptionModule,
		'@contact': contactModule,
		'@duplicates': duplicatesStore,
		'@group': groupModule,
		'@mailchimp': mailchimpStore,
		'@notification': notificationStore,
		'@form': formModule,
		'@issueCenter': issueCenter,
		'@filter': filterModule,
		'@search': searchModule,
		'@settings': settingsModule,
		'@tags': tagsModule,
		'@team': teamModule,
		'@user': userModule,
		'@packs': packsModule,
		'@briefs': briefs,
		'@docs': docs,
		'@import': importModule,
		'@export': exportModule,
		'@massiveManip': massiveManipModule,
		'@invitation': invitationModule,
		'@emailer': emailerModule,
		'@invoice': invoiceModule,
		'@extension': extensionModule,
		'@lists': listsModule,
		'@signup': signupModule,
		'@createForm': createFormModule,
		'@petition': petitionModule,
		'@onlineForm': onlineFormModule,
		'@privacyPolicy': privacyPolicyModule,
		'@subscription': subscriptionModule,
		'@welcome': welcomeModule,
		'@temp': temporaryModule,
		'@waitingUsers': waitingUsersModule,
		'@apiKey': apiKeyModule,
		'@roleManagement': roleManagementModule,
		'@kpi': kpiModule,
		'@masterSpace': masterSpaceModule,
		'@mandates': mandatesModule,
		'@turf': turfModule,
		'@profiles': profilesModule,
	},
})
