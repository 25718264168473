<template>
	<el-dialog
		v-if="computedRoleInfos"
		v-model="computedDisplayDialog"
		width="40%"
		class="p-4"
		:show-close="false"
		@closed="methodResetDialog"
	>
		<template #header>
			<div>
				<h1 v-if="computedRoleInfos.type == 'superadmin'" class="text-xl">
					{{ $t('SETTINGS.ROLE_MANAGEMENT.RENAME_ROLE') }}
				</h1>
				<h1 v-else class="text-xl">{{ $t('SETTINGS.ROLE_MANAGEMENT.EDIT_ROLE') }}</h1>
				<el-divider class="mb-0 mt-4"></el-divider>
			</div>
		</template>
		<!-- Name and color selection -->
		<div class="flex flex-col gap-7 text-black">
			<div class="flex items-center gap-4 pr-4">
				<el-input
					v-model="computedRoleInfos.name"
					show-word-limit
					:maxlength="25"
					:placeholder="$t('SETTINGS.ROLE_MANAGEMENT.ROLE_NAME')"
				></el-input>
				<el-color-picker
					id="newRoleColor"
					v-model="computedRoleInfos.color"
					size="small"
				></el-color-picker>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-between items-center">
				<!-- Reset button -->
				<p
					v-if="computedRoleInfos.type == 'user'"
					class="text-pink-main hover:opacity-80 flex items-center justify-end cursor-pointer"
					@click="methodPutDefaultAccess"
				>
					<i class="gui-reload pr-2"></i>
					<span class="mt-0.5">{{ $t('SETTINGS.ROLE_MANAGEMENT.RESET_ROLE') }}</span>
				</p>
				<!-- Delete button -->
				<p
					v-else-if="computedRoleInfos.type != 'superadmin'"
					class="text-pink-main hover:opacity-80 flex items-center justify-end cursor-pointer"
					@click="methodRemoveRole"
				>
					<i class="gui-delete pr-2"></i>
					<span class="mt-0.5">{{ $t('SETTINGS.ROLE_MANAGEMENT.DELETE_ROLE') }}</span>
				</p>

				<div class="flex gap-6 items-center ml-auto pr-2">
					<button
						type="button"
						class="text-gray-strong hover:opacity-80"
						@click="computedDisplayDialog = false"
					>
						<div>{{ $t('_COMMON.CANCEL') }}</div>
					</button>
					<q-button :disabled="!computedRoleInfos.name" @click="methodUpdateRole">
						{{ $t('_COMMON.SAVE') }}
					</q-button>
				</div>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { computed } from 'vue'

// Emits
const emit = defineEmits(['update:displayDialog', 'update:roleInfos'])

// Props
const props = defineProps({
	displayDialog: { type: Boolean, default: false },
	roleInfos: { type: Object, default: () => {} },
	methodEditRole: { type: Function, default: () => {} },
	methodDeleteRole: { type: Function, default: () => {} },
	methodResetRole: { type: Function, default: () => {} },
})

// Computed
const computedDisplayDialog = computed({
	get() {
		return props.displayDialog
	},
	set(value) {
		emit('update:displayDialog', value)
	},
})

const computedRoleInfos = computed({
	get() {
		return props.roleInfos
	},
	set(value) {
		emit('update:roleInfos', value)
	},
})

// Method
const methodResetDialog = () => {
	computedRoleInfos.value = null
}

const methodUpdateRole = () => {
	props.methodEditRole()
	computedDisplayDialog.value = false
}

const methodRemoveRole = () => {
	props.methodDeleteRole(computedRoleInfos.value.id)
	computedDisplayDialog.value = false
}

const methodPutDefaultAccess = () => {
	props.methodResetRole(computedRoleInfos.value)
	computedDisplayDialog.value = false
}
</script>

<style lang="sass">

#newRoleColor
	.el-color-picker__trigger, .el-color-picker__color-inner, .el-color-picker__color
		@apply rounded-full
	.el-color-picker__color
		@apply  border-0
	.el-color-svpanel
		@apply hidden

#newRoleColor .el-icon.el-color-picker__icon
	@apply hidden
</style>
