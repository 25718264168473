import { actions, fieldFactory, lastchange, name, status, user } from './dynamicTableBuilder'
export default {
	title: '',
	noDataTranslationKey: 'MANAGEMENT.ISSUE_CENTER.NO_ACTION',
	data: [
		name,
		lastchange,
		user,
		actions,
		status,
		fieldFactory({
			minWidth: '100px',
			slotName: 'report',
			translationKey: 'CONTACT.LIST.TABLE.INFOS',
		}),
	],
}
