<template>
	<div v-for="(aFilter, index) in computedFiltersData" :key="index" class="py-1 flex flex-row">
		<el-dropdown
			:ref="'dropdownStatus' + index"
			trigger="click"
			filterable
			class="doc-filter-dropdown my-1 mr-2 md:mr-5"
			popper-class="petition-dropdown-popper dropdown-filter-popper rounded-xl overflow-hidden"
			@visible-change="(event) => handleDropdownVisibilityChange(index, event)"
		>
			<div class="options-trigger h-9 flex" :class="customInputClass">
				<label
					class="mr-4 text-sm text-black whitespace-nowrap cursor-pointer truncate"
					:style="{ 'max-width': maxWidthLabel + 'px' }"
				>
					{{ getCheckedLabels(aFilter.values, aFilter.title) }}

					<!-- {{ aFilter.title }} -->
				</label>

				<el-icon
					class="el-icon--right transition-all"
					:class="{ 'transform rotate-180': dropdownOpenStates[index] }"
					style="color: rgba(33, 62, 97, 0.35)"
					aria-hidden="true"
					><ArrowDown
				/></el-icon>
			</div>

			<template #dropdown>
				<el-dropdown-menu class="dropdown-menu rounded-xl p-1">
					<el-dropdown-item class="flex flex-wrap rounded-lg">
						<q-check
							:checked="methodAllIsChecked(aFilter.values)"
							class="flex gap-x-0.5"
							@change="
								(event) => {
									resetMethod(event, aFilter.mappingKeyForFilter)
									methodTriggeredOnToggleCheck()
								}
							"
						>
							<span class="flex mr-4 items-center fontsize-13" :class="{ bold: false }">
								{{ $t(babelPrefix + 'ALL.' + aFilter.mappingKeyForFilter.toUpperCase()) }}
							</span>
						</q-check>
						<el-divider class="m-0 mt-2"></el-divider>
					</el-dropdown-item>

					<el-dropdown-item
						v-for="(aValue, index2) in aFilter.values"
						:key="index2"
						class="rounded-lg"
					>
						<q-check
							class="py-1 flex gap-x-0.5 fontsize-13"
							sm
							:checked="aValue.isChecked"
							@change="
								(event) => {
									aValue.isChecked = !aValue.isChecked

									if (methodAllIsChecked(aFilter.values)) {
										resetMethod(event, aFilter.mappingKeyForFilter)
									}
									methodTriggeredOnToggleCheck()
								}
							"
						>
							<span class="flex mr-4 items-center" :class="{ bold: aValue.isChecked }">
								{{ aValue.label }}
							</span>
						</q-check>
					</el-dropdown-item>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
	</div>
</template>

<script setup>
import { getYear, set } from 'date-fns'
import { defineProps, ref, defineEmits, computed, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { cloneDeep } from 'lodash'

const props = defineProps({
	filtersDialogVisible: {
		type: Boolean,
		default: false,
	},
	filtersData: {
		type: Object,
		default: () => {},
	},
	defaultFilters: {
		type: Object,
		default: () => {},
	},
	methodTriggeredOnToggleCheck: {
		type: Function,
		default: () => {},
	},

	babelPrefix: {
		type: String,
		default: 'SHARING2.DOCS.CATEGORIES.',
	},
	customInputClass: {
		type: String,
		default: '',
	},
	maxWidthLabel: {
		type: Number,
		default: 200,
	},
})

const emit = defineEmits(['update:filtersDialogVisible', 'update:filtersData'])

const router = useRouter()
// data

const cpyFiltersData = ref({})
const dropdownOpenStates = reactive({})

const handleDropdownVisibilityChange = (index, isOpen) => {
	dropdownOpenStates[index] = isOpen
}

// Mounted
onMounted(() => {
	cpyFiltersData.value = cloneDeep(props.filtersData)
})

// computed

const computedFiltersData = computed({
	get() {
		return props.filtersData
	},
	set(value) {
		emit('update:filtersData', value)
	},
})

// method

const methodAllIsChecked = (values) => {
	return values.every((aValue) => !aValue.isChecked)
}

function getCheckedLabels(values, title) {
	const checkedItems = values.filter((value) => value.isChecked)

	if (checkedItems.length === 0) {
		return title
	}

	return checkedItems.map((value) => value.label).join(', ')
}

const resetMethod = (event, findedFilter) => {
	let cpyFilter = cloneDeep(computedFiltersData.value)
	let cpyValues = cpyFilter.find((e) => e.mappingKeyForFilter === findedFilter)

	if (event.target.checked) {
		cpyValues.values.forEach((element) => {
			element.isChecked = false
		})
	}

	computedFiltersData.value = cpyFilter
}
</script>

<style lang="scss">
.max-w-label {
	max-width: v-bind(maxWidthLabel) + 'px' !important;
	@apply truncate;
}

.doc-filter-dropdown {
	.options-trigger {
		display: flex;
		align-items: center;
		min-width: 225px;
		min-width: fit-content;
		width: fit-content;
		padding: 0.45rem 0.45rem 0.45rem 1.1rem;
		border: 1px solid #a7a7ac;
		border-radius: 0.7rem;

		&:focus {
			border-color: #ff387f;
		}
	}
}

.underline-header .el-dialog__body {
	padding-top: 0px;
}

.dropdown-filter-popper .el-dropdown-menu {
	max-height: 50vh;
}

.fontsize-13 {
	font-size: 13px;
}
</style>

<style lang="scss" scoped></style>
