<template>
	<div class="section">
		<div class="section-title">
			{{ $t('SETTINGS.PROFILE.NOTIFICATION.TITLE') }}
		</div>
		<div class="text-sm text-gray-dark mb-6 flex items-center">
			<i class="gui-infos color-part-one color-part-two text-lg align-text-top mr-1" />
			{{ $t('SETTINGS.PROFILE.NOTIFICATION.WARNING') }}
		</div>
		<selector-profile
			v-if="computedIsEmailNotificationAvailable"
			:value="computedNotificationEmail || false"
			:title="$t('SETTINGS.PROFILE.NOTIFICATION.EMAILS')"
			:subtitle="$t('SETTINGS.PROFILE.NOTIFICATION.EMAILS_DESCRIPTION')"
			:description="$t('SETTINGS.PROFILE.NOTIFICATION.EMAILS_INFO')"
			icon="gui-send_email"
			@change="(value) => methodSetNotificationPreference('email', value)"
		/>
		<selector-profile
			v-if="computedIsPushNotificationAvailable"
			:value="computedNotificationPush || false"
			:title="$t('SETTINGS.PROFILE.NOTIFICATION.NOTIF')"
			:subtitle="$t('SETTINGS.PROFILE.NOTIFICATION.NOTIF_DESCRIPTION')"
			:description="$t('SETTINGS.PROFILE.NOTIFICATION.NOTIF_INFO')"
			icon="gui-send_phone"
			@change="(value) => methodSetNotificationPreference('push', value)"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import SelectorProfile from './selector-profile.vue'

export default {
	components: {
		SelectorProfile,
	},
	props: {
		notificationsPreferences: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			currentNotifications: {},
		}
	},

	computed: {
		computedIsEmailNotificationAvailable() {
			if (!isEmpty(get(this.notificationsPreferences, 'available_types'))) {
				return this.notificationsPreferences.available_types.includes('email')
			}
			return false
		},

		computedIsPushNotificationAvailable() {
			if (!isEmpty(get(this.notificationsPreferences, 'available_types'))) {
				return this.notificationsPreferences.available_types.includes('push')
			}
			return false
		},

		computedNotificationEmail: {
			get() {
				return get(this.currentNotifications, 'email', false)
			},
			set(value) {
				this.currentNotifications['email'] = value
			},
		},
		computedNotificationPush: {
			get() {
				return get(this.currentNotifications, 'push', false)
			},
			set(value) {
				this.currentNotifications['push'] = value
			},
		},
	},

	watch: {
		notificationsPreferences: {
			handler(value) {
				this.currentNotifications = cloneDeep(get(value, 'current'))
			},
			deep: true,
		},
	},

	mounted() {
		this.currentNotifications = cloneDeep(get(this.notificationsPreferences, 'current'))
	},

	methods: {
		...mapActions('@user', ['actionSetNotificationsPreferences']),

		methodGetValueActualNotificationTypePreference(type) {
			return get(this.notificationsPreferences, `current.${type}`)
		},

		methodSetNotificationPreference(type, value) {
			this.actionSetNotificationsPreferences({ [type]: value.value })
		},
	},
}
</script>

<style lang="sass" scoped>
.section
    @apply mt-1 p-8 bg-white
    .section-title
        @apply text-sm text-gray-dark uppercase mb-6
</style>
