<template>
	<div>
		<section
			v-if="formType === 'petitions'"
			class="mb-8"
			:class="{ blurredColorsPart: !hasCustomisationPack }"
		>
			<div class="flex flex-row justify-between items-center mb-2 h-6">
				<div class="flex flex-col w-4/5">
					<p class="bold">Favicon</p>
				</div>
				<image-uploader
					:is-visible="isImageUploaderVisible"
					:display-cropper="false"
					:is-just-url-wanted="true"
					:file-max-size-byte="150000"
					file-max-size="150"
					error-size="PETITIONS.CUSTOMISATION.SIZE_LIMIT_ERROR_EL_UPLOAD"
					:only-upload="true"
					class="pb-0.5"
					:img="computedForm.favicon"
					which-module="favicon"
					:first-tab-wording="$t('PETITIONS.CUSTOMISATION.IMPORT_FAVICON')"
					:title="$t('PETITIONS.CUSTOMISATION.CUSTOM_FAVICON')"
					subtitle=""
					:dimensions="$t('PETITIONS.CUSTOMISATION.CUSTOM_FAVICON_SUBTITLE')"
					:files="
						$t('PETITIONS.CUSTOMISATION.FAVICON_SIZE', {
							fileSizeMax: 150,
						})
					"
					@closeImageUploader="isImageUploaderVisible = false"
					@createImageFromUrl="methodCreateFavicon"
					@methodDeleteFavicon="methodDeleteFavicon"
				/>
				<button
					:disabled="!hasCustomisationPack"
					@click="isImageUploaderVisible = !isImageUploaderVisible"
				>
					<p
						v-show="!computedForm.favicon"
						class="text-sm text-pink-main flex"
						:class="{ 'hover:text-pink-medium cursor-pointer': hasCustomisationPack }"
					>
						{{ $t('PETITIONS.CUSTOMISATION.IMPORT') }}
					</p>
				</button>
				<div v-show="computedForm.favicon" @click="methodDeleteFavicon">
					<i class="gui-delete text-2xl hover:text-gray-medium cursor-pointer transition-all" />
				</div>
			</div>
			<p class="mb-3">{{ $t('PETITIONS.CUSTOMISATION.DESC') }}</p>
			<div class="flex flex-row bg-gray-light filter drop-shadow border-b-3 border-white">
				<div
					class="flex flex-row bg-white mx-7 mt-1 px-4 py-2 items-center justify-start rounded-t-md tabTitle"
				>
					<div class="test flex flex-row gap-2 items-center truncate">
						<img
							v-if="computedForm.favicon && hasCustomisationPack"
							:src="computedForm.favicon"
							class="object-fill w-7 h-7 z-10 border border border-opacity-0"
						/>

						<div
							v-else
							class="border border-pink border-dashed z-10 w-7 h-7 rounded-md border-pink-main flex justify-center items-center"
							@click="() => (hasCustomisationPack ? (isImageUploaderVisible = true) : null)"
						>
							<i
								v-if="hasCustomisationPack"
								class="gui-more text-xl text-white hover:text-pink-main cursor-pointer transition-all"
							/>
						</div>

						<div class="truncate">
							{{ computedForm.title }}
						</div>
					</div>
				</div>
			</div>
		</section>
		<section :class="{ blurredColorsPart: !hasCustomisationPack }">
			<p v-if="formType === 'petitions'" class="mb-2 bold h-6">
				{{ $t('CREATE_FORM.SETTINGS.COLORS.TITLE') }}
			</p>
			<div
				v-for="(color, index) in computedForm.colors"
				:key="`color-${index}`"
				class="params-bar__color-picker rounded-lg relative h-14 mb-2"
				:style="`--bg-picker-color: ${
					labelColors.includes(color.name)
						? getBrightnessFromColor(color.value)
							? '#FFFFFF'
							: '#222230'
						: color.value
				}`"
			>
				<label
					:name="color.name"
					class="absolute z-10 h-full w-full flex items-center px-3 pointer-events-none text-blackFiltered"
					:class="{ disabledColor: !hasCustomisationPack }"
					:style="
						labelColors.includes(color.name)
							? `color: ${color.value};`
							: `filter: invert(${getBrightnessFromColor(color.value)});`
					"
				>
					<span class="text-title mr-3" style="font-size: 22px">Aa</span>
					<span class="flex-1 text-center">
						{{ $t(`CREATE_FORM.COLORS.${color.name.toUpperCase()}`) }}
					</span>
					<i
						class="params-bar__color-picker__edit gui-edit text-2xl"
						:class="{ invisible: !hasCustomisationPack }"
					/>
				</label>

				<el-color-picker
					v-model="color.value"
					color-format="hex"
					:disabled="!hasCustomisationPack"
					@active-change="(e) => onActiveChange(e, index)"
				/>
			</div>

			<div class="w-full flex justify-center mt-6">
				<button
					type="button"
					class="text-gray-strong mx-auto flex items-center"
					:disabled="!hasCustomisationPack"
					:class="{ 'cursor-not-allowed': !hasCustomisationPack }"
					@click="resetColors"
				>
					<i class="gui-reload text-2xl mr-2" />
					<span class="mt-1">{{ $t('CREATE_FORM.COLORS.RESET') }}</span>
				</button>
			</div>
		</section>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import ImageUploader from '../../general/image-uploader.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		ImageUploader,
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
		formType: { type: String, default: '', required: true },
		getBrightnessFromColor: { type: Function, default: () => {}, required: true },
		param: { type: Object, default: () => {}, required: true },
	},
	emits: ['update:form'],
	data() {
		return {
			isImageUploaderVisible: false,
			hasCustomisationPack: get(this.param, 'pack', true),
			colorsToShow: [],
			labelColors: ['form-labels', 'links-text'],
			basicPetitionColors: [
				{
					value: '#FBF8F6',
					name: 'page-background',
				},
				{
					value: '#FFFFFF',
					name: 'texts-background',
				},
				{
					value: '#222230',
					name: 'goal-background',
				},
				{
					value: '#6ED39D',
					name: 'progress-bar',
				},
				{
					value: '#FFFFFF',
					name: 'form-background',
				},
				{
					value: '#DF0B69',
					name: 'form-labels',
				},
				{
					value: '#DF0B69',
					name: 'links-text',
				},
				{
					value: '#DF0B69',
					name: 'sign-button-background',
				},
			],
			basicOnlineFormColors: [
				{
					value: '#FBF8F6',
					name: 'page-background',
				},
				{
					value: '#FFFFFF',
					name: 'form-background',
				},
				{
					value: '#DF0B69',
					name: 'form-labels',
				},
				{
					value: '#DF0B69',
					name: 'links-text',
				},
				{
					value: '#DF0B69',
					name: 'sign-button-background',
				},
			],
		}
	},
	computed: {
		...mapGetters('@group', ['getGroupLocale']),
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
	},

	methods: {
		onActiveChange(color, index) {
			this.computedForm.colors[index].value = color
		},

		resetColors() {
			this.computedForm.colors = cloneDeep(
				this.formType === 'petitions' ? this.basicPetitionColors : this.basicOnlineFormColors
			)
		},

		methodCreateFavicon(imgUrl) {
			this.computedForm.favicon = imgUrl
		},

		methodDeleteFavicon() {
			this.computedForm.favicon = ''
		},

		methodRedirectToPricingPage() {
			const pricingPages = { fr: 'https://fr.qomon.com/tarifs', en: 'https://qomon.com/pricing' }
			window.open(pricingPages[this.$i18n.locale], '_blank')
		},
	},
}
</script>

<style lang="scss">
.test {
	&::after {
		background-color: white;
		position: absolute;
		content: '';
		left: -10px;
		height: 10px;
		width: calc(100% + 20px);
		bottom: 0;
	}
}
.tabTitle {
	position: relative;
	min-width: 15rem;
	max-width: 90%;
	&::before {
		position: absolute;
		left: -10px;
		bottom: 0;
		width: 10px;
		height: 100%;
		content: '';
		z-index: 2;
		border-radius: 0 0 10px 0;
		@apply bg-gray-light;
	}
	&::after {
		position: absolute;
		right: -10px;
		bottom: 0;
		width: 10px;
		height: 100%;
		content: '';
		border-radius: 0 0 0 10px;
		@apply bg-gray-light;
	}
}
.blurredColorsPart {
	-webkit-filter: blur(1px);
	-moz-filter: blur(1px);
	-o-filter: blur(1px);
	-ms-filter: blur(1px);
	filter: blur(1px) grayscale(1);
	opacity: 0.7;
	.el-color-picker__color-inner,
	.el-color-picker__mask {
		background-color: transparent !important;
	}
}

.disabledColor {
	color: #a7a7ac;
}

.params-bar {
	&__color-picker {
		&__edit {
			opacity: 0;
			transition: all 0.1s ease-in;
			.params-bar__color-picker:hover & {
				opacity: 1;
				transition: all 0.1s ease-out;
			}
		}

		.el-color-picker {
			height: 100%;
			width: 100%;

			&__trigger {
				height: 100%;
				width: 100%;
				padding: 0;
				border: 1px solid #ebebeb;
				border-radius: 10px;
			}
			&__color {
				border: none;
			}
			&__color-inner {
				border-radius: 10px;
				background-color: var(--bg-picker-color, white) !important;
			}
			&__icon {
				display: none;
			}
		}
	}
}

.el-color-dropdown__link-btn.el-button--text.el-button--mini {
	display: none; // do not display clear button in color picker
}
.el-color-dropdown__btn.el-button--default.el-button--mini.is-plain {
	@apply border-pink-main text-pink-main;
}
</style>
