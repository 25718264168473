import get from 'lodash/get'
import forIn from 'lodash/forIn'
import { amountToFrontend } from '@/requesters/transactionSerializer'

type AmountItem = {
	[key: string]: number
}

export const batchDeserializer = (batch: BatchItem, currency: string): BatchItem | any => {
	if (!batch) return null
	const clonedBatch: BatchItem = { ...batch }
	const amounts: AmountItem = get(clonedBatch, 'summary.amounts', { [currency]: 0 }) // Add default value for amounts
	if (amounts[currency]) {
		forIn(amounts, (value: number, key: string) => {
			amounts[key as keyof AmountItem] = amountToFrontend(value)
		})
	}
	if (clonedBatch?.transactions?.length) {
		clonedBatch.transactions.forEach((transaction: TransactionItem) => {
			transaction.amount = amountToFrontend(transaction.amount)
		})
	}

	return clonedBatch
}
