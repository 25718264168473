import welcomeModel from '../../models/welcome_store'

import {
	SET_STATUS_MAP_ROOT_LABEL,
	SET_STATUS_ORGANISATION_SIZE,
	SET_STATUS_FEATURES_OF_INTEREST,
} from './_mutation-types'

const getDefaultState = () => {
	return {
		statusMapRootLabel: 'init',
		statusOrganisationSize: 'init',
		statusFeaturesOfInterest: 'init',
	}
}
const state = getDefaultState

export const mutations = {
	[SET_STATUS_MAP_ROOT_LABEL]: (state, payload) => {
		state.statusMapRootLabel = payload
	},

	[SET_STATUS_ORGANISATION_SIZE]: (state, payload) => {
		state.statusOrganisationSize = payload
	},

	[SET_STATUS_FEATURES_OF_INTEREST]: (state, payload) => {
		state.statusFeaturesOfInterest = payload
	},
}

export const actions = {
	actionSetMapRootLabel: ({ commit }, payload) => {
		welcomeModel
			.setMapRootLabel(payload)
			.then(() => commit(SET_STATUS_MAP_ROOT_LABEL, 'success'))
			.catch(() => commit(SET_STATUS_MAP_ROOT_LABEL, 'error'))
	},

	actionSetOrganisationSize: ({ commit }, payload) => {
		welcomeModel
			.setOrganisationSize(payload)
			.then(() => commit(SET_STATUS_ORGANISATION_SIZE, 'success'))
			.catch(() => commit(SET_STATUS_ORGANISATION_SIZE, 'error'))
	},

	actionSetFeaturesOfInterest: ({ commit }, payload) => {
		welcomeModel
			.setFeaturesOfInterest(payload)
			.then(() => commit(SET_STATUS_FEATURES_OF_INTEREST, 'success'))
			.catch(() => commit(SET_STATUS_FEATURES_OF_INTEREST, 'error'))
	},
}

export const getters = {
	getStatusMapRootLabel: (state) => state.statusMapRootLabel,
	getStatusOrganisationSize: (state) => state.statusOrganisationSize,
	getStatusFeaturesOfInterest: (state) => state.statusFeaturesOfInterest,
}

export const welcomeModule = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
