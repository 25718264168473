export default {
	meta: {
		title:
			'SETTINGS.CANCEL_SUBSCRIPTION_TUNNEL.COMPONENTS.LAST_CHANCE_STEP.CANCEL_SUBSCRIPTION_BUTTON',
	},
	path: '/cancel-subscription-tunnel',
	name: 'cancel-subscription-tunnel',
	component: () => import('components/cancel-subscription-tunnel/cancel-subscription-main.vue'),
	props: true,
}
