module.exports = [
	{
		LOCAL: '/api',
		// eslint-disable-next-line no-undef
		REAL: process.env.API_URL || API_URL,
		onlyForDataDev: false,
	},
	{
		LOCAL: '/local-territory-api',
		REAL: 'http://0.0.0.0:5000',
		onlyForDataDev: true,
	},
	{
		LOCAL: '/api-public',
		// eslint-disable-next-line no-undef
		REAL: process.env.API_PUBLIC_URL || API_PUBLIC_URL,
		onlyForDataDev: false,
	},
	{
		LOCAL: '/api-kpi',
		// eslint-disable-next-line no-undef
		// REAL: process.env.API_URL_KPI || API_URL_KPI,
		REAL: 'https://kpi-integration.quorumapps.com',
		onlyForDataDev: false,
	},
]
