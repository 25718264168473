<template>
	<div class="flex pl-2 rounded-lg my-1 items-center bg-whit relative">
		<div v-if="disabled && stringValue !== 'available_all_the_time'" class="isDisabled"></div>
		<div class="h-full flex items-center justify-center py-8 pr-8">
			<availibilities-dropdown-icons
				v-if="isCheckbox && stringValue"
				:type="stringValue"
				:size="25"
				class="mr-4 p-3"
				:bg-color="bgColor"
			></availibilities-dropdown-icons>
			<i v-else :class="icon" class="text-4xl" />
		</div>
		<div class="flex flex-col flex-grow justify-center ml-2">
			<div class="text-lg font-bold">{{ title }}</div>
			<div v-if="subtitle.length > 0" class="mt-1">{{ subtitle }}</div>
			<div v-if="description.length > 0" class="mt-1 text-sm text-gray-dark">{{ description }}</div>
		</div>
		<div
			class="flex items-center justify-end value-switch"
			:class="isCheckbox ? 'justify-center' : 'justify-end'"
		>
			<q-check
				v-if="isCheckbox"
				:checked="inputValue"
				class="w-fit"
				md
				@change="methodUpdateOnChange"
			></q-check>
			<div v-else>
				<span class="mr-2 text-sm" :class="{ 'text-gray-dark': !inputValue }">
					{{ inputValue ? $t('SETTINGS.CONNECT.ENABLE') : $t('SETTINGS.CONNECT.DISABLE') }}
				</span>
				<el-switch v-model="inputValue" @change="methodUpdateOnChange"></el-switch>
			</div>
		</div>
	</div>
</template>

<script>
import AvailibilitiesDropdownIcons from '../../general/availibilities-dropdown-icons.vue'

export default {
	components: {
		AvailibilitiesDropdownIcons,
	},
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		stringValue: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		isCheckbox: {
			type: Boolean,
			default: false,
			required: false,
		},
		bgColor: {
			type: String,
			required: false,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	emits: ['change'],
	data() {
		return {
			inputValue: false,
		}
	},
	watch: {
		value(value) {
			this.inputValue = value
		},
	},
	mounted() {
		this.inputValue = this.value
	},
	methods: {
		methodUpdateOnChange($event) {
			this.$emit('change', {
				event: $event,
				value: this.isCheckbox ? this.stringValue : this.inputValue,
			})
		},
	},
}
</script>

<style scoped>
.isDisabled {
	background-color: #dddddd;
	opacity: 0.2;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	border-radius: 0.5rem;
	cursor: not-allowed;
}
</style>
<style lang="sass" scoped>

.value-switch
	min-width: 9rem

// Temporary: until the gui-receive_email and gui-receive_phone
// are implemented
.gui-send_email:after, .gui-send_phone:after
	display: inline-block
	transform-origin: 75% 50%
	transform: rotate(180deg) translateX(-2px) translateY(-2px)

.gui-send_phone:after
	transform: rotate(180deg) translateX(4px) translateY(-2px)
</style>
