<template>
	<!-- TO REFACTO IN VUE 3 -->
	<div class="flex flex-row flex-wrap">
		<div
			class="flex flex-col flex-1"
		>
			<div class="h-full w-full">
				<!-- EDITORS PART START  -->
				<div
					v-if="selectedTemplate || !advancedBuilderWanted"
					class="h-full"
				>
					<EditorHeader
						v-if="getShowBulkEmailsDetails"
						v-model:mail-cc="computedMailCC"
						v-model:mail-reply-to="computedMailReplyTo"
						v-model:mail-object="computedMailObject"
						v-model:sender="sender"
						:computed-get-validated-senders-sorted-by-default-flag="computedGetValidatedSendersSortedByDefaultFlag"
						:computed-get-sender-formated="computedGetSenderFormated"
						:computed-get-sender-name-by-emailer="computedGetSenderNameByEmailer"
						:get-all-users="getAllUsers"
						:get-emailer-api-key="getEmailerAPIKey"
					/>
					<div
						v-if="advancedBuilderWanted"
						class="w-full h-full bg-white border border-gray-200 rounded-xl mt-2 overflow-hidden"
					>
						<div
							id="bee-plugin-container"
							class="h-full w-full"
						/>
					</div>
					<template v-else>
						<TipTapEditor
							v-model:value="mailContent"
							:imgs-linked="linkToAddOnImage"
							:placeholder="$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.PLACEHOLDER')"
							:is-img-accepted="true"
							:is-editor-background-white="true"
							:is-email-editor="true"
							:is-var-templating="true"
							:var-labels="computedGetVarLabels"
							:is-keeping-its-height="true"
							@add-link-on-img="methodAddLinkToImage"
						/>
						<div class="my-5">
							<Helper
								text-black
								:helper-content="computedHelperContent"
							/>
						</div>
					</template>
				</div>
				<!-- EDITORS PART END  -->

				<!-- TEMPLATES AND EDITOR SELECTION START -->
				<TemplatesPart
					v-else
					v-model:selected-template="selectedTemplate"
					v-model:selected-editor-typology="selectedEditorTypology"
					v-model:selected-template-typology="selectedTemplateTypology"
					v-model:advanced-builder-wanted="advancedBuilderWanted"
					v-model:show-save-template-dialog="showSaveTemplateDialog"
					v-model:template-payload-to-save="templatePayloadToSave"
					:method-template-has-been-selected="methodTemplateHasBeenSelected"
				/>
			</div>
			<!-- TEMPLATE AND EDITOR SELECTION END -->

			<!-- TEST EMAIL DIALOG -->
			<el-dialog
				v-model="showTestEmailDialog"
				class="custom-email-campaign-sending-dialog"
				@close="methodHandleTestEmailDialogClose"
			>
				<template #header>
					<span class="font-title text-xl px-4">{{
						$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.TEST_POP_UP_TITLE')
					}}</span>
				</template>
				<div class="flex flex-col gap-3 px-4">
					<span
						v-dompurify-html="$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.TEST_POP_UP_CONTENT')"
					/>
					<div class="flex flex-col gap-2 mt-6">
						<div class="flex justify-start items-center">
							<label
								for="emails"
								class="text-pink-strong text-sm"
							>{{
								$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.EMAIL_LABEL')
							}}</label>
						</div>

						<el-select
							id="emails"
							v-model="testEmailsList"
							value-key="id"
							filterable
							allow-create
							multiple
							clearable
							:multiple-limit="5"
							class="rounded-lg w-full"
						>
							<el-option
								v-for="(user, index) in getAllUsers"
								:key="index"
								:label="user.mail"
								:value="user.mail"
								class="flex items-center gap-3 h-11 mx-2 hover:bg-pink-50 rounded-lg hover:text-pink-main"
							>
								<img
									v-if="
										user.avatar &&
											user.avatar != null &&
											user.avatar != 'null' &&
											user.avatar != undefined
									"
									:src="user.avatar"
									class="w-8 h-8 rounded-full"
								>
								<div
									v-else
									class="flex justify-center items-center w-8 h-8 rounded-full bg-pink-50 font-title-bold text-black"
								>
									{{
										`${user.firstname.toUpperCase().split('')[0]}${
											user.surname.toUpperCase().split('')[0]
										}`
									}}
								</div>
								<span
									style="max-width: 250px"
									class="truncate"
								>
									{{ user.mail }}
								</span>
							</el-option>
						</el-select>
					</div>
				</div>
				<template #footer>
					<span class="flex items-center justify-end gap-5 px-4">
						<span
							class="cursor-pointer hover:opacity-70 text-center"
							@click="showTestEmailDialog = false"
						>{{ $t('_COMMON.CANCEL') }}</span>
						<q-button
							:disabled="testEmailsList.length === 0"
							type="primary"
							@click="methodSendTest()"
						>{{ $t('CONTACT.HEADER.BULK_EMAILS.EDITOR.TEST_POP_UP_SEND') }}</q-button>
					</span>
				</template>
			</el-dialog>

			<!-- RECAP DIALOG -->
			<el-dialog
				v-model="showRecapDialog"
				:width="recapStep === 'recap' ? '80%' : recapStep === 'sending-campaign' ? '460px' : '500px'"
				:show-close="false"
				:class="recapStep === 'recap' ? 'custom-email-recap-sending-dialog' : 'custom-email-campaign-sending-dialog'"
				class="transition-all duration-300"
				:top="recapStep === 'recap' ? '5vh' : '15vh'"
				:close-on-click-modal="recapStep === 'sending-campaign' ? false : true "
				@close="methodHandleRecapDialogClose"
			>
				<template
					v-if="recapStep === 'recap' || recapStep === 'sending-campaign'"
					#header
				>
					<div
						:class="recapStep === 'recap' ? 'pr-10 pl-8 lg:pl-0' : 'pr-0'"
						class="w-full flex justify-between items-center pb-4 border-b border-gray-light"
					>
						<span class="font-bold text-xl">{{ $t('MAILING.RECAP_PRE_SENDING') }}</span>
						<i
							v-if="recapStep === 'recap'"
							class="gui-close text-2xl font-bold cursor-pointer hover:opacity-70"
							@click="methodHandleRecapDialogClose"
						/>
					</div>
				</template>
				<div class="h-[calc(100%-80px)] flex flex-col">
					<!-- CLASSIC RECAP PART -->
					<div
						v-if="recapStep === 'recap'"
						class="flex-1 flex flex-col lg:flex-row justify-start items-stretch gap-12"
					>
						<div class="bg-white text-black flex flex-col justify-between flex-1 pt-10 pb-8 gap-16 px-8 lg:px-0">
							<div class="flex flex-col gap-10">
								<div
									v-if="!isTestAlreadySent && recapDialogMission === 'real-sending'"
									class="border border-yellow-medium bg-yellow-50 rounded-3xl p-[18px] flex flex-wrap md:flex-nowrap justify-between items-center gap-5"
								>
									<div class="flex flex-col gap-1">
										<span class="text-lg font-bold text-black">{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.SEND_TEST_WARNING.TITLE') }}</span>
										<p class="text-sm text-gray-strong">
											{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.SEND_TEST_WARNING.CONTENT') }}
										</p>
									</div>
									<div class="flex items-center justify-center gap-2">
										<q-button
											class="basic-black"
											@click="methodShowTestEmailModal(true)"
										>
											{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.SEND_TEST_WARNING.CTA') }}
										</q-button>
									</div>
								</div>
								<div class="flex flex-col gap-3">
									<div
										class="w-full flex justify-between gap-1 items-start text-lg"
									>
										<span>{{ $t('CONTACT.EMAILS.EMAIL_VIEW.TO') }}</span>
										<div class="flex-1 flex justify-end">
											<Collapse
												v-if="computedGetToTalOfExcludedContacts !== 0"
												little-and-stacked-collapse-title
												custom-class="w-full font-bold text-black flex justify-end"
												:tooltip-content="$t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.KPI_CONTACTS.TITLE_TOOLTIP')"
											>
												<template #title>
													<span class="pointer-events-none flex items-center gap-2 text-lg">
														<i class="gui-infos text-xl" />
														{{ $tc('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.KPI_CONTACTS.TOTAL', computedGetToTalOfValidsContacts , {count: computedGetToTalOfValidsContacts}) }}
													</span>
												</template>
												<div class="flex flex-col pr-7">
													<div class="w-full flex justify-between items-center gap-10 font-bold">
														<span class="">{{ computedGetToTalOfExcludedContacts }}</span>
														<span class="">{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.KPI_CONTACTS.TOTAL_REMOVED', {count: computedGetToTalOfExcludedContacts}) }}</span>
													</div>
													<div
														v-for="(aSubstractedKpi, aKpiIndex) in methodGetKPIEmailsSubstracted()"
														:key="aKpiIndex"
														class="w-full flex justify-between items-center gap-10"
													>
														<span class="text-gray-dark">{{ aSubstractedKpi.count }}</span>
														<span class="text-gray-dark">{{ $t(`CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.KPI_CONTACTS.${aSubstractedKpi.label}`) }}</span>
													</div>
												</div>
											</Collapse>
											<span
												v-else
												class="pointer-events-none flex items-center gap-2 text-lg"
											>
												<i class="gui-infos text-xl" />
												{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.KPI_CONTACTS.TOTAL', computedGetToTalOfValidsContacts, {count: computedGetToTalOfValidsContacts}) }}
											</span>
										</div>
									</div>
									<div
										class="flex justify-between items-start"
									>
										<span>{{ $t('CONTACT.EMAILS.EMAILS_EDITOR.WITH') }}</span>
										<div class="flex justify-end">
											<p
												class="w-auto truncate font-bold "
												style="min-width: 0"
											>
												<span>{{ computedGetSenderNameByEmailer }}</span>
												<span v-if="computedGetSenderFormated">—</span>
												<span
													class="text-gray-dark"
													:title="computedGetSenderFormated"
												>
													{{ computedGetSenderFormated }}
												</span>
											</p>
										</div>
									<!-- <div class="flex justify-between items-center mb-2">
											<span class="bold">{{ $t('MAILING.COUNT_MAILS') }}</span>
											<el-tooltip
												:content="$t('MAILING.COUNT_POP_HOVER')"
												popper-class="rounded-xl"
												placement="top"
											>
												<span class="text-xl gui-infos" />
											</el-tooltip>
										</div>
										<div class="flex justify-between items-center">
											<span>{{ $t('MAILING.COUNT_MAILS_DETAIL') }}</span>
											<span
												v-if="getEmailsBalance"
												class="bold w-2/5 text-right"
											>{{
												getEmailsBalance
											}}</span>
											<span
												v-else
												class="bold"
											>0</span>
										</div>
										<div class="flex justify-between items-center">
											<span>{{ $t('MAILING.COST') }}</span>
											<span class="bold w-2/5 text-right">{{ computedCost }}</span>
										</div>
										<el-divider class="my-2" />
										<div class="flex justify-between items-baseline mb-4">
											<span v-if="computedGetEmailsBalanceLeft < 0 && computedIsUpsellAutomatically">{{
												$t('MAILING.COUNT_MAILS_DETAIL_LEFT_WITH_REFUND')
											}}</span>
											<span v-else>{{ $t('MAILING.COUNT_MAILS_DETAIL_LEFT') }}</span>
											<template v-if="computedGetEmailsBalanceLeft > 0 || computedIsUpsellAutomatically">
												<template
													v-if="computedGetEmailsBalanceLeft < 0 && computedIsUpsellAutomatically"
												>
													<span class="bold w-2/5 text-right">{{
														computedGetEmailsBalanceLeftWithAutoUpsell
													}}</span>
												</template>
												<template v-else>
													<span class="bold w-2/5 text-right">{{ computedGetEmailsBalanceLeft }}</span>
												</template>
											</template>
											<span
												v-else
												class="bold"
											>0</span>
										</div>
										<div v-if="!getTempValue?.advancedBuilderWanted">
											<span class="bold">
												{{ $t('MAILING.GOOD_TO_KNOW_KEY') }}
												<span class="ml-2 text-lg">💡</span>
											</span>
											<div class="pt-2 mb-2 text-sm w-full">
												<span>{{ $t('MAILING.GOOD_TO_KNOW_VALUE') }} </span>
											</div>
										</div>
										<span
											v-if="computedGetEmailsBalanceLeft < 0 && !computedIsUpsellAutomatically"
											class="balance-alert"
										>
											{{ $t('MAILING.WRONG_COUNT') }}
										</span>
										<p
											v-else-if="computedGetEmailsBalanceLeft < 0 && computedIsUpsellAutomatically"
											class="bg-yellow-50 border border-yellow-500 text-yellow-500 rounded-lg px-3 py-2 text-sm"
										>
											{{ $t('CONTACT.HEADER.BULK_EMAILS.EDITOR.AUTOMATIC_UPSELL_HELP') }}
										</p> -->
									</div>
									<div
										v-if="mailCC.length > 0"
										class="flex justify-between items-start"
									>
										<span>{{ $t('FORM.AUTO_SEND.EDITOR.CC') }}</span>
										<p
											class="w-auto font-bold overflow-x-auto whitespace-nowrap"
											style="min-width: 0"
										>
											{{ mailCC.length > 0 ? mailCC.join(', ') : '—' }}
										</p>
									</div>
									<div class="flex justify-between items-start">
										<span>{{ $t('FORM.AUTO_SEND.EDITOR.REPLY_TO') }}</span>
										<p
											class="w-auto truncate font-bold"
											style="min-width: 0"
										>
											{{ mailReplyTo || '—' }}
										</p>
									</div>
									<div class="flex justify-between items-start">
										<span>{{ $t('CONTACT.EMAILS.EMAILS_EDITOR.SUBJECT') }}</span>
										<p
											class="w-auto truncate font-bold"
											style="min-width: 0"
										>
											{{ mailObject || '—' }}
										</p>
									</div>
								</div>
							</div>

							<div class="flex items-center justify-end w-full">
								<div class="flex items-center gap-6">
									<span
										class="hover:opacity-70 cursor-pointer"
										@click="showRecapDialog = false"
									>{{
										$t('_COMMON.CANCEL')
									}}</span>
									<button-loader>
										<!-- :disabled="stateCTASender !== 'init'" -->

										<q-button
											:class="{ disabled: stateCTASender !== 'init', load: stateCTASender === 'loading', 'pointer-events-none': stateCTASender !== 'init' }"
											@click="methodCallBackRefundDialog"
										>
											{{ $t('MAILING.SEND_BUTTON') }}
										</q-button>
									</button-loader>
								</div>
							</div>
						</div>
						<div class="bg-purple-soft_lila w-full lg:w-[500px] min-h-[300px] flex flex-col justify-center items-center sticky top-0 overflow-auto">
							<div class="pt-5 flex items-center gap-2 text-black">
								<i class="gui-eye_open text-xl" />
								<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.PREVIEW') }}</span>
							</div>
							<div class="h-full w-full flex items-center justify-center relative pt-10">
								<div
									v-if="selectedTemplate?.content_json"
									class="h-full w-[80%] flex justify-center items-start rounded-t-2xl bg-white max-h-[500px]"
									style="box-shadow: 0px 106px 30px 0px rgba(0, 0, 0, 0.00), 0px 68px 27px 0px rgba(0, 0, 0, 0.01), 0px 38px 23px 0px rgba(0, 0, 0, 0.05), 0px 17px 17px 0px rgba(0, 0, 0, 0.09), 0px 4px 9px 0px rgba(0, 0, 0, 0.10);"
								>
									<img
										v-if="tmpThumbnail && !getThumbnailLoading"
										class="w-full pointer-events-none rounded-t-2xl"
										:src="tmpThumbnail"
										alt=""
									>
									<loader
										v-else
										size="10%"
									/>
								</div>

								<div
									v-else
									class="h-full w-[80%] flex justify-center items-start rounded-t-2xl bg-white max-h-[500px] p-3"
									style="box-shadow: 0px 106px 30px 0px rgba(0, 0, 0, 0.00), 0px 68px 27px 0px rgba(0, 0, 0, 0.01), 0px 38px 23px 0px rgba(0, 0, 0, 0.05), 0px 17px 17px 0px rgba(0, 0, 0, 0.09), 0px 4px 9px 0px rgba(0, 0, 0, 0.10);"
								>
									<div
										class="w-full pointer-events-none rounded-t-2xl text-black text-sm"
										v-html="mailContent"
									/>
								</div>
							</div>
						</div>
					</div>

					<!-- CAMPAIGN SENDING PART -->
					<div v-if="recapStep === 'sending-campaign'">
						<div class="w-full flex flex-col items-start justify-start py-10 gap-10">
							<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.BREVO_CAMPAIGN_SENDING_DIALOG.DESC') }}</span>
							<div class="w-full flex flex-col items-start justify-start gap-4">
								<div
									v-for="(aProviderCampaignStatus, aProviderCampaignStatusIndex) in getProviderCampaignStatusesFormated"
									:key="aProviderCampaignStatusIndex"
									class="w-full flex items-start gap-2 text-black"
								>
									<template v-if="aProviderCampaignStatus.status === 'done'">
										<i class="gui-yes text-3xl" />
									</template>
									<template v-if="aProviderCampaignStatus.status === 'todo'">
										<i class="gui-radio_off text-3xl" />
									</template>
									<template v-if="aProviderCampaignStatus.status === 'in_progress'">
										<div class="flex items-center justify-center animate-spin">
											<i class="gui-loading text-3xl " />
										</div>
									</template>
									<div class="w-full flex flex-col items-start justify-start">
										<span class="">{{ methodGetProviderCampaignStepLabel(aProviderCampaignStatus.name) }}</span>
										<span class="text-sm text-gray-dark">{{ methodGetProviderCampaignStatusLabel(aProviderCampaignStatus.status) }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- CAMPAIGN SENT PART -->
					<div
						v-if="recapStep === 'campaign-sent'"
						class="flex flex-col justify-center items-center gap-4"
					>
						<span class="w-[70px] h-[70px] bg-green-lighted rounded-full flex justify-center items-center">
							<i class="gui-yes text-4xl" />
						</span>
						<span class="font-title-bold text-3xl text-black">{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.TITLE') }}</span>
						<div class="text-center text-lg">
							<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.CONTENT') }}</span>
							<span
								class="font-bold text-pink-main cursor-pointer hover:underline"
								@click="$router.push({ name: 'automatic-email-dashboard' })"
							>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.CONTENT_2') }}</span>
							<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.CONTENT_3') }}</span>
						</div>
						<q-button
							class="w-32 mt-5"
							@click="methodHandleRecapDialogClose"
						>
							{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.CTA_CLOSE') }}
						</q-button>
						<span
							class="text-sm text-gray-dark hover:underline cursor-pointer"
							@click="$router.push({ name: 'contacts' })"
						>
							{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_SENT_DIALOG.CTA_SECONDARY') }}
						</span>
					</div>

					<!-- CAMPAIGN ERROR PART -->
					<div
						v-if="recapStep === 'campaign-error'"
						class="flex flex-col justify-center items-center gap-4"
					>
						<span class="w-[70px] h-[70px] bg-red-lighted rounded-full flex justify-center items-center">
							<i class="gui-warning_email text-red-strong text-4xl" />
						</span>
						<span class="font-title-bold text-3xl text-black text-center">{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_ERROR_DIALOG.TITLE') }}</span>
						<div class="text-center text-lg">
							<span>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_ERROR_DIALOG.CONTENT') }}</span>
							<span
								class="text-pink-main cursor-pointer hover:underline"
								@click="methodOpenChat"
							>{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_ERROR_DIALOG.CONTENT_2') }}</span>
						</div>
						<q-button
							class="w-32 mt-5"
							@click="methodHandleRecapDialogClose"
						>
							{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_ERROR_DIALOG.CTA_CLOSE') }}
						</q-button>
						<span
							class="text-sm text-gray-dark hover:underline cursor-pointer"
							@click="$router.push({ name: 'contacts' })"
						>
							{{ $t('CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.CAMPAIGN_ERROR_DIALOG.CTA_SECONDARY') }}
						</span>
					</div>
				</div>
			</el-dialog>

			<!-- SAVE TEMPLATE DIALOG -->
			<el-dialog
				v-model="showSaveTemplateDialog"
				@close="templatePayloadToSave = null"
			>
				<template #header>
					<span>
						<span class="font-title text-2xl">{{
							$t('CONTACT.HEADER.BULK_EMAILS.TEMPLATES_SELECTION.SAVE_A_TEMPLATE_DIALOG.TITLE')
						}}</span>
					</span>
				</template>
				<div>
					<div class="flex flex-col gap-10">
						<span>{{
							$t('CONTACT.HEADER.BULK_EMAILS.TEMPLATES_SELECTION.SAVE_A_TEMPLATE_DIALOG.CONTENT')
						}}</span>
						<div class="flex flex-col gap-3">
							<span>{{
								$t(
									'CONTACT.HEADER.BULK_EMAILS.TEMPLATES_SELECTION.SAVE_A_TEMPLATE_DIALOG.RENAME_IT'
								)
							}}</span>
							<el-input
								v-if="showSaveTemplateDialog && templatePayloadToSave"
								v-model="templatePayloadToSave.name"
							/>
						</div>
						<div class="flex justify-end items-center gap-3">
							<q-button
								class="basic-black"
								@click="methodHandleTemplateSave('copy')"
							>
								{{
									$t(
										'CONTACT.HEADER.BULK_EMAILS.TEMPLATES_SELECTION.SAVE_A_TEMPLATE_DIALOG.MAKE_A_COPY'
									)
								}}
							</q-button>
							<q-button @click="methodHandleTemplateSave('save')">
								{{
									$t(
										'CONTACT.HEADER.BULK_EMAILS.TEMPLATES_SELECTION.SAVE_A_TEMPLATE_DIALOG.UPDATE_IT'
									)
								}}
							</q-button>
						</div>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { formatDayMonthDate, formatTime } from '../../../utils/dateFnsHelper'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import $ from 'jquery'
import { methodIsAccessGranted } from '@/utils/RoleUtils'

import { filtersVerificationByKPI } from '../../../utils/filtersVerificationByKPI.ts'

import analytics, { TRACKING } from '../../../external/analytics'
import { openChat } from '/src/extensions/support'

export default {
	components: {
		loader: defineAsyncComponent(() => import('../../general/loader')),
		buttonLoader: defineAsyncComponent(() => import('../../general/button-loader.vue')),
		TipTapEditor: defineAsyncComponent(
			() => import('../../create-form-components/tipTapEditor.vue'),
		),
		Helper: defineAsyncComponent(() => import('../../app-module/helper.vue')),
		Collapse: defineAsyncComponent(() => import('../../general/collapse-panel.vue')),
		EditorHeader: defineAsyncComponent(() => import('./mailing-part/EditorHeader.vue')),
		TemplatesPart: defineAsyncComponent(() => import('./mailing-part/TemplatesPart.vue')),
	},

	beforeRouteLeave(to, from, next) {
		if (
			this.mailObject !== ''
			|| (this.contentBeenChanged && this.isSendingSucceed !== 'success')
		) {
			this.$confirm(
				this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.ADD_SENDER_PREVENT_EDITION_LOSS_POPUP.CONTENT'),
				this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.ADD_SENDER_PREVENT_EDITION_LOSS_POPUP.TITLE'),
				{
					cancelButtonText: this.$t('_COMMON.CANCEL'),
					confirmButtonText: this.$t('_COMMON.CONFIRM'),
					customClass: 'custom_message_box',
				},
			)
				.then(() => {
					this.methodCleanPageBeforeRouteLeave()
					next(true)
				})
				.catch(() => {
					return false
				})
		}
		else {
			this.methodCleanPageBeforeRouteLeave()
			next(true)
		}
	},

	emits: ['update-visiblity-of-bulk-emails-panel', 'update—mail-cc'],

	data() {
		return {
			totalHit: 0,
			filterSelected: {},
			mailObject: '',
			mailContent: '',
			charactersCount: 0,
			linkToAddOnImage: [],
			sender: '',
			mailCC: [],
			mailReplyTo: '',
			selectedTemplate: null,
			delayedDay: '',
			delayedHour: '',

			routingConfirmed: false,
			result: '',
			beeInstance: null,
			contentBeenChanged: false,
			jsonKeepedToTest: null,
			templatePayloadToSave: null,
			keysAvailable: [],
			isAutoSaveFromRouterGuard: false,
			advancedBuilderWanted: true,
			selectedEditorTypology: 'advanced',
			selectedTemplateTypology: 'last_used_campaign',
			recapDialogMission: '',
			testEmailsList: [],
			isTestAlreadySent: false,
			entryPointRoute: '',

			/// Dialogs ///
			showSaveTemplateDialog: false,
			showTestEmailDialog: false,
			showRecapDialog: false,
			recapStep: 'recap',
			tmpThumbnail: null,
			getThumbnailLoading: false,
			saveToGetThumbnail: false,
			// State CTA on sending email
			stateCTASender: 'init',
		}
	},

	computed: {
		...mapGetters(['getAllUsers']),
		...mapGetters(['info_campagne', 'userConnected']),
		...mapGetters('@emailer', [
			'getEmailsBalance',
			'isSendingSucceed',
			'getTestEmailList',
			'getValidatedEmailerSenders',
			'getEmailerAccount',
			'getEmailerPrices',
			'getTestSendingStatus',
			'getShowBulkEmailsDetails',
			'getRemoveSelectedTemplateTrigger',
			'getIsRecapDialogVisible',
			'getIsSendTestDialogVisible',
			'getProviderCampaignStatusesUnformated',
			'getProviderCampaignStatusesFormated',
		]),
		...mapGetters('@form', ['getActiveStaticKeysAvailables']),
		...mapGetters('@group', ['getEmailerAPIKey']),
		...mapGetters('@temp', ['getTempValue']),
		...mapGetters('@kpi', ['getKPIEmailsMissing', 'getKPISendingEmailsMissing']),

		t() {
			const prefix = 'SMS'

			return {
				send: this.$t(`${prefix}.SEND`),
			}
		},
		getCharactersCount() {
			return new DOMParser().parseFromString(this.mailContent, 'text/html').documentElement
				.textContent.length
		},
		computedGetEmailsBalanceLeft() {
			return this.getTestEmailList && this.getTestEmailList.length > 0
				? this.getEmailsBalance - this.getTestEmailList.length
				: this.getEmailsBalance - this.computedGetToTalOfValidsContacts
		},

		computedGetFormat() {
			return this.$i18n.locale.slice(0, 2) === 'fr' ? 'dd/MM' : 'MM/dd'
		},

		computedGetVarLabels() {
			return this.getActiveStaticKeysAvailables
				.filter(
					(key) => key.type === 'firstname' || key.type === 'surname' || key.type === 'married_name',
				)
				.map((resutl) => ({
					label: resutl.label,
					type: resutl.type,
				}))
		},

		computedGetSenderFormated() {
			/* eslint-disable */
			const stripEmojis = (email) => {
				return email
					.replace(
						/([\u2700-\u27BF\uE000-\uF8FF\u2011-\u26FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/g,
						'',
					)
					.replace(/\s+-/g, '')
					.trim()
			}
			const email = stripEmojis(this.sender.slice())
			 /* eslint-enable */
			return `${email.replaceAll(' ', '_')}`
		},

		computedIsAnotherEmailerThanElasticEmail() {
			return this.getEmailerAPIKey !== 'QOMON'
		},

		computedIsUpsellAutomatically() {
			return Boolean(this.getEmailerAccount?.subscription?.automatic_upsell)
		},

		computedGetValidatedSendersSortedByDefaultFlag() {
			return [...this.getValidatedEmailerSenders].sort((a, b) => {
				const aDefaultFlag = a.default_flag ?? false
				const bDefaultFlag = b.default_flag ?? false

				return bDefaultFlag - aDefaultFlag
			})
		},

		computedGetSenderNameByEmailer() {
			const sender = this.getValidatedEmailerSenders
				? this.getValidatedEmailerSenders.find((sender) => sender.email === this.sender)
				: this.info_campagne.name

			return sender?.name ? sender.name : this.info_campagne.name
		},

		computedHelperContent() {
			return [
				{
					title: this.$t('_COMMON.HELP'),
					text: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.UNSUBSCRIBE_LINK_HELPER'),
				},
			]
		},

		computedIsProviderProvideCampaignSendingStatuses() {
			const providerWhichProvideSendingStatuses = ['SENDINBLUE']
			return providerWhichProvideSendingStatuses.includes(this.getEmailerAPIKey)
		},

		computedGetToTalOfExcludedContacts() {
			let totalExcluded = 0
			if (this.getKPISendingEmailsMissing) {
				totalExcluded = (this.getKPISendingEmailsMissing?.original - this.getKPISendingEmailsMissing?.valids) || 0
			}
			// In case the emailContacts request is not ended
			else if (this.getKPIEmailsMissing.KpiReplies !== null) {
				totalExcluded = this.getKPIEmailsMissing.KpiReplies[0].Doc_count
			}
			return totalExcluded
		},

		computedGetToTalOfValidsContacts() {
			return (this.getKPISendingEmailsMissing ? this.getKPISendingEmailsMissing?.valids : (this.totalHit - this.computedGetToTalOfExcludedContacts)) || 0
		},

		computedMailCC: {
			get() {
				return this.mailCC
			},
			set(value) {
				this.mailCC = value
			},
		},

		computedMailReplyTo: {
			get() {
				return this.mailReplyTo
			},
			set(value) {
				this.mailReplyTo = value.trim()
			},
		},

		computedMailObject: {
			get() {
				return this.mailObject
			},
			set(value) {
				this.mailObject = value.trim()
			},
		},

	},

	watch: {
		/// SEND DATA TO HEADER ///
		mailObject: debounce(function (newValue) {
			const objectLength = newValue.trim().length
			this.actionSetEmailObjectLength(objectLength)
		}, 500),

		mailContent: debounce(function (newValue) {
			const charactersCount = new DOMParser().parseFromString(newValue, 'text/html').documentElement
				.textContent.length

			this.actionSetEmailContentCharactersCount(charactersCount)
		}, 500),

		mailReplyTo: debounce(function (newValue) {
			let valid = true
			if (newValue.length > 0) {
				const emailRegexp = new RegExp(String.raw`^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$`)
				valid = emailRegexp.test(newValue)
			}
			this.actionSetIsReplyToValid(valid)
		}, 500),

		getIsRecapDialogVisible(newValue) {
			if (newValue) {
				this.methodOpenRecapDialog('real-sending')

				if (this.advancedBuilderWanted) {
					// Part which trigger builder save to get thumbnail
					this.saveToGetThumbnail = true
					this.beeInstance.save()
				}
			}
		},

		getIsSendTestDialogVisible(newValue) {
			if (newValue) {
				this.methodShowTestEmailModal()
			}
		},

		getTestSendingStatus(newValue) {
			switch (newValue) {
				case 'success': {
					this.$message({
						type: 'success',
						duration: 3000,
						showClose: true,
						message: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.TEST_MESSAGE_SUCCESS'),
					})
					break
				}
				case 'error': {
					this.$message({
						type: 'error',
						duration: 3000,
						showClose: true,
						message: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.TEST_MESSAGE_ERROR'),
					})
					break
				}
				default: {
					break
				}
			}

			if (!this.computedIsAnotherEmailerThanElasticEmail && this.advancedBuilderWanted)
				this.beeInstance.start(this.jsonKeepedToTest)
		},

		getRemoveSelectedTemplateTrigger() {
			this.selectedTemplate = null
		},

		mailCC: debounce(function (newValue) {
			this.$emit('update—mail-cc', newValue)
		}, 500),

		getTempValue: {
			handler(newValue, oldValue) {
				if (newValue) {
					if (Object.prototype.hasOwnProperty.call(newValue, 'selectedTemplateTypology')) {
						this.selectedTemplateTypology = newValue?.selectedTemplateTypology
					}

					if (Object.prototype.hasOwnProperty.call(newValue, 'advancedEditorTypology')) {
						this.selectedEditorTypology = newValue?.advancedEditorTypology
					}

					if (!oldValue?.editorSaveAutoTrigger && newValue?.editorSaveAutoTrigger) {
						this.isAutoSaveFromRouterGuard = true
						this.beeInstance.save()
					}

					if (Object.prototype.hasOwnProperty.call(newValue, 'contentBeenChanged')) {
						this.contentBeenChanged = newValue.contentBeenChanged
					}

					if (Object.prototype.hasOwnProperty.call(newValue, 'advancedBuilderWanted')) {
						this.advancedBuilderWanted = newValue.advancedBuilderWanted
					}

					if (Object.prototype.hasOwnProperty.call(newValue, 'resetBasicEditorData')) {
						this.resetData(true)
					}
				}
			},
			deep: true,
		},

		getProviderCampaignStatusesUnformated: {
			handler(newValue) {
				if (newValue.some((step) => step.status === 'error')) {
					this.recapStep = 'campaign-error'
				}
				else if (newValue.every((step) => step.status === 'done')) {
					setTimeout(() => {
						this.recapStep = 'campaign-sent'
					}, 2000)
				}
			},
			deep: true,
		},

		getValidatedEmailerSenders(newValue) {
			if (newValue) this.methodSetSender()
		},
	},

	created() {
		this.entryPointRoute = this.$router.options.history.state.back || 'contacts'
	},

	beforeMount() {
		this.actionSetEmailsTemplates()
		this.advancedBuilderWanted = true
	},

	async mounted() {
		/**
		 * Assign from temp-store the filters and the number of contacts selected
		 */
		if (this.computedIsAnotherEmailerThanElasticEmail) {
			this.actionGetSenders()
		}

		if (this.getTempValue) {
			const totalHitCheckVerification
				= this.getTempValue.filterSelected?.contact_ids?.length
				|| this.getTempValue.totalContactsSelected
			const areContactsManuallySelected = Boolean(this.getTempValue.filterSelected?.contact_ids?.length)
			const verification = await filtersVerificationByKPI(
				this.getTempValue.filterSelected,
				totalHitCheckVerification,
				areContactsManuallySelected,
			)

			if (!verification) {
				this.$message({
					type: 'error',
					duration: 3000,
					showClose: true,
					message: this.$t('APP.SIGNUP.ERROR'),
				})
				this.$router.replace({ name: 'contact-list' })

				return
			}

			if (!this.computedIsAnotherEmailerThanElasticEmail) this.actionGetEmailerPrices()

			this.methodSetSender()
			const keys = [...this.getActiveStaticKeysAvailables]
				.filter(
					(key) =>
						key.type === 'firstname'
						|| key.type === 'married_name'
						|| key.type === 'surname'
						|| key.type === 'mail',
				)

			this.keysAvailable = keys.map((key) => {
				return {
					name: `{${key.label}}`,
					value: `{{${key.type}}}`,
				}
			})
			this.filterSelected = this.getTempValue.filterSelected

			this.totalHit = Object.prototype.hasOwnProperty.call(this?.getTempValue?.filterSelected, 'contact_ids') ? this.getTempValue.filterSelected.contact_ids.length : this.getTempValue.totalContactsSelected

			if (this.filterSelected) {
				// Reset kpi in the store before retrieving them so the loader is displayed
				this.actionResetEmailMissingKpi()
				this.actionGetContactsSearchEmailsKpi(this.filterSelected)
			}

			setTimeout(() => {
				this.actionSetMenuCollapse(true)
			}, 500)
		}

		this.actionSetNewTempValue({
			...this.getTempValue,

			selectedTemplate: false,
		})
	},

	methods: {
		methodIsAccessGranted,
		...mapActions('@emailer', [
			'actionSendEmailWithFilters',
			'actionSetEmailContentCharactersCount',
			'actionSetEmailObjectLength',
			'actionUpdateIsSendingSucceed',
			'actionUpdateEmailerCredits',
			'actionGetEmailerPrices',
			'actionCreateNewTemplate',
			'actionSetEmailsTemplates',
			'actionEditTemplate',
			'actionRemoveSelectedTemplateTrigger',
			'actionGetSenders',
			'actionSetRecapDialogVisibility',
			'actionSetTestEmailDialogVisibility',
			'actionSetTestEmailList',
			'actionGetEmailTmpThumbnail',
			'actionSetIsReplyToValid',
		]),
		...mapActions('@temp', ['actionCleanTempValue', 'actionSetNewTempValue']),
		...mapActions('@navBar', ['actionSetMenuCollapse']),
		...mapActions('@kpi', ['actionGetContactsSearchEmailsKpi', 'actionResetEmailMissingKpi']),

		resetData(autoResetFromRouterGuard = false) {
			this.mailObject = ''
			this.mailContent = ''

			if (autoResetFromRouterGuard) {
				const temporaryValue = cloneDeep(this.getTempValue)

				delete temporaryValue.resetBasicEditorData
				this.actionSetNewTempValue({
					...temporaryValue,
				})
				this.mailCC = []
				this.mailReplyTo = ''
			}
		},

		async prepareEmailSending(htmlFromBuilder = '', isJustToGetHtml = false) {
			this.stateCTASender = 'loading'
			// Wait 2000ms
			await new Promise((resolve) => setTimeout(resolve, 2000))

			let mailContent = htmlFromBuilder

			if (!this.advancedBuilderWanted) {
				let placeholder = document.createElement('div')

				placeholder.innerHTML = cloneDeep(mailContent)
				const imgs = placeholder.querySelectorAll('img')

				if (imgs.length > 0)
					for (const img of imgs) {
						img.style.width = '100%'
					}

				if (this.linkToAddOnImage.length > 0) {
					placeholder = placeholder.outerHTML
					for (const imgToLink of this.linkToAddOnImage) {
						placeholder = placeholder.replace(
							`<img src="${imgToLink.src}" style="width: 100%;">`,
							`<a href="${imgToLink.linkUrl}" target="_blank"><img title="${imgToLink.linkUrl}" src="${imgToLink.src}" style="width: 100%;"></a>`,
						)
					}
				}

				mailContent = this.linkToAddOnImage.length > 0 ? placeholder : placeholder.outerHTML
			}

			if (isJustToGetHtml) {
				this.emailHtml = mailContent
				return
			}

			const payload = {
				email: {
					mailObject: mailContent,
					mailContent: this.mailObject,
				},
				filters: {
					...this.filterSelected,
				},
			}

			payload.email.sender_name = this.computedGetSenderNameByEmailer
			payload.email.sender = this.sender

			if (this.mailCC.length > 0) {
				payload.email.cc = this.mailCC[0].trim()
			}

			if (this.mailReplyTo.length > 0) {
				payload.email.reply_to = this.mailReplyTo
			}

			if (this.selectedTemplate) {
				payload.email.template = this.selectedTemplate
			}

			if (this.getEmailerAccount && this?.getEmailerAccount?.subscription?.automatic_upsell) {
				const isShouldUpsell
					= this.computedGetEmailsBalanceLeft < 0 && this.computedIsUpsellAutomatically

				if (isShouldUpsell) {
					const total
						= this.getTestEmailList && this.getTestEmailList.length > 0
							? this.getTestEmailList.length
							: this.computedGetToTalOfValidsContacts

					const tierToUpsell = this.getEmailerPrices.tiers.find(
						(tier) =>
							tier.quantity >= total
							&& tier.quantity > this.getEmailerAccount.credits_refill?.amount,
					)

					if (tierToUpsell) {
						await this.actionUpdateEmailerCredits({
							['isUpsell']: true,
							newVolume: tierToUpsell.quantity,
						})
							.catch((error) => {
								if (error.status === 402)
									this.$message({
										type: 'error',
										duration: 3000,
										showClose: true,
										message: this.$t(
											'CONTACT.HEADER.BULK_EMAILS.EDITOR.SEND_WITH_UPSELL_MESSAGES.ERROR',
										),
									})
							})
							.then(() => {
								this.$message({
									type: 'success',
									duration: 2000,
									showClose: true,
									message: this.$t(
										'CONTACT.HEADER.BULK_EMAILS.EDITOR.SEND_WITH_UPSELL_MESSAGES.SUCESS',
									),
								})
								this.methodSendEmail(payload)
							})
					}

					return
				}
			}

			this.methodSendEmail(payload)
		},

		methodSendEmail(payload) {
			if (this.getTestEmailList && this.getTestEmailList?.length > 0) {
				this.actionSendEmailWithFilters(payload)
					.catch(() => {
						this.stateCTASender = 'init'
						this.actionUpdateIsSendingSucceed('default')
					})
					.then(() => {
						this.stateCTASender = 'init'
						this.actionSetNewTempValue({
							...this.getTempValue,
							contentBeenChanged: false,
						})
						this.contentBeenChanged = false
						this.actionUpdateIsSendingSucceed('default')
					})
			}
			else {
				this.actionUpdateIsSendingSucceed('loading')
				this.actionSendEmailWithFilters(payload)
					.catch(() => {
						this.stateCTASender = 'init'
						this.$router.push({ name: 'contacts' })
						this.actionSetNewTempValue({
							...this.getTempValue,
							contentBeenChanged: false,
						})
						this.contentBeenChanged = false
						this.actionUpdateIsSendingSucceed('default')
						if (!this.computedIsProviderProvideCampaignSendingStatuses) {
							this.recapStep = 'campaign-error'
						}
						this.resetData()
					})
					.then(() => {
						this.stateCTASender = 'init'
						this.actionSetNewTempValue({
							...this.getTempValue,
							contentBeenChanged: false,
						})
						this.contentBeenChanged = false
						this.actionUpdateIsSendingSucceed('success')
						if (!this.computedIsProviderProvideCampaignSendingStatuses) {
							this.recapStep = 'campaign-sent'
						}
						this.resetData()
					})
			}
		},

		methodAddLinkToImage(payload) {
			const index = this.linkToAddOnImage.findIndex((img) => img.src == payload.src)

			if (index !== -1) {
				if (payload.linkUrl == '' || payload.linkUrl.length <= 3) {
					this.linkToAddOnImage.splice(index, 1)
					this.methodNotifyLinkAdd(this.$t('MAILING.MESSAGE_DELETE_LINK_SUCCESS'))

					return
				}

				this.$set(this.linkToAddOnImage[index], 'linkUrl', payload.linkUrl)
				this.methodNotifyLinkAdd(this.$t('MAILING.MESSAGE_MODIFY_LINK_SUCCESS'))

				return
			}

			this.linkToAddOnImage.push(payload)
			this.methodNotifyLinkAdd(this.$t('MAILING.MESSAGE_ADD_LINK_SUCCESS'))
		},

		methodNotifyLinkAdd(message) {
			this.$message({
				type: 'success',
				duration: 2000,
				showClose: true,
				message,
			})
		},

		methodFormatDate(date) {
			const newDate = new Date(date)

			return formatDayMonthDate(newDate)
		},

		methodFormatTime(time) {
			const newTime = new Date(time)

			return formatTime(newTime)
		},

		methodGetTimeFromDataOrTime(date) {
			return new Date(date).getTime()
		},

		methodCleanDelayedSending() {
			this.delayedDay = ''
			this.delayedHour = ''
		},

		methodGoToReport() {
			this.methodCleanPageBeforeRouteLeave()
			this.$router.push({ name: 'contact-list' })
		},

		methodSetSender() {
			if (!this.getValidatedEmailerSenders) {
				this.sender = ''

				return
			}

			let defaultSender = this.getValidatedEmailerSenders.find((sender) => sender?.default_flag)

			if (!defaultSender)
				defaultSender = this.getValidatedEmailerSenders.find((sender) => sender?.validated)

			this.sender = cloneDeep(defaultSender.email)
		},

		methodCleanPageBeforeRouteLeave() {
			this.showPreventEditionLossDialog = false
			this.actionCleanTempValue()
			this.actionSetEmailObjectLength(0)
			this.actionSetIsReplyToValid(true)
			this.actionUpdateIsSendingSucceed('default')
			this.actionSetRecapDialogVisibility(false)
			this.actionSetTestEmailDialogVisibility(false)
			this.resetData()
		},

		methodHandleTemplateSave(mission) {
			switch (mission) {
				case 'save': {
					this.actionEditTemplate(this.templatePayloadToSave)
					this.actionSetNewTempValue({
						...this.getTempValue,
						contentBeenChanged: false,
					})
					break
				}
				case 'copy': {
					const clonedTemplate = { ...this.templatePayloadToSave }

					delete clonedTemplate.id
					this.actionCreateNewTemplate(clonedTemplate)
					this.actionSetNewTempValue({
						...this.getTempValue,
						contentBeenChanged: false,
					})
					break
				}
				default: {
					break
				}
			}
			if (this.recapStep === 'campaign-error') {
				this.$router.go(-1)
			}
			this.showSaveTemplateDialog = false
		},

		// BEEFREE METHODS HERE

		methodSetupBee() {
			const target = document.querySelector('#bee-plugin-container')
			if (!target) {
				setTimeout(() => this.methodSetupBee(), 500)
				return
			}

			this.initializeBeeEditor()
		},

		initializeBeeEditor() {
			const authPayload = this.getBeeAuthPayload()
			const endpoint = 'https://auth.getbee.io/apiauth'

			$.post(endpoint, authPayload).done(this.handleBeeAuthSuccess)
		},

		getBeeAuthPayload() {
			return {
				client_id: BEEFREE_CLIENT_ID,
				client_secret: BEEFREE_CLIENT_SECRET,
				grant_type: 'password',
			}
		},

		handleBeeAuthSuccess(token) {
			const beeConfig = {
				mergeTags: this.getMergeTags(),
				trackChanges: true,
				specialLinks: this.getSpecialLinks(),
				language: this.$i18n.locale.slice(0, 2) === 'fr' ? 'fr-FR' : 'en-US',
				uid: this.getBeeUid(),
				container: 'bee-plugin-container',
				onSave: this.handleBeeSave,
				onSaveAsTemplate: this.handleBeeSaveAsTemplate,
				onSend: this.methodShowTestEmailModal,
				onChange: this.handleBeeChange,
				editorFonts: {
					showDefaultFonts: false,
					customFonts: this.methodGetCustomFonts(),
				},
			}

			globalThis.BeePlugin.create(token, beeConfig, (instance) => {
				this.beeInstance = instance
				this.beeInstance.start(this.selectedTemplate.content_json)
			})
		},

		methodGetCustomFonts() {
			return [
				// Default font builder (need to override to add some font weight) (https://docs.beefree.io/beefree-sdk/other-customizations/advanced-options/font-management)
				{
					name: 'Arial',
					fontFamily: 'Arial, \'Helvetica Neue\', Helvetica, sans-serif',
				},
				{
					name: 'Courier',
					fontFamily: '\'Courier New\', Courier, \'Lucida Sans Typewriter\', \'Lucida Typewriter\', monospace',
				},
				{
					name: 'Georgia',
					fontFamily: 'Georgia, Times, \'Times New Roman\', serif',
				},
				{
					name: 'Helvetica Neue',
					fontFamily: '\'Helvetica Neue\', Helvetica, Arial, sans-serif',
				},
				{
					name: 'Lucida Sans',
					fontFamily: '\'Lucida Sans Unicode\', \'Lucida Grande\', \'Lucida Sans\', Geneva, Verdana, sans-serif',
				},
				{
					name: 'Tahoma',
					fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
				},
				{
					name: 'Times New Roman',
					fontFamily: 'TimesNewRoman, \'Times New Roman\', Times, Beskerville, Georgia, serif',
				},
				{
					name: 'Trebuchet MS',
					fontFamily: '\'Trebuchet MS\', \'Lucida Grande\', \'Lucida Sans Unicode\', \'Lucida Sans\', Tahoma, sans-serif',
				},
				{
					name: 'Verdana',
					fontFamily: 'Verdana, Geneva, sans-serif',
				},
				{
					name: 'ヒラギノ角ゴ Pro W3',
					fontFamily: 'ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif',
				},
				{
					name: 'メイリオ',
					fontFamily: 'メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, sans-serif',
				},
				{
					name: 'Bitter',
					fontFamily: '\'Bitter\', Georgia, Times, \'Times New Roman\', serif',
					url: 'https://fonts.googleapis.com/css?family=Bitter:100,400,700,900',
					fontWeight: {
						100: 'Thin',
						400: 'Regular',
						700: 'Bold',
						900: 'Black',
					},
				},
				{
					name: 'Droid Serif',
					fontFamily: '\'Droid Serif\', Georgia, Times, \'Times New Roman\', serif',
					url: 'https://fonts.googleapis.com/css?family=Droid+Serif',
				},
				{
					name: 'Lato',
					fontFamily: '\'Lato\', Tahoma, Verdana, Segoe, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900',
					fontWeight: {
						100: 'Thin',
						300: 'Light',
						400: 'Regular',
						700: 'Bold',
						900: 'Black',
					},
				},
				{
					name: 'Open Sans',
					fontFamily: '\'Open Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800',
					fontWeight: {
						300: 'Light',
						400: 'Regular',
						500: 'Medium',
						600: 'SemiBold',
						700: 'Bold',
						800: 'ExtraBold',
					},
				},
				{
					name: 'Roboto',
					fontFamily: '\'Roboto\', Tahoma, Verdana, Segoe, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900',
					fontWeight: {
						100: 'Thin',
						200: 'ExtraLight',
						300: 'Light',
						400: 'Regular',
						500: 'Medium',
						600: 'SemiBold',
						700: 'Bold',
						800: 'ExtraBold',
						900: 'Black',
					},
				},
				{
					name: 'Source Sans Pro',
					fontFamily: '\'Source Sans Pro\', Tahoma, Verdana, Segoe, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,900',
					fontWeight: {
						400: 'Regular',
						700: 'Bold',
						900: 'Black',
					},
				},
				{
					name: 'Montserrat',
					fontFamily: '\'Montserrat\', \'Trebuchet MS\', \'Lucida Grande\', \'Lucida Sans Unicode\', \'Lucida Sans\', Tahoma, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900',
					fontWeight: {
						100: 'Thin',
						200: 'ExtraLight',
						300: 'Light',
						400: 'Regular',
						500: 'Medium',
						600: 'SemiBold',
						700: 'Bold',
						800: 'ExtraBold',
						900: 'Black',
					},
				},
				{
					name: 'Ubuntu',
					fontFamily: '\'Ubuntu\', Tahoma, Verdana, Segoe, sans-serif',
					url: 'https://fonts.googleapis.com/css?family=Ubuntu',
				},
				// New fonts
				{
					name: 'Bebas Neue',
					fontFamily: '\'Bebas Neue\', serif',
					url: 'https://fonts.googleapis.com/css2?family=Bebas+Neue',
				},
				{
					name: 'Didot',
					fontFamily: 'Didot, Georgia, \'Times New Roman\', serif',
				},
				{
					name: 'Futura',
					fontFamily: 'Futura, \'Trebuchet MS\', Arial, \'Helvetica Neue\', sans-serif',
				},
				{
					name: 'Helvetica',
					fontFamily: 'Helvetica, \'Helvetica Neue\', Arial, sans-serif',
					fontWeight: {
						300: 'Light',
						400: 'Regular',
						700: 'Bold',
					},
				},
				{
					name: 'Inter',
					fontFamily: '\'Inter\', serif',
					url: 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900',
					fontWeight: {
						100: 'Thin',
						200: 'ExtraLight',
						300: 'Light',
						400: 'Regular',
						500: 'Medium',
						600: 'SemiBold',
						700: 'Bold',
						800: 'ExtraBold',
						900: 'Black',
					},
				},
				{
					name: 'Poppins',
					fontFamily: 'Poppins, serif',
					url: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900',
					fontWeight: {
						100: 'Thin',
						200: 'ExtraLight',
						300: 'Light',
						400: 'Regular',
						500: 'Medium',
						600: 'SemiBold',
						700: 'Bold',
						800: 'ExtraBold',
						900: 'Black',
					},
				},
				{
					name: 'Baskerville',
					fontFamily: 'Baskerville, \'Times New Roman\', serif',
				},
				{
					name: 'Impact',
					fontFamily: 'Impact, \'Arial Black\', sans-serif',
					fontWeight: {
						400: 'Regular',
					},
				},
			]
		},

		getMergeTags() {
			return [
				...this.keysAvailable,
				{
					name: `{${this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.UNSUBSCRIBE_LINK')}}`,
					value: '{{unsubscribe}}',
				},
				{
					name: `{${this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.VIEW_ONLINE_LINK')}}`,
					value: '{{view}}',
				},
			]
		},

		getSpecialLinks() {
			return [
				{
					type: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.FREQUENTLY_USED'),
					label: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.UNSUBSCRIBE_LINK'),
					link: '{{unsubscribe}}',
				},
				{
					type: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.FREQUENTLY_USED'),
					label: this.$t('CONTACT.HEADER.BULK_EMAILS.EDITOR.SPECIAL_LINKS.VIEW_ONLINE_LINK'),
					link: '{{view}}',
				},
			]
		},

		getBeeUid() {
			return this.info_campagne?.befree_uuid || id.toString().padStart(3, '0')
		},

		handleBeeSave(jsonFile, htmlFile) {
			if (this.saveToGetThumbnail) {
				return this.handleThumbnailSave(htmlFile)
			}

			if (this.saveToGetHtml || this.saveToGetHtmlAndSave) {
				return this.handleHtmlSave(jsonFile, htmlFile)
			}

			this.handleTemplateSave(jsonFile, htmlFile)
		},

		handleThumbnailSave(htmlFile) {
			this.methodGetEmailTmpThumbnail(htmlFile)
			this.saveToGetThumbnail = false
		},

		handleHtmlSave(jsonFile, htmlFile) {
			if (this.saveToGetHtml) {
				this.jsonKeepedToTest = jsonFile
				this.prepareEmailSending(htmlFile)
				this.saveToGetHtml = false
			}

			if (this.saveToGetHtmlAndSave) {
				const clonedTemplate = {
					...this.selectedTemplate,
					group_id: this.info_campagne.id,
					content_html: htmlFile,
					content_json: jsonFile,
				}
				delete clonedTemplate.id
				this.actionCreateNewTemplate(clonedTemplate)
				this.saveToGetHtmlAndSave = false
			}
		},

		handleTemplateSave(jsonFile, htmlFile) {
			this.templatePayloadToSave = this.buildTemplatePayload(jsonFile, htmlFile)

			if (this.selectedTemplate?.id) {
				return this.handleExistingTemplateSave()
			}

			this.handleNewTemplateSave()
		},

		buildTemplatePayload(jsonFile, htmlFile) {
			return {
				...this.selectedTemplate,
				name: this.getTemplateName(),
				group_id: this.info_campagne.id,
				content_html: htmlFile,
				content_json: jsonFile,
			}
		},

		getTemplateName() {
			const { name } = this.selectedTemplate
			return name?.includes('DEFAULT_QOMON_TEMPLATES_NAMES')
				? this.$t(name || '')
				: name || ''
		},

		handleExistingTemplateSave() {
			if (this.isAutoSaveFromRouterGuard) {
				this.actionEditTemplate(this.templatePayloadToSave)
				this.cleanupAfterAutoSave()
				return
			}
			this.showSaveTemplateDialog = true
		},

		handleNewTemplateSave() {
			this.actionCreateNewTemplate(this.templatePayloadToSave)
			if (this.isAutoSaveFromRouterGuard) {
				this.actionRemoveSelectedTemplateTrigger()
			}
			this.cleanupAfterSave()
		},

		cleanupAfterAutoSave() {
			this.isAutoSaveFromRouterGuard = false
			this.actionRemoveSelectedTemplateTrigger()
			this.$emit('update-visiblity-of-bulk-emails-panel', false)
			this.actionSetNewTempValue({
				...this.getTempValue,
				contentBeenChanged: false,
				editorSaveAutoTrigger: false,
			})
		},

		cleanupAfterSave() {
			this.actionSetNewTempValue({
				...this.getTempValue,
				contentBeenChanged: false,
				editorSaveAutoTrigger: false,
			})
			this.isAutoSaveFromRouterGuard = false
		},

		handleBeeSaveAsTemplate() {
			this.saveToGetHtmlAndSave = true
			this.beeInstance.save()
		},

		handleBeeChange() {
			this.actionSetNewTempValue({
				...this.getTempValue,
				contentBeenChanged: true,
			})
			this.contentBeenChanged = true
		},

		// BEEFREE METHODS END

		methodSendTriggered(callback) {
			if (!this.advancedBuilderWanted) {
				this.prepareEmailSending(this.mailContent)
				callback()
				return
			}
			this.saveToGetHtml = true
			this.beeInstance.save()

			callback()
		},

		methodTemplateHasBeenSelected(editorType) {
			this.$emit('update-visiblity-of-bulk-emails-panel', true)
			setTimeout(() => {
				editorType !== 'basic' && this.methodSetupBee()
				window.scrollTo({ top: 0, behavior: 'smooth' })
			}, 1000)
		},

		/// RECAP DIALOG START ///
		methodCallBackRefundDialog() {
			switch (this.recapDialogMission) {
				case 'real-sending': {
					const callback = () => {
						// Track email sent
						analytics.track(TRACKING.email_sent, { nb_recipients: this.computedGetToTalOfValidsContacts })

						if (this.computedIsProviderProvideCampaignSendingStatuses) {
							this.recapStep = 'sending-campaign'
						}
					}

					this.methodSendTriggered(callback)

					break
				}
				case 'test': {
					this.methodSendTest()
					this.showRecapDialog = false

					break
				}
			}
		},

		methodOpenRecapDialog(mission) {
			this.recapDialogMission = mission
			this.showRecapDialog = !this.showRecapDialog
		},

		methodHandleRecapDialogClose() {
			this.showRecapDialog = false
			this.actionSetRecapDialogVisibility(false)
			if (this.isSendingSucceed === 'success') {
				if (this.recapStep === 'campaign-sent') {
					this.$router.push({ path: this.entryPointRoute })
				}
				if (this.recapStep === 'campaign-error') {
					this.beeInstance.save()
				}
			}
		},

		methodGetKPIEmailsSubstracted() {
			const kpis = this.getKPISendingEmailsMissing || {}

			const kpiMapping = {
				blacklisted: 'blacklisted',
				no_consent: 'unsubscribe',
				group_optin: 'unsubscribe',
				duplicate: 'duplicate',
				invalid_address: 'invalid_address',
				invalid_mx: 'invalid_domain',
			}

			const kpisArrayFormated = Object.keys(kpis || {})
				.filter((key) => key !== 'original' && key !== 'valids')
				.map((key) => ({
					count: kpis[key] || 0,
					label: kpiMapping[key] ? kpiMapping[key].toUpperCase() : key.toUpperCase(),
				}))
				.sort((a, b) => b?.count - a?.count)

			return kpisArrayFormated
		},

		methodGetProviderCampaignStepLabel(stepName) {
			const stepLabel = `CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.BREVO_CAMPAIGN_SENDING_DIALOG.${stepName.toUpperCase()}`
			return this.$t(stepLabel)
		},

		methodGetProviderCampaignStatusLabel(status) {
			const statusLabel = `CONTACT.HEADER.BULK_EMAILS.RECAP_DIALOG.BREVO_CAMPAIGN_SENDING_DIALOG.STATUS.${status.toUpperCase()}`
			return this.$t(statusLabel)
		},
		/// RECAP DIALOG END ///

		/// TEST EMAIL DIALOG START ///
		methodSendTest() {
			this.actionSetTestEmailList(this.testEmailsList)

			const callback = () => {
				this.showTestEmailDialog = false
				this.isTestAlreadySent = true
			}

			this.methodSendTriggered(callback)
		},

		methodShowTestEmailModal() {
			if (!this.testEmailsList.includes(this.userConnected.mail))
				this.testEmailsList.push(this.userConnected.mail)

			this.showTestEmailDialog = true
		},

		methodHandleTestEmailDialogClose() {
			this.testEmailsList = []
			this.actionSetTestEmailDialogVisibility(false)
		},

		async methodGetEmailTmpThumbnail(contentHtml = null) {
			const payload = {
				emailer_template: {
					group_id: this.info_campagne.id,
					content_html: contentHtml,
				},
			}

			this.getThumbnailLoading = true
			const res = await this.actionGetEmailTmpThumbnail(payload)

			this.tmpThumbnail = res?.base64_thumbnail
			this.getThumbnailLoading = false
		},
		/// TEST EMAIL DIALOG END ///

		methodOpenChat() {
			openChat()
		},
	},

	beforeUnmount() {
		this.actionCleanTempValue()
	},
}
</script>

<style lang="scss">
@import 'src/assets/scss/shared/shared-constants.scss';
.full-container {
	display: flex;
	height: 100%;
	flex-direction: row;
	width: 100%;
	padding-top: 1.75rem;
	overflow: visible;
}
.mail-helper {
	width: 33.33%;
	display: flex;
	flex-direction: column;
	height: fit-content;
}
.balance-alert {
	color: rgba(255, 0, 0, 0.726);
}
.mail-preview-container {
	display: flex;
	flex-direction: column;
}
.divider {
	margin-top: 0.5rem;
	border-bottom: 1px solid rgba(128, 128, 128, 0.274);
}
.textError {
	color: #e02969;
}

.sub-container {
	width: 33%;
}

.delete-programmed-sending {
	transition: all ease 0.4s;
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 9999px;
	background-color: #fce7f3;
	opacity: 0;
	transform: translateX(20px) translateY(-20px);
	cursor: pointer;
}

.edit-programmed-sending {
	transition: all ease 0.4s;
	position: absolute;
	top: -0.5rem;
	left: -0.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 9999px;
	background-color: #d1fae5;
	opacity: 0;
	transform: translateX(-20px) translateY(-20px);
	cursor: pointer;
}

.programmed-sending-card:hover {
	.delete-programmed-sending {
		opacity: 100;
		transform: translateX(0) translateY(0);
	}
	.edit-programmed-sending {
		opacity: 100;
		transform: translateX(0) translateY(0);
	}
}

@media only screen and (max-width: 850px) {
	.full-container {
		flex-direction: column;
	}
	.mail-helper {
		width: 100%;
		margin-bottom: 1rem;
	}
	.sub-container {
		width: 100%;
	}
}

.send-svg {
	filter: drop-shadow(10px 22px 1px rgb(0 0 0 / 0.1));
}

.custom-email-recap-sending-dialog {
	@apply pr-0 pb-0 pt-6 pl-10 overflow-hidden;

	.el-dialog {
		@apply h-[80vh] max-h-[800px]; /* Hauteur fixe pour le dialogue */
		display: flex;
		flex-direction: column;
	}

	.el-dialog__header {
		@apply pb-0;
	}

	.el-dialog__body {
		@apply flex-1 overflow-hidden;
		height: calc(100% - 60px); /* Hauteur totale moins header */
	}
}

@media only screen and (max-width: 1050px) {
	.custom-email-recap-sending-dialog {
		@apply px-0 pb-0 pt-6 overflow-hidden;

		.el-dialog {
			@apply h-[80vh] max-h-[800px]; /* Hauteur fixe pour le dialogue */
			display: flex;
			flex-direction: column;
		}

		.el-dialog__header {
			@apply pb-0;
		}

		.el-dialog__body {
			@apply flex-1 overflow-hidden;
			height: calc(100% - 60px); /* Hauteur totale moins header */
		}
	}
}

.custom-email-campaign-sending-dialog {
	@apply px-10 py-8 overflow-hidden;

	.el-dialog {
		@apply h-[80vh] max-h-[800px]; /* Hauteur fixe pour le dialogue */
		display: flex;
		flex-direction: column;
	}

	.el-dialog__header {
		@apply pb-0;
	}
}
</style>
