import get from 'lodash/get'
import mandates_store from '@/models/mandates_store'
import { RESET_MANDATES_STORE, SET_MANDATES, SET_MANDATES_ORGANIZATIONS, SET_MANDATES_TITLES } from './_mutation-types'
import { cloneDeep, remove } from 'lodash'

/**
 * @store @mandates
 * @namespaced
 * @description Mandates related info and store module
 */

const getDefaultState = () => {
	return {
		mandates: [],
		organisations: [],
		titles: [],
	}
}
const state = getDefaultState

const mutations = {
	[RESET_MANDATES_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},
	[SET_MANDATES]: (state, res) => {
		state.mandates = res
	},
	[SET_MANDATES_ORGANIZATIONS]: (state, res) => {
		state.organisations = res
	},
	[SET_MANDATES_TITLES]: (state, res) => {
		state.titles = res
	},
}

const actions = {
	actionResetMandates({ commit }) {
		commit(RESET_MANDATES_STORE)
	},

	actionRetrieveMandates: async ({ commit }, { contactId, saveInStore = true }) => {
		const res = await mandates_store.getMandates(contactId)
		if (saveInStore) {
			commit(SET_MANDATES, res.mandates)
		}
		return res.mandates
	},

	actionCreateMandate: async (
		{ state, commit },
		{ contactId, mandateData, saveInStore = true },
	) => {
		// If there is only one mandate create an array for the back
		if (!Array.isArray(mandateData)) mandateData = [mandateData]
		await mandates_store.createMandate({ mandates: mandateData }, contactId).then((res) => {
			if (saveInStore) {
				// Add new mandate to the list
				let clonedMandatesList = cloneDeep(state?.mandates)
				res.mandates.forEach((mandate) => {
					clonedMandatesList.push(mandate)
				})
				commit(SET_MANDATES, clonedMandatesList)
			}
		})
	},

	actionUpdateMandate({ state, commit }, { contactId, mandateData, saveInStore = true }) {
		// If there is only one mandate create an array for the back
		if (!Array.isArray(mandateData)) mandateData = [mandateData]
		mandates_store.updateMandate({ mandates: mandateData }, contactId).then((res) => {
			if (saveInStore) {
				// Update the mandate in the list
				let clonedMandatesList = cloneDeep(state?.mandates)
				res.mandates.forEach((mandate) => {
					remove(clonedMandatesList, (clonedMandate) => clonedMandate.id === mandate.id)
					clonedMandatesList.push(mandate)
				})
				commit(SET_MANDATES, clonedMandatesList)
			}
		})
	},

	actionDeleteMandate({ state, commit }, { contactId, mandateData, saveInStore = true }) {
		// If there is only one mandate create an array for the back
		if (!Array.isArray(mandateData)) mandateData = [mandateData]
		mandates_store.deleteMandate({ mandates: mandateData }, contactId).then(() => {
			if (saveInStore) {
				// Remove the mandate from the list
				let clonedMandatesList = cloneDeep(state?.mandates)
				mandateData.forEach((mandate) => {
					remove(clonedMandatesList, (clonedMandate) => clonedMandate.id === mandate.id)
				})
				commit(SET_MANDATES, clonedMandatesList)
			}
		})
	},

	actionDeleteAllMandates({ commit }, { contactId, saveInStore = true }) {
		mandates_store.deleteAllMandates(contactId).then(() => {
			if (saveInStore) {
				// Remove all the mandates from the list
				commit(SET_MANDATES, [])
				return []
			}
			else {
				return
			}
		})
	},

	actionGetTitle: async ({}, { value, gender }) => {
		let res = await mandates_store.getTitle(value, gender)
		return res
	},

	actionGetTitles: async ({}, { search, gender }) => {
		if (!gender || (gender !== 'M' && gender !== 'F')) gender = 'O'
		let res = await mandates_store.getTitles(search, gender)
		return res
	},

	actionGetOrganisations: async ({}, { search }) => {
		let res = await mandates_store.getOrganisations(search)
		return res
	},

	actionFetchOrganizations: async ({ commit }) => {
		return await mandates_store.getOrganisations().then((res) => {
			const organizations = get(res, 'organisations', [])
			commit(SET_MANDATES_ORGANIZATIONS, organizations)
		})
	},

	actionFetchTitles: async ({ commit }) => {
		return await mandates_store.getTitles().then((res) => {
			const titles = get(res, 'titles_whole_obj', [])
			commit(SET_MANDATES_TITLES, titles)
		})
	},
}

const getters = {
	getMandates: (state) => state.mandates,
	getMandatesTitles: (state) => state.titles.map((title) => {
		return {
			id: title.id,
			label: title.neutral,
			value: title.id,
		}
	}),
	getMandatesOrganisations: (state) => state.organisations.map((org) => {
		return {
			id: org.id,
			label: org.name,
			value: org.id,
		}
	}),
}

export const mandatesModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
