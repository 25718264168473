<template>
	<div
		id="paramsBar"
		:class="`params-bar ${paramsBar.show === null ? '' : paramsBar.show ? '--show' : '--hide'}`"
	>
		<div
			v-if="paramsBar.selection === null"
			class="flex flex-col"
		>
			<button
				type="button"
				class="gui-close text-2xl ml-auto px-1 rounded-full closeIconEffect"
				@click="() => closeMethod()"
			/>
			<p
				class="font-title font-bold mb-3"
				style="font-size: 26px"
			>
				{{ $t('CREATE_FORM.SETTINGS.TITLE') }}
			</p>
			<p class="text-gray-strong mb-8">
				{{ paramsSubtitle }}
			</p>

			<ul>
				<template
					v-for="(param, index) in params"
					:key="`param-${index}`"
				>
					<li
						v-if="!param.hidden"
						class="cursor-pointer mb-4 transition-all hover:bg-gray-100 py-1 px-2 rounded-lg scaleEffect flex justify-between items-center"
						:class="param.displayOnlyOnMobile ? 'flex lg:hidden' : ''"
						@click="() => actionSetParamsBar({ show: true, selection: param.name })"
					>
						<p class="font-title text-2xl">
							{{ param?.hidden ? '' : param.title }}
						</p>

						<p
							v-if="param.name === 'legal' && !getPrivacyPolicyUrl"
							class="items-center rounded-md hidden md:flex gap-1 text-yellow-500 bg-yellow-50"
							style="font-size: 10px; height: max-content; padding: 3px 5px"
						>
							<i class="gui-stars gui-stars-yellow" />
							{{ $t('CREATE_FORM.CONFIGURE') }}
						</p>
					</li>
				</template>
			</ul>
		</div>
		<div v-else>
			<div
				class="w-full flex justify-between"
				:class="[
					{
						'mb-9': !computedParam.hidden && formType !== 'online-forms',
						'flex lg:hidden': computedParam.displayBackOnlyOnMobile,
					},
				]"
			>
				<button
					v-if="!computedParam.hidden"
					type="button"
					class="flex items-center pl-1 pr-2 rounded-full closeIconEffect"
					@click="() => (isFormValid ? backMethod() : focusRequiredInput())"
				>
					<i class="gui-back text-2xl mr-2" />
					<span class="text-sm">{{ $t('_COMMON.BACK') }}</span>
				</button>
				<button
					v-if="!computedParam.hideCross"
					type="button"
					class="gui-close text-2xl ml-auto px-1 rounded-full closeIconEffect"
					@click="
						() => {
							isFormValid || isOnlyForUi ? closeMethod() : focusRequiredInput()
						}
					"
				/>
			</div>

			<div class="sm:w-3/4 md:w-full flex flex-col mt-6 gap-3">
				<div
					v-if="computedParam.badges && !computedParam.pack"
					class="flex flex-row gap-2"
				>
					<custom-badge
						v-for="badge in computedParam.badges"
						:key="badge.label"
						:label="badge.label"
						:icon="badge.icon"
						:custom-container-class="badge.customContainerClass"
					/>
				</div>
				<div
					:class="
						computedHasStarterPack && 'bg-yellow-pale p-4 border border-yellow-medium rounded-2xl'
					"
					class="flex flex-col gap-3 mb-8"
				>
					<p class="font-title">
						<span style="font-size: 26px; line-height: 30px">{{ computedParam.title }}</span>
						<span
							v-if="computedEditRowParamTitle"
							class="ml-2"
							style="font-size: 26px; line-height: 17px"
						>
							{{ computedEditRowParamTitle }}</span>
					</p>

					<p
						v-if="computedParam.name !== 'legal'"
						ref="paramSubtitle"
						class="text-gray-strong"
					>
						{{ computedParam.subtitle }}
					</p>
					<p
						v-else
						class="bg-yellow-medium bg-opacity-30 border border-yellow-medium rounded-xl p-3 mt-4 mb-6 flex gap-x-2"
						style="font-size: 13px"
					>
						<i class="gui-infos flex text-2xl block h-full -mt-1" />
						<span class="text-gray-strong">{{ computedParam.subtitle }}</span>
					</p>
					<div
						v-if="computedHasStarterPack"
						class="flex items-center justify-start cursor-pointer hover:opacity-75 transition-all"
						@click="methodDisplayUpsellPopin(true)"
					>
						<span class="text-yellow-dark">{{ $t('MENU.UPSELL_POPIN.FIND_OUT_MORE') }}</span>
					</div>
					<popin-upsell
						v-model:is-pop-up-shown="computedIsUpsellPopinShown"
						:source="computedParam === 'colors' ? 'Petitions' : 'Custom-fields'"
					/>
				</div>

				<slot
					v-if="$slots.content"
					name="content"
				/>
				<template v-else>
					<div
						v-for="(input, index) in computedParam.inputs"
						:key="`input-${index}`"
						:class="`mb-6 ${input.order ? `order-${input.order}` : ''}`"
					>
						<el-tooltip
							v-if="computedParam.name === 'legal'"
							:disabled="!input.isDisabled"
							:content="input.disabledMessage"
						>
							<CreateFormInput
								v-if="legalFields"
								v-model:value="legalFields.find((l) => l.type === input.name).label"
								v-model:hidden="legalFields.find((l) => l.type === input.name).hidden"
								v-model:mandatory="legalFields.find((l) => l.type === input.name).mandatory"
								:input-data="input"
							/>
						</el-tooltip>

						<CreateFormInput
							v-else-if="input.name !== 'target_amount'"
							v-model:value="computedForm[input.name]"
							:input-data="input"
						/>

						<CreateFormInput
							v-else-if="input.name === 'target_amount' && computedParam.radio.value === 'manual'"
							v-model:value="computedForm[input.name]"
							:input-data="input"
						/>
					</div>

					<CreateFormSelect
						v-for="(select, index) in computedParam.selects"
						:key="`select-${index}`"
						v-model:value="computedForm[select.name]"
						:select-data="select"
						:custom-css="
							index === 1 ? 'border-t pt-9' : select.order ? `order-${select.order}` : ''
						"
						:populate-tags=" () => populateTags()"
					/>

					<CreateFormRadio
						v-if="computedParam.radio"
						v-model:value="computedParam.radio.value"
						:radio-data="computedParam.radio"
						:class="`mb-6 ${computedParam.radio.order ? `order-${computedParam.radio.order}` : ''}`"
					/>

					<CreateFormHelp
						v-if="computedParam.help"
						:help-data="computedParam.help"
						:custom-css="computedParam.help.order ? `order-${computedParam.help.order}` : ''"
					/>

					<WebIntegrationPart
						v-if="computedParam.name === 'web_integration'"
						:form="computedForm[computedParam.name]"
						:form-type="formType"
						:slug="form.slug"
						:copy="copyText"
					/>

					<SharePart
						v-if="computedParam.name === 'share' && formType === 'petitions'"
						v-model:form="computedForm"
						:param="computedParam"
						:form-type="formType"
						:copy="copyText"
						:is-loading="isLoading"
					/>

					<ColorsPart
						v-if="computedParam.name === 'colors'"
						v-model:form="computedForm"
						:form-type="formType"
						:get-brightness-from-color="getBrightnessFromColor"
						:param="computedParam"
					/>

					<PublishPart
						v-if="computedParam.name === 'publish'"
						v-model:form="computedForm"
						:param="computedParam"
						:copy="copyText"
						:is-publishing="isPublishing"
					/>
					<!--
					<SupportFormPart
						v-if="computedParam.name === 'supportForm'"
						v-model:form="computedForm"
					/> -->

					<CustomCodePart
						v-if="computedParam.name === 'customCode'"
						v-model:form="computedForm"
					/>

					<a
						v-if="computedParam.name === 'i18n'"
						class="cursor-pointer text-pink-main flex items-center gap-x-1 mt-14"
						:class="`order-${computedParam.link.order}`"
						@click="
							() => {
								actionSetParamsBar({ show: true, selection: 'legal' })
								scrollToTop()
							}
						"
					>
						<i class="gui-settings" />
						<span class="underline">{{ computedParam.link.text }}</span>
					</a>
				</template>

				<!-- online forms -->

				<staticDraggableElement
					v-if="computedParam.name === 'onlineFormsDraggableElements'"
					v-model:form="computedForm"
					v-model:stastic-draggable-element-is-moving="computedStasticDraggableElementIsMoving"
					:drag-categories="computedDragCategories"
					:add-order-key="addOrderKey"
				/>

				<edit-online-forms-row
					v-if="computedParam.name === 'editOnlineFormsRow' || computedParam.name === 'editPetitionFormsRow'"
					v-model:current-row-selected="computedCurrentRowSelected"
					v-model:form="computedForm"
					:type-selectable="computedTypeSelectable"
					:default-param-bar-selection="defaultParamBarSelection"
				/>
				<edit-redirection-url
					v-if="computedParam.name === 'confirmation_page' || computedParam.name === 'redirect'"
					v-model:form="computedForm"
					:param="computedParam"
				/>

				<customize-share-part
					v-if="computedParam.name === 'share' && formType === 'online-forms'"
					v-model:form="computedForm"
					:get-brightness-from-color="getBrightnessFromColor"
				/>
				<add-networks
					v-if="computedParam.name === 'networks' && formType === 'online-forms'"
					v-model:form="computedForm"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEqual, find as lodashFind } from 'lodash'
import get from 'lodash/get'
import { defineAsyncComponent } from 'vue'

import CreateFormInput from '../inputs/createFormInput.vue'
import CreateFormSelect from '../inputs/createFormSelect.vue'
import CreateFormRadio from '../inputs/createFormRadio.vue'
import CreateFormHelp from '../inputs/createFormHelp.vue'
import WebIntegrationPart from '../webIntegrationPart.vue'
import SharePart from './sharePart.vue'
import SupportFormPart from './supportFormPart.vue'
import ColorsPart from './colorsPart.vue'
import PublishPart from './publishPart.vue'
import CustomBadge from '../../badges/customBadge.vue'
// online forms
import staticDraggableElement from './online-forms/staticDraggableElements.vue'
import editOnlineFormsRow from './online-forms/editOnlineFormsRow.vue'
import editRedirectionUrl from './online-forms/editRedirectionUrl.vue'
import CustomizeSharePart from './customizeSharePart.vue'
import AddNetworks from './addNetworks.vue'

import CustomCodePart from './CustomCodePart.vue'

const { ClipboardItem } = window

export default {
	components: {
		// petition
		CreateFormInput,
		CreateFormSelect,
		CreateFormRadio,
		CreateFormHelp,
		WebIntegrationPart,
		SharePart,
		SupportFormPart,
		ColorsPart,
		PublishPart,
		CustomBadge,

		// online forms
		staticDraggableElement,
		editOnlineFormsRow,
		editRedirectionUrl,
		CustomizeSharePart,
		AddNetworks,

		CustomCodePart,

		// upsell popin
		PopinUpsell: defineAsyncComponent(() => import('../../packs/upsellPopin.vue')),
	},
	props: {
		params: { type: Array, default: () => [], required: false },
		paramsSubtitle: { type: String, default: '', required: false },
		form: { type: Object, default: () => {}, required: false },
		formType: { type: String, default: '', required: false },
		getBrightnessFromColor: { type: Function, default: () => {}, required: false },
		isLoading: { type: Boolean, required: false, default: false },
		isPublishing: { type: Boolean, required: false, default: false },
		isFormValid: { type: Boolean, required: false, default: false },
		currentRowSelected: { type: Number || null, default: null },
		typeSelectable: { type: Object, default: () => {} },
		defaultParamBarSelection: { type: String || null, default: null },
		paramBarAlwaysOpen: { type: Boolean, default: false },
		stasticDraggableElementIsMoving: { type: Boolean, default: false },
		dragCategories: { type: Object, default: () => {} },
		addOrderKey: { type: Function, default: () => {} },
		isOnlyForUi: { type: Boolean, required: false, default: false },
	},
	emits: ['update:form', 'update:currentRowSelected', 'update:stasticDraggableElementIsMoving'],
	data() {
		return {
			legalFields: [],
			organizationName: null,
			privacyPolicyLabel: null,
			showUpsellPopin: false,
			isUpsellPopinShown: false,
		}
	},
	computed: {
		...mapGetters('@tags', ['getTags']),
		...mapGetters('@form', ['getFormsStatus', 'getFormsStatic', 'getCustomForms']),
		...mapGetters('@createForm', ['paramsBar', 'isConfirmationView']),
		...mapGetters('@privacyPolicy', ['getPrivacyPolicyUrl']),

		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},

		computedMobileField() {
			if (this.form?.fields) return this.form.fields?.find((f) => f.type === 'mobile')

			return null
		},
		computedPhoneField() {
			if (this.form?.fields) return this.form.fields?.find((f) => f.type === 'phone')

			return null
		},

		computedDragCategories() {
			return this.dragCategories
		},

		computedParam() {
			return this.params?.filter((p) => p.name === this.paramsBar.selection)[0] || {}
		},

		computedEditRowParamTitle() {
			if (
				this.computedForm
				&& this.computedForm.fields
				&& this.computedForm.fields[this.computedCurrentRowSelected]
				&& this.computedForm.fields[this.computedCurrentRowSelected].type
			) {
				const keyTypeNeeded = this.computedForm.fields[this.computedCurrentRowSelected].type
				const currentRowSelected = this.computedForm.fields[this.computedCurrentRowSelected]

				const isCustom = this.computedForm.fields[this.computedCurrentRowSelected].custom_id

				if (keyTypeNeeded === 'address' && !isCustom) {
					return this.$t(
						`CREATE_FORM.EDIT_ROW.TYPE_TITLE.${this.computedForm.fields[
							this.computedCurrentRowSelected
						].type.toUpperCase()}`,
					)
				}

				let staticFound

				if (keyTypeNeeded === 'agecategories') {
					staticFound = lodashFind(this.getFormsStatic, { type: 'age' })
				}
				else if (keyTypeNeeded === 'territories') {
					staticFound = lodashFind(this.getFormsStatic, { type: 'address.pollingstation' })
				}
				else if (isCustom) {
					let defaultLabel = cloneDeep(this.getCustomForms).find(
						(e) => e.id === currentRowSelected.custom_id,
					)

					staticFound = { label: defaultLabel?.label || '' }
				}
				else {
					staticFound = lodashFind(this.getFormsStatic, { type: keyTypeNeeded })
				}

				if (staticFound && staticFound.label) {
					return staticFound.label
				}

				return this.$t(`CREATE_FORM.EDIT_ROW.TYPE_TITLE.${keyTypeNeeded.toUpperCase()}`)
			}

			return ''
		},
		computedCurrentRowSelected: {
			get() {
				return this.currentRowSelected
			},
			set(val) {
				this.$emit('update:currentRowSelected', val)
			},
		},
		computedTypeSelectable() {
			return this.typeSelectable
		},

		computedStasticDraggableElementIsMoving: {
			get() {
				return this.stasticDraggableElementIsMoving
			},
			set(val) {
				this.$emit('update:stasticDraggableElementIsMoving', val)
			},
		},

		computedHasStarterPack() {
			if (
				(this.computedParam.name === 'colors'
				&& this.computedParam.formType === 'petitions'
				&& !this.computedParam.pack)
				|| (this.computedParam.name === 'general'
				&& !this.computedParam.pack
				&& this.computedParam.yellowBackground)
			) {
				return true
			}

			return false
		},

		computedIsUpsellPopinShown: {
			get() {
				return this.isUpsellPopinShown
			},
			set(val) {
				this.isUpsellPopinShown = val
			},
		},
	},
	watch: {
		 paramsBar(val) {
			if (val.selection === 'signatories') {
				this.populateTags()
			}

			if (val.selection === 'legal') {
				this.actionSetIsConfirmationView(false)
				this.checkToDisableConsentMobile()
			}

			if (val.selection === 'confirmation_page') {
				this.actionSetIsConfirmationView(true)
			}
		},

		'computedParam.radio.value'(newVal, oldVal) {
			if (newVal === 'default' && oldVal === 'manual') {
				this.computedForm.target_amount = ''
			}
		},

		'computedForm.slug'(val) {
			const specialCharacter = /[^A-Z0-9]/gi

			this.computedForm.slug = val?.replaceAll(specialCharacter, '-') || ''
		},

		legalFields: {
			handler(newValue) {
				if (this.paramsBar.selection === 'legal' && !isEqual(newValue, this.computedForm.legal)) {
					this.computedForm.legal = cloneDeep(newValue)
				}
			},
			deep: true,
		},

		computedMobileField: {
			handler() {
				if (this.paramsBar.selection === 'legal') {
					this.checkToDisableConsentMobile()
				}
			},
			deep: true,
		},
		computedPhoneField: {
			handler() {
				if (this.paramsBar.selection === 'legal') {
					this.checkToDisableConsentMobile()
				}
			},
			deep: true,
		},
	},

	beforeMount() {
		this.checkToDisableConsentMobile()
	},

	mounted() {
		if (this.form?.language) {
			this.organizationName
				= this.$i18n.messages[this.form.language].CREATE_FORM.LEGAL.ORGANIZATION_NAME
			this.privacyPolicyLabel
				= this.$i18n.messages[this.form.language].CREATE_FORM.LEGAL.PRIVACY_POLICY.LABEL
		}

		if (!this.isOnlyForUi) {
			this.setLegalInputsLabels()
		}
	},

	beforeUnmount() {
		this.actionSetSocialNetworkOptions({ color: '', label: true, full: false })
	},

	methods: {
		...mapActions('@tags', ['actionGetTags']),
		...mapActions('@createForm', ['actionSetParamsBar', 'actionSetIsConfirmationView']),
		...mapActions('@onlineForm', ['actionSetSocialNetworkOptions']),

		get,
		focusRequiredInput() {
			const petitionFormInvalid = document.querySelector('.form-invalid-input input')

			petitionFormInvalid?.focus()
		},

		backMethod() {
			if (this.computedCurrentRowSelected !== null) {
				this.computedCurrentRowSelected = null
			}

			this.actionSetParamsBar({ show: true, selection: null })
		},

		populateTags() {
			this.actionGetTags().then(() => {
				this.computedParam.selects[0].options = this.getTags.map((tag) => {
					return { label: tag.name, value: tag.name }
				})
				this.computedParam.selects[1].options = this.getFormsStatus[0]?.refvalues
			})
		},

		closeMethod() {
			if (this.computedCurrentRowSelected !== null) {
				this.computedCurrentRowSelected = null
			}
			else if (this.isConfirmationView) {
				this.actionSetParamsBar({
					show: this.paramBarAlwaysOpen ? this.paramBarAlwaysOpen : false,
					selection: 'confirmation_page',
				})
			}
			else if (this.paramsBar.selection === 'publish') {
				// else if (this.paramsBar.selection === 'publish' && this.formType === 'online-forms')
				this.actionSetParamsBar({ show: null, selection: null })
			}
			else {
				this.actionSetParamsBar({
					show: this.paramBarAlwaysOpen ? this.paramBarAlwaysOpen : false,
					selection: this.defaultParamBarSelection,
				})
			}
		},
		scrollToTop() {
			let container = document.getElementById('paramsBar')

			container.scrollTo(0, 0)
		},

		checkToDisableConsentMobile() {
			if (this.paramsBar?.selection === 'legal') {
				this.legalFields = cloneDeep(this.form.legal || [])
				const legalMobileCheckbox = this.legalFields?.find((l) => l.type === 'consent_mobile')
				const legalMobileParamInput = this.params
					?.find((p) => p.name === 'legal')
					.inputs?.find((i) => i.name === 'consent_mobile')

				if (
					(!this.computedMobileField || this.computedMobileField.hidden)
					&& (!this.computedPhoneField || this.computedPhoneField.hidden)
				) {
					legalMobileCheckbox.hidden = true
					legalMobileParamInput.isDisabled = true
				}
				else {
					legalMobileCheckbox && (legalMobileCheckbox.hidden = false)
					legalMobileParamInput && (legalMobileParamInput.isDisabled = false)
				}

				this.computedForm.legal = this.legalFields
			}
		},

		setLegalInputsLabels() {
			this.legalFields = cloneDeep(this.form.legal || [])
			this.legalFields = this.legalFields.map((l) => ({
				...l,
				label: this.getLegalLabel(l.label),
			}))
		},

		getLegalLabel(label) {
			let legalLabel = label

			if (legalLabel?.includes('{{organization_name}}')) {
				legalLabel = legalLabel.replaceAll('{{organization_name}}', `{{${this.organizationName}}}`)
			}

			if (legalLabel?.includes('{{privacy_policy}}')) {
				legalLabel = legalLabel.replaceAll('{{privacy_policy}}', `{{${this.privacyPolicyLabel}}}`)
			}

			return legalLabel
		},

		copyText(text) {
			navigator.permissions
				.query({ name: 'clipboard-write' })
				.then(() => {
					navigator.clipboard.writeText(text)
					this.$message({
						type: 'success',
						duration: 3000,
						showClose: true,
						center: true,
						message: this.$t('CREATE_FORM.COPIED'),
					})
				})
				.catch(() => {
					const textArea = document.createElement('textarea')

					textArea.value = text
					document.body.appendChild(textArea)
					textArea.select()
					document.execCommand('copy')
					textArea.remove()

					this.$message({
						type: 'success',
						duration: 3000,
						showClose: true,
						center: true,
						message: this.$t('CREATE_FORM.COPIED'),
					})
				})
		},
		methodDisplayUpsellPopin(val) {
			this.computedIsUpsellPopinShown = val
		},
	},
}
</script>

<style lang="scss">
.closeIconEffect:hover {
	@apply transition-all duration-200 hover:bg-gray-100;
}

.scaleEffect:hover {
	transform: scale(1.02);
}

.params-bar {
	@apply fixed z-50 top-auto inset-x-0 bottom-0 bg-white w-full p-6 border-t rounded-t-xl opacity-0 pointer-events-none overflow-auto h-full pb-24;
	@apply md:z-30 md:inset-x-auto md:inset-y-0 md:right-0 md:border-l md:border-t-0 md:rounded-none md:pt-28 md:w-5/12 lg:w-4/12 xl:w-3/12;

	@media (max-width: 768px) {
		max-height: max-content;
		box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
	}

	.gui-stars-yellow::before,
	.gui-stars-yellow::after {
		@apply text-yellow-500;
	}
}

.--show {
	@apply pointer-events-auto;
	@media (max-width: 768px) {
		animation: slide-in-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
	}
	@media (min-width: 768px) {
		animation: slide-in-left 0.7s cubic-bezier(0.23, 1, 0.32, 1) both;
	}
}

.--hide {
	@media (max-width: 768px) {
		animation: slide-out-bottom 2.5s cubic-bezier(0.23, 1, 0.32, 1) both;
	}
	@media (min-width: 768px) {
		animation: slide-out-right 2s cubic-bezier(0.23, 1, 0.32, 1) both;
	}
}

@keyframes slide-in-top {
	0% {
		transform: translateY(1000px) scaleY(1.1) scaleX(0.9);
		transform-origin: 50% 0%;
		opacity: 0;
	}
	100% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		opacity: 1;
	}
}

@keyframes slide-out-bottom {
	0% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		opacity: 1;
	}
	100% {
		transform: translateY(1000px) scaleY(1.1) scaleX(0.9);
		transform-origin: 50% 0%;
		opacity: 0;
	}
}

@keyframes slide-in-left {
	0% {
		transform: translateX(1000px) scaleX(1.1) scaleY(0.9);
		transform-origin: 0% 50%;
		opacity: 0;
	}
	100% {
		transform: translateX(0) scaleX(1) scaleY(1);
		transform-origin: 50% 50%;
		opacity: 1;
	}
}

@keyframes slide-out-right {
	0% {
		transform: translateX(0) scaleX(1) scaleY(1);
		transform-origin: 50% 50%;
		opacity: 1;
	}
	100% {
		transform: translateX(1000px) scaleX(1.1) scaleY(0.9);
		transform-origin: 0% 50%;
		opacity: 0;
	}
}
</style>
