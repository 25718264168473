import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { firstBy } from 'thenby'
import formStore from '../../models/forms_store'
import store from '../stateStore'
import {
	SET_FORM,
	SET_NEED_PUBLISH_FORM,
	SET_LOADING_SAVING_FORM,
	SET_LOADING_CAN_DELETE,
	SET_CAN_DELETE_INFOS,
	RESET_CAN_DELETE_INFOS,
	RESET_FORM_STORE,
	SET_STATIC_AND_CUSTOM_UPDATE_STATUS,
	SET_REDUCED_LIST_STATE,
	SET_INSTANCIED_MODULE_ID,
} from './_mutation-types'

const getFormsTypolgy = (state, rootState, form, groupId = 0) => {
	let localGroupId = groupId
	if (!localGroupId) {
		localGroupId = get(rootState, '@group.group.id', groupId)
	}
	const formsState = get(state, form, [])
	const formsFiltered = formsState.filter((aForm) => aForm?.group_id.includes(localGroupId))
	return formsFiltered
}

/**
 * @func activeStaticKeysAvailablesFromAccount
 * @desc Give an helper to get the actual keys provided by the account
 */
function activeStaticKeysAvailablesFromAccount(formStatic) {
	if (!isEmpty(formStatic)) {
		const clonedFormStatic = cloneDeep(formStatic)
		const formStaticSortedByOrder = clonedFormStatic.sort((v1, v2) => v1.order > v2.order)
		const keysToExport = formStaticSortedByOrder.map((aFormStatic) => {
			if (aFormStatic.name === 'STATIC') {
				return aFormStatic
			}
		})
		return keysToExport
	}
	return []
}

const getDefaultState = () => {
	return {
		fromDatabase: {},
		needAFormPublish: false,
		support: [],
		static: [],
		form: [],
		formCond: [],
		action: [],
		status: [],
		consent: [],
		custom: [],
		namePresence: [],
		brief: [],
		isLoadingSavingForm: false,
		loadingStateCanDelete: 'init',
		canDeleteInfos: {
			reason: null,
			status: 'not_ok',
			details: null,
		},
		isBothStaticAndCustomFormsUpdated: 'init',
		isReducedListKeysForEmailsAutoSuggestion: false,
		instanceId: '',
	}
}

/**
 * @store @form
 * @namespaced
 */
const state = getDefaultState

const mutations = {
	/**
	 * @mutation SET_FORM
	 * @desc set the form in vuex store depending of the form
	 * @param {Boolean} payload new state
	 */
	[SET_FORM]: (state, payload) => {
		state[payload.name] = payload.value
		if (payload.backupForm) {
			state['fromDatabase'][payload.name] = cloneDeep(payload.value)
		}
	},

	/**
	 * @mutation SET_NEED_PUBLISH_FORM
	 * @desc set the publish mode of the general form
	 * @param {Boolean} payload new state
	 */
	[SET_NEED_PUBLISH_FORM]: (state, payload) => {
		state.needAFormPublish = payload.value
	},

	[SET_LOADING_SAVING_FORM]: (state, payload) => {
		state.isLoadingSavingForm = payload
	},

	[SET_LOADING_CAN_DELETE]: (state, payload) => {
		state.loadingStateCanDelete = payload
	},
	[SET_CAN_DELETE_INFOS]: (state, payload) => {
		state.canDeleteInfos = payload
	},
	[RESET_CAN_DELETE_INFOS]: (state, payload) => {
		state.canDeleteInfos = {
			reason: null,
			status: 'not_ok',
			details: null,
		}
	},

	[RESET_FORM_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},

	[SET_STATIC_AND_CUSTOM_UPDATE_STATUS]: (state, payload) => {
		state.isBothStaticAndCustomFormsUpdated = payload
	},

	[SET_REDUCED_LIST_STATE]: (state, payload) => {
		state.isReducedListKeysForEmailsAutoSuggestion = payload
	},

	[SET_INSTANCIED_MODULE_ID]: (state, res) => {
		state.instanceId = res
	},
}

const getters = {
	backupFormName: (state) => (name) => {
		if (state.fromDatabase[name]) {
			return state.fromDatabase[name]
		}
		return false
	},
	/**
	 * @getter isFormModified
	 * @desc for two forms provided return if the local form and saved
	 * form are the same. This function do not modify the state of the
	 * original form
	 */
	isFormModified: () => (localForm, savedForm) => {
		if (Array.isArray(localForm) && Array.isArray(savedForm)) {
			const localFormCloned = cloneDeep(localForm)
			const savedFormCloned = cloneDeep(savedForm)

			/**
			 * Clean the local form from values
			 */
			localFormCloned.forEach((aData) => {
				delete aData.value
				delete aData.enforceSupport
				if (aData.refvalues) {
					aData.refvalues.forEach((aRefValue) => {
						if (typeof aRefValue.enforceSupport === 'boolean') {
							delete aRefValue.enforceSupport
						}
					})
				}
			})
			localFormCloned.sort(firstBy('id'))
			savedFormCloned.sort(firstBy('id'))

			/**
			 * Can reorder the refvalues
			 */
			const orderByIdRefValues = (form) => {
				if (Array.isArray(form)) {
					form.forEach((aForm) => {
						if (Array.isArray(aForm.refvalues)) {
							aForm.refvalues.sort(firstBy('id'))
						}
					})
				}
			}

			orderByIdRefValues(localFormCloned)
			orderByIdRefValues(savedFormCloned)

			return !isEqual(localFormCloned, savedFormCloned)
		}
		return true
	},
	needAFormPublish: (state) => state.needAFormPublish,
	support: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'support'),
	static: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'static'),
	getActiveStaticKeysAvailables: (state, getters, rootState) =>
		activeStaticKeysAvailablesFromAccount(getFormsTypolgy(state, rootState, 'static')),
	getIsReducedListKeysForEmailsAutoSuggestion: (state) =>
		state.isReducedListKeysForEmailsAutoSuggestion,
	form: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'form'),
	forms: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'form'),
	getFormsClassic: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'form'),
	getNamePresence: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'namePresence'),
	getFormNamePresence: (state, getters, rootState) =>
		getFormsTypolgy(state, rootState, 'namePresence'),
	getFormsStatic: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'static'),
	getFormsAction: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'action'),
	getFormsStatus: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'status'),
	getFormsConsent: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'consent'),
	getFormsBrief: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'brief'),
	getCustomForms: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'custom'),
	formCond: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'formCond'),
	action: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'action'),
	status: (state, getters, rootState) => getFormsTypolgy(state, rootState, 'status'),
	getIsLoadingSavingForm: (state) => state.isLoadingSavingForm,
	getloadingStateCanDelete: (state) => state.loadingStateCanDelete,
	getCanDeleteInfos: (state) => state.canDeleteInfos,
	getBothStaticAndCustomFormsUpdated: (state) => state.isBothStaticAndCustomFormsUpdated,
	getAnyFormsTypologyByGroupId:
		(state, getters, rootState) =>
		(form, groupId = 0) =>
			getFormsTypolgy(state, rootState, form, groupId),
}

const actions = {
	actionSetForm({ commit }, payload) {
		commit(SET_FORM, payload)
	},

	async actionGetStaticForms({ dispatch, commit }) {
		const staticForm = await dispatch('getFormsCalled', 'STATIC')
		commit(SET_FORM, {
			name: 'static',
			value: staticForm.sort(firstBy('order')),
			backupForm: true,
		})
		return staticForm
	},

	async getActionForms({ dispatch, commit }) {
		const actionForm = await dispatch('getFormsCalled', 'ACTION')
		commit(SET_FORM, {
			name: 'action',
			value: actionForm,
			backupForm: true,
		})
		return actionForm
	},

	async getStatusForms({ dispatch, commit }) {
		const statusForm = await dispatch('getFormsCalled', 'STATUS')
		commit(SET_FORM, {
			name: 'status',
			value: statusForm,
			backupForm: true,
		})
		return statusForm
	},

	async getForms({ dispatch, commit }) {
		const forms = await dispatch('getFormsCalled', 'FORM')
		commit(SET_FORM, {
			name: 'form',
			value: forms,
			backupForm: true,
		})
		return forms
	},

	async actionGetCustomForms({ dispatch, commit }) {
		let forms = []
		// Return an empty array of custom fields if the pack is disabled
		if (store.getters['@packs/getAccessCustomFields'] === 'enabled') {
			forms = await dispatch('getFormsCalled', 'CUSTOM')
		}
		commit(SET_FORM, {
			name: 'custom',
			value: forms,
			backupForm: true,
		})
		return forms
	},

	async getConsentForms({ dispatch, commit }) {
		const forms = await dispatch('getFormsCalled', 'CONSENT')
		commit(SET_FORM, {
			name: 'consent',
			value: forms,
			backupForm: true,
		})
		return forms
	},

	async getNamePresenceForms({ dispatch, commit }) {
		const forms = await dispatch('getFormsCalled', 'name_presence')
		commit(SET_FORM, {
			name: 'namePresence',
			value: forms,
			backupForm: true,
		})
		return forms
	},

	async getBriefForms({ dispatch, commit }) {
		const forms = await dispatch('getFormsCalled', 'BRIEF')
		commit(SET_FORM, {
			name: 'brief',
			value: forms,
			backupForm: true,
		})
		return forms
	},

	async getFormsCalled({ rootState }, payload) {
		const isMasterView = get(rootState, '@group.group.masterView', false)
		return await formStore.getFormByName(payload, isMasterView)
	},

	/**
	 * @action saveForms
	 * @param {VuexStore} store
	 * @param {*} payload
	 * @param {Object} payload.forms
	 * @param {String} payload.names
	 *
	 */
	saveForms(store, { forms, names }) {
		return formStore.saveFormsWithNames(forms, names)
	},

	async canDeleteCustomField(store, id) {
		return await formStore.canDeleteCustomField(id)
	},

	actionCanDeleteCustomField({ commit }, id = null) {
		commit(SET_LOADING_CAN_DELETE, 'loading')
		formStore
			.canDeleteCustomField(id)
			.then((res) => {
				if (res) {
					commit(SET_CAN_DELETE_INFOS, {
						reason: res.reason || null,
						status: res.status || 'not_ok',
						details: res.details || null,
					})
				}
				commit(SET_LOADING_CAN_DELETE, 'finish')
			})
			.catch((error) => {
				alert('error')
				console.log(error, 'error')
				commit(SET_CAN_DELETE_INFOS, {
					reason: null,
					status: 'not_ok',
					details: null,
				})

				commit(SET_LOADING_CAN_DELETE, 'finish')
			})
	},

	actionResetCanDeleteInfos({ commit }, id = null) {
		commit(SET_LOADING_CAN_DELETE, {
			reason: null,
			status: 'not_ok',
			details: null,
		})
	},

	actionSaveForms({ commit }, { forms, name }) {
		commit(SET_LOADING_SAVING_FORM, true)
		formStore
			.saveFormsWithNames(forms, name)
			.then((res) => {
				commit(SET_FORM, {
					name: name.toLowerCase(),
					value: res.forms && res.forms.length ? res.forms : [],
					backupForm: true,
				})

				commit(SET_LOADING_SAVING_FORM, false)
			})
			.catch(() => commit(SET_LOADING_SAVING_FORM, false))
	},

	async actionSaveBothStaticAndCustomsForms({ commit }, forms) {
		commit(SET_STATIC_AND_CUSTOM_UPDATE_STATUS, 'loading')
		const { staticForm, customForm } = forms
		const staticResponse = staticForm
			? await formStore.saveFormsWithNames(staticForm.fields, staticForm.name)
			: []

		const customResponse = customForm
			? await formStore.saveFormsWithNames(customForm.fields, customForm.name)
			: []

		const responsesMerged = [
			{ ...staticResponse, name: 'STATIC' },
			{ ...customResponse, name: 'CUSTOM' },
		]
		responsesMerged.forEach((response) => {
			commit(SET_FORM, {
				name: response.name.toLowerCase(),
				value: response.forms && response.forms.length ? response.forms : [],
				backupForm: true,
			})
		})
		commit(SET_STATIC_AND_CUSTOM_UPDATE_STATUS, 'success')
		setTimeout(() => {
			commit(SET_STATIC_AND_CUSTOM_UPDATE_STATUS, 'init')
		}, 1000)
	},

	resetState({ commit }) {
		commit(RESET_FORM_STORE)
	},

	/**
	 * @action changeTheModeOfThePublish
	 * @param {VuexStore} store
	 * @param {*} payload
	 *
	 */
	changeTheModeOfThePublish({ state, commit }, value) {
		if (state.needAFormPublish !== value) {
			commit(SET_NEED_PUBLISH_FORM, {
				value,
			})
		}
	},

	/**
	 *
	 */
	actionResetSelectedValuesForForms({ state, commit }) {
		const forms = cloneDeep(state.form)
		forms.forEach((aForm) => {
			if (aForm.type === 'radio' || aForm.type === 'checkbox') {
				if (aForm?.refvalues.length) {
					aForm?.refvalues.forEach((aRefValue) => {
						aRefValue.selected = null
					})
				}
			}
		})
		commit(SET_FORM, {
			name: 'form',
			value: forms,
			backupForm: true,
		})
	},

	actionUpdateReducedListState({ commit }, payload) {
		commit(SET_REDUCED_LIST_STATE, payload)
	},

	actionSetInstanceId({ commit }, payload) {
		commit(SET_INSTANCIED_MODULE_ID, payload)
	},
}

export const formModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
