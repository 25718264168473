import TasksView from 'components/management-module/TasksView.vue'
import { controllerPackRedirection } from './utils/routerController'
import store from './../store/stateStore'

export default {
	meta: { title: 'MENU.ISSUE_CENTER' },
	path: 'tasks',
	name: 'tasks',
	component: TasksView,
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('task:delete')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessTasks')
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
}
