<template>
	<div :class="['loader-container', color]">
		<div class="dot-flashing left"></div>
		<div class="dot-flashing"></div>
		<div class="dot-flashing right"></div>
	</div>
</template>

<script>
export default {
	props: {
		/**
		 * Variant for the button
		 * @values primary, secondary
		 */
		color: {
			type: String,
			default: 'primary',
			validator: function (value) {
				return ['primary', 'secondary'].includes(value)
			},
		},
	},
}
</script>
<style lang="sass">
@use "sass:math"
@import "../style/variables"

$diameter: 1em
$animation-duration: .5s

.loader-container
	width: 4 * $diameter
	height: $diameter
	position: relative
	margin-left: math.div($diameter, 2)
	margin-right: math.div($diameter, 2)

.dot-flashing
	width: $diameter
	height: $diameter
	border-radius: $diameter
	position: absolute
	top: 0
	left: 1.5*$diameter

	.primary &
		background-color: $pink
		color: $pink
		animation: dotFlashingPrimary $animation-duration infinite linear alternate

	.secondary &
		background-color: $green
		color: $green
		animation: dotFlashingSecondary $animation-duration infinite linear alternate

	&.left
		animation-delay: math.div(-$animation-duration, 2)
		left: 0*$diameter

	&.right
		left: 3*$diameter
		animation-delay: math.div($animation-duration, 2)

@keyframes dotFlashingPrimary
	0%, 10%
		background-color: $pink
	50%, 100%
		background-color: $light-grey

@keyframes dotFlashingSecondary
	0%, 15%
		background-color: $green
	60%, 100%
		background-color: $light-grey
</style>
