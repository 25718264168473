import { $request } from './requester'

export function fetchCountAddressesForPolygon(data) {
	return $request('POST', '/search/nbstreets', data)
		.then((res) => {
			return res
		})
		.catch(() => {
			throw new Error('Impossible to get the count of addresses')
		})
}

export function searchContactsFiltersAdvanced(payload) {
	return $request('POST/WITHOUT_DATA_KEY', `/advanced-search`, payload).then((res) => {
		return res.body.data
	})
}
