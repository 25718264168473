import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-1 bold"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-2 mb-3"
}

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { createLowlight, common } from 'lowlight'
import 'highlight.js/styles/atom-one-dark.css'

interface Props {
	code: string
	title?: string
	description?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TiptapCodeEditor',
  props: {
    code: {},
    title: {},
    description: {}
  },
  emits: ['update:code'],
  setup(__props: any, { emit: __emit }) {

const editor = ref<Editor>()
const lowlight = createLowlight(common)

const CodeBlockLowlightCustomClass = CodeBlockLowlight.extend({
	// Redefine the html of a code block of CodeBlockLowlight so the hljs class is added in the code part for the highlight.js styles to work and add custom class too
	renderHTML() {
		return ['pre', ['code', { class: 'hljs rounded-lg text-sm h-52' }, 0]]
	},
	// Redefine some shortcuts we don't want to have
	addKeyboardShortcuts() {
		return {
			'Mod-Enter': () => { return true },
			// Allow tab inside codeBlock (https://github.com/ueberdosis/tiptap/issues/1153#issuecomment-946693201)
			'Tab': () => {
				if (editor.value.isActive('codeBlock')) {
					return editor.value.commands.insertContent('\t')
				}
			},
		}
	},
})

// ANCHOR - Props
const props = __props

// ANCHOR - Emit
const emit = __emit

// ANCHOR - Mounted
onMounted(() => {
	editor.value = new Editor({
		extensions: [
			Document,
			Text,
			CodeBlockLowlightCustomClass.configure({
				lowlight,
			}),
		],
		editorProps: {
			attributes: {
				spellcheck: 'false',
				// Add rounded class for the editor for the focus state
				class: 'rounded-lg',
			},
		},
		onCreate: ({ editor }) => {
			// Init the editor with a code block and with the content if there is one
			editor.commands.setContent(
				props.code
					? {
							type: 'codeBlock',
							content: [
								{
									type: 'text',
									text: props.code,
								},
							],
						}
					: {
							type: 'codeBlock',
							content: [],
						},
			)
		},
		onUpdate: ({ editor }) => {
			const json = editor.getJSON()
			const isCodeBlock = json.content.length && json.content[0].type === 'codeBlock'

			// If the code block is deleted, create a new one
			if (!isCodeBlock) {
				editor.commands.setCodeBlock()
			}

			// Keep only the first editor code block if there is another one created by accident
			if (json.content.length > 1) {
				editor.commands.setContent(json.content[0])
			}

			emit('update:code', editor.getText())
		},

	})
})

// ANCHOR - Unmount
onBeforeUnmount(() => {
	editor.value?.destroy()
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _createVNode(_unref(EditorContent), { editor: editor.value }, null, 8, ["editor"])
  ]))
}
}

})