<template>
	<div class="popover-container" @click="popoverShadowClick($event)">
		<div class="popover-content">
			<slot name="popover-content"></slot>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'Popover',
	emits: ['closePopover'],

	mounted: function () {
		let vueComponent = this
		// add listener for esc key to throw closePopoverEvent
		$(document).keyup(function (e) {
			// esc key code
			if (e.keyCode === 27) {
				vueComponent.closePopoverEvent()
			}
		})
	},

	methods: {
		// popoverShadow click event handler - get rid of the popover unless content was clicked
		popoverShadowClick: function (event) {
			if (!$(event.target).closest('.popover-content').length) {
				this.closePopoverEvent()
			}
		},

		closePopoverEvent: function () {
			this.$emit('closePopover')
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/general/popover.scss';
</style>
