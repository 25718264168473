<template>
	<div>
		<draggable
			v-bind="{ animation: 200 }"
			v-model="share"
			item-key="name"
			@start="dragging = true"
			@end="dragging = false"
		>
			<template #item="{ element }">
				<div class="flex items-end mb-5 font-medium">
					<i
						class="gui-handle text-gray-medium mr-4 mb-5"
						style="cursor: grab"
					/>
					<div class="flex-1">
						<p class="flex items-center gap-x-2 mb-1.5 font-medium">
							<span class="bg-gray-light rounded-full w-9 h-9 flex justify-center items-center">
								<i
									v-if="element.name === 'custom'"
									class="gui-link text-xl"
								/>
								<component
									:is="capitalize(element.name)"
									v-else
									main-color="#EBEBEB"
									second-color="#222230"
								/>
							</span>

							{{
								element.name === 'custom'
									? $t('ONLINE_FORMS.NETWORKS.CUSTOM_LINK')
									: capitalize(element.name)
							}}
						</p>

						<validation-field
							v-slot="{ field, errors }"
							v-model="element.link"
							:name="`link-${element.name}`"
							rules="required"
							as="div"
							class="relative"
						>
							<el-input
								v-bind="field"
								v-model="element.link"
								:name="`link-${element.name}`"
								class="mb-1.5"
								:class="{ 'form-invalid-input': !element.link?.length }"
								:placeholder="`${element.name === 'custom' ? 'qomon' : element.name}.com /`"
							/>
							<transition
								name="fade-in"
								mode="out-in"
							>
								<p
									v-if="errors.length"
									class="absolute flex items-center text-sm text-gray-strong top-auto pb-6 whitespace-nowrap"
									style="bottom: -36px"
								>
									<i class="gui-no text-gray-dark mr-1" />
									{{ errors[0] }}
								</p>
							</transition>
						</validation-field>
						<el-input
							v-if="element.name === 'custom' && isLabel"
							v-model="element.label"
							:name="`label-${element.name}`"
							class="mb-1.5"
							:class="{ 'form-invalid-input': !element.link?.length }"
							placeholder="Label"
						/>
					</div>
					<i
						class="gui-delete text-xl text-gray-medium ml-2 mb-4 cursor-pointer"
						@click="() => removeNetwork(element)"
					/>
				</div>
			</template>
		</draggable>

		<button
			class="text-sm text-pink-main flex items-center mt-9 ml-auto"
			type="button"
			@click="() => actionSetParamsBar({ show: true, selection: 'networks' })"
		>
			<span class="font-medium mr-3">{{ $t('ONLINE_FORMS.NETWORKS.ADD_LINK') }}</span>
			<i class="gui-more text-xl" />
		</button>

		<el-divider class="my-8" />

		<div class="flex items-center">
			<div class="share-part__color-picker rounded-lg relative h-14 mb-2 flex-1">
				<label
					class="absolute z-10 h-full w-full flex items-center px-3 pointer-events-none text-blackFiltered"
					:style="`filter: invert(${color ? getBrightnessFromColor(color) : 0});`"
				>
					<span>{{ $t('ONLINE_FORMS.NETWORKS.DEFAULT') }}</span>

					<i
						v-if="color"
						class="gui-edit text-2xl ml-auto"
					/>
					<div
						v-else
						class="share-part__color-picker__gradient"
					/>
				</label>

				<el-color-picker
					v-model="color"
					color-format="hex"
					@active-change="onColorChange"
				/>
			</div>
			<i
				class="gui-reload text-2xl ml-3 cursor-pointer"
				:class="{ 'text-gray-medium': !color }"
				@click="onColorReset"
			/>
		</div>

		<div class="flex flex-wrap mt-5">
			<p class="w-full flex justify-between font-medium mb-2">
				{{ $t('ONLINE_FORMS.NETWORKS.STYLE') }}
				<span class="text-sm font-normal bg-gray-100 rounded-lg px-2 py-0.5">
					{{ isFull ? $t('ONLINE_FORMS.NETWORKS.FULL') : $t('ONLINE_FORMS.NETWORKS.BORDER') }}
				</span>
			</p>

			<button
				class="border border-black px-2 py-0.5 rounded-l-lg w-1/2 h-11 hover:opacity-60"
				:class="{ 'bg-white ': isFull, 'bg-black ': !isFull }"
				type="button"
				@click="() => toggleNetworksFull(!isFull)"
			>
				<i
					class="gui-link text-2xl border rounded-full p-1"
					:class="{
						'text-black border-black': isFull,
						'text-white border-white': !isFull,
					}"
				/>
			</button>

			<button
				class="gui-link text-2xl border border-black px-2 py-0.5 rounded-r-lg w-1/2 h-11 hover:opacity-60"
				:class="{ 'bg-black text-white': isFull, 'bg-white text-black': !isFull }"
				type="button"
				@click="() => toggleNetworksFull(!isFull)"
			/>
		</div>

		<div class="flex flex-wrap mt-5">
			<p class="w-full flex justify-between font-medium mb-2">
				Label
				<span class="text-sm font-normal bg-gray-100 rounded-lg px-2 py-0.5">
					{{ isLabel ? $t('ONLINE_FORMS.NETWORKS.WITH') : $t('ONLINE_FORMS.NETWORKS.WITHOUT') }}
				</span>
			</p>

			<button
				class="border border-black px-2 py-0.5 rounded-l-lg w-1/2 h-11 hover:opacity-60"
				:class="{ 'bg-white ': !isLabel, 'bg-black': isLabel }"
				type="button"
				@click="() => toggleNetworksLabel(!isLabel)"
			>
				<i
					class="gui-link text-2xl"
					:class="{ 'text-black': !isLabel, 'text-white': isLabel }"
				/>
				<i
					class="gui-short_text text-2xl"
					:class="{ 'text-black': !isLabel, 'text-white': isLabel }"
				/>
			</button>

			<button
				class="gui-link text-2xl border border-black px-2 py-0.5 rounded-r-lg w-1/2 h-11 hover:opacity-60"
				:class="{ 'bg-black text-white': !isLabel, 'bg-white text-black': isLabel }"
				type="button"
				@click="() => toggleNetworksLabel(!isLabel)"
			/>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import Draggable from 'vuedraggable'
import { Field as ValidationField } from 'vee-validate'

export default {
	components: {
		Draggable,
		ValidationField,
		Bluesky: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/blueskyIcon.vue'),
		),
		Facebook: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/facebookIcon.vue'),
		),
		Instagram: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/instagramIcon.vue'),
		),
		Linkedin: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/linkedinIcon.vue'),
		),
		Mastodon: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/mastodonIcon.vue'),
		),
		Messenger: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/messengerIcon.vue'),
		),
		Snapchat: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/snapchatIcon.vue'),
		),
		Telegram: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/telegramIcon.vue'),
		),
		Threads: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/threadsIcon.vue'),
		),
		Tiktok: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/tiktokIcon.vue'),
		),
		Twitch: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/twitchIcon.vue'),
		),
		Twitter: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/twitterIcon.vue'),
		),
		Wechat: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/wechatIcon.vue'),
		),
		Whatsapp: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/whatsappIcon.vue'),
		),
		X: defineAsyncComponent(() => import('../../online-forms-modules/view/social-icons/xIcon.vue')),
		Youtube: defineAsyncComponent(() =>
			import('../../online-forms-modules/view/social-icons/youtubeIcon.vue'),
		),
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
		getBrightnessFromColor: { type: Function, default: () => {}, required: true },
	},

	emits: ['update:form'],

	data() {
		return {
			share: [],
			customLabelValue: '',
			color: '',
			isFull: false,
			isLabel: true,
		}
	},

	computed: {
		...mapGetters('@createForm', ['paramsBar']),
		...mapGetters('@onlineForm', ['socialNetworkOptions']),

		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
	},

	watch: {
		share: {
			handler(value) {
				this.computedForm.share = cloneDeep(value)
			},
			deep: true,
		},
	},

	beforeMount() {
		this.share = cloneDeep(this.form.share)

		const firstNetwork = this.share[0]
		if (firstNetwork) {
			this.actionSetSocialNetworkOptions({
				color: firstNetwork.color,
				label: firstNetwork.label.length,
				full: firstNetwork.full,
			})
		}

		this.color = this.socialNetworkOptions.color
		this.isLabel = this.socialNetworkOptions.label
		this.isFull = this.socialNetworkOptions.full
	},

	methods: {
		...mapActions('@createForm', ['actionSetParamsBar']),
		...mapActions('@onlineForm', ['actionSetSocialNetworkOptions']),

		capitalize(str) {
			return str.charAt(0).toUpperCase() + str.slice(1)
		},

		removeNetwork(network) {
			this.share = this.share.filter((s) => s.name !== network.name)
		},

		onColorChange(value) {
			this.color = value
			this.actionSetSocialNetworkOptions({ color: value, label: this.isLabel, full: this.isFull })
			this.share.forEach((network) => (network.color = value))
		},
		onColorReset() {
			this.color = ''
			this.actionSetSocialNetworkOptions({ color: '', label: this.isLabel, full: this.isFull })
			this.share.forEach((network) => (network.color = ''))
		},

		toggleNetworksFull(value) {
			this.isFull = value
			this.actionSetSocialNetworkOptions({ color: this.color, label: this.isLabel, full: value })
			this.share.forEach((network) => (network.full = value))
		},
		toggleNetworksLabel(value) {
			this.isLabel = value
			this.actionSetSocialNetworkOptions({ color: this.color, label: value, full: this.isFull })
			this.share.forEach((network) => {
				if (value) {
					if (network.name === 'custom') network.label = this.customLabelValue
					else network.label = this.capitalize(network.name)
				}
				else {
					if (network.name === 'custom') this.customLabelValue = network.label
					network.label = ''
				}
			})
		},
	},
}
</script>

<style lang="scss">
.share-part {
	&__color-picker {
		.el-color-picker {
			height: 100%;
			width: 100%;

			&__trigger {
				height: 100%;
				width: 100%;
				padding: 0;
				border: 1px solid #ebebeb;
				border-radius: 10px;
			}
			&__color {
				border: none;
			}
			&__color-inner {
				border-radius: 10px;
			}
			&__icon {
				display: none;
			}
			&__empty {
				display: none;
			}
		}

		&__gradient {
			width: 24px;
			height: 24px;
			border-radius: 100%;
			margin-left: auto;

			background: conic-gradient(
				from 71.48deg at 51.34% 50%,
				#9557ff -67.34deg,
				#ff5364 21.53deg,
				#fffb45 108.58deg,
				#70fa67 180deg,
				#48c3ff 228.18deg,
				#9557ff 292.66deg,
				#ff5364 381.53deg
			);
		}
	}
}

.el-button.el-button--small.is-text.el-color-dropdown__link-btn {
	display: none; // do not display clear button in color picker
}
.el-button.el-button--small.is-plain.el-color-dropdown__btn {
	@apply border-pink-main text-pink-main;
}
</style>
