import { $request } from './requester'

/**
 * @model Session
 */
export default {
	/**
	 * GET `/mygroup`
	 */
	async mygroup() {
		return $request('GET', '/mygroup').then((res) => {
			return res.body.data.group
		})
	},

	async myGroups() {
		return await $request('GET', '/mygroups').then((res) => res.body.data.groups)
	},
	/**
	 * PATCH `/mygroup`
	 */
	async updateMyGroup(settings) {
		return $request('PATCH', '/mygroup', settings).then((res) => res.body.data.group)
	},

	/**
	 * DELETE `/session`
	 */
	async delSession() {
		return $request('DELETE', '/session')
	},

	/**
	 * GET `/user/mail?mail={mail}`
	 */
	async checkMail(mail) {
		return $request('GET', `/user/mail?mail=${mail}`)
	},

	/**
	 * POST `/newuser`
	 * @param {Object} data
	 */
	async signup(data) {
		return $request('POST/FORM', '/newuser', data)
	},

	/**
	 * PATCH `/newuser`
	 * @param {Object} data
	 */
	async addDetails(data, options) {
		if (data.raw_id_token) return $request('POST/FORM', '/auth/profiling', data, options)

		return $request('PATCH/FORM', '/newuser', data, options)
	},

	/**
	 * POST `/joincause`
	 * @param {Object} data
	 */
	async joinCause(data, options) {
		return $request('POST/FORM', '/joincause', data, options)
	},

	/**
	 * POST `/password/recover`
	 * @param {Object} data
	 */
	async password(data) {
		return $request('POST/FORM', '/password/recover', data)
	},

	/**
	 * POST `/password/validation`
	 * @param {Object} data
	 */
	async confirmPassword(data) {
		return $request('POST/FORM', '/password/validation', data)
	},

	/**
	 * POST `/user/validation`
	 * @param {Object} data
	 */
	async validate(data) {
		if (data.raw_id_token) return $request('POST/FORM', '/auth/validation', data)

		return $request('POST/FORM', '/user/validation', data)
	},

	/**
	 * POST `/session`
	 * @param {Object} data
	 * @param {Boolean} catchable wether to catch errors or not
	 */
	async getSession(data, catchable = true) {
		if (data.raw_id_token) return $request('POST', '/auth/session', data, { catchable })

		return $request('POST/FORM', '/session', data, { catchable })
	},

	/**
	 * GET `/me`
	 */
	async getMe() {
		return $request('GET', '/me').then((res) => {
			return res.body.data.user
		})
	},

	/**
	 * PATCH `/users/password`
	 */
	async updateUserPassword(data) {
		return $request('PATCH/FORM', '/users/password', data)
	},

	/**
	 * DELETE `/photos?location={image_url}` => Delete space profil picture
	 */
	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},

	async postJsonToN8n(json) {
		const n8nUrl = 'https://n8n.qomon.app/webhook/9977b05a-d857-4371-931c-f29d7123ff78'

		return $request('POST/POST_JSON_TO_N8N', n8nUrl, json)
	},

	async createNationBuilderSync(nation) {
		const url = `/nationbuilder/connect/${nation}`

		return $request('GET', url).then((response) => {
			return response
			// this.updateMyGroup(group)
			// window.location.assign(res.body.data)
		})
	},

	async deleteNationBuilderSync(nation) {
		const url = `/nationbuilder/connect/${nation}`

		return $request('DELETE', url).then((res) => {
			return res
		})
	},

	async getStripeSync() {
		const url = '/stripe/account'

		return $request('GET', url).then((res) => {
			return res.body.data
		})
	},

	async createStripeSync() {
		const url = '/stripe/oauth'

		return $request('GET', url).then((res) => {
			if (res.body.data.link?.length > 0) {
				globalThis.location.assign(res.body.data.link)
			}
		})
	},

	async deleteStripeSync() {
		const url = '/stripe/disconnect'

		return $request('DELETE', url).then((res) => {
			return res.body.data?.status
		})
	},

	/**
	 * PATCH `/users/update2fa`
	 * @param {Object} payload
	 */
	async updateUser2fa(payload) {
		return $request('PATCH/WITHOUT_DATA_KEY', '/users/update2fa', payload)
			.then((res) => {
				return res.body.data
			})
			.catch((error) => {
				return error
			})
	},

	/**
	 * GET `/billing/subscription`
	 */
	async getSubscription() {
		return $request('GET', '/billing/subscription')
			.then((response) => {
				return response.body.data
			})
			.catch((error) => {
				return error
			})
	},

	async getEmailerPricing() {
		/// 2 is for test
		return $request('GET', '/billing2/emailer/pricing').then((res) => res.body.data)
	},
}
