import { get, capitalize, includes } from 'lodash'
import { i18n } from '../middlewares/i18nizer'

export const getSubscriptionInfos = (subscriptionStore) => {
	let package_name = get(subscriptionStore, 'package_name', '')
	if (includes(package_name, 'sandbox')) {
		package_name = 'Sandbox'
	} else if (includes(package_name, 'starter')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.STARTER')
	} else if (includes(package_name, 'premium')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.PREMIUM')
	} else if (includes(package_name, 'custom')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.CUSTOM')
	} else if (includes(package_name, 'basic')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.BASIC')
	} else if (includes(package_name, 'organisation')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.ORGANIZATION')
	} else if (includes(package_name, 'entreprise')) {
		package_name = i18n.global.t('MENU.SUBSCRIPTION_PACKAGE_NAME.ENTERPRISE')
	}
	return {
		packageName: capitalize(package_name),
		limit: get(subscriptionStore, 'limit', null),
	}
}
