<template>
	<div class="qview">
		<div class="q-header border-none shadow-sm z-1100">
			<div
				:class="$route.name === 'communication-mail-create' ? 'py-1' : 'py-3'"
				class="pl-4 w-full transition-all"
			>
				<communications-header
					:tabs="computedGetTabs"
					:bulk-emails-panel-visiblity="bulkEmailsPanelVisiblity"
					:mail-cc="computedGetMailCC"
					@update-visiblity-of-bulk-emails-panel="(event) => (bulkEmailsPanelVisiblity = event)"
				/>
			</div>
		</div>
		<div
			class="scrollable-qview-main relative"
			:class="
				$route.name === 'communication-mail-create' &&
					!bulkEmailsPanelVisiblity &&
					!computedIsAnotherEmailerThanElasticEmail
					? 'bg-white'
					: $route.name === 'communications-sms-create' ? 'bg-white' : ''
			"
		>
			<div
				class="w-full"
				:class="[
					$route.name === 'communication-mail-create' ? 'py-4' : '',
					$route.name === 'communications-sms-create' ? '' : 'q-fullwidth px-8 py-8'
				]"
			>
				<router-view
					v-model:body-message-sms-creation="bodyMessageSmsCreation"
					:class="[$route.name === 'communications-sms-create' ? 'md:q-container' : 'q-container']"
					@update-visiblity-of-bulk-emails-panel="methodUpdateVisiblityOfBulkEmailsPanel"
					@update-mail-cc="methodUpdateMailCC"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CommunicationsHeader from './CommunicationsHeader.vue'
import { mapGetters } from 'vuex'

import { ElMessage } from 'element-plus'

export default {
	// eslint-disable-next-line
	name: 'communications',

	components: { CommunicationsHeader },
	beforeRouteLeave(to, from, next) {
		if (this.bodyMessageSmsCreation) {
			ElMessage({
				message: this.$t('SMS.SAVED_DRAFT'),
				duration: 4000,
				type: 'info',
				showClose: true,
				center: true,
			})
			next(true)
		}
		else {
			next(true)
		}
	},

	data() {
		return {
			bodyMessageSmsCreation: '',
			bulkEmailsPanelVisiblity: false,
			mailCC: [],
		}
	},

	computed: {
		...mapGetters(['getIsMessageBirdEnabled', 'getAccessStatusSurveyAutoEmail', 'userConnected']),
		...mapGetters('@packs', ['getAccessStatusSurveyAutoEmail']),
		...mapGetters('@group', ['getEmailerAPIKey']),

		computedGetTabs() {
			return [
				{
					title: this.$t('COMMUNCATIONS.HEADER.GENERAL'),
					name: 'communications-general',
					status: 'enabled',
					settingsCtaDisplayed: true,
					settingsRoute: 'general',
					selected: this.$route.name === 'communications-general',
				},
				{
					title: this.$t('COMMUNCATIONS.HEADER.REPORT'),
					name: 'communications-report',
					status: 'enabled',
					settingsCtaDisplayed: true,
					settingsRoute: 'report',
					selected:
						this.$route.name === 'communications-report'
						|| this.$route.name === 'communications-sms-dashboard'
						|| this.$route.name === 'automatic-email-dashboard',
				},
			].sort((a, b) => {
				return ('' + a.status).localeCompare(b.status)
			})
		},

		computedGetMailCC() {
			return this.mailCC.length > 0 ? this.mailCC[0] : null
		},

		computedIsAnotherEmailerThanElasticEmail() {
			return this.getEmailerAPIKey !== 'QOMON'
		},
	},

	methods: {
		methodUpdateVisiblityOfBulkEmailsPanel(event) {
			this.bulkEmailsPanelVisiblity = event
		},

		methodUpdateMailCC(event) {
			this.mailCC = event
		},
	},
}
</script>

<style lang="scss">
// @import '../../assets/scss/settings/settings.scss';
</style>
