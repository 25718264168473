import { $request } from './requester'

/**
 * @model Document
 */
export default {
	async deletePhotoUploaded(photoURL) {
		return $request('DELETE', `/photos?location=${photoURL}`).then(() => {
			return true
		})
	},
	/**
	 * POST `/docs`
	 */
	async getDocuments() {
		return await $request('POST', `/docs`, {
			File: { minimum_role: 'user', private: false },
		}).then((res) => (res.body?.data?.files?.length ? res.body.data.files : []))
	},

	async uploadDocument(params, files, type) {
		const result = await $request('POST/MULTIPART', '/uploaddoc', params, {}, files, type)
		return result
	},

	async notifyDocument(payload) {
		return await $request('POST/FORM', '/uploaddoc/notify', payload)
	},

	async deleteDocument(id) {
		return await $request('DELETE', `/doc/${id}`)
	},

	async updateDocument(document) {
		return await $request('PATCH/MULTIPART', `/doc/${document.id}`, document)
	},

	async downloadDocument(url) {
		return await $request('GET_EXT', url)
	},
	// async getCategories(group_id) {
	// 	const result = await $request('GET', `/doc-categories/${group_id}`)
	// 	return result
	// },
	async getCategories(group_id) {
		const result = await $request('GET', `/docs/categories`).then((res) => res.body.data)
		return result
	},

	async addDocsCategory(newCategory) {
		const result = await $request('POST', `/docs/category`, newCategory).then(
			(res) => res.body.data
		)
		return result
	},

	// This route is unused as the backend automatically deletes categories with no documents.

	async deleteDocsCategory(category_id) {
		const result = await $request('DELETE', `/docs/category/${category_id}`).then((res) => res.body)
		return result
	},
	async patchDocsCategory(docCategory) {
		const result = await $request('PATCH', `/docs/category`, docCategory).then(
			(res) => res.body.data
		)
		return result
	},
	async patchDocsCategories(docCategories) {
		const result = await $request('PATCH', `/docs/categories`, docCategories).then(
			(res) => res.body.data
		)
		return result
	},
}
