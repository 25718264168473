import forIn from 'lodash/forIn'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'

import { stateKeys, stateKeysPaymentDetails } from '@/stores/transactionTunnel'
import { useTransactionsStore } from '@/stores/transactions'

const getCurrency = () => {
	const storeTransactions = useTransactionsStore()

	return storeTransactions?.getTransactionsSettings?.currency
}

export const transactionSerializer = (data: any) => {
	if (!data) throw new Error('Data is required')

	const bundleTransaction = { transactions: [], donations: [], memberships: [] }
	const transactionObject: TransactionItem = {}

	// Set the id if it exists
	if (data[stateKeys.stateDatabaseId]) {
		transactionObject.id = data[stateKeys.stateDatabaseId]
	}

	transactionObject.amount = amountToBackend(data.amount)
	transactionObject.currency = getCurrency()
	transactionObject.contact_id = data?.mainContact?.mainContactId
	transactionObject.status_id = data[stateKeys.stateStatusId] || data.status_id
	transactionObject.batch_id = data[stateKeys.stateBatchId]

	if (data[stateKeys.stateComment]) transactionObject.comment = data[stateKeys.stateComment]

	if (data[stateKeys.stateCommentDate])
		transactionObject.comment_date = data[stateKeys.stateCommentDate]

	if (data[stateKeys.stateAffectation]) transactionObject.affectation = data[stateKeys.stateAffectation]

	if (data[stateKeys.stateMotifId]) transactionObject.motif_id = data[stateKeys.stateMotifId]

	if (data[stateKeys.stateUnpaidAmount])
		transactionObject.unpaid_amount = amountToBackend(data[stateKeys.stateUnpaidAmount])

	if (data[stateKeys.stateReimbursedAmount])
		transactionObject.reimbursed_amount = amountToBackend(data[stateKeys.stateReimbursedAmount])

	if (data[stateKeys.stateDate]) {
		transactionObject.date = data[stateKeys.stateDate]
	}

	transactionObject.payment_method_kind = data[stateKeys.statePaymentTypology]
	transactionObject.payment_method = {}

	forIn(stateKeysPaymentDetails, (value) => {
		if (data[value]) {
			set(transactionObject, `payment_method.${[value]}`, data[value])
		}
	})

	if (data.campaignCode) transactionObject.code_campaign = data.campaignCode

	bundleTransaction.transactions.push(transactionObject)

	forIn(data.benefitsContacts, (value, key) => {
		value.donation.forEach((aDonation: any) => {
			const donationToAdd: any = {
				donation_price_id: aDonation.id,
				amount: amountToBackend(aDonation.amount),
				currency: getCurrency(),
				contact_id: Number(key),
				comment: aDonation.comment || '',
			}

			if (aDonation.databaseId) {
				donationToAdd.id = aDonation.databaseId
			}

			if (aDonation.deletedAt) {
				donationToAdd.DeletedAt = aDonation.deletedAt
			}

			if (aDonation.affectation) {
				donationToAdd.affectation = aDonation.affectation
			}

			if (aDonation.affectation) {
				donationToAdd.affectation = aDonation.affectation
			}

			bundleTransaction.donations.push(donationToAdd)
		})

		value.membership.forEach((aMembership: any) => {
			const membershipToAdd: any = {
				membership_price_id: aMembership.priceId,
				amount: amountToBackend(aMembership.amount),
				currency: getCurrency(),
				contact_id: Number(key),
				comment: aMembership.comment || '',
				kind: aMembership.kind,
			}

			if (aMembership.databaseId) {
				membershipToAdd.id = aMembership.databaseId
			}

			if (aMembership.deletedAt) {
				membershipToAdd.DeletedAt = aMembership.deletedAt
			}

			bundleTransaction.memberships.push(membershipToAdd)
		})
	})

	return bundleTransaction
}

export const transactionBundleDeserializer = (
	data: any,
	groupsDonation: any,
	groupsMembership: any,
) => {
	if (!data) throw new Error('Data is required')

	const getTransaction = data.transactions[0]

	if (!getTransaction) throw new Error('No transaction into the bundle')

	const transaction = {
		[stateKeys.stateDatabaseId]: getTransaction.id,
		[stateKeys.stateAmount]: amountToFrontend(getTransaction.amount),
		[stateKeys.stateCampaignCode]: getTransaction.code_campaign || '',
		[stateKeys.stateCurrency]: getTransaction.currency,
		[stateKeys.statePaymentTypology]: getTransaction.payment_method_kind,
		[stateKeys.stateStatusId]: getTransaction.status_id,
		[stateKeys.stateBatchId]: getTransaction.batch_id,
		[stateKeys.stateUserId]: getTransaction.user_id,
		[stateKeys.stateDate]: getTransaction.date ? new Date(getTransaction.date) : null,
		[stateKeys.stateCreatedAt]: getTransaction.CreatedAt
			? new Date(getTransaction.CreatedAt)
			: null,
		[stateKeys.stateBatch]: getTransaction?.batch,
		[stateKeys.stateComment]: getTransaction.comment,
		[stateKeys.stateCommentDate]: getTransaction.comment_date
			? new Date(getTransaction.comment_date)
			: null,
		[stateKeys.stateMotifId]: getTransaction.motif_id,
		[stateKeys.stateUnpaidAmount]: amountToFrontend(getTransaction.unpaid_amount),
		[stateKeys.stateReimbursedAmount]: amountToFrontend(getTransaction.reimbursed_amount),
		[stateKeys.stateBundleId]: data.id,
	}

	const mainContact = get(getTransaction, 'contact')

	transaction[stateKeys.benefitContactId] = mainContact.id

	transaction.mainContact = {
		mainContactId: mainContact.id,
		mainContactFirstname: mainContact.firstname,
		mainContactLastname: mainContact.surname,
		mainContactMembershipNumber: mainContact?.membership_number,
		mainContactCounty: mainContact?.address?.county,
	}

	forIn(stateKeysPaymentDetails, (value) => {
		const paymentDetails = get(getTransaction, `payment_method.${[value]}`)

		if (paymentDetails) {
			transaction[value] = paymentDetails
		}
	})

	const contactsKeyByDonations = cloneDeep(keyBy(data.donations, 'contact_id'))
	const contactKeyByMemberships = cloneDeep(keyBy(data.memberships, 'contact_id'))

	const mergedContacts = merge(contactsKeyByDonations, contactKeyByMemberships)

	forIn(mergedContacts, (value, key) => {
		mergedContacts[key] = {
			contactFirstname: get(value, 'contact.firstname'),
			contactLastname: get(value, 'contact.surname'),
			contactId: get(value, 'contact.id'),
			createdAt: new Date(),
			membershipNumber: get(value, 'contact.membership_number'),
			donation: [],
			membership: [],
			contactCounty: get(value, 'contact.address.county'),
		}
	})

	mergedContacts[mainContact.id] = {
		contactFirstname: mainContact.firstname,
		contactLastname: mainContact.surname,
		contactId: mainContact.id,
		membershipNumber: mainContact?.membership_number,
		contactCounty: mainContact?.county,
		createdAt: new Date(),
		donation: [],
		membership: [],
	}

	if (data.donations) {
		data.donations?.forEach((d: any) => {
			mergedContacts[d.contact_id]?.donation.push({
				databaseId: d.id,
				amount: amountToFrontend(d.amount),
				currency: d.currency,
				id: d.donation_price_id,
				affectation: d.affectation,
				...getDonationAttributes(groupsDonation, d.donation_price_id),
				comment: d.comment || '',
				kind: d.kind || '',
			})
		})
	}

	if (data.memberships) {
		data.memberships?.forEach((m: any) => {
			mergedContacts[m.contact_id]?.membership.push({
				databaseId: m.id,
				amount: amountToFrontend(m.amount),
				currency: m.currency,
				...getMembershipAttributes(groupsMembership, m.membership_price_id),
				comment: m.comment || '',
				start_date: m.start_date,
				end_date: m.end_date,
			})
		})
	}

	transaction[stateKeys.benefitsContacts] = mergedContacts

	return transaction
}

export const amountToFrontend = (amount: number): number => {
	if (!amount) return 0

	return amount / 100
}

export const amountToBackend = (amount: number): number => {
	if (!amount) return 0

	const backendAmount = amount * 100

	return parseInt(backendAmount.toFixed(0))
}

const getMembershipAttributes = (groupsMembership: any, id: number) => {
	let priceObject = {}

	groupsMembership?.forEach((g: any) => {
		const priceFound = g.membership_prices.find((p: any) => p.id === id)

		if (priceFound) {
			priceObject = {
				name: g.name,
				id: g.id,
				priceId: priceFound.id,
				currency: priceFound.currency,
				priceName: priceFound.name,
				kind: priceFound.kind,
			}
		}
	})

	return priceObject
}

const getDonationAttributes = (groupsDonation: any, id: number) => {
	const donationObject: any = {}
	const foundDonation = groupsDonation.find((d: any) => {
		return d.id === id
	})

	if (foundDonation) {
		donationObject.allowCustomAmount = foundDonation.allowCustomAmount
		donationObject.name = foundDonation.name
	}

	return donationObject
}
