<template>
	<el-dialog
		v-model="computedDisplayDialog"
		width="40%"
		class="p-4"
		:show-close="false"
		@closed="methodResetDialog"
	>
		<template #header>
			<div>
				<h1 class="text-xl">{{ t('SETTINGS.ROLE_MANAGEMENT.NEW_ROLE') }}</h1>
				<el-divider class="mb-0 mt-4"></el-divider>
			</div>
		</template>
		<!-- Template selection page -->
		<div v-if="!isRoleNameStep" class="flex flex-col gap-5 text-black">
			<div>{{ t('SETTINGS.ROLE_MANAGEMENT.CREATE_ROLE_WITH') }}</div>
			<div class="flex flex-col gap-2 py-3">
				<q-radio
					v-for="role in defaultRolesList"
					:key="role.type"
					v-model="roleTemplate"
					:value="role.type"
					class="radioRoleTemplate flex items-center gap-4 border hover:border-green-medium rounded-xl p-4"
					:class="roleTemplate == role.type ? 'border-green-medium' : 'border-gray-light'"
				>
					<div v-if="role.type != 'custom'" class="flex items-center gap-1">
						<span
							class="text-center py-0.5 px-1 rounded-md"
							:style="[{ color: role.color }, { backgroundColor: role.color + '15' }]"
						>
							{{ methodRetrieveNameAccordingType(role) }}
						</span>
						<!-- Tooltip information -->
						<el-tooltip :content="methodRetrieveInfoAccordingType(role.type)" placement="right">
							<div><i class="gui-infos text-lg ml-1"></i></div>
						</el-tooltip>
					</div>
					<span v-else> {{ t('SETTINGS.ROLE_MANAGEMENT.BLANK_ROLE') }} </span>
				</q-radio>
			</div>
		</div>
		<!-- Name and color selection page -->
		<div v-else class="flex flex-col gap-7 text-black">
			<div>{{ t('SETTINGS.ROLE_MANAGEMENT.CREATE_ROLE_INFOS') }}</div>
			<div class="flex items-center gap-4 pr-4">
				<el-input
					v-model="newRole.name"
					show-word-limit
					:maxlength="25"
					:placeholder="t('SETTINGS.ROLE_MANAGEMENT.ROLE_NAME')"
				></el-input>
				<el-color-picker id="newRoleColor" v-model="newRole.color" size="small"></el-color-picker>
			</div>
		</div>
		<template #footer>
			<!-- Template page buttons -->
			<div v-if="!isRoleNameStep" class="flex gap-6 justify-end items-center pr-4">
				<button
					type="button"
					class="text-gray-strong hover:opacity-80"
					@click="computedDisplayDialog = false"
				>
					<div>{{ t('_COMMON.CANCEL') }}</div>
				</button>
				<q-button @click="methodSelectTemplate"> {{ t('_COMMON.NEXT') }} </q-button>
			</div>
			<!-- Name and color page buttons -->
			<div v-else class="flex gap-6 justify-end items-center pr-2">
				<button
					type="button"
					class="text-gray-strong hover:opacity-80"
					@click="isRoleNameStep = false"
				>
					<div>{{ t('_COMMON.BACK') }}</div>
				</button>
				<q-button :disabled="!newRole.name" @click="methodCreateNewRole">
					{{ t('_COMMON.CREATE') }}
				</q-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { computed, reactive, ref } from 'vue'
import { find, cloneDeep } from 'lodash'
import { methodRetrieveNameAccordingType } from './../../../utils/RoleUtils.ts'
import { useI18n } from 'vue-i18n'

// Data
const { t } = useI18n()
let roleTemplate = ref('custom')
let isRoleNameStep = ref(false)
let newRole = reactive({})

// Emits
const emit = defineEmits(['update:displayDialog', 'scrollEndTable'])

// Props
const props = defineProps({
	displayDialog: { type: Boolean, default: false },
	defaultRolesList: { type: Array, default: () => {} },
	methodAddRole: { type: Function, default: () => {} },
})

// Computed
const computedDisplayDialog = computed({
	get() {
		return props.displayDialog
	},
	set(value) {
		emit('update:displayDialog', value)
	},
})

// Method
const methodResetDialog = () => {
	roleTemplate.value = 'custom'
	isRoleNameStep.value = false
	newRole = {}
}

const methodSelectTemplate = () => {
	newRole = reactive(cloneDeep(find(props.defaultRolesList, ['type', roleTemplate.value])))
	// Set empty name new role
	newRole.name = ''
	// Put custom type for all
	newRole.type = 'custom'
	isRoleNameStep.value = true
}

const methodCreateNewRole = () => {
	props.methodAddRole(newRole)
	emit('scrollEndTable')
	computedDisplayDialog.value = false
}

const methodRetrieveInfoAccordingType = (type) => {
	switch (type) {
		case 'superadmin':
			return t('SETTINGS.ROLE_MANAGEMENT.TEMPLATE_INFO_SUPERADMIN')
		case 'admin':
			return t('SETTINGS.ROLE_MANAGEMENT.TEMPLATE_INFO_ADMIN')
		case 'manager':
			return t('SETTINGS.ROLE_MANAGEMENT.TEMPLATE_INFO_MANAGER')
		case 'user':
			return t('SETTINGS.ROLE_MANAGEMENT.TEMPLATE_INFO_USER')
	}
}
</script>

<style lang="sass">
.radioRoleTemplate input
	margin-top: 4px

#newRoleColor
	.el-color-picker__trigger, .el-color-picker__color-inner, .el-color-picker__color
		@apply rounded-full
	.el-color-picker__color
		@apply  border-0
	.el-color-svpanel
		@apply hidden

#newRoleColor .el-icon.el-color-picker__icon
	@apply hidden
</style>
