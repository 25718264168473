<template>
	<div class="bg-white py-2">
		<query-node
			:level="0"
			:root-node="rootQueryNode"
			:parent-operator="rootQueryNode.type"
			:attributes-builded="objectAttributesBuilded"
			:data-type-available="computedGetDataTypeAvailable"
		/>
		<div class="px-4 flex flex-1 justify-between items-center" />
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { find } from 'lodash'
import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import QueryBuilder from '@quorumsco/quorum-helpers/lib/filter/QueryBuilder'
import { mapGetters } from 'vuex'
import { dataTypeAvailable, typologyDispatchingAttributes, mandatesFields, transactionsFields, donationsFields, membershipsFields, interactionsFields, sanctionsFields, notesFields } from './dataCondition'
import { defineAsyncComponent } from 'vue'
import contactFormTemplate from '../../components/settings-module/contacts/template_contact_form'

export default {
	components: {
		QueryNode: defineAsyncComponent(() => import('./queryNode')),
	},
	props: {
		perPage: {
			type: Number,
			default: 50,
		},
		page: {
			type: Number,
			default: 0,
		},
		sortAttribute: {
			type: String,
			default: 'surname',
		},
		sortOrder: {
			type: String,
			default: 'asc',
		},
		resetSearch: {
			type: Number,
			default: 0,
		},
		forceQuery: {
			type: Object,
			default: () => {},
		},
	},
	emits: ['isMobileOrPhoneAdvancedFiltersEnabled', 'update'],
	data() {
		return {
			searchUpdatePause: true,
			rootQueryNode: new QueryNode('$all'),
			objectAttributesBuilded: {},
			defaultQuery: '',
		}
	},

	computed: {
		...mapGetters('@form', [
			'getActiveStaticKeysAvailables',
			'getFormsClassic',
			'getFormNamePresence',
			'getFormsAction',
			'getFormsStatus',
			'getFormsConsent',
			'getCustomForms',
		]),
		...mapGetters('@packs', ['getAccessOnlineForms', 'getAccessCustomFields', 'getAccessMandates', 'getAccessDonationsMemberships', 'getAccessSanctions']),

		computedGetDataTypeAvailable() {
			let dataAvailableFiltered = dataTypeAvailable
			if (!this.getFormsConsent.length) {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'formdatas:consent',
				)
			}
			if (this.getAccessOnlineForms !== 'enabled') {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'onlineformsdata:base_id',
				)
			}
			// Remove the custom fields select if the pack is not enabled
			if (
				this.getAccessCustomFields !== 'enabled'
				|| !this.getCustomForms
				|| this.getCustomForms.length === 0
			) {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'formdatas:custom_fields',
				)
			}

			// Remove mandates filter if the pack is not enabled
			if (this.getAccessMandates !== 'enabled') {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'mandates',
				)
			}

			// Remove transaction filter if the pack is not enabled
			if (this.getAccessDonationsMemberships !== 'enabled') {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'transactions' && aDataType.type !== 'donations' && aDataType.type !== 'memberships',
				)
			}

			if (this.getAccessSanctions !== 'enabled') {
				dataAvailableFiltered = dataAvailableFiltered.filter(
					(aDataType) => aDataType.type !== 'sanctions',
				)
			}

			return dataAvailableFiltered
		},
	},
	watch: {
		forceQuery: {
			handler(newVal) {
				if (newVal instanceof QueryNode) {
					this.rootQueryNode = cloneDeep(newVal)
				}
			},
			immediate: true,
		},

		resetSearch: {
			handler() {
				this.methodSetDefaultSearch()
			},
		},

		rootQueryNode: {
			handler(QueryNodeInstance) {
				const QueryBuilderInstance = this.methodConstructQueryBuilderInstance(QueryNodeInstance)

				const conditionsMobile = QueryBuilder.getSpecificCondition(
					QueryBuilderInstance.query,
					'attr',
					'mobile',
				)

				const conditionsPhone = QueryBuilder.getSpecificCondition(
					QueryBuilderInstance.query,
					'attr',
					'phone',
				)

				function isSpecificConditionEnabled(conditionsFounded) {
					if (conditionsFounded.length) {
						const isMobileEnabled = conditionsFounded.find((aCondition) => {
							switch (aCondition.ope) {
								case 'ext':
									return true

								case 'eql':
									if (aCondition.value) {
										return true
									}
									return false

								default:
									return false
							}
						})
						if (isMobileEnabled) {
							return true
						}
						return false
					}
					return false
				}

				this.$emit(
					'isMobileOrPhoneAdvancedFiltersEnabled',
					isSpecificConditionEnabled(conditionsMobile)
					|| isSpecificConditionEnabled(conditionsPhone),
				)
				if (!QueryNodeInstance.children.length) {
					const localDefaultRootQueryNode = new QueryNode('$all')
					this.$emit('update', this.methodConstructQueryBuilderInstance(localDefaultRootQueryNode))
				}
				else if (JSON.stringify(QueryNodeInstance) !== this.defaultQuery)
					this.$emit('update', QueryBuilderInstance)
				else this.$emit('update', {})
			},
			deep: true,
			immediate: false,
		},
	},

	beforeMount() {
		this.methodBuildAttributesForConditionsNode()
		this.defaultQuery = JSON.stringify(this.methodGetDefaultSearch())
		if (!(this.forceQuery instanceof QueryNode)) {
			this.methodSetDefaultSearch()
		}
	},

	mounted() {
		setTimeout(() => {
			this.searchUpdatePause = false
		}, 1000)
	},

	methods: {
		methodConstructQueryBuilderInstance(QueryNode) {
			return new QueryBuilder(
				QueryNode.parse(),
				this.perPage,
				this.page,
				this.sortAttribute,
				this.sortOrder,
			)
		},

		methodSetDefaultSearch() {
			this.searchUpdatePause = true
			this.rootQueryNode = this.methodGetDefaultSearch()
		},
		methodGetDefaultSearch() {
			const rootQueryNode = new QueryNode('$all')
			const rootQuerySecond = new QueryNode('$all')
			const firstChild = new QueryNode('$condition')
			const secondChild = new QueryNode('$condition')
			rootQueryNode.addChild(rootQuerySecond)
			rootQuerySecond.addChild(firstChild)
			rootQuerySecond.addChild(secondChild)
			return rootQueryNode
		},

		methodBuildAttributesForConditionsNode() {
			this.objectAttributesBuilded = {
				'formdatas:custom_fields': this.methodGiveRightLanguageToTheAttributes(
					null,
					this.getCustomForms,
				),
				'formdatas:forms': this.methodGiveRightLanguageToTheAttributes(null, this.getFormsClassic),
				'formdatas:status': this.methodGiveRightLanguageToTheAttributes(null, this.getFormsStatus),
				'formdatas:actions': this.methodGiveRightLanguageToTheAttributes(null, this.getFormsAction),
				'formdatas:name_presence': this.methodGiveRightLanguageToTheAttributes(
					null,
					this.getFormNamePresence,
				),
				'formdatas:consent': this.methodGiveRightLanguageToTheAttributes(
					null,
					this.getFormsConsent,
				),
				'actiondatas:actions_ids': this.methodGiveRightLanguageToTheAttributes(null, [
					{
						label: this.$t('CONTACT.FILTERS.ADVANCED.ACTION_NAME'),
						type: 'text',
					},
				]),
				'petitionsdata:petition_id': this.methodGiveRightLanguageToTheAttributes(null, [
					{
						label: this.$t('CONTACT.FILTERS.ADVANCED.PETITION_NAME'),
						type: 'text',
					},
				]),
				'onlineformsdata:base_id': this.methodGiveRightLanguageToTheAttributes(null, [
					{
						label: this.$t('CONTACT.FILTERS.ADVANCED.ONLINEFORM_NAME'),
						type: 'text',
					},
				]),
			}

			const staticKeysCloned = cloneDeep(this.getActiveStaticKeysAvailables)

			contactFormTemplate.staticContactForm.forEach((form) => {
				if (!find(staticKeysCloned, ['type', form.type])) {
					let clonedForm = cloneDeep(form)
					if (clonedForm.type.includes('address.')) {
						clonedForm.label = this.$t(`SETTINGS.CONTACT.ADDRESS_LABELS.${clonedForm.type.split('address.')[1].toUpperCase()}`)
					}
					else {
						clonedForm.label = this.$t(`SETTINGS.CONTACT.${clonedForm.type.toUpperCase()}`)
					}

					staticKeysCloned.push(clonedForm)
				}
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'tags.name',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'lastchange',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'user_id',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'CreatedAt',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'UpdatedAt',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'is_user',
			})

			staticKeysCloned.push({
				name: 'STATIC',
				type: 'birthday',
			})

			const reportingKeys = []
			reportingKeys.push({
				name: 'STATIC',
				type: 'address.invalid',
			})

			reportingKeys.push({
				name: 'STATIC',
				type: 'black_list',
			})

			// if (this.getCustomForms.length > 0) {
			// 	const customsFieldsCloned = cloneDeep(this.getCustomForms)
			// 	customsFieldsCloned.forEach((customfield) => {
			// 		staticKeysCloned.push({
			// 			name: 'FORM',
			// 			type: customfield.label,
			// 		})
			// 	})
			// }

			const indexStaticAge = staticKeysCloned.findIndex((aStatic) => aStatic.type === 'age')

			if (indexStaticAge > -1) {
				staticKeysCloned.splice(indexStaticAge, 1, {
					name: 'STATIC',
					type: 'age_cat',
				})
			}

			this.objectAttributesBuilded['static'] = this.methodGiveRightLanguageToTheAttributes(
				'static',
				staticKeysCloned,
			)

			this.objectAttributesBuilded['reporting'] = this.methodGiveRightLanguageToTheAttributes(
				'reporting',
				reportingKeys,
			)

			this.objectAttributesBuilded['mandates'] = this.methodGiveRightLanguageToTheAttributes(
				'mandates',
				mandatesFields,
			)

			this.objectAttributesBuilded['transactions'] = this.methodGiveRightLanguageToTheAttributes(
				'transactions',
				transactionsFields,
			)

			this.objectAttributesBuilded['donations'] = this.methodGiveRightLanguageToTheAttributes(
				'donations',
				donationsFields,
			)

			this.objectAttributesBuilded['memberships'] = this.methodGiveRightLanguageToTheAttributes(
				'memberships',
				membershipsFields,
			)

			this.objectAttributesBuilded['interactions'] = this.methodGiveRightLanguageToTheAttributes(
				'interactions',
				interactionsFields,
			)

			this.objectAttributesBuilded['sanctions'] = this.methodGiveRightLanguageToTheAttributes(
				'sanctions',
				sanctionsFields,
			)

			this.objectAttributesBuilded['notes'] = this.methodGiveRightLanguageToTheAttributes(
				'notes',
				notesFields,
			)
		},

		methodGiveRightLanguageToTheAttributes(key, forms) {
			const formsCloned = cloneDeep(forms)
			if (formsCloned && formsCloned.length) {
				switch (key) {
					case 'static':
					case 'reporting':
						formsCloned.forEach((aForm) => {
							aForm.id = aForm.type

							if (!aForm.label) {
								if (!aForm.type.includes('address.')) {
									aForm.label = this.$t(`SETTINGS.CONTACT.${aForm.type.toUpperCase()}`)
								}
								else {
									aForm.label = this.$t(
										`SETTINGS.CONTACT.ADDRESS_LABELS.${aForm.type
											.split('address.')[1]
											.toUpperCase()}`,
									)
								}
							}

							/**
							 * Give the typology of the form depending of the key
							 */
							aForm.type = typologyDispatchingAttributes(aForm.id)
						})
						return formsCloned

					case 'mandates':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.EDIT.MANDATES.FILTERS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'transactions':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.TRANSACTIONS.FILTERS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'donations':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.TRANSACTIONS.FILTERS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'memberships':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.TRANSACTIONS.FILTERS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'interactions':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.FILTERS.ADVANCED.INTERACTIONS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'sanctions':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.FILTERS.ADVANCED.SANCTIONS["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'notes':
						formsCloned.forEach((aForm) => {
							aForm.label = this.$t(`CONTACT.FILTERS.ADVANCED.NOTES["${aForm.id.toUpperCase()}"]`)
						})
						return formsCloned

					case 'forms': {
						formsCloned.forEach((aForm) => {
							aForm.id = `${aForm.name}:${aForm.id}`
						})
						return formsCloned
					}

					default:
						return formsCloned
				}
			}
			return formsCloned
		},
	},
}
</script>
