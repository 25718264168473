import multiGroupBy from '../../../utils/multiGroupBy'

/**
 * Helper method to recursively add "_computedLocked" information
 * to an architecture.
 *
 * @param entry
 * @param {null|{dataset_name: String, variable_name: String}[]}architectureRestrictions
 * @param {function} extraChildrenPredicate Predicate used to determinate if an element should be locked based on its children
 */
export function addLocksToArchitecture({
	entry,
	architectureRestrictions,
	extraChildrenPredicate = () => true,
}) {
	// Make sure not to lock when we shouldn't lock anything
	// but we will still be adding the `_computedLocked = false` attribute
	// to all elements
	const globallyUnlocked = architectureRestrictions === null

	// Change datastructure for faster lookups
	const architectureRestrictionsGrouped = globallyUnlocked
		? new Map()
		: multiGroupBy(architectureRestrictions, ['dataset_name', 'variable_name'], true)

	// We use a sub-function for either recursive calls, nothing more
	function _addLocksToArchitecture(entry) {
		if (Array.isArray(entry)) {
			return entry.map((elem) => _addLocksToArchitecture(elem))
		}
		else {
			if (typeof entry._computedLocked === 'boolean') {
				// We consider that we have nothing to do
				return entry
			}
			if (entry.type === 'category' || Array.isArray(entry.elements)) {
				// In this case we set the `._computedLocked` attribute based on if
				// the childrens are locked or not
				const newEntry = { ...entry }

				// children can either be sets (groups of elements for the UI) or elements
				if (Array.isArray(newEntry.elements)) {
					newEntry.elements = newEntry.elements.map((el) => _addLocksToArchitecture(el))
				}
				if (Array.isArray(newEntry.sets)) {
					newEntry.sets = newEntry.sets.map((el) => _addLocksToArchitecture(el))
				}

				if (newEntry.forced_locked) {
					newEntry._computedLocked = true
				}
				else if (newEntry.forced_unlocked === true || globallyUnlocked) {
					newEntry._computedLocked = false
				}
				else {
					if (Array.isArray(newEntry.elements)) {
						newEntry._computedLocked = newEntry.elements
							.filter(extraChildrenPredicate)
							.every((el) => el._computedLocked === true)
					}
					if (Array.isArray(newEntry.sets)) {
						newEntry._computedLocked = newEntry.sets
							.filter(extraChildrenPredicate)
							.every((el) => el._computedLocked === true)
					}

					if (typeof newEntry._computedLocked === 'undefined') {
						newEntry._computedLocked = true
					}
				}
				return newEntry
			}
			else if (
				entry.type === 'variable'
				|| entry.type === 'multiple'
				|| entry.type === 'simple'
			) {
				// if we are on a leaf
				const newEntry = { ...entry }
				if (newEntry.forced_locked) {
					newEntry._computedLocked = true
				}
				else if (newEntry.forced_unlocked || globallyUnlocked) {
					newEntry._computedLocked = false
				}
				else {
					newEntry._computedLocked
						= architectureRestrictionsGrouped
							.get(newEntry.dataset_name)
							?.has(newEntry.variable_name) !== true
				}

				return newEntry
			}
			else {
				// eslint-disable-next-line no-console
				console.error(entry)
				throw new Error(`Unsupported ${entry.type}`)
			}
		}
	}

	return _addLocksToArchitecture(entry)
}

/**
 * Helper method to recursively add "_uniqueId" information
 * to an architecture.
 *
 * Modifies the object in place !
 *
 * @param entry
 */
export function addUniqueIds(entry) {
	// We use a sub-function for either recursive calls, nothing more
	function _addUniqueIds(entry, parentUniqueId) {
		if (Array.isArray(entry)) {
			entry.forEach((elem, idx) => _addUniqueIds(elem, `${parentUniqueId}[${idx}]`))
		}
		else {
			entry._uniqueId = `${parentUniqueId}.`

			if (Array.isArray(entry.elements)) {
				_addUniqueIds(entry.elements, entry._uniqueId)
			}

			// For saved prio mostly
			if (Array.isArray(entry.items)) {
				_addUniqueIds(entry.items, entry._uniqueId)
			}
		}
	}

	_addUniqueIds(entry, '.')
}

export function areVariablesEquals(variable1, variable2) {
	// Compare dataset name
	if (variable1.dataset_name !== variable2.dataset_name) return false
	// Compare category name
	if (variable1.category_name !== variable2.category_name) return false
	// Compare variable name
	if (variable1.variable_name !== variable2.variable_name) return false

	// Compare should value
	let shouldVariable1 = parseShould(variable1.should) || variable1.secondary_group_by_col_value
	let shouldVariable2 = parseShould(variable2.should) || variable2.secondary_group_by_col_value
	if (shouldVariable1 && shouldVariable2) {
		// If they both have a should element compare the values
		if (shouldVariable1 !== shouldVariable2) return false
	}
	// If they don't have both the should empty they are not equals
	else if (!(!shouldVariable1 && !shouldVariable2)) {
		return false
	}

	return true
}

/**
 * @param should
 * @returns {string|null}
 */
export const parseShould = (should) => {
	if (should?.length > 0) {
		return [...Object.values(should[0])][0][0]
	}

	return null
}

/**
 * Normalize the auto config of a profile to fit the architecture of "variables" in getPriorisation
 * @param {*} autoConfig
 */
export const normalizeProfileAutoConfigForPriorisation = (autoConfig) => {
	let normalizedAutoConfig = []
	if (autoConfig?.info_for_datasets) {
		normalizedAutoConfig = autoConfig.info_for_datasets.flatMap((dataset) =>
			dataset.info_for_variables.reduce((acc, variable) => {
				// Do not put variables that equals 0, it will result in a back error
				if (variable.weight != 0) {
					acc.push({
						dataset_label: dataset.dataset_label,
						dataset_name: dataset.dataset_name,
						...variable,
					})
				}
				return acc
			}, []),
		)
	}

	return normalizedAutoConfig
}
