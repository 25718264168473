import { $request } from './requester'
import { batchDeserializer } from '@/requesters/batchSerializer'
import { useTransactionsStore } from '@/stores/transactions'

export default {
	async getCurrentBatch() {
		return $request('GET', `/batchs`).then((res: any) => {
			if (res.statusCode === 200) return res.body.data
		})
	},

	async getUserCurrentOpenedBatches() {
		return $request('GET', `/current_batchs`).then((res: any) => {
			if (res.statusCode === 200) return res.body.data
		})
	},

	async getBatchs(limit, offset) {
		return $request('GET', `/batchs?limit=${limit}&offset=${offset}`).then((res: any) => {
			if (res.body?.data) {
				const transactionsStore = useTransactionsStore()
				res.body.data = res.body.data.map((batch) => batchDeserializer(batch, transactionsStore.getTransactionsSettings?.currency))
				return res.body
			}
			return []
		})
			.catch((err: Error) => {
				throw new Error(err.message)
			})
	},

	async postSearchBatchs(data, limit, offset) {
		return $request('POST', `/search/batchs?limit=${limit}&offset=${offset}`, data).then(
			(res: any) => {
				if (res.body?.data) {
					const transactionsStore = useTransactionsStore()
					res.body.data = res.body.data.map((batch) => batchDeserializer(batch, transactionsStore.getTransactionsSettings?.currency))
					return res.body
				}
				return []
			},
		).catch((err: Error) => {
			throw new Error(err.message)
		})
	},
	async createNewBatch(data = null) {
		return $request('POST', `/batchs`, data).then((res: any) => {
			if (res.statusCode === 200) {
				const transactionsStore = useTransactionsStore()
				return batchDeserializer(res.body.data, transactionsStore.getTransactionsSettings?.currency)
			}
		})
	},

	async getBatchById(id, data) {
		return $request('GET', `/batchs/${id}?transactions=false`).then((res: any) => {
			if (res.statusCode === 200) {
				const transactionsStore = useTransactionsStore()
				const resData = res.body.data
				if (resData) {
					const requestData = {
						batch_id: {
							op: 'eq',
							values: Number(id), // Correction : utilisation de `id`
						},
						order_by: data.order_by,
					}

					return transactionsStore
						.actionRequestSearchTransactions(requestData, data.limit, data.offset)
						.then((allTransactionsInBatch) => {
							resData.transactions = allTransactionsInBatch?.transactions || []
							const resDeserialized = batchDeserializer(resData, transactionsStore.getTransactionsSettings?.currency)
							return resDeserialized
						})
						.catch((error) => {
							return batchDeserializer(resData, transactionsStore.getTransactionsSettings?.currency)
						})
				}

				return batchDeserializer(res.body.data, transactionsStore.getTransactionsSettings?.currency)
			}
		})
	},

	async patchBatch(id: number, data: TransactionItem) {
		return $request('PATCH', `/batchs/${id}`, data).then((res: any) => {
			const transactionsStore = useTransactionsStore()
			return batchDeserializer(res.body.data, transactionsStore.getTransactionsSettings?.currency)
		})
	},
}
