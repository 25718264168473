<template>
	<div
		id="login-vue-recover"
		class="alt-font relative"
	>
		<validation-form
			v-slot="{ meta: formMeta }"
			ref="validationObserver"
			class="container"
			@submit="isFormSubmittable"
		>
			<div class="logo-container">
				<img
					src="/static/images/qomon/black.svg"
					alt="Qomon black logo"
				>
			</div>

			<!-- ask for a reset token -->
			<validation-field
				v-if="!$route.query.mail || !$route.query.code"
				v-slot="{ field, errors }"
				v-model="localUserMail"
				name="email"
				rules="required|email"
				as="div"
				class="flex flex-col justify-center items-center gap-3"
				:class="{'blur-sm': showSuccessDialog}"
			>
				<h1
					v-t="t.title"
					class="font-title-bold text-4xl text-center"
				/>
				<p
					v-dompurify-html="t.recoverText"
					class="text-recover text-center"
				/>
				<label
					v-t="t.placeholders.email"
					class="sr-only"
					for="input-email"
				/>
				<div class="flex flex-col gap-8 justify-center items-center relative pt-8 w-[320px] max-w-[320px]">
					<PublicInput
						v-bind="field"
						v-model:modelValue="localUserMail"
						:title="t.placeholders.email"
						:placeholder="$t('APP.LOGIN.EMAIL_PLACEHOLDER')"
						:enter-event="actionLogin"
						:name="'email'"
						:type="'email'"
						:label="$t('_COMMON.MAIL')"
					/>
					<transition
						name="fade"
					>
						<div
							v-if="errors.length"
							class="max-w-[320px] bg-red-100 rounded-lg pl-2 text-left flex items-center gap-1 absolute -top-2 left-0 right-0 ml-auto mr-auto py-2"
						>
							<span class="gui-error_email text-red-400" />
							<span class="text-sm bold">{{ errors[0] }}</span>
						</div>
					</transition>
					<q-button
						:disabled="!formMeta.valid"
						class="max-w-[320px] w-[320px] big-vertical-padding"
						@click="isFormSubmittable"
					>
						{{ $route.query.mail ? t.sendConfirm : t.send }}
						<span class="q-b-icon-right gui-next" />
					</q-button>
				</div>
			</validation-field>

			<!-- actually reset password -->
			<div
				class="flex flex-col gap-3 text-center w-full px-4 mg:px-0"
				v-if="$route.query.mail && $route.query.code"
			>
				<h1
					v-t="t.title"
					class="text-4xl font-title-bold text-center"
				/>
				<p
					v-t="t.changePassword"
					class="text-recover"
				/>
				<p
					v-t="t.patternForNewPassword"
					class="text-recover margin-bottom"
				/>
				<validation-field
					v-slot="{ field, errors, meta: passwordMeta }"
					v-model="localUserPassword"
					name="password"
					rules="strong_password"
					as="div"
				>
					<div class="relative">
						<el-input
							v-bind="field"
							v-model="localUserPassword"
							icon="lock"
							:type="computedPasswordVisibilityOne"
							:placeholder="t.placeholders.newPassword"
							:valid="passwordMeta.valid"
							autofocus
							password-can-be-shown
							@keydown="handleSubmit"
						/>
						<span
							class="show-password-login __closer-top"
							@click="showPasswordOne = !showPasswordOne"
						>
							<img
								class="eye-icon"
								:src="
									showPasswordOne
										? '/static/images/svg/open_eye.svg'
										: '/static/images/svg/closed_eye.svg'
								"
							>
						</span>
					</div>
					<transition
						name="fade-in"
						mode="out-in"
					>
						<p
							v-if="errors.length && passwordMeta.touched"
							class="flex text-sm text-gray-strong mt-2 text-left"
						>
							<i
								class="gui-no text-gray-dark mr-1"
								style="margin-top: 3px"
							/>
							{{ errors[0] }}
						</p>
					</transition>
				</validation-field>
				<div class="q-v-spacer" />
				<validation-field
					v-slot="{ field, errors, meta: confPasswordMeta }"
					v-model="passwordConfirmation"
					name="password_confirmation"
					rules="confirmed:@password"
					as="div"
				>
					<div class="relative">
						<el-input
							v-bind="field"
							v-model="passwordConfirmation"
							icon="lock"
							:type="computedPasswordVisibilityTwo"
							:placeholder="t.placeholders.confirm"
							:valid="confPasswordMeta.valid"
							password-can-be-shown
							@keydown="handleSubmit"
						/>
						<span
							class="show-password-login __closer-top"
							@click="showPasswordTwo = !showPasswordTwo"
						>
							<img
								class="eye-icon"
								:src="
									showPasswordTwo
										? '/static/images/svg/open_eye.svg'
										: '/static/images/svg/closed_eye.svg'
								"
							>
						</span>
					</div>

					<transition
						name="fade-in"
						mode="out-in"
					>
						<p
							v-if="errors.length && confPasswordMeta.touched"
							class="flex text-sm text-gray-strong mt-2 whitespace-nowrap"
						>
							<i
								class="gui-no text-gray-dark mr-1"
								style="margin-top: 3px"
							/>
							{{ errors[0] }}
						</p>
					</transition>
					<q-button
						:disabled="!formMeta.valid"
						class="mt-5"
						@click="isFormSubmittable"
					>
						{{ $route.query.mail ? t.sendConfirm : t.send }}
					</q-button>
				</validation-field>
				<div class="q-v-spacer" />
			</div>
			<div class="q-m-lg-t" />
		</validation-form>
		<div class="absolute top-10 right-14">
			<LocaleSelector />
		</div>
	</div>
	<el-dialog
		:modal="false"
		:show-close="false"
		v-model="showSuccessDialog"
		@close="$router.push({ name: 'login' })"
	>
		<template #header>
			<div class="px-4">
				<span class="bold text-lg">{{ $t(t.registeredApplication) }}</span>
				<el-divider class="mt-4 mb-7" />
			</div>
		</template>
		<div class="px-4">
			<span>
				{{ t.recoverTextSended(this.localUserMail) }}
			</span>
		</div>
		<template #footer>
			<div class="flex justify-end px-4">
				<q-button @click="showSuccessDialog = !showSuccessDialog">
					{{ $t('_COMMON.CLOSE') }}
				</q-button>
			</div>
		</template>
	</el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import '../../../src/utils/veeValidate'
import { Form as ValidationForm, Field as ValidationField } from 'vee-validate'
import '/src/utils/veeValidate'
import { ref, defineAsyncComponent } from 'vue'

/**
 * @component App/Recover
 * @route /recover
 */
export default {
	name: 'RecoverPassword',
	components: {
		ValidationForm,
		ValidationField,
		LocaleSelector: defineAsyncComponent(() => import('src/components/general/LocaleSelector.vue')),
		PublicInput: defineAsyncComponent(() => import('src/components/general/PublicInput.vue')),
	},
	setup() {
		const localUserPassword = ref('')
		const localUserMail = ref('')
		const passwordConfirmation = ref('')
		const showPasswordOne = ref(false)
		const showPasswordTwo = ref(false)
		const showSuccessDialog = ref(false)
		return { localUserPassword, localUserMail, passwordConfirmation, showPasswordOne, showPasswordTwo, showSuccessDialog }
	},

	computed: {
		t() {
			let routeTranslation = {}
			if (this.$route.query.new) {
				routeTranslation = {
					title: this.$t('APP.RECOVER.TITLE_ALT'),
					changePassword: this.$t('APP.RECOVER.CREATE_PASSWORD', {
						email: this.$route.query.mail,
					}),
				}
			}
			else {
				routeTranslation = {
					title: this.$t('APP.RECOVER.TITLE'),
					changePassword: this.$t('APP.RECOVER.CHANGE_PASSWORD', {
						email: this.$route.query.mail,
					}),
				}
			}
			return {
				...routeTranslation,
				patternForNewPassword: this.$t('VEE_VALIDATE.ENTITY_MUST_CONTAIN', {
					entity: this.$t('APP.RECOVER.ENTITY'),
					correctFormat: this.$t('APP.RECOVER.PATTERN'),
				}),
				registeredApplication: this.$t('APP.RECOVER.REGISTERED_APPLICATION'),
				recoverText: this.$t('APP.RECOVER.RECOVER_TEXT'),
				recoverTextSended: (email) => this.$t('APP.RECOVER.RECOVER_TEXT_SENT', { email: email }),
				send: this.$t('APP.RECOVER.SEND'),
				sendConfirm: this.$t('APP.RECOVER.SEND_CONFIRM'),
				login: this.$t('APP.RECOVER.LOGIN'),
				placeholders: {
					email: this.$t('APP.RECOVER.PLACEHOLDERS.EMAIL'),
					newPassword: this.$t('APP.RECOVER.PLACEHOLDERS.NEW_PASSWORD'),
					confirm: this.$t('APP.RECOVER.PLACEHOLDERS.CONFIRM'),
					example: this.$t('APP.RECOVER.PLACEHOLDERS.EXAMPLE'),
				},
				messages: {
					error: this.$t('APP.RECOVER.MESSAGES.ERROR'),
					success: this.$t('APP.RECOVER.MESSAGES.SUCCESS'),
				},
				okLabel: this.$t('_COMMON.OK'),
			}
		},

		computedPasswordVisibilityOne() {
			if (this.showPasswordOne) {
				return 'text'
			}
			else {
				return 'password'
			}
		},

		computedPasswordVisibilityTwo() {
			if (this.showPasswordTwo) {
				return 'text'
			}
			else {
				return 'password'
			}
		},
	},

	watch: {
		$route: {
			handler(newVal) {
				if (newVal.query?.username) this.localUserMail = newVal.query?.username
			},
			deep: true,
			immediate: true,
		},
	},

	mounted() {
		if (this.$route.params?.username) {
			this.localUserMail = this.$route.params?.username
		}
	},

	methods: {
		...mapActions('@user', ['actionResetUserPassword', 'actionAskForPasswordResetToken']),
		handleSubmit(event) {
			if (event.code === 'Enter') {
				this.isFormSubmittable()
			}
		},
		async isFormSubmittable() {
			if (await this.$refs.validationObserver.validate()) {
				this.recover()
			}
		},

		recover() {
			// generic functions
			let callbackError = () => {
				this.$message({
					message: this.t.messages.error,
					duration: 3000,
					type: 'error',
					showClose: true,
				})
			}
			let passwordProperlyReset = () => {
				this.$message({
					message: this.t.messages.success,
					duration: 3000,
					type: 'success',
					showClose: true,
				})
				this.$router.push({ name: 'login' })
			}

			let localUser = {
				mail: this.localUserMail,
				password: this.localUserPassword,
			}

			if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'code')) {
				// send token to reset password
				this.actionAskForPasswordResetToken(localUser).then(() => this.showSuccessDialog = true).catch(callbackError)
			}
			else {
				// actually reset password
				const { mail, code } = this.$route.query
				const { password } = localUser
				this.actionResetUserPassword({ mail, code, password })
					.then(passwordProperlyReset)
					.catch(callbackError)
			}
		},
	},
}
</script>

<style lang="scss">
@import '/src/assets/scss/animations.scss';

#login-vue-recover .el-input {
	.el-input__wrapper {
		height: 40px;
	}
}
</style>
