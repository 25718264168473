<template>
	<el-dialog
		id="upsellPopup"
		v-model="computedIsPopUpShown"
		top="4%"
		:before-close="handleClose"
		class="lg:px-5"
		append-to-body
		:width="dialogWidth"
	>
		<div
			v-if="computedDisplayPopup === 'optionsScreen'"
			style="max-width: 49.25rem; min-width: auto"
		>
			<p
				class="text-black font-title text-3xl pb-3"
				style="max-width: 32rem"
				v-html="$t('MENU.UPSELL_POPIN.TITLE')"
			/>

			<div class="flex flex-col md:flex-row justify-center py-10 gap-5">
				<div
					v-for="popupText in popupTexts"
					:key="popupText"
					class="flex flex-row items-start md:w-1/3"
				>
					<div class="h-5 w-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							width="20"
							height="20"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M18.1462 12.2016C21.5622 -0.537064 5.79817 -3.85472 1.13441 7.15632C-2.53109 15.8105 14.7303 24.9402 18.1462 12.2016Z"
								fill="#E1F6EB"
							/>
							<path
								d="M12.7704 7.57129L8.65353 11.7481L6.41223 9.62253"
								stroke="#222230"
								stroke-width="2"
								stroke-linecap="square"
								stroke-linejoin="bevel"
							/>
						</svg>
					</div>
					<p
						class="ml-2"
						v-html="popupText"
					/>
				</div>
			</div>
			<el-tooltip
				class="item"
				effect="dark"
				:content="$t('MENU.UPSELL_POPIN.CONTACT_ADMIN')"
				placement="top"
				:popper-class="
					userConnected?.role_data?.access?.includes('settings.billing:delete')
						? 'hidden'
						: 'shadow-md'
				"
			>
				<div
					id="optionsContainer"
					class="flex flex-col md:flex-row justify-center gap-5"
				>
					<div
						v-for="option in options"
						:key="option.text"
						:class="
							userConnected?.role_data?.access?.includes('settings.billing:delete')
								? 'cursor-pointer hover:border-green-main transition-all'
								: 'opacity-50 cursor-not-allowed'
						"
						class="py-8 border rounded-lg md:w-1/3 flex flex-col items-center justify-center gap-7"
						@click="
							userConnected?.role_data?.access?.includes('settings.billing:delete') &&
								handleOption(option.command)
						"
					>
						<img
							:src="`/static/images/svg/${option.icon}.svg`"
							alt=""
							class="h-20"
						>
						<p class="font-title text-black text-2xl text-center">
							{{ option.text }}
						</p>
					</div>
				</div>
			</el-tooltip>
		</div>
		<div
			v-if="computedDisplayPopup === 'callingScreen'"
			class="w-72"
		>
			<div class="flex flex-row items-center border-b border-gray-light pb-3 text-black text-xl">
				<i
					class="gui-arrow_left cursor-pointer hover:text-pink-strong"
					@click="goBack"
				/>
				<p class="font-bold">
					{{ $t('MENU.UPSELL_POPIN.OPTION1') }}
				</p>
			</div>
			<div v-if="!computedCallConfirmation">
				<p class="font-bold text-xl text-black my-6">
					{{ $t('MENU.UPSELL_POPIN.CALLING_SCREEN.SUBTITLE') }}
				</p>
				<div class="my-5">
					<p class="text-pink-main">
						{{ $t('MENU.UPSELL_POPIN.CALLING_SCREEN.YOUR_NUMBER') }}
					</p>
					<el-input
						v-model="computedPhoneNumber"
						clearable
						placeholder="+33..."
						@change="inputPhoneNumber"
					/>
				</div>
				<div class="mb-10">
					<p class="text-pink-main">
						{{ $t('MENU.UPSELL_POPIN.CALLING_SCREEN.CALL_PREFERENCE') }}
					</p>
					<div class="text-black">
						<el-select
							v-model="computedSelectCallAvailability"
							:placeholder="calledBackOptions[0]"
							class="cursor-pointer hover:opacity-75 transition-all w-full"
							placement="bottom-start"
						>
							<el-option
								v-for="calledBackOption in calledBackOptions"
								:key="calledBackOption"
								:label="calledBackOption"
								:value="calledBackOption"
							/>
						</el-select>
					</div>
				</div>
			</div>
			<div
				v-else
				class="my-5 flex flex-col justify-center items-center text-center"
			>
				<p>{{ $t('MENU.UPSELL_POPIN.CALLING_SCREEN.CONFIRMATION') }}</p>
				<p>{{ $t('MENU.UPSELL_POPIN.CALLING_SCREEN.CONFIRMATION_2') }}</p>
			</div>

			<div class="flex flex-row justify-center">
				<div
					:class="
						computedPhoneNumber === ''
							? 'bg-white text-gray-dark border border-gray-dark cursor-not-allowed'
							: 'bg-pink-strong text-white cursor-pointer hover:opacity-75 transition-all border border-pink-strong'
					"
					class="flex flex-row items-center justify-center gap-2 w-48 py-2 px-6 rounded-full text-base"
					@click="sendCallingNotificationOrClosePopup"
				>
					<p>
						{{
							computedCallConfirmation
								? $t('MENU.UPSELL_POPIN.CALLING_SCREEN.CLOSE')
								: $t('MENU.UPSELL_POPIN.CALLING_SCREEN.SEND')
						}}
					</p>
				</div>
			</div>
		</div>
		<div
			v-if="computedDisplayPopup === 'hubspotScreen'"
			style="width: auto"
		>
			<div
				class="flex flex-row items-center border-b border-gray-light pb-3 mb-6 text-black text-xl"
			>
				<i
					class="gui-arrow_left cursor-pointer hover:text-pink-strong"
					@click="goBack"
				/>
				<p class="font-bold">
					{{ $t('MENU.UPSELL_POPIN.HUBSPOT_SCREEN.TITLE') }}
				</p>
			</div>
			<div class="flex flex-col justify-center items-center text-black">
				<p class="text-center">
					{{ $t('MENU.UPSELL_POPIN.HUBSPOT_SCREEN.SUBTITLE1') }}
				</p>
				<p>{{ $t('MENU.UPSELL_POPIN.HUBSPOT_SCREEN.SUBTITLE2') }}</p>
			</div>
			<div
				id="upsellHubspot"
				class="meetings-iframe-container"
				:data-src="this.$i18n.locale === 'fr' ? 'https://meetings.hubspot.com/charles49?embed=true' : 'https://meetings.hubspot.com/helen-sabatine?embed=true'"
			/>
		</div>
	</el-dialog>
</template>

<script>
import { openChat } from 'src/extensions/support'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import analytics, { TRACKING } from '../../external/analytics'

export default {
	props: {
		isPopUpShown: {
			type: Boolean,
			required: true,
			default: false,
		},
		source: {
			type: String,
			required: true,
			default: 'menu',
		},
	},
	emits: ['update:is-pop-up-shown'],
	data() {
		return {
			popupTexts: [
				this.$t('MENU.UPSELL_POPIN.LIST1'),
				this.$t('MENU.UPSELL_POPIN.LIST2'),
				this.$t('MENU.UPSELL_POPIN.LIST3'),
			],
			options: [
				{ text: this.$t('MENU.UPSELL_POPIN.OPTION3'), icon: 'calendar', command: 'hubspot' },
				{ text: this.$t('MENU.UPSELL_POPIN.OPTION1'), icon: 'woman-support', command: 'calling' },
				{ text: this.$t('MENU.UPSELL_POPIN.OPTION2'), icon: 'paper_plane', command: 'chat' },
			],
			displayedPopup: 'optionsScreen',
			selectCallAvailability: this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION1'),
			calledBackOptions: [
				this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION1'),
				this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION2'),
				this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION3'),
				this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION4'),
				this.$t('MENU.UPSELL_POPIN.CALLING_SCREEN.OPTION5'),
			],
			phoneNumber: '',
			callConfirmation: false,
			dialogWidth: '816px',
			analytics,
			TRACKING,
		}
	},
	computed: {
		...mapGetters(['userConnected']),
		...mapGetters({ myGroup: 'info_campagne' }),

		computedIsPopUpShown: {
			get() {
				return this.isPopUpShown
			},
			set(val) {
				this.$emit('update:is-pop-up-shown', val)
			},
		},

		computedDisplayPopup: {
			get() {
				return this.displayedPopup
			},
			set(val) {
				this.displayedPopup = val
			},
		},
		computedDropDownTexts: {
			get() {
				return this.dropDownTexts
			},
			set(val) {
				this.dropDownTexts = val
			},
		},
		computedSelectCallAvailability: {
			get() {
				return this.selectCallAvailability
			},
			set(val) {
				this.selectCallAvailability = val
			},
		},
		computedPhoneNumber: {
			get() {
				return this.phoneNumber
			},
			set(val) {
				if (val === '\n') {
					this.phoneNumber = ''
				}
				else {
					this.phoneNumber = val
				}
			},
		},
		computedCallConfirmation: {
			get() {
				return this.callConfirmation
			},
			set(val) {
				this.callConfirmation = val
			},
		},
	},
	watch: {
		computedDisplayPopup(newValue) {
			if (newValue === 'hubspotScreen') {
				let hubspotScript = document.createElement('script')
				hubspotScript.setAttribute(
					'src',
					'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
				)
				hubspotScript.setAttribute('type', 'text/javascript')
				document.head.appendChild(hubspotScript)
			}
			if (newValue === 'optionsScreen') {
				this.dialogWidth = '816px'
			}
			else if (newValue === 'callingScreen') {
				this.dialogWidth = '350px'
			}
			else {
				this.dialogWidth = ''
			}
		},
	},
	mounted() {
		this.computedPhoneNumber = get(this.userConnected, 'phone', '')
	},
	methods: {
		goToCallingScreen() {
			this.computedDisplayPopup = 'callingScreen'
		},
		goToHubSpotScreen() {
			this.computedDisplayPopup = 'hubspotScreen'
		},
		goBack() {
			this.computedDisplayPopup = 'optionsScreen'
		},
		handleOption(option) {
			switch (option) {
				case 'calling':
					analytics.track(TRACKING.premium_requested, { targeted_feature: this.source, btn_clicked: 'calling' })
					this.goToCallingScreen()
					break
				case 'chat':
					analytics.track(TRACKING.premium_requested, { targeted_feature: this.source, btn_clicked: 'chat' })
					openChat()
					break
				case 'hubspot':
					analytics.track(TRACKING.premium_requested, { targeted_feature: this.source, btn_clicked: 'demo' })
					this.goToHubSpotScreen()
					break

				default:
					break
			}
		},
		handleClose() {
			this.goBack()
			this.computedPhoneNumber = get(this.userConnected, 'phone', '')
			this.computedSelectCallAvailability = this.calledBackOptions[0]
			this.computedIsPopUpShown = false
		},
		inputPhoneNumber(e) {
			this.computedPhoneNumber = e.target.innerText
		},
		handleCallingAvailability(availability) {
			this.computedSelectCallAvailability = availability
		},
		closeCallPopup() {
			this.goBack()
			this.computedCallConfirmation = false
			this.computedIsPopUpShown = false
		},
		async sendCallingNotificationOrClosePopup() {
			if (this.computedPhoneNumber !== '' && !this.computedCallConfirmation) {
				const body = {
					text: `New Calling Upsell`,
					blocks: [
						{
							type: 'header',
							text: {
								type: 'plain_text',
								text: `New Calling Upsell`,
							},
						},
						{
							type: 'section',
							fields: [
								{
									type: 'mrkdwn',
									text: `>*Space Name: ${get(
										this.myGroup,
										'name',
										'undefined',
									)}*\n>*:globe_with_meridians: Locale: "${get(
										this.userConnected,
										'locale',
										'undefined',
									)}"*`,
								},
								{
									type: 'mrkdwn',
									text: `>*Group :id: ${get(this.userConnected, 'group_id', 'undefined')}*`,
								},
							],
						},
						{
							type: 'section',
							fields: [
								{
									type: 'mrkdwn',
									text: `>*:bust_in_silhouette: Firstname/Lastname*\n>_${get(
										this.userConnected,
										'firstname',
										'undefined',
									)} ${get(
										this.userConnected,
										'surname',
										'undefined',
									)}_\n>:phone: *Phonenumber*\n>_${
										this.computedPhoneNumber
									}_\n>:calendar: *Call back preference*\n>_${
										this.computedSelectCallAvailability
									}_\n`,
								},
								{
									type: 'mrkdwn',
									text: `>:email: *Email*\n>_${get(
										this.userConnected,
										'mail',
										'undefined',
									)}_\n>*Source:*\n>_${this.source}_`,
								},
							],
						},
					],
				}

				await fetch(
					`https://hooks.slack.com/services/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`,
					{
						method: 'POST',
						mode: 'no-cors',
						credentials: 'omit',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(body),
					},
				)
				this.computedCallConfirmation = true
			}
			else {
				this.closeCallPopup()
			}
		},
	},
}
</script>
<style>
#upsellPopup :not(#upsellHubspot) iframe {
	display: none;
}
#upsellPopup :is(#upsellHubspot) iframe {
	display: block;
}
/* #upsellPopup.el-dialog__wrapper {
	@apply flex justify-center items-start;
} */
#upsellPopup .el-dialog {
	margin: 0;
	width: min-content;
	height: auto;
}
</style>
