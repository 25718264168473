import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-sm" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "text-pink-main"
}
const _hoisted_4 = { class: "flex justify-end items-center" }
const _hoisted_5 = { class: "mr-2" }


import modalLists from '@/components/contact-module/contact-dynamic-lists/modal-lists.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentDeleteModal',
  props: /*@__PURE__*/_mergeModels({
	segmentSelectedTitle: { type: String, default: '' },
}, {
    "displayModal": { type: Boolean },
    "displayModalModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['delete'], ["update:displayModal"]),
  setup(__props, { emit: __emit }) {

const emit = __emit

const displayModal = _useModel(__props, 'displayModal')

const methodHandleClickDeleteList = () => {
	emit('delete')
}

const props = __props


return (_ctx: any,_cache: any) => {
  const _component_q_button = _resolveComponent("q-button")!

  return (_openBlock(), _createBlock(modalLists, {
    title: _ctx.$t('SETTINGS.SEGMENTS.MODAL.DELETE.TITLE'),
    "show-modal": displayModal.value,
    width: "40%",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (displayModal.value = false))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_button, {
            ghost: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (displayModal.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('_COMMON.CANCEL')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_q_button, { onClick: methodHandleClickDeleteList }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('_COMMON.DELETE')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('SETTINGS.SEGMENTS.MODAL.DELETE.DESCRIPTION')) + " ", 1),
          (__props.segmentSelectedTitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.segmentSelectedTitle), 1))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createTextVNode(" ? "))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('SETTINGS.SEGMENTS.MODAL.DELETE.ALT_DESCRIPTION')), 1)
      ])
    ]),
    _: 1
  }, 8, ["title", "show-modal"]))
}
}

})