import get from 'lodash/get'
import store from '../../../../store/stateStore'
/**
 * @func beforeLeaveRouteHelperGoBack
 * @desc The purpose of this function is to determine
 * if the user is actually clicked on a go back from
 * the window history or the previous button. This helper
 * is exclusive for the Action Creation Workflow.
 * The return of the function is important and used in the if
 * in the component to return if we are in the go back system
 * and apply the modifications when we leave the component.
 */
export default function beforeLeaveRouteHelperGoBack(to) {
	const myGetterStepsToAnalyze = get(store, 'getters.@action/steps')
	const myGetterActiveStepToAnalyze = get(store, 'getters.@action/activeStep')
	if (myGetterActiveStepToAnalyze.index > 0) {
		const indexFound = myGetterStepsToAnalyze.findIndex(
			(arraySteps) => arraySteps.index === myGetterActiveStepToAnalyze.index
		)
		if (indexFound > 0) {
			const pathToSplitted = to.path.split('/')
			const pathTo = pathToSplitted[pathToSplitted.length - 1].toUpperCase()
			if (myGetterStepsToAnalyze[indexFound - 1].title === pathTo) {
				return true
			} else if (myGetterStepsToAnalyze[indexFound + 1].title === pathTo) {
				{
					return false
				}
			}
		}
	}
	return false
}
