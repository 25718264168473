import { acceptHMRUpdate, defineStore } from 'pinia'
import TransactionsRequester from '@/requesters/transactions'
import TransactionsAffectationsRequester from '@/requesters/TransactionAffectations'
import { amountToFrontend } from '@/requesters/transactionSerializer'
import { methodIsAccessGranted } from '@/utils/RoleUtils'
import store from '../store/stateStore'
import { ElMessage } from 'element-plus'
import { useBatchsStore } from '@/stores/batchs'
import { useTransactionStore } from './transactionTunnel'

export const useTransactionsStore = defineStore('transactions-store', {
	state: () => ({
		transactions: [],
		totalTransactions: 0,
		statusCreateTransaction: 'idle',
		settings: {},
		statusTransactionsList: 'init',
		transactionsStatuses: [],
		transactionsStatusesMotifs: [],
		membershipsAndDonationsIds: [],
		affectations: [],
		globalMembershipInfos: {},
	}),

	actions: {
		async actionRequestGetTransactions(limit = 50, offset = 0) {
			this.statusTransactionsList = 'loading'

			// await new Promise((resolve) => setTimeout(resolve, 5000))
			try {
				const response = await TransactionsRequester.getTransactions(limit, offset)
				if (response?.data && response?.data.length > 0) {
					response.data.forEach((aTransaction: TransactionItem) => {
						aTransaction.amount = amountToFrontend(aTransaction.amount)
					})
					this.transactions = this.actionFilterTransactionAccordingRoles(response.data)
					this.totalTransactions = response.total || 0
				}
				this.statusTransactionsList = 'success'
			}
			catch (error) {
				this.statusTransactionsList = 'error'
			}
		},

		async actionRequestSearchTransactions(data = {}, limit = 50, offset = 0) {
			const isForBatchId = data.batch_id ? true : false

			if (!isForBatchId) {
				this.statusTransactionsList = 'loading'
			}

			const formatedCreatedAtData = { ...data }

			if (formatedCreatedAtData?.created_at?.values) {
				formatedCreatedAtData.created_at.values = formatedCreatedAtData.created_at.values.map(
					(aDate) => new Date(Number(aDate)),
				)
			}

			// always add sortByCreatedAt to the search )

			if (formatedCreatedAtData.order_by) {
				formatedCreatedAtData.order_by.push({ column: 'created_at', order: 'desc' })
			}
			else {
				formatedCreatedAtData.order_by = [{ column: 'created_at', order: 'desc' }]
			}

			// await new Promise((resolve) => setTimeout(resolve, 5000))
			try {
				const response = await TransactionsRequester.postSearchTransactions(
					formatedCreatedAtData,
					limit,
					offset,
				)

				const responseData = response.data ?? []

				if (responseData && responseData.length > 0) {
					response.data?.forEach((aTransaction: TransactionItem) => {
						if (!isForBatchId) {
							aTransaction.amount = amountToFrontend(aTransaction.amount)
						}
					})
				}

				if (!isForBatchId) {
					this.totalTransactions = response.total || 0
					this.transactions = this.actionFilterTransactionAccordingRoles(responseData)
					this.statusTransactionsList = 'success'
				}

				return {
					totalTransactions: response.total || 0,
					transactions: this.actionFilterTransactionAccordingRoles(responseData),
				}
			}
			catch (error) {
				this.statusTransactionsList = 'error'
				return error
			}
		},

		actionHydrateTransactionUpdatedEverywhere(updatedTransactionId, updatedTransaction, attributesToUpdate, summaryCount) {
			const batchsStore = useBatchsStore()

			const findedTransaction = this.transactions.find((aTransaction) => aTransaction.id === updatedTransactionId)
			const findedCurrentOpenBatchTransaction = batchsStore?.currentOpenBatch?.transactions?.find(
				(aTransaction) => aTransaction.id === updatedTransactionId,
			)

			attributesToUpdate.forEach((aAttribute) => {
				if (findedTransaction) {
					findedTransaction[aAttribute] = updatedTransaction[aAttribute]
					if (aAttribute === 'products_count') {
						findedTransaction?.transaction_bundle?.summary?.donations_count = summaryCount?.donations_count?.length
						findedTransaction?.transaction_bundle?.summary?.memberships_count = summaryCount?.memberships_count?.length
					}
				}
				if (findedCurrentOpenBatchTransaction) {
					findedCurrentOpenBatchTransaction[aAttribute] = updatedTransaction[aAttribute]
					if (aAttribute === 'products_count') {
						findedCurrentOpenBatchTransaction?.transaction_bundle?.summary?.donations_count = summaryCount?.donations_count?.length
						findedCurrentOpenBatchTransaction?.transaction_bundle?.summary?.memberships_count = summaryCount?.memberships_count?.length
					}
				}
			})
		},

		async actionRequestPatchTransaction(id, data = {}, t = () => {}, localUpdate = false) {
			try {
				const response = await TransactionsRequester.patchTransaction(id, data)

				const batchsStoreCpy = useBatchsStore()

				const keysToUpdate = ['status_id']
				this.actionHydrateTransactionUpdatedEverywhere(id, response, keysToUpdate)

				const findedTransaction = this.transactions.find((aTransaction) => aTransaction.id === id)

				if (findedTransaction) {
					findedTransaction.status_id = response.status_id
				}

				const findedCurrentOpenBatchTransaction = batchsStoreCpy?.currentOpenBatch?.transactions?.find(
					(aTransaction) => aTransaction.id === id,
				)

				if (findedCurrentOpenBatchTransaction) {
					findedCurrentOpenBatchTransaction.status_id = response.status_id
				}
				const transactionStore = useTransactionStore()

				localUpdate
				&& transactionStore.actionSetCurrentTransactionStatus({
					status_id: data.status_id,
					reimbursed_amount: data.reimbursed_amount,
					unpaid_amount: data.unpaid_amount,
					motif_id: data?.motif_id,
					comment_date: data?.comment_date,
					comment: data?.comment,
				})

				ElMessage({
					dangerouslyUseHTMLString: true,
					message: t('TRANSACTIONS.REQUEST_MESSAGE.SUCCESS.UPDATE_STATUS'),
					type: 'success',
					duration: 5000,
				})
			}
			catch (error) {
				ElMessage({
					message: t('TRANSACTIONS.REQUEST_MESSAGE.ERRORS.UPDATE_STATUS'),
					type: 'error',
					duration: 4000,
				})
			}
		},

		async actionRequestDeleteTransaction(id) {
			// alert(`delete id  from store action, id : ${id}`)
			console.log('delete id  from store action, id : ', id)
			// try {
			// 	const response = await TransactionsRequester.deleteTransaction(id)
			// 	this.transactions = response
			// } catch (error) {
			// 	console.log(error)
			// }
		},

		async actionRequestDeleteTransactions(ids) {
			// alert(`many ids deleted : ${ids}`)
			console.log(`many ids deleted : ${ids}`)

			// try {
			// 	const response = await TransactionsRequester.deleteTransaction(id)
			// 	this.transactions = response
			// } catch (error) {
			// 	console.log(error)
			// }
		},

		async actionRequestGetTransactionsSettings() {
			try {
				const response = await TransactionsRequester.getTransactionsSettings()
				const currency = response?.currency || 'eur'
				this.settings = { ...response, currency }
			}
			catch (error) {
				console.log(error)
			}
		},

		/**
		 * @func actionRequestCreationTransaction
		 */
		async actionRequestCreationTransaction(data: any, t = () => {}, routingCallback: Function) {
			this.statusCreateTransaction = 'loading'

			try {
				const response = await TransactionsRequester.postCreateTransaction(data)

				this.statusCreateTransaction = 'success'
				ElMessage({
					message: t('TRANSACTIONS.REQUEST_MESSAGE.SUCCESS.CREATION'),
					type: 'success',
				})
				routingCallback()
			}
			catch (error) {
				this.statusCreateTransaction = 'error'
				ElMessage({
					message: t('TRANSACTIONS.REQUEST_MESSAGE.ERRORS.CREATION'),
					type: 'error',
				})
				routingCallback()
			}

			this.statusCreateTransaction = 'idle'
		},

		async actionRequestUpdateTransactionBundle(
			id: number,
			data: any,
			t = () => {},
			routingCallback: Function,
			updateTransactionLocally = false,
		) {
			this.statusCreateTransaction = 'loading'

			try {
				if (this.membershipsAndDonationsIds.length > 0) {
					this.actionDeleteMembershipsAndDonationsByIds(data.databaseId)
				}

				const response = await TransactionsRequester.patchTransactionBundle(id, data)

				if (response) {
					this.statusCreateTransaction = 'success'
					const keysToUpdate = ['products_count', 'status_id', 'reimbursed_amount', 'unpaid_amount', 'motif_id', 'comment_date', 'comment']
					updateTransactionLocally && this.actionHydrateTransactionUpdatedEverywhere(response.transactions[0].id, response.transactions[0], keysToUpdate, response.summary)

					ElMessage({
						message: t('TRANSACTIONS.REQUEST_MESSAGE.SUCCESS.UPDATED'),
						type: 'success',
					})
					if (routingCallback) routingCallback()
				}
			}
			catch (error) {
				this.statusCreateTransaction = 'error'
				ElMessage({
					message: t('TRANSACTIONS.REQUEST_MESSAGE.ERRORS.UPDATED'),
					type: 'error',
				})
				if (routingCallback) routingCallback()
				console.log(error)
			}

			this.statusCreateTransaction = 'idle'
		},

		actionDeleteMembershipsAndDonationsByIds(aMembershipOrDonation = null) {
			if (aMembershipOrDonation) {
				this.membershipsAndDonationsIds.push(aMembershipOrDonation)
			}

			try {
				this.membershipsAndDonationsIds.forEach((aMembershipOrDonation) => {
					TransactionsRequester.deleteMembershipOrDonation(
						aMembershipOrDonation.id,
						aMembershipOrDonation.type,
					)
				})
				this.membershipsAndDonationsIds = []
			}
			catch (error) {
				this.membershipsAndDonationsIds = []
				console.log(error)
			}
		},

		async actionRequestDeleteTransactionBundle(bundleId: number, isFromBatchByIdView = false) {
			const batchsStore = useBatchsStore()

			try {
				const response = await TransactionsRequester.deleteTransactionBundle(bundleId)

				// update transaction listing store
				this.transactions = this.transactions.filter(
					(aTransaction) => aTransaction.transaction_bundle_id !== bundleId,
				)

				// update store ->  transaction listing inside currentBatch
				batchsStore.actionUpdateBatchLocallyaAfterTransactionDeletion(bundleId, isFromBatchByIdView)

				return response
			}
			catch (error) {
				console.log(error)
			}
		},

		async actionRequestGetTransactionsStatuses() {
			try {
				const response = await TransactionsRequester.getTransactionsStatuses()

				this.transactionsStatuses = response
			}
			catch (error) {
				console.log(error)
			}
		},

		async actionRequestGetTransactionsStatusesMotifs() {
			try {
				const response = await TransactionsRequester.getTransactionsStatusesMotifs()

				this.transactionsStatusesMotifs = response
			}
			catch (error) {
				console.log(error)
			}
		},

		// TODO : Role management should be on back side so this will be just a security
		actionFilterTransactionAccordingRoles(transactionsList = []) {
			// If the user don't have the role to see all the transactions, show only his
			if (!methodIsAccessGranted('transaction_all:read')) {
				return transactionsList.filter(
					(transaction: any) => transaction.user_id == store.getters.userConnected.id,
				)
			}

			return transactionsList
		},

		async actionGetAffectations() {
			try {
				const response = await TransactionsAffectationsRequester.getAffectations()

				this.affectations = response
			}
			catch (error) {
				console.log(error)
			}
		},

		actionFetchMembershipInfos() {
			TransactionsRequester.getMembershipGlobalInfos().then((infos) => {
				this.globalMembershipInfos = infos.year_available
			})
		},
	},

	getters: {
		getTransactions: (state) => state.transactions,
		getStatusCreateTransaction: (state) => state.statusCreateTransaction,
		getTotalTransactions: (state) => state.totalTransactions,
		getTransactionsSettings: (state) => state.settings,
		getStatusTransactionsList: (state) => state.statusTransactionsList,
		getStatusTransactionsStatuses: (state) => {
			return state.transactionsStatuses
				? state.transactionsStatuses.map((status: TransactionStatus) => {
					return {
						value: status.id,
						label: status.name,
						color: status.color,
						kind: status.kind,
					}
				})
				: []
		},
		getStatusTransactionsStatusesMotifs: (state) => {
			return state.transactionsStatusesMotifs
		},
		getAffectations: (state) => state.affectations ? state.affectations.sort((a, b) => a.code.localeCompare(b.code)) : [],
		getGlobalMembershipInfos: (state) => state.globalMembershipInfos,
	},
})

if (import.meta.webpackHot) {
	import.meta.webpackHot.accept(acceptHMRUpdate(useTransactionsStore, import.meta.webpackHot))
}
