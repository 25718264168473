<template>
	<div class="enquete q-col unset">
		<div
			v-if="isLoading"
			class="q-container q-fullwidth"
			style="display: flex; align-items: center"
		>
			<div class="center q-fullwidth">
				<loader size="10em"></loader>
			</div>
		</div>
		<div class="q-fullwidth right q-p-lg">
			<el-select v-model="questionId" class="q-fullwidth">
				<el-option
					v-for="question in formattedForms"
					:key="question.id"
					:label="question.label"
					:value="question.id"
				></el-option>
			</el-select>
		</div>
		<iframe
			id="external-iframe1"
			ref="extiframe"
			class="expander"
			frameborder="0"
			width="100%"
			allowtransparency
			visibility="hidden"
			@load="load($refs.extiframe)"
		></iframe>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { METABASE } from '../../constants'
import Loader from '../general/loader.vue'
import { firstBy } from 'thenby'
import { metabaseCrypto } from '../mixins/metabase-crypto-mixin'

export default {
	name: 'Enquete',
	components: { Loader },
	mixins: [metabaseCrypto],

	data() {
		return {
			METABASE_SITE_URL: METABASE.URL,
			loading: true,
			questionId: -1,
			intercom: undefined,
		}
	},

	computed: {
		...mapGetters(['userConnected', 'isLoading', 'info_campagne']),
		...mapGetters('@form', ['forms']),

		formattedForms() {
			return this.forms
				.concat() // do not mutate, return a new array
				.sort(firstBy('order'))
				.map((form) => {
					return {
						id: form.id,
						label: form.label,
					}
				})
		},

		iframeUrlMetabaseDashboard() {
			var payload = {
				resource: {
					question: this.$i18n.locale.substring(0, 2) === 'fr' ? 136 : 156,
				},
				params: {
					group_id: this.userConnected.selected_group_id[0],
					form_id: this.questionId,
				},
			}
			var url = this.generateURL(payload, 'question', this.info_campagne.metabase_api_key)
			return url
		},
	},

	watch: {
		formattedForms(newVal) {
			if (this.questionId <= 1) {
				this.questionId = newVal[0].id
			}
		},
		questionId(newVal) {
			this.questionId = newVal
			this.$refs.extiframe.src = this.iframeUrlMetabaseDashboard
		},
	},

	mounted() {
		this.intercomToggle()
		this.actionToggleLoadingState(true)
		if (this.formattedForms.length) {
			this.questionId = this.formattedForms[0].id
		}
		this.$refs.extiframe.src = this.iframeUrlMetabaseDashboard
	},

	beforeUnmount() {
		this.intercomToggle(true)
	},

	methods: {
		...mapActions(['actionToggleLoadingState']),
		intercomToggle(show) {
			this.intercom = this.intercom || document.querySelector('.intercom-launcher-frame')
			if (this.intercom && this.intercom.style)
				this.intercom.style.display = show ? 'block' : 'none'
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/analyse/enquete.scss';
</style>
