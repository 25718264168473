<template>
	<div
		ref="docsWindow"
		class="flex flex-col w-full h-full flex-grow"
		style="justify-content: center"
	>
		<div id="comm-header" class="flex items-center px-10 py-4 bg-white w-full">
			<div class="flex-grow">
				<div
					class="q-column flex flex-col items-start : lg:items-start xl:items-center xl:flex-row w-full"
				>
					<!-- here dropdown -->

					<template v-if="$route.name === 'docs'">
						<div class="flex flex-row force-local-border">
							<docs-filters-dropdowns
								v-model:filters-dialog-visible="filtersDialogVisible"
								v-model:filters-data="filters.docs"
								:default-filters="defaultFilters.docs"
							>
							</docs-filters-dropdowns>
						</div>
					</template>

					<!-- end dropdown -->
					<div
						class="mr-5 search-input-container flex items-center px-4 border-gray-500 hover:border-pink-main rounded-full w-full w-full sm:w-96"
					>
						<i class="gui-search text-2xl mx-2 flex items-center font-bold"></i>
						<input
							id="searchBar"
							v-model="searchInput"
							class="h-full w-full bg-transparent q-search focus:outline-none"
							:placeholder="t.header.search"
						/>
					</div>

					<div class="row md:right q-v-spacer-s q-h-spacer"></div>

					<div v-if="$route.name === 'briefs'" class="row mt-0 mb-0 md:mt-auto lg:mt-auto">
						<q-button
							v-if="selectedIds.length && elements.length"
							class="delete"
							@click="deleteSelected"
						>
							{{ t.header.deleteElements }}
						</q-button>
					</div>
					<div v-else class="mt-0 mb-0 md:mt-auto lg:mt-auto">
						<!-- Invisible input clicked on by button -->
						<input
							id="file"
							type="file"
							style="display: none"
							multiple="multiple"
							@change="retrieveDoc"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="h-full flex flex-col flex-grow">
			<keep-alive v-if="$route.name === 'briefs'">
				<div class="mx-8 mt-6 bg-white h-full">
					<briefs
						:edit="editBrief"
						:search-input="searchInput"
						@add="methodAddModalBriefVisible(true)"
						@set-selected-ids="setSelectedIds"
						@set-selected-data="setSelectedData"
					></briefs>
				</div>
			</keep-alive>
			<keep-alive v-else>
				<div class="flex flex-col flex-grow">
					<div
						id="categories-bar"
						class="flex items-center w-full bg-white px-10 overflow-y-hidden overflow-x-auto pb-2"
					>
						<div class="flex horizontal-scrollbar overflow-x-auto pb-2">
							<div
								class="p-1 px-4 m-2 ml-0 rounded-full hover:opacity-60 text-base cursor-pointer custom-font-size"
								:class="currentDocCategoryFilter.id ? 'bg-gray-lighted' : 'bg-black text-white'"
								style="min-width: fit-content"
								@click="methodSetCurrentDocCategoryFilter()"
							>
								{{ $t('_COMMON.ALL') }}
							</div>
							<div
								class="p-1 px-4 m-2 rounded-full hover:opacity-60 text-base cursor-pointer custom-font-size"
								:class="
									currentDocCategoryFilter.id !== 'withoutCategory'
										? 'bg-gray-lighted'
										: 'bg-black text-white'
								"
								style="min-width: fit-content"
								@click="methodSetCurrentDocCategoryFilter({ id: 'withoutCategory', label: '' })"
							>
								{{ $t('SHARING2.DOCS.CATEGORIES.NOT_CLASSIFIED') }}
							</div>

							<template v-for="(aCategory, index) in docsCategories" :key="index">
								<div
									class="p-1 px-4 m-2 rounded-full hover:opacity-80 text-base cursor-pointer custom-font-size"
									style="min-width: fit-content"
									:class="
										currentDocCategoryFilter.id === aCategory.id
											? 'bg-black text-white'
											: 'bg-gray-lighted'
									"
									@click="methodSetCurrentDocCategoryFilter(aCategory)"
								>
									{{ aCategory.label }}
								</div>
							</template>

							<template v-if="computedDialogOrderCategoriesVisible">
								<manage-doc-category-order
									v-model:dialog-order-categories-visible="computedDialogOrderCategoriesVisible"
									:docs-categories="docsCategories"
									:method-patch-category="methodPatchCategory"
									:method-patch-categories="methodPatchCategories"
								/>
							</template>
						</div>
						<div class="w-10">
							<div
								v-if="docsCategories.length"
								class="flex items-center pb-2"
								style="z-index: 1100; min-width: fit-content"
							>
								<el-tooltip :content="$t('SHARING2.DOCS.ACTIONS.SETTINGS.TOOTLTIP')">
									<i
										class="pl-2 gui-settings text-xl cursor-pointer text-gray-medium hover:text-black"
										@click="
											() => {
												computedDialogOrderCategoriesVisible = true
											}
										"
									></i>
								</el-tooltip>
							</div>
						</div>
					</div>

					<div class="mx-4 mt-4 h-full flex flex-grow">
						<DialogAddDocOrLink
							v-model:files-list="filesList"
							v-model:new-doc-from-dialog="newDocFromDialog"
							v-model:dialog-add-doc-or-link-visible="computedDialogAddDocOrLinkVisible"
							v-model:dialog-step="dialogStep"
							:docs-categories="docsCategories"
							:method-add-category="methodAddCategory"
							:method-add-link="methodAddLinkV2"
							:method-add-from-upload="addDocument"
							:method-reset-doc-from-dialog="methodResetDocFromDialog"
							:method-remove-a-file="methodRemoveAFile"
							:method-save-and-update-doc="saveAndUpdateDoc"
							:method-drag-behavior="dragBehavior"
						></DialogAddDocOrLink>

						<docs
							v-model:files-list="filesList"
							v-model:dialog-add-doc-or-link-visible="computedDialogAddDocOrLinkVisible"
							v-model:new-doc-from-dialog="newDocFromDialog"
							v-model:dialog-step="dialogStep"
							:edit="editDoc"
							:search-input="searchInput"
							:visualize="visualizeDoc"
							:delete-selected="deleteSelected"
							:window-width="windowWidth"
							:docs-categories="docsCategories"
							:method-remove-a-file="methodRemoveAFile"
							:method-add-from-upload="addDocument"
							:current-doc-category-filter="currentDocCategoryFilter"
							:filters-data="filters.docs"
							:method-drag-behavior="dragBehavior"
							@add="addDocument"
							@set-selected-ids="setSelectedIds"
							@set-selected-data="setSelectedData"
						></docs>
					</div>
				</div>
			</keep-alive>
		</div>
		<div class="qview-main">
			<div id="comm-container" class="q-hide-scroll pure-u-sm-1-1">
				<router-view class="q-padding q-hide-scroll-content" @edit="edit"></router-view>
			</div>
		</div>
		<!-- Edit/Add pannel for brief -->
		<modal
			v-if="$route.name === 'briefs'"
			name="briefmodal"
			:is-active="dialogAddModalBriefVisible"
			:reset-action="closeBrief"
			overflow="auto"
			large
		>
			<template #title>
				<h2 class="bg-white center my-2 text-xl bold">
					{{ isEditing === true ? t.modal.editTitle : t.modal.add }}
				</h2>
			</template>
			<template #content>
				<div class="q-m-lg">
					<div class="flex flex-col gap-2">
						<span class="text-gray-400"
							>{{ t.modal.name }}<span class="text-pink-main"> *</span></span
						>
						<el-input v-model="newBrief.label"></el-input>
					</div>

					<div class="q-m-t">
						<textarea
							v-model="newBrief.refvalues[0].label"
							class="border-gray-500 q-fullwidth p-2 rounded-lg"
							rows="10"
							:placeholder="t.modal.placeholder"
						></textarea>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="q-p-h q-p-b right">
					<q-button ghost class="mr-1" @click="closeBrief">{{ t.modal.cancel }}</q-button>
					<q-button
						:disabled="!newBrief.label || !newBrief.refvalues[0].label"
						@click="saveBrief"
						>{{ t.modal.save }}</q-button
					>
				</div>
			</template>
		</modal>
		<!-- Rename pannel for document + display -->
		<modal
			v-else
			name="docmodal"
			:is-active="addModalDoc"
			:reset-action="closeDoc"
			overflow="auto"
			:default="getType(newDoc) === 'other' || isEditing"
			:large="getType(newDoc) !== 'other'"
		>
			<template #title>
				<div
					class="bg-white"
					style="
						font-weight: bold;
						padding-top: 10px;
						padding-left: 30px;
						padding-right: 50px;
						color: black;
						font-size: 20px;
						padding-bottom: 15px;
					"
				>
					{{ isEditing === true ? t.modal.editTitle : newDoc.subject }}
				</div>
			</template>
			<template #content>
				<div v-if="isEditing" class="q-m-lg flex flex-col gap-2">
					<span class="text-gray-400">{{ t.modal.name }}</span>
					<el-input v-model="newDoc.subject"> </el-input>
				</div>
				<div v-else-if="isVisualizing">
					<div
						v-if="getType(newDoc) === 'other' || getType(newDoc) === 'pdf'"
						style="display: flex; justify-content: left; padding-left: 30px; padding-right: 50px"
					>
						<div>
							{{ t.modal.noVisualization }}
							<div style="padding-bottom: 15px">
								{{ t.modal.download }}
								<a class="q-link" :href="newDoc.url" target="_blank">{{ t.modal.downloadEnd }}</a
								>.
							</div>
						</div>
					</div>
					<div
						v-if="getType(newDoc) === 'youtube'"
						style="display: flex; justify-content: left; padding-left: 30px; padding-right: 50px"
					>
						<div class="flex flex-col w-full aspect-w-16 aspect-h-9">
							<div class="video-frame-container">
								<iframe
									width="100%"
									height=""
									:src="`https://www.youtube-nocookie.com/embed/${extractVideoId(newDoc.url)}`"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
									class="video"
								></iframe>
							</div>
							<div>
								<p class="py-2">
									<span class="pr-2"> {{ $t('SHARING2.DOCS.MODAL.CANT_READ_YOUTUBE') }} </span>

									<a class="text-pink-main" :href="newDoc.url" target="_blank">
										{{ $t('SHARING2.DOCS.MODAL.OPEN_VIDEO') }}
									</a>
								</p>
							</div>
						</div>
					</div>
					<div v-if="getType(newDoc) === 'link'">
						<div class="flex text-left px-8 pb-6">
							<span>
								{{ t.modal.linkType }}
								<a class="q-link" :href="newDoc.url" target="_blank">{{ t.modal.clickHere }}</a>
								{{ t.modal.toOpen }}</span
							>
						</div>
					</div>
					<div
						v-else
						style="display: flex; justify-content: center; padding-left: 30px; padding-right: 30px"
					>
						<img v-if="getType(newDoc) === 'image'" :src="newDoc.url" />
						<div v-else-if="getType(newDoc) === 'video'" style="max_width: 100%">
							<video controls>
								<source :src="newDoc.url" />
							</video>
						</div>
					</div>
				</div>
			</template>
			<template v-if="!isVisualizing" #footer>
				<div class="q-p-h q-p-b right">
					<q-button ghost class="mr-1" @click="closeDoc">{{ t.modal.cancel }}</q-button>
					<q-button @click="saveDoc">{{ t.modal.save }}</q-button>
				</div>
			</template>
		</modal>

		<!-- delete dialog -->

		<delete-dialog
			:cancel="methodResetDeleteDialog"
			:confirm="confirmDelete"
			:close-dialog="methodResetDeleteDialog"
			:visible-dialog="deleteDialog.visible"
			:title="deleteDialog.title"
		>
			<div class="custom_dialog w-full">
				<div class="flex flex-col py-3">
					<p v-if="deleteDialog" class="pb-2">
						{{ deleteDialog.subtitle }}
					</p>

					<div id="team-users-dialog">
						<div class="flex flex-col w-full">
							<div class="flex flex-wrap">
								<template v-for="(aData, index) in deleteDialog.data" :key="index">
									<template v-if="deleteDialog.data">
										<div
											v-if="index <= 9"
											class="w-fit flex flex-row bg-userRole-user-light border-userRole-user-main border rounded-xl m-1 items-center justify-center p-2 flex flex-col justify-center items-center"
										>
											<div class="flex flex-col">
												<div class="flex justify-center">
													{{ aData }}
												</div>
											</div>
										</div>
										<div
											v-if="index === 10"
											class="relative flex avatar-container items-center justify-center"
										>
											<span
												class="avatar-placeholder flex justify-center items-center rounded-full bg-gray-100 border-2 border-white"
											>
												+{{ aData.length - 10 }}</span
											>
										</div>
									</template>
								</template>
							</div>
						</div>
					</div>

					<div class="py-3">
						<div class="border p-2 border-zinc-200 border relative rounded-lg p-4">
							<div class="p-3 rounded-lg">
								<ul class="relative">
									<li
										v-for="aItem in deleteDialog.contentList"
										:key="aItem.id"
										class="relative pl-5 py-1"
									>
										<i
											class="flex rotate-icon-180 text-2xl gui-back -top-0 absolute -left-2 t-0"
										></i>
										{{ aItem }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</delete-dialog>
		<DialogShareDoc
			v-model:is-visible="showShareDialog"
			:name-doc="nameShareDialog"
			:url-doc="urlShareDialog"
			:method-on-close="methodCloseShareDocDialog"
			:doc-id="docIdShareDialog"
		></DialogShareDoc>
	</div>
</template>
<script>
import Briefs from './briefs.vue'
import DialogAddDocOrLink from './dialogAddDocOrLink.vue'
import DocsFiltersDropdowns from './docsFiltersDropdowns.vue'
import ManageDocCategoryOrder from './manageDocCategoryOrder.vue'
import Docs from './docs.vue'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { defineAsyncComponent } from 'vue'
import modal from 'components/general/modal'

export default {
	components: {
		modal,
		Briefs,
		DialogAddDocOrLink,
		Docs,
		ManageDocCategoryOrder,
		DocsFiltersDropdowns,
		deleteDialog: defineAsyncComponent(
			() => import('../user_management-module/user-list/sub-components/deleteDialog.vue')
		),
		DialogShareDoc: defineAsyncComponent(() => import('./dialogShareDoc.vue')),
	},

	props: {
		dialogAddDocOrLinkVisible: {
			type: Boolean,
			default: false,
		},
		methodToggleDialogAddDocOrLinkVisible: {
			type: Function,
			default: () => {},
		},
		dialogAddModalBriefVisible: {
			type: Boolean,
			default: false,
		},
		methodAddModalBriefVisible: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			selectedIds: [], // Selectied IDs to be deleted
			selectedData: [],
			searchInput: '', // Current query for search bar
			addModalDoc: false, // Renaming doc
			isEditing: false, // Editing ?
			isVisualizing: false, // Visualizing ?
			newBrief: {
				// Brief to be added/edited
				active: false,
				label: '',
				refvalues: [{ label: '' }],
			},
			currentDocCategoryFilter: { id: '', label: '' },
			newDoc: {}, // Document to be renamed,
			windowWidth: 0,
			showLinkModal: false,
			linkUrl: '',
			linkName: '',
			showHttpError: false,
			showTypeError: false,
			debounceTimeout: null,
			onChangeIsEnded: false,
			newDocFromDialog: {
				type: '',
				category: {
					id: null,
					label: this.$t('SHARING2.DOCS.CATEGORIES.NOT_CLASSIFIED'),
				},
				cover: '',
				linkName: '',
				linkUrl: '',
				currentDocId: null,
				shareable: 'internal',
			},
			cloneNewDocFromDialog: {},
			filesList: [],
			dialogStep: 'init',
			dialogOrderCategoriesVisible: false,
			filtersDialogVisible: false,
			defaultFilters: {},

			showShareDialog: false,
			nameShareDialog: '',
			urlShareDialog: '',
			docIdShareDialog: {},

			deleteDialog: {
				visible: false,
				title: '',
				subtitle: '',
				data: [],
				contentList: [],
				itemSelectedLength: 1,
			},

			filters: {
				docs: [
					{
						mappingKeyForFilter: 'type',
						title: this.$t('SHARING2.DOCS.FILTERS.TYPE'),
						type: 'checkbox',

						values: [
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.IMAGE'),
								value: 'image/',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.VIDEO'),
								value: 'video/',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.WORD'),
								value: 'wordprocessingml',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.EXCEL'),
								value: 'spreadsheetml',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.PDF'),
								value: 'pdf',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.CATEGORIES_LISTING.LINK'),
								value: [
									'docs.google',
									'drive.google',
									'1drv',
									'dropbox',
									'icloud',
									'sharepoint',
									'youtube',
								],
								isChecked: false,
							},
						],
					},
					{
						mappingKeyForFilter: 'category',
						title: this.$t('SHARING2.DOCS.FILTERS.SHARE'),
						type: 'radio',

						values: [
							{
								label: this.$t('SHARING2.DOCS.FILTERS.INTERNAL_OR_EXTERNAL.INTERNAL'),
								value: 'internal',
								isChecked: false,
							},
							{
								label: this.$t('SHARING2.DOCS.FILTERS.INTERNAL_OR_EXTERNAL.EXTERNAL'),
								value: 'external',
								isChecked: false,
							},
						],
					},
				],
			},
		}
	},

	computed: {
		...mapGetters('@briefs', ['briefs']),
		...mapGetters('@docs', ['docs', 'categories', 'loading', 'docsCategories']),

		t() {
			var prefix = `SHARING2.${this.$route.name.toUpperCase()}`
			return {
				modal: {
					editTitle: this.$t(`${prefix}.MODAL.EDIT_TITLE`),
					add: this.$t(`${prefix}.MODAL.ADD`),
					name: this.$t(`${prefix}.MODAL.NAME`),
					placeholder: this.$t(`${prefix}.MODAL.PLACEHOLDER`),
					cancel: this.$t(`${prefix}.MODAL.CANCEL`),
					save: this.$t(`${prefix}.MODAL.SAVE`),

					download: this.$t(`${prefix}.MODAL.DOWNLOAD`),
					downloadEnd: this.$t(`${prefix}.MODAL.DOWNLOAD_END`),
					noVisualization: this.$t(`${prefix}.MODAL.NO_VISUALIZATION`),
					linkType: this.$t(`${prefix}.MODAL.LINK_TYPE`, {
						type: this.methodGetLinkWordingTypology(this.newDoc.type),
					}),
					clickHere: this.$t(`${prefix}.MODAL.CLICK_HERE`),
					toOpen: this.$t(`${prefix}.MODAL.TO_OPEN`),
				},
				header: {
					title: this.$t(`${prefix}.HEADER.TITLE`),
					subtitle: this.$t(`${prefix}.HEADER.SUBTITLE`),
					deleteElements: this.$tc(`${prefix}.HEADER.DELETE`, this.selectedIds.length, {
						elements: this.selectedIds.length,
					}),
					search: this.$t(`${prefix}.HEADER.SEARCH`),
					add: this.$t(`${prefix}.HEADER.ADD`),
					modal_title: this.$t(`${prefix}.HEADER.MODAL_TITLE`),
					add_file: this.$t(`${prefix}.HEADER.ADD_FILE`),
					add_link: this.$t(`${prefix}.HEADER.ADD_LINK`),
					link_explanation: this.$t(`${prefix}.HEADER.LINK_EXPLANATION`),
					name_label: this.$t(`${prefix}.HEADER.NAME_LABEL`),
					name_placeholder: this.$t(`${prefix}.HEADER.NAME_PLACEHOLDER`),
					url_label: this.$t(`${prefix}.HEADER.URL_lABEL`),
					url_error: this.$t(`${prefix}.HEADER.URL_ERROR`),
					type_error: this.$t(`${prefix}.HEADER.TYPE_ERROR`),
				},

				success: {
					add: (number) => this.$tc(`${prefix}.SUCCESS.ADD`, number, { count: number }),
					edit: this.$t(`${prefix}.SUCCESS.EDIT`),
					update: this.$t(`${prefix}.SUCCESS.UPDATE`),
					delete: (number) => this.$tc(`${prefix}.SUCCESS.DELETE`, number, { count: number }),
				},
				confirm: {
					title: this.$t(`${prefix}.CONFIRM.TITLE`),
					cancel: this.$t(`${prefix}.CONFIRM.CANCEL`),
					confirm: this.$t(`${prefix}.CONFIRM.CONFIRM`),
					delete: (number) => this.$tc(`${prefix}.CONFIRM.DELETE`, number, { count: number }),
				},
				error: this.$t(`${prefix}.ERROR`),
				errorTooLarge: this.$t(`${prefix}.ERROR_TOO_LARGE`),
				categories: {
					external: this.$t(`${prefix}.CATEGORIES.EXTERNAL`),
					internal: this.$t(`${prefix}.CATEGORIES.INTERNAL`),
				},
			}
		},

		computedDialogOrderCategoriesVisible: {
			get() {
				return this.dialogOrderCategoriesVisible
			},
			set(newValue) {
				this.dialogOrderCategoriesVisible = newValue
			},
		},

		computedCheckedItems() {
			return this.filters.docs.reduce(
				(acc, doc) => {
					const checkedValues = doc.values.filter((value) => value.isChecked)
					acc.total += checkedValues.length
					acc.values.push(...checkedValues.map((value) => value.label))
					return acc
				},
				{ total: 0, values: [] }
			)
		},

		computedDialogAddDocOrLinkVisible: {
			get() {
				return this.dialogAddDocOrLinkVisible
			},
			set(newValue) {
				this.methodToggleDialogAddDocOrLinkVisible(newValue)
			},
		},

		// Briefs or documents according to route
		elements() {
			switch (this.$route.name) {
				case 'briefs':
					return this.briefs
				case 'docs':
					return this.docs
				default:
					return true
			}
		},
	},

	watch: {
		searchInput() {
			const { query, name } = this.$route
			this.$router.replace({
				name: name,
				query: {
					...query,
					b: this.searchInput,
				},
			})
		},
		loading(newValue, oldValue) {
			if (this.showLinkModal && oldValue === true && newValue === false) {
				this.showLinkModal = false
			}
		},
	},

	mounted() {
		this.$router.push({ name: 'sharing' })
		this.actionFetchDocs()
		this.actionFetchBriefs()
		this.actionFetchCategories()
		this.searchInput = this.$route.query.b || ''
		this.windowWidth = this.$refs.docsWindow?.clientWidth // Get windowWidth to handle different behaviours
		window.addEventListener('resize', () => {
			this.methodHandleResize()
		})

		this.cloneNewDocFromDialog = cloneDeep(this.newDocFromDialog)
		this.defaultFilters = cloneDeep(this.filters)
	},
	unmounted() {
		window.removeEventListener('resize', () => {
			this.methodHandleResize()
		})
	},
	methods: {
		...mapActions('@briefs', [
			'actionAddBrief',
			'actionFetchBriefs',
			'updateBrief',
			'deleteBriefs',
		]),
		...mapActions('@docs', [
			'actionAddDocs',
			'actionFetchDocs',
			'updateDoc',
			'deleteDocs',
			'actionFetchCategories',
			'actionAddDocsCategory',
			'actionDeleteDocsCategory',
			'actionPatchDocsCategory',
			'actionPatchDocsCategories',
			'actionNotifyDocument',
		]),

		async methodAddCategory(aNewCategory) {
			const newCategory = await this.actionAddDocsCategory(aNewCategory)
			return newCategory
		},

		methodDeleteCategory(id) {
			this.actionDeleteDocsCategory(id)
		},

		extractVideoId(url) {
			const urlParams = new URLSearchParams(new URL(url).search)
			return urlParams.get('v')
		},

		async dragBehavior() {
			// upload directly if more than one element uploads dragged
			clearTimeout(this.debounceTimeout)
			this.debounceTimeout = setTimeout(async () => {
				this.onChangeIsEnded = true

				if (this.onChangeIsEnded) {
					if (this.filesList.length < 2) {
						this.dialogStep = 'edit'
						this.computedDialogAddDocOrLinkVisible = true
					} else {
						this.dialogStep = 'init'
						this.computedDialogAddDocOrLinkVisible = true
					}
				}
			}, 500)
		},

		methodSetCurrentDocCategoryFilter(value = { id: '', label: '' }) {
			this.currentDocCategoryFilter = value
		},

		methodRemoveAFile(aFile) {
			let filteredFiles = this.filesList.filter(
				(file) => JSON.stringify(file) !== JSON.stringify(aFile)
			)

			this.filesList = filteredFiles
		},
		methodResetDeleteDialog() {
			this.deleteDialog = {
				visible: false,
				title: '',
				subtitle: '',
				data: [],
				contentList: [],
			}
		},

		methodResetDocFromDialog() {
			this.newDocFromDialog = cloneDeep(this.cloneNewDocFromDialog)
			this.filesList = []
			this.dialogStep = 'init'
		},

		methodPatchCategories(newCategories) {
			this.actionPatchDocsCategories(newCategories)
		},

		clickButtonDoc() {
			document.getElementById('file').click()
		},

		// Add document from input element
		retrieveDoc(event) {
			if (event?.srcElement?.files.length) {
				this.addDocument(document.getElementById('file').files)
			}
		},

		// Close brief editor
		closeBrief() {
			this.methodAddModalBriefVisible(false)
			this.isEditing = false
			this.newBrief = {
				label: '',
				refvalues: [
					{
						label: '',
					},
				],
			}
		},

		// Close doc editor
		closeDoc() {
			this.addModalDoc = false
			this.isEditing = false
			this.newDoc = {}
		},

		// Edit doc/brief
		edit({ type, payload }) {
			switch (type) {
				case 'briefs':
					this.editBrief(payload)
					break
				case 'docs':
					this.editDoc(payload)
					break
				default:
					break
			}
		},

		// Edit brief (newBrief)
		editBrief(brief) {
			this.newBrief = brief ? cloneDeep(brief) : this.newBrief
			this.isEditing = true
			this.methodAddModalBriefVisible(true)
		},

		// Edit doc (newDoc)
		editDoc(doc) {
			this.newDoc = cloneDeep(doc)
			this.isEditing = true
			this.isVisualizing = false
			this.addModalDoc = true
		},

		// Visualize doc (newDoc)
		visualizeDoc(doc) {
			this.newDoc = cloneDeep(doc)
			this.isEditing = false
			this.isVisualizing = true
			this.addModalDoc = true
		},

		// Save edited brief (or add it)
		async saveBrief() {
			if (this.newBrief.id) {
				await this.updateBrief(this.newBrief)
					.then(() => {
						this.message({ type: 'success', action: 'edit' })
					})
					.catch((error) => {
						console.error(error)
						this.message({ type: 'error' })
					})
			} else {
				await this.actionAddBrief(this.newBrief)
					.then(() => {
						this.message({ type: 'success', action: 'add', number: 1 })
					})
					.catch((error) => {
						console.error(error)
						this.message({ type: 'error' })
					})
			}
			this.setSelectedIds([])
			this.closeBrief()
		},

		async saveAndUpdateDoc() {
			let updateDoc = {
				id: this.newDocFromDialog.currentDocId,
				subject: this.newDocFromDialog.linkName,
				cover: this.newDocFromDialog.cover,
				linkUrl: this.newDocFromDialog.linkUrl,
				category: this.newDocFromDialog.shareable,
				doc_category_id: this.newDocFromDialog.category.id,
			}

			if (this.newDocFromDialog.category.id === 'newIdToBeGenerated') {
				const newCategory = await this.newCategoryCreated()
				if (newCategory && newCategory.id) {
					updateDoc.doc_category_id = newCategory.id
				}
			}

			const result = await this.updateDoc(updateDoc)

			this.methodResetDocFromDialog()
			this.actionFetchCategories()
		},

		// Save edited Doc and launch message
		async saveDoc() {
			const result = await this.updateDoc(this.newDoc)
			this.message(result)
			this.closeDoc()
		},

		// Get selected Ids (to delete) from docs/briefs component
		setSelectedIds(payload) {
			this.selectedIds = payload
		},
		setSelectedData(payload) {
			this.selectedData = payload
		},

		async confirmDelete() {
			switch (this.$route.name) {
				case 'briefs': {
					await this.deleteBriefs(this.selectedIds)
						.then(() => {
							this.message({ type: 'success', action: 'delete', number: this.itemSelectedLength })
						})
						.catch((error) => {
							console.error(error)
							this.message({ type: 'error', action: 'delete' })
						})
					break
				}
				case 'docs': {
					let ids = cloneDeep(this.selectedData.map((aData) => aData.id))
					const result = await this.deleteDocs(ids)
					this.message(result)
					this.actionFetchCategories()
					break
				}
				default:
					break
			}
			this.selectedIds = []
			this.selectedData = []
			this.methodResetDeleteDialog()
		},
		// Delete selected
		async deleteSelected() {
			this.deleteDialog.visible = true
			let ids = cloneDeep(this.selectedData.map((aData) => aData.id))
			this.itemSelectedLength = ids ? ids.length : 1

			let babelKey = this.$route.name === 'docs' ? 'DOCS' : 'BRIEF'

			this.deleteDialog.title = this.$tc(
				`SHARING2.DOCS.CONFIRM.${babelKey}.TITLE`,
				this.itemSelectedLength
			)
			this.deleteDialog.subtitle =
				this.itemSelectedLength > 10
					? this.$tc(`SHARING2.DOCS.CONFIRM.${babelKey}.TOO_MANY_DOC_SUBTITLE`, {
							count: this.itemSelectedLength,
						})
					: this.$tc(`SHARING2.DOCS.CONFIRM.${babelKey}.SUBTITLE`, this.itemSelectedLength)
			this.deleteDialog.contentList = [
				this.$t(`SHARING2.DOCS.CONFIRM.${babelKey}.CONTENT.NO_LONGER_ACCESSIBLE`),
				this.$tc(`SHARING2.DOCS.CONFIRM.${babelKey}.CONTENT.NO_RETRIEVE`, this.itemSelectedLength),
			]

			if (this.$route.name === 'docs') {
				this.deleteDialog.data = this.selectedData.map((e) => e.subject) || []
			} else {
				this.deleteDialog.data = this.selectedData.map((e) => e.label) || []
			}

			this.$forceUpdate()
		},

		async newCategoryCreated() {
			const maxOrder = this.docsCategories.reduce(
				(max, item) => (item.order > max ? item.order : max),
				0
			)
			const newCategory = await this.methodAddCategory({
				order: maxOrder + 1,
				label: this.newDocFromDialog.category.label,
			})

			return newCategory
		},

		// Add document and launch message
		async addDocument(files) {
			let cover = this.newDocFromDialog.cover || ''
			let urlName = this.newDocFromDialog.linkName || ''
			let docCategoryId = null

			if (this.newDocFromDialog.category.id === 'newIdToBeGenerated') {
				const newCategory = await this.newCategoryCreated()
				if (newCategory && newCategory.id) {
					docCategoryId = newCategory.id
				}
			} else {
				docCategoryId = this.newDocFromDialog.category.id
			}

			let category = this.newDocFromDialog.shareable || ''

			this.actionAddDocs({
				files,
				notify: false,
				cover,
				urlName,
				docCategoryId,
				category,
			}).then((result) => {
				// Toaster according result of doc publication
				this.message(result)
				this.$forceUpdate()

				// Display of the share popup if the doc was successfully uploaded
				if (result.type == 'success') {
					this.showShareDialog = true
					this.nameShareDialog = result.docInfo?.subject
					this.urlShareDialog = result.docInfo?.url
					this.docIdShareDialog = result.docInfo?.id
				}
			})

			this.computedDialogAddDocOrLinkVisible = false
			this.methodResetDocFromDialog()
		},

		methodCloseShareDocDialog(docId, notify) {
			// Send the notification if wanted
			if (notify) {
				this.actionNotifyDocument({ id: docId }).then((type) => {
					if (type == 'success') {
						this.$message({
							type: 'success',
							message: this.$t('SHARING2.DOCS.SHARE_DIALOG.NOTIFICATION_SEND'),
							showClose: true,
						})
					} else {
						this.$message({
							type: 'error',
							message: this.$t('SHARING2.DOCS.SHARE_DIALOG.NOTIFICATION_ERROR'),
							showClose: true,
						})
					}
				})
			}

			// Reset values
			this.nameShareDialog = ''
			this.urlShareDialog = ''
			this.docIdShareDialog = {}
		},

		// Generate message for all possible actions
		message({ type, action, number }) {
			switch (type) {
				case 'success':
					if (action === 'add' || action === 'delete') {
						this.$message({
							type,
							message: this.t.success[action](number || 1),
							showClose: true,
						})
					} else {
						this.$message({
							type,
							message: this.t.success[action],
							showClose: true,
						})
					}
					break

				case 'errorTooLarge':
					this.$message({
						type: 'error',
						message: this.t.errorTooLarge,
						showClose: true,
					})
					break

				default:
					this.$message({
						type,
						message: this.t.error,
						showClose: true,
					})
					break
			}
		},

		// Determine type of card
		getType(doc) {
			var [type, subType] = doc.type ? doc.type.split('/') : []

			switch (type) {
				case 'video':
					return 'video'
				case 'youtube':
					return 'youtube'
				case 'image':
					return 'image'
				case 'application':
					return subType === 'pdf' ? 'pdf' : 'other'
				case 'google_docs':
				case 'google_drive':
				case 'one_drive':
				case 'dropbox':
				case 'icloud':
				case 'sharepoint':
				case 'other_link':
					return 'link'
				default:
					return 'other'
			}
		},

		methodOpenLinkModal() {
			this.showLinkModal = true
		},

		methodResetModalState() {
			this.linkUrl = ''
			this.linkName = ''
			this.showTypeError = false
			this.showHttpError = false
			this.showLinkModal = false
		},

		async methodAddLinkV2() {
			const http_and_https_regex = /^http[s]?:\/\//

			if (!http_and_https_regex.test(this.newDocFromDialog.linkUrl)) {
				this.showHttpError = true
				return
			}

			if (this.showHttpError) this.showHttpError = false

			let docCategoryId

			if (this.newDocFromDialog.category.id === 'newIdToBeGenerated') {
				const maxOrder = this.docsCategories.reduce(
					(max, item) => (item.order > max ? item.order : max),
					0
				)
				const newCategory = await this.methodAddCategory({
					order: maxOrder + 1,
					label: this.newDocFromDialog.category.label,
				})

				if (newCategory && newCategory.id) {
					docCategoryId = newCategory.id
				}
			} else {
				docCategoryId = this.newDocFromDialog?.category?.id || null
			}

			let url = ''
			if (this.newDocFromDialog.linkUrl) {
				url = this.newDocFromDialog.linkUrl
			}

			this.actionAddDocs({
				files: url,
				notify: false,
				docType: 'link',
				cover: this.newDocFromDialog.cover,
				urlName: this.newDocFromDialog.linkName || '',
				docCategoryId: docCategoryId || null,
				category: this.newDocFromDialog.shareable || '',
			}).then((result) => {
				// Display of the share popup if the doc was successfully uploaded
				if (result.type == 'success') {
					this.showShareDialog = true
					this.nameShareDialog = result.docInfo?.subject
					this.urlShareDialog = result.docInfo?.url
					this.docIdShareDialog = result.docInfo?.id
				}

				this.methodResetModalState()
				this.methodResetDocFromDialog()
			})

			this.methodToggleDialogAddDocOrLinkVisible(false)
		},

		handleCommand(command) {
			switch (command) {
				case 'addFile':
					this.clickButtonDoc()
					break
				case 'addLinkToFile':
					this.methodOpenLinkModal()
					break
			}
		},

		methodGetLinkWordingTypology(typology) {
			switch (typology) {
				case 'google_docs':
					return 'Google Docs'
				case 'google_drive':
					return 'Google Drive'
				case 'one_drive':
					return 'One Drive'
				case 'dropbox':
					return 'Dropbox'
				case 'icloud':
					return 'Icloud'
				case 'sharepoint':
					return 'Sharepoint'
				case 'youtube':
					return 'Youtube'
				case 'other_link':
					return this.$t('SHARING2.DOCS.MODAL.UNKNWOWN_TYPE')
			}
		},

		methodHandleResize() {
			this.$nextTick(() => {
				this.windowWidth = this.$refs.docsWindow.clientWidth
			})
		},
	},
}
</script>

<style lang="scss">
#categories-bar *:not(i) {
	font-size: 13px;
}
.pink-main-badge .el-badge__content--danger {
	@apply bg-pink-main;
}

.video-frame-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.video-frame-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

.delete {
	background-color: $pink-quorum;
}

#subtitle {
	@apply text-gray-dark;
}
#comm-header {
	@media (max-width: 768px) {
		display: block;
	}
}
.row {
	text-align: right;
	@media (max-width: 768px) {
		text-align: left;
	}
}
#searchBar {
	padding-bottom: 0px;
	@media (max-width: 768px) {
		max-width: 300px;
	}
}

.search-input-container {
	border-width: 1.5px;

	&:hover {
		i {
			color: #ff387f;
		}
	}
}
</style>
