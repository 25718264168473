<template>
	<div
		:class="filteredDocs.length ? 'not-empty-list' : 'empty-docs-list'"
		class="w-full h-full"
	>
		<div
			v-if="!filteredDocs?.length"
			class="w-full flex items-center justify-center flex-col pt-8 pb-4"
		>
			<div class="w-6/12">
				<p
					class="font-title font-bold text-5xl mb-3 border border-transparent text-blackFiltered text-center"
				>
					<template v-if="!docs.length">
						{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.EMPTY.TITLE') }}
					</template>
					<template v-else>
						{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.NO_DOCUMENT_FOUND') }}
					</template>
				</p>
				<p class="bold text-center">
					{{ $t('SHARING2.DOCS.ADD_DOC_DIALOG.EMPTY.SUBTITLE') }}
				</p>
			</div>
		</div>

		<UploadDocs
			:disabled-pointer-events="filteredDocs?.length ? true : false"
			:before-upload-method="beforeUpload"
			:on-change-method="onChange"
			:show-file-list="true"
			:multiple="true"
			:drag="true"
		>
			<el-upload-list
				:list="localFileList"
				:disabled="filteredDocs.length > 0"
			/>

			<div
				class="pointer-events-auto uploadContentContainer"
				@click="stopClickPropagation"
			>
				<div class="q-fullheight px-2">
					<div
						v-if="loading"
						class="q-container w-full pt-16"
						style="display: flex; justify-content: center; height: 60%"
					>
						<loader size="15%" />
					</div>
					<div
						v-else
						id="doc-list"
						class="q-container"
					>
						<div
							v-if="!filteredDocs.length"
							class="q-col q-container w-full"
						>
							<!-- If there is no document -->
							<div style="height: 100%">
								<!-- @change="emitAdd" -->
								<h2
									v-if="!searchInput && !selectedCategory"
									class="center q-is-grey"
								>
									<small class="action" />
								</h2>
								<h2
									v-else
									class="center q-is-grey"
								>
									<template>
										{{ t.docNotFound }}
									</template>
								</h2>
							</div>
						</div>
						<div
							v-else
							class="h-full w-full flex flex-grow overflow-hidden"
						>
							<transition-group
								name="table"
								tag="div"
								class="h-full w-full relative doc-table"
							>
								<!-- Header with documents selected -->
								<div
									v-if="selectedIds.length"
									class="absolute z-10 ml-16 mt-2"
								>
									<span style="padding-right: 10px">
										{{ t.columns.selectedDocuments(selectedIds.length) }}
									</span>
									<button
										class="q-link"
										@click="deleteSelectedDocs"
									>
										{{ t.actions.delete }}
									</button>
								</div>
								<el-table
									id="tabledocs"
									key="table"
									:data="filteredDocs"
									:max-height="scrollContainerHeight - 15 + 'px'"
									cell-class-name="table-cell"
									header-row-class-name="table-header-row"
									:row-class-name="(scope) => `break-normal ${methodStripedRow(scope)}`"
									@selection-change="toggleSelect"
									@cell-click="clickCell"
								>
									<!-- Checkbox -->
									<el-table-column
										type="selection"
										:width="30"
										:label="!selectedIds.length ? t.columns.name : ''"
										class="my-custom-checkbox"
									/>

									<!-- Subject -->
									<el-table-column
										:class-name="
											isWidthSuperiorToThreshold
												? 'subject-cell table-cell-subject'
												: 'subject-cell table-cell-subject selected-header-cell'
										"
										:label="!selectedIds.length ? t.columns.name : ''"
										:min-width="methodWidthColumn(100)"
									>
										<template #default="scope">
											<span class="doc-subject">{{ scope.row.subject }}</span>
										</template>
									</el-table-column>

									<!-- Type -->
									<el-table-column
										:label="!selectedIds.length ? t.columns.type : ''"
										:class-name="
											isWidthSuperiorToThreshold ? 'selected-header-cell' : 'table-cell-type'
										"
										:width="methodWidthColumn(windowWidth / 8)"
									>
										<template #default="scope">
											<div>
												<div class="flex items-center">
													<span>
														{{ getTypeLabel(scope.row) }}
													</span>
												</div>
											</div>
										</template>
									</el-table-column>

									<!-- Date -->
									<el-table-column
										:label="!selectedIds.length ? t.columns.created : ''"
										:class-name="'table-cell-date'"
										:width="methodWidthColumn(150)"
									>
										<template #default="scope">
											{{ getDate(scope.row.created) }}
										</template>
									</el-table-column>

									<!-- Imported by -->
									<el-table-column
										:label="!selectedIds.length ? t.columns.owner : ''"
										:class-name="'table-cell-owner'"
										:width="methodWidthColumn(windowWidth / 8)"
									>
										<template #default="scope">
											<div class="flex flex-row items-center">
												<div
													v-if="
														getUser(scope.row.owner_id).avatar &&
															getUser(scope.row.owner_id).avatar != 'null'
													"
													class="doc-avatar rounded-full w-8 h-8 flex items-center justify-center"
													style="width: 30px; height: 30px; min-width: 30px"
												>
													<img
														:src="getUser(scope.row.owner_id).avatar"
														class=""
													>
												</div>

												<template v-else>
													<div
														v-if="
															getUser(scope.row.owner_id).firstname ||
																getUser(scope.row.owner_id).surname
														"
														class="doc-avatar"
													>
														<div
															class="doc-avatar-default w-8 h-8"
															:style="[
																{ color: getUser(scope.row.owner_id).roleColor },
																{ backgroundColor: getUser(scope.row.owner_id).roleColor + '15' },
															]"
														>
															<span
																v-if="
																	getUser(scope.row.owner_id).firstname &&
																		getUser(scope.row.owner_id).surname
																"
															>
																{{ getUser(scope.row.owner_id).firstname.toUpperCase().split('')[0]
																}}{{
																	getUser(scope.row.owner_id).surname.toUpperCase().split('')[0]
																}}
															</span>
														</div>
													</div>
												</template>

												<div
													class="pl-2 truncate"
													style="word-break: normal"
												>
													<template
														v-if="
															getUser(scope.row.owner_id).surname ||
																getUser(scope.row.owner_id).firstname
														"
													>
														<p class="truncate">
															<span
																v-if="getUser(scope.row.owner_id).firstname"
																class="pr-1"
															>
																{{ getUser(scope.row.owner_id).firstname }}
															</span>
															<span v-if="getUser(scope.row.owner_id).surname">
																{{ getUser(scope.row.owner_id).surname }}</span>
														</p>
													</template>
													<template v-else>
														<span class=""> -- </span>
													</template>
												</div>
											</div>
										</template>
									</el-table-column>

									<!-- custom category -->
									<el-table-column
										:label="!selectedIds.length ? t.columns.category : ''"
										:class-name="'table-cell-custom-category text-center'"
										:width="methodWidthColumn(windowWidth / 8)"
									>
										<template #default="scope">
											<template v-if="scope.row.doc_category_id">
												<div
													class="force-black-border flex items-center justify-center"
													:style="{ width: methodWidthColumn(windowWidth / 8) - 15 + 'px' }"
													@click="methodEdit(scope.row)"
												>
													<q-tag
														:content="labelOfCategory(scope.row.doc_category_id, 'truncated')"
														:max-width="methodWidthColumn(windowWidth / 8) - 10 + 'px'"
													/>
												</div>
											</template>
											<div
												v-else
												class="text-white w-full text-center items-center justify-center flex"
												@click="methodEdit(scope.row)"
											>
												--
											</div>
										</template>
									</el-table-column>

									<!-- Category/internal/external -->
									<el-table-column
										prop="category"
										:label="!selectedIds.length ? $t('SHARING2.DOCS.COLUMNS.SHARE') : ''"
										:class-name="'table-cell-category text-center'"
										:width="methodWidthColumn(windowWidth / 8)"
									>
										<template #default="scope">
											<template v-if="scope.row.category">
												<!-- <div class="badge-shareable"> -->
												<template
													v-if="
														scope.row.category === 'external' || scope.row.category === 'internal'
													"
												>
													<i
														class="text-xl"
														:class="
															scope.row.category === 'external'
																? 'gui-yes text-green-main'
																: 'gui-no text-black'
														"
														@click="methodEdit(scope.row)"
													/>
												</template>

												<!-- <custom-badge
														:label="
															scope.row.category === 'external'
																? $t('_COMMON.YES')
																: scope.row.category === 'internal'
																? $t('_COMMON.NO')
																: ''
														"
														:custom-container-class="methodClassCategory(scope.row.category)"
													></custom-badge> -->
												<!-- </div> -->
											</template>
											<span
												v-else
												class="text-white"
												@click="methodEdit(scope.row)"
											> -- </span>
										</template>
									</el-table-column>

									<!-- Link -->
									<el-table-column
										:label="!selectedIds.length ? $t('SHARING2.DOCS.COLUMNS.LINK') : ''"
										:class-name="'table-cell-link text-center'"
									>
										<template #default="scope">
											<div>
												<i
													class="text-xl gui-link text-black"
													@click="copyText(scope.row.url)"
												/>
											</div>
										</template>
									</el-table-column>

									<!-- Options -->
									<el-table-column
										label=" "
										align="right"
										:class-name="'table-cell-custom-options'"
										:width="methodWidthColumn(120)"
									>
										<template #default="scope">
											<div class="flex flex-row justify-end items-center gap-1">
												<div
													class="action flex"
													@click="downloadItem(scope.row)"
												>
													<el-tooltip
														class="box-item"
														effect="dark"
														:content="$t('SHARING2.DOCS.ACTIONS.DOWNLOAD')"
														placement="bottom"
													>
														<i class="gui-import text-xl text-green-main" />
													</el-tooltip>
												</div>
												<a
													:id="scope.row.id"
													:href="scope.row.url"
													target="_blank"
												/>
												<div
													class="action"
													@click="methodEdit(scope.row)"
												>
													<el-tooltip
														class="box-item"
														effect="dark"
														:content="$t('SHARING2.DOCS.ACTIONS.EDIT')"
														placement="bottom"
													>
														<i class="gui-edit text-xl" />
													</el-tooltip>
												</div>
												<el-divider
													direction="vertical"
													class="m-1"
												/>

												<div
													class="action pr-2"
													@click="deleteADoc(scope.row)"
												>
													<el-tooltip
														class="box-item"
														effect="dark"
														:content="$t('SHARING2.DOCS.ACTIONS.DELETE')"
														placement="bottom"
													>
														<i class="gui-delete text-xl" />
													</el-tooltip>
													<!-- {{ t.actions.delete }} -->
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
							</transition-group>
						</div>
					</div>
					<div
						class="action q-m-b"
						@click="downloadItem()"
					>
						{{ t.download }}
					</div>
				</div>
			</div>
		</UploadDocs>
	</div>
	<!-- </el-upload> -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { defineAsyncComponent } from 'vue'
import { formatCustomDate, formatRelativeDate, formatTime } from '../../utils/dateFnsHelper'
// Constants
const threshold = 768

export default {
	name: 'DocumentsPage',
	components: {
		Loader: defineAsyncComponent(() => import('src/components/general/loader.vue')),
		UploadDocs: defineAsyncComponent(() => import('src/components/sharing-module/uploadDocs.vue')),
	},
	props: {
		visualize: {
			type: Function,
			default: () => {},
		},
		edit: {
			type: Function,
			default: () => {},
		},
		searchInput: {
			type: String,
			default: '',
		},
		deleteSelected: {
			type: Function,
			default: () => {},
		},
		windowWidth: {
			type: Number,
			default: 0,
		},
		filesList: {
			type: Array,
			default: () => [],
		},
		docsCategories: {
			type: Array,
			default: () => [],
		},
		dialogAddDocOrLinkVisible: {
			type: Boolean,
			default: false,
		},
		dialogStep: {
			type: String,
			default: 'init',
		},
		methodAddFromUpload: {
			type: Function,
			default: () => {},
		},
		currentDocCategoryFilter: {
			type: Object,
			default: () => {},
		},
		filtersData: {
			type: Array,
			default: () => [],
		},
		newDocFromDialog: {
			type: Object,
			default: () => {},
		},
		methodDragBehavior: {
			type: Function,
			default: () => {},
		},
	},
	emits: [
		'setSelectedIds',
		'setSelectedData',
		'add',
		'update:filesList',
		'update:dialogStep',
		'update:dialogAddDocOrLinkVisible',
		'update:newDocFromDialog',
	],

	data() {
		return {
			selectedIds: [],
			selectedCategory: null,
			localFileList: [],
			uploadProgress: {},

			localFindedUserData: [],
			windowHeight: 0,
			scrollContainerHeight: 0,
		}
	},

	computed: {
		...mapGetters('@docs', ['docs', 'loading', 'categories']),
		...mapGetters(['userConnected', 'getAllUsers']),

		t() {
			const prefix = 'SHARING2.DOCS'

			return {
				columns: {
					type: this.$t(`${prefix}.COLUMNS.TYPE`),
					name: this.$t(`${prefix}.COLUMNS.NAME`),
					category: this.$t(`${prefix}.COLUMNS.CATEGORY`),
					created: this.$t(`${prefix}.COLUMNS.UPLOAD_DATE`),
					owner: this.$t(`${prefix}.COLUMNS.OWNER`),
					selectedDocuments: (number) =>
						this.$tc(`${prefix}.COLUMNS.SELECTED`, number, { count: number }),
				},
				actions: {
					add: this.$t(`${prefix}.ACTIONS.ADD`),
					download: this.$t(`${prefix}.ACTIONS.DOWNLOAD`),
					rename: this.$t(`${prefix}.ACTIONS.RENAME`),
					delete: this.$t(`${prefix}.ACTIONS.DELETE`),
				},
				confirm: {
					title: this.$t(`${prefix}.CONFIRM.TITLE`),
					cancel: this.$t(`${prefix}.CONFIRM.CANCEL`),
					confirm: this.$t(`${prefix}.CONFIRM.CONFIRM`),
					delete: (number) => this.$tc(`${prefix}.CONFIRM.DELETE`, number, { count: number }),
				},
				emptyState: this.$t(`${prefix}.EMPTY_STATE`),
				informationDoc: this.$t(`${prefix}.INFORMATION`),
				docNotFound: this.$t(`${prefix}.NOT_FOUND`),
				categories: {
					external: this.$t(`${prefix}.CATEGORIES.EXTERNAL`),
					internal: this.$t(`${prefix}.CATEGORIES.INTERNAL`),
					all: this.$t(`${prefix}.CATEGORIES.ALL`),
					none: this.$t(`${prefix}.CATEGORIES.NONE`),
				},
				success: {
					update: this.$t(`${prefix}.SUCCESS.UPDATE`),
					delete: (number) => this.$tc(`${prefix}.SUCCESS.DELETE`, number, { count: number }),
				},
				error: this.$t(`${prefix}.ERROR`),
				selectCategories: this.$t(`${prefix}.SELECT_CATEGORIES`),
			}
		},

		computedFilesList: {
			get() {
				return this.filesList
			},
			set(value) {
				this.$emit('update:filesList', value)
			},
		},

		computedNewDocFromDialog: {
			get() {
				return this.newDocFromDialog
			},
			set(value) {
				this.$emit('update:newDocFromDialog', value)
			},
		},

		computedDialogStep: {
			get() {
				return this.dialogStep
			},
			set(value) {
				this.$emit('update:dialogStep', value)
			},
		},

		computedDialogAddDocOrLinkVisible: {
			get() {
				return this.dialogAddDocOrLinkVisible
			},
			set(value) {
				this.$emit('update:dialogAddDocOrLinkVisible', value)
			},
		},

		filteredDocs() {
			let filteredByQuery = this.docs.filter(
				(doc) => doc.subject.toLowerCase().indexOf((this.$route.query.b || '').toLowerCase()) > -1,
			)

			this.filtersData.forEach((filterGroup) => {
				let checkedValues = filterGroup.values
					.filter((value) => value.isChecked)
					.map((value) => value.value)

				if (checkedValues.length > 0) {
					if (filterGroup.type === 'radio') {
						filteredByQuery = filteredByQuery.filter((doc) =>
							checkedValues.includes(doc[filterGroup.mappingKeyForFilter]),
						)
					}
					else if (filterGroup.type === 'checkbox') {
						filteredByQuery = filteredByQuery.filter((doc) => {
							return checkedValues.some((checkedValue) => {
								if (Array.isArray(checkedValue)) {
									// Pour les valeurs qui sont des arrays
									return checkedValue.some((val) =>
										doc[filterGroup.mappingKeyForFilter]?.includes(val),
									)
								}

								// Pour les valeurs simples
								return doc[filterGroup.mappingKeyForFilter]?.includes(checkedValue)
							})
						})
					}
				}
			})

			return cloneDeep(
				filteredByQuery.filter((doc) => {
					switch (this.currentDocCategoryFilter.id) {
						case '':
							return true
						case 'withoutCategory':
							return doc.doc_category_id === null || doc.doc_category_id === ''
						default:
							return doc.doc_category_id === this.currentDocCategoryFilter.id
					}
				}),
			)
		},

		isWidthSuperiorToThreshold() {
			return this?.windowWidth > threshold
		},

		categoriesSelector() {
			return this.categories
				.map((c) => {
					return { value: c, label: this.t.categories[c] }
				})
				.concat(
					this.selectedCategory
						? [
								{ value: null, label: this.t.categories.all },
								{ value: 'none', label: this.t.categories.none },
							]
						: [{ value: 'none', label: this.t.categories.none }],
				)
				.filter((c) => c.value !== this.selectedCategory)
		},
	},

	mounted() {
		this.$nextTick(() => {
			//   this.handleResize();
			this.handleResize()
		})
		window.addEventListener('resize', this.handleResize)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize)
	},

	methods: {
		...mapActions('@docs', [
			'deleteDoc',
			'downloadDoc',
			'actionFetchCategories',
			'updateDoc',
			'actionAddDocsCategory',
			'actionDeleteDocsCategory',
			'actionPatchDocsCategory',
		]),

		methodRemainingHeight() {
			const element1Height = this.getElementHeight('q-header')
			const element2Height = this.getElementHeight('comm-header')
			const element3Height = this.getElementHeight('categories-bar')
			let element4Height = this.getElementHeight('table-header-row', 'class')

			let finalHeight

			if (element4Height === 0) {
				element4Height = 30
			}

			finalHeight
				= this.windowHeight - (element1Height + element2Height + element3Height + element4Height)

			return finalHeight
		},

		getElementHeight(value, type = 'div') {
			let element

			if (type === 'class') {
				element = document.getElementsByClassName(value)[0]
			}
			else {
				element = document.getElementById(value)
			}

			// const element = document.getElementById(id)
			return element ? element.offsetHeight : 0
		},
		handleResize() {
			this.windowHeight = cloneDeep(window.innerHeight)
			this.scrollContainerHeight = cloneDeep(this.methodRemainingHeight())
		},

		methodEdit(aDocument) {
			let aDoc = cloneDeep(aDocument)

			let tmp = cloneDeep(this.computedNewDocFromDialog)

			tmp.type = aDoc.type || ''
			tmp.category.id = aDoc.doc_category_id || null
			tmp.category.label = this.labelOfCategory(aDocument.doc_category_id)
			tmp.cover = aDoc.cover || ''
			tmp.linkName = aDoc.subject || ''
			tmp.linkUrl = aDoc.url || ''
			tmp.currentDocId = aDoc.id
			tmp.shareable = aDoc.category

			this.computedNewDocFromDialog = tmp
			this.computedDialogStep = 'edit'
			this.computedDialogAddDocOrLinkVisible = true
		},

		methodWidthColumn(widthValue) {
			if (this.windowWidth && this.windowWidth > 800) {
				return widthValue
			}

			return 250
		},
		methodClassCategory(category) {
			return `rounded-md px-1 py-px flex items-center font-light uppercase justify-center ${
				category === 'external' ? 'bg-green-lighted text-green-main' : 'bg-gray-lighted'
			}`
		},

		beforeUpload(file, currentFileList) {
			this.localFileList = this.computedFilesList
			this.localFileList.push(file)
			this.computedFilesList = this.localFileList

			return false
		},
		onProgress(event, file) {
			this.uploadProgress[file.uid] = event.percent
		},
		async debounceOnChange() {
			clearTimeout(this.debounceTimeout)

			// upload directly if more than one element uploads
			this.debounceTimeout = setTimeout(async () => {
				this.onChangeIsEnded = true

				if (this.onChangeIsEnded) {
					if (this.computedFilesList.length < 2) {
						this.computedDialogStep = 'edit'
						this.computedDialogAddDocOrLinkVisible = true
					}
					else {
						await this.methodAddFromUpload(this.computedFilesList)
						this.computedFilesList = []
					}
				}
			}, 500)
		},

		labelOfCategory(id) {
			return this.docsCategories.find((category) => category.id === id)?.label || ''
		},

		async onChange() {
			await this.methodDragBehavior()
		},
		stopClickPropagation(event) {
			event.stopPropagation()
		},
		// Allows to download file
		downloadItem({ id }) {
			const link = document.getElementById(id)

			link.click()
		},
		allowClick(event) {
			event.stopPropagation()

			// disabled click on upload component
			if (event.target === this.$refs.uploadRef.$el) {
				event.preventDefault()
			}
		},

		toggleSelect(docs) {
			this.selectedIds = docs.map((doc) => doc.id)
			this.$emit('setSelectedIds', this.selectedIds)
			this.$emit('setSelectedData', docs)
			this.$forceUpdate()
		},

		// Open input to upload document
		openInput() {
			document.getElementById('file').click()
		},

		// Emit add to upload document
		emitAdd() {
			this.$emit('add', this.document.getElementById('file').files)
		},

		// Delete document and message
		async deleteDocument(id) {
			const result = await this.deleteDoc(id)

			this.message(result)
		},

		// Generate message for all possible actions
		message({ type, action, number }) {
			if (type === 'success') {
				if (number) {
					this.$message({
						type,
						message: this.t.success[action](number),
						showClose: true,
					})
				}
				else {
					this.$message({
						type,
						message: this.t.success[action],
						showClose: true,
					})
				}
			}
			else if (type === 'error') {
				this.$message({
					type,
					message: this.t.error,
					showClose: true,
				})
			}
		},

		// Determine creating date
		getDate(date) {
			return formatCustomDate(date)
			// return date.substring(0, 10).split('-').reverse().join('/')
		},

		// Determine type of card
		getType(doc) {
			let [type, subType] = doc.type ? doc.type.split('/') : []

			switch (type) {
				case 'video':
					return 'video'
				case 'image':
					return 'image'
				case 'application':
					return subType === 'pdf' ? 'pdf' : 'other'
				case 'google_drive':
				case 'google_docs':
				case 'one_drive':
				case 'dropbox':
				case 'icloud':
				case 'sharepoint':
				case 'youtube':
				case 'other_link':
					return this.$t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.LINK')
				default:
					return this.$t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.OTHER')
			}
		},

		getTypeLabel(doc) {
			let [type, subType] = doc.type ? doc.type.split('/') : []

			switch (type) {
				case 'video':
					return `.${subType}`
				case 'image':
					return `.${subType}`
				case 'application':
					return subType === 'pdf' ? '.' + 'pdf' : 'other'
				case 'google_drive':
				case 'google_docs':
				case 'one_drive':
				case 'dropbox':
				case 'icloud':
				case 'sharepoint':
				case 'youtube':
				case 'other_link':
					return this.$t('SHARING2.DOCS.ADD_DOC_DIALOG.INIT.LINK.LINK')
				default:
					return this.$t('SHARING2.DOCS.ADD_DOC_DIALOG.EDIT.OTHER')
			}
		},

		// Delete doc
		deleteADoc(doc) {
			this.$emit('setSelectedIds', [doc.id])
			this.$emit('setSelectedData', [doc])
			this.deleteSelectedDocs()
		},

		// Get name of user
		getUser(user_id) {
			const user = this.getAllUsers.concat([this.userConnected?.id]).find((user) => {
				return user.id === user_id
			})

			let userInfos = user
				? {
						firstname: user.firstname,
						surname: user.surname,
						avatar: user?.avatar ? user.avatar : '',
						roleColor: user?.role_data?.color || '',
					}
				: {
						userId: user_id,
					}

			return userInfos
		},

		// Launch visualization modal
		clickCell(doc, cell) {
			if (cell.className?.includes('subject-cell')) {
				this.visualize(doc)
			}
		},

		async updateCategory(doc, category) {
			doc.category = category ? category : ' '
			const result = await this.updateDoc(doc)

			this.message(result)
		},

		updateSelectedCategory(value) {
			this.selectedCategory = value
			this.selectedIds = []
		},

		methodStripedRow(rowIndex) {
			if (rowIndex % 2 === 1) {
				return 'rowStripeDark'
			}

			return ''
		},

		deleteSelectedDocs() {
			this.deleteSelected()
			this.selectedIds = []
		},

		copyText(text) {
			if (!navigator.permissions) {
				navigator.permissions = {
					query(parameters) {
						return new Promise((resolve, reject) => {
							if (parameters.name === 'clipboard-write') {
								if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
									resolve({ state: 'granted' })
								}
								else {
									reject(new Error('Clipboard API not supported'))
								}
							}
							else {
								reject(new Error('Permission name not supported'))
							}
						})
					},
				}
			}

			navigator.permissions
				.query({ name: 'clipboard-write' })
				.then(() => {
					navigator.clipboard.writeText(text)
					this.$message({
						type: 'success',
						duration: 3000,
						showClose: true,
						center: true,
						message: this.$t('SHARING2.DOCS.ACTIONS.COPY'),
					})
				})
				.catch(() => {
					const textArea = document.createElement('textarea')

					textArea.value = text
					document.body.appendChild(textArea)
					textArea.select()
					document.execCommand('copy')
					textArea.remove()

					this.$message({
						type: 'success',
						duration: 3000,
						showClose: true,
						center: true,
						message: this.$t('SHARING2.DOCS.ACTIONS.COPY'),
					})
				})
		},
	},
}
</script>

<style lang="scss">
.badge-shareable span {
	@apply px-0.5;
}
.badge-shareable {
	min-width: 50px;
	width: 50px;
}

.doc-table .el-checkbox__input.is-checked .el-checkbox__inner {
	display: flex;
	align-items: 'center';
	justify-content: 'center';
	background-color: transparent !important;
	border: 0;
	// transform: translate(-50%, -50%);
}
.doc-table .el-checkbox__input .el-checkbox__inner {
	display: flex;
	align-items: 'center';
	justify-content: 'center';
	background-color: transparent !important;
	border: 0;
	// transform: translate(-50%, -50%);
}

.doc-table .el-table td.el-table__cell {
	@apply border-pink-lighted;
}

// checkboxChecked

.doc-table .el-checkbox__input .el-checkbox__inner::before {
	content: '\e90c';
	font-family: 'icomoon';
	display: block;
	align-items: center;
	justify-content: center;
	display: flex;
	pointer-event: none;
	font-size: 19px;

	// transform: translate(-50%, -50%);;
}

.doc-table tr:not(.table-header-row) .el-checkbox__input.is-checked .el-checkbox__inner::before {
	content: '\e90d';
	font-family: 'icomoon';
	display: block;
	align-items: center;
	justify-content: center;
	display: flex;
	pointer-event: none;
	@apply text-green-main;
	// transform: translate(-50%, -50%);;
}

.doc-table tr:not(.table-header-row) .el-checkbox__input.is-checked .el-checkbox__inner::before {
	content: '\e90d';
	font-family: 'icomoon';
	display: block;
	align-items: center;
	justify-content: center;
	display: flex;
	@apply text-green-main;
	// transform: translate(-50%, -50%);;
}
.doc-table tr:not(.table-header-row) .el-checkbox__input.is-checked .el-checkbox__inner::after {
	display: none;
}

.table-header-row .el-checkbox__input .el-checkbox__inner::before {
	content: '\e948';
	font-family: 'icomoon';
	display: flex;
	transform: none;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 20px;
	align-items: normal;
	justify-content: normal;
}
.table-header-row .el-checkbox__input .el-checkbox__inner::after {
	content: '\e949';
	font-family: 'icomoon';
	display: flex;
	transform: none;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 20px;
	align-items: normal;
	justify-content: normal;
	border: 0;
}
.table-header-row .el-checkbox__input.is-checked .el-checkbox__inner::after,
.table-header-row .el-checkbox__input.is-checked .el-checkbox__inner::before {
	@apply text-green-main;
}

.table-header-row .el-checkbox__input .el-checkbox__inner {
	margin-top: -5px;
}

.table-header-row .el-table-column--selection .cell {
	width: 100%;
}
.table-header-row .el-table-column--selection .cell {
	width: 100%;
	justify-content: center;
}
.table-header-row .cell {
	font-family: centra-book;
	font-weight: 400;
	text-overflow: ellipsis;
	font-size: 13px;
	line-height: 17px;
	text-transform: uppercase;
	color: #72727b;
}
.table-header-row {
	height: 30px;
}

#tabledocs .el-table__body-wrapper .el-table-column--selection > .cell {
	display: flex;
	justify-content: center;
}

.doc-table .cell span *:not(i),
.doc-table .cell *:not(i),
.doc-table span {
	font-size: 13px;
}

.force-truncate-tag .tag-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.empty-docs-list .el-upload-dragger {
	background-color: transparent !important;
	width: 50%;
	aspect-ratio: 16/9;
	align-items: center;
	display: flex;
	justify-content: center;
	// margin-top: 50px;
}
.empty-docs-list .el-upload {
	@apply items-center justify-center flex;
}

.upload-doc-container,
.upload-doc-container .el-upload.is-drag {
	display: flex;
	flex-grow: 1;
}
.empty-docs-list .upload-doc-container,
.empty-docs-list .upload-doc-container .el-upload.is-drag {
	height: auto;
	flex-grow: 0;
}

.empty-docs-list {
	align-items: center;
	justify-content: start;
	display: flex;
	flex-direction: column;
}
.upload-doc-container,
.el-upload-dragger {
	height: 100%;
	width: 100%;
}

.upload-doc-container .el-upload-dragger.is-dragover {
	@apply bg-gray-300;
}
.upload-doc-container .el-upload-dragger {
	padding: 0;
}

.not-empty-list .upload-doc-container.isFromList .el-upload-dragger,
.not-empty-list .upload-doc-container.isFromList .el-upload-dragger:hover {
	border: 0px;
}

.is-dragover .uploadContentContainer {
	opacity: 0.2 !important;
	filter: blur(5px);
	transition: 1s;
}

.el-upload-dragger.is-dragover {
	transition: 1s;
	border: 1px;
}

.uploadContentContainer {
	opacity: 1 !important;
	transition: 1s;
	filter: blur(0px);
	border: 1px;
}
.force-black-border .tag-container .tag-content {
	font-weight: bolder;
}
.force-black-border .tag-container {
	border: 0;
}

.table-cell-selection {
	width: 5%;
}
.subject-cell.table-cell-subject {
	width: 40%;
}
.table-cell-type {
	width: 15%;
}
.table-cell-date {
	width: 20%;
}
.table-cell-owner {
	width: 15%;
}
.table-cell-category {
	width: 15%;
}
.table-cell-link {
	width: 10%;
}
.table-cell-options {
	width: 10%;
}
.force-black-border .tag-content {
	border: 1px solid black;
	@apply px-3 rounded-lg;
}
</style>

<style lang="scss" scoped>
@import '../../assets/scss/structure/grid.scss';
@import '../../assets/dequorum/style/variables.sass';
// @import 'src/assets/scss/sharing/docs-scoped.scss';

.doc-avatar {
	height: 35px;
	width: 35px;
	.doc-avatar-default,
	img {
		height: 100%;
		width: 100%;
		border-radius: 50%;
	}
	.doc-avatar-default {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 35px;
		font-size: 1em;
		padding: 0.2em;
		&.user {
			background-color: $light-purple;
			color: $darker-light-purple;
		}
		&.manager {
			background-color: $light-green;
			color: $darker-light-green;
		}
		&.admin {
			background-color: $light-blue;
			color: $darker-light-blue;
		}
		&.superadmin {
			background-color: $light-red;
			color: $darker-light-red;
		}
	}
}
.linkIcon:before {
	content: '';
	position: absolute;
	left: -3.2px;
	bottom: -3.2px;
	width: 100%;
	height: 100%;
	border-radius: 0 0 0 2px;
	border-left: 1.5px solid #ff387f;
	border-bottom: 1.5px solid #ff387f;
}
</style>

<style lang="scss" descoped>
// @import 'src/assets/scss/sharing/docs-unscoped.scss';
// @import 'src/assets/scss/settings/table-settings.sass';
</style>
