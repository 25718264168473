import { $request } from './requester'

export default {
	async listWaitingUsers() {
		return $request('POST', '/users_by_global_cause').then((res) => res.body.data.users)
	},

	async acceptWaitingUser(id) {
		return $request('POST', `/users/grantspace/${id}`).then((res) => res)
	},

	async declineWaitingUser(id) {
		return $request('POST', `/users/declinegrantspace/${id}`).then((res) => res)
	},
}
