<template>
	<div class="sub-header-container">
		<div class="top-line">
			<div class="title">{{ title }}</div>
			<div class="controls-container">
				<slot name="controls-container"></slot>
			</div>
			<div v-if="hasButtonsAndControls" class="barrier"></div>
			<div class="buttons-container">
				<slot name="buttons-container"></slot>
			</div>
		</div>
		<div class="sub-content">
			<slot name="sub-content"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageHeader',

	props: ['title'],

	computed: {
		hasButtonsAndControls: function () {
			return this.$slots['controls-container'] && this.$slots['buttons-container']
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/general/sub-header.scss';
</style>
