import { update } from 'lodash'
import { $request } from './requester'
/**
 * @typedef {Object} User
 * @property {Number} id      - unique id
 * @property {Number} group_id - group id of the user
 * @property {String} mail - the email to contact him/her
 * @property {String} phone - the phone number to contact him/her
 * @property {String} avatar - base64 image
 * @property {String} created - UTC time of creation
 * @property {String} surname - Lastname
 * @property {String} firstname - Firstname
 * @property {String} birthdate - UTC time for aging purpose mainly
 */

/**
 * All user-related call to api
 * @model User
 */

export default {
	/**
	 * POST /users
	 * @param {*} state
	 * @param {Number} [state.nb_results_users=10000]
	 * @param {Number} [state.result_index=0]
	 * @param {Boolean} [state.order=true]
	 */
	async list(state = {}, isMasterView = false) {
		const limit = state.nb_results_users || 10000
		const offset = 0
		const sort = state.order || true

		return $request('POST/FORM', `/${isMasterView ? 'master-' : ''}users`, {
			limit,
			offset,
			sort,
		}).then((res) => res.body.data)
	},

	/**
	 * `GET` `/users/:id`
	 * @param {Number|String} id the user id to retrieve
	 * @returns {User}
	 */
	async getUserById(id) {
		return $request('GET', `/users/${id}`).then((res) => res.body.data.user)
	},

	/**
	 * POST `/usernopassword`
	 * @param {User} user
	 * @deprecated not used
	 */
	async save(user) {
		return $request('POST/FORM', '/usernopassword', user)
	},

	/**
	 * PATCH `/users/:id`
	 * @param {User} user
	 */
	async updateUser(user) {
		return $request('PATCH/FORM', `/users/${user.id}`, user)
	},

	/**
	 * PATCH `/users/updateRole`
	 */
	async updateUserRole(data) {
		return $request('PATCH', '/users/updateRole', data).then((res) => res)
	},

	/**
	 * PATCH `/users/updateEmailSenders`
	 */
	async updateEmailSenders(data) {
		return $request('PATCH', '/users/updateEmailSenders', data)
			.then((res) => {
				return res.body.data
			})
			.catch((err) => {
				return err
			})
	},

	/**
	 * DELETE `/users/:id`
	 *
	 * @param {Number|String} id - the user's id to delete
	 */
	async delete(id) {
		return $request('DELETE', `/users/${id}`)
	},

	/**
	 * POST `/invite`
	 * @param {String[]} data - list of email addresses
	 *
	 * @returns {String[]} list of addresses successfully reached
	 */
	async invite(data) {
		return $request('POST', '/invite', data).then((res) => {
			return res.body.data.emails_sent
		})
	},

	// /**
	//  * >>>>> MOCK PROFILE <<<<<
	//  * `GET` `/users/:id/availability`
	//  * @param {Number|String} id the user id to retrieve
	//  * @returns {Object}
	//  */
	// async getUserAvailability(id) {
	// 	// return $request('GET', `/users/${id}/availability`).then((res) => res.body.data.availability)
	// 	return new Promise((resolve) => {
	// 		setTimeout(() => {
	// 			resolve(mockAvailability)
	// 		}, 150)
	// 	})
	// },

	// /**
	//  * >>>>> MOCK PROFILE <<<<<
	//  * PATCH `/users/:id/availability`
	//  * @param {User} user
	//  */
	// async updateUserAvailability(id, availability) {
	// 	// return $request('PATCH/FORM', `/users/${id}/availability`, availability).then((res) => res.body.data.availability)
	// 	return new Promise((resolve) => {
	// 		setTimeout(() => {
	// 			mockAvailability = availability
	// 			resolve(mockAvailability)
	// 		}, 150)
	// 	})
	// },

	async getAllNotificationsPreferences() {
		return $request('GET', '/notification-settings').then((res) => res.body.data)
	},

	async setNotificationPreference(data) {
		return $request('POST', '/notification-settings', data).then((res) => res.body.data)
	},

	async connectBox() {
		return $request('GET', '/geturloauth2').then((res) => {
			window.location.assign(res.body.data)
		})
	},

	async deleteBox(accountId) {
		return $request('DELETE', `/deleteaccount/${accountId}`)
			.then((res) => {
				return res
			})
			.catch((err) => {
				return err
			})
	},

	async getEmailBoxStatus() {
		return $request('GET', '/retrieveaccounts')
			.then((res) => {
				if (res.body) return res.body.data?.accounts ? res.body.data.accounts : []

				return []
			})
	},

	async updateAccountSharing(payload) {
		return $request('PUT', '/shareaccount', payload)
			.then((res) => {
				return res.body.data
			})
			.catch((err) => {
				return err
			})
	},

	/**
	 * GET `/users/:id/senders`
	 * @description retrieve senders for a specific user
	 */
	async getUserSenders(userId) {
		return $request('GET', `/users/${userId}/senders`)
			.then((res) => {
				const senders = res.body?.data?.senders
				return senders || []
			})
			.catch((err) => {
				console.error('Error in getUserSenders:', err)
				return []
			})
	},
}
