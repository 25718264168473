/**
 * Sharing module routes
 *
 * handle communications routes
 *
 * @rootPath {/briefs}
 */
import General from 'components/sharing-module/general.vue'
import Briefs from 'components/sharing-module/sharing-sub-header.vue'
import Docs from 'components/sharing-module/sharing-sub-header.vue'
import { controllerPackRedirection } from './utils/routerController'
import store from './../store/stateStore'

export default {
	meta: { title: 'MENU.DOCS' },
	path: '/sharing',
	name: 'sharing',
	component: General,
	props: true,
	redirect: { name: 'docs' },
	beforeEnter: (to, from, next) => {
		// Role access check
		if (store.getters['userConnected']?.role_data?.access?.includes('documents:delete')) {
			// Pack check
			controllerPackRedirection(next, 'statusWebAccessDocuments')
		} else {
			next({ name: 'command-center', replace: true })
		}
	},
	children: [
		{
			path: '/sharing/briefs',
			name: 'briefs',
			component: Briefs,
			props: true,
		},
		{
			path: '/sharing/docs',
			name: 'docs',
			component: Docs,
			props: true,
		},
	],
}
