<template>
	<div class="q-fullwidth q-m-lg-l w-full">
		<div class="flex items-center w-full flex-col sm:flex-row">
			<tab-with-number
				v-for="(tab, index) in computedGetTabs"
				:key="index"
				:title="tab.title"
				:count="tab.count"
				:selected="$route.name === tab.name"
				:status-pack="tab.status"
				font-size="1.25rem"
				class="mr-10 cursor-pointer"
				@tabClick="methodGoTo(tab.name)"
			>
			</tab-with-number>

			<div class="flex-grow flex items-end justify-end pr-5 w-full mt-3 sm:w-auto sm:mt-0">
				<template v-if="$route.name === 'docs'">
					<q-button class="add" icon="plus" @click="methodToggleDialogAddDocOrLinkVisible(true)">{{
						$t('SHARING2.DOCS.ACTIONS.ADD')
					}}</q-button>
				</template>
				<template v-if="$route.name === 'briefs'">
					<q-button class="add" icon="plus" @click="methodAddModalBriefVisible(true)">{{
						$t('SHARING2.BRIEFS.HEADER.ADD')
					}}</q-button>
				</template>
			</div>
		</div>

		<!-- subtitle -->
		<template v-if="$route.name === 'briefs'">
			<p id="subtitle" class="pt-4">
				{{ $t('SHARING2.BRIEFS.HEADER.BRIEF.SUBTITLE') }}
			</p>
		</template>
		<template v-if="$route.name === 'docs'">
			<p id="subtitle" class="pt-4">
				{{ $t('SHARING2.BRIEFS.HEADER.DOCS.SUBTITLE') }}
			</p>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		TabWithNumber: defineAsyncComponent(() => import('/src/components/general/tabWithNumber.vue')),
	},
	props: {
		methodToggleDialogAddDocOrLinkVisible: {
			type: Function,
			default: () => {},
		},
		methodAddModalBriefVisible: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {}
	},

	computed: {
		...mapGetters('@briefs', ['briefs']),
		...mapGetters('@docs', ['docs']),

		t() {
			const prefix = 'COMMUNCATIONS.HEADER'
			const prefixMenu = 'MENU'
			return {
				general: this.$t(`${prefix}.GENERAL`),
				sms: this.$t(`${prefix}.SMS`),
				briefs: this.$t(`${prefixMenu}.BRIEFS`),
				docs: this.$t(`SHARING2.DOCS.TAB_TITLE`),
			}
		},

		computedGetTabs() {
			return [
				{
					title: this.t.docs,
					count: this.docs?.length,
					name: 'docs',
					status: 'enabled',
				},
				{
					title: this.t.briefs,
					count: this.briefs?.length,
					name: 'briefs',
					status: 'enabled',
				},
			]
		},
	},

	methods: {
		methodGoTo(route) {
			this.$router.push({ name: route })
		},
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/shared/shared-constants.scss';

#subtitle {
	@apply text-gray-dark;
}
</style>
