<template>
	<div>
		<action-creation-helper-container
			:page-is-comment="true"
			less-padding-at-top-and-bottom
			:header-height="headerHeight"
		>
			<div class="w-full flex items-center justify-center">
				<div class="flex flex-col items-center justify-center transition-all w-full xs:w-10/12 sm:w-8/12 md:w-8/12 lg:w-7/12 xl:w-6/12 px-4 sm:px-10 gap-10">
					<div class="flex w-full justify-start items-center gap-4">
						<div class="w-11 h-11 rounded-full flex items-center justify-center bg-gray-100">
							<i class="gui-settings text-3xl " />
						</div>
						<span class="font-title text-3xl">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.MORE_INFORMATIONS_TITLE') }}</span>
					</div>
					<div
						class="flex fields w-full flex-col"
					>
						<!-- v-if="computedIsActionDateEnabled" -->
						<div

							class="flex flex-col w-full"
						>
							<div class="mt-4 flex flex-col gap-10">
								<div>
									<ActionCreationTimeSelectors
										v-model:start-date-action="fromVuexStartDateAction"
										v-model:end-date-action="fromVuexEndDateAction"
										v-model:selected-tz="selectedTZ"
										:show-end-date="computedActionTypeNeededDisplayEndDateOrNot"
										:mandatory="computedMandatoryDate"
										:info-campagne="info_campagne"
									/>
								</div>
							</div>
						</div>
						<el-divider
							v-if="canHaveGoal()"
							class="my-8  border-gray-100"
						/>
						<div
							v-if="canHaveGoal()"
							class="flex flex-col gap-3 w-full"
						>
							<label
								v-t="'ACTION.CALL_TO_ACTION.INFORMATIONS.GOAL_V2'"
								class="bold"
							/>
							<div
								v-t="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.GOAL_HELPER')"
								class="subtitle"
							/>
							<el-input-number
								v-model="fromVuexGoalAction"
								:step="10"
								:min="0"
							/>
						</div>
						<el-divider
							v-if="canHaveGoal()"
							class="my-8  border-gray-100"
						/>
						<el-divider
							v-if="computedIsActionV2Enabled && !canHaveGoal()"
							class="my-8  border-gray-100"
						/>
						<LeadersSelector
							v-if="computedIsActionV2Enabled"
							v-model:from-parent-leaders-action="fromVuexLeadersAction"
						/>
						<el-divider
							v-if="computedIsActionV2Enabled"
							class="my-8  border-gray-100"
						/>
						<div
							v-if="canHaveBrief()"
							class="flex flex-col gap-3 w-full"
						>
							<label
								v-t="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF')"
								class="bold"
							/>
							<el-select
								v-model="actionBrief"
								class="q-p-sm-t q-fullwidth"
								:placeholder="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_CHOOSE_ONE')"
								value-key="label"
								clearable
								@change="handleActionBrief"
							>
								<el-option-group>
									<el-option
										:disabled="!userConnected?.role_data?.access?.includes('documents:delete')"
										:value="newBrief"
									>
										<el-tooltip
											effect="dark"
											:content="$t('SETTINGS.ROLE_MANAGEMENT.DISABLED_FEATURE')"
											placement="top-start"
											:disabled="userConnected?.role_data?.access?.includes('documents:delete')"
										>
											<div>
												{{
													$t('ACTION.CALL_TO_ACTION.INFORMATIONS.CREATE_BRIEF') ||
														$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_NOT_SELECTED')
												}}
											</div>
										</el-tooltip>
									</el-option>
								</el-option-group>
								<el-option-group>
									<el-option
										v-for="brief in briefs"
										:key="brief.id"
										:value="brief"
										:label="brief.label"
									/>
								</el-option-group>
							</el-select>
						</div>
						<el-divider
							v-if="canHaveBrief()"
							class="my-8  border-gray-100"
						/>
						<div
							v-if="canHaveLink()"
						>
							<LinksManager
								v-model:links="computedGetVuexActionLinks"
							/>
						</div>
						<el-divider
							v-if="canHaveRegistrationLink()"
							class="my-8  border-gray-100"
						/>
						<div
							v-if="canHaveRegistrationLink()"
						>
							<ToggleLinkRegistration
								v-model:registration-link="fromVuexLinkRegistrationAction"
								v-model:selected-option-toggle="getSelectedOptionToggle"
							/>
						</div>
						<el-divider
							v-if="computedNeedToDisplayDisableFormsOptions"
							class="my-8  border-gray-100"
						/>
						<div
							v-if="computedNeedToDisplayDisableFormsOptions"
							class="flex justify-between items-start w-full mb-4"
						>
							<div class="flex flex-col gap-3">
								<label class="bold mr-1">
									{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.FORM_ENABLED') }}
								</label>
								<span class="text-gray-strong">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.FORM_ENABLED_HELPER') }}</span>
							</div>
							<div class="flex items-center gap-2 shrink-0">
								<span>{{ fromVuexFormEnabledAction ? $t('ACTION.CALL_TO_ACTION.INFORMATIONS.FORM_ENABLED_TRUE') : $t('ACTION.CALL_TO_ACTION.INFORMATIONS.FORM_ENABLED_FALSE') }}</span>
								<el-switch
									v-model="fromVuexFormEnabledAction"
								/>
							</div>
						</div>
					</div>
					<el-dialog
						@close="cancelBriefCreation()"
						@closed="cancelBriefCreation()"
						v-model="createBrief"
					>
						<div
							class="w-full"
						>
							<div class="flex flex-col w-full">
								<h2
									v-t="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_TITLE_CREATION')"
									class="text-xl center mb-6"
								/>
								<el-input
									v-model="newBrief.label"
									autofocus
									:placeholder="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_TITLE')"
								>
									<span v-t="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_TITLE')" />
								</el-input>
								<div class="q-v-spacer-l" />
								<el-input
									v-model="newBrief.refvalues[0].label"
									class="border-gray-500 w-full"
									rows="10"
									type="textarea"
									:placeholder="$t('ACTION.CALL_TO_ACTION.INFORMATIONS.BRIEF_CREATED_PLACEHOLDER')"
								/>
								<div class="q-v-spacer-l" />
								<div class="center">
									<q-button
										class="q-m-r"
										link
										secondary
										@click="cancelBriefCreation()"
									>
										{{
											$t('_COMMON.CANCEL')
										}}
									</q-button>
									<q-button
										v-if="!loadCreate"
										class="q-m-l"
										:disabled="!newBrief.label && newBrief.refvalues[0].label"
										@click="createNewBrief"
									>
										{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.CREATE_BRIEF') }}
									</q-button>
									<q-button
										v-else
										class="q-m-l"
									>
										<i class="fa fa-circle-o-notch fa-spin" />
									</q-button>
								</div>
							</div>
						</div>
					</el-dialog>
				</div>
			</div>
		</action-creation-helper-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { actionTypology } from 'src/mixins'
import { defineAsyncComponent, nextTick } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION } from 'src/constants'
import analytics, { TRACKING } from '../../../../external/analytics'

import moment from 'moment-timezone'
import { formatCustomDate, formatTime } from '../../../../utils/dateFnsHelper'
import { zonedTimeToUtc } from 'date-fns-tz'

export default {
	components: {
		ActionCreationHelperContainer: defineAsyncComponent(
			() => import('../ActionCreationHelperContainer'),
		),
		ActionCreationTimeSelectors: defineAsyncComponent(
			() => import('../creation-utils/ActionCreationTimeSelectors'),
		),
		ToggleLinkRegistration: defineAsyncComponent(() => import('./toggleLinkRegistration.vue')),
		LinksManager: defineAsyncComponent(() => import('../../actions/globalComponent/LinksManager.vue')),
		LeadersSelector: defineAsyncComponent(() => import('./LeadersSelector.vue')),
	},
	emits: ['update:nextValidatorBoolean', 'update:errorMessage', 'update:nextFunction'],

	mixins: [actionTypology],
	/**
	 * @component Action/CallToAction/Information
	 *
	 * @desc handle the default informations for a new action.
	 * *Warning*: mixin method to create computed
	 *
	 * @mixes ActionTypology
	 *
	 * @borrows ActionTypology#canHaveBriefFunc as canHaveBriefFunc
	 * @borrows ActionTypology#canHaveLinkFunc as canHaveLinkFunc
	 * @borrows ActionTypology#canHaveGoalFunc as canHaveGoalFunc
	 * @borrows ActionTypology#canHaveDateFunc as canHaveDateFunc
	 */

	data() {
		return {
			tempLocalLink: '',
			linkErrorMessage: '',
			comment: '',
			/** @member {Boolean} loadCreate */
			loadCreate: false,
			/** @member {Boolean} createBrief=false */
			createBrief: false,
			/** @member {Object} actionBrief */
			actionBrief: null,
			/** @member {Object} newBrief */
			newBrief: {
				label: '',
				refvalues: [{ label: '' }],
			},
			selectedOptionToggleLocal: 'no',
			ACTION,

			selectedTZ: moment.tz.guess(),
		}
	},

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
	},

	// updated() {},
	computed: {
		...mapGetters('@packs', ['getAccessStatusActionv2']),
		...mapGetters('@action', ['newAction']),
		...mapGetters('@briefs', ['briefs']),
		...mapGetters(['userConnected', 'info_campagne']),
		...mapGetters('@search', [
			'getSearchAddressIncluded',
			'getSearchPollingStationsIncluded',
			'getAllowUnknowAddressesOnActionCreation',
		]),

		fromVuexLeadersAction: {
			get() {
				const { leaders } = this.newAction
				return leaders || []
			},
			set(value) {
				this.actionHandleNewActionLeaders(value)
			},
		},

		computedGetVuexActionLinks: {
			get() {
				const { links } = this.newAction
				return links
			},
			set(value) {
				if (value?.type && value?.type === 'remove-link') {
					this.actionEditNewActionObject({ key: 'remove-links', link: value.link })
					return
				}
				else this.actionEditNewActionObject({ key: 'links', link: value.link })
			},
		},

		fromVuexGoalAction: {
			get() {
				const { goal } = this.newAction
				return goal
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'goal', value })
			},
		},

		fromVuexGoalFormsAction: {
			get() {
				const { goal_forms } = this.newAction
				return goal_forms
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'goal_forms', value })
			},
		},

		fromVuexFormEnabledAction: {
			get() {
				return !this.newAction.typeFormDisabledAt
			},
			set() {
				this.actionEditNewActionObject({
					key: 'typeFormDisabledAt',
					value: !this.newAction.typeFormDisabledAt,
				})
			},
		},

		fromVuexLinkRegistrationAction: {
			get() {
				const { registration_link } = this.newAction
				return registration_link
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'registration_link', value })
			},
		},

		fromVuexStartDateAction: {
			get() {
				const { start } = this.newAction

				return start
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'start', value })
			},
		},

		fromVuexEndDateAction: {
			get() {
				const { end } = this.newAction

				return end
			},
			set(value) {
				this.actionEditNewActionObject({ key: 'end', value })
			},
		},

		// Get if user switch to true the registration link input
		getSelectedOptionToggle: {
			get() {
				return this.selectedOptionToggleLocal
			},
			set(value) {
				this.selectedOptionToggleLocal = value
			},
		},

		computedNeedToDisplayDisableFormsOptions() {
			return this.needToDisplayDisableFormsOptions(this.newAction.type)
		},

		computedIsActionDateEnabled() {
			return this.canHaveDateFunc(this.newAction.type)
		},

		computedMandatoryDate() {
			return this.mandatoryDateFunc(this.newAction.type)
		},

		computedErrorMessage() {
			if (this.computedMandatoryDate) {
				if (!this.computedIsMandatoryDateCompleted) {
					return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.MANDATORY_DATE_ERROR_MESSAGE')
				}
			}
			return ''
		},

		computedActionTypeNeededDisplayEndDateOrNot() {
			const allowedActions = ['static', 'challenge', 'event']
			return allowedActions.includes(this.newAction.type)
		},

		computedIsMandatoryDateCompleted() {
			if (this.mandatoryDateFunc(this.newAction.type)) {
				if (this.fromVuexStartDateAction && this.fromVuexEndDateAction) {
					return true
				}

				return false
			}

			return false
		},

		computedIsActionV2Enabled() {
			return this.getAccessStatusActionv2 === 'enabled'
		},

		computedGetValidator() {
			if (this.createBrief) return false
			if (this.computedMandatoryDate) {
				return this.computedIsMandatoryDateCompleted
			}
			// If registration link input is displayed and empty, block next button
			if (this.getSelectedOptionToggle != 'no' && !this.newAction.registration_link) {
				return false
			}
			return true
		},
	},

	watch: {
		selectedTZ: {
			handler(value) {
				this.actionEditNewActionObject({ key: 'timezone', value: value })
			},
			immediate: true,
		},

		computedGetValidator: {
			handler(newValue, oldValue) {
				this.$emit('update:nextValidatorBoolean', newValue)
				if (oldValue === true && newValue === false) this.$emit('update:errorMessage', this.computedErrorMessage)
			},
			immediate: true,
		},
	},

	mounted() {
		this.$forceUpdate
		this.actionSetStep('MORE_INFORMATION')
		if (!this.briefs.length) {
			this.actionFetchBriefs()
		}
		if (this.newAction.forms) {
			if (this.newAction.forms.length) {
				this.actionBrief = this.newAction.forms.find((form) => form.name === 'BRIEF')
			}
		}
		const nextFunction = this.newAction.publicAction ? this.submitAction : null
		if (this.computedMandatoryDate && !this.computedIsMandatoryDateCompleted) this.$emit('update:errorMessage', this.computedErrorMessage)
		this.$emit('update:nextFunction', nextFunction)
	},

	methods: {
		...mapActions('@action', [
			'actionSetStep',
			'actionEditNewActionObject',
			'actionAddNewActionBrief',
			'actionHandleNewActionLeaders',
			'actionSkipNavigationGuardBeforeRouteLeave',
			'actionCreateNewFieldAction',
		]),
		...mapActions('@briefs', ['actionFetchBriefs', 'actionAddBrief']),

		formatCustomDate,
		zonedTimeToUtc,
		formatTime,

		/** @computed {Boolean} canHaveBrief */
		canHaveBrief() {
			return this.canHaveBriefFunc(this.newAction.type)
		},
		/** @computed {Boolean} canHaveLink */
		canHaveLink() {
			return this.canHaveLinkFunc(this.newAction.type)
		},
		/** @computed {Boolean} canHaveGoal */
		canHaveGoal() {
			return this.canHaveGoalFunc(this.newAction.type)
		},
		canHaveSharingContent() {
			return this.canHaveSharingContentFunc(this.newAction.type)
		},

		canHaveRegistrationLink() {
			return this.canHaveRegistrationLinkFunc(this.newAction.type)
		},

		/**
		 * @method handleActionBrief
		 * @desc wether to push a brief or create a new one
		 */
		handleActionBrief(brief) {
			if (brief.id) {
				this.actionBrief = brief
				this.actionAddNewActionBrief(brief)
			}
			else {
				this.createBrief = true
			}
		},

		createNewBrief() {
			this.loadCreate = true
			this.actionAddBrief(this.newBrief).then((res) => {
				const brief = res.find(
					(brief) =>
						brief.label === this.newBrief.label
						&& brief.refvalues[0].label === this.newBrief.refvalues[0].label,
				)
				this.newBrief.label = ''
				this.newBrief.refvalues[0].label = ''
				this.handleActionBrief(brief)
				this.loadCreate = false
				this.createBrief = false
			})
		},

		cancelBriefCreation() {
			this.createBrief = false
		},

		/**
		 * @func submitAction
		 * In the BottomBar function we will dispatch
		 * this function. We will use a boolean to
		 * if the action need to be empty at the creation.
		 */
		submitAction(needToBeEmptyAction = false) {
			// Dispatch the new action to the store
			// ! ⚠️ short-circuit all the filtering, only taking the selected ones ⚠️ !
			const addressIncluded = cloneDeep(this.getSearchAddressIncluded)
			const pollingStationsIncluded = cloneDeep(this.getSearchPollingStationsIncluded)
			const allowUnknowAddressesOnActionCreation = cloneDeep(
				this.getAllowUnknowAddressesOnActionCreation,
			)
			this.actionSkipNavigationGuardBeforeRouteLeave(true)
			let clonedNewAction = cloneDeep(this.newAction)
			// Pure action creation to create the final action
			this.actionCreateNewFieldAction({
				needToBeEmptyAction,
				action: {
					...clonedNewAction,
					notify: true,
				},
				payload: {
					addressIncluded,
					pollingStationsIncluded,
					allowUnknowAddressesOnActionCreation,
				},
			})

			// Track action created
			analytics.track(TRACKING.action_created, { type_action: clonedNewAction.type })

			// Leave the tunnel
			this.$router.push({
				name: 'actions',
				query: {
					action_created: 1,
				},
			})
			/**
			 * Display a message to see the actions is in the creation
			 * workflow from backend
			 */
			nextTick(() => {
				this.$message({
					message: this.$t('ACTION.CALL_TO_ACTION.SUBMIT_INFO'),
					type: 'info',
					showClose: true,
				})
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.subtitle{
	@apply text-gray-strong;
}

.delete_cta {
		visibility: hidden;
}

.a_link:hover {
	.delete_cta {
		visibility: visible;
	}
}

</style>
