import get from 'lodash/get'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import sortAlphaNumArrayOfObject from '@quorumsco/quorum-helpers/lib/helpers/sortAlphaNumArrayOfObject'

import {
	fetchCountAddressesForPolygon,
	searchContactsFiltersAdvanced,
} from '../../models/search_store'
import contactRequester from '../../models/contact_store'
import { constructQueryFromStore, constructQueryFromStoreAdaptator } from './utils/searchTools'
import modulesInstancesMixin from '../../components/mixins/modules-instances-mixin'

import {
	// Addresses included
	SEARCH_ADD_ADDRESS_INCLUDED,
	SEARCH_REPLACE_ADDRESS_INCLUDED,
	// SEARCH_REMOVE_ADDRESS_INCLUDED,
	SEARCH_RESET_ADDRESS_INCLUDED,
	// Polling stations included
	SEARCH_ADD_POLLING_STATION_INCLUDED,
	SEARCH_REPLACE_POLLING_STATION_INCLUDED,
	SEARCH_REMOVE_POLLING_STATION_INCLUDED,
	SEARCH_RESET_POLLING_STATION_INCLUDED,
	//
	FETCH_POLYGON_TO_GET_COUNT_ADDRESSES,
	IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES,
	// Advanced search
	SEARCH_SET_ADVANCED_SEARCH,
	SEARCH_SET_ADVANCED_SEARCH_ATTR,
	SET_COUNT_CONTACTS_FOUND_ADVANCED_SEARCH_KPI,
	// Reset to initial state
	SEARCH_RESET_TO_INITIAL_STATE,
	SET_INSTANCIED_MODULE_ID,
} from './_mutation-types'

/**
 * @store @search
 * @namespaced
 * @description Search' related info and store module
 */

const getDefaultState = () => {
	return {
		isBuildEmptyAddressesIsFetched: false,
		canBuildEmptyAddresses: false,
		polling_station_included: [],
		address_included: [],
		address_excluded: [],
		advanced_search: {},
		numberContactsFoundsAdvancedSearchKPI: 0,
		instanceId: '',
	}
}

const state = getDefaultState

const mutations = {
	/**
	 * Addresses included
	 */
	[SEARCH_ADD_ADDRESS_INCLUDED]: (state, addressToInclude) => {
		state.address_included.push(addressToInclude)
	},
	[SEARCH_REPLACE_ADDRESS_INCLUDED]: (state, addressToInclude) => {
		state.address_included = addressToInclude
	},

	[SEARCH_RESET_ADDRESS_INCLUDED]: (state) => {
		state.address_included = []
	},
	/**
	 * Polling stations included
	 */
	[SEARCH_ADD_POLLING_STATION_INCLUDED]: (state, pollingStationToInclude) => {
		state.polling_station_included.push(pollingStationToInclude)
	},
	[SEARCH_REPLACE_POLLING_STATION_INCLUDED]: (state, pollingStationToInclude) => {
		state.polling_station_included = pollingStationToInclude
	},
	[SEARCH_REMOVE_POLLING_STATION_INCLUDED]: (state, aPollingStationIncludedToRemove) => {
		state.polling_station_included.filter(
			(aPollingStationIncluded) => aPollingStationIncluded !== aPollingStationIncludedToRemove,
		)
	},
	[SEARCH_RESET_POLLING_STATION_INCLUDED]: (state) => {
		state.polling_station_included = []
	},
	[SEARCH_RESET_TO_INITIAL_STATE]: (state, exceptAdvancedSearch) => {
		if (exceptAdvancedSearch) {
			const advancedSearch = state.advanced_search
			Object.assign(state, getDefaultState())
			state.advanced_search = advancedSearch
		}
		else {
			Object.assign(state, getDefaultState())
		}
	},
	/**
	 * Count Addresses
	 */
	[FETCH_POLYGON_TO_GET_COUNT_ADDRESSES]: (state, canBuildEmptyAddresses) => {
		state.canBuildEmptyAddresses = canBuildEmptyAddresses
	},

	[IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES]: (state, stateWanted) => {
		state.isBuildEmptyAddressesIsFetched = stateWanted
	},

	/**
	 * Advanced Search
	 */
	[SEARCH_SET_ADVANCED_SEARCH]: (state, advancedSearch) => {
		state.advanced_search = advancedSearch
	},

	[SEARCH_SET_ADVANCED_SEARCH_ATTR]: (state, payload) => {
		const advancedSearch = state.advanced_search
		if (!isEmpty(advancedSearch)) {
			const keyToModify = Object.keys(payload)[0]
			state.advanced_search[keyToModify] = payload[keyToModify]
		}
	},

	[SET_COUNT_CONTACTS_FOUND_ADVANCED_SEARCH_KPI]: (state, payload) => {
		state.numberContactsFoundsAdvancedSearchKPI = payload
	},

	[SET_INSTANCIED_MODULE_ID]: (state, res) => {
		state.instanceId = res
	},
}

const actions = {
	/**
	 * Addresses included actions
	 */
	actionAddAddressIncluded({ commit }, payload) {
		commit(SEARCH_ADD_ADDRESS_INCLUDED, payload)
	},
	actionReplaceAddressIncluded({ commit }, payload) {
		commit(SEARCH_REPLACE_ADDRESS_INCLUDED, payload)
	},
	actionResetAddressIncluded({ commit }) {
		commit(SEARCH_RESET_ADDRESS_INCLUDED)
	},
	/**
	 * Polling station included actions
	 */
	actionAddPollingStationIncluded({ commit }, payload) {
		commit(SEARCH_ADD_POLLING_STATION_INCLUDED, payload)
	},
	actionReplacePollingStationIncluded({ commit }, payload) {
		commit(SEARCH_REPLACE_POLLING_STATION_INCLUDED, payload)
	},
	actionRemovePollingStationIncluded({ commit }, payload) {
		commit(SEARCH_REMOVE_POLLING_STATION_INCLUDED, payload)
	},
	actionResetPollingStationIncluded({ commit }) {
		commit(SEARCH_RESET_POLLING_STATION_INCLUDED)
	},
	/**
	 * Will reset the entire search module
	 */
	actionResetSearchModuleToInitialState({ commit, state }, { exceptAdvancedSearch }) {
		commit(SEARCH_RESET_TO_INITIAL_STATE, exceptAdvancedSearch)
	},
	/**
	 * Fetch the polygon to get the count of streets
	 */
	actionFetchPolygonToGetCountStreetsInArea({ state, commit, dispatch, rootGetters }, polygon) {
		const filterName = `${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)}/initialFilterArray`

		const payloadForRequesterSearch = {
			query: '',
			fields: rootGetters[filterName],
			polygon,
			tags: [],
		}
		if (!polygon.length) {
			commit(FETCH_POLYGON_TO_GET_COUNT_ADDRESSES, true)
			commit(IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES, true)
		}
		else {
			fetchCountAddressesForPolygon(payloadForRequesterSearch)
				.then(() => {
					commit(FETCH_POLYGON_TO_GET_COUNT_ADDRESSES, true)
					commit(IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES, true)
				})
				.catch(() => {
					commit(FETCH_POLYGON_TO_GET_COUNT_ADDRESSES, false)
					commit(IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES, true)
				})
		}
	},
	actionResetPolygonToGetCountStreetsInArea({ commit, state }) {
		if (state.getCanBuildEmptyAddresses) {
			commit(FETCH_POLYGON_TO_GET_COUNT_ADDRESSES, false)
		}
		if (state.isBuildEmptyAddressesIsFetched) {
			commit(IS_FETCHED_POLYGON_TO_GET_COUNT_ADDRESSES, false)
		}
	},

	actionSetAdvancedSearch({ commit }, payload) {
		commit(SEARCH_SET_ADVANCED_SEARCH, payload)
	},

	actionSetAdvancedSearchAttribute({ commit }, payload) {
		commit(SEARCH_SET_ADVANCED_SEARCH_ATTR, payload)
	},

	actionGetTotalContacts({ state, commit, dispatch, rootGetters }) {
		const filterName = `${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)}/initialFilterArray`

		contactRequester
			.kpi(
				constructQueryFromStoreAdaptator(
					rootGetters,
					'',
					rootGetters[filterName],
					[],
					[],
					false,
					{},
					null,
					null,
					null,
					state.instanceId,
				),
			)
			.then(({ kpi }) => {
				let totalFromKPI = get(kpi, '[0].KpiReplies[0].Doc_count', false)
				if (totalFromKPI !== null || totalFromKPI !== undefined) {
					commit('CONTACTS_TOTAL', totalFromKPI, { root: true })
				}
			})
	},

	actionFetchAllPollingStations({ state, commit, dispatch, rootGetters }) {
		/**
		 * We get a derivated store in contact to get
		 * the pollingStations. If the pollingStations are allready
		 */

		const filterName = `${modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)}/initialFilterArray`

		contactRequester
			.kpi(
				constructQueryFromStoreAdaptator(
					rootGetters,
					'',
					rootGetters[filterName],
					[],
					[],
					false,
					{},
					null,
					null,
					null,
					state.instanceId,
				),
			)
			.then(({ kpi }) => {
				if (kpi[2].KpiReplies) {
					const bdvs = kpi[2].KpiReplies
					let bdv_options = sortAlphaNumArrayOfObject(
						bdvs.map((bdv) => {
							return { label: bdv.Key, value: bdv.Key }
						}),
						'label',
					)
					bdv_options.push({ label: 'missing', value: 'missing' })
					bdv_options = uniqBy(bdv_options, 'value')
					commit('@contact/SET_ALL_POLLING_STATIONS', bdv_options, { root: true })
				}
			})
	},

	actionFetchContactsFiltersAdvanced({ commit }, payload) {
		searchContactsFiltersAdvanced(payload).then((res) => {
			commit('SET_CONTACTS', res.contacts, { root: true })
		})
	},

	async actionGetKpiAdvancedSearch({ state, rootGetters }, payload) {
	/**
		 * Just specific code to take the polygon in count on local kpi searching
		 * to show results including the polygon or not. Cannot be moved out here
		 */
		const specificBuildQuery = {
			...constructQueryFromStore(rootGetters, state.instanceId),
			advanced_search: payload,
		}
		if (specificBuildQuery.polygon.length) {
			specificBuildQuery.advanced_search.polygon = specificBuildQuery.polygon
		}

		let res = await contactRequester.kpi(specificBuildQuery)
		return get(res, 'kpi[0].KpiReplies[0].Doc_count')
	},

	actionKpiLocalContactsFromAdvancedSearch: (
		{ commit, dispatch },
		payload,
	) => {
		dispatch('actionGetKpiAdvancedSearch', payload).then((countContactsSearched) => {
			commit(SET_COUNT_CONTACTS_FOUND_ADVANCED_SEARCH_KPI, countContactsSearched || 0)
		})
	},

	actionSetInstanceId({ commit }, payload) {
		commit(SET_INSTANCIED_MODULE_ID, payload)
	},
}

const getters = {
	getCanBuildEmptyAddresses: (state) => state.canBuildEmptyAddresses,
	getIsBuildEmptyAddressesIsFetched: (state) => state.isBuildEmptyAddressesIsFetched,
	isStreetAddressOrPollingStationsIncluded: (state) => {
		!!state.address_included.concat(state.polling_station_included).length
	},
	getSearchAddressIncluded: (state) => state.address_included,
	getSearchPollingStationsIncluded: (state) => state.polling_station_included,
	getAllowUnknowAddressesOnActionCreation: (state) => state.allowUnknowAddressesOnActionCreation,
	getAdvancedSearchQuery: (state) =>
		!isEmpty(state?.advanced_search) ? state.advanced_search : null,
	getNumberContactsFoundsAdvancedSearchKPI: (state) => state.numberContactsFoundsAdvancedSearchKPI,
	pageAdvancedSearch: (state, getters, rootState, rootGetters) => {
		const filter = modulesInstancesMixin.methods.getInstanceRegisteredByModule(
			'@filter',
			state.instanceId,
		)
		return rootGetters[`${filter}/result_index`] / rootGetters[`${filter}/nb_results`]
	},

	querySearchConstructed: (state, getters, rootState, rootGetters) => {
		return constructQueryFromStore(rootGetters, state.instanceId)
	},
}

export const searchModule = {
	namespaced: true,
	state: state,
	mutations: mutations,
	getters: getters,
	actions: actions,
}
