<template>
	<div>
		<h1 class="gtDisplayBold text-center text-4xl mb-5">
			{{ $t(`APP.SIGNUP.VALIDATE_EMAIL`) }}
		</h1>

		<p class="text-center">{{ $t(`APP.SIGNUP.VERIF_CODE`) }}</p>
		<p class="text-center text-pink-main">
			{{ computedEmail }}
		</p>

		<code-validation
			v-model:user-code="computedUserCode"
			v-model:validation-status="validationStatus"
			:user-email="computedEmail"
		/>

		<p class="text-gray-dark text-sm text-center">
			{{ $t(`APP.SIGNUP.NO_EMAIL`) }}
		</p>

		<button
			:class="`text-pink-main block mt-1 mx-auto text-sm ${
				waitingTime > 0 ? 'opacity-50 pointer-events-none' : ''
			}`"
			@click.prevent="onClick"
		>
			{{ $t(`APP.SIGNUP.RESEND_EMAIL`) }}
			<span v-if="waitingTime > 0">({{ waitingTime }}s)</span>
		</button>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import codeValidation from '../../general/code-validation.vue'
import analytics, { TRACKING } from '../../../external/analytics'
import store from '../../../store/stateStore'

export default {
	components: { codeValidation },

	beforeRouteEnter(to, from, next) {
		const allowedRoutes = ['login', 'login-auth0-callback', 'create-account']

		if (from.name === 'add-account-details') {
			next('add-account-details')
		} else if (
			!allowedRoutes.includes(from.name) ||
			(from.name === 'login' &&
				Object.keys(store.getters['@authentication/getCredentialsEntered']).length === 0)
		) {
			next('login')
		} else {
			next(vm => {
				vm.fromRoute = from.name
			})
		}
	},

	props: {
		userData: { type: Array, required: true },
		userCode: { type: Array, required: true },
		invalidForm: { type: Boolean, default: true },
	},
	emits: ['update:user-data'],

	data() {
		return {
			successRoute: 'add-account-details',
			waitingTime: 60,
			validationStatus: 'init',
			fromRoute: '',
		}
	},

	computed: {
		...mapGetters(['info_campagne']),
		...mapGetters('@authentication', [
			'getStatusResendCode',
			'getStatusAuth',
			'getCredentialsEntered',
		]),
		computedUserData: {
			get() {
				return this.userData
			},
			set(value) {
				this.$emit('update:user-data', value)
			},
		},
		computedEmail() {
			return this.userData.find((d) => d.name === 'email').value
		},
		computedUserCode() {
			return this.userCode
		},
	},

	watch: {
		getStatusResendCode(status) {
			switch (status) {
				case 'error':
					this.$message({
						message: this.$t('APP.SIGNUP.ERROR'),
						duration: 3000,
						type: 'error',
						showClose: true,
					})
					break
				default:
					break
			}
		},

		validationStatus(status) {
			if (status === 'success' && this.fromRoute === 'login') {
				this.getSession() // 2FA
			} else if (status === 'success') { // from create accounts routes
				this.$router.push({ name: this.successRoute }) // normal signup goto account details form
			}
		},

		getStatusAuth(value) {
			switch (value) {
				case 'success':
					this.$router.push({ name: 'command-center' })
					this.actionResetAuthenticationModule()
					analytics.track(TRACKING.SIGN_IN, null, this.info_campagne.id)
					break

				default:
					break
			}
		},
	},

	mounted() {
		this.checkEmailInUrl()
		this.setWaitingTime()
		this.verifyCodeAlreadyValidated()
	},

	methods: {
		...mapActions('@authentication', [
			'actionPostResendCode',
			'actionLoginApplication',
			'actionResetAuthenticationModule',
		]),

		onClick() {
			const formData = this.getCredentialsEntered?.raw_id_token
				? { raw_id_token: this.getCredentialsEntered.raw_id_token }
				: {
						username: this.computedEmail,
						password: this.userData.find((d) => d.name === 'password')?.value,
					}

			this.actionPostResendCode(formData)
			this.setWaitingTime()
		},
		checkEmailInUrl() {
			if (this.getCredentialsEntered.email) {
				this.computedUserData.find((d) => d.name === 'email').value =
					this.getCredentialsEntered.email
			}
		},
		setWaitingTime() {
			this.waitingTime = 60

			const decraseWaintingTime = setInterval(() => {
				if (this.waitingTime <= 0) {
					clearInterval(decraseWaintingTime)
					return
				}
				this.waitingTime--
			}, 1000)
		},
		verifyCodeAlreadyValidated() {
			if (this.computedUserCode?.every((n) => n?.length > 0)) {
				this.$message({
					message: 'your account is already validated',
					duration: 3000,
					type: 'error',
					showClose: true,
				})
				setTimeout(() => {
					this.$router.push({ name: this.successRoute })
				}, 800)
			}
		},
		getSession() {
			if (this.getCredentialsEntered.raw_id_token) {
				this.actionLoginApplication({ raw_id_token: this.getCredentialsEntered.raw_id_token })
			} else {
				this.actionLoginApplication({
					username: this.findValue('email'),
					password: this.findValue('password'),
				})
			}
		},
		findValue(name) {
			return this.userData.find((d) => d.name === name)?.value
		},
	},
}
</script>
