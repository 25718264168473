<template>
	<div class="h-full w-full flex overflow-auto bg-white">
		<div
			class="flex flex-col gap-10 transition-all py-10 pl-16 pr-8 sm:pr-0 w-full sm:w-8/12"
		>
			<span class="text-3xl font-title">{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPE') }}</span>
			<div class="flex flex-col gap-5">
				<div class="text-gray-strong">
					{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.OFFLINE_ACTION').toUpperCase() }}
				</div>
				<div class="flex flex-wrap gap-5">
					<div
						v-for="(typeAction, index) in offlineTypes"
						:key="index"
						class="w-full md:min-w-[48%] md:max-w-[48%]"
					>
						<el-tooltip
							class="item"
							effect="dark"
							:disabled="typeAction.enabled"
							:content="
								getAdvancedSearchQuery === null && typeAction.value.includes('gotv')
									? $t(
										'ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_ADVANCED_SEARCHED'
									)
									: isContactsSelected
										? $t('ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_CONTACTS')
										: $t('ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_CARTO')
							"
							placement="bottom"
						>
							<div
								class="min-h-[118px]"
								:class="{
									inactiveType: true,
									activeType:
										typeAction.value === newAction.type && newAction.globalType === 'offline',
									disabled: !typeAction.enabled,
									'cursor-not-allowed': !typeAction.enabled,
								}"
								@click="methodSetActionNewType(typeAction)"
							>
								<div
									class="h-full flex justify-center items-center"
									:class="{ 'img-disabled': !typeAction.enabled }"
								>
									<div
										class="flex justify-center items-center min-w-[50px] min-h-[50px] rounded-full bg-pink-lighted"
									>
										<i
											class="text-3xl"
											:style="`color: ${ ACTION.COLORS.ICON_TEXT_MAIN[typeAction.value.toUpperCase()]}`"
											:class="typeAction.icon"
										/>
									</div>
								</div>
								<div
									class="flex flex-col justify-start items-start"
								>
									<span
										class="font-title text-2xl"
										:class="{ 'text-black': typeAction.enabled }"
									>
										{{ typeAction.label }}
									</span>
									<span class="leading-5">
										{{ $t(typeAction.desc) }}
									</span>
								</div>
							</div>
						</el-tooltip>
					</div>
				</div>
				<div class="text-gray-strong">
					{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.ONLINE_ACTION').toUpperCase() }}
				</div>
				<div class="flex flex-wrap gap-5 pb-10">
					<div
						v-for="(typeAction, index) in onlineTypes"
						:key="index"
						class="w-full md:min-w-[48%] md:max-w-[48%]"
					>
						<el-tooltip
							class="item"
							effect="dark"
							:disabled="typeAction.enabled"
							:content="
								getAdvancedSearchQuery === null && typeAction.value.includes('gotv')
									? $t(
										'ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_ADVANCED_SEARCHED'
									)
									: isContactsSelected
										? $t('ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_CONTACTS')
										: $t('ACTION.CALL_TO_ACTION.INFORMATIONS.NOT_AVAILABLE_ACTION_TYPE_CARTO')
							"
							placement="bottom"
						>
							<div
								class="min-h-[118px]"
								:class="{
									inactiveType: true,
									activeType:
										typeAction.value === newAction.type && newAction.globalType === 'online',
									disabled: !typeAction.enabled,
									'cursor-not-allowed': !typeAction.enabled,
								}"
								@click="methodSetActionNewType(typeAction)"
							>
								<div
									class="h-full flex justify-center items-center"
									:class="{ 'img-disabled': !typeAction.enabled }"
								>
									<div
										class="flex justify-center items-center min-w-[50px] min-h-[50px] rounded-full bg-pink-lighted"
									>
										<i
											class="text-3xl"
											:style="`color: ${ ACTION.COLORS.ICON_TEXT_MAIN[typeAction.value.toUpperCase()]}`"
											:class="typeAction.icon"
										/>
									</div>
								</div>
								<div
									class="flex flex-col justify-start items-start"
								>
									<span
										class="font-title text-2xl"
										:class="{ 'text-black': typeAction.enabled }"
									>
										{{ typeAction.label }}
									</span>
									<span class="leading-5">
										{{ $t(typeAction.desc) }}
									</span>
								</div>
							</div>
						</el-tooltip>
					</div>
				</div>
				<template
					v-for="(aActionTypeConstant, index) in getIsSoonActionTypeConstant"
					:key="index"
				>
					<is-soon
						element-type="actionCard"
						:data="aActionTypeConstant"
					/>
				</template>
			</div>
		</div>
		<div
			v-if="isSidebarActive"
			class="flex flex-col absolute sm:sticky top-28 sm:top-0 left-0 sm:flex sm:w-4/12 pt-10 px-8 bg-white h-full overflow-auto"
		>
			<div
				@click="isSidebarActive = false"
				class="sm:hidden pb-5 title text-right"
			>
				{{ $t('_COMMON.CLOSE') }}
			</div>
			<div
				v-if="newAction?.type.length"
				class="flex flex-col justify-start items-start z-20"
			>
				<span class="font-bold text-black text-xl">{{ $t('ACTION.CALL_TO_ACTION.WHAT_IS_THIS_ACTION') }}</span>
				<span class="font-title text-3xl">
					{{
						t.type(newAction)
					}}
				</span>
			</div>
			<img
				class="floating-image z-10"
				v-if="newAction?.type.length"
				:src="`/static/images/actions/creation-type-step-illustration/${this.$i18n.locale === 'fr' ? 'fr' : 'en'}/${computedGetIllustrationActionType}.svg`"
			>
			<div
				v-if="newAction?.type.length"
				class="flex flex-col h-full z-20"
			>
				<div class="flex flex-col gap-3">
					<el-divider class="m-0 mt-O mb-8" />
					<div class="flex flex-col gap-4">
						<div class="flex flex-col bold">
							<span>{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_INFO_V2.LINKS_PARAGRAPH.TITLE') }}</span>
							<span>{{ $t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_INFO_V2.LINKS_PARAGRAPH.SUBTITLE') }}</span>
						</div>

						<div class="flex flex-wrap gap-y-4 pt-4 pb-20">
							<template
								v-for="index in 6"
								:key="`link-${index}`"
							>
								<div
									v-if="t.typeLink(newAction.type, index, newAction.type === 'event' ? newAction.globalType : '')?.link"
									class="w-1/2"
								>
									<div class="flex items-start justify-start gap-4">
										<i
											class="text-2xl relative -mt-1"
											:class="t.typeLink(newAction.type, index, newAction.type === 'event' ? newAction.globalType : '').icon"
										/>
										<div class="flex flex-col">
											<span class="bold">{{ t.typeLink(newAction.type, index, newAction.type === 'event' ? newAction.globalType : '').title }}</span>
											<p
												v-dompurify-html:target="t.typeLink(newAction.type, index, newAction.type === 'event' ? newAction.globalType : '').link"
											/>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isSoonConstant } from '../../../isSoon/isSoonConstant'
import { firstBy } from 'thenby'
import isEmpty from 'lodash/isEmpty'
import toUpper from 'lodash/toUpper'
import { mapGetters, mapActions } from 'vuex'
import { actionTypology } from 'src/mixins'
import { ACTION } from 'src/constants'
import { defineAsyncComponent } from 'vue'
import { getActionIconByTypology } from '../../../../utils/GetActionIconsByTypology.ts'
import { getCoverByActionType } from '../../../../utils/ActionCovers.ts'

/**
 * @component Action/CallToAction/Type
 *
 * @desc handle the type for a new action.
 * *Warning*: mixin method to create computed

 */

export default {
	components: {
		IsSoon: defineAsyncComponent(() => import('../../../isSoon/isSoon.vue')),
	},
	emits: ['update:nextValidatorBoolean', 'update:nextFunction', 'update:errorMessage'],

	mixins: [actionTypology],

	props: {
		nextValidatorBoolean: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			ACTION,
			isContactsSelected: false,
			isFromPolygonCommandCenter: false,
			isSidebarActive: true,
			screenSize: window.innerWidth,
		}
	},

	computed: {
		...mapGetters(['total_hit']),
		...mapGetters('@filter', [
			'filter',
			'getIsFilterModifiedFromOrigin',
			'getIsFilterModifiedFromOrigin',
			'polygon',
			'form_filter_data',
		]),
		...mapGetters('@search', [
			'getAdvancedSearchQuery',
			'isStreetAddressOrPollingStationsIncluded',
		]),
		...mapGetters({
			locationSummaryGeoHash: 'location_summary_geohash',
		}),
		...mapGetters('@action', ['newAction', 'steps', 'activeStep', 'entryPoint']),

		t() {
			const prefix = 'ACTION.CALL_TO_ACTION'
			return {
				cancel: this.$t('_COMMON.CANCEL'),
				create: this.$t('_COMMON.CREATE'),

				typeMandatory: this.$t(`${prefix}.VALIDATE.TYPE`),
				typeLabel: this.$t(`${prefix}.INFORMATIONS.TYPE`),

				type: (actionInfos) => {
					if (actionInfos.type == 'event') {
						if (actionInfos.globalType == 'online') {
							return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.EVENT_ONLINE')
						}
						else {
							return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.EVENT_OFFLINE')
						}
					}
					else if (actionInfos.type == 'other') {
						if (actionInfos.globalType == 'online') {
							return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.OTHER_ONLINE')
						}
						else {
							return this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES.OTHER_OFFLINE')
						}
					}
					else {
						return this.$te(`${prefix}.INFORMATIONS.TYPES.` + actionInfos.type.toUpperCase())
							? this.$t(`${prefix}.INFORMATIONS.TYPES.` + actionInfos.type.toUpperCase())
							: actionInfos.type
					}
				},

				typeLink: (suffix, pointIndex, globalType = '') => {
					const suffixGlobalType = `${suffix}${globalType !== '' ? '_' : ''}${globalType}`.toUpperCase()

					const link = {
						title: this.$t(`${prefix}.INFORMATIONS.TYPES_INFO_V2.` + suffixGlobalType + `.LINKS.LINK_${pointIndex}.TITLE`),
						link: this.$t(`${prefix}.INFORMATIONS.TYPES_INFO_V2.` + suffixGlobalType + `.LINKS.LINK_${pointIndex}.LINK`),
						icon: this.$t(`${prefix}.INFORMATIONS.TYPES_INFO_V2.` + suffixGlobalType + `.LINKS.LINK_${pointIndex}.ICON`),
					}
					return this.$te(`${prefix}.INFORMATIONS.TYPES_INFO_V2.` + suffixGlobalType + `.LINKS.LINK_${pointIndex}.TITLE`) ? link : null
				},
			}
		},

		getIsSoonActionTypeConstant() {
			return isSoonConstant.ACTION_TYPE
		},

		/**
		 * @computed {Array<{label: String, value: String}>} online types
		 * @desc Translated & sorted online types of actions
		 */
		onlineTypes() {
			return Object.keys(ACTION.ONLINE_TYPES)
				.map((key) => {
					return {
						label: this.t.type({ type: ACTION.ONLINE_TYPES[key].label, globalType: 'online' }) || key,
						desc: ACTION.ONLINE_TYPES[key].desc || key,
						value: ACTION.ONLINE_TYPES[key].label,
						enabled: this.methodEnabledTypologiesAction(key),
						icon: getActionIconByTypology(ACTION.ONLINE_TYPES[key].label === 'event' ? 'event_online' : ACTION.ONLINE_TYPES[key].label).icon,
						globalType: 'online',
					}
				})
				.sort(firstBy('enabled', -1))
			// return this.cleanQuery(a.label).localeCompare(this.cleanQuery(b.label))
		},

		/**
		 * @computed {Array<{label: String, value: String}>} offline types
		 * @desc Translated & sorted offline types of actions
		 */
		offlineTypes() {
			return Object.keys(ACTION.OFFLINE_TYPES)
				.map((key) => {
					return {
						label: this.t.type({ type: ACTION.OFFLINE_TYPES[key].label, globalType: 'offline' }) || key,
						desc: ACTION.OFFLINE_TYPES[key].desc || key,
						value: ACTION.OFFLINE_TYPES[key].label,
						enabled: this.methodEnabledTypologiesAction(key),
						icon: getActionIconByTypology(ACTION.OFFLINE_TYPES[key].label === 'event' ? 'event' : ACTION.OFFLINE_TYPES[key].label).icon,
						globalType: 'offline',
					}
				})
				.sort(firstBy('enabled', -1))
			// return this.cleanQuery(a.label).localeCompare(this.cleanQuery(b.label))
		},

		/**
		 * @computed {String} next
		 * @desc the next route to handle in the CTATunnel
		 */
		next() {
			if (this.activeStep.index < 3) {
				return this.steps[this.activeStep.index + 1].route
			}
			else {
				return null
			}
		},

		/**
		 * @computed {String} previous
		 * @desc the previous route to handle in the CTATunnel
		 */
		previous() {
			if (this.activeStep.index > 0) {
				return this.steps[this.activeStep.index - 1].route
			}
			else {
				return null
			}
		},

		computedGetValidator() {
			return !!this.newAction.type.length
		},

		computedGetIllustrationActionType() {
			let type = this.newAction.type
			switch (type) {
				case 'mail':
					return 'mailbox'
				case 'event': {
					if (this.newAction.globalType === 'online') {
						return 'event_online'
					}
					else {
						return 'event'
					}
				}
				case 'static':
					return 'static'
				case 'call':
				case 'gotvcalling':
					return 'calling'
				case 'gotvcanvassing':
				case 'canvassing':
					return 'canvassing'
				case 'sharing':
					return 'sharing'
				case 'other':
					return 'custom'
				default:
					return type
			}
		},
	},

	watch: {
		computedGetValidator: {
			handler(newValue, oldValue) {
				this.$emit('update:nextValidatorBoolean', newValue)
				if (oldValue === true && newValue === false) this.$emit('update:errorMessage', this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPE'))
			},
			immediate: true,
		},

		screenSize(newValue) {
			if (newValue > 640 && !this.isSidebarActive) {
				this.isSidebarActive = true
			}
			else if (newValue < 640 && this.isSidebarActive) {
				this.isSidebarActive = false
			}
		},
	},

	mounted() {
		window.addEventListener('resize', this.handleResize)
		this.actionResetActionMeetingPoint()
		this.handleResize()
		this.actionSetStep('TYPE')
		this.isSidebarActive = true
		/**
		 * Give an information about the origin of the user.
		 * If the user come from the contact list database and
		 * that the filter is non used, we will display the possibility
		 * that the user can use the filter (street and territory)
		 */

		/**
			* We need to block the results if the user come from the contact list
			* and that the filter is modified.
			* We also need to block the results if the user come from the command center
			* and that the filter is modified.
			* We also need to block the results if the user come from the contact list
			* and that the advanced search is used.
		  */
		const needToBlockResultsBoolean
			= (this.entryPoint === 'contact-list' && this.getIsFilterModifiedFromOrigin)
			|| this.isStreetAddressOrPollingStationsIncluded
			|| this.entryPoint === 'command-center'
			// We want to disable the selection of streets and addresses
			// if the user come from database contacts with a polygon choosen.
			|| !isEmpty(this.polygon)
			|| (this.entryPoint === 'contact-list' && !isEmpty(this.getAdvancedSearchQuery))

		/**
		 * To know if contacts were selected
		 */
		this.isFromPolygonCommandCenter = this.entryPoint === 'command-center' || !isEmpty(this.polygon)
		this.isContactsSelected = needToBlockResultsBoolean && this.total_hit > 0

		/**
		 * This point is important to set the number of contacts selected
		 * in our tunnel.
		 */
		if (
			(this.entryPoint === 'contact-list' || this.entryPoint === 'command-center')
			&& needToBlockResultsBoolean
			&& this.entryPoint === 'contact-list'
			&& this.getAdvancedSearchQuery
		) {
			this.actionOverrideKpiTotalHit(this.total_hit)
		}

		if (
			(this.entryPoint === 'contact-list'
			&& !this.getIsFilterModifiedFromOrigin
			&& !this.isStreetAddressOrPollingStationsIncluded)
			|| this.entryPoint === 'actions'
			|| this.entryPoint === 'command-center'
			|| this.entryPoint === 'profiles'
		) {
			this.actionSetResetActionModuleOnCloseModal(true)
		}

		/**
		 * The idea behind the following line is to
		 * provide the global filters to a local filter system.
		 * In this case, the action store will handle for global filters
		 * and will use it to modify them without modifying the global filters.
		 */
		this.actionSetNewActionFilters(this.filter)
		this.actionSetNewAdvancedSearchFilters(this.getAdvancedSearchQuery)
		this.actionSetBlockCitiesAndTerritories(needToBlockResultsBoolean)
		this.$emit('update:errorMessage', this.$t('ACTION.CALL_TO_ACTION.INFORMATIONS.TYPE'))
		this.$emit('update:nextFunction', this.methodNextFunctionToChangeParams)
	},

	methods: {
		...mapActions('@action', [
			'actionSetNewActionType',
			'actionSetNewActionGlobalType',
			'actionSetBlockCitiesAndTerritories',
			'actionSetResetActionModuleOnCloseModal',
			'actionSetNewActionFilters',
			'actionSetNewAdvancedSearchFilters',
			'actionSetNewActionParamsFromType',
			'actionSetStep',
			'actionOverrideKpiTotalHit',
			'actionResetActionMeetingPoint',
		]),

		getActionIconByTypology,
		getCoverByActionType,

		/**
		 * @method methodNextFunctionToChangeParams
		 * @desc This method is used to change some parameters depending
		 * of the action type.
		 */
		methodNextFunctionToChangeParams() {
			this.actionSetNewActionParamsFromType()
		},

		methodSetActionNewType(type) {
			if (type.enabled) {
				let newType = ''
				let newGlobalType = ''
				// Put the new type if it's different, deselect otherwise
				if (this.newAction.type !== type.value || this.newAction.globalType !== type.globalType) {
					newType = type.value
					newGlobalType = type.globalType
				}
				if (this.screenSize < 640) {
					newType !== '' ? this.isSidebarActive = true : this.isSidebarActive = false
				}

				this.actionSetNewActionGlobalType(newGlobalType)
				this.actionSetNewActionType(newType)
			}
		},

		// methodEnabledTypologiesAction(key) {
		// 	if (this.isContactsSelected || this.isFromPolygonCommandCenter) {
		// 		return this.isAllowedForCreationWithSelectedContacts(ACTION.TYPES[key])
		// 	}
		// 	return true
		// },

		methodEnabledTypologiesAction(key) {
			if (this.isContactsSelected || this.isFromPolygonCommandCenter) {
				if (key === 'CALL') {
					if (
						this.isAllowedForCreationWithSelectedContacts(ACTION.TYPES[key])
						&& this.newAction.search.filter[11] != 'UNSET'
					) {
						return true
					}
					else {
						return false
					}
				}
				else if (
					key === toUpper(ACTION.TYPES.GOTVCANVASSING)
					|| key === toUpper(ACTION.TYPES.GOTVCALLING)
				) {
					if (isEmpty(this.getAdvancedSearchQuery)) {
						return false
					}
					return true
				}
				else {
					return this.isAllowedForCreationWithSelectedContacts(ACTION.TYPES[key])
				}
			}
			// GOTV actions are enabled only if contacts are selected from advancedSearch
			if (
				(key === toUpper(ACTION.TYPES.GOTVCANVASSING)
				|| key === toUpper(ACTION.TYPES.GOTVCALLING))
				&& isEmpty(this.getAdvancedSearchQuery)
			) {
				return false
			}
			return true
		},

		/**
		 * @method cleanQuery
		 * @param {String} query
		 * @desc lower case, normalize and remove any accent to the given string
		 * @returns {String}
		 *
		 * @example
		 * cleanQuery('ÉÎö') // returns 'eio'
		 *
		 * @deprecated  extract every cleanQuery throughout the app in one place. Mixin, Filter ?
		 */
		cleanQuery(query) {
			// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
			return query
				.toString()
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
		},

		handleResize() {
			this.screenSize = window.innerWidth
		},
	},
}
</script>

<style lang="scss">
.gui-check_v2:before {
	@apply text-green-lighted
}

@keyframes float {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-5px, 5px) rotate(-0.5deg); }
  20% { transform: translate(3px, 10px) rotate(0.5deg); }
  30% { transform: translate(5px, -3px) rotate(0.2deg); }
  40% { transform: translate(-3px, -5px) rotate(0.5deg); }
  50% { transform: translate(0, 8px) rotate(-0.5deg); }
  60% { transform: translate(5px, 3px) rotate(0.2deg); }
  70% { transform: translate(-5px, 7px) rotate(-0.8deg); }
  80% { transform: translate(3px, -5px) rotate(0.5deg); }
  90% { transform: translate(-2px, 3px) rotate(0deg); }
}

.floating-image {
  animation: float 20s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

.type_link {
	@apply text-pink-main underline
}
</style>

<style lang="sass">
.inactiveType

	cursor: pointer
	@apply flex items-center gap-5 border rounded-3xl border-gray-light bg-white px-4 py-5
	&:hover
		border-color: #05B66F
	&.activeType
		border-color: #05B66F
	&.disabled
		@apply border-gray-light text-gray-medium
.padding-right-types
	padding-right: 3%
.img-disabled
	filter: grayscale(100%)

</style>

<style lang="sass" scoped>
.w-36-rem
	width: 12rem
</style>
