import template_pap_politic_fr from './template-pap-politic-fr.json'
import template_pap_politic_en from './template-pap-politic-en.json'

import template_town_fr from './template-commune-fr.json'
import template_town_en from './template-commune-en.json'

import template_democracy_participative_fr from './template-démocratie-participative-fr.json'
import template_democracy_participative_en from './template-démocratie-participative-en.json'

import template_mediation_fr from './template-mediation-rapport-technique-fr.json'
import template_mediation_en from './template-mediation-rapport-technique-en.json'

import template_action_fr from './template_action-fr.json'
import template_action_en from './template_action-en.json'

import template_status_fr from './template_status-fr.json'
import template_status_en from './template_status-en.json'

import template_nb_status_fr from './template_nb_status-fr.json'
import template_nb_status_en from './template_nb_status-en.json'

export function templatesDictionnary(locale = 'en') {
	let templatesObject
	switch (locale) {
		case 'fr':
			templatesObject = {
				['template_pap_politic_fr']: template_pap_politic_fr,
				['template_town_fr']: template_town_fr,
				['template_democracy_participative_fr']: template_democracy_participative_fr,
				['template_mediation_fr']: template_mediation_fr,
				['template_status_fr']: template_status_fr,
				['template_nb_status_fr']: template_nb_status_fr,
				['template_action_fr']: template_action_fr,
			}
			break
		case 'en':
			templatesObject = {
				['template_pap_politic_en']: template_pap_politic_en,
				['template_town_en']: template_town_en,
				['template_democracy_participative_en']: template_democracy_participative_en,
				['template_mediation_en']: template_mediation_en,
				['template_status_en']: template_status_en,
				['template_nb_status_en']: template_nb_status_en,
				['template_action_en']: template_action_en,
			}
			break

		default:
			templatesObject = {
				['template_pap_politic_en']: template_pap_politic_en,
				['template_town_en']: template_town_en,
				['template_democracy_participative_en']: template_democracy_participative_en,
				['template_mediation_en']: template_mediation_en,
				['template_status_en']: template_status_en,
				['template_nb_status_en']: template_nb_status_en,
				['template_action_en']: template_action_en,
			}
			break
	}

	return templatesObject
}
