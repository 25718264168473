<template>
	<div class="flex flex-col gap-y-1">
		<SegmentModalEdition
			v-if="segmentSelected"
			v-model:display-modal="showModalSegment"
			:segment-selected="segmentSelected"
		/>
		<div class="flex flex-row justify-between items-center p-8 bg-white gap-x-6">
			<p>
				{{ t('SETTINGS.SEGMENTS.DESCRIPTION') }}
			</p>
			<div>
				<q-button @click="methodInitNewSegmentCreation">
					{{
						t('SETTINGS.SEGMENTS.CREATE_SEGMENT')
					}}
				</q-button>
			</div>
		</div>
		<div class="w-full p-8 bg-white">
			<div class="w-full rounded-sm">
				<el-table
					:data="getSegments"
					stripe
					style="width: 100%"
					class="w-full mb-8 dynamic-lists-table"
					header-cell-class-name="tableHeaderCellStyle"
					row-class-name="tableRowStyle cursor-pointer"
					cell-class-name="tableRowCellStyle"
					@cell-click="methodSetCellClickSegment"
				>
					<el-table-column
						prop="title"
						:label="t('SETTINGS.SEGMENTS.TABLE.NAME')"
						width="300"
					/>
					<el-table-column
						prop="updated_at"
						:label="$t('SETTINGS.SEGMENTS.TABLE.LAST_CHANGE')"
						width="200"
					>
						<template #default="{ row }">
							{{ methodFormatDate(row) }}
						</template>
					</el-table-column>
					<el-table-column
						min-width="200"
						prop="user_id"
						align="left"
						:label="$t('CONTACT.DYNAMIC_LISTS.MODIFIED_BY')"
					>
						<template #default="{ row }">
							<span
								v-tooltip
								class="w-full align-middle"
							>
								{{ methodGetMember(row.user_id) }}
							</span>
						</template>
					</el-table-column>
					<el-table-column
						min-width="600"
						prop="id"
						align="left"
						:label="t('SETTINGS.SEGMENTS.TABLE.ASSIGNED_TO')"
					>
						<template #default="{ row }">
							<span
								v-tooltip
								class="w-full align-middle"
							>
								<SegmentUsersAffectation
									:id-list="row.id"
									:all-users="getAllUsers"
								/>
							</span>
						</template>
					</el-table-column>
					<el-table-column
						min-width="90"
						align="right"
					>
						<template #default="{ row }">
							<div class="flex items-center justify-end pr-4">
								<span
									class="flex items-center p-3 pointer text-lg hover-scale"
									@click.stop="
										() => {
											methodSetCellClickSegment(row)
										}
									"
								>
									<i class="gui-edit text-green-main" />
								</span>
								<i
									class="bg-gray-light"
									style="width: 1px; height: 24px"
								/>
								<span
									class="flex items-center p-3 pointer text-lg hover-scale"
									@click.stop="
										() => {
											methodDeleteSegmentModal(row)
										}
									"
								>
									<i class="gui-delete text-gray-dark" />
								</span>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<SegmentDeleteModal
					v-model:display-modal="showDeleteModal"
					@delete="methodDeleteSegment"
					:segment-selected-title="segmentSelected?.title || ''"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import QueryNode from '@quorumsco/quorum-helpers/lib/filter/QueryNode'
import cloneDeep from 'lodash/cloneDeep'
import { formatCustomDate } from '@/utils/dateFnsHelper'
import { ElMessage } from 'element-plus'
import SegmentModalEdition from './SegmentModalEdition.vue'
import SegmentUsersAffectation from './SegmentUsersAffectation.vue'
import SegmentDeleteModal from './SegmentDeleteModal.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()

const segmentSelected = ref<SegmentItem | null>(null)
const showModalSegment = ref(false)
const showDeleteModal = ref(false)

const getSegments = computed(() => store.getters['@lists/getSegments'])
const getAllUsers = computed(() => store.getters.getAllUsers)
const getTags = computed(() => store.getters['@tags/getTags'])

onMounted(() => {
	store.dispatch('@lists/actionFetchLists')
	if (!getTags.value.length) {
		store.dispatch('@tags/actionGetTags')
	}
})

/**
 * Watcher
 * @desc Watcher for the showModalSegment value
 * @desc If the value is false, the segment selected is set to null
 * @param {boolean} value
 */
watch(showModalSegment, (value: boolean) => {
	if (value === false) {
		segmentSelected.value = null
	}
})

const methodSetCellClickSegment = (row: any) => {
	const { payload: segment } = row

	if (segment) {
		const clonedSegment = cloneDeep(row)
		const deserializedPayload = QueryNode.deserialize(segment)

		clonedSegment.deserializedPayload = deserializedPayload

		segmentSelected.value = clonedSegment
		showModalSegment.value = true
	}
}

const methodInitNewSegmentCreation = () => {
	const rootQueryNode = new QueryNode('$all')
	const rootQuerySecond = new QueryNode('$all')
	const firstChild = new QueryNode('$condition')
	const secondChild = new QueryNode('$condition')

	rootQueryNode.addChild(rootQuerySecond)
	rootQuerySecond.addChild(firstChild)
	rootQuerySecond.addChild(secondChild)

	segmentSelected.value = {
		id: 0,
		title: '',
		payload: '',
		deserializedPayload: rootQueryNode,
	}

	showModalSegment.value = true
}

const methodFormatDate = (row: SegmentItem) => {
	let date = row?.updated_at ? new Date(row.updated_at) : row?.updated_at

	return date ? formatCustomDate(date) : '-'
}

const methodGetMember = (userId) => {
	const user = getAllUsers.value.find((user) => user.id === userId)

	return user ? `${user.firstname} ${user.surname}` : t('CONTACT.LIST.USER_UNKNOWN', { id: userId })
}

const methodDeleteSegmentModal = (row: SegmentItem) => {
	segmentSelected.value = row
	showDeleteModal.value = true
}

const methodDeleteSegment = async () => {
	await store.dispatch('@lists/actionRemoveList', segmentSelected.value)
	showDeleteModal.value = false
	ElMessage({
		message: t('SETTINGS.SEGMENTS.MODAL.DELETE.SUCCESS'),
		type: 'success',
		showClose: true,
	})
}
</script>

<style>
#segment-dialog .el-dialog__body {
	@apply px-16 pt-8;
}
</style>
