<template>
	<div :class="`create-form-help pt-9 border-t flex ${customCss ? customCss : ''}`">
		<span class="gui-infos text-2xl flex" />

		<div class="mt-1 ml-3">
			<p class="font-bold mb-3">{{ $t('_COMMON.HELP') }}</p>

			<p
				v-for="(text, index) in helpData.texts"
				:key="index"
				class="text-gray-strong mb-3"
				v-html="text"
			/>

			<a
				v-if="helpData.link"
				:href="helpData.link.href"
				target="_blank"
				class="flex items-center gap-x-1.5 cursor-pointer"
				v-on="helpData.link.onClick ? { click: helpData.link.onClick } : {}"
			>
				<i class="gui-settings" />
				<span class="text-sm">{{ helpData.link.text }}</span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		customCss: { type: String, default: '', required: false },
		helpData: {
			type: Object,
			default() {
				return { texts: [''], link: '' }
			},
			required: true,
		},
	},
}
</script>
