<template>
	<div>
		<!-- Select ui -->
		<div
			v-if="param.select"
			class="w-full flex justify-between items-center"
		>
			<label
				:for="param.select.label"
				class="mb-1"
				:class="{
					bold: true,
					'text-pink-main text sm': false,
					//'text-gray-medium': inputData.isDisabled,
				}"
			>
				{{ param.select.label }}
			</label>

			<el-select
				:model-value="selectedOption"
				placement="bottom"
				class="select-importance ml-auto w-32"
				style="min-width: 128px"
				@change="($event) => onChangeSelectOption($event)"
			>
				<div
					v-for="option in param.select.options"
					:key="option.value"
				>
					<el-option
						placement="bottom"
						:label="option.label"
						:value="option.value"
						class="text-sm flex items-center gap-x-2 justify-between"
					>
						<span style="float: left">
							{{ option.label }}
						</span>
						<i
							v-if="selectedOption === option.value"
							class="gui-yes text-lg"
						/>
					</el-option>
				</div>
			</el-select>
		</div>
		<!-- Switch ui -->
		<div
			v-else-if="param.switch"
			class="w-full flex justify-between items-center mb-4"
		>
			<label
				:for="param.switch.label"
			>
				{{ param.switch.label }}
			</label>

			<el-switch
				:model-value="selectedOption"
				class="toggleRedirection"
				:inactive-text="selectedOption ? param.switch.options[1].label : param.switch.options[0].label"
				@change="($event) => onChangeSelectOption($event)"
			/>
		</div>

		<!-- Redirection input -->
		<div v-if="selectedOption === computedParams.options[1].value">
			<CreateFormInput
				:input-data="param.input"
				v-model:value="urlToDisplay"
			/>
			<p
				v-if="!isURLGood('https://' + urlToDisplay) && urlToDisplay !== ''"
				class="text-pink-main mt-3"
			>
				{{ urlToDisplay }} {{ $t('CREATE_FORM.CONFIRMATION_PAGE.ERROR') }}
			</p>
		</div>
	</div>
</template>
<script>
import CreateFormInput from '../../inputs/createFormInput.vue'
export default {
	components: {
		CreateFormInput,
	},
	props: {
		form: { type: Object, default: () => {}, required: true },
		param: { type: Object, default: () => {}, required: true },
	},
	emits: ['update:form'],
	data() {
		return {
			selectedOption: '',
			urlToDisplay: this.form[this.param.input.name] || '',
		}
	},
	computed: {
		computedForm: {
			get() {
				return this.form
			},
			set(val) {
				this.$emit('update:form', val)
			},
		},
		computedParams() {
			// Retrun the params without taking care of the ui type
			return this.param.select ? this.param.select : this.param.switch
		},
	},
	mounted() {
		this.selectedOption = this.form[this.param.input.name] ? this.computedParams.options[1].value : this.computedParams.options[0].value
	},
	watch: {
		urlToDisplay(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.computedForm[this.param.input.name] = newValue
			}
		},
	},
	methods: {
		onChangeSelectOption(event) {
			this.selectedOption = event
			if (this.selectedOption === this.computedParams.options[0].value) {
				this.computedForm[this.param.input.name] = ''
			}
		},
		isURLGood(string) {
			let givenURL
			try {
				givenURL = new URL(string)
			}
			catch (error) {
				return false
			}
			return true
		},
	},
}
</script>

<style lang="sass">
.toggleRedirection
	.el-switch__label
		width: max-content
	.el-switch__label.is-active
		@apply text-black
</style>
