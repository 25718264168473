import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-6" }
const _hoisted_2 = { class: "flex flex-row gap-x-6" }
const _hoisted_3 = { class: "flex-col flex text-xl gap-y-2" }
const _hoisted_4 = {
  style: 'margin-left: 10rem;',
  class: "flex flex-col gap-y-1 w-1/3"
}
const _hoisted_5 = { class: "text-pink-main text-sm" }
const _hoisted_6 = { class: "flex flex-row items-center justify-between" }
const _hoisted_7 = { class: "flex flex-row gap-x-8 items-center justify-center" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

import { ref, watch, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import { ElMessage } from 'element-plus'
import { searchModule } from '@/store/modules/search-module'
import { filterModule } from '@/store/modules/filter_module'
import { kpiModule } from '@/store/modules/kpi_module'
import { formModule } from '@/store/modules/form-module'
import TabWithNumber from '@/components/general/tabWithNumber.vue'
import ContainerFilter from '@/components/filters-module/containerFilter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentModalEdition',
  props: /*@__PURE__*/_mergeModels({
	segmentSelected: {
		type: Object as () => SegmentItem | null,
		default: null,
	},
}, {
    "displayModal": {
	type: Boolean,
},
    "displayModalModifiers": {},
  }),
  emits: ["update:displayModal"],
  setup(__props) {

const store = useStore()
const { t } = useI18n()

const displayModal = _useModel(__props, 'displayModal')

const refLocalSegmentToEdit = ref<SegmentItem | null>(null)
const refInstanciedModuleId = ref<string>('')
const refResetAdvancedSearch = ref<number>(0)
const refRegisteredModules = ref<boolean>(false)
const refQueryBuildedDispatched = ref<object>({})
const tabSelected = ref<string>('filters')

const props = __props

const computedGetNumberContactsFoundsAdvancedSearchKPI = computed(
	() =>
		store.getters[`@search-${refInstanciedModuleId.value}/getNumberContactsFoundsAdvancedSearchKPI`]
)

const computedIsSegmentCreation = computed(() => {
	return props?.segmentSelected?.id === 0
})

const computedIsTitleCompleted = computed(() => {
	return refLocalSegmentToEdit?.value?.title !== ''
})

const computedIsBuildedSegmentEqual = computed(() => {
	return (
		isEqual(
			refQueryBuildedDispatched?.value?.query,
			props.segmentSelected?.deserializedPayload.parse()
		) && refLocalSegmentToEdit?.value?.title === props.segmentSelected?.title
	)
})

watch(
	() => props.segmentSelected,
	() => {
		if (props.segmentSelected) {
			refLocalSegmentToEdit.value = cloneDeep(props.segmentSelected)
		}
	},
	{ immediate: true }
)

onBeforeMount(() => {
	const instanciedModuleId = uuidv4()
	var promiseSearchInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@search-${instanciedModuleId}`, searchModule))
	})

	var promiseFilterInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@filter-${instanciedModuleId}`, filterModule))
	})
	var promiseKpiInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@kpi-${instanciedModuleId}`, kpiModule))
	})
	var promiseFormInstance = new Promise((resolve) => {
		resolve(store.registerModule(`@form-${instanciedModuleId}`, formModule))
	})

	const promises = [
		promiseSearchInstance,
		promiseFilterInstance,
		promiseKpiInstance,
		promiseFormInstance,
	]

	Promise.all(promises)
		.then(() => {
			refRegisteredModules.value = true
			refInstanciedModuleId.value = instanciedModuleId
		})
		.catch((err) => {
			console.log(err)
		})
})

const methodDispatchKpiLocalContactsFromAdvancedSearch = (queryBuilded: any) => {
	refQueryBuildedDispatched.value = queryBuilded
	if (refInstanciedModuleId.value && !isEmpty(refQueryBuildedDispatched.value)) {
		store.dispatch(
			`@search-${refInstanciedModuleId.value}/actionKpiLocalContactsFromAdvancedSearch`,
			queryBuilded
		)
	}
}

const methodUpdateSegmentSelected = async () => {
	refLocalSegmentToEdit.value.payload = JSON.stringify(refQueryBuildedDispatched.value.query)
	refLocalSegmentToEdit.value.is_segment = true
	if (refLocalSegmentToEdit.value.id === 0) {
		await store.dispatch('@lists/actionCreateList', refLocalSegmentToEdit.value)
	} else {
		await store.dispatch('@lists/actionUpdateList', refLocalSegmentToEdit.value)
	}
	displayModal.value = false
	ElMessage({
		message:
			refLocalSegmentToEdit.value.id === 0
				? t('SETTINGS.SEGMENTS.MODAL.CREATED')
				: t('SETTINGS.SEGMENTS.MODAL.UPDATED'),
		type: 'success',
		showClose: true,
	})
}

const methodResetAdvancedFilter = () => {
	refResetAdvancedSearch.value++
}

return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_q_button = _resolveComponent("q-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    id: "segment-dialog",
    modelValue: displayModal.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((displayModal).value = $event)),
    width: "80%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(TabWithNumber, {
              title: 
							computedIsSegmentCreation.value
								? _unref(t)('SETTINGS.SEGMENTS.MODAL.TITLE')
								: _unref(t)('SETTINGS.SEGMENTS.MODAL.FILTERS')
						,
              selected: tabSelected.value === 'filters',
              onTabClick: _cache[0] || (_cache[0] = ($event: any) => (tabSelected.value = 'filters'))
            }, null, 8, ["title", "selected"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_divider),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_unref(t)('SETTINGS.SEGMENTS.MODAL.NAME')), 1),
              _createVNode(_component_el_input, {
                modelValue: refLocalSegmentToEdit.value.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((refLocalSegmentToEdit.value.title) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _withDirectives(_createElementVNode("div", null, [
          (refRegisteredModules.value)
            ? (_openBlock(), _createBlock(ContainerFilter, {
                key: 0,
                "force-query": refLocalSegmentToEdit.value?.deserializedPayload,
                "reset-search": refResetAdvancedSearch.value,
                onUpdate: methodDispatchKpiLocalContactsFromAdvancedSearch
              }, null, 8, ["force-query", "reset-search"]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, tabSelected.value === 'filters']
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("span", {
            class: "text-gray-500 hover:opacity-75 cursor-pointer",
            onClick: methodResetAdvancedFilter
          }, _toDisplayString(_unref(t)('SETTINGS.SEGMENTS.MODAL.REINITIATE')), 513), [
            [_vShow, tabSelected.value === 'filters']
          ]),
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_vShow, tabSelected.value === 'infos']
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('SETTINGS.SEGMENTS.MODAL.CONTACTS', computedGetNumberContactsFoundsAdvancedSearchKPI.value)), 1),
            _createVNode(_component_el_tooltip, {
              disabled: computedIsTitleCompleted.value,
              placement: "top",
              content: _unref(t)('SETTINGS.SEGMENTS.MODAL.REQUIRED')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_button, {
                  disabled: computedIsBuildedSegmentEqual.value || !computedIsTitleCompleted.value,
                  onClick: methodUpdateSegmentSelected
                }, {
                  default: _withCtx(() => [
                    (computedIsSegmentCreation.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(t)('_COMMON.CREATE')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(t)('_COMMON.TO_UPDATE')), 1))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 8, ["disabled", "content"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})