<template>
	<div
		id="root"
		ref="rootNode"
		:class="{ k: k }"
	>
		<router-view ref="rootNode2" />
	</div>
	<iframe
		src="/api/blank.html"
		id="api-cookie"
		title="api-cookie"
		hidden
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const keys = [
	'ArrowUp',
	'ArrowUp',
	'ArrowDown',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
	'ArrowLeft',
	'ArrowRight',
	'b',
	'a',
]

export default {
	data() {
		return { k: false }
	},

	computed: {
		...mapGetters('@navBar', ['isCollapse']),
	},

	mounted() {
		let k = []
		let w = window
		/* lookForKonamiCode */
		w.addEventListener('keydown', (e) => {
			const isCmdOrCtrlPressed = e.metaKey || e.ctrlKey
			const isBKey = e.key === 'b'

			if (isCmdOrCtrlPressed && isBKey) {
				this.actionSetMenuCollapse(!this.isCollapse)
			}

			const i = w.e ? e.key : e.key
			if (k.length === 10) {
				k.shift()
			}
			k.push(i)
			if (k.length === 10 && k.every((h, j) => h === keys[j])) {
				this.k = !this.k
			}
		})
	},

	methods: {
		...mapActions('@navBar', ['actionSetMenuCollapse']),
	},
}
</script>

<style lang="scss">
@import './assets/dequorum/main.sass';
@import './assets/scss/app/maplibregl.scss';
@import './assets/scss/element-ui_overide.scss';
@import './assets/scss/structure/general.scss';
@import './assets/scss/intercom/intercom.scss';
@import '../static/fonts/dequorum-font/style';
@import '../static/fonts/font-awesome/import-font-awesome/font-awesome.min';
@import './assets/scss/tailwind.scss';

.el-select__wrapper {
	border-radius: 10px;
	min-height: 40px;
	min-width: 100px;
	cursor: pointer;
}

.el-dialog {
	@apply px-8 py-5;
	.el-dialog__header {
		@apply text-left text-lg font-semibold;
		padding-left: 0;
		padding-right: 0;
	}
}

#root {
	transition:
		all 1s ease,
		filter 0.2s ease;
	background-color: $pink-lighted;
	&.k {
		filter: invert(90%);
		transform: rotate(360deg);
	}
}
#root.k img {
	filter: invert(90%);
}
#root.k .bg-img-custom {
	filter: invert(90%);
}

.gtDisplayBold {
	font-family: 'gt-easti-bold', sans-serif;
}

.custom_message_box {
	border-radius: 1.5rem;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	.el-message-box__content {
		padding-top: 1.25rem;
		padding-bottom: 1.5rem;
	}
}

.el-message {
	border-radius: 0.75rem !important;
}

.el-message__closeBtn {
	color: black !important;
}

.el-message-icon--success {
	color: #15472c !important;
}
.el-message--success {
	.el-message__content {
		color: #15472c !important;
	}
	background-color: #f0f9eb !important;
}

.el-message .el-message-icon--info {
	color: #383a3e !important;
}
.el-message--info {
	.el-message__content {
		color: #383a3e !important;
	}
	background-color: #eef2fc !important;
}

.el-message .el-message-icon--warning {
	color: #51350b !important;
}
.el-message--warning {
	.el-message__content {
		color: #51350b !important;
	}
	border-color: #f6e9d9 !important;
	background-color: #fdf6ed !important;
}

.el-message .el-message-icon--error {
	color: #770808 !important;
}
.el-message--error {
	.el-message__content {
		color: #770808 !important;
	}
	background-color: #feeff0 !important;
}

.el-input {
	--el-input-height: 40px;
}
.el-tag {
	--el-tag-text-color: var(--el-color-primary) !important;
}
.el-switch {
	.el-switch__core {
		min-width: 35px !important;
		border-radius: 14px !important;
	}
	--el-switch-on-color: #05b66f;
}
</style>
